import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (params) => axios.get(`/packages?${getQueryString(params)}`);

const create = (data) => axios.post("/packages", data);

const update = (commentId, data) => axios.put(`/packages/${commentId}`, data);

const remove = (commentId) => axios.delete(`/packages/${commentId}`);

const addFeature = (packageId, data) =>
  axios.post(`/packages/${packageId}/features`, data);

const updateFeature = (packageId, featureId, data) =>
  axios.put(`/packages/${packageId}/features/${featureId}`, data);

const deleteFeature = (packageId, featureId) =>
  axios.delete(`/packages/${packageId}/features/${featureId}`);

const addCurrency = (packageId, data) =>
  axios.post(`/packages/${packageId}/currencies`, data);

const updateCurrency = (packageId, currencyId, data) =>
  axios.put(`/packages/${packageId}/currencies/${currencyId}`, data);

const deleteCurrency = (packageId, currencyId) =>
  axios.delete(`/packages/${packageId}/currencies/${currencyId}`);

const packageService = {
  getAll,
  create,
  update,
  remove,
  addFeature,
  updateFeature,
  deleteFeature,
  addCurrency,
  updateCurrency,
  deleteCurrency,
};

export default packageService;
