import axios from '../axios'

const resolve = data => {
    return axios.put(`/form-fields/resolve-fields`, data)
}

const invalidate = data => {
    return axios.put(`/form-fields/invalidate-fields`, data)
}

const formFieldsDataService = {
    resolve,
    invalidate
}

export default formFieldsDataService
