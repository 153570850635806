import React from "react";
import { Formik, Field, Form } from "formik";
import { format } from "date-fns";

import DatePickerField from "components/lib/Shared/DatePickerField";
import Button from "components/lib/Shared/Button";
import Checkbox from "components/lib/Shared/Checkbox";
import Radio from "components/lib/Shared/Radio";
import { Constants } from "config/constants";

const DASHBOARD_PERMISSIONS = Constants.DASHBOARD_PERMISSIONS;

const dataPermissions = [
  {
    name: DASHBOARD_PERMISSIONS.VIEW_RESTRICTED_DATA,
    label: "View restricted data",
  },
  {
    name: DASHBOARD_PERMISSIONS.EDIT_OTHER_DATA,
    label: "Edit other data",
  },
  {
    name: DASHBOARD_PERMISSIONS.MANAGE_USERS,
    label: "Manage users",
  },
];

const documentPermissions = [
  {
    name: DASHBOARD_PERMISSIONS.REGISTER_OF_MEMBERS,
    label: "Register of members",
  },
  {
    name: DASHBOARD_PERMISSIONS.REGISTER_OF_DIRECTORS,
    label: "Register of Directors",
  },
  {
    name: DASHBOARD_PERMISSIONS.MINUTE_BOOK_DIRECTORS,
    label: "Minute Book - Directors",
  },
];

const AssignPermissionsSelection = ({
  initialValues,
  submitBtnLabel,
  loadingText,
  handleSubmit,
}) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        const { dashboardPermissions } = values;

        const handleDateValue = (type) => (date) => {
          setFieldValue([type], format(date, "yyyy/MM/dd"));
        };

        const handleTogglePermission = async (e) => {
          if (e.target.checked) {
            setFieldValue("dashboardPermissions", [
              ...dashboardPermissions,
              e.target.value,
            ]);
          } else {
            setFieldValue(
              "dashboardPermissions",
              dashboardPermissions.filter(
                (permission) => permission !== e.target.value
              )
            );
          }
        };

        return (
          <Form>
            <div className="flex space-x-2 my-2 justify-center items-center">
              <div className="bg-white p-4 flex flex-col grow space-y-2 rounded-sm shadow-sm sm:rounded md:rounded-md">
                <p className="text-gray-700 font-semibold">Data</p>
                {dataPermissions.map((checkboxParams) => (
                  <Checkbox
                    key={checkboxParams.name}
                    {...checkboxParams}
                    checked={dashboardPermissions.includes(checkboxParams.name)}
                    disabled={isSubmitting}
                    onChange={handleTogglePermission}
                  />
                ))}
              </div>
              <div className="bg-white p-4 flex flex-col grow space-y-2 rounded-sm shadow-sm sm:rounded md:rounded-md">
                <p className="text-gray-700 font-semibold">Document</p>
                {documentPermissions.map((checkboxParams) => (
                  <Checkbox
                    key={checkboxParams.name}
                    {...checkboxParams}
                    checked={dashboardPermissions.includes(checkboxParams.name)}
                    disabled={isSubmitting}
                    onChange={handleTogglePermission}
                  />
                ))}
              </div>
            </div>
            <hr />
            <div className="flex space-x-16 my-4">
              <Radio
                name="accessType"
                id="timeBound"
                label="Time Bound"
                value="timeBound"
                disabled={isSubmitting}
              />
              <Radio
                name="accessType"
                id="indefinite"
                label="Indefinite"
                value="indefinite"
                disabled={isSubmitting}
              />
            </div>
            {values.accessType === "timeBound" && (
              <div className="flex space-x-2">
                <div className="flex flex-col grow">
                  <Field
                    name="startDate"
                    label="Start date"
                    id="startDate"
                    component={DatePickerField}
                    disabled={isSubmitting}
                    onChange={handleDateValue("startDate")}
                    onSelect={handleDateValue("startDate")}
                    selected={
                      values?.startDate ? new Date(values.startDate) : ""
                    }
                    minDate={new Date()}
                    placeholderText="Select start date"
                    errorPath="startDate"
                  />
                </div>
                <div className="flex flex-col grow">
                  <Field
                    name="endDate"
                    label="End date"
                    id="endDate"
                    component={DatePickerField}
                    disabled={isSubmitting}
                    onChange={handleDateValue("endDate")}
                    onSelect={handleDateValue("endDate")}
                    selected={values?.endDate ? new Date(values.endDate) : ""}
                    minDate={new Date()}
                    placeholderText="Select end date"
                    errorPath="endDate"
                  />
                </div>
              </div>
            )}
            <div className="flex justify-end my-4">
              <Button
                type="submit"
                isLoading={isSubmitting}
                loadingText={loadingText}
              >
                {submitBtnLabel}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssignPermissionsSelection;
