import { Formik, Field, Form } from "formik";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";
import { format, subDays } from "date-fns";
import _isEmpty from "lodash/isEmpty";
import _pickBy from "lodash/pickBy";
import _forOwn from "lodash/forOwn";
import _times from "lodash/times";

import companySummaryValidation from "./companySummaryValidation";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import { Constants } from "config/constants";
import DatePickerField from "components/lib/Shared/DatePickerField";
import FileUploadForm from "../../CompanyOfficialsTab/FileUploadForm";
import ValidationError from "components/lib/Shared/ValidationError";
import Input from "components/lib/Shared/Input";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Tabs from "components/lib/Shared/Tabs";
import { updateCompanyState } from "state/slices/company";
import { handleFilesUpload } from "utils/filesUtils";
import { getCompanySuffix } from "utils/company";

const tabs = [
  {
    id: "company",
    label: "1. Company Summary",
  },
  {
    id: "governance",
    label: "2. Governance",
  },
];

export default function CompanySummary({ goToPrevStep, goToNextStep }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { company } = useSelector((state) => state.companySlice);
  const dispatch = useDispatch();

  const isIncorporated = company?.status === "Active";

  const registrationNameOptions = _times(company.preferredNamesCount, (num) => {
    const nameSuffix = getCompanySuffix(
      company?.[`isCompanyName${num + 1}Abbreviated`],
      company?.type
    );
    const companyPreferredName = `${_get(
      company,
      `companyName${num + 1}`
    )} ${nameSuffix}`;
    return { value: companyPreferredName, label: companyPreferredName };
  });

  if (selectedTabIndex === 1) {
    return (
      <>
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          showBorderBottom={false}
        />
        <div className="flex flex-col mt-2 space-y-3">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-6 space-y-4 bg-white sm:p-6">
              <div className="flex items-baseline justify-between text-primary">
                <p className="text-sm font-medium min-w-[13rem] whitespace-normal">
                  Full Name
                </p>
                <p className="w-full p-2 bg-formInput">
                  {company.contactPerson.fullName}
                </p>
              </div>
              <div className="flex items-baseline justify-between text-primary">
                <p className="text-sm font-medium min-w-[13rem] whitespace-normal">
                  Email address
                </p>
                <p className="w-full p-2 bg-formInput">
                  {company.contactPerson.emailAddress}
                </p>
              </div>
              <div className="flex items-baseline justify-between text-primary">
                <p className="text-sm font-medium min-w-[13rem] whitespace-normal">
                  Telephone Number
                </p>
                <p className="w-full p-2 bg-formInput">
                  {company.contactPerson.mobileNumber}
                </p>
              </div>
              <div className="flex items-baseline justify-between text-primary">
                <p className="text-sm font-medium min-w-[13rem] whitespace-normal">
                  Designation
                </p>
                <p className="w-full p-2 bg-formInput">
                  {company.contactPerson.designation}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Tabs
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        showBorderBottom={false}
      />
      <Formik
        enableReinitialize
        initialValues={{
          registrationName: company?.registrationName ?? "",
          registrationDate: company?.registrationDate ?? "",
          kraPin: company?.kraPin ?? "",
          NSSFNo: company?.NSSFNo ?? "",
          NHIFNo: company?.NHIFNo ?? "",
          fileUploads: {
            certificateOfIncorporation:
              company?.fileUploads?.certificateOfIncorporation ?? "",
            kraPin: company?.fileUploads?.kraPin ?? "",
            NSSFNo: company?.fileUploads?.NSSFNo ?? "",
            NHIFNo: company?.fileUploads?.NHIFNo ?? "",
            nameReservationConfirmation:
              company?.fileUploads?.nameReservationConfirmation ?? "",
          },
        }}
        validationSchema={companySummaryValidation()}
        onSubmit={async (values) => {
          const uploadedFiles = await handleFilesUpload(values.fileUploads);
          dispatch(
            updateCompanyState({
              company: {
                ...company,
                ...values,
                fileUploads: {
                  ...company.fileUploads,
                  ..._pickBy(
                    values.fileUploads,
                    (file) => typeof file === "string"
                  ),
                  ...uploadedFiles,
                },
              },
            })
          );
          goToNextStep();
        }}
      >
        {({ errors, values, touched, isSubmitting, setFieldValue }) => {
          const handleDateValue = (type) => (date) => {
            setFieldValue([type], format(date, "yyyy/MM/dd"));
          };

          return (
            <Form className="mt-2">
              <div>
                <div className="flex flex-col space-y-3">
                  <div
                    className="shadow sm:rounded-md sm:overflow-hidden"
                    key={1}
                  >
                    <div className="px-4 py-6 space-y-4 bg-white sm:p-6">
                      <div className="flex items-baseline justify-between">
                        <label
                          htmlFor="registrationDate"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          Registration Name
                        </label>
                        <div className="flex flex-col w-full">
                          <Field
                            name="registrationName"
                            component={ReactSelectWithFormik}
                            options={registrationNameOptions}
                            id="registrationName"
                            className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            placeholderText="Select registration name"
                            errorPath="registrationName"
                            isDisabled={isIncorporated}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <label
                          htmlFor="registrationNumber"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          Name Reservation Confirmation
                        </label>
                        <div className="flex flex-col w-full">
                          <div className="mt-1">
                            <label className="block mb-1 text-sm text-gray-600">
                              {isIncorporated
                                ? "Name Reservation Confirmation"
                                : "Upload Name Reservation Confirmation (Required) *"}
                            </label>
                            <Field
                              name="fileUploads"
                              component={FileUploadForm}
                              wrapperDivStyles="py-2"
                              documentData={[
                                {
                                  label: "",
                                  name: "nameReservationConfirmation",
                                },
                              ]}
                              onlyPreview={isIncorporated}
                            />
                            <ValidationError
                              errors={errors.fileUploads}
                              touched={touched.fileUploads}
                              name={"fileUploads.nameReservationConfirmation"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-baseline justify-between">
                        <label
                          htmlFor="registrationNumber"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          Registration Number
                        </label>
                        <Field
                          name="registrationNumber"
                          id="registrationNumber"
                          value={_get(company, "registrationNumber", "N/A")}
                          className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          disabled
                        />
                      </div>
                      <div className="flex items-baseline justify-between">
                        <label
                          htmlFor="type"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          Entity Type
                        </label>
                        <Field
                          name="type"
                          id="type"
                          value={
                            Constants.ENTITY_TYPE_AGAINST_TYPE[
                              _get(company, "type", "N/A")
                            ]
                          }
                          className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="px-4 py-6 space-y-4 bg-white border-t border-gray-300 sm:p-6">
                      <div className="flex items-baseline justify-between">
                        <label
                          htmlFor="entityStatus"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          Entity Status
                        </label>
                        <Field
                          name="status"
                          id="entityStatus"
                          value={_get(company, "status", "N/A")}
                          className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          disabled
                        />
                      </div>
                      <div className="flex items-baseline justify-between">
                        <label
                          htmlFor="registrationDate"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          Registration Date
                        </label>
                        <div className="flex flex-col w-full">
                          <Field
                            name="registrationDate"
                            component={DatePickerField}
                            id="registrationDate"
                            className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            onChange={handleDateValue("registrationDate")}
                            onSelect={handleDateValue("registrationDate")}
                            selected={
                              values?.registrationDate
                                ? new Date(values.registrationDate)
                                : ""
                            }
                            minDate={subDays(new Date(), 14)}
                            maxDate={new Date()}
                            disabled={isIncorporated}
                            placeholderText="Select registration date"
                            errorPath="registrationDate"
                          />
                          <div className="mt-1">
                            <label className="block mb-1 text-sm text-gray-600">
                              {isIncorporated
                                ? "Certificate of Incorporation"
                                : "Upload Certificate of Incorporation (Required) *"}
                            </label>
                            <Field
                              name="fileUploads"
                              component={FileUploadForm}
                              wrapperDivStyles="py-2"
                              documentData={[
                                {
                                  label: "",
                                  name: "certificateOfIncorporation",
                                },
                              ]}
                              onlyPreview={isIncorporated}
                            />
                            <ValidationError
                              errors={errors.fileUploads}
                              touched={touched.fileUploads}
                              name={"fileUploads.certificateOfIncorporation"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-baseline justify-between">
                        <label
                          htmlFor="articlesOfAssociation.natureOfBusiness"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          Nature of Business
                        </label>
                        <Field
                          name="status"
                          id="articlesOfAssociation.natureOfBusiness"
                          value={_get(
                            company,
                            "articlesOfAssociation.natureOfBusiness",
                            "N/A"
                          )}
                          className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="px-4 py-6 space-y-4 bg-white border-t border-gray-300 sm:p-6">
                      <div className="flex justify-between">
                        <label
                          htmlFor="kraPin"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          KRA PIN
                        </label>
                        <div className="flex flex-col w-full">
                          <Field
                            name="kraPin"
                            id="kraPin"
                            placeholder="Add KRA PIN"
                            component={Input}
                            disabled={isIncorporated}
                            className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          />
                          <div className="mt-1">
                            <label className="block mb-1 text-sm text-gray-600">
                              {isIncorporated
                                ? "KRA PIN Certificate"
                                : "Upload KRA PIN Certificate"}
                            </label>
                            <Field
                              name="fileUploads"
                              component={FileUploadForm}
                              wrapperDivStyles="py-2"
                              documentData={[{ label: "", name: "kraPin" }]}
                              onlyPreview={isIncorporated}
                            />
                            <ValidationError
                              errors={errors.fileUploads}
                              touched={touched.fileUploads}
                              name={"fileUploads.kraPin"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <label
                          htmlFor="NSSFNo"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          NSSF NO.
                        </label>
                        <div className="flex flex-col w-full">
                          <Field
                            name="NSSFNo"
                            id="NSSFNo"
                            placeholder="Add NSSF NO"
                            component={Input}
                            disabled={isIncorporated}
                            className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          />
                          <div className="mt-1">
                            <label className="block mb-1 text-sm text-gray-600">
                              {isIncorporated
                                ? "NSSF Registration Certificate"
                                : "Upload NSSF Registration Certificate"}
                            </label>
                            <Field
                              name="fileUploads"
                              component={FileUploadForm}
                              wrapperDivStyles="py-2"
                              documentData={[{ label: "", name: "NSSFNo" }]}
                              onlyPreview={isIncorporated}
                            />
                            <ValidationError
                              errors={errors.fileUploads}
                              touched={touched.fileUploads}
                              name={"fileUploads.NSSFNo"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <label
                          htmlFor="NHIFNo"
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          NHIF NO.
                        </label>
                        <div className="flex flex-col w-full">
                          <Field
                            name="NHIFNo"
                            id="NHIFNo"
                            placeholder="Add NHIF NO"
                            component={Input}
                            disabled={isIncorporated}
                            className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                          />
                          <div className="mt-1">
                            <label className="block mb-1 text-sm text-gray-600">
                              {isIncorporated
                                ? "NHIF Registration Certificate"
                                : "Upload NHIF Registration Certificate"}
                            </label>
                            <Field
                              name="fileUploads"
                              component={FileUploadForm}
                              wrapperDivStyles="py-2"
                              documentData={[{ label: "", name: "NHIFNo" }]}
                              onlyPreview={isIncorporated}
                            />
                            <ValidationError
                              errors={errors.fileUploads}
                              touched={touched.fileUploads}
                              name={"fileUploads.NHIFNo"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between py-3">
                <button
                  type="button"
                  disabled={isSubmitting}
                  onClick={goToPrevStep}
                  className="flex items-center px-2 py-2 button button-back button-back-hover button-focus button-disabled button-outline"
                >
                  <div className="mr-1.5 flex items-center">
                    <ChevronLeftIcon />
                    Back to previous step
                  </div>
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="flex px-2 py-2 text-white button button-hover button-disabled button-outline button-focus bg-sflPrimary"
                >
                  {isSubmitting ? (
                    <>
                      <SpinnerIcon />
                      Saving...
                    </>
                  ) : (
                    <>
                      <div className="ml-1.5 flex items-center">
                        Save and proceed
                        <ChevronRightIcon />
                      </div>
                    </>
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
