/* using react-select within formik */
import React from "react";
import Select, { components } from "react-select";
import _trim from "lodash/trim";
import _isEmpty from "lodash/isEmpty";
import _isPlainObject from "lodash/isPlainObject";
import _get from "lodash/get";
import { useSelector } from "react-redux";

import ValidationError from "../ValidationError";
import CommentsList from "components/lib/Global/CommentsList";
import { Constants } from "config/constants";

const DefaultMenu = (props) => (
  <components.Menu {...props}>
    <div>{props.children}</div>
  </components.Menu>
);

const ReactSelectWithFormik = (props) => {
  const {
    label,
    options,
    onChange,
    field = {},
    form = {},
    id,
    isDisabled,
    isMulti,
    Menu = DefaultMenu,
    innerRef,
    isSearchable = true,
    openMenuOnFocus = true,
    placeholder = "Select",
    showRequiredAsterisk,
  } = props;

  const { errors, touched, setFieldValue } = form;
  const currentUser = useSelector((state) => state.userSlice);
  const isApplicant = form?.values?.createdBy === currentUser._id;
  const name = field.name;
  const value = props?.value ?? field?.value ?? "";
  const handleChange =
    typeof onChange === "function"
      ? onChange
      : (option) => {
          setFieldValue(name, option.value);
        };

  const errorPath = props?.errorPath ? props.errorPath : name;

  const commentPath = props?.commentPath ? props.commentPath : errorPath;

  let comments = props?.comments
    ? props.comments
    : form?.values?.comments ?? [];

  if (_isPlainObject(comments)) {
    comments = _get(comments, commentPath, []);
  }

  let selectedValue = "";
  if ((!_isEmpty(value) || !!value) && _isPlainObject(value)) {
    selectedValue = value;
  } else {
    selectedValue =
      options && options.length > 0 && !!value
        ? options.find((option) => _trim(option.value) === _trim(value))
        : "";
  }
  if (Array.isArray(value)) selectedValue = value;

  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      color:
        isDisabled || resolvedFields.includes(name)
          ? "rgb(100 116 139)"
          : provided.color,
    }),
    multiValueRemove: (base) => ({
      ...base,
      display:
        isDisabled || resolvedFields.includes(name) ? "none" : base.display,
    }),
    control: (base, state) => {
      return {
        ...base,
        boxShadow: state.isFocused
          ? "0 0 0 1px rgba(99, 100, 241, 1)"
          : base.boxShadow,
        borderColor: state.isFocused
          ? "rgba(99, 100, 241, 1)"
          : base.borderColor,
        "input:focus": {
          boxShadow: "none",
        },
        "&:hover": {
          borderColor: state.isDisabled
            ? "rgb(226 232 240/1)"
            : base.borderColor,
        },
        background: state.isDisabled ? "#F8FAFC" : base.background,
        pointerEvents: "visible",
        cursor: state.isDisabled ? "not-allowed" : base.cursor,
      };
    },
    indicatorSeparator: (base) => ({ ...base, display: "none" }),
  };

  let resolvedFields = props?.resolvedFields
    ? props.resolvedFields
    : _get(form?.values, errorPath?.split(".")?.[0])?.resolvedFields ?? [];

  return (
    <div className="col-span-6 sm:col-span-3">
      {label && (
        <label
          htmlFor={id ? id : name}
          className={`block text-sm font-medium text-gray-700 ${
            Constants.REQUIRED_OFFICIALS_FIELDS.includes(name) ||
            showRequiredAsterisk
              ? "after:content-['*'] after:ml-0.5 after:text-red-500"
              : ""
          }`}
        >
          <span className="relative">
            {label}
            {comments.length > 0 && (
              <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-3 -right-5">
                <span>{comments.length}</span>
              </span>
            )}
          </span>
        </label>
      )}
      <Select
        className={`block w-full mt-1 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        options={options}
        components={{ Menu }}
        value={selectedValue}
        onChange={handleChange}
        inputId={id ? id : name}
        name={name}
        onBlur={field.onBlur}
        ref={innerRef}
        isDisabled={isDisabled || resolvedFields.includes(name)}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isOptionDisabled={(option) => option.disabled}
        placeholder={
          selectedValue
            ? placeholder
            : (form?.values?._id ? isApplicant : true) &&
              (isDisabled || resolvedFields.includes(name)) &&
              !name?.includes("beneficialOwnershipForm") &&
              (form?.values?.type === "invited" || form?.values?.hasAccess)
            ? "N/A"
            : placeholder
        }
        openMenuOnFocus={openMenuOnFocus}
        styles={customStyles}
      />
      {comments.length > 0 && (
        <CommentsList comments={comments} color={"text-red-500"} />
      )}
      {errorPath && (
        <ValidationError errors={errors} touched={touched} name={errorPath} />
      )}
    </div>
  );
};

export default ReactSelectWithFormik;
