import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

export const get = (companyId) => axios.get(`/companies/${companyId}`);

export const getCompany = (companyId) => {
  return axios.get(`/companies/${companyId}?isDashboard=true`);
};

export const getCompanyOfficials = (companyId, params) =>
  axios.get(
    `/companies/${companyId}/company-officials?${getQueryString(params)}`
  );

export const getCompanyBeneficialOwners = (companyId) =>
  axios.get(`/companies/${companyId}/beneficial-owners`);

export const getOfficial = (companyId, officialId, idType, JSGroupID) =>
  axios.get(
    `/companies/${companyId}/officials/${officialId}?idType=${idType}&JSGroupID=${JSGroupID}`
  );

const companyDashboardDataService = {
  get,
  getCompany,
  getCompanyOfficials,
  getCompanyBeneficialOwners,
  getOfficial,
};

export default companyDashboardDataService;
