import { useToastDispatchContext } from "context/ToastContext";
import {
  XIcon,
  CheckCircleIcon,
  ExclamationIcon,
} from "@heroicons/react/solid";
import { twMerge } from "tailwind-merge";

const toastTypes = {
  success: {
    bgColor: "bg-green-50",
    Icon: CheckCircleIcon,
    iconStyles: "text-green-500",
    messageStyles: "text-green-800",
    closeIconStyles:
      "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600",
  },
  error: {
    bgColor: "bg-red-50",
    Icon: ExclamationIcon,
    iconStyles: "text-red-500",
    messageStyles: "text-red-800",
    closeIconStyles:
      "bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-green-50 focus:ring-red-600",
  },
  info: {
    bgColor: "bg-blue-50",
    Icon: ExclamationIcon,
    iconStyles: "text-blue-500",
    messageStyles: "text-blue-800",
    closeIconStyles:
      "bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600",
  },
};

export default function Toast({ type = "success", message, id }) {
  const dispatch = useToastDispatchContext();

  const { bgColor, Icon, iconStyles, messageStyles, closeIconStyles } =
    toastTypes[type];

  const handleCloseToast = () => {
    dispatch({ type: "DELETE_TOAST", id });
  };

  return (
    <div className={twMerge("p-4 m-3 rounded-m", bgColor)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={twMerge("w-6 h-6", iconStyles)} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={twMerge("text-sm font-medium", messageStyles)}>
            {message}
          </p>
        </div>
        <div className="pl-3 ml-auto">
          <div className="-mx-1.5 -my-1.5">
            <button
              onClick={handleCloseToast}
              className={twMerge(
                "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2",
                closeIconStyles
              )}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
