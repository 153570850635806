import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";

import { useToast } from "hooks/useToast";
import Tabs from "components/lib/Shared/Tabs";
import Button from "components/lib/Shared/Button";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import CompanyFormation from "components/PLCTABS/ApplicationReviewTab/CompanyFormation";
import AddressParticulars from "components/PLCTABS/ApplicationReviewTab/AddressParticulars";
import ShareInformation from "components/PLCTABS/ApplicationReviewTab/ShareInformation";
import StakeholderDetails from "components/PLCTABS/ApplicationReviewTab/CompanyOfficials/StakeholderDetails";
import BeneficialOwnership from "components/PLCTABS/ApplicationReviewTab/BeneficialOwnership";
import { getComments, resetCommentsSlice } from "state/slices/comment";
import { getCompany, reset } from "state/slices/company";

const initialTabs = [
  {
    id: "companyFormation",
    label: "Company formation",
  },
  {
    id: "addressParticulars",
    label: "Address particulars",
  },
  {
    id: "shareInformation",
    label: "Share information",
  },
  {
    id: "stakeholderDetails",
    label: "Stakeholder details",
  },
  {
    id: "beneficialOwnership",
    label: "Beneficial ownership",
  },
];

function SubRowAsync({ visibleColumns, row }) {
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { toast } = useToast(5000);

  const { company, getCompanyStatus } = useSelector(
    (state) => state.companySlice
  );

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  useEffect(() => {
    if (row?.original?._id) {
      dispatch(getCompany({ id: row.original._id }));
      dispatch(getComments({ companyId: row.original._id }));
    }
    return () => {
      dispatch(reset());
      dispatch(resetCommentsSlice());
    };
  }, [row?.original?._id]);

  useEffect(() => {
    if (getCompanyStatus.status === "failed" && getCompanyStatus.error) {
      toast("error", getCompanyStatus.error);
      row.toggleRowExpanded();
    }
  }, [getCompanyStatus.status, getCompanyStatus.error]);

  if (!company?._id || getCompanyStatus.status === "loading") {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto my-4">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "companyFormation" && (
          <CompanyFormation isViewMode />
        )}
        {selectedTab.id === "addressParticulars" && (
          <AddressParticulars isViewMode />
        )}
        {selectedTab.id === "shareInformation" && (
          <ShareInformation disabledRightsEditor isViewMode />
        )}
        {selectedTab.id === "stakeholderDetails" && (
          <StakeholderDetails isViewMode />
        )}
        {selectedTab.id === "beneficialOwnership" && (
          <BeneficialOwnership handleBackBtn={goToPrevStep} isViewMode />
        )}
        <div className="flex justify-between">
          {!isFirstStep && (
            <Button
              onClick={goToPrevStep}
              preIcon={ChevronLeftIcon}
              variant="text"
              className="my-4"
            >
              Go back
            </Button>
          )}
          {isLastStep ? (
            <Button
              onClick={() => row.toggleRowExpanded()}
              className="my-4"
              postIcon={ChevronRightIcon}
            >
              Close
            </Button>
          ) : (
            <Button
              onClick={goToNextStep}
              postIcon={ChevronRightIcon}
              className="my-4 ml-auto"
            >
              Next step
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
}

export default SubRowAsync;
