import { format } from "date-fns";
import _get from "lodash/get";

import { Constants } from "config/constants";
import { formatCurrency } from "utils";

const PopulatedRow = ({
  shareholder,
  share,
  unpaidClassesOfShares = [],
  classOfSharesHeaders = [],
}) => {
  return (
    <tr className="text-xs">
      <td className="p-1 text-gray-600 bg-gray-100">
        {share.numberOfAllotmentLetter}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ minWidth: "75px" }}
      >
        {shareholder?.appointmentDate
          ? format(new Date(shareholder.appointmentDate), "dd-MM-yyyy")
          : ""}
      </td>
      {classOfSharesHeaders.map((header) => {
        return (
          <td
            key={header.label}
            className="p-1 text-gray-600 bg-gray-100"
            style={{ height: "26px", minWidth: "75px" }}
          >
            {header.key === share.classOfShares ? share.allottedShares : ""}
          </td>
        );
      })}
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ minWidth: "75px" }}
      >
        {unpaidClassesOfShares.includes(share.classOfShares)
          ? share.shareStart
          : "-"}
      </td>
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ height: "26px", minWidth: "75px" }}
      >
        {unpaidClassesOfShares.includes(share.classOfShares)
          ? share.shareEnd
          : "-"}
      </td>
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ height: "26px" }}
      ></td>
      <td className="p-1 text-gray-600 bg-gray-100" style={{ height: "26px" }}>
        {formatCurrency(share.allottedShares * share.sharePrice)}
      </td>
    </tr>
  );
};

const TableHeader = ({ classOfSharesHeaders }) => {
  return (
    <thead className="text-xs">
      <tr>
        <th
          colspan={3 + classOfSharesHeaders.length}
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 h-10"
        >
          Dr.
        </th>
        <th
          colspan="4"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 h-10"
        >
          SHARES ACQUIRED
        </th>
      </tr>
      <tr style={{ height: "110px" }}>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 rotate-180"
          style={{
            writingMode: "vertical-lr",
            textOrientation: "mixed",
          }}
        >
          No. of Allotment
        </th>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 rotate-180"
          style={{
            writingMode: "vertical-lr",
            textOrientation: "mixed",
          }}
        >
          No. of Transfer
        </th>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 min-w-[5rem]"
        >
          Date of Allotment or Entry of Transfer
        </th>
        <th
          scope="col"
          colspan={classOfSharesHeaders.length}
          className="font-semibold text-gray-900 bg-gray-100"
        >
          Total No. of Shares allotted or Transferred
        </th>
        <th
          scope="col"
          colspan="2"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          Distinctive Numbers (if Used) (Inclusive)
        </th>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 rotate-180"
          style={{
            writingMode: "vertical-lr",
            textOrientation: "mixed",
          }}
        >
          Transferor's Folio
        </th>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100  w-24"
        >
          Total Value of Shares Held (KES)
        </th>
      </tr>
      <tr>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 h-10"
        ></th>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 h-10"
        ></th>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 h-10"
        ></th>

        {/* Classes of shares headers */}
        {classOfSharesHeaders.map((classOfShare) => {
          return (
            <th
              key={classOfShare.label}
              scope="col"
              className="font-semibold text-gray-900 bg-gray-100 h-10"
            >
              {classOfShare.label}
            </th>
          );
        })}
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 h-10"
        >
          From
        </th>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 h-10"
        >
          To
        </th>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 h-10"
        ></th>
        <th
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 h-10"
        ></th>
      </tr>
    </thead>
  );
};

const EmptyRow = ({ classOfSharesHeaders = [] }) => {
  return (
    <tr className="text-xs">
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ height: "26px" }}
      ></td>
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ height: "26px" }}
      ></td>
      {classOfSharesHeaders.map((classOfShare) => (
        <td
          key={classOfShare.label}
          className="p-1 text-gray-600 bg-gray-100"
          style={{ height: "26px" }}
        ></td>
      ))}
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ height: "26px" }}
      ></td>
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ height: "26px" }}
      ></td>
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ height: "26px" }}
      ></td>
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ height: "26px" }}
      ></td>
      <td
        className="p-1 text-gray-600 bg-gray-100"
        style={{ height: "26px" }}
      ></td>
    </tr>
  );
};

const DrSharesAcquired = ({
  shareholder = {},
  unpaidClassesOfShares = [],
  classOfSharesHeaders = [],
}) => {
  const sharesAllotted = _get(shareholder, "sharesAllotted", []);
  const numberOfEmptyRows =
    Constants.REGISTERS.LEDGER_ROWS - sharesAllotted.length;

  return (
    <table className="divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
      <TableHeader classOfSharesHeaders={classOfSharesHeaders} />
      <tbody className="divide-y divide-gray-200">
        {sharesAllotted?.map((share) => {
          return (
            <PopulatedRow
              key={`${shareholder._id}_${share._id}`}
              shareholder={shareholder}
              share={share}
              unpaidClassesOfShares={unpaidClassesOfShares}
              classOfSharesHeaders={classOfSharesHeaders}
            />
          );
        })}
        {Array.from(
          new Array(numberOfEmptyRows > 0 ? numberOfEmptyRows : 0)
        ).map((_, rowIndex) => (
          <EmptyRow
            key={rowIndex}
            classOfSharesHeaders={classOfSharesHeaders}
          />
        ))}
      </tbody>
    </table>
  );
};

export default DrSharesAcquired;
