/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _pickBy from "lodash/pickBy";
import _merge from "lodash/merge";

import SectionDescription from "components/lib/Shared/SectionDescription";
import uploadDocumentsValidation from "./validations/uploadDocumentsValidation";
import { getOnboardingDocuments } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import { updateUserState } from "state/slices/user";
import { handleFilesUpload } from "utils/filesUtils";
import { getAccessRequests } from "state/slices/accessRequest";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import Button from "components/lib/Shared/Button";

const UploadDocuments = ({ handleBackBtn, handleNextBtn }) => {
  const { transformedComments } = useSelector(
    (state) => state.commentsSlice.profileComments
  );
  const { idType, fileUploads, electronicAddress } = useSelector(
    (state) => state.userSlice
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // get access requests against the current email of this user
    const handleGetAccessRequest = async () => {
      await dispatch(
        getAccessRequests({
          query: { initiatedToEmail: electronicAddress?.emailAddress },
        })
      );
    };
    handleGetAccessRequest();
  }, []);

  const handleSubmit = async (formData) => {
    const uploadedFiles = await handleFilesUpload(formData.fileUploads);
    dispatch(
      updateUserState({
        fileUploads: {
          ..._pickBy(formData.fileUploads, (file) => typeof file === "string"),
          ...uploadedFiles,
        },
      })
    );
    handleNextBtn();
  };

  const documents = getOnboardingDocuments(idType);

  const { schemaInitialValues, schema } = uploadDocumentsValidation(documents);

  const initialValues = _merge(schemaInitialValues, { fileUploads });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        comments: transformedComments,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <SectionDescription
              id="Step: 4 of 5"
              title="Upload required documents"
              description="The documents presented will be used for registration purposes."
            />
            <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
              <div className="px-4 py-6 space-y-6 sm:p-6">
                <div className="mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Upload documents
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Please provide the correct documents as instructed.
                  </p>
                </div>
                <Field
                  name="fileUploads"
                  component={FileUploadForm}
                  documentData={documents}
                />
              </div>
            </div>
            <div className="flex justify-between py-3">
              <Button
                variant="backBtn"
                onClick={handleBackBtn}
                disabled={isSubmitting}
                preIcon={ChevronLeftIcon}
              >
                Back to previous step
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                postIcon={ChevronRightIcon}
                loadingText="Saving..."
              >
                Save and proceed
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UploadDocuments;
