import { Field } from "formik";
import ValidationError from "./ValidationError";

const IsNonResidentSelection = ({ disabled, errors, touched }) => {
  return (
    <div className=" mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
      <div className="px-4 py-6 space-y-2 sm:p-6">
        <span>Is this Official Non-Resident?</span>
        <div className="flex space-x-2">
          <div className="flex items-center space-x-2">
            <label
              htmlFor="yesResident"
              className="block text-sm font-medium text-gray-700"
            >
              YES
            </label>
            <Field
              type="radio"
              name="isNonResident"
              id="yesResident"
              value="Yes"
              disabled={disabled}
              className="disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            />
          </div>
          <div className="flex items-center space-x-2">
            <label
              htmlFor="notResident"
              className="block text-sm font-medium text-gray-700"
            >
              NO
            </label>
            <Field
              type="radio"
              name="isNonResident"
              id="notResident"
              value="No"
              disabled={disabled}
              className="disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            />
          </div>
        </div>
        <ValidationError
          errors={errors}
          touched={touched}
          name="isNonResident"
        />
      </div>
    </div>
  );
};

export default IsNonResidentSelection;
