import { Link } from "react-router-dom";
import {
  ArrowLeftIcon,
  ChevronRightIcon,
  HomeIcon,
} from "@heroicons/react/outline";

const initialPages = [
  { name: "Select your package", href: "/#package-selection", current: false },
  { name: "Register your business", href: "#", current: false },
  //{ name: "Status", href: "#", current: true },
];

const BreadCrumb = (props) => {
  let pages = (props?.pages ?? initialPages).filter(Boolean);
  if (pages.length > 5) {
    pages = pages.slice(-5);
  }

  const { exitDashboardText, handleExitDashboard = () => null } = props;

  return (
    <nav aria-label="Breadcrumb" className="relative hidden sm:flex h-14">
      <ol className="absolute flex items-center space-x-4 inset-y-1/2">
        <li>
          <div>
            <Link to="/">
              <HomeIcon
                className="flex-shrink-0 w-5 h-5 text-gray-500 hover:text-sflPrimary"
                aria-hidden="true"
              />
              <span className="font-medium sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center justify-center min-w-0">
              <ChevronRightIcon
                className="flex-shrink-0 w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
              {page.current ? (
                <span
                  className="ml-4 text-sm font-semibold text-sflPrimary"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </span>
              ) : (
                <Link
                  to={page.href}
                  className={`ml-4 text-sm font-medium text-gray-500 text-ellipsis overflow-hidden ${
                    page.href ? "hover:text-sflPrimary" : ""
                  }`}
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
      {exitDashboardText && (
        <button
          className="absolute right-0 flex items-center space-x-4 text-sm font-medium inset-y-1/2 text-primary"
          onClick={handleExitDashboard}
        >
          <ArrowLeftIcon
            className="flex-shrink-0 w-4 h-4 mr-2"
            aria-hidden="true"
          />
          {exitDashboardText}
        </button>
      )}
    </nav>
  );
};

export default BreadCrumb;
