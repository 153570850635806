import { useSelector } from "react-redux";
import _get from "lodash/get";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import CommentsList from "components/lib/Global/CommentsList";

export default function AddressParticulars({ isViewMode, handleSaveComment }) {
  const { comments } = useSelector((state) => state.commentsSlice);
  const { organization } = useSelector((state) => state.organizationsSlice);

  const addressParticularsComments = comments.filter(
    (comment) => comment.formName === "residentialAddressDetails"
  );

  const postalAddressFields = [
    {
      key: "Postal Address",
      name: "postalAddress",
      value: _get(organization, "postalAddress.postalAddress") || "N/A",
    },
    {
      key: "Postal Code",
      name: "postalCode",
      value: _get(organization, "postalAddress.postalCode") || "N/A",
    },
  ];

  const electronicAddressesFields = [
    {
      key: "Company Email Address",
      name: "companyEmailAddress",
      value: _get(organization, "identificationDetails.emailAddress") || "N/A",
    },
    {
      key: "Phone Number",
      name: "mobileNumber",
      value: _get(organization, "identificationDetails.telNumber") || "N/A",
    },
  ];

  const residentialAddressFields = [
    {
      key: "Name of Building",
      name: "buildingName",
      value:
        _get(organization, "residentialAddressDetails.buildingName") || "N/A",
    },
    {
      key: "House Number",
      name: "houseNumber",
      value:
        _get(organization, "residentialAddressDetails.houseNumber") || "N/A",
    },
    {
      key: "Estate",
      name: "estate",
      value: _get(organization, "residentialAddressDetails.estate") || "N/A",
    },
    {
      key: "Floor or Suite Number",
      name: "floorNumber",
      value:
        _get(organization, "residentialAddressDetails.floorNumber") || "N/A",
    },
    {
      key: "Street Name",
      name: "streetName",
      value:
        _get(organization, "residentialAddressDetails.streetName") || "N/A",
    },
    {
      key: "Street Number",
      name: "streetNumber",
      value:
        _get(organization, "residentialAddressDetails.streetNumber") || "N/A",
    },
    {
      key: "County",
      name: "county",
      value: _get(organization, "residentialAddressDetails.county") || "N/A",
    },
    {
      key: "District",
      name: "district",
      value: _get(organization, "residentialAddressDetails.district") || "N/A",
    },
    {
      key: "Locality",
      name: "locality",
      value: _get(organization, "residentialAddressDetails.locality") || "N/A",
    },
  ];

  return (
    <>
      {addressParticularsComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 mt-4 bg-white">
          <CommentsList
            comments={addressParticularsComments}
            toggleCommentStatus={!isViewMode}
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {addressParticularsComments.length} of{" "}
            {addressParticularsComments.length}
          </p>
        </div>
      )}
      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="residentialAddressDetails"
        fields={postalAddressFields}
        resolvedFields={organization?.residentialAddressDetails?.resolvedFields}
        formHelperKey="residentialAddressDetails"
        updateEffectKey="updateOrganizationState"
        updateEffectPath="organizationsSlice.organization.residentialAddressDetails"
        formId={_get(organization?.residentialAddressDetails, "_id")}
        handleSaveComment={handleSaveComment}
        comments={addressParticularsComments}
        hideResolveAllCheckbox={isViewMode}
      />
      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="residentialAddressDetails"
        fields={electronicAddressesFields}
        resolvedFields={organization?.residentialAddressDetails?.resolvedFields}
        formHelperKey="residentialAddressDetails"
        updateEffectKey="updateOrganizationState"
        updateEffectPath="organizationsSlice.organization.residentialAddressDetails"
        formId={_get(organization?.residentialAddressDetails, "_id")}
        handleSaveComment={handleSaveComment}
        comments={addressParticularsComments}
        hideResolveAllCheckbox={isViewMode}
      />
      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="residentialAddressDetails"
        fields={residentialAddressFields}
        resolvedFields={organization?.residentialAddressDetails?.resolvedFields}
        formHelperKey="residentialAddressDetails"
        updateEffectKey="updateOrganizationState"
        updateEffectPath="organizationsSlice.organization.residentialAddressDetails"
        formId={_get(organization?.residentialAddressDetails, "_id")}
        handleSaveComment={handleSaveComment}
        comments={addressParticularsComments}
        hideResolveAllCheckbox={isViewMode}
      />
    </>
  );
}
