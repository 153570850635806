/* eslint-disable no-shadow */
/* eslint-disable no-alert */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _uniqBy from "lodash/uniqBy";
import _cloneDeep from "lodash/cloneDeep";
import _orderBy from "lodash/orderBy";
import _get from "lodash/get";
import BeneficialOwnersTable from "./BeneficialOwnersTable";
import BeneficialOwnerSelection from "./BeneficialOwnerSelection";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import { openModal } from "state/slices/modals";
import {
  addBeneficialOwnerAsync,
  deleteBeneficialOwnerAsync,
  updateDropdownEntryStatusAsync,
} from "state/slices/tables/beneficialOwnersTableSlice";
import { CORPORATES, INDIVIDUALS } from "../SFLdata/config/constants";
import {
  addMultipleCorporateShareholder,
  resetCorporateShareholderTable,
} from "state/slices/tables/corporateShareholderTableSlice";
import Tabs from "components/lib/Shared/Tabs";
import { PlusIcon } from "@heroicons/react/outline";
import {
  calculateCumulativeOwnership,
  delay,
  groupBeneficialOwners,
} from "utils";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { useParams } from "react-router-dom";
import Overlay from "components/lib/Shared/Overlay";
// import { deleteFiles } from 'utils/filesUtils'

const tabs = [
  { id: "reportableBO", label: "Reportable BO" },
  { id: "nonReportableBO", label: "Non-Reportable BO" },
];

const BeneficialOwnershipTab = ({
  handleBackBtn,
  handleNextBtn,
  submitBtnLabel,
  isSubmitting,
}) => {
  const [showBeneficialOwnersBtn, setShowBeneficialOwnersBtn] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { modals } = useSelector((state) => state.modalsSlice);
  const [mode, setMode] = useState({ id: "", editing: false });
  const dispatch = useDispatch();
  const [currentOfficial, setCurrentOfficial] = useState({});
  const {
    beneficialOwners,
    deleteBeneficialOwnerStatus,
    addBeneficialOwnerStatus,
    getAllBeneficialOwnersStatus,
    updateDropdownEntryStatusStatus,
  } = useSelector((state) => state.beneficialOwnersTableSlice);
  const [reportableBO, setReportableBO] = useState([]);
  const [nonReportableBO, setNonReportableBO] = useState([]);
  const [selectedCorporateIDType, setSelectedCorporateIDType] = useState(null);
  const beneficialOwnersDropdown = beneficialOwners.filter(
    (bo) => bo.showInBoDropdown
  );
  const [selectedDropdownBO, setSelectedDropdownBO] = useState(false);
  const [boTabValidationError, setBoTabValidationError] = useState("");
  const { company } = useSelector((state) => state.companySlice);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const params = useParams();
  const companyId = company?._id ?? params?.companyId;

  const allBOs = [...beneficialOwners, ...beneficialOwnersDropdown];

  const removeDropdownShareholder = (dropdownShareholder) => {
    const dropdownBO = _cloneDeep(dropdownShareholder);

    if (!dropdownBO?.beneficialOwnershipForm) return;
    const {
      directPercentShareholding = 0,
      indirectPercentShareholding = 0,
      directPercentVotingRights = 0,
      indirectPercentVotingRights = 0,
    } = dropdownBO.beneficialOwnershipForm;

    const linkedBOs = allBOs.filter(
      (bo) =>
        bo?.linkToCompany === dropdownBO._id ||
        bo?.linkToCompany?._id === dropdownBO._id
    );
    const { allottedIndirectShares, allottedIndirectVotingRights } =
      linkedBOs.reduce(
        (acc, bo) => ({
          allottedIndirectShares:
            acc.allottedIndirectShares +
            Number(bo.beneficialOwnershipForm?.directPercentShareholding ?? 0) +
            Number(
              bo.beneficialOwnershipForm?.indirectPercentShareholding ?? 0
            ),
          allottedIndirectVotingRights:
            acc.allottedIndirectVotingRights +
            Number(bo.beneficialOwnershipForm?.directPercentVotingRights ?? 0) +
            Number(
              bo.beneficialOwnershipForm?.indirectPercentVotingRights ?? 0
            ),
        }),
        { allottedIndirectShares: 0, allottedIndirectVotingRights: 0 }
      );

    if (dropdownBO.idType === "Estate") {
      if (
        allottedIndirectShares ===
          Number(indirectPercentShareholding) +
            Number(directPercentShareholding) &&
        allottedIndirectVotingRights ===
          Number(indirectPercentVotingRights) +
            Number(directPercentVotingRights) &&
        linkedBOs.length > 0
      ) {
        dropdownBO["showInBoTable"] = true;
        const alreadyInBOTable = beneficialOwners.some(
          (bo) => bo._id === dropdownBO._id
        );
        if (!alreadyInBOTable)
          dispatch(addBeneficialOwnerAsync({ companyId, data: dropdownBO }));
        dispatch(
          updateDropdownEntryStatusAsync({
            beneficialOwnerId: dropdownBO._id,
            data: { showInBoDropdown: false },
          })
        );
      }
    }
    if (
      allottedIndirectShares === Number(indirectPercentShareholding) &&
      allottedIndirectVotingRights === Number(indirectPercentVotingRights) &&
      linkedBOs.length > 0
    ) {
      dropdownBO["showInBoTable"] = true;
      const alreadyInBOTable = beneficialOwners.some(
        (bo) => bo._id === dropdownBO._id
      );
      if (!alreadyInBOTable)
        dispatch(addBeneficialOwnerAsync({ companyId, data: dropdownBO }));
      dispatch(
        updateDropdownEntryStatusAsync({
          beneficialOwnerId: dropdownBO._id,
          data: { showInBoDropdown: false },
        })
      );
    }
  };

  useEffect(() => {
    beneficialOwnersDropdown.map(removeDropdownShareholder);

    let tempReportableBO = [];
    let tempNonReportableBO = [];
    if (beneficialOwners.length > 0) {
      beneficialOwners.forEach((bo) => {
        const {
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = bo?.beneficialOwnershipForm ?? {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";
        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";

        // hide eleventh minority entry on BO table
        if (bo.idType === "Minority") {
          bo = {
            ...bo,
            hidden: true,
          };
        }

        if (!bo?.hidden === true) {
          const parentBO = bo?.shareholderWithBO
            ? beneficialOwners.find(
                (official) => official._id === bo.shareholderWithBO
              )
            : {};
          if (
            INDIVIDUALS.includes(bo.idType) &&
            bo.isReportable &&
            (CORPORATES.includes(parentBO?.idType)
              ? isDirectBeneficiary
              : isDirectBeneficiary || isIndirectBeneficiary) &&
            bo.isEstateDistributionDetermined !== "No" &&
            bo.idType !== "Estate"
          ) {
            tempReportableBO.push(bo);
          } else tempNonReportableBO.push(bo);
        }
      });
    }

    const verifyKeys = [
      "identificationDetails.nationalIDNumber",
      "identificationDetails.foreignCertificateID",
      "identificationDetails.passportNumber",
      "identificationDetails.birthCertificateNumber",
    ];

    let filteredTempReportableBOs = [...tempReportableBO];
    let filteredTempNonReportableBOs = [...tempNonReportableBO];
    // if one of the multiple instances is reportable shift non-reportable instances to reportable table
    verifyKeys.map((verifyKey) => {
      tempNonReportableBO.map((bo) => {
        // check if current bo has reportable entry/instance
        const hasReportableInstance = tempReportableBO.find(
          (reportableBoRow) =>
            _get(reportableBoRow, verifyKey) &&
            _get(reportableBoRow, verifyKey) === _get(bo, verifyKey)
        );
        if (hasReportableInstance) {
          filteredTempReportableBOs.push(bo);
          const boIndex = filteredTempNonReportableBOs.findIndex(
            (bo) =>
              _get(bo, verifyKey) === _get(hasReportableInstance, verifyKey)
          );
          filteredTempNonReportableBOs.splice(boIndex, 1);
        }
      });
    });

    filteredTempReportableBOs = groupBeneficialOwners(
      _orderBy(
        filteredTempReportableBOs,
        ["shareholderWithBO", "linkToCompany"],
        ["desc", "desc"]
      )
    );

    const jointShareholdersNonReportableBOs =
      filteredTempNonReportableBOs.filter(
        (official) => official.idType === "Joint Shareholder"
      );

    filteredTempNonReportableBOs = groupBeneficialOwners(
      _orderBy(
        filteredTempNonReportableBOs.filter(
          (official) => official.idType !== "Joint Shareholder"
        ),
        ["shareholderWithBO", "linkToCompany"],
        ["desc", "desc"]
      )
    );

    tempReportableBO = filteredTempReportableBOs;
    tempNonReportableBO = filteredTempNonReportableBOs;

    const reportableBOAfterCumulative = tempNonReportableBO
      .filter((bo) => INDIVIDUALS.includes(bo.idType))
      .filter((bo) => {
        const { cumulativeShareholdings, cumulativeVotings } =
          calculateCumulativeOwnership(bo, beneficialOwners);
        if (cumulativeShareholdings >= 10 || cumulativeVotings >= 10) {
          const indexOfBO = tempNonReportableBO.findIndex(
            (beneficialOwner) => beneficialOwner._id === bo._id
          );
          filteredTempNonReportableBOs.splice(indexOfBO, 1);
          return true;
        }
      });

    setReportableBO([
      ...filteredTempReportableBOs,
      ...reportableBOAfterCumulative,
    ]);
    setNonReportableBO([
      ...filteredTempNonReportableBOs,
      ...jointShareholdersNonReportableBOs,
    ]);
    setShowBeneficialOwnersBtn(true);
  }, [beneficialOwners, setReportableBO, setNonReportableBO]);

  const isLinkedTo = (row) =>
    beneficialOwners.some(
      (bo) =>
        bo.linkToCompany === row._id ||
        (bo.shareholderWithBO === row._id && row.idType !== "Joint Shareholder")
    );

  const handleEdit = async (row) => {
    if (
      isLinkedTo(row) &&
      !row?.linkToCompany &&
      !row?.shareholderWithBO &&
      row.mode === "editing"
    ) {
      alert("Please first remove linked beneficial owners");
      return;
    }
    if (row?.corporateIdType) setSelectedCorporateIDType(row.corporateIdType);
    else setSelectedCorporateIDType(null);
    if (!row.isViewOnly && !row?.isReadOnly) setIsViewOnly(false);
    else setIsViewOnly(true);
    if (row.shareholderWithBO && !row.JSGroupID) {
      const selectedShareholderWithBO = beneficialOwners.find(
        (bo) => bo._id === row.shareholderWithBO
      );
      const corporateShareholders = allBOs.filter(
        (bo) => bo.shareholderWithBO === row.shareholderWithBO
      );
      dispatch(resetCorporateShareholderTable());
      dispatch(
        addMultipleCorporateShareholder(
          _uniqBy(corporateShareholders, (official) => {
            if (official?.JSGroupID) return official.JSGroupID;
            else return official._id;
          })
        )
      );
      setSelectedDropdownBO(selectedShareholderWithBO);
      setShowBeneficialOwnersBtn(false);
      return;
    }

    let linkedJSFromDropdown = {};
    if (row?.shareholderWithBO && row?.JSGroupID && !row.linkToCompany) {
      linkedJSFromDropdown = allBOs.find(
        (bo) =>
          bo.JSGroupID &&
          bo.shareholderWithBO &&
          bo.JSGroupID === row.JSGroupID &&
          bo.idType === "Joint Shareholder"
      );
    }

    if (linkedJSFromDropdown?.shareholderWithBO) {
      if (isLinkedTo(linkedJSFromDropdown)) {
        alert("Please first remove linked beneficial owners");
        return;
      }
      const selectedShareholderWithBO = beneficialOwners.find(
        (bo) => bo._id === linkedJSFromDropdown.shareholderWithBO
      );
      const corporateShareholders = allBOs.filter(
        (bo) => bo.shareholderWithBO === linkedJSFromDropdown.shareholderWithBO
      );
      dispatch(resetCorporateShareholderTable());
      dispatch(
        addMultipleCorporateShareholder(
          _uniqBy(corporateShareholders, (official) => {
            if (official?.JSGroupID) return official.JSGroupID;
            else return official._id;
          })
        )
      );
      setSelectedDropdownBO(selectedShareholderWithBO);
      setShowBeneficialOwnersBtn(false);
      return;
    }

    if (!row.linkToCompany) {
      handleBackBtn();
      await delay(); // wait for the company officials tab to be mounted
      let companyShareholderEditBtn = null;
      let companyShareholderViewBtn = null;
      if (row?.JSGroupID) {
        companyShareholderEditBtn = document.querySelector(
          `[id="${row?.JSGroupID}"]`
        );
        companyShareholderViewBtn = document.querySelector(
          `[id="${row?.JSGroupID}_view"]`
        );
      } else if (row?._id && !row.JSGroupID) {
        companyShareholderEditBtn = document.querySelector(
          `[id="${row?._id}"]`
        );
        companyShareholderViewBtn = document.querySelector(
          `[id="${row?._id}_view"]`
        );
      }
      if (
        (row.isViewOnly && companyShareholderViewBtn) ||
        row.mode !== "editing"
      ) {
        companyShareholderViewBtn.click();
      } else if (companyShareholderEditBtn) companyShareholderEditBtn.click();
      return;
    }
    dispatch(openModal({ modalName: "beneficialOwner_edit_modal" }));
    setMode({ editing: true, id: row._id });
    setCurrentOfficial(row);
  };

  const handleDelete = async (row) => {
    if (isLinkedTo(row)) {
      alert("Please first remove linked beneficial owners");
      return;
    }
    if (row?.corporateIdType) setSelectedCorporateIDType(row.corporateIdType);
    else setSelectedCorporateIDType(null);
    if (row.shareholderWithBO && !row.JSGroupID) {
      const selectedShareholderWithBO = beneficialOwners.find(
        (bo) => bo._id === row.shareholderWithBO
      );
      const corporateShareholders = allBOs.filter(
        (bo) => bo.shareholderWithBO === row.shareholderWithBO
      );
      dispatch(resetCorporateShareholderTable());
      dispatch(
        addMultipleCorporateShareholder(
          _uniqBy(corporateShareholders, (official) => {
            if (official?.JSGroupID) return official.JSGroupID;
            else return official._id;
          })
        )
      );
      setSelectedDropdownBO(selectedShareholderWithBO);
      setShowBeneficialOwnersBtn(false);
      return;
    }

    let linkedJSFromDropdown = {};
    if (row?.shareholderWithBO && row?.JSGroupID && !row.linkToCompany) {
      linkedJSFromDropdown = allBOs.find(
        (bo) =>
          bo.JSGroupID &&
          bo.shareholderWithBO &&
          bo.JSGroupID === row.JSGroupID &&
          bo.idType === "Joint Shareholder"
      );
    }

    if (linkedJSFromDropdown?.shareholderWithBO) {
      if (isLinkedTo(linkedJSFromDropdown)) {
        alert("Please first remove linked beneficial owners");
        return;
      }
      const selectedShareholderWithBO = beneficialOwners.find(
        (bo) => bo._id === linkedJSFromDropdown.shareholderWithBO
      );
      const corporateShareholders = allBOs.filter(
        (bo) => bo.shareholderWithBO === linkedJSFromDropdown.shareholderWithBO
      );
      dispatch(resetCorporateShareholderTable());
      dispatch(
        addMultipleCorporateShareholder(
          _uniqBy(corporateShareholders, (official) => {
            if (official?.JSGroupID) return official.JSGroupID;
            else return official._id;
          })
        )
      );
      setSelectedDropdownBO(selectedShareholderWithBO);
      setShowBeneficialOwnersBtn(false);
      return;
    }

    if (!row.linkToCompany) {
      alert("Please edit inside the Company Officials tab");
      return;
    }

    // const filesToDelete = _values(row?.fileUploads ?? {})
    // if (filesToDelete.length > 0) await deleteFiles(filesToDelete)
    await dispatch(
      deleteBeneficialOwnerAsync({
        beneficialOwnerId: row._id,
        companyId,
      })
    );
    const isInDropdown = beneficialOwnersDropdown.some(
      (bo) => bo._id === row.linkToCompany || bo._id === row?.linkToCompany?._id
    );
    if (!isInDropdown && row.linkToCompany) {
      const linkedCompany = beneficialOwners.find(
        (bo) =>
          bo._id === row?.linkToCompany || bo._id === row?.linkToCompany?._id
      );
      dispatch(
        updateDropdownEntryStatusAsync({
          beneficialOwnerId: linkedCompany._id,
          data: { showInBoDropdown: true },
        })
      );
    }
    setShowBeneficialOwnersBtn(true);
  };

  const selectedTab = tabs[selectedTabIndex];
  const tableData =
    selectedTab.id === "reportableBO" ? reportableBO : nonReportableBO;

  useEffect(() => {
    if (
      !modals.includes("beneficialOwner_edit_modal") &&
      currentOfficial?._id
    ) {
      setMode({ id: "", editing: false });
      setCurrentOfficial({});
      setShowBeneficialOwnersBtn(true);
    }
  }, [modals, currentOfficial]);

  const handleSubmit = () => {
    if (beneficialOwnersDropdown.length === 0) {
      setBoTabValidationError("");
      handleNextBtn();
    } else
      setBoTabValidationError(
        "Allocate indirect beneficial ownership interest."
      );
  };

  return (
    <div className="relative">
      {(deleteBeneficialOwnerStatus.inProgressIds.length > 0 ||
        addBeneficialOwnerStatus.inProgressIds.length > 0 ||
        getAllBeneficialOwnersStatus.status === "loading" ||
        updateDropdownEntryStatusStatus.status === "loading") && <Overlay />}
      {showBeneficialOwnersBtn && (
        <>
          {/* Reportable and Non-Reportable BO tabs */}
          <div className="mb-2 sm:-mt-4">
            <Tabs
              tabs={tabs}
              selectedTabIndex={selectedTabIndex}
              setSelectedTabIndex={setSelectedTabIndex}
            />
          </div>

          {/* Reportable and Non-Reportable BO table */}
          <BeneficialOwnersTable
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            data={tableData}
            showActionColumn
            handleBackBtn={handleBackBtn}
            selectedTableType={selectedTab.id}
          />
        </>
      )}
      {mode.editing && (
        <ModalWrapper
          name="beneficialOwner_edit_modal"
          title={isViewOnly ? "View Beneficial Owner" : "Edit Beneficial Owner"}
          maxWidth="sm:max-w-fit"
        >
          <BeneficialOwnerSelection
            selectedDropdownBO={selectedDropdownBO}
            setSelectedDropdownBO={setSelectedDropdownBO}
            setShowBeneficialOwnersBtn={setShowBeneficialOwnersBtn}
            mode={mode}
            currentOfficial={currentOfficial}
            selectedCorporateIDType={selectedCorporateIDType}
            isViewOnly={isViewOnly}
          />
        </ModalWrapper>
      )}
      {!showBeneficialOwnersBtn && !mode.editing ? (
        <BeneficialOwnerSelection
          selectedDropdownBO={selectedDropdownBO}
          setSelectedDropdownBO={setSelectedDropdownBO}
          setShowBeneficialOwnersBtn={setShowBeneficialOwnersBtn}
          mode={mode}
          currentOfficial={currentOfficial}
          selectedCorporateIDType={selectedCorporateIDType}
        />
      ) : (
        <div className="flex flex-col items-start mt-4">
          <button
            onClick={() => {
              setShowBeneficialOwnersBtn(false);
            }}
            className="inline-flex items-center justify-center space-x-2 text-sm font-medium rounded-md text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed"
            disabled={beneficialOwnersDropdown.length === 0}
          >
            <PlusIcon className="w-6 h-6" aria-hidden="true" />
            <span>Add A Beneficial Owner</span>
          </button>
          {boTabValidationError && beneficialOwnersDropdown.length !== 0 && (
            <p className="text-red-500">{boTabValidationError}</p>
          )}
          {beneficialOwnersDropdown.length === 0 && !selectedDropdownBO && (
            <p className="text-sm">There is no BO to be allocated</p>
          )}
        </div>
      )}
      {showBeneficialOwnersBtn && (
        <div className="flex justify-between py-3">
          <button
            type="button"
            onClick={handleBackBtn}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Go back
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
          >
            {isSubmitting ? (
              <>
                <SpinnerIcon />
                Submitting...
              </>
            ) : (
              submitBtnLabel
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default BeneficialOwnershipTab;
