import { parameterizeArray } from "utils";
import axios from "../axios";

const getAll = (
  companyId,
  organizationId,
  createdBy,
  officialIds = [],
  corporate
) => {
  // TODO: make this query param string formation dynamic
  let queryParams = "";

  if (companyId) {
    queryParams = `?company=${encodeURIComponent(companyId)}`;
  }

  if (organizationId) {
    queryParams = `?organization=${encodeURIComponent(organizationId)}`;
  }

  if (corporate) {
    queryParams = `?corporate=${encodeURIComponent(corporate)}`;
  }

  if (officialIds.length > 0) {
    queryParams += `&${parameterizeArray("officialId", officialIds)}`;
  }

  if (createdBy) {
    queryParams += `&createdBy=${createdBy}`;
  }

  return axios.get(`/comments${queryParams}`);
};

const create = (data) => axios.post("/comments", data);

const update = (commentId, data) => axios.put(`/comments/${commentId}`, data);

const remove = (commentId) => axios.delete(`/comments/${commentId}`);

const getProfileComments = (userId) =>
  axios.get(`/comments/profile-comments?user=${userId}`);

const commentDataService = {
  getAll,
  create,
  update,
  remove,
  getProfileComments,
};

export default commentDataService;
