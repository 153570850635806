import _forOwn from "lodash/forOwn";

import { Constants } from "config/constants";

const checkLLPOrganizationRestrictions = (data) => {
  const errors = [];
  let restrictions = {
    partners: {
      count: 0,
      min: {
        value: Constants.MIN_LLP_ORGANIZATION_PARTNERS_LIMIT,
        error: `Minimum of ${Constants.MIN_LLP_ORGANIZATION_PARTNERS_LIMIT} ${
          Constants.MIN_LLP_ORGANIZATION_PARTNERS_LIMIT > 1
            ? "partners are"
            : "partner is"
        } required`,
      },
    },
    managers: {
      count: 0,
      min: {
        value: Constants.MIN_LLP_ORGANIZATION_MANAGERS_LIMIT,
        error: `Minimum of ${Constants.MIN_LLP_ORGANIZATION_MANAGERS_LIMIT} ${
          Constants.MIN_LLP_ORGANIZATION_MANAGERS_LIMIT > 1
            ? "managers are"
            : "manager is"
        } required`,
      },
    },
    managersIdTypes: {
      status: false,
      error:
        "At least one of the manager must be Kenyan Citizen or Foreign Resident",
    },
  };
  data.forEach(({ role = [], idType }) => {
    if (["Partner", "Partner Manager"].includes(role[0])) {
      restrictions.partners.count += 1;
    }

    if (["Manager", "Partner Manager"].includes(role[0])) {
      restrictions.managers.count += 1;
      if (["Kenyan Citizen", "Foreign Resident"].includes(idType)) {
        restrictions.managersIdTypes.status = true;
      }
    }
  });

  _forOwn(restrictions, function (value) {
    const { count, min, max } = value;
    if (min && count < min.value) errors.push(min.error);
    if (max && count > max.value) errors.push(max.error);
  });

  if (restrictions.managersIdTypes.status === false)
    errors.push(restrictions.managersIdTypes.error);
  return { errors };
};

const checkLPOrganizationRestrictions = (data) => {
  const errors = [];
  let restrictions = {
    generalPartners: {
      count: 0,
      min: {
        value: Constants.MIN_LP_ORGANIZATION_GENERAL_PARTNER_LIMIT,
        error: `Minimum of ${
          Constants.MIN_LP_ORGANIZATION_GENERAL_PARTNER_LIMIT
        } general ${
          Constants.MIN_LP_ORGANIZATION_GENERAL_PARTNER_LIMIT > 1
            ? "partners are"
            : "partner is"
        } required`,
      },
    },
    limitedPartners: {
      count: 0,
      min: {
        value: Constants.MIN_LP_ORGANIZATION_LIMITED_PARTNER_LIMIT,
        error: `Minimum of ${
          Constants.MIN_LP_ORGANIZATION_LIMITED_PARTNER_LIMIT
        } limited ${
          Constants.MIN_LP_ORGANIZATION_LIMITED_PARTNER_LIMIT > 1
            ? "partners are"
            : "partner is"
        } required`,
      },
    },
    partners: {
      count: 0,
      max: {
        value: Constants.MAX_LP_ORGANIZATION_PARTNERS_LIMIT,
        error: `Maximum of ${Constants.MAX_LP_ORGANIZATION_PARTNERS_LIMIT} ${
          Constants.MAX_LP_ORGANIZATION_PARTNERS_LIMIT > 1
            ? "partners are"
            : "partner is"
        } allowed`,
      },
    },
  };

  data.forEach(({ role = [] }) => {
    restrictions.partners.count += 1;
    if (["General Partner"].includes(role[0]))
      restrictions.generalPartners.count += 1;
    if (["Limited Partner"].includes(role[0]))
      restrictions.limitedPartners.count += 1;
  });

  _forOwn(restrictions, function (value) {
    const { count, min, max } = value;
    if (min && count < min.value) errors.push(min.error);
    if (max && count > max.value) errors.push(max.error);
  });

  return { errors };
};

const checkGPOrganizationRestrictions = (data) => {
  const errors = [];
  let restrictions = {
    partners: {
      count: 0,
      min: {
        value: Constants.MIN_GP_ORGANIZATION_PARTNER_LIMIT,
        error: `Minimum of ${Constants.MIN_GP_ORGANIZATION_PARTNER_LIMIT} ${
          Constants.MIN_GP_ORGANIZATION_PARTNER_LIMIT > 1
            ? "partners are"
            : "partner is"
        } required`,
      },
      max: {
        value: Constants.MAX_GP_ORGANIZATION_PARTNERS_LIMIT,
        error: `Maximum of ${Constants.MAX_GP_ORGANIZATION_PARTNERS_LIMIT} ${
          Constants.MAX_GP_ORGANIZATION_PARTNERS_LIMIT > 1
            ? "partners are"
            : "partner is"
        } allowed`,
      },
    },
  };

  data.forEach(() => {
    restrictions.partners.count += 1;
  });

  _forOwn(restrictions, function (value) {
    const { count, min, max } = value;
    if (min && count < min.value) errors.push(min.error);
    if (max && count > max.value) errors.push(max.error);
  });

  return { errors };
};

const checkSPOrganizationRestrictions = (data) => {
  const errors = [];
  let restrictions = {
    partners: {
      count: 0,
      min: {
        value: Constants.MIN_SP_ORGANIZATION_PARTNER_LIMIT,
        error: `Minimum of ${Constants.MIN_SP_ORGANIZATION_PARTNER_LIMIT} Sole Proprietorship is required`,
      },
      max: {
        value: Constants.MAX_SP_ORGANIZATION_PARTNER_LIMIT,
        error: `Maximum of ${Constants.MAX_SP_ORGANIZATION_PARTNER_LIMIT} Sole Proprietorship is allowed`,
      },
    },
  };

  data.forEach(({ role = [] }) => {
    if (["Sole Proprietorship"].includes(role[0])) {
      restrictions.partners.count += 1;
    }
  });

  _forOwn(restrictions, function (value) {
    const { count, min, max } = value;
    if (min && count < min.value) errors.push(min.error);
    if (max && count > max.value) errors.push(max.error);
  });

  return { errors };
};

export const validateOrganizationRestrictions = ({
  partnershipType = "Limited Liability Partnership",
  partners = [],
}) => {
  switch (partnershipType) {
    case "Limited Liability Partnership":
      return checkLLPOrganizationRestrictions(partners);
    case "Limited Partnership":
      return checkLPOrganizationRestrictions(partners);
    case "General Partnership":
      return checkGPOrganizationRestrictions(partners);
    case "Sole Proprietorship":
      return checkSPOrganizationRestrictions(partners);
    default:
      return { errors: [] };
  }
};

const checkLLPRegistrar = (partners) => {
  const registrarCandidates = partners.filter((partner) =>
    ["Manager", "Partner Manager"].includes(partner.role[0])
  );
  const existingRegistrar = registrarCandidates.find(
    (partner) => partner.isRegistrar
  );
  return {
    showSelectRegistrarBtn:
      registrarCandidates.length > 1 && !existingRegistrar,
    registrarCandidates,
  };
};

const checkLPRegistrar = (partners) => {
  const registrarCandidates = partners.filter((partner) =>
    ["General Partner"].includes(partner.role[0])
  );

  return {
    showSelectRegistrarBtn: false,
    registrarCandidates,
  };
};

const checkGPRegistrar = (partners) => {
  const registrarCandidates = partners.filter((partner) =>
    ["General Partner"].includes(partner.role[0])
  );
  const existingRegistrar = registrarCandidates.find(
    (partner) => partner.isRegistrar
  );
  return {
    showSelectRegistrarBtn:
      registrarCandidates.length > 1 && !existingRegistrar,
    registrarCandidates,
  };
};

export const checkForRegistrar = ({
  partnershipType = "Limited Liability Partnership",
  partners = [],
}) => {
  switch (partnershipType) {
    case "Limited Liability Partnership":
      return checkLLPRegistrar(partners);
    case "Limited Partnership":
      return checkLPRegistrar(partners);
    case "General Partnership":
      return checkGPRegistrar(partners);
    default:
      return { showSelectRegistrarBtn: false, registrarCandidates: [] };
  }
};
