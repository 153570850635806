import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="px-4 py-6 overflow-hidden sm:mx-auto sm:max-w-7xl sm:px-6 lg:px-8">
      <nav
        className="flex flex-wrap justify-center -mx-5 -my-2"
        aria-label="Footer"
      >
        <div className="space-x-6">
          <Link to="/about">
            <span className="text-base text-gray-500 hover:text-gray-900">
              About Us
            </span>
          </Link>

          <Link to="/legal/1">
            <span className="text-base text-gray-500 hover:text-gray-900">
              Legal Documents
            </span>
          </Link>

          <Link to="/contacts">
            <span className="text-base text-gray-500 hover:text-gray-900">
              Contact Us
            </span>
          </Link>
        </div>
      </nav>
    </div>
  );
}
