import React from "react";

const MAX_NUMBER_ROWS = 4;

function DistinctiveNumbers({
  currentAllottedClass,
  unpaidClassesOfShares = [],
}) {
  const emptyRowsCount = MAX_NUMBER_ROWS - 1;
  const isUnPaid = unpaidClassesOfShares.includes(
    currentAllottedClass.classOfShares
  );
  return (
    <div className="relative">
      <table className="text-center bg-white">
        <thead>
          <tr>
            <th colSpan="2" className="border-2 border-gray-300 px-2 py-1">
              DISTINCTIVE NOS
            </th>
            <th rowSpan="3" className="border-2 border-gray-300 px-2 py-1">
              NUMBER OF SHARES
            </th>
          </tr>
          <tr>
            <th className="border-l-2 border-gray-300 px-2 py-1">From</th>
            <th className="px-2 py-1">To</th>
          </tr>
          <tr>
            <th colSpan="2" className="border-l-2 border-gray-300">
              Inclusive
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-2 border-gray-300 h-12 w-24">
              {isUnPaid ? currentAllottedClass.shareStart : "-"}
            </td>
            <td className="border-2 border-gray-300 h-12 w-24">
              {isUnPaid ? currentAllottedClass.shareEnd : "-"}
            </td>
            <td className="border-2 border-gray-300 h-12">
              {currentAllottedClass.allottedShares}
            </td>
          </tr>
          {Array.from({ length: emptyRowsCount }, (_, i) => i).map((row) => (
            <tr key={row}>
              <td className="border-2 border-gray-300 h-12"></td>
              <td className="border-2 border-gray-300 h-12"></td>
              <td className="border-2 border-gray-300 h-12"></td>
            </tr>
          ))}
        </tbody>
      </table>
      {!isUnPaid && (
        <div class="absolute w-max h-max uppercase inset-0 z-50 top-[60%] left-2 rotate-[345deg] text-xxs font-extrabold text-[#0085FF]">
          <p>DISTINCTIVE NUMBERS OMITTED</p>
          <p>UNDER THE PROVISO TO SECTION</p>
          <p>325 OF THE COMPANIES ACT 2015</p>
        </div>
      )}
    </div>
  );
}

export default DistinctiveNumbers;
