import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import templateCategoryService from "services/templateCategory";

export const getTemplateCategoriesAsync = createAsyncThunk(
  "templateCategories/getTemplateCategoriesAsync",
  async (queryParams) => {
    const res = await templateCategoryService.getAll(queryParams);
    return res.data;
  }
);

export const addTemplateCategoryAsync = createAsyncThunk(
  "templateCategories/addTemplateCategoryAsync",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await templateCategoryService.create(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editTemplateCategoryAsync = createAsyncThunk(
  "templateCategories/editTemplateCategoryAsync",
  async ({ templateCategoryId, data }) => {
    const res = await templateCategoryService.update(templateCategoryId, data);
    return res.data;
  }
);

export const deleteTemplateCategoryAsync = createAsyncThunk(
  "templateCategories/deleteTemplateCategoryAsync",
  async ({ templateCategoryId }, { rejectWithValue }) => {
    try {
      await templateCategoryService.remove(templateCategoryId);
      return { templateCategoryId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  templateCategories: {
    items: [],
    totalCount: 0,
  },
  selectedTemplateCategory: {},
  mode: "",
  addTemplateCategory: {
    status: "idle",
    error: false,
  },
  getTemplateCategories: {
    status: "idle",
    error: false,
  },
  updatingTemplateCategory: {
    status: "idle",
    error: false,
  },
};

const templatesSlice = createSlice({
  name: "templateCategories",
  initialState,
  reducers: {
    updateTemplateCategoryState: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetTemplateCategoryCategoriesSlice: () => initialState,
  },
  extraReducers: {
    [editTemplateCategoryAsync.pending]: (state) => {
      state.updatingTemplateCategory.status = "loading";
      state.updatingTemplateCategory.error = false;
    },
    [editTemplateCategoryAsync.fulfilled]: (state, action) => {
      state.updatingTemplateCategory.status = "loading";
      state.updatingTemplateCategory.error = false;
    },
    [editTemplateCategoryAsync.rejected]: (state, action) => {
      state.updatingTemplateCategory.status = "failed";
      state.updatingTemplateCategory.error = action?.payload?.messageText;
    },

    [getTemplateCategoriesAsync.pending]: (state) => {
      state.getTemplateCategories.status = "loading";
      state.getTemplateCategories.error = false;
    },
    [getTemplateCategoriesAsync.fulfilled]: (state, action) => {
      state.getTemplateCategories.status = "idle";
      state.templateCategories = action.payload;
    },
    [getTemplateCategoriesAsync.rejected]: (state, action) => {
      state.getTemplateCategories.status = "failed";
      state.getTemplateCategories.error = action?.payload?.messageText;
    },

    [addTemplateCategoryAsync.pending]: (state) => {
      state.addTemplateCategory.status = "loading";
      state.addTemplateCategory.error = false;
    },
    [addTemplateCategoryAsync.fulfilled]: (state, action) => {
      state.addTemplateCategory.status = "idle";
      state.templateCategories.items.push(action.payload.templateCategory);
    },
    [addTemplateCategoryAsync.rejected]: (state, action) => {
      state.addTemplateCategory.status = "failed";
      state.addTemplateCategory.error = action?.payload?.messageText;
    },

    [deleteTemplateCategoryAsync.fulfilled]: (state, { payload }) => {
      const templateCategoryIndex = state.templateCategories.items.findIndex(
        (templateCategory) =>
          payload.templateCategoryId === templateCategory._id
      );
      if (templateCategoryIndex > -1)
        state.templateCategories.items.splice(templateCategoryIndex, 1);
    },
  },
});

export const {
  updateTemplateCategoryState,
  resetTemplateCategoryCategoriesSlice,
} = templatesSlice.actions;

export default templatesSlice.reducer;
