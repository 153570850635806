/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _set from "lodash/set";

import professionalDetailsDataService from "services/professionalDetail";

export const getProfessionalDetailsAsync = createAsyncThunk(
  "professionalDetails/getProfessionalDetailsAsync",
  async ({ user }) => {
    const res = await professionalDetailsDataService.getAll(user);
    return res.data;
  }
);

export const addProfessionalDetail = createAsyncThunk(
  "professionalDetails/add-professional-detail",
  async ({ data }) => {
    const res = await professionalDetailsDataService.create(data);
    return res.data;
  }
);

export const editProfessionalDetail = createAsyncThunk(
  "professionalDetails/update",
  async ({ professionalDetailId, data }) => {
    const res = await professionalDetailsDataService.update(
      professionalDetailId,
      data
    );
    return res.data;
  }
);

export const deleteProfessionalDetail = createAsyncThunk(
  "professionalDetails/delete",
  async ({ professionalDetailId }, thunkAPI) => {
    try {
      await professionalDetailsDataService.remove(professionalDetailId);
      return { professionalDetailId };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  items: [],
  totalCount: 0,
  getProfessionalDetails: {
    status: "idle",
    error: false,
  },
};

const professionalDetailsSlice = createSlice({
  name: "professionalDetails",
  initialState,
  reducers: {
    resetProfessionalDetailsSlice: (state) => initialState,
    updateProfessionalDetailsNestedField: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
  },
  extraReducers: {
    [getProfessionalDetailsAsync.pending]: (state, action) => {
      state.getProfessionalDetails.status = "loading";
      state.getProfessionalDetails.error = false;
    },
    [getProfessionalDetailsAsync.fulfilled]: (state, action) => {
      state.getProfessionalDetails.status = "idle";
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
    },
    [getProfessionalDetailsAsync.rejected]: (state, action) => {
      state.getProfessionalDetails.status = "failed";
      state.getProfessionalDetails.error = action?.payload?.messageText;
    },

    [addProfessionalDetail.fulfilled]: (state, action) => {
      state.items.push(action.payload.professionalDetail);
    },
    [editProfessionalDetail.fulfilled]: (state, action) => {
      const { payload } = action;
      const { items } = state;
      const index = items.findIndex(
        (professionalDetail) => payload._id === professionalDetail._id
      );
      if (index > -1) {
        items.splice(index, 1, payload);
      }
    },
    [deleteProfessionalDetail.fulfilled]: (state, action) => {
      const { payload } = action;
      const { items } = state;
      const index = items.findIndex(
        (professionalDetail) =>
          payload.professionalDetailId === professionalDetail._id
      );
      if (index > -1) items.splice(index, 1);
    },
  },
});

export const {
  resetProfessionalDetailsSlice,
  updateProfessionalDetailsNestedField,
} = professionalDetailsSlice.actions;

export default professionalDetailsSlice.reducer;
