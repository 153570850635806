import React, { useEffect, useState } from "react";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";

import { getFullName } from "utils";
import { RESIGNATION_OFFICIAL } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { getCompanyOfficialsAsync } from "state/slices/tables/companyOfficialsSlice";
import ManageCessationOfficialsTable from "./ManageCessationTable";

const documentData = RESIGNATION_OFFICIAL;

const initialTabs = [
  {
    id: "generalInformation",
    label: "General information",
  },
  {
    id: "uploadDocuments",
    label: "Upload Documents",
  },
];

const CessationComponent = ({ companyId }) => {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState(initialTabs[0]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showResignationForm, setShowResignationForm] = useState(false);
  const [officialsList, setOfficialsList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedValueForCessation, setSelectedValueForSession] =
    useState(null);

  const companyDashboardSlice = useSelector(
    (state) => state.companyDashboardSlice
  );

  const { getCompanyOfficialsStatus } = companyDashboardSlice;

  const companyOfficials = companyDashboardSlice.companyOfficials.items;
  const { totalCount } = companyDashboardSlice.companyOfficials;

  console.log({ companyOfficials });

  const JSGroups = new Set();
  companyOfficials.forEach((item) => {
    if (item.JSGroupID) JSGroups.add(item.JSGroupID);
  });

  const allJS = [...JSGroups].map((id) => {
    const names = [];
    let combinedJS = {};
    companyOfficials.forEach((official) => {
      if (official.JSGroupID === id) {
        names.push(getFullName(official));
        if (official.sharesAllotted && official.sharesAllotted.length > 0)
          combinedJS.sharesAllotted = official.sharesAllotted;
        combinedJS = {
          ...combinedJS,
          ..._omit(_cloneDeep(official), ["sharesAllotted"]),
          designation: "Joint Shareholder",
          idType: "Joint Shareholder",
        };
        combinedJS.fullName = names.map((name, index) => {
          if (names.length === 2 && index === 0) {
            return `${name} & `;
          }
          if (names.length > 2 && index === names.length - 2) {
            return `${name} & `;
          }

          if (
            names.length > 2 &&
            index !== names.length - 2 &&
            index !== names.length - 1
          ) {
            return `${name}, `;
          }
          return name;
        });
      }
    });
    return combinedJS;
  });

  const data = [
    ...companyOfficials.filter(
      (item) =>
        (item.designation === "Joint Shareholder" &&
          item.isDirector === "Yes") ||
        item.designation !== "Joint Shareholder"
    ),
    ...allJS.filter((item) => !_isEmpty(item)),
  ];

  useEffect(() => {
    const tempSelectedTab = initialTabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [selectedTabIndex]);

  const closeCessationForm = () => {
    setShowResignationForm(false);
  };

  const handleChangeOfCessionValue = (e) => {
    setSelectedValueForSession(e.target.value);
    setShowResignationForm(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getCompanyOfficialsAsync({
            companyId,
            queryParams: { designation: "Authorized Person" },
          })
        );
        setOfficialsList(response.payload.items);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [companyId, dispatch]);

  return (
    <>
      {isLoading && <>loading...</>}
      {data?.length < 1 &&
        !isLoading &&
        (officialsList === null || officialsList?.length < 1) && (
          <div className="notification-container flex justify-center px-4 py-10 mt-2 mb-2 bg-gray-200 rounded-sm shadow-sm sm:rounded md:rounded-md relative">
            <h2 className="text-gray-900 font-normal">
              You have no assigned Authorized Persons to proceed with the
              workflow
            </h2>
            <span
              className="close-icon absolute top-2 right-2 cursor-pointer text-lg hover:bg-gray-300"
              onClick={() => setShowChangeOfParticularsModal(false)}
            >
              <XIcon className="h-6 w-6" />
            </span>
          </div>
        )}
      {data.length > 0 && !isLoading && (
        <>
          <h2 className="font-semibold text-grey-700"> Select Type: </h2>
          <div className="flex justify-evenly px-4 py-6 mt-2 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
            <div className="flex items-center space-x-2">
              <input
                id="resignation"
                type="radio"
                value="resignation"
                checked={selectedValueForCessation === "resignation"}
                onChange={handleChangeOfCessionValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="resignation" className="radio-label flex">
                Resignation
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                id="removal"
                type="radio"
                value="removal"
                checked={selectedValueForCessation === "removal"}
                onChange={handleChangeOfCessionValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label htmlFor="removal" className="radio-label flex">
                Removal
              </label>
            </div>
          </div>
        </>
      )}
      {selectedValueForCessation && data?.length > 0 && (
        <>
          <div className="mt-4">
            <h4 className="text-gray-800 font-semibold mt-2 mb-2">
              Officials List
            </h4>
            <div>
              <ManageCessationOfficialsTable
                companyId={companyId}
                data={data}
                totalCount={totalCount}
                currentTab="stakeholderTab"
                isLoading={getCompanyOfficialsStatus.status === "loading"}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && officialsList?.length > 0 && (
        <div className="mt-4">
          <h4 className="text-gray-800 font-semibold mt-2 mb-2">
            Recent Updates
          </h4>
          <div>
            <ManageCessationOfficialsTable
              data={officialsList}
              totalCount={0}
              companyId={companyId}
              isLoading={isLoading}
              typeOfTable={"recent-updates"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CessationComponent;
