/* eslint-disable max-len */
import { createSlice } from "@reduxjs/toolkit";

const modalsSlice = createSlice({
  name: "Modals",
  initialState: {
    modals: [],
    modalRow: null,
  },
  reducers: {
    openModal: (state, action) => {
      state.modals.push(action.payload.modalName);
      state.modalRow = action.payload.modalRow;
    },
    closeModal: (state, action) => {
      const index = state.modals.findIndex((modal) => modal === action.payload);
      state.modals.splice(index, 1);
      state.modalRow = null;
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
