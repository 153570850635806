/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from "react";
import { useTable } from "react-table";

import Pagination from "../Pagination";
import SpinnerIcon from "../Icons/SpinnerIcon";
import { Constants } from "config/constants";

const ReactTable = (props) => {
  const {
    columns,
    data = [],
    headingRowStyle,
    headingStyle,
    dataRowStyle = "",
    dataRowCellStyle = "pl-4",
    showSnColumn = true,
    currentPage,
    rowsPerPage = 10,
    paginate,
    totalRows,
    setCurrentPage,
    isLoading,
  } = props;

  // Use the state and functions returned from useTable to build your UI

  const tableColumns = useMemo(() => {
    if (!showSnColumn) {
      return columns;
    }

    return [
      {
        Header: "Sn",
        accessor: "sn",
      },
      ...columns,
    ];
  }, [showSnColumn, columns]);

  const tableData = useMemo(() => {
    return showSnColumn
      ? data.map((item, index) => {
          return {
            ...item,
            sn:
              item.id === "total"
                ? ""
                : currentPage === undefined
                ? index + 1
                : index + 1 + (currentPage - 1) * rowsPerPage,
          };
        })
      : data;
  }, [showSnColumn, data, currentPage, rowsPerPage]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: tableColumns,
      data: tableData,
    });

  // Table UI for headless react-table
  return (
    <>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className={headingRowStyle}
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className={headingStyle}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="w-full relative">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`${dataRowStyle} ${
                  data.length === row.index + 1 ? "border-b-0" : ""
                }`}
              >
                {row.cells.map((cell) => (
                  <td
                    className={`${dataRowCellStyle} ${
                      cell.column?.className ? cell.column?.className : ""
                    }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
          {isLoading && (
            <div
              className="absolute inset-0 flex items-center justify-center bg-gray-200/50 z-10"
              id="loading-overlay"
            >
              <SpinnerIcon className="text-gray-400" />
            </div>
          )}
        </tbody>
      </table>
      {paginate && totalRows > Constants.PAGINATION_LIMIT && (
        <Pagination
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          setCurrentPage={setCurrentPage}
          className="mt-0"
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default ReactTable;
