import React from "react";
import { Menu } from "@headlessui/react";

import { delay } from "utils";
import TableActionMenuWrapper from "./TableActionMenuWrapper";

const OrganizationsTableActionMenu = ({
  row,
  disableResendInviteBtn,
  disableReviewBtn,
  disableResetAccessBtn,
  disableRemoveBtn,
  hideResendInviteBtn,
  hideReviewBtn,
  showViewBtn,
  handleResendInvite,
}) => {
  const handleViewRow = async (row) => {
    if (!row.isExpanded) {
      row.toggleAllRowsExpanded(false); // Close all rows
      await delay(10);
      row.toggleRowExpanded(); // Open the clicked row
    } else {
      row.toggleRowExpanded();
    }
  };

  return (
    <div>
      <TableActionMenuWrapper>
        {!hideResendInviteBtn && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                disabled={disableResendInviteBtn}
                onClick={() => handleResendInvite(row.original)}
                className={`${
                  active && !disableResendInviteBtn
                    ? "bg-violet-500 text-white"
                    : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
              >
                Resend invite
              </button>
            )}
          </Menu.Item>
        )}
        {!hideReviewBtn && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                disabled={disableReviewBtn}
                {...(!disableReviewBtn ? row?.getToggleRowExpandedProps() : {})}
                className={`${
                  active && !disableReviewBtn
                    ? "bg-violet-500 text-white"
                    : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
              >
                {row.isExpanded ? "Close" : "Approve"}
              </button>
            )}
          </Menu.Item>
        )}
        {showViewBtn && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleViewRow(row)}
                className={`${
                  active ? "bg-violet-500 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
              >
                {row.isExpanded ? "Close" : "View"}
              </button>
            )}
          </Menu.Item>
        )}
        {
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                disabled={disableResetAccessBtn}
                className={`${
                  active && !disableResetAccessBtn
                    ? "bg-violet-500 text-white"
                    : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
              >
                Reset Access
              </button>
            )}
          </Menu.Item>
        }
        {
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                disabled={disableRemoveBtn}
                className={`${
                  active && !disableRemoveBtn
                    ? "bg-red-600 text-white"
                    : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
              >
                Remove
              </button>
            )}
          </Menu.Item>
        }
      </TableActionMenuWrapper>
    </div>
  );
};

export default OrganizationsTableActionMenu;
