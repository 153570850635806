import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Input from "components/lib/Shared/Input";
import Button from "components/lib/Shared/Button";
import {
  addPackageAsync,
  editPackageAsync,
  getPackagesAsync,
} from "state/slices/package";
import { Constants } from "config/constants";
import { useToast } from "hooks/useToast";

const validationSchema = object().shape({
  service: string().required("Service is required"),
  product: string().required("Product is required"),
  name: string().required("Name is required"),
  description: string()
    .trim("Description cannot include leading and trailing spaces")
    .strict(true)
    .required("Description is required"),
});

const PackageOverview = () => {
  const cookieRole = Cookies.get("role");
  const { toast } = useToast();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.productSlice);
  const { selectedPackage } = useSelector((state) => state.packageSlice);
  const { packages } = useSelector((state) => state.packageSlice);

  const handleSubmit = async (data) => {
    let response = {};
    if (selectedPackage?._id) {
      response = await dispatch(
        editPackageAsync({ packageId: selectedPackage?._id, data })
      );
    } else {
      response = await dispatch(addPackageAsync({ data }));
    }
    if (response.error) {
      toast(
        "error",
        response.payload?.messageText ?? "Something went wrong please try again"
      );
    } else toast("success", "Successfully saved the details");
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        service: "",
        product: "",
        name: "",
        description: "",
        ...selectedPackage,
        product: selectedPackage?.product?._id ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting }) => {
        const [packageNameOptions, setPackageNameOptions] = useState([]);

        useEffect(() => {
          const { service } = values;
          const packagesNameAlreadyTaken = new Set(
            packages.items
              .filter((pricingPackage) => pricingPackage.service === service)
              .map((pricingPackage) => pricingPackage.name)
          );

          packagesNameAlreadyTaken.delete(selectedPackage.name);

          const packageNameOptions =
            service === "Private Limited Company"
              ? Constants.PVT_PACKAGE_NAMES.filter(
                  (packageName) =>
                    !packagesNameAlreadyTaken.has(packageName.value)
                )
              : Constants.PLC_PACKAGE_NAMES.filter(
                  (packageName) =>
                    !packagesNameAlreadyTaken.has(packageName.value)
                );

          setPackageNameOptions(packageNameOptions);
        }, [values.service, selectedPackage?.service]);

        return (
          <Form className="text-sm w-full">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      name="service"
                      label="SFL Service"
                      options={Constants.SFL_SERVICES}
                      component={ReactSelectWithFormik}
                      isDisabled={
                        cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                      }
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="service"
                      placeholder="Select Service"
                      value={values?.service ?? ""}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      name="product"
                      label="SFL Product"
                      options={products.items.map((product) => ({
                        label: product.name,
                        value: product._id,
                      }))}
                      component={ReactSelectWithFormik}
                      isDisabled={
                        cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                      }
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="product"
                      placeholder="Select Product"
                      value={values?.product ?? ""}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      name="name"
                      label="SFL Package Name"
                      options={packageNameOptions}
                      component={ReactSelectWithFormik}
                      isDisabled={
                        !values?.service ||
                        !values?.product ||
                        cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                      }
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="name"
                      placeholder="Select Package Name"
                      value={values?.name ?? ""}
                    />
                  </div>
                </div>
                <div>
                  <div className="mt-2">
                    <Field
                      component={Input}
                      disabled={
                        cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                      }
                      label="Product Summary"
                      id="description"
                      name="description"
                      rows="5"
                      renderTextarea
                      placeholder="Write a brief description of the product"
                      className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
              <div className="mt-6 flex items-center justify-end gap-x-4">
                <Button
                  type="submit"
                  loadingText="Saving..."
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default PackageOverview;
