import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { verifyPaymentAsync } from "state/slices/payment";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

function PaymentSuccessPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleVerifyPayment = async () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const allParams = {};

      for (const [key, value] of params) {
        allParams[key] = value;
      }

      const response = await dispatch(
        verifyPaymentAsync({
          data: {
            ...allParams,
            payload: window.localStorage.getItem("payload"),
          },
        })
      );

      const { redirectUrl, message } = response.payload;
      if (message) {
        setError(message);
      } else if (redirectUrl) {
        window.localStorage.removeItem("payload");
        history.push(redirectUrl);
      }
    };
    handleVerifyPayment();
  }, [window.location.href]);

  const handleGoBack = () => {
    history.goBack();
  };

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="max-w-md p-6 bg-white rounded shadow-md">
          <h1 className="text-3xl font-bold text-red-500 mb-4">
            Payment Failed!
          </h1>
          <p className="text-gray-700 mb-6">
            Transaction id is not valid, please try again.
          </p>
          <button
            onClick={handleGoBack}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-400"
          >
            Retry Payment
          </button>
        </div>
      </div>
    );
  }

  return (
    <p className="flex justify-center py-4">
      <SpinnerIcon className="text-gray-500" />
    </p>
  );
}

export default PaymentSuccessPage;
