import { useSelector } from "react-redux";
import _get from "lodash/get";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import CommentsList from "components/lib/Global/CommentsList";

function ResidentialDetails({
  handleSaveComment,
  residentialDetailsComments,
  userPathPrefix = "orgAdminSlice.user",
  updateEffectKey = "updateUserStateForOrgAdmin",
  addReviewComment,
  toggleCommentStatus,
  isProfileReview,
}) {
  const { user } = useSelector((state) => state.orgAdminSlice);

  const emailAddressFormFields = [
    {
      key: "Email address",
      name: "emailAddress",
      value: _get(user.electronicAddress, "emailAddress", "N/A"),
    },
    {
      key: "Mobile number",
      name: "mobileNumber",
      value: _get(user.electronicAddress, "mobileNumber", "N/A"),
    },
  ];

  const postalAddressFormFields = [
    {
      key: "Postal address",
      name: "postalAddress",
      value: _get(user.postalAddress, "postalAddress", "N/A"),
    },
    {
      key: "Postal code",
      name: "postalCode",
      value: _get(user.postalAddress, "postalCode", "N/A"),
    },
    {
      key: "Service address",
      name: "serviceAddress",
      value: _get(user.postalAddress, "serviceAddress", "N/A"),
    },
  ];

  const residentialDetailsFormFields = [
    {
      key: "Building name",
      name: "buildingName",
      value: _get(user.residentialAddressDetails, "buildingName", "N/A"),
    },
    {
      key: "House number",
      name: "houseNumber",
      value: _get(user.residentialAddressDetails, "houseNumber", "N/A"),
    },
    {
      key: "Estate",
      name: "estate",
      value: _get(user.residentialAddressDetails, "estate", "N/A"),
    },
    {
      key: "Floor number",
      name: "floorNumber",
      value: _get(user.residentialAddressDetails, "floorNumber", "N/A"),
    },
    {
      key: "Street name",
      name: "streetName",
      value: _get(user.residentialAddressDetails, "streetName", "N/A"),
    },
    {
      key: "Street number",
      name: "streetNumber",
      value: _get(user.residentialAddressDetails, "streetNumber", "N/A"),
    },
  ];

  const city = _get(user.residentialAddressDetails, "city");
  if (city) {
    residentialDetailsFormFields.push({
      key: "City",
      name: "city",
      value: city,
    });
  }

  const country = _get(user.residentialAddressDetails, "country");
  if (country) {
    residentialDetailsFormFields.push({
      key: "Country",
      name: "country",
      value: country,
    });
  }

  const county = _get(user.residentialAddressDetails, "county");
  if (county) {
    residentialDetailsFormFields.push({
      key: "County",
      name: "county",
      value: county,
    });
  }

  const district = _get(user.residentialAddressDetails, "district");
  if (district) {
    residentialDetailsFormFields.push({
      key: "District",
      name: "district",
      value: district,
    });
  }

  const locality = _get(user.residentialAddressDetails, "locality");
  if (locality) {
    residentialDetailsFormFields.push({
      key: "Locality",
      name: "locality",
      value: locality,
    });
  }
  return (
    <>
      {residentialDetailsComments && residentialDetailsComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 mt-6 bg-white">
          <CommentsList
            comments={residentialDetailsComments}
            toggleCommentStatus={toggleCommentStatus}
            isProfileReview
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {residentialDetailsComments.length} of{" "}
            {residentialDetailsComments.length}
          </p>
        </div>
      )}
      <FormFieldsCard
        handleSaveComment={handleSaveComment}
        fields={postalAddressFormFields}
        resolvedFields={_get(user.postalAddress, "resolvedFields")}
        addReviewComment={addReviewComment}
        formName="postalAddress"
        formHelperKey="postalAddress"
        updateEffectKey={updateEffectKey}
        updateEffectPath={`${userPathPrefix}.postalAddress`}
        formId={_get(user.postalAddress, "_id")}
        comments={residentialDetailsComments.filter(
          (comment) => comment.formName === "postalAddress"
        )}
        isProfileReview={isProfileReview}
        rowId={user._id}
      />
      <FormFieldsCard
        handleSaveComment={handleSaveComment}
        fields={emailAddressFormFields}
        resolvedFields={_get(user.electronicAddress, "resolvedFields")}
        addReviewComment={addReviewComment}
        formName="electronicAddress"
        formHelperKey="electronicAddress"
        updateEffectKey={updateEffectKey}
        updateEffectPath={`${userPathPrefix}.electronicAddress`}
        formId={_get(user.electronicAddress, "_id")}
        comments={residentialDetailsComments.filter(
          (comment) => comment.formName === "electronicAddress"
        )}
        isProfileReview={isProfileReview}
        rowId={user._id}
      />
      <FormFieldsCard
        handleSaveComment={handleSaveComment}
        fields={residentialDetailsFormFields}
        resolvedFields={_get(user.residentialAddressDetails, "resolvedFields")}
        addReviewComment={addReviewComment}
        formName="residentialAddressDetails"
        formHelperKey="residentialAddressDetails"
        updateEffectKey={updateEffectKey}
        updateEffectPath={`${userPathPrefix}.residentialAddressDetails`}
        formId={_get(user.residentialAddressDetails, "_id")}
        comments={residentialDetailsComments.filter(
          (comment) => comment.formName === "residentialAddressDetails"
        )}
        isProfileReview={isProfileReview}
        rowId={user._id}
      />
    </>
  );
}

export default ResidentialDetails;
