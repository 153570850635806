import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import _isFunction from "lodash/isFunction";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import { closeModal } from "state/slices/modals";

const ModalWrapper = (props) => {
  const { modals } = useSelector((state) => state.modalsSlice);

  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  const modalIndex = modals.indexOf(props.name);

  useEffect(() => {
    setModalOpen(modalIndex > -1);
  }, [modalIndex, modals, props.name]);

  const close = () => {
    if (_isFunction(props?.toggleShowJSForm)) {
      props.toggleShowJSForm(false);
    }
    dispatch(closeModal(props.name));
  };

  return (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog className="fixed inset-0 z-10 overflow-y-auto" onClose={close}>
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
        <div className="px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span className="inline-block h-screen align-top" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-4xl py-4 mt-20 mb-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-sm shadow-xl">
              <div className="flex justify-between px-4 pb-2 border-b">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {props.title}
                </Dialog.Title>
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => close(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div className="max-h-[80vh] overflow-auto p-6">
                {props.children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalWrapper;
