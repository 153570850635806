/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const corporateJointShareholderTableSlice = createSlice({
  name: "Corporate Joint Shareholders Table",
  initialState: {
    shareholders: [],
    currentJSGroup: "",
  },
  reducers: {
    setJointShareholders: (state, action) => {
      state.shareholders.push(...action.payload);
    },
    addJointShareholder: (state, action) => {
      const id = uuidv4();
      state.shareholders.push({
        id,
        ...action.payload,
      });
    },
    deleteJointShareholder: (state, { payload }) => {
      const officialId = payload.id || payload._id;
      const index = state.shareholders.findIndex(
        (item) => officialId === item.id || officialId === item._id
      );
      if (index > -1) state.shareholders.splice(index, 1);
    },
    editJointShareholder: (state, { payload }) => {
      const officialId = payload.id || payload._id;
      const index = state.shareholders.findIndex(
        (item) => officialId === item.id || officialId === item._id
      );
      if (index > -1) state.shareholders.splice(index, 1, payload);
    },
    resetJointShareholderTable: (state) => {
      state.shareholders = [];
    },
    setCurrentJSGroup: (state, action) => {
      state.currentJSGroup = action.payload;
    },
    resetCurrentJSGroup: (state) => {
      state.currentJSGroup = "";
    },
  },
});

export const {
  addJointShareholder,
  setJointShareholders,
  deleteJointShareholder,
  editJointShareholder,
  resetJointShareholderTable,
  setCurrentJSGroup,
  resetCurrentJSGroup,
} = corporateJointShareholderTableSlice.actions;

export default corporateJointShareholderTableSlice.reducer;
