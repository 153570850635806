import React, { useEffect } from "react";
import { Field } from "formik";
import { subYears, format } from "date-fns";
import _get from "lodash/get";

import BoMaxValue from "./BoMaxValue";
import Input from "components/lib/Shared/Input";
import Radio from "components/lib/Shared/Radio";
import RadioGroup from "components/lib/Shared/RadioGroup";
import DatePickerField from "components/lib/Shared/DatePickerField";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import { Constants } from "config/constants";

const CompaniesBeneficialOwnershipComponent = (props) => {
  const {
    field,
    form,
    isCompanyOfficialTab,
    corporateIdType,
    selectedShareholder,
    isReportable,
    disabled,
  } = props;

  let resolvedFields = props?.resolvedFields
    ? props.resolvedFields
    : field.value?.resolvedFields ?? [];

  const minDate = subYears(new Date(), 1);
  const {
    errors,
    touched,
    setFieldValue,
    values: { comments },
  } = form;
  const handleDateValue = (date) => {
    setFieldValue(field.name, {
      ...field.value,
      dateOfBecomingBO: format(date, "yyyy/MM/dd"),
    });
  };

  useEffect(() => {
    setFieldValue("beneficialOwnershipForm", {
      ...field?.value,
      directPercentShareholding: "",
      directPercentVotingRights: "",
    });
  }, []);

  const {
    maxPercentShareholding,
    directPercentShareholding,
    indirectPercentShareholding,
    maxPercentVotingRights,
    directPercentVotingRights,
    indirectPercentVotingRights,
    indirectRightRemoveDirector,
    indirectCompanyControlRight,
    dateOfBecomingBO,
    sourceOfBOInformation,
  } = field?.value ?? {};

  // temporary update null values to empty strings
  useEffect(() => {
    setFieldValue("beneficialOwnershipForm", {
      ...field?.value,
      dateOfBecomingBO: dateOfBecomingBO !== null ? dateOfBecomingBO : "",
    });
  }, []);

  if (directPercentShareholding === null) {
    setFieldValue("beneficialOwnershipForm", {
      ...field?.value,
      directPercentShareholding: "",
    });
  }

  if (directPercentVotingRights === null) {
    setFieldValue("beneficialOwnershipForm", {
      ...field?.value,
      directPercentVotingRights: "",
    });
  }

  const options = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const sourceOfBOInformationOptions = Constants.SOURCE_OF_BO_INFO_OPTIONS;

  const selectedIndirectRightRemoveDirector = options.find(
    (option) => option.value === indirectRightRemoveDirector
  );

  const selectedIndirectCompanyControlRight = options.find(
    (option) => option.value === indirectCompanyControlRight
  );

  const selectedSourceOfBOInformation = sourceOfBOInformationOptions.find(
    (option) => option.value === sourceOfBOInformation
  );

  return (
    <div className="grid gap-4">
      <div className="space-y-2">
        <h3 className="font-medium">
          1. What percentage of the shareholding does the company holding?
        </h3>
        <div className="grid gap-2">
          <BoMaxValue value={maxPercentShareholding} />
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Field
                name="beneficialOwnershipForm.directPercentShareholding"
                type="number"
                component={Input}
                placeholder="Eg. 10%"
                label="Directly"
                disabled
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="Indirectly"
                showRequiredAsterisk
                name="beneficialOwnershipForm.indirectPercentShareholding"
                type="number"
                placeholder="Eg. 10%"
                errorPath="beneficialOwnershipForm.indirectPercentShareholding"
                commentPath="beneficialOwnershipForm.percentShareholding"
                min={0}
                max={maxPercentShareholding}
                value={indirectPercentShareholding?.toString()}
                step="0.01"
                readOnly={isCompanyOfficialTab}
                disabled={
                  corporateIdType === "Company Limited By Guarantee/NGO" ||
                  resolvedFields.includes("indirectPercentShareholding") ||
                  disabled
                }
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="space-y-2">
        <h3 className="font-medium">
          2. What percentage of the voting rights does the company excercise?
        </h3>
        <div className="grid gap-2">
          <BoMaxValue value={maxPercentVotingRights} />
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="Directly"
                name="beneficialOwnershipForm.directPercentVotingRights"
                type="number"
                placeholder="Eg. 10%"
                disabled
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="Indirectly"
                showRequiredAsterisk
                type="number"
                name="beneficialOwnershipForm.indirectPercentVotingRights"
                errorPath="beneficialOwnershipForm.indirectPercentVotingRights"
                commentPath="beneficialOwnershipForm.percentVotingRights"
                placeholder="Eg. 10%"
                min={0}
                max={maxPercentVotingRights}
                value={indirectPercentVotingRights?.toString()}
                step="0.01"
                readOnly={isCompanyOfficialTab}
                disabled={
                  resolvedFields.includes("indirectPercentVotingRights") ||
                  disabled
                }
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="space-y-2">
        <h3 className="font-medium">
          3. Does the company have a right to appoint/remove a director in the
          company?
        </h3>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <RadioGroup
              label="Directly"
              errors={errors}
              touched={touched}
              name="beneficialOwnershipForm.directRightRemoveDirector"
            >
              <Radio
                name="beneficialOwnershipForm.directRightRemoveDirector"
                id="yesDirectRightRemoveDirector"
                label="Yes"
                value="yes"
                disabled
              />
              <Radio
                name="beneficialOwnershipForm.directRightRemoveDirector"
                id="nodirectRightRemoveDirector"
                label="No"
                value="no"
                disabled
              />
            </RadioGroup>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <RadioGroup
              label="Indirectly"
              showRequiredAsterisk
              errors={errors}
              touched={touched}
              comments={_get(
                comments,
                "beneficialOwnershipForm.rightRemoveDirector",
                []
              )}
              name="beneficialOwnershipForm.indirectRightRemoveDirector"
            >
              <Radio
                name="beneficialOwnershipForm.indirectRightRemoveDirector"
                id="yesIndirectRightRemoveDirector"
                label="Yes"
                value="yes"
                defaultChecked={selectedIndirectRightRemoveDirector}
                disabled={
                  selectedShareholder?.beneficialOwnershipForm
                    ?.indirectRightRemoveDirector === "no" ||
                  resolvedFields.includes("indirectRightRemoveDirector") ||
                  disabled
                }
              />
              <Radio
                name="beneficialOwnershipForm.indirectRightRemoveDirector"
                id="noIndirectRightRemoveDirector"
                label="No"
                value="no"
                defaultChecked={selectedIndirectRightRemoveDirector}
                disabled={
                  selectedShareholder?.beneficialOwnershipForm
                    ?.indirectRightRemoveDirector === "no" ||
                  resolvedFields.includes("indirectRightRemoveDirector") ||
                  disabled
                }
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <hr />
      <div className="space-y-2">
        <h3 className="font-medium">
          4. Does the company exercise significant influence or control over the
          company?
        </h3>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <RadioGroup
              label="Directly"
              errors={errors}
              touched={touched}
              name="beneficialOwnershipForm.directCompanyControlRight"
            >
              <Radio
                name="beneficialOwnershipForm.directCompanyControlRight"
                id="yesDirectCompanyControlRight"
                label="Yes"
                value="yes"
                disabled
              />
              <Radio
                name="beneficialOwnershipForm.directCompanyControlRight"
                id="noDirectCompanyControlRight"
                label="No"
                value="no"
                disabled
              />
            </RadioGroup>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <RadioGroup
              label="Indirectly"
              showRequiredAsterisk
              errors={errors}
              touched={touched}
              comments={_get(
                comments,
                "beneficialOwnershipForm.companyControlRight",
                []
              )}
              name="beneficialOwnershipForm.indirectCompanyControlRight"
            >
              <Radio
                name="beneficialOwnershipForm.indirectCompanyControlRight"
                id="yesIndirectCompanyControlRight"
                label="Yes"
                value="yes"
                defaultChecked={selectedIndirectCompanyControlRight}
                disabled={
                  selectedShareholder?.beneficialOwnershipForm
                    ?.indirectCompanyControlRight === "no" ||
                  resolvedFields.includes("indirectCompanyControlRight") ||
                  disabled
                }
              />
              <Radio
                name="beneficialOwnershipForm.indirectCompanyControlRight"
                id="noIndirectCompanyControlRight"
                label="No"
                value="no"
                defaultChecked={selectedIndirectCompanyControlRight}
                disabled={
                  selectedShareholder?.beneficialOwnershipForm
                    ?.indirectCompanyControlRight === "no" ||
                  resolvedFields.includes("indirectCompanyControlRight") ||
                  disabled
                }
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <hr />
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <DatePickerField
            label="Effective Date Of Becoming B.O"
            showRequiredAsterisk={isReportable}
            selected={dateOfBecomingBO ? new Date(dateOfBecomingBO) : ""}
            onChange={handleDateValue}
            minDate={minDate}
            maxDate={new Date()}
            name="beneficialOwnershipForm.dateOfBecomingBO"
            form={form}
            placeholderText="Select date of becoming B.O"
            errorPath="beneficialOwnershipForm.dateOfBecomingBO"
            disabled={
              isReportable === false ||
              resolvedFields.includes("dateOfBecomingBO") ||
              disabled
            }
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <Field
            label="Source of beneficial owner information"
            showRequiredAsterisk
            value={selectedSourceOfBOInformation}
            name="beneficialOwnershipForm.sourceOfBOInformation"
            component={ReactSelectWithFormik}
            options={sourceOfBOInformationOptions}
            errorPath="beneficialOwnershipForm.sourceOfBOInformation"
            placeholder="Select source of beneficial owner information"
            isDisabled={
              resolvedFields.includes("sourceOfBOInformation") || disabled
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CompaniesBeneficialOwnershipComponent;
