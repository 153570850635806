import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _uniq from "lodash/uniq";

import ShareInformationTopSection from "components/lib/Global/ShareInformationTopSection";
import NominalSharesTable from "components/PLCTABS/NominalShareCapitalTab/NominalSharesTable";
import SharesCategoryTabs from "components/PLCTABS/NominalShareCapitalTab/SharesCategoryTabs";
import CommentsList from "components/lib/Global/CommentsList";
import { addComment, editComment } from "state/slices/comment";
import { getTakenShares, roundNumber } from "utils";

export default function ShareInformation({ disabledRightsEditor, isViewMode }) {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companySlice);
  const { comments } = useSelector((state) => state.commentsSlice);
  const { shares } = useSelector((state) => state).sharesTableSlice;

  const shareInformationComments = comments.filter(
    (comment) => comment.formName === "shareInformation"
  );

  const takenShares = getTakenShares(
    useSelector((state) => state.companyOfficialsSlice.companyOfficials.items)
  );

  const sumNominalCapital = shares
    .map((share) => share.sharesValue * share.sharesNumber)
    .reduce((a, b) => a + b, 0);

  const sumOfVotingRights = shares.reduce(
    (acc, share) => roundNumber(acc + Number(share.votingRights)),
    0
  );

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            company: company._id,
          },
        })
      );
    }
  };

  return (
    <div className="mt-6">
      {shareInformationComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 mb-4 bg-white">
          <CommentsList
            comments={shareInformationComments}
            toggleCommentStatus={!isViewMode}
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {shareInformationComments.length} of{" "}
            {shareInformationComments.length}
          </p>
        </div>
      )}
      <ShareInformationTopSection
        sumNominalCapital={sumNominalCapital}
        sumOfVotingRights={sumOfVotingRights}
      />
      <NominalSharesTable
        handleEdit={() => null}
        takenShares={takenShares}
        handleView={() => null}
        showAddCommentColumn={!isViewMode}
        handleSaveComment={handleSaveComment}
        comments={shareInformationComments}
      />
      <div className="mt-12">
        <SharesCategoryTabs
          categories={_uniq(shares.slice(0).map((row) => row.sharesCategory))}
          disabledRightsEditor={disabledRightsEditor}
        />
      </div>
    </div>
  );
}
