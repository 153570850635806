/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _omit from "lodash/omit";

import reviewerDataService from "services/reviewer";

export const getReviewers = createAsyncThunk(
  "reviewers/get-reviewers",
  async (queryParams) => {
    const res = await reviewerDataService.getAll(queryParams);
    return res.data;
  }
);

export const getReviewer = createAsyncThunk(
  "reviewers/get-reviewer",
  async ({ email }) => {
    const res = await reviewerDataService.get(email);
    return res.data;
  }
);

export const addReviewer = createAsyncThunk(
  "reviewers/create",
  async ({ companyId, data }) => {
    const res = await reviewerDataService.create({ companyId, ...data });
    return res.data;
  }
);

export const updateReviewer = createAsyncThunk(
  "reviewers/update",
  async ({ id, data }) => {
    const res = await reviewerDataService.update(
      id,
      _omit(data, ["_id", "_v", "createdAt", "createdBy", "updatedAt"])
    );
    return res.data;
  }
);

export const deleteReviewer = createAsyncThunk(
  "reviewers/delete",
  async ({ id, companyId }) => {
    await reviewerDataService.remove(id, companyId);
    return { id };
  }
);

export const confirmReviewer = createAsyncThunk(
  "reviewers/confirmReviewer",
  async ({ token }) => {
    const res = await reviewerDataService.confirmReviewer(token);
    return res.data;
  }
);

export const getCompaniesToReviewAsync = createAsyncThunk(
  "reviewers/getCompaniesToReviewAsync",
  async (queryParams) => {
    const res = await reviewerDataService.getCompaniesToReview(queryParams);
    return res.data;
  }
);

export const getCompaniesForCorrectionsAsync = createAsyncThunk(
  "reviewers/getCompaniesForCorrectionsAsync",
  async (queryParams = {}) => {
    const res = await reviewerDataService.getCompaniesForCorrections(
      queryParams
    );
    return res.data;
  }
);

const initialState = {
  reviewers: [],
  reviewer: {},
  companiesToReview: {
    items: [],
    totalCount: 0,
  },
  getCompaniesToReview: {
    status: "idle",
    error: false,
  },
  companiesForCorrections: {
    queryParams: {},
    items: [],
    totalCount: 0,
  },
  getCompaniesForCorrections: {
    status: "idle",
    error: false,
  },
};

const reviewersTableSlice = createSlice({
  name: "Reviewers Table",
  initialState,
  reducers: {
    setReviewers: (state, action) => {
      state.reviewers = action.payload;
    },
    resetReviewersTableSlice: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getReviewers.fulfilled]: (state, action) => {
      state.reviewers = action.payload.items;
    },
    [getReviewer.fulfilled]: (state, action) => {
      state.reviewer = action.payload.items[0];
    },
    [addReviewer.fulfilled]: (state, action) => {
      state.reviewers.push(action.payload.reviewer);
    },
    [updateReviewer.fulfilled]: (state, { payload }) => {
      const reviewerId = payload.id || payload._id;
      const { reviewers } = state;
      const index = reviewers.findIndex(
        (item) => reviewerId === item.id || reviewerId === item._id
      );
      if (index > -1) {
        reviewers.splice(index, 1, { ...payload });
      }
    },
    [deleteReviewer.fulfilled]: (state, { payload }) => {
      const reviewerId = payload.id || payload._id;
      const { reviewers } = state;
      const index = reviewers.findIndex(
        (item) => reviewerId === item.id || reviewerId === item._id
      );
      if (index > -1) reviewers.splice(index, 1);
    },

    [getCompaniesToReviewAsync.pending]: (state) => {
      state.getCompaniesToReview.status = "loading";
      state.getCompaniesToReview.error = false;
    },
    [getCompaniesToReviewAsync.fulfilled]: (state, action) => {
      state.getCompaniesToReview.status = "idle";
      state.getCompaniesToReview.error = false;
      state.companiesToReview = action.payload;
    },
    [getCompaniesToReviewAsync.rejected]: (state, action) => {
      state.getCompaniesToReview.status = "failed";
      state.getCompaniesToReview.error = action.payload.messageText;
    },

    [getCompaniesForCorrectionsAsync.pending]: (state, action) => {
      state.companiesForCorrections.queryParams = action.meta.arg;
      state.getCompaniesForCorrections.status = "loading";
      state.getCompaniesForCorrections.error = false;
    },
    [getCompaniesForCorrectionsAsync.fulfilled]: (state, action) => {
      state.getCompaniesForCorrections.status = "idle";
      state.getCompaniesForCorrections.error = false;
      state.companiesForCorrections = {
        ...state.companiesForCorrections,
        ...action.payload,
      };
    },
    [getCompaniesForCorrectionsAsync.rejected]: (state, action) => {
      state.getCompaniesForCorrections.status = "failed";
      state.getCompaniesForCorrections.error = action?.payload?.messageText;
    },
  },
});

export const { setReviewers, resetReviewersTableSlice } =
  reviewersTableSlice.actions;

export default reviewersTableSlice.reducer;
