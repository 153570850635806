import React from "react";
import { useHistory } from "react-router-dom";

const PaymentFailedPage = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="max-w-md p-6 bg-white rounded shadow-md">
        <h1 className="text-3xl font-bold text-red-500 mb-4">
          Payment Failed!
        </h1>
        <p className="text-gray-700 mb-6">
          We apologize, but your payment could not be processed at this time.
        </p>
        <button
          onClick={handleGoBack}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-400"
        >
          Retry Payment
        </button>
      </div>
    </div>
  );
};

export default PaymentFailedPage;
