import React from "react";

import SectionDescription from "components/lib/Shared/SectionDescription";

function ShareInformationTopSection({
  sectionNumber = "",
  sumNominalCapital,
  sumOfVotingRights,
}) {
  return (
    <div className="grid gap-3 mb-4 sm:grid-cols-2">
      <SectionDescription
        id={sectionNumber}
        title="Share Information Summary"
        description="Nominal capital also commonly referred to as authorized capital refers to the designated or assigned share capital of a company and represented in value of shares of the company."
      />
      <div className="sm:flex sm:justify-end">
        <div className="inline-flex px-6 py-3 bg-gray-200 rounded-sm shadow-sm">
          <div className="flex space-x-4 tracking-tight">
            <div className="flex flex-col justify-center leading-tight text-sflPrimary">
              <p className="text-sm">KES</p>
              <p className="text-xl font-semibold">
                {Number(sumNominalCapital).toLocaleString()}
              </p>
              <div>
                <span className="text-sm">Nominal Capital</span>
              </div>
            </div>
            <span className="border border-l-1 border-sflPrimary"></span>
            <div className="flex flex-col justify-center text-gray-600">
              <div className="flex flex-col">
                <p className="text-sm"></p>
                <p className="text-xl font-semibold">
                  {sumOfVotingRights.toLocaleString()}
                </p>
              </div>
              <div>
                <span className="text-sm">Votes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareInformationTopSection;
