import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (queryParams) =>
  axios.get(`/users?${getQueryString(queryParams)}`);

const get = (userId) => axios.get(`/users/${userId}`);

const create = (data) => axios.post("/users", data);

const update = (userId, data) => axios.put(`/users/${userId}`, data);

const remove = (userId) => axios.delete(`/users/${userId}`);

const verify = (data) => axios.post("/users/verify", data);

const sendCorrectionNotification = (data) =>
  axios.post("/users/send-correction-notification", data);

const updateLinkedCompany = (data) =>
  axios.put(`/users/update-linked-company`, data);

const getTransactions = (queryParams) =>
  axios.get(`/users/get-transactions?${getQueryString(queryParams)}`);

const userDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  verify,
  sendCorrectionNotification,
  updateLinkedCompany,
  getTransactions,
};

export default userDataService;
