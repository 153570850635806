import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ReactTable from "components/lib/Global/ReactTable";
import { getCompaniesForSignatureAsync } from "state/slices/companies";
import { getCompanyNameWithSuffix } from "utils/company";
import { setCookie } from "utils/cookie";

const SignApplications = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const history = useHistory();

  const currentUser = useSelector((state) => state.userSlice);
  const { companiesForSignature, getCompaniesForSignature } = useSelector(
    (state) => state.companiesSlice
  );

  useEffect(() => {
    dispatch(
      getCompaniesForSignatureAsync({
        status: "Signature",
        createdBy: currentUser._id,
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
      })
    );
  }, [rowsPerPage, currentPage]);

  const columns = [
    {
      Header: "Full Name",
      accessor: "companyName1",
      Cell: ({ row: { original } }) => getCompanyNameWithSuffix(original),
    },
    {
      Header: "Application Type",
      accessor: "applicationType",
      Cell: ({ row }) => row?.original?.type ?? "N/A",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const handleReviewBtnClick = () => {
          setCookie("role", 7);
          history.push(`/companies/${row.original?._id}`);
        };
        return (
          <button onClick={handleReviewBtnClick}>
            <span>Sign Documents</span>
          </button>
        );
      },
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="w-full bg-white shadow">
        <div className="w-full">
          <ReactTable
            columns={columns}
            data={companiesForSignature.items}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            totalRows={companiesForSignature.totalCount}
            setCurrentPage={setCurrentPage}
            paginate
            isLoading={getCompaniesForSignature.status === "loading"}
            headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
            headingStyle="pl-4 text-left"
            dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          />
        </div>
      </div>
    </div>
  );
};

export default SignApplications;
