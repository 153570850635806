import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _get from "lodash/get";
import _has from "lodash/has";
import Cookies from "js-cookie";

import CompanyFormation from "components/PLCTABS/CompanyFormationTab/index";
import RegisteredOfficeAddress from "components/PLCTABS/RegisteredOfficeAddressTab";
import NominalShareCapitalTab from "components/PLCTABS/NominalShareCapitalTab";
import CompanyOfficialsTab from "components/PLCTABS/CompanyOfficialsTab";
import BeneficialOwnershipTab from "components/PLCTABS/BeneficialOwnershipTab";
import SummaryTab from "components/PLCTABS/SummaryTab";
import AssignReviewerTab from "components/PLCTABS/AssignReviewerTab";
import Hero from "components/lib/Global/Hero";
import { getComments } from "state/slices/comment";
import {
  submitApplication,
  updateCompanyAsync,
  validateCompanyOfficialsAsync,
} from "state/slices/company";
import { useToast } from "hooks/useToast";
import { getAccessRequests } from "state/slices/accessRequest";
import step1Validation from "components/PLCTABS/CompanyFormationTab/step1Validation";
import step2Validation from "components/PLCTABS/CompanyFormationTab/step2Validation";
import step3Validation from "components/PLCTABS/CompanyFormationTab/step3Validation";
import registeredOfficeValidation from "components/PLCTABS/RegisteredOfficeAddressTab/registeredOfficeValidation";
import { classNames } from "utils";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { Constants } from "config/constants";
import { validateNominalSharesRestrictions } from "utils/nominalShares";
import SignOff from "components/PLCTABS/SignOff";
import { getCompanyNameWithSuffix } from "utils/company";
import ApplicantsDetails from "components/PLCTABS/ApplicantsDetails";

export default function CompanyCreation(props) {
  const role = Cookies.get("role");
  const [submitToAdmin, setSubmitToAdmin] = useState(false);
  const [submitBtnLabel, setSubmitBtnLabel] = useState("Save and proceed");
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { transformedComments = {} } = useSelector(
    (state) => state.commentsSlice
  );
  const companyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );
  const userSlice = useSelector((state) => state.userSlice);
  const { getCompanyStatus } = useSelector((state) => state.companySlice);
  const accessRequests = useSelector(
    (state) => state.accessRequestsSlice.items
  );
  const {
    beneficialOwners,
    deleteBeneficialOwnerStatus,
    addBeneficialOwnerStatus,
    getAllBeneficialOwnersStatus,
    updateDropdownEntryStatusStatus,
  } = useSelector((state) => state.beneficialOwnersTableSlice);

  const beneficialOwnersDropdown = beneficialOwners.filter(
    (bo) =>
      bo.showInBoDropdown &&
      [
        bo?.electronicAddress?.emailAddress,
        bo?.identificationDetails?.guardianEmailAddress,
      ].includes(userSlice.electronicAddress?.emailAddress)
  );

  const enableBoTab =
    role === Constants.COOKIE_ROLES.OFFICIAL
      ? beneficialOwners.find((bo) =>
          [
            bo?.electronicAddress?.emailAddress,
            bo?.identificationDetails?.guardianEmailAddress,
          ].includes(userSlice.electronicAddress?.emailAddress)
        ) || beneficialOwnersDropdown?.length > 0
      : false;

  const history = useHistory();
  const dispatch = useDispatch();
  const { toast } = useToast(4000);

  const applicationState = useSelector((state) => state);

  const {
    companySlice,
    registeredOfficeSlice,
    sharesTableSlice,
    companyOfficialsSlice,
    beneficialOwnersTableSlice,
  } = applicationState;

  const [validTabs, setValidTabs] = useState({
    applicantsDetails: false,
    companyFormation: false,
    addRegisteredDetails: false,
    shareInformation: false,
    companyOfficials: false,
    beneficialOwners: false,
  });
  const pendingRequests = accessRequests.filter(
    (accessRequest) => accessRequest.status === "Pending"
  );

  useEffect(async () => {
    await dispatch(getAccessRequests({ query: { createdBy: userSlice?._id } }));
  }, [companyOfficials.length]);

  const { company } = useSelector((state) => state.companySlice);
  const type = company?.type ?? props.type;
  const companyId = company?._id;

  const handleBackBtn = () => {
    if (currentSectionIndex - 1 >= 0)
      setCurrentSectionIndex(currentSectionIndex - 1);
  };

  const handleSubmitToAdmin = async () => {
    setIsSubmitting(true);

    let status = "Admin Review II";
    if (company.status === "Submission Corrections") {
      status = "Submission II";
    } else if (company.status === "BRS Corrections") {
      status = "BRS Review II";
    } else if (
      ["Signature", "Signature Corrections"].includes(company.status)
    ) {
      status = "BRS Review";
    }

    let response = {};
    if (status !== "Admin Review II") {
      response = await dispatch(
        updateCompanyAsync({
          id: company?._id,
          data: { status },
        })
      );
    } else {
      response = await dispatch(
        submitApplication({
          data: {
            companyId: company?._id,
            companyName: getCompanyNameWithSuffix(company),
            role,
            status,
          },
        })
      );
    }
    setIsSubmitting(false);
    if (response?.payload?.status) {
      toast(
        "success",
        "Your application has been successfully submitted to the admins."
      );
      history.push("/");
    } else {
      toast(
        "error",
        response?.payload?.message ?? "Something went wrong please try again."
      );
    }
  };

  useEffect(() => {
    const tempValidTabs = {
      applicantsDetails: false,
      companyFormation: false,
      addRegisteredDetails: false,
      shareInformation: false,
      companyOfficials: false,
      beneficialOwners: false,
    };
    const checkSidebarNavigationValidation = async () => {
      const isApplicantStepValid = Boolean(
        _has(companySlice, "company.addCoApplicants") &&
          companySlice.company?.applicantCapacity
      );
      tempValidTabs.applicantsDetails = isApplicantStepValid;
      const isStep1Valid = await step1Validation().isValid(
        companySlice.companyFormation.step1
      );
      const isStep2Valid = await step2Validation().schema.isValid(
        companySlice.companyFormation.step2
      );
      const isStep3Valid = await step3Validation().schema.isValid(
        companySlice.companyFormation.step3
      );
      const isCompanyFormationStepValid =
        isApplicantStepValid && isStep1Valid && isStep2Valid && isStep3Valid;
      tempValidTabs.companyFormation = isCompanyFormationStepValid;
      const isRegisteredDetailsStepValid =
        isCompanyFormationStepValid &&
        (await registeredOfficeValidation.isValid(registeredOfficeSlice));
      tempValidTabs.addRegisteredDetails =
        isCompanyFormationStepValid && isRegisteredDetailsStepValid;
      const { errors: nominalSharesErrors } = validateNominalSharesRestrictions(
        { shares: sharesTableSlice?.shares }
      );
      const isShareCapitalStepValid =
        isRegisteredDetailsStepValid && nominalSharesErrors.length === 0;
      tempValidTabs.shareInformation = isShareCapitalStepValid;
      const response = await dispatch(
        validateCompanyOfficialsAsync({
          companyId: company?._id,
        })
      );
      const errors = response.payload?.errors ?? [];
      const isCompanyOfficialsStepValid =
        isShareCapitalStepValid && errors.length === 0;
      tempValidTabs.companyOfficials = isCompanyOfficialsStepValid;
      const beneficialOwnersDropdown =
        beneficialOwnersTableSlice?.beneficialOwners?.filter(
          (bo) => bo.showInBoDropdown
        );
      const isBeneficialOwnersStepValid =
        isCompanyOfficialsStepValid && beneficialOwnersDropdown.length === 0;
      tempValidTabs.beneficialOwners = isBeneficialOwnersStepValid;
      setValidTabs(tempValidTabs);
    };
    if (
      company?._id &&
      deleteBeneficialOwnerStatus.inProgressIds.length === 0 &&
      addBeneficialOwnerStatus.inProgressIds.length === 0 &&
      getAllBeneficialOwnersStatus.status !== "loading" &&
      updateDropdownEntryStatusStatus.status !== "loading"
    ) {
      checkSidebarNavigationValidation();
    }
  }, [
    companySlice.companyFormation.companyFormation,
    companySlice?.company,
    companySlice?.company?.addCoApplicants,
    companySlice.company?.applicantCapacity,
    registeredOfficeSlice,
    sharesTableSlice,
    companyOfficials.items,
    beneficialOwnersTableSlice,
    deleteBeneficialOwnerStatus,
    addBeneficialOwnerStatus,
    getAllBeneficialOwnersStatus,
    updateDropdownEntryStatusStatus,
  ]);

  const navigation = useMemo(() => {
    const defaultNavigation = [
      {
        id: "applicantsDetails",
        name: "Applicant details",
        description:
          "The process by which a new or existing business registers as a company.",
        commentsCount: _get(
          transformedComments,
          "applicantsDetailsCommentCount",
          0
        ),
      },
      {
        id: "companyFormation",
        name: "Company formation",
        description:
          "The process by which a new or existing business registers as a company.",
        commentsCount: _get(
          transformedComments,
          "companyFormationCommentCount",
          0
        ),
        disabled: !validTabs.applicantsDetails,
      },
      {
        id: "addRegisteredDetails",
        name: "Add registered details",
        description:
          "This is an official correspondence address that the company  is legally required to provide during the incorporation process and maintain throughout its existence.",
        commentsCount: _get(
          transformedComments?.addressParticulars,
          "commentsCount",
          0
        ),
        disabled: !validTabs.companyFormation,
      },
      {
        id: "shareInformation",
        name: "Share Capital",
        description:
          "The amount of money the owners of a company have invested in the business as represented by common and/or preferred shares.",
        commentsCount: _get(
          transformedComments?.shareInformation,
          "commentsCount",
          0
        ),
        disabled: !validTabs.addRegisteredDetails,
      },
      {
        id: "companyOfficials",
        name: "Company officials",
        description:
          "The List of Shareholders, Directors, Company Secretary and Authorized Persons duly appointed by the Company.",
        commentsCount: _get(
          transformedComments,
          "companyOfficialsCommentCount",
          0
        ),
        disabled: !validTabs.shareInformation,
      },
      {
        id: "beneficialOwners",
        name: "Beneficial owners",
        description:
          "A beneficial owner is an individual who ultimately owns or controls more than 10% of a company's shares or voting rights, or who otherwise exercise control over the company or its management.",
        commentsCount: _get(
          transformedComments,
          "beneficialOwnersCommentCount",
          0
        ),
        disabled: !validTabs.companyOfficials && !enableBoTab,
      },
    ];

    let companyRelatedPendingRequests = 0;
    if (companyId) {
      companyRelatedPendingRequests = pendingRequests.filter(
        (request) => request.company?._id === companyId
      );
    }

    if (companyRelatedPendingRequests.length > 0) {
      return defaultNavigation.slice(0, 5);
    }

    if (company?.status === "Draft" || !company) {
      if (role === Constants.COOKIE_ROLES.OFFICIAL) {
        if (enableBoTab) return defaultNavigation;
        return defaultNavigation.filter(({ id }) => id !== "beneficialOwners");
      }
      return [
        ...defaultNavigation,
        {
          id: "assignReviewer",
          name: "Assign Reviewer",
          description:
            "Someone who will formally assess the incorporation information with a view to changing it, if necessary.",
          disabled: !validTabs.beneficialOwners || !validTabs.companyOfficials,
        },
      ];
    }
    if (company?.status === "Signature") {
      return [
        {
          id: "signOff",
          name: "Sign Off",
          description:
            "Someone who will formally assess the incorporation information with a view to changing it, if necessary.",
        },
      ];
    }
    if (company?.status === "Directors Review") {
      if (role === Constants.COOKIE_ROLES.OFFICIAL)
        return defaultNavigation.filter(({ id }) => id !== "beneficialOwners");
      return [
        ...defaultNavigation,
        {
          id: "assignReviewer",
          name: "Assign Reviewer",
          description:
            "Someone who will formally assess the incorporation information with a view to changing it, if necessary.",
          disabled: !validTabs.beneficialOwners || !validTabs.companyOfficials,
        },
      ];
    }

    let sidebarNavigation = [];
    if (
      [
        "Directors Corrections",
        "Admin Corrections",
        "Submission Corrections",
        "Signature Corrections",
        "BRS Corrections",
      ].includes(company?.status)
    ) {
      if (_get(transformedComments, "companyFormationCommentCount", 0) > 0) {
        sidebarNavigation.push({
          id: "companyFormation",
          name: "Company formation",
          commentsCount: _get(
            transformedComments,
            "companyFormationCommentCount",
            0
          ),
        });
      }
      if (
        _get(transformedComments?.addressParticulars, "commentsCount", 0) > 0
      ) {
        sidebarNavigation.push({
          id: "addRegisteredDetails",
          name: "Add registered details",
          commentsCount: _get(
            transformedComments?.addressParticulars,
            "commentsCount",
            0
          ),
        });
      }
      if (_get(transformedComments?.shareInformation, "commentsCount", 0) > 0) {
        sidebarNavigation.push({
          id: "shareInformation",
          name: "Share Capital",
          description:
            "The amount of money the owners of a company have invested in the business as represented by common and/or preferred shares.",
          commentsCount: _get(
            transformedComments?.shareInformation,
            "commentsCount",
            0
          ),
        });
      }
      if (_get(transformedComments, "companyOfficialsCommentCount", 0) > 0) {
        sidebarNavigation.push({
          id: "companyOfficials",
          name: "Company officials",
          description:
            "The List of Shareholders, Directors, Company Secretary and Authorized Persons duly appointed by the Company.",
          commentsCount: _get(
            transformedComments,
            "companyOfficialsCommentCount",
            0
          ),
        });
      }
      if (
        _get(transformedComments, "beneficialOwnersCommentCount", 0) > 0 &&
        role !== Constants.COOKIE_ROLES.OFFICIAL
      ) {
        sidebarNavigation.push({
          id: "beneficialOwners",
          name: "Beneficial owners",
          description:
            "A beneficial owner is an individual who ultimately owns or controls more than 10% of a company's shares or voting rights, or who otherwise exercise control over the company or its management.",
          commentsCount: _get(
            transformedComments,
            "beneficialOwnersCommentCount",
            0
          ),
        });
      }
      if (
        _get(transformedComments, "uploadDocuments.commentsCount", 0) > 0 &&
        role !== Constants.COOKIE_ROLES.OFFICIAL
      ) {
        sidebarNavigation.push({
          id: "signOff",
          name: "Sign Off",
          description:
            "Someone who will formally assess the incorporation information with a view to changing it, if necessary.",
          commentsCount: _get(
            transformedComments,
            "uploadDocuments.commentsCount",
            0
          ),
        });
      }
    }
    return sidebarNavigation;
  }, [
    transformedComments,
    company?.status,
    pendingRequests.length,
    validTabs,
    role,
    enableBoTab,
  ]);

  const handleNextBtn = () => {
    if (submitToAdmin) {
      handleSubmitToAdmin();
      return;
    }
    if (navigation.length - 1 === currentSectionIndex) {
      history.push("/");
      return;
    }
    setCurrentSectionIndex(currentSectionIndex + 1);
  };

  const [selectedTab, setSelectedTab] = useState(navigation[0]);

  useEffect(() => {
    const tempSelectedTab = navigation[currentSectionIndex];
    const isLastTab = navigation.length - 1 === currentSectionIndex;
    if (
      isLastTab &&
      [
        "Directors Corrections",
        "Admin Corrections",
        "Submission Corrections",
        "Signature Corrections",
        "BRS Corrections",
      ].includes(company?.status)
    ) {
      setSubmitBtnLabel("Submit to admin");
      setSubmitToAdmin(true);
    } else {
      setSubmitBtnLabel("Save and proceed");
      setSubmitToAdmin(false);
    }
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [navigation, currentSectionIndex, company?.status]);

  useEffect(() => {
    const getOfficialIds = () => {
      const individualOfficials =
        company?.individualOfficials?.filter(
          (official) =>
            official?.electronicAddress?.emailAddress ===
            userSlice?.electronicAddress?.emailAddress
        ) ?? [];
      const corporateOfficials =
        company?.corporateOfficials?.filter(
          (official) =>
            official?.identificationDetails?.emailAddress ===
            userSlice?.electronicAddress?.emailAddress
        ) ?? [];
      const beneficialOwners =
        company?.beneficialOwners?.filter(
          (official) =>
            official?.electronicAddress?.emailAddress ===
            userSlice?.electronicAddress?.emailAddress
        ) ?? [];
      return [
        ...individualOfficials,
        ...corporateOfficials,
        ...beneficialOwners,
      ].map((official) => official._id);
    };

    if (companyId && role === Constants.COOKIE_ROLES.OFFICIAL) {
      const officialIds = getOfficialIds();
      if (officialIds.length > 0)
        dispatch(getComments({ companyId, officialIds }));
    } else if (companyId) {
      dispatch(getComments({ companyId }));
    }
  }, [companyId, role]);

  return (
    <div className="grid gap-10 py-10">
      <Hero
        heading={selectedTab?.label ?? selectedTab?.name}
        description={selectedTab?.description}
      />
      <div className="grid grid-cols-6 border-t border-gray">
        <div className="col-span-6 pr-8 md:col-span-1 pt-8">
          <p className="mb-4 text-sflPrimary">
            Complete the following steps to set up the{" "}
            <span className="font-bold text-gray-700">Company.</span>
          </p>
          <ul>
            {navigation.map((item, index) => (
              <li
                key={item.name}
                className={classNames(
                  index === currentSectionIndex
                    ? "text-sflPrimary"
                    : "text-tertiary",
                  `mb-4 font-medium ${
                    item.disabled
                      ? "cursor-not-allowed text-gray-300"
                      : "hover:text-sflPrimary cursor-pointer"
                  }`
                )}
                onClick={() =>
                  item.disabled ? null : setCurrentSectionIndex(index)
                }
              >
                <span className="relative truncate">
                  {`${index + 1}. ${item.name}`}
                  {item?.commentsCount > 0 && (
                    <span
                      className={`absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-3 -right-4 
                      ${item.disabled ? "cursor-not-allowed" : ""}`}
                    >
                      <span>{item.commentsCount}</span>
                    </span>
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-span-6 md:border-l md:col-span-5 md:pl-8 pt-8 border-gray">
          {getCompanyStatus.status === "loading" ? (
            <div className="flex justify-center mx-auto text-white">
              <SpinnerIcon className="text-gray-400" />
            </div>
          ) : (
            <>
              {selectedTab?.id === "applicantsDetails" && (
                <ApplicantsDetails
                  handleNextBtn={handleNextBtn}
                  submitBtnLabel={submitBtnLabel}
                  isSubmitting={isSubmitting}
                  type={type}
                  validTabs={validTabs}
                />
              )}
              {selectedTab?.id === "companyFormation" && (
                <CompanyFormation
                  handleBackBtn={handleBackBtn}
                  handleNextBtn={handleNextBtn}
                  submitBtnLabel={submitBtnLabel}
                  isSubmitting={isSubmitting}
                  type={type}
                />
              )}
              {selectedTab?.id === "addRegisteredDetails" && (
                <RegisteredOfficeAddress
                  handleBackBtn={handleBackBtn}
                  handleNextBtn={handleNextBtn}
                  submitBtnLabel={submitBtnLabel}
                  isSubmitting={isSubmitting}
                />
              )}
              {selectedTab?.id === "shareInformation" && (
                <NominalShareCapitalTab
                  handleBackBtn={handleBackBtn}
                  handleNextBtn={handleNextBtn}
                  submitBtnLabel={submitBtnLabel}
                  isSubmitting={isSubmitting}
                />
              )}
              {selectedTab?.id === "companyOfficials" && (
                <CompanyOfficialsTab
                  handleBackBtn={handleBackBtn}
                  handleNextBtn={handleNextBtn}
                  submitBtnLabel={submitBtnLabel}
                  isSubmitting={isSubmitting}
                />
              )}
              {selectedTab?.id === "beneficialOwners" && (
                <BeneficialOwnershipTab
                  handleBackBtn={handleBackBtn}
                  handleNextBtn={handleNextBtn}
                  submitBtnLabel={submitBtnLabel}
                  isSubmitting={isSubmitting}
                />
              )}
              {selectedTab?.id === "summaryTab" && (
                <SummaryTab
                  handleBackBtn={handleBackBtn}
                  handleNextBtn={handleNextBtn}
                />
              )}
              {selectedTab?.id === "assignReviewer" && (
                <AssignReviewerTab
                  handleBackBtn={handleBackBtn}
                  handleNextBtn={handleNextBtn}
                />
              )}
              {selectedTab?.id === "signOff" && (
                <SignOff
                  handleNextBtn={handleNextBtn}
                  submitBtnLabel={submitBtnLabel}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
