import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import AcceptCoApplicantInvite from "components/AcceptCoApplicantInvite";
import { acceptCoApplicantInviteAsync } from "state/slices/coApplicant";

function AcceptCoApplicantInvitePage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [redirectTo, setRedirectTo] = useState("register");
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectURI = queryParams.get("redirectURI");

  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(async () => {
    localStorage.setItem("redirectURI", redirectURI);
    if (token) {
      const response = await dispatch(acceptCoApplicantInviteAsync({ token }));
      if (response?.error?.name) {
        setError("Link is not valid.");
      } else {
        setRedirectTo(response?.payload?.redirectTo);
      }
      setLoading(false);
    }
  }, [token]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <SpinnerIcon className="text-gray-500" />
      </div>
    );

  if (error) return <span>{error}</span>;

  return <AcceptCoApplicantInvite redirectTo={redirectTo} />;
}

export default AcceptCoApplicantInvitePage;
