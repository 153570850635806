import { object, string } from "yup";

const step3Validation = () => {
  const schema = object().shape({
    primaryBusinessActivity: object().shape({
      sector: string().required("Sector is required"),
      division: string().required("Division is required"),
      group: string().required("Group is required"),
      businessClass: string().required("Class is required"),
      targetBusinessStartDate: string().required(
        "Target business start date is required"
      ),
      accountingEndMonth: string().required(
        "Accounting period end month is required"
      ),
      numberOfEmployees: string().required("Number of employees is required"),
      turnOver: string().required("Turn over is required"),
    }),
    subsidiaryDetail: object().shape({
      isSubsidiaryCompany: string().required("Please select one option"),
      isKenyanResident: string().when("isSubsidiaryCompany", {
        is: "Yes",
        then: string().required("Please select one option"),
      }),
      holdingCompanyPin: string().when("isKenyanResident", {
        is: "Yes",
        then: string()
          .trim("Company pin cannot include leading and trailing spaces")
          .strict(true)
          .required("Holding company pin is required"),
      }),
      holdingCompanyName: string().when("isKenyanResident", {
        is: "No",
        then: string()
          .trim("Company name cannot include leading and trailing spaces")
          .strict(true)
          .required("Holding company name is required"),
      }),
      holdingCompanyCountry: string().when("isKenyanResident", {
        is: "No",
        then: string().required("Holding company country is required"),
      }),
    }),
    amalgamationDetail: object().shape({
      isAmalgamationCompany: string().required("Please select one option"),
      detailOfBond: string().when("isAmalgamationCompany", {
        is: "Yes",
        then: string().required("Detail of bond is required"),
      }),
      holdingCompanyPin: string().when("isAmalgamationCompany", {
        is: "Yes",
        then: string()
          .trim("Company pin cannot include leading and trailing spaces")
          .strict(true)
          .required("Holding company pin is required"),
      }),
      holdingCompanyName: string().when("isAmalgamationCompany", {
        is: "Yes",
        then: string()
          .trim("Company name cannot include leading and trailing spaces")
          .strict(true)
          .required("Holding company name is required"),
      }),
      holdingCompanyiTaxEmail: string().when("isAmalgamationCompany", {
        is: "Yes",
        then: string()
          .email("Invalid email format")
          .required("Holding company tax email is required"),
      }),
      amalgamationDate: string().when("isAmalgamationCompany", {
        is: "Yes",
        then: string().required("Amalgamation date is required"),
      }),
    }),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default step3Validation;
