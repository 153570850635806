/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from "formik";
import React, { useState, useEffect } from "react";

import Input from "components/lib/Shared/Input";

// Postal address input without the postal code
const PostalAddressFormForeign = ({ field, idType, form, disabled }) => {
  const [postalAddressType, setPostalAddressType] = useState("text");
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    // for validation
    setFieldValue(field.name, {
      postalAddress: "",
      serviceAddress: "",
      ...field.value,
    });
  }, []);

  useEffect(() => {
    if (
      ["Kenyan Citizen", "Foreign Resident"].includes(idType) &&
      form.values.isNonResident !== "Yes"
    ) {
      setPostalAddressType("number");
    } else setPostalAddressType("text");
  }, [idType, form.values.isNonResident]);

  const handleSetValue = (e) => {
    const value =
      postalAddressType === "number" &&
      e.target.name === "postalAddress" &&
      e.target.value
        ? parseFloat(e.target.value)
        : e.target.value;
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: value,
    });
  };

  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Postal Address
      </h3>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Postal Address"
          name="postalAddress"
          placeholder="Enter postal address"
          type={postalAddressType}
          value={field.value?.postalAddress ?? ""}
          errorPath="postalAddress.postalAddress"
          disabled={disabled}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Service Address(If different from above address)"
          name="serviceAddress"
          placeholder="Enter service address"
          value={field.value?.serviceAddress ?? ""}
          errorPath="postalAddress.serviceAddress"
          disabled={disabled}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
    </>
  );
};

export default PostalAddressFormForeign;
