import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import discountService from "services/discount";

export const getDiscountsAsync = createAsyncThunk(
  "discounts/getDiscountsAsync",
  async (queryParams) => {
    const res = await discountService.getAll(queryParams);
    return res.data;
  }
);

export const addDiscountAsync = createAsyncThunk(
  "discounts/addDiscountAsync",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await discountService.create(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editDiscountAsync = createAsyncThunk(
  "discounts/editDiscountAsync",
  async ({ discountId, data }, { rejectWithValue }) => {
    try {
      const res = await discountService.update(discountId, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDiscountAsync = createAsyncThunk(
  "discounts/deleteDiscountAsync",
  async ({ discountId }) => {
    await discountService.remove(discountId);
    return { discountId };
  }
);

export const generateCodeAsync = createAsyncThunk(
  "discounts/generateCodeAsync",
  async () => {
    const res = await discountService.generateCode();
    return res.data;
  }
);

export const verifyDiscountCodeAsync = createAsyncThunk(
  "discounts/verifyDiscountCodeAsync",
  async (queryParams, { rejectWithValue }) => {
    try {
      const res = await discountService.verifyDiscountCode(queryParams);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  discounts: {
    items: [],
    totalCount: 0,
  },
  selectedDiscount: {},
  getDiscounts: {
    status: "idle",
    error: false,
  },
  generateCode: {
    status: "idle",
    error: false,
  },
};

const discountsSlice = createSlice({
  name: "discounts",
  initialState,
  reducers: {
    updateDiscountSlice: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetDiscountsSlice: () => initialState,
  },
  extraReducers: {
    [getDiscountsAsync.pending]: (state) => {
      state.getDiscounts.status = "loading";
      state.getDiscounts.error = false;
    },
    [getDiscountsAsync.fulfilled]: (state, action) => {
      state.getDiscounts.status = "idle";
      state.discounts = action.payload;
    },
    [getDiscountsAsync.rejected]: (state, action) => {
      state.getDiscounts.status = "failed";
      state.getDiscounts.error = action?.payload?.messageText;
    },

    [generateCodeAsync.pending]: (state) => {
      state.generateCode.status = "loading";
      state.generateCode.error = false;
    },
    [generateCodeAsync.fulfilled]: (state) => {
      state.generateCode.status = "idle";
    },
    [generateCodeAsync.rejected]: (state, action) => {
      state.generateCode.status = "failed";
      state.generateCode.error = action?.payload?.messageText;
    },
  },
});

export const { updateDiscountSlice, resetDiscountsSlice } =
  discountsSlice.actions;

export default discountsSlice.reducer;
