import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import corporateDataService from "services/corporate";

export const getCorporates = createAsyncThunk(
  "contactPersonCorporates/get",
  async ({ queryParams }, thunkAPI) => {
    try {
      const res = await corporateDataService.getAll(queryParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  items: [],
  totalCount: 0,
  getOrganizations: {
    status: "idle",
    error: false,
  },
};

const contactPersonCorporatesSlice = createSlice({
  name: "contactPersonCorporatesSlice",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: {
    [getCorporates.pending]: (state) => {
      state.getOrganizations.status = "loading";
      state.getOrganizations.error = false;
    },
    [getCorporates.fulfilled]: (state, action) => {
      state.getOrganizations.status = "idle";
      state.getOrganizations.error = false;
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
    },
    [getCorporates.rejected]: (state, action) => {
      state.getOrganizations.status = "failed";
      state.getOrganizations.error = action.payload.messageText;
    },
  },
});

export const { reset } = contactPersonCorporatesSlice.actions;

const { reducer } = contactPersonCorporatesSlice;

export default reducer;
