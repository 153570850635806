import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (params) => axios.get(`/templates?${getQueryString(params)}`);

const create = (data) => axios.post("/templates", data);

const update = (templateId, data) =>
  axios.put(`/templates/${templateId}`, data);

const remove = (templateId) => axios.delete(`/templates/${templateId}`);

const generateToken = (data) => axios.post("/templates/generate-token", data);

const templateService = {
  getAll,
  create,
  update,
  remove,
  generateToken,
};

export default templateService;
