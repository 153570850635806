import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/lib/Shared/Button";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import AssignPermissionsSelection from "components/lib/Shared/CompanyDashboard/AssignPermission/Selection";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";
import {
  createPermission,
  editPermission,
  getPermissions,
  resetPermissionSlice,
} from "state/slices/permission";
import { useToast } from "hooks/useToast";

export default function SubRowAsync({ visibleColumns, row }) {
  const closeSubRowRef = useRef(null);
  const { company } = useSelector((state) => state.companyDashboardSlice);

  const { permissions, getPermissionsStatus } = useSelector(
    (state) => state.permissionSlice
  );

  const { toast } = useToast(5000);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPermissionSlice());
    dispatch(
      getPermissions({
        [CORPORATES.includes(row.original.idType) ? "corporate" : "individual"]:
          row.original._id,
        company: company._id,
        status: "Confirmed",
      })
    );
  }, []);

  if (getPermissionsStatus.status === "loading") {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto my-2 ">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  const grantedPermission = permissions?.[0] ?? {};

  const handleGrantAccess = async (values) => {
    try {
      await dispatch(
        createPermission({
          data: {
            ...values,
            [CORPORATES.includes(row.original.idType)
              ? "corporate"
              : "individual"]: row.original._id,
            company: company._id,
            status: "Confirmed",
          },
        })
      );
      closeSubRowRef.current.click();
      toast("success", "Allowed access successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  const handleUpdateAccess = async (values) => {
    try {
      await dispatch(
        editPermission({
          permissionId: grantedPermission._id,
          data: values,
        })
      );
      closeSubRowRef.current.click();
      toast("success", "Updated access successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <div className="flex justify-between items-center mt-2">
          {/* button to close sub row programmatically */}
          {row.isExpanded && (
            <Button
              ref={closeSubRowRef}
              variant="text"
              className="space-x-0 gap-0"
              onClick={() => row.getToggleRowExpandedProps()}
              {...row.getToggleRowExpandedProps()}
            ></Button>
          )}
        </div>
        <p className="text-gray-500 text-sm mb-1">Access</p>
        <hr />
        <AssignPermissionsSelection
          initialValues={{
            accessType: "indefinite",
            dashboardPermissions: [],
            ...grantedPermission,
          }}
          handleSubmit={(values) => {
            if (grantedPermission?._id) handleUpdateAccess(values);
            else handleGrantAccess(values);
          }}
          submitBtnLabel={
            grantedPermission?._id ? "Update Rights" : "Grant Rights"
          }
          loadingText={grantedPermission?._id ? "Updating..." : "Granting..."}
        />
      </td>
    </tr>
  );
}
