import React, { forwardRef } from "react";
import {
  Formik,
  Form,
  Field,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
import { object, string } from "yup";

import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import { Constants } from "config/constants";
import { useSelector } from "react-redux";
import CommentsList from "components/lib/Global/CommentsList";

const validationSchema = object().shape({
  directorsLimit: object().shape({
    minimum: string().required("Minimum value is required"),
    maximum: string().required("Minimum value is required"),
    for: string().required("Field is required"),
  }),
  directorMustBeShareholder: object().shape({
    value: string().required("Field is required"),
    for: string().required("Field is required"),
  }),
  directorMaxAgeLimit: object().shape({
    value: string()
      .required("Field is required")
      .test("len", "", function (value) {
        if (!/^(?:[0-9]+|N\/A)$/i.test(value)) {
          return this.createError({
            message: "Field can only contain numbers or 'N/A'",
          });
        }
        if (value !== undefined && value < 60) {
          return this.createError({
            message: "Value must be greater than or equal to 60",
          });
        }
        if (value !== undefined && value > 90) {
          return this.createError({
            message: "Value must be less than or equal to 90",
          });
        }
        return true;
      }),
    for: string().required("Field is required"),
  }),
  boardMeetingNoticePeriod: object().shape({
    value: string().required("Field is required"),
    for: string().required("Field is required"),
  }),
  directorsRequiredForQuorum: object().shape({
    value: string().required("Field is required"),
    for: string().required("Field is required"),
  }),
  resolutionApprovalSignatories: object().shape({
    value: string().required("Field is required"),
    for: string().required("Field is required"),
  }),
  rotationOfDirectors: object().shape({
    value: string().required("Field is required"),
    for: string().required("Field is required"),
  }),
  directorRetiresAtNextAGM: object().shape({
    value: string().required("Field is required"),
    for: string().required("Field is required"),
  }),
  noticePeriodForAGM: object().shape({
    value: string().required("Field is required"),
    for: string().required("Field is required"),
  }),
  membersRequiredForQuorum: object().shape({
    value: string()
      .transform((value) => (value ? value.replace(/[^0-9/]/g, "") : ""))
      .trim("Field cannot include leading and trailing spaces")
      .strict(true)
      .test("is-valid", "Field can only contain numbers and '/'", (value) =>
        /^[0-9/]+$/.test(value)
      ),
    for: string().required("Field is required"),
  }),
  companySealAffixationInPresence: object().shape({
    value: string(),
    for: string(),
  }),
  borrowingPowersForDirectors: object().shape({
    value: string().required("Field is required"),
    for: string().required("Field is required"),
  }),
  approvalRequirementForWrittenResolutions: object().shape({
    value: string().when("$entityType", {
      is: (entityType) => entityType === "PVT",
      then: string().required("Field is required"),
      otherwise: string().optional(),
    }),
    for: string().when("$entityType", {
      is: (entityType) => entityType === "PVT",
      then: string().required("Field is required"),
      otherwise: string().optional(),
    }),
  }),
  shareholdersRightOfFirstRefusal: object().shape({
    value: string().when("$entityType", {
      is: (entityType) => entityType === "PVT",
      then: string().required("Field is required"),
      otherwise: string().optional(),
    }),
    for: string().when("$entityType", {
      is: (entityType) => entityType === "PVT",
      then: string().required("Field is required"),
      otherwise: string().optional(),
    }),
  }),
});

const initialValues = {
  directorsLimit: {
    minimum: "",
    maximum: "",
    for: "",
  },
  directorMustBeShareholder: {
    value: "",
    for: "",
  },
  directorMaxAgeLimit: {
    value: "",
    for: "",
  },
  boardMeetingNoticePeriod: {
    value: "",
    for: "",
  },
  directorsRequiredForQuorum: {
    value: "",
    for: "",
  },
  resolutionApprovalSignatories: {
    value: "",
    for: "",
  },
  rotationOfDirectors: {
    value: "",
    for: "",
  },
  directorRetiresAtNextAGM: {
    value: "",
    for: "",
  },
  noticePeriodForAGM: {
    value: "",
    for: "",
  },
  membersRequiredForQuorum: {
    value: "",
    for: "",
  },
  approvalRequirementForWrittenResolutions: {
    value: "",
    for: "",
  },
  companySealAffixationInPresence: {
    value: "",
    for: "",
  },
  borrowingPowersForDirectors: {
    value: "",
    for: "",
  },
  shareholdersRightOfFirstRefusal: {
    value: "",
    for: "",
  },
};

const TemplateSummary = forwardRef(
  (
    {
      viewOnly,
      selectedVersion,
      comments = [],
      handleSaveSummary = () => null,
      children,
    },
    ref
  ) => {
    const { selectedTemplate } = useSelector((state) => state.templateSlice);
    return (
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          ...selectedVersion?.summary,
          comments,
        }}
        validate={(values) => {
          try {
            validateYupSchema(values, validationSchema, true, {
              entityType: selectedTemplate.entityType,
            });
          } catch (err) {
            return yupToFormErrors(err);
          }
          return {};
        }}
        onSubmit={handleSaveSummary}
        innerRef={ref}
      >
        {({ isSubmitting, handleSubmit }) => {
          return (
            <Form className="text-sm w-full mt-4">
              <div className="bg-white py-6 px-4 sm:p-6 shadow sm:rounded-md">
                <div className="flex justify-between font-medium text-gray-800">
                  <p className="">Summary of Articles</p>
                  <p>
                    {selectedTemplate.templateType === "Short"
                      ? "Model Articles apply"
                      : "Model Articles do not apply"}
                  </p>
                </div>
                <div className="mt-2 grid grid-cols-12 gap-6 border-t border-gray-200">
                  <div className="col-span-12 sm:col-span-12">
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          1. Directors
                          {comments["directorsLimit"]?.length > 0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>{comments["directorsLimit"]?.length}</span>
                            </span>
                          )}
                        </h3>
                        <div className="grid grid-cols-4 gap-6">
                          <Field
                            name="directorsLimit.minimum"
                            label="Minimum"
                            component={Input}
                            disabled={viewOnly}
                            placeholder="Enter minimum number"
                            type="number"
                            min={0}
                            max={1000}
                          />
                          <Field
                            name="directorsLimit.maximum"
                            label="Maximum"
                            component={Input}
                            disabled={viewOnly}
                            placeholder="Enter maximum number"
                            type="number"
                            min={0}
                            max={1000}
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="directorsLimit.for"
                          label="To complete e.g. Article 10."
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={comments["directorsLimit"] || []}
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          2. Share Qualifications
                          {comments["directorMustBeShareholder"]?.length >
                            0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {comments["directorMustBeShareholder"]?.length}
                              </span>
                            </span>
                          )}
                        </h3>
                        <Field
                          name="directorMustBeShareholder.value"
                          label="Is a Director required to be a Shareholder of the Company?"
                          component={ReactSelectWithFormik}
                          options={
                            Constants.TEMPLATES.DIRECTOR_MUST_BE_SHAREHOLDER
                          }
                          isDisabled={viewOnly}
                          placeholder="Enter share qualifications"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="directorMustBeShareholder.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={comments["directorMustBeShareholder"] || []}
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          3. Age Limit
                          {comments["directorMaxAgeLimit"]?.length > 0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {comments["directorMaxAgeLimit"]?.length}
                              </span>
                            </span>
                          )}
                        </h3>
                        <Field
                          name="directorMaxAgeLimit.value"
                          label="Is there a maximum age limit for a Director?, if not put N/A"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Enter age number"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="directorMaxAgeLimit.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={comments["directorMaxAgeLimit"] || []}
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          4. Period of notice for board meeting
                          {comments["boardMeetingNoticePeriod"]?.length > 0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {comments["boardMeetingNoticePeriod"]?.length}
                              </span>
                            </span>
                          )}
                        </h3>
                        <div className="">
                          <Field
                            name="boardMeetingNoticePeriod.value"
                            label="Consider whether clear days apply"
                            component={Input}
                            disabled={viewOnly}
                            placeholder="Enter period of notice"
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="boardMeetingNoticePeriod.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={comments["boardMeetingNoticePeriod"] || []}
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          5. Quorum for board meeting
                          {comments["directorsRequiredForQuorum"]?.length >
                            0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {comments["directorsRequiredForQuorum"]?.length}
                              </span>
                            </span>
                          )}
                        </h3>
                        <Field
                          name="directorsRequiredForQuorum.value"
                          label="Number of Directors required for a meeting to have quorum"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Enter number of quorum"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="directorsRequiredForQuorum.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={
                            comments["directorsRequiredForQuorum"] || []
                          }
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          6. Signed board resolution by
                          {comments["resolutionApprovalSignatories"]?.length >
                            0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {
                                  comments["resolutionApprovalSignatories"]
                                    ?.length
                                }
                              </span>
                            </span>
                          )}
                        </h3>
                        <Field
                          name="resolutionApprovalSignatories.value"
                          label="Indicate whether, all or majority is required to approve written resolutions."
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Enter threshold"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="resolutionApprovalSignatories.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={
                            comments["resolutionApprovalSignatories"] || []
                          }
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          7. Rotation of directors
                          {comments["rotationOfDirectors"]?.length > 0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {comments["rotationOfDirectors"]?.length}
                              </span>
                            </span>
                          )}
                        </h3>
                        <Field
                          name="rotationOfDirectors.value"
                          label="Indicate whether retirement by rotation applies."
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Enter rotation of directors details"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="rotationOfDirectors.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={comments["rotationOfDirectors"] || []}
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          8. Board to fill a Director casual vacancy
                          {comments["directorRetiresAtNextAGM"]?.length > 0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {comments["directorRetiresAtNextAGM"]?.length}
                              </span>
                            </span>
                          )}
                        </h3>
                        <Field
                          name="directorRetiresAtNextAGM.value"
                          label="Indicate whether new Director to retire at next AGM."
                          component={Input}
                          renderTextarea
                          rows={2}
                          disabled={viewOnly}
                          placeholder="Yes, retires at next AGM"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="directorRetiresAtNextAGM.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={comments["directorRetiresAtNextAGM"] || []}
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          9. Period of notice for Annual General Meetings
                          {comments["noticePeriodForAGM"]?.length > 0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {comments["noticePeriodForAGM"]?.length}
                              </span>
                            </span>
                          )}
                        </h3>
                        <Field
                          name="noticePeriodForAGM.value"
                          label="At least 21 days required. You may indicate a longer period if need be."
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Enter period of notice"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="noticePeriodForAGM.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={comments["noticePeriodForAGM"] || []}
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          10. Quorum for General meetings
                          {comments["membersRequiredForQuorum"]?.length > 0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {comments["membersRequiredForQuorum"]?.length}
                              </span>
                            </span>
                          )}
                        </h3>
                        <Field
                          name="membersRequiredForQuorum.value"
                          label="Indicate the number of members required for Quorum at General Meetings.."
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Enter number of members"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="membersRequiredForQuorum.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={comments["membersRequiredForQuorum"] || []}
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4 pt-4">
                        <div className="">
                          <Field
                            name="companySealAffixationInPresence.value"
                            label="11. Company Seal to be affixed, where applicable, in the presence of:"
                            component={Input}
                            disabled
                            placeholder="N/A"
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="companySealAffixationInPresence.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={
                            comments["companySealAffixationInPresence"] || []
                          }
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300 pr-4">
                        <h3 className="font-medium pt-4 relative w-fit">
                          12. Borrowing powers
                          {comments["borrowingPowersForDirectors"]?.length >
                            0 && (
                            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                              <span>
                                {
                                  comments["borrowingPowersForDirectors"]
                                    ?.length
                                }
                              </span>
                            </span>
                          )}
                        </h3>
                        <Field
                          name="borrowingPowersForDirectors.value"
                          label="Indicate whether the Directors have limited or unlimited borrowing powers."
                          options={
                            Constants.TEMPLATES.BORROWING_POWERS_FOR_DIRECTORS
                          }
                          component={ReactSelectWithFormik}
                          isDisabled={viewOnly}
                          placeholder="Select borrowing power"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          name="borrowingPowersForDirectors.for"
                          component={Input}
                          disabled={viewOnly}
                          placeholder="Add details"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 items-end">
                      <div className="col-span-2 border-r border-gray-300">
                        <CommentsList
                          comments={
                            comments["borrowingPowersForDirectors"] || []
                          }
                          color={"text-red-500"}
                        />
                      </div>
                    </div>
                    {selectedTemplate.entityType === "PVT" && (
                      <>
                        <div className="grid grid-cols-3 gap-4 items-end">
                          <div className="col-span-2 border-r border-gray-300 pr-4">
                            <h3 className="font-medium pt-4 relative w-fit">
                              13. Signed member resolution by
                              {comments[
                                "approvalRequirementForWrittenResolutions"
                              ]?.length > 0 && (
                                <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                                  <span>
                                    {
                                      comments[
                                        "approvalRequirementForWrittenResolutions"
                                      ]?.length
                                    }
                                  </span>
                                </span>
                              )}
                            </h3>
                            <Field
                              name="approvalRequirementForWrittenResolutions.value"
                              label="Indicate whether, all or majority is required to approve written resolutions."
                              component={Input}
                              disabled={viewOnly}
                              placeholder="Enter minimum number"
                            />
                          </div>
                          <div className="col-span-1">
                            <Field
                              name="approvalRequirementForWrittenResolutions.for"
                              component={Input}
                              disabled={viewOnly}
                              placeholder="Add details"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4 items-end">
                          <div className="col-span-2 border-r border-gray-300">
                            <CommentsList
                              comments={
                                comments[
                                  "approvalRequirementForWrittenResolutions"
                                ] || []
                              }
                              color={"text-red-500"}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4 items-end">
                          <div className="col-span-2 border-r border-gray-300 pr-4">
                            <h3 className="font-medium pt-4 relative w-fit">
                              14. Pre-emption Rights applicable
                              {comments["shareholdersRightOfFirstRefusal"]
                                ?.length > 0 && (
                                <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full top-1 -right-5">
                                  <span>
                                    {
                                      comments[
                                        "shareholdersRightOfFirstRefusal"
                                      ]?.length
                                    }
                                  </span>
                                </span>
                              )}
                            </h3>
                            <div className="grid grid-cols-2 gap-6">
                              <Field
                                name="shareholdersRightOfFirstRefusal.value"
                                label="Indicate whether the shareholders have the right of first refusal on transfers and allotment of shares."
                                options={
                                  Constants.TEMPLATES
                                    .SHAREHOLDER_RIGHT_OF_FIRST_REFUSAL
                                }
                                component={ReactSelectWithFormik}
                                isDisabled={viewOnly}
                                placeholder="Select"
                              />
                            </div>
                          </div>
                          <div className="col-span-1">
                            <Field
                              name="shareholdersRightOfFirstRefusal.for"
                              component={Input}
                              disabled={viewOnly}
                              placeholder="Add details"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4 items-end">
                          <div className="col-span-2 border-r border-gray-300">
                            <CommentsList
                              comments={
                                comments["shareholdersRightOfFirstRefusal"] ||
                                []
                              }
                              color={"text-red-500"}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {children ? children({ isSubmitting, handleSubmit }) : null}
            </Form>
          );
        }}
      </Formik>
    );
  }
);

export default TemplateSummary;
