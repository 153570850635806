import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import ReactTable from "components/lib/Global/ReactTable";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import { deleteJointShareholder } from "state/slices/tables/jointShareholderTable";
import {
  resetSharesTable,
  setShares,
} from "state/slices/tables/sharesAllocationTable";
import {
  deleteAccessRequest,
  getAccessRequests,
} from "state/slices/accessRequest";
import { deleteCompanyOfficialAsync } from "state/slices/tables/companyOfficialsSlice";
import { getFullName, getMaskedEmail } from "utils";
import AvatarIcon from "components/lib/Shared/Icons/AvatarIcon";
import { useJointShareholders } from "hooks/useJointShareholders";
import { Constants } from "config/constants";
import SubRowAsync from "../../CompanyOfficialsTable/SubRowAsync";

const JointShareholderTable = (props) => {
  const accessRequests = useSelector(
    (state) => state.accessRequestsSlice.items
  );
  const { company } = useSelector((state) => state.companySlice);
  const currentUser = useSelector((state) => state.userSlice);
  const params = useParams();
  const companyId = company?._id ?? params?.companyId;

  const { jointShareholders } = useSelector(
    (state) => state.jointShareholderTableSlice
  );

  const originalCompanyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );

  const { syncBeneficialOwnersWithJointShareholders } = useJointShareholders();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAccessRequests({
        query: { initiatedToEmail: currentUser.electronicAddress.emailAddress },
      })
    );
  }, [currentUser.electronicAddress.emailAddress, dispatch]);

  const handleEdit = (row) => {
    const isFirstRow =
      jointShareholders.findIndex(
        (jointShareholder) => jointShareholder._id === row._id
      ) === 0;
    dispatch(setShares(row?.sharesAllotted ?? []));
    props.handleEdit(row, isFirstRow);
  };

  const handleDelete = async (row, syncChangesToBOTab = true) => {
    const accessRequest = accessRequests.find(
      (request) => request.initiatedToEmail === row.initiatedToEmail
    );
    if (
      row?.type === "invited" &&
      row?.inviteStatus === "Awaiting Data" &&
      accessRequest?._id
    ) {
      dispatch(deleteAccessRequest({ accessRequestsId: accessRequest?._id }));
    }
    if (row?._id) {
      await dispatch(
        deleteCompanyOfficialAsync({
          officialId: row._id,
          idType: row.idType,
          companyId,
        })
      );
    }
    dispatch(deleteJointShareholder(row));
    if (row?.sharesAllotted) dispatch(resetSharesTable);
    if (syncChangesToBOTab) syncBeneficialOwnersWithJointShareholders();
  };

  const handleReview = async (row) => {
    row?.getToggleRowExpandedProps()?.onClick();
  };

  const handleRecallAccess = async (row) => {
    const accessRequest = accessRequests.find(
      (request) => request.initiatedToEmail === row.initiatedToEmail
    );
    if (window.confirm("are you sure you want to recall this request?")) {
      if (
        row?.type === "invited" &&
        row?.inviteStatus === "Awaiting Data" &&
        accessRequest?._id
      ) {
        await dispatch(
          deleteAccessRequest({ accessRequestsId: accessRequest._id })
        );
      }
      dispatch(
        deleteCompanyOfficialAsync({
          officialId: row._id,
          idType: row.idType,
          companyId,
        })
      );
      dispatch(deleteJointShareholder(row));
    }
    // const accessRequest = accessRequests.find(request => request.initiatedToEmail === row.initiatedToEmail)
    // try {
    //   await dispatch(editAccessRequest({ accessRequestsId: accessRequest?._id, data: { status: "Recalled", recalledAt: new Date() } }))
    //   toast('success', 'Request recalled successfully.');
    // } catch (error) {
    //   toast('error', error?.message ?? 'Something went wrong please try again.');
    // }
  };

  const handleDeleteAllJointShareholders = async () => {
    await Promise.all(
      jointShareholders.map((jointShareholder) =>
        handleDelete(jointShareholder, false)
      )
    );
    syncBeneficialOwnersWithJointShareholders();
  };

  const columns = [
    {
      Header: "Full Name",
      accessor: "fullName",
      className: "p-4 text-ellipsis overflow-hidden max-w-[150px]",
      Cell: ({ row: { original } }) => {
        const { names, fileUploads, electronicAddress, initiatedToEmail } =
          original;
        const fullName = getFullName(names);
        const emailAddress =
          electronicAddress?.emailAddress ?? initiatedToEmail ?? "";
        return (
          <div className="flex items-center space-x-1">
            <div className={`flex-shrink-0`}>
              {fileUploads?.passportPhoto ? (
                <img
                  className="w-8 h-8 rounded-full"
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${fileUploads?.passportPhoto}`}
                  alt={fullName}
                />
              ) : (
                <div className="w-8 h-8 rounded-full">
                  <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <AvatarIcon />
                  </div>
                </div>
              )}
            </div>
            <div className="px-3">
              <h1 className="font-bold">{fullName}</h1>
              <p>
                {original.type === "invited"
                  ? getMaskedEmail(emailAddress ?? "")
                  : emailAddress}
              </p>
            </div>
          </div>
        );
      },
    },
    { Header: "ID Type", accessor: "idType" },
    { Header: "Is a Director?", accessor: "isDirector" },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const isFirstRow =
          jointShareholders.findIndex(
            (jointShareholder) => jointShareholder._id === row.original._id
          ) === 0;

        const isOwnerRow = [
          row.original?.electronicAddress?.emailAddress,
          row.original?.identificationDetails?.emailAddress,
          row.original?.identificationDetails?.guardianEmailAddress,
        ].includes(currentUser.electronicAddress.emailAddress);

        const disableEditBtn =
          (row.original?.type === "invited" ||
            role === Constants.COOKIE_ROLES.OFFICIAL) &&
          !isOwnerRow;

        return (
          <TableActionMenu
            row={row}
            showReviewBtn={
              !isOwnerRow &&
              row.original?.type === "invited" &&
              row.original.createdBy === currentUser._id
            }
            handleEdit={(row) =>
              handleEdit({
                ...row,
                isViewOnly: disableEditBtn,
              })
            }
            showRecallBtn={
              row.original?.type &&
              row.original?.type === "invited" &&
              row.original?.inviteStatus === "Awaiting Data" &&
              !isOwnerRow &&
              row.original.createdBy === currentUser._id
            }
            handleRecall={handleRecallAccess}
            handleDelete={
              isFirstRow ? handleDeleteAllJointShareholders : handleDelete
            }
            handleReview={handleReview}
            deleteConfirmationLabel={
              isFirstRow
                ? "This entry is the senior JointShareholder, its deletion will remove all the JointShareholders."
                : "Are you sure you want to permanently delete this entry?"
            }
          />
        );
      },
    },
  ];

  // Create a function that will render our row sub components
  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <SubRowAsync
          row={{
            ...row,
            original: originalCompanyOfficials.find(
              (official) => official._id === row.original._id
            ),
          }}
          officialIndex={originalCompanyOfficials.findIndex(
            (official) => official._id === row.original._id
          )}
          syncChangesToBOTab
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          currentTab={"stakeholderTab"}
        />
      );
    },
    [originalCompanyOfficials]
  );

  return (
    <>
      <h2 className="mb-2 text-lg font-medium leading-6 text-gray-900">
        Joint Shareholder Appointments:
      </h2>
      <div className="w-full bg-white shadow">
        <div className="w-full overflow-x-auto">
          <ReactTable
            columns={columns}
            data={jointShareholders}
            headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
            headingStyle="pl-4 text-left"
            dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            renderRowSubComponent={renderRowSubComponent}
          />
        </div>
      </div>
    </>
  );
};

export default JointShareholderTable;
