import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon } from "@heroicons/react/solid";
import { Formik, Field, Form, useFormikContext } from "formik";
import { useDebounce } from "use-debounce";
import { ExclamationIcon } from "@heroicons/react/outline";
import _merge from "lodash/merge";

import ValidationError from "../ValidationError";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { useToast } from "hooks/useToast";
import { verifyBtnParams } from "./verifyBtnParams";
import Button from "../Button";
import { resetVerifyStatus, verify } from "state/slices/verifyInvite";

const VerifyUserOnboarding = ({
  idType = "Kenyan Citizen",
  autoComplete = "off",
  verificationValue,
  isIdAvailable = false,
  setIsIdAvailable = () => null,
  setVerifyingValue = () => null,
  setFieldValue = () => null,
  setDisableForm = () => null,
  resetForm = () => null,
}) => {
  const {
    name,
    label,
    placeholder,
    type = "string",
    validationSchema = null,
  } = verifyBtnParams[idType];

  const isFirstRender = useRef(true);
  const [verifyError, setVerifyError] = useState(null);

  const formikProps = useFormikContext();

  const { verifyStatus } = useSelector((state) => state.verifyInviteSlice);
  const dispatch = useDispatch();
  const { toast } = useToast(5000);

  const populateFormData = async (official) => {
    // official can be a user or a corporate document
    await Promise.all([
      setFieldValue("names", official?.names ?? {}),
      setFieldValue(
        "identificationDetails",
        official?.identificationDetails ?? {}
      ),
      setFieldValue("electronicAddress", official?.electronicAddress ?? {}),
      setFieldValue(
        "fileUploads",
        _merge(official?.fileUploads ?? {}, formikProps.values.fileUploads)
      ),
      setFieldValue("primarySource", official.primarySource),
    ]);

    if (official?.isNonResident) {
      setFieldValue("isNonResident", official.isNonResident);
    }
  };

  const handleVerifyResponse = async (response) => {
    if (response.payload.populateData) {
      await populateFormData(response.payload?.official ?? {});
    } else if (response?.payload?.idAvailable) {
      setDisableForm(false);
      setIsIdAvailable(false);
    } else {
      setIsIdAvailable(true);
      setVerifyError(
        response?.payload?.message ?? "Something went wrong please try again."
      );
    }
    setFieldValue(
      `identificationDetails.${name}`,
      typeof verificationValue === "string"
        ? verificationValue?.trim()
        : verificationValue ?? {}
    );
  };

  const handleVerify = async () => {
    setVerifyError(null);
    setIsIdAvailable(false);
    resetForm();
    try {
      const response = await dispatch(
        verify({
          data: {
            isOnboardingStage: true,
            verificationKey: name,
            verificationValue:
              typeof verificationValue === "string"
                ? verificationValue?.trim()
                : verificationValue,
          },
        })
      );
      await handleVerifyResponse(response);
    } catch (error) {
      console.log({ error });
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
    }
  };

  const handleResetVerification = () => {
    setIsIdAvailable(null);
    setDisableForm(true);
    resetForm();
  };

  useEffect(() => {
    return () => {
      dispatch(resetVerifyStatus());
    };
  }, []);

  return (
    <div>
      <div>
        <Formik
          enableReinitialize
          initialValues={{ [name]: verificationValue }}
          validationSchema={validationSchema}
          onSubmit={handleVerify}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            ...verificationFormProps
          }) => {
            useEffect(() => {
              const value =
                type === "number" ? parseInt(values[name]) : values[name];
              setVerifyingValue(value);
            }, [values[name], type]);

            const handleKeyDown = (e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                handleSubmit();
              }
            };

            const handleInputChange = (e) => {
              dispatch(resetVerifyStatus());
              verificationFormProps.setFieldValue(name, e.target.value);
            };

            const [debouncedVerificationValue] = useDebounce(
              values[name],
              1000
            );

            // verify verificationValue asynchronously
            useEffect(() => {
              if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
              }
              const verifyDebouncedValue = async () => {
                const isValid = await validationSchema.isValid({
                  [name]: debouncedVerificationValue,
                });
                if (isValid) {
                  handleVerify();
                }
              };
              if (!verifyStatus.loaded) verifyDebouncedValue();
            }, [debouncedVerificationValue]);

            return (
              <Form onKeyDown={handleKeyDown}>
                <div className="col-span-6 sm:col-span-3 ">
                  <label
                    htmlFor={`${name}_verify_btn`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {label}
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <Field
                      type={type}
                      name={name}
                      id={`${name}_verify_btn`}
                      autoComplete={autoComplete}
                      className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                      placeholder={placeholder}
                      disabled={
                        isIdAvailable === false ||
                        verifyStatus.status === "loading"
                      }
                      onChange={handleInputChange}
                    />
                    {verifyStatus.status === "loading" && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <SpinnerIcon className="text-gray-400" />
                      </div>
                    )}
                    {isIdAvailable !== null && debouncedVerificationValue && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 z-[29]">
                        <button type="button" onClick={handleResetVerification}>
                          <PencilIcon className="w-4 h-4 cursor-pointer text-light-blue" />
                        </button>
                      </div>
                    )}
                  </div>
                  <ValidationError
                    errors={errors}
                    touched={{ ...touched, [name]: true }}
                    name={name}
                  />
                </div>

                <div className="flex justify-between my-4">
                  {isIdAvailable && (
                    <Button
                      disabled={
                        !(typeof values[name] === "string"
                          ? values[name]?.trim()
                          : values[name]) || verifyStatus.status === "loading"
                      }
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                      loadingText="Verifying..."
                    >
                      Invite
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
        {verifyError && (
          <div className="flex flex-col my-4 space-y-2 items-between">
            <div className="text-red-500 flex items-start py-2">
              <ExclamationIcon className="w-5 h-5 mr-1" />
              <p className="block text-sm font-medium">{verifyError}</p>
            </div>
          </div>
        )}
      </div>
      <hr />
    </div>
  );
};

export default VerifyUserOnboarding;
