import { useEffect } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getFullName, getGreetings } from "utils";

function SystemAdminWelcomeScreen() {
  const history = useHistory();
  const currentUser = useSelector((state) => state.userSlice);
  const { names } = currentUser || {};

  useEffect(() => {
    if (currentUser.organizations.length > 0) {
      history.push("/dashboard");
    }
  }, [currentUser.organizations.length]);

  return (
    <>
      <div className="flex justify-between mb-10 border-b border-slate-200">
        <h2 className="font-semibold text-title5">
          {getGreetings()}, {getFullName(names)}
        </h2>
      </div>
      <div className="relative flex flex-col items-center justify-center p-32 text-center">
        <svg
          viewBox="0 0 300 100"
          preserveAspectRatio="none"
          className="absolute top-0 left-0 w-full h-full"
        >
          <path
            d="M0,0 300,0 300,100 0,100z"
            className="stroke-2 stroke-sflPrimary fill-transparent [stroke-dasharray:8]"
            vectorEffect="non-scaling-stroke"
          />
        </svg>
        <div className="absolute flex flex-col items-center justify-center p-10">
          <h2 className="font-semibold text-title5">
            Welcome to your Dashboard
          </h2>
          <h6 className="">
            Would you like to set up your first organization?
          </h6>
          <button
            type="button"
            onClick={() => history.push("/organizations")}
            className="inline-flex justify-center px-4 py-2 mt-4 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sflPrimary hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sflPrimary"
          >
            <PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            Get Started
          </button>
        </div>
      </div>
    </>
  );
}

export default SystemAdminWelcomeScreen;
