import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organizationName: "",
  dashboardUrl: "",
  breadCrumbs: [],
};

const activeDashboardSlice = createSlice({
  name: "activeDashboardSlice",
  initialState,
  reducers: {
    updateActiveDashboardState: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetActiveDashboardSlice: () => initialState,
  },
  extraReducers: {},
});

export const { updateActiveDashboardState, resetActiveDashboardSlice } =
  activeDashboardSlice.actions;

const { reducer } = activeDashboardSlice;

export default reducer;
