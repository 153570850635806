import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

import CommentsList from "components/lib/Global/CommentsList";
import { Constants } from "config/constants";
import { ExclamationIcon } from "@heroicons/react/outline";

export default function Status({
  submitToLabel,
  setDisableSubmitBtn,
  agreeToSubmit,
  agreeToTerms,
  setAgreeToSubmit,
  setAgreeToTerms,
}) {
  const { comments } = useSelector((state) => state.commentsSlice);
  const unresolvedComments = comments.filter((comment) => !comment.isResolved);

  const isReviewer = Cookies.get("role") === Constants.COOKIE_ROLES.REVIEWER;
  const isAdmin = [
    Constants.COOKIE_ROLES.ORG_ADMIN,
    Constants.COOKIE_ROLES.SUPER_ADMIN,
  ].includes(Cookies.get("role"));

  useEffect(() => {
    if (agreeToSubmit && agreeToTerms) setDisableSubmitBtn(false);
    else setDisableSubmitBtn(true);
  }, [agreeToSubmit, agreeToTerms]);

  return (
    <>
      {unresolvedComments.length > 0 && (
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                You can submit the application to{" "}
                {isReviewer ? "admin" : isAdmin ? `BRS` : ""} by resolving the
                remaining({unresolvedComments.length}){" "}
                {unresolvedComments.length === 1 ? "comment" : "comments"}.{" "}
              </p>
            </div>
          </div>
        </div>
      )}
      {comments.length > 0 && (
        <div className="px-8 pt-5 pb-6 mb-10 bg-white mt-6">
          <CommentsList comments={comments} toggleCommentStatus paginate />
        </div>
      )}
      <div className="flex flex-col space-y-2 align-middle mt-6">
        <div className="flex items-center form-check">
          <input
            className="float-left mt-1 mr-4 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border rounded appearance-none cursor-pointer form-check-input border-tertiary checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
            type="checkbox"
            id="flexCheckDefault"
            defaultChecked={agreeToSubmit}
            onChange={({ target: { checked } }) => setAgreeToSubmit(checked)}
          />
          <label
            className="inline-block form-check-label text-tertiary"
            htmlFor="flexCheckDefault"
          >
            Are you sure you want to submit the following details to the{" "}
            {submitToLabel}
            <sup className="text-red-500">*</sup>
          </label>
        </div>
        <div className="flex items-center form-check">
          <input
            className="float-left mt-1 mr-4 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border rounded appearance-none cursor-pointer form-check-input border-tertiary checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
            type="checkbox"
            id="agreeToTerms"
            defaultChecked={agreeToTerms}
            onChange={({ target: { checked } }) => setAgreeToTerms(checked)}
          />
          <label
            className="inline-block form-check-label text-tertiary"
            htmlFor="agreeToTerms"
          >
            I agree to the terms and conditions
            <sup className="text-red-500">*</sup>
          </label>
        </div>
      </div>
    </>
  );
}
