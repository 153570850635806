import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import ReactTooltip from "react-tooltip";

import useWidth from "hooks/useWidth";
import { classNames } from "utils";

const Tabs = ({
  tabs,
  selectedTabIndex,
  showTooltip,
  PostPendComponent,
  showBorderBottom = true,
  ...restProps
}) => {
  const setSelectedTabIndex = restProps.setSelectedTabIndex
    ? restProps.setSelectedTabIndex
    : () => null;
  const labelRef = useRef(null);
  const [width] = useWidth();

  // State for tracking if ellipsis is active
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useLayoutEffect(() => {
    if (labelRef?.current?.offsetWidth < labelRef?.current?.scrollWidth) {
      setIsEllipsisActive(true);
    } else setIsEllipsisActive(false);
  }, [width, labelRef, tabs]);

  // rebuild tooltip if one of these dependencies change
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tabs, showTooltip, isEllipsisActive]);

  if (tabs.length === 0) return "";

  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          defaultValue={selectedTabIndex}
          onClick={({ target: { value } }) =>
            setSelectedTabIndex(tabs.findIndex((elem) => elem?.id === value))
          }
        >
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.id}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div
          className={`${showBorderBottom ? "border-b border-gray-200" : ""}`}
        >
          <nav className="flex pt-4 -mb-px space-x-8" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <a
                key={tab.id}
                className={classNames(
                  index === selectedTabIndex
                    ? "border-sflPrimary text-sflPrimary relative"
                    : `relative border-transparent text-gray-500 ${
                        tab.disabled
                          ? "cursor-not-allowed"
                          : "hover:text-gray-700 hover:border-gray-300"
                      } `,
                  `${
                    restProps.setSelectedTabIndex ? "cursor-pointer" : ""
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm text-ellipsis overflow-hidden`
                )}
                onClick={() =>
                  tab.disabled ? null : setSelectedTabIndex(index)
                }
                aria-current={index === selectedTabIndex ? "page" : undefined}
                data-tip={tab.label}
                data-type="dark"
                data-for="tab-tip"
                ref={labelRef}
              >
                {tab.label}
                {tab?.commentsCount > 0 && (
                  <span className="absolute top-0 right-0 flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full">
                    <span>{tab.commentsCount}</span>
                  </span>
                )}
              </a>
            ))}
            {showTooltip && isEllipsisActive && (
              <ReactTooltip
                effect="solid"
                id="tab-tip"
                delayHide={500}
                delayShow={500}
              />
            )}
            {PostPendComponent}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Tabs;
