import { Constants } from "config/constants";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  resetActiveDashboardSlice,
  updateActiveDashboardState,
} from "state/slices/activeDashboard";
import { setCookie } from "utils/cookie";

function RoleSwitch({
  setCookieRole = () => null,
  setSelectedCompanyProcess = () => null,
  organizationName,
  dashboardUrl,
  breadCrumbs,
}) {
  const [role, setRole] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (!Cookies.get("role")) {
      setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
      setCookieRole(Constants.COOKIE_ROLES.APPLICANT);
      setRole(Constants.COOKIE_ROLES.APPLICANT);
      history.push("/");
    } else {
      setCookieRole(Cookies.get("role"));
      setRole(Cookies.get("role"));
    }
  }, [currentUser?._id]);

  const changeAdminView = (e) => {
    const newRole = e.target.value;
    setCookie("role", newRole);
    setCookieRole(newRole);
    setRole(newRole);
    setSelectedCompanyProcess(null);
    if (organizationName && dashboardUrl) {
      dispatch(
        updateActiveDashboardState({
          organizationName,
          dashboardUrl,
          breadCrumbs,
        })
      );
    }
    if (newRole === Constants.COOKIE_ROLES.APPLICANT) {
      dispatch(resetActiveDashboardSlice());
      history.push(dashboardUrl);
    } else {
      history.push("/");
    }
  };

  // if current user is not admin return null
  if (![9, 13].includes(currentUser?.role)) return null;

  return (
    <>
      {role === Constants.COOKIE_ROLES.APPLICANT &&
        [Constants.ROLES.SUPER_ADMIN, Constants.ROLES.ORG_ADMIN].includes(
          currentUser?.role
        ) && (
          <div className="flex flex-col items-start min-w-[15rem]">
            <SignedInAsHeader label="Standard User" />
            <RadioGroupWrapper>
              <label className="flex space-x-2 justify-center items-center radio-inline cursor-pointer">
                <input
                  type="radio"
                  name="optradio"
                  value={Constants.COOKIE_ROLES.ORG_ADMIN}
                  onChange={changeAdminView}
                />
                <span>Org Admin</span>
              </label>
            </RadioGroupWrapper>
          </div>
        )}
      {role === Constants.COOKIE_ROLES.ORG_ADMIN && (
        <div className="flex flex-col items-start min-w-[15rem]">
          <SignedInAsHeader label="Org Admin" />
          <RadioGroupWrapper>
            <label className="flex space-x-2 justify-center items-center radio-inline cursor-pointer">
              <input
                type="radio"
                name="optradio"
                value={Constants.COOKIE_ROLES.APPLICANT}
                onChange={changeAdminView}
              />
              <span>Standard User</span>
            </label>
            <label className="flex space-x-2 justify-center items-center radio-inline cursor-pointer">
              <input
                type="radio"
                name="optradio"
                value={Constants.COOKIE_ROLES.SUPER_ADMIN}
                onChange={changeAdminView}
              />
              <span>Super Admin</span>
            </label>
          </RadioGroupWrapper>
        </div>
      )}
      {role === Constants.COOKIE_ROLES.SUPER_ADMIN && (
        <div className="flex flex-col items-start min-w-[15rem]">
          <SignedInAsHeader label="Super Admin" />
          <RadioGroupWrapper>
            <label className="flex space-x-2 justify-center items-center radio-inline cursor-pointer">
              <input
                type="radio"
                name="optradio"
                value={Constants.COOKIE_ROLES.ORG_ADMIN}
                onChange={changeAdminView}
              />
              <span>Org Admin</span>
            </label>
          </RadioGroupWrapper>
        </div>
      )}
    </>
  );
}

const RadioGroupWrapper = ({ children }) => (
  <div className="flex flex-col w-full justify-center px-4 py-1 text-sm font-medium border border-transparent rounded-md shadow-sm bg-[#F2F2F2] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
    <div className="flex items-baseline">
      <label className="text-sm font-medium text-gray-800">
        Switch view to
      </label>
    </div>
    <hr className="w-full border-gray-300 my-1" />
    <div className="flex space-x-2">{children}</div>
  </div>
);

const SignedInAsHeader = ({ label }) => (
  <p className="w-full mb-1 flex justify-between items-center">
    <span className="text-sm font-medium text-primary">Signed in as:</span>
    <b className="text-sm text-white bg-primary px-4 py-1 rounded-md">
      {label}
    </b>
  </p>
);

export default RoleSwitch;
