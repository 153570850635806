import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "@heroicons/react/outline";
import { PencilAltIcon } from "@heroicons/react/solid";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";
import _has from "lodash/has";
import { useDebounce } from "use-debounce";

import Button from "components/lib/Shared/Button";
import useFullscreenToggle from "hooks/useFullscreenToggle";
import PaginationControls from "./PaginationControls";
import {
  downloadRegistersOfDirectorsAndSecretariesResidencesAsync,
  getRegistersOfDirectorsAndSecretariesResidencesAsync,
} from "state/slices/minuteBook";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import {
  getEmptyRowsForRegisters,
  getFullName,
  getFullResidentialAddress,
  getVerifyId,
} from "utils";
import { Constants } from "config/constants";
import SearchInput from "components/lib/Shared/SearchInput";
import { getPaginationParams } from "utils/registers";
import BreadCrumbs from "./BreadCrumbs";

const { ROW_HEIGHT, DIRECTORS_ROWS, SECRETARIES_ROWS } = Constants.REGISTERS;
const MAX_DIRECTORS_SECTION_HEIGHT =
  ROW_HEIGHT * DIRECTORS_ROWS + (DIRECTORS_ROWS - 2) * 2;
const MAX_SECRETARIES_SECTION_HEIGHT =
  ROW_HEIGHT * SECRETARIES_ROWS + (SECRETARIES_ROWS - 2) * 2;

const DirectorsTableHeading = () => {
  return (
    <thead>
      <tr className="text-xs">
        {[
          {
            label: "Name. {Present first name(s) and surname(s)}",
            className: "text-left w-32",
          },
          {
            label: "Any former first name(s) and surname(s).",
            className: "w-24",
          },
          {
            label: "Telephone Number (to include country code)",
            className: "w-32",
          },
          {
            label:
              "USUAL RESIDENTIAL ADDRESS (to include plot/house number, street name and number, county, district and locality, etc)",
            className: "text-left w-64",
          },
          {
            label: "E-mail address",
            className: "w-64",
          },
          {
            label: "National Identity/Passport Number",
            className: "w-64",
          },
          {
            label: "DATE OF APPOINTMENT",
            className: "w-20",
          },
          {
            label: "DATE OF CESSATION",
            className: "w-20",
          },
        ].map((heading) => {
          return (
            <th
              key={heading.label}
              scope="col"
              className={twMerge(
                "font-semibold text-gray-900 bg-gray-100",
                heading.className
              )}
            >
              {heading.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const SecretariesTableHeading = () => {
  return (
    <thead>
      <tr className="text-xs">
        {[
          {
            label:
              "NAME. (In the case of an individual, present first name or names and surname (2). In the case of a corporation, the corporate name.)",
            className: "text-left w-32",
          },
          {
            label: "Any former first name(s) and surname(s).",
            className: "w-24",
          },
          {
            label: "Telephone Number (to include country code)",
            className: "w-32",
          },
          {
            label:
              "USUAL RESIDENTIAL ADDRESS (In the case of a corporation the registered or principal office.)",
            className: "text-left w-64",
          },
          {
            label: "E-mail address",
            className: "w-64",
          },
          {
            label: "National Identity/Passport Number",
            className: "w-64",
          },
          {
            label: "DATE OF APPOINTMENT",
            className: "w-20",
          },
          {
            label: "DATE OF CESSATION",
            className: "w-20",
          },
        ].map((heading) => {
          return (
            <th
              key={heading.label}
              scope="col"
              className={twMerge(
                "font-semibold text-gray-900 bg-gray-100",
                heading.className
              )}
            >
              {heading.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const PopulatedDirectorAndSecretaryRow = ({ official }) => {
  return (
    <tr className="text-xs">
      <td className="p-1 text-gray-600 bg-gray-100 text-left w-32">
        {getFullName(official.names)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-24">
        {official.names.formerName}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-32">
        {official.electronicAddress.mobileNumber}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 text-left w-64">
        {getFullResidentialAddress(official.residentialAddressDetails)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-64">
        {official.electronicAddress.emailAddress}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-64">
        {getVerifyId(
          official.idType,
          official.identificationDetails,
          official.names
        )}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-20">
        {official?.appointmentDate
          ? format(new Date(official.appointmentDate), "dd-MM-yyyy")
          : ""}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-20">
        {official?.cessationDate
          ? format(new Date(official.cessationDate), "dd-MM-yyyy")
          : ""}
      </td>
    </tr>
  );
};

const EmptyRow = () => {
  return (
    <tr className="text-xs h-6">
      <td className="p-1 text-gray-600 bg-gray-100 text-left"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100 text-left"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
    </tr>
  );
};

const getPopulatedRowNode = (official) => {
  if (!official) return null;
  const currentTableRowNode = document.createElement("tr");
  currentTableRowNode.setAttribute("class", "text-xs");
  currentTableRowNode.innerHTML = `<td class="p-1 text-gray-600 bg-gray-100 text-left w-32">
  ${getFullName(official.names)}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-24">
  ${official.names.formerName}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-32">
  ${official.electronicAddress.mobileNumber}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 text-left w-64">
  ${getFullResidentialAddress(official.residentialAddressDetails)}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-64">
  ${official.electronicAddress.emailAddress}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-64">
  ${getVerifyId(
    official.idType,
    official.identificationDetails,
    official.names
  )}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-20">
  ${
    official?.appointmentDate
      ? format(new Date(official.appointmentDate), "dd-MM-yyyy")
      : ""
  }
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-20">
  ${
    official?.cessationDate
      ? format(new Date(official.cessationDate), "dd-MM-yyyy")
      : ""
  }
  </td>
  `;
  return currentTableRowNode;
};

function RegisterOfDirectorsAndSecretariesResidences({ selectedTab }) {
  const { isFullscreen, ToggleFullscreenContainer, ToggleFullscreenButton } =
    useFullscreenToggle();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const directorsTableBodyRef = useRef(null);
  const secretariesTableBodyRef = useRef(null);
  const hiddenTempTableBodyRef = document.getElementById(
    "tempTableForRegisters"
  );
  const [paginatedDirectors, setPaginatedDirectors] = useState([]);
  const [emptyRowsCountForDirectors, setEmptyRowsCountDirectors] = useState(0);
  const [emptyRowsCountForSecretaries, setEmptyRowsCountSecretaries] =
    useState(0);
  const [paginatedSecretaries, setPaginatedSecretaries] = useState([]);
  const [rowsPerPageForDirectors, setRowsPerPageForDirectors] = useState({});
  const [rowsPerPageForSecretaries, setRowsPerPageForSecretaries] = useState(
    {}
  );
  const [directors, setDirectors] = useState({ items: [], totalCount: 0 });
  const [secretaries, setSecretaries] = useState({ items: [], totalCount: 0 });
  const [directorsLastPageWithData, setDirectorsLastPageWithData] = useState(1);
  const [secretariesLastPageWithData, setSecretariesLastPageWithData] =
    useState(1);

  const { company } = useSelector((state) => state.companyDashboardSlice);
  const {
    registersOfDirectorsAndSecretariesResidences,
    getRegistersOfDirectorsAndSecretariesResidences,
    downloadRegistersOfDirectorsAndSecretariesResidences,
  } = useSelector((state) => state.minuteBookSlice);

  useEffect(() => {
    if (company._id) {
      dispatch(
        getRegistersOfDirectorsAndSecretariesResidencesAsync({
          queryParams: { companyId: company._id },
        })
      );
    }
  }, [company._id]);

  useEffect(() => {
    let tempDirectors =
      registersOfDirectorsAndSecretariesResidences.directors.items;
    let tempSecretaries =
      registersOfDirectorsAndSecretariesResidences.secretaries.items;
    const searchQuery = debouncedSearchTerm.toLowerCase();
    if (searchQuery) {
      tempDirectors = tempDirectors.filter((director) =>
        getFullName(director.names).toLowerCase().includes(searchQuery)
      );
      tempSecretaries = tempSecretaries.filter((secretary) =>
        getFullName(secretary.names).toLowerCase().includes(searchQuery)
      );
    }
    setCurrentPage(1);
    setDirectors({ items: tempDirectors, totalCount: tempDirectors.length });
    setSecretaries({
      items: tempSecretaries,
      totalCount: tempSecretaries.length,
    });
  }, [
    registersOfDirectorsAndSecretariesResidences.directors,
    registersOfDirectorsAndSecretariesResidences.secretaries,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    const getEmptyRowsCount = (currentPage, maxSectionHeight, key) => {
      let tempEmptyRowsCount = 0;
      const tableBody = document.querySelector(
        `[data-table-index="${key}-${currentPage}"]`
      );
      if (!tableBody) return tempEmptyRowsCount;
      while (
        directorsTableBodyRef.current &&
        secretariesTableBodyRef.current &&
        tableBody.clientHeight < maxSectionHeight &&
        maxSectionHeight - tableBody.clientHeight > ROW_HEIGHT &&
        tempEmptyRowsCount < DIRECTORS_ROWS
      ) {
        const emptyTableRow = document.createElement("tr");
        emptyTableRow.setAttribute("class", "text-xs h-6");
        emptyTableRow.innerHTML = `
        <td class="p-1 text-gray-600 bg-gray-100 text-left"></td>
        <td class="p-1 text-gray-600 bg-gray-100"></td>
        <td class="p-1 text-gray-600 bg-gray-100"></td>
        <td class="p-1 text-gray-600 bg-gray-100 text-left"></td>
        <td class="p-1 text-gray-600 bg-gray-100"></td>
        <td class="p-1 text-gray-600 bg-gray-100"></td>
        <td class="p-1 text-gray-600 bg-gray-100"></td>
        <td class="p-1 text-gray-600 bg-gray-100"></td>
        `;
        tableBody.appendChild(emptyTableRow);
        tempEmptyRowsCount++;
      }
      return tempEmptyRowsCount;
    };

    const calculateRowsPerPage = () => {
      if (
        !hiddenTempTableBodyRef ||
        (!directorsTableBodyRef.current && !secretariesTableBodyRef.current)
      )
        return;

      hiddenTempTableBodyRef.innerHTML = "";

      const paginationParamsForDirectors = getPaginationParams(
        directors.items,
        MAX_DIRECTORS_SECTION_HEIGHT,
        directorsTableBodyRef,
        getPopulatedRowNode,
        "director"
      );

      setRowsPerPageForDirectors(paginationParamsForDirectors.rowsPerPage);
      setDirectorsLastPageWithData(paginationParamsForDirectors.lastPage);
      setEmptyRowsCountDirectors(
        paginationParamsForDirectors.lastPage
          ? getEmptyRowsCount(
              paginationParamsForDirectors.lastPage,
              MAX_DIRECTORS_SECTION_HEIGHT,
              "director"
            )
          : 0
      );

      const paginationParamsForSecretaries = getPaginationParams(
        secretaries.items,
        MAX_SECRETARIES_SECTION_HEIGHT,
        secretariesTableBodyRef,
        getPopulatedRowNode,
        "secretary"
      );

      setRowsPerPageForSecretaries(paginationParamsForSecretaries.rowsPerPage);
      setSecretariesLastPageWithData(paginationParamsForSecretaries.lastPage);
      setEmptyRowsCountSecretaries(
        paginationParamsForSecretaries.lastPage
          ? getEmptyRowsCount(
              paginationParamsForSecretaries.lastPage,
              MAX_SECRETARIES_SECTION_HEIGHT,
              "secretary"
            )
          : 0
      );

      setCurrentPage(1);
    };

    calculateRowsPerPage();

    window.addEventListener("resize", calculateRowsPerPage);

    return () => {
      window.removeEventListener("resize", calculateRowsPerPage);
    };
  }, [directors.items, secretaries.items, isFullscreen]);

  useEffect(() => {
    if (currentPage > 0) {
      if (_has(rowsPerPageForDirectors[currentPage], "start")) {
        setPaginatedDirectors(
          directors.items.slice(
            rowsPerPageForDirectors[currentPage].start,
            rowsPerPageForDirectors[currentPage].end
          )
        );
      } else {
        setPaginatedDirectors([]);
      }
      if (_has(rowsPerPageForSecretaries[currentPage], "start")) {
        setPaginatedSecretaries(
          secretaries.items.slice(
            rowsPerPageForSecretaries[currentPage].start,
            rowsPerPageForSecretaries[currentPage].end
          )
        );
      } else {
        setPaginatedSecretaries([]);
      }
    }
  }, [
    currentPage,
    directors,
    secretaries,
    SECRETARIES_ROWS,
    rowsPerPageForDirectors,
  ]);

  if (getRegistersOfDirectorsAndSecretariesResidences.status === "loading") {
    return (
      <div className="col-span-6">
        <div className="flex justify-center mx-auto text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </div>
    );
  }

  const handleDownloadClick = () => {
    dispatch(
      downloadRegistersOfDirectorsAndSecretariesResidencesAsync({
        queryParams: { companyId: company._id, fileFormat: "xlsx" },
      })
    );
  };

  return (
    <div className="overflow-auto">
      <div className="flex justify-between w-full mb-4">
        <BreadCrumbs breadCrumbs={selectedTab.breadCrumbs} />
        <Button
          onClick={handleDownloadClick}
          preIcon={DownloadIcon}
          isLoading={
            downloadRegistersOfDirectorsAndSecretariesResidences.status ===
            "loading"
          }
          loadingText="Downloading..."
        >
          Download
        </Button>
      </div>
      <ToggleFullscreenContainer>
        <ToggleFullscreenButton />
        <div
          className={`bg-white relative ${
            isFullscreen ? "h-[88vh] overflow-auto" : ""
          }`}
        >
          <div
            className={`overflow-auto px-1 pt-4 pb-20 space-y-2 flex flex-col text-center ${
              isFullscreen ? "px-12" : ""
            }`}
          >
            {/* REGISTER OF DIRECTORS' RESIDENCES */}
            <div className="relative flex flex-col items-center">
              <div>
                <h2 className="text-title6 font-medium">
                  REGISTER OF DIRECTORS' RESIDENCES
                </h2>
                <div className="flex items-center justify-center">
                  <span className="mr-2 text-sm">
                    as at {format(new Date(), "dd-MM-yyyy")}
                  </span>
                  <PencilAltIcon className="w-4 h-4" />
                </div>
              </div>
              {isFullscreen && (
                <SearchInput
                  value={searchTerm}
                  placeholder="Search register"
                  className="lg:absolute w-64 top-0 right-0 mt-2"
                  handleOnChange={(event) => setSearchTerm(event.target.value)}
                  autoFocus
                />
              )}
            </div>
            <div>
              <div className="mt-4">
                <table className="min-w-full divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
                  <DirectorsTableHeading />
                  <tbody
                    style={{ height: `${MAX_DIRECTORS_SECTION_HEIGHT}px` }}
                    className="divide-y divide-gray-200"
                    ref={directorsTableBodyRef}
                  >
                    {paginatedDirectors.map((director) => (
                      <PopulatedDirectorAndSecretaryRow
                        key={director._id}
                        official={director}
                      />
                    ))}
                    {currentPage === directorsLastPageWithData &&
                    emptyRowsCountForDirectors
                      ? Array.from({
                          length: emptyRowsCountForDirectors,
                        }).map((_, index) => (
                          <EmptyRow key={`director_empty_row_${index}`} />
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>

            {/* REGISTER OF SECRETARIES' RESIDENCES */}
            <div>
              <h2 className="text-title6 font-medium">
                REGISTER OF SECRETARIES' RESIDENCES (3)
              </h2>
              <div className="mt-4">
                <table className="min-w-full divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
                  <SecretariesTableHeading />
                  <tbody
                    style={{ height: `${MAX_SECRETARIES_SECTION_HEIGHT}px` }}
                    className="divide-y divide-gray-200"
                    ref={secretariesTableBodyRef}
                  >
                    {paginatedSecretaries.map((secretary) => (
                      <PopulatedDirectorAndSecretaryRow
                        key={secretary._id}
                        official={secretary}
                      />
                    ))}
                    {currentPage === secretariesLastPageWithData &&
                    emptyRowsCountForSecretaries
                      ? Array.from({
                          length: emptyRowsCountForSecretaries,
                        }).map((_, index) => (
                          <EmptyRow key={`secretary_empty_row_${index}`} />
                        ))
                      : null}
                    {currentPage > secretariesLastPageWithData
                      ? getEmptyRowsForRegisters(0, SECRETARIES_ROWS).map(
                          (_, index) => (
                            <EmptyRow key={`secretary_empty_row_${index}`} />
                          )
                        )
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <PaginationControls
            isFullscreen={isFullscreen}
            isLastPage={currentPage === directorsLastPageWithData}
            currentPage={currentPage}
            rowsPerPage={rowsPerPageForDirectors[currentPage] || 0}
            totalRows={directors.totalCount}
            totalPages={Object.keys(rowsPerPageForDirectors).length}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </ToggleFullscreenContainer>
    </div>
  );
}

export default RegisterOfDirectorsAndSecretariesResidences;
