import React from "react";
import { useFormikContext } from "formik";
import { format } from "date-fns";
import DatePickerField from "components/lib/Shared/DatePickerField";

const ManageAppointmentDate = ({ field }) => {
  const { setFieldValue } = useFormikContext();

  const handleAppointmentDateValue = (date) => {
    const formattedDate = format(date, "yyyy/MM/dd");
    setFieldValue(field.name, formattedDate);
  };

  return (
    <div className="col-span-6 sm:col-span-3">
      <DatePickerField
        label="Date of Becoming"
        onChange={handleAppointmentDateValue}
        selected={field.value ? new Date(field.value) : null}
        name="appointmentDate"
        placeholderText="Enter dates of becoming"
        errorPath="identificationDetails.appointmentDate"
        disabled={true}
      />
    </div>
  );
};

export default ManageAppointmentDate;
