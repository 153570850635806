import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import _get from "lodash/get";

import Label from "components/lib/Shared/Label";
import ErrorBanner from "components/lib/Shared/ErrorBanner";
import Button from "components/lib/Shared/Button";
import ChangeOfParticulars from "./ChangeOfParticularsFlow/index";
import Appointment from "./AppointmentFlow/index";
import CessationComponent from "./CessationFlow";

const MaintenanceForm = ({ companyId }) => {
  const [formStepErrors, setFormStepErrors] = useState([]);
  const [whichFormToBeRendered, setWhichFormToBeRendered] = useState("");
  const [formFieldsToRender, setFormFieldsToRender] = useState({});

  const dropDownForms = [
    {
      id: "authorizedPersons",
      title: "Authorized Person",
    },
    {
      id: "alternateDirectors",
      title: "Alternate Directors",
    },
    {
      id: "directors",
      title: "Directors",
    },
    {
      id: "directorShareholders",
      title: "Director Shareholders",
    },
    {
      id: "shareholders",
      title: "Shareholders",
    },
    {
      id: "jointShareholders",
      title: "Joint Shareholders",
    },
    {
      id: "beneficialOwners",
      title: "Beneficial Owners",
    },
    {
      id: "companySecretaries",
      title: "Company Secretaries",
    },
    {
      id: "auditors",
      title: "Auditors",
    },
  ];

  const handleDesignationChange = (event, setFieldValue) => {
    const selectedDesignation = event.target.value;
    const dropDownOptions = [
      "Appointment",
      "Change of Particulars",
      "Cessation",
    ];
    const selectedIdType =
      dropDownOptions.find((option) => option === selectedDesignation) || "";

    const initialValues = {
      designation: "",
      idType: "",
      idTypeOptions: dropDownOptions,
      selectedDesignation: selectedDesignation,
      [selectedIdType]: "", // Dynamic key based on selected Id type
    };

    setFieldValue("designation", initialValues.designation);
    setFieldValue("idTypeOptions", initialValues.idTypeOptions);
    setFieldValue("selectedDesignation", initialValues.selectedDesignation);
    setFieldValue("idType", initialValues.idType);
  };

  return (
    <Formik
      initialValues={{
        designation: "",
        idType: "",
        idTypeOptions: [],
        selectedDesignation: "",
      }}
      onSubmit={(values) => {
        // console.log(values);
        const { idType } = values;
        //set state to render form fields
        setFormFieldsToRender({
          ...formFieldsToRender,
          officialMethod: values.selectedDesignation,
          idTypeSelection: values.idType,
        });
        setWhichFormToBeRendered(idType);
      }}
    >
      {({ values, setFieldValue }) => (
        <div className="container mx-auto px-4">
          <ErrorBanner
            errors={formStepErrors}
            className="mb-2"
            showTitle={false}
          />
          <Form className="mt-4">
            <div className="px-4 py-6 mt-2 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <Label htmlFor="designation">Officials ID</Label>
                  <Field
                    as="select"
                    name="designation"
                    className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="designation"
                    onChange={(e) => handleDesignationChange(e, setFieldValue)}
                    value={values.selectedDesignation}
                  >
                    <option value="">Select designation</option>
                    {dropDownForms.map((option) => (
                      <option key={option.id} value={option.title}>
                        {option.title}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <Label htmlFor="idType">Type Of Change</Label>
                  <Field
                    as="select"
                    name="idType"
                    className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="idType"
                  >
                    <option value="">Select type of change</option>
                    {values.idTypeOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Field>
                  <div className="flex justify-end mt-4">
                    <Button type="submit"> Proceed </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <div className="mt-6 mb-6">
            {whichFormToBeRendered === "Appointment" && (
              <Appointment
                companyId={companyId}
                addOfficialProcessType={formFieldsToRender}
                setWhichFormToBeRendered={setWhichFormToBeRendered}
              />
            )}
            {whichFormToBeRendered === "Change of Particulars" && (
              <ChangeOfParticulars companyId={companyId} />
            )}
            {whichFormToBeRendered === "Cessation" && (
              <CessationComponent companyId={companyId} />
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};
export default MaintenanceForm;
