import { object, string, mixed } from "yup";

const professionalDetailValidation = (isProfilePage) => {
  const schema = object().shape({
    capacity: string().required(
      isProfilePage ? "Role is required" : "Capacity is required"
    ),
    practicingNumber: string()
      .trim("Practicing Number cannot include leading and trailing spaces")
      .strict(true)
      .required("Practicing Number is required"),
    practicingCertificate: string().required(
      "Practicing Certificate is required"
    ),
    fileUploads: object().shape({
      practicingCertificate: mixed().required("Certificate is required"),
    }),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default professionalDetailValidation;
