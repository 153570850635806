import { getFullName, getPoBoxAddress } from "utils";
import _get from "lodash/get";

const DrFolio = ({ shareholder = {} }) => {
  return (
    <div className="flex flex-col text-left">
      <p className="p-1 m-1 text-gray-600 bg-gray-100 flex-1 font-bold text-base">
        Folio: {shareholder.memberRegisterFolio}
      </p>
      <div className="flex space-x-2">
        <span className="p-1 m-1 text-gray-600 bg-gray-100 w-32  font-medium">
          NAME
        </span>
        <span className="p-1 m-1 text-gray-600 bg-gray-100 flex-1">
          {getFullName(shareholder.names)}
        </span>
      </div>
      <div className="flex space-x-2">
        <span className="p-1 m-1 text-gray-600 bg-gray-100 w-32  font-medium">
          ADDRESS
        </span>
        <span className="p-1 m-1 text-gray-600 bg-gray-100 flex-1">
          {getPoBoxAddress(
            _get(shareholder, "idType"),
            _get(shareholder, "postalAddress"),
            _get(shareholder, "isNonResident")
          )}
        </span>
      </div>
      <div className="flex space-x-2">
        <span className="p-1 m-1 text-gray-600 bg-gray-100 w-32  font-medium">
          OCCUPATION
        </span>
        <span className="p-1 m-1 text-gray-600 bg-gray-100 flex-1">
          {_get(shareholder, "identificationDetails.occupation") ||
            _get(shareholder, "identificationDetails.entityType")}
        </span>
      </div>
    </div>
  );
};

export default DrFolio;
