import { object, string, number } from "yup";

const getValidationSchema = ({
  availableShares,
  allottedShares,
  sharesValue,
}) => {
  const schema = object().shape({
    classOfShares: string().required("Class of shares is required"),
    allottedShares: number()
      .integer("Please enter a whole number")
      .min(1, "Must be greater than or equal to 1")
      .max(
        availableShares,
        typeof availableShares !== "undefined"
          ? `Value cannot be more than ${availableShares}`
          : ""
      )
      .required("Number of shares is required"),
    paidUpShares: number()
      .integer("Please enter a whole number")
      .min(0, "Must be greater than or equal to 0")
      .max(
        allottedShares,
        typeof allottedShares !== "undefined"
          ? `Value cannot be more than ${allottedShares}`
          : ""
      )
      .required("Number of shares fully paid up is required"),
    sharePrice: number()
      .required("Amount to be paid per share is required")
      .min(
        sharesValue,
        sharesValue ? `Value cannot be less than ${sharesValue}` : ""
      ),
  });

  return {
    schema,
  };
};

export default getValidationSchema;
