import React, { useState, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { Field, useFormikContext } from "formik";

import AffiliatedBusiness from "./AffiliatedBusiness";
import CommentsList from "components/lib/Global/CommentsList";

const SpecialInstructions = ({ field, form, nameCount }) => {
  const { setFieldValue } = useFormikContext();
  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const [specialInstructions, setSpecialInstructions] = useState();

  const handleChange = ({ target: { value, name } }) => {
    form.setFieldValue(name, value);
  };

  useEffect(() => {
    setSpecialInstructions(field?.value);
  }, [field?.value]);

  useEffect(() => {
    if (specialInstructions === "" || specialInstructions === "None") {
      let tempUploadedFiles = { ...(form.values?.fileUploads ?? {}) };
      delete tempUploadedFiles?.[`affiliatedBusiness${nameCount}_LNO`];
      setFieldValue("fileUploads", tempUploadedFiles);
      setFieldValue(`affiliatedBusiness${nameCount}`, {});
    }
  }, [specialInstructions]);

  const specialInstructionComments =
    transformedComments?.[`preferredCompanyNameCard_${nameCount}`]?.[
      `specialInstructions${nameCount}`
    ];
  const businessNameComments =
    transformedComments?.[`preferredCompanyNameCard_${nameCount}`]?.[
      `affiliatedBusiness${nameCount}.affiliatedBusinessName`
    ];
  const regNumberComments =
    transformedComments?.[`preferredCompanyNameCard_${nameCount}`]?.[
      `affiliatedBusiness${nameCount}.affiliatedBusinessRegNumber`
    ];
  const fileUploadComments =
    transformedComments?.[`preferredCompanyNameCard_${nameCount}`]?.[
      `affiliatedBusiness${nameCount}.affiliatedBusiness${nameCount}_LNO`
    ];

  return (
    <div>
      <div className="flex mt-1 rounded-md shadow-sm">
        <span className="inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">
          <span className="relative">
            Special Instruction, If Any
            {specialInstructionComments?.length > 0 && (
              <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-3 -right-3">
                <span>{specialInstructionComments?.length}</span>
              </span>
            )}
          </span>
        </span>
        <select
          className="flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
          type="select"
          name={`specialInstructions${nameCount}`}
          disabled={form.values.resolvedFields.includes(
            `specialInstructions${nameCount}`
          )}
          onChange={handleChange}
          value={specialInstructions}
        >
          <option value="">Choose..</option>
          <option value="None">--None--</option>
          <option value="affiliatedBusiness">
            Affiliated To Existing Business
          </option>
        </select>
      </div>
      {specialInstructionComments && (
        <CommentsList
          comments={specialInstructionComments}
          color={"text-red-500"}
        />
      )}
      {specialInstructions === "affiliatedBusiness" ? (
        <Field
          component={AffiliatedBusiness}
          name={`affiliatedBusiness${nameCount}`}
          businessNameComments={businessNameComments}
          regNumberComments={regNumberComments}
          fileUploadComments={fileUploadComments}
          prefixDocumentName={`affiliatedBusiness${nameCount}.`}
        />
      ) : null}
    </div>
  );
};

export default SpecialInstructions;
