import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import UserOnboarding from "components/UserOnboarding";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import { getProfileComments } from "state/slices/comment";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.userSlice);
  const { getUserStatus } = useSelector((state) => state.authSlice);

  useEffect(async () => {
    if (currentUser?._id) {
      if (currentUser.status === "Verified") history.push("/profile");
      else await dispatch(getProfileComments({ userId: currentUser?._id }));
    }
  }, [currentUser?._id]);

  if (getUserStatus.status === "loading" || !currentUser?._id) {
    return (
      <DashboardWrapper>
        <div className="flex justify-center mx-auto my-2 ">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper>
      <UserOnboarding />
    </DashboardWrapper>
  );
};

export default Dashboard;
