/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import companyDataService from "services/company";

export const getCompaniesAsync = createAsyncThunk(
  "companies/getCompaniesAsync",
  async (queryParams, thunkAPI) => {
    try {
      const res = await companyDataService.getAll(queryParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompaniesForSignatureAsync = createAsyncThunk(
  "companies/getCompaniesForSignatureAsync",
  async (queryParams, thunkAPI) => {
    try {
      const res = await companyDataService.getAll(queryParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "companies/deleteCompany",
  async ({ companyId }) => {
    await companyDataService.remove(companyId);
    return { companyId };
  }
);

export const getManagedCompaniesAsync = createAsyncThunk(
  "companies/getManagedCompaniesAsync",
  async (queryParams, thunkAPI) => {
    try {
      const res = await companyDataService.getManagedCompanies(queryParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  companies: {
    items: [],
    totalCount: 0,
  },
  companiesForSignature: {
    items: [],
    totalCount: 0,
  },
  managedCompanies: {
    items: [],
    totalCount: 0,
  },
  getCompanies: {
    status: "idle",
    error: false,
  },
  getCompaniesForSignature: {
    status: "idle",
    error: false,
  },
  getManagedCompanies: {
    status: "idle",
    error: false,
  },
};

const companySlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getCompaniesAsync.pending]: (state) => {
      state.getCompanies.status = "loading";
      state.getCompanies.error = false;
    },
    [getCompaniesAsync.fulfilled]: (state, action) => {
      state.getCompanies.status = "idle";
      state.getCompanies.error = false;
      state.companies = action.payload;
    },
    [getCompaniesAsync.rejected]: (state, action) => {
      state.getCompanies.status = "failed";
      state.getCompanies.error = action.payload.messageText;
    },

    [getCompaniesForSignatureAsync.pending]: (state) => {
      state.getCompaniesForSignature.status = "loading";
      state.getCompaniesForSignature.error = false;
    },
    [getCompaniesForSignatureAsync.fulfilled]: (state, action) => {
      state.getCompaniesForSignature.status = "idle";
      state.getCompaniesForSignature.error = false;
      state.companiesForSignature = action.payload;
    },
    [getCompaniesForSignatureAsync.rejected]: (state, action) => {
      state.getCompaniesForSignature.status = "failed";
      state.getCompaniesForSignature.error = action.payload.messageText;
    },

    [getManagedCompaniesAsync.pending]: (state) => {
      state.getManagedCompanies.status = "loading";
      state.getManagedCompanies.error = false;
    },
    [getManagedCompaniesAsync.fulfilled]: (state, action) => {
      state.getManagedCompanies.status = "idle";
      state.getManagedCompanies.error = false;
      state.managedCompanies = action.payload.managedCompanies;
    },
    [getManagedCompaniesAsync.rejected]: (state, action) => {
      state.getManagedCompanies.status = "failed";
      state.getManagedCompanies.error = action.payload.messageText;
    },

    [deleteCompany.fulfilled]: (state, { payload }) => {
      const index = state.companies.items.findIndex(
        (company) => payload.companyId === company._id
      );
      if (index > -1) {
        state.companies.items.splice(index, 1);
        state.companies.totalCount -= 1;
      }
    },
  },
});

export const { reset } = companySlice.actions;
const { reducer } = companySlice;

export default reducer;
