import React, { useState, useMemo, useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

import Categories from "./Categories";
import Templates from "./Templates";
import { Constants } from "config/constants";
import { classNames } from "utils";

function TemplatesSettings() {
  const dispatch = useDispatch();
  const cookieRole = Cookies.get("role");
  const [selectedTab, setSelectedTab] = useState("products");

  useEffect(() => {
    if (cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN) {
      setSelectedTab("categories");
    } else setSelectedTab("templates");
  }, [cookieRole]);

  const navigation = useMemo(
    () =>
      [
        cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN
          ? {
              id: "categories",
              label: "Categories",
            }
          : null,
        {
          id: "templates",
          label: "Templates",
        },
      ].filter(Boolean),
    [cookieRole]
  );

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="px-2 py-6 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2 lg:border-r lg:border-gray-300">
        <nav>
          {navigation.map((item) => (
            <button
              key={item.label}
              className={classNames(
                selectedTab === item.id
                  ? "bg-gray-50 text-sflPrimary hover:bg-white font-semibold"
                  : "rounded-md px-3 py-1 text-tertiary flex items-center text-base font-medium",
                "flex items-center px-3 py-1 text-base font-medium hover:text-sflPrimary hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300"
              )}
              onClick={() => setSelectedTab(item.id)}
            >
              {item.label}
            </button>
          ))}
        </nav>
      </aside>
      <div className="col-span-10">
        {selectedTab === "categories" && <Categories />}
        {selectedTab === "templates" && <Templates />}
      </div>
    </div>
  );
}

export default TemplatesSettings;
