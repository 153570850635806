import React from "react";
import { useSelector } from "react-redux";

import ReactTable from "components/lib/Global/ReactTable";
import SubRowAsync from "./SubRowAsync";

const TransactionsList = () => {
  const organization = useSelector(
    (state) => state.organizationsSlice.organization
  );

  const allCorporates = [organization];

  const columns = [
    {
      Header: "Details",
      accessor: "details",
      Cell: ({ row }) => row?.original?.names?.companyName ?? "N/A",
    },
    {
      Header: "Application Type",
      accessor: "applicationType",
      Cell: ({ row }) => {
        return (
          <div className="inline-flex items-center justify-center  px-3 py-1 mx-auto text-sm font-medium text-white rounded-full bg-green-primary">
            {row?.original?.partnershipType ?? "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Individual Officials",
      Cell: ({ row }) => row.original?.individualOfficials?.length ?? 0,
    },
    {
      Header: "Corporate Officials",
      Cell: ({ row }) => row.original?.corporateOfficials?.length ?? 0,
    },
    {
      Header: "Status",
      Cell: ({ row }) => row.original?.status ?? "N/A",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            {...row?.getToggleRowExpandedProps()}
            className={`text-gray-900 group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
          >
            {row.isExpanded ? "Close" : "View"}
          </button>
        );
      },
    },
  ];

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <SubRowAsync
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          currentTab="stakeholderTab"
        />
      );
    },
    []
  );

  return (
    <div className="mt-6 w-full bg-white shadow">
      <ReactTable
        columns={columns}
        data={allCorporates}
        headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
        headingStyle="pl-4 text-left"
        dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
        dataRowCellStyle="pl-4 leading-snug py-2"
        renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
};

export default TransactionsList;
