/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _merge from "lodash/merge";
import Cookies from "js-cookie";

import SectionDescription from "components/lib/Shared/SectionDescription";
import step3Validation from "./step3Validation";
import BusinessApplicationDetails from "./CompanyFormationComponents/BusinessApplicationDetails";
import PrimaryBusinessActivity from "./CompanyFormationComponents/PrimaryBusinessActivity";
import { updateCompanyState } from "state/slices/company";
import { createCompany, updateCompanyAsync } from "state/slices/company";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import Button from "components/lib/Shared/Button";
import { Constants } from "config/constants";

const Step3 = ({
  goToPreviousStep,
  handleNextBtn,
  submitBtnLabel,
  isSubmitting,
  type,
}) => {
  const [isSubmittingForm, setIsSubmittingFrom] = useState(false);
  const role = Cookies.get("role");
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { companyFormation, company } = useSelector(
    (state) => state.companySlice
  );

  const { schema, schemaInitialValues } = step3Validation();

  const initialValues = _merge(schemaInitialValues, companyFormation.step3);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        resolvedFields: companyFormation.resolvedFields,
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        setIsSubmittingFrom(true);
        if (
          role &&
          ![
            Constants.COOKIE_ROLES.APPLICANT,
            Constants.COOKIE_ROLES.CO_APPLICANT,
          ].includes(role)
        ) {
          handleNextBtn();
          return;
        }
        dispatch(
          updateCompanyState({
            companyFormation: { ...companyFormation, step3: values },
          })
        );
        const companyData = {
          ...values,
          type,
          tabName: "Company formation -> Step: 3 of 3",
        };
        if (company?._id) {
          await dispatch(
            updateCompanyAsync({ id: company._id, data: companyData })
          );
        } else {
          const response = await dispatch(createCompany({ data: companyData }));
          if (response.payload?.company && !params?.companyId) {
            history.push(`/companies/${response.payload.company._id}`);
          }
        }
        handleNextBtn();
        setIsSubmittingFrom(false);
      }}
    >
      {({ values, isSubmitting: isLoading }) => {
        return (
          <Form>
            <fieldset disabled={role === Constants.COOKIE_ROLES.OFFICIAL}>
              <SectionDescription
                id="Step: 3 of 3"
                title="Business Application Details"
                description="Please complete the relevant information in each of the following categories."
              />
              <div className="mb-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                <div className="px-4 py-6 sm:p-6">
                  <Field
                    name="primaryBusinessActivity"
                    component={PrimaryBusinessActivity}
                    primaryBusinessActivity={values.primaryBusinessActivity}
                    disabled={role === Constants.COOKIE_ROLES.OFFICIAL}
                  />
                </div>
              </div>
              <Field
                name="primaryBusinessActivity"
                component={BusinessApplicationDetails}
              />
            </fieldset>
            <div className="flex justify-between py-3">
              <Button
                disabled={isSubmitting || isLoading}
                preIcon={ChevronLeftIcon}
                variant="text"
                className="flex button button-back button-back-hover items-center button-focus px-2 py-2 button-disabled button-outline button-focus"
                onClick={goToPreviousStep}
              >
                Back to previous step
              </Button>
              <Button
                type="submit"
                postIcon={ChevronRightIcon}
                isLoading={isSubmitting || isLoading || isSubmittingForm}
                className="flex button button-hover button-disabled button-outline button-focus bg-sflPrimary px-2 py-2 text-white"
              >
                {role === Constants.COOKIE_ROLES.OFFICIAL
                  ? "Next step"
                  : submitBtnLabel}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Step3;
