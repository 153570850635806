import _get from "lodash/get";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import {
  CORPORATES,
  INDIVIDUALS,
} from "components/PLCTABS/SFLdata/config/constants";
import { getFullName, getTransformedFormFields } from "utils";

function GeneralInformation({
  official,
  isCompanyDashboard,
  isCurrentUserRow,
  viewRestrictedData,
  hideResolveAllCheckbox,
}) {
  const namesFormFields = [];
  const { company } = useSelector((state) => state.companyDashboardSlice);

  if (INDIVIDUALS.includes(official.idType)) {
    namesFormFields.push({
      key: "Full Name",
      name: "fullName",
      value: getFullName(official.names) || "N/A",
    });
  }

  if (CORPORATES.includes(official.idType)) {
    const dateOfRegistration = _get(official.names, "dateOfRegistration");
    namesFormFields.push(
      {
        key: "Company Name",
        name: "companyName",
        value: _get(official.names, "companyName", "N/A"),
      },
      {
        key: "Company Registration Date",
        name: "dateOfRegistration",
        value: dateOfRegistration
          ? viewRestrictedData
            ? format(new Date(dateOfRegistration), "yyyy-MM-dd")
            : dateOfRegistration
          : "N/A",
      }
    );
  }

  if (_get(official.names, "formerName")) {
    namesFormFields.push({
      key: "Former name",
      name: "formerName",
      value: _get(official.names, "formerName"),
    });
  }

  const identificationFormFields = [
    ...getTransformedFormFields({
      idType: official.idType,
      formType: "identificationDetails",
      formValues: official.identificationDetails,
      formatDOB: Boolean(isCurrentUserRow || viewRestrictedData),
    }),
  ];

  const datesFormFields = [
    {
      key: `Effective Date Of Becoming ${official.designation}`,
      name: `effectiveDateOfBecoming${official.designation}`,
      value: official?.appointmentDate
        ? format(new Date(official.appointmentDate), "yyyy-MM-dd")
        : "N/A",
    },
    {
      key: `Date of Ceasing to be a ${official.designation}`,
      name: `dateOfCeasingToBeA${official.designation}`,
      value: official?.cessationDate
        ? format(new Date(official.cessationDate), "yyyy-MM-dd")
        : "N/A",
    },
  ];

  return (
    <>
      {isCompanyDashboard ? (
        <FormFieldsCard
          fields={[...namesFormFields, ...identificationFormFields]}
          hideResolveAllCheckbox={isCompanyDashboard}
        />
      ) : (
        <>
          <FormFieldsCard
            fields={namesFormFields}
            hideResolveAllCheckbox={hideResolveAllCheckbox}
          />
          <FormFieldsCard
            fields={identificationFormFields}
            hideResolveAllCheckbox={hideResolveAllCheckbox}
          />
        </>
      )}
      <FormFieldsCard
        fields={datesFormFields}
        formName="beneficialOwnershipForm"
        formHelperKey="beneficialOwnershipForm"
        hideResolveAllCheckbox={hideResolveAllCheckbox}
      />
    </>
  );
}

export default GeneralInformation;
