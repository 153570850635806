/* eslint-disable no-alert */
import React, { useState, useEffect } from "react";
import _has from "lodash/has";
import _get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import { EyeIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";

import ReactTable from "components/lib/Shared/ReactTable";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import CommentsPopup from "components/lib/Global/CommentsPopup";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { deleteNominalShareAsync } from "state/slices/tables/nominalSharesTable";
import { resolveFields, invalidateFields } from "state/slices/resolveField";
import { roundNumber } from "utils";
import { getComments } from "state/slices/comment";
import { deleteTemplateAsync } from "state/slices/template";

const NominalSharesTable = (props) => {
  const { company } = useSelector((state) => state.companySlice);
  const shares =
    props?.nominalShares ||
    useSelector((state) => state.sharesTableSlice.shares);
  const { resolvedFields } = useSelector((state) => state.companySlice);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRow] = useState([]);
  const [allFieldsResolved, setAllFieldsResolved] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.userSlice);
  const isViewMode = history.location.pathname.split("/").pop() === "view";
  const isReviewerMode =
    history.location.pathname.split("/").pop() === "review";

  const handleEdit = (row) => {
    props.handleEdit(row);
  };

  const handleDelete = async (row) => {
    await dispatch(deleteNominalShareAsync({ id: row._id }));
    dispatch(deleteTemplateAsync({ templateId: row.rightsAttached._id }));
  };

  const handleResolveBtnClicked = async (fieldName) => {
    // approve field
    const commentsToBeResolved = props.comments?.filter(
      (comment) => comment.fieldName === fieldName && !comment.isResolved
    );
    const commentsToBeResolvedIds =
      commentsToBeResolved?.map(({ _id }) => _id) ?? [];
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      resolveFields({
        data: {
          formId: _get(company, "_id"),
          formName: "company",
          fieldName,
          resolvedFields: [fieldName],
          commentsToBeResolvedIds,
        },
        updateEffectKey: "updateCompanyState",
      })
    );
    if (commentsToBeResolvedIds.length > 0) {
      const query = { companyId: commentsToBeResolved[0]?.company };
      if (isReviewerMode) query.createdBy = currentUser?._id;
      await dispatch(getComments(query));
    }
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const handleInvalidateBtnClicked = async (fieldName) => {
    // disapprove field
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      invalidateFields({
        data: {
          formId: _get(company, "_id"),
          formName: "company",
          fieldName,
          invalidFields: [fieldName],
        },
        updateEffectKey: "updateCompanyState",
      })
    );
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const fieldsName = shares
    .map((share) => share.sharesCategory)
    .filter(Boolean); // all the shares category names

  useEffect(() => {
    setAllFieldsResolved(
      fieldsName.every((element) => resolvedFields.includes(element))
    );
  }, [fieldsName, resolvedFields]);

  const handleAllFieldsStatus = async (e) => {
    const commentsToBeResolved = props.comments?.filter(
      (comment) => !comment.isResolved
    );
    const commentsToBeResolvedIds =
      commentsToBeResolved?.map(({ _id }) => _id) ?? [];
    setIsLoading(true);
    if (e.target.checked) {
      // resolve/approve all the form fields
      const unresolvedFields = fieldsName.filter(
        (fieldName) => !resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [...selectedRows, ...unresolvedFields]);
      await dispatch(
        resolveFields({
          data: {
            formId: _get(company, "_id"),
            formName: "company",
            resolvedFields: unresolvedFields,
            commentsToBeResolvedIds,
          },
          updateEffectKey: "updateCompanyState",
        })
      );
      if (commentsToBeResolvedIds.length > 0) {
        const query = { companyId: commentsToBeResolved[0]?.company };
        if (isReviewerMode) query.createdBy = currentUser?._id;
        await dispatch(getComments(query));
      }
      setIsLoading(false);
      setSelectedRow([]);
    } else {
      // invalidate/disapprove all the form fields
      const resolvedFieldsSlice = fieldsName.filter((fieldName) =>
        resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [
        ...selectedRows,
        ...resolvedFieldsSlice,
      ]);
      await dispatch(
        invalidateFields({
          data: {
            formId: _get(company, "_id"),
            formName: "company",
            invalidFields: resolvedFieldsSlice,
          },
          updateEffectKey: "updateCompanyState",
        })
      );
      setSelectedRow([]);
    }
    setIsLoading(false);
  };

  const sumOfVotingRights = shares.reduce(
    (acc, share) => roundNumber(acc + Number(share.votingRights)),
    0
  );

  const data = [...shares];
  if (shares.length > 0) {
    data.push({
      id: "total",
      sharesCategory: "Total",
      sharesNumber: "",
      sharesValue: "",
      nominalValue: "",
      votingRights: sumOfVotingRights,
    });
  }

  const columns = [
    {
      Header: "Share Category",
      Cell: ({ row }) => {
        if (row.original.id === "total") return <b>Total</b>;
        return row.original.sharesCategory;
      },
    },
    { Header: "Number of Shares", accessor: "sharesNumber" },
    { Header: "Value of Shares", accessor: "sharesValue" },
    { Header: "Nominal Value", accessor: "nominalValue" },
    {
      Header: "Voting Rights",
      Cell: ({ row }) => {
        if (row.original.id === "total")
          return <b>{row.original.votingRights}</b>;
        return row.original.votingRights;
      },
    },
  ];

  if (props.showActionColumn && !allFieldsResolved) {
    columns.push({
      Header: "Actions",
      Cell: ({ row }) => {
        if (row.original.id === "total") return "";
        return (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => props.handleView(row.original)}
              className="flex space-x-1 justify-center items-center focus:outline-none mr-5 hover:text-gray-600 py-2.5 text-sm leading-3 text-gray-500"
            >
              <EyeIcon className="w-6 h-6" />
              <span>Quick View</span>
            </button>
            <TableActionMenu
              row={row}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </div>
        );
      },
    });
  }

  if (props.showAddCommentColumn && !isViewMode) {
    columns.push({
      Header: "Actions",
      Cell: ({ row }) => {
        if (row.original.id === "total") return "";
        const isRowLoading =
          isLoading && selectedRows.includes(row.original.sharesCategory);

        return (
          <div className="flex items-center space-x-2">
            {!resolvedFields.includes(row.original.sharesCategory) && (
              <CommentsPopup
                handleSaveComment={props.handleSaveComment}
                formName="shareInformation"
                fieldName={row.original.sharesCategory}
                fieldLabel={row.original.sharesCategory}
                comment={props.comments.find(
                  (comment) =>
                    comment.fieldName === row.original.sharesCategory &&
                    currentUser?._id === comment.createdBy?._id
                )}
              >
                <span>Review</span>
                <PencilIcon className="inline w-4 h-4" />
              </CommentsPopup>
            )}
            <div className={`flex items-center justify-center`}>
              {resolvedFields.includes(row.original.sharesCategory) ? (
                <button
                  className={`flex space-x-1 ${
                    isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={() =>
                    isRowLoading
                      ? null
                      : handleInvalidateBtnClicked(row.original.sharesCategory)
                  }
                >
                  {isRowLoading ? (
                    <SpinnerIcon className="text-primary" />
                  ) : (
                    <>
                      <span>Accepted </span>
                      <XIcon className="inline w-4 h-4" />
                    </>
                  )}
                </button>
              ) : (
                <button
                  className={`flex space-x-1 ${
                    isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={() =>
                    isRowLoading
                      ? null
                      : handleResolveBtnClicked(row.original.sharesCategory)
                  }
                >
                  {isRowLoading ? (
                    <SpinnerIcon className="text-primary" />
                  ) : (
                    <>
                      <span>Accept</span>
                      <CheckIcon className="inline w-4 h-4" />
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        );
      },
    });
  }

  return (
    <>
      <div className="w-full bg-white shadow">
        <div className="w-full">
          <ReactTable
            columns={columns}
            data={data}
            headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
            headingStyle="pl-4 text-left"
            dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          />
        </div>
      </div>
    </>
  );
};

export default NominalSharesTable;
