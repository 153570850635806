import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (params) => axios.get(`/products?${getQueryString(params)}`);

const create = (data) => axios.post("/products", data);

const update = (commentId, data) => axios.put(`/products/${commentId}`, data);

const remove = (commentId) => axios.delete(`/products/${commentId}`);

const productService = {
  getAll,
  create,
  update,
  remove,
};

export default productService;
