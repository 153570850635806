import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const generateOrderId = (params) =>
  axios.get(`/orders/generate-order-id?${getQueryString(params)}`);

const orderService = {
  generateOrderId,
};

export default orderService;
