import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import productService from "services/product";

export const getProductsAsync = createAsyncThunk(
  "products/getProductsAsync",
  async (queryParams) => {
    const res = await productService.getAll(queryParams);
    return res.data;
  }
);

export const addProductAsync = createAsyncThunk(
  "products/addProductAsync",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await productService.create(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editProductAsync = createAsyncThunk(
  "products/editProductAsync",
  async ({ productId, data }, { rejectWithValue }) => {
    try {
      const res = await productService.update(productId, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProductAsync = createAsyncThunk(
  "products/deleteProductAsync",
  async ({ productId }, { rejectWithValue }) => {
    try {
      await productService.remove(productId);
      return { productId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  products: {
    items: [],
    totalCount: 0,
  },
  getProducts: {
    status: "idle",
    error: false,
  },
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    resetProductsSlice: () => initialState,
  },
  extraReducers: {
    [getProductsAsync.pending]: (state) => {
      state.getProducts.status = "loading";
      state.getProducts.error = false;
    },
    [getProductsAsync.fulfilled]: (state, action) => {
      state.getProducts.status = "idle";
      state.products = action.payload;
    },
    [getProductsAsync.rejected]: (state, action) => {
      state.getProducts.status = "failed";
      state.getProducts.error = action?.payload?.messageText;
    },

    [addProductAsync.fulfilled]: (state, action) => {
      state.products.items.push(action.payload.product);
      state.products.totalCount += 1;
    },

    [editProductAsync.fulfilled]: (state, action) => {},
  },
});

export const { resetProductsSlice } = productsSlice.actions;

export default productsSlice.reducer;
