import { object, string } from "yup";

const selectIdTypeValidation = () => {
  const schema = object().shape({
    idType: string().required("Id type is required").default(""),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default selectIdTypeValidation;
