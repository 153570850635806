import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { ArrowLeftIcon } from "@heroicons/react/outline";

import Button from "components/lib/Shared/Button";
import TemplatesTable from "./Table";
import TemplateForm from "./TemplateForm";
import SummaryForm from "./SummaryForm";
import { Constants } from "config/constants";
import {
  editTemplateAsync,
  getTemplatesAsync,
  updateTemplateState,
} from "state/slices/template";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import { useToast } from "hooks/useToast";
import Overlay from "components/lib/Shared/Overlay";

const Templates = () => {
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [showAddTemplateForm, setShowAddTemplateForm] = useState(false);
  const { templates, selectedTemplate, selectedVersion, mode, showOverlay } =
    useSelector((state) => state.templateSlice);

  useEffect(() => {
    dispatch(getTemplatesAsync());
  }, []);

  const handleExitWorkflow = () => {
    setShowAddTemplateForm(false);
    dispatch(
      updateTemplateState({
        selectedTemplate: {},
        selectedVersion: {},
        mode: "",
      })
    );
  };

  const handleSaveSummary = async (summary) => {
    let response = {};
    if (selectedTemplate?._id) {
      response = await dispatch(
        editTemplateAsync({
          templateId: selectedTemplate?._id,
          data: { summary, fileUploads: selectedVersion.fileUploads },
        })
      );
    }
    if (response.error) {
      toast(
        "error",
        response.payload?.messageText ?? "Something went wrong please try again"
      );
    } else {
      toast("success", "Successfully saved the details");
    }
  };

  const handleSelectVersion = ({ value }) => {
    dispatch(
      updateTemplateState({
        selectedVersion: selectedTemplate.versions?.find(
          (version) => version.versionNumber === value
        ),
      })
    );
  };

  useEffect(() => {
    // auto select latest version
    if (selectedTemplate?.versions?.length) {
      dispatch(
        updateTemplateState({
          selectedVersion:
            selectedTemplate.versions[selectedTemplate.versions.length - 1],
        })
      );
    }
    return () => {
      updateTemplateState({
        selectedVersion: {},
      });
    };
  }, [selectedTemplate?.versions?.length]);

  if (
    showAddTemplateForm ||
    (selectedTemplate?._id && ["edit", "view"].includes(mode))
  ) {
    const isViewOnly = mode === "view";
    return (
      <div className="relative">
        {showOverlay && <Overlay />}
        <div className="flex justify-between mb-4">
          <Button
            onClick={handleExitWorkflow}
            variant="text"
            className="flex space-x-2 text-sm font-medium rounded-md group text-gray-600 mb-2"
          >
            <ArrowLeftIcon
              className="w-5 h-5 text-gray-400 group-hover:text-gray-800"
              aria-hidden="true"
            />
            <span className="group-hover:text-gray-800">Exit</span>
          </Button>
          {selectedTemplate?.versions && (
            <div className="w-1/4">
              <ReactSelectWithFormik
                label="Version"
                name="selectedVersionNumber"
                options={selectedTemplate.versions.map((version) => ({
                  label: version.versionNumber,
                  value: version.versionNumber,
                }))}
                value={selectedVersion?.versionNumber ?? ""}
                onChange={handleSelectVersion}
              />
            </div>
          )}
        </div>
        <div className="space-y-12">
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4">
            <div className="col-span-3 md:col-span-4 lg:col-span-8">
              {!selectedTemplate?._id && (
                <TemplateForm
                  handleExitWorkflow={handleExitWorkflow}
                  viewOnly={isViewOnly}
                  selectedVersion={selectedVersion}
                />
              )}
              {selectedTemplate?._id && (
                <div>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between text-sm font-medium">
                          <span>Templates</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4">
                          <TemplateForm
                            handleExitWorkflow={handleExitWorkflow}
                            viewOnly={isViewOnly}
                            selectedVersion={selectedVersion}
                          />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  {selectedTemplate.name === "Articles of Association" && (
                    <>
                      <hr className="my-4" />
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full justify-between text-sm font-medium">
                              <span>Summary of Articles</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-5 w-5`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel>
                              <SummaryForm
                                handleSaveSummary={handleSaveSummary}
                                viewOnly={
                                  isViewOnly ||
                                  cookieRole !==
                                    Constants.COOKIE_ROLES.SUPER_ADMIN
                                }
                                selectedVersion={selectedVersion}
                              >
                                {({ isSubmitting, handleSubmit }) => (
                                  <>
                                    {cookieRole ===
                                      Constants.COOKIE_ROLES.SUPER_ADMIN && (
                                      <div className="mt-6 flex items-center justify-end gap-x-4">
                                        <Button
                                          variant="secondary"
                                          disabled={isSubmitting}
                                          onClick={handleExitWorkflow}
                                        >
                                          {isViewOnly ? "Close" : "Cancel"}
                                        </Button>
                                        {!isViewOnly && (
                                          <Button
                                            onClick={handleSubmit}
                                            loadingText="Saving..."
                                            isLoading={isSubmitting}
                                          >
                                            Save
                                          </Button>
                                        )}
                                      </div>
                                    )}
                                  </>
                                )}
                              </SummaryForm>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleAddNewTemplate = () => {
    dispatch(
      updateTemplateState({
        selectedTemplate: {},
        selectedVersion: {},
      })
    );
    setShowAddTemplateForm(true);
  };

  return (
    <div className="pl-4">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold text-sflPrimary">Templates</h3>
        {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
          <Button onClick={handleAddNewTemplate}>Add new template</Button>
        )}
      </div>
      <TemplatesTable
        handleAddNewTemplate={handleAddNewTemplate}
        setShowAddTemplateForm={setShowAddTemplateForm}
      />
    </div>
  );
};

export default Templates;
