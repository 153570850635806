import { object, string } from "yup";

export const verifyBtnParams = {
  "Kenyan Citizen": {
    label: "National ID number",
    name: "nationalIDNumber",
    placeholder: "Enter national id number",
    validationSchema: object({
      nationalIDNumber: string()
        .trim("National ID number cannot include leading and trailing spaces")
        .strict(true)
        .required("National ID number is required")
        .min(6, "National ID must contain at least six characters")
        .max(8, "National ID cannot be more than eight characters")
        .test("len", "National ID can only be a number", (value) =>
          /^\d+$/.test(value)
        ),
    }),
  },
  "Foreign Resident": {
    label: "Foreign Certificate ID",
    name: "foreignCertificateID",
    placeholder: "Enter foreign certificate ID number",
    validationSchema: object({
      foreignCertificateID: string()
        .trim(
          "Foreign certificate ID cannot include leading and trailing spaces"
        )
        .strict(true)
        .required("Foreign certificate ID is required"),
    }),
  },
  Foreigner: {
    label: "Passport number",
    name: "passportNumber",
    placeholder: "Enter passport number",
    validationSchema: object({
      passportNumber: string()
        .trim("Passport cannot include leading and trailing spaces")
        .strict(true)
        .required("Passport number is required"),
    }),
  },
};
