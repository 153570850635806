/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import verifyDataService from "services/verify";

const initialState = {
  verifyStatus: {
    status: "idle",
    error: false,
    loaded: false,
  },
  validateEmailAddressStatus: {
    status: "idle",
    error: false,
  },
};

export const validateEmailAddress = createAsyncThunk(
  "verifyInvite/validateEmailAddress",
  async ({ email }, thunkAPI) => {
    try {
      const res = await verifyDataService.validateEmailAddress(email);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verify = createAsyncThunk(
  "verifyInvite/verify",
  async ({ data }, thunkAPI) => {
    try {
      const res = await verifyDataService.verify(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyForOrganization = createAsyncThunk(
  "verifyInvite/verifyForOrganization",
  async ({ data }, thunkAPI) => {
    try {
      const res = await verifyDataService.verify(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const profileAccessRequest = createAsyncThunk(
  "verifyInvite/profileAccessRequest",
  async ({ data }) => {
    const res = await verifyDataService.profileAccessRequest(data);
    return res.data;
  }
);

const verifyInviteSlice = createSlice({
  name: "verifyInvite",
  initialState,
  reducers: {
    resetVerifyStatus: (state) => {
      state.verifyStatus = {
        status: "idle",
        error: false,
        loaded: false,
      };
    },
    reset: () => initialState,
  },
  extraReducers: {
    [verify.pending]: (state) => {
      state.verifyStatus.status = "loading";
      state.verifyStatus.error = false;
      state.verifyStatus.loaded = false;
    },
    [verify.fulfilled]: (state, action) => {
      state.verifyStatus.status = "idle";
      state.verifyStatus.loaded = true;
    },
    [verify.rejected]: (state, action) => {
      state.verifyStatus.status = "failed";
      state.verifyStatus.error = action?.payload?.messageText;
    },

    [verifyForOrganization.pending]: (state) => {
      state.verifyStatus.status = "loading";
      state.verifyStatus.error = false;
      state.verifyStatus.loaded = false;
    },
    [verifyForOrganization.fulfilled]: (state, action) => {
      state.verifyStatus.status = "idle";
      state.verifyStatus.loaded = true;
    },
    [verifyForOrganization.rejected]: (state, action) => {
      state.verifyStatus.status = "failed";
      state.verifyStatus.error = action?.payload?.messageText;
    },

    [validateEmailAddress.pending]: (state) => {
      state.validateEmailAddressStatus.status = "loading";
      state.validateEmailAddressStatus.error = false;
    },
    [validateEmailAddress.fulfilled]: (state, action) => {
      state.validateEmailAddressStatus.status = "idle";
      state.validateEmailAddressStatus.error = false;
    },
    [validateEmailAddress.rejected]: (state, action) => {
      state.validateEmailAddressStatus.status = "failed";
      state.validateEmailAddressStatus.error = action.payload.messageText;
    },
  },
});

export const { resetVerifyStatus, reset } = verifyInviteSlice.actions;
const { reducer } = verifyInviteSlice;

export default reducer;
