import React, { useEffect } from "react";
import _merge from "lodash/merge";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import Cookies from "js-cookie";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SectionDescription from "components/lib/Shared/SectionDescription";
import IdentificationDetailsForm from "components/PLCTABS/CompanyOfficialsTab/PersonalDetailsComponents";
import NamesForm from "components/PLCTABS/CompanyOfficialsTab/NamesComponents";
import identificationValidation from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/identificationValidation";
import {
  updateCorporate,
  updateOrganizationState,
} from "state/slices/organizations";
import Button from "components/lib/Shared/Button";
import { Constants } from "config/constants";
import { getFullName } from "utils";

const formFields = ["names", "identificationDetails"];

const GeneralInformation = ({ handleBackBtn, handleNextBtn }) => {
  const organization =
    useSelector((state) => state.organizationsSlice.organization) || {};
  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const currentUser = useSelector((state) => state.userSlice);

  const { idType, partnershipType, names, identificationDetails } =
    organization;
  const dispatch = useDispatch();
  const params = useParams();
  const cookieRole = Cookies.get("role");

  const isReadOnly = [
    Constants.COOKIE_ROLES.OFFICIAL,
    Constants.COOKIE_ROLES.CONTACT_PERSON,
  ].includes(cookieRole);

  const handleSubmit = async (formData) => {
    formData = _omit(formData, "comments");
    if (isReadOnly) {
      handleNextBtn();
      return;
    }

    const updatedOfficialData = {};
    formFields.map((formField) => {
      if (!_isEqual(organization[formField], formData[formField])) {
        updatedOfficialData[formField] = formData[formField];
      }
    });

    if (!_isEmpty(updatedOfficialData)) {
      dispatch(updateOrganizationState(formData));
      await dispatch(
        updateCorporate({ id: params.corporateId, data: formData })
      );
    }
    handleNextBtn();
  };

  const { schemaInitialValues, schema } = identificationValidation(idType);

  const initialValues = _merge(schemaInitialValues, {
    names,
    identificationDetails,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        comments: transformedComments,
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        const isCurrentUserContactPerson =
          values.identificationDetails.emailAddress ===
          currentUser.electronicAddress.emailAddress;
        useEffect(() => {
          if (isCurrentUserContactPerson) {
            setFieldValue(
              "identificationDetails.companyContactName",
              getFullName(currentUser.names)
            );
            setFieldValue(
              "identificationDetails.telNumber",
              currentUser.electronicAddress.mobileNumber
            );
          }
        }, [currentUser, isCurrentUserContactPerson]);

        return (
          <Form>
            <fieldset disabled={isReadOnly}>
              <SectionDescription
                id="Step: 2 of 5"
                title="General Information"
                description="Please provide the correct details. The information provided will be used to process your application."
              />
              <div className="bg-white shadow sm:rounded-md">
                <div className="px-4 py-6 space-y-6 sm:p-6">
                  <Field
                    name="names"
                    component={NamesForm}
                    idType={idType}
                    disabled={isReadOnly}
                  />
                  <hr />
                  <IdentificationDetailsForm
                    idType={idType}
                    disabled={isReadOnly}
                    disableEntityType={partnershipType}
                    disableEmailAddress={isCurrentUserContactPerson}
                    disableContactName={isCurrentUserContactPerson}
                    disableTelNumber={isCurrentUserContactPerson}
                  />
                </div>
              </div>
            </fieldset>
            <div className="flex justify-between py-3">
              <Button
                variant="secondary"
                disabled={isSubmitting}
                onClick={handleBackBtn}
              >
                Go back
              </Button>
              <Button type="submit" isLoading={isSubmitting}>
                {isReadOnly ? "Next step" : "Save and proceed"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GeneralInformation;
