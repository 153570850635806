import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { useAuth0 } from "@auth0/auth0-react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { useToast } from "hooks/useToast";
import {
  approveOrganization,
  getCorporate,
  resetOrganization,
  updateCorporate,
} from "state/slices/organizations";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import Tabs from "components/lib/Shared/Tabs";
import CorporateSummary from "components/CorporateOnboarding/SummaryTab/CorporateSummary";
import AddressParticulars from "components/CorporateOnboarding/SummaryTab/AddressParticulars";
import PartnersDetails from "components/CorporateOnboarding/SummaryTab/PartnersDetails";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { Field, Formik } from "formik";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import Label from "components/lib/Shared/Label";
import { getCurrentUser } from "state/slices/auth";
import {
  addComment,
  editComment,
  getComments,
  resetCommentsSlice,
} from "state/slices/comment";
import CommentsList from "components/lib/Global/CommentsList";
import Button from "components/lib/Shared/Button";

const initialTabs = [
  {
    id: "corporateSummary",
    label: "Corporate Summary",
  },
  {
    id: "addressParticulars",
    label: "Address particulars",
  },
  {
    id: "ownerships",
    label: "Ownerships",
  },
  {
    id: "uploadDocuments",
    label: "File Uploads",
  },
  {
    id: "confirmation",
    label: "Confirmation",
  },
];

function SubRowAsync({ visibleColumns, row }) {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setSelectedTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [isApproved, setIsApproved] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast(5000);
  const currentUser = useSelector((state) => state.userSlice);
  const { organization, getOrganization } = useSelector(
    (state) => state.organizationsSlice
  );
  const { comments } = useSelector((state) => state.commentsSlice);

  const fileUploadsComments = comments.filter(
    (comment) => comment.tabName === "uploadDocuments"
  );

  const partners = useSelector((state) => state.partnersSlice);

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  useEffect(() => {
    if (row?.original?._id) {
      dispatch(getCorporate({ corporateId: row.original._id }));
      dispatch(getComments({ organizationId: row.original._id }));
    }
    return () => {
      dispatch(resetOrganization());
      dispatch(resetCommentsSlice());
    };
  }, [row?.original?._id]);

  useEffect(() => {
    if (getOrganization.status === "failed" && getOrganization.error) {
      toast("error", getOrganization.error);
      row.toggleRowExpanded();
    }
  }, [getOrganization.status, getOrganization.error]);

  useEffect(() => {
    const approvedBy = organization?.approvedBy ?? [];
    if (currentUser?._id && approvedBy.includes(currentUser._id))
      setIsApproved("Yes");
  }, [organization, currentUser]);

  useEffect(() => {
    if (row.original.status === "Active") {
      setSelectedTabs(initialTabs.slice(0, -1));
    }
  }, [row.original.status]);

  const unresolvedComments = comments.filter((comment) => !comment.isResolved);

  if (!organization || getOrganization.status === "loading") {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto my-4">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  const handleIsApprovedChange = ({ target: { name, value } }) => {
    setIsApproved(value);
  };

  const handleSubmit = async () => {
    if (unresolvedComments.length > 0) {
      row.toggleRowExpanded();
      return;
    }
    if (["Active", "Rejected"].includes(row.original?.status)) {
      row.toggleRowExpanded();
      return;
    }
    const approvedBy = organization?.approvedBy ?? [];
    if (currentUser?._id && approvedBy.includes(currentUser._id)) {
      toast("success", "Already sent an email to the Principal system admin.");
      row.toggleRowExpanded();
      return;
    }
    try {
      setIsSubmitting(true);
      if (isApproved === "Yes") {
        // submit to other system admin for approval
        await dispatch(
          approveOrganization({ data: { corporateId: organization._id } })
        );
        if (approvedBy.length === 0) {
          toast(
            "success",
            "Email sent successfully to Principal system admin."
          );
        }
        if (approvedBy.length > 0) {
          toast(
            "success",
            `Both principals have approved ${organization.names.companyName} and now has a complete profile.`
          );
        }
      } else {
        await dispatch(
          updateCorporate({
            id: organization._id,
            data: { status: "Rejected" },
          })
        );
      }
      await dispatch(getCurrentUser());
      row.toggleRowExpanded();
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            organization: organization._id,
            // officialId: row.original._id,
            tabName: selectedTab.id,
          },
        })
      );
    }
  };

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "corporateSummary" && (
          <CorporateSummary
            addReviewComment
            currentTab={selectedTab.id}
            hideResolveAllCheckbox={row.original.status === "Active"}
            handleSaveComment={handleSaveComment}
          />
        )}
        {selectedTab.id === "addressParticulars" && (
          <AddressParticulars
            addReviewComment
            currentTab={selectedTab.id}
            hideResolveAllCheckbox={row.original.status === "Active"}
            handleSaveComment={handleSaveComment}
          />
        )}
        {selectedTab.id === "ownerships" && (
          <>
            {partners.length > 0 ? (
              <PartnersDetails
                addReviewComment
                data={partners}
                hideResolveAllCheckbox={row.original.status === "Active"}
              />
            ) : (
              <p className="mt-4 text-center">No data available</p>
            )}
          </>
        )}
        {selectedTab.id === "uploadDocuments" && (
          <>
            {fileUploadsComments.length > 0 && (
              <div className="px-8 pt-5 pb-6 bg-white mt-4">
                <CommentsList
                  comments={fileUploadsComments}
                  toggleCommentStatus
                />
                <p className="mt-6 text-sm text-primary">
                  Comments: {fileUploadsComments.length} of{" "}
                  {fileUploadsComments.length}
                </p>
              </div>
            )}
            <div className="px-8 pt-5 pb-6 mt-6 bg-white">
              <h3 className="mb-2 text-lg">Uploaded incorporation forms</h3>
              <Formik
                enableReinitialize
                initialValues={{
                  fileUploads: organization.fileUploads,
                }}
                onSubmit={() => null}
              >
                {() => {
                  return (
                    <div className="pl-4">
                      <Field
                        addReviewComment
                        currentTab={selectedTab.id}
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={getDocumentData(organization.idType)}
                        resolvedFields={_get(organization, "resolvedFields")}
                        onlyPreview
                        formName="uploadDocuments"
                        formHelperKey="corporateOfficial"
                        updateEffectKey="updateOrganizationState"
                        updateEffectPath="organizationsSlice.organization"
                        formId={_get(organization, "_id")}
                        showResolveAllFieldsCheckbox={
                          row.original.status !== "Active"
                        }
                        showDocumentLabel
                        handleSaveComment={handleSaveComment}
                        fileUploadComments={fileUploadsComments}
                      />
                    </div>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
        {selectedTab.id === "confirmation" && (
          <>
            {unresolvedComments.length > 0 ? (
              <>
                {comments.length > 0 && (
                  <div className="px-8 pt-5 pb-6 my-10 bg-white">
                    <CommentsList
                      comments={comments}
                      toggleCommentStatus
                      paginate
                    />
                  </div>
                )}
                <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                  <p className="mb-4">
                    The organization will be submitted back to the applicant
                    with remaining({unresolvedComments.length}) comments.{" "}
                  </p>
                </div>
              </>
            ) : (
              <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                {organization?.approvedBy?.length > 0 ? (
                  <p className="mt-4 whitespace-normal">
                    By agreeing to the above terms and conditions you will
                    approve the company profile making it complete.
                  </p>
                ) : (
                  <p className="mt-4 whitespace-normal">
                    By agreeing to the above terms and conditions you will
                    initiate an invite to the alternate system admin. They will
                    approve the company profile making it complete.
                  </p>
                )}
                <div className="px-4 space-y-2">
                  <div className="flex space-x-10">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="isApproved"
                        id="yesApproved"
                        value="Yes"
                        defaultChecked={isApproved === "Yes"}
                        onChange={handleIsApprovedChange}
                      />
                      <Label htmlFor="yesApproved" className="pl-4">
                        I approve this profile
                      </Label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="isApproved"
                        id="notApproved"
                        value="No"
                        defaultChecked={isApproved === "No"}
                        onChange={handleIsApprovedChange}
                      />
                      <Label htmlFor="notApproved" className="pl-4">
                        I don't approve this profile
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <>
          <div className="flex justify-between">
            {!isFirstStep && (
              <Button
                onClick={goToPrevStep}
                preIcon={ChevronLeftIcon}
                variant="text"
                className="my-4"
              >
                Go back
              </Button>
            )}
            {isLastStep ? (
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
                isLoading={isSubmitting}
                className="my-4"
                postIcon={ChevronRightIcon}
              >
                {["Active", "Rejected"].includes(row.original?.status)
                  ? "Close"
                  : unresolvedComments.length > 0
                  ? "Notify applicant"
                  : "Save and Proceed"}
              </Button>
            ) : (
              <Button
                onClick={goToNextStep}
                postIcon={ChevronRightIcon}
                className="my-4 ml-auto"
              >
                Review next step
              </Button>
            )}
          </div>
        </>
      </td>
    </tr>
  );
}

export default SubRowAsync;
