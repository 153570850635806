const EmptyRow = () => {
  return (
    <tr className="text-xs h-6">
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100 text-left"></td>
      <td className="p-1 text-gray-600 bg-gray-100 text-left"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
    </tr>
  );
};

export default EmptyRow;
