import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import userDataService from "services/user";

export const getTransactionsAsync = createAsyncThunk(
  "transactions/getTransactionsAsync",
  async (queryParams, thunkAPI) => {
    try {
      const res = await userDataService.getTransactions(queryParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  transactions: {
    items: [],
    totalCount: 0,
  },
  getTransactions: {
    status: "idle",
    error: false,
  },
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [getTransactionsAsync.pending]: (state) => {
      state.getTransactions.status = "loading";
      state.getTransactions.error = false;
    },
    [getTransactionsAsync.fulfilled]: (state, action) => {
      state.getTransactions.status = "idle";
      state.getTransactions.error = false;
      state.transactions = action.payload;
    },
    [getTransactionsAsync.rejected]: (state, action) => {
      state.getTransactions.status = "failed";
      state.getTransactions.error = action.payload.messageText;
    },
  },
});

export const { reset } = transactionsSlice.actions;
const { reducer } = transactionsSlice;

export default reducer;
