import React from "react";
import _merge from "lodash/merge";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import Cookies from "js-cookie";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import SectionDescription from "components/lib/Shared/SectionDescription";
import residentialDetailsValidation from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/residentialDetailsValidation";
import PostalCodeForm from "components/PLCTABS/CompanyOfficialsTab/PostalComponents/PostalCodeForm";
import PostalAddressFormLocal from "components/PLCTABS/CompanyOfficialsTab/PostalComponents/PostalAddressFormLocal";
import PostalAddressFormForeign from "components/PLCTABS/CompanyOfficialsTab/PostalComponents/PostalAddressFormForeign";
import ResidentialPhysicalAddressLoCo from "components/PLCTABS/CompanyOfficialsTab/AddressParticularsComponents/ResidentialAddressComponents/ResidentialPhysicalAddressLoCo";
import ResidentialPhysicalAddressFoCo from "components/PLCTABS/CompanyOfficialsTab/AddressParticularsComponents/ResidentialAddressComponents/ResidentialPhysicalAddressFoCo";
import {
  updateCorporate,
  updateOrganizationState,
} from "state/slices/organizations";
import Button from "components/lib/Shared/Button";
import { Constants } from "config/constants";

const formFields = [
  "isNonResident",
  "postalAddress",
  "electronicAddress",
  "residentialAddressDetails",
];

const AddressDetails = ({ handleBackBtn, handleNextBtn }) => {
  const organization =
    useSelector((state) => state.organizationsSlice.organization) || {};
  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const dispatch = useDispatch();
  const params = useParams();
  const cookieRole = Cookies.get("role");

  const isReadOnly = [
    Constants.COOKIE_ROLES.OFFICIAL,
    Constants.COOKIE_ROLES.CONTACT_PERSON,
  ].includes(cookieRole);

  const {
    idType,
    postalAddress,
    electronicAddress,
    residentialAddressDetails,
  } = organization;

  const handleSubmit = async (formData) => {
    formData = _omit(formData, "comments");
    if (isReadOnly) {
      handleNextBtn();
      return;
    }
    const updatedOfficialData = {};
    formFields.map((formField) => {
      if (!_isEqual(organization[formField], formData[formField])) {
        updatedOfficialData[formField] = formData[formField];
      }
    });
    if (!_isEmpty(updatedOfficialData)) {
      dispatch(updateOrganizationState(formData));
      await dispatch(
        updateCorporate({ id: params.corporateId, data: formData })
      );
    }
    handleNextBtn();
  };

  const { schemaInitialValues, schema } = residentialDetailsValidation({
    idType,
  });

  const initialValues = _merge(schemaInitialValues, {
    postalAddress,
    electronicAddress,
    residentialAddressDetails,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        comments: transformedComments,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <fieldset disabled={isReadOnly}>
              <SectionDescription
                id="Step: 3 of 5"
                title="Residential Details"
                description="Please provide the correct details. The information provided will be used to process your application."
              />
              {(idType === "Local Company" ||
                idType === "Other e.g Parastatals") && (
                <>
                  <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                    <Field
                      name="postalAddress"
                      component={PostalAddressFormLocal}
                      disabled={isReadOnly}
                    />
                    <Field
                      name="postalAddress"
                      component={PostalCodeForm}
                      disabled={isReadOnly}
                    />
                  </div>
                  <Field
                    name="residentialAddressDetails"
                    component={ResidentialPhysicalAddressLoCo}
                    disabled={isReadOnly}
                  />
                </>
              )}
              {idType === "Foreign Company" && (
                <>
                  <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                    <Field
                      name="postalAddress"
                      component={PostalAddressFormForeign}
                      disabled={isReadOnly}
                    />
                  </div>
                  <Field
                    name="residentialAddressDetails"
                    component={ResidentialPhysicalAddressFoCo}
                    disabled={isReadOnly}
                  />
                </>
              )}
            </fieldset>
            <div className="flex justify-between py-3">
              <Button
                variant="secondary"
                disabled={isSubmitting}
                onClick={handleBackBtn}
              >
                Go back
              </Button>
              <Button type="submit" isLoading={isSubmitting}>
                {isReadOnly ? "Next step" : "Save and proceed"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddressDetails;
