import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { EyeIcon, PencilIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";

import CommentsPopup from "./CommentsPopup";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { resolveFields, invalidateFields } from "state/slices/resolveField";
import { getComments, getProfileComments } from "state/slices/comment";
import UploadedFileView from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/UploadedFileView";
import { Constants } from "config/constants";
import OnlyOfficeDocumentEditor from "../Shared/OnlyOfficeDocumentEditor";
import { getFullName } from "utils";
import Button from "../Shared/Button";

export default function FormFieldsCard({
  fields = [],
  addReviewComment,
  handleSaveComment,
  formName,
  formId,
  formHelperKey,
  resolvedFields = [],
  updateEffectKey = "",
  updateEffectPath = "",
  comments = [],
  isProfileReview,
  rowId,
  hideResolveAllCheckbox,
  wrapperStyles = "mt-4",
  files = {},
  ...restProps
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRow] = useState([]);
  const [allFieldsResolved, setAllFieldsResolved] = useState(false);
  const currentUser = useSelector((state) => state.userSlice);
  const isReviewer = Cookies.get("role") === Constants.COOKIE_ROLES.REVIEWER;
  const history = useHistory();
  const dispatch = useDispatch();
  const [showEditor, setShowEditor] = useState(false);
  const [fileForPreview, setFileForPreview] = useState("");
  const isViewMode =
    history.location.pathname.split("/").pop() === "view" ||
    restProps.isViewMode;
  const isReviewerMode =
    history.location.pathname.split("/").pop() === "review";

  const toggleShowEditor = () => {
    setShowEditor(!showEditor);
  };

  const toggleFilePreview = (fileName) => {
    if (fileForPreview === fileName) setFileForPreview("");
    else setFileForPreview(fileName);
  };

  const handleResolveBtnClicked = async (fieldName) => {
    const commentsToBeResolved = comments?.filter(
      (comment) => comment.fieldName === fieldName && !comment.isResolved
    );
    const commentsToBeResolvedIds = commentsToBeResolved?.map(({ _id }) => _id);
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      resolveFields({
        data: {
          formId,
          formName: formHelperKey,
          fieldName,
          resolvedFields: [fieldName],
          commentsToBeResolvedIds,
        },
        updateEffectKey,
        updateEffectPath,
      })
    );
    if (commentsToBeResolvedIds.length > 0) {
      const query = {
        companyId: commentsToBeResolved[0]?.company,
        corporate: commentsToBeResolved[0]?.corporate,
      };
      if (isReviewerMode) query.createdBy = currentUser?._id;
      if (isProfileReview && rowId) {
        await dispatch(getProfileComments({ userId: rowId }));
      } else await dispatch(getComments(query));
    }
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const handleInvalidateBtnClicked = async (fieldName) => {
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      invalidateFields({
        data: {
          formId,
          formName: formHelperKey,
          fieldName,
          invalidFields: [fieldName],
        },
        updateEffectKey,
        updateEffectPath,
      })
    );
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const fieldsName = fields.map((field) => field.name).filter(Boolean);

  useEffect(() => {
    setAllFieldsResolved(
      fieldsName.every((element) => resolvedFields.includes(element))
    );
  }, [fieldsName, resolvedFields]);

  const handleAllFieldsStatus = async (e) => {
    const commentsToBeResolved = comments?.filter(
      (comment) => !comment.isResolved
    );
    const commentsToBeResolvedIds = commentsToBeResolved?.map(({ _id }) => _id);
    setIsLoading(true);
    if (e.target.checked) {
      // resolve/approve all the form fields
      const unresolvedFields = fieldsName.filter(
        (fieldName) => !resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [...selectedRows, ...unresolvedFields]);
      await dispatch(
        resolveFields({
          data: {
            formId,
            formName: formHelperKey,
            resolvedFields: unresolvedFields,
            commentsToBeResolvedIds,
          },
          updateEffectKey,
          updateEffectPath,
        })
      );
      if (commentsToBeResolvedIds.length > 0) {
        const query = {
          companyId: commentsToBeResolved[0]?.company,
          corporate: commentsToBeResolved[0]?.corporate,
        };
        if (isReviewerMode) query.createdBy = currentUser?._id;
        if (isProfileReview && rowId) {
          await dispatch(getProfileComments({ userId: rowId }));
        } else await dispatch(getComments(query));
      }
      setIsLoading(false);
      setSelectedRow([]);
    } else {
      // invalidate/disapprove all the form fields
      const resolvedFieldsSlice = fieldsName.filter((fieldName) =>
        resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [
        ...selectedRows,
        ...resolvedFieldsSlice,
      ]);
      await dispatch(
        invalidateFields({
          data: {
            formId,
            formName: formHelperKey,
            invalidFields: resolvedFieldsSlice,
          },
          updateEffectKey,
          updateEffectPath,
        })
      );
      setSelectedRow([]);
    }
    setIsLoading(false);
  };

  return (
    <div
      className={`px-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6 ${wrapperStyles}`}
    >
      <div
        className={`flex flex-col space-y-2 ${
          formName === "beneficialOwnershipForm" ? "pt-3" : ""
        }`}
      >
        {fields.map((field, index) => {
          const isRowLoading = isLoading && selectedRows.includes(field.name);
          const showCommentAndResolveSection =
            addReviewComment && field.name && !isViewMode;

          const CommentAndResolveSection = () => (
            <div className="flex items-center justify-between space-x-3 text-xs z-20 ml-auto my-auto">
              {field.showFilePreview && (
                <Button
                  variant="text"
                  className="w-32"
                  preIcon={EyeIcon}
                  onClick={() => toggleFilePreview(field.fileName)}
                >
                  {fileForPreview === field.fileName
                    ? "Hide Details"
                    : "View Details"}
                </Button>
              )}
              {!resolvedFields.includes(field.name) && (
                <CommentsPopup
                  handleSaveComment={handleSaveComment}
                  formName={formName}
                  fieldName={field.name}
                  fieldLabel={field.key}
                  comment={comments.find(
                    (comment) =>
                      comment.fieldName === field.name &&
                      currentUser?._id === comment.createdBy?._id
                  )}
                >
                  <span>Review</span>
                  <PencilIcon className="inline w-4 h-4" />
                </CommentsPopup>
              )}
              <div className={`flex items-center justify-center`}>
                {resolvedFields.includes(field.name) ? (
                  <Button
                    className={`flex space-x-1 ${
                      isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={() =>
                      isRowLoading
                        ? null
                        : handleInvalidateBtnClicked(field.name)
                    }
                    overrideBaseStyles
                  >
                    {isRowLoading ? (
                      <SpinnerIcon className="text-primary" />
                    ) : (
                      <>
                        <CheckIcon className="inline w-4 h-4" />
                        <span>Accepted </span>
                      </>
                    )}
                  </Button>
                ) : (
                  <Button
                    className={`flex space-x-1 ${
                      isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={() =>
                      isRowLoading ? null : handleResolveBtnClicked(field.name)
                    }
                    overrideBaseStyles
                  >
                    {isRowLoading ? (
                      <SpinnerIcon className="text-primary" />
                    ) : (
                      <>
                        <span>Accept</span>
                        <CheckIcon className="inline w-4 h-4" />
                      </>
                    )}
                  </Button>
                )}
              </div>
            </div>
          );

          if (typeof field.value === "object" && field.onlyRenderValue) {
            return (
              <React.Fragment key={`${field.key}_${index}`}>
                {field.value}
              </React.Fragment>
            );
          }

          if (
            typeof field.value === "object" &&
            formName === "beneficialOwnershipForm"
          ) {
            return (
              <React.Fragment key={`${field.key}_${index}`}>
                <div className={`flex items-baseline`}>
                  <label
                    htmlFor={field.name}
                    className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                  >
                    {field.key}
                  </label>
                  <>
                    <div
                      className={`bg-formInput flex ${
                        showCommentAndResolveSection ? "w-[60%]" : "w-full"
                      } justify-between relative ${
                        index === 0 ? "border-t-2 border-gray-300/50 pt-2" : ""
                      }`}
                    >
                      <div className="w-full relative right-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 top-[.8rem]">
                        {index === 0 && (
                          <span className="absolute -top-9">Direct</span>
                        )}
                        <p>
                          {typeof field.value.direct === "number"
                            ? `${field.value.direct}%`
                            : field.value.direct}
                        </p>
                      </div>
                      {index === 0 && (
                        <div className="h-[172px] z-10 top-[85px] absolute border-l-2 border-gray-/50 bg-formInput right-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 " />
                      )}
                      <div className="w-full relative right-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 top-[.8rem]">
                        {index === 0 && (
                          <span className="absolute -top-9">Indirect</span>
                        )}
                        <p>
                          {typeof field.value.indirect === "number"
                            ? `${field.value.indirect}%`
                            : field.value.indirect}
                        </p>
                      </div>
                    </div>
                    {showCommentAndResolveSection && (
                      <CommentAndResolveSection />
                    )}
                  </>
                </div>
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={`${field.key}_${index}`}>
              <div className="flex items-baseline justify-between">
                <label
                  htmlFor={field.name}
                  className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                >
                  {field.key}
                </label>
                {typeof field.value === "object" &&
                formName !== "beneficialOwnershipForm" ? (
                  <>
                    {field.value}
                    {!isViewMode && (
                      <CommentAndResolveSection wrapperStyles="text-xs" />
                    )}
                  </>
                ) : field.key === "Articles of association" ? (
                  <>
                    <div className="flex justify-between w-full text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300">
                      <input
                        disabled
                        name={field.name}
                        value={""}
                        className="w-full p-2 text-sm "
                      />
                      <button
                        onClick={toggleShowEditor}
                        className="w-1/4 bg-formInput"
                      >
                        <EyeIcon className="inline w-3 h-3 mr-1" />
                        {showEditor ? "Hide Details" : "View Details"}
                      </button>
                      {!isViewMode && (
                        <CommentAndResolveSection wrapperStyles="text-xs" />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <p className="w-full p-2 text-sm whitespace-normal rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300">
                      {field.value}
                    </p>
                    {showCommentAndResolveSection && (
                      <CommentAndResolveSection />
                    )}
                  </>
                )}
              </div>
              {showEditor && field.key === "Articles of association" && (
                <div className="block text-center">
                  <OnlyOfficeDocumentEditor
                    userFullName={getFullName(currentUser.names)}
                    mode={isReviewer ? "view" : "edit"}
                    userId={currentUser._id}
                    {...field.editorParams}
                    renderSummary
                    SummaryOfArticles={field.SummaryOfArticles}
                  />
                </div>
              )}
              {field.showFilePreview && fileForPreview === field.fileName && (
                <div className="block w-full text-center max-h-[40vh] overflow-auto">
                  {
                    <UploadedFileView
                      file={files[field.fileName]}
                      modalName={field.fileName}
                      renderInModal={false}
                    />
                  }
                </div>
              )}
            </React.Fragment>
          );
        })}
        {!hideResolveAllCheckbox && !isViewMode && (
          <div className="flex items-center">
            <input
              aria-describedby="approve-disapprove"
              type="checkbox"
              className="mr-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              onChange={handleAllFieldsStatus}
              checked={allFieldsResolved}
            />
            <p
              id="approve-disapprove"
              className="flex items-center text-sm font-semibold"
            >
              Click this checkbox to{" "}
              {allFieldsResolved ? "disapprove" : "approve"} all entries if they
              are {allFieldsResolved ? "incorrect" : "correct"} then proceed to
              next step
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
