import _forOwn from "lodash/forOwn";
import { object, mixed } from "yup";

/**
 * This returns an object composed of conditional `yup` schema and initial values
 * related to upload documents form.
 *
 * @param {object[]} documentData - The array of objects related to file uploads.
 * @param {string} documentData[].name - Name of the document.
 * @param {string} documentData[].label - Label of the document.
 * @return {object} This returns object containing schema and initial values based on schema.
 * @example
 *  {
 *    schema
 *    schemaInitialValues
 *  }
 */
const uploadDocumentsValidation = (documentData) => {
  const getFileValidationSchema = () => {
    const fileUploadsSchema = {};
    _forOwn(documentData, ({ name, label, optional = false }) => {
      fileUploadsSchema[name] = mixed().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional || optional,
        then: mixed().optional(),
        otherwise: mixed().when("$areCSFieldsOptional", {
          is: (areCSFieldsOptional) =>
            areCSFieldsOptional && name === "practicingCert",
          then: mixed().optional(),
          otherwise: mixed().required(`${label} is required`),
        }),
      });
    });
    return fileUploadsSchema;
  };

  const schema = object().shape({
    fileUploads: object(getFileValidationSchema()),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default uploadDocumentsValidation;
