import React, { useState, useEffect, useMemo } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { EyeIcon } from "@heroicons/react/outline";

import Table from "components/lib/Global/ReactTable";
import { getFullName } from "utils";
import PartnerSubRow from "./PartnerSubRow";

function PartnersTable({
  data,
  hideResolveAllCheckbox,
  addReviewComment,
  isDashboard,
  toggleCommentStatus,
  readOnly,
}) {
  const [expandedRow, setExpandedRow] = useState({});

  const columns = useMemo(
    () => [
      {
        Header: () => "Name",
        id: "Name",
        accessor: "fullName",
        Cell: ({
          row: {
            original: { names },
          },
        }) => {
          return getFullName(names) || "N/A";
        },
        // We can render something different for subRows
        SubCell: (cellProps) => <>{cellProps.value}</>,
      },
      {
        Header: "Role",
        Cell: ({ row: { original } }) => {
          return original?.role?.join(", ") ?? "";
        },
      },
      {
        Header: () => "ID Type",
        id: "ID Type",
        accessor: "idType",
      },
      {
        // Make an expander cell
        Header: () => "Actions", // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => {
          const originalRow = row.original;
          let onClick = () => {
            setExpandedRow(row);
            row.getToggleRowExpandedProps().onClick();
          };
          return (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span
              className="text-sm font-normal text-primary"
              {...row.getToggleRowExpandedProps()}
              onClick={onClick}
            >
              <EyeIcon className="inline w-4 h-4 mr-1 text-tertiary" />
              {originalRow.isExpanded ? "Hide Details" : "view details"}
            </span>
          );
        },
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },
    ],
    [setExpandedRow]
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <PartnerSubRow
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        hideResolveAllCheckbox={hideResolveAllCheckbox}
        addReviewComment={addReviewComment}
        isDashboard={isDashboard}
        toggleCommentStatus={toggleCommentStatus}
        readOnly={readOnly}
      />
    ),
    [isDashboard]
  );

  useEffect(() => {
    if (expandedRow?.isExpanded) expandedRow?.toggleRowExpanded();
  }, []);

  return (
    <div className="w-full overflow-x-auto">
      <Table
        columns={columns}
        data={data}
        headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
        headingStyle="pl-4 text-left text-white"
        renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
}

export default PartnersTable;
