/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MinusIcon } from "@heroicons/react/outline";
import Badge from "components/lib/Shared/Badge";

import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { deleteReviewer } from "state/slices/tables/reviewersTable";
import { getFullName } from "utils";
import AvatarIcon from "components/lib/Shared/Icons/AvatarIcon";

function AssignReviewerSubRow({ row }) {
  const dispatch = useDispatch();
  const { reviewers } = useSelector((state) => state.reviewersTable);
  const { company } = useSelector((state) => state.companySlice);
  const [isLoading, setIsLoading] = useState(false);

  const childReviewer = reviewers.find(
    (reviewer) => reviewer?.representativeOf === row.original?._id
  );

  const handleDeleteReviewer = async (reviewer) => {
    setIsLoading(true);
    await dispatch(
      deleteReviewer({ id: reviewer?._id, companyId: company._id })
    );
    row.toggleRowExpanded();
    setIsLoading(false);
  };

  if (!childReviewer) return null;

  const reviewerName =
    getFullName(childReviewer?.user?.names) || childReviewer.name;
  const profilePicture = childReviewer?.user?.fileUploads?.passportPhoto;

  return (
    <tr className="bg-[#EDF0F5]">
      <td></td>
      <td>
        <div className="flex items-center pl-16 space-x-1 w-52 py-4">
          {profilePicture ? (
            <img
              className="w-8 h-8 rounded-full"
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${profilePicture}`}
              alt={reviewerName}
            />
          ) : (
            <div className="w-8 h-8 rounded-full">
              <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <AvatarIcon />
              </div>
            </div>
          )}
          <div className="px-3">
            <h1 className="font-bold">{reviewerName}</h1>
            <p>{childReviewer.email}</p>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 pl-8 text-sm font-semibold text-primary">
        {childReviewer.role}
      </td>
      <td className="px-6 py-4 pl-4 text-sm font-semibold text-primary">
        <Badge
          bgColor="bg-green-400"
          textColor="text-gray-800"
          text={"Alternate reviewer"}
        />
      </td>
      <td className="px-6 py-4 pl-4 text-sm font-semibold text-primary">
        <button
          onClick={() => handleDeleteReviewer(childReviewer)}
          disabled={isLoading}
          className={`inline-flex items-center justify-center space-x-2 text-sm font-medium rounded-md text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed ${
            isLoading && "outline-none"
          }`}
        >
          {isLoading ? (
            <SpinnerIcon className="text-primary" />
          ) : (
            <>
              <MinusIcon className="w-4 h-4" aria-hidden="true" />
              <span>Remove as reviewer</span>
            </>
          )}
        </button>
      </td>
    </tr>
  );
}

export default AssignReviewerSubRow;
