import { useSelector } from "react-redux";
import _uniqBy from "lodash/uniqBy";

import Table from "components/lib/Global/ReactTable";
import { getFullName } from "utils";
import { INDIVIDUALS } from "../SFLdata/config/constants";
import AvatarIcon from "components/lib/Shared/Icons/AvatarIcon";

function AssignedReviewer() {
  const { reviewers } = useSelector((state) => state.reviewersTable);

  const columns = [
    {
      id: "expander", // Make sure it has an ID
      Header: "Name",
      Cell: ({ row: { original } }) => {
        if (!original.user) return original.name ?? "N/A";
        const {
          _id,
          names,
          electronicAddress,
          fileUploads,
          idType,
          identificationDetails,
        } = original.user;
        let fullName = getFullName(names) || "N/A";
        const hasChildReviewer = reviewers.find(
          (reviewer) => reviewer?.representativeOf === _id
        );
        const emailAddress = INDIVIDUALS.includes(idType)
          ? electronicAddress?.emailAddress
          : identificationDetails?.emailAddress;
        return (
          <div className="flex items-center space-x-1 w-52">
            <div
              className={`flex-shrink-0 ${hasChildReviewer ? "pl-6" : "pl-6"}`}
            >
              {fileUploads?.passportPhoto ? (
                <img
                  className="w-8 h-8 rounded-full"
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${fileUploads.passportPhoto}`}
                  alt={fullName}
                />
              ) : (
                <div className="w-8 h-8 rounded-full">
                  <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <AvatarIcon />
                  </div>
                </div>
              )}
            </div>
            <div className="px-3">
              <h1 className="mb-2 font-bold">{fullName}</h1>
              <p>{emailAddress}</p>
            </div>
          </div>
        );
      },
    },
    { Header: "ID Type", accessor: "official.idType" },
    {
      Header: "Role",
      Cell: ({ row }) => {
        const { official } = row.original;
        if (!official?.idType) return row.original?.role ?? "";
        if (
          official.isDirector === "Yes" &&
          official.designation === "Joint Shareholder"
        )
          return "Director";
        return official.designation;
      },
    },
  ];

  if (reviewers.length === 0) {
    return <div className="w-full text-center pt-2">No reviewers assigned</div>;
  }

  return (
    <div className="w-full bg-white shadow mt-6">
      <div className="w-full">
        <Table
          columns={columns}
          data={_uniqBy(reviewers, "email")}
          headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
          headingStyle="pl-4 text-left"
          dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          dataRowCellStyle="pl-4"
        />
      </div>
    </div>
  );
}

export default AssignedReviewer;
