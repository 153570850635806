import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import CommentsList from "components/lib/Global/CommentsList";
import { addComment, editComment } from "state/slices/comment";

export default function AddressParticulars({ isViewMode }) {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companySlice);
  const { comments } = useSelector((state) => state.commentsSlice);
  const addressParticularsComments = comments.filter(
    (comment) => comment.formName === "addressParticulars"
  );
  const registeredOfficeSlice = useSelector(
    (state) => state.registeredOfficeSlice
  );

  const addressParticularsFormFields = [
    {
      key: "Postal Address",
      name: "postalAddress",
      value: _get(registeredOfficeSlice, "postalAddress") || "N/A",
    },
    {
      key: "Postal Code",
      name: "postalCode",
      value: _get(registeredOfficeSlice, "postalCode") || "N/A",
    },
    {
      key: "Service Address",
      name: "serviceAddress",
      value: _get(registeredOfficeSlice, "serviceAddress") || "N/A",
    },
    {
      key: "Company Email Address",
      name: "companyEmailAddress",
      value: _get(registeredOfficeSlice, "companyEmailAddress") || "N/A",
    },
    {
      key: "Phone Number",
      name: "mobileNumber",
      value: _get(registeredOfficeSlice, "mobileNumber") || "N/A",
    },
    {
      key: "Name of Building",
      name: "buildingName",
      value: _get(registeredOfficeSlice, "buildingName") || "N/A",
    },
    {
      key: "House Number",
      name: "houseNumber",
      value: _get(registeredOfficeSlice, "houseNumber") || "N/A",
    },
    {
      key: "Estate",
      name: "estate",
      value: _get(registeredOfficeSlice, "estate") || "N/A",
    },
    {
      key: "Floor or Suite Number",
      name: "floorNumber",
      value: _get(registeredOfficeSlice, "floorNumber") || "N/A",
    },
    {
      key: "Street Name",
      name: "streetName",
      value: _get(registeredOfficeSlice, "streetName") || "N/A",
    },
    {
      key: "Street Number",
      name: "streetNumber",
      value: _get(registeredOfficeSlice, "streetNumber") || "N/A",
    },
    {
      key: "County",
      name: "location.county",
      value: _get(registeredOfficeSlice, "location.county") || "N/A",
    },
    {
      key: "District",
      name: "location.district",
      value: _get(registeredOfficeSlice, "location.district") || "N/A",
    },
    {
      key: "Locality",
      name: "location.locality",
      value: _get(registeredOfficeSlice, "location.locality") || "N/A",
    },
  ];

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            company: company._id,
          },
        })
      );
    }
  };

  return (
    <>
      {addressParticularsComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 mt-4 bg-white">
          <CommentsList
            comments={addressParticularsComments}
            toggleCommentStatus={!isViewMode}
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {addressParticularsComments.length} of{" "}
            {addressParticularsComments.length}
          </p>
        </div>
      )}
      <div className="flex my-10 align-middle">
        <div className="flex items-center form-check">
          <input
            className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            type="checkbox"
            defaultChecked={registeredOfficeSlice?.useSimpleFormationsOffice}
            disabled
            id="flexCheckDefault"
          />
          <label
            className="inline-block form-check-label text-tertiary"
            htmlFor="flexCheckDefault"
          >
            Use Simple Formations office details as your registered business
            address in Kenya.
          </label>
        </div>
      </div>
      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="addressParticulars"
        fields={addressParticularsFormFields}
        resolvedFields={registeredOfficeSlice.resolvedFields}
        formHelperKey="registeredOffice"
        updateEffectKey="updateCompanyRegisteredOffice"
        formId={_get(registeredOfficeSlice, "_id")}
        handleSaveComment={handleSaveComment}
        comments={addressParticularsComments}
        hideResolveAllCheckbox={isViewMode}
      />
    </>
  );
}
