import Cookies from "js-cookie";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SystemAdminDashboard from "./SystemAdminDashboard";
import { Constants } from "config/constants";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

const DashboardAgainstRole = () => {
  const history = useHistory();
  const role = Cookies.get("role");

  useEffect(() => {
    if (
      !role ||
      [
        Constants.COOKIE_ROLES.APPLICANT,
        Constants.COOKIE_ROLES.REVIEWER,
        Constants.COOKIE_ROLES.SUPER_ADMIN,
      ].includes(role)
    ) {
      history.push("/");
    }
  }, [history, role]);

  switch (role) {
    // case Constants.COOKIE_ROLES.APPLICANT:
    //   return <UserDashboard />;
    // case Constants.COOKIE_ROLES.REVIEWER:
    //   return <ReviewerDashboard />;
    // case Constants.COOKIE_ROLES.SUPER_ADMIN:
    //   return <AdminDashboard />;
    case Constants.COOKIE_ROLES.SYSTEM_ADMIN:
      return <SystemAdminDashboard />;
    default:
      return (
        <div className="flex justify-center items-center h-screen">
          <SpinnerIcon className="text-gray-500" />
        </div>
      );
  }
};

export default DashboardAgainstRole;
