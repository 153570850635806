const counties = [
    {
      value: 'Baringo',
      label: 'Baringo',
    },
    {
      value: 'Bungoma',
      label: 'Bungoma',
    },
    {
      value: 'Bomet',
      label: 'Bomet',
    },
    {
      value: 'Busia',
      label: 'Busia',
    },
    {
      value: 'Elgeyo/Marakwet',
      label: 'Elgeyo/Marakwet',
    },
    {
      value: 'Embu',
      label: 'Embu',
    },
    {
      value: 'Garissa',
      label: 'Garissa',
    },
    {
      value: 'Homa Bay',
      label: 'Homa Bay',
    },
    {
      value: 'Isiolo',
      label: 'Isiolo',
    },
    {
      value: 'Kajiado',
      label: 'Kajiado',
    },
    {
      value: 'Kakamega',
      label: 'Kakamega',
    },
    {
      value: 'Kericho',
      label: 'Kericho',
    },
    {
      value: 'Kiambu',
      label: 'Kiambu',
    },
    {
      value: 'Kilifi',
      label: 'Kilifi',
    },
    {
      value: 'Kirinyaga',
      label: 'Kirinyaga',
    },
    {
      value: 'Kisii',
      label: 'Kisii',
    },
    {
      value: 'Kisumu',
      label: 'Kisumu',
    },
    {
      value: 'Kitui',
      label: 'Kitui',
    },
    {
      value: 'Kwale',
      label: 'Kwale',
    },
    {
      value: 'Laikipia',
      label: 'Laikipia',
    },
    {
      value: 'Lamu',
      label: 'Lamu',
    },
    {
      value: 'Machakos',
      label: 'Machakos',
    },
    {
      value: 'Makueni',
      label: 'Makueni',
    },
    {
      value: 'Mandera',
      label: 'Mandera',
    },
    {
      value: 'Marsabit',
      label: 'Marsabit',
    },
    {
      value: 'Meru',
      label: 'Meru',
    },
    {
      value: 'Migori',
      label: 'Migori',
    },
    {
      value: 'Mombasa',
      label: 'Mombasa',
    },
    {
      value: 'Muranga',
      label: 'Muranga',
    },
    {
      value: 'Nairobi',
      label: 'Nairobi',
    },
    {
      value: 'Nakuru',
      label: 'Nakuru',
    },
    {
      value: 'Nandi',
      label: 'Nandi',
    },
    {
      value: 'Narok',
      label: 'Narok',
    },
    {
      value: 'Nyamira',
      label: 'Nyamira',
    },
    {
      value: 'Nyandarua',
      label: 'Nyandarua',
    },
    {
      value: 'Nyeri',
      label: 'Nyeri',
    },
    {
      value: 'Samburu',
      label: 'Samburu',
    },
    {
      value: 'Siaya',
      label: 'Siaya',
    },
    {
      value: 'Taita Taveta',
      label: 'Taita Taveta',
    },
    {
      value: 'Tana River',
      label: 'Tana River',
    },
    {
      value: 'Tharaka Nithi',
      label: 'Tharaka Nithi',
    },
    {
      value: 'Trans Nzoia',
      label: 'Trans Nzoia',
    },
    {
      value: 'Turkana',
      label: 'Turkana',
    },
    {
      value: 'Uasin Gishu',
      label: 'Usain Gishu',
    },
    {
      value: 'Vihiga',
      label: 'Vihiga',
    },
    {
      value: 'Wajir',
      label: 'Wajir',
    },
    {
      value: 'West Pokot',
      label: 'West Pokot',
    },
]

const postalCodes = [
  { value: '00100 - G.P.O NAIROBI', label: '00100 - G.P.O NAIROBI' },
  { value: '00101-  JAMIA -NAIROBI', label: '00101 - JAMIA -NAIROBI' },
  { value: '00200 - CITY SQUARE - NAIROBI', label: '00200 - CITY SQUARE - NAIROBI' },
  {
    value: '00202 - KENYATTA N.HOSPITAL-NAIROBI',
    label: '00202 - KENYATTA N.HOSPITAL-NAIROBI',
  },
  { value: '00204 - ATHI-RIVER', label: '00204 - ATHI-RIVER' },
  { value: '00205 - MAGADI ', label: '00205 - MAGADI ' },
  { value: '00206 - KISERIAN ', label: '00206 -KISERIAN ' },
  { value: '00207 - NAMANGA ', label: '00207 - NAMANGA ' },
  { value: '00208 - NGONG HILLS ', label: '00208 - NGONG HILLS ' },
  { value: '00209 - LOITOKITOK  ', label: '00209 - LOITOKITOK ' },
  { value: '00216 - GITHUNGURI ', label: '00216 - GITHUNGURI ' },
  { value: '00217 - LIMURU ', label: '00217 - LIMURU ' },
  { value: '00218 - NGECHA ', label: '00218 - NGECHA ' },
  { value: '00219 - KARURI ', label: '00219 - KARURI ' },
  { value: '00220 - KIJABE ', label: '00220 - KIJABE ' },
  { value: '00221 - MATATHIA ', label: '00221 - MATATHIA ' },
  { value: '00222 - UPLANDS ', label: '00222 - UPLANDS ' },
  { value: '00223 - KAGWE ', label: '00223 - KAGWE ' },
  { value: '00227 - KINARI ', label: '00227 - KINARI ' },
  { value: '00229 - NDERU ', label: '00229 - NDERU ' },
  { value: '00232 - RUIRU ', label: '00232 - RUIRU ' },
  { value: '00240 - GATHUGU ', label: '00240 - GATHUGU ' },
  { value: '00241 - KITENGELA ', label: '00241 - KITENGELA ' },
  { value: '00300 - RONALD NGALA ST. -NAIROBI ', label: '00300 - RONALD NGALA ST.-NAIROBI ' },
  { value: '00400 - TOM MBOYA ST-NAIROBI ', label: '00400 - TOM MBOYA ST-NAIROBI ' },
  { value: '00500 - ENTERPRISE ROAD -NAIROBI ', label: '00500 - ENTERPRISE ROAD -NAIROBI ' },
  { value: '00501 - J.K.I.AIRPORT -NAIROBI ', label: '00501 - J.K.I.AIRPORT -NAIROBI  ' },
  { value: '00502 - KAREN-NAIROBI ', label: '00502 - KAREN-NAIROBI ' },
  { value: '00503 - MBAGATHI-NAIROBI ', label: '00501 -MBAGATHI-NAIROBI ' },
  { value: '00504 - MCHUMBI RD-NAIROBI ', label: '00504 - MCHUMBI RD-NAIROBI ' },
  { value: '00505 - NGONG ROAD-NAIROBI ', label: '00505 - NGONG ROAD-NAIROBI ' },
  { value: '00506 - NYAYO STADIUM-NAIROBI ', label: '00506 - NYAYO STADIUM-NAIROBI ' },
  { value: '00507 - VIWANDANI-NAIROBI ', label: '00507 - VIWANDANI-NAIROBI ' },
  { value: '00508 - YAYA TOWERS-NAIROBI ', label: '00508 - YAYA TOWERS-NAIROBI ' },
  { value: '00509 - LANGATA-NAIROBI ', label: '00509 - LANGATA-NAIROBI ' },
  { value: '00510 - MAKONGENI-NAIROBI ', label: '00510 - MAKONGENI-NAIROBI ' },
  { value: '00511 - ONGATA RONGAI ', label: '00511 - ONGATA RONGAI ' },
  { value: '00515 - BURUBURU -NAIROBI ', label: '00515 - BURUBURU -NAIROBI ' },
  { value: '00516 - DANDORA - NAIROBI ', label: '00516 - DANDORA - NAIROBI ' },
  { value: '00517 - UHURU GARDENS-NAIROBI ', label: '00517 - UHURU GARDENS-NAIROBI ' },
  { value: '00518 - KAYOLE-NAIROBI ', label: '00518 -KAYOLE-NAIROBI ' },
  { value: '00519 - MLOLONGO ', label: '00519 - MLOLONGO ' },
  { value: '00520 - RUAI-NAIROBI ', label: '00520 - RUAI-NAIROBI ' },
  { value: '00521 - EMBAKASI -NAIROBI ', label: '00521 - EMBAKASI -NAIROBI ' },
  { value: '00600 - NGARA ROAD-NAIROBI ', label: '00600 - NGARA ROAD-NAIROBI ' },
  { value: '00601 -GIGIRI -NAIROBI ', label: '00601 - GIGIRI -NAIROBI  ' },
  { value: '00603 - LAVINGTON-NAIROBI ', label: '00603 - LAVINGTON-NAIROBI ' },
  { value: '00604 - LOWER KABETE-NAIROBI ', label: '00604 - LOWER KABETE-NAIROBI ' },
  { value: '00605 - UTHIRU-NAIROBI ', label: '00605 - UTHIRU-NAIROBI ' },
  { value: '00606 - SARIT CENTER-NAIROBI ', label: '00606 - SARIT CENTER-NAIROBI ' },
  { value: '00607 -KAMITI -NAIROBI ', label: '00607 - KAMITI -NAIROBI ' },
  { value: '00607 -KASARANI -NAIROBI ', label: '00607 - KASARANI -NAIROBI ' },
  {
    value: '00609 - KENYATTA UNIVERSITY-NAIROBI ',
    label: '00609 - KENYATTA UNIVERSITY-NAIROBI ',
  },
  { value: '00610 - EASTLEIGH - NAIROBI ', label: '00610 - EASTLEIGH - NAIROBI ' },
  { value: '00611 - MATHARE VALLEY-NAIROBI ', label: '00611 - MATHARE VALLEY-NAIROBI ' },
  { value: '00614 - WANGIGE ', label: '00614 - WANGIGE ' },
  { value: '00618 - RUARAKA-NAIROBI ', label: '00618 - RUARAKA-NAIROBI ' },
  { value: '00619 - MUTHAIGA-NAIROBI ', label: '00619 - MUTHAIGA-NAIROBI ' },
  { value: '00620 - MOBIL PLAZA-NAIROBI ', label: '00620 - MOBIL PLAZA-NAIROBI ' },
  { value: '00621 - VILLAGE MARKET-NAIROBI ', label: '00621 - VILLAGE MARKET-NAIROBI ' },
  { value: '00622 - JUJA-NAIROBI ', label: '00622 - JUJA-NAIROBI ' },
  { value: '00623 - PARKLANDS-NAIROBI ', label: '00623 - PARKLANDS-NAIROBI ' },
  { value: '00625 - KANGEMI-NAIROBI ', label: '00625 - KANGEMI-NAIROBI ' },
  { value: '00800 - WESTLANDS-NAIROBI ', label: '00800 - WESTLANDS-NAIROBI ' },
  { value: '00900 - KIAMBU ', label: '00900 - KIAMBU ' },
  { value: '00901 - NGEWA ', label: '00901 - NGEWA ' },
  { value: '00902 - KIKUYU ', label: '00902 - KIKUYU ' },
  { value: '01000 - THIKA ', label: '01000 - THIKA ' },
  { value: '01001 - KALIMONI ', label: '01001 - KALIMONI ' },
  { value: '01002 - MADARAKA ', label: '01002 - MADARAKA ' },
  { value: '01003 - GITUAMBA ', label: '01003 - GITUAMBA ' },
  { value: '01004 - KANJUKU ', label: '01004 - KANJUKU ' },
  { value: '01013- GATURA ', label: '01013 - GATURA ' },
  { value: '01015 - ITHANGA ', label: '01015 - ITHANGA ' },
  { value: '01016 - NDITHINI ', label: '01016 - NDITHINI ' },
  { value: '01018 - KIRWARA ', label: '01018 - KIRWARA ' },
  { value: '01020 - KENOL ', label: '01020 - KENOL ' },
  { value: '01027 - DONYOSABUK  ', label: '01027 - DONYOSABUK ' },
  { value: '01028 - GATUKUYU ', label: '01028 - GATUKUYU ' },
  { value: '01030 - GATUNDU ', label: '01030 -  GATUNDU' },
  { value: '01031 - KINDARUMA ', label: '01031 -KINDARUMA ' },
  { value: '01033 - KAGUNDU-INI ', label: '01033 - KAGUNDU-INI ' },
  { value: '01034 - KANDARA ', label: '01034 - KANDARA ' },
  { value: '01100 - KAJIADO ', label: '01100 - KAJIADO ' },
  { value: '01101 - BISSEL ', label: '01101 - BISSEL ' },
  { value: '01103 - MASHURU ', label: '01103 - MASHURU ' },
  { value: '10100 - NYERI ', label: '10100 - NYERI ' },
  { value: '10101 - KARATINA ', label: '10101 - KARATINA ' },
  { value: '10102 - KIGANJO ', label: '10102 - KIGANJO ' },
  { value: '10103 - MUKURWEINI ', label: '10103 - MUKURUWEINI ' },
  { value: '10104 - MWEIGA ', label: '10104 - MWEIGA ' },
  { value: '10105 - NARO MORU ', label: '10105 - NARO MORU ' },
  { value: '10106 - OTHAYA ', label: '10106 - OTHAYA ' },
  { value: '10107 - ENDARASHA ', label: '10107 - ENDARASHA ' },
  { value: '10108 - GIAKANJA ', label: '10108 - GIAKANJA ' },
  { value: '10109 - GAKERE ROAD ', label: '10109 -  GAKERE ROAD' },
  { value: '10111 - GAKINDU ', label: '10111 - GAKINDU ' },
  { value: '10114 - GATITU ', label: '10114 - GATITU ' },
  { value: '10122 - KIAMARIGA ', label: '10122 - KIAMARAIGA ' },
  { value: '10129 - MUGUNDA ', label: ' 10129 -MUGUNDA ' },
  { value: '10133 - RURINGU ', label: '10133 - RURINGU ' },
  { value: '10140 - KIMATHI WAY ', label: '10140 - KIMATHI WAY ' },
  { value: '10200 - MURANGA ', label: '10200 - MURANGA ' },
  { value: '10201 - KAHURO ', label: '10201 - KAHURO ' },
  { value: '10202 - KANGEMA ', label: '10202 - KANGEMA ' },
  { value: '10203 - KIGUMO ', label: '10203 - KIGUMO ' },
  { value: '10204 - KIRIANI ', label: '10204 - KIRIANI ' },
  { value: '10205 - MARAGUA ', label: '10205 - MARAGUA' },
  { value: '10206 - KAHUHIA ', label: '10206 - KAHUHIA ' },
  { value: '10207 - KIHOYA ', label: '10207 - KIHOYA ' },
  { value: '10208 - SABA-SABA ', label: '10208 - SABA-SABA ' },
  { value: '10209 - GITUGI ', label: '10209 - GITUGI ' },
  { value: '10210 - GACHARAGE-INI ', label: '10210 - GACHARAGE-INI ' },
  { value: '10213 - GIKOE ', label: '10213 - GIKOE ' },
  { value: '10214 - KAHUTI ', label: '10214 - KAHUTI ' },
  { value: '10218 - KANGARI ', label: '10218 - KANGARI ' },
  { value: '10226 - KAMBITI ', label: '10226 - KAMBITI ' },
  { value: '10230 - SAGANA ', label: '10230 - SAGANA ' },
  { value: '10233 - MBIRI ', label: '10233 - MBIRI ' },
  { value: '10234 - KORA ', label: '10234 - KORA ' },
  { value: '10239 - GAKUNGU ', label: '10239 - GAKUNGU ' },
  { value: '10300 - KERUGOYA ', label: '10300 - KERUGOYA ' },
  { value: '10301 - KIANYAGA ', label: '10301 - KIANGAYA ' },
  { value: '10302 - BARICHO ', label: '10302 - BARICHO ' },
  { value: '10303 - WANGURU ', label: '10303 - WANGURU ' },
  { value: '10304 - KUTUS ', label: '10304 - KUTUS' },
  { value: '10306 - KAGIO ', label: '10306 - KAGIO' },
  { value: '10307 - KAGUMO ', label: '10307 - KAGUMO ' },
  { value: '10309 - KIAMUTUGU ', label: '10309 - KIAMUTUGU ' },
  { value: '10310 - KIMBIMBI ', label: '10310 - KIMBIMBI ' },
  { value: '10400 - NANYUKI ', label: '10400 - NANYUKI ' },
  { value: '10401 - DOLDOL ', label: '10401 - DOLDOL ' },
  { value: '10406 - TIMAU ', label: '10406 - TIMAU ' },
  { value: '20100 - NAKURU ', label: '20100 - NAKURU ' },
  { value: '20101 - BONDENI ', label: '20101 - BONDENI ' },
  { value: '20102 - ELBURGON  ', label: '20102 - ELBURGON ' },
  { value: '20103 - ELDAMA RAVINE ', label: '20103 - ELDAMA RAVINE ' },
  { value: '20104 - MENENGAI ', label: '20104 - MENENGAI ' },
  { value: '20105 - MOGOTIO ', label: '20106 - MOGOTIO ' },
  { value: '20106 - MOLO ', label: '20106 - MOLO ' },
  { value: '20107 - NJORO ', label: '20107 - NJORO ' },
  { value: '20108 - RONGAI ', label: '20108 - RONGAI ' },
  { value: '20109-  SUBUKIA', label: '20109 - SUBUKIA ' },
  { value: '20110 - TIMBER MILLS ', label: '20110 - TIMBER MILLS ' },
  { value: '20112 - LANET ', label: '20112 - LANET ' },
  { value: '20113 - BAHATI ', label: '20113 - BAHATI ' },
  { value: '20114 - KABAZI ', label: '20114 - KABAZI ' },
  { value: '20115 - EGERTON ', label: '20115 - EGERTON ' },
  { value: '20116 - GILGIL ', label: '20116 - GILGIL ' },
  { value: '20117 - NAIVASHA ', label: '20117 - NAIVASHA ' },
  { value: '20123 - MILTON SIDING ', label: '20123 - MILTON SIDING ' },
  { value: '20124 - MIRANGINE ', label: '20124 - MIRANGINE ' },
  { value: '20128 - SOLAI ', label: '20128 - SOLAI ' },
  { value: '20131 - KERINGET ', label: '20131 - KERINGET ' },
  { value: '20133 - KIPTANGWANYI ', label: '20133 - KIPTANGWANYI ' },
  { value: '20134 - KAMARA ', label: '20134 - KAMARA ' },
  { value: '20142 - NAISHI ', label: '20142 - NAISHI ' },
  { value: '20147 - MAAI MAHIU ', label: '20147 - MAAI MAHIU ' },
  { value: '20151 - SULMAC ', label: '20151- SULMAC' },
  { value: '20152 - OLENGURUONE ', label: '20152 - OLENGURUONE' },
  { value: '20157 - KABARAK ', label: '20157 - KABARAK ' },
  { value: '20200 - KERICHO ', label: '20200 - KERICHO ' },
  { value: '20201 - KABIANGA ', label: '20201 - KABIANGA ' },
  { value: '20202 - KIPLEKION ', label: '20202 - KIPLEKION ' },
  { value: '20203 - LONDIANI ', label: '20203 - LONDIANI ' },
  { value: '20204 - RORET ', label: '20204 - RORET ' },
  { value: '20205 - SOSIOT ', label: '20205 - SOSIOT ' },
  { value: '20208 - KIPTUGUMO ', label: '20208 - KIPTUGUMO ' },
  { value: '20209 - FORT TERNAN ', label: '20209 - FORT TERNAN ' },
  { value: '20210 - LITEIN ', label: '20202 - LITEIN ' },
  { value: '20211 - KAPSOIT ', label: '20211 - KAPSOIT ' },
  { value: '20213 - KIPTERE ', label: '20213 - KIPTERE ' },
  { value: '20214 - KAPKATET ', label: '20214 - KAPKATET ' },
  { value: '20215 - CHEBORGE ', label: '20215 - CHEBORGE ' },
  { value: '20217 - CHESINENDET ', label: '20217 - CHESINENDET ' },
  { value: '20220 - KEDOWA ', label: '20220 - KEDOWA ' },
  { value: '20222 - CHEMALUL ', label: '20222 - CHEMALUL ' },
  { value: '20225 -KIMULOT ', label: '20225 - KIMULOT ' },
  { value: '20300 - NYAHURURU ', label: '20300 - NYAHURURU ' },
  { value: '20301 - MIHARATI ', label: '20301 - MIHARATI ' },
  { value: '20302 - OLJORO-OROK ', label: '20302 - OLJORO-OROK ' },
  { value: '20303 - OL-KALOU ', label: '20303 - OL-KALOU ' },
  { value: '20304 - KAHEHO ', label: '20304 - KAHEHO ' },
  { value: '20305 - WANJOHI ', label: '20305 - WANJOHI ' },
  { value: '20306 - NDARAGWA ', label: '20306 - NDARAGWA ' },
  { value: '20307 - IGWAMITI  ', label: '20307 - IGWAMITI  ' },
  { value: '20313 - RURII  ', label: '20313 - RURII  ' },
  { value: '20317 - NDUNYU NJERU  ', label: '20317 - NDUNYU NJERU  ' },
  { value: '20318 - NORTH-KINANGOP  ', label: '20318 - NORTH-KINANGOP  ' },
  { value: '20319 - SOUTH-KINANGOP  ', label: '20319 - SOUTH-KINANGOP  ' },
  { value: '20320 - KINAMBA  ', label: '20320 - KINAMBA  ' },
  { value: ' 20321 - RUMURUTI ', label: ' 20321 - RUMURUTI  ' },
  { value: '20322 -MARMANET  ', label: '20322 - MARMANET  ' },
  { value: '20328 - KARANDI  ', label: '20328 - KARANDI  ' },
  { value: '20330  LAIKIPIA CAMPUS-  ', label: ' 20330 - LAIKIPIA CAMPUS  ' },
  { value: '20400 - BOMET  ', label: '20400 - BOMET  ' },
  { value: ' 20401 - CHEBUNYO  ', label: '20401 - CHEBUNYO ' },
  { value: '20402 - LONGISA ', label: '20402 - LONGISA  ' },
  { value: '20403 - MOGOGOSIEK  ', label: '20403 - MOGOGODIEK ' },
  { value: '20404 - NDANAI ', label: '20404 - NDANAI ' },
  { value: '20405 -SIGOR  ', label: '20405 - SIGOR ' },
  { value: '20406 - SOTIK ', label: '20406 -SOTIK  ' },
  { value: '20407 - CHEMANER ', label: '20407 -CHEMANER  ' },
  { value: '20422 - SILIBWET ', label: '20422 - SILIBWET ' },
  { value: '20423 -SIONGIROI  ', label: '20423 - SIONGIROI ' },
  { value: '20424 - AMALO(FORMERLY OLOOMIRANI) ', label: '20424 -AMALO(FORMERLY OLOOMIRANI)  ' },
  { value: '20500 - NAROK  ', label: '20500 - NAROK  ' },
  { value: '20501 - KEEKOROK ', label: '20501 - KEEKOROK  ' },
  { value: '20503 - OLOLULUNGA ', label: '20503 -OLOLOLUNGA  ' },
  { value: '20504 - NAIRAGE ENKARE ', label: '20504 - NAIRAGE ENKARE  ' },
  { value: '20600 - MARALAL ', label: '20600 - MARALAL  ' },
  { value: '20601 - BARAGOI ', label: '20601 - BARAGOI  ' },
  { value: '20602 - SUGUTA MAR MAR ', label: '20602 -SUGUTA MAR MAR  ' },
  { value: '20603 - WAMBA ', label: '20603 -WAMBA  ' },
  { value: '30100 - ELDORET ', label: '30100 -ELDORET  ' },
  { value: '30101 - AINABKOI ', label: '30101 - AINABKOI  ' },
  { value: '30102 - BURNT FOREST ', label: '30102 - BURNT FOREST  ' },
  { value: '30103 - KIPKABUS ', label: '30103 -KIPKABUS  ' },
  { value: '30104 - MOIBEN ', label: '30104 - MOIBEN  ' },
  { value: '30105 - SOY ', label: '30105 -SOY  ' },
  { value: '30106 - TURBO ', label: '30106 -TURBO  ' },
  { value: '30107 - MOI UNIVERSITY ', label: '30107 - MOI UNIVERSITY  ' },
  { value: '30108 - TIMBOROA ', label: '30108 -TIMBOROA  ' },
  { value: '30109 - HURUMA ', label: '30109 -HURUMA  ' },
  { value: '30112 - LANGAS ', label: '30112 -LANGAS  ' },
  { value: '30114 - KAPTAGAT ', label: '30114 -KAPTAGAT  ' },
  { value: '30128 - KIMWARER ', label: '30128 -KIMWARER  ' },
  { value: '30129 - CHEPKORIO ', label: '30129 -CHEPKORIO ' },
  { value: '30133 - CHEPKOILEL-MOMBASA ', label: '30133 -CHEPKOILEL-MOMBASA  ' },
  { value: '30200 - KITALE ', label: '30200 -KITALE  ' },
  { value: '30201 - ENDEBESS ', label: '30201 -ENDEBESS  ' },
  { value: '30202 - MOIS BRIDGE ', label: '20406 - MOIS BRIDGE  ' },
  { value: '30204 - KAPCHEROP ', label: '30204 - KAPCHEROP  ' },
  { value: '30205 - MATUNDA ', label: '30205 -MATUNDA  ' },
  { value: '30208 - KAPSARA ', label: '30208 -KAPSARA  ' },
  { value: '30209 - KIMININI ', label: '30209 - KIMININI  ' },
  { value: '30214 - ZIWA ', label: '30214 -ZIWA  ' },
  { value: '30215 - KESOGON ', label: '30215 - KESOGON  ' },
  { value: '30300 - KAPSABET ', label: '30300 - KAPSABET  ' },
  { value: '30301 - NANDI HILLS ', label: '30301 - NANDI HILLS  ' },
  { value: '30302 - LESSOS ', label: '30302 - LESSOS  ' },
  { value: '30303 - KABIYET ', label: '30303 - KABIYET  ' },
  { value: '30304 - KAPCHENO ', label: '30304 - KAPCHENO  ' },
  { value: '30305 - KOBUJOI ', label: '30305 - KOBUJOI  ' },
  { value: '30306 - BARATON ', label: '30306 - BARATON  ' },
  { value: '30307 - MOSORIOT ', label: '30307 -MOSORIOT  ' },
  { value: '30400 - KABARNET ', label: '30400 - KABARNET  ' },
  { value: '30401 - KABARTONJO ', label: '30401 -KABARTONJO  ' },
  { value: '30403 - MARIGAT ', label: '30403 - MARIGAT  ' },
  { value: '30404 - NGINYANG ', label: '30404 - NGINYANG  ' },
  { value: '30405 - TENGES ', label: '30405 - TENGES  ' },
  { value: '30406 - KAMPI-YA-SAMAKI ', label: '30406 - KAMPI-YA-SAMAKI  ' },
  { value: '30407 - SERETUNIN ', label: '30407 -SERETUNIN  ' },
  { value: '30500 -  LODWAR ', label: '30500 - LODWAR  ' },
  { value: '30501 - KAKUMA ', label: '30501 -KAKUMA  ' },
  { value: '30502 - KALOKOL ', label: '30502 -KALOKOL  ' },
  { value: '30503 - LOKICHOGGIO', label: '30503 -LOKICHOGGIO  ' },
  { value: '30504 - LOKITAUNG ', label: '30504 -LOKITAUNG  ' },
  { value: '30600 - KAPENGURIA ', label: '30600 -KAPENGURIA  ' },
  { value: '30601 - KACHELIBA', label: '30601 -KACHELIBA  ' },
  { value: '30602 - ORTUM ', label: '30602 -ORTUM  ' },
  { value: '30603 - WEI-WEI ', label: '30603 -WEI-WEI  ' },
  { value: '30605 - CHEPARERIA ', label: '30605 - CHEPARERIA  ' },
  { value: '30700 - ITEN ', label: '30700 - ITEN  ' },
  { value: '30701 - KAPTARAKWA ', label: '30701 - KAPTARAKWA  ' },
  { value: '30704 - TAMBACH ', label: '30704 -TAMBACH  ' },
  { value: '30705 - KAPSOWAR ', label: '30705 - KAPSOWAR  ' },
  { value: '30706 - CHEBIEMIT ', label: '30706 -CHEBIEMIT  ' },
  { value: '30707 - TOT ', label: '30707 -TOT ' },
  { value: '40100 - KISUMU ', label: '40100 -KISUMU  ' },
  { value: '40101 - AHERO ', label: '40101 -AHERO  ' },
  { value: '40102 - KOMBEWA ', label: '40102 -KOMBEWA  ' },
  { value: '40103 - KONDELE ', label: '40103 - KONDELE  ' },
  { value: '40104 - KORU ', label: '40104 -KORU  ' },
  { value: '40105 - MASENO ', label: '40105 -MASENO  ' },
  { value: '40107- MUHORONI ', label: '40107 -MUHORONI  ' },
  { value: '40109 - SONDU ', label: '40109 -SONDU  ' },
  { value: '40110 - SONGOR ', label: '40110 - SONGOR  ' },
  { value: '40111 - PAP-ONDITI ', label: '40111 - PAP-ONDITI  ' },
  { value: '40112 - DAGO ', label: '40112 - DAGO  ' },
  { value: '40116 - CHEMELIL ', label: '40116 -CHEMELIL  ' },
  { value: '40117 - DARAJA MBILI ', label: '40117 - DARAJA MBILI  ' },
  { value: '40118 - KATITO ', label: '40118 -KATITO  ' },
  { value: '40122 - AWASI ', label: '40122 - AWASI  ' },
  { value: '40123 - MEGA CITY ', label: '40123 - MEGA CITY  ' },
  { value: '40124 - NYABONDO ', label: '40124 - NYABONDO  ' },
  { value: '40126 - NYANGANDE', label: '40126 - NYANGANDE  ' },
  { value: '40127 - NYANGORI ', label: '40127 - NYANGORI ' },
  { value: '40131 - PAW AKUCHE ', label: '40131 - PAW AKUCHE ' },
  { value: '40132 - RABOUR ', label: '40132 - RABOUR  ' },
  { value: '40133 - RERU ', label: '40133 - RERU  ' },
  { value: '40137 - RATTA ', label: '40137 - RATTA  ' },
  { value: '40139 - AKALA ', label: '40139 -AKALA  ' },
  { value: '40141 - WINAM(KISUMU GPO EXTN) ', label: '40141 - WINAM(KISUMU GPO EXTN)  ' },
  { value: '40200 - KISII ', label: '40200 - KISII  ' },
  { value: '40201 - GESUSU ', label: '40201 -GESUSU  ' },
  { value: '40202- KEROKA ', label: '40202 - KEROKA  ' },
  { value: '40203 - NYAMACHE ', label: '40203 -NYAMACHE  ' },
  { value: '40204 - OGEMBO ', label: '40204 - OGEMBO  ' },
  { value: '40205 - NYABUNWA ', label: '40205 -NYABUNWA  ' },
  { value: '40206 - NYAMARAMBE ', label: '40206 -NYAMARAMBE  ' },
  { value: '40208 - ETAGO ', label: '40208 -ETAGO  ' },
  { value: '40209 - IGARE ', label: '40209 -IGARE  ' },
  { value: '40211 - KENYENYA ', label: '40211 -KENYENYA  ' },
  { value: '40212 - KEUMBU ', label: '40212 - KEIMBU  ' },
  { value: '40218 - NYANGUSU ', label: '40218 -NYANGUSU  ' },
  { value: '40220 - RIOSIRI ', label: '40220 - RIOSIRI  ' },
  { value: '40221 - OMOGONCHORO', label: '40221 - OMOGONCHORO  ' },
  { value: '40222 - OYUGIS ', label: '40222 - OYUGIS  ' },
  { value: '40223 - KADONGO ', label: '40223 -KADONGO  ' },
  { value: '40228 - URIRI ', label: '40228 -URIRI  ' },
  { value: '40229 - TABAKA ', label: '40229 - TABAKA  ' },
  { value: '40300 - HOMA BAY ', label: '40300 - HOMA BAY  ' },
  { value: '40301 - KENDU BAY ', label: '40301 -KENDU BAY  ' },
  { value: '40302 - NDHIWA ', label: '40302 -NDHIWA  ' },
  { value: '40303 - RANGWE ', label: '40303 -RANGWE  ' },
  { value: '40304 - KANDIEGE ', label: '40304 - KANDIEGE  ' },
  { value: '40305 - MBITA ', label: '40305 - MBITA  ' },
  { value: '40307 - MAGUNGA ', label: '40307 - MAGUNGA  ' },
  { value: '40308 - SINDO', label: '40308 -SINDO  ' },
  { value: '40309 - ASUMBI ', label: '40309 -ASUMBI  ' },
  { value: '40310 - MAWEGO ', label: '40310 -MAWEGO  ' },
  { value: '40311 - NYANGWESO ', label: '40311 - NYANGWESO  ' },
  { value: '40319 - MFANGANO ', label: '40319 - MFANGANO  ' },
  { value: '40320 - MIROGI ', label: '40320 - MIROGI  ' },
  { value: '40323 - OGONGO ', label: '40323 - OGONGO  ' },
  { value: '40326 - RODI KOPANY ', label: '40326 - RODI KOPANY ' },
  { value: '40332 - KOSELE', label: ' 40332 - KOSELE' },
  { value: '40333 - NYANDHIWA', label: '40333 - NYANDHIWA' },
  { value: '40400 - SUNA', label: '40400 - SUNA' },
  { value: '40401 - KARUNGU', label: '40401 - KARUNGU' },
  { value: '40402 - NYATIKE', label: '40402 - NYATIKE' },
  { value: '40403 - RAPOGI', label: '40403 - RAPOGI' },
  { value: '40404 - RONGO', label: '40404 - RONGO' },
  { value: '40405 - SARE', label: '40405 - SARE' },
  { value: '40409 - MUHURU BAY', label: '40409 - MUHURU BAY' },
  { value: '40412 - RANEN', label: '40412 - RANEN' },
  { value: '40413 - KEHANCHA', label: '40413 - KEHANCHA' },
  { value: '40414 - ISIBANIA', label: '40414 - ISIBANIA' },
  { value: '40418 - SUBA-KURIA', label: '40418 - SUBA-KURIA' },
  { value: '40500 - NYAMIRA', label: '40500 - NYAMIRA' },
  { value: '40501 - IKONGE', label: '40501 - IKONGE' },
  { value: '40502 - NYANSIONGO', label: '40502 - NYANSIONGO' },
  { value: '40503 - GESIMA', label: '40503 - GESIMA' },
  { value: '40506 - KEBIRIGO', label: '40506 - KEBIRIGO' },
  { value: '40507 - MAGOMBO', label: '40507 - MAGOMBO' },
  { value: '40508 - MAGWAGWA', label: '40508 - MAGWAGWA' },
  { value: '40510 - MOKOMONI', label: '40510 - MOKOMONI' },
  { value: '40511 - RIGOMA', label: '40511 - RIGOMA' },
  { value: '40512 - RIOCHANDA', label: '40512 - RIOCHANDA' },
  { value: '40514 - NYARAMBA', label: '40514 - NYARAMBA' },
  { value: '40516 - MAGENA', label: '40516 - MAGENA' },
  { value: '40600 - SIAYA', label: '40600 - SIAYA' },
  { value: '40601 - BONDO', label: '40601 - BONDO' },
  { value: '40602 - NDORI', label: '40602 - NDORI' },
  { value: '40603 - NGIYA', label: '40603 - NGIYA' },
  { value: '40604 - RAGENGNI', label: '40604 - RAGENGNI' },
  { value: '40605 - SIDINDI', label: '40605 - SIDINDI' },
  { value: '40606 - UGUNJA', label: '40606 - UGUNJA' },
  { value: '40607 - UKWALA', label: '40607 - UKWALA' },
  { value: '40608 - URANGA', label: '40608 - URANGA' },
  { value: '40609 - USENGE', label: '40609 - USENGE' },
  { value: '40610 - YALA', label: '40610 - YALA' },
  { value: '40611 - NYILIMA', label: '40611 - NYILIMA' },
  { value: '40612 - SAWAGONGO', label: '40612 - SAWAGONGO' },
  { value: '40613 - MADIANY', label: '40613 - MADIANY' },
  { value: '40614 - SEGA', label: '40614 - SEGA' },
  { value: '40615 - NANGO', label: '40615 - NANGO' },
  { value: '40620 - BORO', label: '40620 - BORO' },
  { value: '40623 - LUHANO', label: '40623 - LUHANO' },
  { value: '40628 - MUTUMBU', label: '40628 - MUTUMBU' },
  { value: '40632 - NYAMONYE', label: '40632 - NYAMONYE' },
  { value: '40635 - SIGOMRE', label: '40635 - SIGOMRE' },
  { value: '40640 - HAWINGA', label: '40640 - HAWINGA' },
  { value: '40700 - KILGORIS', label: '40700 - KILGORIS' },
  { value: '40701 - LOLGORIAN', label: '40701 - LOLGORIAN' },
  { value: '50100 - KAKAMEGA', label: '50100 - KAKAMEGA' },
  { value: '50101 - BUTERE', label: '50101 - BUTERE' },
  { value: '50102 - MUMIAS', label: '50102 - MUMIAS' },
  { value: '50103 - MALAVA', label: '50103 - MALAVA' },
  { value: '50104 - KHAYEGA', label: '50104 - KHAYEGA' },
  { value: '50105 - BUKURA', label: '50105 - BUKURA' },
  { value: '50106 - SHIANDA', label: '50106 - SHIANDA' },
  { value: '50107 - SHINYALU', label: '50107 - SHINYALU' },
  { value: '50108 - LUGARI', label: '50108 - LUGARI' },
  { value: '50109 - BULIMBO', label: '50109 - BULIMBO' },
  { value: '50115 - KAKUNGA', label: '50115 - KAKUNGA' },
  { value: '50116 - KAMBIRI', label: '50116 - KAMBIRI' },
  { value: '50117 - KOYONZO', label: '50117 - KOYONZO' },
  { value: '50118 - LUBAO', label: '50118 - LUBAO' },
  { value: '50119 - LUNZA', label: '50119 - LUNZA' },
  { value: '50125 - MUSANDA', label: '50125 - MUSANDA' },
  { value: '50127 - NAMBACHA', label: '50127 - NAMBACHA' },
  { value: '50135 - KHWISERO', label: '50135 - KHWISERO' },
  { value: '50137 - BOOKER', label: '50137 - BOOKER' },
  { value: '50138 - MILIMANI', label: '50138 - MILIMANI' },
  { value: '50200 - BUNGOMA', label: '50200 - BUNGOMA' },
  { value: '50201 - CHEPTAIS', label: '50201 - CHEPTAIS' },
  { value: '50202 - CHWELE', label: '50202 - CHWELE' },
  { value: '50203 - KAPSOKWONY', label: '50203 - KAPSOKWONY' },
  { value: '50204 - KIMILILI', label: '50204 - KIMILILI' },
  { value: '50205 - WEBUYE', label: '50205 - WEBUYE' },
  { value: '50206 - BOKOII', label: '50206 - BOKOII' },
  { value: '50207 - MISIKHU', label: '50207 - MISIKHU' },
  { value: '50208 - SIRISIA', label: '50208 - SIRISIA' },
  { value: '50209 - MALAKISI', label: '50209 - MALAKISI' },
  { value: '50210 - BUYOFU', label: '50210 - BUYOFU' },
  { value: '50211 - NAITIRI', label: '50211 - NAITIRI' },
  { value: '50212 - NDALU', label: '50212 - NDALU' },
  { value: '50216 - KAMUKUYWA', label: '50216 - KAMUKUYWA' },
  { value: '50218 - LUGULU', label: '50218 - LUGULU' },
  { value: '50220 - LWAKHAKHA', label: '50220 - LWAKHAKHA' },
  { value: '50225 - MUKHE', label: '50225 - MUKHE' },
  { value: '50226 - MYANGA', label: '50226 - MYANGA' },
  { value: '50230 - LUKUSI', label: '50230 - LUKUSI' },
  { value: '50235 - MABUSI', label: '50235 - MABUSI' },
  { value: '50240 - LUANDETI', label: '50240 - LUANDETI' },
  { value: '50241 - KIPKARREN RIVER', label: '50241 - KIPKARREN RIVER' },
  { value: '50242 - LUMAKANDA', label: '50242 - LUMAKANDA' },
  { value: '50244 - AMAGORO', label: '50244 - AMAGORO' },
  { value: '50245 - BRIGADIER', label: '50245 - BRIGADIER' },
  { value: '50300 - MARAGOLI', label: '50300 - MARAGOLI' },
  { value: '50302 - CHAMAKANGA', label: '50302 - CHAMAKANGA' },
  { value: '50304 - GISAMBAI', label: '50304 - GISAMBAI' },
  { value: '50305 - KAIMOSI', label: '50305 - KAIMOSI' },
  { value: '50307 - LUANDA', label: '50307 - LUANDA' },
  { value: '50308 - SEREM', label: '50308 - SEREM' },
  { value: '50309 - TIRIKI', label: '50309 - TIRIKI' },
  { value: '50310 - VIHIGA', label: '50310 - VIHIGA' },
  { value: '50311 - WODANGA', label: '50311 - WODANGA' },
  { value: '50312 - HAMISI', label: '50312 - HAMISI' },
  { value: '50313 - KIRITU', label: '50313 - KIRITU' },
  { value: '50314 - EMUHAYA', label: '50314 - EMUHAYA' },
  { value: '50315 - KILINDILI-MOMBASA', label: '50315 - KILINDILI-MOMBASA' },
  { value: '50316 - BANJA', label: '50316 - BANJA' },
  { value: '50317 - CHAVAKALI', label: '50317 - CHAVAKALI' },
  { value: '50318 - GAMBOGI', label: '50318 - GAMBOGI' },
  { value: '50325 - MAGO', label: '50325 - MAGO' },
  { value: '50400 - BUSIA', label: '50400 - BUSIA' },
  { value: '50403 - AMUKURA', label: '50403 - AMUKURA' },
  { value: '50404 - BUMALA', label: '50404 - BUMALA' },
  { value: '50405 - BUTULA', label: '50405 - BUTULA' },
  { value: '50406 - FUNYULA', label: '50406 - FUNYULA' },
  { value: '50408 - KAMURIAI', label: '50408 - KAMURIAI' },
  { value: '50409 - NAMBALE', label: '50409 - NAMBALE' },
  { value: '50410 - PORT VICTORIA', label: '50410 - PORT VICTORIA' },
  { value: '50423 - MUBWAYO', label: '50423 - MUBWAYO' },
  { value: '60100 - EMBU', label: '60100 - EMBU' },
  { value: '60101 - MANYATTA', label: '60101 - MANYATTA' },
  { value: '60102 - ISHIARA', label: '60102 - ISHIARA' },
  { value: '60103 - RUNYENJES', label: '60103 - RUNYENJES' },
  { value: '60104 - SIAKAGO', label: '60104 - SIAKAGO' },
  { value: '60105 - KARABA', label: '60105 - KARABA' },
  { value: '60113 - KIRITIRI', label: '60113 - KIRITIRI' },
  { value: '60114 - KITHIMU', label: '60114 - KITHIMU' },
  { value: '60117 - KARURUMO', label: '60117 - KARURUMO' },
  { value: '60118 - KANJA', label: '60118 - KANJA' },
  { value: '60121 - WACHORO', label: '60121 - WACHORO' },
  { value: '60122 - KIANJOKOMA', label: '60122 - KIANJOKOMA' },
  { value: '60125 - KUBU KUBU', label: '60125 - KUBU KUBU' },
  { value: '60200 - MERU', label: '60200 - MERU' },
  { value: '60201 - KIBIRICHIA', label: '60201 - KIBIRICHIA' },
  { value: '60202 - NKUBU', label: '60202 - NKUBU' },
  { value: '60204 - MITUNGUU', label: '60204 - MITUNGUU' },
  { value: '60205 - GITHONGO', label: '60205 - GITHONGO' },
  { value: '60206 - KANYAKINE', label: '60206 - KANYAKINE' },
  { value: '60207 - KIIRUA', label: '60207 - KIIRUA' },
  { value: '60209 - GAITU', label: '60209 - GAITU' },
  { value: '60211 - KIONYO', label: '60211 - KIONYO' },
  { value: '60212 - GITEMENE', label: '60212 - GITEMENE' },
  { value: '60213 - TUNYAI', label: '60213 - TUNYAI' },
  { value: '60214 - NKONDI', label: '60214 - NKONDI' },
  { value: '60215 - MARIMANTI', label: '60215 - MARIMANTI' },
  { value: '60216 - KINORU', label: '60216 - KINORU' },
  { value: '60300 - ISIOLO', label: '60300 - ISIOLO' },
  { value: '60301 - GARBA TULLA', label: '60301 - GARBA TULLA' },
  { value: '60303 - MERTI', label: '60303 - MERTI' },
  { value: '60400 - CHUKA', label: '60400 - CHUKA' },
  { value: '60401 - CHOGORIA', label: '60401 - CHOGORIA' },
  { value: '60402 - IGOJI', label: '60402 - IGOJI' },
  { value: '60403 - MAGUMONI', label: '60403 - MAGUMONI' },
  { value: '60404 - GATUNGA', label: '60404 - GATUNGA' },
  { value: '60405 - IKUU', label: '60405 - IKUU' },
  { value: '60406 - KATHWANA', label: '60406 - KATHWANA' },
  { value: '60407 - MAGUTUNI', label: '60407 - MAGUTUNI' },
  { value: '60408 - MARIMA', label: '60408 - MARIMA' },
  { value: '60409 - CHIAKARIGA', label: '60409 - CHIAKARIGA' },
  { value: '60410 - CHIAKANYINGA-MOMBASA', label: '60410 - CHIAKANYINGA-MOMBASA' },
  { value: '60500 - MARSABIT', label: '60500 - MARSABIT' },
  { value: '60501 - LOIYANGALANI', label: '60501 - LOIYANGALANI' },
  { value: '60502 - LAISAMIS', label: '60502 - LAISAMIS' },
  { value: '60600 - MAUA', label: '60600 - MAUA' },
  { value: '60601 - LAARE', label: '60601 - LAARE' },
  { value: '60602 - KIANJAI', label: '60602 - KIANJAI' },
  { value: '60604 - MIATHENE', label: '60604 - MIATHENE' },
  { value: '60605 - MUTHARA', label: '60605 - MUTHARA' },
  { value: '60607 - MIKINDURI', label: '' },
  { value: '60700 - MOYALE', label: '60700 - MOYALE' },
  { value: '70100 - GARISSA', label: '70100 - GARISSA' },
  { value: '70101 - HOLA', label: '70101 - HOLA' },
  { value: '70102 - MODO GASHE', label: '70102 - MODO GASHE' },
  { value: '70103 - DADAAB', label: '70103 - DADAAB' },
  { value: '70104 - BURATANA', label: '70104 - BURATANA' },
  { value: '70105 - MASALANI', label: '70105 - MASALANI' },
  { value: '70200 - WAJIR', label: '70200 - WAJIR' },
  { value: '70201 - HABASWEIN', label: '70201 - HABASWEIN' },
  { value: '70202 - GRIFTU', label: '70202 - GRIFTU' },
  { value: '70300 - MANDERA', label: '70300 - MANDERA' },
  { value: '70301 - ELWAK', label: '70301 - ELWAK' },
  { value: '70302 - RHAMU', label: '70302 - RHAMU' },
  { value: '70303 - TAKABA', label: '70303 - TAKABA' },
  { value: '80100 - MOMBASA  G.P.O', label: '80100 - MOMBASA  G.P.O' },
  { value: '80101 - BAMBURI-MOMBASA', label: '80101 - BAMBURI-MOMBASA' },
  { value: '80102 - CHANGAMWE-MOMBASA', label: '80102 - CHANGAMWE-MOMBASA' },
  { value: '80103 - COAST GEN.HOSTIPAL-MOMBASA', label: '80103 - COAST GEN.HOSTIPAL-MOMBASA' },
  { value: '80104 - DOCKS-MOMBASA', label: '80104 - DOCKS-MOMBASA' },
  { value: '80105 - KALOLENI', label: '80105 - KALOLENI' },
  { value: '80106 - MKOMANI-MOMBASA', label: '80106 - MKOMANI-MOMBASA' },
  { value: '80107 - KILINDINI', label: '80107 - KILINDINI' },
  { value: '80108 - KILIFI', label: '80108 - KILIFI' },
  { value: '80109 - MTWAPA', label: '80109 - MTWAPA' },
  { value: '80110 - LIKONI-MOMBASA', label: '80110 - LIKONI-MOMBASA' },
  { value: '80111 - MTONGWE-MOMBASA', label: '80111 - MTONGWE-MOMBASA' },
  { value: '80112 - MAKUPA-MOMBASA', label: '80112 - MAKUPA-MOMBASA' },
  { value: '80113 - MARIAKANI', label: '80113 - MARIAKANI' },
  { value: '80114 - MAZERAS', label: '80114 - MAZERAS' },
  { value: '80115 - MOI  AIRPORT-MOMBASA', label: '80115 - MOI  AIRPORT-MOMBASA' },
  { value: '80116 - KIPEVU-MOMBASA', label: '80116 - KIPEVU-MOMBASA' },
  { value: '80117 - MTOPANGA', label: '80117 - MTOPANGA' },
  { value: '80118 - NYALI-MOMBASA', label: '80118 - NYALI-MOMBASA' },
  { value: '80119 - VIPINGO', label: '80119 - VIPINGO' },
  { value: '80120 - SAMBURU', label: '80120 - SAMBURU' },
  { value: '80122 - KENGELENI-MOMBASA', label: '80122 - KENGELENI-MOMBASA' },
  { value: '80200 - MALINDI', label: '80200 - MALINDI' },
  { value: '80201 - GARSEN', label: '80201 - GARSEN' },
  { value: '80202 - WATAMU', label: '80202 - WATAMU' },
  { value: '80205 - GANZE', label: '80205 - GANZE' },
  { value: '80206 - GONGONI', label: '80206 - GONGONI' },
  { value: '80207 - MADINA', label: '80207 - MADINA' },
  { value: '80208 - GEDE', label: '80208 - GEDE' },
  { value: '80211 - VITENGENI', label: '80211 - VITENGENI' },
  { value: '80300 - VOI', label: '80300 - VOI' },
  { value: '80302 - TAVETA', label: '80302 - TAVETA' },
  { value: '80303 - WERUGHA', label: '80303 - WERUGHA' },
  { value: '80304 - WUNDANYI', label: '80304 - WUNDANYI' },
  { value: '80305 - MWATATE', label: '80305 - MWATATE' },
  { value: '80306 - MGANGE', label: '80306 - MGANGE' },
  { value: '80308 - SAGALLA', label: '80308 - SAGALLA' },
  { value: '80309 - TAUSA', label: '80309 - TAUSA' },
  { value: '80311 - NGAMBWA', label: '80311 - NGAMBWA' },
  { value: '80313 - MGAMBONYI', label: '80313 - MGAMBONYI' },
  { value: '80314 - CHUMVINI', label: '80314 - CHUMVINI' },
  { value: '80317 - MAUNGU', label: '80317 - MAUNGU' },
  { value: '80400 - UKUNDA', label: '80400 - UKUNDA' },
  { value: '80401 - DIANI BEACH', label: '80401 - DIANI BEACH' },
  { value: '80402 - LUNGA LUNGA', label: '80402 - LUNGA LUNGA' },
  { value: '80403 - KWALE', label: '80403 - KWALE' },
  { value: '80404 - MSAMBWENI', label: '80404 - MSAMBWENI' },
  { value: '80405 - KINANGO', label: '80405 - KINANGO' },
  { value: '80406 - MATUGA', label: '80406 - MATUGA' },
  { value: '80407 - SHIMBA HILLS', label: '80407 - SHIMBA HILLS' },
  { value: '80409 - SHIMONI', label: '80409 - SHIMONI' },
  { value: '80410 - KIKONENI', label: '80410 - KIKONENI' },
  { value: '80500 - LAMU', label: '80500 - LAMU' },
  { value: '80501 - FAZA', label: '80501 - FAZA' },
  { value: '80502 - MOKOWE', label: '80502 - MOKOWE' },
  { value: '80503 - MPEKETONI', label: '80503 - MPEKETONI' },
  { value: '80504 - WITU', label: '80504 - WITU' },
  { value: '90100 - MACHAKOS', label: '90100 - MACHAKOS' },
  { value: '90101 - MASII', label: '90101 - MASII' },
  { value: '90102 - MWALA', label: '90102 - MWALA' },
  { value: '90103 - WAMUNYU', label: '90103 - WAMUNYU' },
  { value: '90104 - MITABONI', label: '90104 - MITABONI' },
  { value: '90105 - KATHIANI', label: '90105 - KATHIANI' },
  { value: '90106 - KATANGI', label: '90106 - KATANGI' },
  { value: '90107 - KAVIANI', label: '90107 - KAVIANI' },
  { value: '90108 - KOLA', label: '90108 - KOLA' },
  { value: '90110 - MBIUNI', label: '90110 - MBIUNI' },
  { value: '90111 - KIVUNGA', label: '90111 - KIVUNGA' },

  { value: '90112 - MIU', label: '90112 - MIU' },
  { value: '90113 - MUTHETHENI', label: '90113 - MUTHETHENI' },
  { value: '90115 - KANGUNDO', label: '90115 - KANGUNDO' },
  { value: '90117 - MUTITUNI', label: '90117 - MUTITUNI' },
  { value: '90118 - NDALANI', label: '90118 - NDALANI' },
  { value: '90119 - MATUU', label: '90119 - MATUU' },
  { value: '90120 - IIANI', label: '90120 - IIANI' },
  { value: '90121 - EMALI', label: '90121 - EMALI' },
  { value: '90122 - KALAMBA', label: '90122 - KALAMBA' },
  { value: '90123 - KASIKEU', label: '90123 - KASIKEU' },
  { value: '90124 - KITHIMANI', label: '90124 - KITHIMANI' },

  { value: '90125 - KIKIMA', label: '90125 - KIKIMA' },
  { value: '90127 - MBUMBUNI', label: '90127 - MBUMBUNI' },
  { value: '90128 - MTITU ANDEI', label: '90128 - MTITU ANDEI' },
  { value: '90129 - NGWATA', label: '90129 - NGWATA' },
  { value: '90130 - NUNGUNI', label: '90130 - NUNGUNI' },
  { value: '90131 - TALA', label: '90131 - TALA' },
  { value: '90132 - SULTAN HAMUD', label: '90132 - SULTAN HAMUD' },
  { value: '90133 - TAWA', label: '90133 - TAWA' },
  { value: '90134 - YOANI', label: '90134 - YOANI' },
  { value: '90135 - IKALAASA', label: '90135 - IKALAASA' },
  { value: '90136 - NZEEKA', label: '90136 - NZEEKA' },
  { value: '90137 - KIBWEZI', label: '90137 - KIBWEZI' },
  { value: '90138 - MAKINDU', label: '90138 - MAKINDU' },

  { value: '90139 - EKALAKALA', label: '90139 - EKALAKALA' },
  { value: '90140 - MATILIKU', label: '90140 - MATILIKU' },
  { value: '90141 - MASINGA', label: '90141 - MASINGA' },
  { value: '90143 - NZIU', label: '90143 - NZIU' },
  { value: '90144 - KITHYOKO', label: '90144 - KITHYOKO' },
  { value: '90145 - DAYSTAR', label: '90145 - DAYSTAR' },
  { value: '90147 - CHUMVI', label: '90147 - CHUMVI' },
  { value: '90200 - KITUI', label: '90200 - KITUI' },
  { value: '90201 - MUTOMO', label: '90201 - MUTOMO' },
  { value: '90202 - NDOOA', label: '90202 - NDOOA' },
  { value: '90203 - TULIA', label: '90203 - TULIA' },
  { value: '90204 - KISASI', label: '90204 - KISASI' },
  { value: '90205 - KABATI', label: '90205 - KABATI' },
  { value: '90207 - IKUTHA', label: '90207 - IKUTHA' },
  { value: '90209 - KYENI', label: '90209 - KYENI' },
  { value: '90211 - MUTHA', label: '90211 - MUTHA' },
  { value: '90213 - ZOMBE', label: '90213 - ZOMBE' },
  { value: '90214 - MBITINI', label: '90214 - MBITINI' },
  { value: '90220 - KYATUNE', label: '90220 - KYATUNE' },
  { value: '90300 - MAKUENI', label: '90300 - MAKUENI' },
  { value: '90301 - OKIA', label: '90301 - OKIA' },
  { value: '90303 - KITISE', label: '90303 - KITISE' },
  { value: '90304 - MAVINDINI', label: '90304 - MAVINDINI' },
  { value: '90305 - KILALA', label: '90305 - KILALA' },
  { value: '90400 - MWINGI', label: '90400 - MWINGI' },
  { value: '90401 - KYUSO', label: '90401 - KYUSO' },
  { value: '90402 - MIGWANI', label: '90402 - MIGWANI' },
  { value: '90403 - KAMUWONGO', label: '90403 - KAMUWONGO' },
  { value: '90407 - NGUNI', label: '90407 - NGUNI' },

]

const countries = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'BQ', label: 'British Antarctic Territory' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'VG', label: 'British Virgin Islands' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CT', label: 'Canton and Enderbury Islands' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos [Keeling] Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo - Brazzaville' },
  { value: 'CD', label: 'Congo - Kinshasa' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'CI', label: 'Côte d’Ivoire' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'NQ', label: 'Dronning Maud Land' },
  { value: 'DD', label: 'East Germany' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'FQ', label: 'French Southern and Antarctic Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong SAR China' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JT', label: 'Johnston Island' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macau SAR China' },
  { value: 'MK', label: 'Macedonia' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'FX', label: 'Metropolitan France' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'MI', label: 'Midway Islands' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar [Burma]' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'AN', label: 'Netherlands Antilles' },
  { value: 'NT', label: 'Neutral Zone' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'KP', label: 'North Korea' },
  { value: 'VD', label: 'North Vietnam' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PC', label: 'Pacific Islands Trust Territory' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestinian Territories' },
  { value: 'PA', label: 'Panama' },
  { value: 'PZ', label: 'Panama Canal Zone' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'YD', label: 'People Democratic Republic of Yemen' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn Islands' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russia' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'RE', label: 'Réunion' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'CS', label: 'Serbia and Montenegro' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'KR', label: 'South Korea' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syria' },
  { value: 'ST', label: 'São Tomé and Príncipe' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UM', label: 'U.S. Minor Outlying Islands' },
  { value: 'PU', label: 'U.S. Miscellaneous Pacific Islands' },
  { value: 'VI', label: 'U.S. Virgin Islands' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'SU', label: 'Union of Soviet Socialist Republics' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'ZZ', label: 'Unknown or Invalid Region' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Vatican City' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'WK', label: 'Wake Island' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
  { value: 'AX', label: 'Åland Islands' },
]

const gender = [
  { value: 'Male', label: ' Male' },
  { value: 'Female', label: ' Female' },
  { value: 'Other', label: ' Other', disabled: true },
]

const shareholderIDs = [
  { value: 'Kenyan Citizen', label: 'Kenyan Citizen' },
  { value: 'Foreign Resident', label: 'Foreign Resident' },
  { value: 'Foreigner', label: 'Foreigner' },
  { value: 'Minor', label: 'Minor' },
]

const companyOfficials = [
  { value: 'Director', label: 'Director' },
  { value: 'Director Shareholder', label: 'Director Shareholder' },
  { value: 'Shareholder', label: 'Shareholder' },
  { value: 'Joint Shareholder', label: 'Joint Shareholder' },
  { value: 'Company Secretary', label: 'Company Secretary' },
  { value: 'Authorized Person', label: 'Authorized Person' },
]

// const companyOfficialIdTypes = [
//   // may be redundant - see shareholderIDs above
//   { value: 'Kenyan Citizen', label: 'Kenyan Citizen' },
//   { value: 'Foreign Resident', label: 'Foreign Resident' },
//   { value: 'Foreigner', label: 'Foreigner' },
//   { value: 'Local Company', label: 'Local Company' },
//   { value: 'Foreign Company', label: 'Foreign Company' },
//   { value: 'Other e.g Parastatals', label: 'Other e.g Parastatals' },
//   { value: 'Minor', label: ' Minor' },
// ]

export default {
  counties,
  postalCodes,
  countries,
  gender,
  shareholderIDs,
  companyOfficials,
  // companyOfficialIdTypes,
}
