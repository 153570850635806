import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";

import { resetActiveDashboardSlice } from "state/slices/activeDashboard";
import { setCookie } from "utils/cookie";
import { Constants } from "config/constants";
import BreadCrumb from "components/lib/Global/BreadCrumb";
import { BriefcaseIcon } from "@heroicons/react/outline";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { classNames } from "utils";
import MaintenanceForm from "./MaintenanceForm";
import { ManageModuleContext } from "context/ManageCompanyModuleContext";

const MaintainYourCompany = ({ companyId }) => {
  const location = useLocation();
  const hash = location.hash.slice(1);
  const [selectedCompanyProcess, setSelectedCompanyProcess] = useState(null);
  const [currentStateOfProcess, setCurrentStateOfProcess] = useState("default");
  const [appointmentStatus, setAppointmentStatus] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const showBreadCrumbs = useState(true);

  let pages = [
    { name: "Home", href: "/companies", current: true },
    { name: "Maintain a company", href: "#", current: true },
  ];

  let companyProcesses = [
    {
      id: "changes",
      title: "Changes",
      description:
        "Initiate a new change application with just a few clicks and you will be on your way.",
      icon: <BriefcaseIcon />,
      hash: "company-changes",
    },
    {
      id: "annualReturns",
      title: "Annual Returns",
      description:
        "Initiate the auto-generation of your Annual Return for signature and filing with the Registrar of Companies.",
      icon: <BriefcaseIcon />,
      disabled: true,
      hash: "annual-returns",
    },
  ];

  let companyChangesProcesses = [
    {
      id: "officials/sharesholders/authorizedpersons",
      title: "Officials/Shareholders/Authorized Persons",
      description:
        "Initiate a new application with just a few clicks and you will be on your way.",
      icon: <BriefcaseIcon />,
      hash: "officials-shareholders-authorized-person-changes",
    },
    {
      id: "articlesOfAssociation",
      title: "Articles Of Association",
      description:
        "Initiate a new application with just a few clicks and you will be on your way to amend your articles.",
      icon: <BriefcaseIcon />,
      hash: "articles-of-association-changes",
      disabled: true,
    },
    {
      id: "shareCapital",
      title: "Share Capital",
      description:
        "Initiate a new application with just a few clicks and you will be on your way.",
      icon: <BriefcaseIcon />,
      hash: "share-capital-changes",
      disabled: true,
    },
    {
      id: "registeredAddress",
      title: "Registered Address",
      description:
        "Initiate a new application with just a few clicks and you will be on your way.",
      icon: <BriefcaseIcon />,
      hash: "registered-address-changes",
      disabled: true,
    },
    {
      id: "accountReferenceDate",
      title: "Account Reference Date",
      description:
        "Initiate a new application with just a few clicks and you will be on your way.",
      icon: <BriefcaseIcon />,
      hash: "reference-date-changes",
      disabled: true,
    },
    {
      id: "companyConversion",
      title: "Conversion of a company",
      description:
        "Initiate a new application with just a few clicks and you will be on your way.",
      icon: <BriefcaseIcon />,
      hash: "company-conversion-changes",
      disabled: true,
    },
    {
      id: "strikeOff",
      title: "Strike Off",
      description:
        "Initiate a new application with just a few clicks and you will be on your way.",
      icon: <BriefcaseIcon />,
      hash: "strike-off-changes",
      disabled: true,
    },
    {
      id: "bankAccounts",
      title: "Bank Account(s)",
      description:
        "Maintain the particulars of your Bank Account(s) with just a few clicks.",
      icon: <BriefcaseIcon />,
      hash: "bank-accounts-changes",
      disabled: true,
    },
    {
      id: "companyName",
      title: "Name of a company",
      description:
        "Initiate a new application with just a few clicks and you will be on your way.",
      icon: <BriefcaseIcon />,
      hash: "company-name-changes",
      disabled: true,
    },
  ];

  const handleExitDashboard = () => {
    setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
    dispatch(resetActiveDashboardSlice());
    history.push("/companies");
  };

  const handleCompanyProcessSelection = (companyProcess) => {
    if (companyProcess.hash) {
      // window.location.hash = companyProcess.hash;
      setCurrentStateOfProcess(companyProcess.hash);
      pages.push({
        name: companyProcess.title,
        href: "#",
        current: true,
      });
    }
    // else setSelectedCompanyProcess(companyProcess);
  };

  useEffect(() => {
    if (hash) {
      setSelectedCompanyProcess(
        companyProcesses.find((companyProcess) => companyProcess.hash === hash)
      );
    }
  }, [hash, companyProcesses, currentStateOfProcess]);

  return (
    <>
      <ManageModuleContext.Provider
        value={{
          currentStateOfProcess,
          appointmentStatus,
          setAppointmentStatus,
          appointmentInfo: {},
          changeOfParticularsInfo: {},
          cessationInfo: {},
        }}
      >
        <div className="bg-white container mx-auto px-4">
          <div className="">
            {showBreadCrumbs && (
              <BreadCrumb
                pages={pages}
                handleExitDashboard={handleExitDashboard}
              />
            )}
          </div>
        </div>
        <div className="container mx-auto px-4">
          {currentStateOfProcess === "default" ? (
            <div className="my-10 text-sflPrimary">
              <RadioGroup
                value={selectedCompanyProcess}
                onChange={handleCompanyProcessSelection}
                className="my-4"
              >
                <RadioGroup.Label className="text-base">
                  <div>
                    <h2 className="pb-4 font-semibold text-title5">
                      What you would like todo today
                    </h2>
                    <h1 className="font-medium">
                      Changes section, here you can update your company details
                      or information if you want
                    </h1>
                  </div>
                </RadioGroup.Label>
                <CompanyProcessesForChanges
                  companyProcesses={companyProcesses}
                />
              </RadioGroup>
            </div>
          ) : currentStateOfProcess === "company-changes" ? (
            <div className="my-10 text-sflPrimary">
              <RadioGroup
                value={selectedCompanyProcess}
                onChange={handleCompanyProcessSelection}
                className="my-4"
              >
                <RadioGroup.Label className="text-base">
                  <div>
                    <h2 className="pb-4 font-semibold text-title5">changes</h2>
                    <h1 className="font-medium">
                      Changes section, here you can update your company details
                      or information if you want
                    </h1>
                  </div>
                </RadioGroup.Label>
                <CompanyProcessesForChanges
                  companyProcesses={companyChangesProcesses}
                />
              </RadioGroup>
            </div>
          ) : currentStateOfProcess ===
            "officials-shareholders-authorized-person-changes" ? (
            <MaintenanceForm companyId={companyId} />
          ) : (
            ""
          )}
        </div>
      </ManageModuleContext.Provider>
    </>
  );
};
export default MaintainYourCompany;

function CompanyProcessesForChanges({ companyProcesses }) {
  return (
    <div
      className={
        companyProcesses.length > 2
          ? "grid grid-cols-3 mt-4 gap-y-6 sm:grid-cols-6 sm:gap-x-6"
          : "grid grid-cols-2 mt-4 gap-y-6 sm:grid-cols-4 sm:gap-x-4"
      }
    >
      {companyProcesses.map((companyProcess) => (
        <RadioGroup.Option
          key={companyProcess.id}
          value={companyProcess}
          disabled={companyProcess.disabled}
          className={({ checked, active }) =>
            classNames(
              checked ? "border-transparent" : "border-gray-300",
              active ? "ring-2 ring-indigo-500" : "",
              companyProcess.disabled ? "cursor-not-allowed" : "cursor-pointer",
              "col-span-2 relative bg-white border rounded-lg shadow-sm p-4 flex focus:outline-none select-none"
            )
          }
        >
          {({ checked, active }) => (
            <>
              <div className="flex flex-1">
                <div className="flex flex-col">
                  <div className="w-14 h-14">{companyProcess.icon}</div>
                  <RadioGroup.Label
                    as="span"
                    className="block text-sm font-medium"
                  >
                    {companyProcess.title}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className="flex items-center mt-1 text-sm"
                  >
                    {companyProcess.description}
                  </RadioGroup.Description>
                </div>
              </div>
              <CheckCircleIcon
                className={classNames(
                  !checked ? "invisible" : "",
                  "h-5 w-5 text-indigo-600"
                )}
                aria-hidden="true"
              />
              <div
                className={classNames(
                  active && !checked ? "border" : "border-2",
                  checked ? "border-indigo-500" : "border-transparent",
                  "absolute -inset-px rounded-lg pointer-events-none"
                )}
                aria-hidden="true"
              />
            </>
          )}
        </RadioGroup.Option>
      ))}
    </div>
  );
}
