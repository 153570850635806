import React, { useContext, useEffect, useState } from "react";
import _get from "lodash/get";
import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { getMaxCompanySecretariesLimit } from "utils";
import { Constants } from "config/constants";
import options from "../../../PLCTABS/SFLdata/selectOptions";
import CompanyOfficialSelection from "components/PLCTABS/CompanyOfficialsTab/CompanyOfficialSelection";
import Button from "components/lib/Shared/Button";
import ManageAppointmentTable from "./ManageAppointmentTable";
import { setCurrentJSGroupID } from "state/slices/tables/jointShareholderTable";
import { getCompanyOfficialsAsync } from "state/slices/tables/companyOfficialsSlice";
import { ManageModuleContext } from "context/ManageCompanyModuleContext";

const Appointment = ({
  companyId,
  addOfficialProcessType,
  setWhichFormToBeRendered,
}) => {
  const dispatch = useDispatch();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [recentUpdateOfficials, setRecentUpdateOfficials] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const [showCompanyOfficialsForm, setShowCompanyOfficialsForm] =
    useState(true);

  const [isViewOnly, setIsViewOnly] = useState(false);
  const role = Cookies.get("role");
  const [mode, setMode] = useState({ editing: false, id: "", viewing: false });
  const { appointmentStatus } = useContext(ManageModuleContext);

  const companyDashboardSlice = useSelector(
    (state) => state.companyDashboardSlice
  );

  const { getCompanyOfficialsStatus } = companyDashboardSlice;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getCompanyOfficialsAsync({
            companyId,
            queryParams: {
              manageModuleStatus: "Draft",
              designation: "Authorized Person",
            },
          })
        );
        setRecentUpdateOfficials(response.payload.items);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    return () => {
      // Cleanup logic if needed
    };
  }, [companyId, dispatch]);

  useEffect(() => {
    if (appointmentStatus) {
      setShowNotification(true);
      const timeout = setTimeout(() => {
        setShowNotification(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [appointmentStatus]);

  const numberOfCompanySecretaries =
    recentUpdateOfficials &&
    recentUpdateOfficials.filter(
      (official) => official.designation === "Company Secretary"
    ).length;

  const companyOfficialOptions = [
    ...options.companyOfficials.filter(
      (official) => official.value !== "Company Secretary"
    ),
    {
      value: "Company Secretary",
      label: "Company Secretary",
      disabled:
        numberOfCompanySecretaries === getMaxCompanySecretariesLimit("PVT"),
    },
  ];

  const toggleShowOfficialsForm = (show) => {
    if (show) {
      setMode({ editing: false, id: "", designation: null, viewing: false });
      setShowCompanyOfficialsForm(true);
    } else {
      setShowCompanyOfficialsForm(show);
      setWhichFormToBeRendered("");
    }
  };

  const handleAddCompanyOfficial = () => {
    setMode({ editing: false, id: "", designation: null, viewing: false });
    setShowCompanyOfficialsForm(true);
    dispatch(setCurrentJSGroupID(""));
  };

  const handleBackBtn = () => {
    if (currentSectionIndex - 1 >= 0)
      setCurrentSectionIndex(currentSectionIndex - 1);
  };

  return (
    <>
      {showNotification && (
        <div className="notification-container flex justify-center px-4 py-8 mt-2 bg-gray-200 rounded-sm shadow-sm sm:rounded md:rounded-md relative">
          <h2 className="text-gray-900 font-normal">
            New Official is appointed successfully!
          </h2>
          <span
            className="close-icon absolute top-2 right-2 cursor-pointer text-lg hover:bg-gray-300"
            onClick={() => setShowNotification(false)}
          >
            <XIcon className="h-6 w-6" />
          </span>
        </div>
      )}
      {!showNotification && showCompanyOfficialsForm && (
        <CompanyOfficialSelection
          companyOfficialOptions={companyOfficialOptions}
          toggleShowOfficialsForm={toggleShowOfficialsForm}
          mode={mode}
          isViewOnly={isViewOnly}
          setMode={setMode}
          handleBackBtn={handleBackBtn}
          setIsViewOnly={setIsViewOnly}
          addOfficialProcessType={addOfficialProcessType}
        />
      )}
      {recentUpdateOfficials && recentUpdateOfficials.length > 0 && (
        <>
          <div className="flex justify-between">
            <h4 className="text-gray-800 font-semibold mt-2 mb-2">
              Recent Updates
            </h4>
            {!showCompanyOfficialsForm &&
              role !== Constants.COOKIE_ROLES.OFFICIAL && (
                <div className="mt-2 d-flex justify-content-end">
                  <Button
                    variant="text"
                    onClick={handleAddCompanyOfficial}
                    className="inline-flex px-2 py-2 mb-2 bg-slate-100 items-center justify-center space-x-2 text-sm font-medium rounded-md text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span>Initiate New Change</span>
                  </Button>
                </div>
              )}
          </div>
          <div>
            <ManageAppointmentTable
              data={recentUpdateOfficials}
              totalCount={0}
              companyId={companyId}
              isLoading={getCompanyOfficialsStatus.status === "loading"}
            />
          </div>
          <div className="flex justify-end mt-4">
            <Button type="submit"> Proceed </Button>
          </div>
        </>
      )}
    </>
  );
};
export default Appointment;
