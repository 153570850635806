import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (params) =>
  axios.get(`/template-categories?${getQueryString(params)}`);

const create = (data) => axios.post("/template-categories", data);

const update = (templateCategoryId, data) =>
  axios.put(`/template-categories/${templateCategoryId}`, data);

const remove = (templateCategoryId) =>
  axios.delete(`/template-categories/${templateCategoryId}`);

const templateCategoryService = {
  getAll,
  create,
  update,
  remove,
};

export default templateCategoryService;
