import { Constants } from "config/constants";

const checkPLCRegistrar = (officials) => {
  const registrarCandidates = officials.filter((official) =>
    ["Company Secretary"].includes(official.designation)
  );
  const existingRegistrar = registrarCandidates.find(
    (official) => official.isRegistrar
  );
  return {
    registrarCandidates,
    existingRegistrar,
    showSelectRegistrarBtn:
      registrarCandidates.length > 1 && !existingRegistrar,
  };
};

const checkPVTRegistrar = (officials) => {
  const registrarCandidates = officials.filter((official) =>
    ["Company Secretary"].includes(official.designation)
  );

  const existingRegistrar = registrarCandidates.find(
    (official) => official.isRegistrar
  );

  return {
    registrarCandidates,
    existingRegistrar,
    showSelectRegistrarBtn:
      registrarCandidates.length > 1 && !existingRegistrar,
  };
};

export const checkForCompaniesRegistrar = ({
  type = "PLC",
  officials = [],
}) => {
  if (officials.length === 0)
    return { existingRegistrar: false, registrarCandidates: [] };
  switch (type) {
    case "PLC":
      return checkPLCRegistrar(officials);
    case "PVT":
      return checkPVTRegistrar(officials);
    default:
      return { existingRegistrar: false, registrarCandidates: [] };
  }
};
export const getCompanyNameWithSuffix = (company) => {
  if (company && company.registrationName) {
    return company.registrationName;
  }

  if (company && company.companyName1) {
    const companyType = company.type ?? "PLC";
    const nameSuffix = getCompanySuffix(
      company.isCompanyName1Abbreviated,
      companyType
    );

    return `${company.companyName1} ${nameSuffix}`;
  }

  return "N/A"; // Return default value if neither registrationName nor companyName1 is present
};

export const getCompanySuffix = (isAbbreviated, companyType = "PLC") => {
  return isAbbreviated
    ? companyType === "PVT"
      ? "LTD"
      : companyType
    : Constants.COMPANY_NAME_SUFFICE[companyType];
};
