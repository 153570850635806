/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import _set from "lodash/set";

import companyDataService from "services/company";
import { updateRegisteredOfficeState } from "./registeredOffice";
import { setNominalShares } from "./tables/nominalSharesTable";
import { getCompanyOfficialsAsync } from "./tables/companyOfficialsSlice";
import { setBeneficialOwner } from "./tables/beneficialOwnersTableSlice";

// Company related slices
import { resetCommentsSlice } from "state/slices/comment";
import { resetRegisteredOfficeSlice } from "state/slices/registeredOffice";
import { resetSharesTable } from "state/slices/tables/nominalSharesTable";
import { resetCompanyOfficialsTableSlice } from "state/slices/tables/companyOfficialsSlice";
import { resetBeneficialOwnerTable } from "state/slices/tables/beneficialOwnersTableSlice";
import { resetAccessRequestsSlice } from "state/slices/accessRequest";
import { resetAccessRequestCommentsSlice } from "state/slices/accessRequestComment";
import { resetJointShareholderTable } from "state/slices/tables/jointShareholderTable";
import {
  resetCurrentJSGroup as resetCorporateCurrentJSGroup,
  resetJointShareholderTable as resetCorporateJointShareholderTable,
} from "state/slices/tables/corporateJointShareholderTableSlice";
import { resetCorporateShareholderTable } from "state/slices/tables/corporateShareholderTableSlice";
import { resetReviewersTableSlice } from "state/slices/tables/reviewersTable";

export const step1Attributes = [
  "companyName1",
  "companyName2",
  "companyName3",
  "companyName4",
  "companyName5",
  "companyName1WithSuffix",
  "preferredNamesCount",
  "isCompanyName1Abbreviated",
  "isCompanyName2Abbreviated",
  "isCompanyName3Abbreviated",
  "isCompanyName4Abbreviated",
  "isCompanyName5Abbreviated",
  "specialInstructions1",
  "specialInstructions2",
  "specialInstructions3",
  "specialInstructions4",
  "specialInstructions5",
  "affiliatedBusiness1",
  "affiliatedBusiness2",
  "affiliatedBusiness3",
  "affiliatedBusiness4",
  "affiliatedBusiness5",
  "fileUploads.affiliatedBusiness1_LNO",
  "fileUploads.affiliatedBusiness2_LNO",
  "fileUploads.affiliatedBusiness3_LNO",
  "fileUploads.affiliatedBusiness4_LNO",
  "fileUploads.affiliatedBusiness5_LNO",
];

export const step2Attributes = [
  "articlesOfAssociation",
  "articlesOfAssociationToUse",
  "associationChoice",
  "fileUploads.regulatedArticles_LNO",
];

export const step3Attributes = [
  "primaryBusinessActivity",
  "subsidiaryDetail",
  "amalgamationDetail",
];

const initialState = {
  companyFormation: {
    step1: {
      companyName1: "",
      companyName2: "",
      companyName3: "",
      preferredNamesCount: 3,
      isCompanyName1Abbreviated: false,
      isCompanyName2Abbreviated: false,
      isCompanyName3Abbreviated: false,
      isCompanyName4Abbreviated: false,
      isCompanyName5Abbreviated: false,
      affiliatedBusiness1: {},
      affiliatedBusiness2: {},
      affiliatedBusiness3: {},
    },
    step2: {
      articlesOfAssociation: {},
    },
    step3: {
      primaryBusinessActivity: {
        sector: "",
        division: "",
        group: "",
        businessClass: "",
        targetBusinessStartDate: "",
        accountingEndMonth: "",
        numberOfEmployees: "",
        turnOver: "",
      },
      subsidiaryDetail: {},
      amalgamationDetail: {},
    },
    resolvedFields: [],
  },
  registeredOffice: {
    postalAddress: "",
    postalCode: "",
    serviceAddress: "",
    companyEmailAddress: "",
    mobileNumber: "",
    buildingName: "",
    houseNumber: "",
    estate: "",
    floorNumber: "",
    streetName: "",
    streetNumber: "",
    location: {
      county: "",
      district: "",
      locality: "",
    },
    resolvedFields: [],
  },
  resolvedFields: [],
  nameValidity: { isVerifying: false },
  getCompanyStatus: {
    status: "idle",
    error: false,
  },
  downloadBusinessDocuments: {
    status: "idle",
    error: false,
  },
  getContactPersonCandidatesStatus: {
    status: "idle",
    error: false,
  },
  validateCompanyOfficialsStatus: {
    status: "idle",
    error: false,
  },
  contactPersonCandidates: [],
  updateCompany: {
    status: "idle",
    error: false,
  },
};

export const downloadBusinessDocumentsAsync = createAsyncThunk(
  "companies/downloadBusinessDocuments",
  async ({ companyId, businessDocument }, thunkAPI) => {
    try {
      const response = await companyDataService.downloadBusinessDocuments(
        companyId,
        businessDocument.key
      );
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", businessDocument.name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCompany = createAsyncThunk(
  "companies/create",
  async ({ data }) => {
    const res = await companyDataService.create(data);
    return res.data;
  }
);

export const updateCompanyAsync = createAsyncThunk(
  "companies/updateAsync",
  async ({ id, data }, thunkAPI) => {
    const res = await companyDataService.update(id, data);
    const step1 = _pick(res.data, step1Attributes);
    const step2 = _pick(res.data, step2Attributes);
    const step3 = _pick(res.data, step3Attributes);
    const resolvedFields = _get(res.data, "resolvedFields");
    thunkAPI.dispatch(
      updateCompanyState({
        companyFormation: { step1, step2, step3, resolvedFields },
        articlesOfAssociation: res.data.articlesOfAssociation,
        resolvedFields,
      })
    );
    return res.data;
  }
);

export const getCompany = createAsyncThunk(
  "companies/get",
  async ({ id, isDashboard }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCompanyOfficialsAsync({ companyId: id }));
      const res = await companyDataService.get(id, isDashboard);
      const step1 = _pick(res.data, step1Attributes);
      const step2 = _pick(res.data, step2Attributes);
      const step3 = _pick(res.data, step3Attributes);
      const resolvedFields = _get(res.data, "resolvedFields");
      thunkAPI.dispatch(
        updateCompanyState({
          companyFormation: { step1, step2, step3, resolvedFields },
          resolvedFields,
        })
      );
      thunkAPI.dispatch(
        updateRegisteredOfficeState(
          res.data?.registeredOffice ?? initialState.registeredOffice
        )
      );
      thunkAPI.dispatch(setNominalShares(res.data?.nominalShares ?? []));
      thunkAPI.dispatch(setBeneficialOwner(res.data?.beneficialOwners));
      return res.data;
    } catch (error) {
      if (error.status === 404) {
        window.location.href = "/404";
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const submitApplication = createAsyncThunk(
  "companies/submit-application",
  async ({ data }) => {
    const res = await companyDataService.submitApplication(data);
    return res.data;
  }
);

export const submitApplicationToBRS = createAsyncThunk(
  "companies/submit-application-to-brs",
  async ({ data }) => {
    const res = await companyDataService.submitApplicationToBRS(data);
    return res.data;
  }
);

const setVerifyingLoading = (thunkAPI, fieldName, isVerifying) => {
  const applicationState = thunkAPI.getState();

  thunkAPI.dispatch(
    updateCompanyState({
      nameValidity: {
        ...applicationState.companySlice.nameValidity,
        isVerifying,
        [fieldName]: {
          isVerifying,
        },
      },
    })
  );
};

export const verifyName = createAsyncThunk(
  "companies/verify-name",
  _debounce(async ({ name, fieldName }, thunkAPI) => {
    const applicationState = thunkAPI.getState();
    try {
      setVerifyingLoading(thunkAPI, fieldName, true);
      const res = await companyDataService.verifyName(name);
      thunkAPI.dispatch(
        updateCompanyState({
          nameValidity: {
            ...applicationState.companySlice.nameValidity,
            isVerifying: false,
            [fieldName]: {
              ...res.data,
              isVerifying: false,
            },
          },
        })
      );
      return res.data;
    } catch (error) {
      console.log(error);
      setVerifyingLoading(thunkAPI, fieldName, false);
    }
  }, 1000)
);

export const handleRecallApplication = createAsyncThunk(
  "companies/recall-application",
  async ({ companyId }) => {
    const res = await companyDataService.handleRecallApplication(companyId);
    return res.data;
  }
);

export const resetCompanyRelatedSlices = createAsyncThunk(
  "companies/resetCompanyRelatedSlices",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(resetRegisteredOfficeSlice());
    thunkAPI.dispatch(resetSharesTable());
    thunkAPI.dispatch(resetCompanyOfficialsTableSlice());
    thunkAPI.dispatch(resetBeneficialOwnerTable());
    thunkAPI.dispatch(resetAccessRequestsSlice());
    thunkAPI.dispatch(resetAccessRequestCommentsSlice());
    thunkAPI.dispatch(resetCommentsSlice());
    thunkAPI.dispatch(resetJointShareholderTable());
    thunkAPI.dispatch(resetCorporateCurrentJSGroup());
    thunkAPI.dispatch(resetCorporateJointShareholderTable());
    thunkAPI.dispatch(resetCorporateShareholderTable());
    thunkAPI.dispatch(resetReviewersTableSlice());
  }
);

export const convertArticlesOfAssociationToPdfAsync = createAsyncThunk(
  "companies/convertArticlesOfAssociationToPdf",
  async ({ companyId }, thunkAPI) => {
    try {
      const response =
        await companyDataService.convertArticlesOfAssociationToPdf(companyId);
      thunkAPI.dispatch(
        updateNestedCompanyState({
          newNestedField: response.data.preSignedBusinessDocuments,
          updateEffectPath: "companySlice.company.preSignedBusinessDocuments",
        })
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getContactPersonCandidatesAsync = createAsyncThunk(
  "companies/getContactPersonCandidatesAsync",
  async ({ companyId }, thunkAPI) => {
    try {
      const response = await companyDataService.getContactPersonCandidates(
        companyId
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkAffiliationAsync = createAsyncThunk(
  "companies/checkAffiliationAsync",
  async ({ companyId }, thunkAPI) => {
    try {
      const response = await companyDataService.checkAffiliation(companyId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const validateCompanyOfficialsAsync = createAsyncThunk(
  "companies/validateCompanyOfficialsAsync",
  async ({ companyId }, thunkAPI) => {
    try {
      const response = await companyDataService.validateCompanyOfficials(
        companyId
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const notifyApplicantOnSubmissionByACoApplicantAsync = createAsyncThunk(
  "companies/notifyApplicantOnSubmissionByACoApplicantAsync",
  async ({ companyId }, thunkAPI) => {
    try {
      const response =
        await companyDataService.notifyApplicantOnSubmissionByACoApplicant(
          companyId
        );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendApplicationToDirectorsReviewAsync = createAsyncThunk(
  "companies/sendApplicationToDirectorsReviewAsync",
  async ({ companyId }, thunkAPI) => {
    try {
      const response =
        await companyDataService.sendApplicationToDirectorsReview(companyId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateCompanyState: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateNestedCompanyState: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
    reset: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [createCompany.fulfilled]: (state, action) => {
      state.company = action.payload.company;
    },

    [updateCompanyAsync.pending]: (state) => {
      state.updateCompany.status = "loading";
      state.updateCompany.error = false;
    },
    [updateCompanyAsync.fulfilled]: (state, action) => {
      state.company = {
        ...state.company,
        fileUploads: action.payload.fileUploads ?? {},
        registrationNumber: action.payload.registrationNumber,
        status: action.payload.status,
        contactPerson: action.payload.contactPerson,
        articlesOfAssociation: action.payload.articlesOfAssociation,
        applicantCapacity: action.payload?.applicantCapacity,
      };
      state.updateCompany.status = "idle";
      state.updateCompany.error = false;
    },
    [updateCompanyAsync.rejected]: (state, action) => {
      state.updateCompany.status = "failed";
      state.updateCompany.error = action.payload.messageText;
    },

    [getCompany.pending]: (state) => {
      state.getCompanyStatus.status = "loading";
      state.getCompanyStatus.error = false;
    },
    [getCompany.fulfilled]: (state, action) => {
      state.company = action.payload;
      state.getCompanyStatus.status = "idle";
      state.getCompanyStatus.error = false;
    },
    [getCompany.rejected]: (state, action) => {
      state.getCompanyStatus.status = "failed";
      state.getCompanyStatus.error = action.payload.messageText;
    },

    [getContactPersonCandidatesAsync.pending]: (state) => {
      state.getContactPersonCandidatesStatus.status = "loading";
      state.getContactPersonCandidatesStatus.error = false;
    },
    [getContactPersonCandidatesAsync.fulfilled]: (state, action) => {
      state.contactPersonCandidates = action.payload.contactPersonCandidates;
      state.getContactPersonCandidatesStatus.status = "idle";
      state.getContactPersonCandidatesStatus.error = false;
    },
    [getContactPersonCandidatesAsync.rejected]: (state, action) => {
      state.getContactPersonCandidatesStatus.status = "failed";
      state.getContactPersonCandidatesStatus.error = action.payload.messageText;
    },

    [validateCompanyOfficialsAsync.pending]: (state) => {
      state.validateCompanyOfficialsStatus.status = "loading";
      state.validateCompanyOfficialsStatus.error = false;
    },
    [validateCompanyOfficialsAsync.fulfilled]: (state, action) => {
      state.validateCompanyOfficialsStatus.status = "idle";
      state.validateCompanyOfficialsStatus.error = false;
    },
    [validateCompanyOfficialsAsync.rejected]: (state, action) => {
      state.validateCompanyOfficialsStatus.status = "failed";
      state.validateCompanyOfficialsStatus.error = action.payload.messageText;
    },
  },
});

export const { updateCompanyState, updateNestedCompanyState, reset } =
  companySlice.actions;
const { reducer } = companySlice;

export default reducer;
