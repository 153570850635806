import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (queryParams) => {
  return axios.get(`/companies?${getQueryString(queryParams)}`);
};

const create = (data) => {
  return axios.post("/companies", data);
};

const update = (companyId, data) => {
  return axios.put(`/companies/${companyId}`, data);
};

const get = (companyId, isDashboard) => {
  let url = `/companies/${companyId}`;
  if (isDashboard) url += `?isDashboard=${isDashboard}`;
  return axios.get(url);
};

const submitApplication = (data) => {
  return axios.post("/companies/submit-application", data);
};

const submitApplicationToBRS = (data) =>
  axios.post("/companies/submit-application-to-brs", data);

const verifyName = (name) => {
  return axios({
    url: `${process.env.REACT_APP_VERIFY_NAME_BASE_API_URL}/api/verify-name?name=${name}`,
  });
};

const remove = (companyId) => axios.delete(`/companies/${companyId}`);

const handleRecallApplication = (companyId) =>
  axios.post(`/companies/${companyId}/recall`);

const downloadBusinessDocuments = (companyId, fileKey) =>
  axios.get(
    `/companies/${companyId}/download-business-documents?fileKey=${fileKey}`,
    {
      responseType: "blob",
    }
  );

const getManagedCompanies = (queryParams) =>
  axios.get(`/companies/get-managed-companies?${getQueryString(queryParams)}`);

const convertArticlesOfAssociationToPdf = (companyId) =>
  axios.put(`/companies/${companyId}/convert-articles-of-association-to-pdf`);

const getContactPersonCandidates = (companyId) =>
  axios.get(`/companies/${companyId}/get-contact-person-candidates`);

const checkAffiliation = (companyId) =>
  axios.get(`/companies/${companyId}/check-affiliation`);

const validateCompanyOfficials = (companyId) =>
  axios.get(`/companies/${companyId}/validate-company-officials`);

const notifyApplicantOnSubmissionByACoApplicant = (companyId) =>
  axios.post(
    `/companies/${companyId}/notify-applicant-on-submission-by-co-applicant`
  );

const sendApplicationToDirectorsReview = (companyId) =>
  axios.post(`/companies/${companyId}/send-application-to-directors-review`);

const companyDataService = {
  getAll,
  create,
  update,
  get,
  submitApplication,
  verifyName,
  remove,
  submitApplicationToBRS,
  handleRecallApplication,
  downloadBusinessDocuments,
  getManagedCompanies,
  convertArticlesOfAssociationToPdf,
  getContactPersonCandidates,
  checkAffiliation,
  validateCompanyOfficials,
  notifyApplicantOnSubmissionByACoApplicant,
  sendApplicationToDirectorsReview,
};

export default companyDataService;
