/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from "react";
import { useFormikContext } from "formik";

import Input from "components/lib/Shared/Input";

const IdentificationDetailsCompanyContactPerson = ({
  field,
  form,
  disabled,
  disableEmailAddress,
  disableContactName,
  disableTelNumber,
}) => {
  const { setFieldValue } = useFormikContext();

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <h3 className="my-6 text-lg font-medium leading-6 text-gray-900">
        Company Contact Person Information
      </h3>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Full Name"
            name="companyContactName"
            placeholder="Enter full name"
            form={form}
            value={field.value?.companyContactName ?? ""}
            errorPath="identificationDetails.companyContactName"
            disabled={disabled || disableContactName}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Designation"
            name="companyContactDesignation"
            placeholder="Enter designation details"
            form={form}
            value={field.value?.companyContactDesignation ?? ""}
            errorPath="identificationDetails.companyContactDesignation"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Telephone Number"
            name="telNumber"
            placeholder="Enter telephone number"
            form={form}
            value={field.value?.telNumber ?? ""}
            errorPath="identificationDetails.telNumber"
            disabled={disabled || disableTelNumber}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Email Address"
            name="emailAddress"
            placeholder="Enter email address"
            form={form}
            type="email"
            value={field.value?.emailAddress ?? ""}
            errorPath="identificationDetails.emailAddress"
            disabled={disabled || disableEmailAddress}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
      </div>
    </>
  );
};

export default IdentificationDetailsCompanyContactPerson;
