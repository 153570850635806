/**
 * @file The class to host frontend application-wide constants
 * @author Simple Formations
 */

export class Constants {
  static get COMPANY_OFFICIAL_ID_TYPES() {
    return [
      { value: "Kenyan Citizen", label: "Kenyan Citizen" },
      { value: "Foreign Resident", label: "Foreign Resident" },
      { value: "Foreigner", label: "Foreigner" },
      { value: "Local Company", label: "Local Company" },
      { value: "Foreign Company", label: "Foreign Company" },
      { value: "Other e.g Parastatals", label: "Other e.g Parastatals" },
      { value: "Minor", label: " Minor" },
    ];
  }

  static get OWNERS_ID_TYPES() {
    return [
      { value: "Kenyan Citizen", label: "Kenyan Citizen" },
      { value: "Foreign Resident", label: "Foreign Resident" },
      { value: "Foreigner", label: "Foreigner" },
      { value: "Local Company", label: "Local Company" },
      { value: "Foreign Company", label: "Foreign Company" },
      { value: "Other e.g Parastatals", label: "Other e.g Parastatals" },
    ];
  }

  static get MAX_JOINT_SHAREHOLDER_LIMIT() {
    return 4;
  }

  // public limited company
  static get MAX_PLC_COMPANY_SECRETARIES_LIMIT() {
    return 2;
  }

  // private limited company
  static get MAX_PVT_COMPANY_SHAREHOLDERS_LIMIT() {
    return 50;
  }
  static get MAX_PVT_COMPANY_SECRETARIES_LIMIT() {
    return 2;
  }

  static get COMPANIES_LIMIT_FOR_UNVERIFIED_USER() {
    return 1;
  }

  // Limited Liability Partnership organizations
  static get MIN_LLP_ORGANIZATION_PARTNERS_LIMIT() {
    return 2;
  }

  static get MIN_LLP_ORGANIZATION_MANAGERS_LIMIT() {
    return 1;
  }

  // Limited Partnership organizations
  static get MIN_LP_ORGANIZATION_GENERAL_PARTNER_LIMIT() {
    return 1;
  }
  static get MIN_LP_ORGANIZATION_LIMITED_PARTNER_LIMIT() {
    return 1;
  }
  static get MAX_LP_ORGANIZATION_PARTNERS_LIMIT() {
    return 20;
  }

  // General Partnership organizations
  static get MIN_GP_ORGANIZATION_PARTNER_LIMIT() {
    return 2;
  }
  static get MAX_GP_ORGANIZATION_PARTNERS_LIMIT() {
    return 20;
  }

  // Sole Proprietorship organizations
  static get MIN_SP_ORGANIZATION_PARTNER_LIMIT() {
    return 1;
  }
  static get MAX_SP_ORGANIZATION_PARTNER_LIMIT() {
    return 1;
  }

  static get MIN_NON_REDEEMABLE_CLASS() {
    return 1;
  }

  static get MIN_VOTING_SHARES_CLASS() {
    return 1;
  }

  static get INDIVIDUAL_BO_OPTIONS() {
    return [
      { value: "Kenyan Citizen", label: "Kenyan Citizen" },
      { value: "Foreign Resident", label: "Foreign Resident" },
      { value: "Foreigner", label: "Foreigner" },
      { value: "Minor", label: "Minor" },
      { value: "Trust", label: "Trust" },
    ];
  }

  static get CORPORATE_BO_OPTIONS() {
    return [
      { value: "Public Limited", label: "Public Limited" },
      { value: "Private Limited", label: "Private Limited" },
      {
        value: "Limited Liability Partnership",
        label: "Limited Liability Partnership",
      },
      { value: "Limited Partnership", label: "Limited Partnership" },
      { value: "Trust", label: "Trust" },
      {
        value: "Company Limited By Guarantee/NGO",
        label: "Company Limited By Guarantee/NGO",
      },
      { value: "State Owned Enterprise", label: "State Owned Enterprise" },
    ];
  }

  static get CS_AND_AP_ENTITY_TYPES() {
    return [
      {
        value: "Limited Liability Partnership",
        label: "Limited Liability Partnership",
      },
      { value: "Limited Partnership", label: "Limited Partnership" },
      { value: "General Partnership", label: "General Partnership" },
      { value: "Sole Proprietorship", label: "Sole Proprietorship" },
    ];
  }

  static get OTHER_PARASTATALS_ENTITY_TYPES() {
    return [
      { value: "Trust", label: "Trust" },
      {
        value: "Company Limited By Guarantee/NGO",
        label: "Company Limited By Guarantee/NGO",
      },
      { value: "State Owned Enterprise", label: "State Owned Enterprise" },
    ];
  }

  static get PARTNERSHIP_OPTIONS() {
    return [
      {
        value: "Limited Liability Partnership",
        label: "Limited Liability Partnership",
      },
      { value: "Limited Partnership", label: "Limited Partnership" },
      { value: "General Partnership", label: "General Partnership" },
      { value: "Sole Proprietorship", label: "Sole Proprietorship" },
    ];
  }

  static get ON_BOARDING_FORM_FIELDS() {
    return {
      "Kenyan Citizen": {
        identificationDetails: [
          {
            key: "nationalIDNumber",
            label: "National ID",
            showFilePreview: true,
            fileName: "nationalID",
          },
        ],
      },
      "Foreign Resident": {
        identificationDetails: [
          {
            key: "foreignCertificateID",
            label: "Foreign Certificate ID",
            showFilePreview: true,
            fileName: "foreignerCert",
          },
          {
            key: "foreignCertificateDateOfExpiry",
            label: "Foreign Certificate Date Of Expiry",
            isDate: true,
            fileName: "foreignCertificateDateOfExpiry",
          },
        ],
      },
      Foreigner: {
        identificationDetails: [
          { key: "passportNumber", label: "Passport Number" },
          {
            key: "passportDateOfExpiry",
            label: "Passport Date Of Expiry",
            isDate: true,
            showFilePreview: true,
            fileName: "passportBIOS",
          },
        ],
      },
    };
  }

  static get FORM_FIELDS() {
    const contactPersonInfoFields = [
      { key: "companyContactName", label: "Full Name" },
      { key: "companyContactDesignation", label: "Designation" },
      { key: "telNumber", label: "Telephone Number" },
      { key: "emailAddress", label: "Email Address" },
    ];

    const individualsNamesFields = [
      { key: "firstName", label: "First name" },
      { key: "middleName", label: "Middle name" },
      { key: "lastName", label: "Last name" },
      { key: "formerName", label: "Former name" },
    ];

    const electronicAddressFields = [
      { key: "emailAddress", label: "Email address" },
      { key: "mobileNumber", label: "Mobile number" },
    ];

    return {
      "Kenyan Citizen": {
        names: individualsNamesFields,
        identificationDetails: [
          { key: "dateOfBirth", label: "Date of birth", isDate: true },
          {
            key: "nationalIDNumber",
            label: "National ID",
            showFilePreview: true,
            fileName: "nationalID",
          },
          {
            key: "KRAPin",
            label: "KRA PIN",
            showFilePreview: true,
            fileName: "KRACert",
          },
          { key: "occupation", label: "Occupation" },
          { key: "gender", label: "Gender" },
          { key: "nationality", label: "Nationality", isCountry: true },
        ],
        electronicAddress: electronicAddressFields,
        postalAddress: [
          { key: "postalAddress", label: "Postal Address" },
          { key: "postalCode", label: "Postal code" },
          { key: "serviceAddress", label: "Service address" },
        ],
        residentialAddressDetails: [
          { key: "buildingName", label: "Building Name" },
          { key: "houseNumber", label: "House Number" },
          { key: "estate", label: "Estate" },
          { key: "floorNumber", label: "Floor Number" },
          { key: "streetName", label: "Street Name" },
          { key: "streetNumber", label: "Street Number" },
          { key: "county", label: "County" },
          { key: "district", label: "District" },
          { key: "locality", label: "Locality" },
        ],
      },
      "Foreign Resident": {
        names: individualsNamesFields,
        identificationDetails: [
          { key: "dateOfBirth", label: "Date of birth", isDate: true },
          {
            key: "foreignCertificateID",
            label: "Foreign Certificate ID",
            showFilePreview: true,
            fileName: "foreignerCert",
          },
          {
            key: "foreignCertificateDateOfExpiry",
            label: "Foreign Certificate Date Of Expiry",
            isDate: true,
            fileName: "foreignCertificateDateOfExpiry",
          },
          {
            key: "KRAPin",
            label: "KRA PIN",
            showFilePreview: true,
            fileName: "KRACert",
          },
          { key: "occupation", label: "Occupation" },
          { key: "nationality", label: "Nationality", isCountry: true },
          { key: "gender", label: "Gender" },
        ],
        postalAddress: [
          { key: "postalAddress", label: "Postal Address" },
          { key: "postalCode", label: "Postal code" },
          { key: "serviceAddress", label: "Service address" },
        ],
        electronicAddress: electronicAddressFields,
        residentialAddressDetails: [
          { key: "buildingName", label: "Building Name" },
          { key: "houseNumber", label: "House Number" },
          { key: "estate", label: "Estate" },
          { key: "floorNumber", label: "Floor Number" },
          { key: "streetName", label: "Street Name" },
          { key: "streetNumber", label: "Street Number" },
          { key: "county", label: "County" },
          { key: "district", label: "District" },
          { key: "locality", label: "Locality" },
        ],
      },
      Foreigner: {
        names: individualsNamesFields,
        identificationDetails: [
          { key: "dateOfBirth", label: "Date of birth", isDate: true },
          { key: "passportNumber", label: "Passport Number" },
          {
            key: "passportDateOfExpiry",
            label: "Passport Date Of Expiry",
            isDate: true,
            showFilePreview: true,
            fileName: "passportBIOS",
          },
          { key: "occupation", label: "Occupation" },
          { key: "nationality", label: "Nationality", isCountry: true },
          { key: "gender", label: "Gender" },
        ],
        electronicAddress: electronicAddressFields,
        postalAddress: [
          { key: "postalAddress", label: "Postal Address" },
          { key: "serviceAddress", label: "Service address" },
        ],
        residentialAddressDetails: [
          { key: "buildingName", label: "Building Name" },
          { key: "houseNumber", label: "House Number" },
          { key: "estate", label: "Estate" },
          { key: "floorNumber", label: "Floor Number" },
          { key: "streetName", label: "Street Name" },
          { key: "streetNumber", label: "Street Number" },
          { key: "city", label: "City" },
          { key: "country", label: "Country", isCountry: true },
        ],
      },
      Minor: {
        names: individualsNamesFields,
        identificationDetails: [
          {
            key: "birthCertificateNumber",
            label: "Birth Certificate Number",
            showFilePreview: true,
            fileName: "birthCertificate",
          },
          { key: "dateOfBirth", label: "Date of birth", isDate: true },
          { key: "gender", label: "Gender" },
          { key: "nationality", label: "Nationality", isCountry: true },
          { key: "guardianFullName", label: "Guardian Full Name" },
          { key: "guardianRelationship", label: "Guardian Relationship" },
          { key: "guardianMobileNumber", label: "Guardian Mobile Number" },
          { key: "guardianEmailAddress", label: "Guardian Email Address" },
        ],
        electronicAddress: electronicAddressFields,
        postalAddress: [
          { key: "postalAddress", label: "Postal Address" },
          { key: "postalCode", label: "Postal code" },
          { key: "serviceAddress", label: "Service address" },
        ],
        residentialAddressDetails: [
          { key: "buildingName", label: "Building Name" },
          { key: "houseNumber", label: "House Number" },
          { key: "estate", label: "Estate" },
          { key: "floorNumber", label: "Floor Number" },
          { key: "streetName", label: "Street Name" },
          { key: "streetNumber", label: "Street Number" },
          { key: "county", label: "County" },
          { key: "district", label: "District" },
          { key: "locality", label: "Locality" },
        ],
      },
      "Local Company": {
        names: [
          { key: "companyName", label: "Company Name" },
          { key: "companyRegNumber", label: "Company Registration Number" },
          {
            key: "dateOfRegistration",
            label: "Company Registration Date",
            isDate: true,
          },
        ],
        identificationDetails: [
          {
            key: "companyKRAPIN",
            label: "Company's KRA PIN",
            showFilePreview: true,
            fileName: "KRACert",
          },
          { key: "companyFormerName", label: "Company Former Name" },
          {
            key: "countryOfIncorporation",
            label: "Country of Incorporation",
            isCountry: true,
            showFilePreview: true,
            fileName: "certOfIncorporation",
          },
          { key: "entityType", label: "Entity Type" },
        ],
        contactPersonInfo: contactPersonInfoFields,
        postalAddress: [
          { key: "postalAddress", label: "Postal Address" },
          { key: "postalCode", label: "Postal code" },
          { key: "serviceAddress", label: "Service address" },
        ],
        electronicAddress: electronicAddressFields,
        residentialAddressDetails: [
          { key: "buildingName", label: "Building Name" },
          { key: "houseNumber", label: "House Number" },
          { key: "estate", label: "Estate" },
          { key: "floorNumber", label: "Floor Number" },
          { key: "streetName", label: "Street Name" },
          { key: "streetNumber", label: "Street Number" },
          { key: "county", label: "County" },
          { key: "district", label: "District" },
          { key: "locality", label: "Locality" },
        ],
      },
      "Foreign Company": {
        names: [
          { key: "companyName", label: "Company Name" },
          { key: "companyRegNumber", label: "Company Registration Number" },
          {
            key: "dateOfRegistration",
            label: "Company Registration Date",
            isDate: true,
          },
        ],
        identificationDetails: [
          {
            key: "countryOfIncorporation",
            label: "Country of Incorporation",
            isCountry: true,
            showFilePreview: true,
            fileName: "certOfIncorporation",
          },
          { key: "companyFormerName", label: "Company Former Name" },
          { key: "entityType", label: "Entity Type" },
        ],
        contactPersonInfo: contactPersonInfoFields,
        postalAddress: [
          { key: "postalAddress", label: "Postal Address" },
          { key: "serviceAddress", label: "Service address" },
        ],
        electronicAddress: electronicAddressFields,
        residentialAddressDetails: [
          { key: "buildingName", label: "Building Name" },
          { key: "houseNumber", label: "House Number" },
          { key: "estate", label: "Estate" },
          { key: "floorNumber", label: "Floor Number" },
          { key: "streetName", label: "Street Name" },
          { key: "streetNumber", label: "Street Number" },
          { key: "city", label: "City" },
          { key: "country", label: "Country" },
        ],
      },
      "Other e.g Parastatals": {
        names: [
          { key: "companyName", label: "Company Name" },
          { key: "companyRegNumber", label: "Company Registration Number" },
          {
            key: "dateOfRegistration",
            label: "Company Registration Date",
            isDate: true,
          },
        ],
        identificationDetails: [
          { key: "companyKRAPIN", label: "Company's KRA PIN" },
          { key: "companyFormerName", label: "Company Former Name" },
          { key: "governingLaw", label: "Governing Law" },
          {
            key: "countryOfIncorporation",
            label: "Country of Incorporation",
            isCountry: true,
          },
          { key: "entityType", label: "Entity Type" },
        ],
        contactPersonInfo: contactPersonInfoFields,
        postalAddress: [
          { key: "postalAddress", label: "Postal Address" },
          { key: "postalCode", label: "Postal code" },
          { key: "serviceAddress", label: "Service address" },
        ],
        electronicAddress: electronicAddressFields,
        residentialAddressDetails: [
          { key: "buildingName", label: "Building Name" },
          { key: "houseNumber", label: "House Number" },
          { key: "estate", label: "Estate" },
          { key: "floorNumber", label: "Floor Number" },
          { key: "streetName", label: "Street Name" },
          { key: "streetNumber", label: "Street Number" },
          { key: "county", label: "County" },
          { key: "district", label: "District" },
          { key: "locality", label: "Locality" },
        ],
      },
      "State Owned Enterprise": {
        names: [{ key: "fullName", label: "Full Name" }],
        identificationDetails: [],
      },
    };
  }

  static get COMPANY_NAME_SUFFICE() {
    return {
      PLC: "PUBLIC LIMITED COMPANY",
      PVT: "LIMITED",
    };
  }

  static get COMPANY_NAME_ABBREVIATION() {
    return {
      "Public Limited Company": "PLC",
      "Private Company": "PVT",
    };
  }

  static get PAYMENT_TRANSACTION_NEXT_STEPS() {
    return {
      PROCEED: "PROCEED",
      RETRY: "RETRY",
      REGENERATE: "REGENERATE",
      INITIATE: "INITIATE",
    };
  }

  static get REQUIRED_NAME_FIELDS() {
    return [
      "firstName",
      "lastName",
      "companyName",
      "companyRegNumber",
      "dateOfRegistration",
    ];
  }

  static get REQUIRED_IDENTIFICATION_FIELDS() {
    return [
      "dateOfBirth",
      "gender",
      "nationalIDNumber",
      "KRAPin",
      "occupation",
      "foreignCertificateID",
      "nationality",
      "passportNumber",
      "passportDateOfExpiry",
      "birthCertificateNumber",
      "guardianFullName",
      "guardianRelationship",
      "guardianMobileNumber",
      "guardianEmailAddress",
      "companyContactName",
      "companyContactDesignation",
      "telNumber",
      "emailAddress",
      "companyKRAPIN",
      "countryOfIncorporation",
      "governingLaw",
    ];
  }

  static get REQUIRED_POSTAL_CODE_FIELDS() {
    return ["isNonResident", "postalAddress", "postalCode"];
  }

  static get REQUIRED_ELECTRONIC_ADDRESS_FIELDS() {
    return ["emailAddress", "companyEmailAddress", "mobileNumber"];
  }

  static get REQUIRED_RESIDENTIAL_FIELDS() {
    return [
      "buildingName",
      "estate",
      "city",
      "country",
      "county",
      "district",
      "locality",
    ];
  }

  static get REQUIRED_FILE_UPLOAD_FIELDS() {
    return [
      "passportPhoto",
      "passportBIOS",
      "KRACert",
      "foreignerCert",
      "practicingCert",
      "nationalID",
      "certOfIncorporation",
      "KRAPIN",
      "leadPartnerCertificate",
      "articlesOfAssociation",
      "birthCertificate",
      "gazetteNotice",
      "formCR1",
      "formCR2",
      "statementOfNominalCapital",
      "formCR8",
      "formCR10",
      "formCR12",
    ];
  }

  static get REQUIRED_SHARE_ALLOCATION_FIELDS() {
    return ["classOfShares", "allottedShares", "paidUpShares", "sharePrice"];
  }

  static get REQUIRED_OFFICIALS_FIELDS() {
    return [
      ...this.REQUIRED_NAME_FIELDS,
      ...this.REQUIRED_IDENTIFICATION_FIELDS,
      ...this.REQUIRED_POSTAL_CODE_FIELDS,
      ...this.REQUIRED_ELECTRONIC_ADDRESS_FIELDS,
      ...this.REQUIRED_RESIDENTIAL_FIELDS,
      ...this.REQUIRED_SHARE_ALLOCATION_FIELDS,
    ];
  }

  static get DELAY_FORM_SUBMISSION() {
    return 1000;
  }

  static get MAX_COMMENT_LENGTH() {
    return 1000;
  }

  static get DASHBOARD_ROLES() {
    return {
      VOR: "VOR",
      UPLOADER: "UPLOADER",
      REVIEWER: "REVIEWER",
      APPROVER: "APPROVER",
      REGISTRAR: "REGISTRAR",
    };
  }

  static get DASHBOARD_PERMISSIONS() {
    return {
      VIEW_AND_EDIT_OWN_DATA: "VIEW_AND_EDIT_OWN_DATA",
      VIEW_PUBLIC_DATA: "VIEW_PUBLIC_DATA",
      VIEW_RESTRICTED_DATA: "VIEW_RESTRICTED_DATA",
      EDIT_OTHER_DATA: "EDIT_OTHER_DATA",
      REVIEW_TRANSACTIONS: "REVIEW_TRANSACTIONS",
      APPROVE: "APPROVE",
      MANAGE_USERS: "MANAGE_USERS",
      REGISTER_OF_MEMBERS: "REGISTER_OF_MEMBERS",
      REGISTER_OF_DIRECTORS: "REGISTER_OF_DIRECTORS",
      MINUTE_BOOK_DIRECTORS: "MINUTE_BOOK_DIRECTORS",
      SHOW_REQUEST_BUTTON: "SHOW_REQUEST_BUTTON",
    };
  }

  static get COOKIE_ROLES() {
    return {
      APPLICANT: "7",
      REVIEWER: "8",
      SUPER_ADMIN: "9",
      SYSTEM_ADMIN: "11",
      ORG_ADMIN: "13",
      OFFICIAL: "6",
      CONTACT_PERSON: "14",
      CO_APPLICANT: "15",
    };
  }

  static get ROLES() {
    return {
      OFFICIAL: 6,
      APPLICANT: 7,
      REVIEWER: 8,
      SUPER_ADMIN: 9,
      SYSTEM_ADMIN: 11,
      ORG_ADMIN: 13,
      CO_APPLICANT: 15,
    };
  }

  static get SFL_SERVICES() {
    return [
      { label: "Public Limited Company", value: "Public Limited Company" },
      { label: "Private Limited Company", value: "Private Limited Company" },
    ];
  }

  static get PVT_PACKAGE_NAMES() {
    return [
      { label: "Basic", value: "Basic" },
      { label: "Print", value: "Print" },
      { label: "Premium", value: "Premium" },
    ];
  }

  static get PLC_PACKAGE_NAMES() {
    return [
      { label: "Print", value: "Print" },
      { label: "Premium", value: "Premium" },
    ];
  }

  static get CURRENCIES() {
    return [
      { label: "KES", value: "KES" },
      { label: "USD", value: "USD" },
    ];
  }

  static get APPLICABLE_TAX_OPTIONS() {
    return [
      { label: "16 %", value: 1.16 },
      { label: "N/A", value: 1 },
    ];
  }

  static get SFL_DATA() {
    return {
      name: "SIMPLE FORMATIONS LIMITED",
      buildingName: "The Address",
      floorNumber: "8th Floor",
      streetName: "Muthangari Drive Off Waiyaki way",
      pin: "P051793526K",
      location: {
        county: "Nairobi",
      },
    };
  }

  static get PHONE_NUMBER_REGEX() {
    return /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  }

  static get DPO() {
    return {
      SERVICE_TYPES: {
        // COMPANY_REGISTRATION: 77416, // old
        // COMPANY_REGISTRATION: 54841, // redirect checkout test
        COMPANY_REGISTRATION: 77416,
        REGISTRATION_FEE: 80295,
      },
      CHECKOUT_LINK: "https://secure.3gdirectpay.com/payv3.php",
    };
  }

  static get MPESA_LIMIT() {
    return { MAX_AMOUNT: 150000 };
  }

  static get SOURCE_OF_BO_INFO() {
    return {
      officialRegister: "Taken from an official register",
      selfOrAuthorizedPerson:
        "Provided by the beneficial owner or their authorized representative",
      thirdParty:
        "Provided by a third party not directly related to the beneficial owner",
    };
  }

  static get TEMPLATES() {
    return {
      ENTITY_TYPES: [
        { label: "PLC", value: "PLC" },
        { label: "PVT", value: "PVT" },
      ],
      TYPES: {
        ARTICLES: [
          { label: "Short", value: "Short" },
          { label: "Long", value: "Long" },
        ],
        SHARES_RIGHTS: [
          { value: this.ORDINARY_SHARES, label: this.ORDINARY_SHARES },
          {
            value: this.PREFERENCE_SHARES,
            label: this.PREFERENCE_SHARES,
          },
          {
            value: this.OTHER_SHARES,
            label: this.OTHER_SHARES,
          },
        ],
      },
      DIRECTOR_MUST_BE_SHAREHOLDER: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ],
      BORROWING_POWERS_FOR_DIRECTORS: [
        { label: "Limited Powers", value: "Limited Powers" },
        { label: "Unlimited Powers", value: "Unlimited Powers" },
      ],
      SHAREHOLDER_RIGHT_OF_FIRST_REFUSAL: [
        { label: "Apply", value: "Apply" },
        { label: "Do Not Apply", value: "Do Not Apply" },
      ],
    };
  }

  static get ORDINARY_SHARES() {
    return "Ordinary Shares";
  }
  static get PREFERENCE_SHARES() {
    return "Preference Shares";
  }
  static get OTHER_SHARES() {
    return "Other Shares";
  }

  static get ENTITY_TYPE_AGAINST_TYPE() {
    return {
      PLC: "Public Limited",
      PVT: "Private Limited",
    };
  }

  static get SFL_URL() {
    return "https://www.simpleformations.ke/";
  }

  static get SFL_APP_URL() {
    return "https://www.app.simpleformations.ke";
  }

  static get PAGINATION_LIMIT() {
    return 15;
  }

  static get ACCESS_REQUEST() {
    return {
      PERSONAL_DATA: "Personal Data",
      CORPORATE_DATA: "Corporate Data",
      MINOR_DATA: "Minor Data",
      CO_APPLICANT: "Co Applicant",
    };
  }

  static get INACTIVITY_TIMEOUT_MS() {
    return 30 * 60 * 1000; // milliseconds
  }

  static get INACTIVE_STATE_CHECK_INTERVAL() {
    return 1000; // milliseconds
  }

  static get ID_TYPES() {
    return {
      KENYAN_CITIZEN: "Kenyan Citizen",
      FOREIGN_RESIDENT: "Foreign Resident",
      FOREIGNER: "Foreigner",
      MINOR: "Minor",
      LOCAL_COMPANY: "Local Company",
      FOREIGN_COMPANY: "Foreign Company",
      OTHER_PARASTATALS: "Other e.g Parastatals",
    };
  }

  static get SOURCE_OF_BO_INFO_OPTIONS() {
    return [
      {
        value: "selfOrAuthorizedPerson",
        label:
          "Provided by the beneficial owner or their authorized representative",
      },
      {
        value: "officialRegister",
        label: "Taken from an official register",
      },
      {
        value: "thirdParty",
        label:
          "Provided by a third party not directly related to the beneficial owner",
      },
    ];
  }

  static get REGISTERS() {
    return {
      ROW_HEIGHT: 26,
      DIRECTORS_ROWS: 17,
      SECRETARIES_ROWS: 4,
      BENEFICIAL_OWNERS_ROWS: 3,
      MEMBER_AND_LEDGER_ROWS: 2,
      APPLICATIONS_AND_ALLOTMENT_ROWS: 17,
      LEDGER_ROWS: 7,
    };
  }

  static get SOCKET_EVENTS() {
    return {
      FETCH_ACCESS_REQUESTS: "fetch-access-requests",
      UPDATE_REGISTERED_OFFICE: "update-registered-office",
      FETCH_CO_APPLICANTS: "fetch-co-applicants",
      ADD_COMPANY_OFFICIAL: "add-company-official",
      REFETCH_BENEFICIAL_OWNER: "refetch-beneficial-owner",
    };
  }

  static get APPLICANT_CAPACITIES() {
    return {
      DIRECTOR: "Director",
      DIRECTOR_SHAREHOLDER: "Director Shareholder",
      SHAREHOLDER: "Shareholder",
      BENEFICIAL_OWNER: "Beneficial Owner",
      COMPANY_SECRETARY: "Company Secretary",
      ADVOCATE: "Advocate",
    };
  }

  static get PROFILE_TYPES() {
    return {
      EMPLOYEE: "Employee",
      CO_APPLICANT: "Co Applicant",
      APPLICANT: "Applicant",
      FULL: "Full",
      PROFESSIONAL: "Professional",
    };
  }
}
