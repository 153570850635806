import React, { useState, useMemo, useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

import Products from "./Management/Products";
import Packages from "./Management/Packages";
import Discount from "./Management/Discount";
import Invoices from "./Management/Invoices";
import { Constants } from "config/constants";
import { getPackagesAsync } from "state/slices/package";
import { classNames } from "utils";

function Settings() {
  const dispatch = useDispatch();
  const cookieRole = Cookies.get("role");
  const [selectedTab, setSelectedTab] = useState("products");

  useEffect(() => {
    dispatch(getPackagesAsync());
    if (cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN) {
      setSelectedTab("products");
    } else setSelectedTab("packages");
  }, [cookieRole]);

  const navigation = useMemo(
    () =>
      [
        cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN
          ? {
              id: "products",
              label: "Products",
            }
          : null,
        {
          id: "packages",
          label: "Packages",
        },
        cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN
          ? {
              id: "discounts",
              label: "Discounts",
            }
          : null,
        {
          id: "invoices",
          label: "Billing",
        },
        {
          id: "receipt",
          label: "Receipt",
        },
      ].filter(Boolean),
    [cookieRole]
  );

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="px-2 py-6 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2 lg:border-r lg:border-gray-300">
        <nav>
          {navigation.map((item) => (
            <button
              key={item.label}
              className={classNames(
                selectedTab === item.id
                  ? "bg-gray-50 text-sflPrimary hover:bg-white font-semibold"
                  : "rounded-md px-3 py-1 text-tertiary flex items-center text-base font-medium",
                "flex items-center px-3 py-1 text-base font-medium hover:text-sflPrimary hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300"
              )}
              onClick={() => setSelectedTab(item.id)}
            >
              {item.label}
            </button>
          ))}
        </nav>
      </aside>
      <div className="col-span-10">
        {selectedTab === "products" && <Products />}
        {selectedTab === "packages" && <Packages />}
        {selectedTab === "discounts" && <Discount />}
        {selectedTab === "invoices" && <Invoices />}
      </div>
    </div>
  );
}

export default Settings;
