import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const inviteOrganization = (data) =>
  axios.post("/corporates/invite-organization", data);

const sendInviteNotification = (data) =>
  axios.post("/corporates/send-invite-notification", data);

const submitOrganization = (data) =>
  axios.post(`/corporates/submit-organization`, data);

const getAll = (queryParams) => {
  return axios.get(`/corporates?${getQueryString(queryParams)}`);
};

const get = (corporateId, isDashboard, cancelToken) => {
  let url = `/corporates/${corporateId}`;
  if (isDashboard) url += `?isDashboard=${isDashboard}`;
  return axios.get(url, { cancelToken });
};

const update = (corporateId, data) =>
  axios.put(`/corporates/${corporateId}`, data);

const approveOrganization = (data) =>
  axios.put("/corporates/approve-organization", data);

const confirmOrganizationReviewer = (token) =>
  axios.get(`/corporates/confirm-organization-reviewer/${token}`);

const getCorporatesForDashboard = (queryParams) =>
  axios.get(
    `/corporates/get-corporates-for-dashboard?${getQueryString(queryParams)}`
  );

const corporateDataService = {
  inviteOrganization,
  sendInviteNotification,
  submitOrganization,
  get,
  update,
  approveOrganization,
  confirmOrganizationReviewer,
  getAll,
  getCorporatesForDashboard,
};

export default corporateDataService;
