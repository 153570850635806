import axios from "../axios";
import { getQueryString } from "utils/getQueryString";

const getAll = (queryParams) => {
  return axios.get(`/reviewers?${getQueryString(queryParams)}`);
};

const get = (email) => {
  return axios.get(`/reviewers?email=${email}`);
};

const create = (data) => {
  return axios.post("/reviewers", data);
};

const update = (reviewerId, data) => {
  return axios.put(`/reviewers/${reviewerId}`, data);
};

const remove = (reviewerId, companyId) => {
  return axios.delete(`/reviewers/${reviewerId}?companyId=${companyId}`);
};

const confirmReviewer = (token) => {
  return axios.get(`/reviewers/confirm-reviewer/${token}`);
};

const getCompaniesToReview = (queryParams) =>
  axios.get(
    `/reviewers/get-companies-to-review?${getQueryString(queryParams)}`
  );

const getCompaniesForCorrections = (queryParams) =>
  axios.get(
    `/companies/get-companies-for-corrections?${getQueryString(queryParams)}`
  );

const reviewerDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  confirmReviewer,
  getCompaniesToReview,
  getCompaniesForCorrections,
};

export default reviewerDataService;
