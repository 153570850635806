import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import companyDataService from "services/company";

export const getIncorporatedCompaniesAsync = createAsyncThunk(
  "superAdmin/getIncorporatedCompaniesAsync",
  async (queryParams, thunkAPI) => {
    try {
      const res = await companyDataService.getAll(queryParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBrsPendingCorrectionCompaniesAsync = createAsyncThunk(
  "superAdmin/getBrsPendingCorrectionCompaniesAsync",
  async (queryParams, thunkAPI) => {
    try {
      const res = await companyDataService.getAll(queryParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  incorporated: {
    items: [],
    totalCount: 0,
  },
  brsPendingCorrection: {
    items: [],
    totalCount: 0,
  },
  getIncorporatedCompanies: {
    status: "idle",
    error: false,
  },
  getBrsPendingCorrectionCompanies: {
    status: "idle",
    error: false,
  },
};

const superAdminSlice = createSlice({
  name: "superAdminSlice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [getIncorporatedCompaniesAsync.pending]: (state) => {
      state.getIncorporatedCompanies.status = "loading";
      state.getIncorporatedCompanies.error = false;
    },
    [getIncorporatedCompaniesAsync.fulfilled]: (state, action) => {
      state.getIncorporatedCompanies.status = "idle";
      state.getIncorporatedCompanies.error = false;
      state.incorporated.items = action.payload.items;
      state.incorporated.totalCount = action.payload.totalCount;
    },
    [getIncorporatedCompaniesAsync.rejected]: (state, action) => {
      state.getIncorporatedCompanies.status = "failed";
      state.getIncorporatedCompanies.error = action.payload.messageText;
    },

    [getBrsPendingCorrectionCompaniesAsync.pending]: (state) => {
      state.getBrsPendingCorrectionCompanies.status = "loading";
      state.getBrsPendingCorrectionCompanies.error = false;
    },
    [getBrsPendingCorrectionCompaniesAsync.fulfilled]: (state, action) => {
      state.getBrsPendingCorrectionCompanies.status = "idle";
      state.getBrsPendingCorrectionCompanies.error = false;
      state.brsPendingCorrection.items = action.payload.items;
      state.brsPendingCorrection.totalCount = action.payload.totalCount;
    },
    [getBrsPendingCorrectionCompaniesAsync.rejected]: (state, action) => {
      state.getBrsPendingCorrectionCompanies.status = "failed";
      state.getBrsPendingCorrectionCompanies.error = action.payload.messageText;
    },
  },
});

export const { reset } = superAdminSlice.actions;

const { reducer } = superAdminSlice;

export default reducer;
