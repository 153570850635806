import { object, array, number, string, boolean } from "yup";

const shareAllocationValidation = ({ shares, isReportable }) => {
  const schema = object().shape({
    isReportable: boolean().default(isReportable),
    showShareAllocationForm: boolean(),
    sharesAllotted: array().when("showShareAllocationForm", {
      is: (showShareAllocationForm) =>
        showShareAllocationForm && shares && shares.length === 0,
      then: array().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: array().optional(),
        otherwise: array().required("Allot at least one share"),
      }),
    }),
    beneficialOwnershipForm: object()
      .when("showShareAllocationForm", {
        is: (showShareAllocationForm) => showShareAllocationForm,
        then: object().shape({
          maxPercentShareholding: number().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: number().optional(),
            otherwise: number().required(
              "Max percent shareholding is required"
            ),
          }),
          directPercentShareholding: number().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: number().optional(),
            otherwise: number()
              .min(0, "Value must be greater than or equal to 0")
              .required("Direct percent shareholding is required"),
          }),
          indirectPercentShareholding: number().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: number().optional(),
            otherwise: number()
              .min(0, "Value must be greater than or equal to 0")
              .required("Indirect percent shareholding is required"),
          }),
          maxPercentVotingRights: number().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: number().optional(),
            otherwise: number().required(
              "Max percent voting rights is required"
            ),
          }),
          directPercentVotingRights: number().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: number().optional(),
            otherwise: number().test("len", "", function (value) {
              if (
                this.parent.maxPercentVotingRights > 0 &&
                value !== undefined &&
                value < 0
              ) {
                return this.createError({
                  message: "Value must be greater than or equal to 0",
                });
              }
              if (
                this.parent.maxPercentVotingRights > 0 &&
                value === undefined
              ) {
                return this.createError({
                  message: "Direct percent voting rights is required",
                });
              }
              return true;
            }),
          }),
          indirectPercentVotingRights: number().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: number().optional(),
            otherwise: number().test("len", "", function (value) {
              if (
                this.parent.maxPercentVotingRights > 0 &&
                value !== undefined &&
                value < 0
              ) {
                return this.createError({
                  message: "Value must be greater than or equal to 0",
                });
              }
              if (
                this.parent.maxPercentVotingRights > 0 &&
                value === undefined
              ) {
                return this.createError({
                  message: "Indirect percent voting rights is required",
                });
              }
              return true;
            }),
          }),
          directRightRemoveDirector: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Please select one option"),
          }),
          indirectRightRemoveDirector: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Please select one option"),
          }),
          directCompanyControlRight: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Please select one option"),
          }),
          indirectCompanyControlRight: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Please select one option"),
          }),
          dateOfBecomingBO: string().when("isReportable", {
            is: () => isReportable === true,
            then: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Date of becoming BO is required"),
            }),
            otherwise: string().notRequired(),
          }),
          sourceOfBOInformation: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required(
              "Source of beneficial owner information is required"
            ),
          }),
        }),
      })
      .default({
        maxPercentShareholding: "",
        directPercentShareholding: "",
        indirectPercentShareholding: "",
        maxPercentVotingRights: "",
        directPercentVotingRights: "",
        indirectPercentVotingRights: "",
        directRightRemoveDirector: "",
        indirectRightRemoveDirector: "",
        directCompanyControlRight: "",
        indirectCompanyControlRight: "",
        dateOfBecomingBO: "",
        sourceOfBOInformation: "",
      }),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default shareAllocationValidation;
