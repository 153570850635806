import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactTable from "components/lib/Global/ReactTable";
import SubRowAsync from "./SubRowAsync";
import OrganizationsTableActionMenu from "components/lib/Shared/OrganizationsTableActionMenu";
import { useToast } from "hooks/useToast";
import { sendInviteNotification } from "state/slices/organizations";
import SearchInput from "components/lib/Shared/SearchInput";

const OrganizationsTable = () => {
  const currentTab = "stakeholderTab";
  const { toast } = useToast(5000);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const { items: organizations } = useSelector(
    (state) => state.organizationsSlice
  ).organizations;

  const handleResendInvite = async (row) => {
    try {
      await dispatch(
        sendInviteNotification({
          data: {
            organizationName: row.names.companyName,
            contactPersonEmail: row.identificationDetails.emailAddress,
            corporateId: row._id,
          },
        })
      );
      toast("success", "Invite sent successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  const columns = [
    {
      Header: "Organization",
      className: "p-4 text-ellipsis overflow-hidden max-w-[150px]",
      Cell: ({ row }) => row.original?.names?.companyName ?? "N/A",
    },
    {
      Header: "Type",
      accessor: "partnershipType",
      Cell: ({ row }) => row.original?.partnershipType ?? "N/A",
    },
    {
      Header: "Email",
      accessor: "idType",
      Cell: ({ row }) =>
        row.original?.identificationDetails?.emailAddress ?? "N/A",
    },
    {
      Header: "Contact Person",
      Cell: ({ row }) =>
        row.original?.identificationDetails?.companyContactName ?? "N/A",
    },
    {
      Header: "Status",
      Cell: ({ row }) => row.original?.status ?? "N/A",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <OrganizationsTableActionMenu
            row={row}
            disableRemoveBtn
            disableResetAccessBtn
            showViewBtn={["Active", "Rejected"].includes(row.original?.status)}
            hideResendInviteBtn={row.original?.status !== "Draft"}
            hideReviewBtn={row.original?.status !== "Review"}
            disableResendInviteBtn={row.original?.status !== "Draft"}
            disableReviewBtn={row.original?.status !== "Review"}
            handleResendInvite={handleResendInvite}
          />
        );
      },
    },
  ];

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <SubRowAsync
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          currentTab={currentTab}
        />
      );
    },
    []
  );

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full bg-white shadow">
        <div className="w-full">
          <ReactTable
            columns={columns}
            data={organizations.map(({ corporate }) => corporate)}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={organizations.length}
            setCurrentPage={setCurrentPage}
            paginate
            headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
            headingStyle="pl-4 text-left text-white"
            dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            renderRowSubComponent={renderRowSubComponent}
          />
        </div>
      </div>
    </>
  );
};

export default OrganizationsTable;
