import { useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { InformationCircleIcon } from "@heroicons/react/solid";

function NotificationBanner({ notificationKey, handleView, type = "success" }) {
  const [flashMessage, setFlashMessage] = useState("");

  // remove from session storage
  const removeFlashMessage = () => {
    window.sessionStorage.removeItem(notificationKey);
    window.dispatchEvent(new Event("storage"));
    const hiddenNotifications =
      JSON.parse(window.sessionStorage.getItem("hiddenNotifications")) || [];
    window.sessionStorage.setItem(
      "hiddenNotifications",
      JSON.stringify([...hiddenNotifications, notificationKey])
    );
  };

  useEffect(() => {
    const handleStorageChange = (e) => {
      setFlashMessage(window.sessionStorage.getItem(notificationKey));
    };

    window.addEventListener("storage", handleStorageChange);
    window.dispatchEvent(new Event("storage"));

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.sessionStorage.removeItem(notificationKey);
      window.dispatchEvent(new Event("storage"));
    };
  }, [notificationKey]);

  if (!flashMessage) return null;

  let Icon = CheckCircleIcon;
  if (type === "info") Icon = InformationCircleIcon;

  return (
    <div className="p-4 my-4 rounded-md bg-lime-50 shadow">
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className="w-8 h-8 text-green-600" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p
            className="text-sm font-medium text-sflPrimary"
            dangerouslySetInnerHTML={{ __html: flashMessage }}
          />
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              {handleView && (
                <button
                  type="button"
                  onClick={handleView}
                  className="rounded-md bg-lime-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                >
                  View
                </button>
              )}
              <button
                type="button"
                onClick={removeFlashMessage}
                className="ml-3 rounded-md bg-lime-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationBanner;
