import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { confirmReviewer } from "state/slices/tables/reviewersTable";
import ConfirmReviewerSuccess from "components/ConfirmReviewerSuccess";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

export default function ConfirmReviewerPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [redirectTo, setRedirectTo] = useState("login");
  const [companyId, setCompanyId] = useState("");

  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(async () => {
    if (token) {
      const response = await dispatch(confirmReviewer({ token }));
      if (response?.error?.name) {
        setError("Link is not valid.");
      } else {
        setRedirectTo(response?.payload?.redirectTo);
        setCompanyId(response?.payload?.companyId);
      }
      setLoading(false);
    }
  }, [token]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <SpinnerIcon className="text-gray-500" />
      </div>
    );

  if (error) return <span>{error}</span>;

  return (
    <ConfirmReviewerSuccess redirectTo={redirectTo} companyId={companyId} />
  );
}
