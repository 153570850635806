import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import _cloneDeep from "lodash/cloneDeep";
import _isFunction from "lodash/isFunction";
import _merge from "lodash/merge";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { XIcon } from "@heroicons/react/outline";
import { Constants } from "config/constants";
import validation from "./validation";
import Tabs from "components/lib/Shared/Tabs";
import Button from "components/lib/Shared/Button";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import Label from "components/lib/Shared/Label";
import JointShareholderTable from "../AssembledCompanyOfficialForms/JointComponents/JointShareholderTable";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect/index";
import CompanyOfficialsForms from "../CompanyOfficialsForms";
import SubmitJointShareholdersList from "../AssembledCompanyOfficialForms/JointComponents/SubmitJointShareholdersList";
import { openModal } from "state/slices/modals";
import { resetSharesTable } from "state/slices/tables/sharesAllocationTable";
import {
  resetJointShareholderTable,
  setCurrentJSGroupID,
} from "state/slices/tables/jointShareholderTable";
import { useToast } from "hooks/useToast";
import { flattenObject } from "utils";
import IsDirectorSelection from "./IsDirectorSelection";
import ESOPSelection from "./ESOPSelection";
import ErrorBanner from "components/lib/Shared/ErrorBanner";

const CompanyOfficialSelection = (props) => {
  const [showShareAllocation, setShowShareAllocation] = useState(false);
  const { company } = useSelector((state) => state.companySlice);
  const companyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );
  const [selectedTab, setSelectedTab] = useState({
    id: "assignOfficials",
    label: "Assign Officials",
  });
  const [tabsClickable, setTabsClickable] = useState(false);
  const { toast } = useToast(5000);

  const { jointShareholders, currentJSGroupID } = useSelector(
    (state) => state.jointShareholderTableSlice
  );

  const jointShareholdersCount = jointShareholders.length;

  const firstJointShareholder = jointShareholders[0];

  const {
    mode,
    setMode,
    handleBackBtn,
    setIsViewOnly,
    isViewOnly,
    addOfficialProcessType = {},
  } = props;
  const [showJSForm, setShowJSForm] = useState(jointShareholdersCount < 2);
  const [formStepErrors, setFormStepErrors] = useState([]);

  const { comments } = useSelector((state) => state.commentsSlice);

  const stakeholderComments = useMemo(() => {
    return (
      comments.filter(
        (comment) =>
          comment?.officialId &&
          comment.officialId === mode.id &&
          comment?.tabName &&
          comment.tabName === "stakeholderTab"
      ) || []
    );
  }, [comments, mode.id]);

  const generalInformationCommentsCount = useMemo(() => {
    return (
      stakeholderComments.filter(
        (comment) =>
          comment.formName === "identificationDetails" ||
          comment.formName === "names"
      )?.length ?? 0
    );
  }, [stakeholderComments]);

  const residentialDetailsCommentsCount = useMemo(() => {
    return (
      stakeholderComments.filter(
        (comment) =>
          comment.formName === "postalAddress" ||
          comment.formName === "electronicAddress" ||
          comment.formName === "residentialAddressDetails" ||
          comment.formName === "isNonResident"
      )?.length ?? 0
    );
  }, [stakeholderComments]);

  const officialShareAllocationCommentsCount = useMemo(() => {
    return (
      stakeholderComments.filter((comment) =>
        ["officialShareAllocation", "beneficialOwnershipForm"].includes(
          comment.formName
        )
      )?.length ?? 0
    );
  }, [stakeholderComments]);

  const fileUploadCommentsCount = useMemo(() => {
    return (
      stakeholderComments.filter(
        (comment) => comment.formName === "uploadDocuments"
      )?.length ?? 0
    );
  }, [stakeholderComments]);

  const { initialTabs, shortFormTabs, longFormTabs } = useMemo(() => {
    const initialTabs = [
      { id: "assignOfficials", label: "Assign Officials" },
      {
        id: "generalInformation",
        label: "General Information",
        commentsCount: generalInformationCommentsCount,
      },
      {
        id: "residentialDetails",
        label: "Address Details",
        commentsCount: residentialDetailsCommentsCount,
      },
      {
        id: "uploadDocuments",
        label: "Upload Documents",
        commentsCount: fileUploadCommentsCount,
      },
    ];

    const shortFormTabs = [
      { id: "assignOfficials", label: "Assign Officials" },
      {
        id: "generalInformation",
        label: "General Information",
        commentsCount: generalInformationCommentsCount,
      },
      {
        id: "uploadDocuments",
        label: "Upload Documents",
        commentsCount: fileUploadCommentsCount,
      },
    ];

    const longFormTabs = [
      { id: "assignOfficials", label: "Assign Officials" },
      {
        id: "generalInformation",
        label: "General Information",
        commentsCount: generalInformationCommentsCount,
      },
      {
        id: "residentialDetails",
        label: "Address Details",
        commentsCount: residentialDetailsCommentsCount,
      },
      {
        id: "uploadDocuments",
        label: "Upload Documents",
        commentsCount: fileUploadCommentsCount,
      },
    ];

    return { initialTabs, shortFormTabs, longFormTabs };
  }, [
    generalInformationCommentsCount,
    residentialDetailsCommentsCount,
    fileUploadCommentsCount,
  ]);

  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { modals } = useSelector((state) => state.modalsSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (modals.length === 0) {
      if (mode.editing) dispatch(resetSharesTable());
      if (_isFunction(setMode)) {
        const unApprovedSeniorJointShareholder = jointShareholders.find(
          (official) =>
            official.type === "invited" && official.inviteStatus !== "Approved"
        );
        if (!unApprovedSeniorJointShareholder)
          setShowJSForm(jointShareholdersCount < 2);
        else setShowJSForm(false);
        setMode({ editing: false, id: "", designation: null, viewing: false });
      }
    }
  }, [jointShareholdersCount, modals]);

  const handleEditJointShareholder = (row, isFirstRow) => {
    if (!row.isViewOnly) setIsViewOnly(false);
    else setIsViewOnly(true);
    setMode({ editing: true, id: row._id, designation: "Joint" });
    setShowShareAllocation(isFirstRow);
    setShowJSForm(true);
    dispatch(setCurrentJSGroupID(row.JSGroupID));
    dispatch(openModal({ modalName: "jointShareholderForm_edit_modal" }));
  };

  const toggleShowJSForm = (btn) => {
    // if fired from onclick, btn would be html event
    if (btn === false) {
      setShowJSForm(btn);
    } else setShowJSForm(true);
  };

  let currentOfficial = mode.editing
    ? companyOfficials.find(
        (companyOfficial) => companyOfficial._id === mode.id
      )
    : {};

  currentOfficial =
    mode.designation === "Joint"
      ? jointShareholders.find(
          (jointShareholder) => jointShareholder._id === mode.id
        )
      : currentOfficial;

  currentOfficial = { fileUploads: {}, ...currentOfficial };

  const resetFormState = () => {
    dispatch(resetJointShareholderTable());
    toggleShowJSForm();
    props.toggleShowOfficialsForm();
  };

  const handleExitWorkflow = () => {
    dispatch(resetSharesTable());
    setShowJSForm(false);
    if (jointShareholdersCount === 0) {
      props.toggleShowOfficialsForm();
    }
  };

  const handleExitForm = async (resetForm) => {
    resetFormState();
    resetForm();
  };

  const { schema, schemaInitialValues } = validation();

  const initialValues = _merge(schemaInitialValues, {
    designation:
      (firstJointShareholder?.details?.designation ||
        addOfficialProcessType.officialMethod) ??
      "",
    idType:
      (firstJointShareholder?.details?.idType ||
        addOfficialProcessType.idTypeSelection) ??
      "",
    isDirector: firstJointShareholder?.details?.isDirector ?? "",
    ...currentOfficial,
  });

  const goToPrevStep = () => {
    if (selectedTabIndex === 0) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;
  const goToAssignOfficialsTab = () => setSelectedTabIndex(0);

  let isFirstJSReportable = false;
  if (firstJointShareholder?.beneficialOwnershipForm) {
    const {
      directPercentShareholding,
      directPercentVotingRights,
      directRightRemoveDirector,
      directCompanyControlRight,
    } = firstJointShareholder?.beneficialOwnershipForm;
    isFirstJSReportable =
      directPercentShareholding >= 10 ||
      directPercentVotingRights >= 10 ||
      directRightRemoveDirector === "yes" ||
      directCompanyControlRight === "yes";
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {
        setSelectedTabIndex(selectedTabIndex + 1);
      }}
    >
      {/* see formik docs if confused how we use state here */}
      {({ values, setFieldValue, resetForm }) => {
        const idTypesOptions = _cloneDeep(
          Constants.COMPANY_OFFICIAL_ID_TYPES
        ).filter((id) => {
          if (
            values.designation === "Director" ||
            values.designation === "Director Shareholder"
          ) {
            return id.value !== "Minor";
          }
          if (values.designation === "Joint Shareholder") {
            return (
              id.value !== "Local Company" &&
              id.value !== "Foreign Company" &&
              id.value !== "Other e.g Parastatals"
            );
          }
          if (values.designation === "Company Secretary") {
            return (
              id.value !== "Foreigner" &&
              id.value !== "Foreign Company" &&
              id.value !== "Minor" &&
              id.value !== "Other e.g Parastatals"
            );
          }
          if (
            values.designation === "Authorized Person" ||
            addOfficialProcessType.officialMethod === "Authorized Person"
          ) {
            return (
              id.value !== "Foreign Company" &&
              id.value !== "Minor" &&
              id.value !== "Other e.g Parastatals"
            );
          }
          return id.value;
        });

        const shareholdersCount = companyOfficials.reduce(
          (acc, curr) =>
            acc +
            ([
              "Director Shareholder",
              "Shareholder",
              "Joint Shareholder",
            ].includes(curr.designation)
              ? 1
              : 0),
          0
        );

        const getIdTypeOptions = () => {
          if (
            values?.designation &&
            [
              "Director Shareholder",
              "Shareholder",
              "Joint Shareholder",
            ].includes(values.designation) &&
            company?.type === "PVT" &&
            shareholdersCount >= Constants.MAX_PVT_COMPANY_SHAREHOLDERS_LIMIT
          ) {
            return [
              { value: "Kenyan Citizen", label: "Kenyan Citizen" },
              { value: "Foreign Resident", label: "Foreign Resident" },
              { value: "Foreigner", label: "Foreigner" },
            ];
          }
          return idTypesOptions;
        };

        useEffect(() => {
          const isFirstRow =
            jointShareholders.findIndex(
              (jointShareholder) => jointShareholder._id === currentOfficial._id
            ) === 0;

          setShowShareAllocation(
            (!mode.editing && jointShareholdersCount === 0) ||
              (!mode.editing &&
                values?.isDirector === "Yes" &&
                jointShareholdersCount === 0) ||
              isFirstRow
          );
        }, [
          mode,
          jointShareholdersCount,
          values?.isDirector,
          currentOfficial?.beneficialOwnershipForm,
        ]);

        const renderLongForm =
          values.isDirector === "Yes" || isFirstJSReportable;

        useEffect(() => {
          let tempTabs = [];
          if (
            ["Shareholder", "Director Shareholder"].includes(values.designation)
          ) {
            tempTabs = [
              ...initialTabs,
              {
                id: "shareAllocation",
                label: "Share Allocation",
                commentsCount: officialShareAllocationCommentsCount,
              },
            ];
          } else {
            tempTabs = initialTabs;
          }
          if (values.designation === "Joint Shareholder") {
            if (showShareAllocation) {
              tempTabs = [
                ...initialTabs,
                {
                  id: "shareAllocation",
                  label: "Share Allocation",
                  commentsCount: officialShareAllocationCommentsCount,
                },
              ];
            } else {
              if (renderLongForm) tempTabs = longFormTabs;
              if (!renderLongForm) tempTabs = shortFormTabs;
            }
          }
          if (mode.editing) {
            tempTabs = tempTabs.filter((tab) => tab.id !== "assignOfficials");
          }
          setTabs(tempTabs);
          currentOfficial = {
            ...currentOfficial,
            details: {
              ...currentOfficial?.details,
              isDirector: values?.isDirector,
            },
          };
        }, [
          values,
          showShareAllocation,
          renderLongForm,
          mode.editing,
          officialShareAllocationCommentsCount,
        ]);

        useEffect(() => {
          if (values.designation === "Joint Shareholder") {
            if (
              jointShareholdersCount ===
                Constants.MAX_JOINT_SHAREHOLDER_LIMIT ||
              !showJSForm
            ) {
              setTabs([]);
            } else {
              setTabs([{ id: "assignOfficials", label: "Assign Officials" }]);
            }
            // setFieldValue('idType', '')
          }
        }, [jointShareholdersCount, showJSForm]);

        useEffect(() => {
          if (
            (currentJSGroupID || jointShareholdersCount > 0) &&
            !mode.editing
          ) {
            setFieldValue("designation", "Joint Shareholder");
            setFieldValue("idType", "");
            setFieldValue("isDirector", "No");
          }
        }, [
          currentJSGroupID,
          showJSForm,
          jointShareholdersCount,
          mode.editing,
        ]);

        useEffect(() => {
          setFieldValue("idType", "Kenyan Citizen");
        }, [showJSForm]);

        useEffect(() => {
          if (values.isMemberOfESOP === "No" && !mode.editing) {
            handleExitWorkflow();
            toast(
              "error",
              `Maximum of ${Constants.MAX_PVT_COMPANY_SHAREHOLDERS_LIMIT} shareholders are allowed`
            );
          }
        }, [values.isMemberOfESOP]);

        useEffect(() => {
          if (values.designation === "Joint Shareholder" && !currentJSGroupID)
            dispatch(setCurrentJSGroupID(uuidv4()));
        }, [values.designation, currentJSGroupID]);

        /*
          we place the handler here rather than in the ReactSelect field in order
          to take advantage of formik props and change field value
         */
        const handleSelect = (name) => (option) => {
          if (name === "designation") {
            setFieldValue("idType", "");
          }
          setFieldValue(name, option.value);
          setFieldValue("isDirector", "No");
        };

        const renderESOPToggle =
          !mode.editing &&
          selectedTab.id === "assignOfficials" &&
          values?.designation &&
          ["Director Shareholder", "Shareholder", "Joint Shareholder"].includes(
            values.designation
          ) &&
          company?.type === "PVT" &&
          shareholdersCount >= Constants.MAX_PVT_COMPANY_SHAREHOLDERS_LIMIT &&
          values.idType;

        return (
          <div>
            <ErrorBanner
              errors={formStepErrors}
              className="mb-2"
              showTitle={false}
            />
            {!props.mode.editing &&
              values.designation === "Joint Shareholder" &&
              jointShareholdersCount > 0 && (
                <div className="mb-6">
                  <JointShareholderTable
                    handleEdit={handleEditJointShareholder}
                  />
                </div>
              )}
            {/* Exit, Add More and Submit list buttons */}
            {values.designation === "Joint Shareholder" &&
              jointShareholdersCount > 0 &&
              !showJSForm && (
                <div
                  className={`flex space-x-2 ${
                    jointShareholdersCount <
                      Constants.MAX_JOINT_SHAREHOLDER_LIMIT || showJSForm
                      ? "justify-end"
                      : "justify-between"
                  }`}
                >
                  <SubmitJointShareholdersList
                    showAddMoreBtn={
                      values.isDirector &&
                      jointShareholdersCount <
                        Constants.MAX_JOINT_SHAREHOLDER_LIMIT
                    }
                    handleAddMore={toggleShowJSForm}
                    handleExitForm={() => handleExitForm(resetForm)}
                    resetFormState={() => resetFormState(resetForm)}
                    setFormStepErrors={setFormStepErrors}
                  />
                </div>
              )}
            {/* Official's form tabs */}
            {values.designation === "Joint Shareholder" &&
            !showJSForm ? null : (
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={
                    isViewOnly || tabsClickable
                      ? setSelectedTabIndex
                      : () => null
                  }
                />
              </div>
            )}
            {/* we don't need to show the dropdowns form when editing  */}
            {!props.mode.editing && (
              <Form className="mt-4">
                {showJSForm && (
                  <Button
                    variant="text"
                    className="flex ml-auto"
                    onClick={handleExitWorkflow}
                  >
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  </Button>
                )}
                {selectedTab.id === "assignOfficials" && showJSForm && (
                  <div className="px-4 py-6 mt-2 bg-white rounded-sm shadow-sm sm:p-6 sm:rounded md:rounded-md">
                    <div className="grid grid-cols-6 gap-6">
                      {!Object.keys(addOfficialProcessType).length > 0 && (
                        <div className="col-span-6 sm:col-span-3">
                          <Label htmlFor="designation">Company Officials</Label>
                          <Field
                            name="designation"
                            isDisabled={jointShareholdersCount > 0}
                            component={ReactSelectWithFormik}
                            options={props.companyOfficialOptions}
                            className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            id="designation"
                            type="text"
                            placeholder="Select designation"
                            onChange={handleSelect("designation")}
                            value={values?.designation ?? ""}
                          />
                        </div>
                      )}
                      <div className="col-span-6 sm:col-span-3">
                        <Label htmlFor="idType">ID Types</Label>
                        <Field
                          name="idType"
                          component={ReactSelectWithFormik}
                          options={getIdTypeOptions()}
                          className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          id="idType"
                          placeholder="Select Id type"
                          onChange={handleSelect("idType")}
                          value={values?.idType ?? ""}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {renderESOPToggle && <ESOPSelection />}
                {showJSForm &&
                  values.idType &&
                  values.designation &&
                  values.designation === "Joint Shareholder" &&
                  selectedTab?.id === "assignOfficials" &&
                  jointShareholdersCount <
                    Constants.MAX_JOINT_SHAREHOLDER_LIMIT &&
                  addOfficialProcessType !== "manage-company" && (
                    <IsDirectorSelection
                      isMinor={values.idType === "Minor"}
                      isDirector={values.isDirector}
                    />
                  )}
                {selectedTab.id === "assignOfficials" && showJSForm && (
                  <div className="flex justify-between py-3">
                    <Button
                      onClick={goToPrevStep}
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Go back
                    </Button>
                    <Button
                      type="submit"
                      disabled={
                        renderESOPToggle && values.isMemberOfESOP !== "Yes"
                      }
                    >
                      Save and proceed
                    </Button>
                  </div>
                )}
              </Form>
            )}
            {mode.designation === "Joint" ? (
              <ModalWrapper
                name="jointShareholderForm_edit_modal"
                title="Edit Company Official"
                maxWidth="sm:max-w-fit"
                handleClose={goToAssignOfficialsTab}
                // toggleShowJSForm={
                //   jointShareholdersCount > 1
                //     ? toggleShowJSForm
                //     : null
                // }
              >
                {/* Official's form tabs */}
                {values.designation === "Joint Shareholder" &&
                !showJSForm ? null : (
                  <div className="sm:-mt-4">
                    <Tabs
                      tabs={tabs}
                      selectedTabIndex={selectedTabIndex}
                      setSelectedTabIndex={setSelectedTabIndex}
                    />
                  </div>
                )}
                {values.idType &&
                  values.designation &&
                  values.idType !== "Minor" &&
                  selectedTab.id === "generalInformation" &&
                  jointShareholdersCount <
                    Constants.MAX_JOINT_SHAREHOLDER_LIMIT && (
                    <IsDirectorSelection
                      isMinor={values.idType === "Minor"}
                      isDirector={values.isDirector}
                    />
                  )}
                <CompanyOfficialsForms
                  values={
                    mode.editing
                      ? {
                          ...flattenObject(currentOfficial),
                          isDirector: values.isDirector,
                        }
                      : addOfficialProcessType === "manage-company"
                      ? { ...values, designation: "Authorized Person" }
                      : values
                  }
                  currentOfficial={currentOfficial}
                  mode={mode}
                  companyOfficials={companyOfficials}
                  showJSForm={showJSForm}
                  toggleShowJSForm={toggleShowJSForm}
                  toggleShowOfficialsForm={props.toggleShowOfficialsForm}
                  showShareAllocation={showShareAllocation}
                  selectedTab={selectedTab}
                  isFirstStep={isFirstStep}
                  isLastStep={isLastStep}
                  isViewOnly={isViewOnly}
                  goToPrevStep={goToPrevStep}
                  goToNextStep={goToNextStep}
                  goToAssignOfficialsTab={goToAssignOfficialsTab}
                />
              </ModalWrapper>
            ) : (
              <CompanyOfficialsForms
                values={
                  mode.editing
                    ? {
                        ...flattenObject(currentOfficial),
                        isDirector: values.isDirector,
                      }
                    : addOfficialProcessType === "manage-company"
                    ? { ...values, designation: "Authorized Person" }
                    : values
                }
                currentOfficial={currentOfficial}
                mode={mode}
                companyOfficials={companyOfficials}
                showJSForm={showJSForm}
                toggleShowJSForm={toggleShowJSForm}
                toggleShowOfficialsForm={props.toggleShowOfficialsForm}
                showShareAllocation={
                  addOfficialProcessType === "manage-company"
                    ? false
                    : showShareAllocation
                }
                selectedTab={selectedTab}
                isFirstStep={isFirstStep}
                isLastStep={isLastStep}
                goToPrevStep={goToPrevStep}
                goToNextStep={goToNextStep}
                goToAssignOfficialsTab={goToAssignOfficialsTab}
                isViewOnly={isViewOnly}
                setTabsClickable={setTabsClickable}
              />
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default CompanyOfficialSelection;
