import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/lib/Shared/Button";
import DiscountsTable from "./Table";
import DiscountForm from "./Form";
import DashOutline from "components/lib/Shared/Icons/DashOutline";
import { getDiscountsAsync, updateDiscountSlice } from "state/slices/discount";
import { getPackagesAsync } from "state/slices/package";
import { getDiscountAssigneesAsync } from "state/slices/discountAssignee";

const Discounts = () => {
  const dispatch = useDispatch();
  const { discounts } = useSelector((state) => state.discountSlice);
  const [showAddDiscountForm, setShowAddDiscountForm] = useState(false);

  useEffect(() => {
    dispatch(getDiscountsAsync());
    dispatch(getPackagesAsync());
  }, []);

  if (showAddDiscountForm) {
    return <DiscountForm setShowAddDiscountForm={setShowAddDiscountForm} />;
  }

  const handleEditDiscount = (discount) => {
    dispatch(updateDiscountSlice({ selectedDiscount: discount }));
    dispatch(getDiscountAssigneesAsync({ discount: discount._id }));
    setShowAddDiscountForm(true);
  };

  return (
    <div className="pl-4">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold text-sflPrimary">Discounts</h3>
        <Button onClick={() => setShowAddDiscountForm(true)}>
          Add new discount
        </Button>
      </div>
      <DiscountsTable handleEditDiscount={handleEditDiscount} />
    </div>
  );
};

export default Discounts;
