import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { twMerge } from "tailwind-merge";
import SpinnerIcon from "./Icons/SpinnerIcon";

const Pagination = ({
  currentPage,
  rowsPerPage,
  totalRows,
  setCurrentPage = () => null,
  children,
  className,
  isLoading,
}) => {
  if (totalRows === 0) return null;

  const lastPage = Math.ceil(totalRows / rowsPerPage);
  const delta = 1;
  let pageNumbers = [];
  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(lastPage - 1, currentPage + delta);
    i++
  ) {
    pageNumbers.push(i);
  }
  if (currentPage - delta > 2) {
    pageNumbers.unshift("...");
  }
  if (currentPage + delta < lastPage - 1) {
    pageNumbers.push("...");
  }
  pageNumbers.unshift(1);
  if (lastPage != 1) pageNumbers.push(lastPage);
  const disableBackBtn = currentPage === 1 || totalRows < 1;
  const disableNextBtn =
    currentPage === pageNumbers[pageNumbers.length - 1] ||
    (pageNumbers.length === 0 && totalRows < 6);

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalRows);

  return (
    <div
      className={twMerge(
        "px-4 py-3 mt-2 bg-white border-t border-gray-300 sm:px-6",
        className
      )}
    >
      <div className="flex justify-between flex-1 sm:hidden">
        <button
          type="button"
          disabled={disableBackBtn}
          onClick={() => setCurrentPage(currentPage - 1)}
          className={`${
            disableBackBtn ? "bg-gray-100 cursor-not-allowed" : "bg-white"
          } relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700  hover:bg-gray-50`}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={disableNextBtn}
          onClick={() => setCurrentPage(currentPage + 1)}
          className={`${
            disableNextBtn ? "bg-gray-100 cursor-not-allowed" : "bg-white"
          } ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {isLoading ? (
            <SpinnerIcon className="text-gray-500" />
          ) : (
            children || (
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{startIndex}</span> to{" "}
                <span className="font-medium">{endIndex}</span> of{" "}
                <span className="font-medium">{totalRows}</span> results
              </p>
            )
          )}
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              type="button"
              disabled={disableBackBtn}
              onClick={() => setCurrentPage(currentPage - 1)}
              className={`${
                disableBackBtn ? "bg-gray-100 cursor-not-allowed" : "bg-white"
              } relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
            </button>
            {pageNumbers.map((number, index) => (
              <button
                type="button"
                key={`${number}_${index}`}
                className={` ${
                  currentPage === number
                    ? "bg-primary text-white"
                    : "bg-white text-gray-500 hover:bg-gray-50 border-gray-300 border"
                } ${
                  !isNaN(number) ? "cursor-pointer" : "cursor-text"
                } hidden md:inline-flex relative items-center px-4 py-2 text-sm font-medium`}
                disabled={isLoading}
                onClick={() => (!isNaN(number) ? setCurrentPage(number) : null)}
              >
                {number}
              </button>
            ))}
            <button
              type="button"
              disabled={disableNextBtn}
              onClick={() => setCurrentPage(currentPage + 1)}
              className={` ${
                disableNextBtn ? "bg-gray-100 cursor-not-allowed" : "bg-white"
              } relative mx-auto inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
