/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import _get from "lodash/get";
import _isPlainObject from "lodash/isPlainObject";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

import ValidationError from "../ValidationError";
import CommentsList from "components/lib/Global/CommentsList";
import { Constants } from "config/constants";

// simplified usage of Datepicker with Formik
const DatePickerField = (props) => {
  const { setFieldValue } = useFormikContext();
  const {
    label,
    id,
    name,
    errorPath,
    maxDate,
    minDate,
    disabled = false,
    showYearDropdown = true,
    scrollableMonthYearDropdown = true,
    autoComplete = "off",
    placeholderText = "Select date",
    dateFormat = "yyyy-MM-dd",
    form = {},
    selected = null,
    showRequiredAsterisk,
    showMonthYearPicker,
    showIcon,
  } = props;

  const currentUser = useSelector((state) => state.userSlice);
  const isApplicant = form?.values?.createdBy === currentUser._id;
  const [field] = useField(props?.name ?? props.field?.name);
  const onChange =
    typeof props.onChange === "function"
      ? props.onChange
      : (val) => {
          setFieldValue(field.name, val);
        };
  const { errors = {}, touched = {} } = form;

  const commentPath = props?.commentPath ? props.commentPath : errorPath;

  let comments = props?.comments
    ? props.comments
    : form?.values?.comments ?? [];

  if (_isPlainObject(comments)) {
    comments = _get(comments, commentPath, []);
  }

  let resolvedFields = props?.resolvedFields
    ? props.resolvedFields
    : _get(form?.values, errorPath?.split(".")?.[0])?.resolvedFields ?? [];

  return (
    <>
      {label && (
        <label
          htmlFor={id ? id : name}
          className={`block text-sm font-medium text-gray-700 ${
            Constants.REQUIRED_OFFICIALS_FIELDS.includes(name) ||
            showRequiredAsterisk
              ? "after:content-['*'] after:ml-0.5 after:text-red-500"
              : ""
          }`}
        >
          <span className="relative">
            {label}
            {comments.length > 0 && (
              <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-3 -right-5">
                <span>{comments.length}</span>
              </span>
            )}
          </span>
        </label>
      )}
      <DatePicker
        id={id ? id : name}
        name={name}
        showYearDropdown={showYearDropdown}
        scrollableMonthYearDropdown={scrollableMonthYearDropdown}
        autoComplete={autoComplete}
        showMonthYearPicker={showMonthYearPicker}
        showIcon={showIcon}
        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
        placeholderText={
          selected
            ? placeholderText
            : (form?.values?._id ? isApplicant : true) &&
              (disabled || resolvedFields.includes(name)) &&
              !name?.includes("beneficialOwnershipForm") &&
              (form?.values?.type === "invited" || form?.values?.hasAccess)
            ? "N/A"
            : placeholderText
        }
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        selected={selected}
        disabled={disabled || resolvedFields.includes(name)}
        onChange={onChange}
        onSelect={onChange}
      />
      {comments.length > 0 && (
        <CommentsList comments={comments} color={"text-red-500"} />
      )}
      {errorPath && (
        <ValidationError errors={errors} touched={touched} name={errorPath} />
      )}
    </>
  );
};

export default DatePickerField;
