import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { useDebounce } from "use-debounce";

import ReactTable from "components/lib/Global/ReactTable";
import DeleteButtonWithConfirmation from "components/lib/Shared/DeleteButtonWithConfirmation";
import Button from "components/lib/Shared/Button";
import { deleteDiscountAsync, getDiscountsAsync } from "state/slices/discount";
import { getCompletePackageName } from "utils";
import TableActionMenuWrapper from "components/lib/Shared/TableActionMenuWrapper";
import ConfirmDialog from "components/lib/Shared/ConfirmDialog";
import SearchInput from "components/lib/Shared/SearchInput";
import DashOutline from "components/lib/Shared/Icons/DashOutline";

const DiscountsTable = ({ handleEditDiscount }) => {
  const dispatch = useDispatch();
  const { discounts, getDiscounts } = useSelector(
    (state) => state.discountSlice
  );
  const { modalRow } = useSelector((state) => state.modalsSlice);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const handleDeleteDiscount = async (row) => {
    await dispatch(deleteDiscountAsync({ discountId: row._id }));
    dispatch(getDiscountsAsync());
  };

  useEffect(() => {
    if (debouncedSearchTerm) setCurrentPage(1);
    dispatch(
      getDiscountsAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm.trim(),
        order: "asc",
      })
    );
  }, [dispatch, currentPage, debouncedSearchTerm]);

  const columns = [
    {
      Header: "Code",
      className:
        "p-4 whitespace-normal text-ellipsis overflow-hidden max-w-[40px]",
      Cell: ({ row }) => row.original?.code ?? "N/A",
    },
    {
      Header: "Packages",
      className:
        "p-4 whitespace-normal text-ellipsis overflow-hidden max-w-[200px]",
      Cell: ({ row }) => {
        const packagesHtml = row.original?.packages
          .map((pricingPackage) => getCompletePackageName(pricingPackage))
          .join(",<br>");
        return <span dangerouslySetInnerHTML={{ __html: packagesHtml }} />;
      },
    },
    {
      Header: "Start Date",
      Cell: ({ row: { original } }) =>
        original?.startDate ? format(new Date(original?.startDate), "PP") : "",
    },
    {
      Header: "End Date",
      Cell: ({ row: { original } }) =>
        original?.endDate ? format(new Date(original?.endDate), "PP") : "",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <TableActionMenuWrapper>
          <Menu.Item>
            {({ active }) => (
              <Button
                onClick={() => handleEditDiscount(row.original)}
                overrideBaseStyles
                className={`${
                  active ? "bg-violet-500 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                Edit
              </Button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <DeleteButtonWithConfirmation
                confirmationModalName="delete_discount_confirmation_modal"
                overrideBaseStyles
                btnClasses={`${
                  active ? "bg-red-600 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                row={row.original}
              />
            )}
          </Menu.Item>
        </TableActionMenuWrapper>
      ),
    },
  ];

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput
          value={searchTerm}
          placeholder="search by code"
          handleOnChange={handleSearch}
        />
      </div>
      <div className="w-full bg-white shadow">
        {discounts.totalCount === 0 ? (
          <div className="relative flex flex-col items-center justify-center p-32 text-center">
            <DashOutline />
            <div className="absolute flex flex-col items-center justify-center">
              <h2 className="text-title5">There are no discounts to display</h2>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <ReactTable
              columns={columns}
              data={discounts.items}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              totalRows={discounts.totalCount}
              setCurrentPage={setCurrentPage}
              paginate
              isLoading={getDiscounts.status === "loading"}
              headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
              headingStyle="pl-4 text-left text-white"
              dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            />
          </div>
        )}
      </div>
      <ConfirmDialog
        name="delete_discount_confirmation_modal"
        onConfirm={() => handleDeleteDiscount(modalRow)}
      >
        Are you sure you want to permanently delete this entry?
      </ConfirmDialog>
    </>
  );
};

export default DiscountsTable;
