/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from "react";
import { useFormikContext, Field } from "formik";

import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "../../SFLdata/addressOptions";
import Input from "components/lib/Shared/Input";
import Label from "components/lib/Shared/Label";
import ValidationError from "components/lib/Shared/ValidationError";
import { Constants } from "config/constants";
import { getEntityTypeOptions } from "utils";

// Identification Details for Foreign Company
const IdentificationDetailsFoCo = ({
  field,
  form,
  disabled,
  disableEntityType,
  idType,
  designation,
}) => {
  const { setFieldValue } = useFormikContext();
  const [countryOfIncorporation, setCountryOfIncorporation] = useState(() =>
    options.countries.find(
      (country) => country.value === field?.value?.countryOfIncorporation
    )
  );
  const [selectedEntityType, setSelectedEntityType] = useState(() =>
    Constants.CORPORATE_BO_OPTIONS.find(
      (option) => option.value === field.value?.entityType
    )
  );

  const handleSelect = (type) => (option) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
    if (type === "countryOfIncorporation") {
      setCountryOfIncorporation({ label: option.label, value: option.value });
    }
    if (type === "entityType") {
      setSelectedEntityType({ label: option.label, value: option.value });
    }
  };

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  const entityTypeExistsInOptions = Constants.CORPORATE_BO_OPTIONS.find(
    (option) => option.value === field.value?.entityType
  );

  const countriesOptions = useMemo(() => {
    return options.countries.map((country) => {
      if (
        ["Foreign Resident", "Foreigner", "Foreign Company"].includes(idType) &&
        country.value === "KE"
      ) {
        return { ...country, disabled: true };
      }
      return country;
    });
  }, [idType, options.countries]);

  return (
    <>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <Field
            {...field}
            label="Country of Incorporation"
            value={countryOfIncorporation}
            name="countryOfIncorporation"
            component={ReactSelectWithFormik}
            options={countriesOptions}
            errorPath="identificationDetails.countryOfIncorporation"
            placeholder="Select country of incorporation"
            onBlur={field.onBlur}
            onChange={handleSelect("countryOfIncorporation")}
            isSearchable
            isDisabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Company Former Name"
            name="companyFormerName"
            placeholder="Enter company former name"
            form={form}
            value={field.value?.companyFormerName ?? ""}
            errorPath="identificationDetails.companyFormerName"
            onBlur={field.onBlur}
            onChange={handleSetValue}
            disabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          {!field.value?.entityType || entityTypeExistsInOptions ? (
            <>
              <Label htmlFor="entityType">Entity Type</Label>
              <Field
                name="entityType"
                component={ReactSelectWithFormik}
                options={getEntityTypeOptions(designation, idType)}
                isDisabled={disabled || disableEntityType}
                placeholder="Select entity type"
                className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="entityType"
                value={selectedEntityType}
                onChange={handleSelect("entityType")}
              />
            </>
          ) : (
            <Input
              label="Entity Type"
              name="entityType"
              placeholder="Enter entity type"
              form={form}
              value={field.value?.entityType ?? ""}
              errorPath="entityType"
              onBlur={field.onBlur}
              onChange={handleSetValue}
              disabled={disabled || disableEntityType}
            />
          )}
          <ValidationError
            errors={form.errors}
            touched={form.touched}
            name="entityType"
          />
        </div>
      </div>
    </>
  );
};

export default IdentificationDetailsFoCo;
