import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import SignInPage from "pages/Auth/SignIn";
import SignUpPage from "pages/Auth/SignUp";
import ConfirmReviewer from "pages/ConfirmReviewer";
import ConfirmOrganizationReviewer from "pages/ConfirmOrganizationReviewer";
import Dashboard from "pages/Dashboard";
import FrontPage from "pages/FrontPage";
import NotFoundPage from "pages/NotFound";
import AboutUs from "pages/FrontPage/InnerPages/AboutUs";
import LegalDocuments from "pages/FrontPage/LegalDocuments";
import Contacts from "pages/FrontPage/ContactUs";
import Forbidden from "pages/Forbidden";
import { useSocket } from "context/SocketContext";
import { useToast } from "hooks/useToast";
import CookieCleanup from "components/lib/Global/CookieCleanup";
import ProtectedRoute from "components/Auth/ProtectedRoute";
import CompanyFormationPage from "pages/CompanyFormation";
import PaymentSuccessPage from "pages/Payments/Success";
import Corporates from "components/Applications/Corporates";
import ContactPersonCompaniesListPage from "pages/ContactPersonCompaniesList";
import DraftApplications from "components/Applications/Draft";
import Signature from "components/Applications/Signature";
import PaymentFailedPage from "pages/PaymentFailedPage";
import ApplicationsPending from "components/Applications/Pending";
import ProfilesPending from "components/Profiles";
import Profile from "pages/Profile";
import ProfileEdit from "pages/Profile/Edit";
import DashboardAgainstRole from "pages/Dashboard/DashboardAgainstRole";
import Transactions from "pages/Transactions";
import Corrected from "components/Applications/Corrected";
import Deferred from "components/Applications/Deferred";
import BRSSubmission from "components/Applications/BRSSubmission";
import AllApplications from "components/Applications/AllApplications";
import CompanyDashboardPage from "pages/CompanyDashboard";
import ApplicationReview from "pages/ApplicationReview";
import CompaniesList from "pages/CompaniesList";
import PublicLimitedCompanyPage from "pages/PublicLimitedCompany";
import CheckoutSuccess from "pages/CheckoutSuccess";
import PrivateLimitedCompanyPage from "pages/PrivateLimitedCompany";
import SuccessfulSubmission from "pages/SuccessfulSubmission";
import OrganizationsPage from "pages/Organizations";
import CorporateOnboarding from "components/CorporateOnboarding";
import CorporateDashboardPage from "pages/CorporateDashboard";
import { Auth0ProviderWithHistory } from "Auth0ProviderWithHistory";
import VerifyAccountHandler from "components/Auth/verifyAccountHandler";
import { getCurrentUser } from "state/slices/auth";
import ToastContainer from "components/lib/Shared/ToastContainer";
import ConfirmAccount from "pages/Auth/ConfirmAccount";
import InActivityTracker from "components/lib/Global/InActivityTracker";
import Cookies from "js-cookie";
import ApplicantCompanyDashboard from "components/TransferYourBusiness/ApplicantWorkflow";
import AcceptCoApplicantInvitePage from "pages/AcceptCoApplicantInvite";

const App = () => {
  const auth = useAuth0();
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = auth;

  const [authLoading, setAuthLoading] = useState(true);
  const [browserClose, setBrowserClose] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const dispatch = useDispatch();

  const socket = useSocket();
  const { toast } = useToast();
  const history = useHistory();

  useEffect(() => {
    socket.on("redirect", (data) => {
      history.push(data.redirectUrl);
    });
    socket.on("toast", (data) => {
      toast(data.type, data.message);
    });
  }, [history]);

  useEffect(async () => {
    if (isAuthenticated && !isLoading) {
      const token = await getAccessTokenSilently();
      window.localStorage.setItem("token", token);
      sessionStorage.setItem("isBrowserClose", "false");
      localStorage.setItem("isAuthenticated", "true");
      dispatch(getCurrentUser());
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (
      sessionStorage.getItem("isBrowserClose") === "true" &&
      !localStorage.getItem("isAuthenticated")
    ) {
      setShouldRedirect(true);
    } else {
      sessionStorage.setItem("isBrowserClose", "false");
    }
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem("isBrowserClose") === null ||
      sessionStorage.getItem("isBrowserClose") === "true"
    ) {
      setBrowserClose(true);
      Cookies.remove("role");
      localStorage.clear();
    } else {
      setBrowserClose(false);
    }
  }, []);

  // listen event of manual logout
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel("logoutChannel");
    broadcastChannel.onmessage = (event) => {
      if (event.data === "logout") {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }
    };
    return () => {
      broadcastChannel.close();
    };
  }, [logout]);

  useEffect(() => {
    if (!isLoading) {
      setAuthLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (authLoading) {
      return;
    }
    const isAuthenticatedInLocalStorage =
      localStorage.getItem("isAuthenticated") === "true";
    if (browserClose) {
      if (!isAuthenticatedInLocalStorage && shouldRedirect) {
        loginWithRedirect({
          authorizationParams: {
            screen_hint: "login",
            redirect_uri: window.location.origin,
            base_api_url: process.env.REACT_APP_BASE_API_URL,
          },
        });
      }
    }
  }, [browserClose, authLoading]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative min-h-screen bg-gray-50 text-sflPrimary font-main">
      <Auth0ProviderWithHistory>
        <Switch>
          <Route
            path="/"
            exact
            render={() => (isAuthenticated ? <Dashboard /> : <FrontPage />)}
          />
          <Route path="/about" component={AboutUs} />
          <Route path="/legal" component={LegalDocuments}></Route>
          <Route path="/contacts" component={Contacts} />
          <Route path="/login" component={SignInPage} />
          <Route path="/register" component={SignUpPage} />
          <Route path="/verify-account" component={VerifyAccountHandler} />
          <ProtectedRoute path="/profile/edit" component={ProfileEdit} />
          <ProtectedRoute path="/profile" component={Profile} />
          <ProtectedRoute path="/account-settings" component={Profile} />
          <ProtectedRoute path="/billing" component={Profile} />
          <ProtectedRoute path="/dashboard" component={DashboardAgainstRole} />
          <ProtectedRoute path="/transactions" component={Transactions} />
          <Route path="/confirm-account/:token" component={ConfirmAccount} />
          <Route path="/confirm-reviewer/:token" component={ConfirmReviewer} />
          <Route
            path="/organization-review/:token"
            component={ConfirmOrganizationReviewer}
          />
          <Route
            path="/accept-co-applicant-invite/:token"
            component={AcceptCoApplicantInvitePage}
          />
          <ProtectedRoute
            path="/payment-failed"
            component={PaymentFailedPage}
          />
          <ProtectedRoute
            path="/applications/pending"
            component={ApplicationsPending}
          />
          <ProtectedRoute
            path="/applications/draft"
            component={DraftApplications}
          />
          <ProtectedRoute path="/profiles" component={ProfilesPending} />
          <ProtectedRoute
            path="/applications/corrected"
            component={Corrected}
          />
          <ProtectedRoute path="/applications/deferred" component={Deferred} />
          <ProtectedRoute
            path="/applications/signature"
            component={Signature}
          />
          <ProtectedRoute
            path="/applications/brs-submission"
            component={BRSSubmission}
          />
          <ProtectedRoute
            path="/applications/all-applications"
            component={AllApplications}
          />
          <ProtectedRoute
            path="/companies/:companyId/dashboard"
            component={CompanyDashboardPage}
          />
          <ProtectedRoute
            path="/companies/:companyId/review"
            component={ApplicationReview}
          />
          <ProtectedRoute
            path="/companies/:companyId/view"
            component={ApplicationReview}
          />
          <ProtectedRoute
            path="/companies/:companyId"
            component={CompanyFormationPage}
          />
          <ProtectedRoute exact path="/companies" component={CompaniesList} />
          <ProtectedRoute
            path="/corporate-directors-shareholders"
            component={ContactPersonCompaniesListPage}
          />
          <ProtectedRoute
            path="/public-limited-company"
            component={PublicLimitedCompanyPage}
          />
          <ProtectedRoute
            path="/checkout-success"
            component={CheckoutSuccess}
          />
          {/* <ProtectedRoute
            path="/applicant-transfer-business"
            component={ApplicantCompanyDashboard}
          /> */}
          <ProtectedRoute
            path="/private-limited-company"
            component={PrivateLimitedCompanyPage}
          />
          <ProtectedRoute
            path="/successful-submission"
            component={SuccessfulSubmission}
          />
          <ProtectedRoute path="/organizations" component={OrganizationsPage} />
          <ProtectedRoute
            exact
            path="/corporates/:corporateId"
            component={CorporateOnboarding}
          />
          <ProtectedRoute
            path="/corporates/:corporateId/view"
            component={CorporateOnboarding}
          />
          <ProtectedRoute
            path="/corporates/:corporateId/dashboard"
            component={CorporateDashboardPage}
          />
          <ProtectedRoute path="/corporates" component={Corporates} />
          <ProtectedRoute
            path="/payments/success"
            component={PaymentSuccessPage}
          />
          <Route path="/access-denied" component={Forbidden} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Auth0ProviderWithHistory>
      <ToastContainer />
      <CookieCleanup />
      <InActivityTracker />
    </div>
  );
};

export default App;
