export default {
  residence: {
    county: [
      {
        value: "Baringo",
        label: "Baringo",
      },
      {
        value: "Bungoma",
        label: "Bungoma",
      },
      {
        value: "Bomet",
        label: "Bomet",
      },
      {
        value: "Busia",
        label: "Busia",
      },
      {
        value: "Elgeyo/Marakwet",
        label: "Elgeyo/Marakwet",
      },
      {
        value: "Embu",
        label: "Embu",
      },
      {
        value: "Garissa",
        label: "Garissa",
      },
      {
        value: "Homa Bay",
        label: "Homa Bay",
      },
      {
        value: "Isiolo",
        label: "Isiolo",
      },
      {
        value: "Kajiado",
        label: "Kajiado",
      },
      {
        value: "Kakamega",
        label: "Kakamega",
      },
      {
        value: "Kericho",
        label: "Kericho",
      },
      {
        value: "Kiambu",
        label: "Kiambu",
      },
      {
        value: "Kilifi",
        label: "Kilifi",
      },
      {
        value: "Kirinyaga",
        label: "Kirinyaga",
      },
      {
        value: "Kisii",
        label: "Kisii",
      },
      {
        value: "Kisumu",
        label: "Kisumu",
      },
      {
        value: "Kitui",
        label: "Kitui",
      },
      {
        value: "Kwale",
        label: "Kwale",
      },
      {
        value: "Laikipia",
        label: "Laikipia",
      },
      {
        value: "Lamu",
        label: "Lamu",
      },
      {
        value: "Machakos",
        label: "Machakos",
      },
      {
        value: "Makueni",
        label: "Makueni",
      },
      {
        value: "Mandera",
        label: "Mandera",
      },
      {
        value: "Marsabit",
        label: "Marsabit",
      },
      {
        value: "Meru",
        label: "Meru",
      },
      {
        value: "Migori",
        label: "Migori",
      },
      {
        value: "Mombasa",
        label: "Mombasa",
      },
      {
        value: "Muranga",
        label: "Muranga",
      },
      {
        value: "Nairobi",
        label: "Nairobi",
      },
      {
        value: "Nakuru",
        label: "Nakuru",
      },
      {
        value: "Nandi",
        label: "Nandi",
      },
      {
        value: "Narok",
        label: "Narok",
      },
      {
        value: "Nyamira",
        label: "Nyamira",
      },
      {
        value: "Nyandarua",
        label: "Nyandarua",
      },
      {
        value: "Nyeri",
        label: "Nyeri",
      },
      {
        value: "Samburu",
        label: "Samburu",
      },
      {
        value: "Siaya",
        label: "Siaya",
      },
      {
        value: "Taita Taveta",
        label: "Taita Taveta",
      },
      {
        value: "Tana River",
        label: "Tana River",
      },
      {
        value: "Tharaka Nithi",
        label: "Tharaka Nithi",
      },
      {
        value: "Trans Nzoia",
        label: "Trans Nzoia",
      },
      {
        value: "Turkana",
        label: "Turkana",
      },
      {
        value: "Uasin Gishu",
        label: "Usain Gishu",
      },
      {
        value: "Vihiga",
        label: "Vihiga",
      },
      {
        value: "Wajir",
        label: "Wajir",
      },
      {
        value: "West Pokot",
        label: "West Pokot",
      },
    ],
    district: [
      {
        label: "Baringo Central District",
        value: "Baringo Central District",
        county: "Baringo",
      },
      {
        label: "Baringo North District",
        value: "Baringo North District",
        county: "Baringo",
      },
      {
        label: "East Pokot District",
        value: "East Pokot District",
        county: "Baringo",
      },
      {
        label: "Koibatek District",
        value: "Koibatek District",
        county: "Baringo",
      },
      {
        label: "Marigat District",
        value: "Marigat District",
        county: "Baringo",
      },
      {
        label: "Mogotio District",
        value: "Mogotio District",
        county: "Baringo",
      },
      {
        label: "Bomet District",
        value: "Bomet District",
        county: "Bomet",
      },
      {
        label: "Chepalungu District",
        value: "Chepalungu District",
        county: "Bomet",
      },
      {
        label: "Sotik District",
        value: "Sotik District",
        county: "Bomet",
      },
      {
        label: "Bumula District",
        value: "Bumula District",
        county: "Bungoma",
      },
      {
        label: "Bungoma Central District",
        value: "Bungoma Central District",
        county: "Bungoma",
      },
      {
        label: "Bungoma East District",
        value: "Bungoma East District",
        county: "Bungoma",
      },
      {
        label: "Bungoma North District",
        value: "Bungoma North District",
        county: "Bungoma",
      },
      {
        label: "Bungoma South District",
        value: "Bungoma South District",
        county: "Bungoma",
      },
      {
        label: "Bungoma West District",
        value: "Bungoma West District",
        county: "Bungoma",
      },
      {
        label: "Cheptais District",
        value: "Cheptais District",
        county: "Bungoma",
      },
      {
        label: "Kimilili District",
        value: "Kimilili District",
        county: "Bungoma",
      },
      {
        label: "Mt Elgon District",
        value: "Mt Elgon District",
        county: "Bungoma",
      },
      {
        label: "Bunyala District",
        value: "Bunyala District",
        county: "Busia",
      },
      {
        label: "Busia District",
        value: "Busia District",
        county: "Busia",
      },
      {
        label: "Butula District",
        value: "Butula District",
        county: "Busia",
      },
      {
        label: "Nambale District",
        value: "Nambale District",
        county: "Busia",
      },
      {
        label: "Samia District",
        value: "Samia District",
        county: "Busia",
      },
      {
        label: "Teso North District",
        value: "Teso North District",
        county: "Busia",
      },
      {
        label: "Teso South District",
        value: "Teso South District",
        county: "Busia",
      },
      {
        label: "Keiyo District",
        value: "Keiyo District",
        county: "Elgeyo/Marakwet",
      },
      {
        label: "Keiyo South District",
        value: "Keiyo South District",
        county: "Elgeyo/Marakwet",
      },
      {
        label: "Marakwet East District",
        value: "Marakwet East District",
        county: "Elgeyo/Marakwet",
      },
      {
        label: "Marakwet West District",
        value: "Marakwet West District",
        county: "Elgeyo/Marakwet",
      },
      {
        label: "Embu East District",
        value: "Embu East District",
        county: "Embu",
      },
      {
        label: "Embu West District",
        value: "Embu West District",
        county: "Embu",
      },
      {
        label: "Embu North District",
        value: "Embu North District",
        county: "Embu",
      },
      {
        label: "Mbeere North District",
        value: "Mbeere North District",
        county: "Embu",
      },
      {
        label: "Mbeere South District",
        value: "Mbeere South District",
        county: "Embu",
      },
      {
        label: "Balambala District",
        value: "Balambala District",
        county: "Garissa",
      },
      {
        label: "Dadaab District",
        value: "Dadaab District",
        county: "Garissa",
      },
      {
        label: "Fafi District",
        value: "Fafi District",
        county: "Garissa",
      },
      {
        label: "Garissa District",
        value: "Garissa District",
        county: "Garissa",
      },
      {
        label: "Hulugho District",
        value: "Hulugho District",
        county: "Garissa",
      },
      {
        label: "Ijara District",
        value: "Ijara District",
        county: "Garissa",
      },
      {
        label: "Lagdera District",
        value: "Lagdera District",
        county: "Garissa",
      },
      {
        label: "Homa Bay District",
        value: "Homa Bay District",
        county: "Homa Bay",
      },
      {
        label: "Mbita District",
        value: "Mbita District",
        county: "Homa Bay",
      },
      {
        label: "Ndhiwa District",
        value: "Ndhiwa District",
        county: "Homa Bay",
      },
      {
        label: "Rachuonyo North District",
        value: "Rachuonyo North District",
        county: "Homa Bay",
      },
      {
        label: "Rachuonyo South District",
        value: "Rachuonyo South District",
        county: "Homa Bay",
      },
      {
        label: "Suba District",
        value: "Suba District",
        county: "HHoma Bay",
      },
      {
        label: "Garbatula District",
        value: "Garbatula District",
        county: "Isiolo",
      },
      {
        label: "Garbatulla District",
        value: "Garbatulla District",
        county: "Isiolo",
      },
      {
        label: "Isiolo District",
        value: "Isiolo District",
        county: "Isiolo",
      },
      {
        label: "Merti District",
        value: "Merti District",
        county: "Isiolo",
      },
      {
        label: "Isinya District",
        value: "Isinya District",
        county: "Kajiado",
      },
      {
        label: "Kajiado Central District",
        value: "Kajiado Central District",
        county: "Kajiado",
      },
      {
        label: "Kajiado East District",
        value: "Kajiado East District",
        county: "Kajiado",
      },
      {
        label: "Kajiado North District",
        value: "Kajiado North District",
        county: "Kajiado",
      },
      {
        label: "Kajiado South District",
        value: "Kajiado South District",
        county: "Kajiado",
      },
      {
        label: "Kajiado West District",
        value: "Kajiado West District",
        county: "Kajiado",
      },
      {
        label: "Loitoktok District",
        value: "Loitoktok District",
        county: "Kajiado",
      },
      {
        label: "Mashuru District",
        value: "Mashuru District",
        county: "Kajiado",
      },
      {
        label: "Butere District",
        value: "Butere District",
        county: "Kakamega",
      },
      {
        label: "Kakamega Central District",
        value: "Kakamega Central District",
        county: "Kakamega",
      },
      {
        label: "Kakamega East District",
        value: "Kakamega East District",
        county: "Kakamega",
      },
      {
        label: "Kakamega North District",
        value: "Kakamega North District",
        county: "Kakamega",
      },
      {
        label: "Kakamega South District",
        value: "Kakamega South District",
        county: "Kakamega",
      },
      {
        label: "Khwisero District",
        value: "Khwisero District",
        county: "Kakamega",
      },
      {
        label: "Likuyani District",
        value: "Likuyani District",
        county: "Kakamega",
      },
      {
        label: "Matete District",
        value: "Matete District",
        county: "Kakamega",
      },
      {
        label: "Matungu District",
        value: "Matungu District",
        county: "Kakamega",
      },
      {
        label: "Mumias  District",
        value: "Mumias District",
        county: "Kakamega",
      },
      {
        label: "Belgut District",
        value: "Belgut District",
        county: "Kericho",
      },
      {
        label: "Buret District",
        value: "Buret District",
        county: "Kericho",
      },
      {
        label: "Bureti District",
        value: "Bureti District",
        county: "Kericho",
      },
      {
        label: "Kericho District",
        value: "Kericho District",
        county: "Kericho",
      },
      {
        label: "Kipkelion District",
        value: "Kipkelion District",
        county: "Kericho",
      },
      {
        label: "Konoin District",
        value: "Konoin District",
        county: "Kericho",
      },
      {
        label: "Londiani District",
        value: "Londiani District",
        county: "Kericho",
      },
      {
        label: "Gatundu District",
        value: "Gatundu District",
        county: "Kiambu",
      },
      {
        label: "Gatundu North District",
        value: "Gatundu North District",
        county: "Kiambu",
      },
      {
        label: "Githunguri District",
        value: "Githunguri District",
        county: "Kiambu",
      },
      {
        label: "Kabete District",
        value: "Kabete District",
        county: "Kiambu",
      },
      {
        label: "Kiambaa District",
        value: "Kiambaa District",
        county: "Kiambu",
      },
      {
        label: "Kiambu District",
        value: "Kiambu District",
        county: "Kiambu",
      },
      {
        label: "Kiambu East District",
        value: "Kiambu East District",
        county: "Kiambu",
      },
      {
        label: "Kiambu West District",
        value: "Kiambu West District",
        county: "Kiambu",
      },
      {
        label: "Lari District",
        value: "Lari District",
        county: "Kiambu",
      },
      {
        label: "Limuru District",
        value: "Limuru District",
        county: "Kiambu",
      },
      {
        label: "Ruiru District",
        value: "Ruiru District",
        county: "Kiambu",
      },
      {
        label: "Thika East District",
        value: "Thika East District",
        county: "Kiambu",
      },
      {
        label: "Thika West District",
        value: "Thika West District",
        county: "Kiambu",
      },
      {
        label: "Ganze District",
        value: "Ganze District",
        county: "Kilifi",
      },
      {
        label: "Kaloleni District",
        value: "Kaloleni District",
        county: "Kilifi",
      },
      {
        label: "Kilifi District",
        value: "Kilifi District",
        county: "Kilifi",
      },
      {
        label: "Magarini District",
        value: "Magarini District",
        county: "Kilifi",
      },
      {
        label: "Malindi District",
        value: "Malindi District",
        county: "Kilifi",
      },
      {
        label: "Rabai District",
        value: "Rabai District",
        county: "Kilifi",
      },
      {
        label: "Kirinyaga Central District",
        value: "Kirinyaga Central District",
        county: "Kirinyaga",
      },
      {
        label: "Kirinyaga East District",
        value: "Kirinyaga East District",
        county: "Kirinyaga",
      },
      {
        label: "Kirinyaga North District",
        value: "Kirinyaga North District",
        county: "Kirinyaga",
      },
      {
        label: "Kirinyaga South District",
        value: "Kirinyaga South District",
        county: "Kirinyaga",
      },
      {
        label: "Kirinyaga West District",
        value: "Kirinyaga West District",
        county: "Kirinyaga",
      },
      {
        label: "Gucha North District",
        value: "Gucha North District",
        county: "Kisii",
      },
      {
        label: "Gucha South District",
        value: "Gucha South District",
        county: "Kisii",
      },
      {
        label: "Kenyenya District",
        value: "Kenyenya District",
        county: "Kisii",
      },
      {
        label: "Kisii Central District",
        value: "Kisii Central District",
        county: "Kisii",
      },
      {
        label: "Kisii South District",
        value: "Kisii South District",
        county: "Kisii",
      },
      {
        label: "Marani District",
        value: "Marani District",
        county: "Kisii",
      },
      {
        label: "Masaba District",
        value: "Masaba District",
        county: "Kisii",
      },
      {
        label: "North Masaba District",
        value: "North Masaba District",
        county: "Kisii",
      },
      {
        label: "Nyamache District",
        value: "Nyamache District",
        county: "Kisii",
      },
      {
        label: "Sameta District",
        value: "Sameta District",
        county: "Kisii",
      },
      {
        label: "Kisumu East District",
        value: "Kisumu East District",
        county: "Kisumu",
      },
      {
        label: "Kisumu Town West District",
        value: "Kisumu Town West District",
        county: "Kisumu",
      },
      {
        label: "Kisumu West District",
        value: "Kisumu West District",
        county: "Kisumu",
      },
      {
        label: "Muhoroni District",
        value: "Muhoroni District",
        county: "Kisumu",
      },
      {
        label: "Nyakach District",
        value: "Nyakach District",
        county: "Kisumu",
      },
      {
        label: "Nyando District",
        value: "Nyando District",
        county: "Kisumu",
      },
      {
        label: "Ikutha District",
        value: "Ikutha District",
        county: "Kitui",
      },
      {
        label: "Katulani District",
        value: "Katulani District",
        county: "Kitui",
      },
      {
        label: "Kisasi District",
        value: "Kisasi District",
        county: "Kitui",
      },
      {
        label: "Kitui Central District",
        value: "Kitui Central District",
        county: "Kitui",
      },
      {
        label: "Kitui West District",
        value: "Kitui West District",
        county: "Kitui",
      },
      {
        label: "Kyuso District",
        value: "Kyuso District",
        county: "Kitui",
      },
      {
        label: "Lower Yatta District",
        value: "Lower Yatta District",
        county: "Kitui",
      },
      {
        label: "Matinyani District",
        value: "Matinyani District",
        county: "Kitui",
      },
      {
        label: "Mumoni District",
        value: "Mumoni District",
        county: "Kitui",
      },
      {
        label: "Mutitu District",
        value: "Mutitu District",
        county: "Kitui",
      },
      {
        label: "Mutomo District",
        value: "Mutomo District",
        county: "Kitui",
      },
      {
        label: "Mwingi Central",
        value: "Mwingi Central",
        county: "Kitui",
      },
      {
        label: "Mwingi District",
        value: "Mwingi District",
        county: "Kitui",
      },
      {
        label: "Mwingi East District",
        value: "Mwingi East District",
        county: "Kitui",
      },
      {
        label: "Mwingi West District",
        value: "Mwingi West District",
        county: "Kitui",
      },
      {
        label: "Nzambani District",
        value: "Nzambani District",
        county: "Kitui",
      },
      {
        label: "Tseikuru District",
        value: "Tseikuru District",
        county: "Kitui",
      },
      {
        label: "Kinango District",
        value: "Kinango District",
        county: "Kwale",
      },
      {
        label: "Kwale District",
        value: "Kwale District",
        county: "Kwale",
      },
      {
        label: "Matuga District",
        value: "Matuga District",
        county: "Kwale",
      },
      {
        label: "Msabweni District",
        value: "Msabweni District",
        county: "Kwale",
      },
      {
        label: "Msambweni District",
        value: "Msambweni District",
        county: "Kwale",
      },
      {
        label: "Laikipia Central District",
        value: "Laikipia Central District",
        county: "Laikipia",
      },
      {
        label: "Laikipia East District",
        value: "Laikipia East District",
        county: "Laikipia",
      },
      {
        label: "Laikipia North District",
        value: "Laikipia North District",
        county: "Laikipia",
      },
      {
        label: "Laikipia West District",
        value: "Laikipia West District",
        county: "Laikipia",
      },
      {
        label: "Nyahururu District",
        value: "Nyahururu District",
        county: "Laikipia",
      },
      {
        label: "Lamu District",
        value: "Lamu District",
        county: "Lamu",
      },
      {
        label: "Lamu East District",
        value: "Lamu East District",
        county: "Lamu",
      },
      {
        label: "Lamu West District",
        value: "Lamu West District",
        county: "Lamu",
      },
      {
        label: "Athi River District",
        value: "Athi River District",
        county: "Machakos",
      },
      {
        label: "Kangundo District",
        value: "Kangundo District",
        county: "Machakos",
      },
      {
        label: "Kathiani District",
        value: "Kathiani District",
        county: "Machakos",
      },
      {
        label: "Machakos District",
        value: "Machakos District",
        county: "Machakos",
      },
      {
        label: "Masinga District",
        value: "Masinga District",
        county: "Machakos",
      },
      {
        label: "Matungulu District",
        value: "Matungulu District",
        county: "Machakos",
      },
      {
        label: "Mwala District",
        value: "Mwala District",
        county: "Machakos",
      },
      {
        label: "Nambale District",
        value: "Nambale District",
        county: "Machakos",
      },
      {
        label: "Kathonzweni District",
        value: "Kathonzweni District",
        county: "Makueni",
      },
      {
        label: "Kibwezi District",
        value: "Kibwezi District",
        county: "Makueni",
      },
      {
        label: "Kilungu District",
        value: "Kilungu District",
        county: "Makueni",
      },
      {
        label: "Makindu District",
        value: "Makindu District",
        county: "Makueni",
      },
      {
        label: "Mbooni East District",
        value: "Mbooni East District",
        county: "Makueni",
      },
      {
        label: "Mbooni West District",
        value: "Mbooni West District",
        county: "Makueni",
      },
      {
        label: "Makueni District",
        value: "Makueni District",
        county: "Makueni",
      },
      {
        label: "Mukaa District",
        value: "Mukaa District",
        county: "Makueni",
      },
      {
        label: "Nzaui District",
        value: "Nzaui District",
        county: "Makueni",
      },
      {
        label: "Banisa District",
        value: "Banisa District",
        county: "Mandera",
      },
      {
        label: "Lafey District",
        value: "Lafey District",
        county: "Mandera",
      },
      {
        label: "Mandera Central District",
        value: "Mandera Central District",
        county: "Mandera",
      },
      {
        label: "Mandera East District",
        value: "Mandera East District",
        county: "Mandera",
      },
      {
        label: "Mandera North District",
        value: "Mandera North District",
        county: "Mandera",
      },
      {
        label: "Mandera West District",
        value: "Mandera West District",
        county: "Mandera",
      },
      {
        label: "Loyangalani District",
        value: "Loyangalani District",
        county: "Marsabit",
      },
      {
        label: "Marsabit District",
        value: "Marsabit District",
        county: "Marsabit",
      },
      {
        label: "Marsabit North District",
        value: "Marsabit North District",
        county: "Marsabit",
      },
      {
        label: "Marsabit South District",
        value: "Marsabit South District",
        county: "Marsabit",
      },
      {
        label: "Moyale District",
        value: "Moyale District",
        county: "Marsabit",
      },
      {
        label: "North Horr District",
        value: "North Horr District",
        county: "Marsabit",
      },
      {
        label: "Sololo District",
        value: "Sololo District",
        county: "Marsabit",
      },

      {
        label: "Buuri District",
        value: "Buuri District",
        county: "Meru",
      },
      {
        label: "Central Imenti District",
        value: "Central Imenti District",
        county: "Meru",
      },
      {
        label: "Igembe North District",
        value: "Igembe North District",
        county: "Meru",
      },
      {
        label: "Igembe South District",
        value: "Igembe South District",
        county: "Meru",
      },
      {
        label: "Imenti North District",
        value: "Imenti North District",
        county: "Meru",
      },
      {
        label: "Imenti South District",
        value: "Imenti South District",
        county: "Meru",
      },
      {
        label: "Maara District",
        value: "Maara District",
        county: "Meru",
      },
      {
        label: "Meru Central District",
        value: "Meru Central District",
        county: "Meru",
      },
      {
        label: "Tigania East District",
        value: "Tigania East District",
        county: "Meru",
      },
      {
        label: "Tigania West District",
        value: "Tigania West District",
        county: "Meru",
      },
      {
        label: "Awendo District",
        value: "Awendo District",
        county: "Migori",
      },
      {
        label: "Kuria East District",
        value: "Kuria East District",
        county: "Migori",
      },
      {
        label: "Kuria West District",
        value: "Kuria West District",
        county: "Migori",
      },
      {
        label: "Migori District",
        value: "Migori District",
        county: "Migori",
      },
      {
        label: "Nyatike District",
        value: "Nyatike District",
        county: "Migori",
      },
      {
        label: "Rongo District",
        value: "Rongo District",
        county: "Migori",
      },
      {
        label: "Uriri District",
        value: "Uriri District",
        county: "Migori",
      },
      {
        label: "Changamwe District",
        value: "Changamwe District",
        county: "Mombasa",
      },
      {
        label: "Kilindini District",
        value: "Kilindini District",
        county: "Mombasa",
      },
      {
        label: "Kisauni District",
        value: "Kisauni District",
        county: "Mombasa",
      },
      {
        label: "Likoni District",
        value: "Likoni District",
        county: "Mombasa",
      },
      {
        label: "Mombasa District",
        value: "Mombasa District",
        county: "Mombasa",
      },
      {
        label: "Gatanga District",
        value: "Gatanga District",
        county: "Muranga",
      },
      {
        label: "Kahuro District",
        value: "Kahuro District",
        county: "Muranga",
      },
      {
        label: "Kamukunji District",
        value: "Kamukunji District",
        county: "Muranga",
      },
      {
        label: "Kandara District",
        value: "Kandara District",
        county: "Muranga",
      },
      {
        label: "Kigumo District",
        value: "Kigumo District",
        county: "Muranga",
      },
      {
        label: "Mathioya District",
        value: "Mathioya District",
        county: "Muranga",
      },
      {
        label: "Muranga District",
        value: "Muranga District",
        county: "Muranga",
      },
      {
        label: "Muranga East District",
        value: "Muranga East District",
        county: "Muranga",
      },
      {
        label: "Muranga South District",
        value: "Muranga South District",
        county: "Muranga",
      },
      {
        label: "Muranga West",
        value: "Muranga West",
        county: "Muranga",
      },
      {
        label: "Dagoreti District",
        value: "Dagoreti District",
        county: "Nairobi",
      },
      {
        label: "Embakasi District",
        value: "Embakasi District",
        county: "Nairobi",
      },
      {
        label: "Kamukunji District Nairobi",
        value: "Kamukunji District Nairobi",
        county: "Nairobi",
      },
      {
        label: "Kasarani District",
        value: "Kasarani District",
        county: "Nairobi",
      },
      {
        label: "Langata District",
        value: "Langata District",
        county: "Nairobi",
      },
      {
        label: "Makadara District",
        value: "Makadara District",
        county: "Nairobi",
      },
      {
        label: "Nairobi East District",
        value: "Nairobi East District",
        county: "Nairobi",
      },
      {
        label: "Nairobi North District",
        value: "Nairobi North District",
        county: "Nairobi",
      },
      {
        label: "Nairobi West District",
        value: "Nairobi West District",
        county: "Nairobi",
      },
      {
        label: "Njiiru District",
        value: "Njiiru District",
        county: "Nairobi",
      },
      {
        label: "Starehe District",
        value: "Starehe District",
        county: "Nairobi",
      },
      {
        label: "Westlands District",
        value: "Westlands District",
        county: "Nairobi",
      },
      {
        label: "Gilgil District",
        value: "Gilgil District",
        county: "Nakuru",
      },
      {
        label: "Kuresoi District",
        value: "Kuresoi District",
        county: "Nakuru",
      },
      {
        label: "Molo District",
        value: "Molo District",
        county: "Nakuru",
      },
      {
        label: "Naivasha District",
        value: "Naivasha District",
        county: "Nakuru",
      },
      {
        label: "Nakuru District",
        value: "Nakuru District",
        county: "Nakuru",
      },
      {
        label: "Nakuru North District",
        value: "Nakuru North District",
        county: "Nakuru",
      },
      {
        label: "Njoro District",
        value: "Njoro District",
        county: "Nakuru",
      },
      {
        label: "Rongai District",
        value: "Rongai District",
        county: "Nakuru",
      },
      {
        label: "Subukia District",
        value: "Subukia District",
        county: "Nakuru",
      },
      {
        label: "Nandi Central District",
        value: "Nandi Central District",
        county: "Nandi",
      },
      {
        label: "Nandi East District",
        value: "Nandi East District",
        county: "Nandi",
      },
      {
        label: "Nandi North District",
        value: "Nandi North District",
        county: "Nandi",
      },
      {
        label: "Nandi South District",
        value: "Nandi South District",
        county: "Nandi",
      },
      {
        label: "Tinderet District",
        value: "Tinderet District",
        county: "Nandi",
      },
      {
        label: "Dikirr District",
        value: "Dikirr District",
        county: "Narok",
      },
      {
        label: "Narok North District",
        value: "Narok North District",
        county: "Narok",
      },
      {
        label: "Narok South District",
        value: "Narok South District",
        county: "Narok",
      },
      {
        label: "Trans Mara District",
        value: "Trans Mara District",
        county: "Narok",
      },
      {
        label: "Borabu District",
        value: "Borabu District",
        county: "Nyamira",
      },
      {
        label: "Kitutu District",
        value: "Kitutu District",
        county: "Nyamira",
      },
      {
        label: "Manga District",
        value: "Manga District",
        county: "Nyamira",
      },
      {
        label: "Nyamira District",
        value: "Nyamira District",
        county: "Nyamira",
      },
      {
        label: "Nyamira North District",
        value: "Nyamira North District",
        county: "Nyamira",
      },
      {
        label: "Kinangop District",
        value: "Kinangop District",
        county: "Nyandarua",
      },
      {
        label: "Kipipiri District",
        value: "Kipipiri District",
        county: "Nyandarua",
      },
      {
        label: "Mirangini District",
        value: "Mirangini District",
        county: "Nyandarua",
      },
      {
        label: "Nyandarua Central District",
        value: "Nyandarua Central District",
        county: "Nyandarua",
      },
      {
        label: "Nyandarua North District",
        value: "Nyandarua North District",
        county: "Nyandarua",
      },
      {
        label: "Nyandarua South District",
        value: "Nyandarua South District",
        county: "Nyandarua",
      },
      {
        label: "Nyandarua West District",
        value: "Nyandarua West District",
        county: "Nyandarua",
      },
      {
        label: "Kieni East District",
        value: "Kieni East District",
        county: "Nyeri",
      },
      {
        label: "Kieni West District",
        value: "Kieni West District",
        county: "Nyeri",
      },
      {
        label: "Mathira East District",
        value: "Mathira East District",
        county: "Nyeri",
      },
      {
        label: "Mukureini District",
        value: "Mukureini District",
        county: "Nyeri",
      },
      {
        label: "Mukurweini District",
        value: "Mukurweini District",
        county: "Nyeri",
      },
      {
        label: "Nyeri Central District",
        value: "Nyeri Central District",
        county: "Nyeri",
      },
      {
        label: "Nyeri East District",
        value: "Nyeri East District",
        county: "Nyeri",
      },
      {
        label: "Nyeri South District",
        value: "Nyeri South District",
        county: "Nyeri",
      },
      {
        label: "Tetu District",
        value: "Tetu District",
        county: "Nyeri",
      },
      {
        label: "Samburu Central District",
        value: "Samburu Central District",
        county: "Samburu",
      },
      {
        label: "Samburu East District",
        value: "Samburu East District",
        county: "Samburu",
      },
      {
        label: "Samburu North District",
        value: "Samburu North District",
        county: "Samburu",
      },
      {
        label: "Bondo District",
        value: "Bondo District",
        county: "Siaya",
      },
      {
        label: "Gem District",
        value: "Gem District",
        county: "Siaya",
      },
      {
        label: "Rarieda District",
        value: "Rarieda District",
        county: "Siaya",
      },
      {
        label: "Siaya District",
        value: "Siaya District",
        county: "Siaya",
      },
      {
        label: "Ugenya District",
        value: "Ugenya District",
        county: "Siaya",
      },
      {
        label: "Ugunja District",
        value: "Ugunja District",
        county: "Siaya",
      },
      {
        label: "Mwatate District",
        value: "Mwatate District",
        county: "Taita Taveta",
      },
      {
        label: "Taita District",
        value: "Taita District",
        county: "Taita Taveta",
      },
      {
        label: "Taveta District",
        value: "Taveta District",
        county: "Taita Taveta",
      },
      {
        label: " Voi District",
        value: "Voi District",
        county: "Taita Taveta",
      },
      {
        label: "Voi",
        value: "Voi",
        county: "Taita Taveta",
      },
      {
        label: "Wundanyi District",
        value: "Wundanyi District",
        county: "Taita Taveta",
      },
      {
        label: "Bura District",
        value: "Bura District",
        county: "Tana River",
      },
      {
        label: "Tana Delta District",
        value: "Tana Delta District",
        county: "Tana River",
      },
      {
        label: "Tana North Bura District",
        value: "Tana North Bura District",
        county: "Tana River",
      },
      {
        label: "Tana River District",
        value: "Tana River District",
        county: "Tana River",
      },
      {
        label: "Meru South District",
        value: "Meru South District",
        county: "Tharaka Nithi",
      },
      {
        label: "Tharaka North District",
        value: "Tharaka North District",
        county: "Tharaka Nithi",
      },
      {
        label: "Tharaka South District",
        value: "Tharaka South District",
        county: "Tharaka Nithi",
      },
      {
        label: "Kwanza District",
        value: "Kwanza District",
        county: "Trans Nzoia",
      },
      {
        label: "Trans Nzoia East District",
        value: "Trans Nzoia East District",
        county: "Trans Nzoia",
      },
      {
        label: "Trans Nzoia West District",
        value: "Trans Nzoia West District",
        county: "Trans Nzoia",
      },
      {
        label: "Loima District",
        value: "Loima District",
        county: "Turkana",
      },
      {
        label: "Turkana Central District",
        value: "Turkana Central District",
        county: "Turkana",
      },
      {
        label: "Turkana East District",
        value: "Turkana East District",
        county: "Turkana",
      },
      {
        label: "Turkana North District",
        value: "Turkana North District",
        county: "Turkana",
      },
      {
        label: "Turkana South District",
        value: "Turkana South District",
        county: "Turkana",
      },
      {
        label: "Turkana West District",
        value: "Turkana West District",
        county: "Turkana",
      },
      {
        label: "Eldoret East District",
        value: "Eldoret East District",
        county: "Uasin Gishu",
      },
      {
        label: "Eldoret West District",
        value: "Eldoret West District",
        county: "Uasin Gishu",
      },
      {
        label: "Wareng District",
        value: "Wareng District",
        county: "Uasin Gishu",
      },
      {
        label: "Emuhaya District",
        value: "Emuhaya District",
        county: "Vihiga",
      },
      {
        label: "Hamisi District",
        value: "Hamisi District",
        county: "Vihiga",
      },
      {
        label: "Sabatia District",
        value: "Sabatia District",
        county: "Vihiga",
      },
      {
        label: "Vihiga District",
        value: "Vihiga District",
        county: "Vihiga",
      },
      {
        label: "Buna District",
        value: "Buna District",
        county: "Wajir",
      },
      {
        label: "Eldas District",
        value: "Eldas District",
        county: "Wajir",
      },
      {
        label: "Habaswein District",
        value: "Habaswein District",
        county: "Wajir",
      },
      {
        label: "Tarbaj District",
        value: "Tarbaj District",
        county: "Wajir",
      },
      {
        label: "Wajir East District",
        value: "Wajir East District",
        county: "Wajir",
      },
      {
        label: "Wajir North District",
        value: "Wajir North District",
        county: "Wajir",
      },
      {
        label: "Wajir South District",
        value: "Wajir South District",
        county: "Wajir",
      },
      {
        label: "Wajir West District",
        value: "Wajir West District",
        county: "Wajir",
      },
      {
        label: "North Pokot District",
        value: "North Pokot District",
        county: "West Pokot",
      },
      {
        label: "Pokot Central District",
        value: "Pokot Central District",
        county: "West Pokot",
      },
      {
        label: "Pokot South District",
        value: "Pokot South District",
        county: "West Pokot",
      },
      {
        label: "West Pokot District",
        value: "West Pokot District",
        county: "West Pokot",
      },
    ],
    locality: [
      {
        label: "Kabernet",
        value: "Kabernet",
        district: "Baringo Central District",
      },
      {
        label: "Kabartonjo",
        value: "Kabartonjo",
        district: "Baringo North District",
      },
      {
        label: "Eldas",
        value: "Eldas",
        district: "East Pokot District",
      },
      {
        label: "Elnur",
        value: "Elnur",
        district: "East Pokot District",
      },
      {
        label: "Nginyang",
        value: "Nginyang",
        district: "East Pokot District",
      },
      {
        label: "Eldama Ravine",
        value: "Eldama Ravine",
        district: "Koibatek District",
      },
      {
        label: "Marigat",
        value: "Marigat",
        district: "Marigat District",
      },
      {
        label: "Mogotio",
        value: "Mogotio",
        district: "Mogotio District",
      },
      {
        label: "Bumula",
        value: "Bumula",
        district: "Bumula District",
      },
      {
        label: "Bomet",
        value: "Bomet",
        district: "Bomet District",
      },
      {
        label: "Longisa",
        value: "Longisa",
        district: "Bomet District",
      },
      {
        label: "Mulot",
        value: "Mulot",
        district: "Bomet District",
      },
      {
        label: "Sigor in Bomet",
        value: "Sigor in Bomet",
        district: "Chepalungu District",
      },
      {
        label: "Chebilat",
        value: "Chebilat",
        district: "Sotik District",
      },
      {
        label: "Kaplang",
        value: "Kaplang",
        district: "Sotik District",
      },
      {
        label: "Sotik",
        value: "Sotik",
        district: "Sotik District",
      },
      {
        label: "Buluma",
        value: "Buluma",
        district: "Buluma District",
      },
      {
        label: "Chebukaka",
        value: "Chebukaka",
        district: "Bungoma Central District",
      },
      {
        label: "Chebukwa",
        value: "Chebukwa",
        district: "Bungoma Central District",
      },
      {
        label: "Chemwa",
        value: "Chemwa",
        district: "Bungoma Central District",
      },
      {
        label: "Chwele",
        value: "Chwele",
        district: "Bungoma Central District",
      },
      {
        label: "Kabuchai",
        value: "Kabuchai",
        district: "Bungoma Central District",
      },
      {
        label: "Kimukungi",
        value: "Kimukungi",
        district: "Bungoma Central District",
      },
      {
        label: "Kisiwa",
        value: "Kisiwa",
        district: "Bungoma Central District",
      },
      {
        label: "Luucho",
        value: "Luucho",
        district: "Bungoma Central District",
      },
      {
        label: "Mabanga",
        value: "Mabanga",
        district: "Bungoma Central District",
      },
      {
        label: "Malinda",
        value: "Malinda",
        district: "Bungoma Central District",
      },
      {
        label: "Marakaru",
        value: "Marakaru",
        district: "Bungoma Central District",
      },
      {
        label: "Marobo",
        value: "Marobo",
        district: "Bungoma Central District",
      },
      {
        label: "Mukhweya",
        value: "Mukhweya",
        district: "Bungoma Central District",
      },
      {
        label: "Musese",
        value: "Musese",
        district: "Bungoma Central District",
      },
      {
        label: "Muyayi",
        value: "Muyayi",
        district: "Bungoma Central District",
      },
      {
        label: "Nairumbi",
        value: "Nairumbi",
        district: "Bungoma Central District",
      },
      {
        label: "Nalondo",
        value: "Nalondo",
        district: "Bungoma Central District",
      },
      {
        label: "Bilongo",
        value: "Bilongo",
        district: "Bungoma East District",
      },
      {
        label: "Check Point",
        value: "Check Point",
        district: "Bungoma East District",
      },
      {
        label: "Dina",
        value: "Dina",
        district: "Bungoma East District",
      },
      {
        label: "Kakimanyi",
        value: "Kakimanyi",
        district: "Bungoma East District",
      },
      {
        label: "Khalala",
        value: "Khalala",
        district: "Bungoma East District",
      },
      {
        label: "Kituni",
        value: "Kituni",
        district: "Bungoma East District",
      },
      {
        label: "Kuywa",
        value: "Kuywa",
        district: "Bungoma East District",
      },
      {
        label: "Lugulu",
        value: "Lugulu",
        district: "Bungoma East District",
      },
      {
        label: "Lukusi",
        value: "Lukusi",
        district: "Bungoma East District",
      },
      {
        label: "Lutacho",
        value: "Lutacho",
        district: "Bungoma East District",
      },
      {
        label: "Makemo",
        value: "Makemo",
        district: "Bungoma East District",
      },
      {
        label: "Makheso",
        value: "Makheso",
        district: "Bungoma East District",
      },
      {
        label: "Makuma",
        value: "Makuma",
        district: "Bungoma East District",
      },
      {
        label: "Malaha",
        value: "Malaha",
        district: "Bungoma East District",
      },
      {
        label: "Mangana",
        value: "Mangana",
        district: "Bungoma East District",
      },
      {
        label: "Maraka",
        value: "Maraka",
        district: "Bungoma East District",
      },
      {
        label: "Matisi",
        value: "Matisi",
        district: "Bungoma East District",
      },
      {
        label: "Matulo",
        value: "Matulo",
        district: "Bungoma East District",
      },
      {
        label: "Milo",
        value: "Milo",
        district: "Bungoma East District",
      },
      {
        label: "Brigaider",
        value: "Brigaider",
        district: "Bungoma North District",
      },
      {
        label: "Maliki",
        value: "Maliki",
        district: "Bungoma North District",
      },
      {
        label: "Mufupi",
        value: "Mufupi",
        district: "Bungoma North District",
      },
      {
        label: "Mukuyuni",
        value: "Mukuyuni",
        district: "Bungoma North District",
      },
      {
        label: "Naitiri",
        value: "Naitiri",
        district: "Bungoma North District",
      },
      {
        label: "Ndalu",
        value: "Ndalu",
        district: "Bungoma North District",
      },
      {
        label: "Shikhendu",
        value: "Shikhendu",
        district: "Bungoma North District",
      },
      {
        label: "Tongaren",
        value: "Tongaren",
        district: "Bungoma North District",
      },
      {
        label: "Bukembe",
        value: "Bukembe",
        district: "Bungoma South District",
      },
      {
        label: "Bungoma",
        value: "Bungoma",
        district: "Bungoma South District",
      },
      {
        label: "Bungoma CBD",
        value: "Bungoma CBD",
        district: "Bungoma South District",
      },
      {
        label: "Kanduyi",
        value: "Kanduyi",
        district: "Bungoma South District",
      },
      {
        label: "Khaweli",
        value: "Khaweli",
        district: "Bungoma South District",
      },
      {
        label: "Kibabii",
        value: "Kibabii",
        district: "Bungoma South District",
      },
      {
        label: "Kongoli",
        value: "Kongoli",
        district: "Bungoma South District",
      },
      {
        label: "Lutundu",
        value: "Lutundu",
        district: "Bungoma South District",
      },
      {
        label: "Lwanda",
        value: "Lwanda",
        district: "Bungoma South District",
      },
      {
        label: "Mandizini",
        value: "Mandizini",
        district: "Bungoma South District",
      },
      {
        label: "Marrel",
        value: "Marrel",
        district: "Bungoma South District",
      },
      {
        label: "Mashambani",
        value: "Mashambani",
        district: "Bungoma South District",
      },
      {
        label: "Mayanja",
        value: "Mayanja",
        district: "Bungoma South District",
      },
      {
        label: "Mechimero",
        value: "Mechimero",
        district: "Bungoma South District",
      },
      {
        label: "Musikoma",
        value: "Musikoma",
        district: "Bungoma South District",
      },
      {
        label: "Mutomolo",
        value: "Mutomolo",
        district: "Bungoma South District",
      },
      {
        label: "Mwibale",
        value: "Mwibale",
        district: "Bungoma South District",
      },
      {
        label: "Nandola",
        value: "Nandola",
        district: "Bungoma South District",
      },
      {
        label: "Nzoia",
        value: "Nzoia",
        district: "Bungoma South District",
      },
      {
        label: "Lwakhakha",
        value: "Lwakhakha",
        district: "Bungoma West District",
      },
      {
        label: "Malakisi",
        value: "Malakisi",
        district: "Bungoma West District",
      },
      {
        label: "Miluki",
        value: "Miluki",
        district: "Bungoma West District",
      },
      {
        label: "Sango",
        value: "sango",
        district: "Bungoma West District",
      },
      {
        label: "Sirisia",
        value: "Sirisia",
        district: "Bungoma West District",
      },
      {
        label: "Tulienge",
        value: "Tulienge",
        district: "Bungoma West District",
      },
      {
        label: "Cheptais",
        value: "Cheptais",
        district: "Cheptais District",
      },
      {
        label: "Kimilili",
        value: "Kimilili",
        district: "Kimilili District",
      },
      {
        label: "Kapsokwony",
        value: "Kapsokwony",
        district: "Mt Elgon District",
      },
      {
        label: "Kaptama",
        value: "Kaptama",
        district: "Mt Elgon District",
      },
      {
        label: "Kopsiro",
        value: "Kopsiro",
        district: "Mt Elgon District",
      },
      {
        label: "Budalangi",
        value: "Budalangi",
        district: "Bunyala District",
      },
      {
        label: "Magombe",
        value: "Magombe",
        district: "Bunyala District",
      },
      {
        label: "Mau Mau",
        value: "Mau Mau",
        district: "Bunyala District",
      },
      {
        label: "Mundere",
        value: "Mundere",
        district: "Bunyala District",
      },
      {
        label: "Port Victoria",
        value: "Port Victoria",
        district: "Bunyala District",
      },
      {
        label: "Air Strip",
        value: "Air Strip",
        district: "Busia District",
      },
      {
        label: "Customs",
        value: "Customs",
        district: "Busia District",
      },
      {
        label: "Kasarani",
        value: "Kasarani",
        district: "Busia District",
      },
      {
        label: "Korinda",
        value: "Korinda",
        district: "Busia District",
      },
      {
        label: "Sofia",
        value: "Sofia",
        district: "Busia District",
      },
      {
        label: "Bulemia",
        value: "Bulemia",
        district: "Butula District",
      },
      {
        label: "Bumala A",
        value: "Bumala A",
        district: "Butula District",
      },
      {
        label: "Bumala B",
        value: "Bumala B",
        district: "Butula District",
      },
      {
        label: "Elukhari",
        value: "Elukhari",
        district: "Butula District",
      },
      {
        label: "Elukongo",
        value: "Elukongo",
        district: "Butula District",
      },
      {
        label: "Ogalo",
        value: "Ogalo",
        district: "Butula District",
      },
      {
        label: "Tingolo",
        value: "Tingolo",
        district: "Butula District",
      },
      {
        label: "Kisoko",
        value: "Kisoko",
        district: "Nambale District",
      },
      {
        label: "Malanga",
        value: "Malanga",
        district: "Nambale District",
      },
      {
        label: "Nasira",
        value: "Nasira",
        district: "Nambale District",
      },
      {
        label: "Tanga Kona",
        value: "Tanga Kona",
        district: "Nambale District",
      },
      {
        label: "Bukiri",
        value: "Bukiri",
        district: "Samia District",
      },
      {
        label: "Bumbe",
        value: "Bumbe",
        district: "Samia District",
      },
      {
        label: "Funyula",
        value: "Funyula",
        district: "Samia District",
      },
      {
        label: "Ganga",
        value: "Ganga",
        district: "Samia District",
      },
      {
        label: "Hakati",
        value: "Hakati",
        district: "Samia District",
      },
      {
        label: "Lwaunda",
        value: "Lwaunda",
        district: "Samia District",
      },
      {
        label: "Sio Port",
        value: "Sio Port",
        district: "Samia District",
      },
      {
        label: "Amoding",
        value: "Amoding",
        district: "Teso North District",
      },
      {
        label: "Amogoro",
        value: "Amgoro",
        district: "Teso North District",
      },
      {
        label: "Kocholia",
        value: "Kocholia",
        district: "Teso North District",
      },
      {
        label: "Kolanya",
        value: "Kolanya",
        district: "Teso North District",
      },
      {
        label: "Alupe",
        value: "Alupe",
        district: "Teso South District",
      },
      {
        label: "Amukura",
        value: "Amukura",
        district: "Teso South District",
      },
      {
        label: "Chakol",
        value: "Chakol",
        district: "Teso South District",
      },
      {
        label: "Igara",
        value: "Igara",
        district: "Teso South District",
      },
      {
        label: "Iten",
        value: "Iten",
        district: "Keiyo District",
      },
      {
        label: "Chepkorio",
        value: "Chepkorio",
        district: "Keiyo South District",
      },
      {
        label: "Chesoi",
        value: "Chesoi",
        district: "Marakwet East District",
      },
      {
        label: "Kapsowar",
        value: "Kapsowar",
        district: "Marakwet West District",
      },
      {
        label: "Ena",
        value: "Ena",
        district: "Embu East District",
      },
      {
        label: "Kanja",
        value: "Kanja",
        district: "Embu East District",
      },
      {
        label: "Kanyuambora",
        value: "Kanyuambora",
        district: "Embu East District",
      },
      {
        label: "Karurumo",
        value: "Karurumo",
        district: "Embu East District",
      },
      {
        label: "Kathangeri",
        value: "Kathangeri",
        district: "Embu East District",
      },
      {
        label: "Kyeni",
        value: "Kyeni",
        district: "Embu East District",
      },
      {
        label: "Runyenjes",
        value: "Runyenjes",
        district: "Embu East District",
      },
      {
        label: "Kianjokoma",
        value: "Kianjokoma",
        district: "Embu North District",
      },
      {
        label: "Embu Town",
        value: "Embu Town",
        district: "Embu West District",
      },
      {
        label: "Gakwegori",
        value: "Gakwegori",
        district: "Embu West District",
      },
      {
        label: "Kangaru",
        value: "Kangaru",
        district: "Embu West District",
      },
      {
        label: "Kivwe",
        value: "Kivwe",
        district: "Embu West District",
      },
      {
        label: "Mutunduri",
        value: "Mutunduri",
        district: "Embu West District",
      },
      {
        label: "Ishiara",
        value: "Ishiara",
        district: "Mbeere North District",
      },
      {
        label: "Kathangariri",
        value: "Kathangariri",
        district: "Mbeere North District",
      },
      {
        label: "Kiangombe Mbeere",
        value: "Kiangombe Mbeere",
        district: "Mbeere North District",
      },
      {
        label: "Kibugu",
        value: "Kibugu",
        district: "Mbeere North District",
      },
      {
        label: "Manyatta",
        value: "Manyatta",
        district: "Mbeere North District",
      },
      {
        label: "Nthawa",
        value: "Nthawa",
        district: "Mbeere North District",
      },
      {
        label: "Siakago",
        value: "Siakago",
        district: "Mbeere North District",
      },
      {
        label: "Gachoka",
        value: "Gachoka",
        district: "Mbeere South District",
      },
      {
        label: "Karaba",
        value: "Karaba",
        district: "Mbeere South District",
      },
      {
        label: "Kiambere",
        value: "Kiambere",
        district: "Mbeere South District",
      },
      {
        label: "Kiritiri",
        value: "Kiritiri",
        district: "Mbeere South District",
      },
      {
        label: "Makutano Mbeere",
        value: "Makutano Mbeere",
        district: "Mbeere South District",
      },
      {
        label: "Riakanaumakima",
        value: "Riakanaumakima",
        district: "Mbeere South District",
      },
      {
        label: "Bamlamba",
        value: "Balambala",
        district: "Balambala District",
      },
      {
        label: "Danyere",
        value: "Danyere",
        district: "Balambala District",
      },
      {
        label: "Dujis",
        value: "Dujis",
        district: "Balambala District",
      },
      {
        label: "Sankuri",
        value: "Sankuri",
        district: "Balambala District",
      },
      {
        label: "Dadaab",
        value: "Dadaab",
        district: "Dadaab District",
      },
      {
        label: "Dertu",
        value: "Dertu",
        district: "Dadaab District",
      },
      {
        label: "Ifo",
        value: "Ifo",
        district: "Dadaab District",
      },
      {
        label: "Liboi",
        value: "Liboi",
        district: "Dadaab District",
      },
      {
        label: "Alinjugur",
        value: "Alinjugur",
        district: "Fafi District",
      },
      {
        label: "Bura",
        value: "Bura",
        district: "Fafi District",
      },
      {
        label: "Galmagala",
        value: "Galmagala",
        district: "Fafi District",
      },
      {
        label: "Hagardera",
        value: "Hagardera",
        district: "Fafi District",
      },
      {
        label: "Garissa Township",
        value: "Garissa Township",
        district: "Garissa District",
      },
      {
        label: "KoraKora",
        value: "KoraKora",
        district: "Garissa District",
      },
      {
        label: "Hulugho",
        value: "Hulugho",
        district: "Hulugho District",
      },
      {
        label: "Ikabere",
        value: "Ikabere",
        district: "Hulugho District",
      },
      {
        label: "Kolbio",
        value: "Kolbio",
        district: "Hulugho District",
      },
      {
        label: "Ijara",
        value: "Ijara",
        district: "Ijara District",
      },
      {
        label: "Kotile",
        value: "Kotile",
        district: "Ijara District",
      },
      {
        label: "Masalani",
        value: "Masalani",
        district: "Ijara District",
      },
      {
        label: "El Dera",
        value: "El Dera",
        district: "Lagdera District",
      },
      {
        label: "Madogashe",
        value: "Madogashe",
        district: "Lagdera District",
      },
      {
        label: "Tokajo",
        value: "Tokajo",
        district: "Lagdera District",
      },
      {
        label: "Asumbi",
        value: "Asumbi",
        district: "Homa Bay District",
      },
      {
        label: "Homa Bay",
        value: "Homa Bay",
        district: "Homa Bay District",
      },
      {
        label: "Imbo",
        value: "Imbo",
        district: "Homa Bay District",
      },
      {
        label: "Nyangweso",
        value: "Nyangweso",
        district: "Homa Bay District",
      },
      {
        label: "Olare",
        value: "Olare",
        district: "Homa Bay District",
      },
      {
        label: "Rangwe",
        value: "Rangwe",
        district: "Homa Bay District",
      },
      {
        label: "Rodi",
        value: "Rodi",
        district: "Homa Bay District",
      },
      {
        label: "Mbita",
        value: "Mbita",
        district: "Mbita District",
      },
      {
        label: "Miranga",
        value: "Miranga",
        district: "Ndhiwa District",
      },
      {
        label: "Migori",
        value: "Migori",
        district: "Ndhiwa District",
      },
      {
        label: "Ndihiwa",
        value: "Ndihiwa",
        district: "Ndhiwa District",
      },
      {
        label: "Okok",
        value: "Okok",
        district: "Ndhiwa District",
      },
      {
        label: "Rantanga",
        value: "Rantanga",
        district: "Ndhiwa District",
      },
      {
        label: "Chabera",
        value: "Chabera",
        district: "Rachuonyo North District",
      },
      {
        label: "Kadel",
        value: "Kadel",
        district: "Rachuonyo North District",
      },
      {
        label: "Kadongo",
        value: "Kadongo",
        district: "Rachuonyo North District",
      },
      {
        label: "Kendu Bay",
        value: "Kendu Bay",
        district: "Rachuonyo North District",
      },
      {
        label: "Kosele",
        value: "Kosele",
        district: "Rachuonyo North District",
      },
      {
        label: "Oyugis",
        value: "Oyugis",
        district: "Rachuonyo North District",
      },
      {
        label: "Rachuonyo North",
        value: "Rachuonyo North",
        district: "Rachuonyo North District",
      },
      {
        label: "Ringa",
        value: "Ringa",
        district: "Rachuonyo North District",
      },
      {
        label: "Sikri",
        value: "Sikri",
        district: "Rachuonyo North District",
      },
      {
        label: "Kosele",
        value: "Kosele",
        district: "Rachuonyo South District",
      },
      {
        label: "Magunga",
        value: "Magunga",
        district: "Rachuonyo South District",
      },
      {
        label: "Rachuonyo South",
        value: "rachuonyo South",
        district: "Rachuonyo South District",
      },
      {
        label: "Luanda Suba",
        value: "Luanda Suba",
        district: "Suba District",
      },
      {
        label: "Ogongo",
        value: "Ogongo",
        district: "Suba District",
      },
      {
        label: "Remba",
        value: "Remba",
        district: "Suba District",
      },
      {
        label: "Rusinga",
        value: "Rusinga",
        district: "Suba District",
      },
      {
        label: "Sindo",
        value: "Sindo",
        district: "Suba District",
      },
      {
        label: "Suba",
        value: "Suba",
        district: "Suba District",
      },
      {
        label: "Garbatula",
        value: "Garbatula",
        district: "Garbatula District",
      },
      {
        label: "Garbatulla",
        value: "Garbatulla",
        district: "Garbatulla District",
      },
      {
        label: "BBC",
        value: "BBC",
        district: "Isiolo District",
      },
      {
        label: "Isiolo",
        value: "Isiolo",
        district: "Isiolo District",
      },
      {
        label: "Subuiga",
        value: "Subuiga",
        district: "Isiolo District",
      },
      {
        label: "Merti",
        value: "Merti",
        district: "Merti District",
      },
      {
        label: "Isinya",
        value: "Isinya",
        district: "Isinya District",
      },
      {
        label: "Ilbisil",
        value: "Ilbisil",
        district: "Kajiado Central District",
      },
      {
        label: "Kajiado Town",
        value: "Kajiado Town",
        district: "Kajiado Central District",
      },
      {
        label: "Kumpa",
        value: "Kumpa",
        district: "Kajiado Central District",
      },
      {
        label: "Maili Tisa",
        value: "Maili Tisa",
        district: "Kajiado Central District",
      },
      {
        label: "Namanga",
        value: "Namanga",
        district: "Kajiado Central District",
      },
      {
        label: "Isinya",
        value: "isinya",
        district: "Kajiado East District",
      },
      {
        label: "Kisaju",
        value: "Kisaju",
        district: "Kajiado East District",
      },
      {
        label: "Kitengela",
        value: "Kitengela",
        district: "Kajiado East District",
      },
      {
        label: "Mashuru",
        value: "Mashuru",
        district: "Kajiado East District",
      },
      {
        label: "Ngong",
        value: "Ngong",
        district: "Kajiado East District",
      },
      {
        label: "Oloolotikoshi",
        value: "Oloolotikoshi",
        district: "Kajiado East District",
      },
      {
        label: "Kiserian",
        value: "Kiserian",
        district: "Kajiado North District",
      },
      {
        label: "Ongata Rongai",
        value: "Ongata Rongai",
        district: "Kajiado North District",
      },
      {
        label: "Isara",
        value: "Isara",
        district: "Kajiado South District",
      },
      {
        label: "Kimana",
        value: "Kimana",
        district: "Kajiado South District",
      },
      {
        label: "Loitoktok",
        value: "Loitoktok",
        district: "Kajiado South District",
      },
      {
        label: "Mbilini",
        value: "Mbilini",
        district: "Kajiado South District",
      },
      {
        label: "Rombo",
        value: "Rombo",
        district: "Kajiado South District",
      },
      {
        label: "Ewaso Kedong",
        value: "Ewaso Kedong",
        district: "Kajiado West District",
      },
      {
        label: "Kamukuru",
        value: "Kamukuru",
        district: "Kajiado West District",
      },
      {
        label: "Kisames",
        value: "Kisames",
        district: "Kajiado West District",
      },
      {
        label: "Magadi",
        value: "Magadi",
        district: "Kajiado West District",
      },
      {
        label: "Ngurumani",
        value: "Ngurumani",
        district: "Kajiado West District",
      },
      {
        label: "Oltepesi",
        value: "Oltepesi",
        district: "Kajiado West District",
      },
      {
        label: "Tinga",
        value: "Tinga",
        district: "Kajiado West District",
      },
      {
        label: "Loitoktok",
        value: "Loitoktok",
        district: "Loitoktok District",
      },
      {
        label: "Mashuru",
        value: "Mashuru",
        district: "Mashuru District",
      },
      {
        label: "Butere Town",
        value: "Butere Town",
        district: "Butere District",
      },
      {
        label: "Khumusalaba",
        value: "Khumusalaba",
        district: "Butere District",
      },
      {
        label: "Khiwisero",
        value: "Khiwisero",
        district: "Butere District",
      },
      {
        label: "Manyulia",
        value: "Manyulia",
        district: "Butere District",
      },
      {
        label: "Mulwanda",
        value: "Mulwanda",
        district: "Butere District",
      },
      {
        label: "Sabatia Junction",
        value: "Sabatia Junction",
        district: "Butere District",
      },
      {
        label: "Kakamega Town",
        value: "Kakamega Town",
        district: "Kakamega Central District",
      },
      {
        label: "Lubao",
        value: "Lubao",
        district: "Kakamega Central District",
      },
      {
        label: "Lurambi Amalemba",
        value: "Lurambi Amalemba",
        district: "Kakamega Central District",
      },
      {
        label: "Mwiyala",
        value: "Mwiyala",
        district: "Kakamega Central District",
      },
      {
        label: "Shinyalu",
        value: "Shinyalu",
        district: "Kakamega East District",
      },
      {
        label: "Kakamenga East",
        value: "Kakamega East",
        district: "Kakamega East District",
      },
      {
        label: "Butali",
        value: "Butali",
        district: "Kakamega North District",
      },
      {
        label: "Kakunga",
        value: "kakunga",
        district: "Kakamega North District",
      },
      {
        label: "Malava Town",
        value: "Malava Town",
        district: "Kakamega North District",
      },
      {
        label: "Matete",
        value: "Matete",
        district: "Kakamega North District",
      },
      {
        label: "West Kenya",
        value: "West Kenya",
        district: "Kakamenga North District",
      },
      {
        label: "Bukura",
        value: "Bukura",
        district: "Kakamega South District",
      },
      {
        label: "Eregi",
        value: "Eregi",
        district: "Kakamega South District",
      },
      {
        label: "Khayega",
        value: "Khayega",
        district: "Kakamega South District",
      },
      {
        label: "Malinya",
        value: "Malinya",
        district: "Kakamega South District",
      },
      {
        label: "Musoli",
        value: "Musoli",
        district: "Kakamega South District",
      },
      {
        label: "Sigalagala",
        value: "Sigalagala",
        district: "Kakamega South District",
      },
      {
        label: "Sigalagala Junction",
        value: "Sigalagala Junction",
        district: "Kakamega South District",
      },
      {
        label: "Khwisero",
        value: "Khwisero",
        district: "Khwisero District",
      },
      {
        label: "Kongoni",
        value: "Kongoni",
        district: "Likuyani District",
      },
      {
        label: "Chimoi",
        value: "Chimoi",
        district: "Lugari District",
      },
      {
        label: "Kaburengo",
        value: "Kaburengo",
        district: "Lugari District",
      },
      {
        label: "Kipkaren River",
        value: "Kipkaren River",
        district: "Lugari District",
      },
      {
        label: "Luandeti",
        value: "Luandeti",
        district: "Lugari District",
      },
      {
        label: "Lumakanda",
        value: "Lumakanda",
        district: "Lugari District",
      },
      {
        label: "Vihiga County",
        value: "Vihiga County",
        district: "Lugari District",
      },
      {
        label: "Matete",
        value: "Matete",
        district: "Matete District",
      },
      {
        label: "Matungu",
        value: "Matungu",
        district: "Matungu District",
      },
      {
        label: "Ekero",
        value: "Ekero",
        district: "Mumias District",
      },
      {
        label: "Harambe",
        value: "Harambe",
        district: "Mumias District",
      },
      {
        label: "Lukoye",
        value: "Lukoye",
        district: "Mumias District",
      },
      {
        label: "Makunga",
        value: "Makunga",
        district: "Mumias District",
      },
      {
        label: "Matungu",
        value: "Matungu",
        district: "Mumias District",
      },
      {
        label: "Mayoni",
        value: "Mayoni",
        district: "Mumias District",
      },
      {
        label: "Mumias Town",
        value: "Mumias Town",
        district: "Mumias District",
      },
      {
        label: "Shianda",
        value: "Shianda",
        district: "Mumias District",
      },
      {
        label: "Shibale",
        value: "Shibale",
        district: "Mumias District",
      },
      {
        label: "Sosiot",
        value: "Sosiot",
        district: "Belgut District",
      },
      {
        label: "Kapkatet",
        value: "Kapkatet",
        district: "Buret District",
      },
      {
        label: "Litein",
        value: "Litein",
        district: "Buret District",
      },
      {
        label: "Loret",
        value: "Loret",
        district: "Buret District",
      },
      {
        label: "Roret",
        value: "Roret",
        district: "Buret District",
      },
      {
        label: "Bureti District",
        value: "Bureti District",
        district: "Bureti District",
      },

      {
        label: "Brooke",
        value: "Brooke",
        district: "Kericho District",
      },
      {
        label: "Kapsoit",
        value: "Kapsoit",
        district: "Kericho District",
      },
      {
        label: "Kapsuser",
        value: "Kapsuser",
        district: "Kericho District",
      },
      {
        label: "Kericho Municipality",
        value: "Kericho Municipality",
        district: "Kericho District",
      },
      {
        label: "Chepsion",
        value: "Chepsion",
        district: "Kipkelion District",
      },
      {
        label: "Kedowa",
        value: "Kedowa",
        district: "Kipkelion District",
      },
      {
        label: "Kipkelion",
        value: "Kipkelion",
        district: "Kipkelion District",
      },
      {
        label: "Mogogosiek",
        value: "Mogogosiek",
        district: "Konoin District",
      },
      {
        label: "Londiani",
        value: "Londiani",
        district: "Londiani District",
      },
      {
        label: "Gakoe",
        value: "Gakoe",
        district: "Gatundu District",
      },
      {
        label: "Gatukuyu",
        value: "Gatukuyu",
        district: "Kipkelion District",
      },
      {
        label: "Gatundu",
        value: "Gatundu",
        district: "Kiambu District",
      },
      {
        label: "Kamwangi",
        value: "Kamwangi",
        district: "Kiambu District",
      },
      {
        label: "Mutumo Gatundu",
        value: "Mutumo Gatundu",
        district: "Kiambu District",
      },
      {
        label: "Wamwangi",
        value: "Wamwangi",
        district: "Kiambu District",
      },
      {
        label: "Gakoe",
        value: "Gakoe",
        district: "Gatundu North District",
      },
      {
        label: "Gatukuyu",
        value: "Gatukuyu",
        district: "Gatundu North District",
      },
      {
        label: "Kamwangi",
        value: "Kamwangi",
        district: "Gatundu North District",
      },
      {
        label: "Githunguri",
        value: "Githunguri",
        district: "Githunguri District",
      },
      {
        label: "Kagwe",
        value: "Kagwe",
        district: "Githunguri North District",
      },
      {
        label: "Kamwangi",
        value: "Kamwangi",
        district: "Githunguri North District",
      },
      {
        label: "Gitaru",
        value: "Gitaru",
        district: "Kabete District",
      },
      {
        label: "Kahatia",
        value: "kahatia",
        district: "Kabete District",
      },
      {
        label: "Kahuro",
        value: "Kahuro",
        district: "Kabete District",
      },
      {
        label: "Kikuyu",
        value: "Kikuyu",
        district: "Kabete District",
      },
      {
        label: "Kinoo",
        value: "Kinoo",
        district: "Kabete District",
      },
      {
        label: "Murarandia",
        value: "Murarandia",
        district: "Kabete District",
      },
      {
        label: "Ndederu",
        value: "Ndederu",
        district: "Kabete District",
      },
      {
        label: "Ndumbuini",
        value: "Ndumbuini",
        district: "Kabete District",
      },
      {
        label: "Sigona",
        value: "Sigona",
        district: "Kabete District",
      },
      {
        label: "Uthiru",
        value: "Uthiru",
        district: "Kabete District",
      },
      {
        label: "Wangige",
        value: "Wangige",
        district: "Kabete District",
      },
      {
        label: "Kiambaa",
        value: "Kiambaa",
        district: "Kiambaa District",
      },
      {
        label: "Banana",
        value: "Banana",
        district: "Kiambu District",
      },
      {
        label: "Gachie",
        value: "Gachie",
        district: "Kiambu District",
      },
      {
        label: "Githiga",
        value: "Githiga",
        district: "Kiambu District",
      },
      {
        label: "Ikinu",
        value: "Ikinu",
        district: "Kiambu District",
      },
      {
        label: "Karuri",
        value: "Karuri",
        district: "Kiambu District",
      },
      {
        label: "Kiambu",
        value: "Kiambu",
        district: "Kiambu District",
      },
      {
        label: "Muchatha",
        value: "Muchatha",
        district: "Kiambu District",
      },
      {
        label: "Ndumberi",
        value: "Ndumberi",
        district: "Kiambu District",
      },
      {
        label: "Ruaka",
        value: "Ruaka",
        district: "Kiambu District",
      },
      {
        label: "Banana",
        value: "Banana",
        district: "Kiambu East District",
      },
      {
        label: "Gachie",
        value: "Gachie",
        district: "Kiambu East District",
      },
      {
        label: "Ikinu",
        value: "Ikinu",
        district: "Kiambu East District",
      },
      {
        label: "Muchatha",
        value: "Muchatha",
        district: "Kiambu East District",
      },
      {
        label: "Ndumberi",
        value: "Ndumberi",
        district: "Kiambu East District",
      },
      {
        label: "Ruaka",
        value: "Ruaka",
        district: "Kiambu East District",
      },
      {
        label: "Kijabe",
        value: "Kijabe",
        district: "Kiambu West District",
      },
      {
        label: "Kimende",
        value: "Kimende",
        district: "Kiambu West District",
      },
      {
        label: "Limuru",
        value: "Limuru",
        district: "Kiambu West District",
      },
      {
        label: "Uplands",
        value: "Uplands",
        district: "Kiambu West District",
      },
      {
        label: "Lari",
        value: "Lari",
        district: "Lari District",
      },
      {
        label: "Kijabe",
        value: "Kijabe",
        district: "Limuru District",
      },
      {
        label: "Kimende",
        value: "Kimende",
        district: "Limuru District",
      },
      {
        label: "Limuru",
        value: "Limuru",
        district: "Limuru District",
      },
      {
        label: "Uplands",
        value: "Uplands",
        district: "Limuru District",
      },
      {
        label: "Kijabe",
        value: "Kijabe",
        district: "Kiambu West District",
      },
      {
        label: "Kimende",
        value: "Kimende",
        district: "Kiambu West District",
      },
      {
        label: "Limuru",
        value: "Limuru",
        district: "Kiambu West District",
      },
      {
        label: "Uplands",
        value: "Uplands",
        district: "Kiambu West District",
      },
      {
        label: "Githurai",
        value: "Githurai",
        district: "Ruiru District",
      },
      {
        label: "Githurai Kimbo",
        value: "Githurai Kimbo",
        district: "Ruiru District",
      },
      {
        label: "Kahawa",
        value: "Kahawa",
        district: "Ruiru District",
      },
      {
        label: "Kahawa Sukari",
        value: "Kahawa Sukari",
        district: "Ruiru District",
      },
      {
        label: "Kahawa Wendani",
        value: "Kahawa Wendani",
        district: "Ruiru District",
      },
      {
        label: "Membley",
        value: "Membley",
        district: "Ruiru District",
      },
      {
        label: "Sukari",
        value: "Sukari",
        district: "Ruiru District",
      },
      {
        label: "Toll",
        value: "Toll",
        district: "Ruiru District",
      },
      {
        label: "Wendani",
        value: "Wendani",
        district: "Ruiru District",
      },
      {
        label: "Gatuanyaga",
        value: "Gatuanyaga",
        district: "Thika East District",
      },
      {
        label: "Makutano",
        value: "Makutano",
        district: "Thika East District",
      },
      {
        label: "Juja",
        value: "Juja",
        district: "Thika West District",
      },
      {
        label: "Ndarugo",
        value: "Ndarugo",
        district: "Thika West District",
      },
      {
        label: "Thika",
        value: "Thika",
        district: "Thika West District",
      },
      {
        label: "Witeithie",
        value: "Witeithie",
        district: "Thika West District",
      },
      {
        label: "Bamba",
        value: "Bamba",
        district: "Ganze District",
      },
      {
        label: "Ganze",
        value: "Ganze",
        district: "Ganze District",
      },
      {
        label: "Kaloleni",
        value: "Kaloleni",
        district: "Kaloleni District",
      },
      {
        label: "Mariakani",
        value: "Mariakani",
        district: "Kaloleni District",
      },
      {
        label: "Mazeras",
        value: "Mazeras",
        district: "Kaloleni District",
      },
      {
        label: "Chumani",
        value: "Chumani",
        district: "Kilifi District",
      },
      {
        label: "Kanamai",
        value: "Kanamai",
        district: "Kilifi District",
      },
      {
        label: "Kikambala",
        value: "Kikambala",
        district: "Kilifi District",
      },
      {
        label: "Kilifi",
        value: "Kilifi",
        district: "Kilifi District",
      },
      {
        label: "Matsangoni",
        value: "Matsangoni",
        district: "Kilifi District",
      },
      {
        label: "Mnarani",
        value: "Mnarani",
        district: "Kilifi District",
      },
      {
        label: "Mtwapa",
        value: "Mtawapa",
        district: "Kilifi District",
      },
      {
        label: "Takaungu",
        value: "Takaungu",
        district: "Kilifi District",
      },
      {
        label: "Tezo",
        value: "Tezo",
        district: "Kilifi District",
      },
      {
        label: "Vipingo",
        value: "Vipingo",
        district: "Kilifi District",
      },
      {
        label: "Marafa",
        value: "Marafa",
        district: "Magarini District",
      },
      {
        label: "Gede",
        value: "Gede",
        district: "Malindi District",
      },
      {
        label: "Gongoni",
        value: "Gongoni",
        district: "Malindi District",
      },
      {
        label: "Mambrui",
        value: "Mambrui",
        district: "Malindi District",
      },
      {
        label: "Marereni",
        value: "Marereni",
        district: "Malindi District",
      },
      {
        label: "Watamu",
        value: "Watamu",
        district: "Malindi District",
      },
      {
        label: "Rabai",
        value: "Rabai",
        district: "Rabai District",
      },
      {
        label: "Kangaita",
        value: "Kangaita",
        district: "Kirinyaga Central District",
      },
      {
        label: "Kerugoya",
        value: "Kerugoya",
        district: "Kirinyaga Central District",
      },
      {
        label: "Kiamuruga",
        value: "Kiamuruga",
        district: "Kirinyaga Central District",
      },
      {
        label: "Kiaritha",
        value: "Kiaritha",
        district: "Kirinyaga Central District",
      },
      {
        label: "Ndima",
        value: "Ndima",
        district: "Kirinyaga Central District",
      },
      {
        label: "Gatugura",
        value: "Gatugura",
        district: "Kirinyaga East District",
      },
      {
        label: "Gichugu",
        value: "Gichugu",
        district: "Kirinyaga East District",
      },
      {
        label: "Kamweti",
        value: "Kamweti",
        district: "Kirinyaga East District",
      },
      {
        label: "Kiamutugu",
        value: "Kiamutugu",
        district: "Kirinyaga East District",
      },
      {
        label: "Kianyaga",
        value: "Kianyaga",
        district: "Kirinyaga East District",
      },
      {
        label: "Kimunye",
        value: "Kimunye",
        district: "Kirinyaga East District",
      },
      {
        label: "Kutus",
        value: "Kutus",
        district: "Kirinyaga East District",
      },
      {
        label: "Mururi",
        value: "Mururi",
        district: "Kirinyaga East District",
      },
      {
        label: "Pia",
        value: "Pia",
        district: "Kirinyaga East District",
      },
      {
        label: "Kandongu",
        value: "Kandongu",
        district: "Kirinyaga North District",
      },
      {
        label: "Kimbibi",
        value: "Kimbibi",
        district: "Kirinyaga South District",
      },
      {
        label: "Mutithi",
        value: "Mutithi",
        district: "Kirinyaga South District",
      },
      {
        label: "Nguka",
        value: "Nguka",
        district: "Kirinyaga South District",
      },
      {
        label: "Wamumu",
        value: "Wamumu",
        district: "Kirinyaga South District",
      },
      {
        label: "Wanguru",
        value: "Wanguru",
        district: "Kirinyaga South District",
      },
      {
        label: "Baricho",
        value: "Baricho",
        district: "Kirinyaga West District",
      },
      {
        label: "Kagio",
        value: "Kagio",
        district: "Kirinyaga West District",
      },
      {
        label: "Kiangai",
        value: "Kiangai",
        district: "Kirinyaga West District",
      },
      {
        label: "Kiangombe",
        value: "Kiangombe",
        district: "Kirinyaga West District",
      },
      {
        label: "Kiangwaa",
        value: "Kiangwaa",
        district: "Kirinyaga West District",
      },
      {
        label: "Kibigoti",
        value: "Kibigoti",
        district: "Kirinyaga West District",
      },
      {
        label: "Makutano Town",
        value: "Makutano Town",
        district: "Kirinyaga West District",
      },
      {
        label: "Sagana",
        value: "Sagana",
        district: "Kirinyaga West District",
      },
      {
        label: "Gucha North",
        value: "Gucha North",
        district: "Gucha North District",
      },
      {
        label: "Igare",
        value: "Igare",
        district: "Gucha North District",
      },
      {
        label: "Itumbe",
        value: "Itumbe",
        district: "Gucha North District",
      },
      {
        label: "Mogonga",
        value: "Mogonga",
        district: "Gucha North District",
      },
      {
        label: "Ogembo",
        value: "Ogembo",
        district: "Gucha North District",
      },
      {
        label: "Bosinange",
        value: "Bosinange",
        district: "Gucha South District",
      },
      {
        label: "Gucha South",
        value: "Gucha South",
        district: "Gucha South District",
      },
      {
        label: "Nyamarambe",
        value: "Nyamarambe",
        district: "Gucha South District",
      },
      {
        label: "Nyaramba",
        value: "Nyaramba",
        district: "Gucha South District",
      },
      {
        label: "Riosiri",
        value: "Riosiri",
        district: "Gucha South District",
      },
      {
        label: "Tabaka",
        value: "Tabaka",
        district: "Gucha South District",
      },
      {
        label: "Kanyanya ",
        value: "Kanyanya ",
        district: "Kenyenya District",
      },
      {
        label: "Kenyenya ",
        value: "Kenyenya ",
        district: "Kenyenya District",
      },
      {
        label: "Bobaracho",
        value: "Bobaracho",
        district: "Kisii Central District",
      },
      {
        label: "Darajambili",
        value: "Darajambili",
        district: "Kisii Central District",
      },
      {
        label: "Gesonso",
        value: "Gesonso",
        district: "Kisii Central District",
      },
      {
        label: "Getare",
        value: "Getare",
        district: "Kisii Central District",
      },
      {
        label: "Jogoo",
        value: "Jogoo",
        district: "Kisii Central District",
      },
      {
        label: "Keumbo",
        value: "Keumbo",
        district: "Kisii Central District",
      },
      {
        label: "Kisii Central",
        value: "Kisii Central",
        district: "Kisii Central District",
      },
      {
        label: "Kisii Township",
        value: "Kisii Township",
        district: "Kisii Central District",
      },
      {
        label: "Mogonchoro",
        value: "Mogonchoro",
        district: "Kisii Central District",
      },
      {
        label: "Mosocho",
        value: "Mosocho",
        district: "Kisii Central District",
      },
      {
        label: "Mwembe",
        value: "Mwembe",
        district: "Kisii Central District",
      },
      {
        label: "Nyakoe",
        value: "Nyakoe",
        district: "Kisii Central District",
      },
      {
        label: "Nyanchwa",
        value: "Nyanchwa",
        district: "Kisii Central District",
      },
      {
        label: "Kerina",
        value: "Kerina",
        district: "Kisii South District",
      },
      {
        label: "Kisii South",
        value: "Kisii South",
        district: "Kisii South District",
      },
      {
        label: "Nyabigege",
        value: "Nyabigege",
        district: "Kisii South District",
      },
      {
        label: "Nyachenge",
        value: "Nyachenge",
        district: "Kisii South District",
      },
      {
        label: "Suneka",
        value: "Suneka",
        district: "Kisii South District",
      },
      {
        label: "Kegogi",
        value: "Kegogi",
        district: "Marani District",
      },
      {
        label: "Kemera",
        value: "Kemera",
        district: "Marani District",
      },
      {
        label: "Marani",
        value: "Marani",
        district: "Marani District",
      },
      {
        label: "Menyinkwa",
        value: "Menyinkwa",
        district: "Marani District",
      },
      {
        label: "Gesusu",
        value: "Gesusu",
        district: "Masaba District",
      },
      {
        label: "Kaimokama",
        value: "Kaimokama",
        district: "Masaba District",
      },
      {
        label: "Masaba",
        value: "Masaba",
        district: "Masaba District",
      },
      {
        label: "Masimba",
        value: "Masimba",
        district: "Masaba District",
      },
      {
        label: "Nyacheki",
        value: "Nyacheki",
        district: "Masaba District",
      },
      {
        label: "Nyangusu",
        value: "Nyangusu",
        district: "Masaba District",
      },
      {
        label: "Keroka",
        value: "Keroka",
        district: "North Masaba District",
      },
      {
        label: "North Masaba",
        value: "North Masaba",
        district: "North Masaba District",
      },
      {
        label: "Magina",
        value: "Magina",
        district: "Nyamache District",
      },
      {
        label: "Nyamache",
        value: "Nyamache",
        district: "Nyamache District",
      },
      {
        label: "Sameta",
        value: "Sameta",
        district: "Sameta District",
      },
      {
        label: "Kachok",
        value: "Kachok",
        district: "Kisumu East District",
      },
      {
        label: "Kibos",
        value: "Kibos",
        district: "Kisumu East District",
      },
      {
        label: "Kisumu CBD",
        value: "Kisumu CBD",
        district: "Kisumu East District",
      },
      {
        label: "Kisumu East",
        value: "Kisumu East",
        district: "Kisumu East District",
      },
      {
        label: "Kondele",
        value: "Kondele",
        district: "Kisumu East District",
      },
      {
        label: "Mamboleo",
        value: "Mamboleo",
        district: "Kisumu East District",
      },
      {
        label: "Nyalenda",
        value: "Nyalenda",
        district: "Kisumu East District",
      },
      {
        label: "Nyamasaria",
        value: "Nyamasaria",
        district: "Kisumu East District",
      },
      {
        label: "Rabuor",
        value: "Rabuor",
        district: "Kisumu East District",
      },
      {
        label: "Chulaimbo",
        value: "Chulaimbo",
        district: "Kisumu Town West District",
      },
      {
        label: "Daraja Mbili",
        value: "Daraja Mbili",
        district: "Kisumu Town West District",
      },
      {
        label: "Kisian",
        value: "Kisian",
        district: "Kisumu Town West District",
      },
      {
        label: "Maseno",
        value: "Maseno",
        district: "Kisumu Town West District",
      },
      {
        label: "Ojola",
        value: "Ojola",
        district: "Kisumu Town West District",
      },
      {
        label: "Ontonglo",
        value: "Ontonglo",
        district: "Kisumu Town West District",
      },
      {
        label: "Paw Akuche",
        value: "Paw Akuche",
        district: "Kisumu Town West District",
      },
      {
        label: "Kiboswa",
        value: "Kiboswa",
        district: "Kisumu West District",
      },
      {
        label: "Kisumu West",
        value: "Kisumu West",
        district: "Kisumu West District",
      },
      {
        label: "Nyahera",
        value: "Nyahera",
        district: "Kisumu West District",
      },
      {
        label: "Riat",
        value: "Riat",
        district: "Kisumu West District",
      },
      {
        label: "Chemilil",
        value: "Chemilil",
        district: "Muhoroni District",
      },
      {
        label: "Muhoroni",
        value: "Muhoroni",
        district: "Muhoroni District",
      },
      {
        label: "Harambee",
        value: "harambee",
        district: "Nyakach District",
      },
      {
        label: "Katito",
        value: "Katito",
        district: "Nyakach District",
      },
      {
        label: "Kolweny",
        value: "Kolweny",
        district: "Nyakach District",
      },
      {
        label: "Nyakach",
        value: "Nyakach",
        district: "Nyakach District",
      },
      {
        label: "Nyakwere",
        value: "Nyakwere",
        district: "Nyakach District",
      },
      {
        label: "Pap Onditi",
        value: "Pap Onditi",
        district: "Nyakach District",
      },
      {
        label: "Sondu",
        value: "Sondu",
        district: "Nyakach District",
      },
      {
        label: "Ahero",
        value: "Ahero",
        district: "Nyando District",
      },
      {
        label: "Awasi",
        value: "Awasi",
        district: "Nyakach District",
      },
      {
        label: "Nyando",
        value: "Nyando",
        district: "Nyakach District",
      },
      {
        label: "Ikutha",
        value: "Ikutha",
        district: "Ikutha District",
      },
      {
        label: "Katulani",
        value: "Katulani",
        district: "Katulani District",
      },
      {
        label: "Kisasi",
        value: "Kisasi",
        district: "Kisasi District",
      },
      {
        label: "Kitui",
        value: "Kitui",
        district: "Kitui Central District",
      },
      {
        label: "Kabati Kitui",
        value: "Kabati Kitui",
        district: "Kitui West District",
      },
      {
        label: "Kitui West",
        value: "Kitui West",
        district: "Kitui West District",
      },
      {
        label: "Katse",
        value: "Katse",
        district: "Kyuso District",
      },
      {
        label: "Kamuongo",
        value: "Kamuongo",
        district: "Kyuso District",
      },
      {
        label: "Kimangao",
        value: "Kimangao",
        district: "Kyuso District",
      },
      {
        label: "Kyuso",
        value: "Kyuso",
        district: "Kyuso District",
      },
      {
        label: "Lafey",
        value: "Lafey",
        district: "Kyuso District",
      },
      {
        label: "Waita",
        value: "Waita",
        district: "Kyuso District",
      },
      {
        label: "Kyusyani",
        value: "Kyusyani",
        district: "Lower Yatta District",
      },
      {
        label: "Matinyani",
        value: "Matinyani",
        district: "Matinyani District",
      },
      {
        label: "Mumoni",
        value: "Mumoni",
        district: "Mumoni District",
      },
      {
        label: "Nzombe",
        value: "Nzombe",
        district: "Mutitu District",
      },
      {
        label: "Mutitu",
        value: "Mutitu",
        district: "Mutitu District",
      },
      {
        label: "Mutomo",
        value: "Mutomo",
        district: "Mutomo District",
      },
      {
        label: "Mwingi",
        value: "Mwingi",
        district: "Mwingi District",
      },
      {
        label: "Ngomeni",
        value: "Ngomeni",
        district: "Mwingi Central",
      },
      {
        label: "Nguni",
        value: "Nguni",
        district: "Mwingi Central",
      },
      {
        label: "Ngutani",
        value: "Ngutani",
        district: "Mwingi Central",
      },
      {
        label: "Nuu",
        value: "Nuu",
        district: "Mwingi Central",
      },
      {
        label: "Ukasi",
        value: "Ukasi",
        district: "Mwingi Central",
      },

      {
        label: "Mathuki",
        value: "Mathuki",
        district: "Mwingi East District",
      },
      {
        label: "Migwani",
        value: "Migwani",
        district: "Mwingi West District",
      },
      {
        label: "Chuluni",
        value: "Chuluni",
        district: "Nzambani District",
      },
      {
        label: "Tseikuru",
        value: "Tseikuru",
        district: "Tseikuru District",
      },
      {
        label: "Kinango",
        value: "Kinango",
        district: "Kinango District",
      },
      {
        label: "Makinon",
        value: "Makinon",
        district: "Kinango District",
      },
      {
        label: "Samburu",
        value: "Samburu",
        district: "Kinango District",
      },
      {
        label: "Taru",
        value: "Taru",
        district: "Kinango District",
      },
      {
        label: "Diani",
        value: "Diani",
        district: "Kwale District",
      },
      {
        label: "Kwale",
        value: "Kwale",
        district: "Kwale  District",
      },
      {
        label: "Matuga",
        value: "Matuga",
        district: "Matuga District",
      },
      {
        label: "Msabweni",
        value: "Msabweni",
        district: "Msabweni District",
      },
      {
        label: "Lunga Lunga",
        value: "Lunga Lunga",
        district: "Msambweni District",
      },
      {
        label: "Msambweni",
        value: "Msambweni",
        district: "Msambweni District",
      },
      {
        label: "Shimoni",
        value: "Shimoni",
        district: "Msambweni District",
      },
      {
        label: "Ukunda",
        value: "Ukunda",
        district: "Msambweni District",
      },
      {
        label: "Vanga",
        value: "Vanga",
        district: "Msambweni District",
      },
      {
        label: "Laikipia Central",
        value: "Laikipia Central",
        district: "Laikipia Central District",
      },
      {
        label: "Narumoru  Town",
        value: "Narumoru Town",
        district: "Laikipia Central District",
      },
      {
        label: "Sirima",
        value: "Sirima",
        district: "Laikipia Central District",
      },
      {
        label: "Burguret",
        value: "Burguret",
        district: "Laikipia East District",
      },
      {
        label: "Nanyuki",
        value: "Nanyuki",
        district: "Laikipia East District",
      },
      {
        label: "Doldol",
        value: "Doldol",
        district: "Laikipia North District",
      },
      {
        label: "Mukogodo",
        value: "Mukogodo",
        district: "Laikipia North District",
      },
      {
        label: "Kinamba",
        value: "Kinamba",
        district: "Laikipia West District",
      },
      {
        label: "Marmanet",
        value: "Marmanet",
        district: "Laikipia West District",
      },
      {
        label: "Ngarua",
        value: "Ngarua",
        district: "Laikipia West District",
      },
      {
        label: "Rumuruti",
        value: "Rumuruti",
        district: "Laikipia West District",
      },
      {
        label: "Sipili",
        value: "Sipili",
        district: "Laikipia West District",
      },
      {
        label: "Nyahururu",
        value: "Nyahururu",
        district: "Nyahururu District",
      },
      {
        label: "Witu",
        value: "Witu",
        district: "Lamu District",
      },
      {
        label: "Faza",
        value: "Faza",
        district: "Lamu East District",
      },
      {
        label: "Hindi",
        value: "Hindi",
        district: "Lamu West District",
      },
      {
        label: "Kiunga",
        value: "Kiunga",
        district: "Lamu West District",
      },
      {
        label: "Lamu",
        value: "Lamu",
        district: "Lamu West District",
      },
      {
        label: "Mpeketoni",
        value: "Mpeketoni",
        district: "Lamu West District",
      },
      {
        label: "Mukowe",
        value: "Mukowe",
        district: "Lamu West District",
      },
      {
        label: "Athi",
        value: "Athi",
        district: "Athi River District",
      },
      {
        label: "Kangundo",
        value: "Kangundo",
        district: "Kangundo District",
      },
      {
        label: "Kathiani",
        value: "Kathiani",
        district: "Kathiani District",
      },
      {
        label: "Machakos",
        value: "Machakos",
        district: "Machakos District",
      },
      {
        label: "Ekalakala",
        value: "Ekalakala",
        district: "Masinga District",
      },
      {
        label: "Gikoe",
        value: "Gikoe",
        district: "Masinga District",
      },
      {
        label: "Gitugi",
        value: "Gitugi",
        district: "Masinga District",
      },
      {
        label: "Kikumini",
        value: "Kikumini",
        district: "Masinga District",
      },
      {
        label: "Kivaa",
        value: "Kivaa",
        district: "Masinga District",
      },
      {
        label: "Masinga",
        value: "Masinga",
        district: "Masinga District",
      },
      {
        label: "Kisukioni",
        value: "Kisukioni",
        district: "Matungulu District",
      },
      {
        label: "Mwala",
        value: "Mwala",
        district: "Mwala District",
      },
      {
        label: "Kithimani",
        value: "Kithimani",
        district: "Nambale District",
      },
      {
        label: "Kathonzweni",
        value: "Kathonzweni",
        district: "Kathonzweni District",
      },
      {
        label: "Kibwezi",
        value: "Kibwezi",
        district: "Kibwezi District",
      },
      {
        label: "Kilungu",
        value: "Kilingu",
        district: "Kilungu District",
      },
      {
        label: "Nunguni",
        value: "Nunguni",
        district: "Kilungu District",
      },
      {
        label: "Makindu",
        value: "Makindu",
        district: "Makindu District",
      },
      {
        label: "Makueni",
        value: "Makueni",
        district: "Makueni District",
      },
      {
        label: "Wote",
        value: "Wote",
        district: "Makueni District",
      },
      {
        label: "Matiliku",
        value: "Matiliku",
        district: "Mbooni East District",
      },
      {
        label: "Mbooni East",
        value: "Mbooni East",
        district: "Mbooni East District",
      },
      {
        label: "Mbumbuni",
        value: "Mbumbuni",
        district: "Mbooni West District",
      },
      {
        label: "Mbooni West",
        value: "Mbooni West",
        district: "Mbooni West District",
      },
      {
        label: "Mukaa",
        value: "Mukaa",
        district: "Mukaa District",
      },
      {
        label: "Malili",
        value: "Malili",
        district: "Mukaa East District",
      },
      {
        label: "Nzaui",
        value: "Nzaui",
        district: "Nzaui District",
      },
      {
        label: "Malka Mari",
        value: "Malka Mari",
        district: "Banisa District",
      },
      {
        label: "Banisa",
        value: "Banisa",
        district: "Banisa District",
      },
      {
        label: "Fino",
        value: "Fino",
        district: "Lafey District",
      },
      {
        label: "Lafey",
        value: "Lafey",
        district: "Lafey District",
      },
      {
        label: "Elwak",
        value: "Elwak",
        district: "Mandera Central District",
      },
      {
        label: "Wargadud",
        value: "Wargadud",
        district: "Mandera Central District",
      },
      {
        label: "Arabia",
        value: "Arabia",
        district: "Mandera East District",
      },
      {
        label: "Mandera Township",
        value: "Mandera Township",
        district: "Mandera East District",
      },
      {
        label: "As Habito",
        value: "As Habito",
        district: "Mandera North District",
      },
      {
        label: "Ramu",
        value: "Ramu",
        district: "Mandera North District",
      },
      {
        label: "Dantu",
        value: "Dantu",
        district: "Mandera West District",
      },
      {
        label: "Takaba",
        value: "Takaba",
        district: "Mandera West District",
      },
      {
        label: "Loyangalani",
        value: "Loyangalani",
        district: "Loyangalani District",
      },
      {
        label: "Marsabit",
        value: "Marsabit",
        district: "Marsabit District",
      },
      {
        label: "Kalacha",
        value: "Kalacha",
        district: "Marsabit North District",
      },
      {
        label: "Maikona",
        value: "Maikona",
        district: "Marsabit North District",
      },
      {
        label: "Turbi",
        value: "Turbi",
        district: "Marsabit North District",
      },
      {
        label: "Korr",
        value: "Korr",
        district: "Marsabit South District",
      },
      {
        label: "Laisamis",
        value: "Laisamis",
        district: "Marsabit South District",
      },
      {
        label: "Logologo",
        value: "Logologo",
        district: "Marsabit South District",
      },
      {
        label: "Merile",
        value: "Merile",
        district: "Marsabit South District",
      },
      {
        label: "Golbo",
        value: "Golbo",
        district: "Moyale District",
      },
      {
        label: "Moyale",
        value: "Moyale",
        district: "Moyale District",
      },
      {
        label: "Uran",
        value: "Uran",
        district: "Moyale District",
      },
      {
        label: "North Horr",
        value: "North Horr",
        district: "North Horr District",
      },
      {
        label: "Dabel",
        value: "Dabel",
        district: "Sololo District",
      },
      {
        label: "Obbu",
        value: "Obbu",
        district: "Sololo District",
      },
      {
        label: "Odda",
        value: "Odda",
        district: "Sololo District",
      },
      {
        label: "Ex Lewa",
        value: "Ex Lewa",
        district: "Buuri District",
      },
      {
        label: "Kibirichia",
        value: "Kibirichia",
        district: "Buuri District",
      },
      {
        label: "Kiirua",
        value: "Kiirua",
        district: "Buuri District",
      },
      {
        label: "Kirimara",
        value: "Kirimara",
        district: "Buuri District",
      },
      {
        label: "Kisima",
        value: "Kisima",
        district: "Buuri District",
      },
      {
        label: "Mailinane",
        value: "Mailinane",
        district: "Buuri District",
      },
      {
        label: "Naari",
        value: "Naari",
        district: "Buuri District",
      },
      {
        label: "Ngusishi",
        value: "Ngusishi",
        district: "Buuri District",
      },
      {
        label: "Ntirimiti",
        value: "Ntirimiti",
        district: "Buuri District",
      },
      {
        label: "Ntugi",
        value: "Ntugi",
        district: "Buuri District",
      },
      {
        label: "Timau",
        value: "Timau",
        district: "Buuri District",
      },
      {
        label: "Charia",
        value: "Charia",
        district: "Central Imenti District",
      },
      {
        label: "Equator",
        value: "Equator",
        district: "Central Imenti District",
      },
      {
        label: "Gatimbi",
        value: "Gatimbi",
        district: "Central Imenti District",
      },
      {
        label: "Gikumene",
        value: "Gikumene",
        district: "Central Imenti District",
      },
      {
        label: "Githongo",
        value: "Githongo",
        district: "Central Imenti District",
      },
      {
        label: "Kariene",
        value: "Kariene",
        district: "Central Imenti District",
      },
      {
        label: "Kithirune",
        value: "Kithirune",
        district: "Central Imenti District",
      },
      {
        label: "Igembe North",
        value: "Igembe North",
        district: "Igembe North District",
      },
      {
        label: "Kangeta",
        value: "Kangeta",
        district: "Igembe North District",
      },
      {
        label: "Kiengu",
        value: "Kiengu",
        district: "Igembe North District",
      },
      {
        label: "Kiutine",
        value: "Kiutine",
        district: "Igembe North District",
      },
      {
        label: "Laare",
        value: "Laare",
        district: "Igembe North District",
      },
      {
        label: "Igembe South",
        value: "Igembe South",
        district: "Igembe South District",
      },
      {
        label: "Junction",
        value: "Junction",
        district: "Igembe South District",
      },
      {
        label: "Maua",
        value: "Maua",
        district: "Igembe South District",
      },
      {
        label: "Mutuate",
        value: "Mutuate",
        district: "Igembe South District",
      },
      {
        label: "Imenti North",
        value: "Imenti North",
        district: "Imenti North District",
      },
      {
        label: "Kaaga",
        value: "Kaaga",
        district: "Imenti North District",
      },
      {
        label: "Kinoru",
        value: "|Kinoru",
        district: "Imenti North District",
      },
      {
        label: "Makutano Meru",
        value: "Makutano Meru",
        district: "Imenti North District",
      },
      {
        label: "Meru Township",
        value: "Meru Township",
        district: "Imenti North District",
      },
      {
        label: "Ruiri",
        value: "Ruiri",
        district: "Imenti North District",
      },
      {
        label: "Thimangiri",
        value: "Thimagiri",
        district: "Imenti North District",
      },
      {
        label: "Igoji",
        value: "Igoji",
        district: "Imenti South District",
      },
      {
        label: "Imenti South",
        value: "Imenti South",
        district: "Imenti South District",
      },
      {
        label: "Kanyakine",
        value: "Kanyakine",
        district: "Imenti South District",
      },
      {
        label: "Kathera",
        value: "Kathera",
        district: "Imenti South District",
      },
      {
        label: "Magumoni",
        value: "Magumoni",
        district: "Imenti South District",
      },
      {
        label: "Mikumbune",
        value: "Mikumbune",
        district: "Imenti South District",
      },
      {
        label: "Mitiine",
        value: "Mitiine",
        district: "Imenti South District",
      },
      {
        label: "Mitunguu",
        value: "Mitunguu",
        district: "Imenti South District",
      },
      {
        label: "Nkubu",
        value: "Nkubu",
        district: "Imenti South District",
      },
      {
        label: "Ntharene",
        value: "Ntharene",
        district: "Imenti South District",
      },
      {
        label: "Ukuu",
        value: "Ukuu",
        district: "Imenti North District",
      },
      {
        label: "Chogoria",
        value: "Chogoria",
        district: "Maara District",
      },
      {
        label: "Keria",
        value: "Keria",
        district: "Maara District",
      },
      {
        label: "Kieganguru",
        value: "Kieganguru",
        district: "Maara District",
      },
      {
        label: "Maara",
        value: "Maara",
        district: "Maara District",
      },
      {
        label: "Meru Central",
        value: "Meru Central",
        district: "Meru Central District",
      },
      {
        label: "Mikinduri",
        value: "Mikinduri",
        district: "Tigania East District",
      },
      {
        label: "Tigania East",
        value: "Tigania East",
        district: "Tigania East District",
      },
      {
        label: "Kianjai",
        value: "Kianjai",
        district: "Tigania West District",
      },
      {
        label: "Muthaara",
        value: "Muthaara",
        district: "Tigania West District",
      },
      {
        label: "Urru",
        value: "Urru",
        district: "Tigania West District",
      },
      {
        label: "Tigania West",
        value: "Tigania West",
        district: "Tigania West District",
      },
      {
        label: "Rapogi",
        value: "Rapogi",
        district: "Awendo District",
      },
      {
        label: "Awendo",
        value: "Awendo",
        district: "Awendo District",
      },
      {
        label: "Kegonga",
        value: "Kegonga",
        district: "Kuria East District",
      },
      {
        label: "Kuria East",
        value: "Kuria East",
        district: "Kuria East District",
      },
      {
        label: "Ntimaru",
        value: "Ntimaru",
        district: "Kuria East District",
      },
      {
        label: "Nyabohanze",
        value: "Nyabohanze",
        district: "Kuria East District",
      },
      {
        label: "Igena",
        value: "Igena",
        district: "Kuria West District",
      },
      {
        label: "Isebania",
        value: "Isebania",
        district: "Kuria West District",
      },
      {
        label: "Mabera",
        value: "Mabera",
        district: "Kuria West District",
      },
      {
        label: "Kuria West",
        value: "Kuria West",
        district: "Kuria West District",
      },
      {
        label: "Nyamaharaga",
        value: "Nyamaharaga",
        district: "Kuria West District",
      },
      {
        label: "Nyamtiro",
        value: "Nyamtiro",
        district: "Kuria West District",
      },
      {
        label: "Taranganya",
        value: "Taranganya",
        district: "Kuria West District",
      },
      {
        label: "Migori",
        value: "Migori",
        district: "Migori District",
      },
      {
        label: "Macalder",
        value: "Macalder",
        district: "Nyatike District",
      },
      {
        label: "Muhuru Bay",
        value: "Muhuru Bay",
        district: "Nyatike District",
      },
      {
        label: "Ranen",
        value: "Ranen",
        district: "Nyatike District",
      },
      {
        label: "Sori",
        value: "Sori",
        district: "Nyatike District",
      },
      {
        label: "Nyatike",
        value: "Nyatike",
        district: "Nyatike District",
      },
      {
        label: "Kaluoch",
        value: "Kaluoch",
        district: "Rongo District",
      },
      {
        label: "Kamagambo",
        value: "Kamagambo",
        district: "Rongo District",
      },
      {
        label: "Kamenji",
        value: "Kamenji",
        district: "Rongo District",
      },
      {
        label: "Okenge",
        value: "Okenge",
        district: "Rongo District",
      },
      {
        label: "Opapo ",
        value: "Opapo",
        district: "Rongo District",
      },
      {
        label: "Otati",
        value: "Otati",
        district: "Rongo District",
      },
      {
        label: "Rongo",
        value: "Rongo",
        district: "Rongo District",
      },
      {
        label: "Uriri",
        value: "Uriri",
        district: "Uriri District",
      },
      {
        label: "Bahati",
        value: "Bahati",
        district: "Changamwe District",
      },
      {
        label: "Chaani",
        value: "Chaani",
        district: "Changamwe District",
      },
      {
        label: "Changamwe",
        value: "Changamwe",
        district: "Changamwe District",
      },
      {
        label: "Jomvu",
        value: "Jomvu",
        district: "Changamwe District",
      },
      {
        label: "Mikindani",
        value: "Mikindani",
        district: "Changamwe District",
      },
      {
        label: "Miritini",
        value: "Miritini",
        district: "Changamwe District",
      },
      {
        label: "Portreitz",
        value: "Portreitz",
        district: "Changamwe District",
      },
      {
        label: "Refinery",
        value: "Refinery",
        district: "Changamwe District",
      },
      {
        label: "Kilindini",
        value: "Kilindini",
        district: "Kilindini District",
      },
      {
        label: "Bamburi",
        value: "Bamburi",
        district: "Kisauni District",
      },
      {
        label: "Bombolulu",
        value: "Bombolulu",
        district: "Kisauni District",
      },
      {
        label: "Kiembeni",
        value: "Kiembeni",
        district: "Kisauni District",
      },
      {
        label: "Kongowea",
        value: "Kongowea",
        district: "Kisauni District",
      },
      {
        label: "Mishomoroni",
        value: "Mishomoroni",
        district: "Kisauni District",
      },
      {
        label: "Utange",
        value: "Utange",
        district: "Kisauni District",
      },
      {
        label: "Nyali",
        value: "Nyali",
        district: "Kisauni District",
      },
      {
        label: "Shanzu",
        value: "Shanzu",
        district: "Kisauni District",
      },
      {
        label: "Mtongwe",
        value: "Mtongwe",
        district: "Likoni District",
      },
      {
        label: "Shelly Beach",
        value: "Shelly Beach",
        district: "Likoni District",
      },
      {
        label: "Shika Adabu",
        value: "Shika Adabu",
        district: "Likoni District",
      },
      {
        label: "Soko Mjinga",
        value: "Soko Mjinga",
        district: "Likoni District",
      },
      {
        label: "Buxton",
        value: "Buxton",
        district: "Mombasa District",
      },
      {
        label: "Ganjoni",
        value: "Ganjoni",
        district: "Mombasa District",
      },
      {
        label: "Kingorani",
        value: "Kingorani",
        district: "Mombasa District",
      },
      {
        label: "Majengo",
        value: "Majengo",
        district: "Mombasa District",
      },
      {
        label: "Mombasa",
        value: "Mombasa",
        district: "Mombasa District",
      },
      {
        label: "Mwembe Tayari",
        value: "Mwembe Tayari",
        district: "Mombasa District",
      },
      {
        label: "Sega",
        value: "Sega",
        district: "Mombasa District",
      },
      {
        label: "Shimanzi",
        value: "Shimanzi",
        district: "Mombasa District",
      },
      {
        label: "Tudor",
        value: "Tudor",
        district: "Mombasa District",
      },
      {
        label: "Ziwani",
        value: "Ziwani",
        district: "Mombasa District",
      },
      {
        label: "Gatanga",
        value: "Gatanga",
        district: "Gatanga District",
      },
      {
        label: "Gatunyu",
        value: "Gatunyu",
        district: "Gatanga District",
      },
      {
        label: "Gatura",
        value: "Gatura",
        district: "Gatanga District",
      },
      {
        label: "Kirwara",
        value: "Kirwara",
        district: "Gatanga District",
      },
      {
        label: "Mabanda",
        value: "Mabanda",
        district: "Gatanga District",
      },
      {
        label: "Ndakaini",
        value: "Ndakaini",
        district: "Gatanga District",
      },

      {
        label: "Kahatia",
        value: "Kahatia",
        district: "Kahuro District",
      },
      {
        label: "Kahuro",
        value: "Kahuro",
        district: "Kahuro District",
      },
      {
        label: "Murarandia",
        value: "Murarandia",
        district: "Kahuro District",
      },
      {
        label: "Kamunkunji",
        value: "Kamukunji",
        district: "Kamukunji District",
      },

      {
        label: "Gacharage",
        value: "Gacharage",
        district: "Kandara District",
      },
      {
        label: "Githumu",
        value: "Githumu",
        district: "Kandara District",
      },
      {
        label: "Kagunduini",
        value: "Kagunduini",
        district: "Kandara District",
      },
      {
        label: "Kandara",
        value: "Kandara",
        district: "Kandara District",
      },
      {
        label: "Kangari",
        value: "Kangari",
        district: "Kigumo District",
      },
      {
        label: "Kinyona",
        value: "Kinyona",
        district: "Kigumo District",
      },
      {
        label: "Kigumo",
        value: "Kigumo",
        district: "Kigumo District",
      },
      {
        label: "Gikoe",
        value: "Gikoe",
        district: "Mathioya District",
      },
      {
        label: "Gitugi",
        value: "Gitugi",
        district: "Mathioya District",
      },
      {
        label: "Kamacharia",
        value: "Kamacharia",
        district: "Mathioya District",
      },
      {
        label: "Kiriaini",
        value: "Kiriaini",
        district: "Mathioya District",
      },
      {
        label: "Muranga",
        value: "Muranga",
        district: "Muranga District",
      },
      {
        label: "Gathinja",
        value: "Gathinja",
        district: "Muranga East District",
      },
      {
        label: "Kaweru",
        value: "Kaweru",
        district: "Muranga East District",
      },
      {
        label: "Kambirwa",
        value: "Kambirwa",
        district: "Muranga East District",
      },
      {
        label: "Mukuyu",
        value: "Mukuyu",
        district: "Muranga East District",
      },
      {
        label: "Kabati",
        value: "Kabati",
        district: "Muranga South District",
      },
      {
        label: "Kakuzi",
        value: "Kakuzi",
        district: "Muranga South District",
      },
      {
        label: "Kenol",
        value: "Kenol",
        district: "Muranga South District",
      },
      {
        label: "Makuyu",
        value: "Makuyu",
        district: "Muranga South District",
      },
      {
        label: "Kanyenyaini",
        value: "Kanyenyaini",
        district: "Muranga South District",
      },
      {
        label: "Maragwa",
        value: "Maragwa",
        district: "Muranga South District",
      },
      {
        label: "Saba Saba",
        value: "Saba Saba",
        district: "Muranga South District",
      },
      {
        label: "Muranga West",
        value: "Muranga West",
        district: "Muranga West",
      },
      {
        label: "Dargoreti",
        value: "Dargoreti",
        district: "Dagoreti District",
      },
      {
        label: "Kawangare",
        value: "Kawangware",
        district: "Dagoreti District",
      },
      {
        label: "Kileleshwa",
        value: "Kileleshwa",
        district: "Dagoreti District",
      },
      {
        label: "Riruta",
        value: "Riruta",
        district: "Dagoreti District",
      },
      {
        label: "Waithaka",
        value: "Waithaka",
        district: "Dagoreti District",
      },
      {
        label: "Embakasi",
        value: "Embakasi",
        district: "Embakasi District",
      },
      {
        label: "Donholm",
        value: "Donholm",
        district: "Embakasi District",
      },
      {
        label: "Imara Daima",
        value: "Imara Daima",
        district: "Embakasi District",
      },
      {
        label: "Kariobangi",
        value: "Kariobangi",
        district: "Embakasi District",
      },
      {
        label: "Komarok",
        value: "Komarok",
        district: "Embakasi District",
      },
      {
        label: "South B",
        value: "South B",
        district: "Nairobi District",
      },
      {
        label: "Kamukunji",
        value: "Kamukunji",
        district: "Kamukunji District Nairobi",
      },
      {
        label: "Kariakor",
        value: "Kariakor",
        district: "Kamukunji District Nairobi",
      },
      {
        label: "Kasarani",
        value: "Kasarani",
        district: "Kasarani District",
      },
      {
        label: "Ruaraka",
        value: "Ruaraka",
        district: "Kasarani District",
      },
      {
        label: "Jamhuri",
        value: "Jamhuri",
        district: "Langata District",
      },
      {
        label: "Karen",
        value: "Karen",
        district: "Langata District",
      },
      {
        label: "Kibera",
        value: "Kibera",
        district: "Langata District",
      },
      {
        label: "Langata",
        value: "Langata",
        district: "Langata District",
      },
      {
        label: "Madaraka",
        value: "Madaraka",
        district: "Langata District",
      },
      {
        label: "Nairobi West",
        value: "Nairobi West",
        district: "Langata District",
      },
      {
        label: "Otiende",
        value: "Otiende",
        district: "Langata District",
      },
      {
        label: "South C",
        value: "South C",
        district: "Langata District",
      },
      {
        label: "Wilson Airport",
        value: "Wilson Airport",
        district: "Langata District",
      },
      {
        label: "Woodley",
        value: "Woodley",
        district: "Langata District",
      },
      {
        label: "Buruburu",
        value: "Buruburu",
        district: "Makadara District",
      },
      {
        label: "Industrial Area",
        value: "Industrial Area",
        district: "Makadara District",
      },
      {
        label: "Jericho",
        value: "Jericho",
        district: "Makadara District",
      },
      {
        label: "Kaloleni Nairobi",
        value: "Kaloleni Nairobi",
        district: "Makadara District",
      },
      {
        label: "Makadara",
        value: "Makadara",
        district: "MakadaraDistrict",
      },
      {
        label: "Makongeni",
        value: "Makongeni",
        district: "Makadara District",
      },
      {
        label: "Nairobi East",
        value: "Nairobi East",
        district: "Nairobi East District",
      },
      {
        label: "Nairobi North",
        value: "Nairobi North",
        district: "Nairobi North District",
      },
      {
        label: "Nairobi West",
        value: "Nairobi West",
        district: "Nairobi West District",
      },
      {
        label: "Dandora",
        value: "Dandora",
        district: "Njiiru District",
      },
      {
        label: "Kayole",
        value: "Kayole",
        district: "Njiiru District",
      },
      {
        label: "Njiru",
        value: "Njiru",
        district: "Njiiru District",
      },
      {
        label: "Umoja",
        value: "Umoja",
        district: "Njiiru District",
      },
      {
        label: "CBD",
        value: "CBD",
        district: "Starehe District",
      },
      {
        label: "Eastleigh",
        value: "Eastleigh",
        district: "Starehe District",
      },
      {
        label: "Gigiri",
        value: "Gigiri",
        district: "Starehe District",
      },
      {
        label: "Kyuna",
        value: "Kyuna",
        district: "Starehe District",
      },
      {
        label: "Mathare",
        value: "Mathare",
        district: "Starehe District",
      },
      {
        label: "Muthaiga",
        value: "Muthaiga",
        district: "Starehe District",
      },
      {
        label: "Ngara",
        value: "Ngara",
        district: "Starehe District",
      },
      {
        label: "Pangani",
        value: "Pangani",
        district: "Starehe District",
      },
      {
        label: "Parklands",
        value: "Parklands",
        district: "Starehe District",
      },
      {
        label: "Runda",
        value: "Runda",
        district: "Starehe District",
      },
      {
        label: "Starehe",
        value: "Starehe",
        district: "Starehe District",
      },
      {
        label: "Westlands",
        value: "Westlands",
        district: "Westlands District",
      },
      {
        label: "Highridge",
        value: "Highridge",
        district: "Westlands District",
      },
      {
        label: "Chiromo",
        value: "Chiromo",
        district: "Westlands District",
      },
      {
        label: "Kabete",
        value: "Kabete",
        district: "Westlands District",
      },
      {
        label: "Kangemi",
        value: "Kangemi",
        district: "Westlands District",
      },
      {
        label: "Kilimani",
        value: "Kilimani",
        district: "Westlands District",
      },
      {
        label: "Kitusuru",
        value: "Kitusuru",
        district: "Westlands District",
      },
      {
        label: "Lavington",
        value: "Lavington",
        district: "Westlands District",
      },
      {
        label: "Gilgil",
        value: "Gilgil",
        district: "Gilgil District",
      },
      {
        label: "Keringet",
        value: "Keringet",
        district: "Kuresoi District",
      },
      {
        label: "Elbugon",
        value: "Elbugon",
        district: "Molo District",
      },
      {
        label: "Molo",
        value: "Molo",
        district: "Molo District",
      },
      {
        label: "Longonot",
        value: "Longonot",
        district: "Naivasha District",
      },
      {
        label: "Naivasha",
        value: "Naivasha",
        district: "Naivasha District",
      },
      {
        label: "Nakuru",
        value: "Nakuru",
        district: "Nakuru District",
      },
      {
        label: "Ndundori",
        value: "Ndundori",
        district: "Nakuru North District",
      },
      {
        label: "Bahati",
        value: "Bahati",
        district: "Nakuru North District",
      },
      {
        label: "Egerton",
        value: "Egerton",
        district: "Njoro District",
      },
      {
        label: "Njoro",
        value: "Njoro",
        district: "Njoro District",
      },
      {
        label: "Kampi Ya Moto",
        value: "Kampi Ya Moto",
        district: "Rongai District",
      },
      {
        label: "Subukia",
        value: "Subukia",
        district: "Subukia District",
      },
      {
        label: "Kapsabet",
        value: "Kapsabet",
        district: "Nandi Central District",
      },
      {
        label: "Nandi Hills",
        value: "Nandi Hills",
        district: "Nandi East District",
      },
      {
        label: "Kabiyet",
        value: "Kabiyet",
        district: "Nandi North District",
      },
      {
        label: "Kaptumo",
        value: "Kaptumo",
        district: "Nandi South District",
      },
      {
        label: "Kobujoy",
        value: "Kabujoy",
        district: "Nandi South District",
      },
      {
        label: "Songhor",
        value: "Songhor",
        district: "Tinderet District",
      },
      {
        label: "Tinderet",
        value: "Tinderet",
        district: "Tinderet District",
      },
      {
        label: "Kilgoris",
        value: "Kilgoris",
        district: "Dikirr District",
      },
      {
        label: "Narok",
        value: "Narok",
        district: "Narok North District",
      },
      {
        label: "Narok Township",
        value: "Narok Township",
        district: "Narok North District",
      },
      {
        label: "Suswa",
        value: "Suswa",
        district: "Narok North District",
      },
      {
        label: "Loita",
        value: "Loita",
        district: "Narok South District",
      },
      {
        label: "Ololunga",
        value: "Ololunga",
        district: "Narok South District",
      },
      {
        label: "Murkhan",
        value: "Murkhan",
        district: "Trans Mara District",
      },
      {
        label: "Trans Mara",
        value: "Trans Mara",
        district: "Trans Mara District",
      },
      {
        label: "Birongo",
        value: "Birongo",
        district: "Borabu District",
      },
      {
        label: "Borabu",
        value: "Borabu",
        district: "Borabu District",
      },
      {
        label: "Matemaiywa",
        value: "Matemaiywa",
        district: "Borabu District",
      },
      {
        label: "Nyansiongo",
        value: "Nyansiongo",
        district: "Borabu District",
      },
      {
        label: "Kitutu",
        value: "Kitutu",
        district: "Kitutu District",
      },
      {
        label: "Manga",
        value: "Manga",
        district: "Manga District",
      },
      {
        label: "Kebirigo",
        value: "Kebirigo",
        district: "Nyamira District",
      },
      {
        label: "Nyamira",
        value: "Nyamira",
        district: "Nyamira District",
      },
      {
        label: "Sanganyi",
        value: "Sanganyi",
        district: "Nyamira District",
      },
      {
        label: "Ekurenyo",
        value: "Ekurenyo",
        district: "Nyamira North District",
      },
      {
        label: "Ikonge",
        value: "Ikonge",
        district: "Nyamira North District",
      },
      {
        label: "Ol Kalau",
        value: "Ol Kalau",
        district: "Nyamira North District",
      },
      {
        label: "Njambini",
        value: "Njambini",
        district: "Kinangop District",
      },
      {
        label: "Kipipiri",
        value: "Kipipiri",
        district: "Kipipiri District",
      },
      {
        label: "Mirangini",
        value: "Mirangini",
        district: "Mirangini District",
      },
      {
        label: "Olkalau",
        value: "Olkalau",
        district: "Nyandarua Central District",
      },
      {
        label: "Nyandarua Central",
        value: "Nyandarua Cemtral",
        district: "Nyandarua Central District",
      },
      {
        label: "Nyandarua North",
        value: "Nyandarua North",
        district: "Nyandarua North District",
      },
      {
        label: "Engineer",
        value: "Engineer",
        district: "Nyandarua South District",
      },
      {
        label: "Nyandarua South",
        value: "Nyandarua South",
        district: "Nyandarua South District",
      },
      {
        label: "Ol jororok",
        value: "Ol jororok",
        district: "Nyandarua West District",
      },
      {
        label: "Nyandarua West",
        value: "Nyandarua West",
        district: "Nyandarua West District",
      },
      {
        label: "Chaka",
        value: "Chaka",
        district: "Kieni East District",
      },
      {
        label: "Kabaru",
        value: "Kabaru",
        district: "Kieni East District",
      },
      {
        label: "Kiganjo Nyeri",
        value: "Kiganjo Nyeri",
        district: "Kieni East District",
      },
      {
        label: "Mbilili",
        value: "Mbilili",
        district: "Kieni East District",
      },
      {
        label: "Narumoru",
        value: "Narumoru",
        district: "Kieni East District",
      },
      {
        label: "Munyu",
        value: "Munyu",
        district: "Kieni East District",
      },
      {
        label: "Belview",
        value: "Belview",
        district: "Kieni West District",
      },
      {
        label: "Endarasha",
        value: "Endarasha",
        district: "Kieni West District",
      },
      {
        label: "Mugunda",
        value: "Mugunda",
        district: "Kieni West District",
      },
      {
        label: "Mweiga",
        value: "Mweiga",
        district: "Kieni West District",
      },
      {
        label: "Nairuta",
        value: "Nairuta",
        district: "Kieni West District",
      },
      {
        label: "Ramuira",
        value: "Ramuira",
        district: "Kieni West District",
      },
      {
        label: "Watuka",
        value: "Watuka",
        district: "Kieni West District",
      },
      {
        label: "Karatina",
        value: "Karatina",
        district: "Mathira East District",
      },
      {
        label: "Kiaruiyu",
        value: "Kiaruiyu",
        district: "Mathira East District",
      },
      {
        label: "Mathaithi",
        value: "Mathaithi",
        district: "Mathira East District",
      },
      {
        label: "Kaiyaba",
        value: "Kaiyaba",
        district: "Mathira West District",
      },
      {
        label: "Kiamariga",
        value: "Kiamariga",
        district: "Mathira West District",
      },
      {
        label: "Mukurueini",
        value: "Mukurueini",
        district: "Mukureini District",
      },
      {
        label: "Mukurweini",
        value: "Mukurweini",
        district: "Mukurweini District",
      },
      {
        label: "Giakanja",
        value: "Giakanja",
        district: "Nyeri Central District",
      },
      {
        label: "Kamakwa",
        value: "Kamakwa",
        district: "Nyeri Central District",
      },
      {
        label: "Kingongo",
        value: "Kingongo",
        district: "Nyeri Central District",
      },
      {
        label: "Kiricho",
        value: "Kiricho",
        district: "Nyeri Central District",
      },
      {
        label: "Marua",
        value: "Marua",
        district: "Nyeri Central District",
      },
      {
        label: "Nyeri Central ",
        value: "Nyeri Central",
        district: "Nyeri Central District",
      },
      {
        label: "Ruringu",
        value: "Ruringu",
        district: "Nyeri Central District",
      },
      {
        label: "Nyeri East",
        value: "Nyeri East",
        district: "Nyeri East District",
      },
      {
        label: "Birithia",
        value: "Birithia",
        district: "Nyeri South District",
      },
      {
        label: "Chinga",
        value: "Chinga",
        district: "Nyeri South District",
      },
      {
        label: "Karima",
        value: "Karima",
        district: "Nyeri South District",
      },
      {
        label: "Ndunyu",
        value: "Ndunyu",
        district: "Nyeri South District",
      },
      {
        label: "Othaya",
        value: "Othaya",
        district: "Nyeri South District",
      },
      {
        label: "Gichira",
        value: "Gichira",
        district: "Tetu District",
      },
      {
        label: "Kiandu",
        value: "Kiandu",
        district: "Tetu District",
      },
      {
        label: "Wamagana",
        value: "Wamagana",
        district: "Tetu District",
      },
      {
        label: "Maralal",
        value: "Maralal",
        district: "Samburu Central District",
      },
      {
        label: "Wamba",
        value: "Wamba",
        district: "Samburu East District",
      },
      {
        label: "Baragoi",
        value: "Baragoi",
        district: "Samburu North District",
      },
      {
        label: "Akala",
        value: "Akala",
        district: "Bondo District",
      },
      {
        label: "Bondo",
        value: "Bondo",
        district: "Bondo District",
      },
      {
        label: "Ndori",
        value: "Ndori",
        district: "Bondo District",
      },
      {
        label: "Usenge",
        value: "Usenge",
        district: "Bondo District",
      },
      {
        label: "Dudi",
        value: "Dudi",
        district: "Gem District",
      },
      {
        label: "Luanda",
        value: "Luanda",
        district: "Gem District",
      },
      {
        label: "Ngiya",
        value: "Ngiya",
        district: "Gem District",
      },
      {
        label: "Sawagongo",
        value: "Sawagongo",
        district: "Gem District",
      },
      {
        label: "Wagai",
        value: "Wagai",
        district: "Gem District",
      },
      {
        label: "Yala",
        value: "Yala",
        district: "Gem District",
      },
      {
        label: "Aram",
        value: "Aram",
        district: "Rarieda District",
      },
      {
        label: "Asembo Bay",
        value: "Asembo Bay",
        district: "Rarieda District",
      },
      {
        label: "Chianda",
        value: "Chianda",
        district: "Rarieda District",
      },
      {
        label: "Luanda Kotieno",
        value: "Luanda Kotieno",
        district: "Rarieda District",
      },
      {
        label: "Madiany",
        value: "Madiany",
        district: "Rarieda District",
      },
      {
        label: "Ndigwa",
        value: "Ndigwa",
        district: "Rarieda District",
      },
      {
        label: "Nyilima",
        value: "Nyilima",
        district: "Rarieda District",
      },
      {
        label: "Oyude",
        value: "Oyude",
        district: "Rarieda District",
      },
      {
        label: "Ragengni",
        value: "Ragengni",
        district: "Rarieda District",
      },
      {
        label: "Rarieda",
        value: "Rarieda",
        district: "Rarieda District",
      },
      {
        label: "Karemo",
        value: "Karemo",
        district: "Siaya District",
      },
      {
        label: "Nyadorera",
        value: "Nyadorera",
        district: "Siaya District",
      },
      {
        label: "Nyiya",
        value: "Nyiya",
        district: "Siaya District",
      },
      {
        label: "Siaya",
        value: "Siaya",
        district: "Siaya District",
      },
      {
        label: "Ukwala",
        value: "Ukwala",
        district: "Ugenya District",
      },
      {
        label: "Rangala",
        value: "Rangala",
        district: "Ugunja District",
      },
      {
        label: "Sega Siaya",
        value: "Sega Siaya",
        district: "Ugunja District",
      },
      {
        label: "Sidindi",
        value: "Sidindi",
        district: "Ugunja District",
      },
      {
        label: "Ugunja",
        value: "Ugunja",
        district: "Ugunja District",
      },
      {
        label: "Mwakitau",
        value: "Mwakitau",
        district: "Mwatate District",
      },
      {
        label: "Mwatate",
        value: "Mwatate",
        district: "Mwatate District",
      },
      {
        label: "Ngambwa",
        value: "Ngambwa",
        district: "Mwatate District",
      },
      {
        label: "Mgange",
        value: "Mgange",
        district: "Taita District",
      },
      {
        label: "Taita",
        value: "Taita",
        district: "Taita District",
      },
      {
        label: "Werugha",
        value: "Werugha",
        district: "Taita District",
      },
      {
        label: "Wundanyi Town",
        value: "Wundanyi Town",
        district: "Taita District",
      },
      {
        label: "Taveta",
        value: "Taveta",
        district: "Taveta District",
      },
      {
        label: "Voi",
        value: "Voi",
        district: "Voi",
      },
      {
        label: "Kambu",
        value: "Kambu",
        district: "Voi District",
      },
      {
        label: "Kasigau",
        value: "Kasigau",
        district: "Voi District",
      },
      {
        label: "Makinon Road",
        value: "Makinon Road",
        district: "Voi District",
      },
      {
        label: "Manyani",
        value: "Manyani",
        district: "Voi District",
      },
      {
        label: "Maungu",
        value: "Maungu",
        district: "Voi District",
      },
      {
        label: "Mtito",
        value: "Mtito",
        district: "Voi District",
      },
      {
        label: "Voi",
        value: "Voi",
        district: "Voi District",
      },
      {
        label: "Wundanyi",
        value: "Wundanyi",
        district: "Wundanyi District",
      },
      {
        label: "Bura",
        value: "Bura",
        district: "Bura District",
      },
      {
        label: "Garsen",
        value: "Garsen",
        district: "Tana Delta District",
      },
      {
        label: "Kipini",
        value: "Kipini",
        district: "Tana Delta District",
      },
      {
        label: "Mnazini",
        value: "Mnazini",
        district: "Tana Delta District",
      },
      {
        label: "Tana Delta",
        value: "Tana Delta",
        district: "Tana Delta District",
      },
      {
        label: "Tarasaa",
        value: "Tarasaa",
        district: "Tana Delta District",
      },
      {
        label: "Bura Tana River",
        value: "Bura Tana River",
        district: "Tana North Bura District",
      },
      {
        label: "Charidende",
        value: "Charidende",
        district: "Tana North Bura District",
      },
      {
        label: "Laza",
        value: "Laza",
        district: "Tana North Bura District",
      },
      {
        label: "Madogo",
        value: "Madogo",
        district: "Tana North Bura District",
      },
      {
        label: "Minijilia",
        value: "Minijilia",
        district: "Tana North Bura District",
      },
      {
        label: "Hola",
        value: "Hola",
        district: "Tana River District",
      },
      {
        label: "Wenje",
        value: "Wenje",
        district: "Tana River District",
      },
      {
        label: "Kibugua",
        value: "Kibugua",
        district: "Meru South District",
      },
      {
        label: "Chuka",
        value: "Chuka",
        district: "Meru South District",
      },
      {
        label: "Marima",
        value: "Marima",
        district: "Meru South District",
      },
      {
        label: "Meru South",
        value: "Meru South",
        district: "Meru South District",
      },
      {
        label: "Ndagani",
        value: "Ndagani",
        district: "Meru South District",
      },
      {
        label: "Ciakariga",
        value: "Ciakariga",
        district: "Tharaka North District",
      },
      {
        label: "Gatunga",
        value: "Gatunga",
        district: "Tharaka North District",
      },
      {
        label: "Tharaka North",
        value: "Tharaka North",
        district: "Tharaka North District",
      },
      {
        label: "Marimanti",
        value: "Marimanti",
        district: "Tharaka South District",
      },
      {
        label: "Nkondi",
        value: "Mkondi",
        district: "Tharaka North District",
      },
      {
        label: "Endebbes",
        value: "Endebbes",
        district: "Kwanza District",
      },
      {
        label: "Maili Saba",
        value: "Maili Saba",
        district: "Trans Nzoia East District",
      },
      {
        label: "Cheregany",
        value: "Cheregany",
        district: "Trans Nzoia West District",
      },
      {
        label: "Kitale",
        value: "Kitale",
        district: "Trans Nzoia West District",
      },
      {
        label: "Lorugum",
        value: "Lorugum",
        district: "Loima District",
      },
      {
        label: "Loima",
        value: "Loima",
        district: "Loima District",
      },
      {
        label: "Lodwar",
        value: "Lodwar",
        district: "Turkana Central District",
      },
      {
        label: "Lokori",
        value: "Lokori",
        district: "Turkana East District",
      },
      {
        label: "Lokiatung",
        value: "Lokiatung",
        district: "Turkana North District",
      },
      {
        label: "Lokichar",
        value: "Lokichar",
        district: "Turkana South District",
      },
      {
        label: "Kakuma",
        value: "Kakuma",
        district: "Turkana West District",
      },
      {
        label: "Lokichogio",
        value: "Lokichogio",
        district: "Turkana West District",
      },
      {
        label: "Kapsoya",
        value: "Kapsoya",
        district: "Eldoret East District",
      },
      {
        label: "Medi Heal",
        value: "Medi Heal",
        district: "Eldoret East District",
      },
      {
        label: "Naibei",
        value: "Naibei",
        district: "Eldoret East District",
      },
      {
        label: "Eldoret",
        value: "Eldoret",
        district: "Eldoret West District",
      },
      {
        label: "Outspan",
        value: "Outspan",
        district: "Wareng District",
      },
      {
        label: "Pioneer",
        value: "Pioneer",
        district: "Wareng District",
      },
      {
        label: "Kima",
        value: "Kima",
        district: "Emuhaya District",
      },
      {
        label: "Luanda Town",
        value: "Luanda Town",
        district: "Emuhaya District",
      },
      {
        label: "Bendera",
        value: "Bendera",
        district: "Hamisi District",
      },
      {
        label: "Cheptulu",
        value: "Cheptulu",
        district: "Hamisi District",
      },
      {
        label: "Kaimosi",
        value: "Kaimosi",
        district: "Hamisi District",
      },
      {
        label: "Hamisi",
        value: "Hamisi",
        district: "Hamisi District",
      },
      {
        label: "Serem",
        value: "Serem",
        district: "Hamisi District",
      },
      {
        label: "Mudete",
        value: "Mudete",
        district: "Hamisi District",
      },
      {
        label: "Shamakhokho",
        value: "Shamakhokho",
        district: "Hamisi District",
      },
      {
        label: "Sabatia Town",
        value: "Sabatia Town",
        district: "Sabatia District",
      },
      {
        label: "Boyani",
        value: "Boyani",
        district: "Vihiga District",
      },
      {
        label: "Chavakali",
        value: "Chavakali",
        district: "Vihiga District",
      },
      {
        label: "Gambogi",
        value: "Gambogi",
        district: "Vihiga District",
      },
      {
        label: "Kilingili",
        value: "Kilingili",
        district: "Vihiga District",
      },
      {
        label: "Majengo",
        value: "Majengo",
        district: "Vihiga District",
      },
      {
        label: "Mbale",
        value: "Mbale",
        district: "Vihiga District",
      },
      {
        label: "Vihiga Centre",
        value: "Vihiga Centre",
        district: "Vihiga District",
      },
      {
        label: "Buna",
        value: "Buna",
        district: "Buna District",
      },
      {
        label: "El nur",
        value: "El nur",
        district: "Eldas District",
      },
      {
        label: "Eldas",
        value: "Eldas",
        district: "Eldas District",
      },
      {
        label: "Lakole South",
        value: "Lakole SOuth",
        district: "Eldas District",
      },
      {
        label: "Habswein",
        value: "Habswein",
        district: "Habaswein District",
      },
      {
        label: "Dambas",
        value: "Dambas",
        district: "Tarbaj District",
      },
      {
        label: "Tarbaj",
        value: "Tarbaj",
        district: "Tarbaj District",
      },
      {
        label: "Khorof Harar",
        value: "Khorof Harar",
        district: "Wajir East District",
      },
      {
        label: "Wajir Bor",
        value: "Wajor Bor",
        district: "Wajir  East District",
      },
      {
        label: "Buna",
        value: "Buna",
        district: "Wajir North District",
      },
      {
        label: "Bute",
        value: "Bute",
        district: "Wajir North District",
      },
      {
        label: "Danaba",
        value: "Danaba",
        district: "Wajir North District",
      },
      {
        label: "Gurar",
        value: "Gurar",
        district: "Wajir North District",
      },
      {
        label: "Abakore",
        value: "Abakore",
        district: "Wajir South District",
      },
      {
        label: "Dadaja Bula",
        value: "Dadaja Bula",
        district: "Wajir South District",
      },
      {
        label: "Diif",
        value: "Diif",
        district: "Wajir South District",
      },
      {
        label: "Leheley",
        value: "Leheley",
        district: "Wajir South District",
      },
      {
        label: "Arbajan",
        value: "Arbajan",
        district: "Wajir West District",
      },
      {
        label: "Griftu",
        value: "Griftu",
        district: "Wajir West District",
      },
      {
        label: "Hadado",
        value: "Hadado",
        district: "Wajir West District",
      },
      {
        label: "Wajir Township",
        value: "Wajir Township",
        district: "Wajir West District",
      },
      {
        label: "Alale",
        value: "Alale",
        district: "North Pokot District",
      },
      {
        label: "Sigor",
        value: "Sigor",
        district: "Pokot Central District",
      },
      {
        label: "Kalbichbich",
        value: "Kalbichbich",
        district: "Pokot South District",
      },
      {
        label: "Kapenguria",
        value: "Kapenguria",
        district: "West Pokot District",
      },
    ],
  },
};
