import { useSelector } from "react-redux";
import { Formik, Field } from "formik";
import _isEmpty from "lodash/isEmpty";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";

const FileUploads = ({ hideResolveAllCheckbox }) => {
  const { idType, fileUploads } =
    useSelector((state) => state.organizationsSlice.organization) || {};

  return (
    <div className="mt-6">
      <p className="font-semibold mb-2">
        {_isEmpty(fileUploads) ? "No documents found" : "Uploaded documents"}
      </p>
      <Formik
        enableReinitialize
        initialValues={{
          fileUploads,
        }}
        onSubmit={() => null}
      >
        {() => {
          return (
            <div className="pl-4">
              <Field
                name="fileUploads"
                component={FileUploadForm}
                documentData={getDocumentData(idType)}
                onlyPreview
                showDocumentLabel
                hideResolveAllCheckbox={hideResolveAllCheckbox}
              />
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default FileUploads;
