import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";
import { useHistory, useParams } from "react-router-dom";

import { editComment, deleteComment } from "state/slices/comment";
import Pagination from "components/lib/Shared/Pagination";
import CommentsPopup from "./CommentsPopup";
import { useToast } from "hooks/useToast";

const CommentsList = ({
  comments = [],
  color,
  toggleCommentStatus,
  paginate,
  applicantReviewer,
  isProfileReview,
}) => {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState({
    submitting: false,
    commentId: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [paginatedComments, setPaginatedComments] = useState(comments);
  const history = useHistory();
  const params = useParams();
  const currentUser = useSelector((state) => state.userSlice);
  const isViewMode = history.location.pathname.split("/").pop() === "view";
  const isApplicantView =
    history.location.pathname.split("/").pop() === params.companyId;
  const isProfileEditView = history.location.pathname === "/profile/edit";

  const { toast } = useToast(3000);

  useEffect(() => {
    if (paginate)
      setPaginatedComments(
        comments.slice(
          (currentPage - 1) * rowsPerPage,
          currentPage * rowsPerPage
        )
      );
    else setPaginatedComments(comments);
  }, [currentPage, comments, paginate]);

  const toggleResolveComment = async (comment) => {
    setApiStatus({ submitting: true, commentId: comment._id });
    await dispatch(
      editComment({
        commentId: comment._id,
        data: { isResolved: !comment.isResolved },
        isProfileReview,
      })
    );
    setApiStatus({ submitting: false, commentId: null });
  };

  // rebuild tooltip if one of these dependencies change
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [comments, toggleCommentStatus]);

  const handleDeleteComment = async (commentId) => {
    try {
      await dispatch(deleteComment({ commentId, isProfileReview }));
      toast("success", "Comment deleted successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  const handleUpdateComment = async ({ commentId, text }) => {
    try {
      await dispatch(
        editComment({ commentId, data: { text }, isProfileReview })
      );
      toast("success", "Comment updated successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  const getCommentLabel = (comment) => {
    const createdByFirstName = comment.createdBy.names.firstName;
    let commentText = "";
    if (
      comment?.fieldLabel &&
      ((!isApplicantView && !isProfileEditView) ||
        comment.formName === "shareInformation")
    ) {
      commentText = comment.fieldLabel;
    }
    if (applicantReviewer) commentText = comment.fieldLabel;
    if (comment?.createdBy && currentUser?._id !== comment?.createdBy?._id) {
      commentText +=
        (isApplicantView || isProfileEditView) &&
        comment.formName !== "shareInformation"
          ? `${createdByFirstName}: `
          : `(${createdByFirstName}): `;
    }
    if (commentText && !commentText.includes(":")) {
      commentText += ": ";
    }
    return commentText;
  };

  if (!Array.isArray(comments)) return null;

  return (
    <ul className="space-y-1 text-sm">
      {paginatedComments?.map((comment, index) => (
        <li
          key={`${comment}_${index} `}
          className={`flex items-center ${color} ${
            comment.isResolved ? "text-green-focused" : "text-primary"
          }`}
        >
          {((!isViewMode && !isApplicantView) || applicantReviewer) &&
            toggleCommentStatus && (
              <span className="self-start">
                <CheckCircleIcon
                  className={`inline-block h-4 w-4 mr-2 outline-none self-baseline
            ${
              apiStatus.submitting && apiStatus.commentId === comment._id
                ? "cursor-not-allowed"
                : `${toggleCommentStatus && "cursor-pointer"}`
            }
            `}
                  onClick={() =>
                    toggleCommentStatus ? toggleResolveComment(comment) : null
                  }
                  data-tip={"Toggle comment status"}
                  data-type="light"
                  data-for="comment-tip"
                />
              </span>
            )}
          <span>
            <span className="font-medium">{getCommentLabel(comment)}</span>
            <span className="whitespace-normal w-full">
              {typeof comment === "string" ? comment : comment.text}
            </span>
          </span>
          {toggleCommentStatus && !isViewMode && (
            <span className="flex space-x-2 ml-4 self-baseline mt-0.5">
              <CommentsPopup
                handleSaveComment={handleUpdateComment}
                formName={comment.formName}
                fieldName={comment.fieldName}
                comment={comment}
              >
                <PencilAltIcon className="w-4 h-4 cursor-pointer text-light-blue" />
              </CommentsPopup>
              <TrashIcon
                onClick={() => handleDeleteComment(comment._id)}
                className="w-4 h-4 text-red-500 cursor-pointer"
              />
            </span>
          )}
        </li>
      ))}
      {paginate && (
        <Pagination
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          totalRows={comments.length}
          setCurrentPage={setCurrentPage}
        >
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {(currentPage - 1) * rowsPerPage + 1}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {(currentPage - 1) * rowsPerPage + paginatedComments.length}
            </span>{" "}
            of <span className="font-medium">{comments.length}</span> results
          </p>
        </Pagination>
      )}
      {toggleCommentStatus && (
        <ReactTooltip
          effect="solid"
          id="comment-tip"
          delayHide={500}
          delayShow={500}
          border
          borderColor="gray"
        />
      )}
    </ul>
  );
};

export default CommentsList;
