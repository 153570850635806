/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import _omit from "lodash/omit";
import _filter from "lodash/filter";
import _isEqual from "lodash/isEqual";
import _sortBy from "lodash/sortBy";

import ValidationError from "components/lib/Shared/ValidationError";
import {
  addNominalShareAsync,
  updateNominalShareAsync,
} from "state/slices/tables/nominalSharesTable";
import { closeModal } from "state/slices/modals";
import schemaGenerator from "./validationSchema";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { useToast } from "hooks/useToast";
import { Constants } from "config/constants";
import { ExclamationIcon } from "@heroicons/react/outline";

const nominalOptions = [
  { value: Constants.ORDINARY_SHARES, label: Constants.ORDINARY_SHARES },
  {
    value: Constants.PREFERENCE_SHARES,
    label: Constants.PREFERENCE_SHARES,
  },
  {
    value: Constants.OTHER_SHARES,
    label: Constants.OTHER_SHARES,
  },
];

const preferenceRights = [
  { label: "Cumulative", order: 1, correlativeRight: "Non-Cumulative" },
  { label: "Redeemable", order: 3, correlativeRight: "Non-Redeemable" },
  { label: "Participating", order: 5, correlativeRight: "Non-Participating" },
  { label: "Convertible", order: 7, correlativeRight: "Non-Convertible" },
  { label: "Non-Cumulative", order: 2, correlativeRight: "Cumulative" },
  { label: "Non-Redeemable", order: 4, correlativeRight: "Redeemable" },
  { label: "Non-Participating", order: 6, correlativeRight: "Participating" },
  { label: "Non-Convertible", order: 8, correlativeRight: "Convertible" },
];

const ordinaryRights = [
  {
    label: "Redeemable",
    order: 1,
    correlativeRight: "Non-Redeemable",
    disabled: true,
  },
  {
    label: "Non-Redeemable",
    order: 2,
    correlativeRight: "Redeemable",
    disabled: true,
  },
];

const otherRights = [
  { label: "Redeemable", order: 1, correlativeRight: "Non-Redeemable" },
  { label: "Non-Redeemable", order: 2, correlativeRight: "Redeemable" },
];

const NominalShareSelection = ({
  mode,
  takenShares,
  setShowAddMoreSharesBtn,
}) => {
  const dispatch = useDispatch();
  const { toast } = useToast(5000);
  const { company } = useSelector((state) => state.companySlice);
  const { shares } = useSelector((state) => state).sharesTableSlice;
  const [prefixes, setPrefixes] = useState([]);

  let currentRow = {};
  if (mode?.id) {
    currentRow = shares.find((share) => share._id === mode.id);
  }

  useEffect(() => {
    if (currentRow?.rights) {
      setPrefixes(currentRow?.rights ?? []);
    }
  }, [currentRow]);

  const handlePrefixChange = (name, checked, prefix) => {
    if (checked) setPrefixes((prevValue) => [...prevValue, prefix]);
    else
      setPrefixes((prevValue) =>
        prevValue.filter((prefix) => prefix.label !== name)
      );
  };

  const handleCancel = () => {
    dispatch(closeModal("main_shares_modal_edit"));
    setShowAddMoreSharesBtn(true);
  };

  return (
    <Formik
      initialValues={
        mode?.editing
          ? {
              ...currentRow,
              customSharesCategory: currentRow.sharesCategory,
              sharesCategory:
                currentRow?.baseSharesCategory ?? currentRow.sharesCategory,
            }
          : {
              sharesCategory: "",
              sharesNumber: "",
              sharesValue: "",
              customSharesCategory: "",
              showCustomSharesCategory: false,
              hasVotingRights: "",
              votesPerShare: 1,
            }
      }
      enableReinitialize
      validationSchema={schemaGenerator({
        takenSharesCategories: _filter(
          shares.map(({ sharesCategory }) => sharesCategory),
          (cat) => cat !== currentRow?.sharesCategory
        ),
        minSharesNumber: takenShares[currentRow?.sharesCategory] ?? 1,
        shares: shares.filter((share) => share._id !== currentRow?._id),
      })}
      onSubmit={async (values) => {
        if (!company?._id) {
          toast(
            "error",
            "Something went wrong please reload the page and try again."
          );
          return;
        }
        let payload = _omit(values, [
          "customSharesCategory",
          "showCustomSharesCategory",
        ]);
        if (
          values?.customSharesCategory &&
          [Constants.OTHER_SHARES, Constants.PREFERENCE_SHARES].includes(
            values.sharesCategory
          )
        ) {
          payload = {
            ...payload,
            sharesCategory: values.customSharesCategory,
            baseSharesCategory: values.sharesCategory,
          };
        }
        payload.nominalValue = payload.sharesNumber * payload.sharesValue;
        payload.votingRights = payload.sharesNumber * payload.votesPerShare;
        if (mode?.editing) {
          if (!_isEqual(payload, currentRow))
            await dispatch(
              updateNominalShareAsync({ id: mode.id, data: payload })
            );
          dispatch(closeModal("main_shares_modal_edit"));
        } else {
          payload.entityType = company.type;
          payload.templateType = [
            Constants.ORDINARY_SHARES,
            Constants.PREFERENCE_SHARES,
          ].includes(values.sharesCategory)
            ? values.sharesCategory
            : Constants.OTHER_SHARES;
          await dispatch(
            addNominalShareAsync({ companyId: company._id, data: payload })
          );
          setShowAddMoreSharesBtn(true);
        }
      }}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => {
        useEffect(() => {
          if (values.sharesCategory === Constants.ORDINARY_SHARES) {
            setFieldValue("hasVotingRights", "Yes");
          } else setFieldValue("hasVotingRights", values.hasVotingRights);

          if (
            values.sharesCategory === Constants.OTHER_SHARES ||
            [values?.sharesCategory, values?.baseSharesCategory].includes(
              Constants.PREFERENCE_SHARES
            )
          ) {
            setFieldValue("showCustomSharesCategory", true);
          } else setFieldValue("showCustomSharesCategory", false);
        }, [values]);

        useEffect(() => {
          if (values.hasVotingRights === "No") {
            setFieldValue("votesPerShare", 0);
          } else setFieldValue("votesPerShare", 1);
        }, [values.hasVotingRights]);

        useEffect(() => {
          setFieldValue("rights", prefixes);
        }, [prefixes]);

        // handle shares category changed
        useEffect(() => {
          if (values.sharesCategory === Constants.ORDINARY_SHARES) {
            setPrefixes([
              {
                label: "Non-Redeemable",
                order: 2,
                correlativeRight: "Redeemable",
                disabled: true,
              },
            ]);
          } else setPrefixes([]);
        }, [values.sharesCategory]);

        let rights = [];
        if (
          [values.sharesCategory, values?.baseSharesCategory].includes(
            Constants.PREFERENCE_SHARES
          )
        ) {
          rights = preferenceRights;
        } else if (values.sharesCategory == Constants.ORDINARY_SHARES) {
          rights = ordinaryRights;
        } else if (values.sharesCategory == Constants.OTHER_SHARES) {
          rights = otherRights;
        }

        const titlePrefix = _sortBy(values.rights, ["order"])
          .map(({ label }) => label)
          .join(" ");
        let combinationAlreadyExists = false;
        shares.map(({ hasVotingRights, rights }) => {
          const tempTitlePrefix = _sortBy(rights, ["order"])
            .map(({ label }) => label)
            .join(" ");
          if (
            hasVotingRights === values.hasVotingRights &&
            !!titlePrefix &&
            titlePrefix === tempTitlePrefix
          )
            combinationAlreadyExists = true;
        });

        return (
          <Form>
            <div
              className={`${
                !mode?.editing ? "shadow bg-white sm:rounded-md" : ""
              }`}
            >
              <div className="px-4 py-6 space-y-6 sm:p-6">
                <div>
                  <label className="text-base font-medium text-gray-900">
                    Share category type
                  </label>
                  <fieldset className="mt-4">
                    <legend className="sr-only">Share category type</legend>
                    <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                      {nominalOptions.map((nominalOption) => (
                        <div
                          key={nominalOption.value}
                          className="flex items-center"
                        >
                          <Field
                            id={nominalOption.value}
                            name="sharesCategory"
                            type="radio"
                            value={nominalOption.value}
                            className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor={nominalOption.value}
                            className="block ml-3 text-sm font-medium text-gray-700"
                          >
                            {nominalOption.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>
                <ValidationError
                  errors={errors}
                  touched={touched}
                  name="sharesCategory"
                />
                {(values.sharesCategory === Constants.OTHER_SHARES ||
                  values.showCustomSharesCategory) && (
                  <div>
                    <label
                      htmlFor="customSharesCategory"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Custom Shares Category
                    </label>
                    <Field
                      name="customSharesCategory"
                      placeholder="Enter a unique shares name. Eg Ordinary A"
                      className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <ValidationError
                      errors={errors}
                      touched={touched}
                      name="customSharesCategory"
                    />
                  </div>
                )}
                <hr />
                <div>
                  <label className="text-base font-medium text-gray-900">
                    Do the shares have voting rights?
                  </label>
                  <fieldset className="mt-4">
                    <legend className="sr-only">
                      Do the shares have voting rights?
                    </legend>
                    <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                      {["Yes", "No"].map((votingOption) => (
                        <div key={votingOption} className="flex items-center">
                          <Field
                            type="radio"
                            name="hasVotingRights"
                            value={votingOption}
                            id={votingOption}
                            className={`${
                              values.sharesCategory ===
                              Constants.ORDINARY_SHARES
                                ? "border-gray-200"
                                : "border-gray-300"
                            } w-4 h-4 text-indigo-600  focus:ring-indigo-500`}
                            disabled={
                              values.sharesCategory ===
                              Constants.ORDINARY_SHARES
                            }
                          />
                          <label
                            htmlFor={votingOption}
                            className={`${
                              values.sharesCategory ===
                                Constants.ORDINARY_SHARES &&
                              votingOption === "No"
                                ? "text-gray-300"
                                : "text-gray-700"
                            } block ml-3 text-sm font-medium`}
                          >
                            {votingOption}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                  <ValidationError
                    errors={errors}
                    touched={touched}
                    name="hasVotingRights"
                  />
                </div>
                <hr />
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="sharesNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Number of shares
                    </label>
                    <Field
                      id="sharesNumber"
                      name="sharesNumber"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Add number of shares. Eg 100"
                      className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <ValidationError
                      errors={errors}
                      touched={touched}
                      name="sharesNumber"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="sharesValue"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Value of shares
                    </label>
                    <Field
                      id="sharesValue"
                      name="sharesValue"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Add value of shares. Eg 100"
                      className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <ValidationError
                      errors={errors}
                      touched={touched}
                      name="sharesValue"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="votesPerShare"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Votes Per Share
                    </label>
                    <Field
                      id="votesPerShare"
                      name="votesPerShare"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Not less than 1"
                      className={`${
                        values.hasVotingRights === "No" ? "bg-gray-200" : ""
                      } block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      min={1}
                      disabled={values.hasVotingRights === "No"}
                    />
                    <ValidationError
                      errors={errors}
                      touched={touched}
                      name="votesPerShare"
                    />
                  </div>
                </div>
                {rights.length > 0 && (
                  <>
                    <p className="pt-4 font-semibold border-t">
                      Please select the rights affiliated to your class of
                      shares
                    </p>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                      {rights.map((prefix) => {
                        const disabled =
                          prefixes.find(
                            ({ label }) => label === prefix.correlativeRight
                          ) || prefix.disabled;
                        return (
                          <div
                            className={`relative flex items-start mb-2 mr-4`}
                            key={prefix.label}
                          >
                            <div className="flex items-center h-5">
                              <input
                                id={prefix.label}
                                aria-describedby="preference-share-name-prefix"
                                name={prefix.label}
                                type="checkbox"
                                checked={prefixes.find(
                                  ({ label }) => label === prefix.label
                                )}
                                onChange={({ target: { name, checked } }) => {
                                  if (!disabled) {
                                    handlePrefixChange(name, checked, prefix);
                                  }
                                }}
                                disabled={disabled}
                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 disabled:cursor-not-allowed"
                              />
                            </div>
                            <div className="ml-2 text-sm">
                              <label
                                htmlFor={prefix.label}
                                className={`font-medium ${
                                  disabled
                                    ? "cursor-not-allowed text-gray-300"
                                    : "text-gray-700"
                                }`}
                              >
                                {prefix.label}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                <ValidationError
                  errors={errors}
                  touched={touched}
                  name="rights"
                />
                {combinationAlreadyExists &&
                  values.sharesCategory === Constants.OTHER_SHARES && (
                    <div className="rounded-md bg-yellow-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                            className="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-yellow-800">
                            Attention needed
                          </h3>
                          <div className="mt-2 text-sm text-yellow-700">
                            <p>This combination of rights already exists</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="flex justify-end mt-2 space-x-2">
                  <button
                    type="button"
                    onClick={handleCancel}
                    disabled={isSubmitting}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="inline-flex justify-center px-4 py-2 ml-auto text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sflPrimary hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed"
                    style={{ alignSelf: "center" }}
                  >
                    {isSubmitting ? (
                      <>
                        <SpinnerIcon />
                        Submitting...
                      </>
                    ) : mode?.editing ? (
                      "Save"
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NominalShareSelection;
