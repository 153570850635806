/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import DatePickerField from "components/lib/Shared/DatePickerField";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "components/PLCTABS/SFLdata/PrimaryBusinessOptions";
import addressOptions from "components/PLCTABS/SFLdata/addressOptions";
import CommentsList from "components/lib/Global/CommentsList";
import ValidationError from "components/lib/Shared/ValidationError";

function PrimaryBusinessActivity({
  field,
  form,
  primaryBusinessActivity,
  disabled,
}) {
  const { values = {}, errors = {}, touched = {} } = form || {};
  const { setFieldValue } = useFormikContext();
  const [selectedDate, setSelectedDate] = useState(null);
  const { transformedComments } = useSelector((state) => state.commentsSlice);

  const { group, sector, division, businessClass, accountingEndMonth } =
    primaryBusinessActivity;

  const [selectedSector, setSelectedSector] = useState();
  const [selectedDivision, setSelectedDivision] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedBusinessClass, setSelectedBusinessClass] = useState();
  const [dropdownData, setDropdownData] = useState({
    dropdown: {
      sectors: options.primaryBusinessActivity.sectors,
      divisions: options.primaryBusinessActivity.divisions.filter(
        (item) => item.sector === sector
      ),
      groups: options.primaryBusinessActivity.groups.filter(
        (item) => item.division === division
      ),
      classes: options.primaryBusinessActivity.classes.filter(
        (item) => item.group === group
      ),
    },
  });

  useEffect(() => {
    const { sector, division, group } = primaryBusinessActivity;
    setSelectedSector(sector);
    setSelectedDivision(division);
    setSelectedGroup(group);
    setSelectedBusinessClass(businessClass);
    if (primaryBusinessActivity.targetBusinessStartDate) {
      setSelectedDate(
        new Date(primaryBusinessActivity.targetBusinessStartDate)
      );
    }
    setDropdownData({
      dropdown: {
        sectors: options.primaryBusinessActivity.sectors,
        divisions: options.primaryBusinessActivity.divisions.filter(
          (item) => item.sector === sector
        ),
        groups: options.primaryBusinessActivity.groups.filter(
          (item) => item.division === division
        ),
        classes: options.primaryBusinessActivity.classes.filter(
          (item) => item.group === group
        ),
      },
    });
  }, [primaryBusinessActivity]);

  const handleSelect = (type) => (option) => {
    if (type === "sector") {
      setDropdownData({
        ...dropdownData,
        dropdown: {
          ...dropdownData.dropdown,
          divisions: options.primaryBusinessActivity.divisions.filter(
            (item) => item.sector === option.value
          ),
        },
      });
      setSelectedSector(option);
      setFieldValue(field.name, {
        ...field.value,
        division: "",
        group: "",
        businessClass: "",
      });
      setSelectedDivision({ value: "", label: "" });
      setSelectedGroup({ value: "", label: "" });
      setSelectedBusinessClass({ value: "", label: "" });
    }
    if (type === "division") {
      setDropdownData({
        ...dropdownData,
        dropdown: {
          ...dropdownData.dropdown,
          groups: options.primaryBusinessActivity.groups.filter(
            (item) => item.division === option.value
          ),
        },
      });
      setSelectedDivision(option);
      setFieldValue(field.name, {
        ...field.value,
        group: "",
        businessClass: "",
      });
      setSelectedGroup({ value: "", label: "" });
      setSelectedBusinessClass({ value: "", label: "" });
    } else if (type === "group") {
      setDropdownData({
        ...dropdownData,
        dropdown: {
          ...dropdownData.dropdown,
          classes: options.primaryBusinessActivity.classes.filter(
            (item) => item.group === option.value
          ),
        },
      });
      setSelectedGroup(option);
      setFieldValue(field.name, {
        ...field.value,
        businessClass: "",
      });
      setSelectedBusinessClass({ value: "", label: "" });
    } else if (type === "businessClass") {
      setSelectedBusinessClass(option);
    }
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
  };

  const handleDateValue = (type) => (date) => {
    setFieldValue(
      `primaryBusinessActivity.${type}`,
      format(date, "yyyy/MM/dd")
    );
    setSelectedDate(date);
  };

  const sectorComments =
    transformedComments?.["businessApplicationDetails"]?.sector ?? [];
  const divisionComments =
    transformedComments?.["businessApplicationDetails"]?.division ?? [];
  const groupComments =
    transformedComments?.["businessApplicationDetails"]?.group ?? [];
  const classComments =
    transformedComments?.["businessApplicationDetails"]?.businessClass ?? [];
  const businessStartDateComments =
    transformedComments?.["businessApplicationDetails"]
      ?.targetBusinessStartDate ?? [];
  const accountingEndMonthComments =
    transformedComments?.["businessApplicationDetails"]?.accountingEndMonth ??
    [];

  return (
    <>
      <h3 className=" font-medium mb-3">Primary business activity</h3>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="sector"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Sector
              {sectorComments.length > 0 && (
                <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-2 -right-5">
                  <span>{sectorComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <Field
            component={ReactSelectWithFormik}
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            options={options.primaryBusinessActivity.sectors}
            onChange={handleSelect("sector")}
            isDisabled={values.resolvedFields.includes("sector") || disabled}
            value={selectedSector}
            id="sector"
            name="sector"
            placeholder="Select a sector"
            isSearchable
          />
          {sectorComments.length > 0 && (
            <CommentsList comments={sectorComments} color={"text-red-500"} />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="primaryBusinessActivity.sector"
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="division"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Division
              {divisionComments.length > 0 && (
                <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-2 -right-5">
                  <span>{sectorComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <Field
            component={ReactSelectWithFormik}
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            name="division"
            id="division"
            options={dropdownData.dropdown.divisions}
            value={selectedDivision}
            isDisabled={values.resolvedFields.includes("division") || disabled}
            onChange={handleSelect("division")}
            isSearchable
            placeholder="Select division"
          />
          {divisionComments.length > 0 && (
            <CommentsList comments={divisionComments} color={"text-red-500"} />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="primaryBusinessActivity.division"
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="group"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Group
              {groupComments.length > 0 && (
                <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-2 -right-5">
                  <span>{groupComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <Field
            id="group"
            component={ReactSelectWithFormik}
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            name="group"
            options={dropdownData.dropdown.groups}
            value={selectedGroup}
            isDisabled={values.resolvedFields.includes("group") || disabled}
            onChange={handleSelect("group")}
            isSearchable
            placeholder="Select group"
          />
          {groupComments.length > 0 && (
            <CommentsList comments={groupComments} color={"text-red-500"} />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="primaryBusinessActivity.group"
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="businessClass"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Class
              {classComments.length > 0 && (
                <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-2 -right-5">
                  <span>{classComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <Field
            component={ReactSelectWithFormik}
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            name="businessClass"
            id="businessClass"
            options={dropdownData.dropdown.classes}
            value={selectedBusinessClass}
            isDisabled={
              values.resolvedFields.includes("businessClass") || disabled
            }
            onChange={handleSelect("businessClass")}
            isSearchable
            placeholder="Select class"
          />
          {classComments.length > 0 && (
            <CommentsList comments={classComments} color={"text-red-500"} />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="primaryBusinessActivity.businessClass"
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="targetBusinessStartDate"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Target Business Start Date
              {businessStartDateComments.length > 0 && (
                <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-2 -right-5">
                  <span>{businessStartDateComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <DatePickerField
            name="targetBusinessStartDate"
            id="targetBusinessStartDate"
            selected={selectedDate}
            onChange={handleDateValue("targetBusinessStartDate")}
            onSelect={handleDateValue("targetBusinessStartDate")}
            disabled={
              values.resolvedFields.includes("targetBusinessStartDate") ||
              disabled
            }
            minDate={new Date()}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select target business start date"
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            autoComplete="off"
            showYearDropdown
            scrollableMonthYearDropdown
          />
          {businessStartDateComments.length > 0 && (
            <CommentsList
              comments={businessStartDateComments}
              color={"text-red-500"}
            />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="primaryBusinessActivity.targetBusinessStartDate"
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="accountingEndMonth"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Accounting Period End Month
              {accountingEndMonthComments.length > 0 && (
                <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-2 -right-5">
                  <span>{accountingEndMonthComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <Field
            onChange={handleSelect("accountingEndMonth")}
            component={ReactSelectWithFormik}
            isDisabled={
              values.resolvedFields.includes("accountingEndMonth") || disabled
            }
            options={addressOptions.months}
            value={accountingEndMonth}
            id="accountingEndMonth"
            name="accountingEndMonth"
            placeholder="Select month"
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {accountingEndMonthComments.length > 0 && (
            <CommentsList
              comments={accountingEndMonthComments}
              color={"text-red-500"}
            />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="primaryBusinessActivity.accountingEndMonth"
          />
        </div>
      </div>
    </>
  );
}

export default PrimaryBusinessActivity;
