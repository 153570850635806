const statutoryRecords = [
  {
    id: "registrationDocuments",
    parentCategory: "minuteBook",
    label: "Registration Documents",
    disabled: true,
  },
  {
    id: "incorporationForms",
    label: "Incorporation Forms",
    parentCategory: "minuteBook",
    disabled: true,
  },
  {
    id: "statutoryFilings",
    label: "Statutory Filings",
    parentCategory: "minuteBook",
    disabled: true,
  },
  {
    id: "trademarks",
    label: "Trademarks",
    parentCategory: "minuteBook",
    disabled: true,
  },
];

const shareholderResolutionsAndConsents = [
  {
    id: "shareholdersResolutions",
    label: "Shareholders Resolutions",
    parentCategory: "minuteBook",
    disabled: true,
  },
  {
    id: "shareholdersConsents",
    label: "Shareholders Concents",
    parentCategory: "minuteBook",
    disabled: true,
  },
];

const directorsResolutionsAndConsents = [
  {
    id: "directorsResolutions",
    label: "Directors Resolutions",
    parentCategory: "minuteBook",
    disabled: true,
  },
  {
    id: "boardCommitteesResolutions",
    label: "Board Committees' Resolutions",
    parentCategory: "minuteBook",
    disabled: true,
  },
  {
    id: "directorsConsents",
    label: "Directors Consents/Agreement",
    parentCategory: "minuteBook",
    disabled: true,
  },
];

const statutoryRegisters = [
  {
    id: "registerOfDirectorsAndSecretaries",
    parentCategory: "statutoryRegisters",
    label: "Register of Directors and Secretaries",
    renderMinuteBook: true,
    nextRegister: "registerOfDirectorsAndSecretariesResidences",
    breadCrumbs: [
      {
        id: "minuteBook",
        label: "Minute Book",
        disabled: true,
      },
      {
        id: "statutoryRegisters",
        label: "Statutory Registers",
        parentCategory: "minuteBook",
        disabled: true,
      },
      {
        id: "registerOfDirectorsAndSecretaries",
        label: "Register of Directors and Secretaries",
        parentCategory: "statutoryRegisters",
        disabled: true,
      },
    ],
  },
  {
    id: "registerOfDirectorsAndSecretariesResidences",
    parentCategory: "statutoryRegisters",
    label: "Register of Directors' and Secretary's Residences",
    renderMinuteBook: true,
    prevRegister: "registerOfDirectorsAndSecretaries",
    nextRegister: "registerOfMembers",
    breadCrumbs: [
      {
        id: "minuteBook",
        label: "Minute Book",
        disabled: true,
      },
      {
        id: "statutoryRegisters",
        label: "Statutory Registers",
        parentCategory: "minuteBook",
        disabled: true,
      },
      {
        id: "registerOfDirectorsAndSecretariesResidences",
        label: "Register of Directors' & Secretary's Residences",
        parentCategory: "statutoryRegisters",
        disabled: true,
      },
    ],
  },
  {
    id: "registerOfMembersAndShareLeger",
    parentCategory: "statutoryRegisters",
    label: "Register of Members and Share Ledger",
    renderMinuteBook: true,
    breadCrumbs: [],
    children: [
      {
        id: "registerOfMembers",
        parentCategory: "registerOfMembersAndShareLeger",
        label: "Register of Members",
        renderMinuteBook: true,
        prevRegister: "registerOfDirectorsAndSecretariesResidences",
        nextRegister: "registerOfApplicationsAndAllotments",
        breadCrumbs: [
          {
            id: "minuteBook",
            label: "Minute Book",
            disabled: true,
          },
          {
            id: "statutoryRegisters",
            label: "Statutory Registers",
            parentCategory: "minuteBook",
            disabled: true,
          },
          {
            id: "registerOfMembersAndShareLedger",
            label: "Register of Members and Share Ledger",
            parentCategory: "statutoryRegisters",
            disabled: true,
          },
          {
            id: "registerOfMembers",
            label: "REGISTER OF MEMBERS",
            parentCategory: "registerOfMembersAndShareLedger",
            disabled: true,
          },
        ],
      },
      {
        id: "registerOfApplicationsAndAllotments",
        parentCategory: "registerOfMembersAndShareLeger",
        label: "Register of Applications and Allotments",
        renderMinuteBook: true,
        prevRegister: "registerOfMembers",
        nextRegister: "registerOfBeneficialOwners",
        breadCrumbs: [
          {
            id: "minuteBook",
            label: "Minute Book",
            disabled: true,
          },
          {
            id: "statutoryRegisters",
            label: "Statutory Registers",
            parentCategory: "minuteBook",
            disabled: true,
          },
          {
            id: "registerOfMembersAndShareLedger",
            label: "Register of Members and Share Ledger",
            parentCategory: "statutoryRegisters",
            disabled: true,
          },
          {
            id: "registerOfTransfers",
            label: "Register of Applications and Allotments",
            parentCategory: "registerOfMembersAndShareLedger",
            disabled: true,
          },
        ],
      },
      {
        id: "registerOfTransfers",
        parentCategory: "registerOfMembersAndShareLeger",
        label: "Register of Transfers",
        renderMinuteBook: true,
        prevRegister: "registerOfApplicationsAndAllotments",
        nextRegister: "registerOfChargesMortgages",
        breadCrumbs: [
          {
            id: "minuteBook",
            label: "Minute Book",
            disabled: true,
          },
          {
            id: "statutoryRegisters",
            label: "Statutory Registers",
            parentCategory: "minuteBook",
            disabled: true,
          },
          {
            id: "registerOfMembersAndShareLedger",
            label: "Register of Members and Share Ledger",
            parentCategory: "statutoryRegisters",
            disabled: true,
          },
          {
            id: "registerOfTransfers",
            label: "Register of Transfers",
            parentCategory: "registerOfMembersAndShareLedger",
            disabled: true,
          },
        ],
      },
    ],
  },
  {
    id: "registerOfChargesMortgages",
    parentCategory: "statutoryRegisters",
    label: "Register of Charges / Mortgages",
    renderMinuteBook: true,
    prevRegister: "registerOfTransfers",
    nextRegister: "registerOfDebentures",
    breadCrumbs: [
      {
        id: "minuteBook",
        label: "Minute Book",
        disabled: true,
      },
      {
        id: "statutoryRegisters",
        label: "Statutory Registers",
        parentCategory: "minuteBook",
        disabled: true,
      },
      {
        id: "registerOfChargesMortgages",
        label: "Register of Charges / Mortgages",
        parentCategory: "statutoryRegisters",
        disabled: true,
      },
    ],
  },
  {
    id: "registerOfDebentures",
    parentCategory: "statutoryRegisters",
    label: "Register of Debentures",
    renderMinuteBook: true,
    prevRegister: "registerOfApplicationsAndAllotments",
    nextRegister: "registerOfBeneficialOwners",
    breadCrumbs: [
      {
        id: "minuteBook",
        label: "Minute Book",
        disabled: true,
      },
      {
        id: "statutoryRegisters",
        label: "Statutory Registers",
        parentCategory: "minuteBook",
        disabled: true,
      },
      {
        id: "registerOfDebentures",
        label: "Register of Debentures",
        parentCategory: "statutoryRegisters",
        disabled: true,
      },
    ],
  },
  {
    id: "registerOfBeneficialOwners",
    parentCategory: "statutoryRegisters",
    label: "Register of Beneficial Owners",
    renderMinuteBook: true,
    prevRegister: "registerOfApplicationsAndAllotments",
    nextRegister: "shareCertificates",
    breadCrumbs: [
      {
        id: "minuteBook",
        label: "Minute Book",
        disabled: true,
      },
      {
        id: "statutoryRegisters",
        label: "Statutory Registers",
        parentCategory: "minuteBook",
        disabled: true,
      },
      {
        id: "registerOfBeneficialOwners",
        label: "Register of Beneficial Owners",
        parentCategory: "statutoryRegisters",
        disabled: true,
      },
    ],
  },
];

export const minuteBookSidebarItems = [
  {
    id: "statutoryRecords",
    label: "Statutory Records",
    renderMinuteBook: true,
    children: statutoryRecords,
  },
  {
    id: "shareholderResolutionsAndConsents",
    label: "Shareholder Resolutions and Consents",
    children: shareholderResolutionsAndConsents,
  },
  {
    id: "directorsResolutionsAndConsents",
    label: "Director Resolutions and Consents",
    children: directorsResolutionsAndConsents,
  },
  {
    id: "statutoryRegisters",
    label: "Statutory Registers",
    children: statutoryRegisters,
  },
  {
    id: "shareCertificates",
    label: "Share Certificates",
    prevRegister: "registerOfBeneficialOwners",
    renderMinuteBook: true,
    breadCrumbs: [
      {
        id: "minuteBook",
        label: "Minute Book",
        disabled: true,
      },
      {
        id: "minuteBook",
        label: "Share Certificates",
        parentCategory: "minuteBook",
        disabled: true,
      },
    ],
  },
  {
    id: "agreementsAndContracts",
    label: "Agreements & Contracts",
    disabled: true,
  },
  {
    id: "policies",
    label: "Policies",
    disabled: true,
  },
];
