/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { format } from "date-fns";

import { getCompaniesForCorrectionsAsync } from "state/slices/tables/reviewersTable";
import ReactTable from "components/lib/Global/ReactTable";
import SearchInput from "components/lib/Shared/SearchInput";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { getCompanyNameWithSuffix } from "utils/company";
import { Constants } from "config/constants";
import EmptyTableState from "components/lib/Shared/EmptyTableState";
import { setCookie } from "utils/cookie";

const ReviewApplicationCorrections = ({ setSelectedCompany = () => null }) => {
  const { companiesForCorrections, getCompaniesForCorrections } = useSelector(
    (state) => state.reviewersTable
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const currentUser = useSelector((state) => state.userSlice);

  const columns = [
    {
      Header: "Full Name",
      accessor: "companyName1",
      Cell: ({ row: { original } }) => getCompanyNameWithSuffix(original),
    },
    {
      Header: "Application Type",
      accessor: "applicationType",
      Cell: ({ row }) => (
        <div className="inline-flex items-center justify-center w-full px-3 py-1 mx-auto text-sm font-medium text-white rounded-full bg-green-primary">
          {row?.original?.type}
        </div>
      ),
    },
    {
      Header: "Individual Officials",
      Cell: ({ row: { original } }) =>
        original?.individualOfficials?.length ?? 0,
    },
    {
      Header: "Corporate Officials",
      Cell: ({ row: { original } }) =>
        original?.corporateOfficials?.length ?? 0,
    },
    {
      Header: "Date",
      Cell: ({ row }) => format(new Date(row.original.createdAt), "yyyy/MM/dd"),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <TableActionMenu
          row={row}
          showCorrectedBtn
          handleCorrected={() => {
            setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
            history.push(`/companies/${row.original._id}`);
          }}
          hideEditBtn={true}
          hideDeleteBtn={true}
        />
      ),
    },
  ];

  useEffect(() => {
    if (debouncedSearchTerm) setCurrentPage(1);
    dispatch(
      getCompaniesForCorrectionsAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm,
        order: "asc",
      })
    );
  }, [
    currentUser,
    rowsPerPage,
    currentPage,
    setSelectedCompany,
    debouncedSearchTerm,
  ]);

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      {companiesForCorrections.totalCount > 0 ? (
        <div className="w-full bg-white shadow">
          <div className="w-full">
            <ReactTable
              columns={columns}
              data={companiesForCorrections.items}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={companiesForCorrections.totalCount}
              setCurrentPage={setCurrentPage}
              paginate
              headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
              headingStyle="pl-4 text-left"
              dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
              isLoading={getCompaniesForCorrections.status === "loading"}
            />
          </div>
        </div>
      ) : (
        <>
          {getCompaniesForCorrections.status === "loading" ? (
            <div className="z-10 flex items-center justify-center mt-10">
              <SpinnerIcon className="text-gray-400" />
            </div>
          ) : (
            <EmptyTableState>No companies</EmptyTableState>
          )}
        </>
      )}
    </div>
  );
};

export default ReviewApplicationCorrections;
