/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _set from "lodash/set";
import axios from "axios";

import corporateDataService from "services/corporate";
import { setOrganizationPartner } from "./tables/partnersSlice";

export const inviteOrganization = createAsyncThunk(
  "organizations/inviteOrganization",
  async ({ data }, thunkAPI) => {
    try {
      const res = await corporateDataService.inviteOrganization(data);
      return res.data;
    } catch (error) {
      setTimeout(
        () =>
          thunkAPI.dispatch(
            updateOrganizationNestedField({
              updateEffectPath: "state.inviteOrganizationStatus",
              newNestedField: {
                status: "idle",
                error: false,
              },
            })
          ),
        5000
      );
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendInviteNotification = createAsyncThunk(
  "organizations/sendInviteNotification",
  async ({ data }) => {
    const res = await corporateDataService.sendInviteNotification(data);
    return res.data;
  }
);

export const submitOrganization = createAsyncThunk(
  "organizations/submitOrganization",
  async ({ data }) => {
    const res = await corporateDataService.submitOrganization(data);
    return res.data;
  }
);

export const getCorporate = createAsyncThunk(
  "organizations/get",
  async ({ corporateId, isDashboard }, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const res = await corporateDataService.get(
        corporateId,
        isDashboard,
        source.token
      );
      if (res) {
        const partners = [
          ...(res.data?.corporate?.individualOfficials ?? []),
          ...(res.data?.corporate?.corporateOfficials ?? []),
        ];
        thunkAPI.dispatch(setOrganizationPartner(partners));
        return res.data;
      }
    } catch (error) {
      if (error.status === 404) {
        window.location.href = "/404";
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCorporate = createAsyncThunk(
  "organizations/update",
  async ({ id, data }) => {
    const res = await corporateDataService.update(id, data);
    return res.data;
  }
);

export const approveOrganization = createAsyncThunk(
  "organizations/update",
  async ({ data }) => {
    const res = await corporateDataService.approveOrganization(data);
    return res.data;
  }
);

export const confirmOrganizationReviewer = createAsyncThunk(
  "reviewers/confirmOrganizationReviewer",
  async ({ token }) => {
    const res = await corporateDataService.confirmOrganizationReviewer(token);
    return res.data;
  }
);

const initialState = {
  breadCrumbs: [],
  organizations: {
    items: [],
    totalCount: 0,
  },
  organization: null,
  dashboardRole: "VOR",
  dashboardPermissions: [],
  getOrganization: {
    status: "idle",
    error: false,
  },
  inviteOrganizationStatus: {
    status: "idle",
    error: false,
  },
};

const organizationsSlice = createSlice({
  name: "corporate",
  initialState,
  reducers: {
    updateOrganizationsState: (state, action) => {
      state.organizations = action.payload.organizations;
    },
    updateOrganizationState: (state, action) => {
      state.organization = { ...state.organization, ...action.payload };
    },
    updateOrganizationNestedField: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
    resetOrganization: (state) => {
      state.organization = null;
      state.getOrganization = {
        status: "idle",
        error: false,
      };
    },
    reset: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getCorporate.pending]: (state) => {
      state.getOrganization.status = "loading";
      state.getOrganization.error = false;
    },
    [getCorporate.fulfilled]: (state, action) => {
      state.getOrganization.status = "idle";
      state.getOrganization.error = false;
      state.organization = {
        ...state.organization,
        ...action.payload.corporate,
      };
      state.dashboardRole = action.payload.dashboardRole;
      state.dashboardPermissions = action.payload.dashboardPermissions;
      state.breadCrumbs = action.payload?.breadCrumbs ?? [];
    },
    [getCorporate.rejected]: (state, action) => {
      state.getOrganization.status = "failed";
      state.getOrganization.error = action.payload?.messageText;
    },
    [inviteOrganization.pending]: (state) => {
      state.inviteOrganizationStatus.status = "loading";
      state.inviteOrganizationStatus.error = false;
    },
    [inviteOrganization.fulfilled]: (state, action) => {
      state.inviteOrganizationStatus.status = "idle";
      state.inviteOrganizationStatus.error = false;
      state.organizations.items.push({
        corporate: action.payload.corporate,
        role: 11,
      });
      state.organizations.totalCount += 1;
    },
    [inviteOrganization.rejected]: (state, action) => {
      state.inviteOrganizationStatus.status = "failed";
      state.inviteOrganizationStatus.error = action.payload.messageText;
    },
  },
});

export const {
  reset,
  updateOrganizationsState,
  updateOrganizationState,
  updateOrganizationNestedField,
  resetOrganization,
} = organizationsSlice.actions;
const { reducer } = organizationsSlice;

export default reducer;
