import React from 'react'
import DashboardWrapper from 'components/lib/Global/DashboardWrapper'
import CompaniesList from 'components/CompaniesList'

export default function index() {
  return (
    <DashboardWrapper>
      <CompaniesList />
    </DashboardWrapper>
  )
}
