/* eslint-disable multiline-ternary */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _uniq from "lodash/uniq";
import Cookies from "js-cookie";
import { PlusIcon } from "@heroicons/react/solid";

import ShareSelection from "./NominalShareSelection";
import SharesTable from "./NominalSharesTable";
import SharesCategoryTabs from "./SharesCategoryTabs";
import ViewShare from "./ViewShare";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import { getTakenShares, roundNumber } from "utils";
import ShareInformationTopSection from "components/lib/Global/ShareInformationTopSection";
import CommentsList from "components/lib/Global/CommentsList";
import ErrorBanner from "components/lib/Shared/ErrorBanner";
import Button from "components/lib/Shared/Button";
import { Constants } from "config/constants";
import { validateNominalSharesRestrictions } from "utils/nominalShares";
import { openModal } from "state/slices/modals";
import { updateNominalSharesSlice } from "state/slices/tables/nominalSharesTable";

const index = ({
  handleBackBtn,
  handleNextBtn,
  submitBtnLabel,
  isSubmitting,
}) => {
  const role = Cookies.get("role");
  const { shares } = useSelector((state) => state).sharesTableSlice;
  const [showAddMoreSharesBtn, setShowAddMoreSharesBtn] = useState(true);
  const [mode, setMode] = useState({ editing: false, id: "" });
  const [selectedShare, setSelectedShare] = useState({});
  const [formStepErrors, setFormStepErrors] = useState([]);

  const { comments } = useSelector((state) => state.commentsSlice);
  const dispatch = useDispatch();
  const shareInformationComments = comments.filter(
    (comment) => comment.formName === "shareInformation" && !comment.isResolved
  );
  const takenShares = getTakenShares(
    useSelector((state) => state.companyOfficialsSlice.companyOfficials.items)
  );

  const sumNominalCapital = shares
    .map((share) => share.sharesValue * share.sharesNumber)
    .reduce((a, b) => a + b, 0);

  const sumOfVotingRights = shares.reduce(
    (acc, share) => roundNumber(acc + Number(share.votingRights)),
    0
  );

  const validateTab = (goToNextStep = false) => {
    setFormStepErrors([]);
    const { errors } = validateNominalSharesRestrictions({ shares });
    if (errors.length !== 0) setFormStepErrors(errors);
    else if (goToNextStep) handleNextBtn();
  };

  useEffect(() => {
    if (formStepErrors.length > 0) {
      validateTab();
    }
  }, [formStepErrors.length, shares]);

  const handleEdit = (row) => {
    dispatch(openModal({ modalName: "main_shares_modal_edit" }));
    dispatch(updateNominalSharesSlice({ activeTab: "" }));
    setMode({
      editing: true,
      id: row._id,
    });
  };

  const handleView = (row) => {
    dispatch(openModal({ modalName: "main_shares_modal_view" }));
    dispatch(updateNominalSharesSlice({ activeTab: "" }));
    setSelectedShare(row);
  };

  const handleSubmit = () => {
    validateTab(true);
  };

  const handleAddClassOfShares = () => {
    setShowAddMoreSharesBtn(false);
    dispatch(updateNominalSharesSlice({ activeTab: "" }));
  };

  return (
    <>
      <ErrorBanner errors={formStepErrors} className="mb-2" />
      <div data-label="Share Capital Information " className="df-example">
        <div className="pb-4 p-1-0 p-r-0">
          {shareInformationComments.length > 0 && (
            <div className="px-8 pt-5 pb-6 mb-4 bg-white">
              <CommentsList
                comments={shareInformationComments}
                color="text-red-500"
              />
              <p className="mt-6 text-sm text-primary">
                Comments: {shareInformationComments.length} of{" "}
                {shareInformationComments.length}
              </p>
            </div>
          )}
          {shares.length > 0 && (
            <ShareInformationTopSection
              sectionNumber="3"
              sumNominalCapital={sumNominalCapital}
              sumOfVotingRights={sumOfVotingRights}
            />
          )}
          {showAddMoreSharesBtn && shares.length === 0 && (
            <div className="w-2/3 space-y-2">
              <div className="inline-flex px-6 py-3 bg-white rounded shadow-sm">
                <p className="text-gray-800">
                  We have no current share capital information to allocate.
                  Please click the{" "}
                  <b className="text-secondary">+ Add class of shares</b> to add
                  nominal capital.
                </p>
              </div>
            </div>
          )}
          {showAddMoreSharesBtn && shares.length > 0 && (
            <SharesTable
              handleEdit={handleEdit}
              takenShares={takenShares}
              handleView={handleView}
              showActionColumn={role !== Constants.COOKIE_ROLES.OFFICIAL}
            />
          )}
          <ModalWrapper
            name="main_shares_modal_view"
            title="Share Capital Information"
          >
            <ViewShare selectedShare={selectedShare} />
          </ModalWrapper>
          <ModalWrapper
            name="main_shares_modal_edit"
            title="Edit Share Capital Information"
            maxWidth="sm:max-w-[45rem]"
          >
            <ShareSelection
              setShowAddMoreSharesBtn={setShowAddMoreSharesBtn}
              mode={mode}
              takenShares={takenShares}
            />
          </ModalWrapper>
          {!showAddMoreSharesBtn ? (
            <ShareSelection
              setShowAddMoreSharesBtn={setShowAddMoreSharesBtn}
              takenShares={takenShares}
            />
          ) : (
            role !== Constants.COOKIE_ROLES.OFFICIAL && (
              <Button
                variant="text"
                disabled={role === Constants.COOKIE_ROLES.OFFICIAL}
                onClick={handleAddClassOfShares}
                className="mt-4"
              >
                <PlusIcon className="w-6 h-6" aria-hidden="true" />
                <span>
                  {shares.length > 0
                    ? "Add new class of shares"
                    : "Add class of shares"}
                </span>
              </Button>
            )
          )}
        </div>
      </div>
      <hr />

      {shares.length > 0 && (
        <SharesCategoryTabs
          categories={_uniq(shares.slice(0).map((row) => row.sharesCategory))}
          disabledRightsEditor={role === Constants.COOKIE_ROLES.OFFICIAL}
          disableTabs={!showAddMoreSharesBtn}
        />
      )}
      <div className="flex justify-between py-3">
        <Button
          onClick={handleBackBtn}
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Go back
        </Button>
        <Button
          type="submit"
          isLoading={isSubmitting}
          onClick={handleSubmit}
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
        >
          {role === Constants.COOKIE_ROLES.OFFICIAL
            ? "Next step"
            : submitBtnLabel}
        </Button>
      </div>
    </>
  );
};

export default index;
