/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _omit from "lodash/omit";

import nominalShareDataService from "services/nominalShare";

export const addNominalShareAsync = createAsyncThunk(
  "nominalShares/addNominalShareAsync",
  async ({ companyId, data }) => {
    const res = await nominalShareDataService.create({ companyId, ...data });
    return res.data;
  }
);

export const updateNominalShareAsync = createAsyncThunk(
  "nominalShares/updateNominalShareAsync",
  async ({ id, data }) => {
    const res = await nominalShareDataService.update(
      id,
      _omit(data, [
        "_id",
        "_v",
        "createdAt",
        "createdBy",
        "updatedAt",
        "rightsAttached",
      ])
    );
    return res.data;
  }
);

export const deleteNominalShareAsync = createAsyncThunk(
  "nominalShares/deleteNominalShareAsync",
  async ({ id }) => {
    await nominalShareDataService.remove(id);
    return { id };
  }
);

const sharesTableSlice = createSlice({
  name: "Nominal Shares Table",
  initialState: {
    activeTab: "",
    shares: [],
  },
  reducers: {
    addNominalShare: (state, action) => {
      state.shares.push(action.payload.nominalShare);
    },
    updateNominalShare: (state, { payload: { nominalShare } }) => {
      const id = nominalShare._id;
      const index = state.shares.findIndex((item) => id === item._id);
      if (index > -1) state.shares.splice(index, 1, nominalShare);
    },
    deleteNominalShare: (state, { payload }) => {
      const index = state.shares.findIndex((item) => payload._id === item._id);
      if (index > -1) state.shares.splice(index, 1);
    },
    updateNominalSharesSlice: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setNominalShares: (state, action) => {
      state.shares = action.payload;
    },
    resetSharesTable: (state) => {
      state.shares = [];
    },
  },
  extraReducers: {
    [addNominalShareAsync.fulfilled]: (state, action) => {
      state.shares.push(action.payload.nominalShare);
    },
    [updateNominalShareAsync.fulfilled]: (state, { payload }) => {
      const nominalShareId = payload.id || payload._id;
      const { shares } = state;
      const index = shares.findIndex(
        (item) => nominalShareId === item.id || nominalShareId === item._id
      );
      if (index > -1) {
        shares.splice(index, 1, { ...payload });
      }
    },
    [deleteNominalShareAsync.fulfilled]: (state, { payload }) => {
      const nominalShareId = payload.id || payload._id;
      const { shares } = state;
      const index = shares.findIndex(
        (item) => nominalShareId === item.id || nominalShareId === item._id
      );
      if (index > -1) shares.splice(index, 1);
    },
  },
});

export const {
  addNominalShare,
  updateNominalShare,
  deleteNominalShare,
  updateNominalSharesSlice,
  setNominalShares,
  resetSharesTable,
} = sharesTableSlice.actions;

export default sharesTableSlice.reducer;
