import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getFullName } from "utils";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import CompanyStakeholderTable from "./companyStakeholderTable";
import Button from "components/lib/Shared/Button";
import MinorityDetails from "./MinorityDetails";

export default function StakeholderDetails() {
  const { topTenShareholders } = useSelector(
    (state) => state.organizationsSlice.organization
  );
  const [minorityView, setMinorityView] = useState(false);
  const JSGroups = new Set();
  topTenShareholders.forEach((item) => {
    if (item.JSGroupID) JSGroups.add(item.JSGroupID);
  });

  const allJS = [...JSGroups].map((id) => {
    const names = [];
    let combinedJS = {};
    topTenShareholders.forEach((official) => {
      if (official.JSGroupID === id) {
        names.push(getFullName(official));
        if (official.sharesAllotted && official.sharesAllotted.length > 0)
          combinedJS.sharesAllotted = official.sharesAllotted;
        combinedJS = {
          ...combinedJS,
          ..._omit(_cloneDeep(official), ["sharesAllotted"]),
          designation: "Joint Shareholder",
          idType: "Joint Shareholder",
        };
        combinedJS.fullName = names.map((name, index) => {
          if (names.length === 2 && index === 0) {
            return `${name} & `;
          }
          if (names.length > 2 && index === names.length - 2) {
            return `${name} & `;
          }

          if (
            names.length > 2 &&
            index !== names.length - 2 &&
            index !== names.length - 1
          ) {
            return `${name}, `;
          }
          return name;
        });
      }
    });
    return combinedJS;
  });

  const data = [
    ...topTenShareholders.filter(
      (item) =>
        (item.designation === "Joint Shareholder" &&
          item.isDirector === "Yes") ||
        (item.designation !== "Joint Shareholder" && item.idType !== "Minority")
    ),
    ...allJS.filter((item) => !_isEmpty(item)),
  ];

  const minorityData = topTenShareholders.filter(
    (minority) => minority.idType === "Minority"
  );

  const handleMinorityEntry = () => {
    setMinorityView((minorityView) => !minorityView);
  };

  return (
    <div className="mt-6">
      {data.length === 0 ? (
        <p className="text-center">No data available</p>
      ) : (
        <CompanyStakeholderTable data={data} currentTab="stakeholderTab" />
      )}
      {minorityData?.length !== 0 && (
        <>
          <hr className="h-px mt-6 bg-gray-200 border-0 dark:bg-gray-700" />
          <div className="mb-6 flex flex-col">
            <div className="flex flex-col justify-between py-4">
              <Button
                variant="primary"
                className="max-w-fit h-8 py-2"
                onClick={handleMinorityEntry}
              >
                {minorityView ? "Close" : " View minority entry"}
              </Button>
            </div>
            {minorityView && (
              <div className="mt-4">
                <MinorityDetails
                  data={minorityData}
                  currentTab="stakeholderTab"
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
