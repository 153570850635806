import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Hero from "components/lib/Global/Hero";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import ApplicationReviewTab from "components/PLCTABS/ApplicationReviewTab";
import Unauthorized from "pages/Unauthorized";
import { getCompany, reset } from "state/slices/company";
import { getComments, resetCommentsSlice } from "state/slices/comment";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import {
  getReviewers,
  resetReviewersTableSlice,
} from "state/slices/tables/reviewersTable";
import { Constants } from "config/constants";
import { classNames } from "utils";
import { setCookie } from "utils/cookie";

const navigation = [{ id: "applicationReview", name: "Application Review" }];

const ApplicationReview = () => {
  const { companyId } = useParams();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(navigation[0]);
  const currentUser = useSelector((state) => state.userSlice);
  const { getCompanyStatus } = useSelector((state) => state.companySlice);

  const role = Cookies.get("role");

  const handleBackBtn = () => {
    if (currentSectionIndex - 1 >= 0)
      setCurrentSectionIndex(currentSectionIndex - 1);
  };

  const handleNextBtn = () => {
    setCurrentSectionIndex(currentSectionIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = navigation[currentSectionIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [navigation, currentSectionIndex]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (companyId && currentUser._id) {
      dispatch(getCompany({ id: companyId }));
      dispatch(getComments({ companyId, createdBy: currentUser._id }));
      dispatch(getReviewers({ companyId }));
    }
    return () => {
      setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
      dispatch(reset());
      dispatch(resetCommentsSlice());
      dispatch(resetReviewersTableSlice());
    };
  }, [companyId, currentUser._id]);

  if (
    !role ||
    ![
      Constants.COOKIE_ROLES.OFFICIAL,
      Constants.COOKIE_ROLES.APPLICANT,
      Constants.COOKIE_ROLES.REVIEWER,
      Constants.COOKIE_ROLES.SUPER_ADMIN,
      Constants.COOKIE_ROLES.ORG_ADMIN,
      Constants.COOKIE_ROLES.CO_APPLICANT,
    ].includes(role)
  )
    return <Unauthorized />;

  return (
    <DashboardWrapper showBreadCrumbs>
      {currentUser && currentUser?.isOnboarded && (
        <div className="grid gap-10 py-10 mx-auto">
          <Hero
            heading={selectedTab?.label ?? selectedTab?.name}
            description=""
          />
          <div className="grid grid-cols-6 border-t border-gray">
            <div className="col-span-6 pr-8 md:col-span-1 pt-14">
              <ul>
                {navigation.map((item, index) => (
                  <li
                    key={item.name}
                    className={classNames(
                      index === currentSectionIndex
                        ? "text-primary"
                        : "text-tertiary",
                      "mb-4 font-medium cursor-pointer hover:text-primary"
                    )}
                    onClick={() => setCurrentSectionIndex(index)}
                  >
                    <span className="truncate">{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-6 md:border-l md:col-span-5 md:pl-11 pt-14 border-gray">
              {selectedTab.id === "applicationReview" && (
                <>
                  {getCompanyStatus.status === "loading" ? (
                    <div className="flex justify-center mx-auto text-white">
                      <SpinnerIcon className="text-gray-400" />
                    </div>
                  ) : (
                    <ApplicationReviewTab
                      handleBackBtn={handleBackBtn}
                      handleNextBtn={handleNextBtn}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </DashboardWrapper>
  );
};

export default ApplicationReview;
