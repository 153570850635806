/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useFormikContext, Field } from "formik";
import { subYears, format } from "date-fns";

import DatePickerField from "components/lib/Shared/DatePickerField";
import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "../../../../../PLCTABS/SFLdata/selectOptions";

// Identification Details for Kenyan Citizen
const IdentificationDetailsKeCi = ({ field }) => {
  const maxDateOfBirth = subYears(new Date(), 18);

  const [startDate, setStartDate] = useState("");

  const { setFieldValue } = useFormikContext();

  const handleSetValue = (e) => {
    const value =
      e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: value,
    });
  };

  const handleSelect = (type) => (option) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
  };

  const handleDateValue = (date) => {
    setFieldValue(field.name, {
      ...field.value,
      dateOfBirth: format(date, "yyyy/MM/dd"),
    });
    return setStartDate(date);
  };

  const selectedDOB =
    field.value && field.value.dateOfBirth
      ? new Date(field.value.dateOfBirth)
      : startDate;

  return (
    <>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <DatePickerField
            label="Date of Birth"
            onChange={handleDateValue}
            selected={selectedDOB}
            maxDate={maxDateOfBirth}
            name="dateOfBirth"
            placeholderText="Select date of birth"
            errorPath="identificationDetails.dateOfBirth"
            disabled={true}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="National ID Number"
            name="nationalIDNumber"
            placeholder="Enter national id number"
            value={field.value?.nationalIDNumber ?? ""}
            errorPath="identificationDetails.nationalIDNumber"
            onBlur={field.onBlur}
            disabled={true}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="KRA PIN"
            name="KRAPin"
            placeholder="Enter KRA PIN"
            value={field.value?.KRAPin ?? ""}
            errorPath="identificationDetails.KRAPin"
            disabled={true}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Occupation"
            name="occupation"
            placeholder="Enter occupation"
            value={field.value?.occupation ?? ""}
            errorPath="identificationDetails.occupation"
            disabled={false}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Field
            {...field}
            label="Gender"
            onBlur={field.onBlur}
            onChange={handleSelect("gender")}
            value={field?.value?.gender ?? ""}
            name="gender"
            component={ReactSelectWithFormik}
            options={options.gender}
            errorPath="identificationDetails.gender"
            isDisabled={true}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Nationality"
            name="nationality"
            placeholder="KENYAN"
            value={"KENYAN"}
            errorPath="identificationDetails.nationality"
            onBlur={field.onBlur}
            onChange={handleSetValue}
            disabled={true}
          />
        </div>
      </div>
    </>
  );
};

export default IdentificationDetailsKeCi;
