import selectIdTypeValidation from "./selectIdTypeValidation";
import stateOwnedEnterpriseValidation from "./stateOwnedEnterpriseValidation";
import beneficialOwnershipValidation from "./beneficialOwnershipValidation";
import selectRoleValidation from "./selectRoleValidation";
import identificationValidation from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/identificationValidation";
import residentialDetailsValidation from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/residentialDetailsValidation";
import uploadDocumentsValidation from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/uploadDocumentsValidation";

/**
 * This returns an object composed of conditional `yup` schema,
 * based on the current tab of `Shareholder` and `Director Shareholder` officials.
 *
 * @param {string} selectedTab The current tab/step of the multi step form e.g `"generalInformation"`.
 * @param {string} idType The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {string} isNonResident Conditional string value `'No' || 'Yes` of the current official.
 * @param {array} documentData The array of objects related to file uploads.
 * @param {object} beneficialOwnershipForm - Object containing fields related to BO form.
 * @param {number} allottedShares - Allotted shares.
 * @param {number} allottedVotingRights - Alloted voting rights.
 * @param {string} corporateIdType The corporate id type e.g `"Company Limited By Guarantee/NGO"`.
 * @param {Boolean} renderLongForm Render long form `true` | `false`.
 * @return {object} This returns object containing schema.
 * @example
 *  {
 *    schema // yup schema
 *  }
 */
const getValidationSchema = ({
  selectedTab,
  idType,
  isNonResident = null,
  documentData,
  beneficialOwnershipForm,
  allottedShares,
  allottedVotingRights,
  corporateIdType,
  renderLongForm,
}) => {
  if (corporateIdType === "State Owned Enterprise") {
    return stateOwnedEnterpriseValidation().schema;
  }

  if (!selectedTab?.id && renderLongForm)
    return selectIdTypeValidation()
      .schema.concat(
        beneficialOwnershipValidation(
          idType,
          beneficialOwnershipForm,
          allottedShares,
          allottedVotingRights,
          renderLongForm,
          corporateIdType
        ).schema
      )
      .concat(selectRoleValidation().schema)
      .concat(identificationValidation(idType).schema)
      .concat(residentialDetailsValidation({ idType, isNonResident }).schema)
      .concat(uploadDocumentsValidation(documentData).schema);

  if (!selectedTab?.id && !renderLongForm)
    return selectIdTypeValidation()
      .schema.concat(
        beneficialOwnershipValidation(
          idType,
          beneficialOwnershipForm,
          allottedShares,
          allottedVotingRights,
          renderLongForm,
          corporateIdType
        ).schema
      )
      .concat(selectRoleValidation().schema)
      .concat(identificationValidation(idType).schema)
      .concat(uploadDocumentsValidation(documentData).schema);

  switch (selectedTab?.id) {
    case "assignOfficials":
      return selectIdTypeValidation();
    case "beneficialOwnershipAllocation":
      return beneficialOwnershipValidation(
        idType,
        beneficialOwnershipForm,
        allottedShares,
        allottedVotingRights,
        renderLongForm,
        corporateIdType
      );
    case "selectRole":
      return selectRoleValidation();
    case "generalInformation":
      return identificationValidation(idType);
    case "residentialDetails":
      return residentialDetailsValidation({ idType, isNonResident });
    case "uploadDocuments":
      return uploadDocumentsValidation(documentData);
    default:
      return { schema: null };
  }
};

export default getValidationSchema;
