/* eslint-disable react/jsx-props-no-spreading */
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";

import addressOptions from "components/PLCTABS/SFLdata/addressOptions";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Input from "components/lib/Shared/Input";

// Postal Code without postal address
const PostalCodeForm = ({ form, field, disabled }) => {
  const { setFieldValue } = useFormikContext();
  const selectedPostalCode = addressOptions.postalCodes.find(
    (postalCode) => postalCode.value === field?.value?.postalCode
  );

  useEffect(() => {
    // for validation
    setFieldValue(field.name, {
      postalCode: "",
      serviceAddress: "",
      ...field.value,
    });
  }, []);

  const handleSelect = (option) => {
    setFieldValue(field.name, {
      ...field.value,
      postalCode: option.value,
    });
  };

  const handleSetValue = ({ target: { name, value } }) => {
    setFieldValue(field.name, {
      ...field.value,
      [name]: value,
    });
  };

  return (
    <>
      <div className="col-span-6 sm:col-span-2">
        <Field
          {...field}
          label="Postal Code"
          value={selectedPostalCode}
          name="postalCode"
          component={ReactSelectWithFormik}
          options={addressOptions.postalCodes}
          errorPath="postalAddress.postalCode"
          placeholder="Select postal code"
          onBlur={field.onBlur}
          onChange={handleSelect}
          isDisabled={disabled}
          isSearchable
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Service Address(If different from above address)"
          name="serviceAddress"
          placeholder="Enter service address"
          form={form}
          value={field.value?.serviceAddress ?? ""}
          errorPath="postalAddress.serviceAddress"
          disabled={disabled}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
    </>
  );
};

export default PostalCodeForm;
