import _get from "lodash/get";
import { useSelector } from "react-redux";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import CommentsList from "components/lib/Global/CommentsList";

const ContactPersonDetails = ({ comments, isViewMode, handleSaveComment }) => {
  const { organization } = useSelector((state) => state.organizationsSlice);

  const ContactPersonTabs = [
    {
      key: "Contact Designation",
      name: "companyContactDesignation",
      value:
        _get(organization, "identificationDetails.companyContactDesignation") ||
        "N/A",
    },
    {
      key: "Contact Name",
      name: "companyContactName",
      value:
        _get(organization, "identificationDetails.companyContactName") || "N/A",
    },
    {
      key: "Contact Email Address",
      name: "emailAddress",
      value: _get(organization, "identificationDetails.emailAddress") || "N/A",
    },
    {
      key: "Tel Phone Number",
      name: "telNumber",
      value: _get(organization, "identificationDetails.telNumber") || "N/A",
    },
  ];

  return (
    <div>
      {comments.length > 0 && (
        <div className="px-8 pt-5 pb-6 mt-4 bg-white">
          <CommentsList comments={comments} toggleCommentStatus={!isViewMode} />
          <p className="mt-6 text-sm text-primary">
            Comments: {comments?.length} of {comments?.length}
          </p>
        </div>
      )}
      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="identificationDetails"
        fields={ContactPersonTabs}
        resolvedFields={organization.identificationDetails?.resolvedFields}
        formHelperKey="identificationDetails"
        updateEffectKey="updateOrganizationState"
        updateEffectPath="organizationsSlice.organization.identificationDetails"
        formId={_get(organization.identificationDetails, "_id")}
        handleSaveComment={handleSaveComment}
        comments={comments}
        hideResolveAllCheckbox={isViewMode}
      />
    </div>
  );
};

export default ContactPersonDetails;
