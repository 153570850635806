import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PencilIcon, XIcon } from "@heroicons/react/outline";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import { format } from "date-fns";
import { Formik, Field } from "formik";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import Tag from "components/lib/Shared/Tag";
import {
  getCompanySecretaryDocumentData,
  getDocumentData,
} from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import CommentsPopup from "components/lib/Global/CommentsPopup";
import ReactTable from "components/lib/Shared/ReactTable";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import Tabs from "components/lib/Shared/Tabs";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";
import CommentsList from "components/lib/Global/CommentsList";
import StakeholderTable from "./StakeholderTable";
import { addComment, editComment, getComments } from "state/slices/comment";
import { resolveFields, invalidateFields } from "state/slices/resolveField";
import { getBeneficialOwnershipFormFields, getFormFields } from "utils";
import { Constants } from "config/constants";

function StakeholderSubRow({
  visibleColumns,
  row,
  currentTab,
  initialRows,
  handleBackBtn,
  hideReviewWorkflow,
  renderDateOfCeasingToBeABO,
  ...restProps
}) {
  const history = useHistory();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch();
  const isViewMode =
    history.location.pathname.split("/").pop() === "view" ||
    restProps.isViewMode;
  const isReviewerMode =
    history.location.pathname.split("/").pop() === "review";
  const isCompanyDashboard =
    history.location.pathname.split("/").pop() === "dashboard";
  const { comments } = useSelector((state) => state.commentsSlice);
  const currentUser = useSelector((state) => state.userSlice);

  const originalCompanyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );

  const { organization } = useSelector((state) => state.organizationsSlice);
  const { beneficialOwners } = organization;

  const { topTenShareholders } = organization;

  const stakeholderComments = comments.filter(
    (comment) =>
      comment?.officialId &&
      comment.officialId === row.original._id &&
      comment?.tabName &&
      comment.tabName === currentTab
  );

  let generalInformationComments = stakeholderComments.filter(
    (comment) =>
      comment.formName === "identificationDetails" ||
      comment.formName === "names"
  );

  const onlyDatesComments = stakeholderComments.filter(
    (comment) =>
      comment.formName === "beneficialOwnershipForm" &&
      (comment.fieldName === "dateOfBecomingBO" ||
        comment.fieldName === "dateOfCeasingToBeABO" ||
        comment.fieldName === "sourceOfBOInformation")
  );

  const fieldBOS = [
    "dateOfBecomingBO",
    "dateOfCeasingToBeABO",
    "sourceOfBOInformation",
  ];

  if (onlyDatesComments) {
    generalInformationComments =
      generalInformationComments.concat(onlyDatesComments);
  }
  const residentialDetailsComments = stakeholderComments.filter(
    (comment) =>
      comment.formName === "postalAddress" ||
      comment.formName === "electronicAddress" ||
      comment.formName === "residentialAddressDetails" ||
      comment.formName === "isNonResident"
  );
  const officialShareAllocationComments = stakeholderComments.filter(
    (comment) => comment.formName === "officialShareAllocation"
  );
  const officialBeneficialOwnershipComments = stakeholderComments.filter(
    (comment) =>
      comment.formName === "beneficialOwnershipForm" &&
      !fieldBOS.includes(comment.fieldName)
  );
  const fileUploadComments = stakeholderComments.filter(
    (comment) => comment.formName === "uploadDocuments"
  );
  const showOfficialShareAllocationTab =
    row.original?.sharesAllotted?.length > 0;
  const showBOTab = row.original?.beneficialOwnershipForm;

  const tabs = useMemo(() => {
    let tempTabs = [];
    if (
      ["Director", "Authorized Person", "Company Secretary"].includes(
        row.original.designation
      )
    ) {
      tempTabs = [
        {
          id: "generalInformation",
          label: "General information",
          commentsCount: generalInformationComments?.length,
        },
        {
          id: "residentialDetails",
          label: "Address details",
          commentsCount: residentialDetailsComments?.length,
        },
        {
          id: "uploadDocuments",
          label: "File Uploads",
          commentsCount: fileUploadComments?.length,
        },
      ];
    } else {
      tempTabs = [
        {
          id: "generalInformation",
          label: "General information",
          commentsCount: generalInformationComments?.length,
        },
        {
          id: "residentialDetails",
          label: "Address details",
          commentsCount: residentialDetailsComments?.length,
        },
        showOfficialShareAllocationTab
          ? {
              id: "officialShareAllocation",
              label: "Share information",
              commentsCount: officialShareAllocationComments?.length,
            }
          : null,
        showBOTab
          ? {
              id: "officialBeneficialOwnership",
              label: "Beneficial ownership",
              commentsCount: officialBeneficialOwnershipComments?.length,
            }
          : null,
        {
          id: "uploadDocuments",
          label: "File Uploads",
          commentsCount: fileUploadComments?.length,
        },
      ].filter(Boolean);
    }
    if (currentTab === "beneficialOwnership") {
      tempTabs = [
        {
          id: "generalInformation",
          label: "General information",
          commentsCount: generalInformationComments?.length,
        },
        {
          id: "residentialDetails",
          label: "Address details",
          commentsCount: residentialDetailsComments?.length,
        },
        {
          id: "officialBeneficialOwnership",
          label: "Beneficial ownership",
          commentsCount: officialBeneficialOwnershipComments?.length,
        },
        {
          id: "uploadDocuments",
          label: "File Uploads",
          commentsCount: fileUploadComments?.length,
        },
      ];
    }
    return tempTabs;
  }, [
    row.original.designation,
    currentTab,
    generalInformationComments?.length,
    residentialDetailsComments?.length,
    fileUploadComments?.length,
    showOfficialShareAllocationTab,
    officialShareAllocationComments?.length,
    showBOTab,
    officialBeneficialOwnershipComments?.length,
  ]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            corporate: organization._id,
            officialId: row.original._id,
            tabName: currentTab,
          },
        })
      );
    }
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  let dateOfBirth = _get(row.original.identificationDetails, "dateOfBirth");
  if (dateOfBirth) dateOfBirth = format(new Date(dateOfBirth), "yyyy/MM/dd");
  else dateOfBirth = "N/A";

  // approve/disapprove allotted shares to official
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRow] = useState([]);
  const [allFieldsResolved, setAllFieldsResolved] = useState(false);
  const formId = _get(row.original, "_id");
  const resolvedFields = _get(row.original, "resolvedFields", []);

  const fieldsName = row.original?.sharesAllotted
    ?.map((share) => share.classOfShares)
    .filter(Boolean); // all the shares category names

  useEffect(() => {
    setAllFieldsResolved(
      fieldsName?.every((element) => resolvedFields.includes(element))
    );
  }, [fieldsName, resolvedFields]);

  const jointShareholderForReview = originalCompanyOfficials.filter(
    (official) =>
      row.original?.JSGroupID &&
      official?.JSGroupID === row.original?.JSGroupID &&
      row.original.idType === "Joint Shareholder"
  );

  if (
    jointShareholderForReview.length > 0 &&
    row.original?.JSGroupID &&
    row.original.idType === "Joint Shareholder"
  ) {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="p-9">
          <StakeholderTable
            data={_sortBy(jointShareholderForReview, ["JSGroupOrder"])}
            currentTab={currentTab}
            isJointShareholderTable
          />
        </td>
      </tr>
    );
  }

  const officialIndex = topTenShareholders.findIndex(
    (official) => official._id === row.original._id
  );
  const officialSliceName = `organizationsSlice.organization.topTenShareholders.${officialIndex}`;
  const companyOfficialEffectKey = "updateOrganizationState";

  const handleResolveBtnClicked = async (fieldName) => {
    const commentsToBeResolved = officialShareAllocationComments?.filter(
      (comment) => comment.fieldName === fieldName && !comment.isResolved
    );
    const commentsToBeResolvedIds = commentsToBeResolved?.map(({ _id }) => _id);
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      resolveFields({
        data: {
          formId,
          formName:
            currentTab === "stakeholderTab"
              ? INDIVIDUALS.includes(row?.original?.idType)
                ? "individualOfficial"
                : "corporateOfficial"
              : "beneficialOwner",
          fieldName,
          resolvedFields: [fieldName],
          commentsToBeResolvedIds,
        },
        updateEffectKey: companyOfficialEffectKey,
        updateEffectPath: officialSliceName,
      })
    );
    if (commentsToBeResolvedIds.length > 0) {
      const query = { companyId: commentsToBeResolved[0]?.company };
      if (isReviewerMode) query.createdBy = currentUser?._id;
      await dispatch(getComments(query));
    }
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const handleInvalidateBtnClicked = async (fieldName) => {
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      invalidateFields({
        data: {
          formId,
          formName:
            currentTab === "stakeholderTab"
              ? INDIVIDUALS.includes(row?.original?.idType)
                ? "individualOfficial"
                : "corporateOfficial"
              : "beneficialOwner",
          fieldName,
          invalidFields: [fieldName],
        },
        updateEffectKey: companyOfficialEffectKey,
        updateEffectPath: officialSliceName,
      })
    );
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const handleAllFieldsStatus = async (e) => {
    const commentsToBeResolved = officialShareAllocationComments?.filter(
      (comment) => !comment.isResolved
    );
    const commentsToBeResolvedIds = commentsToBeResolved?.map(({ _id }) => _id);
    setIsLoading(true);
    if (e.target.checked) {
      // resolve/approve all the form fields
      const unresolvedFields = fieldsName.filter(
        (fieldName) => !resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [...selectedRows, ...unresolvedFields]);
      await dispatch(
        resolveFields({
          data: {
            formId,
            formName:
              currentTab === "stakeholderTab"
                ? INDIVIDUALS.includes(row?.original?.idType)
                  ? "individualOfficial"
                  : "corporateOfficial"
                : "beneficialOwner",
            resolvedFields: unresolvedFields,
            commentsToBeResolvedIds,
          },
          updateEffectKey: companyOfficialEffectKey,
          updateEffectPath: officialSliceName,
        })
      );
      if (commentsToBeResolvedIds.length > 0) {
        const query = { companyId: commentsToBeResolved[0]?.company };
        if (isReviewerMode) query.createdBy = currentUser?._id;
        await dispatch(getComments(query));
      }
      setIsLoading(false);
      setSelectedRow([]);
    } else {
      // invalidate/disapprove all the form fields
      const resolvedFieldsSlice = fieldsName.filter((fieldName) =>
        resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [
        ...selectedRows,
        ...resolvedFieldsSlice,
      ]);
      await dispatch(
        invalidateFields({
          data: {
            formId,
            formName:
              currentTab === "stakeholderTab"
                ? INDIVIDUALS.includes(row?.original?.idType)
                  ? "individualOfficial"
                  : "corporateOfficial"
                : "beneficialOwner",
            invalidFields: resolvedFieldsSlice,
          },
          updateEffectKey: companyOfficialEffectKey,
          updateEffectPath: officialSliceName,
        })
      );
      setSelectedRow([]);
    }
    setIsLoading(false);
  };

  const officialShareAllocationColumns = [
    {
      Header: "Class of shares",
      Cell: ({ row }) => row.original.classOfShares,
    },
    { Header: "Number of Shares", accessor: "allottedShares" },
    {
      Header: "Value Of Shares",
      Cell: ({ row }) => (
        <div className="inline-flex items-baseline">
          <span className="px-1 text-xs font-medium">KES</span>{" "}
          <Tag>{row.original.sharesValue}</Tag>
        </div>
      ),
    },
    { Header: "Paid Up Shares", accessor: "paidUpShares" },
    {
      Header: "Premium Paid",
      Cell: ({ row }) => (
        <div className="inline-flex items-baseline">
          <span className="px-1 text-xs font-medium">KES</span>{" "}
          <Tag>{row.original.sharePremium}</Tag>
        </div>
      ),
    },
    {
      Header: "Nominal Value",
      Cell: ({ row }) => (
        <div className="inline-flex items-baseline">
          <span className="px-1 text-xs font-medium">KES</span>{" "}
          <Tag>{row.original.nominalValue}</Tag>
        </div>
      ),
    },
    {
      Header: "Voting Rights",
      Cell: ({ row }) => row.original.votingRights,
    },
  ];

  if (!isViewMode && !isCompanyDashboard) {
    officialShareAllocationColumns.push({
      Header: "Action",
      Cell: ({ row }) => {
        const isRowLoading =
          isLoading && selectedRows.includes(row.original.classOfShares);
        return (
          <div className="flex items-center space-x-2">
            {!resolvedFields.includes(row.original.classOfShares) && (
              <CommentsPopup
                handleSaveComment={handleSaveComment}
                formName="officialShareAllocation"
                fieldName={row.original.classOfShares}
                fieldLabel={row.original.classOfShares}
                comment={officialShareAllocationComments.find(
                  (comment) =>
                    comment.fieldName === row.original.classOfShares &&
                    currentUser?._id === comment.createdBy?._id
                )}
              >
                <span>Review</span>
                <PencilIcon className="inline w-4 h-4" />
              </CommentsPopup>
            )}
            <div className={`flex items-center justify-center pr-4`}>
              {resolvedFields.includes(row.original.classOfShares) ? (
                <button
                  className={`flex space-x-1 ${
                    isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={() =>
                    isRowLoading
                      ? null
                      : handleInvalidateBtnClicked(row.original.classOfShares)
                  }
                >
                  {isRowLoading ? (
                    <SpinnerIcon className="text-primary" />
                  ) : (
                    <>
                      <span>Accepted</span>
                      <XIcon className="inline w-4 h-4" />
                    </>
                  )}
                </button>
              ) : (
                <button
                  className={`flex space-x-1 ${
                    isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={() =>
                    isRowLoading
                      ? null
                      : handleResolveBtnClicked(row.original.classOfShares)
                  }
                >
                  {isRowLoading ? (
                    <SpinnerIcon className="text-primary" />
                  ) : (
                    <>
                      <span>Accept</span>
                      <CheckIcon className="inline w-4 h-4" />
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        );
      },
    });
  }

  const handleConfirmDetails = () => {
    row.toggleRowExpanded();
    const nextRow = initialRows?.[row.index + 1] || null;
    if (nextRow) nextRow?.getToggleRowExpandedProps()?.onClick();
  };

  const roleFields = [
    {
      key: `Date Of Becoming a ${row.original.role}`,
      name: `dateOfBecomingBO`,
      value: row.original.beneficialOwnershipForm.dateOfBecomingBO
        ? format(
            new Date(row.original.beneficialOwnershipForm.dateOfBecomingBO),
            "yyyy-MM-dd"
          )
        : "N/A",
    },
    {
      key: `Date Of Ceasing to be a ${row.original.role}`,
      name: `dateOfCeasingToBeABO`,
      value: "N/A",
    },
  ];

  return (
    <tr className="bg-[#EDF0F5]">
      <td
        colSpan={visibleColumns.length}
        className={`px-9 ${isCompanyDashboard ? "pb-7" : ""}`}
      >
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "generalInformation" && (
          <>
            {generalInformationComments &&
              generalInformationComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={generalInformationComments}
                    toggleCommentStatus={!isViewMode}
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments: {generalInformationComments.length} of{" "}
                    {generalInformationComments.length}
                  </p>
                </div>
              )}
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType: row.original.idType,
                formType: "names",
                formValues: row.original.names,
              })}
              resolvedFields={_get(row.original.names, "resolvedFields")}
              addReviewComment={!isCompanyDashboard && !isViewMode}
              hideResolveAllCheckbox={isCompanyDashboard || isViewMode}
              formName="names"
              formHelperKey="names"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.names`}
              formId={_get(row.original.names, "_id")}
              comments={generalInformationComments.filter(
                (comment) => comment.formName === "names"
              )}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType: row?.original?.idType,
                formType: "identificationDetails",
                formValues: row?.original?.identificationDetails,
              })}
              resolvedFields={_get(
                row.original.identificationDetails,
                "resolvedFields"
              )}
              addReviewComment={!isCompanyDashboard && !isViewMode}
              hideResolveAllCheckbox={isCompanyDashboard || isViewMode}
              formName="identificationDetails"
              formHelperKey="identificationDetails"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.identificationDetails`}
              formId={_get(row.original.identificationDetails, "_id")}
              comments={generalInformationComments.filter(
                (comment) => comment.formName === "identificationDetails"
              )}
              files={row.original.fileUploads}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={roleFields}
              resolvedFields={_get(
                row.original.beneficialOwnershipForm,
                "resolvedFields"
              )}
              addReviewComment={!hideReviewWorkflow && !restProps.isViewMode}
              hideResolveAllCheckbox={
                hideReviewWorkflow || restProps.isViewMode
              }
              formName="beneficialOwnershipForm"
              formHelperKey="beneficialOwnershipForm"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.beneficialOwnershipForm`}
              formId={_get(row.original.beneficialOwnershipForm, "_id")}
              comments={generalInformationComments}
            />
            {[
              "Local Company",
              "Foreign Company",
              "Other e.g Parastatals",
            ].includes(row?.original?.idType) && (
              <FormFieldsCard
                handleSaveComment={handleSaveComment}
                fields={getFormFields({
                  idType: row.original.idType,
                  formType: "contactPersonInfo",
                  formValues: row.original.identificationDetails,
                })}
                resolvedFields={_get(
                  row.original.identificationDetails,
                  "resolvedFields"
                )}
                addReviewComment={!isCompanyDashboard && !isViewMode}
                hideResolveAllCheckbox={isCompanyDashboard || isViewMode}
                formName="identificationDetails"
                formHelperKey="identificationDetails"
                updateEffectKey={companyOfficialEffectKey}
                updateEffectPath={`${officialSliceName}.identificationDetails`}
                formId={_get(row.original.identificationDetails, "_id")}
                comments={generalInformationComments.filter(
                  (comment) => comment.formName === "identificationDetails"
                )}
              />
            )}
          </>
        )}
        {selectedTab.id === "residentialDetails" && (
          <>
            {residentialDetailsComments &&
              residentialDetailsComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={residentialDetailsComments}
                    toggleCommentStatus={!isViewMode}
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments: {residentialDetailsComments.length} of{" "}
                    {residentialDetailsComments.length}
                  </p>
                </div>
              )}
            {row?.original?.idType === "Kenyan Citizen" && (
              <FormFieldsCard
                handleSaveComment={handleSaveComment}
                fields={[
                  {
                    key: "Non-Resident",
                    name: "isNonResident",
                    value: row.original?.isNonResident ?? "N/A",
                  },
                ]}
                resolvedFields={_get(row.original, "resolvedFields")}
                addReviewComment={!isCompanyDashboard && !isViewMode}
                hideResolveAllCheckbox={isCompanyDashboard || isViewMode}
                formName="isNonResident"
                formHelperKey="beneficialOwner"
                updateEffectKey={companyOfficialEffectKey}
                updateEffectPath={officialSliceName}
                formId={_get(row.original, "_id")}
                comments={residentialDetailsComments.filter(
                  (comment) => comment.formName === "isNonResident"
                )}
              />
            )}
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType:
                  row?.original?.isNonResident &&
                  row?.original?.isNonResident === "Yes"
                    ? "Foreigner"
                    : row?.original?.idType,
                formType: "postalAddress",
                formValues: row?.original?.postalAddress,
              })}
              resolvedFields={_get(
                row.original.postalAddress,
                "resolvedFields"
              )}
              addReviewComment={!isCompanyDashboard && !isViewMode}
              hideResolveAllCheckbox={isCompanyDashboard || isViewMode}
              formName="postalAddress"
              formHelperKey="postalAddress"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.postalAddress`}
              formId={_get(row.original.postalAddress, "_id")}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "postalAddress"
              )}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType: row?.original?.idType,
                formType: "electronicAddress",
                formValues: row?.original?.electronicAddress,
              })}
              resolvedFields={_get(
                row.original.electronicAddress,
                "resolvedFields"
              )}
              addReviewComment={!isCompanyDashboard && !isViewMode}
              hideResolveAllCheckbox={isCompanyDashboard || isViewMode}
              formName="electronicAddress"
              formHelperKey="electronicAddress"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.electronicAddress`}
              formId={_get(row.original.electronicAddress, "_id")}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "electronicAddress"
              )}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType:
                  row?.original?.isNonResident &&
                  row?.original?.isNonResident === "Yes"
                    ? "Foreigner"
                    : row?.original?.idType,
                formType: "residentialAddressDetails",
                formValues: row?.original?.residentialAddressDetails,
              })}
              resolvedFields={_get(
                row.original.residentialAddressDetails,
                "resolvedFields"
              )}
              addReviewComment={!isCompanyDashboard && !isViewMode}
              hideResolveAllCheckbox={isCompanyDashboard || isViewMode}
              formName="residentialAddressDetails"
              formHelperKey="residentialAddressDetails"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.residentialAddressDetails`}
              formId={_get(row.original.residentialAddressDetails, "_id")}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "residentialAddressDetails"
              )}
            />
          </>
        )}
        {selectedTab.id === "officialShareAllocation" && (
          <>
            {officialShareAllocationComments &&
              officialShareAllocationComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 my-6 bg-white">
                  <CommentsList
                    comments={officialShareAllocationComments}
                    toggleCommentStatus={!isViewMode}
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments: {officialShareAllocationComments.length} of{" "}
                    {officialShareAllocationComments.length}
                  </p>
                </div>
              )}
            {row.original.sharesAllotted?.length > 0 ? (
              <div className="w-full bg-white shadow">
                <div className="w-full overflow-x-auto">
                  <ReactTable
                    columns={officialShareAllocationColumns}
                    data={row.original.sharesAllotted}
                    headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600"
                    headingStyle="pl-4 text-left"
                    dataRowCellStyle="pl-4 leading-snug py-2 whitespace-normal"
                    dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
                  />
                </div>
              </div>
            ) : (
              <p className="mt-4 text-center">No data available</p>
            )}
            {!isCompanyDashboard &&
              !isViewMode &&
              row.original.sharesAllotted?.length > 0 && (
                <div className="flex items-center mt-2">
                  <input
                    aria-describedby="comments-description"
                    type="checkbox"
                    className="mr-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    onChange={handleAllFieldsStatus}
                    checked={allFieldsResolved}
                  />
                  <p className="flex items-center text-sm font-semibold">
                    Click this checkbox to{" "}
                    {allFieldsResolved ? "disapprove" : "approve"} all entries
                    if they are {allFieldsResolved ? "incorrect" : "correct"}{" "}
                    then proceed to next step
                  </p>
                </div>
              )}
          </>
        )}
        {selectedTab.id === "officialBeneficialOwnership" && (
          <>
            {officialBeneficialOwnershipComments &&
              officialBeneficialOwnershipComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={officialBeneficialOwnershipComments}
                    toggleCommentStatus={!isViewMode}
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments: {officialBeneficialOwnershipComments.length} of{" "}
                    {officialBeneficialOwnershipComments.length}
                  </p>
                </div>
              )}
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getBeneficialOwnershipFormFields({
                beneficialOwnershipForm: row.original.beneficialOwnershipForm,
                reviewTab: true,
                renderDateOfBecomingToBeABO: false,
                renderDateOfCeasingToBeABO: false,
                sourceOfBO: false,
                beneficialOwner: row.original,
                beneficialOwners,
              })}
              resolvedFields={_get(
                row.original.beneficialOwnershipForm,
                "resolvedFields"
              )}
              addReviewComment={!isCompanyDashboard && !isViewMode}
              hideResolveAllCheckbox={isCompanyDashboard || isViewMode}
              formName="beneficialOwnershipForm"
              formHelperKey="beneficialOwnershipForm"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.beneficialOwnershipForm`}
              formId={_get(row.original.beneficialOwnershipForm, "_id")}
              comments={officialBeneficialOwnershipComments}
            />
          </>
        )}
        {selectedTab.id === "uploadDocuments" && (
          <>
            {fileUploadComments && fileUploadComments.length > 0 && (
              <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                <CommentsList
                  comments={fileUploadComments}
                  toggleCommentStatus={!isViewMode}
                />
                <p className="mt-6 text-sm text-primary">
                  Comments: {fileUploadComments.length} of{" "}
                  {fileUploadComments.length}
                </p>
              </div>
            )}
            <div className="px-8 pt-5 pb-6 mt-6 bg-white">
              <h3 className="mb-2 text-lg">Uploaded incorporation forms</h3>
              <Formik
                enableReinitialize
                initialValues={{
                  fileUploads: row.original.fileUploads,
                }}
                onSubmit={() => null}
              >
                {() => {
                  return (
                    <div className="pl-4">
                      <Field
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={
                          row.original.designation === "Company Secretary"
                            ? getCompanySecretaryDocumentData(
                                row.original.idType
                              )
                            : getDocumentData(row.original.idType)
                        }
                        resolvedFields={_get(row.original, "resolvedFields")}
                        onlyPreview
                        addReviewComment={!isCompanyDashboard && !isViewMode}
                        hideResolveAllCheckbox={
                          isCompanyDashboard || isViewMode
                        }
                        handleSaveComment={handleSaveComment}
                        formName="uploadDocuments"
                        formHelperKey="beneficialOwner"
                        updateEffectKey={companyOfficialEffectKey}
                        updateEffectPath={officialSliceName}
                        formId={_get(row.original, "_id")}
                        resolveAllFieldsCheckbox
                        fileUploadComments={fileUploadComments}
                      />
                    </div>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
        {!isCompanyDashboard && (
          <div className="flex justify-between">
            {!isFirstStep && (
              <button
                onClick={goToPrevStep}
                className="flex items-start float-right py-2 font-semibold rounded text-primary mt-7"
              >
                <span className="flex items-center mr-4 h-7">
                  <ChevronLeftIcon
                    className="flex-shrink-0 w-5 h-5"
                    aria-hidden="true"
                  />
                </span>
                <p className="font-semibold">Go back</p>
              </button>
            )}
            {isLastStep ? (
              <button
                onClick={() => handleConfirmDetails()}
                className="flex items-center px-4 py-2 mb-2 font-semibold text-white rounded bg-green-focused mt-7"
              >
                <p>{isViewMode ? "Close" : "Confirm details"}</p>
                {!isViewMode && (
                  <span className="flex items-center ml-4 text-white h-7">
                    <ChevronRightIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                  </span>
                )}
              </button>
            ) : (
              <button
                onClick={goToNextStep}
                className="flex items-start float-right py-2 ml-auto font-semibold rounded text-primary mt-7"
              >
                <p className="font-semibold">Review next step </p>
                <span className="flex items-center ml-4 h-7">
                  <ChevronRightIcon
                    className="flex-shrink-0 w-5 h-5"
                    aria-hidden="true"
                  />
                </span>
              </button>
            )}
          </div>
        )}
      </td>
    </tr>
  );
}

export default StakeholderSubRow;
