import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PlusIcon } from "@heroicons/react/solid";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _cloneDeep from "lodash/cloneDeep";
import _sortBy from "lodash/sortBy";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import ModalWrapper from "components/lib/Shared/ModalWrapper";
import CompanyOfficialSelection from "./CompanyOfficialSelection";
import CompanyOfficialsTable from "./CompanyOfficialsTable";
import options from "../SFLdata/selectOptions";
import { openModal } from "state/slices/modals";
import {
  flattenObject,
  getFullName,
  getMaxCompanySecretariesLimit,
} from "utils";
import { resetSharesTable } from "state/slices/tables/sharesAllocationTable";
import ErrorBanner from "components/lib/Shared/ErrorBanner";
import { checkForCompaniesRegistrar } from "utils/company";
import Button from "components/lib/Shared/Button";
import { Constants } from "config/constants";
import { setCurrentJSGroupID } from "state/slices/tables/jointShareholderTable";
import { validateCompanyOfficialsAsync } from "state/slices/company";
import Overlay from "components/lib/Shared/Overlay";

const CompanyOfficialsTab = ({
  handleBackBtn,
  handleNextBtn,
  submitBtnLabel,
  isSubmitting,
}) => {
  const history = useHistory();
  const role = Cookies.get("role");
  const [showCompanyOfficialsForm, setShowCompanyOfficialsForm] =
    useState(false);
  const [mode, setMode] = useState({ editing: false, id: "", viewing: false });
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [formStepErrors, setFormStepErrors] = useState([]);
  const [showSelectRegistrarBtn, setShowSelectRegistrarBtn] = useState(false);
  const [registrarCandidates, setRegistrarCandidates] = useState([]);
  const [assigningRegistrar, setAssigningRegistrar] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const dispatch = useDispatch();
  const companyOfficialsSlice = useSelector(
    (state) => state.companyOfficialsSlice
  );
  const originalCompanyOfficials = companyOfficialsSlice.companyOfficials.items;
  const { getCompanyOfficialsStatus } = companyOfficialsSlice;
  const { jointShareholders, currentJSGroupID } = useSelector(
    (state) => state.jointShareholderTableSlice
  );

  const count = jointShareholders.length;

  const companyOfficials = originalCompanyOfficials.map((co) =>
    flattenObject(co)
  );

  const JSGroups = new Set();
  companyOfficials.forEach((item) => {
    if (item.JSGroupID) JSGroups.add(item.JSGroupID);
  });

  const userSlice = useSelector((state) => state.userSlice);
  const { company, validateCompanyOfficialsStatus } = useSelector(
    (state) => state.companySlice
  );
  const { beneficialOwners } = useSelector(
    (state) => state.beneficialOwnersTableSlice
  );
  const beneficialOwnersDropdown = beneficialOwners.filter(
    (bo) =>
      bo.showInBoDropdown &&
      [
        bo?.electronicAddress?.emailAddress,
        bo?.identificationDetails?.guardianEmailAddress,
      ].includes(userSlice.electronicAddress?.emailAddress)
  );
  const numberOfBoOfficials = beneficialOwnersDropdown?.length ?? 0;
  const isApplicant = company.createdBy._id === userSlice._id;

  const enableBoTab =
    beneficialOwners.find(
      (bo) =>
        bo?.electronicAddress?.emailAddress &&
        [
          bo?.electronicAddress?.emailAddress,
          bo?.identificationDetails?.guardianEmailAddress,
        ].includes(userSlice.electronicAddress?.emailAddress)
    ) || numberOfBoOfficials > 0;

  const allJS = [...JSGroups].map((id) => {
    const names = [];
    let combinedJS = {};
    const currentGroupOfficials = [];
    companyOfficials.forEach((official) => {
      if (official.JSGroupID === id) currentGroupOfficials.push(official);
    });
    // sorted by JS Group order
    const sortedJsOfficials = _sortBy(currentGroupOfficials, ["JSGroupOrder"]);
    sortedJsOfficials.map((official) => {
      if (official.JSGroupID === id) {
        names.push(getFullName(official));
        if (official.sharesAllotted && official.sharesAllotted.length > 0)
          combinedJS.sharesAllotted = official.sharesAllotted;
        combinedJS = {
          ...combinedJS,
          ..._omit(_cloneDeep(official), ["sharesAllotted"]),
          designation: "Joint Shareholder",
          idType: "Joint Shareholder",
        };
        combinedJS.fullName = names.map((name, index) => {
          if (names.length === 2 && index === 0) {
            return `${name} & `;
          }
          if (names.length > 2 && index === names.length - 2) {
            return `${name} & `;
          }

          if (
            names.length > 2 &&
            index !== names.length - 2 &&
            index !== names.length - 1
          ) {
            return `${name}, `;
          }
          return name;
        });
      }
    });
    return combinedJS;
  });

  const data = [
    ...companyOfficials.filter(
      (item) =>
        (item.designation === "Joint Shareholder" &&
          item.isDirector === "Yes") ||
        item.designation !== "Joint Shareholder"
    ),
    ...allJS.filter((item) => !_isEmpty(item)),
  ];

  const toggleShowOfficialsForm = (show) => {
    if (show) {
      setMode({ editing: false, id: "", designation: null, viewing: false });
      setShowCompanyOfficialsForm(true);
    } else setShowCompanyOfficialsForm(show);
  };

  const handleAddCompanyOfficial = () => {
    setMode({ editing: false, id: "", designation: null, viewing: false });
    setShowCompanyOfficialsForm(true);
    dispatch(setCurrentJSGroupID(""));
  };

  const handleCOEdit = (row) => {
    if (!row.isViewOnly) setIsViewOnly(false);
    else setIsViewOnly(true);
    setMode({ editing: true, id: row._id, viewing: false });
    dispatch(openModal({ modalName: "companyOfficial_edit_modal" }));
  };

  const handleView = (row) => {
    setMode({ editing: true, id: row._id, viewing: true });
    setIsViewOnly(true);
    dispatch(openModal({ modalName: "companyOfficial_edit_modal" }));
  };

  // open joint shareholder workflow when
  useEffect(() => {
    if (currentJSGroupID || count > 0) {
      toggleShowOfficialsForm(true);
    } else dispatch(setCurrentJSGroupID(""));
  }, [count, currentJSGroupID]);

  // Clear share allocation table when user exit from company official workflow
  useEffect(() => {
    return () => {
      dispatch(resetSharesTable());
    };
  }, [dispatch, resetSharesTable]);

  const validateTab = async (goToNextStep = false) => {
    setFormStepErrors([]);
    setIsValidating(true);
    const response = await dispatch(
      validateCompanyOfficialsAsync({
        companyId: company?._id,
      })
    );
    const errors = response.payload?.errors ?? [];
    setIsValidating(false);
    if (errors.length !== 0) setFormStepErrors(errors);
    else if (goToNextStep) handleNextBtn();
  };

  useEffect(() => {
    if (isApplicant) {
      const { showSelectRegistrarBtn, registrarCandidates = [] } =
        checkForCompaniesRegistrar({
          type: company?.type,
          officials: originalCompanyOfficials,
        });
      setShowSelectRegistrarBtn(showSelectRegistrarBtn);
      setRegistrarCandidates(registrarCandidates);
    }
  }, [originalCompanyOfficials, company.type, isApplicant]);

  const handleSubmit = () => {
    if (role === Constants.COOKIE_ROLES.OFFICIAL) {
      if (enableBoTab && company?.status === "Draft") {
        handleNextBtn();
      } else history.push("/transactions");
      return;
    }
    validateTab(true);
  };

  const numberOfCompanySecretaries = data.filter(
    (official) => official.designation === "Company Secretary"
  ).length;

  const companyOfficialOptions = [
    ...options.companyOfficials.filter(
      (official) => official.value !== "Company Secretary"
    ),
    {
      value: "Company Secretary",
      label: "Company Secretary",
      disabled:
        numberOfCompanySecretaries ===
        getMaxCompanySecretariesLimit(company?.type),
    },
  ];

  return (
    <div className="relative">
      <ErrorBanner errors={formStepErrors} className="mb-2" />
      {getCompanyOfficialsStatus.status === "loading" && <Overlay />}
      {!showCompanyOfficialsForm && data.length === 0 && (
        <div className="space-y-2">
          <div className="inline-flex px-6 py-3 bg-white rounded shadow-sm">
            <p className="text-gray-800">
              Your company has no officials listed. Please click the{" "}
              <b className="text-secondary">+ Add company officials</b> to add
              appointments.
            </p>
          </div>
        </div>
      )}
      {!showCompanyOfficialsForm && data.length > 0 && (
        <>
          {showSelectRegistrarBtn && !assigningRegistrar && (
            <div className="flex justify-end">
              <Button onClick={() => setAssigningRegistrar(true)}>
                Assign Registrar
              </Button>
            </div>
          )}
          <CompanyOfficialsTable
            assigningRegistrar={assigningRegistrar}
            registrarCandidates={registrarCandidates}
            toggleShowOfficialsForm={toggleShowOfficialsForm}
            handleEdit={handleCOEdit}
            handleView={handleView}
            mode={mode}
            data={data}
          />
        </>
      )}
      {!showCompanyOfficialsForm &&
        role !== Constants.COOKIE_ROLES.OFFICIAL && (
          <div className="mt-4 d-flex justify-content-end">
            <Button
              variant="text"
              onClick={handleAddCompanyOfficial}
              className="inline-flex items-center justify-center space-x-2 text-sm font-medium rounded-md text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon className="w-6 h-6" aria-hidden="true" />
              <span>Add company officials</span>
            </Button>
          </div>
        )}
      {mode.editing && (
        <ModalWrapper
          name="companyOfficial_edit_modal"
          title={isViewOnly ? "View Company Official" : "Edit Company Official"}
          size="lg"
          maxWidth="sm:max-w-fit"
        >
          <CompanyOfficialSelection
            companyOfficialOptions={companyOfficialOptions}
            toggleShowOfficialsForm={toggleShowOfficialsForm}
            mode={mode}
            component={CompanyOfficialSelection}
            isViewOnly={isViewOnly}
            setIsViewOnly={setIsViewOnly}
          />
        </ModalWrapper>
      )}
      {showCompanyOfficialsForm && (
        <CompanyOfficialSelection
          companyOfficialOptions={companyOfficialOptions}
          toggleShowOfficialsForm={toggleShowOfficialsForm}
          mode={mode}
          isViewOnly={isViewOnly}
          setMode={setMode}
          handleBackBtn={handleBackBtn}
          setIsViewOnly={setIsViewOnly}
        />
      )}
      {!showCompanyOfficialsForm && (
        <div className="flex justify-between py-3">
          <Button
            onClick={handleBackBtn}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Go back
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting || isValidating}
            onClick={handleSubmit}
            loadingText={isValidating ? "Validating..." : "Submitting..."}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
          >
            {submitBtnLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CompanyOfficialsTab;
