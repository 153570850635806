import { object, string } from "yup";

const INVALID_COMPANY_NAME_REGEX =
  /^(?!$)(?!.*\b(?:PUBLIC LIMITED COMPANY|LIMITED|LTD|PLC|PVT|LLC|LP|Inc(?:\.|orporated)?|LLP|CLG)\b)/i;
const INVALID_COMPANY_NAME_ERROR =
  "Do not include the extension i.e. PUBLIC LIMITED COMPANY or PLC";
const DUPLICATE_COMPANY_NAME_ERROR = "Company name should be unique";

const step1Validation = (
  firstPreference = "",
  secondPreference = "",
  thirdPreference = "",
  fourthPreference = "",
  fifthPreference = ""
) =>
  object().shape({
    companyName1: string()
      .trim("Name cannot include leading and trailing spaces")
      .strict(true)
      .matches(INVALID_COMPANY_NAME_REGEX, INVALID_COMPANY_NAME_ERROR)
      .notOneOf(
        [secondPreference, thirdPreference, fourthPreference, fifthPreference],
        DUPLICATE_COMPANY_NAME_ERROR
      )
      .required("Name is required"),
    companyName2: string()
      .trim("Name cannot include leading and trailing spaces")
      .strict(true)
      .matches(INVALID_COMPANY_NAME_REGEX, INVALID_COMPANY_NAME_ERROR)
      .notOneOf(
        [firstPreference, thirdPreference, fourthPreference, fifthPreference],
        DUPLICATE_COMPANY_NAME_ERROR
      )
      .required("Name is required"),
    companyName3: string()
      .trim("Name cannot include leading and trailing spaces")
      .strict(true)
      .matches(INVALID_COMPANY_NAME_REGEX, INVALID_COMPANY_NAME_ERROR)
      .notOneOf(
        [firstPreference, secondPreference, fourthPreference, fifthPreference],
        DUPLICATE_COMPANY_NAME_ERROR
      )
      .required("Name is required"),
    companyName4: string()
      .trim("Name cannot include leading and trailing spaces")
      .strict(true)
      .matches(INVALID_COMPANY_NAME_REGEX, INVALID_COMPANY_NAME_ERROR)
      .notOneOf(
        [firstPreference, secondPreference, thirdPreference, fifthPreference],
        DUPLICATE_COMPANY_NAME_ERROR
      ),
    companyName5: string()
      .trim("Name cannot include leading and trailing spaces")
      .strict(true)
      .matches(INVALID_COMPANY_NAME_REGEX, INVALID_COMPANY_NAME_ERROR)
      .notOneOf(
        [firstPreference, secondPreference, thirdPreference, fourthPreference],
        DUPLICATE_COMPANY_NAME_ERROR
      ),
  });

export default step1Validation;
