import React, { useContext } from "react";

import Button from "components/lib/Shared/Button";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import { MinuteBookContext } from "context/MinuteBookContext";

function PaginationControls({
  isLastPage,
  isFullscreen,
  disableBackBtn,
  disableNextBtn,
  renderBackBtn = true,
  renderNextBtn = true,
  currentPage = 1,
  totalRows = 0,
  totalPages = 0,
  setCurrentPage = () => null,
}) {
  const { selectedTab, setSelectedTab, individualSidebarItems } =
    useContext(MinuteBookContext);

  if (totalRows === 0) return null;

  const handleGoToPrevPage = () => {
    if (currentPage === 1 && selectedTab?.prevRegister) {
      const prevRegister = individualSidebarItems.find(
        (item) => item.id === selectedTab.prevRegister
      );
      if (prevRegister) setSelectedTab(prevRegister);
    } else setCurrentPage(currentPage - 1);
  };

  const handleGoToNextPage = () => {
    if (isLastPage && selectedTab?.nextRegister) {
      const nextRegister = individualSidebarItems.find(
        (item) => item.id === selectedTab.nextRegister
      );
      if (nextRegister) setSelectedTab(nextRegister);
    } else setCurrentPage(currentPage + 1);
  };

  return (
    <>
      {isFullscreen ? (
        <div className="fixed flex justify-between bottom-1/2 w-full">
          {renderBackBtn && (
            <Button
              className="bg-gray-100 p-2 rounded-full absolute left-2"
              overrideBaseStyles
              disabled={disableBackBtn}
              onClick={handleGoToPrevPage}
            >
              <ArrowLeftIcon className="w-6 h-6" />
            </Button>
          )}
          {renderNextBtn && (
            <Button
              className="bg-gray-100 p-2 rounded-full absolute left-[97vw]"
              overrideBaseStyles
              disabled={disableNextBtn}
              onClick={handleGoToNextPage}
            >
              <ArrowRightIcon className="w-6 h-6" />
            </Button>
          )}
        </div>
      ) : (
        <div className="absolute flex space-x-4 bottom-0 right-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {renderBackBtn && (
            <Button
              className="bg-gray-100 p-1 rounded-full opacity-50 hover:opacity-100"
              overrideBaseStyles
              disabled={disableBackBtn}
              onClick={handleGoToPrevPage}
            >
              <ArrowLeftIcon className="w-6 h-6" />
            </Button>
          )}
          {renderNextBtn && (
            <Button
              className="bg-gray-100 p-1 rounded-full opacity-50 hover:opacity-100"
              overrideBaseStyles
              disabled={disableNextBtn}
              onClick={handleGoToNextPage}
            >
              <ArrowRightIcon className="w-6 h-6" />
            </Button>
          )}
        </div>
      )}
      {isFullscreen && currentPage > 0 && (
        <p className="text-center pb-16">
          Page: {currentPage}
          {totalPages ? ` of ${totalPages}` : ""}
        </p>
      )}
    </>
  );
}

export default PaginationControls;
