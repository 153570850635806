import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";

import invoiceService from "services/invoice";

export const getInvoicesAsync = createAsyncThunk(
  "invoices/getInvoicesAsync",
  async (queryParams) => {
    const res = await invoiceService.getAll(queryParams);
    return res.data;
  }
);

export const getInvoiceAsync = createAsyncThunk(
  "invoices/getInvoiceAsync",
  async ({ invoiceId }) => {
    const res = await invoiceService.get(invoiceId);
    return res.data;
  }
);

export const downloadInvoiceAsync = createAsyncThunk(
  "invoices/downloadInvoiceAsync",
  async ({ invoiceId, invoiceNumber }, thunkAPI) => {
    try {
      const response = await invoiceService.downloadInvoice(invoiceId);
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Invoice No. ${invoiceNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const exportInvoicesAsync = createAsyncThunk(
  "invoices/exportInvoicesAsync",
  async (queryParams, thunkAPI) => {
    try {
      const response = await invoiceService.exportInvoices(queryParams);
      saveAs(
        response.data,
        `${queryParams.fileName}.${queryParams.fileFormat}`
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  invoices: {
    items: [],
    totalCount: 0,
  },
  invoice: {},
  getInvoices: {
    status: "idle",
    error: false,
  },
  getInvoice: {
    status: "idle",
    error: false,
  },
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    resetInvoicesSlice: () => initialState,
  },
  extraReducers: {
    [getInvoicesAsync.pending]: (state) => {
      state.getInvoices.status = "loading";
      state.getInvoices.error = false;
    },
    [getInvoicesAsync.fulfilled]: (state, action) => {
      state.getInvoices.status = "idle";
      state.invoices = action.payload;
    },
    [getInvoicesAsync.rejected]: (state, action) => {
      state.getInvoices.status = "failed";
      state.getInvoices.error = action?.payload?.messageText;
    },

    [getInvoiceAsync.pending]: (state) => {
      state.getInvoice.status = "loading";
      state.getInvoice.error = false;
    },
    [getInvoiceAsync.fulfilled]: (state, action) => {
      state.getInvoice.status = "idle";
      state.invoice = action.payload;
    },
    [getInvoiceAsync.rejected]: (state, action) => {
      state.getInvoice.status = "failed";
      state.getInvoice.error = action?.payload?.messageText;
    },
  },
});

export const { resetInvoicesSlice } = invoicesSlice.actions;

export default invoicesSlice.reducer;
