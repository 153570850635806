import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { useHistory, useParams } from "react-router-dom";
import _get from "lodash/get";
import { format } from "date-fns";
import { Formik, Field } from "formik";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import Tabs from "components/lib/Shared/Tabs";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import OfficialBeneficialOwnership from "./OfficialBeneficialOwnership";
import GeneralInformation from "./GeneralInformation";
import ResidentialDetails from "./ResidentialDetails";
import OfficialShareAllocation from "./OfficialShareAllocation";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { Constants } from "config/constants";
import { getOfficial, resetOfficial } from "state/slices/organizationDashboard";

function corporateOfficialSubRow({
  visibleColumns,
  row,
  currentTab,
  initialRows,
}) {
  const history = useHistory();
  const params = useParams();
  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch();
  const { organization, dashboardPermissions } = useSelector(
    (state) => state.organizationsSlice
  );
  const { beneficialOwners } = organization;

  const { official, getOfficialStatus } = useSelector(
    (state) => state.organizationDashboardSlice
  );

  const isCompanyDashboard =
    history.location.pathname.split("/").pop() === "dashboard";
  const viewRestrictedData = dashboardPermissions.includes(
    Constants.DASHBOARD_PERMISSIONS.VIEW_RESTRICTED_DATA
  );

  const currentUser = useSelector((state) => state.userSlice);

  const isCurrentUserRow =
    official?.electronicAddress?.emailAddress === currentUser?.email;

  useEffect(() => {
    if (row?.original?._id) {
      dispatch(
        getOfficial({
          corporateId: params.corporateId,
          officialId: row?.original?._id,
          idType: row?.original?.idType,
        })
      );
    }
    return () => {
      dispatch(resetOfficial());
    };
  }, [row?.original?._id]);

  useEffect(() => {
    let tempTabs = [];
    if (
      ["Director", "Authorized Person", "Company Secretary"].includes(
        row.original.designation
      )
    ) {
      tempTabs = [
        {
          id: "generalInformation",
          label: "General information",
        },
        {
          id: "residentialDetails",
          label: "Address details",
        },
        {
          id: "uploadDocuments",
          label: "File Uploads",
        },
      ];
    } else {
      tempTabs = [
        {
          id: "generalInformation",
          label: "General information",
        },
        {
          id: "residentialDetails",
          label: "Address details",
        },
        {
          id: "officialShareAllocation",
          label: "Share information",
        },
        {
          id: "officialBeneficialOwnership",
          label: "Beneficial ownership",
        },
        {
          id: "uploadDocuments",
          label: "File Uploads",
        },
      ];
    }
    setTabs(tempTabs);
  }, [
    row.original.designation,
    currentTab,
    isCurrentUserRow,
    row,
    official,
    viewRestrictedData,
  ]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  if (!official || getOfficialStatus.status === "loading") {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto m-2">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  let dateOfBirth = _get(row.original.identificationDetails, "dateOfBirth");
  if (dateOfBirth) dateOfBirth = format(new Date(dateOfBirth), "yyyy/MM/dd");
  else dateOfBirth = "N/A";

  const handleConfirmDetails = () => {
    row.toggleRowExpanded();
    const nextRow = initialRows?.[row.index + 1] || null;
    if (nextRow) nextRow?.getToggleRowExpandedProps()?.onClick();
  };

  return (
    <tr className="bg-[#EDF0F5]">
      <td
        colSpan={visibleColumns.length}
        className={`px-9 ${isCompanyDashboard ? "pb-7" : ""}`}
      >
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "generalInformation" && (
          <GeneralInformation
            official={official}
            isCompanyDashboard={isCompanyDashboard}
            isCurrentUserRow={isCurrentUserRow}
            viewRestrictedData={viewRestrictedData}
            hideResolveAllCheckbox
          />
        )}
        {selectedTab.id === "residentialDetails" && (
          <ResidentialDetails
            official={official}
            isCurrentUserRow={isCurrentUserRow}
            viewRestrictedData={viewRestrictedData}
            hideResolveAllCheckbox
          />
        )}
        {selectedTab.id === "officialShareAllocation" && (
          <OfficialShareAllocation
            sharesAllotted={row.original.sharesAllotted}
          />
        )}
        {selectedTab.id === "officialBeneficialOwnership" && (
          <OfficialBeneficialOwnership
            official={official}
            hideResolveAllCheckbox
            beneficialOwners={beneficialOwners}
          />
        )}
        {selectedTab.id === "uploadDocuments" && (
          <div className="px-8 pt-5 pb-6 mt-6 bg-white">
            <h3 className="mb-2 text-lg">Uploaded incorporation forms</h3>
            <Formik
              enableReinitialize
              initialValues={{
                fileUploads: official.fileUploads,
              }}
              onSubmit={() => null}
            >
              {() => {
                return (
                  <div className="pl-4">
                    <Field
                      name="fileUploads"
                      component={FileUploadForm}
                      documentData={getDocumentData(official.idType)}
                      onlyPreview
                      showDocumentLabel
                      hideResolveAllCheckbox
                      resolveAllFieldsCheckbox
                    />
                  </div>
                );
              }}
            </Formik>
          </div>
        )}
        {!isCompanyDashboard && (
          <div className="flex justify-between">
            {!isFirstStep && (
              <button
                onClick={goToPrevStep}
                className="flex items-start float-right py-2 font-semibold rounded text-primary mt-7"
              >
                <span className="flex items-center mr-4 h-7">
                  <ChevronLeftIcon
                    className="flex-shrink-0 w-5 h-5"
                    aria-hidden="true"
                  />
                </span>
                <p className="font-semibold">Go back</p>
              </button>
            )}
            {isLastStep ? (
              <button
                onClick={() => handleConfirmDetails()}
                className="flex items-center px-4 py-2 mb-2 font-semibold text-white rounded bg-green-focused mt-7"
              >
                <p>Close</p>
              </button>
            ) : (
              <button
                onClick={goToNextStep}
                className="flex items-start float-right py-2 ml-auto font-semibold rounded text-primary mt-7"
              >
                <p className="font-semibold">Review next step </p>
                <span className="flex items-center ml-4 h-7">
                  <ChevronRightIcon
                    className="flex-shrink-0 w-5 h-5"
                    aria-hidden="true"
                  />
                </span>
              </button>
            )}
          </div>
        )}
      </td>
    </tr>
  );
}

export default corporateOfficialSubRow;
