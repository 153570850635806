import ProfileLabel from "components/lib/Shared/ProfileLabel";
import Header from "./Header";

function SystemAdminProfile({ userSlice, fullName }) {
  const { electronicAddress } = userSlice;
  return (
    <div className="px-2 space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="px-6 my-4">
        <Header {...userSlice} />
        <div>
          <div className="grid gap-6">
            <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Personal details
              </h3>
              <div className="flex flex-col space-y-2">
                <ProfileLabel label="Full names" value={fullName || "N/A"} />
              </div>
            </div>
            <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <h3 className="my-4 text-lg font-medium leading-6 text-gray-900">
                Electronic address
              </h3>
              <div className="flex flex-col space-y-2">
                <ProfileLabel
                  label="Email address"
                  value={electronicAddress?.emailAddress ?? "N/A"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemAdminProfile;
