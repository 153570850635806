import React, { useMemo, useState, useEffect } from "react";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";

import Table from "components/lib/Global/ReactTable";
import { capitalizeFirstLetter } from "utils";
import { MinusIcon } from "@heroicons/react/outline";
import { PlusIcon } from "components/lib/Shared/Icons/sflIcons";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import {
  getAccessRequestComments,
  resetAccessRequestCommentsSlice,
} from "state/slices/accessRequestComment";
import { editAccessRequest } from "state/slices/accessRequest";
import { useToast } from "hooks/useToast";
import { openModal } from "state/slices/modals";
import { getCompanyNameWithSuffix } from "utils/company";
import { Constants } from "config/constants";

// render additional data & comments
const SubRow = ({
  visibleColumns,
  row,
  comments,
  loadingComments,
  setSelectedRow,
}) => {
  const originalRow = row?.original?.parentRow ?? row?.original;

  const {
    grantedAt,
    createdAt,
    revokedAt,
    expiredAt,
    recalledAt,
    status,
    createdBy,
    requestFor,
  } = originalRow;

  const userSlice = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  const [accessType, setAccessType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast(5000);

  useEffect(() => {
    setAccessType(originalRow.accessType);
  }, [originalRow.accessType]);

  const handleAccessTypeChange = async (e) => {
    setAccessType(e.target.value);
    try {
      setIsLoading(true);
      await dispatch(
        editAccessRequest({
          accessRequestsId: originalRow?._id,
          data: { accessType: e.target.value },
        })
      );
      toast("success", "Updated access type successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRevokeAccess = () => {
    setSelectedRow(originalRow);
    dispatch(openModal({ modalName: "revoke_request_confirmation_modal" }));
  };

  return (
    <tr className="bg-[#EDF0F5] text-gray-800">
      <td colSpan={visibleColumns.length} className="px-4">
        <div className="mt-2">
          <h3 className="font-medium uppercase">Additional Details</h3>
          <div className="grid grid-cols-5 gap-2 my-4">
            {grantedAt && (
              <div className="flex flex-col">
                <span>Granted:</span>
                <span className="font-medium text-gray-800">
                  {format(new Date(grantedAt), "PP")}
                </span>
              </div>
            )}
            {createdAt && (
              <div className="flex flex-col">
                <span>Requested:</span>
                <span className="font-medium text-gray-800">
                  {format(new Date(createdAt), "PP")}
                </span>
              </div>
            )}
            {revokedAt && (
              <div className="flex flex-col">
                <span>Revoked:</span>
                <span className="font-medium text-gray-800">
                  {format(new Date(revokedAt), "PP")}
                </span>
              </div>
            )}
            {expiredAt && (
              <div className="flex flex-col">
                <span>Expired:</span>
                <span className="font-medium text-gray-800">
                  {format(new Date(expiredAt), "PP")}
                </span>
              </div>
            )}
            {recalledAt && (
              <div className="flex flex-col">
                <span>Recalled:</span>
                <span className="font-medium text-gray-800">
                  {format(new Date(recalledAt), "PP")}
                </span>
              </div>
            )}
          </div>
        </div>
        <hr />
        {status === "Confirmed" &&
          createdBy?._id !== userSlice?._id &&
          requestFor === Constants.ACCESS_REQUEST.CORPORATE_DATA && (
            <div className="ml-auto">
              <p className="text-sm text-gray-500">Select type of access</p>
              <div className="flex p-3 mt-1 space-x-4 justify-between rounded-md bg-gray-50">
                <div>
                  <div className="flex items-center">
                    <input
                      name={`accessType-${row.id}`}
                      type="radio"
                      id={`recurring-${row.id}`}
                      value="Recurring"
                      checked={accessType === "Recurring"}
                      disabled={isLoading}
                      onChange={handleAccessTypeChange}
                      className="w-4 h-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                    />
                    <label
                      htmlFor={`recurring-${row.id}`}
                      className="block ml-2 text-sm font-medium text-gray-700"
                    >
                      (Recurring access)
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      name={`accessType-${row.id}`}
                      type="radio"
                      id={`oneOff-${row.id}`}
                      value="One Off"
                      checked={accessType === "One Off"}
                      disabled={isLoading}
                      onChange={handleAccessTypeChange}
                      className="w-4 h-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                    />
                    <label
                      htmlFor={`oneOff-${row.id}`}
                      className="block ml-2 text-sm font-medium text-gray-700"
                    >
                      (One-time access)
                    </label>
                  </div>
                </div>
                <p className="w-[21rem] ml-auto whitespace-normal text-sm">
                  If you would wish to revoke {createdBy?.names?.firstName}'s
                  access to future applications,{" "}
                  <span
                    className="text-red-500 underline decoration-red-500 cursor-pointer"
                    onClick={handleRevokeAccess}
                  >
                    Click here to revoke access.
                  </span>
                </p>
              </div>
            </div>
          )}
        <hr />
        <p className="my-2 font-medium">Comments</p>
        {loadingComments ? (
          <p className="flex justify-center py-4">
            <SpinnerIcon className="text-gray-500" />
          </p>
        ) : (
          <div className="mb-4 d-flex justify-content-end">
            <ul className="space-y-2 text-gray-800 bg-white rounded p-4">
              {comments.length > 0 ? (
                comments.map((comment) => {
                  const isCommentAuthor =
                    comment.createdBy?._id === userSlice?._id;
                  return (
                    <li key={comment?._id}>
                      <div className="flex space-x-2 justify-between">
                        <p>
                          {isCommentAuthor
                            ? "You:"
                            : `${capitalizeFirstLetter(
                                comment.createdBy?.names?.firstName
                              )}:`}{" "}
                          <span className="text-gray-500 text-sm">
                            {format(new Date(comment.createdAt), "PPPp")}
                          </span>
                        </p>
                      </div>
                      <p className="pl-2">{comment.text}</p>
                    </li>
                  );
                })
              ) : (
                <p className="text-center font-medium">No comments</p>
              )}
            </ul>
          </div>
        )}
      </td>
    </tr>
  );
};

// fetch comments in this component
function SubRowAsync({ row, rowProps, visibleColumns, setSelectedRow }) {
  const [loadingComments, setLoadingComments] = useState(false);
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);
  const { toast } = useToast(5000);
  const currentUser = useSelector((state) => state.userSlice);

  useEffect(async () => {
    if (
      row?.original?.parentRow?._id &&
      currentUser?._id &&
      row?.original?.commentsCount
    ) {
      try {
        setLoadingComments(true);
        const response = await dispatch(
          getAccessRequestComments({
            companyId: row?.original?.company?._id,
            organizationId: row?.original?.organization?._id,
            accessRequest: row?.original?.parentRow?._id,
          })
        );
        setComments(response.payload.items);
      } catch (error) {
        toast(
          "error",
          error?.message ?? "Something went wrong please try again."
        );
      } finally {
        setLoadingComments(false);
      }
    }

    return () => {
      dispatch(resetAccessRequestCommentsSlice());
    };
  }, [row.id, currentUser?._id]);

  return (
    <SubRow
      row={row}
      rowProps={rowProps}
      visibleColumns={visibleColumns}
      comments={comments}
      loadingComments={loadingComments}
      setSelectedRow={setSelectedRow}
    />
  );
}

export default function CompaniesList({ companies, original, setSelectedRow }) {
  const columns = useMemo(
    () =>
      [
        {
          Header: "Application",
          id: "application",
          accessor: "application",
          Cell: ({ row: { original } }) => {
            if (original?.company?.companyName1) {
              return getCompanyNameWithSuffix(original.company);
            }
            if (original?.organization) {
              return original.organization.names.companyName;
            }
            return "N/A";
          },
        },
        {
          Header: "Type",
          id: "type",
          accessor: "type",
          Cell: ({ row: { original } }) => {
            if (original?.organization) {
              return original.organization.partnershipType;
            }
            return original.company?.type ?? "N/A";
          },
        },
        {
          Header: "Designation",
          id: "designation",
          accessor: "designation",
          Cell: ({ row: { original } }) => original?.designation ?? "N/A",
        },
        original?.requestFor === Constants.ACCESS_REQUEST.CORPORATE_DATA && {
          Header: "For",
          id: "for",
          accessor: "for",
          Cell: ({ row }) => {
            const corporate = row?.original?.parentRow?.corporate;
            return corporate ? (
              <span className="font-medium text-gray-800">
                {corporate?.names?.companyName ??
                  `Company Reg. #${corporate?.names?.companyRegNumber}`}
              </span>
            ) : (
              "N/A"
            );
          },
        },
        {
          Header: "Comments",
          id: "comments",
          accessor: "comments",
          Cell: ({ row }) => row.original?.commentsCount || "",
        },
        {
          // Make an expander cell
          Header: () => "Actions", // No header
          id: "actions", // It needs an ID
          Cell: ({ row }) => {
            if (
              row.index !== 0 &&
              original?.requestFor !==
                Constants.ACCESS_REQUEST.CORPORATE_DATA &&
              original?.status === "Confirmed"
            )
              return "";
            const onClick = () => {
              row.getToggleRowExpandedProps().onClick();
            };

            return (
              <button
                {...row.getToggleRowExpandedProps()}
                onClick={onClick}
                className="flex items-center w-full text-sm text-gray-900 rounded-md group disabled:cursor-not-allowed"
              >
                {row.isExpanded ? (
                  <MinusIcon className="w-5 h-5" aria-hidden="true" />
                ) : (
                  <PlusIcon className="w-5 h-5" aria-hidden="true" />
                )}
              </button>
            );
          },
          // We can override the cell renderer with a SubCell to be used with an expanded row
          SubCell: () => null, // No expander on an expanded row
        },
      ].filter(Boolean),
    []
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        setSelectedRow={setSelectedRow}
      />
    ),
    []
  );

  return (
    <div className="py-4">
      <Table
        columns={columns}
        data={companies}
        renderRowSubComponent={renderRowSubComponent}
        headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
        headingStyle="pl-4 text-left"
        dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
        dataRowCellStyle="pl-4 font-normal"
      />
    </div>
  );
}
