import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _set from "lodash/set";

import partnerDataService from "services/partner";

export const addOrganizationPartner = createAsyncThunk(
  "organizationPartners/create",
  async ({ corporateId, data }) => {
    const res = await partnerDataService.create({ corporateId, ...data });
    return res.data;
  }
);

export const updateOrganizationPartner = createAsyncThunk(
  "organizationPartners/update",
  async ({ partnerId, data }) => {
    const res = await partnerDataService.update(partnerId, data);
    return res.data;
  }
);

export const deleteOrganizationPartner = createAsyncThunk(
  "organizationPartners/delete",
  async ({ partnerId, idType, corporateId }) => {
    const res = await partnerDataService.remove(partnerId, idType, corporateId);
    return res.data;
  }
);

const organizationPartnersSlice = createSlice({
  name: "Company Officials Slice",
  initialState: [],
  reducers: {
    setOrganizationPartner: (state, action) => {
      return action.payload;
    },
    updatePartnerNestedField: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
  },
  extraReducers: {
    [addOrganizationPartner.fulfilled]: (state, action) => {
      state.push(action.payload.partner);
    },
    [updateOrganizationPartner.fulfilled]: (
      state,
      { payload: { partner } }
    ) => {
      const id = partner._id;
      const index = state.findIndex((item) => id === item._id);
      if (index > -1) state.splice(index, 1, partner);
    },
    [deleteOrganizationPartner.fulfilled]: (state, { payload }) => {
      const partnerId = payload.id || payload._id;
      const index = state.findIndex(
        (item) => partnerId === item.id || partnerId === item._id
      );
      if (index > -1) state.splice(index, 1);
    },
  },
});

export const { setOrganizationPartner, updatePartnerNestedField } =
  organizationPartnersSlice.actions;

export default organizationPartnersSlice.reducer;
