import { useSelector } from "react-redux";

import RoleSwitch from "components/lib/Shared/RoleSwitch";

function Hero() {
  const { organizationName, dashboardUrl, breadCrumbs } = useSelector(
    (state) => state.activeDashboardSlice
  );

  return (
    <div className="flex justify-between flex-col sm:flex-row">
      <h1 className="text-4xl font-semibold text-primary">
        {organizationName}
      </h1>
      <div className="flex flex-col justify-end mb-4 lg:flex-row my-6 sm:my-0">
        <RoleSwitch
          organizationName={organizationName}
          breadCrumbs={breadCrumbs}
          dashboardUrl={dashboardUrl}
        />
      </div>
    </div>
  );
}

export default Hero;
