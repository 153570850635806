import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _isEmpty from "lodash/isEmpty";

import Tabs from "components/lib/Shared/Tabs";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import RefreshIcon from "components/lib/Shared/Icons/RefreshIcon";
import { getAccessRequests } from "state/slices/accessRequest";
import { useToast } from "hooks/useToast";
import RequestsTable from "./RequestsTable";
import { Constants } from "config/constants";

const initialTabs = [];

export default function AccountSettings({
  page,
  organization,
  company,
  dashboardRole,
}) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [approvedInvites, setApprovedInvites] = useState([]);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [recalledInvites, setRecalledInvites] = useState([]);
  const [deniedInvites, setDeniedInvites] = useState([]);
  const [revokedInvites, setRevokedInvites] = useState([]);
  const [tabs, setTabs] = useState(initialTabs);
  const [isLoading, setIsLoading] = useState(false);

  // redux selector
  const userSlice = useSelector((state) => state.userSlice);

  const { items: accessRequests, getAccessRequestsStatus } = useSelector(
    (state) => state.accessRequestsSlice
  );

  const dispatch = useDispatch();
  const { toast } = useToast(4000);

  let query = { page };
  if (organization) query["organization"] = organization._id;
  if (company) query["company"] = company._id;

  if (dashboardRole === Constants.DASHBOARD_ROLES.VOR) {
    query["createdBy"] = userSlice._id;
  }

  const handleRefreshData = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await dispatch(getAccessRequests({ query }));
      toast("success", "Data refreshed successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // filter data based on status
    let tempTabs = initialTabs;
    const tempConfirmedRequests = [];
    const tempPendingRequests = [];
    const tempRecalledInvites = [];
    const tempDeniedInvites = [];
    const tempRevokedInvites = [];
    if (accessRequests.length > 0) {
      accessRequests.forEach((access) => {
        if (access.status === "Confirmed") tempConfirmedRequests.push(access);
        if (access.status === "Pending") tempPendingRequests.push(access);
        if (access.status === "Recalled") tempRecalledInvites.push(access);
        if (access.status === "Denied") tempDeniedInvites.push(access);
        if (access.status === "Revoked") tempRevokedInvites.push(access);
      });
    }

    // add tabs based on data availability
    if (tempConfirmedRequests.length > 0)
      tempTabs = [{ id: "approvedInvites", label: "Approved invites" }];
    if (tempPendingRequests.length > 0)
      tempTabs = [
        ...tempTabs,
        { id: "pendingInvites", label: "Awaiting review" },
      ];
    if (tempRecalledInvites.length > 0)
      tempTabs = [
        ...tempTabs,
        { id: "recalledInvites", label: "Recalled invites" },
      ];
    if (tempDeniedInvites.length > 0)
      tempTabs = [
        ...tempTabs,
        { id: "deniedInvites", label: "Denied invites" },
      ];
    if (tempRevokedInvites.length > 0)
      tempTabs = [
        ...tempTabs,
        { id: "revokedInvites", label: "Revoked invites" },
      ];

    setTabs(tempTabs);
    setApprovedInvites(tempConfirmedRequests);
    setPendingInvites(tempPendingRequests);
    setRecalledInvites(tempRecalledInvites);
    setDeniedInvites(tempDeniedInvites);
    setRevokedInvites(tempRevokedInvites);
  }, [accessRequests]);

  useEffect(() => {
    dispatch(getAccessRequests({ query }));
  }, []);

  const selectedTab = tabs[selectedTabIndex];
  let tableData = [];
  if (selectedTab?.id === "approvedInvites") tableData = approvedInvites;
  if (selectedTab?.id === "pendingInvites") tableData = pendingInvites;
  if (selectedTab?.id === "recalledInvites") tableData = recalledInvites;
  if (selectedTab?.id === "deniedInvites") tableData = deniedInvites;
  if (selectedTab?.id === "revokedInvites") tableData = revokedInvites;

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div>
        {getAccessRequestsStatus.status === "idle" ||
        accessRequests.length > 0 ? (
          <>
            {/* accepted & awaiting tabs */}
            <div className="sm:-mt-4">
              <Tabs
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                PostPendComponent={
                  <div
                    onClick={handleRefreshData}
                    className="relative px-1 py-4 overflow-hidden text-sm font-medium text-gray-500 border-b-2 border-transparent cursor-pointer hover:text-gray-700 hover:border-gray-300 whitespace-nowrap text-ellipsis"
                  >
                    <RefreshIcon
                      className={`w-5 h-5 ${
                        isLoading ? "animate-spin cursor-not-allowed" : ""
                      }`}
                      aria-hidden="true"
                    />
                  </div>
                }
              />
            </div>
            <div>
              {tableData.length > 0 ? (
                <RequestsTable
                  requests={tableData}
                  key={selectedTab.id}
                  organization={organization}
                  company={company}
                />
              ) : (
                <h3 className="mt-6 text-xl font-medium text-center">
                  No data found
                </h3>
              )}
            </div>
          </>
        ) : (
          <p className="flex justify-center">
            <SpinnerIcon className="text-gray-500" />
          </p>
        )}
      </div>
    </div>
  );
}
