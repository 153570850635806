import { useDispatch } from "react-redux";
import { LockOpenIcon } from "@heroicons/react/outline";

import Button from "components/lib/Shared/Button";
import { openModal } from "state/slices/modals";

const AccessRequestButton = ({ modalName }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openModal({ modalName }));
  };

  return (
    <Button
      className="!ml-auto w-48 whitespace-pre-wrap text-left text-sm text-gray-600"
      onClick={handleClick}
      variant="text"
    >
      <span className="bg-gray-300 p-2 rounded-full">
        <LockOpenIcon className="flex-shrink-0 w-5 h-5" />
      </span>
      <div className="flex flex-col">
        <span className="font-semibold tracking-wider">Request Access</span>
        <span>to Restricted Data</span>
      </div>
    </Button>
  );
};

export default AccessRequestButton;
