import React from "react";
import { useSelector } from "react-redux";

import PartnersTable from "./PartnersTable";

const PartnersDetails = (props) => {
  const partners = useSelector((state) => state.partnersSlice);

  if (partners.length === 0) {
    return (
      <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center mt-4">
        <svg
          className="w-12 h-12 mx-auto text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <span className="mt-2 block text-sm font-medium text-gray-900">
          No partners
        </span>
      </div>
    );
  }

  return (
    <div className="mt-6">
      <div className="w-full mt-4">
        <div className="w-full overflow-x-auto">
          <PartnersTable data={partners} {...props} />
        </div>
      </div>
    </div>
  );
};

export default PartnersDetails;
