import axios from '../axios'

const create = data => {
  return axios.post('/nominal-shares', data)
}

const update = (nominalShareId, data) => {
  return axios.put(`/nominal-shares/${nominalShareId}`, data)
}

const remove = nominalShareId => {
  return axios.delete(`/nominal-shares/${nominalShareId}`)
}

const nominalShareDataService = {
  create,
  update,
  remove
}

export default nominalShareDataService
