import React from "react";
import _get from "lodash/get";
import { useSelector } from "react-redux";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import CommentsList from "components/lib/Global/CommentsList";
import { getFormFields } from "utils";

const CorporateSummary = ({
  addReviewComment,
  hideResolveAllCheckbox,
  currentTab = null,
  toggleCommentStatus = true,
  paginate = true,
  handleSaveComment = () => null,
}) => {
  const organization = useSelector(
    (state) => state.organizationsSlice.organization
  );
  const { comments } = useSelector((state) => state.commentsSlice);

  const corporateSummaryComments = comments.filter(
    (comment) => comment.tabName === currentTab
  );

  const {
    idType,
    names = {},
    identificationDetails = {},
    fileUploads = {},
  } = organization;

  return (
    <>
      {corporateSummaryComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 bg-white mt-4">
          <CommentsList
            comments={corporateSummaryComments}
            toggleCommentStatus={toggleCommentStatus}
            paginate={paginate}
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {corporateSummaryComments.length} of{" "}
            {corporateSummaryComments.length}
          </p>
        </div>
      )}
      <div className="divide-y-2 bg-white">
        <FormFieldsCard
          addReviewComment={addReviewComment}
          formName="idType"
          formHelperKey="corporateOfficial"
          formId={_get(organization, "_id")}
          updateEffectKey="updateOrganizationState"
          updateEffectPath={`organizationsSlice.organization`}
          resolvedFields={_get(organization, "resolvedFields")}
          hideResolveAllCheckbox={hideResolveAllCheckbox}
          fields={[
            {
              key: "Corporate Id Type",
              value: _get(organization, "idType", "N/A"),
              name: "idType",
            },
          ]}
          handleSaveComment={handleSaveComment}
          comments={corporateSummaryComments}
        />
        <FormFieldsCard
          addReviewComment={addReviewComment}
          wrapperStyles="mt-0"
          formName="names"
          formHelperKey="names"
          formId={_get(names, "_id")}
          updateEffectKey="updateOrganizationState"
          updateEffectPath={`organizationsSlice.organization.names`}
          resolvedFields={_get(names, "resolvedFields")}
          hideResolveAllCheckbox={hideResolveAllCheckbox}
          fields={getFormFields({
            idType,
            formType: "names",
            formValues: names,
          })}
          handleSaveComment={handleSaveComment}
          comments={corporateSummaryComments}
        />
        <FormFieldsCard
          addReviewComment={addReviewComment}
          wrapperStyles="mt-0"
          formName="identificationDetails"
          formHelperKey="identificationDetails"
          formId={_get(identificationDetails, "_id")}
          updateEffectKey="updateOrganizationState"
          updateEffectPath={`organizationsSlice.organization.identificationDetails`}
          resolvedFields={_get(identificationDetails, "resolvedFields")}
          hideResolveAllCheckbox={hideResolveAllCheckbox}
          fields={getFormFields({
            idType,
            formType: "identificationDetails",
            formValues: identificationDetails,
          })}
          handleSaveComment={handleSaveComment}
          comments={corporateSummaryComments}
          files={fileUploads}
        />
        <FormFieldsCard
          addReviewComment={addReviewComment}
          wrapperStyles="mt-0"
          formName="identificationDetails"
          formHelperKey="identificationDetails"
          formId={_get(identificationDetails, "_id")}
          updateEffectKey="updateOrganizationState"
          updateEffectPath={`organizationsSlice.organization.identificationDetails`}
          resolvedFields={_get(identificationDetails, "resolvedFields")}
          hideResolveAllCheckbox={hideResolveAllCheckbox}
          fields={getFormFields({
            idType,
            formType: "contactPersonInfo",
            formValues: identificationDetails,
          })}
          handleSaveComment={handleSaveComment}
          comments={corporateSummaryComments}
        />
      </div>
    </>
  );
};

export default CorporateSummary;
