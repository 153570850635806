/* eslint-disable no-console */
import axios from '../axios'
import _isEmpty from 'lodash/isEmpty'
import _entries from 'lodash/entries'

const uploadFile = (file, folder) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('folder', folder)
  return axios.post(`/files/upload`, formData)
}

const deleteFiles = files => axios.post(`/files/delete-files`, { files })

const handleFilesUpload = (files, currentOfficial = null, isEditing = false, folder = "images") => new Promise(resolve => {
  const filesToUpload = _entries(files).filter(file => typeof file[1] !== 'string')

  if (!_isEmpty(filesToUpload)) {
    (async() => {
      let uploadedFiles = {}
      try {
        await Promise.all(filesToUpload.map(async([fileName, file]) => {
          if (file) {
            const { data } = await uploadFile(file, folder)
            uploadedFiles = { ...uploadedFiles, [fileName]: data }
          }
        }))
        // if (isEditing) {
        //   const filesToDelete = filesToUpload
        //     .map(([fileName]) => currentOfficial?.fileUploads[fileName])
        //     .filter(Boolean)
        //   if (filesToDelete.length > 0) await deleteFiles(filesToDelete)
        // }
        resolve(uploadedFiles)
      } catch (error) {
        console.log(error)
      }
    })()
  } else resolve()
})

export {
  deleteFiles,
  handleFilesUpload
}
