import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import Sidebar from "components/Applications/Sidebar";
import HeroSection from "components/Applications/HeroSection";
import ReactTable from "components/lib/Global/ReactTable";
import SearchInput from "components/lib/Shared/SearchInput";
import Checkbox from "components/lib/Shared/Checkbox";
import OrgAdminDashboardWrapper from "components/lib/Global/OrgAdminDashboardWrapper";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import SubRowAsync from "./SubRowAsync";
import { getProfileComments } from "state/slices/comment";
import { getUserProfilesAsync, getUserAsync } from "state/slices/orgAdmin";
import {
  getProfessionalDetailsAsync,
  resetProfessionalDetailsSlice,
} from "state/slices/professionalDetails";
import { resetCommentsSlice } from "state/slices/comment";
import { resetUser } from "state/slices/orgAdmin";
import { getFullName } from "utils";
import GoBackToHomeBtn from "components/Applications/GoBackToHomeBtn";

const statuses = [
  { value: "Pending Review", label: "Pending Review" },
  { value: "Pending Correction", label: "Pending Correction" },
  { value: "Verified", label: "Verified" },
  { value: "Deferred", label: "Deferred" },
];

export default function Profiles() {
  const currentUser = useSelector((state) => state.userSlice);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const location = useLocation();

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState(() =>
    statuses.map((status) => status.value)
  );
  const { profiles, getUserProfiles } = useSelector(
    (state) => state.orgAdminSlice
  );

  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  const handleReview = async (row) => {
    row?.getToggleRowExpandedProps()?.onClick();
    if (!row.isExpanded) {
      dispatch(getUserAsync({ userId: row.original?._id }));
      dispatch(getProfessionalDetailsAsync({ user: row.original?._id }));
      dispatch(getProfileComments({ userId: row.original?._id }));
    }
    return () => {
      dispatch(resetUser());
      dispatch(resetProfessionalDetailsSlice());
      dispatch(resetCommentsSlice());
    };
  };

  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (isAuthenticated) {
      const queryParams = new URLSearchParams(location.search);
      if (debouncedSearchTerm) setCurrentPage(1);
      const status = queryParams.getAll("status");
      setFilters(status);
      dispatch(
        getUserProfilesAsync({
          limit: rowsPerPage,
          skip: (currentPage - 1) * rowsPerPage,
          search: debouncedSearchTerm,
          status: status.length > 0 ? status : null,
          order: "asc",
        })
      );
    }
  }, [
    isAuthenticated,
    rowsPerPage,
    currentPage,
    debouncedSearchTerm,
    location.search,
  ]);

  const refreshTableData = async () => {
    dispatch(
      getUserProfilesAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm,
        status: filters,
        order: "asc",
      })
    );
  };

  const columns = [
    {
      Header: "Full Name",
      accessor: "fullName",
      className: "p-4 whitespace-normal max-w-[150px]",
      Cell: ({ row }) => getFullName(row.original?.names ?? {}),
    },
    { Header: "ID Type", accessor: "idType" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handleReview(row)}
              className={`group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed`}
            >
              {row.isExpanded
                ? "Close"
                : row.original.status === "Pending Review"
                ? "Review"
                : "View"}
            </button>
          </div>
        );
      },
    },
  ];

  // this function will render our row sub components
  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <SubRowAsync
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          refreshTableData={refreshTableData}
        />
      );
    },
    []
  );

  if (!currentUser?._id) {
    return (
      <DashboardWrapper>
        <div className="flex justify-center mx-auto mt-10 text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </DashboardWrapper>
    );
  }

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  const handleFilterChange = (e) => {
    const params = new URLSearchParams();
    if (e.target.checked) {
      const tempFilters = [...filters, e.target.value];
      tempFilters.forEach((status) => params.append("status", status));
      history.push({ search: params.toString() });
      setFilters([...filters, e.target.value]);
    } else {
      const tempFilters = filters.filter((filter) => filter !== e.target.value);
      tempFilters.forEach((status) => params.append("status", status));
      history.push({ search: params.toString() });
      setFilters(tempFilters);
    }
    setCurrentPage(1);
  };

  return (
    <OrgAdminDashboardWrapper>
      <div className="grid gap-4 py-10 mx-auto">
        <HeroSection />
        <GoBackToHomeBtn />
        <div className="grid grid-cols-6 border-t border-gray">
          <div className="col-span-6 pr-8 md:col-span-1">
            <Sidebar />
          </div>
          <div className="col-span-6 md:border-l md:col-span-5 md:pl-8 pt-8 border-gray">
            {<h1 className="text-title5 mb-6">Pending Profiles</h1>}
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <h2 className="font-semibold">Filter by:</h2>
                <div className="flex space-x-2 my-2">
                  {statuses.map((status) => (
                    <Checkbox
                      key={status.value}
                      name={status.label}
                      label={status.label}
                      checked={filters.includes(status.value)}
                      onChange={handleFilterChange}
                    />
                  ))}
                </div>
              </div>
              <SearchInput value={searchTerm} handleOnChange={handleSearch} />
            </div>
            {profiles.items?.length > 0 ? (
              <div className="mt-4">
                <ReactTable
                  columns={columns}
                  data={profiles.items}
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  totalRows={profiles.totalCount}
                  setCurrentPage={setCurrentPage}
                  paginate
                  headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-slate-200"
                  headingStyle="pl-4 text-left"
                  dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
                  renderRowSubComponent={renderRowSubComponent}
                  isLoading={getUserProfiles.status === "loading"}
                />
              </div>
            ) : (
              <div className="relative mt-4 block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span className="mt-2 block text-sm font-semibold text-gray-900">
                  No profiles available.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </OrgAdminDashboardWrapper>
  );
}
