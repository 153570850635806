import React from "react";
import _get from 'lodash/get'
import { useSelector } from 'react-redux'
import { Formik, Field } from "formik"

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import FileUploadForm from 'components/PLCTABS/CompanyOfficialsTab/FileUploadForm'

const FileUploads = () => {
    const { companyFormation: { step1 } } = useSelector(state => state.companySlice)
    const letterOfNoObjection = _get(step1, `fileUploads.affiliatedBusiness1_LNO`)

    return (
        <div className="mt-6">
            <p>Uploaded incorporation forms</p>
            <Formik
                enableReinitialize
                initialValues={{
                    fileUploads: {
                        [`affiliatedBusiness1_LNO`]: letterOfNoObjection
                    }
                }}
                onSubmit={() => null}
            >
                {() => {
                    return (
                        <FormFieldsCard
                            fields={[
                                {
                                    key: "Form CR1",
                                    value: <>
                                        <div
                                            className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                        >
                                            {
                                                letterOfNoObjection ? (
                                                    <Field
                                                        name="fileUploads"
                                                        component={FileUploadForm}
                                                        documentData={[{ name: `affiliatedBusiness1_LNO`, label: `affiliatedBusiness1_LNO` }]}
                                                        onlyPreview
                                                    />
                                                ) : 'N/A'
                                            }
                                        </div>
                                    </>,
                                },
                            ]}
                        />

                    )
                }}
            </Formik>
        </div>
    );
}

export default FileUploads
