/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useFormikContext, Field } from "formik";

import ElectronicAddress from "../ElectronicAddress";
import Input from "components/lib/Shared/Input";
import ResidentialPhysicalAddressShared from "./ResidentialPhysicalAddressShared";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "components/PLCTABS/SFLdata/addressOptions";

// Residential Address Specific Details For Foreigner Company
const ResidentialPhysicalAddressFoCo = (props) => {
  const { field, form, disabled, disableEmailAddress } = props;
  const { setFieldValue } = useFormikContext();
  const [nationality, setNationality] = useState(() =>
    options.countries.find((country) => country.value === field?.value?.country)
  );

  const handleSelect = (type) => (option) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
    if (type === "country") {
      setNationality({ label: option.label, value: option.value });
    }
  };

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Field
        name="electronicAddress"
        component={ElectronicAddress}
        disabled={disabled}
        disableEmailAddress={disableEmailAddress}
      />
      <div className="px-4 mt-4 space-y-4 bg-white shadow-sm sm:rounded-sm sm:p-6">
        <ResidentialPhysicalAddressShared {...props} />
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="City"
              name="city"
              placeholder="Enter city"
              form={form}
              value={field.value?.city ?? ""}
              errorPath="residentialAddressDetails.city"
              disabled={disabled}
              onBlur={field.onBlur}
              onChange={handleSetValue}
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Field
              {...field}
              label="Country"
              value={nationality}
              name="country"
              form={form}
              component={ReactSelectWithFormik}
              options={options.countries}
              errorPath="residentialAddressDetails.country"
              placeholder="Select country"
              isDisabled={disabled}
              onBlur={field.onBlur}
              onChange={handleSelect("country")}
              isSearchable
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResidentialPhysicalAddressFoCo;
