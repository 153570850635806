import { Field } from "formik";

import IsNonResidentSelection from "components/lib/Shared/IsNonResidentSelection";
import ResidentialAddressDetailsFormKeCiFoRe from "../../AddressParticularsComponents/ResidentialAddressComponents/ResidentialAddressDetailsFormKeCiFoRe";
import ResidentialPhysicalAddressLoCo from "../../AddressParticularsComponents/ResidentialAddressComponents/ResidentialPhysicalAddressLoCo";
import ResidentialAddressDetailsFormFoMi from "../../AddressParticularsComponents/ResidentialAddressComponents/ResidentialAddressDetailsFormFoMi";
import PostalCodeForm from "../../PostalComponents/PostalCodeForm";
import PostalAddressFormLocal from "../../PostalComponents/PostalAddressFormLocal";
import PostalAddressFormForeign from "../../PostalComponents/PostalAddressFormForeign";

const ResidentialDetails = ({
  designation,
  idType,
  disabled,
  errors,
  touched,
  isNonResident,
}) => {
  return (
    <>
      {designation === "Company Secretary" && idType === "Kenyan Citizen" && (
        <IsNonResidentSelection
          disabled={disabled}
          errors={errors}
          touched={touched}
        />
      )}
      {((idType === "Kenyan Citizen" && isNonResident === "No") ||
        idType === "Foreign Resident") && (
        <>
          <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
            <Field
              name="postalAddress"
              component={PostalAddressFormLocal}
              idType={idType}
              disabled={disabled}
            />
            <Field
              name="postalAddress"
              component={PostalCodeForm}
              disabled={disabled}
            />
          </div>
          <Field
            name="residentialAddressDetails"
            component={ResidentialAddressDetailsFormKeCiFoRe}
            colWidth={4}
            disabled={disabled}
          />
        </>
      )}
      {idType === "Kenyan Citizen" && isNonResident === "Yes" && (
        <>
          <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
            <Field
              name="postalAddress"
              component={PostalAddressFormForeign}
              idType={idType}
              disabled={disabled}
            />
          </div>
          <Field
            name="residentialAddressDetails"
            component={ResidentialAddressDetailsFormFoMi}
            disabled={disabled}
          />
        </>
      )}
      {idType === "Local Company" && (
        <>
          <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
            <Field
              name="postalAddress"
              component={PostalAddressFormLocal}
              disabled={disabled}
            />
            <Field
              name="postalAddress"
              component={PostalCodeForm}
              disabled={disabled}
            />
          </div>
          <Field
            name="residentialAddressDetails"
            component={ResidentialPhysicalAddressLoCo}
            colWidth={4}
            disabled={disabled}
          />
        </>
      )}
      {(idType === "Foreign Company" || idType === "Foreigner") && (
        <>
          <Field
            name="postalAddress"
            component={PostalAddressFormForeign}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
};

export default ResidentialDetails;
