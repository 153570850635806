import React, { useState, useMemo } from "react";
import _get from "lodash/get";
import { useSelector } from "react-redux";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import { getFormFields } from "utils";
import Tabs from "components/lib/Shared/Tabs";
import { Constants } from "config/constants";

const CorporateSummary = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { organization, dashboardPermissions = [] } = useSelector(
    (state) => state.organizationsSlice
  );

  const viewRestrictedData = dashboardPermissions.includes(
    Constants.DASHBOARD_PERMISSIONS.VIEW_RESTRICTED_DATA
  );
  const tabs = useMemo(
    () => [
      {
        id: "company",
        label: "1. Company Summary",
      },
      {
        id: "governance",
        label: "2. Governance Summary",
        disabled: !viewRestrictedData,
      },
    ],
    [viewRestrictedData]
  );

  const { idType, names = {}, identificationDetails = {} } = organization;

  return (
    <>
      <Tabs
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        showBorderBottom={false}
      />
      <div className="divide-y-2 bg-white">
        {selectedTabIndex === 0 ? (
          <>
            <FormFieldsCard
              hideResolveAllCheckbox
              fields={[
                {
                  key: "Corporate Id Type",
                  value: _get(organization, "idType", "N/A"),
                  name: "idType",
                },
              ]}
            />
            <FormFieldsCard
              wrapperStyles="mt-0"
              hideResolveAllCheckbox
              fields={getFormFields({
                idType,
                formType: "names",
                formValues: names,
              })}
            />
            <FormFieldsCard
              wrapperStyles="mt-0"
              hideResolveAllCheckbox
              fields={getFormFields({
                idType,
                formType: "identificationDetails",
                formValues: identificationDetails,
              })}
            />
          </>
        ) : (
          <FormFieldsCard
            wrapperStyles="mt-0"
            hideResolveAllCheckbox
            fields={getFormFields({
              idType,
              formType: "contactPersonInfo",
              formValues: identificationDetails,
            })}
          />
        )}
      </div>
    </>
  );
};

export default CorporateSummary;
