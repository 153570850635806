import axios from "../axios";

const create = (data) => {
  return axios.post("/beneficial-owners", data);
};

const update = (beneficialOwnerId, data) => {
  return axios.put(`/beneficial-owners/${beneficialOwnerId}`, data);
};

const remove = (beneficialOwnerId, companyId, hardDelete = false) => {
  return axios.delete(
    `/beneficial-owners/${beneficialOwnerId}?companyId=${companyId}&hardDelete=${hardDelete}`
  );
};

const getAll = (companyId) => {
  return axios.get(`/beneficial-owners?companyId=${companyId}`);
};

const updateDropdownEntryStatus = (beneficialOwnerId, data) => {
  return axios.put(
    `/beneficial-owners/${beneficialOwnerId}/update-dropdown-status`,
    data
  );
};

const beneficialOwnerDataService = {
  create,
  update,
  remove,
  getAll,
  updateDropdownEntryStatus,
};

export default beneficialOwnerDataService;
