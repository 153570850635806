import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";

function GoBackToHomeBtn() {
  return (
    <Link
      to={"/"}
      className="flex space-x-2 text-sm font-medium rounded-md group text-gray-600 max-w-fit"
    >
      <ArrowLeftIcon
        className="w-5 h-5 text-gray-400 group-hover:text-gray-800"
        aria-hidden="true"
      />
      <span className="group-hover:text-gray-800">Go back to Home</span>
    </Link>
  );
}

export default GoBackToHomeBtn;
