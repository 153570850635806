import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import { Constants } from "config/constants";

const InActivityTracker = () => {
  const { isAuthenticated, logout } = useAuth0();
  const [InactivityLimitReached, setInactivityLimitReached] = useState(false);
  const [counter, setCounter] = useState(10);

  const events = ["click", "mousemove", "keydown", "scroll"];

  const checkForInactivity = () => {
    const expireAt = JSON.parse(localStorage.getItem("expireAt"));
    if (expireAt && expireAt < Date.now()) {
      setInactivityLimitReached(true);
      localStorage.setItem("InactivityLimitReached", "true");
    }
  };

  const updateExpiryTime = () => {
    const expireAt = Date.now() + Constants.INACTIVITY_TIMEOUT_MS;
    localStorage.setItem("expireAt", expireAt);
  };

  useEffect(() => {
    if (isAuthenticated) {
      updateExpiryTime();
      const interval = setInterval(() => {
        checkForInactivity();
      }, Constants.INACTIVE_STATE_CHECK_INTERVAL);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      events.forEach((event) => {
        window.addEventListener(event, updateExpiryTime);
      });
      return () => {
        events.forEach((event) => {
          window.removeEventListener(event, updateExpiryTime);
        });
      };
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (InactivityLimitReached) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      if (counter === 1) {
        Cookies.remove("role");
        localStorage.clear();
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }
    }
  }, [counter, InactivityLimitReached, logout]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "InactivityLimitReached" && event.newValue === "true") {
        setInactivityLimitReached(true);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      {InactivityLimitReached && isAuthenticated && (
        <div className="fixed inset-0 flex items-center justify-center backdrop-filter backdrop-blur-sm">
          <div className="overlay absolute inset-0 bg-gray-800 bg-opacity-50"></div>
          <div className="bg-white p-8 rounded-lg shadow-md relative z-10">
            <p className="text-2xl font-semibold mb-4 text-center text-gray-700">
              Your session has expired.
            </p>
            <div className="flex flex-col justify-between items-center space-x-2 space-y-3">
              <div className="flex space-y-2 flex-col justify-between items-center">
                <p className=" text-gray-700">
                  You will be redirected to the home page in {counter} seconds
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InActivityTracker;
