import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import _pick from "lodash/pick";
import _get from "lodash/get";

import Unauthorized from "pages/Unauthorized";
import PublicLimitedCompany from "components/PublicLimitedCompany";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import {
  getCompany,
  reset,
  step1Attributes,
  step2Attributes,
  step3Attributes,
  updateCompanyState,
} from "state/slices/company";
import { resetCommentsSlice } from "state/slices/comment";
import { Constants } from "config/constants";
import { useSocket } from "context/SocketContext";
import {
  addCompanyOfficial,
  deleteCompanyOfficial,
  getCompanyOfficialsAsync,
  updateCompanyOfficial,
} from "state/slices/tables/companyOfficialsSlice";
import {
  addBeneficialOwner,
  deleteBeneficialOwner,
  getAllBeneficialOwners,
  updateBeneficialOwner,
} from "state/slices/tables/beneficialOwnersTableSlice";
import { updateTemplateState } from "state/slices/template";
import { getCompanySuffix } from "utils/company";
import { getAccessRequests } from "state/slices/accessRequest";
import { updateRegisteredOfficeState } from "state/slices/registeredOffice";
import { getCoApplicantAsync } from "state/slices/coApplicant";
import { useToast } from "hooks/useToast";
import {
  addNominalShare,
  deleteNominalShare,
  updateNominalShare,
} from "state/slices/tables/nominalSharesTable";
import { getReviewers } from "state/slices/tables/reviewersTable";
import { setCookie } from "utils/cookie";

const CompanyFormationPage = () => {
  const { companyId } = useParams();
  const socket = useSocket();
  const { toast } = useToast(15000);
  const [pages, setPages] = useState([]);
  const role = Cookies.get("role");

  useEffect(() => {
    if (companyId) dispatch(getCompany({ id: companyId }));
    return () => {
      dispatch(reset());
      dispatch(resetCommentsSlice());
    };
  }, [companyId]);

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userSlice);
  const {
    companyFormation: { step1 },
    company,
  } = useSelector((state) => state.companySlice);

  const isCoApplicant = company?.isCoApplicant;

  useEffect(() => {
    if (isCoApplicant) {
      setCookie("role", Constants.COOKIE_ROLES.CO_APPLICANT);
    }
  }, [isCoApplicant]);

  if (
    role &&
    ![
      Constants.COOKIE_ROLES.OFFICIAL,
      Constants.COOKIE_ROLES.APPLICANT,
      Constants.COOKIE_ROLES.CO_APPLICANT,
    ].includes(role)
  )
    return <Unauthorized />;

  useEffect(() => {
    let tempPages = [
      {
        name: "Select your package",
        href: "/#package-selection",
        current: false,
      },
      { name: "Register your business", href: "#", current: false },
      {
        name: "Status",
        href: "#",
        current: step1?.companyName1 ? false : true,
      },
    ];
    if (step1?.companyName1) {
      const nameSuffix = getCompanySuffix(
        step1.isCompanyName1Abbreviated,
        company?.type
      );
      tempPages.push({
        name: `${step1.companyName1} ${nameSuffix}`,
        href: "#",
        current: true,
      });
    }
    setPages(tempPages);
  }, [step1?.companyName1, step1?.isCompanyName1Abbreviated, company?.type]);

  useEffect(() => {
    socket.emit("join-company-room", companyId);

    // Catch company formation socket events
    socket.on("update-company-state", ({ company, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      const step1 = _pick(company, step1Attributes);
      const step2 = _pick(company, step2Attributes);
      const step3 = _pick(company, step3Attributes);
      const resolvedFields = _get(company, "resolvedFields");
      dispatch(
        updateCompanyState({
          companyFormation: { step1, step2, step3, resolvedFields },
          company: {
            ...company,
            resolvedFields,
            articlesOfAssociation: company.articlesOfAssociation,
            applicantCapacity: company?.applicantCapacity,
          },
        })
      );
    });

    // Catch registered details socket events
    socket.on(
      Constants.SOCKET_EVENTS.UPDATE_REGISTERED_OFFICE,
      ({ registeredOffice, toastData }) => {
        if (toastData) {
          toast(toastData.type, toastData.message);
        }
        dispatch(updateRegisteredOfficeState(registeredOffice));
      }
    );

    // Catch nominal share socket events
    socket.on("add-nominal-share", ({ nominalShare, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(addNominalShare({ nominalShare }));
    });

    socket.on("update-nominal-share", ({ nominalShare, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(updateNominalShare({ nominalShare }));
    });

    socket.on("delete-nominal-share", ({ nominalShare, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(deleteNominalShare(nominalShare));
    });

    // Catch company officials socket events
    socket.on("add-company-official", ({ official, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(addCompanyOfficial({ official }));
    });
    socket.on("update-company-official", ({ official, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(updateCompanyOfficial({ official }));
    });
    socket.on("delete-company-official", ({ official, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(deleteCompanyOfficial(official));
    });
    socket.on("fetch-company-officials", (companyId) => {
      dispatch(getCompanyOfficialsAsync({ companyId }));
    });

    // Beneficial owners events
    socket.on("add-beneficial-owner", ({ beneficialOwner, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(addBeneficialOwner({ beneficialOwner }));
    });
    socket.on("update-beneficial-owner", ({ beneficialOwner, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(updateBeneficialOwner({ official: beneficialOwner }));
    });
    socket.on("delete-beneficial-owner", ({ beneficialOwner, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(deleteBeneficialOwner(beneficialOwner));
    });
    socket.on("fetch-beneficial-owner", (companyId) => {
      dispatch(getAllBeneficialOwners({ companyId }));
    });

    socket.on("fetch-assign-reviewer", ({ companyId, toastData }) => {
      if (toastData) {
        toast(toastData.type, toastData.message);
      }
      dispatch(getReviewers({ companyId }));
    });

    // Articles of association
    socket.on("set-overlay-on-company-formation-second-step", (showOverlay) => {
      dispatch(updateTemplateState({ showOverlay }));
    });
    socket.on("replace-selected-template", (template) => {
      console.log("replace-selected-template event", template);
      dispatch(
        updateTemplateState({
          selectedTemplate: template,
        })
      );
    });

    // Access requests
    socket.on(Constants.SOCKET_EVENTS.FETCH_ACCESS_REQUESTS, (company) => {
      dispatch(getAccessRequests({ query: { company } }));
    });

    socket.on(
      Constants.SOCKET_EVENTS.FETCH_CO_APPLICANTS,
      ({ companyId, toastData }) => {
        if (toastData) {
          toast(toastData.type, toastData.message);
        }
        dispatch(getCoApplicantAsync({ company: companyId }));
      }
    );

    return () => {
      socket.emit("leave-company-room", companyId);
    };
  }, [companyId]);

  return (
    <DashboardWrapper showBreadCrumbs pages={pages}>
      {currentUser && currentUser?.isOnboarded ? (
        <PublicLimitedCompany type={step1.type} />
      ) : (
        <div className="flex justify-center mx-auto text-white mt-4">
          <SpinnerIcon className="text-gray-400" />
        </div>
      )}
    </DashboardWrapper>
  );
};

export default CompanyFormationPage;
