import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import _get from "lodash/get";

import Button from "components/lib/Shared/Button";
import Tag from "components/lib/Shared/Tag";
import CommentsPopup from "components/lib/Global/CommentsPopup";
import ReactTable from "components/lib/Shared/ReactTable";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { CheckIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";
import { addComment, editComment, getComments } from "state/slices/comment";
import { resolveFields, invalidateFields } from "state/slices/resolveField";
import { Constants } from "config/constants";
import CommentsList from "components/lib/Global/CommentsList";
import ShareAllocationSelection from "./ShareAllocationSelection";
import { updateCompanyOfficialAsync } from "state/slices/tables/companyOfficialsSlice";
import DeleteButtonWithConfirmation from "components/lib/Shared/DeleteButtonWithConfirmation";
import ConfirmDialog from "components/lib/Shared/ConfirmDialog";

function OfficialShareAllocation({
  row,
  isViewMode,
  officialSliceName,
  companyOfficialEffectKey,
  officialShareAllocationComments,
  currentTab = "stakeholderTab",
}) {
  const [showAllocateSharesBtn, setAllocateSharesBtn] = useState(true);
  const [editableShareAlloc, setEditableShareAlloc] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { modalRow } = useSelector((state) => state.modalsSlice);

  // approve/disapprove allotted shares to official
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRow] = useState([]);
  const [allFieldsResolved, setAllFieldsResolved] = useState(false);

  const isReviewerMode =
    history.location.pathname.split("/").pop() === "review";
  const isReviewer = Cookies.get("role") === Constants.COOKIE_ROLES.REVIEWER;

  const { shares: nominalShareCapital } = useSelector(
    (state) => state.sharesTableSlice
  );
  const { shares } = useSelector((state) => state.sharesAllocationTableSlice);

  const alreadyTakenClassOfShares = shares.map((share) => share.classOfShares);

  let classOfSharesOptions = useMemo(() => {
    return nominalShareCapital.map(({ sharesCategory }) => ({
      label: sharesCategory,
      value: sharesCategory,
    }));
  }, [nominalShareCapital]);

  const [selectedClassOfShare, setSelectedClassOfShare] = useState("");

  useEffect(() => {
    if (editableShareAlloc?.classOfShares) {
      setSelectedClassOfShare(
        classOfSharesOptions.find(
          (classOfShares) =>
            classOfShares.value === editableShareAlloc?.classOfShares
        )
      );
    }
  }, [classOfSharesOptions, editableShareAlloc?.classOfShares]);

  const formId = _get(row.original, "_id");

  const fieldsName = row.original?.sharesAllotted
    ?.map((share) => share.classOfShares)
    .filter(Boolean); // all the shares category names

  const resolvedFields = _get(row.original, "resolvedFields", []);

  useEffect(() => {
    setAllFieldsResolved(
      fieldsName?.every((element) => resolvedFields.includes(element))
    );
  }, [fieldsName, resolvedFields]);

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            company: company._id,
            officialId: row.original._id,
            tabName: currentTab,
          },
        })
      );
    }
  };

  const officialShareAllocationColumns = [
    {
      Header: "Class of shares",
      Cell: ({ row }) => row.original.classOfShares,
    },
    { Header: "Number of Shares", accessor: "allottedShares" },
    {
      Header: "Value Of Shares",
      Cell: ({ row }) => (
        <div className="inline-flex items-baseline">
          <span className="px-1 text-xs font-medium">KES</span>{" "}
          <Tag>{row.original.sharesValue}</Tag>
        </div>
      ),
    },
    { Header: "Paid Up Shares", accessor: "paidUpShares" },
    {
      Header: "Premium Paid",
      Cell: ({ row }) => (
        <div className="inline-flex items-baseline">
          <span className="px-1 text-xs font-medium">KES</span>{" "}
          <Tag>{row.original.sharePremium}</Tag>
        </div>
      ),
    },
    {
      Header: "Nominal Value",
      Cell: ({ row }) => (
        <div className="inline-flex items-baseline">
          <span className="px-1 text-xs font-medium">KES</span>{" "}
          <Tag>{row.original.nominalValue}</Tag>
        </div>
      ),
    },
    {
      Header: "Voting Rights",
      Cell: ({ row }) => row.original.votingRights,
    },
  ];

  const handleShareEdit = (row) => {
    setEditableShareAlloc(row);
  };

  const handleShareDelete = async (shareRow) => {
    setEditableShareAlloc(null);
    await dispatch(
      updateCompanyOfficialAsync({
        officialId: row.original._id,
        data: {
          companyId: company._id,
          idType: row.original.idType,
          sharesAllotted: shares.filter((share) => share._id !== shareRow._id),
          beneficialOwnershipForm: {
            ...row.original.beneficialOwnershipForm,
            directPercentShareholding: "",
            indirectPercentShareholding: "",
            directPercentVotingRights: "",
            indirectPercentVotingRights: "",
            dateOfBecomingBO: "",
          },
        },
      })
    );
  };

  if (!isViewMode) {
    officialShareAllocationColumns.push({
      Header: "Action",
      Cell: ({ row }) => {
        const isRowLoading =
          isLoading && selectedRows.includes(row.original.classOfShares);
        return (
          <div className="flex items-center space-x-2">
            <Button
              className="items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed"
              onClick={() => handleShareEdit(row.original)}
              disabled={resolvedFields.includes(row.original.classOfShares)}
            >
              Edit
            </Button>
            <DeleteButtonWithConfirmation
              confirmationModalName="delete_share_allocation_confirmation_modal"
              row={row.original}
              disabled={resolvedFields.includes(row.original.classOfShares)}
              btnClasses="items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:cursor-not-allowed"
            />
            {!resolvedFields.includes(row.original.classOfShares) && (
              <CommentsPopup
                handleSaveComment={handleSaveComment}
                formName="officialShareAllocation"
                fieldName={row.original.classOfShares}
                fieldLabel={row.original.classOfShares}
                comment={officialShareAllocationComments.find(
                  (comment) =>
                    comment.fieldName === row.original.classOfShares &&
                    currentUser?._id === comment.createdBy?._id
                )}
              >
                <span>Review</span>
                <PencilIcon className="inline w-4 h-4" />
              </CommentsPopup>
            )}
            <div className={`flex items-center justify-center pr-4`}>
              {resolvedFields.includes(row.original.classOfShares) ? (
                <button
                  className={`flex space-x-1 ${
                    isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={() =>
                    isRowLoading
                      ? null
                      : handleInvalidateBtnClicked(row.original.classOfShares)
                  }
                >
                  {isRowLoading ? (
                    <SpinnerIcon className="text-primary" />
                  ) : (
                    <>
                      <span>Accepted </span>
                      <XIcon className="inline w-4 h-4" />
                    </>
                  )}
                </button>
              ) : (
                <button
                  className={`flex space-x-1 ${
                    isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={() =>
                    isRowLoading
                      ? null
                      : handleResolveBtnClicked(row.original.classOfShares)
                  }
                >
                  {isRowLoading ? (
                    <SpinnerIcon className="text-primary" />
                  ) : (
                    <>
                      <span>Accept</span>
                      <CheckIcon className="inline w-4 h-4" />
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        );
      },
    });
  }

  const handleResolveBtnClicked = async (fieldName) => {
    // approve field
    const commentsToBeResolved = officialShareAllocationComments?.filter(
      (comment) => comment.fieldName === fieldName && !comment.isResolved
    );
    const commentsToBeResolvedIds = commentsToBeResolved?.map(({ _id }) => _id);
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      resolveFields({
        data: {
          formId,
          formName:
            currentTab === "stakeholderTab"
              ? INDIVIDUALS.includes(row?.original?.idType)
                ? "individualOfficial"
                : "corporateOfficial"
              : "beneficialOwner",
          fieldName,
          resolvedFields: [fieldName],
          commentsToBeResolvedIds,
        },
        updateEffectKey: companyOfficialEffectKey,
        updateEffectPath: officialSliceName,
      })
    );
    if (commentsToBeResolvedIds.length > 0) {
      const query = { companyId: commentsToBeResolved[0]?.company };
      if (isReviewerMode) query.createdBy = currentUser?._id;
      await dispatch(getComments(query));
    }
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const handleInvalidateBtnClicked = async (fieldName) => {
    // disapprove field
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      invalidateFields({
        data: {
          formId,
          formName:
            currentTab === "stakeholderTab"
              ? INDIVIDUALS.includes(row?.original?.idType)
                ? "individualOfficial"
                : "corporateOfficial"
              : "beneficialOwner",
          fieldName,
          invalidFields: [fieldName],
        },
        updateEffectKey: companyOfficialEffectKey,
        updateEffectPath: officialSliceName,
      })
    );
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const handleAllFieldsStatus = async (e) => {
    const commentsToBeResolved = officialShareAllocationComments?.filter(
      (comment) => !comment.isResolved
    );
    const commentsToBeResolvedIds = commentsToBeResolved?.map(({ _id }) => _id);
    setIsLoading(true);
    if (e.target.checked) {
      // resolve/approve all the form fields
      const unresolvedFields = fieldsName.filter(
        (fieldName) => !resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [...selectedRows, ...unresolvedFields]);
      await dispatch(
        resolveFields({
          data: {
            formId,
            formName:
              currentTab === "stakeholderTab"
                ? INDIVIDUALS.includes(row?.original?.idType)
                  ? "individualOfficial"
                  : "corporateOfficial"
                : "beneficialOwner",
            resolvedFields: unresolvedFields,
            commentsToBeResolvedIds,
          },
          updateEffectKey: companyOfficialEffectKey,
          updateEffectPath: officialSliceName,
        })
      );
      if (commentsToBeResolvedIds.length > 0) {
        const query = { companyId: commentsToBeResolved[0]?.company };
        if (isReviewerMode) query.createdBy = currentUser?._id;
        await dispatch(getComments(query));
      }
      setIsLoading(false);
      setSelectedRow([]);
    } else {
      // invalidate/disapprove all the form fields
      const resolvedFieldsSlice = fieldsName.filter((fieldName) =>
        resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [
        ...selectedRows,
        ...resolvedFieldsSlice,
      ]);
      await dispatch(
        invalidateFields({
          data: {
            formId,
            formName:
              currentTab === "stakeholderTab"
                ? INDIVIDUALS.includes(row?.original?.idType)
                  ? "individualOfficial"
                  : "corporateOfficial"
                : "beneficialOwner",
            invalidFields: resolvedFieldsSlice,
          },
          updateEffectKey: companyOfficialEffectKey,
          updateEffectPath: officialSliceName,
        })
      );
      setSelectedRow([]);
    }
    setIsLoading(false);
  };

  const showShareAllocationSelection =
    !showAllocateSharesBtn || editableShareAlloc;

  const availableClassOfSharesOptions = classOfSharesOptions.filter(
    (option) => !alreadyTakenClassOfShares.includes(option.value)
  );

  if (showShareAllocationSelection) {
    return (
      <div className="px-8 pt-5 pb-6 my-6 bg-white">
        <ShareAllocationSelection
          companyId={company._id}
          setAllocateSharesBtn={setAllocateSharesBtn}
          editableShareAlloc={editableShareAlloc}
          setEditableShareAlloc={setEditableShareAlloc}
          currentOfficial={row.original}
          alreadyTakenClassOfShares={alreadyTakenClassOfShares}
          selectedClassOfShare={selectedClassOfShare}
          setSelectedClassOfShare={setSelectedClassOfShare}
        />
      </div>
    );
  }

  return (
    <div className="mt-4">
      <ConfirmDialog
        name="delete_share_allocation_confirmation_modal"
        onConfirm={() => handleShareDelete(modalRow)}
      >
        Are you sure you want to permanently delete this entry?
      </ConfirmDialog>
      {availableClassOfSharesOptions.length > 0 && (
        <Button
          className="mb-2"
          disabled={availableClassOfSharesOptions.length === 0}
          onClick={() => {
            setAllocateSharesBtn(false);
          }}
        >
          Allot Shares
        </Button>
      )}
      {officialShareAllocationComments &&
        officialShareAllocationComments.length > 0 && (
          <div className="px-8 pt-5 pb-6 my-6 bg-white">
            <CommentsList
              comments={officialShareAllocationComments}
              toggleCommentStatus
              applicantReviewer
            />
            <p className="mt-6 text-sm text-primary">
              Comments: {officialShareAllocationComments.length} of{" "}
              {officialShareAllocationComments.length}
            </p>
          </div>
        )}
      {row.original.sharesAllotted?.length > 0 ? (
        <>
          <div className="bg-white shadow overflow-auto">
            <ReactTable
              columns={officialShareAllocationColumns}
              data={row.original.sharesAllotted}
              headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600"
              headingStyle="pl-4 text-left"
              dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            />
          </div>
          {!isReviewer && (
            <div className="flex items-center mt-2">
              <input
                aria-describedby="comments-description"
                type="checkbox"
                className="mr-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                onChange={handleAllFieldsStatus}
                checked={allFieldsResolved}
              />
              <p className="flex items-center text-sm font-semibold">
                Click this checkbox to{" "}
                {allFieldsResolved ? "disapprove" : "approve"} all entries if
                they are {allFieldsResolved ? "incorrect" : "correct"} then
                proceed to next step
              </p>
            </div>
          )}
        </>
      ) : (
        <p className="mt-4 text-center">No data available</p>
      )}
    </div>
  );
}

export default OfficialShareAllocation;
