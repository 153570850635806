/* eslint-disable react/no-danger */
import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import ReactTable from "components/lib/Global/ReactTable";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import {
  calculateCumulativeOwnership,
  delay,
  getFullName,
  getMaskedEmail,
  getBeneficialOwnershipFormFields,
  roundNumber,
} from "utils";
import { deleteBeneficialOwnerAsync } from "state/slices/tables/beneficialOwnersTableSlice";
import { useParams } from "react-router-dom";
import { CORPORATES } from "../SFLdata/config/constants";
import NotificationBanner from "components/lib/Shared/NotificationBanner";
import BeneficialOwnersSubRowAsync from "./BeneficialOwnersSubRowAsync";
import TypeOfControlColumn from "components/lib/Shared/TypeOfControlColumn";
import { PlusIcon } from "components/lib/Shared/Icons/sflIcons";
import { Constants } from "config/constants";
import AvatarIcon from "components/lib/Shared/Icons/AvatarIcon";

const BeneficialOwnersTable = ({
  handleEdit,
  handleDelete,
  data,
  showActionColumn,
  handleBackBtn,
  groupBOEntries = true,
  hideColumnsRow,
  selectedTableType,
}) => {
  const role = Cookies.get("role");
  const dispatch = useDispatch();
  const collapseBtnRef = useRef(null);
  const { company } = useSelector((state) => state.companySlice);
  const currentUser = useSelector((state) => state.userSlice);
  const params = useParams();
  const companyId = company?._id ?? params?.companyId;
  const { beneficialOwners, deleteBeneficialOwnerStatus } = useSelector(
    (state) => state.beneficialOwnersTableSlice
  );
  const beneficialOwnersDropdown = beneficialOwners.filter(
    (bo) => bo.showInBoDropdown
  );
  const allBeneficialOwners = [
    ...beneficialOwners,
    ...beneficialOwnersDropdown,
  ];

  const getBoFullName = ({ names, remainingMinorityShareholders }) => {
    if (remainingMinorityShareholders && remainingMinorityShareholders >= 0)
      return (
        <span>{`${remainingMinorityShareholders} Minority Shareholders`}</span>
      );
    return getFullName(names);
  };

  const getLinkedName = (row) => {
    const { linkToCompany, shareholderWithBO, JSCombinedName } = row;

    if (JSCombinedName) return JSCombinedName;
    if (linkToCompany) {
      const linkToCompanyBO = allBeneficialOwners.find(
        (bo) => bo._id === linkToCompany || bo._id === linkToCompany?._id
      );
      if (!linkToCompanyBO) {
        if (!deleteBeneficialOwnerStatus.inProgressIds.includes(row._id)) {
          dispatch(
            deleteBeneficialOwnerAsync({
              beneficialOwnerId: row._id,
              companyId,
            })
          );
        }
        return "";
      }
      if (linkToCompanyBO.linkToCompany || linkToCompanyBO.shareholderWithBO)
        return `${getLinkedName(linkToCompanyBO)} -> ${getBoFullName(
          linkToCompanyBO
        )}`;
      return getBoFullName(linkToCompanyBO);
    }

    if (shareholderWithBO) {
      const linkedCompany = allBeneficialOwners.find(
        (bo) => bo._id === shareholderWithBO
      );
      if (!linkedCompany) {
        if (!deleteBeneficialOwnerStatus.inProgressIds.includes(row._id)) {
          dispatch(
            deleteBeneficialOwnerAsync({
              beneficialOwnerId: row._id,
              companyId,
            })
          );
        }
        return "";
      }
      if (linkedCompany.linkToCompany || linkedCompany.shareholderWithBO)
        return `${getLinkedName(linkedCompany)} -> ${getBoFullName(
          linkedCompany
        )}`;
      return getBoFullName(linkedCompany);
    }
    return "Directly";
  };

  const columns = [
    {
      Header: "Details",
      accessor: "fullName",
      className: "p-4 text-ellipsis overflow-hidden",
      Cell: ({ row: { original } }) => {
        if (!groupBOEntries) return <div className="w-56"> </div>;
        if (original?.remainingMinorityShareholders) {
          const minorityFullName = `${original.remainingMinorityShareholders} Minority Shareholders`;
          return <span>{minorityFullName}</span>;
        }
        const emailAddress =
          original?.emailAddress || original?.electronicAddress?.emailAddress;
        const fullName = getBoFullName(original);
        if (
          CORPORATES.includes(original.idType) ||
          original.idType === "Joint Shareholder"
        ) {
          return <span>{fullName}</span>;
        }
        return (
          <div className="flex items-center space-x-1 w-52">
            <div className={`flex-shrink-0`}>
              {original?.fileUploads?.passportPhoto ? (
                <img
                  className="w-8 h-8 rounded-full"
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${original?.fileUploads?.passportPhoto}`}
                  alt={"Bo Official"}
                />
              ) : (
                <div className="w-8 h-8 rounded-full">
                  <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <AvatarIcon />
                  </div>
                </div>
              )}
            </div>
            <div className="p-3">
              <h1 className="font-bold">{fullName}</h1>
              <p>
                {original?.type === "invited"
                  ? getMaskedEmail(emailAddress)
                  : emailAddress}
              </p>
              <p className="mt-1">{original.idType}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Link to Company",
      className: "p-4 text-ellipsis overflow-hidden max-w-[150px]",
      Cell: ({ row }) => {
        if (row.original?.LinkToCompanyLabel)
          return <div className="w-28">{row.original.LinkToCompanyLabel}</div>;
        if (row.original?.children) {
          const linkedCompanyNames = row.original.children?.map((child) =>
            getLinkedName(child)
          );
          if (linkedCompanyNames.length === 1)
            return <div className="w-28">{linkedCompanyNames}</div>;
          const lastName = linkedCompanyNames.pop();
          const linkedNames =
            linkedCompanyNames.join(", ") + " and " + lastName;
          return <div className="w-28">{linkedNames}</div>;
        }
        return <div className="w-28">{getLinkedName(row.original)}</div>;
      },
    },
    {
      Header: "Type Of Control",
      Cell: ({ row }) => {
        const fields = getBeneficialOwnershipFormFields({
          beneficialOwnershipForm: row.original.beneficialOwnershipForm,
          reviewTab: true,
          beneficialOwner: row.original,
          beneficialOwners,
        });
        return <TypeOfControlColumn fields={fields.slice(0, 4)} />;
      },
    },
    {
      Header: "Cumulative Ownership",
      Cell: ({ row: { original } }) => {
        const { cumulativeShareholdings, cumulativeVotings } =
          calculateCumulativeOwnership(original, beneficialOwners);
        return (
          <div className="w-24">{`${roundNumber(
            cumulativeShareholdings
          )}%, ${roundNumber(cumulativeVotings)}%`}</div>
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const isOwnerRow = [
          row.original?.electronicAddress?.emailAddress,
          row.original?.identificationDetails?.guardianEmailAddress,
        ].includes(currentUser.electronicAddress.emailAddress);

        const disableEditBtn =
          (row.original?.type === "invited" ||
            role === Constants.COOKIE_ROLES.OFFICIAL) &&
          !isOwnerRow;
        const parentOfficial = allBeneficialOwners.find(
          (bo) => bo._id === row.original?.linkToCompany
        );
        const isParentComingFromCOTab =
          !parentOfficial?.linkToCompany && !parentOfficial?.shareholderWithBO;

        let reviewBtnProps = {};
        if (row?.getToggleRowExpandedProps) {
          reviewBtnProps = row?.getToggleRowExpandedProps();
        }

        return (
          <div className="flex flex-col items-center">
            {showActionColumn &&
              (groupBOEntries
                ? !row.isExpanded && row.original?.children
                : true) && (
                <TableActionMenu
                  row={row}
                  showViewBtn={role !== Constants.COOKIE_ROLES.OFFICIAL}
                  showReviewBtn={
                    !isOwnerRow &&
                    row.original?.type === "invited" &&
                    row.original.createdBy === currentUser._id &&
                    !row.original?.isReadOnly
                  }
                  disableEditBtn={
                    (((row.original?.type === "invited" ||
                      role === Constants.COOKIE_ROLES.OFFICIAL) &&
                      !isOwnerRow) ||
                      row.original?.isReadOnly) &&
                    !isParentComingFromCOTab &&
                    row.original?.isReadOnly
                  }
                  disableDeleteBtn={
                    (((row.original?.type === "invited" ||
                      role === Constants.COOKIE_ROLES.OFFICIAL) &&
                      !isOwnerRow) ||
                      row.original?.isReadOnly) &&
                    !isParentComingFromCOTab &&
                    row.original?.isReadOnly
                  }
                  handleEdit={(row) =>
                    handleEdit({
                      ...row,
                      mode: "editing",
                      isViewOnly:
                        (disableEditBtn || row.original?.isReadOnly) &&
                        !isParentComingFromCOTab,
                    })
                  }
                  handleView={(row) => {
                    handleEdit({
                      ...row,
                      isViewOnly:
                        (disableEditBtn || row.original?.isReadOnly) &&
                        !isParentComingFromCOTab,
                    });
                  }}
                  handleDelete={handleDelete}
                  handleReview={handleReview}
                />
              )}
            {groupBOEntries &&
              !row.isExpanded &&
              row.original?.children &&
              row.original?.children?.length > 1 && (
                <button
                  type="button"
                  {...reviewBtnProps}
                  ref={collapseBtnRef}
                  className={`mt-32 text-gray-900 bg-gray-200 rounded-full items-center p-2`}
                >
                  <PlusIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              )}
          </div>
        );
      },
    },
  ].filter(Boolean);

  const handleReview = async (row) => {
    if (!row.linkToCompany) {
      handleBackBtn();
      await delay(); // wait for the company officials tab to be mounted
      let companyShareholderReviewBtn = null;
      if (row?.JSGroupID)
        companyShareholderReviewBtn = document.querySelector(
          `[id="${row?.JSGroupID}_reviewButton"]`
        );
      else if (row?._id && !row.JSGroupID)
        companyShareholderReviewBtn = document.querySelector(
          `[id="${row?._id}_reviewButton"]`
        );
      if (companyShareholderReviewBtn) companyShareholderReviewBtn.click();
      return;
    }
    row?.getToggleRowExpandedProps()?.onClick();
  };

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <BeneficialOwnersSubRowAsync
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          currentTab="beneficialOwnership"
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleBackBtn={handleBackBtn}
          selectedTableType={selectedTableType}
        />
      );
    },
    []
  );

  return (
    <>
      <NotificationBanner notificationKey="flashMessageBOTable" />
      <div className="w-full bg-white shadow">
        <div className="w-full overflow-x-auto">
          <ReactTable
            columns={columns}
            data={data}
            headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-slate-200"
            headingStyle="px-4 text-left"
            dataRowStyle={`h-12 text-sm text-gray-700 bg-white ${
              !groupBOEntries
                ? "border-white border-b-0"
                : "border-b border-t border-gray-200"
            } hover:bg-gray-50`}
            dataRowCellStyle="whitespace-normal px-4"
            renderRowSubComponent={renderRowSubComponent}
            hideColumnsRow={hideColumnsRow}
          />
        </div>
      </div>
    </>
  );
};

export default BeneficialOwnersTable;
