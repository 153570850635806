import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { PrinterIcon } from "@heroicons/react/solid";
import { addMonths, format, startOfWeek } from "date-fns";
import { Form, Formik } from "formik";
import { Menu, Transition } from "@headlessui/react";
import { isAfter } from "date-fns";

import ReactTable from "components/lib/Global/ReactTable";
import Checkbox from "components/lib/Shared/Checkbox";
import Button from "components/lib/Shared/Button";
import ExportIcon from "components/lib/Shared/Icons/Export";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import Radio from "components/lib/Shared/Radio";
import DatePickerField from "components/lib/Shared/DatePickerField";
import {
  downloadInvoiceAsync,
  exportInvoicesAsync,
  getInvoiceAsync,
  getInvoicesAsync,
} from "state/slices/invoice";
import { closeModal, openModal } from "state/slices/modals";
import { formatCurrency } from "utils";
import ViewInvoice from "./View";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

const currencyTypes = [
  { value: "KES", label: "KES" },
  { value: "USD", label: "USD" },
];

const InvoicesTable = ({ onlySelf }) => {
  const dispatch = useDispatch();
  const { invoices, getInvoices } = useSelector((state) => state.invoiceSlice);
  const userSlice = useSelector((state) => state.userSlice);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [selectAllInvoices, setSelectAllInvoices] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [downloadingInvoices, setDownloadingInvoices] = useState(false);
  const [currencyFilters, setCurrencyFilters] = useState(
    currencyTypes.map((currencyType) => currencyType.value)
  );

  useEffect(() => {
    dispatch(
      getInvoicesAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        createdBy: onlySelf ? userSlice?._id : undefined,
        currency: currencyFilters.length > 0 ? currencyFilters : null,
      })
    );
    setSelectAllInvoices(false);
    setSelectedInvoices([]);
  }, [rowsPerPage, currentPage, currencyFilters]);

  useEffect(() => {
    if (selectedInvoices.length === invoices.items.length) {
      setSelectAllInvoices(true);
    } else {
      setSelectAllInvoices(false);
    }
  }, [selectedInvoices, invoices.totalCount]);

  const handleToggleInvoiceSelection = (e) => {
    const invoice = invoices.items.find(
      (invoice) => invoice._id === e.target.value
    );
    if (e.target.checked) {
      setSelectedInvoices((prevSelection) => [...prevSelection, invoice]);
    } else {
      setSelectedInvoices((prevSelection) =>
        prevSelection.filter((invoiceId) => invoiceId._id !== e.target.value)
      );
    }
  };

  const handleToggleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectAllInvoices(true);
      setSelectedInvoices(invoices.items.map((invoice) => invoice));
    } else {
      setSelectAllInvoices(false);
      setSelectedInvoices([]);
    }
  };

  const handleViewInvoice = (invoice) => {
    dispatch(getInvoiceAsync({ invoiceId: invoice._id }));
    dispatch(openModal({ modalName: "view_invoice" }));
  };

  const columns = [
    {
      Header: "",
      id: "invoiceSelection",
      className: "p-4 whitespace-normal max-w-[30px]",
      Cell: ({ row }) => (
        <Checkbox
          name={row.original._id}
          checked={selectedInvoices.find(
            (invoice) => row.original._id === invoice._id
          )}
          onChange={handleToggleInvoiceSelection}
        />
      ),
    },
    {
      Header: "Org Details",
      accessor: "orgName",
      className: "p-4 whitespace-normal max-w-[150px]",
    },
    {
      Header: "Plan",
      accessor: "plan",
    },
    {
      Header: "Payment",
      accessor: "paymentMethod",
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }) => {
        const totalAmount = row.original.items.reduce((total, item) => {
          return total + item.rate * item.quantity;
        }, 0);
        return formatCurrency(totalAmount);
      },
    },
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ row }) => {
        return format(new Date(row.original.createdAt), "dd-LL-yyyy");
      },
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button>
                <DotsVerticalIcon className="w-6 h-6" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-[9999] top-0 right-5 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <Button
                        overrideBaseStyles
                        onClick={() => handleViewInvoice(row.original)}
                        className={`${
                          active ? "bg-violet-500 text-white" : "text-gray-900"
                        } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                      >
                        View
                      </Button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        );
      },
    },
  ];

  const handleDownloadInvoice = async () => {
    try {
      setDownloadingInvoices(true);
      await Promise.all(
        selectedInvoices.map((selectedInvoice) =>
          dispatch(
            downloadInvoiceAsync({
              invoiceId: selectedInvoice._id,
              invoiceNumber: selectedInvoice.number,
            })
          )
        )
      );
      setDownloadingInvoices(false);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleExportInvoices = async ({
    fileFormat,
    exportStartDate,
    exportEndDate,
  }) => {
    await dispatch(
      exportInvoicesAsync({
        fileName: `invoices ${format(
          new Date(exportStartDate),
          "PP"
        )} - ${format(new Date(exportEndDate), "PP")}`,
        fileFormat,
        startDate: exportStartDate,
        endDate: exportEndDate,
      })
    );
    dispatch(closeModal("export_invoices_modal"));
  };

  const handleFilterChange = (e) => {
    if (e.target.checked) {
      setCurrencyFilters([...currencyFilters, e.target.value]);
    } else {
      const tempFilters = currencyFilters.filter(
        (filter) => filter !== e.target.value
      );
      setCurrencyFilters(tempFilters);
    }
    setCurrentPage(1);
  };

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <div className="flex space-x-2 my-2">
            <Checkbox
              name="selectAll"
              label="Select All"
              checked={selectAllInvoices && invoices.totalCount > 0}
              indeterminate={
                !selectAllInvoices &&
                selectedInvoices.length > 0 &&
                invoices.totalCount > 0
              }
              disabled={invoices.totalCount === 0}
              onChange={handleToggleSelectAll}
            />
            {currencyTypes.map((status) => (
              <Checkbox
                key={status.value}
                name={status.label}
                label={status.label}
                checked={currencyFilters.includes(status.value)}
                onChange={handleFilterChange}
              />
            ))}
          </div>
        </div>
        {invoices.totalCount > 0 && (
          <div className="flex space-x-4 text-primary">
            {downloadingInvoices ? (
              <div className="flex justify-center items-center">
                <SpinnerIcon className="text-gray-500" />
                Downloading...
              </div>
            ) : (
              <Button
                variant="text"
                disabled={downloadingInvoices}
                preIcon={DownloadIcon}
                onClick={handleDownloadInvoice}
              >
                Download Selected
              </Button>
            )}
            <Button disabled variant="text" preIcon={PrinterIcon}>
              Print
            </Button>
            <Button
              variant="text"
              preIcon={ExportIcon}
              onClick={() =>
                dispatch(openModal({ modalName: "export_invoices_modal" }))
              }
            >
              Export
            </Button>
          </div>
        )}
      </div>
      {invoices.totalCount > 0 ? (
        <>
          <div className="w-full overflow-x-auto">
            <ReactTable
              columns={columns}
              data={invoices.items}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              totalRows={invoices.totalCount}
              setCurrentPage={setCurrentPage}
              paginate
              isLoading={getInvoices.status === "loading"}
              headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
              headingStyle="pl-4 text-left text-white"
              dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            />
          </div>
        </>
      ) : (
        <p className="text-2xl text-center text-primary">
          No invoices available.
        </p>
      )}
      {/* Export Invoices */}
      <ModalWrapper
        name="export_invoices_modal"
        title="Export Invoices"
        maxWidth="sm:max-w-fit"
      >
        <div className="mt-8 text-lg">
          <Formik
            enableReinitialize
            initialValues={{
              exportStartDate: startOfWeek(new Date()),
              exportEndDate: new Date(),
              fileFormat: "xlsx",
            }}
            onSubmit={handleExportInvoices}
          >
            {({ values, isSubmitting, setFieldValue }) => {
              const { fileFormat, exportStartDate, exportEndDate } = values;
              const handleDateValue = (type) => (date) => {
                if (type === "exportStartDate") {
                  const maxEndDate = addMonths(new Date(date), 3);
                  const exportEndDate = isAfter(maxEndDate, new Date())
                    ? new Date()
                    : maxEndDate;
                  setFieldValue(
                    "exportEndDate",
                    format(exportEndDate, "yyyy/MM/dd")
                  );
                }
                setFieldValue([type], format(new Date(date), "yyyy/MM/dd"));
              };
              const maxEndDate = addMonths(new Date(exportStartDate), 3);
              return (
                <Form className="text-sm w-full">
                  <div className="flex gap-2">
                    <div className="flex flex-col grow">
                      <DatePickerField
                        id="startDate"
                        name="exportStartDate"
                        label="Start date"
                        selected={
                          exportStartDate ? new Date(exportStartDate) : ""
                        }
                        maxDate={new Date()}
                        onChange={handleDateValue("exportStartDate")}
                        onSelect={handleDateValue("exportStartDate")}
                      />
                    </div>
                    <div className="flex flex-col grow">
                      <DatePickerField
                        selected={exportEndDate ? new Date(exportEndDate) : ""}
                        name="exportEndDate"
                        label="End date"
                        minDate={new Date(exportStartDate)}
                        maxDate={
                          isAfter(maxEndDate, new Date())
                            ? new Date()
                            : maxEndDate
                        }
                        onChange={handleDateValue("exportEndDate")}
                        onSelect={handleDateValue("exportEndDate")}
                      />
                    </div>
                  </div>
                  <div className="col-span-6 pt-2">
                    <fieldset>
                      <legend className="block text-sm font-medium text-gray-700">
                        Select file format
                      </legend>
                      <div className="mt-1">
                        <div className="flex space-x-6">
                          <Radio
                            id="xlsx"
                            name="fileFormat"
                            label="XLSX"
                            type="radio"
                            checked={fileFormat === "xlsx"}
                            onChange={() => setFieldValue("fileFormat", "xlsx")}
                            className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                          />
                          <Radio
                            id="csv"
                            name="fileFormat"
                            label="CSV"
                            type="radio"
                            checked={fileFormat === "csv"}
                            onChange={() => setFieldValue("fileFormat", "csv")}
                            className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className="flex justify-end space-x-2 mt-4">
                    <Button
                      disabled={isSubmitting}
                      onClick={() =>
                        dispatch(closeModal("export_invoices_modal"))
                      }
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      loadingText="Confirming..."
                    >
                      Export
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalWrapper>

      {/* View Invoice */}
      <ModalWrapper
        name="view_invoice"
        title="View Invoice"
        maxWidth="sm:max-w-fit"
      >
        <div className="mt-8 text-lg">
          <ViewInvoice />
        </div>
      </ModalWrapper>
    </>
  );
};

export default InvoicesTable;
