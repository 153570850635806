import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { Formik, Field } from "formik";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import Tabs from "components/lib/Shared/Tabs";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import {
  getFormFields,
  getFullName,
  getTransformedFormFields,
  getBeneficialOwnershipFormFields,
} from "utils";
import { getPartner, resetPartner } from "state/slices/organizationDashboard";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { Constants } from "config/constants";
import { closeModal } from "state/slices/modals";
import { addAccessRequest } from "state/slices/accessRequest";
import { useToast } from "hooks/useToast";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";
import AccessRequestButton from "components/lib/Shared/CompanyDashboard/AccessRequestButton";
import RequestConfirmationModal from "components/lib/Shared/CompanyDashboard/RequestConfirmationModal";
import AssignPermissionsSelection from "components/lib/Shared/CompanyDashboard/AssignPermission/Selection";

const initialTabs = [
  {
    id: "generalInformation",
    label: "General information",
  },
  {
    id: "residentialDetails",
    label: "Address details",
  },
  {
    id: "uploadDocuments",
    label: "File Uploads",
  },
];

const MODAL_NAME = "initiate_request_confirmation_modal_organization_dashboard";

const { DASHBOARD_PERMISSIONS } = Constants;

function PartnerSubRow({ visibleColumns, row }) {
  console.log(`row, `, row);
  const params = useParams();
  const { toast } = useToast(5000);
  const dispatch = useDispatch();
  const { partner, getPartnerStatus } = useSelector(
    (state) => state.organizationDashboardSlice
  );
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { organization, dashboardPermissions = [] } = useSelector(
    (state) => state.organizationsSlice
  );
  const { beneficialOwners } = organization;
  const { comments } = useSelector((state) => state.commentsSlice);
  const currentUser = useSelector((state) => state.userSlice);
  const { items } = useSelector((state) => state.accessRequestsSlice);

  const accessRequests = items.filter(
    (item) =>
      item.status === "Pending" &&
      item.page === "ORGANIZATION_DASHBOARD" &&
      item.organization?._id === organization._id
  );
  const isCurrentUserRow = row.original?.isCurrentUserRow;
  const isVORPLUS = dashboardPermissions.includes(
    DASHBOARD_PERMISSIONS.SHOW_REQUEST_BUTTON
  );
  const showLimitedData =
    !isCurrentUserRow &&
    !dashboardPermissions.includes(DASHBOARD_PERMISSIONS.VIEW_RESTRICTED_DATA);
  const showRequestButton =
    accessRequests.length === 0 && showLimitedData && isVORPLUS;
  const currentUserRow = organization.individualOfficials.find(
    (official) => official.isCurrentUserRow
  );

  const isCurrentUserCorporateRow = organization.corporateOfficials.find(
    (official) => official.isCurrentUserRow
  );

  useEffect(() => {
    if (row?.original?._id) {
      dispatch(
        getPartner({
          organizationId: params.corporateId,
          partnerId: row?.original?._id,
          idType: row?.original?.idType,
        })
      );
    }
    return () => {
      dispatch(resetPartner());
    };
  }, [row?.original?._id]);

  useMemo(() => {
    setTabs(
      [
        {
          id: "generalInformation",
          label: "General information",
        },
        {
          id: "residentialDetails",
          label: "Address details",
        },
        !showLimitedData || isVORPLUS
          ? {
              id: "uploadDocuments",
              label: "File Uploads",
            }
          : null,
      ].filter(Boolean)
    );
  }, [comments, showLimitedData, isVORPLUS]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  if (!partner || getPartnerStatus.status === "loading") {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto my-2 ">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  const namesFormFields = getTransformedFormFields({
    idType: partner.idType,
    formType: "names",
    formValues: partner.names,
    formatDOB: isCurrentUserRow || !showLimitedData,
  });

  const identificationFormFields = getTransformedFormFields({
    idType: partner.idType,
    formType: "identificationDetails",
    formValues: partner.identificationDetails,
    formatDOB: isCurrentUserRow || !showLimitedData,
  });

  const fileUploads = partner.fileUploads;

  const official = partner;

  const handleInitiateAccess = async (values) => {
    try {
      await dispatch(
        addAccessRequest({
          data: {
            permissions: values.dashboardPermissions,
            permissionsAccessType: values.accessType,
            startDate: values.startDate,
            endDate: values.endDate,
            initiatedBy: currentUser._id,
            role: currentUserRow?.role[0] ?? isCurrentUserCorporateRow.role[0],
            individual: currentUserRow?._id,
            corporate: isCurrentUserCorporateRow._id,
            organization: organization._id,
            page: "ORGANIZATION_DASHBOARD",
          },
        })
      );
      toast(
        "success",
        "The request has been successfully submitted to the registrar."
      );
      row?.toggleRowExpanded();
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
    }
  };

  const handleShowAccessRequestTab = () => {
    setTabs((prevTabs) => [
      ...prevTabs,
      {
        id: "requestAccess",
        label: "Request Access",
      },
    ]);
    setSelectedTabIndex(tabs.length);
    dispatch(closeModal(MODAL_NAME));
  };

  return (
    <>
      <RequestConfirmationModal
        modalName={MODAL_NAME}
        handleShowAccessRequestTab={handleShowAccessRequestTab}
      />
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9 pb-7">
          <Tabs
            tabs={tabs}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
            PostPendComponent={
              showLimitedData &&
              showRequestButton &&
              !tabs.find((tab) => tab.id === "requestAccess") ? (
                <AccessRequestButton modalName={MODAL_NAME} />
              ) : null
            }
          />
          {selectedTab.id === "generalInformation" && (
            <>
              {!showLimitedData && (
                <>
                  <FormFieldsCard
                    fields={[
                      {
                        key: "Role",
                        name: "role",
                        value: row.original.role?.join(", ") ?? "N/A",
                      },
                    ]}
                    hideResolveAllCheckbox
                  />

                  <FormFieldsCard
                    fields={getBeneficialOwnershipFormFields({
                      beneficialOwnershipForm:
                        row.original.beneficialOwnershipForm,
                      reviewTab: true,
                      renderShortTypeOfControl: true,
                      onlyDates: true,
                      beneficialOwner: row.original,
                      beneficialOwners,
                    })}
                    formName="beneficialOwnershipForm"
                    formHelperKey="beneficialOwnershipForm"
                    hideResolveAllCheckbox
                  />
                </>
              )}
              {!isCurrentUserRow ? (
                <>
                  <FormFieldsCard
                    fields={[
                      ...(CORPORATES.includes(partner.idType)
                        ? namesFormFields
                        : [
                            {
                              key: "Full Name",
                              name: "fullName",
                              value: getFullName(partner.names) || "N/A",
                            },
                          ]),
                      ...identificationFormFields,
                    ]}
                    hideResolveAllCheckbox
                  />
                  {!showLimitedData &&
                    [
                      "Local Company",
                      "Foreign Company",
                      "Other e.g Parastatals",
                    ].includes(row?.original?.idType) && (
                      <FormFieldsCard
                        fields={getFormFields({
                          idType: official.idType,
                          formType: "contactPersonInfo",
                          formValues: official.identificationDetails,
                        })}
                        hideResolveAllCheckbox
                      />
                    )}
                </>
              ) : (
                <>
                  <FormFieldsCard
                    fields={getFormFields({
                      idType: official.idType,
                      formType: "names",
                      formValues: official.names,
                    })}
                    hideResolveAllCheckbox
                  />
                  <FormFieldsCard
                    fields={getFormFields({
                      idType: official.idType,
                      formType: "identificationDetails",
                      formValues: official.identificationDetails,
                    })}
                    hideResolveAllCheckbox
                  />
                  {[
                    "Local Company",
                    "Foreign Company",
                    "Other e.g Parastatals",
                  ].includes(row?.original?.idType) && (
                    <FormFieldsCard
                      fields={getFormFields({
                        idType: official.idType,
                        formType: "contactPersonInfo",
                        formValues: official.identificationDetails,
                      })}
                      hideResolveAllCheckbox
                    />
                  )}
                </>
              )}
            </>
          )}
          {selectedTab.id === "residentialDetails" && (
            <>
              {row?.original?.idType === "Kenyan Citizen" &&
                !showLimitedData && (
                  <FormFieldsCard
                    fields={[
                      {
                        key: "Non-Resident",
                        name: "isNonResident",
                        value: official.isNonResident ?? "N/A",
                      },
                    ]}
                    hideResolveAllCheckbox
                  />
                )}
              <FormFieldsCard
                fields={getTransformedFormFields({
                  idType: partner.idType,
                  formType: "postalAddress",
                  formValues: partner.postalAddress,
                })}
                hideResolveAllCheckbox
              />
              {!showLimitedData && (
                <FormFieldsCard
                  fields={getFormFields({
                    idType: official.idType,
                    formType: "electronicAddress",
                    formValues: official?.electronicAddress,
                  })}
                  hideResolveAllCheckbox
                />
              )}
              {!showLimitedData && (
                <FormFieldsCard
                  fields={getFormFields({
                    idType:
                      official.isNonResident && official.isNonResident === "Yes"
                        ? "Foreigner"
                        : official.idType,
                    formType: "residentialAddressDetails",
                    formValues: official.residentialAddressDetails,
                  })}
                  hideResolveAllCheckbox
                />
              )}
            </>
          )}
          {selectedTab.id === "uploadDocuments" && (
            <>
              <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                <h3 className="mb-2 text-lg">
                  {_isEmpty(fileUploads)
                    ? "No documents found"
                    : "Uploaded documents"}
                </h3>
                <Formik
                  enableReinitialize
                  initialValues={{ fileUploads }}
                  onSubmit={() => null}
                >
                  {() => {
                    return (
                      <div className="pl-4">
                        <Field
                          name="fileUploads"
                          component={FileUploadForm}
                          documentData={getDocumentData(row.original.idType)}
                          onlyPreview
                          showDocumentLabel
                          showResolveAllFieldsCheckbox={false}
                        />
                      </div>
                    );
                  }}
                </Formik>
              </div>
            </>
          )}
          {selectedTab.id === "requestAccess" && (
            <div className="mt-4">
              <AssignPermissionsSelection
                initialValues={{
                  accessType: "indefinite",
                  dashboardPermissions: [],
                }}
                handleSubmit={handleInitiateAccess}
                submitBtnLabel={"Request Access"}
                loadingText={"Requesting..."}
              />
            </div>
          )}
        </td>
      </tr>
    </>
  );
}

export default PartnerSubRow;
