/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _merge from "lodash/merge";

import SectionDescription from "components/lib/Shared/SectionDescription";
import { updateUserState } from "state/slices/user";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import residentialDetailsValidation from "./validations/residentialDetailsValidation";
import Button from "components/lib/Shared/Button";
import ElectronicAddress from "components/PLCTABS/CompanyOfficialsTab/AddressParticularsComponents/ElectronicAddress";

const ResidentialDetails = ({ handleBackBtn, handleNextBtn }) => {
  const [validationSchema, setValidationSchema] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const { transformedComments } = useSelector(
    (state) => state.commentsSlice.profileComments
  );
  const { idType, electronicAddress } = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();

  const handleSubmit = (formData) => {
    dispatch(updateUserState(formData));
    handleNextBtn();
  };

  useEffect(() => {
    const { schemaInitialValues, schema } = residentialDetailsValidation({
      idType,
    });
    setValidationSchema(schema);
    setInitialValues(_merge(schemaInitialValues, { electronicAddress }));
  }, [idType]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        comments: transformedComments,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, errors, touched }) => {
        console.log({ values, errors });
        useEffect(() => {
          setValidationSchema(
            residentialDetailsValidation({
              idType,
            }).schema
          );
        }, [idType]);

        return (
          <Form>
            <SectionDescription
              id="Step: 3 of 5"
              title="Residential Details"
              description="Please provide the correct details. The information provided will be used to process your application."
            />
            <Field
              name="electronicAddress"
              component={ElectronicAddress}
              disableEmailAddress
            />
            <div className="flex items-center justify-between mt-6">
              <Button
                variant="backBtn"
                onClick={handleBackBtn}
                preIcon={ChevronLeftIcon}
              >
                Back to previous step
              </Button>
              <Button type="submit" postIcon={ChevronRightIcon}>
                Save and proceed
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ResidentialDetails;
