import React from "react";
import SpinnerIcon from "./Icons/SpinnerIcon";

function Overlay({ showSpinner = true }) {
  return (
    <div className="absolute inset-0 bg-gray-50 opacity-50 z-10 cursor-not-allowed">
      <div className="flex justify-center mx-auto mt-20 text-white">
        {showSpinner && <SpinnerIcon className="text-gray-800" />}
      </div>
    </div>
  );
}

export default Overlay;
