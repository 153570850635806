const TableHeader = () => {
  return (
    <thead className="text-xs">
      <tr>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          DATE OF APPLICATION
        </th>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 text-left"
        >
          NAME
        </th>
        <th
          rowspan="3"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100 text-left"
        >
          ADDRESS
        </th>
        <th
          rowspan="3"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          DESCRIPTION
        </th>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          NO. OF SHARES APPLIED FOR
        </th>
        <th rowspan="4" className="font-semibold text-gray-900 bg-gray-100">
          AMOUNT OF DEPOSIT (KES)
        </th>
        <th colspan="2" className="font-semibold text-gray-900 bg-gray-100">
          DISTINCTIVE NUMBERS OF SHARE ALLOTED. (SEE COMPANIES ACT, 2015 SECT,
          325)
        </th>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          TOTAL AMOUNT IN RESPECT OF SHARES ALLOTED (KES)
        </th>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          FURTHER AMOUNT PAYABLE (KES)
        </th>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          AMOUNT RETURNABLE (KES)
        </th>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          DATE OF ALLOTMENT
        </th>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          NO. OF ALLOTMENT LETTER
        </th>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          FOLIO IN REGISTER OF MEMBERS
        </th>
        <th
          rowspan="2"
          scope="col"
          className="font-semibold text-gray-900 bg-gray-100"
        >
          SHARE CERT. NO.
        </th>
      </tr>
      <tr>
        <th scope="col" className="font-semibold text-gray-900 bg-gray-100">
          FROM
        </th>
        <th scope="col" className="font-semibold text-gray-900 bg-gray-100">
          TO
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
