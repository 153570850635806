function DashOutline() {
  return (
    <svg
      viewBox="0 0 300 100"
      preserveAspectRatio="none"
      className="absolute top-0 left-0 w-full h-full"
    >
      <path
        d="M0,0 300,0 300,100 0,100z"
        className="stroke-2 stroke-sflPrimary fill-transparent [stroke-dasharray:8]"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
}

export default DashOutline;
