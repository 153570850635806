import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import ReactTable from "components/lib/Global/ReactTable";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import PartnersSubRowAsync from "./PartnersSubRowAsync";
import { getFullName } from "utils";
import {
  deleteAccessRequest,
  sendAccessRequestInvite,
} from "state/slices/accessRequest";
import { useToast } from "hooks/useToast";
import {
  deleteOrganizationPartner,
  updateOrganizationPartner,
} from "state/slices/tables/partnersSlice";
import Checkbox from "components/lib/Shared/Checkbox";
import { Constants } from "config/constants";
import SearchInput from "components/lib/Shared/SearchInput";

const OwnersTable = (props) => {
  const {
    partners,
    assigningRegistrar,
    registrarCandidates = [],
    handleEdit,
    handleDelete,
  } = props;

  const registrarCandidatesIds = registrarCandidates.map(
    (registrarCandidate) => registrarCandidate._id
  );
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();
  const { toast } = useToast(5000);
  const [selectedRegistrar, setSelectedRegistrar] = useState(null);
  const currentUser = useSelector((state) => state.userSlice);
  const { organization } = useSelector((state) => state.organizationsSlice);
  const accessRequests = useSelector(
    (state) => state.accessRequestsSlice.items
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const handleReview = async (row) => {
    row?.getToggleRowExpandedProps()?.onClick();
  };

  const handleRecallAccess = async (row) => {
    const accessRequest = accessRequests.find(
      (request) =>
        (request.initiatedToEmail &&
          request.initiatedToEmail === row.initiatedToEmail) ||
        (request?.initiatedTo?.electronicAddress?.emailAddress &&
          request?.initiatedTo?.electronicAddress?.emailAddress ===
            row.initiatedToEmail)
    );
    if (window.confirm("are you sure you want to recall this request?")) {
      if (row?.type === "invited" && row?.inviteStatus === "Awaiting Data") {
        await dispatch(
          deleteAccessRequest({ accessRequestsId: accessRequest?._id })
        );
      }
      dispatch(
        deleteOrganizationPartner({
          partnerId: row._id,
          idType: row.idType,
          corporateId: organization._id,
        })
      );
    }
  };

  const handleResendInvite = async (row) => {
    try {
      await dispatch(
        sendAccessRequestInvite({
          data: {
            userEmail: row.initiatedToEmail,
          },
        })
      );
      toast("success", "Invite sent successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  const handleToggleRegistrar = async (e) => {
    const idTypeOfSelectedRegistrar = registrarCandidates.find(
      (registrarCandidate) => registrarCandidate._id === e.target.value
    )?.idType;
    if (e.target.checked) {
      setSelectedRegistrar(e.target.value);
      await dispatch(
        updateOrganizationPartner({
          partnerId: e.target.value,
          data: { isRegistrar: true, idType: idTypeOfSelectedRegistrar },
        })
      );
    } else {
      setSelectedRegistrar(null);
      await dispatch(
        updateOrganizationPartner({
          partnerId: e.target.value,
          data: { isRegistrar: false, idType: idTypeOfSelectedRegistrar },
        })
      );
    }
  };

  const columns = [
    assigningRegistrar && {
      Header: "",
      id: "registrarSelection",
      Cell: ({ row }) => {
        if (registrarCandidatesIds.includes(row.original._id)) {
          return (
            <Checkbox
              name={row.original._id}
              checked={selectedRegistrar === row.original._id}
              disabled={
                selectedRegistrar !== row.original._id && selectedRegistrar
              }
              onChange={handleToggleRegistrar}
            />
          );
        }
        return <span> </span>;
      },
    },
    {
      Header: "Role",
      Cell: ({ row }) => row.original?.role?.join(", ") ?? "",
    },
    {
      Header: "Full Name",
      className: "p-4 text-ellipsis overflow-hidden max-w-[150px]",
      Cell: ({ row }) => <span>{getFullName(row.original.names)}</span>,
    },
    { Header: "ID Type", accessor: "idType" },
    {
      Header: "Status",
      Cell: ({ row: { original } }) => {
        if (original.type && original?.type === "invited")
          return original?.inviteStatus;
        return "";
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const { original } = row;
        const isOwnerRow = [
          original?.electronicAddress?.emailAddress,
          original?.identificationDetails?.emailAddress,
        ].includes(currentUser.electronicAddress.emailAddress);

        const isApplicant = currentUser?._id === organization?.createdBy?._id;

        const disableEditBtn =
          (original?.type === "invited" ||
            cookieRole === Constants.COOKIE_ROLES.OFFICIAL) &&
          !isOwnerRow;

        return (
          <div className="flex items-center space-x-2">
            <TableActionMenu
              row={row}
              showRecallBtn={
                original?.type &&
                original?.type === "invited" &&
                original?.inviteStatus === "Awaiting Data" &&
                !isOwnerRow &&
                original.createdBy === currentUser._id
              }
              showResendInviteBtn={
                original?.type &&
                original?.type === "invited" &&
                original?.inviteStatus === "Awaiting Data" &&
                !isOwnerRow &&
                original.createdBy === currentUser._id
              }
              showReviewBtn={
                !isOwnerRow &&
                original?.type === "invited" &&
                original.createdBy === currentUser._id
              }
              showViewBtn={cookieRole !== Constants.COOKIE_ROLES.OFFICIAL}
              handleView={(row) => handleEdit({ ...row, isViewOnly: true })}
              handleRecall={handleRecallAccess}
              handleEdit={(row) =>
                handleEdit({ ...row, isViewOnly: disableEditBtn })
              }
              disableEditBtn={disableEditBtn}
              disableDeleteBtn={
                (original?.type === "invited" ||
                  cookieRole === Constants.COOKIE_ROLES.OFFICIAL) &&
                !isOwnerRow &&
                !isApplicant
              }
              handleDelete={handleDelete}
              handleReview={handleReview}
              handleResendInvite={handleResendInvite}
            />
          </div>
        );
      },
    },
  ].filter(Boolean);

  // Create a function that will render our row sub components
  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <PartnersSubRowAsync
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          currentTab="ownership"
        />
      );
    },
    [partners]
  );

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full bg-white shadow">
        <div className="w-full">
          <ReactTable
            columns={columns}
            data={partners}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            totalRows={partners.length}
            setCurrentPage={setCurrentPage}
            paginate
            headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-slate-200"
            headingStyle="p-2 text-left"
            dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            dataRowCellStyle="whitespace-normal"
            renderRowSubComponent={renderRowSubComponent}
          />
        </div>
      </div>
    </>
  );
};

export default OwnersTable;
