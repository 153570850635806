import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import Organizations from "components/SystemAdminDashboard/Organizations";
import { classNames } from "utils";
import { Constants } from "config/constants";

const navigation = [
  { name: "Organizations", id: "organizations" },
  { name: "Analytics" },
  { name: "Billing" },
  { name: "Access Control" },
];

export default function OrganizationsPage() {
  const history = useHistory();
  const [currentSection, setCurrentSection] = useState("organizations");
  const currentUser = useSelector((state) => state.userSlice);

  if (currentUser?._id && currentUser?.role !== Constants.ROLES.SYSTEM_ADMIN)
    history.push(`/404`);

  return (
    <DashboardWrapper showSystemAdminBar>
      <div>
        <div className="flex items-center justify-between">
          <div className="flex-1 my-10 border-b border-slate-200">
            <h1 className="my-4 text-2xl font-bold leading-7 sm:text-3xl">
              Settings
            </h1>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="px-2 py-6 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="flex flex-col space-y-1">
              {navigation.map((item, index) => (
                <button
                  key={`${item.name}_${index}`}
                  className={classNames(
                    item.id === currentSection
                      ? "bg-gray-50  hover:text-indigo-700 hover:bg-white"
                      : "rounded-md px-3 py-2 flex items-center text-gray-400 text-base font-medium",
                    "flex items-center px-3 py-2 text-base font-medium hover:text-gray-900 hover:bg-gray-50"
                  )}
                  onClick={() => setCurrentSection(item.id)}
                >
                  <span className="truncate">{item.name}</span>
                </button>
              ))}
            </nav>
          </aside>
          <div className="px-2 space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            {currentSection === "organizations" && <Organizations />}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
