import { useState, useEffect, useRef } from "react";

export default function useCounter(seconds = 5) {
    const [counter, setCounter] = useState(seconds);
    const timer = useRef(null)

    useEffect(async () => {
        if (counter > 0) {
            // useRef value stored in .current property
            timer.current = setTimeout(() => setCounter(counter - 1), 1000)
        }

        // clear on component unmount
        return () => {
            clearInterval(timer.current);
        };
    }, [counter, timer]);

    return [counter, timer?.current]
}
