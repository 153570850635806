import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Hero from "components/lib/Global/Hero";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import BusinessDetails from "components/CorporateDashboard/BusinessDetails";
import MinuteBook from "components/CorporateDashboard/MinuteBook";
import TransactionsList from "components/CorporateDashboard/TransactionsList";
import CompaniesList from "components/CorporateDashboard/CompaniesList";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { getCorporate, reset } from "state/slices/organizations";
import RoleSwitch from "components/lib/Shared/RoleSwitch";
import DashboardAccessRequests from "components/CorporateDashboard/DashboardAccessRequests";
import { getAccessRequests } from "state/slices/accessRequest";
import {
  resetActiveDashboardSlice,
  updateActiveDashboardState,
} from "state/slices/activeDashboard";
import { Constants } from "config/constants";
import { useSocket } from "context/SocketContext";
import { classNames } from "utils";
import { setCookie } from "utils/cookie";
import TransferYourBusiness from "../components/TransferYourBusiness";

const auditTabs = [
  { id: "accountSettings", name: "Account Settings" },
  { id: "notifications", name: "Notifications", disabled: true },
  { id: "transactions", name: "Transactions" },
  { id: "auditTrail", name: "Audit Trail", disabled: true },
];

const CorporateDashboard = () => {
  const { corporateId } = useParams();
  const socket = useSocket();
  const history = useHistory();
  const { organization, getOrganization, dashboardRole, breadCrumbs } =
    useSelector((state) => state.organizationsSlice);

  const generalTabs = useMemo(
    () => [
      { id: "businessDetails", name: "Business Details" },
      {
        id: "minuteBook",
        name: "Minute Book",
        disabled: dashboardRole === "VOR",
      },
      { id: "registers", name: "Registers", disabled: true },
    ],
    [dashboardRole]
  );

  const managementTabs = useMemo(
    () => [
      { id: "compliance", name: "Compliance - Coming soon", disabled: true },
      {
        id: "companies",
        name: "Companies",
        disabled: dashboardRole !== "REGISTRAR",
      },
      {
        id: "manageYourCompany",
        name: "Manage your company - Coming soon",
        disabled: true,
      },
      { id: "contracts", name: "Contracts - Coming soon", disabled: true },
    ],
    [dashboardRole]
  );

  const [selectedTab, setSelectedTab] = useState(generalTabs[0]);
  const currentUser = useSelector((state) => state.userSlice);

  useEffect(() => {
    let getCorporatePromise = {};
    if (corporateId && currentUser?._id) {
      getCorporatePromise = dispatch(
        getCorporate({ corporateId, isDashboard: true })
      );
      dispatch(getAccessRequests({ query: { createdBy: currentUser?._id } }));
    }
    return () => {
      if (typeof getCorporatePromise?.abort === "function") {
        getCorporatePromise.abort();
      }
      dispatch(reset());
    };
  }, [corporateId, currentUser?._id]);

  useEffect(() => {
    socket.emit("join-corporate-dashboard", corporateId);
    socket.on("refresh-primarySource-corporate", (corporateId) => {
      console.log("refresh-primarySource-corporate", corporateId);
      dispatch(getCorporate({ corporateId, isDashboard: true }));
    });
    return () => {
      socket.emit("leave-corporate-dashboard", corporateId);
    };
  }, [corporateId]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (organization?.status === "Active") {
      dispatch(
        updateActiveDashboardState({
          dashboardUrl: `${organization._id}/dashboard`,
        })
      );
    }
  }, [organization]);

  const handleTabClick = (item) => {
    if (item?.disabled) return null;
    if (item?.href) return history.push(item.href);
    else setSelectedTab(item);
  };

  let pages =
    breadCrumbs.length > 0
      ? [
          { name: "My Companies", href: "/companies", current: false },
          ...breadCrumbs,
        ]
      : [
          { name: "My Companies", href: "/companies", current: false },
          organization?.names?.companyName
            ? {
                name: organization?.names?.companyName,
                href: "#",
                current: true,
              }
            : null,
        ];

  const handleMakeApplication = () => {
    setSelectedTab({
      id: "transferYourBusiness",
      name: "Transfer Your Business",
    });
  };

  const goToBusinessDetails = () =>
    setSelectedTab({ id: "businessDetails", name: "Business Details" });

  const handleExitDashboard = () => {
    setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
    dispatch(resetActiveDashboardSlice());
    history.push("/companies");
  };

  return (
    <DashboardWrapper
      pages={pages}
      showBreadCrumbs
      exitDashboardText="Exit to personal Dashboard"
      handleExitDashboard={handleExitDashboard}
    >
      {currentUser && currentUser?.isOnboarded && (
        <div className="grid gap-10 py-10 mx-auto">
          <div className="flex justify-between">
            <Hero
              heading={
                organization?.names?.companyName ??
                selectedTab?.label ??
                selectedTab?.name
              }
            />
            {organization?._id && organization.status === "Active" && (
              <RoleSwitch
                organizationName={organization?.names?.companyName}
                breadCrumbs={pages}
                dashboardUrl={history.location.pathname}
              />
            )}
          </div>
          <div className="grid grid-cols-4 border-t border-gray">
            <div className="col-span-4 pr-8 md:col-span-1 pt-14">
              <h4 className="mb-8 text-sflPrimary text-xl font-medium">
                Corporate Dashboard
              </h4>
              <div>
                <h4 className="mb-4 text-sflPrimary border-b font-medium">
                  General
                </h4>
                <ul>
                  {generalTabs.map((item) => (
                    <li
                      key={item.name}
                      className={classNames(
                        item.id === selectedTab.id
                          ? "text-primary"
                          : "text-tertiary",
                        `mb-4 font-medium ${
                          item.disabled
                            ? "cursor-not-allowed text-gray-300"
                            : "hover:text-sflPrimary cursor-pointer"
                        }`
                      )}
                      onClick={() => handleTabClick(item)}
                    >
                      <span className="truncate">{item.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="mb-4 text-sflPrimary border-b font-medium">
                  Management
                </h4>
                <ul>
                  {managementTabs.map((item) => (
                    <li
                      key={item.name}
                      className={classNames(
                        item.id === selectedTab.id
                          ? "text-primary"
                          : "text-tertiary",
                        `mb-4 font-medium ${
                          item.disabled
                            ? "cursor-not-allowed text-gray-300"
                            : "hover:text-sflPrimary cursor-pointer"
                        }`
                      )}
                      onClick={() => handleTabClick(item)}
                    >
                      <span className="truncate">{item.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="mb-4 text-sflPrimary border-b font-medium">
                  Audit
                </h4>
                <ul>
                  {auditTabs.map((item) => (
                    <li
                      key={item.name}
                      className={classNames(
                        item.id === selectedTab.id
                          ? "text-primary"
                          : "text-tertiary",
                        `mb-4 font-medium ${
                          item.disabled
                            ? "cursor-not-allowed text-gray-300"
                            : "hover:text-sflPrimary cursor-pointer"
                        }`
                      )}
                      onClick={() => handleTabClick(item)}
                    >
                      <span className="truncate">{item.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-span-4 md:border-l md:col-span-3 md:pl-11 pt-14 border-gray">
              {getOrganization.status === "loading" || !organization?._id ? (
                <div className="flex justify-center mx-auto text-white">
                  <SpinnerIcon className="text-gray-400" />
                </div>
              ) : (
                <>
                  {selectedTab.id === "businessDetails" && <BusinessDetails />}
                  {selectedTab.id === "minuteBook" && <MinuteBook />}
                  {selectedTab.id === "transactions" && <TransactionsList />}
                  {selectedTab.id === "companies" && (
                    <CompaniesList goToBusinessDetails={goToBusinessDetails} />
                  )}
                  {selectedTab.id === "accountSettings" && (
                    <DashboardAccessRequests />
                  )}
                  {selectedTab.id === "transferYourBusiness" && (
                    <TransferYourBusiness
                    // goToBusinessDetails={goToBusinessDetails}
                    />
                  )}
                </>
              )}
            </div>
            <div className="flex flex-col">
              <button
                className="bg-sflPrimary w-full text-white font-bold py-2 px-4 rounded mb-4 cursor-not-allowed text-gray-300/95"
                onClick={handleMakeApplication}
                disabled
              >
                Make Application
              </button>
            </div>
          </div>
        </div>
      )}
    </DashboardWrapper>
  );
};

export default CorporateDashboard;
