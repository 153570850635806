/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from "react";
import { useFormikContext, Field } from "formik";
import { subYears, format, addMonths } from "date-fns";

import DatePickerField from "components/lib/Shared/DatePickerField";
import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "../../../../../PLCTABS/SFLdata/selectOptions";

// Identification Details for Foreign Resident
const IdentificationDetailsFormFoRe = ({ field, idType }) => {
  const minDateOfExpiry = addMonths(new Date(), 1);
  const maxDateOfBirth = subYears(new Date(), 18);
  const { setFieldValue } = useFormikContext();
  const [startDate, setStartDate] = useState("");
  const [foreignCertificateDateOfExpiry, setForeignCertificateDateOfExpiry] =
    useState("");

  const handleSetValue = (e) => {
    const value =
      e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: value,
    });
  };

  const countriesOptions = useMemo(() => {
    return options.countries.map((country) => {
      if (
        ["Foreign Resident", "Foreigner", "Foreign Company"].includes(idType) &&
        country.value === "KE"
      ) {
        return { ...country, disabled: true };
      }
      return country;
    });
  }, [idType, options.countries]);

  const handleSelect = (type) => (option) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
  };

  const handleDateValue = (type) => (date) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: format(date, "yyyy/MM/dd"),
    });
    if (type === "foreignCertificateDateOfExpiry")
      setForeignCertificateDateOfExpiry(date);
    else setStartDate(date);
  };

  const selectedDOB =
    field.value && field.value.dateOfBirth
      ? new Date(field.value.dateOfBirth)
      : startDate;

  const selectedFCDateOfExpiry =
    field.value && field.value.foreignCertificateDateOfExpiry
      ? new Date(field.value.foreignCertificateDateOfExpiry)
      : foreignCertificateDateOfExpiry;

  return (
    <>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <DatePickerField
            label="Date of Birth"
            onChange={handleDateValue("dateOfBirth")}
            selected={selectedDOB}
            maxDate={maxDateOfBirth}
            name="dateOfBirth"
            placeholderText="Select date of birth"
            errorPath="identificationDetails.dateOfBirth"
            disabled={true}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Foreign Certificate ID"
            name="foreignCertificateID"
            placeholder="Enter foreign certificate ID"
            value={field.value?.foreignCertificateID ?? ""}
            errorPath="identificationDetails.foreignCertificateID"
            disabled={true}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <DatePickerField
            showRequiredAsterisk
            label="Foreign Certificate Date Of Expiry"
            onChange={handleDateValue("foreignCertificateDateOfExpiry")}
            selected={selectedFCDateOfExpiry}
            minDate={minDateOfExpiry}
            name="foreignCertificateDateOfExpiry"
            placeholderText="Select date of expiry"
            errorPath="identificationDetails.foreignCertificateDateOfExpiry"
            disabled={true}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="KRA PIN"
            name="KRAPin"
            placeholder="Enter KRA PIN"
            value={field.value?.KRAPin ?? ""}
            errorPath="identificationDetails.KRAPin"
            onBlur={field.onBlur}
            onChange={handleSetValue}
            disabled={true}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Occupation"
            name="occupation"
            placeholder="Enter occupation"
            value={field.value?.occupation ?? ""}
            errorPath="identificationDetails.occupation"
            onBlur={field.onBlur}
            onChange={handleSetValue}
            disabled={false}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Field
            {...field}
            label="Nationality"
            value={field?.value?.nationality ?? ""}
            name="nationality"
            component={ReactSelectWithFormik}
            options={countriesOptions}
            errorPath="identificationDetails.nationality"
            placeholder="Enter nationality"
            onBlur={field.onBlur}
            onChange={handleSelect("nationality")}
            isSearchable
            isDisabled={true}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Field
            {...field}
            label="Gender"
            value={field?.value?.gender ?? ""}
            name="gender"
            component={ReactSelectWithFormik}
            options={options.gender}
            errorPath="identificationDetails.gender"
            placeholder="Select gender"
            onBlur={field.onBlur}
            onChange={handleSelect("gender")}
            isDisabled={false}
          />
        </div>
      </div>
    </>
  );
};

export default IdentificationDetailsFormFoRe;
