/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from "react";
import { subYears, format } from "date-fns";
import { useFormikContext, Field } from "formik";
import { useSelector } from "react-redux";

import DatePickerField from "components/lib/Shared/DatePickerField";
import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "../../SFLdata/addressOptions";

// Identification Details for Foreign Resident
const IdentificationDetailsCompanySecretaryFoRe = ({
  field,
  form,
  disabled,
  disableVerificationField,
  idType,
}) => {
  const maxDateOfBirth = subYears(new Date(), 18);

  const [startDate, setStartDate] = useState("");

  const { setFieldValue } = useFormikContext();

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (option) => {
    setFieldValue(field.name, {
      ...field.value,
      nationality: option.value,
    });
  };

  const { values } = form;
  const currentUser = useSelector((state) => state.userSlice);
  const isApplicant = values?.createdBy === currentUser._id;
  const areCSFieldsOptional =
    (values?._id ? isApplicant : true) &&
    (values?.type === "invited" || values?.hasAccess);

  const countriesOptions = useMemo(() => {
    return options.countries.map((country) => {
      if (
        ["Foreign Resident", "Foreigner", "Foreign Company"].includes(idType) &&
        country.value === "KE"
      ) {
        return { ...country, disabled: true };
      }
      return country;
    });
  }, [idType, options.countries]);

  const handleDateValue = (date) => {
    setFieldValue(field.name, {
      ...field.value,
      dateOfBirth: format(date, "yyyy/MM/dd"),
    });
    return setStartDate(date);
  };

  const selectedDOB =
    field.value && field.value.dateOfBirth
      ? new Date(field.value.dateOfBirth)
      : startDate;

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-2">
        <DatePickerField
          label="Date of Birth"
          onChange={handleDateValue}
          selected={selectedDOB}
          maxDate={maxDateOfBirth}
          form={form}
          name="dateOfBirth"
          placeholderText="Select date of birth"
          errorPath="identificationDetails.dateOfBirth"
          disabled={disabled}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Foreign Certificate ID"
          name="foreignCertificateID"
          placeholder="Enter foreign certificate ID"
          form={form}
          value={field.value?.foreignCertificateID ?? ""}
          errorPath="identificationDetails.foreignCertificateID"
          onBlur={field.onBlur}
          onChange={handleSetValue}
          disabled={disabled || disableVerificationField}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="KRA Pin Number"
          name="KRAPin"
          placeholder="Enter KRA PIN"
          form={form}
          value={field.value?.KRAPin ?? ""}
          errorPath="identificationDetails.KRAPin"
          disabled={disabled}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Occupation"
          name="occupation"
          placeholder="Enter occupation"
          form={form}
          value={field.value?.occupation ?? ""}
          errorPath="identificationDetails.occupation"
          disabled={disabled}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Company Secretary Number"
          name="secretaryNumber"
          placeholder="Enter secretary number"
          form={form}
          showRequiredAsterisk
          value={field.value?.secretaryNumber ?? ""}
          areCSFieldsOptional={areCSFieldsOptional}
          errorPath="identificationDetails.secretaryNumber"
          disabled={disabled}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Company Secretary Practicing Number"
          name="practicingNumber"
          placeholder="Enter practicing number"
          form={form}
          showRequiredAsterisk
          value={field.value?.practicingNumber ?? ""}
          areCSFieldsOptional={areCSFieldsOptional}
          errorPath="identificationDetails.practicingNumber"
          disabled={disabled}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Field
          {...field}
          label="Nationality"
          value={field?.value?.nationality ?? ""}
          name="nationality"
          component={ReactSelectWithFormik}
          options={countriesOptions}
          errorPath="identificationDetails.nationality"
          placeholder="Select nationality"
          isDisabled={disabled}
          onBlur={field.onBlur}
          onChange={handleSelect}
        />
      </div>
    </div>
  );
};

export default IdentificationDetailsCompanySecretaryFoRe;
