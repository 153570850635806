/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { Field, useFormikContext } from "formik";

import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import countyOptions from "components/PLCTABS/SFLdata/countyOptions";

const CountyDistrictLocalityForm = (props) => {
  const { setFieldValue } = useFormikContext();
  const {
    field,
    form,
    location,
    transformedComments = {},
    resolvedFields = [],
    useSimpleFormationsOffice = false,
    disabled,
  } = props;
  const { county, district, locality } = location;

  const [selectedCounty, setSelectedCounty] = useState(county);
  const [selectedDistrict, setSelectedDistrict] = useState(district);
  const [selectedLocality, setSelectedLocality] = useState(locality);

  const [dropdownData, setDropdownData] = useState({
    county: countyOptions.residence.county,
    district: [],
    locality: [],
  });

  useEffect(() => {
    setDropdownData({
      ...dropdownData,
      district: countyOptions.residence.district.filter((item) => {
        return item.county === county;
      }),
      locality: countyOptions.residence.locality.filter((item) => {
        return item.district === district;
      }),
    });
    setSelectedCounty(county);
    setSelectedDistrict(district);
    setSelectedLocality(locality);
  }, [location]);

  const handleSelect = (type) => (option) => {
    if (type === "county") {
      setDropdownData({
        ...dropdownData,
        district: countyOptions.residence.district.filter((item) => {
          return item.county === option.value;
        }),
      });
      setSelectedCounty(option);
      setSelectedDistrict({ value: "", label: "" });
      setSelectedLocality({ value: "", label: "" });
    }
    if (type === "district") {
      setDropdownData({
        ...dropdownData,
        locality: countyOptions.residence.locality.filter((item) => {
          return item.district === option.value;
        }),
      });
      setSelectedDistrict(option);
      setSelectedLocality({ value: "", label: "" });
    } else if (type === "locality") {
      setSelectedLocality(option);
    }

    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
  };

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-2">
        <Field
          {...field}
          label="County"
          value={selectedCounty}
          name="county"
          form={form}
          component={ReactSelectWithFormik}
          options={countyOptions.residence.county}
          errorPath="location.county"
          placeholder="Select county"
          onBlur={field.onBlur}
          comments={
            transformedComments?.["addressParticulars"]?.["location.county"] ??
            []
          }
          onChange={handleSelect("county")}
          isDisabled={
            resolvedFields.includes("location.county") ||
            useSimpleFormationsOffice ||
            disabled
          }
          isSearchable
        />
      </div>

      <div className="col-span-6 sm:col-span-2">
        <Field
          {...field}
          label="District"
          value={selectedDistrict}
          name="district"
          form={form}
          component={ReactSelectWithFormik}
          options={dropdownData.district}
          errorPath="location.district"
          placeholder="Select district"
          onBlur={field.onBlur}
          comments={
            transformedComments?.["addressParticulars"]?.[
              "location.district"
            ] ?? []
          }
          onChange={handleSelect("district")}
          isDisabled={
            resolvedFields.includes("location.district") ||
            useSimpleFormationsOffice ||
            disabled
          }
          isSearchable
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Field
          {...field}
          label="Locality"
          value={selectedLocality}
          name="locality"
          form={form}
          component={ReactSelectWithFormik}
          options={dropdownData.locality}
          errorPath="location.locality"
          placeholder="Select locality"
          onBlur={field.onBlur}
          comments={
            transformedComments?.["addressParticulars"]?.[
              "location.locality"
            ] ?? []
          }
          onChange={handleSelect("locality")}
          isDisabled={
            resolvedFields.includes("location.locality") ||
            useSimpleFormationsOffice ||
            disabled
          }
          isSearchable
        />
      </div>
    </div>
  );
};

export default CountyDistrictLocalityForm;
