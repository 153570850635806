import _get from "lodash/get";
import { format } from "date-fns";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import {
  CORPORATES,
  INDIVIDUALS,
} from "components/PLCTABS/SFLdata/config/constants";
import { getFullName, getTransformedFormFields } from "utils";
import { Constants } from "config/constants";

function GeneralInformation({
  official,
  isCompanyDashboard,
  isCurrentUserRow,
  viewRestrictedData,
  hideResolveAllCheckbox,
}) {
  const namesFormFields = [];

  if (INDIVIDUALS.includes(official.idType)) {
    namesFormFields.push(
      {
        key: "First name",
        name: "firstName",
        value: _get(official.names, "firstName", "N/A"),
      },
      {
        key: "Middle name",
        name: "middleName",
        value: _get(official.names, "middleName", "N/A"),
      },
      {
        key: "Last name",
        name: "lastName",
        value: _get(official.names, "lastName", "N/A"),
      }
    );
  }

  if (CORPORATES.includes(official.idType)) {
    namesFormFields.push(
      {
        key: "Company Name",
        name: "companyName",
        value: _get(official.names, "companyName", "N/A"),
      },
      {
        key: "Company Registration Number",
        name: "companyRegNumber",
        value: _get(official.names, "companyRegNumber", "N/A"),
      },
      {
        key: "Company Registration Date",
        name: "dateOfRegistration",
        value: _get(official.names, "dateOfRegistration", "N/A"),
      }
    );
  }

  if (_get(official.names, "formerName")) {
    namesFormFields.push({
      key: "Former name",
      name: "formerName",
      value: _get(official.names, "formerName"),
    });
  }

  const identificationFormFields = [
    ...getTransformedFormFields({
      idType: official.idType,
      formType: "identificationDetails",
      formValues: official.identificationDetails,
      formatDOB: isCurrentUserRow || viewRestrictedData,
    }),
  ];

  const datesFormFields = [
    {
      key: `Date Of Becoming a ${official.role}`,
      name: `effectiveDateOfBecoming${official.role}`,
      value: official.createdAt
        ? format(new Date(official.createdAt), "yyyy-MM-dd")
        : "N/A",
    },
    {
      key: `Date Of Ceasing to be a ${official.role}`,
      name: `effectiveDateOfBecoming${official.role}`,
      value: "N/A",
    },
  ];

  return (
    <>
      {isCompanyDashboard && !isCurrentUserRow ? (
        <>
          <FormFieldsCard
            fields={[
              {
                key: "Full Name",
                name: "fullName",
                value: getFullName(official.names) || "N/A",
              },
              ...identificationFormFields,
            ]}
            hideResolveAllCheckbox={isCompanyDashboard}
          />
          <FormFieldsCard
            fields={datesFormFields}
            formName="beneficialOwnershipForm"
            formHelperKey="beneficialOwnershipForm"
            hideResolveAllCheckbox={hideResolveAllCheckbox}
          />
        </>
      ) : (
        <>
          <FormFieldsCard
            fields={namesFormFields}
            hideResolveAllCheckbox={hideResolveAllCheckbox}
          />
          <FormFieldsCard
            fields={identificationFormFields}
            hideResolveAllCheckbox={hideResolveAllCheckbox}
          />
          <FormFieldsCard
            fields={datesFormFields}
            formName="beneficialOwnershipForm"
            formHelperKey="beneficialOwnershipForm"
            hideResolveAllCheckbox={hideResolveAllCheckbox}
          />
        </>
      )}
    </>
  );
}

export default GeneralInformation;
