import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import permissionDataService from "services/permission";

export const getPermissions = createAsyncThunk(
  "permissions/getPermissions",
  async ({ company, organization, individual, corporate, status }) => {
    const res = await permissionDataService.getAll(
      company,
      organization,
      individual,
      corporate,
      status
    );
    return res.data;
  }
);

export const createPermission = createAsyncThunk(
  "permissions/createPermission",
  async ({ data }) => {
    const res = await permissionDataService.create(data);
    return res.data;
  }
);

export const editPermission = createAsyncThunk(
  "permissions/update",
  async ({ permissionId, data }) => {
    const res = await permissionDataService.update(permissionId, data);
    return res.data;
  }
);

const initialState = {
  permissions: [],
  permission: null,
  getPermissionsStatus: {
    status: "idle",
    error: false,
  },
};

const permissions = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    updatePermissionState: (state, action) => {
      state.permission = action.payload.permission;
    },
    resetPermissionSlice: (state) => initialState,
  },
  extraReducers: {
    [createPermission.fulfilled]: (state, action) => {
      state.permission = action.payload.permission;
    },
    [editPermission.fulfilled]: (state, action) => {
      state.permission = action.payload.permission;
    },
    [getPermissions.pending]: (state) => {
      state.getPermissionsStatus.status = "loading";
      state.getPermissionsStatus.error = false;
    },
    [getPermissions.fulfilled]: (state, action) => {
      state.getPermissionsStatus.status = "idle";
      state.getPermissionsStatus.error = false;
      state.permissions = action.payload.items;
    },
    [getPermissions.rejected]: (state, action) => {
      state.getPermissionsStatus.status = "failed";
      state.getPermissionsStatus.error = action.payload?.messageText;
    },
  },
});

export const { updatePermissionState, resetPermissionSlice } =
  permissions.actions;

const { reducer } = permissions;

export default reducer;
