import React, { createContext, useEffect, useContext } from "react";
import io from "socket.io-client";

const SocketContext = createContext(null);

export const SocketProvider = ({ url, children }) => {
  const socket = io(url);

  useEffect(() => {
    socket.on("socketId", (socketId) => {
      window.sessionStorage.setItem("socketId", socketId);
      socket["id"] = socketId;
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
