/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _omit from "lodash/omit";

import registeredOfficeDataService from "services/registeredOffice";

const initialState = {
  postalAddress: "",
  postalCode: "",
  serviceAddress: "",
  companyEmailAddress: "",
  mobileNumber: "",
  buildingName: "",
  houseNumber: "",
  estate: "",
  floorNumber: "",
  streetName: "",
  streetNumber: "",
  location: {
    county: "",
    district: "",
    locality: "",
  },
  resolvedFields: [],
};

export const createRegisteredOfficeAsync = createAsyncThunk(
  "companies/createRegisteredOfficeAsync",
  async ({ companyId, data }) => {
    const res = await registeredOfficeDataService.create({
      companyId,
      ..._omit(data, ["_id", "_v", "createdAt", "createdBy", "updatedAt"]),
    });
    return res.data.registeredOffice;
  }
);

const registeredOfficeSlice = createSlice({
  name: "registeredOffice",
  initialState,
  reducers: {
    updateRegisteredOfficeState: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetRegisteredOfficeSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [createRegisteredOfficeAsync.fulfilled]: (state, action) => {
      state.registeredOffice = action.payload;
    },
  },
});

export const { updateRegisteredOfficeState, resetRegisteredOfficeSlice } =
  registeredOfficeSlice.actions;
const { reducer } = registeredOfficeSlice;

export default reducer;
