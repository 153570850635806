import axios from '../axios'

const create = data => {
  return axios.post('/officials', data)
}

const update = (officialId, data) => {
  return axios.put(`/officials/${officialId}`, data)
}

const remove = (officialId, idType, companyId) => {
  return axios.delete(`/officials/${officialId}?idType=${idType}&companyId=${companyId}`)
}

const officialDataService = {
  create,
  update,
  remove
}

export default officialDataService
