import React from "react";
import { Field, useFormikContext } from "formik";

import Label from "components/lib/Shared/Label";
import PostalAddressFormForeign from "../CompanyOfficialsTab/PostalComponents/PostalAddressFormForeign";
import ResidentialAddressDetailsFormFoMi from "../CompanyOfficialsTab/AddressParticularsComponents/ResidentialAddressComponents/ResidentialAddressDetailsFormFoMi";
import ResidentialPhysicalAddressFoCo from "../CompanyOfficialsTab/AddressParticularsComponents/ResidentialAddressComponents/ResidentialPhysicalAddressFoCo";
import ResidentialPhysicalAddressLoCo from "../CompanyOfficialsTab/AddressParticularsComponents/ResidentialAddressComponents/ResidentialPhysicalAddressLoCo";
import PostalAddressFormLocal from "../CompanyOfficialsTab/PostalComponents/PostalAddressFormLocal";
import PostalCodeForm from "../CompanyOfficialsTab/PostalComponents/PostalCodeForm";
import ResidentialAddressDetailsFormKeCiFoRe from "../CompanyOfficialsTab/AddressParticularsComponents/ResidentialAddressComponents/ResidentialAddressDetailsFormKeCiFoRe";
import CommentsList from "components/lib/Global/CommentsList";

const BeneficialOwnersAddressParticulars = ({
  idType,
  isNonResident,
  resolvedFields = [],
  disableForm,
}) => {
  const { values } = useFormikContext();
  let comments = values?.comments?.isNonResident?.isNonResident ?? [];

  return (
    <>
      {idType === "Kenyan Citizen" && (
        <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
          <div className="px-4 py-6 sm:p-6 space-y-2">
            <span>Is this Official Non-Resident?</span>
            <div className="flex space-x-10">
              <div className="flex items-center">
                <Field
                  type="radio"
                  name="isNonResident"
                  id="yesResident"
                  value="Yes"
                  disabled={
                    resolvedFields.includes("isNonResident") || disableForm
                  }
                  className="disabled:opacity-40 disabled:cursor-not-allowed"
                />
                <Label htmlFor="yesResident" className="pl-4">
                  YES
                </Label>
              </div>
              <div className="flex items-center">
                <Field
                  type="radio"
                  name="isNonResident"
                  id="notResident"
                  value="No"
                  disabled={
                    resolvedFields.includes("isNonResident") || disableForm
                  }
                  className="disabled:opacity-40 disabled:cursor-not-allowed"
                />
                <Label htmlFor="notResident" className="pl-4">
                  NO
                </Label>
              </div>
            </div>
            {comments.length > 0 && (
              <CommentsList comments={comments} color={"text-red-500"} />
            )}
          </div>
        </div>
      )}
      {((idType === "Kenyan Citizen" && isNonResident === "No") ||
        idType === "Foreign Resident" ||
        idType === "Minor") && (
        <>
          <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
            <Field
              name="postalAddress"
              component={PostalAddressFormLocal}
              idType={idType}
              disabled={disableForm}
            />
            <Field
              name="postalAddress"
              component={PostalCodeForm}
              disabled={disableForm}
            />
          </div>
          <Field
            name="residentialAddressDetails"
            component={ResidentialAddressDetailsFormKeCiFoRe}
            colWidth={4}
            disabled={disableForm}
          />
        </>
      )}
      {(idType === "Foreigner" ||
        (idType === "Kenyan Citizen" && isNonResident === "Yes")) && (
        <>
          <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
            <Field
              name="postalAddress"
              component={PostalAddressFormForeign}
              idType={idType}
              disabled={disableForm}
            />
          </div>
          <Field
            name="residentialAddressDetails"
            component={ResidentialAddressDetailsFormFoMi}
            disabled={disableForm}
          />
        </>
      )}
      {(idType === "Local Company" || idType === "Other e.g Parastatals") && (
        <>
          <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
            <Field
              name="postalAddress"
              component={PostalAddressFormLocal}
              disabled={disableForm}
            />
            <Field
              name="postalAddress"
              component={PostalCodeForm}
              disabled={disableForm}
            />
          </div>
          <Field
            name="residentialAddressDetails"
            component={ResidentialPhysicalAddressLoCo}
            disabled={disableForm}
          />
        </>
      )}
      {idType === "Foreign Company" && (
        <>
          <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
            <Field
              name="postalAddress"
              component={PostalAddressFormForeign}
              disabled={disableForm}
            />
          </div>
          <Field
            name="residentialAddressDetails"
            component={ResidentialPhysicalAddressFoCo}
            disabled={disableForm}
          />
        </>
      )}
    </>
  );
};

export default BeneficialOwnersAddressParticulars;
