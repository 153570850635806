import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";

import minuteBookDataService from "services/minuteBook";

const initialState = {
  registersOfDirectorsAndSecretaries: {
    directors: {
      items: [],
      totalCount: 0,
    },
    secretaries: {
      items: [],
      totalCount: 0,
    },
  },
  registersOfDirectorsAndSecretariesResidences: {
    directors: {
      items: [],
      totalCount: 0,
    },
    secretaries: {
      items: [],
      totalCount: 0,
    },
  },
  registerOfApplicationsAndAllotments: {
    items: [],
    totalCount: 0,
    unpaidClassesOfShares: [],
  },
  registerOfMembersAndShareLedger: {
    items: [],
    totalCount: 0,
    classOfSharesHeaders: [],
  },
  registerOfBeneficialOwners: {
    items: [],
    totalCount: 0,
  },
  downloadRegisterOfBeneficialOwners: {
    status: "idle",
    error: false,
  },
  registerOfShareCertificates: {
    items: [],
    totalCount: 0,
    unpaidClassesOfShares: [],
  },
  getRegistersOfDirectorsAndSecretaries: {
    status: "idle",
    error: false,
  },
  downloadRegistersOfDirectorsAndSecretaries: {
    status: "idle",
    error: false,
  },
  getRegistersOfDirectorsAndSecretariesResidences: {
    status: "idle",
    error: false,
  },
  downloadRegistersOfDirectorsAndSecretariesResidences: {
    status: "idle",
    error: false,
  },
  getRegisterOfApplicationsAndAllotments: {
    status: "idle",
    error: false,
  },
  downloadRegisterOfApplicationsAndAllotments: {
    status: "idle",
    error: false,
  },
  getRegisterOfMembersAndShareLedger: {
    status: "idle",
    error: false,
  },
  downloadRegisterOfMembersAndShareLedger: {
    status: "idle",
    error: false,
  },
  getRegisterOfBeneficialOwners: {
    status: "idle",
    error: false,
  },
  getRegisterOfShareCertificates: {
    status: "idle",
    error: false,
  },
  downloadRegisterOfShareCertificates: {
    status: "idle",
    error: false,
  },
  updateAllotments: {
    status: "idle",
    error: false,
  },
};

export const getRegistersOfDirectorsAndSecretariesAsync = createAsyncThunk(
  "minuteBook/getRegistersOfDirectorsAndSecretariesAsync",
  async ({ queryParams }, thunkAPI) => {
    try {
      const res =
        await minuteBookDataService.getRegistersOfDirectorsAndSecretaries(
          queryParams
        );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const downloadRegistersOfDirectorsAndSecretariesAsync = createAsyncThunk(
  "minuteBook/downloadRegistersOfDirectorsAndSecretariesAsync",
  async ({ queryParams }, thunkAPI) => {
    try {
      const response =
        await minuteBookDataService.downloadRegistersOfDirectorsAndSecretaries(
          queryParams
        );
      saveAs(
        response.data,
        `RegistersOfDirectorsAndSecretaries.${queryParams.fileFormat}`
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRegistersOfDirectorsAndSecretariesResidencesAsync =
  createAsyncThunk(
    "minuteBook/getRegistersOfDirectorsAndSecretariesResidencesAsync",
    async ({ queryParams }, thunkAPI) => {
      try {
        const res =
          await minuteBookDataService.getRegistersOfDirectorsAndSecretariesResidences(
            queryParams
          );
        return res.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

export const downloadRegistersOfDirectorsAndSecretariesResidencesAsync =
  createAsyncThunk(
    "minuteBook/downloadRegistersOfDirectorsAndSecretariesResidencesAsync",
    async ({ queryParams }, thunkAPI) => {
      try {
        const response =
          await minuteBookDataService.downloadRegistersOfDirectorsAndSecretariesResidences(
            queryParams
          );
        saveAs(
          response.data,
          `RegistersOfDirectorsAndSecretariesResidences.${queryParams.fileFormat}`
        );
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

export const getRegisterOfApplicationsAndAllotmentsAsync = createAsyncThunk(
  "minuteBook/getRegisterOfApplicationsAndAllotmentsAsync",
  async ({ queryParams }, thunkAPI) => {
    try {
      const res =
        await minuteBookDataService.getRegisterOfApplicationsAndAllotments(
          queryParams
        );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const downloadRegisterOfApplicationsAndAllotmentsAsync =
  createAsyncThunk(
    "minuteBook/downloadRegisterOfApplicationsAndAllotmentsAsync",
    async ({ queryParams }, thunkAPI) => {
      try {
        const response =
          await minuteBookDataService.downloadRegisterOfApplicationsAndAllotments(
            queryParams
          );
        saveAs(
          response.data,
          `RegisterOfApplicationsAndAllotments.${queryParams.fileFormat}`
        );
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

export const getRegisterOfMembersAndShareLedgerAsync = createAsyncThunk(
  "minuteBook/getRegisterOfMembersAndShareLedgerAsync",
  async ({ queryParams }, thunkAPI) => {
    try {
      const res =
        await minuteBookDataService.getRegisterOfMembersAndShareLedger(
          queryParams
        );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const downloadRegisterOfMembersAndShareLedgerAsync = createAsyncThunk(
  "minuteBook/downloadRegisterOfMembersAndShareLedgerAsync",
  async ({ queryParams }, thunkAPI) => {
    try {
      const res =
        await minuteBookDataService.downloadRegisterOfMembersAndShareLedger(
          queryParams
        );
      saveAs(
        res.data,
        `RegisterOfMembersAndShareLedgerData.${queryParams.fileFormat}`
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRegisterOfBeneficialOwnersAsync = createAsyncThunk(
  "minuteBook/getRegisterOfBeneficialOwnersAsync",
  async ({ queryParams }, thunkAPI) => {
    try {
      const res = await minuteBookDataService.getRegisterOfBeneficialOwners(
        queryParams
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const downloadRegisterOfBeneficialOwnersAsync = createAsyncThunk(
  "minuteBook/downloadRegisterOfBeneficialOwnersAsync",
  async ({ queryParams }, thunkAPI) => {
    try {
      const response =
        await minuteBookDataService.downloadRegisterOfBeneficialOwners(
          queryParams
        );
      saveAs(
        response.data,
        `RegistersOfBeneficialOwners.${queryParams.fileFormat}`
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getRegisterOfShareCertificatesAsync = createAsyncThunk(
  "minuteBook/getRegisterOfShareCertificatesAsync",
  async ({ queryParams }, thunkAPI) => {
    try {
      const res = await minuteBookDataService.getRegisterOfShareCertificates(
        queryParams
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAllotmentsAsync = createAsyncThunk(
  "minuteBook/updateAllotmentsAsync",
  async ({ data, queryParams }, thunkAPI) => {
    try {
      const res = await minuteBookDataService.updateAllotments(
        queryParams,
        data
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const downloadRegistersOfShareCertificateAsync = createAsyncThunk(
  "minuteBook/downloadRegistersOfShareCertificateAsync",
  async ({ queryParams }, thunkAPI) => {
    try {
      const response =
        await minuteBookDataService.downloadRegistersOfShareCertificate(
          queryParams
        );
      saveAs(response.data, queryParams.fileName);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const minuteBookSlice = createSlice({
  name: "minuteBook",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [getRegistersOfDirectorsAndSecretariesAsync.pending]: (state) => {
      state.getRegistersOfDirectorsAndSecretaries.status = "loading";
      state.getRegistersOfDirectorsAndSecretaries.error = false;
    },
    [getRegistersOfDirectorsAndSecretariesAsync.fulfilled]: (state, action) => {
      state.registersOfDirectorsAndSecretaries =
        action.payload.registersOfDirectorsAndSecretaries;
      state.getRegistersOfDirectorsAndSecretaries.status = "idle";
      state.getRegistersOfDirectorsAndSecretaries.error = false;
    },
    [getRegistersOfDirectorsAndSecretariesAsync.rejected]: (state, action) => {
      state.getRegistersOfDirectorsAndSecretaries.status = "failed";
      state.getRegistersOfDirectorsAndSecretaries.error =
        action.payload.messageText;
    },

    [downloadRegistersOfDirectorsAndSecretariesAsync.pending]: (state) => {
      state.downloadRegistersOfDirectorsAndSecretaries.status = "loading";
      state.downloadRegistersOfDirectorsAndSecretaries.error = false;
    },
    [downloadRegistersOfDirectorsAndSecretariesAsync.fulfilled]: (
      state,
      action
    ) => {
      state.downloadRegistersOfDirectorsAndSecretaries.status = "idle";
      state.downloadRegistersOfDirectorsAndSecretaries.error = false;
    },
    [downloadRegistersOfDirectorsAndSecretariesAsync.rejected]: (
      state,
      action
    ) => {
      state.downloadRegistersOfDirectorsAndSecretaries.status = "failed";
      state.downloadRegistersOfDirectorsAndSecretaries.error =
        action.payload.messageText;
    },

    [getRegistersOfDirectorsAndSecretariesResidencesAsync.pending]: (state) => {
      state.getRegistersOfDirectorsAndSecretariesResidences.status = "loading";
      state.getRegistersOfDirectorsAndSecretariesResidences.error = false;
    },
    [getRegistersOfDirectorsAndSecretariesResidencesAsync.fulfilled]: (
      state,
      action
    ) => {
      state.registersOfDirectorsAndSecretariesResidences =
        action.payload.registersOfDirectorsAndSecretariesResidences;
      state.getRegistersOfDirectorsAndSecretariesResidences.status = "idle";
      state.getRegistersOfDirectorsAndSecretariesResidences.error = false;
    },
    [getRegistersOfDirectorsAndSecretariesResidencesAsync.rejected]: (
      state,
      action
    ) => {
      state.getRegistersOfDirectorsAndSecretariesResidences.status = "failed";
      state.getRegistersOfDirectorsAndSecretariesResidences.error =
        action.payload.messageText;
    },

    [downloadRegistersOfDirectorsAndSecretariesResidencesAsync.pending]: (
      state
    ) => {
      state.downloadRegistersOfDirectorsAndSecretariesResidences.status =
        "loading";
      state.downloadRegistersOfDirectorsAndSecretariesResidences.error = false;
    },
    [downloadRegistersOfDirectorsAndSecretariesResidencesAsync.fulfilled]: (
      state,
      action
    ) => {
      state.downloadRegistersOfDirectorsAndSecretariesResidences.status =
        "idle";
      state.downloadRegistersOfDirectorsAndSecretariesResidences.error = false;
    },
    [downloadRegistersOfDirectorsAndSecretariesResidencesAsync.rejected]: (
      state,
      action
    ) => {
      state.downloadRegistersOfDirectorsAndSecretariesResidences.status =
        "failed";
      state.downloadRegistersOfDirectorsAndSecretariesResidences.error =
        action.payload.messageText;
    },

    [getRegisterOfApplicationsAndAllotmentsAsync.pending]: (state) => {
      state.getRegisterOfApplicationsAndAllotments.status = "loading";
      state.getRegisterOfApplicationsAndAllotments.error = false;
    },
    [getRegisterOfApplicationsAndAllotmentsAsync.fulfilled]: (
      state,
      action
    ) => {
      state.registerOfApplicationsAndAllotments =
        action.payload.registerOfApplicationsAndAllotments;
      state.getRegisterOfApplicationsAndAllotments.status = "idle";
      state.getRegisterOfApplicationsAndAllotments.error = false;
    },
    [getRegisterOfApplicationsAndAllotmentsAsync.rejected]: (state, action) => {
      state.getRegisterOfApplicationsAndAllotments.status = "failed";
      state.getRegisterOfApplicationsAndAllotments.error =
        action.payload.messageText;
    },

    [downloadRegisterOfApplicationsAndAllotmentsAsync.pending]: (state) => {
      state.downloadRegisterOfApplicationsAndAllotments.status = "loading";
      state.downloadRegisterOfApplicationsAndAllotments.error = false;
    },
    [downloadRegisterOfApplicationsAndAllotmentsAsync.fulfilled]: (
      state,
      action
    ) => {
      state.downloadRegisterOfApplicationsAndAllotments.status = "idle";
      state.downloadRegisterOfApplicationsAndAllotments.error = false;
    },
    [downloadRegisterOfApplicationsAndAllotmentsAsync.rejected]: (
      state,
      action
    ) => {
      state.downloadRegisterOfApplicationsAndAllotments.status = "failed";
      state.downloadRegisterOfApplicationsAndAllotments.error =
        action.payload.messageText;
    },

    [getRegisterOfMembersAndShareLedgerAsync.pending]: (state) => {
      state.getRegisterOfMembersAndShareLedger.status = "loading";
      state.getRegisterOfMembersAndShareLedger.error = false;
    },
    [getRegisterOfMembersAndShareLedgerAsync.fulfilled]: (state, action) => {
      state.registerOfMembersAndShareLedger =
        action.payload.registerOfMembersAndShareLedger;
      state.getRegisterOfMembersAndShareLedger.status = "idle";
      state.getRegisterOfMembersAndShareLedger.error = false;
    },
    [getRegisterOfMembersAndShareLedgerAsync.rejected]: (state, action) => {
      state.getRegisterOfMembersAndShareLedger.status = "failed";
      state.getRegisterOfMembersAndShareLedger.error =
        action.payload.messageText;
    },

    [getRegisterOfBeneficialOwnersAsync.pending]: (state) => {
      state.getRegisterOfBeneficialOwners.status = "loading";
      state.getRegisterOfBeneficialOwners.error = false;
    },
    [getRegisterOfBeneficialOwnersAsync.fulfilled]: (state, action) => {
      state.registerOfBeneficialOwners =
        action.payload.registerOfBeneficialOwners;
      state.getRegisterOfBeneficialOwners.status = "idle";
      state.getRegisterOfBeneficialOwners.error = false;
    },
    [getRegisterOfBeneficialOwnersAsync.rejected]: (state, action) => {
      state.getRegisterOfBeneficialOwners.status = "failed";
      state.getRegisterOfBeneficialOwners.error = action.payload.messageText;
    },

    [downloadRegisterOfBeneficialOwnersAsync.pending]: (state) => {
      state.downloadRegisterOfBeneficialOwners.status = "loading";
      state.downloadRegisterOfBeneficialOwners.error = false;
    },
    [downloadRegisterOfBeneficialOwnersAsync.fulfilled]: (state, action) => {
      state.downloadRegisterOfBeneficialOwners.status = "idle";
      state.downloadRegisterOfBeneficialOwners.error = false;
    },
    [downloadRegisterOfBeneficialOwnersAsync.rejected]: (state, action) => {
      state.downloadRegisterOfBeneficialOwners.status = "failed";
      state.downloadRegisterOfBeneficialOwners.error =
        action.payload.messageText;
    },

    [downloadRegisterOfMembersAndShareLedgerAsync.pending]: (state) => {
      state.downloadRegisterOfMembersAndShareLedger.status = "loading";
      state.downloadRegisterOfMembersAndShareLedger.error = false;
    },
    [downloadRegisterOfMembersAndShareLedgerAsync.fulfilled]: (
      state,
      action
    ) => {
      state.downloadRegisterOfMembersAndShareLedger.status = "idle";
      state.downloadRegisterOfMembersAndShareLedger.error = false;
    },
    [downloadRegisterOfMembersAndShareLedgerAsync.rejected]: (
      state,
      action
    ) => {
      state.downloadRegisterOfMembersAndShareLedger.status = "failed";
      state.downloadRegisterOfMembersAndShareLedger.error =
        action.payload.messageText;
    },

    [getRegisterOfShareCertificatesAsync.pending]: (state) => {
      state.getRegisterOfShareCertificates.status = "loading";
      state.getRegisterOfShareCertificates.error = false;
    },
    [getRegisterOfShareCertificatesAsync.fulfilled]: (state, action) => {
      state.registerOfShareCertificates =
        action.payload.registerOfShareCertificates;
      state.getRegisterOfShareCertificates.status = "idle";
      state.getRegisterOfShareCertificates.error = false;
    },
    [getRegisterOfShareCertificatesAsync.rejected]: (state, action) => {
      state.getRegisterOfShareCertificates.status = "failed";
      state.getRegisterOfShareCertificates.error = action.payload.messageText;
    },

    [downloadRegistersOfShareCertificateAsync.pending]: (state) => {
      state.downloadRegisterOfShareCertificates.status = "loading";
      state.downloadRegisterOfShareCertificates.error = false;
    },
    [downloadRegistersOfShareCertificateAsync.fulfilled]: (state, action) => {
      state.downloadRegisterOfShareCertificates.status = "idle";
      state.downloadRegisterOfShareCertificates.error = false;
    },
    [downloadRegistersOfShareCertificateAsync.rejected]: (state, action) => {
      state.downloadRegisterOfShareCertificates.status = "failed";
      state.downloadRegisterOfShareCertificates.error =
        action.payload.messageText;
    },

    [updateAllotmentsAsync.pending]: (state) => {
      state.updateAllotments.status = "loading";
      state.updateAllotments.error = false;
    },
    [updateAllotmentsAsync.fulfilled]: (state, action) => {
      state.updateAllotments.status = "idle";
      state.updateAllotments.error = false;
    },
    [updateAllotmentsAsync.rejected]: (state, action) => {
      state.updateAllotments.status = "failed";
      state.updateAllotments.error = action.payload.messageText;
    },
  },
});

export const { reset } = minuteBookSlice.actions;

const { reducer } = minuteBookSlice;

export default reducer;
