import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import _get from "lodash/get";
import { Formik, Field, Form } from "formik";
import _merge from "lodash/merge";

import Tabs from "components/lib/Shared/Tabs";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import InviteExistingUser from "components/lib/Shared/Verify/InviteExistingUser";
import getValidationSchema from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/CompanyOfficialsAuthorizedDirector/getValidationSchema";
import NamesFormEditable from "./NamesFormEditable/NamesFormEditable";
import ResidentialDetails from "./ResidentialDetails";
import getInitialValues from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/CompanyOfficialsAuthorizedDirector/getInitialValues";
import IdentificationDetailsForm from "./IdentificationDetails/index";
import { getOfficial, resetOfficial } from "state/slices/companyDashboard";
import ManageAppointmentDate from "./EffectiveDates/AppointmentDate";
import ManageCessationDate from "./EffectiveDates/CessationDate";
import { getFullName } from "utils";

const initialTabs = [
  {
    id: "generalInformation",
    label: "General information",
  },
  {
    id: "addressDetails",
    label: "Address Details",
  },
  {
    id: "fileUploads",
    label: "Upload Documents",
  },
];

const ChangeOfParticularsSubRow = ({ row, visibleColumns }) => {
  const currentUser = useSelector((state) => state.userSlice);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [validationSchema, setValidationSchema] = useState(null);

  const [initialValues, setInitialValues] = useState({});
  const [selectedTab, setSelectedTab] = useState(initialTabs[0]);
  const [inviteExistingUser, setInviteExistingUser] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const formStepErrors = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();

  const { official, getOfficialStatus } = useSelector(
    (state) => state.companyDashboardSlice
  );

  const goToPrevStep = () => {
    if (selectedTabIndex === 0) return;
    setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    if (row?.original?._id) {
      dispatch(
        getOfficial({
          companyId: params.companyId,
          officialId: row?.original?._id,
          idType: row?.original?.idType,
          JSGroupID: row?.original?.JSGroupID,
        })
      );
    }
    return () => {
      dispatch(resetOfficial());
    };
  }, [row?.original?._id]);

  useEffect(() => {
    const tempSelectedTab = initialTabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [selectedTabIndex]);

  if (!official || getOfficialStatus.status === "loading") {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto m-2">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = initialTabs.length - 1 === selectedTabIndex;

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={12} className={`px-9`}>
        <Formik
          initialValues={{
            role: [],
            ...initialValues,
          }}
          onSubmit={(values) => {}}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            resetForm,
            setTouched,
            submitCount,
          }) => {
            const isContactPerson =
              [
                values?.electronicAddress?.emailAddress,
                values?.identificationDetails?.emailAddress,
              ].includes(currentUser.electronicAddress.emailAddress) &&
              values?.type === "invited";

            useEffect(() => {
              if (isContactPerson) {
                setFieldValue(
                  "identificationDetails.companyContactName",
                  getFullName(currentUser?.names)
                );
                setFieldValue(
                  "identificationDetails.telNumber",
                  currentUser?.electronicAddress?.mobileNumber ?? ""
                );
              }
            }, [isContactPerson]);

            // update initial values based on current official
            useEffect(() => {
              setInitialValues(_merge(values, official));
            }, [row.original]);

            // update initial values based on current form step
            useEffect(() => {
              setInitialValues(
                _merge(
                  getInitialValues({
                    idType: values.idType,
                    isNonResident: values.isNonResident,
                    documentData: getDocumentData(values.idType),
                  }),
                  values
                )
              );
            }, [values.idType, values.isNonResident]);

            useEffect(() => {
              setValidationSchema(
                getValidationSchema({
                  selectedTab,
                  idType,
                  isNonResident: values.isNonResident,
                  documentData: getDocumentData(values.idType),
                }).schema
              );
            }, [selectedTab, idType, values.isNonResident]);

            useEffect(() => {
              resetForm();
              formStepErrors.current = null;
            }, [idType]);

            // Reset touched fields on tab change, e.g fix errors jumping around
            useEffect(() => {
              setTouched({}, false);
              setIsFormValid(true);
            }, [selectedTab, setTouched]);

            // Update ErrorBanner errors when user update data
            useEffect(() => {
              if (formStepErrors.current?.[selectedTab.id]?.length > 0) {
                try {
                  validateYupSchema(values, validationSchema, true);
                  formStepErrors.current = {
                    ...formStepErrors.current,
                    [selectedTab.id]: [],
                  };
                } catch (error) {
                  formStepErrors.current = {
                    ...formStepErrors.current,
                    [selectedTab.id]: error.errors,
                  };
                }
              }
            }, [values]);

            // set touched to true for required fields to render errors
            useEffect(() => {
              if (
                values.isIncompleteProfile &&
                Object.keys(errors).length > 0 &&
                submitCount > 0
              ) {
                setTouched(setNestedObjectValues(errors, true));
              }
            }, [values.isIncompleteProfile, errors, submitCount]);

            if (inviteExistingUser) {
              return (
                <InviteExistingUser
                  userId={values.userId}
                  idType={idType}
                  designation={designation}
                  closeVerifyProcess={closeVerifyProcess}
                  userEmailAddress={values.userEmailAddress}
                  values={values}
                  inviteOfficial={addOrEditOfficial}
                />
              );
            }
            return (
              <Form>
                <div className="relative">
                  <Tabs
                    tabs={initialTabs}
                    selectedTabIndex={selectedTabIndex}
                    setSelectedTabIndex={setSelectedTabIndex}
                  />
                  {selectedTab.id === "generalInformation" && (
                    <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                      <div className="px-4 py-6 space-y-6 sm:p-6">
                        <div className="mb-4">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Personal Information
                          </h3>
                        </div>
                        <>
                          <Field
                            name="names"
                            component={NamesFormEditable}
                            idType={row.original.idType}
                            designation={row.original.designation}
                          />
                          <hr />
                          <IdentificationDetailsForm
                            idType={row.original.idType}
                            designation={row.original.designation}
                          />
                          <hr />
                          <h3 className="text-lg mb-2 font-medium leading-6 text-gray-900">
                            Effective Dates
                          </h3>
                          <div className="grid grid-cols-6 gap-6">
                            <Field
                              name="appointmentDate"
                              component={ManageAppointmentDate}
                              idType={row.original.idType}
                              designation={row.original.designation}
                            />
                            <Field
                              name="cessationDate"
                              component={ManageCessationDate}
                              idType={row.original.idType}
                              designation={row.original.designation}
                            />
                          </div>
                        </>
                      </div>
                    </div>
                  )}
                  {selectedTab.id === "addressDetails" && (
                    <>
                      <ResidentialDetails
                        idType={official?.idType}
                        designation={official?.designation}
                        errors={errors}
                        touched={touched}
                        isNonResident={official?.isNonResident}
                      />
                    </>
                  )}
                  {selectedTab.id === "fileUploads" && (
                    <div className="mt-4">
                      <Field
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={getDocumentData(official?.idType)}
                        disabled={false}
                      />
                    </div>
                  )}
                  <div className="flex justify-between">
                    {!isFirstStep && (
                      <button
                        onClick={goToPrevStep}
                        className="flex items-start float-right py-2 font-semibold rounded text-primary mt-7"
                        type="button"
                      >
                        <span className="flex items-center mr-4 h-7">
                          <ChevronLeftIcon
                            className="flex-shrink-0 w-5 h-5"
                            aria-hidden="true"
                          />
                        </span>
                        <p className="font-semibold">Go back</p>
                      </button>
                    )}
                    {isLastStep ? (
                      <button
                        type="submit"
                        className="flex items-center px-4 py-2 mb-2 font-semibold text-white rounded bg-green-focused mt-7 disabled:cursor-not-allowed"
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        onClick={goToNextStep}
                        className="flex items-start float-right py-2 ml-auto font-semibold rounded text-primary mt-7"
                        type="button"
                      >
                        <p className="font-semibold">Next step </p>
                        <span className="flex items-center ml-4 h-7">
                          <ChevronRightIcon
                            className="flex-shrink-0 w-5 h-5"
                            aria-hidden="true"
                          />
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </td>
    </tr>
  );
};

export default ChangeOfParticularsSubRow;
