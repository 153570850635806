import React, { useState, useEffect } from "react";
import { PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import _throttle from "lodash/throttle";

import { useDebouncedEffect } from "hooks/useDebouncedEffect";
import OrganizationInviteForm from "./OrganizationInviteForm";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import OrganizationsTable from "./OrganizationsTable";
import axios from "../../axios";
import { useToast } from "hooks/useToast";
import { inviteOrganization } from "state/slices/organizations";
import Button from "components/lib/Shared/Button";

function Organizations() {
  const { items: organizations } = useSelector(
    (state) => state.organizationsSlice
  ).organizations;
  const currentUser = useSelector((state) => state.userSlice);
  const [showSearchSection, setShowSearchSection] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [error, setError] = useState("");
  const [showOrganizationInviteFlow, setShowOrganizationInviteFlow] =
    useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const { toast } = useToast(4000);
  const dispatch = useDispatch();

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setShowOrganizationInviteFlow(false);
    setOrganization(null);
    setError("");
  };

  useEffect(() => {
    if (!showSearchSection) setSearchValue("");
  }, [showSearchSection]);

  useDebouncedEffect(
    async () => {
      if (searchValue.trim()) {
        try {
          setIsSearching(true);
          const {
            data: { items },
          } = await axios.get(
            `${process.env.REACT_APP_BASE_API_URL}/api/corporates?corporateNameOrRegNumber=${searchValue}`
          );
          if (items.length > 0) {
            setOrganization(items[0]);
            setError("");
          } else {
            setOrganization(null);
            setError(`No match found for ${searchValue}`);
          }
        } catch (error) {
          console.log({ error });
        } finally {
          setIsSearching(false);
        }
      }
    },
    [searchValue, setError, setOrganization],
    1000
  );

  const handleInviteExistingOrg = async () => {
    const contactPersonEmail =
      organization?.identificationDetails?.emailAddress;
    if (!contactPersonEmail) {
      toast("error", `Contact person email is missing for ${searchValue}`);
      return;
    }
    try {
      setIsInviting(true);
      await dispatch(
        inviteOrganization({
          data: {
            organizationName: searchValue,
            contactPersonEmail,
            corporateId: organization._id,
          },
        })
      );
      toast("success", `Email sent successfully to ${searchValue}.`);
      setShowSearchSection(false);
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsInviting(false);
    }
  };

  const inviteAlreadySent = organizations.find(
    ({ corporate }) =>
      corporate.names.companyName === organization?.names?.companyName
  );

  const handleCancelInviteWorkflow = () => {
    setOrganization(null);
    setShowSearchSection(false);
    setError("");
  };

  if (showSearchSection) {
    return (
      <div>
        <b>
          Use the search bar to check if your organization is within the system
        </b>
        <div className="w-full max-w-lg lg:max-w-xs">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              name="search"
              value={searchValue}
              disabled={isInviting}
              onChange={handleSearchChange}
              className="block w-full py-2 pl-10 pr-3 leading-5 text-gray-600 placeholder-gray-400 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-sflPrimary focus:text-gray-900 sm:text-sm disabled:cursor-not-allowed"
              placeholder="Search"
              autoComplete="off"
            />
            {isSearching && (
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <SpinnerIcon className="text-gray-400" />
              </div>
            )}
          </div>
        </div>
        {/* Non existing organizations */}
        {error && !showOrganizationInviteFlow && (
          <div>
            <div className="p-8 mt-4 bg-white border border-gray-100">
              <p className="text-sflPrimary">{error}</p>
            </div>
            <Button
              disabled={isInviting}
              variant="secondary"
              onClick={handleCancelInviteWorkflow}
            >
              Cancel
            </Button>
            <Button
              disabled={isInviting}
              onClick={() => setShowOrganizationInviteFlow(true)}
              className="mt-4 ml-2"
            >
              Add new organization
            </Button>
          </div>
        )}
        {/* Existing organization */}
        {organization && (
          <div>
            <div className="p-8 mt-4 bg-white border border-gray-100 ">
              <p className="text-sflPrimary">1 match found for {searchValue}</p>
              <p className="text-sm text-gray-500">
                {inviteAlreadySent
                  ? "This organization is already invited."
                  : "This organization is within our system but has not been fully onboarded."}
              </p>
            </div>
            {!inviteAlreadySent && (
              <>
                <p className="text-xs text-gray-500">
                  Click add new organization to provide the relevant details to
                  complete the Organization profile.
                </p>
                <Button
                  disabled={isInviting}
                  variant="secondary"
                  onClick={handleCancelInviteWorkflow}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isInviting}
                  onClick={handleInviteExistingOrg}
                  className="mt-4 ml-2"
                >
                  Add new organization
                </Button>
              </>
            )}
          </div>
        )}
        {showOrganizationInviteFlow && searchValue && (
          <OrganizationInviteForm
            searchValue={searchValue}
            setShowSearchSection={setShowSearchSection}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between">
        <h3 className="text-lg font-semibold text-sflPrimary">Organizations</h3>
        {organizations?.length > 0 && currentUser?.isPrincipalAdmin && (
          <Button onClick={() => setShowSearchSection(true)}>
            <PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
            Add Organization
          </Button>
        )}
      </div>
      {organizations?.length === 0 ? (
        <div className="relative flex flex-col items-center justify-center p-32 text-center">
          <svg
            viewBox="0 0 300 100"
            preserveAspectRatio="none"
            className="absolute top-0 left-0 w-full h-full"
          >
            <path
              d="M0,0 300,0 300,100 0,100z"
              className="stroke-2 stroke-sflPrimary fill-transparent [stroke-dasharray:8]"
              vectorEffect="non-scaling-stroke"
            />
          </svg>
          <div className="absolute flex flex-col items-center justify-center">
            {currentUser?.isPrincipalAdmin ? (
              <>
                <h2 className="font-semibold text-title5">
                  Create your first organization
                </h2>
                <p className="text-sflPrimary">
                  Get started by creating an organization and assigning it to an
                  Administrator
                </p>
                <Button
                  onClick={() => setShowSearchSection(true)}
                  className="mt-4"
                >
                  <PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
                  Add Organization
                </Button>
              </>
            ) : (
              <h2 className="font-semibold text-title5">No organizations</h2>
            )}
          </div>
        </div>
      ) : (
        <OrganizationsTable />
      )}
    </>
  );
}

export default Organizations;
