import axios from "../axios";

const getAll = (company, organization, individual, corporate, status) => {
  let queryParams = "";

  if (company) {
    queryParams = `?company=${encodeURIComponent(company)}`;
  }

  if (organization) {
    queryParams = `?organization=${encodeURIComponent(organization)}`;
  }

  if (individual) {
    queryParams += `&individual=${individual}`;
  }

  if (corporate) {
    queryParams += `&corporate=${corporate}`;
  }

  if (status) {
    queryParams += `&status=${status}`;
  }

  return axios.get(`/permissions${queryParams}`);
};

const create = (data) => axios.post("/permissions", data);

const update = (permissionId, data) =>
  axios.put(`/permissions/${permissionId}`, data);

const permissionDataService = {
  getAll,
  create,
  update,
};

export default permissionDataService;
