import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilAltIcon } from "@heroicons/react/solid";
import { DownloadIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import { useDebounce } from "use-debounce";

import Button from "components/lib/Shared/Button";
import useFullscreenToggle from "hooks/useFullscreenToggle";
import DrSharesAcquired from "./DrSharesAcquired";
import DrCashPayableOnShares from "./DrCashPayableOnShares";
import CrCashPayableOnShares from "./CrCashPayableOnShares";
import CrSharesAcquired from "./CrSharesAcquired";
import DrFolio from "./DrFolio";
import CrFolio from "./CrFolio";
import PaginationControls from "../PaginationControls";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import {
  getRegisterOfMembersAndShareLedgerAsync,
  downloadRegisterOfMembersAndShareLedgerAsync,
} from "state/slices/minuteBook";
import {
  getEmptyRowsForRegisters,
  getFullName,
  getUnpaidClassesOfShares,
} from "utils";
import { Constants } from "config/constants";
import BreadCrumbs from "../BreadCrumbs";
import SearchInput from "components/lib/Shared/SearchInput";

const { MEMBER_AND_LEDGER_ROWS } = Constants.REGISTERS;

function RegisterOfMembers({ selectedTab }) {
  const { isFullscreen, ToggleFullscreenContainer, ToggleFullscreenButton } =
    useFullscreenToggle();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
  const [membersAndShareLedger, setMembersAndShareLedger] = useState({
    items: [],
    totalCount: 0,
  });
  const [unpaidClassesOfShares, setUnpaidClassesOfShares] = useState([]);
  const [lastPageWithData, setLastPageWithData] = useState(1);
  const dispatch = useDispatch();
  const [rowsPerPage] = useState(MEMBER_AND_LEDGER_ROWS);
  const [paginatedMembers, setPaginatedMembers] = useState([]);
  const { company } = useSelector((state) => state.companyDashboardSlice);
  const {
    registerOfMembersAndShareLedger,
    getRegisterOfMembersAndShareLedger,
    downloadRegisterOfMembersAndShareLedger,
  } = useSelector((state) => state.minuteBookSlice);

  useEffect(() => {
    if (company._id) {
      dispatch(
        getRegisterOfMembersAndShareLedgerAsync({
          queryParams: { companyId: company._id },
        })
      );
    }
  }, [company._id]);

  useEffect(() => {
    let tempMembersAndShareLedger = registerOfMembersAndShareLedger.items;
    const searchQuery = debouncedSearchTerm.toLowerCase();
    if (searchQuery) {
      tempMembersAndShareLedger = tempMembersAndShareLedger.filter((official) =>
        getFullName(official.names).toLowerCase().includes(searchQuery)
      );
    }
    setCurrentPage(1);
    setMembersAndShareLedger({
      items: tempMembersAndShareLedger,
      totalCount: tempMembersAndShareLedger.length,
    });
  }, [registerOfMembersAndShareLedger, debouncedSearchTerm]);

  useEffect(() => {
    if (membersAndShareLedger.items.length > 0) {
      setUnpaidClassesOfShares(
        getUnpaidClassesOfShares(membersAndShareLedger.items)
      );
      setLastPageWithData(
        Math.ceil(membersAndShareLedger.totalCount / MEMBER_AND_LEDGER_ROWS)
      );
    }
  }, [membersAndShareLedger.items]);

  useEffect(() => {
    if (membersAndShareLedger.totalCount) {
      const start = (currentPage - 1) * rowsPerPage;
      const end = Math.min(
        start + rowsPerPage,
        membersAndShareLedger.items.length
      );
      setPaginatedMembers(membersAndShareLedger.items.slice(start, end));
    } else {
      setPaginatedMembers([]);
    }
  }, [currentPage, membersAndShareLedger, rowsPerPage]);

  if (getRegisterOfMembersAndShareLedger.status === "loading") {
    return (
      <div className="col-span-6">
        <div className="flex justify-center mx-auto text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </div>
    );
  }

  const classOfSharesHeaders =
    registerOfMembersAndShareLedger.classOfSharesHeaders;

  const handleDownloadClick = () => {
    dispatch(
      downloadRegisterOfMembersAndShareLedgerAsync({
        queryParams: { companyId: company._id, fileFormat: "xlsx" },
      })
    );
  };

  return (
    <div className="overflow-auto">
      <div className="flex justify-between w-full mb-4">
        <BreadCrumbs breadCrumbs={selectedTab.breadCrumbs} />
        <Button
          onClick={handleDownloadClick}
          preIcon={DownloadIcon}
          isLoading={
            downloadRegisterOfMembersAndShareLedger.status === "loading"
          }
          loadingText="Downloading..."
        >
          Download
        </Button>
      </div>
      <ToggleFullscreenContainer>
        <ToggleFullscreenButton />
        <div
          className={`bg-white relative ${
            isFullscreen ? "h-[88vh] overflow-auto" : ""
          }`}
        >
          <div className="px-1 pt-4 pb-20 space-y-2 flex flex-col text-center">
            {/* REGISTER OF MEMBERS AND SHARE LEDGER */}
            <div className="relative flex flex-col items-center">
              <div>
                <h2 className="text-title6 font-medium">
                  REGISTER OF MEMBERS AND SHARE LEDGER
                </h2>
                <div className="flex items-center justify-center">
                  <span className="mr-2 text-sm">
                    as at {format(new Date(), "dd-MM-yyyy")}
                  </span>
                  <PencilAltIcon className="w-4 h-4" />
                </div>
                {isFullscreen && (
                  <SearchInput
                    value={searchTerm}
                    placeholder="Search register"
                    className="lg:absolute w-64 top-0 right-0 mt-2"
                    handleOnChange={(event) =>
                      setSearchTerm(event.target.value)
                    }
                    autoFocus
                  />
                )}
              </div>
              <div className={`overflow-auto ${isFullscreen ? "px-12" : ""}`}>
                {paginatedMembers.map((shareholder) => {
                  return (
                    <div className="mt-4 flex divide-x-2 divide-gray-400 text-xs">
                      <div className="flex flex-col">
                        <DrFolio shareholder={shareholder} />
                        <div className="flex">
                          <DrSharesAcquired
                            shareholder={shareholder}
                            unpaidClassesOfShares={unpaidClassesOfShares}
                            classOfSharesHeaders={classOfSharesHeaders}
                          />
                          <DrCashPayableOnShares />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <CrFolio shareholder={shareholder} />
                        <div className="flex">
                          <CrCashPayableOnShares />
                          <CrSharesAcquired
                            classOfSharesHeaders={classOfSharesHeaders}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                {getEmptyRowsForRegisters(
                  paginatedMembers.length,
                  MEMBER_AND_LEDGER_ROWS
                ).map((_, emptyRowIndex) => {
                  return (
                    <div
                      key={emptyRowIndex}
                      className="mt-4 flex divide-x-2 divide-gray-400 text-xs"
                    >
                      <div className="flex flex-col">
                        <DrFolio />
                        <div className="flex">
                          <DrSharesAcquired
                            classOfSharesHeaders={classOfSharesHeaders}
                          />
                          <DrCashPayableOnShares />
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <CrFolio />
                        <div className="flex">
                          <CrCashPayableOnShares />
                          <CrSharesAcquired
                            classOfSharesHeaders={classOfSharesHeaders}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <PaginationControls
            isFullscreen={isFullscreen}
            isLastPage={currentPage === lastPageWithData}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage || 0}
            totalRows={membersAndShareLedger.totalCount}
            totalPages={Math.ceil(
              membersAndShareLedger.totalCount / rowsPerPage
            )}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </ToggleFullscreenContainer>
    </div>
  );
}

export default RegisterOfMembers;
