import React from "react";
import Header from "components/lib/Global/Header";
import { Link, Route, useRouteMatch } from "react-router-dom";
import Footer from "components/lib/Global/Footer";
import Documents from "../Documents";

export default function LegalDocuments() {
  const { url, path } = useRouteMatch();
  return (
    <>
      <div className="flex flex-col min-h-screen overflow-y-hidden text-lg text-sflPrimary">
        <Header />
        <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
          <main className="container flex flex-col mx-auto">
            <section className=" border-b-2">
              <h1 className="text-4xl font-bold tracking-tight sm:text-4xl lg:text-5xl pb-4">
                Legal Documents
              </h1>

              <p className="text-gray-500 text-base py-4">
                Simple Formations reserves the right to amend or modify its
                legal documents at any time. Below are our documents that govern
                how we operate and use client data.
              </p>
            </section>
            <div className="grid grid-cols-4 gap-4">
              {/* class="grid md:grid-cols-2" */}

              <div className="col-span-1 py-4">
                {" "}
                <Route path={path}>
                  <ul className="grid gap-y-4 bg-white shadow-sm p-4">
                    <li className=" text-base uppercase font-semibold">
                      <Link to={`${url}/1`}>1. Data Privacy</Link>
                    </li>
                    <li className=" text-base uppercase font-semibold">
                      <Link to={`${url}/2`}>2. Term of Use</Link>
                    </li>
                  </ul>
                </Route>
              </div>

              <div className="sm:col-span-3 py-4">
                <Route path={`${path}/:id`} component={Documents} />
              </div>
            </div>
            {/* */}
          </main>
        </div>

        <Footer />
      </div>
    </>
  );
}
