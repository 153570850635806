import React from "react";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import { Formik, Field } from "formik";
import { PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";

import Accordion from "components/lib/Global/Accordion";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import CommentsList from "components/lib/Global/CommentsList";
import { getCompanySuffix } from "utils/company";

const preferredNamesTitles = {
  1: "First preferred name",
  2: "Second preferred name",
  3: "Third preferred name",
  4: "Fourth preferred name",
  5: "Fifth preferred name",
};

function FirstStep({ handleSaveComment, isViewMode }) {
  const { company } = useSelector((state) => state.companySlice);
  const {
    companyFormation: { step1, resolvedFields },
  } = useSelector((state) => state.companySlice);
  const { comments } = useSelector((state) => state.commentsSlice);
  const { preferredNamesCount } = step1;

  return (
    <Accordion
      wrapperClasses="max-w-7xl ml-4 mx-auto py-3 sm:py-3"
      titleClasses="text-sm font-medium text-primary uppercase"
      verticalSpace="space-y-4"
      iconColor="text-gray-400"
      openIcon={PlusSmIcon}
      collapseIcon={MinusSmIcon}
      tabs={Array.from({ length: preferredNamesCount }, (_, i) => i + 1).map(
        (nameCount) => {
          const letterOfNoObjection = _get(
            step1,
            `fileUploads.affiliatedBusiness${nameCount}_LNO`
          );
          const preferredCompanyNameCardComments = comments.filter(
            (comment) =>
              comment.formName === `preferredCompanyNameCard_${nameCount}`
          );
          const specialInstructions = _get(
            step1,
            `specialInstructions${nameCount}`,
            "N/A"
          );
          const isAbbreviated = _get(
            step1,
            `isCompanyName${nameCount}Abbreviated`
          );

          const companyPreferredName = _get(
            step1,
            `companyName${nameCount}`,
            "N/A"
          );
          const companyType = _get(company, "type", "PLC");

          const nameSuffix = getCompanySuffix(
            _get(step1, `isCompanyName${nameCount}Abbreviated`),
            companyType
          );

          const formFields = [
            {
              key: "Name",
              name: `companyName${nameCount}`,
              value: `${companyPreferredName} ${nameSuffix}`,
            },
            {
              key: "Abbreviate",
              name: `isCompanyName${nameCount}Abbreviated`,
              value: isAbbreviated ? "Yes" : "No",
            },
            {
              key: "Special Instruction",
              name: `specialInstructions${nameCount}`,
              value:
                specialInstructions === "affiliatedBusiness"
                  ? "Affiliated To Existing Business"
                  : specialInstructions,
            },
          ];

          // only render affiliated business related fields if they exists
          if (specialInstructions === "affiliatedBusiness") {
            formFields.push(
              {
                key: "Affiliated Business",
                name: `affiliatedBusiness${nameCount}.affiliatedBusinessName`,
                value: _get(
                  step1,
                  `affiliatedBusiness${nameCount}.affiliatedBusinessName`,
                  "N/A"
                ),
              },
              {
                key: "Registration number of affiliated business",
                name: `affiliatedBusiness${nameCount}.affiliatedBusinessRegNumber`,
                value: _get(
                  step1,
                  `affiliatedBusiness${nameCount}.affiliatedBusinessRegNumber`,
                  "N/A"
                ),
              },
              {
                key: "No objection letter",
                name: `affiliatedBusiness${nameCount}.affiliatedBusiness${nameCount}_LNO`,
                value: (
                  <>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        fileUploads: {
                          [`affiliatedBusiness${nameCount}_LNO`]:
                            letterOfNoObjection,
                        },
                      }}
                      onSubmit={() => null}
                    >
                      {() => {
                        return (
                          <div className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300">
                            {letterOfNoObjection ? (
                              <Field
                                name="fileUploads"
                                component={FileUploadForm}
                                documentData={[
                                  {
                                    name: `affiliatedBusiness${nameCount}_LNO`,
                                    label: `affiliatedBusiness${nameCount}_LNO`,
                                  },
                                ]}
                                onlyPreview
                                formName={`preferredCompanyNameCard_${nameCount}`}
                                handleSaveComment={handleSaveComment}
                              />
                            ) : (
                              "N/A"
                            )}
                          </div>
                        );
                      }}
                    </Formik>
                  </>
                ),
              }
            );
          }

          return {
            title: preferredNamesTitles[nameCount],
            commentsCount: preferredCompanyNameCardComments.length,
            content: (
              <>
                {preferredCompanyNameCardComments.length > 0 && (
                  <div className="px-8 pt-5 pb-6 bg-white">
                    <CommentsList
                      comments={preferredCompanyNameCardComments}
                      toggleCommentStatus={!isViewMode}
                    />
                    <p className="mt-6 text-sm text-primary">
                      Comments: {preferredCompanyNameCardComments.length} of{" "}
                      {preferredCompanyNameCardComments.length}
                    </p>
                  </div>
                )}
                <FormFieldsCard
                  addReviewComment={!isViewMode}
                  fields={formFields}
                  resolvedFields={resolvedFields}
                  formName={`preferredCompanyNameCard_${nameCount}`}
                  key={`preferredCompanyNameCard_${nameCount}`}
                  formHelperKey="company"
                  updateEffectKey="updateCompanyFormationState"
                  formId={_get(company, "_id")}
                  handleSaveComment={handleSaveComment}
                  comments={preferredCompanyNameCardComments}
                  hideResolveAllCheckbox={isViewMode}
                  isViewMode={isViewMode}
                />
              </>
            ),
          };
        }
      )}
    />
  );
}

export default FirstStep;
