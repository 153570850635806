import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import packageService from "services/package";

export const getPackagesAsync = createAsyncThunk(
  "packages/getPackagesAsync",
  async (queryParams) => {
    const res = await packageService.getAll(queryParams);
    return res.data;
  }
);

export const addPackageAsync = createAsyncThunk(
  "packages/addPackageAsync",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await packageService.create(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editPackageAsync = createAsyncThunk(
  "packages/editPackageAsync",
  async ({ packageId, data }) => {
    const res = await packageService.update(packageId, data);
    return res.data;
  }
);

export const deletePackageAsync = createAsyncThunk(
  "packages/deletePackageAsync",
  async ({ packageId }, { rejectWithValue }) => {
    try {
      await packageService.remove(packageId);
      return { packageId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addFeatureAsync = createAsyncThunk(
  "packages/addFeatureAsync",
  async ({ packageId, data }) => {
    const res = await packageService.addFeature(packageId, data);
    return res.data;
  }
);

export const updateFeatureAsync = createAsyncThunk(
  "packages/updateFeatureAsync",
  async ({ packageId, featureId, data }) => {
    const res = await packageService.updateFeature(packageId, featureId, data);
    return res.data;
  }
);

export const deleteFeatureAsync = createAsyncThunk(
  "packages/deleteFeatureAsync",
  async ({ packageId, featureId }) => {
    const res = await packageService.deleteFeature(packageId, featureId);
    return res.data;
  }
);

export const addCurrencyAsync = createAsyncThunk(
  "packages/addCurrencyAsync",
  async ({ packageId, data }) => {
    const res = await packageService.addCurrency(packageId, data);
    return res.data;
  }
);

export const updateCurrencyAsync = createAsyncThunk(
  "packages/updateCurrencyAsync",
  async ({ packageId, currencyId, data }) => {
    const res = await packageService.updateCurrency(
      packageId,
      currencyId,
      data
    );
    return res.data;
  }
);

export const deleteCurrencyAsync = createAsyncThunk(
  "packages/deleteCurrencyAsync",
  async ({ packageId, currencyId }) => {
    const res = await packageService.deleteCurrency(packageId, currencyId);
    return res.data;
  }
);

const initialState = {
  packages: {
    items: [],
    totalCount: 0,
    pendingPaidApplicationsCount: 0,
  },
  selectedPackage: {
    features: [],
    currencies: [],
  },
  mode: "",
  updatingFeatures: [],
  deletingFeatures: [],
  getPackages: {
    status: "idle",
    error: false,
  },
  updatingPackage: {
    status: "idle",
    error: false,
  },
};

const packagesSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    updatePackageState: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetPackagesSlice: () => initialState,
  },
  extraReducers: {
    [editPackageAsync.pending]: (state) => {
      state.updatingPackage.status = "loading";
      state.updatingPackage.error = false;
    },
    [editPackageAsync.fulfilled]: (state, action) => {
      state.updatingPackage.status = "loading";
      state.updatingPackage.error = false;
    },
    [editPackageAsync.rejected]: (state, action) => {
      state.updatingPackage.status = "failed";
      state.updatingPackage.error = action?.payload?.messageText;
    },

    [getPackagesAsync.pending]: (state) => {
      state.getPackages.status = "loading";
      state.getPackages.error = false;
    },
    [getPackagesAsync.fulfilled]: (state, action) => {
      state.getPackages.status = "idle";
      state.packages = action.payload;
    },
    [getPackagesAsync.rejected]: (state, action) => {
      state.getPackages.status = "failed";
      state.getPackages.error = action?.payload?.messageText;
    },

    [addPackageAsync.fulfilled]: (state, action) => {
      state.selectedPackage = action.payload.package;
    },
    [addFeatureAsync.fulfilled]: (state, action) => {
      state.selectedPackage.features = action.payload.features;
    },

    [updateFeatureAsync.pending]: (state, action) => {
      state.updatingFeatures.push(action.meta.arg.featureId);
    },
    [updateFeatureAsync.fulfilled]: (state, action) => {
      state.selectedPackage.features = action.payload.features;
      state.updatingFeatures = state.updatingFeatures.filter(
        (featureId) => featureId !== action.meta.arg.featureId
      );
    },
    [updateFeatureAsync.rejected]: (state, action) => {
      state.updatingFeatures = state.updatingFeatures.filter(
        (featureId) => featureId !== action.meta.arg.featureId
      );
    },

    [deleteFeatureAsync.pending]: (state, action) => {
      state.deletingFeatures.push(action.meta.arg.featureId);
    },
    [deleteFeatureAsync.fulfilled]: (state, action) => {
      state.selectedPackage.features = action.payload.features;
    },
    [deleteFeatureAsync.rejected]: (state, action) => {
      state.deletingFeatures = state.deletingFeatures.filter(
        (featureId) => featureId !== action.meta.arg.featureId
      );
    },

    [addCurrencyAsync.fulfilled]: (state, action) => {
      state.selectedPackage.currencies = action.payload.currencies;
    },
    [updateCurrencyAsync.fulfilled]: (state, action) => {
      state.selectedPackage.currencies = action.payload.currencies;
    },
    [deleteCurrencyAsync.fulfilled]: (state, action) => {
      state.selectedPackage.currencies = action.payload.currencies;
    },
  },
});

export const { updatePackageState, resetPackagesSlice } = packagesSlice.actions;

export default packagesSlice.reducer;
