import React from "react";
import { TrashIcon } from "@heroicons/react/solid";

import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

function CustomTrashIcon({ isLoading, disabled, className, ...props }) {
  if (isLoading) return <SpinnerIcon className="text-gray-500" />;

  return (
    <button
      disabled={isLoading || disabled}
      className={`disabled:cursor-not-allowed ${className}`}
    >
      <TrashIcon className="w-4 h-4 text-red-500" {...props} />
    </button>
  );
}

export default CustomTrashIcon;
