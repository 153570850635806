import { object, string } from "yup";

const selectIdTypeValidation = () => {
  const schema = object().shape({
    linkToCompany: string()
      .required("Shareholder with B.O required")
      .default(""),
    idType: string().required("ID Type of the B.O is required").default(""),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default selectIdTypeValidation;
