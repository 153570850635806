import { Link } from "react-router-dom";
export const DataPrivacy = () => {
  return (
    <>
      <div>
        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          In the course of using our website, you may provide us with your
          personal data and we are obliged to use the personal data in
          accordance with all the laws concerning the protection of personal
          data including the Data Protection Act 2019 (“DPA”) and the
          Regulations thereto. Please read this Data Privacy Statement carefully
          to understand our personal data management practices.
        </p>
      </div>

      <div className="space-y-0.6 pb-10 ">
        <p className="text-gray-500 font-semibold text-base py-4">
          Definitions
        </p>
        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          “Simple Formations”, “Us” or “We” means Simple Formations Limited and
          includes its successors in title and assigns, affiliates, and
          subsidiaries as may from time to time be specified by Simple
          Formations to you.
        </p>

        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          “Personal Data” means information about you or information that
          identifies you as an individual.
        </p>

        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          “Processing” collectively means handling, collecting, using altering,
          merging, linking, organizing, disseminating, storing, protecting,
          retrieving, disclosing, erasing, archiving, destroying, or disposing
          of your personal information.
        </p>

        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          “Your” means the customer, their authorized agent/attorney, visitors,
          suppliers, and service providers.
        </p>

        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          “Site” means our website www.simpleformations.ke.
        </p>
      </div>

      <div>
        <div>
          <div className="flex py-6 justify-between items-center border-b border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              What Personal Information do we collect?
            </h1>
          </div>

          <div>
            <ul>
              <li className="xl:py-8 lg:py-8 md:py-8 sm:py-8 py-4">
                <p className="text-gray-800 md:text-xl lg:text-xl xl:w-10/12 w-full lg:mb-4 mb-2">
                  We will collect the following information:
                </p>
                <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                  Identification information of the shareholders, directors,
                  beneficial owners, company secretaries, trustees, authorized
                  persons and partners of a customer, phone numbers, bank
                  account details, credit card details, e-mail addresses,
                  residential and business addresses, postal address,
                  registration documents, KRA PIN Certificate, photograph, date
                  of birth, email address, age and gender.
                </p>
              </li>
              <li className="py-4 md:py-6 lg:py-8">
                <p className="text-gray-800 md:text-xl lg:text-xl xl:w-10/12 w-full lg:mb-4 mb-2">
                  Use of Information We Collect
                </p>
                <p className="xl:w-10/12 lg:w-10/12 w-full  text-base">
                  <ul className="space-y-0.5">
                    <li>
                      <span>a)</span>
                      Processing products and services that suite the needs of
                      our users.
                    </li>
                    <li>
                      <span>b)</span>
                      Billing you for using our products and services{" "}
                    </li>
                    <li>
                      <span>c)</span>
                      Processing your digital information such as IP address,
                      device type used to access the service and duration for
                      which your session lasted.{" "}
                    </li>
                    <li>
                      <span>d)</span>
                      To understand our users preferences so that we can enhance
                      user experience and tools in future{" "}
                    </li>
                    <li>
                      {" "}
                      <span>e)</span>To respond to your enquiries{" "}
                    </li>
                    <li>
                      {" "}
                      <span>f)</span>
                      To verify information submitted to us from other parties{" "}
                    </li>
                    <li>
                      {" "}
                      <span>g)</span>
                      For marketing purposes and to keep you informed about our
                      products and services and contacting you with offers,
                      promotions and discounts unless you opt out of receiving
                      promotional messages{" "}
                    </li>
                    <li>
                      {" "}
                      <span>h)</span>
                      To comply with regulatory requirements{" "}
                    </li>
                    <li>
                      {" "}
                      <span>i)</span>
                      For research, business development and process improvement{" "}
                    </li>
                  </ul>
                </p>
              </li>
              <li className="py-4 md:py-6 lg:py-8">
                <p className="text-gray-800 md:text-xl lg:text-xl xl:w-10/12 w-full lg:mb-4 mb-2">
                  Legal Basis for Processing your Personal Data
                </p>
                <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                  <ul className="space-y-0.5">
                    <li>
                      <span>a)</span>
                      For the performance of services for which you are a party
                      to:
                    </li>
                    <li>
                      <span>b)</span>
                      Where processing is necessary for our legitimate business
                      interests or a third party provided that they are in
                      accordance with the DPA.{" "}
                    </li>

                    <li>
                      {" "}
                      <span>c)</span>Public Interest Purposes;{" "}
                    </li>
                    <li>
                      <span>d)</span>
                      To protect your vital interests or vital interests of
                      another person.{" "}
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div>
          {/* Tab 1 */}
          <div className="flex py-6 justify-between items-center border-b border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              How long will my Data be held?
            </h1>
          </div>

          <div>
            <ul>
              <li className="xl:py-8 lg:py-8 md:py-8 sm:py-8 py-4">
                <p className="">
                  Your personal data will be held for as long as you are a
                  customer of Simple Formations and for as long as is reasonably
                  necessary to fulfill the purposes it was collected for. Once
                  you cease being a customer, we will only retain the personal
                  data necessary for the purposes of:
                </p>

                <div className="px-8">
                  <ol className="list-decimal">
                    <li className="">
                      Fulfilling a legal, regulatory, tax, accounting or
                      reporting requirements; or
                    </li>
                    <li className="">
                      Establishing or defending a legal claim.
                    </li>
                  </ol>
                </div>

                <div className="space-y-0.5">
                  <p className="">
                    In determining the retention period, we shall consider the
                    amount, sensitivity of the personal data, the potential risk
                    of harm from unauthorized access or use of personal data.
                  </p>

                  <p className="">
                    Information collected for purposes of submission to the
                    Business Registration Service shall be maintained for at
                    least ten (10) years from the date one ceases to be a
                    customer.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Tab 2  */}

        <div>
          <div className="flex py-6 justify-between items-center border-b border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              Disclosure of Your Personal Data
            </h1>
          </div>
          <div className="xl:w-10/12 lg:w-10/12 w-full  text-base space-y-4">
            <div className="space-y-0.5">
              <p className="">
                In the course of provision of our services, we may disclose your
                information to:
              </p>
            </div>
            <ul className="space-y-0.5">
              <li>
                <span>a) </span>
                Law-enforcement agencies, regulatory authorities, courts
                provided that they provide a written demand within their lawful
                mandate;
              </li>
              <li>
                <span>b) </span>
                Our partners, business partners and associates, software
                developers involved in business development; and
              </li>
              <li>
                <span>c) </span>
                Anti-money laundering and fraud prevention agencies.
              </li>
            </ul>
          </div>
        </div>
        {/* Tab 3 */}
        <div>
          <div className="flex py-6 justify-between items-center border-b border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              Your Rights and How to Exercise them
            </h1>
          </div>

          <div className="xl:w-10/12 lg:w-10/12 w-full  text-base space-y-4">
            <div className="space-y-0.5">
              <p className="">
                It is important to us that you are aware of the following rights
                and how to exercise them: They include to:
              </p>
            </div>
            <ul className="space-y-0.5">
              <li>
                <span>a) </span>
                Request to access the personal data that we hold.
              </li>
              <li>
                <span>b) </span>
                Ask us to erase personal data if it is no longer necessary for
                the reason/purpose for which it was collected
              </li>
              <li>
                <span>c) </span>
                Anti-money laundering and fraud prevention agencies.
              </li>

              <li>
                <span>d) </span>
                Ask us to rectify inaccurate personal data or to complete
                incomplete personal data
              </li>
              <li>
                <span>e) </span>
                Restrict processing of information for certain specific
                circumstances
              </li>
              <li>
                <span>f) </span>
                Request information on the safeguards that Simple Formations has
                put in place when your data is transferred and/or stored outside
                of Kenya
              </li>

              <li>
                <span>g) </span>
                Withdraw consent to receiving marketing material at any time;
                and
              </li>

              <li>
                <span>h) </span>
                Receive or ask for your personal data to be transferred to a
                third party in a machine-readable format
              </li>
            </ul>
          </div>
        </div>

        {/* Tab 4  */}
        <div>
          <div className="flex py-6 justify-between items-center border-b border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              Cookies
            </h1>
          </div>

          <div className="xl:w-10/12 lg:w-10/12 w-full  text-base space-y-4">
            <div className="space-y-0.5">
              <p className="">
                Your information will be collected by our systems and processed
                in line with our IT policy and our Cookie Policy. We shall
                however, ask for your consent prior to placing cookies on your
                devices. Cookies enable us to capture and collect information
                that enables us to understand and save your preferences for
                future use and to compile and aggregate data so that we can
                offer better experiences and tools in the future.
              </p>

              <p className="">
                We may also use these services for marketing purposes to choose
                personalized offers, discounts or recommendations for you. We
                may from time-to-time contract/outsource these services to Third
                Parties to assist us in better understanding our users. We shall
                not, under any circumstances share with, or sell your personal
                data to any third party for marketing purposes.
              </p>
            </div>
          </div>
        </div>

        {/* Tab 5  */}
        <div>
          <div className="flex py-6 justify-between items-center border-b border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              Protection of Your Personal Information
            </h1>
          </div>
          <div className="xl:w-10/12 lg:w-10/12 w-full  text-base space-y-4">
            <div className="space-y-0.5">
              <p className="">
                We shall take all the reasonable steps to maintain the safety of
                your personal information. You are reminded to keep your
                password and account information confidential and you should
                always logout and close your browser when you finish using the
                Site.
              </p>

              <p className="">
                Simple Formations may, from time to time, share your personal
                data with third parties. Such disclosures will be in accordance
                with the law and, where necessary, with your consent.
              </p>
            </div>
          </div>
        </div>

        {/* Tab 6  */}
        <div>
          <div className="flex py-6 justify-between items-center border-b border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              Marketing and Promotional Messages
            </h1>
          </div>
          <div className="xl:w-10/12 lg:w-10/12 w-full  text-base space-y-4">
            <div className="space-y-0.5">
              <p className="">
                Your information may be collected to inform us about your
                preferred products and services and in this connection, we may
                send you marketing material. You are reminded that you are
                required to opt in or give any form of explicit consent before
                receiving marketing messages. You also have a right to opt out
                at any time by writing to us on{" "}
                <Link to="" className="font-bold">
                  dpo@simpleformations.ke
                </Link>{" "}
                or logging onto our website{" "}
                <Link to="" className=" font-bold">
                  www.simpleformations.ke/dpo
                </Link>{" "}
                or unchecking any relevant boxes to suit your preference.
              </p>
            </div>
          </div>
        </div>

        {/* Tab 7  */}
        <div>
          <div className="flex py-6 justify-between items-center border-b border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              Lodging of Complaints
            </h1>
          </div>
          <div className="xl:w-10/12 lg:w-10/12 w-full  text-base space-y-4">
            <div className="space-y-0.5">
              <p className="">
                You may lodge a complaint by sending the email to{" "}
                <Link to="" className="font-bold">
                  dpo@simpleformations.ke
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>

        {/* Tab 8  */}
        <div>
          <div className="flex py-6 justify-between items-center border-b border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              Amendments
            </h1>
          </div>
          <div className="xl:w-10/12 lg:w-10/12 w-full  text-base space-y-4">
            <div className="space-y-0.5">
              <p className="">
                We reserve the right to amend and modify this Data Privacy
                Statement and notifications of such amendments will be posted on
                our website. Your continued use of our products and services
                will constitute agreement to be bound by the terms of any such
                amendment or variation. You can access the most current version
                on our website.
              </p>
            </div>
          </div>
        </div>

        {/* Tab 9 */}
        <div>
          <div className="flex py-6 justify-between items-center border-gray-200">
            <h1 className="text-base md:text-xl lg:text-2xl font-bold">
              Version Control
            </h1>
          </div>
          <div className="text-base mb-4 w-full rounded-lg bg-white shadow dark:bg-gray-800">
            <div className="rounded-lg border border-gray-100 dark:border-gray-700">
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <thead>
                    <tr className="h-12 w-full bg-gray-50 text-base leading-none dark:bg-gray-700 dark:text-white">
                      <th
                        tabindex="0"
                        className="pl-8 text-left focus:outline-none"
                      >
                        Version No.
                      </th>
                      <th
                        tabindex="0"
                        className="px-10 text-left focus:outline-none lg:px-6 xl:px-0"
                      >
                        Document Description
                      </th>
                      <th
                        tabindex="0"
                        className="px-10 text-left focus:outline-none lg:px-6 xl:px-0"
                      >
                        Approval Date
                      </th>

                      <th
                        tabindex="0"
                        className="px-10 text-left focus:outline-none lg:px-6 xl:px-0"
                      >
                        Target Review Date
                      </th>
                      <th
                        tabindex="0"
                        className="px-10 text-left focus:outline-none lg:px-6 xl:px-0"
                      >
                        Approved By
                      </th>
                    </tr>
                  </thead>
                  <tbody className="w-full">
                    <tr className="h-12 border-b border-gray-100 text-base leading-none dark:text-white">
                      <td tabindex="0" className="pl-8 focus:outline-none">
                        1.0
                      </td>
                      <td
                        tabindex="0"
                        className="px-10 font-medium focus:outline-none lg:px-6 xl:px-0"
                      >
                        First version of the Data Privacy Statement
                      </td>
                      <td
                        tabindex="0"
                        className="px-10 font-medium focus:outline-none lg:px-6 xl:px-0"
                      >
                        14th March 2022
                      </td>
                      <td
                        tabindex="0"
                        className="px-10 font-medium focus:outline-none lg:px-6 xl:px-0"
                      >
                        14th March 2022
                      </td>
                      <td className="px-10 lg:px-6 xl:px-0">
                        <p
                          tabindex="0"
                          className="text-blue-700 underline focus:outline-none dark:text-blue-400"
                        >
                          Board
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
