export const convertWholeNumberToWords = (number, concatenateWithAnd) => {
  const units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  const tens = [
    "",
    "ten",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const thousands = ["", "thousand", "million", "billion", "trillion"];

  function convertToWordsLessThanThousand(num, level) {
    if (num < 20) {
      return units[num];
    } else if (num < 100) {
      return tens[Math.floor(num / 10)] + " " + units[num % 10];
    } else {
      return (
        units[Math.floor(num / 100)] +
        ` hundred${
          num % 100 !== 0 && level === 0 && concatenateWithAnd ? " and " : " "
        }` +
        convertToWordsLessThanThousand(num % 100, level)
      );
    }
  }

  function convertToWords(num, level) {
    if (num === 0) {
      return "";
    }

    const result =
      convertToWordsLessThanThousand(num, level) + " " + thousands[level];
    return result.trim();
  }

  if (number === 0) {
    return "zero";
  }

  let words = "";
  let level = 0;

  while (number > 0) {
    const chunk = number % 1000;
    if (chunk !== 0) {
      words = convertToWords(chunk, level) + " " + words;
    }
    level++;
    number = Math.floor(number / 1000);
  }

  return words.trim();
};

export const numberToWords = (number) => {
  let [wholeNumber, decimalPart] = String(number).split(".");
  decimalPart = decimalPart ? decimalPart.slice(0, 2) : null;

  if (wholeNumber === "0" && decimalPart)
    return `${convertWholeNumberToWords(Number(decimalPart), false)} cents`;

  const wholeNumberWords = convertWholeNumberToWords(
    Number(wholeNumber),
    decimalPart ? false : true
  );

  return decimalPart
    ? `${wholeNumberWords} and ${convertWholeNumberToWords(
        Number(decimalPart),
        false
      )} cents`
    : wholeNumberWords;
};
