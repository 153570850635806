import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { DocumentEditor } from "@onlyoffice/document-editor-react";

import { generateTokenAsync } from "state/slices/template";
import SpinnerIcon from "../Icons/SpinnerIcon";
import useFullscreenToggle from "hooks/useFullscreenToggle";

const defaultPermissions = {
  download: true,
  print: true,
};

function OnlyOfficeDocumentEditor(props) {
  console.log({ props });
  const {
    url,
    editorKey,
    userFullName,
    userId,
    companyId,
    renderSummary = false,
    SummaryOfArticles = <></>,
    id = "Editor",
    documentType = "word",
    width = "100%",
    fileType = "docx",
    title = "Articles of Association.docx",
    mode = "edit",
    zoom = -2, // fitting the document page width to the editor page
    containerHeight = "",
    showFullScreenToggle = true,
    permissions = {},
    onDocumentReady = () => null,
    onLoadComponentError = () => null,
    onDocumentStateChange = () => null,
    onError = () => null,
    handleSave = (event) => console.log({ handleSaveDocument: event }),
  } = props;

  const [token, setToken] = useState(null);
  const {
    isFullscreen,
    documentEditorHeight,
    ToggleFullscreenContainer,
    ToggleFullscreenButton,
  } = useFullscreenToggle(
    renderSummary ? "right-2/4 pl-4" : "",
    !showFullScreenToggle ? "900px" : containerHeight
  );
  const role = Cookies.get("role");

  const height =
    props.height && !isFullscreen ? props.height : documentEditorHeight;

  const config = useMemo(
    () => ({
      documentType,
      height,
      width,
      document: {
        fileType,
        key: editorKey,
        title,
        url,
        permissions: {
          ...defaultPermissions,
          ...permissions,
        },
      },
      editorConfig: {
        callbackUrl: `${process.env.REACT_APP_BASE_API_URL}/api/templates/track-changes`,
        mode,
        user: {
          // Defines the user currently viewing or editing the document
          name: userFullName,
          id: `${userId}-${role}-${companyId}`,
        },
        customization: {
          customer: {},
          anonymous: {
            request: false,
          },
          autosave: true,
          logo: {
            image:
              "https://sfl-bucket.s3.us-west-2.amazonaws.com/transparent.png",
          },
          uiTheme: "theme-classic-light",
          feedback: false,
          help: false,
          zoom,
        },
      },
    }),
    [height]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    async function generateToken() {
      const response = await dispatch(generateTokenAsync({ data: config }));
      console.log({ response });
      setToken(response.payload.token);
    }
    generateToken();
  }, [config]);

  console.log({ props, config, token });

  if (!token) {
    return (
      <div className="flex justify-center mx-auto mt-10 text-white">
        <SpinnerIcon className="text-gray-800" />
      </div>
    );
  }

  return (
    <ToggleFullscreenContainer>
      {showFullScreenToggle && <ToggleFullscreenButton />}
      <DocumentEditor
        id={id}
        documentServerUrl={
          process.env.REACT_APP_ONLY_OFFICE_DOCUMENT_SERVER_URL
        }
        config={{
          ...config,
          token,
        }}
        events_onDocumentReady={onDocumentReady}
        onLoadComponentError={(errorCode, errorDescription) => {
          console.log({
            errorCode,
            errorDescription,
          });
          onLoadComponentError(errorCode, errorDescription);
        }}
        events_onDocumentStateChange={(event) => {
          onDocumentStateChange(event);
        }}
        events_onError={(events_onError) => {
          console.log({ events_onError });
          onError(events_onError);
        }}
        events_onRequestSaveAs={handleSave}
      />
      {renderSummary && isFullscreen && (
        <div
          className={`${
            isFullscreen
              ? `fixed inset-0 ${
                  renderSummary ? "left-2/4" : ""
                } top-[7.5rem] z-50`
              : "w-full min-h-[900px]"
          } overflow-auto text-left bg-white px-6`}
        >
          <SummaryOfArticles />
        </div>
      )}
    </ToggleFullscreenContainer>
  );
}

export default React.memo(OnlyOfficeDocumentEditor);
