import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import Cookies from "js-cookie";

import ReactTable from "components/lib/Global/ReactTable";
import Button from "components/lib/Shared/Button";
import DeleteButtonWithConfirmation from "components/lib/Shared/DeleteButtonWithConfirmation";
import { deleteCurrencyAsync } from "state/slices/package";
import { Constants } from "config/constants";
import { formatCurrency } from "utils";
import ConfirmDialog from "components/lib/Shared/ConfirmDialog";
import TableActionMenuWrapper from "components/lib/Shared/TableActionMenuWrapper";
import SearchInput from "components/lib/Shared/SearchInput";

function CurrenciesTable({ handleViewPackage, handleEditPackage }) {
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const { selectedPackage } = useSelector((state) => state.packageSlice);
  const { modalRow } = useSelector((state) => state.modalsSlice);

  const handleDeleteCurrency = async (row) => {
    await dispatch(
      deleteCurrencyAsync({
        packageId: selectedPackage._id,
        currencyId: row._id,
      })
    );
  };

  const columns = [
    {
      Header: "Currency",
      accessor: "type",
    },
    {
      Header: "Base Price",
      accessor: "basePrice",
      Cell: ({ row }) => formatCurrency(row.original.basePrice),
    },
    {
      Header: "VAT Applicable?",
      accessor: "applicableTax",
      Cell: ({ row }) => {
        if (row.original.applicableTax === 1.16) return "16 %";
        return "N/A";
      },
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <TableActionMenuWrapper>
            <Menu.Item>
              {({ active }) => (
                <Button
                  overrideBaseStyles
                  onClick={() => handleViewPackage(row.original)}
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                >
                  View
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  onClick={() => handleEditPackage(row.original)}
                  overrideBaseStyles
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                >
                  Edit
                </Button>
              )}
            </Menu.Item>
            {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
              <Menu.Item>
                {({ active }) => (
                  <DeleteButtonWithConfirmation
                    confirmationModalName="delete_currency_confirmation_modal"
                    overrideBaseStyles
                    btnClasses={`${
                      active ? "bg-red-600 text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                    row={row.original}
                    handleDelete={handleDeleteCurrency}
                  />
                )}
              </Menu.Item>
            )}
          </TableActionMenuWrapper>
        );
      },
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full bg-white shadow">
        <div className="w-full">
          <ReactTable
            columns={columns}
            data={selectedPackage.currencies}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={selectedPackage?.currencies?.length}
            setCurrentPage={setCurrentPage}
            headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
            headingStyle="pl-4 text-left text-white"
            dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          />
        </div>
      </div>
      <ConfirmDialog
        name="delete_currency_confirmation_modal"
        onConfirm={() => handleDeleteCurrency(modalRow)}
      >
        Are you sure you want to permanently delete this entry?
      </ConfirmDialog>
    </>
  );
}

export default CurrenciesTable;
