import React from "react";
import { useSelector } from "react-redux";
import _uniq from "lodash/uniq";

import ShareInformationTopSection from "components/lib/Global/ShareInformationTopSection";
import NominalSharesTable from "components/PLCTABS/NominalShareCapitalTab/NominalSharesTable";
import SharesCategoryTabs from "components/PLCTABS/NominalShareCapitalTab/SharesCategoryTabs";
import { getTakenShares, roundNumber } from "utils";

const ShareInformation = () => {
  const { shares } = useSelector((state) => state).sharesTableSlice;

  const takenShares = getTakenShares(
    useSelector((state) => state.companyOfficialsSlice.companyOfficials.items)
  );

  const sumNominalCapital = shares
    .map((share) => share.sharesValue * share.sharesNumber)
    .reduce((a, b) => a + b, 0);

  const sumOfVotingRights = shares.reduce(
    (acc, share) => roundNumber(acc + Number(share.votingRights)),
    0
  );

  return (
    <div className="mt-6">
      <ShareInformationTopSection
        sumNominalCapital={sumNominalCapital}
        sumOfVotingRights={sumOfVotingRights}
      />
      <NominalSharesTable
        handleEdit={() => null}
        takenShares={takenShares}
        handleView={() => null}
      />
      <div className="mt-12">
        <SharesCategoryTabs
          categories={_uniq(shares.slice(0).map((row) => row.sharesCategory))}
        />
      </div>
    </div>
  );
};

export default ShareInformation;
