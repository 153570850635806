import axios from "../axios";

const verify = (data) => axios.post("/verify", data);

const profileAccessRequest = (data) =>
  axios.post("/verify/request-profile-data", data);

const validateEmailAddress = (email) =>
  axios.get(`/verify/validate-email-address?email=${email}`);

const verifyDataService = {
  verify,
  profileAccessRequest,
  validateEmailAddress,
};

export default verifyDataService;
