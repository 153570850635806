import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _set from "lodash/set";

import userDataService from "services/user";

export const getUserProfilesAsync = createAsyncThunk(
  "orgAdmin/getUserProfiles",
  async (queryParams, thunkAPI) => {
    try {
      const res = await userDataService.getAll(queryParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserAsync = createAsyncThunk(
  "orgAdmin/getUser",
  async ({ userId }, thunkAPI) => {
    try {
      const res = await userDataService.get(userId);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "orgAdmin/updateUser",
  async ({ userId, data }) => {
    const res = await userDataService.update(userId, data);
    return res.data;
  }
);

const initialState = {
  profiles: {
    items: [],
    totalCount: 0,
  },
  user: {},
  getUserProfiles: {
    status: "idle",
    error: false,
  },
  getUser: {
    status: "idle",
    error: false,
  },
};

const orgAdminSlice = createSlice({
  name: "orgAdminSlice",
  initialState,
  reducers: {
    updateOrgAdminUserNestedField: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
    reset: () => initialState,
    resetUser: (state) => {
      state.user = {};
    },
  },
  extraReducers: {
    [getUserProfilesAsync.pending]: (state) => {
      state.getUserProfiles.status = "loading";
      state.getUserProfiles.error = false;
    },
    [getUserProfilesAsync.fulfilled]: (state, action) => {
      state.getUserProfiles.status = "idle";
      state.getUserProfiles.error = false;
      state.profiles.items = action.payload.items;
      state.profiles.totalCount = action.payload.totalCount;
    },
    [getUserProfilesAsync.rejected]: (state, action) => {
      state.getUserProfiles.status = "failed";
      state.getUserProfiles.error = action.payload.messageText;
    },

    [getUserAsync.pending]: (state) => {
      state.getUser.status = "loading";
      state.getUser.error = false;
    },
    [getUserAsync.fulfilled]: (state, action) => {
      state.getUser.status = "idle";
      state.getUser.error = false;
      state.user = action.payload;
    },
    [getUserAsync.rejected]: (state, action) => {
      state.getUser.status = "failed";
      state.getUser.error = action.payload.messageText;
    },
  },
});

export const { reset, resetUser, updateOrgAdminUserNestedField } =
  orgAdminSlice.actions;

const { reducer } = orgAdminSlice;

export default reducer;
