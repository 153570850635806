export const getQueryString = (queryParams = {}) => {
  const searchParams = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (typeof value !== "undefined") {
      if (Array.isArray(value)) {
        value.forEach((v) => searchParams.append(key, v));
      } else {
        searchParams.set(key, value);
      }
    }
  });

  const queryString = searchParams.toString();
  return queryString;
};
