import { useEffect } from "react";
import { Constants } from "config/constants";
import { setCookie } from "utils/cookie";

const CookieCleanup = () => {
  useEffect(() => {
    const cleanup = () => {
      setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
    };

    window.addEventListener("beforeunload", cleanup);

    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  }, []);

  return null;
};

export default CookieCleanup;
