import AvatarIcon from "components/lib/Shared/Icons/AvatarIcon";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import { getFullName } from "utils";

function Header({ names, idType, fileUploads, createdAt, status }) {
  const history = useHistory();
  return (
    <>
      <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl ">
        <div className="flex items-center space-x-5">
          <div className="flex items-center flex-shrink-0 space-x-4">
            <div className="relative group">
              {fileUploads?.passportPhoto ? (
                <img
                  className="w-16 h-16 rounded-full"
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${fileUploads.passportPhoto}`}
                  alt="profile pic"
                />
              ) : (
                <div className="w-16 h-16 rounded-full">
                  <div className="relative w-16 h-16 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <AvatarIcon className="absolute text-gray-400 -translate-x-1/2 -translate-y-1/2 w-14 h-14 top-2/4 left-2/4" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">
              {`${getFullName(names) || "N/A"}`}
            </h1>
            {idType && (
              <p className="text-sm font-medium text-gray-500">
                Applied as a{" "}
                <a href="#" className="text-gray-900">
                  {idType}
                </a>{" "}
                on{" "}
                <time dateTime="2020-08-25">
                  {createdAt ? format(new Date(createdAt), "PP") : "N/A"}
                </time>
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col-reverse mt-6 space-y-4 space-y-reverse justify-stretch sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <button
            type="button"
            disabled={status === "Verified"}
            onClick={() => history.push("/profile/edit")}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500"
          >
            <svg
              className="w-5 h-5 mr-2 -ml-1 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            Edit
          </button>
          <p className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sflPrimary hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
            {status !== "Verified" ? "Status: Awaiting review" : status}
          </p>
        </div>
      </div>
      <div className="py-5 ">
        <hr />
      </div>
    </>
  );
}

export default Header;
