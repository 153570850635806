import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { XIcon } from "@heroicons/react/outline";
import { format } from "date-fns";
import _groupBy from "lodash/groupBy";
import _forOwn from "lodash/forOwn";

import Table from "components/lib/Global/ReactTable";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import Button from "components/lib/Shared/Button";
import {
  getAccessRequestComments,
  resetAccessRequestCommentsSlice,
} from "state/slices/accessRequestComment";
import { capitalizeFirstLetter, getFullName } from "utils";
import SubRowAsync from "./SubRowAsync";
import { editAccessRequest } from "state/slices/accessRequest";
import { closeModal } from "state/slices/modals";
import { useToast } from "hooks/useToast";

export default function RequestsTable({ requests }) {
  const [expandedRow, setExpandedRow] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { toast } = useToast(5000);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (
      expandedRow?.original?._id &&
      currentUser?._id &&
      expandedRow?.original?.status === "Pending"
    ) {
      dispatch(
        getAccessRequestComments({
          companyId: expandedRow?.original?.company?._id,
          organizationId: expandedRow?.original?.organization?._id,
          accessRequest: expandedRow?.original?._id,
        })
      );
    }

    return () => {
      dispatch(resetAccessRequestCommentsSlice());
    };
  }, [expandedRow, currentUser?._id]);

  const columns = useMemo(
    () => [
      {
        Header: "Initiated by",
        id: "initiatedBy",
        accessor: "initiatedBy",
        Cell: ({ row: { original } }) => {
          if (original?.createdBy?._id === currentUser?._id)
            return `${capitalizeFirstLetter(
              currentUser.names.firstName
            )} (You)`;
          const { names } = original?.createdBy ?? {};
          if (!names) return "N/A";
          return getFullName(names);
        },
      },
      {
        Header: "Role",
        id: "role",
        accessor: "role",
        Cell: ({ row: { original } }) => original?.role ?? "N/A",
      },
      {
        Header: "From",
        id: "fromDate",
        accessor: "fromDate",
        Cell: ({ row: { original } }) =>
          original?.startDate
            ? format(new Date(original.startDate), "dd-MMM-yyyy")
            : "",
      },
      {
        Header: "To",
        id: "toDate",
        accessor: "toDate",
        Cell: ({ row: { original } }) =>
          original?.endDate
            ? format(new Date(original.endDate), "dd-MMM-yyyy")
            : "",
      },
      {
        Header: "Status",
        id: "status",
        accessor: "status",
        Cell: ({
          row: {
            original: { status },
          },
        }) => status || "N/A",
      },
      {
        // Make an expander cell
        Header: () => "Actions", // No header
        id: "actions", // It needs an ID
        Cell: ({ row }) => {
          const onClick = () => {
            setExpandedRow(row);
            row.getToggleRowExpandedProps().onClick();
          };

          return (
            <button
              {...row.getToggleRowExpandedProps()}
              onClick={onClick}
              className="flex items-center w-full px-2 text-sm text-gray-900 rounded-md group disabled:cursor-not-allowed"
            >
              {row.isExpanded ? (
                <XIcon className="w-5 h-5" aria-hidden="true" />
              ) : (
                "View"
              )}
            </button>
          );
        },
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },
    ],
    [expandedRow, setExpandedRow]
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        setSelectedRow={setSelectedRow}
      />
    ),
    []
  );

  const handleRevokeAccess = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        editAccessRequest({
          accessRequestsId: selectedRow?._id,
          data: {
            status: "Revoked",
            revokedAt: new Date(),
            revokedBy: userSlice?._id,
          },
        })
      );
      toast("success", "Access revoked successfully.");
      dispatch(closeModal("revoke_request_confirmation_modal"));
      expandedRow?.toggleRowExpanded();
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const initiatedByName = getFullName(selectedRow?.createdBy?.names);

  return (
    <>
      <ModalWrapper
        name="revoke_request_confirmation_modal"
        title="Confirm Revoke Access"
        maxWidth="sm:max-w-fit"
      >
        <div className="my-8 text-lg">
          You are about to revoke access to {initiatedByName}. By clicking "I
          confirm" you will be revoking access. <br />
          However, if you have clicked the deny button by mistake, click
          "cancel" the deny process.
        </div>
        <hr />
        <div className="flex justify-between mt-4">
          <Button
            disabled={isLoading}
            onClick={() =>
              dispatch(closeModal("revoke_request_confirmation_modal"))
            }
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            onClick={handleRevokeAccess}
            loadingText="Confirming..."
          >
            I confirm
          </Button>
        </div>
      </ModalWrapper>
      <div className="w-full mt-4 overflow-x-auto">
        <Table
          columns={columns}
          data={requests}
          renderRowSubComponent={renderRowSubComponent}
          headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
          headingStyle="pl-4 text-left"
          dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          dataRowCellStyle="pl-4 font-normal"
        />
      </div>
    </>
  );
}
