import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/solid";
import { Formik, Field, Form, useFormikContext } from "formik";
import { useDebounce } from "use-debounce";
import _merge from "lodash/merge";
import _isEmpty from "lodash/isEmpty";
import ValidationError from "../ValidationError";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import {
  resetVerifyStatus,
  verify,
  verifyForOrganization,
} from "state/slices/verifyInvite";
import { useToast } from "hooks/useToast";
import { ExclamationIcon } from "@heroicons/react/outline";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";
import { verifyBtnParams } from "./verifyBtnParams";
import Button from "../Button";
import { getFormFields } from "utils";

const Verify = ({
  idType = "Kenyan Citizen",
  designation = "Director",
  tabName = "companyOfficials",
  autoComplete = "off",
  verificationValue,
  isIdAssociated,
  JSGroupID,
  isAccessAllowed = false,
  isAccessRevoked = false,
  beneficialOwner,
  setShowIdentificationSection = () => null,
  setIsIdAssociated = () => null,
  setVerifyingValue = () => null,
  setFieldValue = () => null,
  setDisableForm = () => null,
  resetForm = () => null,
  handleNewUserWorkflow = () => null,
  setIsAccessAllowed = () => null,
  setIsAccessRevoked = () => null,
  setInviteExistingUser = () => null,
  setTabsClickable = () => null,
}) => {
  const {
    name,
    label,
    placeholder,
    type = "string",
    validationSchema = null,
  } = verifyBtnParams[designation][idType];

  const isFirstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const { corporateId, companyId } = useParams();
  const formikProps = useFormikContext();
  const { verifyStatus } = useSelector((state) => state.verifyInviteSlice);
  const { organization } = useSelector((state) => state.organizationsSlice);
  const { company } = useSelector((state) => state.companySlice);
  const dispatch = useDispatch();
  const { toast } = useToast(5000);

  const populateFormData = async (official) => {
    // official can be a user or a corporate document
    await Promise.all([
      setFieldValue("type", "invited"),
      setFieldValue("names", official?.names ?? {}),
      setFieldValue(
        "identificationDetails",
        official?.identificationDetails ?? {}
      ),
      setFieldValue("postalAddress", official?.postalAddress ?? {}),
      setFieldValue("electronicAddress", official?.electronicAddress ?? {}),
      setFieldValue(
        "residentialAddressDetails",
        official?.residentialAddressDetails ?? {}
      ),
      setFieldValue(
        "fileUploads",
        _merge(official?.fileUploads ?? {}, formikProps.values.fileUploads)
      ),
      setFieldValue("initiatedTo", official?._id),
      setFieldValue("hasAccess", true),
      setFieldValue("primarySource", official.primarySource),
      setFieldValue("resolvedFields", official?.resolvedFields ?? []),
    ]);
    if (official?.isNonResident) {
      setFieldValue("isNonResident", official.isNonResident);
    }

    // set initial values for short form individuals to enable validation
    if (!official.isReportable && _isEmpty(official.electronicAddress)) {
      setFieldValue("enableLongForm", true);
      if (_isEmpty(official.postalAddress)) {
        getFormFields({
          idType: official?.idType,
          formType: "postalAddress",
          formValues: official?.postalAddress,
        }).map(({ name }) => {
          setFieldValue(`postalAddress.${name}`, "");
        });
      }
      if (_isEmpty(official.electronicAddress)) {
        getFormFields({
          idType: official?.idType,
          formType: "electronicAddress",
          formValues: official?.electronicAddress,
        }).map(({ name }) => {
          setFieldValue(`electronicAddress.${name}`, "");
        });
      }
      if (_isEmpty(official.residentialAddressDetails)) {
        getFormFields({
          idType:
            official?.isNonResident && official?.isNonResident === "Yes"
              ? "Foreigner"
              : official?.idType,
          formType: "residentialAddressDetails",
          formValues: official?.residentialAddressDetails,
        }).map(({ name }) => {
          setFieldValue(`residentialAddressDetails.${name}`, "");
        });
      }
    }
  };

  const getVerifyResponse = async () => {
    if (companyId) {
      return dispatch(
        verify({
          data: {
            companyId: company?._id,
            verificationKey: name,
            verificationValue:
              typeof verificationValue === "string"
                ? verificationValue?.trim()
                : verificationValue,
            idType,
            designation,
            tabName,
            for: "company",
            beneficialOwner,
            JSGroupID,
          },
        })
      );
    } else if (corporateId) {
      return dispatch(
        verifyForOrganization({
          data: {
            organizationId: organization?._id,
            verificationKey: name,
            verificationValue:
              typeof verificationValue === "string"
                ? verificationValue?.trim()
                : verificationValue,
            idType,
            for: "organization",
          },
        })
      );
    }
  };

  const handleVerifyResponse = async (response) => {
    if (response?.payload?.message === "User exists") {
      setShowIdentificationSection(false);
      setFieldValue("initiatedToCompany", response.payload?.initiatedToCompany);
      setFieldValue("userId", response.payload?.userId);
      setFieldValue("applicant", response.payload?.applicant);
      setFieldValue("registrar", response.payload?.registrar);
      setFieldValue("individual", response.payload?.individual);
      setFieldValue("beneficialOwner", response.payload?.beneficialOwner);
      setFieldValue("userEmailAddress", response.payload.userEmail);
      if (name === "companyRegNumber") {
        setFieldValue(
          `names.${name}`,
          typeof verificationValue === "string"
            ? verificationValue?.trim()
            : verificationValue ?? {}
        );
      } else {
        setFieldValue(
          `identificationDetails.${name}`,
          typeof verificationValue === "string"
            ? verificationValue?.trim()
            : verificationValue ?? {}
        );
      }
    }
    if (response?.payload?.sameLinkedToUser) {
      toast(
        "error",
        response?.payload?.message ?? "Something went wrong please try again."
      );
      return;
    }
    if (response?.payload?.accessDenied) {
      toast(
        "error",
        response?.payload?.message ?? "Something went wrong please try again."
      );
      return;
    }
    if (response?.payload?.inviteAlreadySent) {
      toast(
        "error",
        response?.payload?.message ?? "Something went wrong please try again."
      );
      return;
    }
    if (response?.payload?.accessRevoked) {
      setIsAccessRevoked(true);
      setFieldValue("userId", response.payload?.userId);
      setFieldValue("applicant", response.payload?.applicant);
      setFieldValue("registrar", response.payload?.registrar);
      setFieldValue("individual", response.payload?.individual);
      setFieldValue("beneficialOwner", response.payload?.beneficialOwner);
      setFieldValue("initiatedToCompany", response.payload?.initiatedToCompany);
      setFieldValue("userEmailAddress", response.payload.userEmail);
      if (name === "companyRegNumber") {
        setFieldValue(
          `names.${name}`,
          typeof verificationValue === "string"
            ? verificationValue?.trim()
            : verificationValue ?? {}
        );
      } else {
        setFieldValue(
          `identificationDetails.${name}`,
          typeof verificationValue === "string"
            ? verificationValue?.trim()
            : verificationValue ?? {}
        );
      }
      return;
    }
    if (response?.payload?.officialAlreadyExists) {
      toast("error", response?.payload?.message);
      return;
    }
    if (response?.payload?.status) {
      setIsIdAssociated(true);
      setIsVerified(true);
      if (response.payload.populateData) {
        await populateFormData(response.payload?.official ?? {});
        setShowIdentificationSection(true);
        setIsAccessAllowed(true);
        setTabsClickable(true);
        setFieldValue(
          "recordAccessRequest",
          response?.payload?.accessRequest?._id
        );
        // For co-applicants and employees with partial data
        if (response.payload.official?.isIncompleteProfile) {
          setFieldValue("isIncompleteProfile", true);
          setTabsClickable(false);
        }
      }
    } else {
      // official does not exists
      setDisableForm(false);
      setIsIdAssociated(false);
      if (name === "companyRegNumber") {
        setFieldValue(
          `names.${name}`,
          typeof verificationValue === "string"
            ? verificationValue?.trim()
            : verificationValue ?? {}
        );
      } else {
        setFieldValue(
          `identificationDetails.${name}`,
          typeof verificationValue === "string"
            ? verificationValue?.trim()
            : verificationValue ?? {}
        );
      }
    }
  };

  const handleVerify = async () => {
    if (!company?._id && !organization?._id && !companyId) {
      toast(
        "error",
        "Something went wrong please reload the page and try again."
      );
      return;
    }
    if (isVerified) {
      setInviteExistingUser(true);
      return;
    }
    setIsLoading(true);
    resetForm();
    try {
      const response = await getVerifyResponse();
      await handleVerifyResponse(response);
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetVerification = () => {
    setShowIdentificationSection(true);
    setInviteExistingUser(false);
    setIsIdAssociated(null);
    setDisableForm(true);
    setIsAccessAllowed(false);
    setIsAccessRevoked(false);
    setIsVerified(false);
    resetForm();
  };

  useEffect(() => {
    return () => {
      dispatch(resetVerifyStatus());
    };
  }, []);

  return (
    <div>
      <div>
        <Formik
          enableReinitialize
          initialValues={{ [name]: verificationValue }}
          validationSchema={validationSchema}
          onSubmit={handleVerify}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            ...verificationFormProps
          }) => {
            useEffect(() => {
              const value =
                type === "number" ? parseInt(values[name]) : values[name];
              setVerifyingValue(value);
            }, [values[name], type]);

            const handleKeyDown = (e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                handleSubmit();
              }
            };

            const handleInputChange = (e) => {
              dispatch(resetVerifyStatus());
              verificationFormProps.setFieldValue(name, e.target.value);
            };

            const [debouncedVerificationValue] = useDebounce(
              values[name],
              1000
            );

            // verify verificationValue asynchronously
            useEffect(() => {
              if (isFirstRender.current) {
                isFirstRender.current = false;
                return;
              }
              const verifyDebouncedValue = async () => {
                const isValid = await validationSchema.isValid({
                  [name]: debouncedVerificationValue,
                });
                if (isValid) {
                  handleVerify();
                }
              };
              if (!verifyStatus.loaded) verifyDebouncedValue();
            }, [debouncedVerificationValue]);

            return (
              <Form onKeyDown={handleKeyDown}>
                <div className="col-span-6 sm:col-span-3 ">
                  <label
                    htmlFor={`${name}_verify_btn`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {label}
                  </label>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <Field
                      type={type}
                      name={name}
                      id={`${name}_verify_btn`}
                      autoComplete={autoComplete}
                      className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                      placeholder={placeholder}
                      disabled={
                        isVerified ||
                        isAccessAllowed ||
                        isIdAssociated === false ||
                        isAccessRevoked ||
                        verifyStatus.status === "loading"
                      }
                      onChange={handleInputChange}
                    />
                    {verifyStatus.status === "loading" && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <SpinnerIcon className="text-gray-400" />
                      </div>
                    )}
                    {isIdAssociated !== null && debouncedVerificationValue && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 z-[29]">
                        <button type="button" onClick={handleResetVerification}>
                          <PencilIcon className="w-4 h-4 cursor-pointer text-light-blue" />
                        </button>
                      </div>
                    )}
                  </div>
                  <ValidationError
                    errors={errors}
                    touched={{ ...touched, [name]: true }}
                    name={name}
                  />
                </div>

                <div className="flex justify-between my-4">
                  {!isAccessAllowed && (
                    <Button
                      disabled={
                        !(typeof values[name] === "string"
                          ? values[name]?.trim()
                          : values[name]) ||
                        isLoading ||
                        isIdAssociated === false ||
                        isAccessRevoked ||
                        verifyStatus.status === "loading"
                      }
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                      loadingText="Verifying..."
                    >
                      {isVerified ? "Invite" : "Verify"}
                    </Button>
                  )}
                  {isIdAssociated === false && (
                    <Button
                      onClick={handleNewUserWorkflow}
                      isLoading={isSubmitting}
                      loadingText="Verifying..."
                    >
                      Invite
                    </Button>
                  )}
                  {isAccessRevoked && (
                    <Button
                      onClick={() => {
                        setInviteExistingUser(true);
                      }}
                    >
                      Invite
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
        {isIdAssociated === false && (
          <div className="flex flex-col my-4 space-y-2 items-between">
            <div className="text-red-500 flex items-start py-2">
              <ExclamationIcon className="w-5 h-5 mr-1" />
              <p className="block text-sm font-medium">
                We don't have such an ID in our system. Please initiate an
                invite or complete the form below.
              </p>
            </div>
          </div>
        )}
        {isAccessRevoked && (
          <div className="flex flex-col my-4 space-y-2 items-between">
            <div className="text-red-500 flex items-start py-2">
              <ExclamationIcon className="w-5 h-5 mr-1" />
              <p className="block text-sm font-medium">
                {CORPORATES.includes(idType) ? "Corporate" : "User"} exists in
                the system however their data can only be used once. Kindly
                initiate an invite to request reuse of their data.
              </p>
            </div>
          </div>
        )}
      </div>
      <hr />
    </div>
  );
};

export default Verify;
