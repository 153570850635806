import React from "react";

function FolderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#F3F4F6" rx="16"></rect>
      <g fill="#1F2937" clipPath="url(#clip0_70_21969)">
        <path d="M8.5 11l.04.87a1.99 1.99 0 00-.342 1.311l.637 7A2 2 0 0010.825 22H17v-1h-6.174a1 1 0 01-.995-.91l-.637-7A1 1 0 0110.19 12h11.62a.999.999 0 01.996 1.09L22.54 16h1.005l.256-2.819A2 2 0 0021.81 11h-3.982a2 2 0 01-1.414-.586l-.828-.828A2 2 0 0014.172 9H10.5a2 2 0 00-2 2zm5.672-1a1 1 0 01.707.293l.707.707H10.19c-.24 0-.47.042-.683.12l-.007-.14a1 1 0 011-.98h3.672z"></path>
        <path d="M23.854 18.146a.5.5 0 010 .708l-3 3a.5.5 0 01-.707 0l-1.5-1.5a.5.5 0 11.707-.708l1.146 1.147 2.646-2.647a.5.5 0 01.708 0z"></path>
      </g>
      <defs>
        <clipPath id="clip0_70_21969">
          <path fill="#fff" d="M0 0H16V16H0z" transform="translate(8 8)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default FolderIcon;
