import React from "react";
import { PlusIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import Button from "components/lib/Shared/Button";

function NoCompanies() {
  const history = useHistory();
  const currentPathname = history.location.pathname;

  if (currentPathname === "/corporate-directors-shareholders") {
    return (
      <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
        <svg
          className="w-12 h-12 mx-auto text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <span className="mt-2 block text-sm font-medium text-gray-900">
          No companies
        </span>
      </div>
    );
  }

  return (
    <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
      <svg
        className="w-12 h-12 mx-auto text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No companies</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new company.
      </p>
      <div className="mt-6">
        <Button
          onClick={() => history.push("/")}
          preIcon={PlusIcon}
          className=" text-white hover:text-white bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 focus:ring-indigo-500"
        >
          New Company
        </Button>
      </div>
    </div>
  );
}

export default NoCompanies;
