export const SignInPartial = () => {
  return (
    <div>
      <p className="">
        We are redirecting you to the{" "}
        <span className="py-1 font-medium border-b-2 text-sflPrimary">
          Sign In page.
        </span>
      </p>
    </div>
  );
};

export const SignUpPartial = () => {
  return (
    <div>
      <p className="">
        We are redirecting you to the{" "}
        <span className="py-1 font-medium border-b-2 text-sflPrimary">
          Sign up page
        </span>
      </p>
    </div>
  );
};
