/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useFormikContext } from "formik";

import Input from "components/lib/Shared/Input";

// Identification Details for Minor Guardian
const IdentificationDetailsFormGuardian = ({ field, form, disabled }) => {
  const { setFieldValue } = useFormikContext();
  const handleChange = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <h3 className="my-2 text-lg font-medium leading-6 text-gray-900">
        Guardian Information
      </h3>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Full Name"
            name="guardianFullName"
            placeholder="Enter guardian name"
            form={form}
            value={field.value?.guardianFullName ?? ""}
            errorPath="identificationDetails.guardianFullName"
            onBlur={field.onBlur}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Relationship"
            name="guardianRelationship"
            placeholder="Enter relationship"
            form={form}
            value={field.value?.guardianRelationship ?? ""}
            errorPath="identificationDetails.guardianRelationship"
            onBlur={field.onBlur}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Mobile Number"
            name="guardianMobileNumber"
            placeholder="Enter mobile number"
            form={form}
            value={field.value?.guardianMobileNumber ?? ""}
            errorPath="identificationDetails.guardianMobileNumber"
            onBlur={field.onBlur}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Email Address"
            name="guardianEmailAddress"
            placeholder="Enter email"
            form={form}
            type="email"
            value={field.value?.guardianEmailAddress ?? ""}
            errorPath="identificationDetails.guardianEmailAddress"
            onBlur={field.onBlur}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

export default IdentificationDetailsFormGuardian;
