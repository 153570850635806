import { Field } from "formik";

import Label from "components/lib/Shared/Label";

const Radio = (props) => {
  let { label, id, disabled } = props;

  return (
    <div className="flex items-center">
      <Field
        type="radio"
        className="disabled:opacity-40 disabled:cursor-not-allowed"
        {...props}
      />
      <Label htmlFor={id} className="pl-4" disabled={disabled}>
        {label}
      </Label>
    </div>
  );
};

export default Radio;
