import React, { useEffect } from "react";

import ValidationError from "components/lib/Shared/ValidationError";
import CommentsList from "components/lib/Global/CommentsList";

function NonRegulatedArticles({
  field,
  form: { values, setFieldValue, errors, touched },
  transformedComments,
}) {
  const handleChange = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  // for validation
  useEffect(() => {
    setFieldValue(field.name, {
      natureOfBusiness: "",
      ...field.value,
    });
  }, []);

  const natureOfBusinessComments =
    transformedComments?.["articlesOfAssociation"]?.natureOfBusiness ?? [];

  return (
    <div>
      <label
        htmlFor="natureOfBusiness"
        className="block text-sm font-medium text-gray-700"
      >
        <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
          Nature Of Business
          {natureOfBusinessComments.length > 0 && (
            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-2 -right-5">
              <span>{natureOfBusinessComments.length}</span>
            </span>
          )}
        </span>
      </label>
      <input
        type="text"
        id="natureOfBusiness"
        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
        name="natureOfBusiness"
        placeholder="Enter name of business"
        disabled={values.resolvedFields.includes("natureOfBusiness")}
        onChange={handleChange}
        value={field.value.natureOfBusiness}
      />
      {natureOfBusinessComments.length > 0 && (
        <CommentsList
          comments={natureOfBusinessComments}
          color={"text-red-500"}
        />
      )}
      <ValidationError
        errors={errors}
        touched={touched}
        name="articlesOfAssociation.natureOfBusiness"
      />
    </div>
  );
}

export default NonRegulatedArticles;
