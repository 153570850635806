import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import coApplicantService from "services/coApplicant";

export const getCoApplicantAsync = createAsyncThunk(
  "coApplicants/getCoApplicantAsync",
  async (queryParams, { rejectWithValue }) => {
    try {
      const res = await coApplicantService.getAll(queryParams);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCoApplicantAsync = createAsyncThunk(
  "coApplicants/addCoApplicantAsync",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await coApplicantService.create(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editCoApplicantAsync = createAsyncThunk(
  "coApplicants/editCoApplicantAsync",
  async ({ coApplicantId, data }, { rejectWithValue }) => {
    try {
      const res = await coApplicantService.update(coApplicantId, data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCoApplicantAsync = createAsyncThunk(
  "coApplicants/deleteCoApplicantAsync",
  async ({ coApplicantId }, { rejectWithValue }) => {
    try {
      const res = await coApplicantService.remove(coApplicantId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const acceptCoApplicantInviteAsync = createAsyncThunk(
  "coApplicants/acceptCoApplicantInviteAsync",
  async ({ token }) => {
    const res = await coApplicantService.acceptCoApplicantInvite(token);
    return res.data;
  }
);

export const sendAccessRequestForCoApplicantAsync = createAsyncThunk(
  "coApplicants/sendAccessRequestForCoApplicantAsync",
  async ({ data }) => {
    const res = await coApplicantService.sendAccessRequestForCoApplicant(data);
    return res.data;
  }
);

export const reinstateCoApplicantAsync = createAsyncThunk(
  "coApplicants/reinstateCoApplicantAsync",
  async ({ coApplicantId }, { rejectWithValue }) => {
    try {
      const res = await coApplicantService.reinstateCoApplicant(coApplicantId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resendCoApplicantInviteAsync = createAsyncThunk(
  "coApplicants/resendCoApplicantInviteAsync",
  async ({ coApplicantId }) => {
    const res = await coApplicantService.resendCoApplicantInvite(coApplicantId);
    return res.data;
  }
);

const initialState = {
  coApplicants: {
    items: [],
    totalCount: 0,
  },
  getCoApplicants: {
    status: "idle",
    error: false,
  },
  addCoApplicant: {
    status: "idle",
    error: false,
  },
};

const coApplicantsSlice = createSlice({
  name: "coApplicants",
  initialState,
  reducers: {
    resetCoApplicantsSlice: () => initialState,
  },
  extraReducers: {
    [getCoApplicantAsync.pending]: (state) => {
      state.getCoApplicants.status = "loading";
      state.getCoApplicants.error = false;
    },
    [getCoApplicantAsync.fulfilled]: (state, action) => {
      state.getCoApplicants.status = "idle";
      state.coApplicants = action.payload;
    },
    [getCoApplicantAsync.rejected]: (state, action) => {
      state.getCoApplicants.status = "failed";
      state.getCoApplicants.error = action?.payload?.messageText;
    },

    [addCoApplicantAsync.pending]: (state, action) => {
      state.addCoApplicant.status = "loading";
      state.addCoApplicant.error = false;
    },
    [addCoApplicantAsync.fulfilled]: (state, action) => {
      if (action.payload.coApplicant) {
        state.coApplicants.items.push(action.payload.coApplicant);
        state.coApplicants.totalCount += 1;
      }
      state.addCoApplicant.status = "idle";
    },
    [addCoApplicantAsync.rejected]: (state, action) => {
      state.addCoApplicant.status = "failed";
      state.addCoApplicant.error = action?.payload?.messageText;
    },

    [editCoApplicantAsync.fulfilled]: (state, action) => {
      const { payload } = action;
      const index = state.coApplicants.items.findIndex(
        (coApplicant) => payload._id === coApplicant._id
      );
      if (index > -1) {
        state.coApplicants.items.splice(index, 1, payload);
      }
    },

    [deleteCoApplicantAsync.fulfilled]: (state, action) => {
      const { payload } = action;
      const index = state.coApplicants.items.findIndex(
        (coApplicant) => payload._id === coApplicant._id
      );
      if (index > -1) {
        if (["Pending", "Opened", "Denied"].includes(payload.status)) {
          // had delete the co applicant
          state.coApplicants.items.splice(index, 1);
        } else {
          state.coApplicants.items.splice(index, 1, payload);
        }
      }
    },

    [reinstateCoApplicantAsync.fulfilled]: (state, action) => {
      const { payload } = action;
      const index = state.coApplicants.items.findIndex(
        (coApplicant) => payload._id === coApplicant._id
      );
      if (index > -1) {
        state.coApplicants.items.splice(index, 1, payload);
      }
    },

    [sendAccessRequestForCoApplicantAsync.fulfilled]: (state, action) => {
      if (action.payload.coApplicant) {
        state.coApplicants.items.push(action.payload.coApplicant);
        state.coApplicants.totalCount += 1;
      }
    },
  },
});

export const { resetCoApplicantsSlice } = coApplicantsSlice.actions;

export default coApplicantsSlice.reducer;
