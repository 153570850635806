/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const sharesTableSlice = createSlice({
  name: 'Share Allocation Table',
  initialState: { shares: [] },
  reducers: {
    addShares: (state, action) => {
      const id = uuidv4()
      const { payload } = action
      state.shares.push({
        id,
        ...payload,
      })
    },
    deleteShares: (state, { payload }) => {
      const sharesId = payload.id || payload._id
      const { shares } = state
      const index = shares.findIndex(item => sharesId === item.id || sharesId === item._id)
      if (index > -1) shares.splice(index, 1)
    },
    editShares: (state, { payload }) => {
      const sharesId = payload.id || payload._id
      const { shares } = state
      const index = shares.findIndex(item => sharesId === item.id || sharesId === item._id)
      if (index > -1) {
        shares.splice(index, 1, {
          ...payload,
        })
      }
    },
    setShares: (state, action) => {
      state.shares = action.payload
    },
    resetSharesTable: state => {
      state.shares = []
    }
  },
})

export const {
  addShares,
  deleteShares,
  editShares,
  setShares,
  resetSharesTable
} = sharesTableSlice.actions

export default sharesTableSlice.reducer
