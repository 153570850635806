import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { resetActiveDashboardSlice } from "state/slices/activeDashboard";
import DashboardWrapper from "./DashboardWrapper";
import { Constants } from "config/constants";
import { setCookie } from "utils/cookie";

function OrgAdminDashboardWrapper({ children }) {
  const { dashboardUrl, breadCrumbs } = useSelector(
    (state) => state.activeDashboardSlice
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const handleExitDashboard = () => {
    dispatch(resetActiveDashboardSlice());
    setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
    history.push(dashboardUrl);
  };

  return (
    <DashboardWrapper
      pages={breadCrumbs}
      showBreadCrumbs
      exitDashboardText="Exit back to Company Dashboard"
      handleExitDashboard={handleExitDashboard}
    >
      {children}
    </DashboardWrapper>
  );
}

export default OrgAdminDashboardWrapper;
