import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (params) =>
  axios.get(`/co-applicants?${getQueryString(params)}`);

const create = (data) => axios.post("/co-applicants", data);

const update = (coApplicantId, data) =>
  axios.put(`/co-applicants/${coApplicantId}`, data);

const remove = (coApplicantId) =>
  axios.delete(`/co-applicants/${coApplicantId}`);

const acceptCoApplicantInvite = (token) =>
  axios.post(`/co-applicants/accept-co-applicant-invite/${token}`);

const sendAccessRequestForCoApplicant = (data) =>
  axios.post("/co-applicants/send-access-request-for-co-applicant", data);

const reinstateCoApplicant = (coApplicantId) =>
  axios.put(`/co-applicants/${coApplicantId}/reinstate-co-applicant`);

const resendCoApplicantInvite = (coApplicantId) =>
  axios.post(`/co-applicants/${coApplicantId}/resend-co-applicant-invite`);

const coApplicantDataService = {
  getAll,
  create,
  update,
  remove,
  acceptCoApplicantInvite,
  sendAccessRequestForCoApplicant,
  reinstateCoApplicant,
  resendCoApplicantInvite,
};

export default coApplicantDataService;
