import _forEach from "lodash/forEach";
import _isPlainObject from "lodash/isPlainObject";
import _cloneDeep from "lodash/cloneDeep";
import _flatten from "lodash/flatten";
import _forOwn from "lodash/forOwn";
import _has from "lodash/has";
import _isEqual from "lodash/isEqual";
import _groupBy from "lodash/groupBy";
import _orderBy from "lodash/orderBy";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { format } from "date-fns";
import { Constants } from "config/constants";
import options from "components/PLCTABS/SFLdata/selectOptions";
import {
  CORPORATES,
  INDIVIDUALS,
} from "components/PLCTABS/SFLdata/config/constants";

export const flattenObject = (obj) => {
  let payload = {};
  // fatten object because of the many sub forms
  _forEach(obj, (value, key) => {
    if (_isPlainObject(value)) {
      _forEach(value, (val, k) => {
        let v;
        if (_isPlainObject(val)) {
          v = _cloneDeep(val);
        } else v = val;
        payload[k] = v;
      });
    } else payload[key] = value;
  });
  if (obj?._id) payload = { ...payload, _id: obj?._id };
  return payload;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Returns taken or allotted shares.
 *
 * @param {array} companyOfficials The array of company officials.
 * @return {object} The object containing classes of shares and their taken shares.
 * @example
 *  {
 *    'Ordinary Shares': 50,
 *    'Preference Shares': 70
 *  }
 */
export const getTakenShares = (companyOfficials) => {
  const takenShares = {};
  _flatten(
    companyOfficials.map(({ sharesAllotted }) => sharesAllotted)
  ).forEach((share) => {
    if (share) {
      const { classOfShares, allottedShares } = share;
      if (!takenShares[classOfShares])
        takenShares[classOfShares] = allottedShares;
      else takenShares[classOfShares] += allottedShares;
    }
  });
  return takenShares;
};

/**
 * Returns a rounded number.
 *
 * @param number The number to be rounded.
 * @param fractionDigits Number of digits after the decimal point.
 */
export const roundNumber = (number, fractionDigits = 2) => {
  return Number(Number(number).toFixed(fractionDigits));
};

const calculateSingleEntryCumulativeOwnership = (
  beneficialOwner,
  beneficialOwners,
  cumulativeShareholdings = 0,
  cumulativeVotings = 0,
  sharesArray = [],
  votingsArray = []
) => {
  // for direct officials coming from company official tab
  if (
    !beneficialOwner?.shareholderWithBO &&
    !beneficialOwner?.linkToCompany &&
    sharesArray.length === 0
  ) {
    if (
      [...INDIVIDUALS, "Joint Shareholder"].includes(beneficialOwner.idType)
    ) {
      return {
        cumulativeShareholdings: roundNumber(
          beneficialOwner.beneficialOwnershipForm?.directPercentShareholding ??
            0
        ),
        cumulativeVotings: roundNumber(
          beneficialOwner.beneficialOwnershipForm?.directPercentVotingRights ??
            0
        ),
      };
    } else {
      return {
        cumulativeShareholdings: roundNumber(
          beneficialOwner.beneficialOwnershipForm.indirectPercentShareholding
        ),
        cumulativeVotings: roundNumber(
          beneficialOwner.beneficialOwnershipForm.indirectPercentVotingRights
        ),
      };
    }
  }

  let parentBeneficialOwner = beneficialOwners.find(
    (bo) =>
      (beneficialOwner?.shareholderWithBO &&
        beneficialOwner.shareholderWithBO === bo._id) ||
      (beneficialOwner?.linkToCompany &&
        beneficialOwner.linkToCompany === bo._id)
  );

  if (!parentBeneficialOwner) {
    sharesArray.push(
      beneficialOwner?.beneficialOwnershipForm?.indirectPercentShareholding ?? 0
    );
    votingsArray.push(
      beneficialOwner?.beneficialOwnershipForm?.indirectPercentVotingRights ?? 0
    );

    return {
      cumulativeShareholdings: roundNumber(
        cumulativeShareholdings +
          sharesArray.reduce((a, v) => a * (v / 100), 1) * 100
      ),
      cumulativeVotings: roundNumber(
        cumulativeVotings +
          votingsArray.reduce((a, v) => a * (v / 100), 1) * 100
      ),
    };
  }

  // current BO is individual and its parent is corporate
  if (
    [...INDIVIDUALS, "Joint Shareholder"].includes(beneficialOwner.idType) &&
    CORPORATES.includes(parentBeneficialOwner.idType)
  ) {
    sharesArray.push(
      beneficialOwner.beneficialOwnershipForm?.directPercentShareholding ?? 0
    );
    votingsArray.push(
      beneficialOwner.beneficialOwnershipForm?.directPercentVotingRights ?? 0
    );
  }

  // current BO is corporate
  if (CORPORATES.includes(beneficialOwner.idType)) {
    sharesArray.push(
      beneficialOwner.beneficialOwnershipForm?.indirectPercentShareholding ?? 0
    );
    votingsArray.push(
      beneficialOwner.beneficialOwnershipForm?.indirectPercentVotingRights ?? 0
    );
  }

  // if current BO & its parent are individual
  if (
    [...INDIVIDUALS, "Joint Shareholder"].includes(beneficialOwner.idType) &&
    [...INDIVIDUALS, "Joint Shareholder"].includes(parentBeneficialOwner.idType)
  ) {
    // skip first level individual parent and calculate ownership against next parent
    const tempParentBeneficialOwner = beneficialOwners.find(
      (bo) =>
        (parentBeneficialOwner?.shareholderWithBO &&
          parentBeneficialOwner.shareholderWithBO === bo._id) ||
        (parentBeneficialOwner?.linkToCompany &&
          parentBeneficialOwner.linkToCompany === bo._id)
    );

    if (tempParentBeneficialOwner) {
      sharesArray.push(
        beneficialOwner.beneficialOwnershipForm?.indirectPercentShareholding ??
          0
      );
      votingsArray.push(
        beneficialOwner.beneficialOwnershipForm?.indirectPercentVotingRights ??
          0
      );
      parentBeneficialOwner = tempParentBeneficialOwner;
    } else {
      return {
        cumulativeShareholdings: roundNumber(
          beneficialOwner.beneficialOwnershipForm
            ?.indirectPercentShareholding ?? 0
        ),
        cumulativeVotings: roundNumber(
          beneficialOwner.beneficialOwnershipForm
            ?.indirectPercentVotingRights ?? 0
        ),
      };
    }
  }

  return calculateSingleEntryCumulativeOwnership(
    parentBeneficialOwner,
    beneficialOwners,
    cumulativeShareholdings,
    cumulativeVotings,
    sharesArray,
    votingsArray
  );
};

export const calculateCumulativeOwnership = (
  beneficialOwner,
  beneficialOwners
) => {
  let cumulativeShareholdings = 0;
  let cumulativeVotings = 0;

  if (!beneficialOwner.children || beneficialOwner.children.length === 1) {
    return calculateSingleEntryCumulativeOwnership(
      beneficialOwner,
      beneficialOwners
    );
  }

  beneficialOwner.children.map((groupedBo) => {
    const { linkToCompany, shareholderWithBO, JSCombinedName } = groupedBo;
    // add the direct values of the shares and voting rights for the direct instance coming from CO table
    if (!linkToCompany && !shareholderWithBO && !JSCombinedName) {
      cumulativeShareholdings +=
        groupedBo.beneficialOwnershipForm?.directPercentShareholding ?? 0;
      cumulativeVotings +=
        groupedBo.beneficialOwnershipForm?.directPercentVotingRights ?? 0;
    } else {
      const { cumulativeShareholdings: temp1, cumulativeVotings: temp2 } =
        calculateSingleEntryCumulativeOwnership(groupedBo, beneficialOwners);
      cumulativeShareholdings += temp1;
      cumulativeVotings += temp2;
    }
  });

  return {
    cumulativeShareholdings: roundNumber(cumulativeShareholdings),
    cumulativeVotings: roundNumber(cumulativeVotings),
  };
};

const calculateOwnershipSummary = (beneficialOwner, beneficialOwners) => {
  if (!beneficialOwner.children || beneficialOwner.children.length < 2)
    return beneficialOwner?.beneficialOwnershipForm ?? {};

  let beneficialOwnershipForm = {
    directPercentShareholding: 0,
    indirectPercentShareholding: 0,
    directPercentVotingRights: 0,
    indirectPercentVotingRights: 0,
    directRightRemoveDirector: "None",
    indirectRightRemoveDirector: "None",
    directCompanyControlRight: "None",
    indirectCompanyControlRight: "None",
  };

  beneficialOwner.children.map((groupedBo) => {
    if (!_has(groupedBo, "beneficialOwnershipForm")) return;
    const { linkToCompany, shareholderWithBO, JSCombinedName } = groupedBo;
    // add the direct values of the shares and voting rights
    if (!linkToCompany && !shareholderWithBO && !JSCombinedName) {
      beneficialOwnershipForm.directPercentShareholding = roundNumber(
        beneficialOwnershipForm.directPercentShareholding +
          groupedBo.beneficialOwnershipForm?.directPercentShareholding ?? 0
      );

      beneficialOwnershipForm.directPercentVotingRights = roundNumber(
        beneficialOwnershipForm.directPercentVotingRights +
          groupedBo.beneficialOwnershipForm?.directPercentVotingRights ?? 0
      );
      // Add "No" for any of the entry contain "no" for the following values
      if (groupedBo.beneficialOwnershipForm?.directRightRemoveDirector === "no")
        beneficialOwnershipForm.directRightRemoveDirector = "no";
      if (
        groupedBo.beneficialOwnershipForm?.indirectRightRemoveDirector === "no"
      )
        beneficialOwnershipForm.indirectRightRemoveDirector = "no";
      if (groupedBo.beneficialOwnershipForm?.directCompanyControlRight === "no")
        beneficialOwnershipForm.directCompanyControlRight = "no";
      if (
        groupedBo.beneficialOwnershipForm?.indirectCompanyControlRight === "no"
      )
        beneficialOwnershipForm.indirectCompanyControlRight = "no";
    } else {
      const { cumulativeShareholdings, cumulativeVotings } =
        calculateSingleEntryCumulativeOwnership(groupedBo, beneficialOwners);
      beneficialOwnershipForm.indirectPercentShareholding = roundNumber(
        beneficialOwnershipForm.indirectPercentShareholding +
          cumulativeShareholdings
      );
      beneficialOwnershipForm.indirectPercentVotingRights = roundNumber(
        beneficialOwnershipForm.indirectPercentVotingRights + cumulativeVotings
      );

      // Add "No" for any of the entry contains "no" for the following values
      if (groupedBo.beneficialOwnershipForm?.directRightRemoveDirector === "no")
        beneficialOwnershipForm.directRightRemoveDirector = "no";
      if (
        groupedBo.beneficialOwnershipForm?.indirectRightRemoveDirector === "no"
      )
        beneficialOwnershipForm.indirectRightRemoveDirector = "no";
      if (groupedBo.beneficialOwnershipForm?.directCompanyControlRight === "no")
        beneficialOwnershipForm.directCompanyControlRight = "no";
      if (
        groupedBo.beneficialOwnershipForm?.indirectCompanyControlRight === "no"
      )
        beneficialOwnershipForm.indirectCompanyControlRight = "no";
    }
  });
  return beneficialOwnershipForm;
};

export const groupBeneficialOwners = (data) => {
  const verifyKeys = [
    "identificationDetails.nationalIDNumber",
    "identificationDetails.foreignCertificateID",
    "identificationDetails.passportNumber",
    "identificationDetails.birthCertificateNumber",
    "names.companyRegNumber",
  ];

  const groupedTableData = [];

  verifyKeys.map((verifyKey) => {
    const dataAgainstKey = data.filter((row) => _get(row, verifyKey));
    const groupedDataByVerifyKey = _groupBy(dataAgainstKey, (row) =>
      _get(row, verifyKey)
    );
    _forOwn(groupedDataByVerifyKey, (value, verifyValue) => {
      groupedTableData.push({
        ...data.find((row) => {
          return _get(row, verifyKey) === verifyValue;
        }),
        children: _orderBy(
          value,
          ["shareholderWithBO", "linkToCompany"],
          ["desc", "desc"]
        ),
      });
    });
  });

  return groupedTableData;
};

/**
 * Returns `Combined names`.
 *
 * @param array Array of names.
 */
export const getCombinedNames = (names) => {
  const fullName = names.map((name, index) => {
    if (names.length === 2 && index === 0) return `${name} & `;
    if (names.length > 2 && index === names.length - 2) return `${name} & `;
    if (
      names.length > 2 &&
      index !== names.length - 2 &&
      index !== names.length - 1
    )
      return `${name}, `;
    return name;
  });
  return fullName.join("");
};

/**
 * Returns `Full name`.
 *
 * @param object Object of names.
 */
export const getFullName = (names = {}) => {
  let fullName = "";
  if (names?.companyName) return names.companyName;
  if (names?.fullName) return names.fullName;
  if (names?.firstName) fullName += names.firstName;
  if (names?.middleName) fullName += ` ${names.middleName}`;
  if (names?.lastName) fullName += ` ${names.lastName}`;
  return fullName.trim();
};

/**
 * `Removes empty objects`.
 *
 * @param {Object} Original object
 * @return {object} Modified object.
 */
export const clearEmpties = (originalObject) => {
  let tempObject = { ...originalObject };
  if (typeof tempObject !== "object") return tempObject;
  for (var k in tempObject) {
    if (!tempObject[k] || typeof tempObject[k] !== "object") {
      continue; // If null or not an object, skip to the next iteration
    }

    // The property is an object
    if (Object.values(tempObject[k]).filter(Boolean).length === 0) {
      delete tempObject[k]; // The object had no properties, or all the properties were undefiend so delete that property
    }
    clearEmpties(tempObject[k]);
  }
  return tempObject;
};

/**
 * This returns only the updated form object
 * by comparing the old form values to the new ones.
 *
 * @param {string[]} formFields The array of strings which represents the form fields `"names"`.
 * @param {object} oldValues The new form values..
 * @param {object} newValues The old form values.
 * @return {object} This returns object with updated form values.
 */
export const getUpdatedFormData = (formFields, oldValues, newValues) => {
  const updatedFormData = {};
  formFields.map((formField) => {
    if (!_isEqual(oldValues[formField], newValues[formField])) {
      updatedFormData[formField] = newValues[formField];
    }
  });
  return updatedFormData;
};

/**
 * This returns promise that resolve in some milliseconds
 *
 * @param {number} ms milliseconds to wait for the promise to resolve.
 * @return {object} This returns promise that resolve in the provided milliseconds.
 */
export const delay = (ms = 1000) =>
  new Promise((resolve) => setTimeout(() => resolve(), ms));

/**
 * This returns greetings depending on time of day
 *
 * @return {string} This returns greetings like `Good Morning`.
 */
export const getGreetings = () => {
  let greet = "";
  const hr = new Date().getHours();

  if (hr < 12) {
    greet = "Good Morning";
  } else if (hr >= 12 && hr <= 17) {
    greet = "Good Afternoon";
  } else if (hr >= 17 && hr <= 24) greet = "Good Evening";
  return greet;
};

export const capitalizeFirstLetter = (string = "") => {
  if (!string.trim()) return;
  return `${string.slice(0, 1).toLocaleUpperCase()}${string.slice(1)}`;
};

export const replaceAll = (str, mapObj) => {
  const regex = new RegExp(Object.keys(mapObj).join("|"), "gi");
  return str.replace(regex, (matched) => mapObj[matched.toLowerCase()]);
};

/**
 * This returns array of objects containing key, value & name based on id type, form type and form Values.
 * It also format date fields and return label for country value.
 *
 * @param {object} object of idType, formType & formValues
 * @return {array} This returns an array of objects.
 */
export const getFormFields = ({ idType, formType, formValues = {} }) => {
  if (!idType) return null;
  return Constants.FORM_FIELDS?.[idType]?.[formType]?.map((formField) => {
    let value = (formValues?.[formField.key] ?? "N/A") || "N/A";
    if (formField.isDate && value && value !== "N/A") {
      value = format(new Date(value), "yyyy/MM/dd");
    }

    if (formField.isCountry) {
      value = options.countries.find(
        (country) => country.value === value
      )?.label;
    }

    return {
      ...formField,
      key: formField?.label,
      value: value,
      name: formField.key,
    };
  });
};

export const getTransformedFormFields = ({
  idType,
  formType,
  formValues,
  formatDOB = true,
}) => {
  if (!idType) return null;
  return (
    Constants.FORM_FIELDS?.[idType]?.[formType]
      ?.map((formField) => {
        let value = formValues?.[formField.key];
        if (!value) return;
        if (formField.isDate && value && value !== "N/A" && formatDOB) {
          value = format(new Date(value), "yyyy/MM/dd");
        }

        if (formField.isCountry) {
          value = options.countries.find(
            (country) => country.value === value
          )?.label;
        }

        return {
          key: formField?.label,
          value: value,
          name: formField.key,
        };
      })
      .filter(Boolean) || []
  );
};

export const getBeneficialOwnershipFormFields = ({
  beneficialOwnershipForm = {},
  reviewTab = false,
  renderShortTypeOfControl = false,
  renderDateOfCeasingToBeABO,
  renderDateOfBecomingToBeABO,
  onlyDates,
  sourceOfBO,
  beneficialOwner = {},
  beneficialOwners = [],
}) => {
  const transformYesAndNo = (value) =>
    value === "yes" ? "Yes" : value === "no" ? "No" : "N/A";

  beneficialOwnershipForm =
    calculateOwnershipSummary(beneficialOwner, beneficialOwners) || {};

  const dateOfBecomingBo = _get(beneficialOwnershipForm, "dateOfBecomingBO");
  const dateOfCeasingToBeABO = _get(
    beneficialOwnershipForm,
    "dateOfCeasingToBeABO"
  );

  const directRightRemoveDirector = transformYesAndNo(
    _get(beneficialOwnershipForm, "directRightRemoveDirector") || "N/A"
  );
  const indirectRightRemoveDirector = transformYesAndNo(
    _get(beneficialOwnershipForm, "indirectRightRemoveDirector") || "N/A"
  );
  const directCompanyControlRight = transformYesAndNo(
    _get(beneficialOwnershipForm, "directCompanyControlRight") || "N/A"
  );
  const indirectCompanyControlRight = transformYesAndNo(
    _get(beneficialOwnershipForm, "indirectCompanyControlRight") || "N/A"
  );
  let sourceOfBOInformation =
    _get(beneficialOwnershipForm, "sourceOfBOInformation") || "N/A";

  sourceOfBOInformation =
    Constants.SOURCE_OF_BO_INFO?.[sourceOfBOInformation] ??
    sourceOfBOInformation;

  if (reviewTab && onlyDates) {
    return [
      {
        key: "Date of becoming BO",
        name: "dateOfBecomingBO",
        value: dateOfBecomingBo
          ? format(new Date(dateOfBecomingBo), "yyyy-MM-dd")
          : "N/A",
      },
      {
        key: `Date of Ceasing to be a BO`,
        name: `dateOfCeasingToBeABO`,
        value: dateOfCeasingToBeABO
          ? format(new Date(dateOfCeasingToBeABO), "yyyy-MM-dd")
          : "N/A",
      },
      {
        key: "Source of BO information",
        name: "sourceOfBOInformation",
        value: sourceOfBOInformation,
      },
    ].filter(Boolean);
  }

  if (reviewTab && !onlyDates) {
    return [
      {
        key: renderShortTypeOfControl
          ? "Shareholding"
          : "Percentage of shareholding",
        name: "percentShareholding",
        value: {
          direct: _get(
            beneficialOwnershipForm,
            "directPercentShareholding",
            "N/A"
          ),
          indirect: _get(
            beneficialOwnershipForm,
            "indirectPercentShareholding",
            "N/A"
          ),
        },
      },
      {
        key: renderShortTypeOfControl
          ? "Voting rights"
          : "Percentage of voting rights",
        name: "percentVotingRights",
        value: {
          direct: _get(
            beneficialOwnershipForm,
            "directPercentVotingRights",
            "N/A"
          ),
          indirect: _get(
            beneficialOwnershipForm,
            "indirectPercentVotingRights",
            "N/A"
          ),
        },
      },
      {
        key: renderShortTypeOfControl
          ? "Appoint/remove director?"
          : "Right to appoint/remove director",
        name: "rightRemoveDirector",
        value: {
          direct: directRightRemoveDirector,
          indirect: indirectRightRemoveDirector,
        },
      },
      {
        key: renderShortTypeOfControl
          ? "Influence/control?"
          : "Influence/control over the company?",
        name: "companyControlRight",
        value: {
          direct: directCompanyControlRight,
          indirect: indirectCompanyControlRight,
        },
      },
      renderDateOfBecomingToBeABO
        ? {
            key: "Date of becoming BO",
            name: "dateOfBecomingBO",
            value: dateOfBecomingBo
              ? format(new Date(dateOfBecomingBo), "yyyy-MM-dd")
              : "N/A",
          }
        : null,
      renderDateOfCeasingToBeABO
        ? {
            key: `Date of Ceasing to be a BO`,
            name: `dateOfCeasingToBeABO`,
            value: dateOfCeasingToBeABO
              ? format(new Date(dateOfCeasingToBeABO), "yyyy-MM-dd")
              : "N/A",
          }
        : null,
      sourceOfBO
        ? {
            key: "Source of BO information",
            name: "sourceOfBOInformation",
            value: sourceOfBOInformation,
          }
        : null,
    ].filter(Boolean);
  }

  return [
    {
      key: "Direct percentage of shareholding",
      name: "directPercentShareholding",
      value: _get(beneficialOwnershipForm, "directPercentShareholding", "N/A"),
    },
    {
      key: "Indirect percentage of shareholding",
      name: "indirectPercentShareholding",
      value: _get(
        beneficialOwnershipForm,
        "indirectPercentShareholding",
        "N/A"
      ),
    },
    {
      key: "Direct percentage of voting rights",
      name: "directPercentVotingRights",
      value: _get(beneficialOwnershipForm, "directPercentVotingRights", "N/A"),
    },
    {
      key: "Indirect percentage of voting rights",
      name: "indirectPercentVotingRights",
      value: _get(
        beneficialOwnershipForm,
        "indirectPercentVotingRights",
        "N/A"
      ),
    },
    {
      key: "Direct right to appoint/remove director",
      name: "directRightRemoveDirector",
      value: directRightRemoveDirector,
    },
    {
      key: "Indirect right to appoint/remove director",
      name: "indirectRightRemoveDirector",
      value: indirectRightRemoveDirector,
    },
    {
      key: "Direct influence/control over the company?",
      name: "directCompanyControlRight",
      value: directCompanyControlRight,
    },
    {
      key: "Indirect influence/control over the company",
      name: "indirectCompanyControlRight",
      value: indirectCompanyControlRight,
    },
    {
      key: "Date of becoming BO",
      name: "dateOfBecomingBO",
      value: dateOfBecomingBo
        ? format(new Date(dateOfBecomingBo), "yyyy-MM-dd")
        : "N/A",
    },
    {
      key: "Source of BO information",
      name: "sourceOfBOInformation",
      value: sourceOfBOInformation,
    },
  ];
};

/**
 * This returns masked email address.
 *
 * @param {email} string email to be masked
 * @param {skipFirstChars} number number of characters to skip from the start
 * @return {string} This returns masked email e.g c*******6@ekbasia.com
 */
export function getMaskedEmail(email = "", skipFirstChars = 1) {
  let firstCharsToSkip = email.slice(0, skipFirstChars);

  let domainIndexStart = email.lastIndexOf("@");
  let maskedEmail = email.slice(skipFirstChars, domainIndexStart - 1);
  maskedEmail = maskedEmail.replace(/./g, "*");
  let domainPlusPreviousChar = email.slice(domainIndexStart - 1, email.length);

  return firstCharsToSkip
    .concat(maskedEmail)
    .concat(domainPlusPreviousChar)
    .trim();
}

export const parameterizeArray = (key, arr) => {
  arr = arr.map(encodeURIComponent);
  return key + "=" + arr.join("&" + key + "=");
};

export const isProfessional = (designation) =>
  ["Authorized Person", "Company Secretary"].includes(designation);

export const getMaxCompanySecretariesLimit = (type = "PLC") => {
  switch (type) {
    case "PLC":
      return Constants.MAX_PLC_COMPANY_SECRETARIES_LIMIT;
    case "PVT":
      return Constants.MAX_PVT_COMPANY_SECRETARIES_LIMIT;
    default:
      return 2;
  }
};

export const getCompletePackageName = (pricingPackage) => {
  let packageName = `${pricingPackage.service}`;
  if (pricingPackage?.product) packageName += ` ${pricingPackage.product.name}`;
  if (pricingPackage?.name) packageName += ` ${pricingPackage.name}`;
  return packageName;
};

export const formatCurrency = (
  amount,
  currency,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
) => {
  const options = { minimumFractionDigits, maximumFractionDigits };
  if (currency) {
    options["style"] = "currency";
    options["currency"] = currency;
  }
  return Intl.NumberFormat("en-US", options).format(amount);
};

export const classNames = (...classes) => classes.filter(Boolean).join(" ");

export const getEntityTypeOptions = (designation, idType) => {
  if (idType === "Other e.g Parastatals")
    return Constants.OTHER_PARASTATALS_ENTITY_TYPES;
  if (["Company Secretary", "Authorized Person"].includes(designation)) {
    return Constants.CS_AND_AP_ENTITY_TYPES;
  }
  return Constants.CORPORATE_BO_OPTIONS;
};

/**
 * Formats a number into a smaller, more readable representation with an appropriate suffix (K, M, B, T, etc.).
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number as a string with an appropriate suffix.
 *
 * @throws {Error} If the input is not a number.
 *
 * @example
 * // Returns "0" for 0
 * formatNumber(0);
 *
 * // Returns "200" for 200
 * formatNumber(200);
 *
 * // Returns "-2.0K" for -2000
 * formatNumber(-2000);
 *
 * // Returns "2.0K" for 2000
 * formatNumber(2000);
 *
 * // Returns "2.0M" for 2000000
 * formatNumber(2000000);
 *
 * // Returns "2.0B" for 2000000000
 * formatNumber(2000000000);
 *
 * @note This function will round the number to one decimal place if it has a fractional part.
 *       Numbers greater than 1000 trillion (10^15) will be represented with the "T" suffix.
 */
export function formatNumber(number) {
  if (typeof number !== "number") {
    throw new Error("Input must be a number.");
  }

  if (number === 0) return "0";

  const sign = number < 0 ? "-" : "";
  const absNumber = Math.abs(number);
  const abbreviations = ["", "K", "M", "B", "T"];
  const tier = Math.floor(Math.log10(absNumber) / 3);

  if (tier === 0) return sign + absNumber.toFixed(0);

  const suffix = abbreviations[Math.min(tier, abbreviations.length - 1)];
  const scale = Math.pow(10, tier * 3);
  const scaledNumber = absNumber / scale;

  if (scaledNumber % 1 !== 0) return sign + scaledNumber.toFixed(2) + suffix;

  return sign + scaledNumber.toString() + suffix;
}

export const getCountryLabel = (value) =>
  options.countries.find((country) => country.value === value)?.label ??
  "Kenya";

export const getFullResidentialAddress = ({
  buildingName = "",
  floorNumber = "",
  houseNumber = "",
  streetName = "",
  streetNumber = "",
  estate = "",
  locality = "",
  district = "",
  county = "",
  city = "",
  country = "",
}) => {
  let residentialAddress = "";
  if (buildingName) residentialAddress += `${buildingName}, `;
  if (floorNumber) residentialAddress += `${floorNumber}, `;
  if (houseNumber) residentialAddress += `${houseNumber}, `;
  if (streetName) residentialAddress += `${streetName}, `;
  if (streetNumber) residentialAddress += `${streetNumber}, `;
  if (estate) residentialAddress += `${estate}, `;
  if (locality) residentialAddress += `${locality}, `;
  if (district) residentialAddress += `${district}, `;
  if (county) residentialAddress += `${county} `;
  if (city) residentialAddress += `${city}, `;
  if (country) residentialAddress += `${getCountryLabel(country)} `;
  return residentialAddress;
};

export const getVerifyId = (idType, identificationDetails, names) => {
  if (CORPORATES.includes(idType)) {
    return names.companyRegNumber;
  }
  if (idType === "Kenyan Citizen")
    return identificationDetails.nationalIDNumber;
  if (idType === "Foreign Resident")
    return identificationDetails.foreignCertificateID;
  if (idType === "Foreigner") return identificationDetails.passportNumber;
  return "";
};

export const getPoBoxAddress = (idType, postalAddress, isNonResident) => {
  if (_isEmpty(postalAddress)) return "";
  if (
    [Constants.ID_TYPES.KENYAN_CITIZEN, Constants.ID_TYPES.MINOR].includes(
      idType
    ) &&
    isNonResident === "No"
  ) {
    return `PO BOX ${postalAddress.postalAddress} ${postalAddress.postalCode}`;
  }
  if (
    [
      Constants.ID_TYPES.FOREIGN_RESIDENT,
      Constants.ID_TYPES.LOCAL_COMPANY,
      Constants.ID_TYPES.OTHER_PARASTATALS,
    ].includes(idType)
  ) {
    return `PO BOX ${postalAddress.postalAddress} ${postalAddress.postalCode}`;
  }
  return `${postalAddress.postalAddress} ${postalAddress.postalCode}`;
};

export const getEmptyRowsForRegisters = (
  currentRowsCount,
  rowsPerPage = 17
) => {
  if (currentRowsCount === 0) return Array.from({ length: rowsPerPage });

  const lastPage = Math.ceil(currentRowsCount / rowsPerPage);
  const rowsToAdd = lastPage * rowsPerPage - currentRowsCount;

  if (rowsToAdd > 0) {
    return Array(rowsToAdd).fill(null);
  }

  return [];
};

export const getUnpaidClassesOfShares = (shareholders) => {
  return shareholders
    .flatMap((shareholder) =>
      shareholder.sharesAllotted
        .filter((share) => share.allottedShares - share.paidUpShares > 0)
        .map((share) => share.classOfShares)
    )
    .filter(Boolean);
};

export const flattenNestedChildren = (
  items,
  level = 1,
  maxLevel = Infinity
) => {
  if (level > maxLevel) {
    return items;
  }

  return items.flatMap((item) => [
    item,
    ...flattenNestedChildren(item.children || [], level + 1, maxLevel),
  ]);
};
