import React, { useState } from "react";
import _get from "lodash/get";

import CommentsList from "components/lib/Global/CommentsList";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import Button from "components/lib/Shared/Button";
import { getBeneficialOwnershipFormFields } from "utils";
import BeneficialOwnershipForm from "./Form";

function OfficialBeneficialOwnership({
  row,
  officialBeneficialOwnershipComments,
  companyOfficialEffectKey,
  officialSliceName,
  handleSaveComment,
  beneficialOwners,
}) {
  const [editOfficialBeneficialOwnership, setEditOfficialBeneficialOwnership] =
    useState(false);

  if (editOfficialBeneficialOwnership) {
    return (
      <div className="px-8 pt-5 pb-6 my-6 bg-white">
        <BeneficialOwnershipForm
          row={row}
          setEditOfficialBeneficialOwnership={
            setEditOfficialBeneficialOwnership
          }
        />
      </div>
    );
  }

  return (
    <div className="mt-4">
      <Button
        className="mb-2"
        onClick={() => {
          setEditOfficialBeneficialOwnership(true);
        }}
      >
        Edit ownership
      </Button>
      {officialBeneficialOwnershipComments &&
        officialBeneficialOwnershipComments.length > 0 && (
          <div className="px-8 pt-5 pb-6 mt-6 bg-white">
            <CommentsList
              comments={officialBeneficialOwnershipComments}
              toggleCommentStatus
            />
            <p className="mt-6 text-sm text-primary">
              Comments: {officialBeneficialOwnershipComments.length} of{" "}
              {officialBeneficialOwnershipComments.length}
            </p>
          </div>
        )}
      <FormFieldsCard
        handleSaveComment={handleSaveComment}
        fields={getBeneficialOwnershipFormFields({
          beneficialOwnershipForm: row.original.beneficialOwnershipForm,
          reviewTab: true,
          beneficialOwner: row.original,
          beneficialOwners,
        })}
        resolvedFields={_get(
          row.original.beneficialOwnershipForm,
          "resolvedFields"
        )}
        addReviewComment
        formName="beneficialOwnershipForm"
        formHelperKey="beneficialOwnershipForm"
        updateEffectKey={companyOfficialEffectKey}
        updateEffectPath={`${officialSliceName}.beneficialOwnershipForm`}
        formId={_get(row.original.beneficialOwnershipForm, "_id")}
        comments={officialBeneficialOwnershipComments}
      />
    </div>
  );
}

export default OfficialBeneficialOwnership;
