import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const SignIn = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "login",
        redirect_uri: window.location.origin,
        base_api_url: process.env.REACT_APP_BASE_API_URL,
      },
    });
  });

  return null;
};

export default SignIn;
