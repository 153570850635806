import { object, string } from "yup";

const contactPersonValidation = () => {
  const schema = object().shape({
    fullName: string().required("Full name is required"),
    emailAddress: string().required("Email address is required"),
    mobileNumber: string().required("Mobile number is required"),
    designation: string().required("Designation is required"),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default contactPersonValidation;
