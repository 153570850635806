/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import { Field } from "formik";

import NoSubsidiaryRadioButton from "./NoSubsidiaryRadioButton";
import YesSubsidiaryRadioButton from "./YesSubsidiaryRadioButton";
import CommentsList from "components/lib/Global/CommentsList";
import ValidationError from "components/lib/Shared/ValidationError";

function PrincipalHoldingCompany({
  field,
  form: { setFieldValue, errors, touched, values = {} },
  transformedComments,
}) {
  const { isKenyanResident } = field.value;

  // initialValues are required for validation
  useEffect(() => {
    setFieldValue(field.name, {
      isKenyanResident: "",
      ...field.value,
    });
  }, []);

  const onSelectChange = (e) => {
    setFieldValue(field.name, {
      isSubsidiaryCompany: field.value.isSubsidiaryCompany,
      isKenyanResident: e.target.value,
    });
  };

  const isKenyanResidentComments =
    transformedComments?.["businessApplicationDetails"]?.[
      "subsidiaryDetail.isKenyanResident"
    ] ?? [];

  return (
    <div className="my-2">
      <div>
        <h5>
          <span className="relative">
            Is the principal holding company resident in Kenya?
            {isKenyanResidentComments.length > 0 && (
              <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
                <span>{isKenyanResidentComments.length}</span>
              </span>
            )}
          </span>
        </h5>
        <div className="flex mb-4 space-x-16">
          <div className="space-x-2">
            <Field
              type="radio"
              name="isKenyanResident"
              id="kenyanResident"
              value="Yes"
              checked={isKenyanResident === "Yes"}
              onChange={onSelectChange}
              className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              disabled={values?.resolvedFields?.includes(
                "subsidiaryDetail.isKenyanResident"
              )}
            />
            <label
              className="text-sm font-medium text-gray-700"
              htmlFor="kenyanResident"
            >
              Yes
            </label>
          </div>
          <div className="space-x-2">
            <Field
              type="radio"
              name="isKenyanResident"
              id="nonKenyanResident"
              value="No"
              checked={isKenyanResident === "No"}
              onChange={onSelectChange}
              className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              disabled={values?.resolvedFields?.includes(
                "subsidiaryDetail.isKenyanResident"
              )}
            />
            <label
              className="text-sm font-medium text-gray-700"
              htmlFor="nonKenyanResident"
            >
              No
            </label>
          </div>
        </div>
        {isKenyanResidentComments.length > 0 && (
          <CommentsList
            comments={isKenyanResidentComments}
            color={"text-red-500"}
          />
        )}
        <ValidationError
          errors={errors}
          touched={touched}
          name="subsidiaryDetail.isKenyanResident"
        />
        {isKenyanResident === "Yes" && (
          <Field
            name="subsidiaryDetail"
            component={YesSubsidiaryRadioButton}
            transformedComments={transformedComments}
          />
        )}
        {isKenyanResident === "No" && (
          <Field
            name="subsidiaryDetail"
            component={NoSubsidiaryRadioButton}
            transformedComments={transformedComments}
          />
        )}
      </div>
    </div>
  );
}

export default PrincipalHoldingCompany;
