import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { roundNumber } from "utils";
import Table from "components/lib/Global/ReactTable";

function MinorityTable({
  data,
  currentTab,
  isJointShareholderTable,
  goToPrevStep = () => null,
}) {
  const { comments } = useSelector((state) => state.commentsSlice);

  const columns = useMemo(
    () => [
      {
        Header: () => "Name",
        id: "Name",
        accessor: "fullName",
        Cell: ({ row }) => (
          <span>{`${row.original.remainingMinorityShareholders} Minority Shareholders`}</span>
        ),
        // We can render something different for subRows
        SubCell: (cellProps) => <>{cellProps.value}</>,
      },
      {
        Header: () => "ID Type",
        id: "ID Type",
        accessor: "idType",
      },
      {
        Header: "Percentage Shareholding",
        Cell: ({ row: { original } }) => {
          if ("totalMinorityShareholdingPercent" in original)
            return (
              <span>{`${roundNumber(
                original.totalMinorityShareholdingPercent
              )}%`}</span>
            );
        },
      },
      {
        Header: "Percentage Voting",
        Cell: ({ row: { original } }) => {
          if ("totalMinorityVotingPercent" in original)
            return (
              <span>{`${roundNumber(
                original.totalMinorityVotingPercent
              )}%`}</span>
            );
        },
      },
    ],
    [isJointShareholderTable, currentTab, comments, goToPrevStep]
  );

  return (
    <div className="w-full">
      <Table
        columns={columns}
        data={data}
        dataRowCellStyle="whitespace-normal"
        headingStyle="bg-sflPrimary text-white"
        // We added this as a prop for our table component
        // Remember, this is not part of the React Table API,
        // it's merely a rendering option we created for
        // ourselves
      />
    </div>
  );
}

export default MinorityTable;
