/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Field, useFormikContext } from "formik";
import Cookies from "js-cookie";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import CommentsList from "components/lib/Global/CommentsList";
import { Constants } from "config/constants";

const AffiliatedBusiness = ({
  field,
  form,
  regNumberComments,
  businessNameComments,
  fileUploadComments,
  prefixDocumentName,
}) => {
  const role = Cookies.get("role");
  const { setFieldValue } = useFormikContext();
  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="flex flex-col mt-4 space-y-3">
      <div>
        <label
          htmlFor="affiliatedBusinessName"
          className="block mb-2 text-sm text-gray-600"
        >
          <span className="relative">
            Name of affiliated business
            {businessNameComments?.length > 0 && (
              <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-2 -right-5">
                <span>{businessNameComments?.length}</span>
              </span>
            )}
          </span>
        </label>
        <input
          type="text"
          className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
          {...field}
          // onBlur={field.onBlur}
          onChange={handleSetValue}
          disabled={form.values.resolvedFields.includes(
            `${field.name}.affiliatedBusinessName`
          )}
          value={"" || field.value?.affiliatedBusinessName}
          id="affiliatedBusinessName"
          name="affiliatedBusinessName"
        />
        {businessNameComments && (
          <CommentsList
            comments={businessNameComments}
            color={"text-red-500"}
          />
        )}
      </div>
      <div>
        <label
          htmlFor="affiliatedBusinessRegNumber"
          className="block mb-2 text-sm text-gray-600"
        >
          <span className="relative">
            Registration number of affiliated business
            {regNumberComments?.length > 0 && (
              <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-2 -right-5">
                <span>{regNumberComments?.length}</span>
              </span>
            )}
          </span>
        </label>
        <input
          type="text"
          {...field}
          onChange={handleSetValue}
          value={"" || field.value?.affiliatedBusinessRegNumber}
          disabled={form.values.resolvedFields.includes(
            `${field.name}.affiliatedBusinessRegNumber`
          )}
          className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
          aria-label="sizing example input"
          aria-describedby="inputGroup-sizing-lg"
          id="affiliatedBusinessRegNumber"
          name="affiliatedBusinessRegNumber"
        />
        {regNumberComments && (
          <CommentsList comments={regNumberComments} color={"text-red-500"} />
        )}
      </div>
      <div>
        <label htmlFor="" className="block mb-2 text-sm text-gray-600">
          <span className="relative">
            Upload a letter of no objections from
            {fileUploadComments?.length > 0 && (
              <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-2 -right-5">
                <span>{fileUploadComments?.length}</span>
              </span>
            )}
          </span>
        </label>
        <Field
          name="fileUploads"
          component={FileUploadForm}
          editorParams={{
            showFullScreenToggle: true,
            containerHeight: "900px",
          }}
          prefixDocumentName={prefixDocumentName}
          documentData={[{ label: "", name: `${field.name}_LNO` }]}
          disabled={role === Constants.COOKIE_ROLES.OFFICIAL}
        />
        {fileUploadComments && (
          <CommentsList comments={fileUploadComments} color={"text-red-500"} />
        )}
      </div>
    </div>
  );
};

export default AffiliatedBusiness;
