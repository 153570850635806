import React from "react";
import { useSelector } from "react-redux";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";

import CompanyStakeholderTable from "./CompanyStakeholderTable";
import { getFullName } from "utils";

export default function StakeholderDetails({ companyId }) {
  const companyDashboardSlice = useSelector(
    (state) => state.companyDashboardSlice
  );

  const { getCompanyOfficialsStatus } = companyDashboardSlice;

  const companyOfficials = companyDashboardSlice.companyOfficials.items;
  const { totalCount } = companyDashboardSlice.companyOfficials;

  const JSGroups = new Set();
  companyOfficials.forEach((item) => {
    if (item.JSGroupID) JSGroups.add(item.JSGroupID);
  });

  const allJS = [...JSGroups].map((id) => {
    const names = [];
    let combinedJS = {};
    companyOfficials.forEach((official) => {
      if (official.JSGroupID === id) {
        names.push(getFullName(official));
        if (official.sharesAllotted && official.sharesAllotted.length > 0)
          combinedJS.sharesAllotted = official.sharesAllotted;
        combinedJS = {
          ...combinedJS,
          ..._omit(_cloneDeep(official), ["sharesAllotted"]),
          designation: "Joint Shareholder",
          idType: "Joint Shareholder",
        };
        combinedJS.fullName = names.map((name, index) => {
          if (names.length === 2 && index === 0) {
            return `${name} & `;
          }
          if (names.length > 2 && index === names.length - 2) {
            return `${name} & `;
          }

          if (
            names.length > 2 &&
            index !== names.length - 2 &&
            index !== names.length - 1
          ) {
            return `${name}, `;
          }
          return name;
        });
      }
    });
    return combinedJS;
  });

  const data = [
    ...companyOfficials.filter(
      (item) =>
        (item.designation === "Joint Shareholder" &&
          item.isDirector === "Yes") ||
        item.designation !== "Joint Shareholder"
    ),
    ...allJS.filter((item) => !_isEmpty(item)),
  ];

  return (
    <div className="mt-6">
      <CompanyStakeholderTable
        data={data}
        totalCount={totalCount}
        companyId={companyId}
        currentTab="stakeholderTab"
        isLoading={getCompanyOfficialsStatus.status === "loading"}
      />
    </div>
  );
}
