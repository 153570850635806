import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import _cloneDeep from "lodash/cloneDeep";
import _keys from "lodash/keys";
import _omit from "lodash/omit";
import { useParams } from "react-router-dom";

import {
  addBeneficialOwnerAsync,
  deleteBeneficialOwnerAsync,
  updateDropdownEntryStatusAsync,
} from "state/slices/tables/beneficialOwnersTableSlice";
import { clearEmpties, delay, getCombinedNames, getFullName } from "utils";
import { getJointShareholders } from "state/slices/tables/jointShareholderTable";

export const useJointShareholders = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companySlice);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams();
  let { jointShareholders, currentJSGroupID } = useSelector(
    (state) => state.jointShareholderTableSlice
  );

  const companyId = company?._id ?? params?.companyId;

  // sort joint shareholders by beneficiaries first
  const JsOfficialWithBoForm = [...jointShareholders].sort(
    (a, b) => _keys(b).length - _keys(a).length
  )[0];
  const indexOfJsOfficialWithBoForm = jointShareholders.findIndex(
    (jointShareholder) => jointShareholder?._id === JsOfficialWithBoForm?._id
  );

  const { beneficialOwners } = useSelector(
    (state) => state.beneficialOwnersTableSlice
  );
  const beneficialOwnersDropdown = beneficialOwners.filter(
    (bo) => bo.showInBoDropdown
  );
  const allBOs = [...beneficialOwners, ...beneficialOwnersDropdown];

  const syncBeneficialOwnersWithJointShareholders = async () => {
    setIsSubmitting(true);
    const {
      payload: { jointShareholders },
    } = await dispatch(getJointShareholders());

    // check for pending invites
    const unApprovedInvitedOfficials = jointShareholders.filter(
      (official) =>
        official.type === "invited" && official.inviteStatus !== "Approved"
    );

    if (unApprovedInvitedOfficials.length > 0) {
      setIsSubmitting(false);
      return;
    }

    // Delete outdated BOs and update dropdown status
    const deleteOutdatedBeneficialOwnersPromises = [];

    const JSGroupID = currentJSGroupID || uuidv4();
    allBOs.forEach((bo) => {
      if (bo.JSGroupID && bo.JSGroupID === JSGroupID) {
        deleteOutdatedBeneficialOwnersPromises.push(
          dispatch(
            deleteBeneficialOwnerAsync({ beneficialOwnerId: bo._id, companyId })
          )
        );
        deleteOutdatedBeneficialOwnersPromises.push(
          dispatch(
            updateDropdownEntryStatusAsync({
              beneficialOwnerId: bo._id,
              data: { showInBoDropdown: false },
            })
          )
        );
      }
    });
    const fullName = getCombinedNames(
      jointShareholders.map((bo) => bo.names.fullName || getFullName(bo.names))
    );
    const {
      directPercentShareholding,
      indirectPercentShareholding,
      directPercentVotingRights,
      indirectPercentVotingRights,
      directRightRemoveDirector,
      indirectRightRemoveDirector,
      directCompanyControlRight,
      indirectCompanyControlRight,
    } = jointShareholders[0]?.beneficialOwnershipForm ?? {};
    const isDirectBeneficiary =
      directPercentShareholding >= 10 ||
      directPercentVotingRights >= 10 ||
      directRightRemoveDirector === "yes" ||
      directCompanyControlRight === "yes";
    const isIndirectBeneficiary =
      indirectPercentShareholding >= 10 ||
      indirectPercentVotingRights >= 10 ||
      indirectRightRemoveDirector === "yes" ||
      indirectCompanyControlRight === "yes";

    const createBeneficialOwnersPromises = [];
    jointShareholders.map(async (JS, index) => {
      JS = { ...JS, JSGroupID, JSGroupOrder: index + 1 };
      const boJS = _cloneDeep(JS);
      boJS.beneficialOwnershipForm =
        jointShareholders[0]?.beneficialOwnershipForm;
      boJS.proRata = directPercentShareholding || indirectPercentShareholding;
      boJS.JSCombinedName = fullName;
      boJS.isReportable = true;
      if (!isDirectBeneficiary && !isIndirectBeneficiary)
        boJS.isReportable = false;

      const combinedBOEntry = {
        ..._omit(boJS, [
          "_id",
          "electronicAddress",
          "postalAddress",
          "residentialAddressDetails",
          "identificationDetails",
        ]),
        id: uuidv4(),
        names: {
          ...boJS.names,
          fullName,
        },
        idType: "Joint Shareholder",
      };

      if (
        (isDirectBeneficiary ||
          directPercentShareholding > 0 ||
          directPercentVotingRights > 0) &&
        index === indexOfJsOfficialWithBoForm
      ) {
        combinedBOEntry["showInBoTable"] = true;
      }

      await delay(100);

      if (!boJS?._id && JS?._id) {
        boJS._id = JS?._id;
      }

      if (
        isDirectBeneficiary ||
        directPercentShareholding > 0 ||
        directPercentVotingRights > 0
      ) {
        createBeneficialOwnersPromises.push(
          dispatch(
            addBeneficialOwnerAsync({
              companyId,
              data: clearEmpties({ ...boJS }),
            })
          )
        );
      }

      if (
        (isIndirectBeneficiary ||
          indirectPercentShareholding > 0 ||
          indirectPercentVotingRights > 0) &&
        index === indexOfJsOfficialWithBoForm
      ) {
        combinedBOEntry["showInBoDropdown"] = true;
      }

      if (index === indexOfJsOfficialWithBoForm) {
        createBeneficialOwnersPromises.push(
          dispatch(
            addBeneficialOwnerAsync({
              companyId,
              data: clearEmpties(combinedBOEntry),
            })
          )
        );
      }
    });
    await Promise.all([
      ...deleteOutdatedBeneficialOwnersPromises,
      ...createBeneficialOwnersPromises,
    ]);
    setIsSubmitting(false);
  };

  return {
    syncBeneficialOwnersWithJointShareholders,
    isSubmitting,
  };
};
