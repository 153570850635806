import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

import Button from "components/lib/Shared/Button";
import { resetJointShareholderTable } from "state/slices/tables/jointShareholderTable";
import { resetSharesTable } from "state/slices/tables/sharesAllocationTable";
import { getAllBeneficialOwners } from "state/slices/tables/beneficialOwnersTableSlice";
import { usePopper } from "hooks/usePopper";
import { classNames } from "utils";

const SubmitJointShareholdersList = (props) => {
  const { showAddMoreBtn, handleExitForm, setFormStepErrors } = props;
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companySlice);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams();
  let { jointShareholders } = useSelector(
    (state) => state.jointShareholderTableSlice
  );

  const companyId = company?._id ?? params?.companyId;

  const [trigger, container] = usePopper({
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  const handleSubmitJointShareholdersList = async () => {
    setIsSubmitting(true);
    await dispatch(getAllBeneficialOwners({ companyId }));
    props.resetFormState();
    dispatch(resetJointShareholderTable());
    dispatch(resetSharesTable());
    setIsSubmitting(false);
  };

  const handleAddMore = () => {
    setFormStepErrors([]);
    const unApprovedSeniorJointShareholder = jointShareholders.find(
      (official) =>
        official.type === "invited" && official.inviteStatus !== "Approved"
    );
    if (jointShareholders.length === 1 && unApprovedSeniorJointShareholder) {
      setFormStepErrors([
        "The pending invite must be responded to and data accepted before adding additional joint shareholders.",
      ]);
    } else {
      props.handleAddMore();
    }
  };

  return (
    <div className="flex w-40 justify-between bg-green-700 rounded-md">
      <Button
        variant="text"
        isLoading={isSubmitting}
        className="pl-3 text-sm font-normal leading-3 tracking-normal text-white"
      >
        Select Options
      </Button>
      <Menu as="div" className="relative -ml-px block flex-end">
        <Menu.Button
          ref={trigger}
          disabled={isSubmitting}
          className="relative inline-flex border-l border-white items-center rounded-r-md bg-green-focused px-2 py-2 text-white hover:bg-green-700 focus:z-10 disabled:cursor-not-allowed disabled:bg-green-200"
        >
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <div ref={container} className="z-50">
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {showAddMoreBtn && (
                  <Menu.Item>
                    {({ active }) => (
                      <Button
                        variant="text"
                        onClick={handleAddMore}
                        disabled={isSubmitting}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-sm"
                        )}
                      >
                        Add More
                      </Button>
                    )}
                  </Menu.Item>
                )}
                {jointShareholders.length > 1 && jointShareholders.length <= 4 && (
                  <Menu.Item>
                    {({ active }) => (
                      <Button
                        onClick={handleSubmitJointShareholdersList}
                        variant="text"
                        disabled={isSubmitting}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-sm"
                        )}
                      >
                        Submit List
                      </Button>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <Button
                      onClick={handleExitForm}
                      variant="text"
                      disabled={isSubmitting}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-sm"
                      )}
                    >
                      Exit
                    </Button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </div>
      </Menu>
    </div>
  );
};

export default SubmitJointShareholdersList;
