import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _keys from "lodash/keys";
import _orderBy from "lodash/orderBy";
import { useParams } from "react-router-dom";
import ReactTable from "components/lib/Global/ReactTable";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";
import Cookies from "js-cookie";
import SectionDescription from "components/lib/Shared/SectionDescription";
import { setShares } from "state/slices/tables/sharesAllocationTable";
import {
  setJointShareholders,
  setCurrentJSGroupID,
} from "state/slices/tables/jointShareholderTable";
import {
  deleteCompanyOfficialAsync,
  updateCompanyOfficialAsync,
} from "state/slices/tables/companyOfficialsSlice";
import {
  deleteBeneficialOwnerAsync,
  updateDropdownEntryStatusAsync,
} from "state/slices/tables/beneficialOwnersTableSlice";
import { useToast } from "hooks/useToast";
import {
  deleteAccessRequest,
  getAccessRequests,
  sendAccessRequestInvite,
} from "state/slices/accessRequest";
import { getFullName, getMaskedEmail } from "utils";
import NotificationBanner from "components/lib/Shared/NotificationBanner";
import SubRowAsync from "./SubRowAsync";
import Checkbox from "components/lib/Shared/Checkbox";
import { Constants } from "config/constants";
import AvatarIcon from "components/lib/Shared/Icons/AvatarIcon";
import SearchInput from "components/lib/Shared/SearchInput";

const CompanyOfficialsTable = (props) => {
  const { assigningRegistrar, registrarCandidates = [] } = props;
  const currentTab = "stakeholderTab";
  const dispatch = useDispatch();
  const role = Cookies.get("role");
  const [selectedRegistrar, setSelectedRegistrar] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const { comments } = useSelector((state) => state.commentsSlice);
  const accessRequests = useSelector(
    (state) => state.accessRequestsSlice.items
  );
  const { company } = useSelector((state) => state.companySlice);
  const currentUser = useSelector((state) => state.userSlice);

  const { toast } = useToast(5000);
  const params = useParams();
  const companyId = company?._id ?? params?.companyId;

  useEffect(() => {
    dispatch(
      getAccessRequests({
        query: { initiatedToEmail: currentUser.electronicAddress.emailAddress },
      })
    );
  }, [currentUser.electronicAddress.emailAddress, dispatch]);

  const transformSharesAllotted = (sharesAllotted) =>
    sharesAllotted
      .map(
        ({ classOfShares, allottedShares }) =>
          `<div>${classOfShares}: ${allottedShares}</div>`
      )
      .join("");

  const originalCompanyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );

  const handleEdit = (row) => {
    dispatch(setShares(row?.sharesAllotted ?? []));
    props.handleEdit(row);
  };

  const handleView = (row) => {
    dispatch(setShares(row?.sharesAllotted ?? []));
    props.handleView(row);
  };

  const handleEditAndDeleteJointShareholder = (row) => {
    const jointShareholderForEdit = originalCompanyOfficials.filter(
      (official) => official.JSGroupID === row.JSGroupID
    );
    dispatch(setCurrentJSGroupID(jointShareholderForEdit[0].JSGroupID));
    props.toggleShowOfficialsForm(true);
    // sort joint shareholders in the order they were added
    const sortedJointShareholders = _orderBy(
      jointShareholderForEdit,
      "createdAt",
      "asc"
    );
    dispatch(setJointShareholders(sortedJointShareholders));
  };

  const handleDelete = async (row) => {
    const accessRequest = accessRequests.find(
      (request) => request.initiatedToEmail === row.initiatedToEmail
    );
    if (
      row?.type === "invited" &&
      row?.inviteStatus === "Awaiting Data" &&
      accessRequest?._id
    ) {
      dispatch(deleteAccessRequest({ accessRequestsId: accessRequest?._id }));
    }
    await Promise.all([
      dispatch(
        deleteCompanyOfficialAsync({
          officialId: row._id,
          idType: row.idType,
          companyId,
        })
      ),
      dispatch(
        updateDropdownEntryStatusAsync({
          beneficialOwnerId: row._id,
          data: { showInBoDropdown: false },
        })
      ),
      dispatch(
        deleteBeneficialOwnerAsync({
          beneficialOwnerId: row._id,
          companyId,
        })
      ),
    ]);
  };

  const handleReview = async (row) => {
    row?.getToggleRowExpandedProps()?.onClick();
  };

  const handleRecallAccess = async (row) => {
    const accessRequest = accessRequests.find(
      (request) => request.initiatedToEmail === row.initiatedToEmail
    );
    if (window.confirm("are you sure you want to recall this request?")) {
      if (row?.type === "invited" && row?.inviteStatus === "Awaiting Data") {
        await dispatch(
          deleteAccessRequest({ accessRequestsId: accessRequest?._id })
        );
      }
      dispatch(
        deleteCompanyOfficialAsync({
          officialId: row._id,
          idType: row.idType,
          companyId,
        })
      );
      dispatch(
        updateDropdownEntryStatusAsync({
          beneficialOwnerId: row._id,
          data: { showInBoDropdown: false },
        })
      );
      dispatch(
        deleteBeneficialOwnerAsync({
          beneficialOwnerId: row._id,
          companyId,
        })
      );
    }
    // const accessRequest = accessRequests.find(request => request.initiatedToEmail === row.initiatedToEmail)
    // try {
    //   await dispatch(editAccessRequest({ accessRequestsId: accessRequest?._id, data: { status: "Recalled", recalledAt: new Date() } }))
    //   toast('success', 'Request recalled successfully.');
    // } catch (error) {
    //   toast('error', error?.message ?? 'Something went wrong please try again.');
    // }
  };

  const handleResendInvite = async (row) => {
    // const accessRequest = accessRequests.find(request => request.initiatedToEmail === row.initiatedToEmail)
    try {
      await dispatch(
        sendAccessRequestInvite({
          data: {
            userEmail: row.initiatedToEmail,
          },
        })
      );
      toast("success", "Invite sent successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  const handleToggleRegistrar = async (e) => {
    const idTypeOfSelectedRegistrar = registrarCandidates.find(
      (registrarCandidate) => registrarCandidate._id === e.target.value
    )?.idType;
    if (e.target.checked) {
      setSelectedRegistrar(e.target.value);
      await dispatch(
        updateCompanyOfficialAsync({
          officialId: e.target.value,
          data: {
            isRegistrar: true,
            idType: idTypeOfSelectedRegistrar,
            companyId,
          },
        })
      );
      toast("success", "Registrar assigned successfully.");
    } else {
      setSelectedRegistrar(null);
      await dispatch(
        updateCompanyOfficialAsync({
          officialId: e.target.value,
          data: {
            isRegistrar: false,
            idType: idTypeOfSelectedRegistrar,
            companyId,
          },
        })
      );
      toast("success", "Registrar removed successfully.");
    }
  };

  const registrarCandidatesIds = registrarCandidates.map(
    (registrarCandidate) => registrarCandidate._id
  );

  const columns = useMemo(
    () =>
      [
        assigningRegistrar && {
          Header: "",
          id: "registrarSelection",
          Cell: ({ row }) => {
            if (registrarCandidatesIds.includes(row.original._id)) {
              return (
                <Checkbox
                  name={row.original._id}
                  checked={selectedRegistrar === row.original._id}
                  disabled={
                    selectedRegistrar !== row.original._id && selectedRegistrar
                  }
                  onChange={handleToggleRegistrar}
                />
              );
            }
            return <span> </span>;
          },
        },
        {
          Header: "Full Name",
          accessor: "fullName",
          className: "p-4 text-ellipsis overflow-hidden",
          Cell: ({ row: { original } }) => {
            const fullName = getFullName(original);
            if (
              CORPORATES.includes(original.idType) ||
              (original.designation === "Joint Shareholder" &&
                original.isDirector !== "Yes")
            ) {
              return (
                <div className="px-3">
                  <h1 className="font-bold">{fullName}</h1>
                  <p>
                    {original?.type === "invited"
                      ? getMaskedEmail(original?.emailAddress ?? "")
                      : original?.emailAddress ?? ""}
                  </p>
                </div>
              );
            }

            return (
              <div className="flex items-center space-x-1">
                <div className={`flex-shrink-0`}>
                  {original?.passportPhoto ? (
                    <img
                      className="w-8 h-8 rounded-full"
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${original?.passportPhoto}`}
                      alt={fullName}
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full">
                      <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                        <AvatarIcon />
                      </div>
                    </div>
                  )}
                </div>
                <div className="px-3">
                  <h1 className="font-bold">{fullName}</h1>
                  <p>
                    {original?.type === "invited"
                      ? getMaskedEmail(original?.emailAddress)
                      : original.emailAddress}
                  </p>
                </div>
              </div>
            );
          },
        },
        { Header: "ID Type", accessor: "idType" },
        {
          Header: "Designation",
          Cell: ({ row: { original } }) => {
            return Array.isArray(original.fullName)
              ? "Joint Shareholder"
              : original.isDirector === "Yes"
              ? "Director"
              : original.designation;
          },
        },
        {
          Header: "Shares Allotted",
          Cell: ({ row }) => {
            if (
              row.original?.isDirector === "Yes" &&
              !Array.isArray(row.original?.fullName)
            )
              return "";
            return (
              <span
                className="space-y-1"
                dangerouslySetInnerHTML={{
                  __html: transformSharesAllotted(
                    row.original?.sharesAllotted ?? []
                  ),
                }}
              />
            );
          },
        },
        {
          Header: "Status",
          Cell: ({ row }) => {
            if (row.original.type && row.original?.type === "invited")
              return row.original?.inviteStatus;
            return "";
          },
        },
        {
          Header: "Action",
          Cell: ({ row }) => {
            const isJointShareholderRow = Boolean(
              row.original.designation === "Joint Shareholder"
            );
            const unFlattenRow =
              originalCompanyOfficials.find(
                (official) => official._id === row.original._id
              ) || row.original;
            const isOwnerRow = [
              unFlattenRow?.electronicAddress?.emailAddress,
              unFlattenRow?.identificationDetails?.emailAddress,
              unFlattenRow?.identificationDetails?.guardianEmailAddress,
            ].includes(currentUser.electronicAddress.emailAddress);

            const isApplicant = currentUser?._id === company?.createdBy?._id;

            const disableEditBtn =
              (row.original?.type === "invited" ||
                role === Constants.COOKIE_ROLES.OFFICIAL) &&
              !isOwnerRow;

            const officialComments =
              comments.filter(
                (comment) =>
                  comment?.officialId &&
                  comment.officialId === row.original._id &&
                  comment?.tabName &&
                  comment.tabName === currentTab
              )?.length ?? 0;

            return (
              <div className="relative flex items-center space-x-2">
                {/* We need the following edit btn if the user want to edit from BO tab */}
                <button
                  type="button"
                  id={`${row.original?.JSGroupID ?? row?.original?._id}_view`}
                  onClick={() =>
                    isJointShareholderRow
                      ? handleEditAndDeleteJointShareholder({
                          ...row.original,
                          isViewOnly: true,
                        })
                      : handleEdit({ ...row.original, isViewOnly: true })
                  }
                  hidden
                >
                  View
                </button>
                <button
                  type="button"
                  id={row.original?.JSGroupID ?? row?.original?._id}
                  onClick={() =>
                    isJointShareholderRow
                      ? handleEditAndDeleteJointShareholder(row.original)
                      : handleEdit(row.original)
                  }
                  hidden
                >
                  Edit
                </button>
                <button
                  type="button"
                  id={`${
                    row.original?.JSGroupID ?? row?.original?._id
                  }_reviewButton`}
                  onClick={() => handleReview(row)}
                  hidden
                >
                  Review Button
                </button>
                <TableActionMenu
                  deleteConfirmationLabel="Are you sure you want to permanently delete this entry? This will also delete any linked beneficial owners, if present."
                  row={{
                    ...row,
                    original: {
                      ...row.original,
                      isJointShareholderRow,
                    },
                  }}
                  showRecallBtn={
                    row.original?.type &&
                    row.original?.type === "invited" &&
                    row.original?.inviteStatus === "Awaiting Data" &&
                    !isOwnerRow &&
                    unFlattenRow.createdBy === currentUser._id &&
                    !isJointShareholderRow
                  }
                  showResendInviteBtn={
                    row.original?.type &&
                    row.original?.type === "invited" &&
                    row.original?.inviteStatus === "Awaiting Data" &&
                    !isOwnerRow &&
                    unFlattenRow.createdBy === currentUser._id
                  }
                  showReviewBtn={
                    !isOwnerRow &&
                    row.original?.type === "invited" &&
                    unFlattenRow.createdBy === currentUser._id &&
                    !isJointShareholderRow
                  }
                  showViewBtn={role !== Constants.COOKIE_ROLES.OFFICIAL}
                  handleView={
                    isJointShareholderRow
                      ? handleEditAndDeleteJointShareholder
                      : handleView
                  }
                  handleRecall={handleRecallAccess}
                  handleEdit={
                    isJointShareholderRow
                      ? handleEditAndDeleteJointShareholder
                      : (row) =>
                          handleEdit({
                            ...row,
                            isViewOnly: disableEditBtn,
                          })
                  }
                  disableEditBtn={
                    disableEditBtn &&
                    (!isApplicant ||
                      !row.original?.designation.includes("Shareholder"))
                  }
                  disableDeleteBtn={
                    (row.original?.type === "invited" ||
                      role === Constants.COOKIE_ROLES.OFFICIAL) &&
                    !isOwnerRow &&
                    !isApplicant
                  }
                  handleDelete={(row) => {
                    row.isJointShareholderRow
                      ? handleEditAndDeleteJointShareholder(row)
                      : handleDelete(row);
                  }}
                  showDeleteConfirmation={!isJointShareholderRow}
                  handleResendInvite={handleResendInvite}
                />
                {officialComments ? (
                  <span className="absolute flex items-center justify-center min-w-[4] h-4 p-1 text-white bg-red-700 rounded-full -top-3 right-0">
                    {officialComments}
                  </span>
                ) : null}
              </div>
            );
          },
        },
      ].filter(Boolean),
    []
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <SubRowAsync
          row={{
            ...row,
            original: originalCompanyOfficials.find(
              (official) => official._id === row.original._id
            ),
          }}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          currentTab={currentTab}
        />
      );
    },
    [originalCompanyOfficials]
  );

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  const startIndex = (currentPage - 1) * rowsPerPage;
  const totalRows = props.data.length;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);

  return (
    <>
      <SectionDescription id="" title="Current Appointments:" description="" />
      <NotificationBanner notificationKey="flashMessageCOTable" />
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full bg-white shadow">
        <div className="w-full">
          <ReactTable
            columns={columns}
            data={
              totalRows > 15
                ? props.data.slice(startIndex, endIndex)
                : props.data
            }
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            setCurrentPage={setCurrentPage}
            paginate
            headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-slate-200"
            headingStyle="pl-4 text-left"
            dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            dataRowCellStyle="whitespace-normal"
            renderRowSubComponent={renderRowSubComponent}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyOfficialsTable;
