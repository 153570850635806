import { useEffect, useMemo, useState } from "react";
import { Formik, Field, Form } from "formik";
import Input from "components/lib/Shared/Input";
import Button from "components/lib/Shared/Button.jsx";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import ValidationError from "components/lib/Shared/ValidationError";
import { useDispatch, useSelector } from "react-redux";
import { updateCompanyDashboardState } from "state/slices/companyDashboard";
import { getCompanyDocumentsValidation } from "components/CompanyDashboard/BusinessDetails/companyDocumentsValidation";
import _pickBy from "lodash/pickBy";
import { handleFilesUpload } from "utils/filesUtils";
import { updateTemplateState } from "state/slices/template";
import Tabs from "components/lib/Shared/Tabs";

export default function CompanySummary() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { company } = useSelector((state) => state.companyDashboardSlice);
  const dispatch = useDispatch();

  const tabs = useMemo(() => [
    {
      id: "company",
      label: "1. Company Summary",
    },
    {
      id: "governance",
      label: "2. Governance Summary",
    },
  ]);

  const initialValues = {
    registrationName: "",
    registrationNumber: "",
    entityType: "",
    entityStatus: "",
    registrationDate: "",
    natureOfBusiness: "",
    kraPin: "",
    NSSFNo: "",
    NHIFNo: "",
    fileUploads: {
      kraPin: "",
      NSSFNo: "",
      NHIFNo: "",
    },
  };
  //const { articlesOfAssociation } = company;
  //const templateVersions = articlesOfAssociation?.template.versions ?? [];

  //const latestTemplateVersion = templateVersions[templateVersions.length - 1];

  return (
    <>
      <Tabs
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        showBorderBottom={false}
      />
      <div>
        {selectedTabIndex === 0 ? (
          <Formik
            initialValues={initialValues}
            validationSchema={() => getCompanyDocumentsValidation()}
            //onSubmit={handleFormSubmit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-6 space-y-4 bg-white border-gray-300 sm:p-6 form__wrapper">
                    <div className="InputWrapper flex ">
                      <div className="InputList flex flex-col w-full">
                        <div className="flex items-center InputComponent ">
                          <label className="mr-4 text-sm text-gray-600 w-1/4">
                            Registration Name
                          </label>
                          <div className="w-full items-start">
                            <Field
                              name="registrationName"
                              id="registrationName"
                              component={Input}
                              className="flex-grow p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="InputWrapper flex ">
                      <div className="InputList flex flex-col w-full">
                        <div className="flex items-center InputComponent ">
                          <label className="mr-4 text-sm text-gray-600 w-1/4">
                            Registration Number
                          </label>
                          <div className="w-full">
                            <Field
                              name="registrationNumber"
                              id="registrationNumber"
                              component={Input}
                              className="flex-grow p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="InputWrapper flex ">
                      <div className="InputList flex flex-col w-full">
                        <div className="flex items-center InputComponent ">
                          <label className="mr-4 text-sm text-gray-600 w-1/4">
                            Entity Type
                          </label>
                          <div className="w-full">
                            <Field
                              name="entityType"
                              id="enityType"
                              component={Input}
                              className="flex-grow p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="InputWrapper flex ">
                      <div className="InputList flex flex-col w-full">
                        <div className="flex items-center InputComponent ">
                          <label className="mr-4 text-sm text-gray-600 w-1/4">
                            Entity Status
                          </label>
                          <div className="w-full">
                            <Field
                              name="enityStatus"
                              id="entityStatus"
                              component={Input}
                              className="flex-grow p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="InputWrapper flex ">
                      <div className="InputList flex flex-col w-full">
                        <div className="flex items-center InputComponent ">
                          <label className="mr-4 text-sm text-gray-600 w-1/4">
                            Registration Date
                          </label>
                          <div className="w-full">
                            <Field
                              name="registrationDate"
                              id="registrationDate"
                              component={Input}
                              className="flex-grow p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="InputWrapper flex ">
                      <div className="InputList flex flex-col w-full">
                        <div className="flex items-center InputComponent ">
                          <label className="mr-4 text-sm text-gray-600 w-1/4">
                            Nature of business
                          </label>
                          <div className="w-full">
                            <Field
                              name="natureOfBusiness"
                              id="natureOfBusiness"
                              component={Input}
                              className="flex-grow p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="InputWrapper flex ">
                      <div className="InputList flex flex-col w-full">
                        <div className="flex items-start InputComponent ">
                          <label className="mr-4 text-sm text-gray-600 w-1/4">
                            KRA PIN
                          </label>
                          <div className="w-full">
                            <Field
                              name="kraPin"
                              id="kraPin"
                              placeholder="Add KRA PIN"
                              component={Input}
                              className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                            <div className="mt-1">
                              <label className="block mb-1 text-sm text-gray-600">
                                KRA PIN Certificate
                              </label>
                              <Field
                                name="fileUploads"
                                component={FileUploadForm}
                                wrapperDivStyles="py-2"
                                documentData={[{ label: "", name: "kraPin" }]}
                                onlyPreview={false}
                              />
                              <ValidationError
                                errors={errors.fileUploads}
                                touched={touched.fileUploads}
                                name={"fileUploads.kraPin"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="InputWrapper flex ">
                      <div className="InputList flex flex-col w-full">
                        <div className="flex items-start InputComponent ">
                          <label className="mr-4 text-sm text-gray-600 w-1/4">
                            NSSF Number
                          </label>
                          <div className="w-full">
                            <Field
                              name="NSSFNo"
                              id="NSSFNo"
                              placeholder="Add NSSF Number"
                              component={Input}
                              className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                            <div className="mt-1">
                              <label className="block mb-1 text-sm text-gray-600">
                                NSSF Registration Certificate
                              </label>
                              <Field
                                name="fileUploads"
                                component={FileUploadForm}
                                wrapperDivStyles="py-2"
                                documentData={[{ label: "", name: "NSSFNo" }]}
                                onlyPreview={false}
                              />
                              <ValidationError
                                errors={errors.fileUploads}
                                touched={touched.fileUploads}
                                name={"fileUploads.NSSFNo"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="InputWrapper flex ">
                      <div className="InputList flex flex-col w-full">
                        <div className="flex items-start InputComponent ">
                          <label className="mr-4 text-sm text-gray-600 w-1/4">
                            NHIF Number
                          </label>
                          <div className="w-full">
                            <Field
                              name="NHIFNo"
                              id="NHIFNo"
                              placeholder="Add NHIF Number"
                              component={Input}
                              className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            />
                            <div className="mt-1">
                              <label className="block mb-1 text-sm text-gray-600">
                                NHIF Registration Certificate
                              </label>
                              <Field
                                name="fileUploads"
                                component={FileUploadForm}
                                wrapperDivStyles="py-2"
                                documentData={[{ label: "", name: "NHIFNo" }]}
                                onlyPreview={false}
                              />
                              <ValidationError
                                errors={errors.fileUploads}
                                touched={touched.fileUploads}
                                name={"fileUploads.NHIFNo"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="px-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                      <div className="flex justify-between w-full">
                        <div className="InputWrapper flex w-1/2">
                          <div className="InputList flex flex-col w-full">
                            <div className="flex items-center InputComponent ">
                              <label className="mr-4 text-sm text-gray-600 w-1/4">
                                Annual Return Date
                              </label>
                              <div className="w-full">
                                <Field
                                  name="natureOfBusiness"
                                  id="natureOfBusiness"
                                  component={Input}
                                  className="flex-grow p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="InputWrapper flex w-1/2">
                          <div className="InputList flex flex-col w-full">
                            <div className="flex items-center InputComponent ">
                              <label className="mr-4 text-sm text-gray-600 w-1/4">
                                Financial Year
                              </label>
                              <div className="w-full">
                                <Field
                                  name="natureOfBusiness"
                                  id="natureOfBusiness"
                                  component={Input}
                                  className="flex-grow p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ProceedWrapper flex justify-end space-x-2 py-3">
                      <Button
                        disabled={isSubmitting}
                        className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed`}
                        //onClick={handleFormClose}
                      >
                        <div className="mr-1.5 flex items-center">Cancel</div>
                      </Button>
                      <Button
                        type="submit"
                        isLoading={isSubmitting}
                        loadingText="Saving..."
                        className="flex px-2 py-2 text-white button button-hover button-disabled button-outline button-focus bg-sflPrimary"
                      >
                        <div className="ml-1.5 flex items-center">Save</div>
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <h2 className="font-medium">Articles</h2>
          </>
        )}
      </div>
    </>
  );
}
