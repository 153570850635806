import axios from "../axios";

const getAll = (corporateId) =>
  axios.get(`/partners?corporateId=${corporateId}`);

const create = (data) => axios.post("/partners", data);

const update = (partnerId, data) => axios.put(`/partners/${partnerId}`, data);

const remove = (partnerId, idType, corporateId) => {
  return axios.delete(
    `/partners/${partnerId}?idType=${idType}&corporateId=${corporateId}`
  );
};

const partnerDataService = {
  getAll,
  create,
  update,
  remove,
};

export default partnerDataService;
