import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

export default function Dialog(props) {
  const { open, onClose } = props;

  return (
    <Transition
      show={open}
      appear={true}
      enter="transition-opacity ease-linear duration-100"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-linear duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex bg-gray-400/50">
        <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
          <div>{props.children}</div>
          <span className="absolute top-0 right-0 p-4">
            <button
              type="button"
              onClick={() => onClose()}
              className={`focus:outline-none focus:border-none hover:bg-gray-400 hover:bg-opacity-25 p-2 rounded-full inline-flex items-center`}
            >
              <XIcon className="h-6 w-6" />
            </button>
          </span>
        </div>
      </div>
    </Transition>
  );
}
