import { Constants } from "config/constants";
import { object, string, number, mixed, lazy } from "yup";

/**
 * This returns an object composed of conditional `yup` schema and initial values
 * related to residential details form.
 *
 * @param {object} validationParams - Object containing `idType` and `isNonResident` properties of official.
 * @param {string} validationParams.idType - The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {string} validationParams.isNonResident - Conditional string value `'No' || 'Yes` of the current official.
 * @return {object} This returns object containing schema and initial values based on schema.
 * @example
 *  {
 *    schema
 *    schemaInitialValues
 *  }
 */
const residentialDetailsValidation = ({ idType, isNonResident }) => {
  let schema = object()
    .shape({
      isNonResident: string().when("idType", {
        is: () => ["Kenyan Citizen", "Minor"].includes(idType),
        then: string().required("Please select one option").default("No"),
      }),
      postalAddress: object()
        .when("isNonResident", {
          is: (value) => {
            return (
              (value && value === "No" && idType !== "Foreigner") ||
              [
                "Local Company",
                "Other e.g Parastatals",
                "Foreign Resident",
              ].includes(idType)
            );
          },
          then: object().shape({
            postalAddress: lazy((value) => {
              if (!value)
                return mixed().when("$makeFieldsOptional", {
                  is: (makeFieldsOptional) => makeFieldsOptional,
                  then: mixed().optional(),
                  otherwise: mixed().required("Postal address is required"),
                });
              const typeofField = [
                "Kenyan Citizen",
                "Foreign Resident",
              ].includes(idType)
                ? "number"
                : "string";
              switch (typeofField) {
                case "number":
                  return number("Enter valid postal address")
                    .typeError("Postal address must be a number")
                    .strict(true)
                    .when("$makeFieldsOptional", {
                      is: (makeFieldsOptional) => makeFieldsOptional,
                      then: number().optional(),
                      otherwise: number().required(
                        "Postal address is required"
                      ),
                    });
                case "string":
                  return string()
                    .trim(
                      "Postal address cannot include leading and trailing spaces"
                    )
                    .strict(true)
                    .when("$makeFieldsOptional", {
                      is: (makeFieldsOptional) => makeFieldsOptional,
                      then: string().optional(),
                      otherwise: string().required(
                        "Postal address is required"
                      ),
                    });
                default:
                  return mixed();
              }
            }),
            postalCode: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Postal code is required"),
            }),
            serviceAddress: string()
              .trim(
                "Service address cannot include leading and trailing spaces"
              )
              .strict(true)
              .optional(),
          }),
          otherwise: object().shape({
            postalAddress: lazy((value) => {
              if (!value)
                return mixed().when("$makeFieldsOptional", {
                  is: (makeFieldsOptional) => makeFieldsOptional,
                  then: mixed().optional(),
                  otherwise: mixed().required("Postal address is required"),
                });
              const typeofField = [
                "Kenyan Citizen",
                "Foreign Resident",
              ].includes(idType)
                ? "number"
                : "string";
              switch (typeofField) {
                case "number":
                  return number("Enter valid postal address")
                    .typeError("Postal address must be a number")
                    .strict(true)
                    .when("$makeFieldsOptional", {
                      is: (makeFieldsOptional) => makeFieldsOptional,
                      then: number().optional(),
                      otherwise: number().required(
                        "Postal address is required"
                      ),
                    });
                case "string":
                  return string()
                    .trim(
                      "Postal address cannot include leading and trailing spaces"
                    )
                    .strict(true)
                    .when("$makeFieldsOptional", {
                      is: (makeFieldsOptional) => makeFieldsOptional,
                      then: string().optional(),
                      otherwise: string().required(
                        "Postal address is required"
                      ),
                    });
                default:
                  return mixed().when("$makeFieldsOptional", {
                    is: (makeFieldsOptional) => makeFieldsOptional,
                    then: mixed().optional(),
                    otherwise: mixed().required("Postal address is required"),
                  });
              }
            }),
            serviceAddress: string()
              .trim(
                "Service address cannot include leading and trailing spaces"
              )
              .strict(true)
              .optional(),
          }),
        })
        .default({ postalAddress: "", postalCode: "", serviceAddress: "" }),
    })
    .default({});

  const electronicAddressSchema = object().shape({
    electronicAddress: object().shape({
      emailAddress: string()
        .email("Invalid email format")
        .trim("Email cannot include leading and trailing spaces")
        .strict(true)
        .when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Email address is required"),
        }),
      mobileNumber: string()
        .trim("Mobile number cannot include leading and trailing spaces")
        .when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Mobile number is required"),
        })
        .matches(Constants.PHONE_NUMBER_REGEX, "Phone number is not valid")
        .test("length", function (value) {
          if (
            value &&
            [
              "Kenyan Citizen",
              "Foreign Resident",
              "Local Company",
              "Other e.g Parastatals",
            ].includes(idType)
          ) {
            if (value.length !== 13) {
              return this.createError({
                message: "Mobile number must be exactly 13 characters",
              });
            }
            if (!value.startsWith("+254")) {
              return this.createError({
                message: "Correct format is +254#########",
              });
            }
            return true;
          } else if (
            value &&
            (value.length < 11 || value.length > 13) &&
            ["Foreigner", "Foreign Company"].includes(idType)
          ) {
            return this.createError({
              message: "Mobile number is not correct",
            });
          } else return true;
        }),
    }),
  });

  let residentialAddressDetailsSchema = object().shape({
    residentialAddressDetails: object().shape({
      buildingName: string()
        .trim("Building name cannot include leading and trailing spaces")
        .strict(true)
        .when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Building name is required"),
        }),
      houseNumber: string()
        .trim("House number cannot include leading and trailing spaces")
        .strict(true)
        .optional(),
      estate: string()
        .trim("Estate cannot include leading and trailing spaces")
        .strict(true)
        .when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Estate is required"),
        }),
      floorNumber: string()
        .trim("Floor number cannot include leading and trailing spaces")
        .strict(true)
        .optional(),
      streetName: string()
        .trim("Street name cannot include leading and trailing spaces")
        .strict(true)
        .optional(),
      streetNumber: string()
        .trim("Street number cannot include leading and trailing spaces")
        .strict(true)
        .optional(),
    }),
  });

  if (
    ["Foreigner", "Foreign Company"].includes(idType) ||
    (isNonResident && isNonResident === "Yes")
  ) {
    residentialAddressDetailsSchema = residentialAddressDetailsSchema.concat(
      object().shape({
        residentialAddressDetails: object().shape({
          city: string()
            .trim("City cannot include leading and trailing spaces")
            .strict(true)
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("City is required"),
            }),
          country: string()
            .trim("Country cannot include leading and trailing spaces")
            .strict(true)
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Country is required"),
            }),
        }),
      })
    );
  } else {
    residentialAddressDetailsSchema = residentialAddressDetailsSchema.concat(
      object().shape({
        residentialAddressDetails: object().shape({
          county: string()
            .trim("County cannot include leading and trailing spaces")
            .strict(true)
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("County is required"),
            }),
          district: string()
            .trim("District cannot include leading and trailing spaces")
            .strict(true)
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("District is required"),
            }),
          locality: string()
            .trim("Locality cannot include leading and trailing spaces")
            .strict(true)
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Locality is required"),
            }),
        }),
      })
    );
  }

  schema = schema.concat(electronicAddressSchema).concat(
    residentialAddressDetailsSchema.default({
      residentialAddressDetails: {
        ...residentialAddressDetailsSchema.cast().residentialAddressDetails,
        city: "",
        country: "",
        county: "",
        district: "",
        locality: "",
      },
    })
  );

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default residentialDetailsValidation;
