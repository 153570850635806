import React from "react";

function PointerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#F3F4F6" rx="16"></rect>
      <g clipPath="url(#clip0_70_21965)">
        <path
          fill="#1F2937"
          d="M14.75 9a.75.75 0 01.75.75V16a.5.5 0 001 0v-2.533l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V16a.5.5 0 001 0v-1.565l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.08.096.15.19.2.259V16.5a.5.5 0 001 0v-1h.342a1 1 0 01.995 1.1l-.271 2.715a2.5 2.5 0 01-.317.991l-1.395 2.442a.5.5 0 01-.434.252h-6.302a.5.5 0 01-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 01.809-.631l2.41 2.41A.5.5 0 0014 17.5V9.75a.75.75 0 01.75-.75zm1.75 3.466V9.75a1.75 1.75 0 00-3.5 0v6.543l-1.557-1.557a1.517 1.517 0 00-2.373 1.852l2.49 4.153 1.216 2.43a1.5 1.5 0 001.342.829h6.302a1.5 1.5 0 001.302-.756l1.395-2.441a3.5 3.5 0 00.444-1.389l.27-2.715a2 2 0 00-1.99-2.199h-.58a5.011 5.011 0 00-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 00-.56-.642 2.63 2.63 0 00-.738-.288c-.31-.062-.74-.058-1.05-.046l-.048.002z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_70_21965">
          <path fill="#fff" d="M0 0H16V16H0z" transform="translate(8 8)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PointerIcon;
