/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useHistory, Link } from "react-router-dom";
import { useToast } from "hooks/useToast";
import ReactTable from "components/lib/Global/ReactTable";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import { deleteCompany } from "state/slices/companies";
import { capitalizeFirstLetter, getFullName } from "utils";
import { updateLinkedCompanies } from "state/slices/user";
import { handleRecallApplication } from "state/slices/company";
import { getTransactionsAsync } from "state/slices/transactions";
import {
  CORPORATES,
  INDIVIDUALS,
} from "components/PLCTABS/SFLdata/config/constants";
import SearchInput from "components/lib/Shared/SearchInput";
import { useDebounce } from "use-debounce";
import { getCompanyNameWithSuffix } from "utils/company";
import { setCookie } from "utils/cookie";
import { Constants } from "config/constants";

const TransactionsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, user } = useAuth0();
  const { toast } = useToast(4000);
  const currentUser = useSelector((state) => state.userSlice);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isRecalling, setIsRecalling] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  const [searchTerm, setSearchTerm] = useState("");

  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const { transactions, getTransactions } = useSelector(
    (state) => state.transactionsSlice
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (debouncedSearchTerm) setCurrentPage(1);
      dispatch(
        getTransactionsAsync({
          limit: rowsPerPage,
          skip: (currentPage - 1) * rowsPerPage,
          search: debouncedSearchTerm,
          order: "desc",
        })
      );
    }
  }, [
    dispatch,
    isAuthenticated,
    user,
    currentPage,
    debouncedSearchTerm,
    rowsPerPage,
  ]); // Update if authState changes

  const handleRecall = async (company, rowIndex) => {
    setSelectedRowIndex(rowIndex);
    setIsRecalling(true);
    try {
      if (company.createdBy._id === currentUser._id) {
        // for the applicant
        await dispatch(handleRecallApplication({ companyId: company?._id }));
        await dispatch(
          getTransactionsAsync({
            limit: rowsPerPage,
            skip: 0,
            order: "desc",
          })
        );
        return;
      }
      const updatedLinkedCompanies = currentUser?.linkedCompanies.map(
        (linkedCompany) => {
          if (linkedCompany.company?._id === company?._id) {
            return {
              ...linkedCompany,
              status: "review",
            };
          }
          return linkedCompany;
        }
      );
      await dispatch(
        updateLinkedCompanies({
          data: {
            companyId: company?._id,
            status: "review",
          },
          updatedLinkedCompanies,
        })
      );
    } catch (error) {
    } finally {
      setIsRecalling(false);
      setSelectedRowIndex(null);
    }
  };

  const handleDelete = async (companyId, rowIndex) => {
    setIsDeleting(true);
    setSelectedRowIndex(rowIndex);
    try {
      await dispatch(deleteCompany({ companyId }));
      await dispatch(
        getTransactionsAsync({
          limit: rowsPerPage,
          skip: 0,
          order: "desc",
        })
      );
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsDeleting(true);
      setSelectedRowIndex(null);
    }
  };

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Details",
        accessor: "details",
        Cell: ({ row: { original } }) => {
          if (CORPORATES.includes(original?.idType)) {
            return original.names.companyName;
          }

          if (INDIVIDUALS.includes(original?.idType)) {
            return getFullName(original.names);
          }
          return getCompanyNameWithSuffix(original.company);
        },
      },
      {
        Header: "Application Type",
        accessor: "applicationType",
        Cell: ({ row }) => {
          let type = "N/A";
          if (INDIVIDUALS.includes(row?.original?.idType)) type = "Profile";
          else if (CORPORATES.includes(row?.original?.idType)) {
            type = "Organization";
          } else {
            type = row?.original?.company?.type;
          }
          return (
            <div className="inline-flex items-center justify-center w-full px-3 py-1 mx-auto text-sm font-medium text-white rounded-full bg-green-primary">
              {type}
            </div>
          );
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        Cell: ({ row: { original } }) => {
          if (CORPORATES.includes(original?.idType)) {
            return getFullName(original.createdBy.names);
          }
          if (INDIVIDUALS.includes(original?.idType))
            return getFullName(original.names);
          return capitalizeFirstLetter(
            original?.company?.createdBy?.names?.firstName ?? "N/A"
          );
        },
      },
      {
        Header: "Individual Officials",
        Cell: ({ row: { original } }) => {
          if (CORPORATES.includes(original?.idType)) {
            return original?.individualOfficials?.length ?? 0;
          }
          if (INDIVIDUALS.includes(original?.idType)) return "";
          return original?.company?.individualOfficials?.length ?? 0;
        },
      },
      {
        Header: "Corporate Officials",
        Cell: ({ row: { original } }) => {
          if (CORPORATES.includes(original?.idType)) {
            return original?.corporateOfficials?.length ?? 0;
          }
          if (INDIVIDUALS.includes(original?.idType)) return "";
          return original?.company?.corporateOfficials?.length ?? 0;
        },
      },
      {
        Header: "Status",
        Cell: ({ row }) => {
          const { original } = row;
          const showLoader =
            row.index === selectedRowIndex && (isRecalling || isDeleting);
          const loadingText = isRecalling
            ? "Recalling"
            : isDeleting
            ? "Deleting"
            : "";
          if (showLoader) {
            return (
              <div className="flex space-x-2">
                <span>{loadingText}</span>
                <SpinnerIcon className="text-gray-600" />
              </div>
            );
          }
          let status = "N/A";
          if (
            INDIVIDUALS.includes(original?.idType) ||
            CORPORATES.includes(original?.idType)
          ) {
            return original?.status ?? "N/A";
          }
          if (original?.company?.status) status = original?.company?.status;
          return status === "Active" ? (
            <div className="inline-flex items-center justify-center w-full px-3 py-1 mx-auto text-sm font-medium text-white rounded-full bg-green-primary">
              Registered
            </div>
          ) : (
            status
          );
        },
      },
      {
        Header: "Date",
        Cell: ({ row: { original } }) => {
          if (
            INDIVIDUALS.includes(original?.idType) ||
            CORPORATES.includes(original?.idType)
          ) {
            return format(new Date(original?.createdAt), "yyyy/MM/dd");
          }
          return original?.company?.createdAt
            ? format(new Date(original?.company?.createdAt), "yyyy/MM/dd")
            : "N/A";
        },
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          const { original } = row;
          if (INDIVIDUALS.includes(original?.idType)) {
            return <Link to="/profile">View</Link>;
          }
          const { role, status, company, partnershipType } = original || {};
          if (partnershipType) {
            // for organizations
            const isContactPerson =
              original?.identificationDetails?.emailAddress ===
              currentUser?.email;
            const role = isContactPerson ? 7 : 6;

            return (
              <TableActionMenu
                row={row}
                handleEdit={() => {
                  setCookie("role", role);
                  history.push(`/corporates/${original._id}`);
                }}
                hideDeleteBtn={!isContactPerson}
                handleDelete={() => handleDelete(original._id, row.index)}
                hideEditBtn={
                  (!isContactPerson || status !== "Draft") && role !== 6
                }
                disableEditBtn={!isContactPerson && role !== 6}
                disableDeleteBtn={!isContactPerson}
                showViewBtn={status !== "Draft"}
                handleView={() => {
                  setCookie("role", role);
                  history.push(`/corporates/${original._id}/view`);
                }}
              />
            );
          }
          const createdByCurrentUser =
            company?.createdBy?._id === currentUser?._id;

          if ((isDeleting || isRecalling) && row.index === selectedRowIndex)
            return null;
          const isCoApplicant = role === Constants.ROLES.CO_APPLICANT;

          return (
            // for companies
            <TableActionMenu
              row={row}
              handleEdit={() => {
                history.push(`/companies/${company._id}`);
                if (
                  [
                    Constants.ROLES.OFFICIAL,
                    Constants.ROLES.APPLICANT,
                    Constants.ROLES.CO_APPLICANT,
                  ].includes(role)
                ) {
                  setCookie("role", role);
                }
              }}
              hideDeleteBtn={!createdByCurrentUser}
              handleDelete={() => handleDelete(company._id, row.index)}
              hideEditBtn={
                ((!createdByCurrentUser && !isCoApplicant) ||
                  ![
                    "Draft",
                    "Directors Corrections",
                    "Admin Corrections",
                    "Submission Corrections",
                    "Signature",
                    "Signature Corrections",
                    "BRS Corrections",
                  ].includes(company?.status)) &&
                role !== Constants.ROLES.OFFICIAL
              }
              disableEditBtn={
                !createdByCurrentUser &&
                !isCoApplicant &&
                role !== Constants.ROLES.OFFICIAL
              }
              disableDeleteBtn={!createdByCurrentUser}
              showViewBtn={
                role === Constants.ROLES.REVIEWER || company?.status !== "Draft"
              }
              showRecallBtn={
                (role === Constants.ROLES.REVIEWER &&
                  status === "Admin Review") ||
                (company?.status === "Directors Review" && createdByCurrentUser)
              }
              handleView={() => {
                setCookie("role", role);
                history.push(`/companies/${company._id}/view`);
              }}
              handleRecall={() => handleRecall(company, row.index)}
            />
          );
        },
      },
    ],
    [currentUser]
  );

  return (
    <div className="mt-6">
      <div className="my-16">
        <h1 className="text-2xl font-medium text-primary">Transactions</h1>
      </div>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>

      <div className="grid grid-cols-6 border-t border-gray">
        <div className="col-span-6 pr-8 md:col-span-1 pt-14">
          <div>
            <h4 className="mb-4 font-medium text-sflPrimary">
              My Applications
            </h4>
          </div>
        </div>
        <div className="col-span-6 md:border-l md:col-span-5 md:pl-11 pt-14 border-gray">
          {transactions?.items?.length > 0 ? (
            <div className="w-full bg-white shadow">
              <ReactTable
                columns={columns}
                data={transactions?.items}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                totalRows={transactions?.totalCount}
                setCurrentPage={setCurrentPage}
                paginate
                headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
                headingStyle="pl-4 text-left"
                dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
                dataRowCellStyle="pl-4 leading-snug py-2"
                isLoading={getTransactions.status === "loading"}
              />
            </div>
          ) : (
            <>
              {getTransactions.status === "loading" ? (
                <div className="z-10 flex items-center justify-center mt-10">
                  <SpinnerIcon className="text-gray-400" />
                </div>
              ) : (
                <p className="text-center">No transaction found</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionsList;
