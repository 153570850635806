import { object, string, array } from "yup";

/**
 * This returns an object composed of conditional `yup` schema and initial values
 * related to state owned enterprise validation.
 *
 * @return {object} This returns object containing schema and initial Values based on schema.
 * @example
 *  {
 *    schema // yup schema
 *    schemaInitialValues
 *  }
 */
const stateOwnedEnterpriseValidation = () => {
  const schema = object().shape({
    role: array().required("Role is required").default(""),
    nameOfStateAgency: string()
      .required("Name of the state agency is required")
      .default(""),
    nameOfGovernment: string()
      .required("Name of the government is required")
      .default(""),
    country: string().required("Country is required").default(""),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default stateOwnedEnterpriseValidation;
