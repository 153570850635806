import React, { useMemo } from "react";
import { Field } from "formik";
import options from "../../PLCTABS/SFLdata/selectOptions";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Input from "components/lib/Shared/Input";

export const BillingDetails = ({ country, idType, setFieldValue }) => {
  const countriesOptions = useMemo(() => {
    return options.countries.map((country) => {
      if (
        ["Foreign Resident", "Foreigner", "Foreign Company"].includes(idType) &&
        country.value === "KE"
      ) {
        return { ...country, disabled: true };
      }
      return country;
    });
  }, [idType, options.countries]);

  const handleSelectCountry = (option) => {
    setFieldValue("country", { label: option.label, value: option.value });
  };

  return (
    <div className="shadow sm:rounded-md">
      <div className="bg-white py- px-4 space-y-6 sm:p-4">
        <div className="PaymentDetails">
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="First Name"
                name="firstName"
                placeholder="Enter your first name"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="Last Name"
                name="lastName"
                placeholder="Enter your last name"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                showRequiredAsterisk
                label="Email Address"
                name="email"
                placeholder="Enter your email address"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="Phone Number"
                name="mobileNumber"
                placeholder="Enter your phone number"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                showRequiredAsterisk
                label="Billing Address"
                name="billingAddress"
                placeholder="Enter your billing address"
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                showRequiredAsterisk
                label="Zip Code / Postal Code"
                name="zipCode"
                placeholder="Enter your zip code"
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                showRequiredAsterisk
                label="City"
                name="city"
                placeholder="Enter your City"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              {/* <Field
                component={Input}
                showRequiredAsterisk
                label="Country"
                name="country"
                placeholder="Enter your country"
              /> */}

              <Field
                // {...field}
                label="Country"
                value={country}
                name="country"
                component={ReactSelectWithFormik}
                options={countriesOptions}
                errorPath="country"
                placeholder="Select country"
                // onBlur={field.onBlur}
                onChange={handleSelectCountry}
                isSearchable
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="KRA Pin"
                name="pin"
                placeholder="Enter your KRA pin"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
