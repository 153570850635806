import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { format } from "date-fns";

import ReactTable from "components/lib/Global/ReactTable";
import Sidebar from "components/Applications/Sidebar";
import HeroSection from "components/Applications/HeroSection";
import OrgAdminDashboardWrapper from "components/lib/Global/OrgAdminDashboardWrapper";
import SearchInput from "components/lib/Shared/SearchInput";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { getCompaniesAsync } from "state/slices/companies";
import SubRowAsync from "./SubRow";
import { Constants } from "config/constants";
import Checkbox from "components/lib/Shared/Checkbox";
import GoBackToHomeBtn from "../GoBackToHomeBtn";
import { getCompanyNameWithSuffix } from "utils/company";

export default function Pending() {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useAuth0();
  const location = useLocation();
  const history = useHistory();
  const role = Cookies.get("role");
  const [statuses, setStatuses] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const { getUserStatus } = useSelector((state) => state.authSlice);
  const { getCompanies, companies } = useSelector(
    (state) => state.companiesSlice
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState(() =>
    statuses.map((status) => status.value)
  );

  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (role === Constants.COOKIE_ROLES.ORG_ADMIN) {
      setStatuses([
        { value: "Admin Review", label: "Admin Review" },
        { value: "Admin Review II", label: "Admin Review II" },
        { value: "Admin Corrections", label: "Admin Corrections" },
      ]);
    } else if (role === Constants.COOKIE_ROLES.SUPER_ADMIN) {
      setStatuses([
        { value: "Submission", label: "Submission" },
        { value: "Submission II", label: "Submission II" },
        { value: "Submission Corrections", label: "Submission Corrections" },
      ]);
    }
    setIsMounted(true);
  }, [role]);

  useEffect(() => {
    if (isAuthenticated && isMounted) {
      if (debouncedSearchTerm) setCurrentPage(1);
      const queryParams = new URLSearchParams(location.search);
      const status = queryParams.getAll("status");
      setFilters(status);
      dispatch(
        getCompaniesAsync({
          limit: rowsPerPage,
          skip: (currentPage - 1) * rowsPerPage,
          search: debouncedSearchTerm,
          status: status.length > 0 ? status : null,
          order: "asc",
        })
      );
    }
  }, [
    isAuthenticated,
    rowsPerPage,
    currentPage,
    debouncedSearchTerm,
    statuses,
    isMounted,
    location.search,
  ]);

  const currentUser = useSelector((state) => state.userSlice);

  const refreshTableData = async () => {
    let statuses = [];
    if (role === Constants.COOKIE_ROLES.ORG_ADMIN) {
      statuses = ["Admin Review", "Admin Corrections", "Admin Review II"];
    } else if (role === Constants.COOKIE_ROLES.SUPER_ADMIN) {
      statuses = ["Submission", "Submission Corrections", "Submission II"];
    }
    dispatch(
      getCompaniesAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm,
        status: statuses,
        order: "asc",
      })
    );
  };

  // this function will render our row sub components
  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <SubRowAsync
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          refreshTableData={refreshTableData}
        />
      );
    },
    []
  );

  if (!currentUser?._id || getUserStatus.status === "loading") {
    return (
      <OrgAdminDashboardWrapper>
        <div className="flex justify-center mx-auto mt-10 text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </OrgAdminDashboardWrapper>
    );
  }

  const columns = [
    {
      Header: "Company Name",
      accessor: "companyName",
      className: "p-4 whitespace-normal max-w-[150px]",
      Cell: ({ row }) => getCompanyNameWithSuffix(row.original),
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row }) => row.original.type,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        if (row.original.status.includes("II")) {
          return (
            <>
              {row.original.status}{" "}
              <span className="items-center gap-x-1.5 rounded-full px-2 py-1 text-xxs font-medium text-gray-900 ring-1 ring-inset ring-green-primary bg-green-100/25 tracking-wide">
                Corrected
              </span>
            </>
          );
        }
        if (
          ["Admin Review", "Submission", "BRS Review"].includes(
            row.original.status
          )
        ) {
          return (
            <>
              {row.original.status}{" "}
              <span className="items-center gap-x-1.5 rounded-full px-2 py-1 text-xxs font-medium text-gray-900 ring-1 ring-inset ring-primary-200 bg-purple-100/25 tracking-wide">
                New
              </span>
            </>
          );
        }
        return row.original.status;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) =>
        row.original?.createdAt
          ? format(new Date(row.original?.createdAt), "yyyy/MM/dd")
          : "N/A",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            {...row?.getToggleRowExpandedProps()}
            className={`text-gray-900 group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
          >
            {row.isExpanded ? "Close" : "View"}
          </button>
        );
      },
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  const handleFilterChange = (e) => {
    const params = new URLSearchParams();
    if (e.target.checked) {
      const tempFilters = [...filters, e.target.value];
      tempFilters.forEach((status) => params.append("status", status));
      history.push({ search: params.toString() });
      setFilters([...filters, e.target.value]);
    } else {
      const tempFilters = filters.filter((filter) => filter !== e.target.value);
      tempFilters.forEach((status) => params.append("status", status));
      history.push({ search: params.toString() });
      setFilters(tempFilters);
    }
    setCurrentPage(1);
  };

  return (
    <OrgAdminDashboardWrapper>
      <div className="grid gap-4 py-10 mx-auto">
        <HeroSection />
        <GoBackToHomeBtn />
        <div className="grid grid-cols-6 border-t border-gray">
          <div className="col-span-6 pr-8 md:col-span-1">
            <Sidebar />
          </div>
          <div className="col-span-6 pt-8 md:border-l md:col-span-5 md:pl-8 border-gray">
            <div className="mb-6">
              <h1 className="text-title5">Pending Applications</h1>
            </div>
            <div className="flex items-center justify-between my-2">
              <div className="flex items-center space-x-4">
                <h2 className="font-semibold">Filter by:</h2>
                <div className="flex my-2 space-x-2">
                  {statuses.map((status) => (
                    <Checkbox
                      key={status.value}
                      name={status.label}
                      label={status.label}
                      checked={filters.includes(status.value)}
                      onChange={handleFilterChange}
                    />
                  ))}
                </div>
              </div>
              <SearchInput value={searchTerm} handleOnChange={handleSearch} />
            </div>
            {companies.totalCount > 0 ? (
              <ReactTable
                columns={columns}
                data={companies.items}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                totalRows={companies.totalCount}
                paginate
                headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-slate-200"
                headingStyle="pl-4 text-left"
                dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
                renderRowSubComponent={renderRowSubComponent}
                isLoading={getCompanies.status === "loading"}
              />
            ) : (
              <p className="text-2xl text-center text-primary">
                No applications available.
              </p>
            )}
          </div>
        </div>
      </div>
    </OrgAdminDashboardWrapper>
  );
}
