/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from "react";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useFormikContext } from "formik";
import {
  EyeIcon,
  EyeOffIcon,
  UploadIcon,
  PencilIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";

import UploadedFileView from "./UploadedFileView";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import CommentsPopup from "components/lib/Global/CommentsPopup";
import ValidationError from "components/lib/Shared/ValidationError";
import CommentsList from "components/lib/Global/CommentsList";
import { Constants } from "config/constants";
import { resolveFields, invalidateFields } from "state/slices/resolveField";
import { formatBytes, replaceAll } from "utils";
import { getComments, getProfileComments } from "state/slices/comment";

const acceptStyle = { borderColor: "#00e676" };
const rejectStyle = { borderColor: "#ff1744" };

const mapFileTypes = {
  "image/jpeg": ".jpeg",
  "image/jpg": ".jpg",
  "image/png": ".png",
  "application/pdf": ".pdf",
};

const FILE_UPLOAD_ERRORS = {
  "file-too-large": "File can not be larger than 2 MB",
};

const Dropzone = (props) => {
  const {
    document,
    onlyPreview,
    files,
    setFiles,
    showDocumentLabel = false,
    addReviewComment,
    handleSaveComment,
    formName,
    comments = [],
    formId,
    formHelperKey,
    prefixDocumentName,
    updateEffectKey = "",
    updateEffectPath = "",
    isLoading = false,
    editorParams = {},
    previewFileName,
    selectedRows = [],
    setIsLoading = () => null,
    setSelectedRow = () => null,
    fileUploadComments = [],
    setPreviewFileName,
    isViewMode = false,
    isProfileReview,
    rowId,
    showResolveAllFieldsCheckbox,
    wrapperDivStyles = "py-4",
    disabled,
    horizontalLayout = false,
    showRequiredAsterisk,
    isManageCompanyModule = false,
  } = props;

  const currentUser = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();
  const history = useHistory();
  const isReviewerMode =
    history.location.pathname.split("/").pop() === "review";
  let resolvedFields = props?.resolvedFields
    ? props.resolvedFields
    : _get(props.form?.values, "resolvedFields", []);
  const tempDocumentName = prefixDocumentName
    ? `${prefixDocumentName}${document.name}`
    : document.name;

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: document?.accept || "image/jpeg, image/jpg, application/pdf",
    noKeyboard: true,
    maxFiles: 1,
    maxSize: 2097152,
    disabled:
      onlyPreview || resolvedFields.includes(tempDocumentName) || disabled,
    onDropAccepted: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const uploadedFiles = {
          ...files,
          [document.name]: acceptedFiles[0],
        };
        setFieldValue(props.field.name, {
          ...props.form.values?.fileUploads,
          ...uploadedFiles,
        });
        setFiles(uploadedFiles);
      }
    },
  });

  const style = useMemo(
    () => ({
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragReject, isDragAccept]
  );

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path} className="text-red-500">
        {`${file.path} - ${formatBytes(file.size, 2)}`}
        <ul>
          {errors.map((e) =>
            FILE_UPLOAD_ERRORS[e.code] ? (
              <li key={e.code}>{FILE_UPLOAD_ERRORS[e.code]}</li>
            ) : (
              <li key={e.code}>{replaceAll(e.message, mapFileTypes)}</li>
            )
          )}
        </ul>
      </li>
    );
  });

  const handleClick = (e) => {
    e.preventDefault();
    e.target.value = null;
  };

  const handlePreview = (name) => {
    if (previewFileName === name) setPreviewFileName("");
    else setPreviewFileName(name);
  };

  const getOriginalFileName = (file) => {
    if (typeof file === "string")
      return file.replace(`${file.split("-")[0]}-`, "");
    return file?.name;
  };

  const handleResolveBtnClicked = async (fieldName) => {
    const commentsToBeResolved = fileUploadComments?.filter(
      (comment) => comment.fieldName === fieldName && !comment.isResolved
    );
    const commentsToBeResolvedIds = commentsToBeResolved?.map(({ _id }) => _id);
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      resolveFields({
        data: {
          formId,
          formName: formHelperKey,
          fieldName,
          resolvedFields: [fieldName],
          commentsToBeResolvedIds,
        },
        updateEffectKey,
        updateEffectPath,
      })
    );
    if (commentsToBeResolvedIds.length > 0) {
      const query = {
        companyId: commentsToBeResolved[0]?.company,
        corporate: commentsToBeResolved[0]?.corporate,
      };
      if (isReviewerMode) query.createdBy = currentUser?._id;
      if (isProfileReview && rowId) {
        await dispatch(getProfileComments({ userId: rowId }));
      } else await dispatch(getComments(query));
    }
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const handleInvalidateBtnClicked = async (fieldName) => {
    setIsLoading(true);
    setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
    const promise = await dispatch(
      invalidateFields({
        data: {
          formId,
          formName: formHelperKey,
          fieldName,
          invalidFields: [fieldName],
        },
        updateEffectKey,
        updateEffectPath,
      })
    );
    setIsLoading(false);
    setSelectedRow((selectedRows) => [
      ...selectedRows.filter(
        (rowName) => rowName !== promise.meta.arg.data.fieldName
      ),
    ]);
  };

  const getAcceptFileTypeLabel = () => {
    if (!document?.accept) return "JPEG, JPG, PDF up to 2MB";
    const types = [];
    if (document?.accept.includes("jpeg")) types.push("JPEG, JPG");
    if (document?.accept.includes("png")) types.push("PNG");
    if (document?.accept.includes("pdf")) types.push("PDF");
    if (
      document?.accept.includes(
        ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.documen"
      )
    )
      types.push("DOC");
    return `${types.join(", ")} up to 2MB`;
  };

  const isRowLoading = isLoading && selectedRows.includes(document.name);

  return (
    <>
      <div
        key={document.name}
        className={`${!onlyPreview ? "mb-4" : "mb-1"} ${
          horizontalLayout ? "flex items-center" : ""
        }`}
      >
        {!onlyPreview && (
          <p
            className={`text-sm font-medium leading-6 text-gray-900 ${
              horizontalLayout ? "w-60" : ""
            }`}
          >
            <span
              className={`relative ${
                (Constants.REQUIRED_FILE_UPLOAD_FIELDS.includes(
                  document.name
                ) ||
                  showRequiredAsterisk) &&
                !document?.optional
                  ? "after:content-['*'] after:ml-0.5 after:text-red-500"
                  : ""
              }`}
            >
              {document.label}
              {comments.length > 0 && (
                <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-3 -right-5">
                  <span>{comments.length}</span>
                </span>
              )}
            </span>
          </p>
        )}
        <div className="flex text-sm font-medium items-baseline w-full space-x-8">
          {onlyPreview ? (
            <p className="flex-1">
              {addReviewComment ||
              showResolveAllFieldsCheckbox ||
              (files?.[document.name] && showDocumentLabel)
                ? document.label
                : getOriginalFileName(files?.[document.name])}
            </p>
          ) : (
            <div
              {...(isManageCompanyModule &&
              getOriginalFileName(files[document.name])
                ? {
                    className: "cursor-not-allowed py-4 basis-2/3 mr-6",
                  }
                : {
                    ...getRootProps({
                      className: `dropzone flex-1 ${
                        getOriginalFileName(files?.[document.name])
                          ? "bg-green-100"
                          : "bg-white"
                      } ${
                        resolvedFields.includes(tempDocumentName) || disabled
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      } transition duration-500 ease-in-out px-5 flex flex-col items-center outline-none color-[#bdbdbd] border-2 border-gray-300 border-dashed rounded-sm ${wrapperDivStyles}`,
                      style,
                      name: document.name,
                      onClick: handleClick,
                    }),
                  })}
            >
              {isManageCompanyModule &&
              getOriginalFileName(files[document.name]) ? (
                <div className="flex space-x-4">
                  <div className="bg-gray-300 p-1 rounded-full">
                    <CheckIcon className="w-4 h-4 text-red-500" />
                  </div>
                  <span className="mt-0.5">
                    Letter of {document.label.split("|")[0]}
                  </span>
                </div>
              ) : getOriginalFileName(files[document.name]) ? (
                <p className="p-2">
                  {getOriginalFileName(files[document.name])}
                </p>
              ) : (
                <div
                  className={`space-y-1 text-center ${
                    disabled
                      ? "cursor-not-allowed text-gray-400"
                      : "cursor-pointer"
                  }`}
                >
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className={`relative font-medium bg-white rounded-md ${
                        resolvedFields.includes(document.name) || disabled
                          ? "cursor-not-allowed text-gray-400"
                          : "cursor-pointer hover:text-indigo-500 focus-within:ring-indigo-500"
                      } text-sflPrimary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 `}
                    >
                      <span>Upload a file</span>
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    {getAcceptFileTypeLabel()}
                  </p>
                </div>
              )}
              <input
                type="file"
                name={document.name}
                id={document.name}
                style={{ display: "none" }}
                {...getInputProps()}
              />
            </div>
          )}
          {files !== undefined && files[document.name] ? (
            <div className="flex my-auto space-x-4">
              <button
                className="flex space-x-2 mg-r-10"
                type="button"
                onClick={() => handlePreview(document.name)}
              >
                <span className="sr-only">Toggle preview</span>
                {document.name === previewFileName ? (
                  <>
                    <EyeOffIcon className="w-6 h-6" aria-hidden="true" />
                    <span>Close</span>
                  </>
                ) : (
                  <>
                    <EyeIcon className="w-6 h-6" aria-hidden="true" />
                    <span>Preview</span>
                  </>
                )}
              </button>
              {!onlyPreview && (
                <button
                  className="flex space-x-2 mg-r-10 disabled:cursor-not-allowed disabled:text-gray-400"
                  type="button"
                  name={document.name}
                  onClick={open}
                  disabled={
                    resolvedFields.includes(tempDocumentName) ||
                    disabled ||
                    document.disableReplace
                  }
                >
                  <span className="sr-only">Replace document</span>
                  <UploadIcon className="w-6 h-6" aria-hidden="true" />
                  <span>Replace</span>
                </button>
              )}
            </div>
          ) : (
            showResolveAllFieldsCheckbox && "N/A"
          )}
          {addReviewComment && !isViewMode && (
            <>
              {!resolvedFields.includes(document.name) && (
                <CommentsPopup
                  handleSaveComment={handleSaveComment}
                  formName={formName}
                  fieldName={document.name}
                  fieldLabel={document.label}
                  comment={fileUploadComments.find(
                    (comment) =>
                      comment.fieldName === document.name &&
                      currentUser?._id === comment.createdBy?._id
                  )}
                >
                  <span>Review</span>
                  <PencilIcon className="inline w-4 h-4" />
                </CommentsPopup>
              )}
              <div className="flex items-center justify-center">
                {resolvedFields.includes(document.name) ? (
                  <button
                    className="flex space-x-1"
                    onClick={() => handleInvalidateBtnClicked(document.name)}
                  >
                    {isRowLoading ? (
                      <SpinnerIcon className="text-primary" />
                    ) : (
                      <>
                        <span>Accepted </span>
                        <XIcon className="inline w-4 h-4" />
                      </>
                    )}
                  </button>
                ) : (
                  <button
                    className="flex space-x-1"
                    onClick={() => handleResolveBtnClicked(document.name)}
                  >
                    {isRowLoading ? (
                      <SpinnerIcon className="text-primary" />
                    ) : (
                      <>
                        <span>Accept</span>
                        <CheckIcon className="inline w-4 h-4" />
                      </>
                    )}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        {comments.length > 0 && (
          <CommentsList comments={comments} color={"text-red-500"} />
        )}
        {!horizontalLayout && (
          <ValidationError
            errors={props.form?.errors?.fileUploads}
            touched={props.form.touched?.fileUploads}
            name={document.name}
          />
        )}
        <ul>{fileRejectionItems}</ul>
      </div>
      {horizontalLayout && (
        <ValidationError
          errors={props.form?.errors?.fileUploads}
          touched={props.form.touched?.fileUploads}
          name={document.name}
        />
      )}
      {previewFileName && previewFileName === document.name && (
        <UploadedFileView
          file={files[previewFileName]}
          modalName={`${props.field.name}_${previewFileName}`}
          renderInModal={false}
          editorParams={editorParams}
          label={
            document.name ? getOriginalFileName(files?.[document.name]) : ""
          }
        />
      )}
    </>
  );
};

const FileUploadForm = (props) => {
  const [files, setFiles] = useState(() =>
    _isEmpty(props?.field?.value) ? {} : props?.field?.value
  );
  const [previewFileName, setPreviewFileName] = useState("");
  const [allFieldsResolved, setAllFieldsResolved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRow] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const isViewMode =
    history.location.pathname.split("/").pop() === "view" || props.isViewMode;

  const {
    resolvedFields = [],
    formId,
    formHelperKey,
    updateEffectKey = "",
    updateEffectPath = "",
    resolveAllFieldsCheckbox,
    isProfileReview,
    rowId,
    innerRef,
    showResolveAllFieldsCheckbox,
    isManageCompanyModule = false,
  } = props || {};

  useEffect(() => {
    setFiles(props?.field?.value ?? {});
  }, [props?.field?.value]);

  const fieldsName = props?.documentData?.map((document) => document.name);

  useEffect(() => {
    setAllFieldsResolved(
      fieldsName.every((element) => resolvedFields.includes(element))
    );
  }, [fieldsName, resolvedFields]);

  const commentPath = props?.commentPath
    ? props.commentPath
    : "uploadDocuments";

  let comments = props?.comments
    ? props.comments
    : props?.form?.values?.comments ?? [];

  comments = _get(comments, commentPath, []);

  const handleAllFieldsStatus = async (e) => {
    setIsLoading(true);
    const commentsToBeResolved =
      props?.fileUploadComments?.filter((comment) => !comment.isResolved) || [];
    const commentsToBeResolvedIds = commentsToBeResolved?.map(({ _id }) => _id);
    if (e.target.checked) {
      // resolve/approve all the form fields
      const unresolvedFields = fieldsName.filter(
        (fieldName) => !resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [...selectedRows, ...unresolvedFields]);
      await dispatch(
        resolveFields({
          data: {
            formId,
            formName: formHelperKey,
            resolvedFields: unresolvedFields,
            commentsToBeResolvedIds,
          },
          updateEffectKey,
          updateEffectPath,
        })
      );
      if (commentsToBeResolvedIds.length > 0) {
        if (isProfileReview && rowId) {
          await dispatch(getProfileComments({ userId: rowId }));
        } else
          await dispatch(
            getComments({
              companyId: commentsToBeResolved[0]?.company,
              corporate: commentsToBeResolved[0]?.corporate,
            })
          );
      }
      setSelectedRow([]);
    } else {
      // invalidate/disapprove all the form fields
      const resolvedFieldsSlice = fieldsName.filter((fieldName) =>
        resolvedFields.includes(fieldName)
      );
      setSelectedRow((selectedRows) => [
        ...selectedRows,
        ...resolvedFieldsSlice,
      ]);
      await dispatch(
        invalidateFields({
          data: {
            formId,
            formName: formHelperKey,
            invalidFields: resolvedFieldsSlice,
          },
          updateEffectKey,
          updateEffectPath,
        })
      );
      setSelectedRow([]);
    }
  };

  const handleClosePreview = () => setPreviewFileName("");

  return (
    <>
      <button
        type="button"
        className="hidden"
        onClick={handleClosePreview}
        ref={innerRef}
      >
        Close Preview
      </button>
      {props.documentData.map((document, index) => (
        <Dropzone
          key={`${document.name}_${index}`}
          {...props}
          files={files}
          setFiles={setFiles}
          document={document}
          comments={comments?.[document?.name]}
          isLoading={isLoading}
          isViewMode={isViewMode}
          previewFileName={previewFileName}
          selectedRows={selectedRows}
          setIsLoading={setIsLoading}
          setSelectedRow={setSelectedRow}
          setPreviewFileName={setPreviewFileName}
          isManageCompanyModule={isManageCompanyModule}
        />
      ))}
      {((props.addReviewComment && resolveAllFieldsCheckbox && !isViewMode) ||
        showResolveAllFieldsCheckbox) && (
        <div className="flex items-center">
          <input
            aria-describedby="approve-disapprove"
            type="checkbox"
            className="mr-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            onChange={handleAllFieldsStatus}
            checked={allFieldsResolved}
          />
          <p
            id="approve-disapprove"
            className="flex items-center text-sm font-semibold whitespace-normal"
          >
            Click this checkbox to approve all entries if they are correct then
            proceed to next step
          </p>
        </div>
      )}
    </>
  );
};

export default FileUploadForm;
