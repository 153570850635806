import React from "react";
import { twMerge } from "tailwind-merge";

import Badge from "components/lib/Shared/Badge";

function SidebarItem({ item, selectedTab, handleTabClick = () => null }) {
  return (
    <li
      key={item.label}
      className={twMerge(
        item.id === selectedTab.id ? "text-primary" : "text-gray-400",
        `mb-3 font-medium ${
          item.disabled
            ? "cursor-not-allowed text-gray-300/95"
            : "hover:text-sflPrimary cursor-pointer"
        }`
      )}
      onClick={() => handleTabClick(item)}
    >
      <span className="">{item.label}</span>{" "}
      {item.comingSoon && (
        <Badge
          bgColor="bg-blue-200"
          textColor="text-gray-700"
          text={"Coming Soon"}
          className={
            "inline-flex absolute ml-1 items-center rounded-full bg-blue-50 px-1 py-0.5 text-xxs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
          }
        />
      )}
    </li>
  );
}

export default SidebarItem;
