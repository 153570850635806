import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../../axios";
import { getQueryString } from "utils/getQueryString";

export const getStatsAsync = createAsyncThunk(
  "statsSlice/getStatsAsync",
  async (queryParams, thunkAPI) => {
    try {
      const res = await axios.get(`/stats?${getQueryString(queryParams)}`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  stats: {
    companies: {},
    profiles: {},
    corporateDirectorsShareholdersCount: 0,
  },
  getStats: {
    status: "idle",
    error: false,
  },
};

const statsSlice = createSlice({
  name: "statsSlice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [getStatsAsync.pending]: (state) => {
      state.getStats.status = "loading";
      state.getStats.error = false;
    },
    [getStatsAsync.fulfilled]: (state, action) => {
      state.getStats.status = "idle";
      state.getStats.error = false;
      state.stats = action.payload.stats;
    },
    [getStatsAsync.rejected]: (state, action) => {
      state.getStats.status = "failed";
      state.getStats.error = action.payload.messageText;
    },
  },
});

export const { reset } = statsSlice.actions;

const { reducer } = statsSlice;

export default reducer;
