import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { object, string } from "yup";

import Button from "components/lib/Shared/Button";
import ValidationError from "components/lib/Shared/ValidationError";
import InviteExistingUser from "components/lib/Shared/VerifyUserOnboarding/InviteExistingUser";
import {
  addCoApplicantAsync,
  sendAccessRequestForCoApplicantAsync,
} from "state/slices/coApplicant";

const validationSchema = object().shape({
  email: string()
    .email("Invalid email format")
    .trim("Email cannot include leading and trailing spaces")
    .strict(true)
    .required("Email address is required"),
});

function AddCoApplicantForm({ currentUserFullName, setMode }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { company } = useSelector((state) => state.companySlice);
  const { addCoApplicant } = useSelector((state) => state.coApplicantSlice);
  const [renderInviteExistingUser, setRenderInviteExistingUser] =
    useState(false);

  const handleCancelInvite = () => {
    setMode(null);
  };

  return (
    <div className="flex flex-col mt-4">
      <Formik
        enableReinitialize
        initialValues={{ email: "", accessType: "Recurring" }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          if (renderInviteExistingUser) {
            try {
              const response = await dispatch(
                sendAccessRequestForCoApplicantAsync({
                  data: {
                    ...values,
                    companyId: company?._id ?? params?.companyId,
                  },
                })
              );

              if (!response.error) {
                resetForm();
                setMode(null);
              }
            } catch (error) {
              toast(
                "error",
                error?.message ?? "Something went wrong please try again."
              );
            }
          } else {
            const data = {
              email: values.email,
              company: company?._id ?? params?.companyId,
            };
            const response = await dispatch(addCoApplicantAsync({ data }));
            if (response.payload.sendInvite) {
              setRenderInviteExistingUser(true);
            } else if (!response.error) {
              resetForm();
              setMode(null);
            }
          }
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          setFieldError,
          setFieldValue,
        }) => {
          useEffect(() => {
            setFieldError("email", addCoApplicant.error);
          }, [addCoApplicant.error]);

          const handleEmailChange = (e) => {
            setRenderInviteExistingUser(false);
            setFieldValue("email", e.target.value);
          };

          const submitForm = (e) => {
            e.preventDefault();
            handleSubmit();
          };

          return (
            <Form onSubmit={submitForm}>
              <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                <div className="px-4 py-6 space-y-4 sm:p-6">
                  <label className="block text-sm font-medium text-gray-600">
                    We will send an invite link to the non-existing official;
                    otherwise, we will add this user as a co-applicant in the
                    company.
                  </label>
                  <div className="grid grid-cols-6 gap-2">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500"
                      >
                        User Email Address
                      </label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        onChange={handleEmailChange}
                        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                        placeholder="Enter email address"
                        disabled={isSubmitting}
                      />
                      <ValidationError
                        errors={errors}
                        touched={touched}
                        name="email"
                      />
                    </div>
                  </div>
                  {renderInviteExistingUser && <InviteExistingUser />}
                  <div className="flex space-x-4">
                    <Button
                      onClick={handleCancelInvite}
                      disabled={isSubmitting}
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={submitForm}
                      isLoading={isSubmitting}
                      loadingText="Sending..."
                    >
                      Send Invite
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddCoApplicantForm;
