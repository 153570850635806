import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import Cookies from "js-cookie";
import { format } from "date-fns";
import { useDebounce } from "use-debounce";

import ReactTable from "components/lib/Global/ReactTable";
import Button from "components/lib/Shared/Button";
import DeleteButtonWithConfirmation from "components/lib/Shared/DeleteButtonWithConfirmation";
import TableActionMenuWrapper from "components/lib/Shared/TableActionMenuWrapper";
import ConfirmDialog from "components/lib/Shared/ConfirmDialog";
import {
  deleteTemplateAsync,
  getTemplatesAsync,
  updateTemplateState,
} from "state/slices/template";
import { Constants } from "config/constants";
import { useToast } from "hooks/useToast";
import SearchInput from "components/lib/Shared/SearchInput";

const TemplatesTable = ({ handleAddNewTemplate, setShowAddTemplateForm }) => {
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();
  const { toast } = useToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const { templates, getTemplates } = useSelector(
    (state) => state.templateSlice
  );

  const { modalRow } = useSelector((state) => state.modalsSlice);

  const handleViewTemplate = async (row) => {
    const selectedVersion = row.versions[row.versions.length - 1];
    dispatch(
      updateTemplateState({
        selectedTemplate: row,
        selectedVersion,
        mode: "view",
      })
    );
    setShowAddTemplateForm(true);
  };

  const handleEditTemplate = async (row) => {
    const selectedVersion = row.versions[row.versions.length - 1];
    dispatch(
      updateTemplateState({
        selectedTemplate: row,
        selectedVersion,
        mode: "edit",
      })
    );
    setShowAddTemplateForm(true);
  };

  const handleDeleteTemplate = async (row) => {
    const response = await dispatch(
      deleteTemplateAsync({ templateId: row._id })
    );
    if (response.error) {
      toast(
        "error",
        response.payload?.messageText ?? "Something went wrong please try again"
      );
    } else {
      dispatch(getTemplatesAsync());
    }
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Application Type",
      Cell: ({ row: { original } }) =>
        `${original.entityType} ${original.templateType}` || "N/A",
    },
    {
      Header: "Version",
      accessor: "version",
      Cell: ({ row }) => (
        <span className="border border-gray-300 rounded-xl px-5 py-px">
          {row.original?.versions?.length ?? 1}
        </span>
      ),
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ row }) => format(new Date(row.original.createdAt), "dd MMM"),
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <TableActionMenuWrapper>
            <Menu.Item>
              {({ active }) => (
                <Button
                  overrideBaseStyles
                  onClick={() => handleViewTemplate(row.original)}
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                >
                  View
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  onClick={() => handleEditTemplate(row.original)}
                  overrideBaseStyles
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                >
                  Edit
                </Button>
              )}
            </Menu.Item>
            {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
              <Menu.Item>
                {({ active }) => (
                  <DeleteButtonWithConfirmation
                    confirmationModalName="delete_template_confirmation_modal"
                    overrideBaseStyles
                    btnClasses={`${
                      active ? "bg-red-600 text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                    row={row.original}
                  />
                )}
              </Menu.Item>
            )}
          </TableActionMenuWrapper>
        );
      },
    },
  ];

  useEffect(() => {
    if (debouncedSearchTerm) setCurrentPage(1);
    dispatch(
      getTemplatesAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm.trim(),
        order: "asc",
      })
    );
  }, [dispatch, currentPage, debouncedSearchTerm]);

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput
          value={searchTerm}
          placeholder="search by name or application type"
          handleOnChange={handleSearch}
        />
      </div>
      <div className="w-full bg-white shadow">
        {templates.totalCount === 0 ? (
          <div className="relative flex flex-col items-center justify-center p-32 text-center bg-white border border-gray-100 rounded-md shadow-sm">
            <div className="absolute flex flex-col items-center justify-center">
              <p className="text-title5">No template to display yet.</p>
              {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
                <span className="text-title5">
                  Click{" "}
                  <span
                    className="cursor-pointer font-medium text-sflPrimary"
                    onClick={handleAddNewTemplate}
                  >
                    here
                  </span>{" "}
                  to create one
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full">
            <ReactTable
              columns={columns}
              data={templates.items}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={templates.totalCount}
              setCurrentPage={setCurrentPage}
              paginate
              isLoading={getTemplates.status === "loading"}
              headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
              headingStyle="pl-4 text-left text-white"
              dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            />
          </div>
        )}
      </div>
      <ConfirmDialog
        name="delete_template_confirmation_modal"
        onConfirm={() => handleDeleteTemplate(modalRow)}
      >
        Are you sure you want to permanently delete this entry?
      </ConfirmDialog>
    </>
  );
};

export default TemplatesTable;
