/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import _cloneDeep from "lodash/cloneDeep";

import { getCombinedNames } from "utils";
import {
  addCorporateShareholder,
  deleteCorporateShareholder,
} from "state/slices/tables/corporateShareholderTableSlice";
import { setCurrentJSGroup } from "state/slices/tables/corporateJointShareholderTableSlice";

const SubmitJointShareholdersList = (props) => {
  const dispatch = useDispatch();

  const corporateShareholders = useSelector(
    (state) => state.corporateShareholderTableSlice
  );

  const { currentJSGroup } = useSelector(
    (state) => state
  ).corporateJointShareholderTableSlice;

  const jointShareholders = useSelector((state) => {
    return state.corporateJointShareholderTableSlice.shareholders.filter(
      (js) => js.JSGroupID === currentJSGroup.JSGroupID
    );
  });

  const handleClick = () => {
    const JSGroupID = currentJSGroup?.JSGroupID ?? uuidv4();

    corporateShareholders.forEach((bo) => {
      if (bo.JSGroupID && bo.JSGroupID === currentJSGroup?.JSGroupID)
        dispatch(deleteCorporateShareholder(bo));
    });

    const fullName = getCombinedNames(
      jointShareholders.map((bo) => bo.names.fullName)
    );
    const {
      directPercentShareholding,
      indirectPercentShareholding,
      directPercentVotingRights,
      directRightRemoveDirector,
      directCompanyControlRight,
    } = jointShareholders[0]?.beneficialOwnershipForm;
    const isDirectBeneficiary =
      directPercentShareholding >= 10 ||
      directPercentVotingRights >= 10 ||
      directRightRemoveDirector === "yes" ||
      directCompanyControlRight === "yes";

    jointShareholders.forEach((JS, index) => {
      JS = { ...JS, JSGroupID };
      const boJS = _cloneDeep(JS);
      boJS.beneficialOwnershipForm =
        jointShareholders[0]?.beneficialOwnershipForm;
      boJS.proRata = directPercentShareholding || indirectPercentShareholding;
      boJS.isReportable = false;
      if (props.selectedShareholder.isReportable && isDirectBeneficiary)
        boJS.isReportable = true;

      const combinedBOEntry = {
        ...boJS,
        id: uuidv4(),
        names: {
          ...boJS.names,
          fullName,
        },
        role: ["Joint Shareholder"],
        idType: "Joint Shareholder",
        shareholderWithBO: props.shareholderWithBO,
      };
      combinedBOEntry.id = currentJSGroup?.id ? currentJSGroup?.id : uuidv4();
      if (currentJSGroup?._id) {
        combinedBOEntry._id = currentJSGroup?._id;
      }
      if (index === 0) {
        dispatch(addCorporateShareholder(combinedBOEntry));
        dispatch(setCurrentJSGroup(""));
      }
    });
    props.resetFormState();
  };

  if (jointShareholders.length > 1 && jointShareholders.length <= 4) {
    return (
      <button
        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
        onClick={handleClick}
      >
        Subm
      </button>
    );
  }
  return null;
};

export default SubmitJointShareholdersList;
