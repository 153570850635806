import React from "react";
import Tag from "components/lib/Shared/Tag";

const BoMaxValue = ({ value = 0 }) => {
  return (
    <div>
      <div>
        <div className="mt-4">
          <div className="flex">
            <h2 className="text-xs font-semibold">Max:</h2>
            <div className="px-4 text-xs">
              <Tag>{`${Number(value)}%`}</Tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoMaxValue;
