import { object, string } from 'yup'

const validation = () => {
  const schema = object().shape({
    designation: string().required('Designation is required'),
    idType: string().required('Id type is required'),
  })

  return {
    schema,
    schemaInitialValues: schema.cast()
  }
}

export default validation
