import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _pickBy from "lodash/pickBy";
import _values from "lodash/values";
import _merge from "lodash/merge";

import {
  addJointShareholder,
  editJointShareholder,
} from "state/slices/tables/corporateJointShareholderTableSlice";
import NamesForm from "../../../../CompanyOfficialsTab/NamesComponents/index";
import FileUploadForm from "../../../../CompanyOfficialsTab/FileUploadForm";
import { getDocumentData } from "../../../../CompanyOfficialsTab/FileUploadForm/documentData";
import { closeModal } from "state/slices/modals";
import OfficialsBeneficialOwnershipComponent from "../../../../CompanyOfficialsTab/BeneficialOwnershipComponents/OfficialsBeneficialOwnershipComponent";
import { handleFilesUpload } from "utils/filesUtils";
import { clearEmpties, getFullName, roundNumber } from "utils";
import IdentificationDetailsForm from "../../../../CompanyOfficialsTab/PersonalDetailsComponents/index";
import AddressParticulars from "../../../BeneficialOwnersAddressParticulars";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import Tabs from "components/lib/Shared/Tabs";
import getInitialValues from "./getInitialValues";
import getValidationSchema from "./getValidationSchema";
import Verify from "components/lib/Shared/Verify";

const initialTabs = [
  {
    id: "beneficialOwnershipAllocation",
    label: "Beneficial Ownership Allocation",
  },
];

const shortFormTabs = [
  {
    id: "beneficialOwnershipAllocation",
    label: "Beneficial Ownership Allocation",
  },
  { id: "generalInformation", label: "General Information" },
];

const longFormTabs = [
  {
    id: "beneficialOwnershipAllocation",
    label: "Beneficial Ownership Allocation",
  },
  { id: "generalInformation", label: "General Information" },
  { id: "residentialDetails", label: "Residential Details" },
  { id: "uploadDocuments", label: "Upload Documents" },
];

const JointShareholderForms = (props) => {
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState({
    id: "beneficialOwnershipAllocation",
    label: "Beneficial Ownership Allocation",
  });
  const [validationSchema, setValidationSchema] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const { transformedComments } = useSelector((state) => state.commentsSlice);

  const { currentJSGroup } = useSelector(
    (state) => state
  ).corporateJointShareholderTableSlice;

  const jointShareholders = useSelector((state) => {
    return state.corporateJointShareholderTableSlice.shareholders.filter(
      (js) => js.JSGroupID === currentJSGroup.JSGroupID
    );
  });

  const jointShareholdersCount = jointShareholders.length;
  const { mode, beneficialOwnershipForm, currentOfficial, toggleShowJSForm } =
    props;
  let { idType } = props.values;

  let currentRow = {};

  if (mode.editing && !_isEmpty(jointShareholders)) {
    const index = jointShareholders.findIndex(
      (item) =>
        (item?.id && item.id === mode.id) ||
        (item?._id && item?._id === mode.id)
    );

    if (index > -1) currentRow = jointShareholders.slice(0)[index];
  }
  if (currentRow.id) idType = currentRow.idType;
  if (jointShareholders.length === 4 && !mode.editing) {
    toggleShowJSForm(false);
  }
  if (jointShareholders.length === 1) {
    toggleShowJSForm(true);
  }

  let isFirstJSReportable = false;
  if (jointShareholders[0]?.beneficialOwnershipForm) {
    const {
      directPercentShareholding,
      directPercentVotingRights,
      directRightRemoveDirector,
      directCompanyControlRight,
    } = jointShareholders[0]?.beneficialOwnershipForm;
    isFirstJSReportable =
      directPercentShareholding >= 10 ||
      directPercentVotingRights >= 10 ||
      directRightRemoveDirector === "yes" ||
      directCompanyControlRight === "yes";
  }

  const goToPrevStep = () => {
    if (selectedTabIndex === 0) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  const initialValuesDynamic = mode.editing ? currentRow : initialValues;

  useEffect(() => {
    setSelectedTabIndex(0);
  }, [idType]);

  const documentData = getDocumentData(idType);

  useEffect(() => {
    setValidationSchema(
      getValidationSchema({
        selectedTab,
        idType,
        isNonResident: currentRow.isNonResident,
        documentData,
      }).schema
    );
    setInitialValues(
      _merge(getInitialValues({ idType, documentData }), currentRow)
    );
  }, [selectedTab, idType]);

  const showShareAllocationForm =
    (props.showShareAllocation && mode.editing) ||
    (!mode.editing && jointShareholdersCount === 0);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValuesDynamic,
          beneficialOwnershipForm,
          ...currentRow,
          showShareAllocationForm,
          comments:
            transformedComments?.[currentOfficial?._id]?.[
              "beneficialOwnershipTab"
            ] ?? [],
        }}
        onSubmit={async (values, { resetForm }) => {
          if (!isLastStep) {
            goToNextStep();
            return;
          }
          const uploadedFiles = await handleFilesUpload(
            values.fileUploads,
            currentOfficial,
            mode.editing
          );
          const { names } = values;
          let payload = {
            ..._omit(values, [
              "details",
              "fileUploads",
              "showShareAllocationForm",
              "comments",
            ]),
            idType,
            fileUploads: {
              ..._pickBy(
                values.fileUploads,
                (file) => typeof file === "string"
              ),
              ...uploadedFiles,
            },
            names: {
              ...names,
              fullName: getFullName(names),
            },
            corporateIdType: props.corporateIdType,
            JSGroupID: currentJSGroup?.JSGroupID,
          };
          payload = clearEmpties(payload);
          if (mode.editing) {
            dispatch(editJointShareholder(payload));
            dispatch(closeModal("jointShareholderForm_edit_modal"));
          } else {
            dispatch(addJointShareholder(payload));
            if (jointShareholdersCount > 0) {
              props.toggleShowJSForm(false);
            } else props.toggleShowJSForm(true);
          }
          resetForm();
          setSelectedTabIndex(0);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          handleSubmit,
          setTouched,
        }) => {
          // useEffect(() => {
          //   setFieldValue('beneficialOwnershipForm.maxPercentShareholding', roundNumber(
          //     beneficialOwnershipForm.maxPercentShareholding
          //     + (currentRow?.beneficialOwnershipForm?.directPercentShareholding ?? 0)
          //     + (currentRow?.beneficialOwnershipForm?.indirectPercentShareholding ?? 0)
          //   ))
          //   setFieldValue('beneficialOwnershipForm.maxPercentVotingRights', roundNumber(
          //     beneficialOwnershipForm.maxPercentVotingRights
          //     + (currentRow?.beneficialOwnershipForm?.directPercentVotingRights ?? 0)
          //     + (currentRow?.beneficialOwnershipForm?.indirectPercentVotingRights ?? 0)
          //   ))
          // }, [])

          const {
            directPercentShareholding,
            directPercentVotingRights,
            directRightRemoveDirector,
            directCompanyControlRight,
          } = values?.beneficialOwnershipForm ?? {};

          const isDirectBeneficiary =
            directPercentShareholding >= 10 ||
            directPercentVotingRights >= 10 ||
            directRightRemoveDirector === "yes" ||
            directCompanyControlRight === "yes";

          const renderForm =
            _values(values.beneficialOwnershipForm).filter(
              (value) => value !== ""
            ).length > 5 || jointShareholders.length > 0;
          const renderLongForm =
            renderForm && (isDirectBeneficiary || isFirstJSReportable);
          const renderShortForm = renderForm && !isDirectBeneficiary;

          // Update validation schema based on current form step
          useEffect(() => {
            setValidationSchema(
              getValidationSchema({
                selectedTab,
                idType,
                isNonResident: values.isNonResident,
                documentData,
                beneficialOwnershipForm: values.beneficialOwnershipForm,
                renderLongForm,
              }).schema
            );
          }, [
            values.isNonResident,
            values?.beneficialOwnershipForm,
            renderLongForm,
          ]);

          // update form steps based on long or short form
          useEffect(() => {
            let tempTabs = [];
            if (renderLongForm) tempTabs = longFormTabs;
            if (!renderLongForm) tempTabs = shortFormTabs;
            if (mode.editing) {
              tempTabs = tempTabs.filter((tab) => tab.id !== "assignOfficials");
            }
            if (!showShareAllocationForm) {
              tempTabs = tempTabs.filter(
                (tab) => tab.id !== "beneficialOwnershipAllocation"
              );
            }
            setTabs(tempTabs);
          }, [
            values,
            renderShortForm,
            renderLongForm,
            mode.editing,
            showShareAllocationForm,
          ]);

          // Reset touched fields on tab change, e.g fix errors jumping around
          useEffect(() => {
            setTouched({}, false);
          }, [selectedTab, setTouched]);

          return (
            <Form>
              {/* Official's form tabs */}
              {values.designation === "Joint Shareholder" &&
              !showJSForm ? null : (
                <div className="mt-4">
                  <Tabs
                    tabs={tabs}
                    selectedTabIndex={selectedTabIndex}
                    setSelectedTabIndex={setSelectedTabIndex}
                    showTooltip={!mode.editing}
                  />
                </div>
              )}
              {!mode.editing && (
                <>
                  {/* <h5 className="text-lg font-medium">Add the Joint Shareholder</h5>
                    <p className="text-lg font-medium">
                      Current Number of Joint Shareholders:
                      {jointShareholdersCount}
                    </p> */}
                </>
              )}
              {/* Share Allocation form */}
              {((props.showShareAllocation && mode.editing) ||
                (!mode.editing && jointShareholdersCount === 0)) &&
                selectedTab?.id === "beneficialOwnershipAllocation" && (
                  <>
                    {/* Beneficial Ownership form */}
                    <div
                      div
                      className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md"
                    >
                      <div className="px-4 py-6 sm:p-6">
                        <h2 className="mb-1 text-lg font-medium leading-6 text-gray-900">
                          Beneficial Ownership
                        </h2>
                        <Field
                          name="beneficialOwnershipForm"
                          component={OfficialsBeneficialOwnershipComponent}
                          corporateIdType={props.corporateIdType}
                          disableChangeEffect
                          isBOTab
                          isReportable={renderLongForm === true}
                        />
                      </div>
                    </div>
                  </>
                )}
              {(renderShortForm || renderLongForm) &&
                selectedTab.id === "generalInformation" && (
                  <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                    <div className="px-4 py-6 space-y-6 sm:p-6">
                      <div className="mb-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Personal Information
                        </h3>
                      </div>
                      <div>
                        {!mode.editing && (
                          <Verify idType={idType} designation="Shareholder" />
                        )}
                      </div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        General Information
                      </h3>
                      <Field
                        name="names"
                        component={NamesForm}
                        idType={idType}
                      />
                      <hr />
                      <IdentificationDetailsForm idType={idType} />
                    </div>
                  </div>
                )}
              {renderLongForm && (
                <div>
                  {selectedTab.id === "residentialDetails" && (
                    <AddressParticulars
                      idType={idType}
                      isNonResident={values.isNonResident}
                    />
                  )}
                  {selectedTab.id === "uploadDocuments" && (
                    <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                      <div className="px-4 py-6 sm:p-6">
                        <h3>Please upload the following documents</h3>
                        <Field
                          name="fileUploads"
                          component={FileUploadForm}
                          documentData={documentData}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="flex justify-between py-3">
                <div
                  className={`flex w-full ${
                    isFirstStep ? "justify-end" : "justify-between"
                  } py-3`}
                >
                  {!isFirstStep && (
                    <button
                      type="button"
                      disabled={isSubmitting}
                      onClick={goToPrevStep}
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:cursor-not-allowed"
                    >
                      Go back
                    </button>
                  )}
                  <button
                    type="button"
                    disabled={
                      isSubmitting ||
                      (jointShareholdersCount === 4 && !mode.editing)
                    }
                    onClick={handleSubmit}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
                  >
                    {isSubmitting && isLastStep ? (
                      <>
                        <SpinnerIcon />
                        Submitting...
                      </>
                    ) : (
                      "Save and proceed"
                    )}
                  </button>
                </div>
              </div>
              {jointShareholdersCount >= 4 && !mode.editing && (
                <small style={{ color: "red" }}>
                  {" "}
                  YOU HAVE REACHED MAXIMUM NUMBER OF JOINT SHAREHOLDERS
                </small>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default JointShareholderForms;
