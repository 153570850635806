import _forOwn from "lodash/forOwn";
import { Constants } from "config/constants";

const checkRestrictions = (shares) => {
  const errors = [];
  let restrictions = {
    nonRedeemable: {
      count: 0,
      min: {
        value: Constants.MIN_NON_REDEEMABLE_CLASS,
        error: `A minimum of ${Constants.MIN_NON_REDEEMABLE_CLASS} Non-Redeemable class must be included`,
      },
    },
    votingShares: {
      count: 0,
      min: {
        value: Constants.MIN_VOTING_SHARES_CLASS,
        error: `A minimum of ${Constants.MIN_VOTING_SHARES_CLASS} Voting shares class must be included`,
      },
    },
  };

  shares.forEach(({ hasVotingRights, rights }) => {
    if (rights.find((right) => right.label === "Non-Redeemable")) {
      restrictions.nonRedeemable.count += 1;
    }
    if (hasVotingRights === "Yes") restrictions.votingShares.count += 1;
  });

  _forOwn(restrictions, function (value) {
    const { count, min, max } = value;
    if (min && count < min.value) errors.push(min.error);
    if (max && count > max.value) errors.push(max.error);
  });

  return { errors };
};

export const validateNominalSharesRestrictions = ({ shares }) => {
  return checkRestrictions(shares);
};
