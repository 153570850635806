/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _set from "lodash/set";

import companyDashboardDataService from "services/companyDashboard";

export const getCompanyDashboardAsync = createAsyncThunk(
  "companyDashboard/getCompanyDashboardAsync",
  async ({ companyId }, thunkAPI) => {
    try {
      const res = await companyDashboardDataService.getCompany(companyId);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyOfficialsAsync = createAsyncThunk(
  "companyDashboard/getCompanyOfficialsAsync",
  async ({ companyId, queryParams }, thunkAPI) => {
    try {
      const res = await companyDashboardDataService.getCompanyOfficials(
        companyId,
        queryParams
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyBeneficialOwnersAsync = createAsyncThunk(
  "companyDashboard/getCompanyBeneficialOwnersAsync",
  async ({ companyId }, thunkAPI) => {
    try {
      const res = await companyDashboardDataService.getCompanyBeneficialOwners(
        companyId
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOfficial = createAsyncThunk(
  "companyDashboard/getOfficial",
  async ({ companyId, officialId, idType, JSGroupID }, thunkAPI) => {
    try {
      const res = await companyDashboardDataService.getOfficial(
        companyId,
        officialId,
        idType,
        JSGroupID
      );
      if (res) {
        return res.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTransactionCompany = createAsyncThunk(
  "companyDashboard/getTransactionCompany",
  async ({ companyId }, thunkAPI) => {
    try {
      const res = await companyDashboardDataService.get(companyId);
      if (res) {
        return res.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  company: null,
  currentUserRow: null,
  isCurrentUserCorporateRow: null,
  companyOfficials: {
    items: [],
    totalCount: 0,
  },
  beneficialOwners: {
    items: [],
    totalCount: 0,
  },
  groupedBeneficialOwners: {
    items: [],
    totalCount: 0,
  },
  dashboardRole: "VOR",
  dashboardPermissions: [],
  designation: "",
  breadCrumbs: [],
  official: null,
  transactionCompany: {},
  getCompanyStatus: {
    status: "idle",
    error: false,
  },
  getCompanyOfficialsStatus: {
    status: "loading",
    error: false,
  },
  getCompanyBeneficialOwnersStatus: {
    status: "idle",
    error: false,
  },
  getOfficialStatus: {
    status: "idle",
    error: false,
  },
  getTransactionCompanyStatus: {
    status: "idle",
    error: false,
  },
};

const companyDashboardSlice = createSlice({
  name: "companyDashboard",
  initialState,
  reducers: {
    updateCompanyDashboardState: (state, action) => {
      state.company = { ...state.company, ...action.payload };
    },
    updateOfficialState: (state, action) => {
      state.official = { ...state.official, ...action.payload };
    },
    updateOfficialNestedField: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
    resetOfficial: (state) => {
      state.official = null;
      state.getOfficialStatus = {
        status: "idle",
        error: false,
      };
    },
    resetTransactionCompany: (state) => {
      state.transactionCompany = {};
    },
    resetCompanyDashboard: () => initialState,
  },
  extraReducers: {
    [getCompanyDashboardAsync.pending]: (state) => {
      state.getCompanyStatus.status = "loading";
      state.getCompanyStatus.error = false;
    },
    [getCompanyDashboardAsync.fulfilled]: (state, action) => {
      state.getCompanyStatus.status = "idle";
      state.getCompanyStatus.error = false;
      state.company = action.payload.company;
      state.dashboardRole = action.payload.dashboardRole ?? "VOR";
      state.dashboardPermissions = action.payload.dashboardPermissions ?? [];
      state.currentUserRow = action.payload.currentUserRow;
      state.isCurrentUserCorporateRow =
        action.payload.isCurrentUserCorporateRow;
      state.breadCrumbs = action.payload.breadCrumbs ?? [];
      state.designation = action.payload?.designation ?? "";
    },
    [getCompanyDashboardAsync.rejected]: (state, action) => {
      state.getCompanyStatus.status = "failed";
      state.getCompanyStatus.error = action.payload.messageText;
    },

    [getCompanyOfficialsAsync.pending]: (state) => {
      state.getCompanyOfficialsStatus.status = "loading";
      state.getCompanyOfficialsStatus.error = false;
    },
    [getCompanyOfficialsAsync.fulfilled]: (state, action) => {
      state.getCompanyOfficialsStatus.status = "idle";
      state.getCompanyOfficialsStatus.error = false;
      state.companyOfficials = action.payload;
    },
    [getCompanyOfficialsAsync.rejected]: (state, action) => {
      state.getCompanyOfficialsStatus.status = "failed";
      state.getCompanyOfficialsStatus.error = action.payload.messageText;
    },

    [getCompanyBeneficialOwnersAsync.pending]: (state) => {
      state.getCompanyBeneficialOwnersStatus.status = "loading";
      state.getCompanyBeneficialOwnersStatus.error = false;
    },
    [getCompanyBeneficialOwnersAsync.fulfilled]: (state, action) => {
      state.getCompanyBeneficialOwnersStatus.status = "idle";
      state.getCompanyBeneficialOwnersStatus.error = false;
      state.beneficialOwners = action.payload.beneficialOwners;
      state.groupedBeneficialOwners = action.payload.groupedBeneficialOwners;
    },
    [getCompanyBeneficialOwnersAsync.rejected]: (state, action) => {
      state.getCompanyBeneficialOwnersStatus.status = "failed";
      state.getCompanyBeneficialOwnersStatus.error = action.payload.messageText;
    },

    [getOfficial.pending]: (state) => {
      state.getOfficialStatus.status = "loading";
      state.getOfficialStatus.error = false;
    },
    [getOfficial.fulfilled]: (state, action) => {
      state.getOfficialStatus.status = "idle";
      state.getOfficialStatus.error = false;
      state.official = { ...state.official, ...action.payload };
    },
    [getOfficial.rejected]: (state, action) => {
      state.getOfficialStatus.status = "failed";
      state.getOfficialStatus.error = action.payload.messageText;
    },

    [getTransactionCompany.pending]: (state) => {
      state.getTransactionCompanyStatus.status = "loading";
      state.getTransactionCompanyStatus.error = false;
    },
    [getTransactionCompany.fulfilled]: (state, action) => {
      state.getTransactionCompanyStatus.status = "idle";
      state.getTransactionCompanyStatus.error = false;
      state.transactionCompany = action.payload;
    },
    [getTransactionCompany.rejected]: (state, action) => {
      state.getTransactionCompanyStatus.status = "failed";
      state.getTransactionCompanyStatus.error = action.payload.messageText;
    },
  },
});

export const {
  updateCompanyDashboardState,
  updateOfficialState,
  updateOfficialNestedField,
  resetOfficial,
  resetCompanyDashboard,
  resetTransactionCompany,
} = companyDashboardSlice.actions;

const { reducer } = companyDashboardSlice;

export default reducer;
