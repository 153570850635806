/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import beneficialOwnerDataService from "services/beneficialOwner";
import _set from "lodash/set";

export const addBeneficialOwnerAsync = createAsyncThunk(
  "beneficialOwners/create",
  async ({ companyId, data }, thunkAPI) => {
    try {
      const res = await beneficialOwnerDataService.create({
        companyId,
        ...data,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editBeneficialOwnerAsync = createAsyncThunk(
  "beneficialOwners/update",
  async ({ companyId, beneficialOwnerId, data }, thunkAPI) => {
    try {
      const res = await beneficialOwnerDataService.update(beneficialOwnerId, {
        companyId,
        ...data,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteBeneficialOwnerAsync = createAsyncThunk(
  "beneficialOwners/delete",
  async ({ beneficialOwnerId, companyId, hardDelete }, thunkAPI) => {
    try {
      const res = await beneficialOwnerDataService.remove(
        beneficialOwnerId,
        companyId,
        hardDelete
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllBeneficialOwners = createAsyncThunk(
  "beneficialOwners/getAll",
  async ({ companyId }) => {
    const res = await beneficialOwnerDataService.getAll(companyId);
    return res.data;
  }
);

export const updateDropdownEntryStatusAsync = createAsyncThunk(
  "beneficialOwners/updateDropdownEntryStatusAsync",
  async ({ beneficialOwnerId, data }) => {
    const res = await beneficialOwnerDataService.updateDropdownEntryStatus(
      beneficialOwnerId,
      data
    );
    return res.data;
  }
);

const beneficialOwnerTableSlice = createSlice({
  name: "Beneficial Owners Table",
  initialState: {
    beneficialOwners: [],
    totalCount: 0,
    deleteBeneficialOwnerStatus: {
      status: "idle",
      error: false,
      loaded: false,
      inProgressIds: [],
    },
    addBeneficialOwnerStatus: {
      status: "idle",
      error: false,
      loaded: false,
      inProgressIds: [],
    },
    getAllBeneficialOwnersStatus: {
      status: "idle",
      error: false,
      loaded: false,
    },
    updateDropdownEntryStatusStatus: {
      status: "idle",
      error: false,
      loaded: false,
    },
  },
  reducers: {
    addBeneficialOwner: (state, action) => {
      state.beneficialOwners.push(action.payload.beneficialOwner);
      state.totalCount = state.totalCount + 1;
    },
    updateBeneficialOwner: (state, { payload: { official } }) => {
      const boIndex = state.beneficialOwners.findIndex(
        (item) => official._id === item._id
      );
      if (boIndex > -1) state.beneficialOwners.splice(boIndex, 1, official);
    },
    deleteBeneficialOwner: (state, { payload }) => {
      const boIndex = state.beneficialOwners.findIndex(
        (item) => payload._id === item._id
      );
      if (boIndex > -1) state.beneficialOwners.splice(boIndex, 1);
      state.totalCount = state.totalCount - 1;
    },
    setBeneficialOwner: (state, action) => {
      state.beneficialOwners = action.payload;
      state.totalCount = action.payload.length;
    },
    resetBeneficialOwnerTable: (state) => {
      state.beneficialOwners = [];
      state.totalCount = 0;
    },
    updateBONestedField: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
  },
  extraReducers: {
    [addBeneficialOwnerAsync.pending]: (state, action) => {
      state.addBeneficialOwnerStatus.status = "loading";
      state.addBeneficialOwnerStatus.error = false;
      state.addBeneficialOwnerStatus.loaded = false;
      state.addBeneficialOwnerStatus.inProgressIds.push(action.meta.requestId);
    },
    [addBeneficialOwnerAsync.fulfilled]: (state, action) => {
      state.addBeneficialOwnerStatus.status = "idle";
      state.addBeneficialOwnerStatus.loaded = true;
      state.beneficialOwners.push(action.payload.beneficialOwner);
      state.addBeneficialOwnerStatus.inProgressIds.splice(
        state.addBeneficialOwnerStatus.inProgressIds.findIndex(
          (id) => id === action.payload._id
        ),
        1
      );
    },
    [addBeneficialOwnerAsync.rejected]: (state, action) => {
      state.addBeneficialOwnerStatus.status = "failed";
      state.addBeneficialOwnerStatus.inProgressIds.splice(
        state.addBeneficialOwnerStatus.inProgressIds.findIndex(
          (id) => id === action.payload._id
        ),
        1
      );
    },
    [editBeneficialOwnerAsync.fulfilled]: (
      state,
      { payload: { beneficialOwner } }
    ) => {
      const { beneficialOwners } = state;
      const index = beneficialOwners.findIndex(
        (bo) => beneficialOwner._id === bo._id
      );
      if (index > -1) {
        beneficialOwners.splice(index, 1, beneficialOwner);
      }
    },
    [updateDropdownEntryStatusAsync.pending]: (state) => {
      state.updateDropdownEntryStatusStatus.status = "loading";
      state.updateDropdownEntryStatusStatus.error = false;
      state.updateDropdownEntryStatusStatus.loaded = false;
    },
    [updateDropdownEntryStatusAsync.fulfilled]: (state, { payload }) => {
      const { beneficialOwners } = state;
      const index = beneficialOwners.findIndex(
        (bo) => payload?._id === bo?._id
      );
      if (index > -1) {
        beneficialOwners.splice(index, 1, {
          ...beneficialOwners[index],
          showInBoTable: payload.showInBoTable,
          showInBoDropdown: payload.showInBoDropdown,
        });
      }
      state.updateDropdownEntryStatusStatus.status = "idle";
      state.updateDropdownEntryStatusStatus.loaded = true;
    },
    [updateDropdownEntryStatusAsync.rejected]: (state) => {
      state.updateDropdownEntryStatusStatus.status = "failed";
    },
    [deleteBeneficialOwnerAsync.pending]: (state, action) => {
      state.deleteBeneficialOwnerStatus.status = "loading";
      state.deleteBeneficialOwnerStatus.error = false;
      state.deleteBeneficialOwnerStatus.loaded = false;
      state.deleteBeneficialOwnerStatus.inProgressIds.push(
        action.meta.arg.beneficialOwnerId
      );
    },
    [deleteBeneficialOwnerAsync.fulfilled]: (state, { payload }) => {
      const { beneficialOwners } = state;
      const index = beneficialOwners.findIndex((bo) => payload._id === bo._id);
      if (index > -1) beneficialOwners.splice(index, 1);
      state.deleteBeneficialOwnerStatus.status = "idle";
      state.deleteBeneficialOwnerStatus.loaded = true;
      const boIndex = state.deleteBeneficialOwnerStatus.inProgressIds.findIndex(
        (id) => id === payload._id
      );
      state.deleteBeneficialOwnerStatus.inProgressIds.splice(boIndex, 1);
    },
    [deleteBeneficialOwnerAsync.rejected]: (state, action) => {
      state.deleteBeneficialOwnerStatus.status = "failed";
      const boIndex = state.deleteBeneficialOwnerStatus.inProgressIds.findIndex(
        (id) => id === action.meta.arg.beneficialOwnerId
      );
      state.deleteBeneficialOwnerStatus.inProgressIds.splice(boIndex, 1);
    },
    [getAllBeneficialOwners.pending]: (state) => {
      state.getAllBeneficialOwnersStatus.status = "loading";
      state.getAllBeneficialOwnersStatus.error = false;
      state.getAllBeneficialOwnersStatus.loaded = false;
    },
    [getAllBeneficialOwners.fulfilled]: (
      state,
      { payload: { items, totalCount } }
    ) => {
      state.getAllBeneficialOwnersStatus.status = "idle";
      state.getAllBeneficialOwnersStatus.loaded = true;
      state.beneficialOwners = items;
      state.totalCount = totalCount;
    },
    [getAllBeneficialOwners.rejected]: (state) => {
      state.getAllBeneficialOwnersStatus.status = "failed";
    },
  },
});

export const {
  addBeneficialOwner,
  updateBeneficialOwner,
  deleteBeneficialOwner,
  setBeneficialOwner,
  resetBeneficialOwnerTable,
  updateBONestedField,
} = beneficialOwnerTableSlice.actions;

export default beneficialOwnerTableSlice.reducer;
