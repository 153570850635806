export default {
  primaryBusinessActivity: {
    sectors: [
      {
        value: 'Agriculture,Forestry & Fishing',
        label: ' Agriculture,Forestry & Fishing',
      },
      {
        value: 'Mining and Quarrying',
        label: 'Mining and Quarrying',
      },
      {
        value: 'Manufacturing',
        label: 'Manufacturing',
      },
      {
        value: 'Electricity, Gas, Steam And Air Conditioning Supply',
        label: 'Electricity, Gas, Steam And Air Conditioning Supply',
      },

      {
        value:
          'Water Supply; Sewerage, Waste Management And Remediation Activities',
        label:
          'Water Supply; Sewerage, Waste Management And Remediation Activities',
      },
      {
        value: 'Construction',
        label: 'Construction',
      },
      {
        value:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
        label:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
      },
      {
        value: 'Transportation And Storage',
        label: 'Transportation And Storage',
      },
      {
        value: 'Accomodation And Food Service Activities',
        label: 'Accomodation And Food Service Activities',
      },
      {
        value: 'Information And Communication',
        label: 'Information And Communication',
      },
      {
        value: 'Financial And Insurance Activities',
        label: 'Financial And Insurance Activities',
      },
      {
        value: 'Real Estate Activities',
        label: 'Real Estate Activities',
      },
      {
        value: 'Professional,Scientific And Technical Activities',
        label: 'Professional,Scientific And Technical Activities',
      },
      {
        value: 'Adminstrative And Support Service Activities',
        label: 'Adminstrative And Support Service Activities',
      },
      {
        value: 'Public Adminstration And Defence; Compulsory Social Security',
        label: 'Public Adminstration And Defence; Compulsory Social Security',
      },
      {
        value: 'Education',
        label: 'Education',
      },
      {
        value: 'Human Health And Social Work Activities',
        label: 'Human Health And Social Work Activities',
      },
      {
        value: 'Arts, Entertainment And Recreation',
        label: 'Arts, Entertainment And Recreation',
      },
      {
        value: 'Other Service Activities',
        label: 'Other Service Activities',
      },
      {
        value:
          'Activities Of Households As employers; Undifferenciated Goods And Services- Producing activities Of Households For Own Use',
        label:
          'Activities Of Households As employers; Undifferenciated Goods And Services- Producing activities Of Households For Own Use',
      },
      {
        value: 'Activities Of Extraterritorial Organizations And Bodies',
        label: 'Activities Of Extraterritorial Organizations And Bodies',
      },
    ],
    divisions: [
      {
        value:
          'Crop and animal production,hunting and related service activies',
        label:
          'Crop and animal production,hunting and related service activies',
        sector: 'Agriculture,Forestry & Fishing',
      },
      {
        sector: 'Agriculture,Forestry & Fishing',
        value: 'Forestry and Logging',
        label: 'Forestry and Logging',
      },
      {
        sector: 'Agriculture,Forestry & Fishing',
        value: 'Fishing and aquaculture',
        label: 'Fishing and aquaculture',
      },
      {
        sector: 'Mining and Quarrying',
        value: 'Mining of coal and lignite',
        label: 'Mining of coal and lignite',
      },
      {
        sector: 'Mining and Quarrying',
        value: 'Extraction of crude petroleum and natural gas',
        label: 'Extraction of crude petroleum and natural gas',
      },
      {
        sector: 'Mining and Quarrying',
        value: 'Mining of metal ores',
        label: 'Mining of metal ores',
      },
      {
        sector: 'Mining and Quarrying',
        value: 'Other mining and quarrying',
        label: 'Other mining and quarrying',
      },
      {
        sector: 'Mining and Quarrying',
        value: 'Mining support service activities',
        label: 'Mining support service activities',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of food products',
        label: 'Manufacture of food products',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of beverages',
        label: 'Manufacture of beverages',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of tobacco products',
        label: 'Manufacture of tobacco products',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of textiles',
        label: 'Manufacture of textiles',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of wearing apparel',
        label: 'Manufacture of wearing apparel',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of leather and related products',
        label: 'Manufacture of leather and  related products',
      },
      {
        sector: 'Manufacturing',
        value:
          'Manufacture of wood and products of wood and cork expect furniture,Manufacture of articles of straw and plaiting materials',
        label:
          'Manufacture of wood and products of wood and cork expect furniture,Manufacture of articles of straw and plaiting materials',
      },

      {
        sector: 'Manufacturing',
        value: 'Manufacture of paper and paper products',
        label: 'Manufacture of paper and paper products',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of printing and reproduction of recorded media',
        label: 'Manufacture of printing and reproduction of recorded media',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of coke and refined petroleum products',
        label: 'Manufacture of coke and refined petroleum products',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of chemicals and chemical products',
        label: 'Manufacture of chemicals and chemical products',
      },
      {
        sector: 'Manufacturing',
        value:
          'Manufacture of pharmaceuticals,medicinal chemical and botanical products',
        label:
          'Manufacture of pharmaceuticals,medicinal chemical and botanical products',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of rubber and plastic products',
        label: 'Manufacture of rubber and plastic products',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of other non-metallic mineral products',
        label: 'Manufacture of other non-metallic mineral products',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of basic metals',
        label: 'Manufacture of basic metals',
      },
      {
        sector: 'Manufacturing',
        value:
          'Manufacture of fabricated metal products,except machinery and equipment',
        label:
          'Manufacture of fabricated metal products,except machinery and equipment',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of computer,electronic and optical products',
        label: 'Manufacture of computer,electronic and optical products',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of electrical equipment',
        label: 'Manufacture of electrical equipment',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of machinery and equipment',
        label: 'Manufacture of machinery and equipment',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of motor vehicles',
        label: 'Manufacture of motor vehicles',
      },
      {
        sector: 'Manufacturing',
        value: 'Manufacture of other transport equipment',
        label: 'Manufacture of other transport equipment',
      },

      {
        sector: 'Manufacturing',
        value: 'Manufacture of furniture',
        label: 'Manufacture of furniture',
      },
      {
        sector: 'Manufacturing',
        value: 'Other manufacturing',
        label: 'Other manufacturing',
      },
      {
        sector: 'Manufacturing',
        value: 'Repair and installation of machinery',
        label: 'Repair and installation of machinery',
      },
      {
        sector: 'Electricity, Gas, Steam And Air Conditioning Supply',
        value: 'Electricity,Gas,Steam And Air Conditioning Supply',
        label: 'Electricity,Gas,Steam And Air Conditioning Supply',
      },
      {
        sector:
          'Water Supply; Sewerage, Waste Management And Remediation Activities',
        value: 'Water Collection,treatment and supply',
        label: 'Water Collection,treatment and supply',
      },
      {
        sector:
          'Water Supply; Sewerage, Waste Management And Remediation Activities',
        value: 'Sewerage',
        label: 'Sewerage',
      },
      {
        sector:
          'Water Supply; Sewerage, Waste Management And Remediation Activities',
        value:
          'Waste collection,treatment and disposal activities,materials recovery',
        label:
          'Waste collection,treatment and disposal activities,materials recovery',
      },
      {
        sector:
          'Water Supply; Sewerage, Waste Management And Remediation Activities',
        value: 'Water Collection,treatment and supply',
        label: 'Water Collection,treatment and supply',
      },
      {
        sector:
          'Water Supply; Sewerage, Waste Management And Remediation Activities',
        value: 'Remediation activities and other waste management services',
        label: 'Remediation activities and other waste management services',
      },
      {
        sector: 'Construction',
        value: 'Construction of buildings',
        label: 'Construction of buildings',
      },
      {
        sector: 'Construction',
        value: 'Civil Engineering',
        label: 'Civil Engineering',
      },
      {
        sector: 'Construction',
        value: 'Specialized construction activities',
        label: 'Specialized construction activities',
      },
      {
        sector:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
        value:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
        label:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
      },
      {
        sector:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
        value: 'Wholesale trade except of motor vehicles and motorcycles',
        label: 'Wholesale trade except of motor vehicles and motorcycles',
      },
      {
        sector:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
        value: 'Retail trade except of motor vehicles and motorcycles',
        label: 'Retail trade except of motor vehicles and motorcycles',
      },
      {
        sector: 'Transportation And Storage',
        value: 'Land transport and transport via pipelines',
        label: 'Land transport and transport via pipelines',
      },
      {
        sector: 'Transportation And Storage',
        value: 'Water transport',
        label: 'Water transport',
      },
      {
        sector: 'Transportation And Storage',
        value: 'Air transport',
        label: 'Air transport',
      },
      {
        sector: 'Transportation And Storage',
        value: 'Warehousing and support activities for transportation',
        label: 'Warehousing and support activities for transportation',
      },
      {
        sector: 'Transportation And Storage',
        value: 'Postal and courier activities',
        label: 'Postal and courier activities',
      },
      {
        sector: 'Accomodation And Food Service Activities',
        value: 'Accomodation',
        label: 'Accomodation',
      },
      {
        sector: 'Accomodation And Food Service Activities',
        value: 'Food and beverage service activities',
        label: 'Food and beverage service activities',
      },
      {
        sector: 'Information And Communication',
        value: 'Publishing Activities',
        label: 'Publishing Activities',
      },
      {
        sector: 'Information And Communication',
        value:
          'Motion picture,video and television programme production,sound recording and music publishing activities',
        label:
          'Motion picture,video and television programme production,sound recording and music publishing activities',
      },
      {
        sector: 'Information And Communication',
        value: 'Broadcasting and programming activites',
        label: 'Broadcasting and programming activites',
      },
      {
        sector: 'Information And Communication',
        value: 'Telecommunication',
        label: 'Telecommunication',
      },
      {
        sector: 'Information And Communication',
        value: 'Information technology service activities',
        label: 'Information technology service actitivies',
      },
      {
        sector: 'Information And Communication',
        value: 'Information service activites',
        label: 'Information service activites',
      },
      {
        sector: 'Financial And Insurance Activities',
        value: 'Financial intermediation except insurance and pension funding',
        label: 'Financial intermediation except insurance and pension funding',
      },
      {
        sector: 'Financial And Insurance Activities',
        value:
          'Insurance,reinsurance and pension funding except compulsory social security',
        label:
          'Insurance,reinsurance and pension funding except compulsory social security',
      },
      {
        sector: 'Financial And Insurance Activities',
        value: 'Other financial activites',
        label: 'Other financial activites',
      },
      {
        sector: 'Real Estate Activities',
        value: 'Real Estate Activities',
        label: 'Real Estate Activities',
      },
      {
        sector: 'Professional,Scientific And Technical Activities',
        value: 'Legal and accounting activities',
        label: 'Legal and accounting activities',
      },
      {
        sector: 'Professional,Scientific And Technical Activities',
        value:
          'Activities of head offices,management and management consultancy activities',
        label:
          'Activities of head office,management and management consultancy activities',
      },
      {
        sector: 'Professional,Scientific And Technical Activities',
        value: 'Scientific research and development',
        label: 'Scientific research and development',
      },
      {
        sector: 'Professional,Scientific And Technical Activities',
        value: 'Advertising and market research',
        label: 'Advertising and market research',
      },
      {
        sector: 'Professional,Scientific And Technical Activities',
        value: 'Other professional,scientific and techical activities',
        label: 'Other professional,secientific and technical activities',
      },
      {
        sector: 'Professional,Scientific And Technical Activities',
        value: 'Veterinary activities',
        label: 'Veterinary activities',
      },

      {
        sector: 'Professional,Scientific And Technical Activities',
        value:
          'Architectural and engineering activities;technical testing and analysis',
        label:
          'Architectural and engineering activities;technical testing and analysis',
      },
      {
        sector: 'Adminstrative And Support Service Activities',
        value: 'Rental and leasing activities',
        label: 'Rental and leasing activities',
      },
      {
        sector: 'Adminstrative And Support Service Activities',
        value: 'Employment Activities',
        label: 'Employment Activities',
      },
      {
        sector: 'Adminstrative And Support Service Activities',
        value:
          'Travel agency,tour operator and other reservation service activities',
        label:
          'Travel agency,tour operator and other reservation service activities',
      },
      {
        sector: 'Adminstrative And Support Service Activities',
        value: 'Security and investigation activities',
        label: 'Security and investigation activities',
      },
      {
        sector: 'Adminstrative And Support Service Activities',
        value: 'Services to buildings and landscape activities',
        label: 'Services to buildings and landscape activities',
      },
      {
        sector: 'Adminstrative And Support Service Activities',
        value:
          'Office adminstrative,office support and other business support architecture',
        label:
          'Office adminstrative,office support and other business support architecture',
      },
      {
        sector: 'Public Adminstration And Defence; Compulsory Social Security',
        value: 'Public Adminstration And Defence; Compulsory Social Security',
        label: 'Public Adminstration And Defence; Compulsory Social Security',
      },
      {
        sector: 'Education',
        value: 'Education',
        label: 'Education',
      },
      {
        sector: 'Human Health And Social Work Activities',
        value: 'Human Health Activities',
        label: 'Human Health Activities',
      },
      {
        sector: 'Human Health And Social Work Activities',
        value: 'Residential care activities',
        label: 'Residential care activities',
      },
      {
        sector: 'Human Health And Social Work Activities',
        value: 'Social work activities without accomodation',
        label: 'Social work activities without accomodation',
      },
      {
        sector: 'Arts, Entertainment And Recreation',
        value: 'Creative,arts and entertainment activities',
        label: 'Creative,arts and entertainment activities',
      },
      {
        sector: 'Arts, Entertainment And Recreation',
        value: 'Libraries,archives,museums and other cultural activities',
        label: 'Libraries,archives,museums and other cultural activities',
      },
      {
        sector: 'Arts, Entertainment And Recreation',
        value: 'Gambling and betting activities',
        label: 'Gambling and betting activities',
      },
      {
        sector: 'Arts, Entertainment And Recreation',
        value: 'Sport activities,amusement and recreation',
        label: 'Sport activities,amusement and recreation',
      },
      {
        sector: 'Other Service Activities',
        value: 'Activities of membership organzation',
        label: ' Activities of membership organzation',
      },
      {
        sector: 'Other Service Activities',
        value: 'Repair of computers,personal and household goods',
        label: 'Repair of computers,personal and household goods',
      },
      {
        sector: 'Other Service Activities',
        value: 'Other Service Activities',
        label: 'Other Service Activities',
      },
      {
        sector:
          'Activities Of Households As employers; Undifferenciated Goods And Services- Producing activities Of Households For Own Use',
        value: 'Activities of households as employers of domestic personnel',
        label: 'Activities of households as employers of domestic personnel',
      },
      {
        sector:
          'Activities Of Households As employers; Undifferenciated Goods And Services- Producing activities Of Households For Own Use',
        value:
          'Undifferenciated goods and services producing activities of private households for own use',
        label:
          'Undifferenciated goods and services producing activities of private households for own use',
      },
      {
        sector: 'Activities Of Extraterritorial Organizations And Bodies',
        value: 'Support activities for other mining and quarrying',
        label: 'Support activities for other mining and quarrying',
      },
    ],
    groups: [
      {
        label: 'Growing of non-perennial crops',
        value: 'Growing of non-perennial crops',
        division:
          'Crop and animal production,hunting and related service activies',
      },
      {
        label: 'Growing of perennial crops',
        value: 'Growing of perennial crops',
        division:
          'Crop and animal production,hunting and related service activies',
      },
      {
        label: 'Plant propagation',
        value: 'Plant propagation',
        division:
          'Crop and animal production,hunting and related service activies',
      },
      {
        label: 'Animal Propagation',
        value: 'Animnal propagation',
        division:
          'Crop and animal production,hunting and related service activies',
      },
      {
        label: 'Mixed farming',
        value: 'Mixed farming',
        division:
          'Crop and animal production,hunting and related service activies',
      },
      {
        label:
          'Support activities to agriculture and post harvest crop activities',
        value:
          'Support activities to agriculture and post harvest crop activities',
        division:
          'Crop and animal production,hunting and related service activies',
      },
      {
        label: 'Hunting,trapping and related service activities',
        value: 'Hunting,trapping and related service activities',
        division:
          'Crop and animal production,hunting and related service activies',
      },
      {
        label: 'Silviculture and other forestry activities',
        value: 'Silviculture and other forestry activities',
        division: 'Forestry and Logging',
      },
      {
        label: 'Logging',
        value: 'Logging',
        division: 'Forestry and Logging',
      },
      {
        label: 'Gathering of non-wood forest products',
        value: 'Gathering of non-wood forest products',
        division: 'Forestry and Logging',
      },
      {
        label: 'Support services to forestry',
        value: 'Support services to forestry',
        division: 'Forestry and Logging',
      },
      {
        label: 'Fishing',
        value: 'Fishing',
        division: 'Fishing and aquaculture',
      },
      {
        label: 'Aquaculture',
        value: 'Aquaculture',
        division: 'Fishing and aquaculture',
      },
      {
        label: 'Mining of hard coal',
        value: 'Mining of hard coal',
        division: 'Mining of coal and lignite',
      },
      {
        label: 'Mining of lignite',
        value: 'Mining of lignite',
        division: 'Mining of coal and lignite',
      },
      {
        label: 'Extraction of crude petroleum',
        value: 'Extraction of crude petroleum',
        division: 'Extraction of crude petroleum and natural gas',
      },
      {
        label: 'Extraction of natural gas',
        value: 'Extraction of natural gas',
        division: 'Extraction of crude petroleum and natural gas',
      },
      {
        label: 'Mining of iron ores',
        value: 'Mining of iron ores',
        division: 'Mining of metal ores',
      },
      {
        label: 'Mining of non-ferrous metal ores',
        value: 'Mining of non-ferrous metal ores',
        division: 'Mining of metal ores',
      },
      {
        label: 'Quarrying of a stone,sand and clay',
        value: 'Quarrying of a stone,sand and clay',
        division: 'Other mining and quarrying',
      },
      {
        label: 'Mining and quarrying',
        value: 'Mining and quarrying',
        division: 'Other mining and quarrying',
      },
      {
        label: 'Support activities for petroleum and natural gas mining',
        value: 'Support activities for petroleum and natural gas mining',
        division: 'Mining support service activities',
      },
      {
        label: 'Processing and preserving meat',
        value: 'Processing and preserving meat',
        division: 'Manufacture of food products',
      },
      {
        label: 'Processing and preserving of fish,crustaceans and molluscs',
        value: 'Processing and preserving of fish,crustaceans and molluscs',
        division: 'Manufacture of food products',
      },
      {
        label: 'Processing and preserving of fruits and vegetables',
        value: 'Processing and preserving of fruits and vegetables',
        division: 'Manufacture of food products',
      },
      {
        label: 'Manufacture of vegetable and animal oils and fats',
        value: 'Manufacture of vegetable and animal oils and fats',
        division: 'Manufacture of food products',
      },

      {
        label: 'Manufacture of dairy products',
        value: 'Manufacture of dairy products',
        division: 'Manufacture of food products',
      },
      {
        label:
          'Manufacture of grain mill products,starches and strach products',
        value:
          'Manufacture of grain mill products,starches and starch products',
        division: 'Manufacture of food products',
      },
      {
        label: 'Manufacture of other food products',
        value: 'Manufacture of other food products',
        division: 'Manufacture of food products',
      },
      {
        label: 'Manufacture of prepared animal feeds',
        value: 'Manufacture of prepared animal feeds',
        division: 'Manufacture of food products',
      },
      {
        label: 'Manufacture of beverages',
        value: 'Manufacture of beverages',
        division: 'Manufacture of beverages',
      },
      {
        label: 'Manufacture of tobacco products',
        value: 'Manufacture of tobacco products',
        division: 'Manufacture of tobacco products',
      },
      {
        label: 'Spinning,weaving and finishing textiles',
        value: 'Spinning,weaving and finishing textiles',
        division: 'Manufacture of textiles',
      },
      {
        label: 'Manufacture of other textiles',
        value: 'Manufacture of other textiles',
        division: 'Manufacture of textiles',
      },
      {
        label: 'Manufacture of wearing apparel,except fur apparel',
        value: 'Manufacture of wearing apparel,except fur apparel',
        division: 'Manufacture of wearing apparel',
      },
      {
        label: 'Manufacture of articles of fur',
        value: 'Manufacture of articles of fur',
        division: 'Manufacture of wearing apparel',
      },
      {
        label: 'Manufacture of knitted and crocheted apparel',
        value: 'Manufacture of knitted and crocheted apparel',
        division: 'Manufacture of wearing apparel',
      },

      {
        label:
          'Tanning and dressing of leather,manufacture of luggage,handbags,saddlery and harness,dressing and dyeing of fur',
        value:
          'Tanning and dressing of leather,manufacture of luggage,handbags,saddlery and harness,dressing and dyeing of fur',
        division: 'Manufacture of leather and related products',
      },
      {
        label: 'Manufacture of footwear',
        value: 'Manufacture of footwear',
        division: 'Manufacture of leather and related products',
      },

      {
        label: 'Sawmilling and planning of wood',
        value: 'Sawmilling and planning of wood',
        division:
          'Manufacture of wood and products of wood and cork expect furniture,Manufacture of articles of straw and plaiting materials',
      },
      {
        label:
          'Manufacture of products of wood,cork,straw and plaiting materials',
        value:
          'Manufacture of products of wood,cork,straw and plaiting materials',
        division:
          'Manufacture of wood and products of wood and cork expect furniture,Manufacture of articles of straw and plaiting materials',
      },
      {
        label: 'Manufacture of paper and paper products',
        value: 'Manufacture of paper and paper products',
        division: 'Manufacture of paper and paper products',
      },
      {
        label: 'Printing and service activities related to printing',
        value: 'Printing and service activities related to printing',
        division: 'Manufacture of printing and reproduction of recorded media',
      },
      {
        label: 'Reproduction of recorded media',
        value: 'Reproduction of recorded media',
        division: 'Manufacture of printing and reproduction of recorded media',
      },
      {
        label: 'Manufacture of coke oven products',
        value: 'Manufacture of coke oven products',
        division: 'Manufacture of coke and refined petroleum products',
      },
      {
        label: 'Manufacture of refined petroleum products',
        value: 'Manufacture of refined petroleum products',
        division: 'Manufacture of coke and refined petroleum products',
      },
      {
        label:
          'Manufacture of basic chemicals,fertilizer and nitrogen compounds,plastics and synthetic rubber in primary forms',
        value:
          'Manufacture of basic chemicals,fertilizer and nitrogen compounds,plastics and synthetic rubber in primary forms',
        division: 'Manufacture of chemicals and chemical products',
      },
      {
        label: 'Manufacture of other chemical products',
        value: 'Manufacture of other chemical products',
        division: 'Manufacture of chemicals and chemical products',
      },
      {
        label: 'Manufacture of man-made fibers',
        value: 'Manufacture of man-made fibers',
        division: 'Manufacture of chemicals and chemical products',
      },
      {
        label:
          'Manufacture of pharmaceuticals,medicinal chemical and botanical products',
        value:
          'Manufacture of pharmaceuticals,medicinal chemical and botanical products',
        division:
          'Manufacture of pharmaceuticals,medicinal chemical and botanical products',
      },
      {
        label: 'Manufacture of rubber products',
        value: 'Manufacture of rubber products',
        division: 'Manufacture of rubber and plastic products',
      },
      {
        label: 'Manufacture of plastics products',
        value: 'Manufacture of plastics products',
        division: 'Manufacture of rubber and plastic products',
      },
      {
        label: 'Manufacture of glass and glass products',
        value: 'Manufacture of glass and glass products',
        division: 'Manufacture of other non-metallic mineral products',
      },
      {
        label: 'Manufacture of non-metallic mineral products',
        value: 'Manufacture of non-metallic mineral products',
        division: 'Manufacture of other non-metallic mineral products',
      },
      {
        label: 'Manufacture of basic iron and steel',
        value: 'Manufacture of basic iron and steel',
        division: 'Manufacture of basic metals',
      },
      {
        label: 'Manufacture of basic precious and other non-ferrous metals',
        value: 'Manufacture of basic precious and other non-ferrous metals',
        division: 'Manufacture of basic metals',
      },
      {
        label: 'Casting of metals',
        value: 'Casting of metals',
        division: 'Manufacture of basic metals',
      },
      {
        label:
          'Manufacture of structural metal products,tanks,reservoirs and steam generators',
        value:
          'Manufacture of structural metal products,tanks,reservoirs and steam generators',
        division:
          'Manufacture of fabricated metal products,except machinery and equipment',
      },
      {
        label: 'Manufacture of weapons and ammunition',
        value: 'Manufacture of weapons and ammunition',
        division:
          'Manufacture of fabricated metal products,except machinery and equipment',
      },
      {
        label:
          'Manufacture of other fabricated metal products, metal working service activities',
        value:
          'Manufacture of other fabricated metal products,metal working service activities',
        division:
          'Manufacture of fabricated metal products,except machinery and equipment',
      },

      {
        label: 'Manufacture of electronic components',
        value: 'Manufacture of electronic components',
        division: 'Manufacture of computer,electronic and optical products',
      },
      {
        label: 'Manufacture of computers and peripheral equipment',
        value: 'Manufacture of computers and peripheral equipment',
        division: 'Manufacture of computer,electronic and optical products',
      },
      {
        label: 'Manufacture of communication equipment',
        value: 'Manufacture of communication equipment',
        division: 'Manufacture of computer,electronic and optical products',
      },
      {
        label: 'Manufacture of consumer electronics',
        value: 'Manufacture of consumer electronics',
        division: 'Manufacture of computer,electronic and optical products',
      },
      {
        label:
          'Manufacture of measuring,testing,navigating and control equipment,watches and clocks',
        value:
          'Manufacture of measuring,testing,navigating and control equipment,watches and clocks',
        division: 'Manufacture of computer,electronic and optical products',
      },
      {
        label:
          'Manufacture of irradiation and electromedical and electrotheraputic',
        value:
          'Manufacture of irradiation and electromedical and electrotheraputic',
        division: 'Manufacture of computer,electronic and optical products',
      },
      {
        label: 'Manufacture of optical instruments and equipment',
        value: 'Manufacture of optical instruments and equipment',
        division: 'Manufacture of computer,electronic and optical products',
      },
      {
        label: 'Manufacture of magnetic and optical media',
        value: 'Manufacture of magnetic and optical media',
        division: 'Manufacture of computer,electronic and optical products',
      },
      {
        label:
          'Manufacture of electronic motors,generators,transformers and electricity distribution and control apparatus',
        value:
          'Manufacture of electronic motors,generators,transformers and electricity distribution and control apparatus',
        division: 'Manufacture of electrical equipment',
      },
      {
        label: 'Manufacture of batteries and accumulators',
        value: 'Manufacture of batteries and accumulators',
        division: 'Manufacture of electrical equipment',
      },
      {
        label: 'Manufacture of wiring and wiring devices',
        value: 'Manufacture of wiring and wiring devices',
        division: 'Manufacture of electrical equipment',
      },
      {
        label: 'Manufacture of electric lighting equipment',
        value: 'Manufacture of electric lighting equipment',
        division: 'Manufacture of electrical equipment',
      },
      {
        label: 'Manufacture of domestic appliances',
        value: 'Manufacture of domestic appliances',
        division: 'Manufacture of electrical equipment',
      },
      {
        label: 'Manufacture of other electrical equipment',
        value: 'Manufacture of other electrical equipment',
        division: 'Manufacture of electrical equipment',
      },

      {
        label: 'Manufacture of general purpose machinery',
        value: 'Manufacture of general purpose machinery',
        division: 'Manufacture of machinery and equipment',
      },
      {
        label: 'Manufacture of special purpose machinery',
        value: 'Manufacture of special purpose machinery',
        division: 'Manufacture of machinery and equipment',
      },
      {
        label: 'Manufacture of motor vehicles',
        value: 'Manufacture of motor vehicles',
        division: 'Manufacture of motor vehicles',
      },
      {
        label:
          'Manufacture of bodies(coachwork) for motor vehicles,manufacture of trailers and semi-trailers',
        value:
          'Manufacture of bodies(coachwork) for motor vehicles,manufacture of trailers and semi-trailers',
        division: 'Manufacture of motor vehicles',
      },
      {
        label:
          'Manufacture of parts and accessories for motor vehicles and their engines',
        value:
          'Manufacture of parts and accessories for motor vehicles and their engines',
        division: 'Manufacture of motor vehicles',
      },
      {
        label: 'Building of ships and boats',
        value: 'Building of ships and boats',
        division: 'Manufacture of other transport equipment',
      },
      {
        label: 'Manufacture of railway locomotives and rolling stock',
        value: 'Manufacture of railway locomotives and rolling stock',
        division: 'Manufacture of other transport equipment',
      },
      {
        label: 'Manufacture of air and spacecraft and related machinery',
        value: 'Manufacture of air and spacecraft and related machinery',
        division: 'Manufacture of other transport equipment',
      },
      {
        label: 'Manufacture of military fighting vehicles',
        value: 'Manufacture of military fighting vehicles',
        division: 'Manufacture of other transport equipment',
      },
      {
        label: 'Manufacture of transport equipment',
        value: 'Manufacture of transport equipment',
        division: 'Manufacture of other transport equipment',
      },
      {
        label: 'Manufacture of furniture',
        value: 'Manufacture of furniture',
        division: 'Manufacture of furniture',
      },
      {
        label: 'Manufacture of jewellery,bijouterie and related articles',
        value: 'Manufacture of jewellery,bijouterie and related articles',
        division: 'Other manufacturing',
      },
      {
        label: 'Manufacture of musical instruments',
        value: 'Manufacture of musical instruments',
        division: 'Other manufacturing',
      },
      {
        label: 'Manufacture of sport goods',
        value: 'Manufacture of sport goods',
        division: 'Other manufacturing',
      },
      {
        label: 'Manufacture of games and toys',
        value: 'Manufacture of games and toys',
        division: 'Other manufacturing',
      },
      {
        label: 'Manufacture of medical and dental instruments and supplies',
        value: 'Manufacture of medical and dental instruments and supplies',
        division: 'Other manufacturing',
      },
      {
        label: 'Manufacture of other manufacturing n.e.c',
        value: 'Manufacture of other manufacturing n.e.c',
        division: 'Other manufacturing',
      },
      {
        label: 'Repair of fabricated metal products,machinery and equipment',
        value: 'Repair of fabricated metal products,machinery and equipment',
        division: 'Repair and installation of machinery',
      },
      {
        label: 'Installation of industrial machinery and equipment',
        value: 'Installation of industrial machinery and equipment',
        division: 'Repair and installation of machinery',
      },
      {
        label: 'Electric power generation,transmission and distribution',
        value: 'Electric power generation,transmission and distribution',
        division: 'Electricity,Gas,Steam And Air Conditioning Supply',
      },
      {
        label: 'Manufacture of gas,distribution of gaseous fuels through mains',
        value: 'Manufacture of gas,distribution of gaseous fuels through mains',
        division: 'Electricity,Gas,Steam And Air Conditioning Supply',
      },
      {
        label: 'Steam and air conditioning supply',
        value: 'Steam and air conditioning supply',
        division: 'Electricity,Gas,Steam And Air Conditioning Supply',
      },
      {
        label: 'Water Collection,treatment and supply',
        value: 'Water Collection,treatment and supply',
        division: 'Water Collection,treatment and supply',
      },
      {
        label: 'Sewerage',
        value: 'Sewerage',
        division: 'Sewerage',
      },
      {
        label: 'Waste Collection',
        value: 'Waste Collection',
        division:
          'Waste collection,treatment and disposal activities,materials recovery',
      },
      {
        label: 'Waste treatment and disposal',
        value: 'Waste treatment and disposal',
        division:
          'Waste collection,treatment and disposal activities,materials recovery',
      },
      {
        label: 'Materials recovery',
        value: 'Materials recovery',
        division:
          'Waste collection,treatment and disposal activities,materials recovery',
      },
      {
        label: 'Remediation activities and other waste management services',
        value: 'Remediation activities and other waste management services',
        division: 'Remediation activities and other waste management services',
      },
      {
        label: 'Construction of buildings',
        value: 'Construction of buildings',
        division: 'Construction of buildings',
      },
      {
        label: 'Construction of roads and railways',
        value: 'Construction of roads and railways',
        division: 'Civil Engineering',
      },
      {
        label: 'Construction of utility projects',
        value: 'Construction of utility projects',
        division: 'Civil Engineering',
      },
      {
        label: 'Construction of other civil engineering projects',
        value: 'Construction of other civil engineering projects',
        division: 'Civil Engineering',
      },
      {
        label: 'Demolition and site preparation',
        value: 'Demolition and site preparation',
        division: 'Specialized construction activities',
      },
      {
        label:
          'Electrical,plumbing and other construction installation activities',
        value:
          'Electrical,plumbing and other construction installation activities',
        division: 'Specialized construction activities',
      },
      {
        label: 'Building completion and finishing',
        value: 'Building completion and finishing',
        division: 'Specialized construction activities',
      },
      {
        label: 'Other specialized construction activities',
        value: 'Other specialized construction activities',
        division: 'Specialized construction activities',
      },
      {
        label: 'Sale of motor vehicles',
        value: 'Sale of motor vehicles',
        division:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
      },
      {
        label: 'Maintainance and repair of motor vehicles',
        value: 'Maintainance and repair of motor vehicles',
        division:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
      },
      {
        label: 'Sale of motor vehicle parts and accessories',
        value: 'Sale of motor vehicle parts and accessories',
        division:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
      },
      {
        label:
          'Sale,maintainance and repair of motorcycles and related parts and accessories',
        value:
          'Sale,maintainance and repair of motorcycles and related parts and accessories',
        division:
          'Wholesale And Retail Trade; Repair of Motor Vehicles And Motorcycles',
      },
      {
        label: 'Wholesale on a fee or contract basis',
        value: 'Wholesale on a fee or contract basis',
        division: 'Wholesale trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Wholesale of agricultural raw materials and live animals',
        value: 'Wholesale of agricultural raw materials and live animals',
        division: 'Wholesale trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Wholesale of food,beverages and tobacco',
        value: 'Wholesale of food,beverages and tobacco',
        division: 'Wholesale trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Wholesale of household goods',
        value: 'Wholesale of household goods',
        division: 'Wholesale trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Wholesale of machinery equipment and supplies',
        value: 'Wholesale of machinery equipment and supplies',
        division: 'Wholesale trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Other specialized wholesale',
        value: 'Other specialized wholesale',
        division: 'Wholesale trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Non-specialized wholesale trade',
        value: 'Non-specialized wholesale trade',
        division: 'Wholesale trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Retail sale in non-specialized stores',
        value: 'Retail sale in non-specialized stores',
        division: 'Retail trade except of motor vehicles and motorcycles',
      },
      {
        label:
          'Retail sale of food,beverages and tobacco in specialized stores',
        value:
          'Retail sale of food,beverages and tobacco in specialized stores',
        division: 'Retail trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Retail sale of automotive fuel',
        value: 'Retail sale of automotive fuel',
        division: 'Retail trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Retail sale of ICT equipment in specialized stores',
        value: 'Retail sale of ICT equipment in specialized stores',
        division: 'Retail trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Retail sale of other households in specialized stores',
        value: 'Retail sale of other households in specialized stores',
        division: 'Retail trade except of motor vehicles and motorcycles',
      },
      {
        label:
          'Retail sale of cultural and recreation goods in specialized stores',
        value:
          'Retail sale of cultural and recreation goods in specialized stores',
        division: 'Retail trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Retail sale of other goods in specialized stores',
        value: 'Retail sale of other goods in specialized stores',
        division: 'Retail trade except of motor vehicles and motorcycles',
      },
      {
        label: 'Retail sale via stalls and markets',
        value: 'Retail sale via stalls and markets',
        division: 'Retail trade except of motor vehicles and motorcycles',
      },

      {
        label: 'Transport via railways',
        value: 'Transport via railways',
        division: 'Land transport and transport via pipelines',
      },
      {
        label: 'Water transport',
        value: 'Water transport',
        division: 'Land transport and transport via pipelines',
      },
      {
        label: 'Air transport',
        value: 'Air transport',
        division: 'Land transport and transport via pipelines',
      },
      {
        label: 'Sea and coastal transport',
        value: 'Sea and coastal transport',
        division: 'Water transport',
      },
      {
        label: 'Inland water transport',
        value: 'Inland water transport',
        division: 'Water transport',
      },
      {
        label: 'Passenger air transport',
        value: 'Passenger air transport',
        division: 'Air transport',
      },
      {
        label: 'Freight air transport',
        value: 'Freight air transport',
        division: 'Air transport',
      },
      {
        label: 'Warehousing and storage',
        value: 'Warehousing and storage',
        division: 'Warehousing and support activities for transportation',
      },
      {
        label: 'Support activities for transportation',
        value: 'Support activities for transportation',
        division: 'Warehousing and support activities for transportation',
      },
      {
        label: 'Postal activities',
        value: 'Postal activities',
        division: 'Postal and courier activities',
      },
      {
        label: 'Courier activities',
        value: 'Courier activities',
        division: 'Postal and courier activities',
      },
      {
        label: 'Short term accomodation activities',
        value: 'Short term accomodation activities',
        division: 'Accomodation',
      },
      {
        label: 'Recreational vehicle parks,trailer parks and camping grounds',
        value: 'Recreational vehicle parks,trailer parks and camping grounds',
        division: 'Accomodation',
      },
      {
        label: 'Other accomodation',
        value: 'Other accomodation',
        division: 'Accomodation',
      },
      {
        label: 'Restaurants and mobile food service activities',
        value: 'Restaurants and mobile food service activities',
        division: 'Food and beverage service activities',
      },
      {
        label: 'Event catering and food service activities',
        value: 'Event catering and food service activities',
        division: 'Food and beverage service activities',
      },
      {
        label: 'Beverage serving activities',
        value: 'Beverage serving activities',
        division: 'Food and beverage service activities',
      },
      {
        label:
          'Publishing of books,periodicals and other publishing activities',
        value:
          'Publishing of books,periodicals and other publishing activities',
        division: 'Publishing Activities',
      },
      {
        label: 'Software Publishing',
        value: 'Software Publishing',
        division: 'Publishing Activities',
      },
      {
        label: 'Motion pictures,video and television programme activities',
        value: 'Motion pictures,video and television programme activities',
        division:
          'Motion picture,video and television programme production,sound recording and music publishing activities',
      },
      {
        label: 'Sound recording and music publishing activities',
        value: 'Sound recording and music publishing activities',
        division:
          'Motion picture,video and television programme production,sound recording and music publishing activities',
      },
      {
        label: 'Radio broadcasting',
        value: 'Radio broadcasting',
        division: 'Broadcasting and programming activites',
      },
      {
        label: 'Television broadcasting and subscription programming',
        value: 'Television broadcasting and subscription programming',
        division: 'Broadcasting and programming activites',
      },
      {
        label: 'Wired Telecommunication activities',
        value: 'Wired Telecommunication activities',
        division: 'Telecommunication',
      },
      {
        label: 'Wireless Telecommunication activities',
        value: 'Wireless Telecommunication activities',
        division: 'Telecommunication',
      },
      {
        label: 'Satellite Telecommunication activities',
        value: 'Satellite Telecommunication activities',
        division: 'Telecommunication',
      },
      {
        label: 'Other Telecommunication activities',
        value: 'Other Telecommunication activities',
        division: 'Telecommunication',
      },
      {
        label: 'Information technology service activities',
        value: 'Information technology service activities',
        division: 'Information technology service activities',
      },
      {
        label: 'Web portals,data processing,hosting and related activities',
        value: 'Web portals,data processing,hosting and related activities',
        division: 'Information service activites',
      },
      {
        label: 'Other information service activities',
        value: 'Other information service activities',
        division: 'Information service activites',
      },
      {
        label: 'Monetary intermediation',
        value: 'Monetary intermediation',
        division:
          'Financial intermediation except insurance and pension funding',
      },
      {
        label: 'Activities of holding companies',
        value: 'Activities of holding companies',
        division:
          'Financial intermediation except insurance and pension funding',
      },
      {
        label: 'Trusts,funds and other financial vehicles',
        value: 'Trusts,funds and other financial vehicles',
        division:
          'Financial intermediation except insurance and pension funding',
      },
      {
        label: 'Other financial intermediation',
        value: 'Other financial intermediation',
        division:
          'Financial intermediation except insurance and pension funding',
      },
      {
        label: 'Insurance',
        value: 'Insurance',
        division:
          'Insurance,reinsurance and pension funding except compulsory social security',
      },
      {
        label: 'Reinsurance',
        value: 'Reinsurance',
        division:
          'Insurance,reinsurance and pension funding except compulsory social security',
      },
      {
        label: 'Pension funding',
        value: 'Pension funding',
        division:
          'Insurance,reinsurance and pension funding except compulsory social security',
      },
      {
        label:
          'Activities to financial intermediation,except insurance and pension funding',
        value:
          'Activities to financial intermediation,except insurance and pension funding',
        division: 'Other financial activites',
      },
      {
        label: 'Activities  auxiliary to insurance and pension funding',
        value: 'Activities  auxiliary to insurance and pension funding',
        division: 'Other financial activites',
      },

      {
        label: 'Fund management activities',
        value: 'Fund management activities',
        division: 'Other financial activites',
      },
      {
        label: 'Real estate activities with own or leased property',
        value: 'Real estate activities with own or leased property',
        division: 'Real Estate Activities',
      },
      {
        label: 'Real activities on a free or contract basis',
        value: 'Real activities on a free or contract basis',
        division: 'Real Estate Activities',
      },
      {
        label: 'Legal activities',
        value: 'Legal activities',
        division: 'Legal and accounting activities',
      },
      {
        label: 'Accounting,bookkeeping and auditing activities;tax consultancy',
        value: 'Accounting,bookkeeping and auditing activities;tax consultancy',
        division: 'Legal and accounting activities',
      },
      {
        label: 'Activities of head offices',
        value: 'Activities of head offices',
        division:
          'Activities of head offices,management and management consultancy activities',
      },
      {
        label: 'Management consultancy activities',
        value: 'Management consultancy activities',
        division:
          'Activities of head offices,management and management consultancy activities',
      },
      {
        label:
          'Architectural and engineering activities and related technical consultancy',
        value:
          'Architectural and engineering activities and related technical consultancy',
        division:
          'Architectural and engineering activities;technical testing and analysis',
      },
      {
        label: 'Technical testing and analysis',
        value: 'Technical testing and analysis',
        division:
          'Architectural and engineering activities;technical testing and analysis',
      },
      {
        label:
          ' Research and experimental development of natural sciences and engineering',
        value:
          ' Research and experimental development of natural sciences and engineering',
        division: 'Scientific research and development',
      },
      {
        label:
          ' Research and experimental development of social sciences and humanities',
        value:
          ' Research and experimental development of social sciences and humanities',
        division: 'Scientific research and development',
      },
      {
        label: ' Advertising',
        value: 'Advertising',
        division: 'Advertising and market research',
      },
      {
        label: 'Market research and public opinion polling',
        value: 'Market research and public opinion polling',
        division: 'Advertising and market research',
      },
      {
        label: 'Specialized design activities',
        value: 'Specialized design activities',
        division: 'Other professional,scientific and techical activities',
      },
      {
        label: 'Photographic activities',
        value: 'Photographic activities',
        division: 'Other professional,scientific and techical activities',
      },
      {
        label: 'Other professional,scientific and techical activities n.e.c',
        value: 'Other professional,scientific and techical activities n.e.c',
        division: 'Other professional,scientific and techical activities',
      },
      {
        label: 'Veterinary activities',
        value: 'Veterinary activities',
        division: 'Veterinary activities',
      },
      {
        label: 'Renting and leasing of motor vehicles',
        value: 'Renting and leasing of motor vehicles',
        division: 'Rental and leasing activities',
      },
      {
        label: 'Renting and leasing of pesonal and household goods',
        value: 'Renting and leasing of personal and household goods',
        division: 'Rental and leasing activities',
      },

      {
        label:
          'Renting and leasing of other machinery,equipment and tangible goods',
        value:
          'Renting and leasing of other machinery,equipment and tangible goods',
        division: 'Rental and leasing activities',
      },
      {
        label: 'Leasing of non-financial intangible assets',
        value: 'Leasing of non-financial intangible assets',
        division: 'Rental and leasing activities',
      },
      {
        label: 'Activities of employment placement agencies',
        value: 'Activities of employment placement agencies',
        division: 'Employment Activities',
      },
      {
        label: 'Temporary employment agency activities',
        value: 'Temporary employment agency activities',
        division: 'Employment Activities',
      },
      {
        label:
          'Human resources provision and management of human resources functions',
        value:
          'Human resources provision and management of human resources functions',
        division: 'Employment Activities',
      },
      {
        label: 'Travel agency and tour operator activities',
        value: 'Travel agency and tour operator activities',
        division:
          'Travel agency,tour operator and other reservation service activities',
      },
      {
        label: 'Other reservation service activities',
        value: 'Other reservation service activities',
        division:
          'Travel agency,tour operator and other reservation service activities',
      },
      {
        label: 'Private security activities',
        value: 'Private security activities',
        division: 'Security and investigation activities',
      },
      {
        label: 'Security systems service activities',
        value: 'Security systems service activities',
        division: 'Security and investigation activities',
      },
      {
        label: 'Investigation activities',
        value: 'Investigation activities',
        division: 'Security and investigation activities',
      },
      {
        label: 'Combined  facilities support activities',
        value: 'Combined  facilities support activities',
        division: 'Services to buildings and landscape activities',
      },
      {
        label: 'Cleaning activities',
        value: 'Cleaning activities',
        division: 'Services to buildings and landscape activities',
      },
      {
        label: 'Landscape care and maintainance service activities',
        value: 'Landscape care and maintainance service activities',
        division: 'Services to buildings and landscape activities',
      },
      {
        label: 'Office adminstrative and support activities',
        value: 'Office adminstrative and support activities',
        division:
          'Office adminstrative,office support and other business support architecture',
      },
      {
        label: 'Activities of call centers',
        value: 'Activities of call centers',
        division:
          'Office adminstrative,office support and other business support architecture',
      },
      {
        label: 'Convention and trade show organizers',
        value: 'Convention and trade show organizers',
        division:
          'Office adminstrative,office support and other business support architecture',
      },
      {
        label: 'Business support service activities n.e.c',
        value: 'Business support service activities n.e.c',
        division:
          'Office adminstrative,office support and other business support architecture',
      },
      {
        label:
          'Adminstration of the state and the economic and social policy of the community',
        value:
          'Adminstration of the state and the economic and social policy of the community',
        division:
          'Public Adminstration And Defence; Compulsory Social Security',
      },
      {
        label: 'Provision of services to the community as a whole',
        value: 'Provision of services to the community as a whole',
        division:
          'Public Adminstration And Defence; Compulsory Social Security',
      },
      {
        label: 'Compulsory social security activities',
        value: 'Compulsory social security activities',
        division:
          'Public Adminstration And Defence; Compulsory Social Security',
      },
      {
        label: 'Primary Education',
        value: 'Primary Education',
        division: 'Education',
      },
      {
        label: 'Secondary Education',
        value: 'Secondary Education',
        division: 'Education',
      },
      {
        label: 'Higher Education',
        value: 'Higher Education',
        division: 'Education',
      },
      {
        label: 'Other Education',
        value: 'Other Education',
        division: 'Education',
      },
      {
        label: 'Educational support activities',
        value: 'Educational support activities',
        division: 'Education',
      },
      {
        label: 'Hospital activities',
        value: 'Hospital activities',
        division: 'Human Health Activities',
      },
      {
        label: 'Medical and dental practice activities',
        value: 'Medical and dental practice activities',
        division: 'Human Health Activities',
      },
      {
        label: 'Other human health activities',
        value: 'Other human health activities',
        division: 'Human Health Activities',
      },
      {
        label: 'Nursing care facilities',
        value: 'Nursing care facilities',
        division: 'Residential care activities',
      },
      {
        label:
          'Residential care activities for mental retardation,mental health and subsbtance abuse',
        value:
          'Residential care activities for mental retardation,mental health and subsbtance abuse',
        division: 'Residential care activities',
      },
      {
        label: 'Residential care activities for the elderly and disabled',
        value: 'Residential care activities for the elderly and disabled',
        division: 'Residential care activities',
      },
      {
        label: 'Other residential care activities n.e.c',
        value: 'Other residential care activities n.e.c',
        division: 'Residential care activities',
      },
      {
        label:
          'Social work activities without accomodation for the elderly and disabled ',
        value:
          'Social work activities without accomodation for the elderly and disabled ',
        division: 'Social work activities without accomodation',
      },
      {
        label:
          'Other social work activities without accomodation for the elderly and disabled ',
        value:
          'Other social work activities without accomodation for the elderly and disabled ',
        division: 'Social work activities without accomodation',
      },
      {
        label: 'Creative,arts and entertainment activities',
        value: 'Creative,arts and entertainment activities',
        division: 'Creative,arts and entertainment activities',
      },
      {
        label: 'Libraries,archives,museums and other cultural activities',
        value: 'Libraries,archives,museums and other cultural activities',
        division: 'Libraries,archives,museums and other cultural activities',
      },
      {
        label: 'Gambling and betting activities',
        value: 'Gambling and betting activities',
        division: 'Gambling and betting activities',
      },
      {
        label: 'Sport activities',
        value: 'Sport activities',
        division: 'Sport activities,amusement and recreation',
      },
      {
        label: 'Other amusement and recreation activities',
        value: 'Other amusement and recreation activities',
        division: 'Sport activities,amusement and recreation',
      },
      {
        label:
          'Activities of business,employers and profressional membership organization',
        value:
          'Activities of business,employers and profressional membership organization',
        division: 'Activities of membership organzation',
      },
      {
        label: 'Activities of trade unions',
        value: 'Activities of trade unions',
        division: 'Activities of membership organzation',
      },
      {
        label: 'Activities of other membership organization',
        value: 'Activities of other membership organization',
        division: 'Activities of membership organzation',
      },
      {
        label: 'Repair of computers and communication equipment',
        value: 'Repair of computers and communication equipment',
        division: 'Repair of computers,personal and household goods',
      },
      {
        label: 'Repair of personal and household goods',
        value: 'Repair of personal and household goods',
        division: 'Repair of computers,personal and household goods',
      },
      {
        label: 'Other Service Activities',
        value: 'Other Service Activities',
        division: 'Other Service Activities',
      },
      {
        label: 'Activities of households as employers of domestic personnel',
        value: 'Activities of households as employers of domestic personnel',
        division: 'Activities of households as employers of domestic personnel',
      },
      {
        label:
          'Undifferenciated goods-producing activities of private households for own use',
        value:
          'Undifferenciated goods- producing activities of private households for own use',
        division:
          'Undifferenciated goods and services producing activities of private households for own use',
      },
      {
        label:
          'Undifferenciated service-producing activities of private households for own use',
        value:
          'Undifferenciated service- producing activities of private households for own use',
        division:
          'Undifferenciated goods and services producing activities of private households for own use',
      },
      {
        label:
          'Undifferenciated service-producing activities of private households for own use',
        value:
          'Undifferenciated service- producing activities of private households for own use',
        division:
          'Undifferenciated goods and services producing activities of private households for own use',
      },
      {
        label: 'Activities of extraterritorial oragnizations and bodies',
        value: 'Activities of extraterritorial oragnizations and bodies',
        division: 'Support activities for other mining and quarrying',
      },
      {
        label: 'Support activities for other mining and quarrying',
        value: 'Support activities for other mining and quarrying',
        division: 'Support activities for other mining and quarrying',
      },
    ],
    classes: [
      {
        label: 'Growing of cereals(except rice),leguminous crops and oil seeds',
        value: 'Growinh of cereals(except rice),leguminous crops and oil seeds',
        group: 'Growing of non-perennial crops',
      },
      {
        label: 'Growing of rice',
        value: 'Growing of rice',
        group: 'Growing of non-perennial crops',
      },
      {
        label: 'Growing of vegetabes and melons,roots and tubers',
        value: 'Growing of vegetables and melons,roots and tubers',
        group: 'Growing of non-perennial crops',
      },
      {
        label: 'Growing of sugarcane',
        value: 'Growing of sugarcane',
        group: 'Growing of non-perennial crops',
      },
      {
        label: 'Growing of tobacco',
        value: 'Growing of tobacco',
        group: 'Growing of non-perennial crops',
      },
      {
        label: 'Growing of fibre crops',
        value: 'Growing of fibre crops',
        group: 'Growing of non-perennial crops',
      },
      {
        label: 'Growing of flowers',
        value: 'Growing of flowers',
        group: 'Growing of non-perennial crops',
      },
      {
        label: 'Growing of pyrethrum',
        value: 'Growing of pyrethrum',
        group: 'Growing of non-perennial crops',
      },
      {
        label: 'Growing of other non-perennial crops',
        value: 'Growing of other non-perennial crops',
        group: 'Growing of non-perennial crops',
      },
      {
        label: 'Growing of grapes',
        value: 'Growing of grapes',
        group: 'Growing of perennial crops',
      },
      {
        label: 'Growing of tropical and subtropical fruits',
        value: 'Growing of tropical and subtropical fruits',
        group: 'Growing of perennial crops',
      },
      {
        label: 'Growing of citrus fruits',
        value: 'Growing of citrus fruits',
        group: 'Growing of perennial crops',
      },
      {
        label: 'Growing of pome fruits and stone fruits',
        value: 'Growing of pome fruits and stone fruits',
        group: 'Growing of perennial crops',
      },
      {
        label: 'Growing of other tree and bush fruits and nuts',
        value: 'Growing of other tree and bush fruits and nuts',
        group: 'Growing of perennial crops',
      },
      {
        label: 'Growing of oleaginous fruits',
        value: 'Growing of oleaginous fruits',
        group: 'Growing of perennial crops',
      },
      {
        label: 'Growing of beverage crops',
        value: 'Growing of beverage crops',
        group: 'Growing of perennial crops',
      },
      {
        label: 'Growing of spices,aromatic,drug and pharmaceutical crops',
        value: 'Growing of spices,aromatic,drug and pharmaceutical crops',
        group: 'Growing of perennial crops',
      },
      {
        label: 'Growing of other perennial crops',
        value: 'Growing of other perennal crops',
        group: 'Growing of perennial crops',
      },
      {
        label: 'Plant propagation',
        value: 'Plant propagation',
        group: 'Plant propagation',
      },
      {
        label: 'Raising of cattle and buffaloes',
        value: 'Raising of cattle and buffaloes',
        group: 'Animnal propagation',
      },
      {
        label: 'Raising of horses and other  equines',
        value: 'Raising of horses and other equines',
        group: 'Animnal propagation',
      },
      {
        label: 'Mixed farming',
        value: 'Mixed farming',
        group: 'Mixed farming',
      },
      {
        label: 'Support activities for crop production',
        value: 'Support activities for crop production',
        group:
          'Support activities to agriculture and post harvest crop activities',
      },
      {
        label: 'Support activities for animal production',
        value: 'Support activities for animal production',
        group:
          'Support activities to agriculture and post harvest crop activities',
      },
      {
        label: 'Post-harvest crop activities',
        value: 'Post-harvest crop activities',
        group:
          'Support activities to agriculture and post harvest crop activities',
      },
      {
        label: 'Seed processing for propagation',
        value: 'Seed processing for propagation',
        group:
          'Support activities to agriculture and post harvest crop activities',
      },
      {
        label: 'Hunting,trapping and related service activities',
        value: 'Hunting,trapping and related service activities',
        group: 'Hunting,trapping and related service activities',
      },
      {
        label: 'Silviculture and other forestry activities',
        value: 'Silviculture and other forestry activities',
        group: 'Silviculture and other forestry activities',
      },
      {
        label: 'Logging',
        value: 'Logging',
        group: 'Logging',
      },
      {
        label: 'Gathering of non-wood forest products',
        value: 'Gathering of non-wood forest products',
        group: 'Gathering of non-wood forest products',
      },
      {
        label: 'Support services to forestry',
        value: 'Support services to forestry',
        group: 'Support services to forestry',
      },
      {
        label: 'Marine fishing',
        value: 'Marine fishing',
        group: 'Fishing',
      },
      {
        label: 'Freshwater fishing',
        value: 'Freshwater fishing',
        group: 'Fishing',
      },
      {
        label: 'Freshwater fishing',
        value: 'Freshwater fishing',
        group: 'Aquaculture',
      },
      {
        label: 'Freshwater aquaculture',
        value: 'Freshwater aquaculture',
        group: 'Aquaculture',
      },
      {
        label: 'Mining of hard coal',
        value: 'Mining of hard coal',
        group: 'Mining of hard coal',
      },
      {
        label: 'Mining of lignite',
        value: 'Mining of lignite',
        group: 'Mining of lignite',
      },
      {
        label: 'Extraction of crude petroleum',
        value: 'Extraction of crude petroleum',
        group: 'Extraction of crude petroleum',
      },
      {
        label: 'Extraction of natural gas',
        value: 'Extraction of natural gas',
        group: 'Extraction of natural gas',
      },
      {
        label: 'Mining of iron ores',
        value: 'Mining of iron ores',
        group: 'Mining of iron ores',
      },
      {
        label: 'Mining of uranium and thorium ores',
        value: 'Mining of uranimu and thorium ores',
        group: 'Mining of non-ferrous metal ores',
      },
      {
        label: 'Mining of other non-ferrous metal ores',
        value: 'Mining of other non-ferrous metal ores',
        group: 'Mining of non-ferrous metal ores',
      },
      {
        label: 'Quarrying of a stone,sand and clay',
        value: 'Quarrying of a stone,sand and clay',
        group: 'Quarrying of a stone,sand and clay',
      },
      {
        label: 'Mining of chemical and fertilizer minerals',
        value: 'Mining of chemical and fertilizer minerals',
        group: 'Mining and quarrying',
      },
      {
        label: 'Extraction and agglomeration of peat',
        value: 'Extraction and agglomeration of peat',
        group: 'Mining and quarrying',
      },
      {
        label: 'Extraction of salt',
        value: 'Extraction of salt',
        group: 'Mining and quarrying',
      },
      {
        label: 'Other mining and quarrying n.e.c',
        value: 'Other mining ang quarrying n.e.c',
        group: 'Mining and quarrying',
      },
      {
        label: 'Support activities for petroleum and natural gas mining',
        value: 'Support activities for petroleum and natural gas mining',
        group: 'Support activities for petroleum and natural gas mining',
      },
      {
        label: 'Processing and preserving meat',
        value: 'Processing and preserving meat',
        group: 'Processing and preserving meat',
      },
      {
        label: 'Processing and preserving of fish,crustaceans and molluscs',
        value: 'Processing and preserving of fish,crustaceans and molluscs',
        group: 'Processing and preserving of fish,crustaceans and molluscs',
      },
      {
        label: 'Processing and preserving of fruits and vegetables',
        value: 'Processing and preserving of fruits and vegetables',
        group: 'Processing and preserving of fruits and vegetables',
      },
      {
        label: 'Manufacture of vegetable and animal oils and fats',
        value: 'Manufacture of vegetable and animal oils and fats',
        group: 'Manufacture of vegetable and animal oils and fats',
      },
      {
        label: 'Manufacture of dairy products',
        value: 'Manufacture of dairy products',
        group: 'Manufacture of dairy products',
      },
      {
        label: 'Manufacture of grain mill products',
        value: 'Manufacture of grain mill products',
        group:
          'Manufacture of grain mill products,starches and starch products',
      },
      {
        label: 'Manufacture of starches and starch products',
        value: 'Manufacture of starches and starch products',
        group:
          'Manufacture of grain mill products,starches and starch products',
      },
      {
        label: 'Manufacture of bakery products',
        value: 'Manufacture of bakery products',
        group: 'Manufacture of other food products',
      },
      {
        label: 'Manufacture of sugar',
        value: 'Manufacture of sugar',
        group: 'Manufacture of other food products',
      },
      {
        label: 'Manufacture of cocoa,chocolate and sugar confectionery',
        value: 'Manufacture of cocoa,chocolate and sugar confectionery',
        group: 'Manufacture of other food products',
      },
      {
        label:
          'Manufacture of macaroni,noodles,couscous and similar farinaceous products',
        value:
          'Manufacture of macaroni,noodles,couscous and similar farinaceous products',
        group: 'Manufacture of other food products',
      },
      {
        label: 'Manufacture of prepared meals and dishes',
        value: 'Manufacture of prepared meals and dishes',
        group: 'Manufacture of other food products',
      },
      {
        label: 'Manufacture of other food products n.e.c',
        value: 'Manufacture of other food products n.e.c',
        group: 'Manufacture of other food products',
      },
      {
        label: 'Manufacture of prepared animal feeds',
        value: 'Manufacture of prepared animal feeds',
        group: 'Manufacture of prepared animal feeds',
      },

      {
        label:
          'Distilling,rectifying and blending of spirits;ethyl alcohol production from fermented materials',
        value:
          'Distilling,rectifying and blending of spirits;ethyl alcohol production from fermented materials',
        group: 'Manufacture of beverages',
      },
      {
        label: 'Manufacture of wines',
        value: 'Manufacture of wines',
        group: 'Manufacture of beverages',
      },
      {
        label: 'Manufacture of malt liquors and malt',
        value: 'Manufacture of malt liquors and malt',
        group: 'Manufacture of beverages',
      },
      {
        label:
          'Manufacture of soft drinks,production of mineral waters and other bottled waters',
        value:
          'Manufacture of soft drinks,production of mineral waters and other bottled waters',
        group: 'Manufacture of beverages',
      },
      {
        label: 'Manufacture of tobacco products',
        value: 'Manufacture of tobacco products',
        group: 'Manufacture of tobacco products',
      },
      {
        label: 'Preparation and spinning of textile fibres',
        value: 'Preparation and spinning of textle fibres',
        group: 'Spinning,weaving and finishing textiles',
      },
      {
        label: 'Weaving of textiles',
        value: 'Weaving of textiles',
        group: 'Spinning,weaving and finishing textiles',
      },
      {
        label: 'Finishing of textiles',
        value: 'Finishing of textiles',
        group: 'Spinning,weaving and finishing textiles',
      },

      {
        label: 'Manufacture of knitted and crocheted fabrics',
        value: 'Manufacture of knitted and crocheted fabrics',
        group: 'Manufacture of other textiles',
      },
      {
        label: 'Manufacture of made-up textile articles,except apparel',
        value: 'Manufacture of made-up textile articles,except apparel',
        group: 'Manufacture of other textiles',
      },
      {
        label: 'Manufacture of carpets and rugs',
        value: 'Manufacture of carpets and rugs',
        group: 'Manufacture of other textiles',
      },
      {
        label: 'Manufacture of cordoge,rope,twine and netting',
        value: 'Manufacture of cordoge,rope,twine and netting',
        group: 'Manufacture of other textiles',
      },

      {
        label: 'Manufacture of other textiles n.e.c',
        value: 'Manufacture of other textiles n.e.c',
        group: 'Manufacture of other textiles',
      },
      {
        label: 'Manufacture of wearing apparel,except fur apparel',
        value: 'Manufacture of wearing apparel,except fur apparel',
        group: 'Manufacture of wearing apparel,except fur apparel',
      },
      {
        label: 'Manufacture of articles of fur',
        value: 'Manufacture of articles of fur',
        group: 'Manufacture of articles of fur',
      },
      {
        label: 'Manufacture of knitted and crocheted apparel',
        value: 'Manufacture of knitted and crocheted apparel',
        group: 'Manufacture of knitted and crocheted apparel',
      },
      {
        label: 'Tanning and dressing of leather;dressing and dyeing of fur',
        value: 'Tanning and dressing of leather;dressing and dyeing of fur',
        group:
          'Tanning and dressing of leather,manufacture of luggage,handbags,saddlery and harness,dressing and dyeing of fur',
      },
      {
        label:
          'Manufacture of luggage,handbags and the like,saddlery and harness',
        value:
          'Manufacture of luggage,handbags and the like,saddlery and harness',
        group:
          'Tanning and dressing of leather,manufacture of luggage,handbags,saddlery and harness,dressing and dyeing of fur',
      },
      {
        label: 'Manufacture of footwear',
        value: 'Manufacture of footwear',
        group: 'Manufacture of footwear',
      },
      {
        label: 'Sawmilling and planning of wood',
        value: 'Sawmilling and planning of wood',
        group: 'Sawmilling and planning of wood',
      },
      {
        label: 'Manufacture of builders capentry and joinery',
        value: 'Manufacture of builders capentry and joinery',
        group:
          'Manufacture of products of wood,cork,straw and plaiting materials',
      },
      {
        label:
          'Manufacture of veneer sheets;manufacture of plywood;laminboard,particle board and other panels and board',
        value:
          'Manufacture of veneer sheets;manufacture of plywood;laminboard,particle board and other panels and board',
        group:
          'Manufacture of products of wood,cork,straw and plaiting materials',
      },
      {
        label: 'Manufacture of wooden containers',
        value: 'Manufacture of wooden containers',
        group:
          'Manufacture of products of wood,cork,straw and plaiting materials',
      },
      {
        label:
          'Manufacture of other products of wood;manufacture of articles of cork,straw and paliting materials',
        value:
          'Manufacture of other products of wood;manufacture of articles of cork,straw and paliting materials',
        group:
          'Manufacture of products of wood,cork,straw and plaiting materials',
      },
      {
        label: 'Manufacture of pulp,paper and paperboard',
        value: 'Manufacture of pulp,paper and paperboard',
        group: 'Manufacture of paper and paper products',
      },
      {
        label:
          'Manufacture of corrugated paper and paperboard and container of paper and paperboard',
        value:
          'Manufacture of corrugated paper and paperboard and container of paper and paperboard',
        group: 'Manufacture of paper and paper products',
      },
      {
        label:
          'Manufacture of other products of other articles of paper and paperboard',
        value:
          'Manufacture of other products of other articles of paper and paperboard',
        group: 'Manufacture of paper and paper products',
      },
      {
        label: 'Printing',
        value: 'Printing',
        group: 'Printing and service activities related to printing',
      },
      {
        label: 'Service activities related to printing',
        value: 'Service activities related to printing',
        group: 'Printing and service activities related to printing',
      },
      {
        label: 'Reproduction of recorded media',
        value: 'Reproduction of recorded media',
        group: 'Reproduction of recorded media',
      },
      {
        label: 'Manufacture of coke oven products',
        value: 'Manufacture of coke oven products',
        group: 'Manufacture of coke oven products',
      },
      {
        label: 'Manufacture of refined petroleum products',
        value: 'Manufacture of refined petroleum products',
        group: 'Manufacture of refined petroleum products',
      },
      {
        label: 'Manufacture of basic chemicals',
        value: 'Manufacture of basic chemicals',
        group:
          'Manufacture of basic chemicals,fertilizer and nitrogen compounds,plastics and synthetic rubber in primary forms',
      },
      {
        label: 'Manufacture of fertilizers and nitrogen compounds',
        value: 'Manufacture of fertilizers and nitrogen compounds',
        group:
          'Manufacture of basic chemicals,fertilizer and nitrogen compounds,plastics and synthetic rubber in primary forms',
      },
      {
        label: 'Manufacture of plastics and synthetic rubber in primary forms',
        value: 'Manufacture of plastics and synthetic rubber in primary forms',
        group:
          'Manufacture of basic chemicals,fertilizer and nitrogen compounds,plastics and synthetic rubber in primary forms',
      },
      {
        label: 'Manufacture of pesticides and other agrochemical products',
        value: 'Manufacture of pesticides and other agrochemical products',
        group: 'Manufacture of other chemical products',
      },
      {
        label:
          'Manufacture of paints,varnishes and similar coatings,printing ink and mastics',
        value:
          'Manufacture of paints,varnishes and similar coatings,printing ink and mastics',
        group: 'Manufacture of other chemical products',
      },
      {
        label:
          'Manufacture of soap and detergents,cleaning and polishing preparations,perfumes and toilet preparations',
        value:
          'Manufacture of soap and detergents,cleaning and polishing preparations,perfumes and toilet preparations',
        group: 'Manufacture of other chemical products',
      },
      {
        label: 'Manufacture of other chemical products',
        value: 'Manufacture of other chemical products',
        group: 'Manufacture of other chemical products',
      },
      {
        label: 'None',
        value: 'None',
        group: 'Manufacture of man-made fibers',
      },
      {
        label:
          'Manufacture of pharmaceuticals,medicinal chemical and botanical products',
        value:
          'Manufacture of pharmaceuticals,medicinal chemical and botanical products',
        group:
          'Manufacture of pharmaceuticals,medicinal chemical and botanical products',
      },
      {
        label:
          'Manufacture of rubber tyres and tubes,retreading and rebuilding of rubber tyres',
        value:
          'Manufacture of rubber tyres and tubes,retreading and rebuilding of rubber tyres',
        group: 'Manufacture of rubber products',
      },
      {
        label: 'Manufacture of other rubber products',
        value: 'Manufacture of other rubber products',
        group: 'Manufacture of rubber products',
      },
      {
        label: 'Manufacture of plastics products',
        value: 'Manufacture of plastics products',
        group: 'Manufacture of plastics products',
      },
      {
        label: 'Manufacture of glass and glass products',
        value: 'Manufacture of glass and glass products',
        group: 'Manufacture of glass and glass products',
      },
      {
        label: 'Manufacture of refactory products',
        value: 'Manufacture of refactory products',
        group: 'Manufacture of non-metallic mineral products',
      },
      {
        label: 'Manufacture of clay building materials',
        value: 'Manufacture of clay building materials',
        group: 'Manufacture of non-metallic mineral products',
      },
      {
        label: 'Manufacture of other porcelain and ceramic products',
        value: 'Manufacture of other porcelain and ceramic products',
        group: 'Manufacture of non-metallic mineral products',
      },
      {
        label: 'Manufacture of cement,lime and plaster',
        value: 'Manufacture of cement,lime and plaster',
        group: 'Manufacture of non-metallic mineral products',
      },
      {
        label: 'Manufacture of articles of concrete,cement and plaster',
        value: 'Manufacture of articles of conrete,cement and plaster',
        group: 'Manufacture of non-metallic mineral products',
      },
      {
        label: 'Cutting,shaping and finishing stone',
        value: 'Cutting,shaping and finishing stone',
        group: 'Manufacture of non-metallic mineral products',
      },
      {
        label: 'Manufacture of other non-metallic mineral products n.e.c',
        value: 'Manufacture of other non-metallic mineral products n.e.c',
        group: 'Manufacture of non-metallic mineral products',
      },
      {
        label: 'Manufacture of basic iron and steel',
        value: 'Manufacture of basic iron and steel',
        group: 'Manufacture of basic iron and steel',
      },
      {
        label: 'Manufacture of basic precious and other non-ferrous metals',
        value: 'Manufacture of basic precious and other non-ferrous metals',
        group: 'Manufacture of basic precious and other non-ferrous metals',
      },
      {
        label: 'Casting of iron and steel',
        value: 'Casting of iron and steel',
        group: 'Casting of metals',
      },
      {
        label: 'Casting of non-ferrous metals',
        value: 'Casting of non-ferrous metals',
        group: 'Casting of metals',
      },
      {
        label: 'Manufacture of structural metal products',
        value: 'Manufacture of structural metal products',
        group:
          'Manufacture of structural metal products,tanks,reservoirs and steam generators',
      },

      {
        label: 'Manufacture of tanks,reservoirs and containers of metal',
        value: 'Manufacture of tanks,reservoirs and containers of metal',
        group:
          'Manufacture of structural metal products,tanks,reservoirs and steam generators',
      },
      {
        label:
          'Manufacture of steam generators,except central heating hot water boilers',
        value:
          'Manufacture of steam generators,except central heating hot water boilers',
        group:
          'Manufacture of structural metal products,tanks,reservoirs and steam generators',
      },
      {
        label: 'Manufacture of weapons and ammunition',
        value: 'Manufacture of weapons and ammunition',
        group: 'Manufacture of weapons and ammunition',
      },
      {
        label: 'Manufacture of weapons and ammunition',
        value: 'Manufacture of weapons and ammunition',
        group: 'Manufacture of weapons and ammunition',
      },
      {
        label:
          'Forging,pressing,stamping and roll-forming of metal,powder metallurgy',
        value:
          'Forging,pressing,stamping and rol-forming of metal.powder metallurgy',
        group:
          'Manufacture of other fabricated metal products,metal working service activities',
      },
      {
        label: 'Machining,treatment and coating of metals',
        value: 'Machining,treatment and coating of metals',
        group:
          'Manufacture of other fabricated metal products,metal working service activities',
      },
      {
        label: 'Manufacture of cutlery,hand tools and general hardware',
        value: 'Manufacture of cutlery,hand tools and general hardware',
        group:
          'Manufacture of other fabricated metal products,metal working service activities',
      },
      {
        label: 'Manufacture of other fabricated metal products n.e.c',
        value: 'Manufacture of other fabricated metal products n.e.c',
        group:
          'Manufacture of other fabricated metal products,metal working service activities',
      },
      {
        label: 'Manufacture of electronic components',
        value: 'Manufacture of electronic components',
        group: 'Manufacture of electronic components',
      },
      {
        label: 'Manufacture of computers and peripheral equipment',
        value: 'Manufacture of computers and peripheral equipment',
        group: 'Manufacture of computers and peripheral equipment',
      },
      {
        label: 'Manufacture of communication equipment',
        value: 'Manufacture of communication equipment',
        group: 'Manufacture of communication equipment',
      },
      {
        label: 'Manufacture of consumer electronics',
        value: 'Manufacture of consumer electronics',
        group: 'Manufacture of consumer electronics',
      },
      {
        label:
          'Manufacture of measuring,testing,navigating and control equipment',
        value:
          'Manufacture of measuring,testing,navigating and control equipment',
        group:
          'Manufacture of measuring,testing,navigating and control equipment,watches and clocks',
      },
      {
        label: 'Manufacture of watches and clocks',
        value: 'Manufacture of watches and clocks',
        group:
          'Manufacture of measuring,testing,navigating and control equipment,watches and clocks',
      },
      {
        label:
          'Manufacture of irradiation and electromedical and electrotheraputic',
        value:
          'Manufacture of irradiation and electromedical and electrotheraputic',
        group:
          'Manufacture of irradiation and electromedical and electrotheraputic',
      },
      {
        label: 'Manufacture of optical instruments and equipment',
        value: 'Manufacture of optical instruments and equipment',
        group: 'Manufacture of optical instruments and equipment',
      },
      {
        label: 'Manufacture of magnetic and optical media',
        value: 'Manufacture of magnetic and optical media',
        group: 'Manufacture of magnetic and optical media',
      },
      {
        label:
          'Manufacture of electronic motors,generators,transformers and electricity distribution and control apparatus',
        value:
          'Manufacture of electronic motors,generators,transformers and electricity distribution and control apparatus',
        group:
          'Manufacture of electronic motors,generators,transformers and electricity distribution and control apparatus',
      },
      {
        label: 'Manufacture of batteries and accumulators',
        value: 'Manufacture of batteries and accumulators',
        group: 'Manufacture of batteries and accumulators',
      },
      {
        label: 'Manufacture of fiber optic cables',
        value: 'Manufacture of fiber optic cables',
        group: 'Manufacture of wiring and wiring devices',
      },

      {
        label: 'Manufacture of other electronic and electric wires and cables',
        value:
          'Manufacture of other electrinic and electric and wires and cables',
        group: 'Manufacture of wiring and wiring devices',
      },
      {
        label: 'Manufacture of wiring devices',
        value: 'Manufacture of wiring devices',
        group: 'Manufacture of wiring and wiring devices',
      },
      {
        label: 'Manufacture of electric lighting equipment',
        value: 'Manufacture of electric lighting equipment',
        group: 'Manufacture of electric lighting equipment',
      },
      {
        label: 'Manufacture of domestic appliances',
        value: 'Manufacture of domestic appliances',
        group: 'Manufacture of domestic appliances',
      },
      {
        label: 'Manufacture of other electrical equipment',
        value: 'Manufacture of other electrical equipment',
        group: 'Manufacture of other electrical equipment',
      },
      {
        label: 'Manufacture of other electrical equipment',
        value: 'Manufacture of other electrical equipment',
        group: 'Manufacture of other electrical equipment',
      },
      {
        label:
          'Manufacture of engines and turbines,except aircraft,vehicle and cycle engines',
        value:
          'Manufacture of engines and turbines,except aircraft,vehicle and cycle engines',
        group: 'Manufacture of general purpose machinery',
      },
      {
        label: 'Manufacture of fluid power equipment',
        value: 'Manufacture of fluid power equipment',
        group: 'Manufacture of general purpose machinery',
      },
      {
        label: 'Manufacture of other pumps,compressors,taps and valves',
        value: 'Manufacture of other pumps,compressors,taps and valves',
        group: 'Manufacture of general purpose machinery',
      },
      {
        label: 'Manufacture of bearings,gears,gearing and driving elements',
        value: 'Manufacture of bearings,gears,gearing and driving elements',
        group: 'Manufacture of general purpose machinery',
      },
      {
        label: 'Manufacture of ovens,furnances and furnances burners',
        value: 'Manufacture of ovens,furnances and furnances burners',
        group: 'Manufacture of general purpose machinery',
      },
      {
        label: 'Manufacture of lifting and handling equipment',
        value: 'Manufacture of lifting and handling equipment',
        group: 'Manufacture of general purpose machinery',
      },
      {
        label:
          'Manufacture of office machinery and equipment(except computers and peripheral equipment)',
        value:
          'Manufacture of office machinery and equipment (except computers abd peripheral equipment)',
        group: 'Manufacture of general purpose machinery',
      },
      {
        label: 'Manufacture of power driven hand tools',
        value: 'Manufacture of power driven hand tools',
        group: 'Manufacture of general purpose machinery',
      },
      {
        label: 'Manufacture of other general-purpose machinery',
        value: 'Manufacture of other general-purpose machinery',
        group: 'Manufacture of general purpose machinery',
      },
      {
        label: 'Manufacture of agricultural and forestry machinery',
        value: 'Manufacture of agricultural and forestry machinery',
        group: 'Manufacture of special purpose machinery',
      },
      {
        label: 'Manufacture of  metal forming machinery and machine tools',
        value: 'Manufacture of metal forming machinery and machine tools',
        group: 'Manufacture of special purpose machinery',
      },
      {
        label: 'Manufacture of machinery for metallurgy',
        value: 'Manufacture of machinery for metallurgy',
        group: 'Manufacture of special purpose machinery',
      },
      {
        label: 'Manufacture of machinery for mining,quarrying and construction',
        value: 'Manufacture of machinery for mining,quarrying and construction',
        group: 'Manufacture of special purpose machinery',
      },
      {
        label:
          'Manufacture of machinery for food,beverage and tobacco processing',
        value:
          'Manufacture of machinery for food beverage and tobacco processing',
        group: 'Manufacture of special purpose machinery',
      },
      {
        label:
          'Manufacture of machinery for textile,apparel and leather production',
        value:
          'Manufacture of machinery for textile,apparel and leather production',
        group: 'Manufacture of special purpose machinery',
      },
      {
        label: 'Manufacture of other special-purpose machinery',
        value: 'Manufacture of other special-purpose machinery',
        group: 'Manufacture of special purpose machinery',
      },
      {
        label: 'Manufacture of motor vehicles',
        value: 'Manufacture of motor vehicles',
        group: 'Manufacture of motor vehicles',
      },
      {
        label:
          'Manufacture of bodies(coachwork) for motor vehicles,manufacture of trailers and semi-trailers',
        value:
          'Manufacture of bodies(coachwork) for motor vehicles,manufacture of trailers and semi-trailers',
        group:
          'Manufacture of bodies(coachwork) for motor vehicles,manufacture of trailers and semi-trailers',
      },
      {
        label:
          'Manufacture of parts and accessories for motor vehicles and their engines',
        value:
          'Manufacture of parts and accessories for motor vehicles and their engines',
        group:
          'Manufacture of parts and accessories for motor vehicles and their engines',
      },
      {
        label: 'Building of ships and floating structures',
        value: 'Building of ships and floating structures',
        group: 'Building of ships and boats',
      },
      {
        label: 'Building of pleasure and sporting boats',
        value: 'Building of pleasure and sporting boats',
        group: 'Building of ships and boats',
      },
      {
        label: 'Manufacture of railway locomotives and rolling stock',
        value: 'Manufacture of railway locomotives and rolling stock',
        group: 'Manufacture of railway locomotives and rolling stock',
      },
      {
        label: 'Manufacture of air and spacecraft and related machinery',
        value: 'Manufacture of air and spacecraft and related machinery',
        group: 'Manufacture of air and spacecraft and related machinery',
      },
      {
        label: 'Manufacture of military fighting vehicles',
        value: 'Manufacture of military fighting vehicles',
        group: 'Manufacture of military fighting vehicles',
      },
      {
        label: 'Manufacture of motorcycles',
        value: 'Manufacture of motorcycles',
        group: 'Manufacture of transport equipment',
      },
      {
        label: 'Manufacture of bicycles and invalid carriages',
        value: 'Manufacture of bicycles and invalid carriages',
        group: 'Manufacture of transport equipment',
      },
      {
        label: 'Manufacture of other transport equipement n.e.c',
        value: 'Manufacture of other transport equipment n.e.c',
        group: 'Manufacture of transport equipment',
      },
      {
        label: 'Manufacture of furniture',
        value: 'Manufacture of furniture',
        group: 'Manufacture of furniture',
      },
      {
        label: 'Manufacture of jewellery and related articles',
        value: 'Manufacture of jewellery and related articles',
        group: 'Manufacture of jewellery,bijouterie and related articles',
      },
      {
        label: 'Manufacture of imitation jewellery and related articles',
        value: 'Manufacture of imitation jewellery and related articles',
        group: 'Manufacture of jewellery,bijouterie and related articles',
      },
      {
        label: 'Manufacture of musical instruments',
        value: 'Manufacture of musical instruments',
        group: 'Manufacture of musical instruments',
      },
      {
        label: 'Manufacture of sport goods',
        value: 'Manufacture of sport goods',
        group: 'Manufacture of sport goods',
      },
      {
        label: 'Manufacture of games and toys',
        value: 'Manufacture of games and toys',
        group: 'Manufacture of games and toys',
      },
      {
        label: 'Manufacture of medical and dental instruments and supplies',
        value: 'Manufacture of medical and dental instruments and supplies',
        group: 'Manufacture of medical and dental instruments and supplies',
      },

      {
        label: 'Manufacture of other manufacturing n.e.c',
        value: 'Manufacture of other manufacturing n.e.c',
        group: 'Manufacture of other manufacturing n.e.c',
      },
      {
        label: 'Repair of fabricated metal products',
        value: 'Repair of fabricated metal products',
        group: 'Repair of fabricated metal products,machinery and equipment',
      },
      {
        label: 'Repair of machinery',
        value: 'Reapir of machinery',
        group: 'Repair of fabricated metal products,machinery and equipment',
      },
      {
        label: 'Repair of electronic and optical equipment',
        value: 'Repair of electronic and optical equipment',
        group: 'Repair of fabricated metal products,machinery and equipment',
      },
      {
        label: 'Repair of electrical equipment',
        value: 'Repair of electrical equipment',
        group: 'Repair of fabricated metal products,machinery and equipment',
      },
      {
        label: 'Repair of transport equipment,except motor vehicles',
        value: 'Repair of transport equipment,except motor vehicles',
        group: 'Repair of fabricated metal products,machinery and equipment',
      },
      {
        label: 'Repair of other equipment',
        value: 'Repair of other equipment',
        group: 'Repair of fabricated metal products,machinery and equipment',
      },
      {
        label: 'Installation of industrial machinery and equipment',
        value: 'Installation of industrial machinery and equipment',
        group: 'Installation of industrial machinery and equipment',
      },
      {
        label: 'Electric power generation,transmission and distribution',
        value: 'Electric power generation,transmission and distribution',
        group: 'Electric power generation,transmission and distribution',
      },
      {
        label: 'Manufacture of gas,distribution of gaseous fuels through mains',
        value: 'Manufacture of gas,distribution of gaseous fuels through mains',
        group: 'Manufacture of gas,distribution of gaseous fuels through mains',
      },
      {
        label: 'Steam and air conditioning supply',
        value: 'Steam and air conditioning supply',
        group: 'Steam and air conditioning supply',
      },
      {
        label: 'Water Collection,treatment and supply',
        value: 'Water Collection,treatment and supply',
        group: 'Water Collection,treatment and supply',
      },
      {
        label: 'Sewerage',
        value: 'Sewerage',
        group: 'Sewerage',
      },
      {
        label: 'Collection of hazardous waste',
        value: 'Collection of hazardous waste',
        group: 'Waste Collection',
      },
      {
        label: 'Collection of non-hazardous waste',
        value: 'Collection of non-hazardous waste',
        group: 'Waste Collection',
      },
      {
        label: 'Treatment and disposal of non-hazardous waste',
        value: 'Treatment and disposal of non-hazardous waste',
        group: 'Waste treatment and disposal',
      },
      {
        label: 'Treatment and disposal of hazardous waste',
        value: 'Treatment and disposal of hazardous waste',
        group: 'Waste treatment and disposal',
      },
      {
        label: 'Materials recovery',
        value: 'Materials recovery',
        group: 'Materials recovery',
      },
      {
        label: 'Remediation activities and other waste management services',
        value: 'Remediation activities and other waste management services',
        group: 'Remediation activities and other waste management services',
      },
      {
        label: 'Construction of buildings',
        value: 'Construction of buildings',
        group: 'Construction of buildings',
      },
      {
        label: 'Construction of roads and railways',
        value: 'Construction of roads and railways',
        group: 'Construction of roads and railways',
      },
      {
        label: 'Construction of utility projects',
        value: 'Construction of utility projects',
        group: 'Construction of utility projects',
      },
      {
        label: 'Construction of other civil engineering projects',
        value: 'Construction of other civil engineering projects',
        group: 'Construction of other civil engineering projects',
      },
      {
        label: 'Demolition',
        value: 'Demolition',
        group: 'Demolition and site preparation',
      },
      {
        label: 'Site preparation',
        value: 'Site preparation',
        group: 'Demolition and site preparation',
      },
      {
        label: 'Electrical Installation',
        value: 'Electrical Installation',
        group:
          'Electrical,plumbing and other construction installation activities',
      },
      {
        label: 'Plumbing,heat and air-conditioning installtion',
        value: 'Plumbing,heat and air-conditioning installation',
        group:
          'Electrical,plumbing and other construction installation activities',
      },
      {
        label: 'Other construction installation',
        value: 'Other construction installation',
        group:
          'Electrical,plumbing and other construction installation activities',
      },
      {
        label: 'Building completion and finishing',
        value: 'Building completion and finishing',
        group: 'Building completion and finishing',
      },
      {
        label: 'Other specialized construction activities',
        value: 'Other specialized construction activities',
        group: 'Other specialized construction activities',
      },
      {
        label: 'Sale of motor vehicles',
        value: 'Sale of motor vehicles',
        group: 'Sale of motor vehicles',
      },
      {
        label: 'Maintainance and repair of motor vehicles',
        value: 'Maintainance and repair of motor vehicles',
        group: 'Maintainance and repair of motor vehicles',
      },
      {
        label: 'Sale of motor vehicle parts and accessories',
        value: 'Sale of motor vehicle parts and accessories',
        group: 'Sale of motor vehicle parts and accessories',
      },
      {
        label:
          'Sale,maintainance and repair of motorcycles and related parts and accessories',
        value:
          'Sale,maintainance and repair of motorcycles and related parts and accessories',
        group:
          'Sale,maintainance and repair of motorcycles and related parts and accessories',
      },
      {
        label: 'Wholesale on a fee or contract basis',
        value: 'Wholesale on a fee or contract basis',
        group: 'Wholesale on a fee or contract basis',
      },
      {
        label: 'Wholesale of agricultural raw materials and live animals',
        value: 'Wholesale of agricultural raw materials and live animals',
        group: 'Wholesale of agricultural raw materials and live animals',
      },
      {
        label: 'Wholesale of food,beverages and tobacco',
        value: 'Wholesale of food,beverages and tobacco',
        group: 'Wholesale of food,beverages and tobacco',
      },
      {
        label: 'Wholesale of textiles,clothing and footwear',
        value: 'Wholesale of textiles,clothing and footwear',
        group: 'Wholesale of household goods',
      },
      {
        label: 'Wholesale of other household goods',
        value: 'Wholesale of other household goods',
        group: 'Wholesale of household goods',
      },
      {
        label:
          'Wholesale of computers,computer peripheral equipment and software',
        value:
          'Wholesale of computers,computer peripheral equipment and software ',
        group: 'Wholesale of machinery equipment and supplies',
      },
      {
        label:
          'Wholesale of electronic and telecommunications equipment and parts',
        value:
          'Wholesale of electronic and telecommunication equipment and parts',
        group: 'Wholesale of machinery equipment and supplies',
      },
      {
        label: 'Wholesale of agricultural machinery equipment and supplies',
        value: 'Wholesale of agricultural machinery equipmeny and supplies',
        group: 'Wholesale of machinery equipment and supplies',
      },
      {
        label: 'Wholesale of other machinery and equipment',
        value: 'Wholesale of other machinery and equipment',
        group: 'Wholesale of machinery equipment and supplies',
      },
      {
        label:
          'Wholesale of solid,liquid and gaseous fuels and related products',
        value:
          'Wholesale of solid,liquid and gaseous fuels and related products',
        group: 'Other specialized wholesale',
      },
      {
        label: 'Wholesale of metals and metal ores',
        value: 'Wholesale of metals and metal ores',
        group: 'Other specialized wholesale',
      },
      {
        label:
          'Wholesale of construction materials,hardware,plumbing and heating equipment and supplies',
        value:
          'Wholesale of construction materials,hardware,plumbing and heating equipment and supplies',
        group: 'Other specialized wholesale',
      },
      {
        label: 'Wholesale of waste,scrap and other products n.e.c',
        value: 'Wholesale of waste,scrap and other products n.e.c',
        group: 'Other specialized wholesale',
      },
      {
        label: 'Wholesale of waste,scrap and other products n.e.c',
        value: 'Wholesale of waste,scrap and other products n.e.c',
        group: 'Other specialized wholesale',
      },
      {
        label: 'Non-specialized wholesale trade',
        value: 'Non-specialized wholesale trade',
        group: 'Non-specialized wholesale trade',
      },
      {
        label:
          'Retail sale in non-specialized stores with food,beverages or tobacco pre-dominating',
        value:
          'Retail sale in non-specialized stores with food,beverages or tobacco prr-dominating',
        group: 'Retail sale in non-specialized stores',
      },
      {
        label: 'Other retail sale in non-specialized stores',
        value: 'Other retail sale in non-specialized stores',
        group: 'Retail sale in non-specialized stores',
      },
      {
        label: 'Retail sale of beverages in specialized stores',
        value: 'Retail sale of beverages in specialized stores',
        group:
          'Retail sale of food,beverages and tobacco in specialized stores',
      },
      {
        label: 'Retail sale of food in specialized stores',
        value: 'Retail sale of food in specialized stores',
        group:
          'Retail sale of food,beverages and tobacco in specialized stores',
      },
      {
        label: 'Retail sale of tobacco in specialized stores',
        value: 'Retail sale of tobacco in specialized stores',
        group:
          'Retail sale of food,beverages and tobacco in specialized stores',
      },
      {
        label: 'Retail sale of automotive fuel',
        value: 'Retail sale of automotive fuel',
        group: 'Retail sale of automotive fuel',
      },
      {
        label:
          'Retail sale of computers,peripheral units,software and telecommunications equipment',
        value:
          'Retail sale of computers,peripheral units,software and telecommunications equipment',
        group: 'Retail sale of ICT equipment in specialized stores',
      },
      {
        label: 'Retail sale of audio and video equipment',
        value: 'Retail sale of audio and video equipment',
        group: 'Retail sale of ICT equipment in specialized stores',
      },
      {
        label: 'Retail sale of textiles',
        value: 'Retail sale of textiles',
        group: 'Retail sale of other households in specialized stores',
      },
      {
        label: 'Retail sale of hardware,paints and glass',
        value: 'Retail sale of hardware,paints and glass',
        group: 'Retail sale of other households in specialized stores',
      },
      {
        label: 'Retail sale of carpets,rugs,wall and floor coverings',
        value: 'Retail sale of carpets,rugs,wall and floor coverings',
        group: 'Retail sale of other households in specialized stores',
      },
      {
        label:
          'Retail sale of electrical household appliances,furniture,lighting equipment and other household articles',
        value:
          'Retail sale of electrical household appliances,furniture,lighting equipment and other household articles',
        group: 'Retail sale of other households in specialized stores',
      },
      {
        label: 'Retail sale of books,newspapers and stationary',
        value: 'Retail sale of books,newspapers and stationary',
        group:
          'Retail sale of cultural and recreation goods in specialized stores',
      },
      {
        label: 'Retail sale of music  and video recordings',
        value: 'Retail sale of music and video recordings',
        group:
          'Retail sale of cultural and recreation goods in specialized stores',
      },
      {
        label: 'Retail sale of sporting equipment',
        value: 'Retail sale of sporting equipment',
        group:
          'Retail sale of cultural and recreation goods in specialized stores',
      },
      {
        label: 'Retail sale of games and toys',
        value: 'Retail sale of games and toys',
        group:
          'Retail sale of cultural and recreation goods in specialized stores',
      },
      {
        label: 'Retail sale of clothing,footwear and leather articles',
        value: 'Retail sale of clothing,footwear and leather articles',
        group: 'Retail sale of other goods in specialized stores',
      },
      {
        label:
          'Retail sale of pharmaceutical and medical goods,cosmetics and toilet articles',
        value:
          'Retail sale of pharmaceutical and medical goods,cometics and toilet articles',
        group: 'Retail sale of other goods in specialized stores',
      },
      {
        label: 'Other retail sale of new goods',
        value: 'Other retail sale of new goods',
        group: 'Retail sale of other goods in specialized stores',
      },
      {
        label: 'Retail sale of second-hand goods',
        value: 'Retail sale of second-hand goods',
        group: 'Retail sale of other goods in specialized stores',
      },
      {
        label:
          'Retail sale via stalls and markets of food,beverages and tobacco products',
        value:
          'Retail sale via stalls and markets of food,beverages and tobacco products',
        group: 'Retail sale via stalls and markets',
      },
      {
        label:
          'Retail sale via stalls and markets of textiles,clothing and footwear',
        value:
          'Retail sale via stalls and markets of textile,clothing and footwear ',
        group: 'Retail sale via stalls and markets',
      },
      {
        label: 'Retail sale via stalls and markets of other goods',
        value: 'Retail sale via stalls and markets of other goods',
        group: 'Retail sale via stalls and markets',
      },
      {
        label: 'Rail transport',
        value: 'Rail transport',
        group: 'Transport via railways',
      },
      {
        label: 'Urban,suburban  or metropolitan area passenger land transport',
        value: 'Urban,suburban or metropolitan area passenger land transport',
        group: 'Water transport',
      },
      {
        label: 'Other passenger land transport',
        value: 'Other passenger land transport',
        group: 'Water transport',
      },
      {
        label: 'Freight transport by road',
        value: 'Freight transport by road',
        group: 'Water transport',
      },
      {
        label: 'Transport via pipeline',
        value: 'Transport via pipeline',
        group: 'Air transport',
      },
      {
        label: 'Sea and coastal passenger water transport',
        value: 'Sea and coastal passenger water transport',
        group: 'Sea and coastal transport',
      },
      {
        label: 'Sea and coastal freight water transport',
        value: 'Sea and coastal freight water transport',
        group: 'Sea and coastal transport',
      },
      {
        label: 'Inland passenger water transport',
        value: 'Inland passenger water transport',
        group: 'Inland water transport',
      },
      {
        label: 'Inland freight water transport',
        value: 'Inland freight water transport',
        group: 'Inland water transport',
      },
      {
        label: 'Passenger air transport',
        value: 'Passenger air transport',
        group: 'Passenger air transport',
      },

      {
        label: 'Freight air transport',
        value: 'Freight air transport',
        group: 'Freight air transport',
      },
      {
        label: 'Warehousing and storage',
        value: 'Warehousing and storage',
        group: 'Warehousing and storage',
      },
      {
        label: 'Service activities incidental to land transportation',
        value: 'Service activities incidental to land transportation',
        group: 'Support activities for transportation',
      },
      {
        label: 'Service activities incidental to water transportation',
        value: 'Service activities incidental to water transportation',
        group: 'Support activities for transportation',
      },
      {
        label: 'Service activities incidental to air transportation',
        value: 'Service activities incidental to air transportation',
        group: 'Support activities for transportation',
      },
      {
        label: 'Cargo handling',
        value: 'Cargo handling',
        group: 'Support activities for transportation',
      },
      {
        label: 'Other transportation support activities',
        value: 'Other transportation support activities',
        group: 'Support activities for transportation',
      },
      {
        label: 'Postal activities',
        value: 'Postal activities',
        group: 'Postal activities',
      },
      {
        label: 'Courier activities',
        value: 'Courier activities',
        group: 'Courier activities',
      },
      {
        label: 'Short term accomodation activities',
        value: 'Short term accomodation activities',
        group: 'Short term accomodation activities',
      },
      {
        label: 'Recreational vehicle parks,trailer parks and camping grounds',
        value: 'Recreational vehicle parks,trailer parks and camping grounds',
        group: 'Recreational vehicle parks,trailer parks and camping grounds',
      },
      {
        label: 'Other accomodation',
        value: 'Other accomodation',
        group: 'Other accomodation',
      },
      {
        label: 'Restaurants and mobile food service activities',
        value: 'Restaurants and mobile food service activities',
        group: 'Restaurants and mobile food service activities',
      },
      {
        label: 'Event catering ',
        value: 'Event catering ',
        group: 'Event catering and food service activities',
      },
      {
        label: 'Other food service activities',
        value: 'Other food service activities',
        group: 'Event catering and food service activities',
      },
      {
        label: 'Beverage serving activities',
        value: 'Beverage serving activities',
        group: 'Beverage serving activities',
      },
      {
        label: 'Book publishing',
        value: 'Book publishing',
        group:
          'Publishing of books,periodicals and other publishing activities',
      },

      {
        label: 'Publishing of directories and mailing lists',
        value: 'Publishing of directories and mailing lists',
        group:
          'Publishing of books,periodicals and other publishing activities',
      },
      {
        label: 'Publishing of newspapers,journals and periodicals',
        value: 'Publishing of newspaper,journals and periodicals',
        group:
          'Publishing of books,periodicals and other publishing activities',
      },
      {
        label: 'Other publishing activities',
        value: 'Other publishing activities',
        group:
          'Publishing of books,periodicals and other publishing activities',
      },
      {
        label: 'Software Publishing',
        value: 'Software Publishing',
        group: 'Software Publishing',
      },
      {
        label: 'Software Publishing',
        value: 'Software Publishing',
        group: 'Software Publishing',
      },
      {
        label:
          'Motion pictures,video and television programme production activities',
        value:
          'Motion pictures,video and television programme production activities',
        group: 'Motion pictures,video and television programme activities',
      },
      {
        label:
          'Motion pictures,video and television programme post production activities',
        value:
          'Motion pictures,video and television programme post production activities',
        group: 'Motion pictures,video and television programme activities',
      },
      {
        label:
          'Motion pictures,video and television programme distribution activities',
        value:
          'Motion pictures,video and television programme distribution activities',
        group: 'Motion pictures,video and television programme activities',
      },
      {
        label: 'Motion pictures projection activities',
        value: 'Motion pictures projection activities',
        group: 'Motion pictures,video and television programme activities',
      },
      {
        label: 'Sound recording and music publishing activities',
        value: 'Sound recording and music publishing activities',
        group: 'Sound recording and music publishing activities',
      },
      {
        label: 'Radio broadcasting',
        value: 'Radio broadcasting',
        group: 'Radio broadcasting',
      },
      {
        label: 'Television broadcasting',
        value: 'Television broadcasting',
        group: 'Television broadcasting and subscription programming',
      },
      {
        label: 'Cable,satellite and other subscription programming',
        value: 'Cable,satellite and other subscription programming',
        group: 'Television broadcasting and subscription programming',
      },
      {
        label: 'Wired Telecommunication activities',
        value: 'Wired Telecommunication activities',
        group: 'Wired Telecommunication activities',
      },
      {
        label: 'Wireless Telecommunication activities',
        value: 'Wireless Telecommunication activities',
        group: 'Wireless Telecommunication activities',
      },
      {
        label: 'Satellite Telecommunication activities',
        value: 'Satellite Telecommunication activities',
        group: 'Satellite Telecommunication activities',
      },
      {
        label: 'Other Telecommunication activities',
        value: 'Other Telecommunication activities',
        group: 'Other Telecommunication activities',
      },
      {
        label: 'Computer programming activities',
        value: 'Computer programming activities',
        group: 'Information technology service activities',
      },
      {
        label:
          'Information technology consultancy activities and computer facilities management activities',
        value:
          'Information technology consultancy activities and computer facilities management activities',
        group: 'Information technology service activities',
      },
      {
        label: 'Other information technology service activities',
        value: 'Other information technology service activities',
        group: 'Information technology service activities',
      },
      {
        label: 'Data processing,hosting and related activities',
        value: 'Data processing,hosting and related activities',
        group: 'Web portals,data processing,hosting and related activities',
      },
      {
        label: 'Web portals',
        value: 'Web portals',
        group: 'Web portals,data processing,hosting and related activities',
      },
      {
        label: 'News agenecy activities',
        value: 'News agency activities',
        group: 'Other information service activities',
      },
      {
        label: 'Other information service activities n.e.c',
        value: 'Other information service activties n.e.c',
        group: 'Other information service activities',
      },
      {
        label: 'Central banking',
        value: 'Central banking',
        group: 'Monetary intermediation',
      },
      {
        label: 'Other monetary intermediation',
        value: 'Other monetary intermediation',
        group: 'Monetary intermediation',
      },
      {
        label: 'Activities of holding companies',
        value: 'Activities of holding companies',
        group: 'Activities of holding companies',
      },
      {
        label: 'Trusts,funds and other financial vehicles',
        value: 'Trusts,funds and other financial vehicles',
        group: 'Trusts,funds and other financial vehicles',
      },
      {
        label: 'Financial leasing',
        value: 'Financial leasing',
        group: 'Other financial intermediation',
      },
      {
        label: 'Other credit granting',
        value: 'Other credit granting',
        group: 'Other financial intermediation',
      },
      {
        label: 'Other financial intermediation',
        value: 'Other financial intermediation',
        group: 'Other financial intermediation',
      },
      {
        label: 'Life Insurance',
        value: 'Life Insurance',
        group: 'Insurance',
      },
      {
        label: 'Non-life insurance',
        value: 'Non-life insurance',
        group: 'Insurance',
      },
      {
        label: 'Reinsurance',
        value: 'Reinsurance',
        group: 'Reinsurance',
      },
      {
        label: 'Pension funding',
        value: 'Pension funding',
        group: 'Pension funding',
      },
      {
        label: 'Adminstration of financial markets',
        value: 'Adminstration of financial markets',
        group:
          'Activities to financial intermediation,except insurance and pension funding',
      },
      {
        label: 'Security and commodity contracts dealing activities',
        value: 'Security and commodity contracts dealing activities',
        group:
          'Activities to financial intermediation,except insurance and pension funding',
      },
      {
        label: 'Activities auxiliary to financial intermediation n.e.c',
        value: 'Activities auxiiary to financial intermediation n.e.c',
        group:
          'Activities to financial intermediation,except insurance and pension funding',
      },
      {
        label: 'Risk and damage evaluation',
        value: 'Risk and damage evaluation',
        group: 'Activities  auxiliary to insurance and pension funding',
      },
      {
        label: 'Activities of insurance agents and brokers',
        value: 'Activities of insurance agents and brokers',
        group: 'Activities  auxiliary to insurance and pension funding',
      },
      {
        label: 'Other activities to insurance and pension funding',
        value: 'Other activities to insurance and pension funding',
        group: 'Activities  auxiliary to insurance and pension funding',
      },
      {
        label: 'Fund management activities',
        value: 'Fund management activities',
        group: 'Fund management activities',
      },
      {
        label: 'Real estate activities with own or leased property',
        value: 'Real estate activities with own or leased property',
        group: 'Real estate activities with own or leased property',
      },
      {
        label: 'Real activities on a free or contract basis',
        value: 'Real activities on a free or contract basis',
        group: 'Real activities on a free or contract basis',
      },
      {
        label: 'Legal activities',
        value: 'Legal activities',
        group: 'Legal activities',
      },
      {
        label: 'Accounting,bookkeeping and auditing activities;tax consultancy',
        value: 'Accounting,bookkeeping and auditing activities;tax consultancy',
        group: 'Accounting,bookkeeping and auditing activities;tax consultancy',
      },
      {
        label: 'Activities of head offices',
        value: 'Activities of head offices',
        group: 'Activities of head offices',
      },
      {
        label: 'Management consultancy activities',
        value: 'Management consultancy activities',
        group: 'Management consultancy activities',
      },
      {
        label:
          'Architectural and engineering activities and related technical consultancy',
        value:
          'Architectural and engineering activities and related technical consultancy',
        group:
          'Architectural and engineering activities and related technical consultancy',
      },
      {
        label: 'Technical testing and analysis',
        value: 'Technical testing and analysis',
        group: 'Technical testing and analysis',
      },
      {
        label:
          ' Research and experimental development of natural sciences and engineering',
        value:
          ' Research and experimental development of natural sciences and engineering',
        group:
          ' Research and experimental development of natural sciences and engineering',
      },
      {
        label:
          ' Research and experimental development of social sciences and humanities',
        value:
          ' Research and experimental development of social sciences and humanities',
        group:
          ' Research and experimental development of social sciences and humanities',
      },
      {
        label: 'Advertising',
        value: 'Advertising',
        group: 'Advertising',
      },
      {
        label: 'Market research and public opinion polling',
        value: 'Market research and public opinion polling',
        group: 'Market research and public opinion polling',
      },
      {
        label: 'Specialized design activities',
        value: 'Specialized design activities',
        group: 'Specialized design activities',
      },
      {
        label: 'Photographic activities',
        value: 'Photographic activities',
        group: 'Photographic activities',
      },
      {
        label: 'Other professional,scientific and techical activities n.e.c',
        value: 'Other professional,scientific and techical activities n.e.c',
        group: 'Other professional,scientific and techical activities n.e.c',
      },
      {
        label: 'Veterinary activities',
        value: 'Veterinary activities',
        group: 'Veterinary activities',
      },
      {
        label: 'Renting and leasing of motor vehicles',
        value: 'Renting and leasing of motor vehicles',
        group: 'Renting and leasing of motor vehicles',
      },
      {
        label: 'Renting and leasing of recreational and sports goods',
        value: 'Renting and leasing of recreational and sports goods',
        group: 'Renting and leasing of personal and household goods',
      },
      {
        label: 'Renting and leasing of video tapes and disks',
        value: 'Renting and leasing of video tapes and disks',
        group: 'Renting and leasing of personal and household goods',
      },
      {
        label:
          'Renting and leasing of other personal and household goods n.e.c',
        value:
          'Renting and leasing of other personal and household goods n.e.c',
        group: 'Renting and leasing of personal and household goods',
      },
      {
        label:
          'Renting and leasing of other machinery,equipment and tangible goods',
        value:
          'Renting and leasing of other machinery,equipment and tangible goods',
        group:
          'Renting and leasing of other machinery,equipment and tangible goods',
      },

      {
        label: 'Leasing of non-financial intangible assets',
        value: 'Leasing of non-financial intangible assets',
        group: 'Leasing of non-financial intangible assets',
      },
      {
        label: 'Activities of employment placement agencies',
        value: 'Activities of employment placement agencies',
        group: 'Activities of employment placement agencies',
      },
      {
        label: 'Temporary employment agency activities',
        value: 'Temporary employment agency activities',
        group: 'Temporary employment agency activities',
      },
      {
        label:
          'Human resources provision and management of human resources functions',
        value:
          'Human resources provision and management of human resources functions',
        group:
          'Human resources provision and management of human resources functions',
      },
      {
        label: 'Travel agency activities',
        value: 'Travel agency activities',
        group: 'Travel agency and tour operator activities',
      },
      {
        label: 'Tour operator activities',
        value: 'Tour operator activities',
        group: 'Travel agency and tour operator activities',
      },
      {
        label: 'Other reservation service activities',
        value: 'Other reservation service activities',
        group: 'Other reservation service activities',
      },
      {
        label: 'Private security activities',
        value: 'Private security activities',
        group: 'Private security activities',
      },
      {
        label: 'Security systems service activities',
        value: 'Security systems service activities',
        group: 'Security systems service activities',
      },
      {
        label: 'Investigation activities',
        value: 'Investigation activities',
        group: 'Investigation activities',
      },
      {
        label: 'Combined  facilities support activities',
        value: 'Combined  facilities support activities',
        group: 'Combined  facilities support activities',
      },
      {
        label: 'General cleaning of buildings',
        value: 'General cleaning of buildings',
        group: 'Cleaning activities',
      },
      {
        label: 'Other building and industrial cleaning activities',
        value: 'Other building and industrial cleaning activities',
        group: 'Cleaning activities',
      },

      {
        label: 'Landscape care and maintainance service activities',
        value: 'Landscape care and maintainance service activities',
        group: 'Landscape care and maintainance service activities',
      },
      {
        label: 'Combined office adminstrative service activities',
        value: 'Combined office adminstrative service activities',
        group: 'Office adminstrative and support activities',
      },
      {
        label:
          'Photocopying,document preparation and other specialized office support activities',
        value:
          'Photocopying,document preparation and other specialized office support activities',
        group: 'Office adminstrative and support activities',
      },
      {
        label: 'Activities of call centers',
        value: 'Activities of call centers',
        group: 'Activities of call centers',
      },
      {
        label: 'Convention and trade show organizers',
        value: 'Convention and trade show organizers',
        group: 'Convention and trade show organizers',
      },
      {
        label: 'Activities of collection agencies and credit bureaus',
        value: 'Activities of collection agencies and credit bureaus',
        group: 'Business support service activities n.e.c',
      },
      {
        label: 'Packaging activities',
        value: 'Packaging activities',
        group: 'Business support service activities n.e.c',
      },
      {
        label: 'Other business support service activities',
        value: 'Other business support service activities',
        group: 'Business support service activities n.e.c',
      },
      {
        label: 'General public adminstration activities',
        value: 'General public adminstration activities',
        group:
          'Adminstration of the state and the economic and social policy of the community',
      },
      {
        label:
          'Regulation of the activities of providing health care,education,cultural services and other social services excluding social security',
        value:
          'Regulation of the activities of providing health care,education,cultural services and other social services excluding social security',
        group:
          'Adminstration of the state and the economic and social policy of the community',
      },
      {
        label:
          'Regulation of and contribution to more efficient operation of businesses',
        value:
          'Regulation of and contribution to more efficient operation of business',
        group:
          'Adminstration of the state and the economic and social policy of the community',
      },
      {
        label: 'Foreign Affairs',
        value: 'Foreign Affairs',
        group: 'Provision of services to the community as a whole',
      },
      {
        label: 'Defence Activities',
        value: 'Defence Activities',
        group: 'Provision of services to the community as a whole',
      },
      {
        label: 'Public order and safety activities',
        value: 'Public order and safety activities',
        group: 'Provision of services to the community as a whole',
      },
      {
        label: 'Compulsory social security activities',
        value: 'Compulsory social security activities',
        group: 'Compulsory social security activities',
      },
      {
        label: 'Primary Education',
        value: 'Primary Education',
        group: 'Primary Education',
      },
      {
        label: 'General secondary education',
        value: 'General secondary education',
        group: 'Secondary Education',
      },
      {
        label: 'Technical and vocational secondary education',
        value: 'Technical and vocational secondary education',
        group: 'Secondary Education',
      },
      {
        label: 'Higher Education',
        value: 'Higher Education',
        group: 'Higher Education',
      },
      {
        label: 'Sports and recreation education',
        value: 'Sports and recreation education',
        group: 'Other Education',
      },
      {
        label: 'Cultural Education',
        value: 'Cultural Education',
        group: 'Other Education',
      },
      {
        label: 'Other Education n.e.c',
        value: 'Other Education n.e.c',
        group: 'Other Education',
      },
      {
        label: 'Educational support activities',
        value: 'Educational support activities',
        group: 'Educational support activities',
      },
      {
        label: 'Hospital activities',
        value: 'Hospital activities',
        group: 'Hospital activities',
      },
      {
        label: 'Medical and dental practice activities',
        value: 'Medical and dental practice activities',
        group: 'Medical and dental practice activities',
      },
      {
        label: 'Other human health activities',
        value: 'Other human health activities',
        group: 'Other human health activities',
      },
      {
        label: 'Nursing care facilities',
        value: 'Nursing care facilities',
        group: 'Nursing care facilities',
      },
      {
        label:
          'Residential care activities for mental retardation,mental health and subsbtance abuse',
        value:
          'Residential care activities for mental retardation,mental health and subsbtance abuse',
        group:
          'Residential care activities for mental retardation,mental health and subsbtance abuse',
      },
      {
        label: 'Residential care activities for the elderly and disabled',
        value: 'Residential care activities for the elderly and disabled',
        group: 'Residential care activities for the elderly and disabled',
      },
      {
        label: 'Other residential care activities n.e.c',
        value: 'Other residential care activities n.e.c',
        group: 'Other residential care activities n.e.c',
      },
      {
        label:
          'Social work activities without accomodation for the elderly and disabled ',
        value:
          'Social work activities without accomodation for the elderly and disabled ',
        group:
          'Social work activities without accomodation for the elderly and disabled ',
      },
      {
        label:
          'Other social work activities without accomodation for the elderly and disabled ',
        value:
          'Other social work activities without accomodation for the elderly and disabled ',
        group:
          'Other social work activities without accomodation for the elderly and disabled ',
      },
      {
        label: 'Creative,arts and entertainment activities',
        value: 'Creative,arts and entertainment activities',
        group: 'Creative,arts and entertainment activities',
      },
      {
        label: 'Library and archives activities',
        value: 'Library and archives activities',
        group: 'Libraries,archives,museums and other cultural activities',
      },
      {
        label:
          'Museum activities and operation of historical sites and buildings',
        value:
          'Museum activties and operation of historical sites and buildings',
        group: 'Libraries,archives,museums and other cultural activities',
      },
      {
        label:
          'Botanical and zoological gardens and nature reserves activities',
        value:
          'Botanical and zoological gardens and nature reserves activities',
        group: 'Libraries,archives,museums and other cultural activities',
      },
      {
        label: 'Gambling and betting activities',
        value: 'Gambling and betting activities',
        group: 'Gambling and betting activities',
      },
      {
        label: 'Operation of sports facilities',
        value: 'Operation of sports facilities',
        group: 'Sport activities',
      },
      {
        label: 'Activities of sports clubs',
        value: 'Activities of sports clubs',
        group: 'Sport activities',
      },
      {
        label: 'Other sport activities',
        value: 'Other sport activities',
        group: 'Sport activities',
      },
      {
        label: 'Activities of amusement parks and theme parks',
        value: 'Activities of amusement parks and theme parks',
        group: 'Other amusement and recreation activities',
      },
      {
        label: 'Other amusement and recreation activities n.e.c',
        value: 'Other amusement and recreation activities n.e.c',
        group: 'Other amusement and recreation activities',
      },
      {
        label: 'Activities of business and employers membership organization',
        value: 'Activities of business and employers membership organization',
        group:
          'Activities of business,employers and profressional membership organization',
      },
      {
        label: 'Activities of professional membership organization',
        value: 'Activities of professinal membership organization',
        group:
          'Activities of business,employers and profressional membership organization',
      },
      {
        label: 'Activities of trade unions',
        value: 'Activities of trade unions',
        group: 'Activities of trade unions',
      },
      {
        label: 'Activities of religious organizations',
        value: 'Activities of religious organizations',
        group: 'Activities of other membership organization',
      },
      {
        label: 'Activities of political organzations',
        value: 'Activities of political organizations',
        group: 'Activities of other membership organization',
      },
      {
        label: 'Activities of other membership organizations n.e.c',
        value: 'Activities of other memebership organizations n.e.c',
        group: 'Activities of other membership organization',
      },
      {
        label: 'Repair of computers and peripheral equipment',
        value: 'Repair of computers and peripheral equipment',
        group: 'Repair of computers and communication equipment',
      },
      {
        label: 'Repair of communication equipment',
        value: 'Repair of communication equipment',
        group: 'Repair of computers and communication equipment',
      },
      {
        label: 'Repair of consumer electronics',
        value: 'Repair of consumer electronics',
        group: 'Repair of personal and household goods',
      },
      {
        label: 'Repair of household appliances and home and garden equipment',
        value: 'Repair of household appliances and home and garden equipment',
        group: 'Repair of personal and household goods',
      },
      {
        label: 'Repair of footwear and leather goods',
        value: 'Repair of footwear and leather goods',
        group: 'Repair of personal and household goods',
      },
      {
        label: 'Repair of furniture and home furnishings',
        value: 'Repair of furniture and home furnishings',
        group: 'Repair of personal and household goods',
      },
      {
        label: 'Repair of personal and household goods n.e.c',
        value: 'Repair of personal and household goods n.e.c',
        group: 'Repair of personal and household goods',
      },
      {
        label: 'Washing and (dry)cleaning of textile and fur products',
        value: 'Washing and (dry)cleaning of textile and fur products',
        group: 'Other Service Activities',
      },
      {
        label: 'Hairdressing and other beauty treatment',
        value: 'Hairdressing and other beauty treatment',
        group: 'Other Service Activities',
      },
      {
        label: 'Funeral and related activities',
        value: 'Funeral and related activities',
        group: 'Other Service Activities',
      },
      {
        label: 'Other service activities n.e.c',
        value: 'Other service activities n.e.c',
        group: 'Other Service Activities',
      },
      {
        label: 'Activities of households as employers of domestic personnel',
        value: 'Activities of households as employers of domestic personnel',
        group: 'Activities of households as employers of domestic personnel',
      },
      {
        label:
          'Undifferenciated goods-producing activities of private households for own use',
        value:
          'Undifferenciated goods-producing activities of private households for own use',
        group:
          'Undifferenciated goods-producing activities of private households for own use',
      },
      {
        label:
          'Undifferenciated service-producing activities of private households for own use',
        value:
          'Undifferenciated service-producing activities of private households for own use',
        group:
          'Undifferenciated service-producing activities of private households for own use',
      },
      {
        label: 'Activities of extraterritorial oragnizations and bodies',
        value: 'Activities of extraterritorial oragnizations and bodies',
        group: 'Activities of extraterritorial oragnizations and bodies',
      },
      {
        label: 'Support activities for other mining and quarrying',
        value: 'Support activities for other mining and quarrying',
        group: 'Support activities for other mining and quarrying',
      },
    ],
  },
}
