import { Constants } from "config/constants";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Invites from "components/lib/Shared/CompanyDashboard/Invites";
import AccountSettings from "components/lib/Shared/CompanyDashboard/AccessTab";
import AssignPermission from "./AssignPermission";
import { classNames } from "utils";

const initialTabs = [{ id: "access", name: "Access" }];

const DashboardAccessRequests = () => {
  const [generalTabs, setGeneralTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState(initialTabs[0]);
  const { company, dashboardRole } = useSelector(
    (state) => state.companyDashboardSlice
  );

  useEffect(() => {
    if (dashboardRole === Constants.DASHBOARD_ROLES.VOR) {
      setGeneralTabs([{ id: "access", name: "Access" }]);
    }
    if (dashboardRole === Constants.DASHBOARD_ROLES.REGISTRAR) {
      setGeneralTabs([
        { id: "invites", name: "Invites" },
        { id: "access", name: "Access" },
        { id: "assign", name: "Assign" },
      ]);
    }
  }, [dashboardRole]);

  const handleTabClick = (item) => {
    if (item?.disabled) return null;
    else setSelectedTab(item);
  };

  return (
    <div className="grid gap-4 mx-auto">
      <h4 className="text-sflPrimary text-xl font-medium">Access control</h4>
      <div className="grid grid-cols-12 border-t border-gray">
        <div className="col-span-12 pr-2 md:col-span-1 pt-14">
          <div>
            <ul>
              {generalTabs.map((item) => (
                <li
                  key={item.name}
                  className={classNames(
                    item.id === selectedTab.id
                      ? "text-primary"
                      : "text-tertiary",
                    `mb-4 font-medium ${
                      item.disabled
                        ? "cursor-not-allowed text-gray-300"
                        : "hover:text-sflPrimary cursor-pointer"
                    }`
                  )}
                  onClick={() => handleTabClick(item)}
                >
                  <span className="truncate">{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-span-12 md:border-l md:col-span-11 md:pl-8 pt-14 border-gray">
          {selectedTab.id === "invites" && <Invites />}
          {selectedTab.id === "access" && (
            <AccountSettings
              page="COMPANY_DASHBOARD"
              company={company}
              dashboardRole={dashboardRole}
            />
          )}
          {selectedTab.id === "assign" && <AssignPermission />}
        </div>
      </div>
    </div>
  );
};

export default DashboardAccessRequests;
