import { string, object } from 'yup'

const addReviewerValidation = object().shape({
    name: string()
        .trim('Name cannot include leading and trailing spaces')
        .strict(true)
        .required("Name is required"),
    email: string()
        .email("Invalid email address format")
        .trim('Email address cannot include leading and trailing spaces')
        .strict(true)
        .required("Email address is required"),
    role: string()
        .trim('Role cannot include leading and trailing spaces')
        .strict(true)
        .required("Role is required"),
})

export default addReviewerValidation
