import { useRef } from "react";
import Footer from "components/lib/Global/Footer";
import Header from "components/lib/Global/Header";

export default function Contacts() {
  const form = useRef();
  const sendEmail = () => {};
  return (
    <>
      <div className="flex flex-col min-h-screen overflow-y-hidden text-lg text-sflPrimary">
        <Header />

        <div className="2xl:container 2xl:mx-auto lg:py-12 lg:px-20 md:py-8 md:px-6 px-4">
          <section className="container flex flex-col flex-1 mx-auto items-center ">
            <main className="container flex flex-col mx-auto">
              <section className=" border-b-2">
                <h1 className="text-3xl lg:text-5xl font-bold text-gray-800 w-11/12">
                  Get in touch
                </h1>
              </section>

              <div className="flex flex-col lg:flex-row justify-center items-start h-full">
                <div className="lg:w-full 2xl:w-2/5">
                  <div className="xl:px-0 px-8 py-10 mx-auto">
                    <p className="text-base  pb-8 leading-relaxed font-normal ">
                      We believe digital innovation & quality service is at the
                      heart of every business success. <br />
                      Got a question about us? Are you interested in partnering
                      with us? Have some suggestions or just want to say Hi?{" "}
                      <br />
                      Reach out
                    </p>
                    <div className="space-y-4">
                      <div className="flex items-center">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-phone-call w-6 h-6"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <path d="M4 4h5l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v5a1 1 0 0 1 -1 1a16 16 0 0 1 -16 -16a1 1 0 0 1 1 -1" />
                            <path d="M15 7a2 2 0 0 1 2 2" />
                            <path d="M15 3a6 6 0 0 1 6 6" />
                          </svg>
                        </div>
                        <p className="pl-4  text-base">+254 (733) 774 000</p>
                      </div>
                      <div className="flex items-center">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="pl-4  text-base">
                            hello@simpleformations.ke
                          </p>
                        </div>
                      </div>

                      <div className="flex items-start">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                            />
                          </svg>
                        </div>

                        <div className="pl-4 ">
                          <p className="text-base ">
                            <p className="">
                              The Address, 8th Floor, Space D, <br />
                            </p>
                            Muthangari Drive, Off Waiyaki Way, <br />
                            Nairobi
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w-full 2xl:w-3/5 flex w-full bg-gray-50 flex-col justify-center px-5 py-5 md:px-7 md:py-7 lg:py-12 lg:px-20 mt-2 md:mt-6 lg:mt-0">
                  <h2 className="text-lg font-semibold text-gray-800">
                    Send us a message
                  </h2>

                  <form ref={form} onSubmit={sendEmail} className="w-full">
                    <input
                      className="rounded-md border border-gray-300 mt-4 md:mt-6 p-4 text-base text-gray-600 focus:outline-none focus:border-gray-700 w-full"
                      type="text"
                      aria-label="Name"
                      placeholder="Enter your Name"
                    />
                    <input
                      className="rounded-md border border-gray-300 mt-4 p-4 text-base text-gray-600 focus:outline-none focus:border-gray-700 w-full"
                      type="email"
                      aria-label="Email"
                      placeholder="Enter your email"
                    />
                    <textarea
                      name=""
                      id=""
                      aria-label="Your message"
                      placeholder="Message"
                      className="w-full h-48 lg:h-36 xl:h-48 2xl:h-56 mt-4 md:mt-6 p-4 rounded-md border border-gray-300 resize-none text-base text-gray-600 focus:outline-none focus:border-gray-700"
                    ></textarea>
                    <button className="w-full text-white bg-sflPrimary hover:bg-green-primary hover:text-sflPrimary rounded-md mt-4 md:mt-5 leading-4 p-4 text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700">
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </main>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}
