import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from "hooks/useToast";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import CorporatesReviewTab from "components/PLCTABS/CorporatesReviewTab";
import { getCorporate, reset } from "state/slices/organizations";
import { getComments, resetCommentsSlice } from "state/slices/comment";

function SubRowAsync({ visibleColumns, row, refreshTableData }) {
  const dispatch = useDispatch();
  const { toast } = useToast(5000);

  const { organization, getOrganization } = useSelector(
    (state) => state.organizationsSlice
  );

  useEffect(() => {
    if (row?.original?._id) {
      dispatch(getCorporate({ corporateId: row.original._id }));
      dispatch(getComments({ corporate: row.original._id }));
    }
    return () => {
      dispatch(reset());
      dispatch(resetCommentsSlice());
    };
  }, [row?.original?._id]);

  useEffect(() => {
    if (getOrganization.status === "failed" && getOrganization.error) {
      toast("error", getOrganization.error);
      row.toggleRowExpanded();
    }
  }, [getOrganization.status, getOrganization.error]);

  if (!organization?._id || getOrganization.status === "loading") {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto my-4">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  const handleBackBtn = () => {
    row.toggleRowExpanded();
    refreshTableData();
  };

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="p-8 max-w-lg">
        <CorporatesReviewTab
          handleBackBtn={handleBackBtn}
          row={row}
          refreshTableData={refreshTableData}
        />
      </td>
    </tr>
  );
}

export default SubRowAsync;
