import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { array, boolean, number, object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import Button from "components/lib/Shared/Button";
import Radio from "components/lib/Shared/Radio";
import Input from "components/lib/Shared/Input";
import {
  addDiscountAsync,
  editDiscountAsync,
  generateCodeAsync,
  getDiscountsAsync,
  updateDiscountSlice,
} from "state/slices/discount";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import DatePickerField from "components/lib/Shared/DatePickerField";
import AssignmentForm from "./Assignment/Form";
import { useToast } from "hooks/useToast";
import {
  addDiscountAssigneeAsync,
  deleteDiscountAssigneeAsync,
  resetDiscountAssigneeSlice,
} from "state/slices/discountAssignee";
import { getCompletePackageName } from "utils";

const validationSchema = object().shape({
  percentage: number()
    .test(
      "valid-percentage",
      "Discount percentage must be between 1 and 100",
      (value) => {
        if (value !== undefined && value !== null && value !== "") {
          const parsedValue = parseInt(value);
          return !isNaN(parsedValue) && parsedValue >= 1 && parsedValue <= 100;
        }
        return false;
      }
    )
    .required("Discount percentage is required"),
  packages: array().required("Please select a package").default(""),
  code: string()
    .required("Discount code is required")
    .length(7, "Discount code must be exactly 7 characters long"),
  isTimeBound: boolean().default(false),
  startDate: string().when("isTimeBound", {
    is: true,
    then: string().required("Start date is required"),
    otherwise: string().notRequired(),
  }),
  endDate: string().when("isTimeBound", {
    is: true,
    then: string().required("End date is required"),
    otherwise: string().notRequired(),
  }),
  isGeneral: boolean(),
  discountAssignees: array().when("isGeneral", {
    is: false,
    then: array().required("Please at least assign one user"),
    otherwise: array().notRequired(),
  }),
});

const Discount = ({ setShowAddDiscountForm }) => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const { packages } = useSelector((state) => state.packageSlice);
  const { selectedDiscount, generateCode } = useSelector(
    (state) => state.discountSlice
  );
  const { discountAssignees, discountAssigneesToDelete } = useSelector(
    (state) => state.discountAssigneeSlice
  );

  const handleExitWorkflow = () => {
    setShowAddDiscountForm(false);
  };

  const [selectedPackages, setSelectedPackages] = useState(
    selectedDiscount?.packages?.map((pricingPackage) => ({
      value: pricingPackage._id,
      label: getCompletePackageName(pricingPackage),
    }))
  );

  useEffect(() => {
    return () => {
      dispatch(getDiscountsAsync());
      dispatch(updateDiscountSlice({ selectedDiscount: {} }));
      dispatch(resetDiscountAssigneeSlice());
    };
  }, []);

  const handleSubmit = async (data) => {
    let response = {};
    if (data?._id) {
      response = await dispatch(
        editDiscountAsync({ discountId: data?._id, data })
      );
    } else {
      response = await dispatch(addDiscountAsync({ data }));
    }
    if (response.error) {
      toast(
        "error",
        response.payload?.messageText ??
          "Something went wrong please try again."
      );
    } else {
      await Promise.all(
        discountAssignees.items.map((discountAssignee) => {
          if (discountAssignee?._id) return Promise.resolve();
          return dispatch(
            addDiscountAssigneeAsync({
              data: {
                discount:
                  response.payload?.discount?._id || response.payload._id,
                ...discountAssignee,
              },
            })
          );
        })
      );
      discountAssigneesToDelete.map((discountAssigneeId) => {
        dispatch(deleteDiscountAssigneeAsync({ discountAssigneeId }));
      });
      setShowAddDiscountForm(false);
      dispatch(updateDiscountSlice({ selectedDiscount: {} }));
      dispatch(resetDiscountAssigneeSlice());
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        percentage: selectedDiscount?.percentage ?? "",
        code: selectedDiscount?.code ?? "",
        packages: [],
        isGeneral: selectedDiscount?.isGeneral ?? true,
        idType: selectedDiscount?.idType ?? "",
        isTimeBound: selectedDiscount?.isTimeBound ?? false,
        startDate: selectedDiscount?.startDate ?? "",
        endDate: selectedDiscount?.endDate ?? "",
        ...selectedDiscount,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        submitCount,
        isSubmitting,
        setFieldValue,
      }) => {
        useEffect(() => {
          if (!values.isTimeBound) {
            setFieldValue("startDate", "");
            setFieldValue("endDate", "");
          }
        }, [values.isTimeBound]);

        useEffect(() => {
          setFieldValue("discountAssignees", discountAssignees.items);
        }, [discountAssignees.items]);

        const handleGenerateCode = async () => {
          const response = await dispatch(generateCodeAsync());
          setFieldValue("code", response.payload.code);
        };

        const handlePackageSelect = (name) => (option) => {
          const roles = Array.isArray(option)
            ? option.map(({ value }) => value)
            : option?.value
            ? [option?.value]
            : [];
          setFieldValue(name, roles);
          setSelectedPackages(option);
        };

        const handleDateValue = (type) => (date) => {
          if (type === "startDate") {
            setFieldValue("endDate", "");
          }
          setFieldValue([type], format(date, "yyyy/MM/dd"));
        };

        return (
          <Form className="text-sm w-full">
            <div className="flex flex-col justify-between">
              <fieldset className="shadow sm:rounded-md mt-1">
                <div className="mt-1 pl-4 bg-white py-6 px-4 space-y-6 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Field
                        component={Input}
                        showRequiredAsterisk
                        label="Enter discount %"
                        id="percentage"
                        name="percentage"
                        type="number"
                        min={1}
                        step={1}
                        max={100}
                        placeholder="Enter discount"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <div className="flex justify-between">
                        <legend
                          className={`block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500`}
                        >
                          Discount Code
                        </legend>
                        <Button
                          variant="text"
                          onClick={handleGenerateCode}
                          disabled={generateCode.status === "loading"}
                        >
                          {generateCode.status === "loading"
                            ? "Generating..."
                            : values?.code
                            ? "Regenerate Code"
                            : "Generate Code"}
                        </Button>
                      </div>
                      <Field
                        component={Input}
                        disabled
                        id="code"
                        name="code"
                        placeholder="Enter discount code"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Field
                        component={ReactSelectWithFormik}
                        onChange={handlePackageSelect("packages")}
                        isMulti
                        showRequiredAsterisk
                        label="Select packages"
                        id="packages"
                        name="packages"
                        options={packages.items.map((pricingPackage) => ({
                          value: pricingPackage._id,
                          label: getCompletePackageName(pricingPackage),
                        }))}
                        value={selectedPackages}
                        placeholder="Select packages"
                      />
                    </div>
                    <div className="col-span-6 border-t border-gray-200 pt-2">
                      <fieldset>
                        <legend className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
                          Is this general?
                        </legend>
                        <div className="mt-1 pl-4">
                          <div className="flex space-x-6">
                            <Radio
                              id="yesGeneral"
                              name="yesGeneral"
                              label="Yes"
                              type="radio"
                              checked={values.isGeneral === true}
                              onChange={() => setFieldValue("isGeneral", true)}
                              className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                            />
                            <Radio
                              id="notGeneral"
                              name="notGeneral"
                              label="No"
                              type="radio"
                              checked={values.isGeneral === false}
                              onChange={() => setFieldValue("isGeneral", false)}
                              className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <AssignmentForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      submitCount={submitCount}
                    />
                    <div className="col-span-6 border-t border-gray-200 pt-2">
                      <fieldset>
                        <legend className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500">
                          Will the discount be
                        </legend>
                        <div className="mt-1 pl-4">
                          <div className="flex space-x-6">
                            <Radio
                              id="yesTimeBound"
                              name="yesTimeBound"
                              label="Timebound"
                              type="radio"
                              checked={values.isTimeBound === true}
                              onChange={() =>
                                setFieldValue("isTimeBound", true)
                              }
                              className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                            />
                            <Radio
                              id="notTimebound"
                              name="notTimebound"
                              label="Indefinite"
                              type="radio"
                              checked={values.isTimeBound === false}
                              onChange={() =>
                                setFieldValue("isTimeBound", false)
                              }
                              className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    {values.isTimeBound && (
                      <>
                        <div className="col-span-6 sm:col-span-3 flex flex-col grow">
                          <Field
                            name="startDate"
                            label="Start date"
                            id="startDate"
                            showRequiredAsterisk
                            component={DatePickerField}
                            disabled={isSubmitting}
                            onChange={handleDateValue("startDate")}
                            onSelect={handleDateValue("startDate")}
                            selected={
                              values?.startDate
                                ? new Date(values.startDate)
                                : ""
                            }
                            minDate={new Date()}
                            placeholderText="Select start date"
                            errorPath="startDate"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-3 flex flex-col grow">
                          <Field
                            name="endDate"
                            label="End date"
                            id="endDate"
                            showRequiredAsterisk
                            component={DatePickerField}
                            disabled={isSubmitting}
                            onChange={handleDateValue("endDate")}
                            onSelect={handleDateValue("endDate")}
                            selected={
                              values?.endDate ? new Date(values.endDate) : ""
                            }
                            minDate={
                              values?.startDate
                                ? new Date(values.startDate)
                                : new Date()
                            }
                            placeholderText="Select end date"
                            errorPath="endDate"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </fieldset>
              <div className="flex items-center justify-end gap-x-4 mt-2">
                <Button
                  disabled={isSubmitting}
                  variant="secondary"
                  onClick={handleExitWorkflow}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  loadingText="Saving..."
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Discount;
