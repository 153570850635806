import { useState } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { Formik, Form, Field } from "formik";
import { object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";
import _sortBy from "lodash/sortBy";
import Cookies from "js-cookie";

import Input from "components/lib/Shared/Input";
import Button from "components/lib/Shared/Button";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { useToast } from "hooks/useToast";
import {
  addFeatureAsync,
  deleteFeatureAsync,
  updateFeatureAsync,
} from "state/slices/package";
import { Constants } from "config/constants";

const validationSchema = object().shape({
  description: string().required("Feature description is required"),
});

const Features = () => {
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();
  const [showAddFeature, setShowFeature] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState("");
  const { selectedPackage, updatingFeatures, deletingFeatures } = useSelector(
    (state) => state.packageSlice
  );

  const features = _sortBy(selectedPackage?.features ?? [], "status");

  const handleAddNewFeature = () => {
    setShowFeature(true);
    setSelectedFeature("");
  };

  const handleUpdateFeature = async ({ featureId, data }) => {
    await dispatch(
      updateFeatureAsync({ packageId: selectedPackage?._id, featureId, data })
    );
  };

  const handleEditFeature = (feature) => {
    setSelectedFeature(feature);
    setShowFeature(true);
  };

  const handleDeleteFeature = (featureId) => {
    dispatch(
      deleteFeatureAsync({ packageId: selectedPackage?._id, featureId })
    );
  };

  if (!showAddFeature) {
    return (
      <div className="flex flex-col space-y-2">
        <p>A list of all offerings for the package</p>
        {features.length > 0 && (
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <ul className="list-disc bg-white py-6 px-4 space-y-2 sm:p-6">
              {features.map((feature) => {
                return (
                  <li key={feature._id} className="ml-4">
                    <div className="flex justify-between items-center">
                      <span>{feature.description}</span>
                      {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
                        <div className="flex space-x-2">
                          {updatingFeatures.includes(feature._id) ||
                          deletingFeatures.includes(feature._id) ? (
                            <SpinnerIcon className="text-gray-500 my-1" />
                          ) : (
                            <>
                              {feature.status === "Active" ? (
                                <>
                                  <Button
                                    variant="text"
                                    className="p-1"
                                    onClick={() =>
                                      handleUpdateFeature({
                                        featureId: feature._id,
                                        data: { status: "Archive" },
                                      })
                                    }
                                  >
                                    Archive
                                  </Button>
                                  <Button
                                    className="p-1"
                                    onClick={() => handleEditFeature(feature)}
                                  >
                                    Edit Details
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="text"
                                    className="p-1"
                                    onClick={() =>
                                      handleUpdateFeature({
                                        featureId: feature._id,
                                        data: { status: "Active" },
                                      })
                                    }
                                  >
                                    Unarchive
                                  </Button>
                                  <Button
                                    className="p-1"
                                    onClick={() =>
                                      handleDeleteFeature(feature._id)
                                    }
                                  >
                                    Delete
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
          <Button
            preIcon={PlusIcon}
            variant="text"
            className="self-start"
            onClick={handleAddNewFeature}
          >
            Add New
          </Button>
        )}
      </div>
    );
  }

  const handleSubmit = async (data) => {
    if (data?._id) {
      await dispatch(
        updateFeatureAsync({
          packageId: selectedPackage?._id,
          featureId: data._id,
          data,
        })
      );
    } else {
      await dispatch(
        addFeatureAsync({ packageId: selectedPackage?._id, data })
      );
    }
    setShowFeature(false);
  };

  if (showAddFeature || selectedFeature) {
    return (
      <Formik
        enableReinitialize
        initialValues={{ description: "", ...selectedFeature }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => {
          return (
            <Form className="text-sm w-full">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <div className="flex justify-between items-start gap-6">
                    <div className="flex-grow">
                      <Field
                        component={Input}
                        name="description"
                        type="text"
                        placeholder="List your offer"
                      />
                    </div>
                    <div className="flex items-center justify-end gap-x-4 mt-1">
                      <Button
                        disabled={isSubmitting}
                        variant="secondary"
                        onClick={() => setShowFeature(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        loadingText="Saving..."
                        isLoading={isSubmitting}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
};

export default Features;
