import axios from "../axios";

const initiateCheckout = (data) =>
  axios.post(`/payments/initiate-checkout`, data);

const checkTransactionStatus = (data) =>
  axios.post(`/payments/check-transaction-status`, data);

const verifyPayment = (data) => axios.post(`/payments/verify-payment`, data);

const cancelTransaction = (data) =>
  axios.post(`/payments/cancel-transaction`, data);

const checkTransactionToken = (data) =>
  axios.get(`/payments/check-transaction-token?${new URLSearchParams(data)}`);

const paymentService = {
  initiateCheckout,
  checkTransactionStatus,
  cancelTransaction,
  verifyPayment,
  checkTransactionToken,
};

export default paymentService;
