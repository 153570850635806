import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import { getBeneficialOwnershipFormFields } from "utils";

function OfficialBeneficialOwnership({
  official,
  hideResolveAllCheckbox,
  beneficialOwners = [],
}) {
  return (
    <div className="overflow-hidden">
      <FormFieldsCard
        fields={getBeneficialOwnershipFormFields({
          beneficialOwnershipForm: official.beneficialOwnershipForm,
          reviewTab: true,
          renderShortTypeOfControl: true,
          renderDateOfCeasingToBeABO: false,
          renderDateOfBecomingToBeABO: false,
          sourceOfBO: false,
          onlyDates: false,
          beneficialOwner: official,
          beneficialOwners,
        })}
        formName="beneficialOwnershipForm"
        formHelperKey="beneficialOwnershipForm"
        hideResolveAllCheckbox={hideResolveAllCheckbox}
      />
    </div>
  );
}

export default OfficialBeneficialOwnership;
