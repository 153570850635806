import React, { useEffect, useRef } from "react";
import _get from "lodash/get";
import _isPlainObject from "lodash/isPlainObject";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";

import ValidationError from "./ValidationError";
import CommentsList from "components/lib/Global/CommentsList";
import { Constants } from "config/constants";

const Input = (props) => {
  let {
    label,
    name,
    id,
    field = {},
    min,
    max,
    step,
    readOnly,
    applychangeeffectto,
    required,
    disabled = false,
    placeholder = "",
    autoComplete = "off",
    type = "text",
    renderTextarea,
    maxLength,
    rows = 4,
    form = {},
    horizontalLayout = false,
    areCSFieldsOptional,
    showRequiredAsterisk,
    leadingIcon: LeadingIcon,
    renderError = true,
  } = props;

  const currentUser = useSelector((state) => state.userSlice);
  const isApplicant = form?.values?.createdBy === currentUser._id;
  const inputRef = useRef(null);

  useEffect(() => {
    // Moving cursor to the end for textarea type
    if (inputRef?.current && ["textarea"].includes(inputRef?.current.type)) {
      inputRef.current.selectionStart = inputRef?.current?.value?.length;
      inputRef.current.selectionEnd = inputRef?.current?.value?.length;
    }
  }, []);

  const { errors = {}, touched = {} } = form;

  if (field?.name) name = field.name;

  const onChange = props?.onChange
    ? props.onChange
    : field?.onChange
    ? field.onChange
    : () => null;

  const onBlur = props?.onBlur
    ? props.onBlur
    : field?.onBlur
    ? field?.onBlur
    : () => null;

  const value = props?.value ?? field?.value ?? "";

  const errorPath = props?.errorPath ? props.errorPath : name;

  const commentPath = props?.commentPath ? props.commentPath : errorPath;

  let comments = props?.comments
    ? props.comments
    : form?.values?.comments ?? [];

  if (_isPlainObject(comments)) {
    comments = _get(comments, commentPath, []);
  }

  let resolvedFields = props?.resolvedFields
    ? props.resolvedFields
    : _get(form?.values, errorPath?.split(".")?.[0])?.resolvedFields ?? [];

  return (
    <>
      <div
        className={`col-span-6 sm:col-span-2 ${
          horizontalLayout ? "flex items-center" : ""
        }`}
      >
        {label && (
          <label
            htmlFor={id ? id : name}
            className={`block text-sm font-medium text-gray-700 ${
              Constants.REQUIRED_OFFICIALS_FIELDS.includes(name) ||
              form?.values?.requiredFields?.includes(name) ||
              showRequiredAsterisk
                ? "after:content-['*'] after:ml-0.5 after:text-red-500"
                : ""
            } ${horizontalLayout ? "w-60" : ""}`}
          >
            <span className="relative">
              {label}
              {comments.length > 0 && (
                <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-3 -right-5">
                  <span>{comments.length}</span>
                </span>
              )}
            </span>
          </label>
        )}
        {renderTextarea ? (
          <textarea
            id={id ? id : name}
            type={type}
            name={name}
            maxLength={maxLength}
            value={
              value ||
              ((form?.values?._id ? isApplicant : true) &&
              (disabled || resolvedFields.includes(name)) &&
              (form?.values?.type === "invited" || form?.values?.hasAccess)
                ? "N/A"
                : value)
            }
            rows={rows}
            className={twMerge(
              "block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed",
              props.className
            )}
            placeholder={placeholder}
            applychangeeffectto={applychangeeffectto}
            required={required}
            disabled={disabled || resolvedFields.includes(name)}
            readOnly={readOnly}
            onBlur={onBlur}
            onChange={onChange}
            ref={inputRef}
          />
        ) : (
          <div className="relative">
            {LeadingIcon && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <LeadingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            )}
            <input
              id={id ? id : name}
              type={type}
              onWheel={(e) => (type === "number" ? e.target.blur() : null)}
              name={name}
              value={
                value ||
                ((form?.values?._id ? isApplicant : true) &&
                (disabled || resolvedFields.includes(name)) &&
                !name?.includes("beneficialOwnershipForm") &&
                (form?.values?.type === "invited" || form?.values?.hasAccess)
                  ? areCSFieldsOptional
                    ? "To be completed"
                    : "N/A"
                  : value)
              }
              min={min}
              max={max}
              autoComplete={autoComplete}
              className={twMerge(
                `block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed ${
                  horizontalLayout ? "h-9" : ""
                }`,
                props.className
              )}
              placeholder={
                (form?.values?._id ? isApplicant : true) &&
                type === "number" &&
                (form?.values?.type === "invited" || form?.values?.hasAccess) &&
                !name?.includes("beneficialOwnershipForm") &&
                (disabled || resolvedFields.includes(name)) &&
                !value
                  ? areCSFieldsOptional
                    ? "To be completed"
                    : "N/A"
                  : placeholder
              }
              applychangeeffectto={applychangeeffectto}
              required={required}
              step={step}
              disabled={disabled || resolvedFields.includes(name)}
              readOnly={readOnly}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>
        )}
        {!horizontalLayout && comments.length > 0 && (
          <CommentsList comments={comments} color={"text-red-500"} />
        )}
        {!horizontalLayout && errorPath && renderError && (
          <ValidationError errors={errors} touched={touched} name={errorPath} />
        )}
      </div>
      {horizontalLayout && (
        <div>
          {comments.length > 0 && (
            <CommentsList comments={comments} color={"text-red-500"} />
          )}
          {errorPath && renderError && (
            <ValidationError
              errors={errors}
              touched={touched}
              name={errorPath}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Input;
