import { Constants } from "config/constants";
import { object, string } from "yup";

/**
 * This returns an object composed of conditional `yup` schema and initial values
 * related to residential details form.
 *
 * @param {object} validationParams - Object containing `idType` and `isNonResident` properties of official.
 * @param {string} validationParams.idType - The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {string} validationParams.isNonResident - Conditional string value `'No' || 'Yes` of the current official.
 * @return {object} This returns object containing schema and initial values based on schema.
 * @example
 *  {
 *    schema
 *    schemaInitialValues
 *  }
 */
const residentialDetailsValidation = ({ idType }) => {
  const electronicAddressSchema = object().shape({
    electronicAddress: object().shape({
      emailAddress: string()
        .email("Invalid email format")
        .trim("Email cannot include leading and trailing spaces")
        .strict(true)
        .when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Email address is required"),
        }),
      mobileNumber: string()
        .trim("Mobile number cannot include leading and trailing spaces")
        .when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Mobile number is required"),
        })
        .matches(Constants.PHONE_NUMBER_REGEX, "Phone number is not valid")
        .test("length", function (value) {
          if (
            value &&
            [
              "Kenyan Citizen",
              "Foreign Resident",
              "Local Company",
              "Other e.g Parastatals",
            ].includes(idType)
          ) {
            if (value.length !== 13) {
              return this.createError({
                message: "Mobile number must be exactly 13 characters",
              });
            }
            if (!value.startsWith("+254")) {
              return this.createError({
                message: "Correct format is +254#########",
              });
            }
            return true;
          } else if (
            value &&
            (value.length < 11 || value.length > 13) &&
            ["Foreigner", "Foreign Company"].includes(idType)
          ) {
            return this.createError({
              message: "Mobile number is not correct",
            });
          } else return true;
        }),
    }),
  });

  return {
    schema: electronicAddressSchema,
    schemaInitialValues: electronicAddressSchema.cast(),
  };
};

export default residentialDetailsValidation;
