import React, { useEffect } from "react";
import { Field } from "formik";
import Cookies from "js-cookie";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import ValidationError from "components/lib/Shared/ValidationError";
import CommentsList from "components/lib/Global/CommentsList";
import { Constants } from "config/constants";

const RegulatedArticles = ({
  field,
  form: { values, setFieldValue, errors, touched },
  transformedComments,
}) => {
  const role = Cookies.get("role");

  const handleChange = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  // for validation
  useEffect(() => {
    setFieldValue(field.name, {
      nameOfRegulator: "",
      regulatorInstitute: "",
      ...field.value,
    });
  }, []);

  const nameOfRegulatorComments =
    transformedComments?.["articlesOfAssociation"]?.nameOfRegulator ?? [];
  const regulatorInstituteComments =
    transformedComments?.["articlesOfAssociation"]?.regulatorInstitute ?? [];
  const regulatedArticles_LNOComments =
    transformedComments?.["articlesOfAssociation"]?.regulatedArticles_LNO ?? [];

  return (
    <div className="space-y-4">
      <div className="bg-gray-50 p-4 shadow-sm">
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="nameOfRegulator"
              className="block text-sm font-medium text-gray-700"
            >
              <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
                Name of Regulator
                {nameOfRegulatorComments.length > 0 && (
                  <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-2 -right-5">
                    <span>{nameOfRegulatorComments.length}</span>
                  </span>
                )}
              </span>
            </label>

            <Field
              className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              id="nameOfRegulator"
              name="nameOfRegulator"
              placeholder="Enter name of regulator"
              disabled={values.resolvedFields.includes("nameOfRegulator")}
              value={field.value.nameOfRegulator}
              onChange={handleChange}
            />
            {nameOfRegulatorComments.length > 0 && (
              <CommentsList
                comments={nameOfRegulatorComments}
                color={"text-red-500"}
              />
            )}
            <ValidationError
              errors={errors}
              touched={touched}
              name="articlesOfAssociation.nameOfRegulator"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="regulatorInstitute"
              className="block text-sm font-medium text-gray-700"
            >
              <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
                Select
                {regulatorInstituteComments.length > 0 && (
                  <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-2 -right-5">
                    <span>{regulatorInstituteComments.length}</span>
                  </span>
                )}
              </span>
            </label>
            <select
              type="select"
              className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              name="regulatorInstitute"
              id="regulatorInstitute"
              disabled={values.resolvedFields.includes("regulatorInstitute")}
              value={field.value.regulatorInstitute}
              onChange={handleChange}
            >
              <option value="">Select</option>
              <option>University</option>
              <option>Banks,Microfinance,Forex Bureau</option>
              <option>Gaming Rooms, Casinos</option>
              <option>Other</option>
            </select>
            {regulatorInstituteComments.length > 0 && (
              <CommentsList
                comments={regulatorInstituteComments}
                color={"text-red-500"}
              />
            )}
            <ValidationError
              errors={errors}
              touched={touched}
              name="articlesOfAssociation.regulatorInstitute"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="exampleSelect"
            className="block mb-1 text-sm font-medium text-gray-700"
          >
            <span className="relative">
              Upload a letter of no objections from the regulator
              {regulatedArticles_LNOComments.length > 0 && (
                <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-2 -right-5">
                  <span>{regulatedArticles_LNOComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <Field
            name="fileUploads"
            component={FileUploadForm}
            documentData={[{ label: "", name: `regulatedArticles_LNO` }]}
            disabled={role === Constants.COOKIE_ROLES.OFFICIAL}
          />
          {regulatedArticles_LNOComments.length > 0 && (
            <CommentsList
              comments={regulatedArticles_LNOComments}
              color={"text-red-500"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RegulatedArticles;
