import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import sharesTableSlice from "./slices/tables/nominalSharesTable";
import modalsSlice from "./slices/modals";
import companyOfficialsSlice from "./slices/tables/companyOfficialsSlice";
import jointShareholderTableSlice from "./slices/tables/jointShareholderTable";
import sharesAllocationTableSlice from "./slices/tables/sharesAllocationTable";
import beneficialOwnersTableSlice from "./slices/tables/beneficialOwnersTableSlice";
import corporateShareholderTableSlice from "./slices/tables/corporateShareholderTableSlice";
import corporateJointShareholderTableSlice from "./slices/tables/corporateJointShareholderTableSlice";
import companiesSlice from "./slices/companies";
import authSlice from "./slices/auth";
import userSlice from "./slices/user";
import companySlice from "./slices/company";
import registeredOfficeSlice from "./slices/registeredOffice";
import reviewersTable from "./slices/tables/reviewersTable";
import commentsSlice from "./slices/comment";
import resolveField from "./slices/resolveField";
import accessRequestsSlice from "./slices/accessRequest";
import accessRequestComments from "./slices/accessRequestComment";
import organizationsSlice from "./slices/organizations";
import partnersSlice from "./slices/tables/partnersSlice";
import companyDashboardSlice from "./slices/companyDashboard";
import verifyInviteSlice from "./slices/verifyInvite";
import professionalDetailsSlice from "./slices/professionalDetails";
import organizationDashboardSlice from "./slices/organizationDashboard";
import permissionSlice from "./slices/permission";
import contactPersonCorporatesSlice from "./slices/contactPersonCorporates";
import corporatesForDashboardSlice from "./slices/corporatesForDashboard";
import superAdminSlice from "./slices/superAdmin";
import orgAdminSlice from "./slices/orgAdmin";
import statsSlice from "./slices/stats";
import activeDashboardSlice from "./slices/activeDashboard";
import productSlice from "./slices/product";
import packageSlice from "./slices/package";
import discountSlice from "./slices/discount";
import discountAssigneeSlice from "./slices/discountAssignee";
import invoiceSlice from "./slices/invoice";
import orderSlice from "./slices/order";
import transactionsSlice from "./slices/transactions";
import templateCategory from "./slices/templateCategory";
import templateSlice from "./slices/template";
import minuteBookSlice from "./slices/minuteBook";
import coApplicantSlice from "./slices/coApplicant";

const appReducer = combineReducers({
  sharesAllocationTableSlice,
  sharesTableSlice,
  modalsSlice,
  companyOfficialsSlice,
  jointShareholderTableSlice,
  beneficialOwnersTableSlice,
  corporateShareholderTableSlice,
  corporateJointShareholderTableSlice,
  companiesSlice,
  authSlice,
  userSlice,
  companySlice,
  registeredOfficeSlice,
  reviewersTable,
  commentsSlice,
  resolveField,
  accessRequestsSlice,
  accessRequestComments,
  organizationsSlice,
  partnersSlice,
  companyDashboardSlice,
  verifyInviteSlice,
  professionalDetailsSlice,
  organizationDashboardSlice,
  permissionSlice,
  contactPersonCorporatesSlice,
  corporatesForDashboardSlice,
  superAdminSlice,
  orgAdminSlice,
  statsSlice,
  activeDashboardSlice,
  productSlice,
  packageSlice,
  discountSlice,
  discountAssigneeSlice,
  invoiceSlice,
  orderSlice,
  transactionsSlice,
  templateCategory,
  templateSlice,
  minuteBookSlice,
  coApplicantSlice,
});

const reducerProxy = (state, action) => {
  if (action.type === "auth/logout") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: reducerProxy,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
