import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";

import ReactTable from "components/lib/Global/ReactTable";
import NoCompanies from "./NoCompanies";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";
import { getManagedCompaniesAsync } from "state/slices/companies";
import { capitalizeFirstLetter, classNames } from "utils";
import SearchInput from "components/lib/Shared/SearchInput";
import { useDebounce } from "use-debounce";
import { setCookie } from "utils/cookie";

const navigation = [
  { name: "1.Managed Companies", href: "/companies" },
  {
    name: "2. Corporate directors/shareholders",
    href: "/corporate-directors-shareholders",
  },
];

const CompaniesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, user } = useAuth0();
  const currentUser = useSelector((state) => state.userSlice);
  const { managedCompanies, getManagedCompanies } = useSelector(
    (state) => state.companiesSlice
  );

  const currentPathname = history.location.pathname;

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (isAuthenticated) {
      if (debouncedSearchTerm) setCurrentPage(1);
      dispatch(
        getManagedCompaniesAsync({
          limit: rowsPerPage,
          skip: (currentPage - 1) * rowsPerPage,
          search: debouncedSearchTerm,
          order: "asc",
        })
      );
    }
  }, [isAuthenticated, rowsPerPage, currentPage, debouncedSearchTerm]);

  const columns = [
    {
      Header: "Organization",
      accessor: "companyName1",
      Cell: ({ row: { original } }) => {
        if (CORPORATES.includes(original?.idType)) {
          return original.names.companyName || "N/A";
        }
        if (original?.company) {
          return (
            original.company?.registrationName ?? original.company.companyName1
          );
        }
        if (original?.registrationName) return original.registrationName;
        return "N/A";
      },
    },
    {
      Header: "Email",
      accessor: "createdBy",
      Cell: ({ row: { original } }) => {
        if (CORPORATES.includes(original?.idType)) {
          return (
            original?.identificationDetails?.emailAddress ??
            original?.contactPerson?.email
          );
        }
        return "N/A";
      },
    },
    {
      Header: "Contact Person",
      accessor: "contactPerson",
      Cell: ({ row: { original } }) => {
        if (CORPORATES.includes(original?.idType)) {
          return (
            original?.identificationDetails?.companyContactName ??
            capitalizeFirstLetter(original?.contactPerson?.names?.firstName)
          );
        }
        if (original?.createdBy?.names?.firstName) {
          return capitalizeFirstLetter(
            original?.createdBy?.names?.firstName ?? "N/A"
          );
        }
        return capitalizeFirstLetter(
          original?.company?.createdBy?.names?.firstName ?? "N/A"
        );
      },
    },
    {
      Header: "Status",
      Cell: ({ row: { original } }) => {
        let status = "N/A";
        if (CORPORATES.includes(original?.idType)) {
          status = original.status;
        }
        if (original?.status) status = original.status;
        if (original?.company?.status) status = original.company.status;
        if (status === "Active")
          return (
            <span className="inline-flex items-center justify-center w-full px-3 py-1 mx-auto text-sm font-medium text-white rounded-full bg-green-primary">
              {status}
            </span>
          );
        return status || "N/A";
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) =>
        row.original?.createdAt
          ? format(new Date(row.original?.createdAt), "yyyy/MM/dd")
          : "N/A",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        if (CORPORATES.includes(row?.original?.idType)) {
          return (
            <Link to={`/corporates/${row.original._id}/dashboard`}>View</Link>
          );
        }

        let company = row.original;
        if (row.original?.company) company = row.original?.company;
        const { role } = row.original || {};
        let status = row.original.status;
        if (company?.status) status = company.status;
        const createdByCurrentUser =
          company?.createdBy?._id === currentUser?._id;

        if (status === "Active") {
          return <Link to={`/companies/${company._id}/dashboard`}>View</Link>;
        }

        return (
          <TableActionMenu
            row={row}
            handleEdit={() => history.push(`/companies/${company._id}`)}
            hideDeleteBtn={!createdByCurrentUser}
            hideEditBtn={!createdByCurrentUser}
            disableEditBtn={!createdByCurrentUser}
            disableDeleteBtn={!createdByCurrentUser}
            showViewBtn
            showRecallBtn={role === 8 && status === "correction"}
            handleView={() => {
              // setCookie("role", role);
              history.push(`/companies/${company._id}/dashboard`);
            }}
          />
        );
      },
    },
  ];

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  return (
    <div className="mt-6">
      <div className="my-16">
        <h1 className="text-2xl font-medium text-primary">My Companies</h1>
        <p className="text-gray-800">
          A list of all your registered and verified companies.
        </p>
      </div>
      <div className="grid grid-cols-4 border-t border-gray">
        <div className="col-span-4 pr-8 md:col-span-1 pt-14">
          {navigation.map((item, index) => (
            <button
              key={`${item.name}_${index}`}
              className={classNames(
                item.href === currentPathname
                  ? "bg-gray-50  hover:text-indigo-700 hover:bg-white border-sflPrimary"
                  : "rounded-md pr-3 py-2 flex items-center text-gray-400 text-base font-medium disabled:cursor-not-allowed",
                "flex items-center pr-3 py-2 text-base font-medium hover:text-gray-900 hover:bg-gray-50"
              )}
              onClick={() => (item?.href ? history.push(item.href) : null)}
            >
              <span className="truncate">{item.name}</span>
            </button>
          ))}
        </div>
        <div className="col-span-4 md:border-l md:col-span-3 md:pl-11 pt-14 border-gray">
          <div className="flex items-center justify-between my-2">
            <div className="flex items-center space-x-4">
              <h2 className="font-semibold">Filter by:</h2>
            </div>
            <SearchInput value={searchTerm} handleOnChange={handleSearch} />
          </div>
          {managedCompanies.totalCount > 0 ? (
            <>
              <ReactTable
                columns={columns}
                data={managedCompanies.items}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                totalRows={managedCompanies.totalCount}
                setCurrentPage={setCurrentPage}
                paginate
                headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
                headingStyle="pl-4 text-left"
                dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
                dataRowCellStyle="pl-4 leading-snug py-2"
                isLoading={getManagedCompanies.status === "loading"}
              />
            </>
          ) : (
            <>
              {getManagedCompanies.status === "loading" ? (
                <div className="flex items-center justify-center z-10 mt-10">
                  <SpinnerIcon className="text-gray-400" />
                </div>
              ) : (
                <NoCompanies />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompaniesList;
