/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _pickBy from "lodash/pickBy";
import _forOwn from "lodash/forOwn";
import Cookies from "js-cookie";

import SectionDescription from "components/lib/Shared/SectionDescription";
import PreferredCompanyName from "./CompanyFormationComponents/PreferredCompanyName";
import SpecialInstructions from "./CompanyFormationComponents/SpecialInstructions";
import step1Validation from "./step1Validation";
import { updateCompanyAsync, updateCompanyState } from "state/slices/company";
import { handleFilesUpload } from "utils/filesUtils";
import {
  ChevronRightIcon,
  PlusIcon,
} from "components/lib/Shared/Icons/sflIcons";
import Button from "components/lib/Shared/Button";
import { Constants } from "config/constants";
import { getCompanyNameWithSuffix } from "utils/company";

const Step1 = ({ type = "PLC", handleBackBtn, goToNextStep }) => {
  const role = Cookies.get("role");

  // component state
  const [preferredNamesCount, setPreferredNamesCount] = useState(3);
  const [validationSchema, setValidationSchema] = useState(step1Validation());

  // hooks
  const dispatch = useDispatch();

  // redux selector
  const { companyFormation, company } = useSelector(
    (state) => state.companySlice
  );
  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const { nameValidity } = useSelector((state) => state.companySlice);

  // set preferred names count on component mount
  useEffect(() => {
    setPreferredNamesCount(companyFormation.step1.preferredNamesCount);
  }, [companyFormation.step1]);

  const addPreferredNameForm = () => {
    setPreferredNamesCount(preferredNamesCount + 1);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...companyFormation.step1,
        resolvedFields: companyFormation.resolvedFields,
      }}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={async (values, { setFieldError }) => {
        if (
          role &&
          ![
            Constants.COOKIE_ROLES.APPLICANT,
            Constants.COOKIE_ROLES.CO_APPLICANT,
          ].includes(role)
        ) {
          goToNextStep();
          return;
        }
        const apiErrors = {};
        // set name verification errors if any
        _forOwn(nameValidity, (name, key) => {
          if (!name.available && name.message) {
            apiErrors[key] = name.message;
            setFieldError(key, name.message);
          }
        });

        // if there are errors stop formik from submitting
        if (!_isEmpty(apiErrors)) return;

        const uploadedFiles = await handleFilesUpload(values.fileUploads);
        await dispatch(
          updateCompanyAsync({
            id: company._id,
            data: {
              ...values,
              companyName1WithSuffix: getCompanyNameWithSuffix({
                companyName1: values.companyName1,
                isCompanyName1Abbreviated: values.isCompanyName1Abbreviated,
                type: company.type,
              }),
              preferredNamesCount,
              fileUploads: {
                ...company.fileUploads,
                ..._pickBy(
                  values.fileUploads,
                  (file) => typeof file === "string"
                ),
                ...uploadedFiles,
              },
              tabName: "Company formation -> Step: 1 of 3",
            },
          })
        );
        dispatch(
          updateCompanyState({
            companyFormation: {
              ...companyFormation,
              step1: {
                ...values,
                preferredNamesCount,
                fileUploads: {
                  ...company.fileUploads,
                  ..._pickBy(
                    values.fileUploads,
                    (file) => typeof file === "string"
                  ),
                  ...uploadedFiles,
                },
              },
            },
          })
        );
        goToNextStep();
      }}
    >
      {({ values, isSubmitting }) => {
        useEffect(() => {
          setValidationSchema(
            step1Validation(
              values.companyName1,
              values.companyName2,
              values.companyName3,
              values.companyName4,
              values.companyName5
            )
          );
        }, [
          values.companyName1,
          values.companyName2,
          values.companyName3,
          values.companyName4,
          values.companyName5,
        ]);

        return (
          <Form>
            <fieldset disabled={role === Constants.COOKIE_ROLES.OFFICIAL}>
              <SectionDescription
                id="Step: 1 of 3"
                title="Submit your preferred business/company name"
                description="Please complete the relevant information in each of the following categories."
              />
              <div>
                <div className="mb-4">
                  {/* <span className="text-sm font-semibold">Note</span> */}

                  <div
                    className="bg-green-100 border-t-4 border-sflPrimary rounded-b text-teal-900 px-4 py-3 shadow-md"
                    role="alert"
                  >
                    <div className="flex-1 md:flex md:justify-between">
                      <div className="flex">
                        <div className="py-1">
                          <svg
                            className="fill-current h-6 w-6 text-teal-500 mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                          </svg>
                        </div>
                        <p className="text-sm font-medium">
                          It is mandatory to select three preferred names.
                          <br />
                          You can however select upto 5 preferred names. <br />
                          The first available name will automatically be
                          assigned to your business/company.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col space-y-3">
                  {Array.from(
                    { length: preferredNamesCount },
                    (_, i) => i + 1
                  ).map((nameCount) => {
                    return (
                      <div
                        className="shadow sm:rounded-md sm:overflow-hidden"
                        key={nameCount}
                      >
                        <div className="px-4 py-6 space-y-4 bg-white sm:p-6 ">
                          <Field
                            component={PreferredCompanyName}
                            name={`companyName${nameCount}`}
                            type={type}
                            nameCount={nameCount}
                            abbreviatedFlagName={`isCompanyName${nameCount}Abbreviated`}
                            title={`Preferred Name ${nameCount}`}
                            nameComments={
                              transformedComments?.[
                                `preferredCompanyNameCard_${nameCount}`
                              ]?.[`companyName${nameCount}`]
                            }
                            abbreviationComments={
                              transformedComments?.[
                                `preferredCompanyNameCard_${nameCount}`
                              ]?.[`isCompanyName${nameCount}Abbreviated`]
                            }
                          />

                          <Field
                            component={SpecialInstructions}
                            name={`specialInstructions${nameCount}`}
                            nameCount={nameCount}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {preferredNamesCount < 5 &&
                role !== Constants.COOKIE_ROLES.OFFICIAL && (
                  <>
                    <div className="mt-4">
                      <Button
                        onClick={addPreferredNameForm}
                        preIcon={PlusIcon}
                        variant="text"
                        className="inline-flex button button-back button-back-hover button-focus button-back-hover button-disabled button-outline button-focus bg-transparent text-sflPrimary"
                      >
                        Add Preferred Name
                      </Button>
                    </div>
                  </>
                )}
            </fieldset>
            <div className="flex justify-between py-3">
              <Button
                onClick={handleBackBtn}
                color="gray"
                disabled={isSubmitting}
              >
                Go back
              </Button>
              <Button
                type="submit"
                disabled={nameValidity?.isVerifying}
                isLoading={isSubmitting}
                postIcon={ChevronRightIcon}
              >
                <div className="ml-1.5 flex items-center">
                  {role === Constants.COOKIE_ROLES.OFFICIAL
                    ? "Next step"
                    : "Save and proceed"}
                </div>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Step1;
