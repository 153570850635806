/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _get from "lodash/get";

import { updateCompanyState, updateNestedCompanyState } from "./company";
import { updateRegisteredOfficeState } from "./registeredOffice";
import { updateOfficialsNestedField } from "./tables/companyOfficialsSlice";
import { updateBONestedField } from "./tables/beneficialOwnersTableSlice";
import formFieldsDataService from "services/formField";
import { updateUserNestedField } from "./user";
import { updateOrganizationNestedField } from "./organizations";
import { updatePartnerNestedField } from "./tables/partnersSlice";
import { updateProfessionalDetailsNestedField } from "./professionalDetails";
import { updateOrgAdminUserNestedField } from "./orgAdmin";

const handleResolveFieldEffect = (
  thunkAPI,
  resolvedFields = [],
  updateEffectKey = "",
  updateEffectPath = ""
) => {
  const applicationState = thunkAPI.getState();
  if (updateEffectKey === "updateOrganizationState") {
    const newNestedField = {
      ..._get(applicationState, updateEffectPath.split(".")),
      resolvedFields,
    };
    thunkAPI.dispatch(
      updateOrganizationNestedField({ newNestedField, updateEffectPath })
    );
  }
  if (updateEffectKey === "updateOrganizationPartnersState") {
    const newNestedField = {
      ..._get(applicationState, updateEffectPath.split(".")),
      resolvedFields,
    };
    thunkAPI.dispatch(
      updatePartnerNestedField({ newNestedField, updateEffectPath })
    );
    return;
  }
  if (updateEffectKey === "updateCompanyFormationState") {
    thunkAPI.dispatch(
      updateCompanyState({
        companyFormation: {
          ...applicationState.companySlice.companyFormation,
          resolvedFields,
        },
      })
    );
    return;
  }
  if (updateEffectKey === "updateCompanyRegisteredOffice") {
    thunkAPI.dispatch(updateRegisteredOfficeState({ resolvedFields }));
    return;
  }
  if (updateEffectKey === "updateCompanyOfficialState") {
    const newNestedField = {
      ..._get(applicationState, updateEffectPath.split(".")),
      resolvedFields,
    };
    thunkAPI.dispatch(
      updateOfficialsNestedField({ newNestedField, updateEffectPath })
    );
    return;
  }
  if (updateEffectKey === "updateBeneficialOfficialState") {
    const newNestedField = {
      ..._get(applicationState, updateEffectPath.split(".")),
      resolvedFields,
    };
    thunkAPI.dispatch(
      updateBONestedField({ newNestedField, updateEffectPath })
    );
    return;
  }
  if (updateEffectKey === "updateCompanyState") {
    thunkAPI.dispatch(updateCompanyState({ resolvedFields }));
    return;
  }
  if (updateEffectKey === "updateNestedCompanyState") {
    const newNestedField = {
      ..._get(applicationState, updateEffectPath.split(".")),
      resolvedFields,
    };
    thunkAPI.dispatch(
      updateNestedCompanyState({ newNestedField, updateEffectPath })
    );
  }
  if (updateEffectKey === "updateUserState") {
    const newNestedField = {
      ..._get(applicationState, updateEffectPath.split(".")),
      resolvedFields,
    };
    thunkAPI.dispatch(
      updateUserNestedField({ newNestedField, updateEffectPath })
    );
    return;
  }

  if (updateEffectKey === "updateProfessionalState") {
    const newNestedField = {
      ..._get(applicationState, updateEffectPath.split(".")),
      resolvedFields,
    };
    thunkAPI.dispatch(
      updateProfessionalDetailsNestedField({ newNestedField, updateEffectPath })
    );
    return;
  }

  if (updateEffectKey === "updateUserStateForOrgAdmin") {
    const newNestedField = {
      ..._get(applicationState, updateEffectPath.split(".")),
      resolvedFields,
    };
    thunkAPI.dispatch(
      updateOrgAdminUserNestedField({ newNestedField, updateEffectPath })
    );
    return;
  }
};

export const resolveFields = createAsyncThunk(
  "form-fields/resolve-fields",
  async ({ data, updateEffectKey = "", updateEffectPath = "" }, thunkAPI) => {
    const res = await formFieldsDataService.resolve(data);
    handleResolveFieldEffect(
      thunkAPI,
      res.data?.resolvedFields,
      updateEffectKey,
      updateEffectPath
    );
    return res.data;
  }
);

export const invalidateFields = createAsyncThunk(
  "form-fields/invalidate-fields",
  async ({ data, updateEffectKey = "", updateEffectPath = "" }, thunkAPI) => {
    const res = await formFieldsDataService.invalidate(data);
    handleResolveFieldEffect(
      thunkAPI,
      res.data?.resolvedFields,
      updateEffectKey,
      updateEffectPath
    );
    return res.data;
  }
);

const resolveFieldsSlice = createSlice({
  name: "Resolve fields",
  initialState: {},
  reducers: {},
  extraReducers: {
    [resolveFields.fulfilled]: (state, action) => {},
    [invalidateFields.fulfilled]: (state, action) => {},
  },
});

export default resolveFieldsSlice.reducer;
