import Cookies from "js-cookie";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { Constants } from "config/constants";
import { useSelector } from "react-redux";
import { classNames } from "utils";

export default function Sidebar() {
  const history = useHistory();

  const { stats } = useSelector((state) => state.statsSlice);
  const {
    companies = {},
    profiles = {},
    corporateDirectorsShareholdersCount,
  } = stats;

  const { pathname } = history.location;
  const role = Cookies.get("role");

  const navigation = useMemo(() => {
    return [
      {
        label: "Profiles",
        href: "/profiles?status=Pending+Review&status=Pending+Correction&status=Verified&status=Deferred",
        totalCount: Object.values(profiles).reduce(
          (accumulator, value) => accumulator + value,
          0
        ),
      },
      role === Constants.COOKIE_ROLES.SUPER_ADMIN
        ? {
            label: "Submitted",
            href: "/applications/pending?status=Submission&status=Submission+II&status=Submission+Corrections",
            totalCount:
              (companies?.brsReview ?? 0) +
              (companies?.brsReviewII ?? 0) +
              (companies?.brsCorrections ?? 0) +
              (companies?.pendingIncorporation ?? 0),
          }
        : {
            label: "Submitted",
            href: "/applications/pending?status=Admin+Review&status=Admin+Review+II&status=Admin+Corrections",
            totalCount:
              (companies?.adminReview ?? 0) +
              (companies?.adminReviewII ?? 0) +
              (companies?.adminCorrections ?? 0),
          },
      {
        label: "Signature",
        href: "/applications/signature?status=Signature&status=Signature+Corrections&status=Signature Deferred",
        totalCount:
          (companies?.signature ?? 0) + (companies?.signatureCorrections ?? 0),
      },
      role === Constants.COOKIE_ROLES.SUPER_ADMIN
        ? {
            label: "BRS Submitted",
            href: "/applications/brs-submission?status=BRS+Review&status=BRS+Review+II&status=BRS+Corrections&status=Pending+Incorporation&status=Incorporation Deferred&status=Vetting",
            totalCount:
              (companies?.brsReview ?? 0) +
              (companies?.brsReviewII ?? 0) +
              (companies?.brsCorrections ?? 0) +
              (companies?.pendingIncorporation ?? 0),
          }
        : null,
      {
        label: "Completed",
        href: "/applications/all-applications",
        totalCount: companies?.active ?? 0,
      },
      {
        label: "Corporates",
        href: "/corporates",
        totalCount: corporateDirectorsShareholdersCount,
      },
      {
        label: "Draft Applications",
        href: "/applications/draft",
        totalCount:
          (companies?.draft ?? 0) +
          (companies?.["directors review"] ?? 0) +
          (companies?.["directors corrections"] ?? 0) +
          (companies?.["directorsDeferred"] ?? 0),
      },
    ].filter(Boolean);
  }, [profiles, companies]);

  return (
    <aside className="px-2 py-6 mt-8 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
      <div className="space-y-10">
        <aside className="px-2 py-6 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <nav className="flex flex-col space-y-1">
            {navigation.map((item) => (
              <button
                key={item.label}
                className={classNames(
                  item.href.includes(pathname)
                    ? "bg-gray-50 text-sflPrimary hover:bg-white"
                    : "rounded-md px-3 py-2 text-tertiary flex items-center text-base font-medium",
                  "flex items-center px-3 py-2 text-base font-medium hover:text-sflPrimary hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300"
                )}
                onClick={() => history.push(item.href)}
                disabled={item.disabled}
              >
                <p className="relative">
                  {item.label}
                  {item?.totalCount > 0 && (
                    <span className="absolute top-[-12px] right-[-20px] flex items-center justify-center h-4 p-1 text-white bg-sflPrimary rounded-full">
                      {item.totalCount}
                    </span>
                  )}
                </p>
              </button>
            ))}
          </nav>
        </aside>
      </div>
    </aside>
  );
}
