import identificationValidation from "../../commonValidationSchema/identificationValidation";
import residentialDetailsValidation from "../../commonValidationSchema/residentialDetailsValidation";
import uploadDocumentsValidation from "../../commonValidationSchema/uploadDocumentsValidation";
import shareAllocationValidation from "./shareAllocationValidation";

/**
 * This returns an object composed of conditional initial values using `yup` schema
 * for identification, residential details, share allocation table and upload documents forms
 * of `Shareholder` and `Director Shareholder` officials.
 *
 * @param {string} idType The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {string} isNonResident Conditional string value `'No' || 'Yes` of the current official.
 * @param {array} documentData The array of objects related to file uploads.
 * @param {array} shares The array of allotted shares to current official.
 * @return {object} This returns object containing initial values.
 * @example
 *  {}
 */

const getInitialValues = ({
  idType,
  isNonResident = null,
  documentData,
  shares,
}) => {
  return {
    ...identificationValidation(idType).schemaInitialValues,
    ...residentialDetailsValidation({ idType, isNonResident })
      .schemaInitialValues,
    ...uploadDocumentsValidation(documentData).schemaInitialValues,
    ...shareAllocationValidation(shares).schemaInitialValues,
  };
};

export default getInitialValues;
