import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (params) => axios.get(`/discounts?${getQueryString(params)}`);

const create = (data) => axios.post("/discounts", data);

const update = (discountId, data) =>
  axios.put(`/discounts/${discountId}`, data);

const remove = (discountId) => axios.delete(`/discounts/${discountId}`);

const generateCode = () => axios.get(`/discounts/generate-code`);

const verifyDiscountCode = (params) =>
  axios.get(`/discounts/verify-code?${getQueryString(params)}`);

const discountService = {
  getAll,
  create,
  update,
  remove,
  generateCode,
  verifyDiscountCode,
};

export default discountService;
