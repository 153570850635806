/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _set from "lodash/set";

import userDataService from "services/user";

const initialState = {
  generalInformation: {},
  postalAddress: {},
  electronicAddress: {},
  residentialAddressDetails: {},
  fileUploads: {},
  updateUserStatus: {
    status: "idle",
    error: false,
    loaded: false,
  },
};

export const updateUser = createAsyncThunk(
  "users/update",
  async ({ id, data }, thunkAPI) => {
    const res = await userDataService.update(id, data);
    thunkAPI.dispatch(
      updateUserState({
        isOnboarded: res.data.isOnboarded,
        isProfileApprovedByUser: res.data.isProfileApprovedByUser,
        linkedCompanies: res.data.linkedCompanies,
        isProfessional: res.data.isProfessional,
        attachProfessionsDetails: res.data.attachProfessionsDetails,
      })
    );
    return res.data;
  }
);

export const updateLinkedCompanies = createAsyncThunk(
  "users/updateLinkedCompanies",
  async ({ data, updatedLinkedCompanies }, thunkAPI) => {
    const res = await userDataService.updateLinkedCompany(data);
    thunkAPI.dispatch(
      updateUserState({ linkedCompanies: updatedLinkedCompanies })
    );
    return res.data;
  }
);

export const sendCorrectionNotification = createAsyncThunk(
  "users/sendCorrectionNotification",
  async ({ data }) => {
    const res = await userDataService.sendCorrectionNotification(data);
    return res.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserState: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateUserNestedField: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
    reset: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [updateUser.pending]: (state) => {
      state.updateUserStatus.status = "loading";
      state.updateUserStatus.error = false;
    },
    [updateUser.fulfilled]: (state) => {
      state.updateUserStatus.status = "idle";
      state.updateUserStatus.error = false;
    },
    [updateUser.rejected]: (state, action) => {
      state.updateUserStatus.status = "failed";
      state.updateUserStatus.error = action.payload.messageText;
    },
  },
});

export const { updateUserState, updateUserNestedField, reset } =
  userSlice.actions;
const { reducer } = userSlice;

export default reducer;
