import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useExpanded, useTable } from "react-table";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";
import { Menu } from "@headlessui/react";
import { EyeIcon, PencilAltIcon } from "@heroicons/react/outline";

import { getFullName } from "utils";
import { Constants } from "config/constants";
import Pagination from "components/lib/Shared/Pagination";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";
import CompanyOfficialSubRow from "../../BusinessDetails/CompanyOfficialSubRow/index";
import ChangeOfParticularsSubRow from "./ChangeOfParticularsSubRow";
import TableActionMenuWrapper from "components/lib/Shared/TableActionMenuWrapper";

const ManageChangeOfParticularsTable = ({
  companyId,
  data,
  totalCount,
  currentTab,
  isLoading,
  typeOfTable = "",
}) => {
  const dispatch = useDispatch();
  // const [expandedRow, setExpandedRow] = useState({});
  const [initialRows, setInitialRows] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [actionType, setActionType] = useState("");
  const [rowsPerPage] = useState(10);

  const columns = useMemo(() => {
    const tempTabs = [
      {
        Header: () => "Full Name",
        id: "Name",
        accessor: "fullName",
        Cell: ({ row: { original } }) => {
          const { names, fileUploads } = original;
          const fullName = getFullName(names) || "N/A";
          if (
            CORPORATES.includes(original.idType) ||
            (original.designation === "Joint Shareholder" &&
              original.isDirector !== "Yes")
          ) {
            return <h1>{fullName}</h1>;
          }
          return (
            <div className="flex items-center space-x-1">
              <div className={`flex-shrink-0`}>
                {fileUploads?.passportPhoto ? (
                  <img
                    className="w-8 h-8 rounded-full"
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${fileUploads?.passportPhoto}`}
                    alt={fullName}
                  />
                ) : (
                  <div className="w-8 h-8 rounded-full">
                    <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                      <AvatarIcon />
                    </div>
                  </div>
                )}
              </div>
              <div className="px-3">
                <h1 className="">{fullName}</h1>
              </div>
            </div>
          );
        },
        // We can render something different for subRows
        SubCell: (cellProps) => <>{cellProps.value}</>,
      },
      {
        Header: () => "ID Type",
        id: "ID Type",
        accessor: "idType",
      },
      ...(typeOfTable === "recent-updates"
        ? [
            {
              Header: () => "Brs Ref",
              id: "BRS REF",
              Cell: ({ row }) =>
                row.original?.brsRef ? row.original?.brsRef : "-",
            },
            {
              Header: () => "Date",
              id: "DATE",
              Cell: ({ row }) =>
                row.original?.createdAt
                  ? format(new Date(row.original?.createdAt), "yyyy/MM/dd")
                  : "N/A",
            },
          ]
        : []),
      {
        Header: () => "Designation",
        id: "Designation",
        Cell: ({ row: { original } }) => {
          return Array.isArray(original.fullName)
            ? "Joint Shareholder"
            : original.isDirector === "Yes"
            ? "Director"
            : original.designation ?? "";
        },
      },
      {
        Header: () => "Status",
        id: "manageModuleStatus",
        Cell: ({ row: { original } }) =>
          original?.manageModuleStatus ? original?.manageModuleStatus : "-",
      },
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <ActionButtons
              actionType={actionType}
              setActionType={setActionType}
              row={row}
            />
          );
        },
      },
    ];
    return tempTabs;
  }, []);

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => {
      if (!actionType)
        return (
          <tr>
            <td></td>
          </tr>
        );
      if (actionType === "change") {
        return (
          <ChangeOfParticularsSubRow
            row={row}
            visibleColumns={visibleColumns}
          />
        );
      } else {
        return (
          <CompanyOfficialSubRow
            row={row}
            rowProps={rowProps}
            visibleColumns={visibleColumns}
            currentTab={currentTab}
            initialRows={initialRows}
          />
        );
      }
    },
    [currentTab, initialRows, actionType]
  );

  return (
    <div className="w-full bg-white shadow">
      <div className="w-full">
        <ManageTable
          columns={columns}
          data={data}
          totalCount={totalCount}
          setCurrentPage={setCurrentPage}
          paginate
          companyId={companyId}
          dataRowCellStyle="whitespace-normal"
          renderRowSubComponent={renderRowSubComponent}
          actionType={actionType}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

const ActionButtons = ({ actionType, setActionType, row }) => {
  return (
    <div>
      <TableActionMenuWrapper>
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              onClick={() => {
                row.toggleRowExpanded();
                setActionType("view");
              }}
              className={`${
                active ? "bg-violet-500 text-white" : "text-gray-900"
              } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
            >
              {row.isExpanded ? (
                <>
                  <span className="ml-4">Close</span>
                </>
              ) : (
                <>
                  <EyeIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                  View
                </>
              )}
            </button>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              onClick={() => {
                row.toggleRowExpanded();
                setActionType("change");
              }}
              className={`${
                active ? "bg-violet-500 text-white" : "text-gray-900"
              } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
            >
              <PencilAltIcon className="w-5 h-5 mr-2" aria-hidden="true" />
              Change
            </button>
          )}
        </Menu.Item>
      </TableActionMenuWrapper>
    </div>
  );
};

export default ManageChangeOfParticularsTable;

const ManageTable = ({
  columns,
  data,
  renderRowSubComponent,
  headingStyle,
  headingRowStyle,
  dataRowStyle,
  dataRowCellStyle,
  hideColumnsRow,
  isLoading,
  currentPage,
  rowsPerPage = 10,
  showSnColumn = true,
  totalRows,
  setCurrentPage,
  paginate,
}) => {
  const tableColumns = useMemo(() => {
    if (!showSnColumn) {
      return columns;
    }

    return [
      {
        Header: "Sn",
        accessor: "sn",
      },
      ...columns,
    ];
  }, [showSnColumn, columns]);

  const tableData = useMemo(() => {
    return showSnColumn
      ? data.map((item, index) => {
          return {
            ...item,
            sn:
              item.id === "total"
                ? ""
                : currentPage === undefined
                ? index + 1
                : index + 1 + (currentPage - 1) * rowsPerPage,
          };
        })
      : data;
  }, [showSnColumn, data, currentPage, rowsPerPage]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    visibleColumns,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      autoResetExpanded: false,
    },
    useExpanded // We can useExpanded to track the expanded state
    // for sub components too!
  );

  // Main Table here
  return (
    <div className="">
      <div className="w-full overflow-auto">
        <table className="min-w-full min-h-full" {...getTableProps()}>
          {!hideColumnsRow && (
            <thead className={"bg-[#31415d] align-top"}>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={headingRowStyle}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className={twMerge(
                        "px-6 py-3 text-left text-sm font-medium tracking-wider text-slate-50",
                        headingStyle,
                        column.headingStyle
                      )}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody
            className="align-top bg-white relative"
            {...getTableBodyProps()}
          >
            {rows.map((row, i) => {
              row["toggleAllRowsExpanded"] = toggleAllRowsExpanded;
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                // Use a React.Fragment here so the table markup is still valid
                <React.Fragment key={rowProps.key}>
                  <tr
                    {...rowProps}
                    className={twMerge(
                      dataRowStyle,
                      data.length === row.index + 1 ? "border-b-0" : ""
                    )}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className={twMerge(
                            "px-6 py-4 text-sm text-primary",
                            dataRowCellStyle,
                            cell.column.className
                          )}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {/* We could pass anything into this */}
                  {row.isExpanded &&
                    renderRowSubComponent({
                      row,
                      rowProps,
                      visibleColumns,
                    })}{" "}
                  {/* Remove actionType here */}
                </React.Fragment>
              );
            })}
            {isLoading && (
              <div
                className="absolute inset-0 flex items-center justify-center bg-gray-200/50 z-10"
                id="loading-overlay"
              >
                <SpinnerIcon textColor="text-gray-400" />
              </div>
            )}
          </tbody>
        </table>
      </div>
      {paginate && totalRows > Constants.PAGINATION_LIMIT && (
        <Pagination
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          setCurrentPage={setCurrentPage}
          className="mt-0"
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
