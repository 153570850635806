import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { useExpanded, useTable } from "react-table";
import SpinnerIcon from "../Shared/Icons/SpinnerIcon";
import Pagination from "../Shared/Pagination";
import { Constants } from "config/constants";

// A simple way to support a renderRowSubComponent is to make a render prop
// This is NOT part of the React Table API, it's merely a rendering
// option we are creating for ourselves in our table renderer
function Table({
  columns,
  data,
  renderRowSubComponent,
  headingStyle,
  headingRowStyle,
  dataRowStyle,
  dataRowCellStyle,
  hideColumnsRow,
  isLoading,
  currentPage,
  rowsPerPage = 10,
  showSnColumn = true,
  totalRows,
  setCurrentPage,
  paginate,
  tableColor = "default",
}) {
  const tableColumns = useMemo(() => {
    if (!showSnColumn) {
      return columns;
    }

    return [
      {
        Header: "Sn",
        accessor: "sn",
      },
      ...columns,
    ];
  }, [showSnColumn, columns]);

  const tableData = useMemo(() => {
    return showSnColumn
      ? data.map((item, index) => {
          return {
            ...item,
            sn:
              item.id === "total"
                ? ""
                : currentPage === undefined
                ? index + 1
                : index + 1 + (currentPage - 1) * rowsPerPage,
          };
        })
      : data;
  }, [showSnColumn, data, currentPage, rowsPerPage]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    visibleColumns,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      autoResetExpanded: false,
    },
    useExpanded // We can useExpanded to track the expanded state
    // for sub components too!
  );

  //Main Table here
  return (
    <div className="">
      <div className="w-full overflow-auto">
        <table className="min-w-full min-h-full" {...getTableProps()}>
          {!hideColumnsRow && (
            <thead
              className={
                tableColor === "manage-table"
                  ? "bg-[#31415d] align-top"
                  : "bg-[#EDF0F5] align-top"
              }
            >
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={headingRowStyle}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className={twMerge(
                        "px-6 py-3 text-left text-sm font-medium tracking-wider",
                        tableColor === "manage-table"
                          ? "text-slate-50"
                          : "text-primary",
                        headingStyle,
                        column.headingStyle
                      )}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody
            className="align-top bg-white relative"
            {...getTableBodyProps()}
          >
            {rows.map((row, i) => {
              row["toggleAllRowsExpanded"] = toggleAllRowsExpanded;
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                // Use a React.Fragment here so the table markup is still valid
                <React.Fragment key={rowProps.key}>
                  <tr
                    {...rowProps}
                    className={twMerge(
                      dataRowStyle,
                      data.length === row.index + 1 ? "border-b-0" : ""
                    )}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className={twMerge(
                            "px-6 py-4 text-sm text-primary",
                            dataRowCellStyle,
                            cell.column.className
                          )}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {/* We could pass anything into this */}
                  {row.isExpanded &&
                    renderRowSubComponent({ row, rowProps, visibleColumns })}
                </React.Fragment>
              );
            })}
            {isLoading && (
              <div
                className="absolute inset-0 flex items-center justify-center bg-gray-200/50 z-10"
                id="loading-overlay"
              >
                <SpinnerIcon className="text-gray-400" />
              </div>
            )}
          </tbody>
        </table>
      </div>
      {paginate && totalRows > Constants.PAGINATION_LIMIT && (
        <Pagination
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          setCurrentPage={setCurrentPage}
          className="mt-0"
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default Table;
