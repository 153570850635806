import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import _isEmpty from "lodash/isEmpty";
import _pickBy from "lodash/pickBy";
import Cookies from "js-cookie";
import { PlusIcon } from "components/lib/Shared/Icons/sflIcons";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import ProfileLabel from "components/lib/Shared/ProfileLabel";
import Label from "components/lib/Shared/Label";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { Constants } from "config/constants";
import options from "../PLCTABS/SFLdata/selectOptions";
import { getFormFields, getFullName } from "utils";
import { updateUser } from "state/slices/user";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import Button from "components/lib/Shared/Button";
import { useToast } from "hooks/useToast";
import { closeModal, openModal } from "state/slices/modals";
import {
  getProfessionalDetailsAsync,
  resetProfessionalDetailsSlice,
} from "state/slices/professionalDetails";
import ProfessionalDetail from "./ProfessionalDetail";
import Header from "./Header";
import SystemAdminProfile from "./SystemAdminProfile";

const defaultRoles = ["Company Secretary", "Advocate", "Accountant"];

export default function ProfileComponent() {
  // redux selector
  const userSlice = useSelector((state) => state.userSlice);
  const { transformedComments } = useSelector(
    (state) => state.commentsSlice.profileComments
  );
  const {
    idType,
    isNonResident,
    names,
    identificationDetails,
    postalAddress,
    electronicAddress,
    residentialAddressDetails,
    fileUploads,
    isProfessional,
    attachProfessionsDetails,
    updateUserStatus,
    profileType,
  } = userSlice;

  const dispatch = useDispatch();
  const cookieRole = Cookies.get("role");
  const professionalDetails = useSelector(
    (state) => state.professionalDetailsSlice.items
  );

  const fullName = getFullName(names);
  const { toast } = useToast(5000);

  useEffect(() => {
    if (userSlice?._id) {
      dispatch(getProfessionalDetailsAsync({ user: userSlice?._id }));
    }
    return () => {
      dispatch(resetProfessionalDetailsSlice());
    };
  }, [userSlice?._id]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ fileUploads, isProfessional, attachProfessionsDetails }}
      onSubmit={() => null}
    >
      {({ values, setFieldValue }) => {
        const [isLoading, setIsLoading] = useState(false);
        const [selectedDetails, setSelectedDetail] = useState(null);
        const [addNewDetail, setAddNewDetail] = useState(false);

        const handleChange = async (addDetails) => {
          if (addDetails) {
            setFieldValue("attachProfessionsDetails", true);
          } else {
            dispatch(
              openModal({
                modalName: "deny_professional_data_confirmation_modal",
              })
            );
          }
        };

        const handleDenyProfessionalData = async () => {
          try {
            setIsLoading(true);
            await dispatch(
              updateUser({
                id: userSlice._id,
                data: {
                  isProfessional: false,
                  attachProfessionsDetails: false,
                },
              })
            );
            setFieldValue("attachProfessionsDetails", false);
            dispatch(closeModal("deny_professional_data_confirmation_modal"));
          } catch (error) {
            toast(
              "error",
              error?.message ?? "Something went wrong please try again."
            );
          } finally {
            setIsLoading(false);
          }
        };

        const handleAddNewProfessionalDetail = () => {
          setSelectedDetail(null);
          setAddNewDetail(true);
        };

        const handleEditProfessionalDetail = (professionalDetail) => {
          setSelectedDetail(professionalDetail);
          setAddNewDetail(false);
        };

        const assignedRoles = professionalDetails.map(
          ({ capacity }) => capacity
        );
        const capacities = defaultRoles.filter(
          (capacity) => !assignedRoles.includes(capacity)
        );

        if (cookieRole === Constants.COOKIE_ROLES.SYSTEM_ADMIN) {
          return (
            <SystemAdminProfile userSlice={userSlice} fullName={fullName} />
          );
        }

        return (
          <Form>
            <ModalWrapper
              name="deny_professional_data_confirmation_modal"
              title="The invite will be deleted."
              titleColor="text-red-500"
              maxWidth="sm:max-w-2xl"
            >
              <div className="my-4 text-lg">
                Are you sure you want to proceed with not having your
                professional details attached to your profile?
              </div>
              <hr />
              <div className="flex justify-between mt-4">
                <Button
                  disabled={isLoading}
                  onClick={() =>
                    dispatch(
                      closeModal("deny_professional_data_confirmation_modal")
                    )
                  }
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button
                  isLoading={isLoading}
                  onClick={handleDenyProfessionalData}
                  variant="danger"
                  loadingText="Confirming..."
                >
                  I confirm
                </Button>
              </div>
            </ModalWrapper>
            <div className="px-2 space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
              <div className="px-6 my-4">
                <Header {...userSlice} />
                <div>
                  <div className="grid gap-6">
                    <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Personal details
                      </h3>
                      <div className="flex flex-col space-y-2">
                        <ProfileLabel
                          label="Full names"
                          value={fullName || "N/A"}
                        />
                        <ProfileLabel
                          label="Former Name"
                          value={names?.formerName || "N/A"}
                        />
                        {idType &&
                          Constants.FORM_FIELDS?.[
                            idType
                          ]?.identificationDetails?.map((formField) => {
                            let value =
                              identificationDetails?.[formField.key] ?? "N/A";
                            if (formField.isDate) {
                              if (value === "N/A") return;
                              value = format(new Date(value), "yyyy/MM/dd");
                            }

                            if (formField.isCountry && value) {
                              value =
                                options.countries.find(
                                  (country) => country.value === value
                                )?.label ?? "N/A";
                            }

                            return (
                              <ProfileLabel
                                label={formField.label}
                                value={value}
                                key={formField.key}
                              />
                            );
                          })}
                      </div>
                    </div>
                    <div>
                      {[
                        Constants.PROFILE_TYPES.FULL,
                        Constants.PROFILE_TYPES.PROFESSIONAL,
                      ].includes(profileType) ? (
                        <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                          <h3 className="mb-4 text-lg font-medium leading-6 text-gray-900">
                            Postal details
                          </h3>
                          <div className="flex flex-col space-y-2">
                            {idType === "Kenyan Citizen" && (
                              <ProfileLabel
                                key="isNonResident"
                                label="Non-Resident"
                                value={isNonResident}
                              />
                            )}
                            {idType &&
                              getFormFields({
                                idType:
                                  isNonResident && isNonResident === "Yes"
                                    ? "Foreigner"
                                    : idType,
                                formType: "postalAddress",
                                formValues: postalAddress,
                              })?.map((formField) => (
                                <ProfileLabel
                                  key={formField.key}
                                  label={formField.name}
                                  value={formField.value}
                                />
                              ))}
                          </div>
                        </div>
                      ) : null}
                      <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                        <h3 className="my-4 text-lg font-medium leading-6 text-gray-900">
                          Electronic address
                        </h3>
                        <div className="flex flex-col space-y-2">
                          <ProfileLabel
                            label="Email address"
                            value={electronicAddress?.emailAddress ?? "N/A"}
                          />
                          <ProfileLabel
                            label="Mobile number"
                            value={electronicAddress?.mobileNumber ?? "N/A"}
                          />
                        </div>
                      </div>
                      {[
                        Constants.PROFILE_TYPES.FULL,
                        Constants.PROFILE_TYPES.PROFESSIONAL,
                      ].includes(profileType) ? (
                        <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                          <h3 className="my-4 text-lg font-medium leading-6 text-gray-900">
                            Registered address
                          </h3>
                          <div className="flex flex-col space-y-2">
                            {idType &&
                              getFormFields({
                                idType:
                                  isNonResident && isNonResident === "Yes"
                                    ? "Foreigner"
                                    : idType,
                                formType: "residentialAddressDetails",
                                formValues: residentialAddressDetails,
                              })?.map((formField) => (
                                <ProfileLabel
                                  key={formField.name}
                                  label={formField.key}
                                  value={formField.value}
                                />
                              ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {isProfessional && !values?.attachProfessionsDetails && (
                      <div className="px-4 space-y-2 sm:px-6">
                        <span>
                          Do you want your professional details attached to your
                          profile?
                        </span>
                        <div className="flex space-x-8 ml-4">
                          <div className="flex items-center space-x-2">
                            <Field
                              type="radio"
                              name="attachProfessionsDetails"
                              id="yesAttachProfessionsDetails"
                              checked={
                                values?.attachProfessionsDetails === true
                              }
                              onChange={() => handleChange(true)}
                            />
                            <Label htmlFor="yesAttachProfessionsDetails">
                              YES
                            </Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <Field
                              type="radio"
                              name="attachProfessionsDetails"
                              id="notAttachProfessionsDetails"
                              checked={
                                values?.attachProfessionsDetails === false
                              }
                              onChange={() => handleChange(false)}
                            />
                            <Label htmlFor="notAttachProfessionsDetails">
                              NO
                            </Label>
                          </div>
                        </div>
                      </div>
                    )}
                    {values?.attachProfessionsDetails &&
                      professionalDetails?.length > 0 && (
                        <div className="px-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                          <h3 className="my-4 text-lg font-medium leading-6 text-gray-900">
                            Professional qualifications
                          </h3>
                          {professionalDetails?.map(
                            (professionalDetail, professionalDetailIndex) => (
                              <div
                                key={`professionalDetail_${professionalDetail?._id}`}
                              >
                                <ProfessionalDetail
                                  professionalDetail={professionalDetail}
                                  isViewMode={
                                    selectedDetails?._id !==
                                    professionalDetail?._id
                                  }
                                  isEditMode={
                                    selectedDetails?._id ===
                                    professionalDetail?._id
                                  }
                                  handleEdit={handleEditProfessionalDetail}
                                  comments={
                                    transformedComments.professionalDetail
                                  }
                                />
                                {professionalDetailIndex <
                                  professionalDetails?.length - 1 && <hr />}
                              </div>
                            )
                          )}
                          {addNewDetail ? (
                            <ProfessionalDetail
                              isViewMode={false}
                              setAddNewDetail={setAddNewDetail}
                              capacities={capacities}
                            />
                          ) : (
                            professionalDetails?.length < 3 && (
                              <Button
                                type="button"
                                onClick={handleAddNewProfessionalDetail}
                                className="flex ml-auto mr-auto mt-4 uppercase"
                                variant="text"
                                preIcon={PlusIcon}
                              >
                                Add New Qualification
                              </Button>
                            )
                          )}
                        </div>
                      )}
                    {values?.attachProfessionsDetails &&
                      professionalDetails?.length === 0 &&
                      updateUserStatus?.status !== "loading" && (
                        <div className="px-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                          <ProfessionalDetail
                            isViewMode={false}
                            setAddNewDetail={setAddNewDetail}
                            capacities={capacities}
                          />
                        </div>
                      )}
                    {!_isEmpty(fileUploads) && (
                      <div>
                        <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            File uploads
                          </h3>
                          <div className="flex flex-col space-y-2">
                            <Field
                              name="fileUploads"
                              component={FileUploadForm}
                              documentData={getDocumentData(idType)}
                              onlyPreview
                              showDocumentLabel
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
