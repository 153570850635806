import { useState } from "react";
import { Formik, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import { getBusinessDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import { addComment, editComment } from "state/slices/comment";
import Cookies from "js-cookie";
import { Constants } from "config/constants";
import CommentsList from "components/lib/Global/CommentsList";
import Button from "components/lib/Shared/Button";
import { downloadBusinessDocumentsAsync } from "state/slices/company";
import { useToast } from "hooks/useToast";
import useFullscreenToggle from "hooks/useFullscreenToggle";

function FileUploads() {
  const [isDownloading, setIsDownloading] = useState(false);
  const { isFullscreen, ToggleFullscreenButton } = useFullscreenToggle();
  const role = Cookies.get("role");
  const { company } = useSelector((state) => state.companySlice);
  const { comments } = useSelector((state) => state.commentsSlice);

  const { toast } = useToast();
  const dispatch = useDispatch();

  const fileUploadComments = comments.filter(
    (comment) => comment.formName === "uploadDocuments"
  );

  const { fileUploads, preSignedBusinessDocuments } = company;

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            company: company._id,
          },
        })
      );
    }
  };

  return (
    <div className="flex flex-col w-full mt-6">
      {fileUploadComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 bg-white">
          <CommentsList
            comments={fileUploadComments}
            toggleCommentStatus={role === Constants.COOKIE_ROLES.SUPER_ADMIN}
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {fileUploadComments.length} of {fileUploadComments.length}
          </p>
        </div>
      )}
      <div
        className={`${
          isFullscreen
            ? `fixed inset-0 bg-white px-4 top-[7.5rem] z-50`
            : "w-full"
        }`}
      >
        <ToggleFullscreenButton />
        <div
          className={`flex mt-4 justify-between overflow-auto ${
            isFullscreen ? "h-[80vh] overflow-auto" : ""
          }`}
        >
          <Formik
            enableReinitialize
            initialValues={{
              fileUploads: preSignedBusinessDocuments,
            }}
          >
            {() => {
              return (
                <>
                  {!_isEmpty(preSignedBusinessDocuments) ? (
                    <div className="px-4 flex-1 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6 grow mr-4">
                      <div className="flex flex-col space-y-2">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Unsigned
                        </h3>
                      </div>
                      <div className="space-y-2">
                        <Field
                          name="fileUploads"
                          component={FileUploadForm}
                          documentData={getBusinessDocumentData()}
                          onlyPreview
                          showDocumentLabel
                        />
                      </div>
                    </div>
                  ) : (
                    "No documents found"
                  )}
                </>
              );
            }}
          </Formik>
          <Formik
            enableReinitialize
            initialValues={{ fileUploads }}
            onSubmit={() => null}
          >
            {() => {
              const handleDownloadDocuments = async () => {
                try {
                  setIsDownloading(true);
                  const businessDocuments = [];

                  getBusinessDocumentData().map((businessDocument) => {
                    const fileKey = company.fileUploads[businessDocument.name];
                    if (fileKey) {
                      businessDocuments.push({
                        key: fileKey,
                        name: `${businessDocument.label}.${fileKey
                          .split(".")
                          .pop()}`,
                      });
                    }
                  });

                  await Promise.all(
                    businessDocuments.map(async (businessDocument) => {
                      return dispatch(
                        downloadBusinessDocumentsAsync({
                          companyId: company._id,
                          businessDocument,
                        })
                      );
                    })
                  );
                } catch (error) {
                  toast(
                    "error",
                    error?.message ?? "Something went wrong, please try again"
                  );
                } finally {
                  setIsDownloading(false);
                }
              };

              return (
                <div className="px-4 flex-1 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6 grow">
                  <div className="flex relative">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Signed
                    </h3>
                    {role === Constants.COOKIE_ROLES.SUPER_ADMIN && (
                      <Button
                        className="ml-auto absolute right-0 top-0"
                        onClick={handleDownloadDocuments}
                        isLoading={isDownloading}
                        loadingText="Downloading..."
                      >
                        Download documents
                      </Button>
                    )}
                  </div>
                  <div className="space-y-2">
                    <Field
                      name="fileUploads"
                      component={FileUploadForm}
                      documentData={getBusinessDocumentData()}
                      resolvedFields={_get(company, "resolvedFields")}
                      onlyPreview={true}
                      addReviewComment={
                        role === Constants.COOKIE_ROLES.SUPER_ADMIN
                      }
                      hideResolveAllCheckbox={
                        role === Constants.COOKIE_ROLES.ORG_ADMIN
                      }
                      handleSaveComment={handleSaveComment}
                      formName="uploadDocuments"
                      formHelperKey="company"
                      updateEffectKey="updateNestedCompanyState"
                      updateEffectPath="companySlice.company"
                      formId={_get(company, "_id")}
                      resolveAllFieldsCheckbox
                      fileUploadComments={fileUploadComments}
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default FileUploads;
