import _pickBy from "lodash/pickBy";
import _merge from "lodash/merge";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import Cookies from "js-cookie";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import SectionDescription from "components/lib/Shared/SectionDescription";
import uploadDocumentsValidation from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/uploadDocumentsValidation";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import { handleFilesUpload } from "utils/filesUtils";
import {
  updateCorporate,
  updateOrganizationState,
} from "state/slices/organizations";
import Button from "components/lib/Shared/Button";
import { Constants } from "config/constants";

const UploadDocuments = ({ handleBackBtn, handleNextBtn }) => {
  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const organization =
    useSelector((state) => state.organizationsSlice.organization) || {};
  const dispatch = useDispatch();
  const params = useParams();
  const cookieRole = Cookies.get("role");

  const isReadOnly = [
    Constants.COOKIE_ROLES.OFFICIAL,
    Constants.COOKIE_ROLES.CONTACT_PERSON,
  ].includes(cookieRole);

  const { idType, fileUploads } = organization;

  const handleSubmit = async (formData) => {
    if (isReadOnly) {
      handleNextBtn();
      return;
    }
    const uploadedFiles = await handleFilesUpload(formData.fileUploads);

    const fileUploads = {
      ..._pickBy(formData.fileUploads, (file) => typeof file === "string"),
      ...uploadedFiles,
    };

    const updatedOfficialData = {};
    if (!_isEqual(organization["fileUploads"], fileUploads)) {
      updatedOfficialData["fileUploads"] = fileUploads;
    }

    if (!_isEmpty(updatedOfficialData)) {
      await dispatch(updateOrganizationState({ fileUploads }));
      await dispatch(
        updateCorporate({
          id: params.corporateId,
          data: { fileUploads },
        })
      );
    }
    handleNextBtn();
  };

  const documents = getDocumentData(idType);
  const { schemaInitialValues, schema } = uploadDocumentsValidation(documents);

  const initialValues = _merge(schemaInitialValues, { fileUploads });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <SectionDescription
              id="Step: 4 of 5"
              title="Upload required documents"
              description="The documents presented will be used for registration purposes."
            />
            <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
              <div className="px-4 py-6 space-y-6 sm:p-6">
                <div className="mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Upload documents
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Please provide the correct documents as instructed.
                  </p>
                </div>
                <Field
                  name="fileUploads"
                  component={FileUploadForm}
                  documentData={documents}
                  comments={transformedComments}
                  disabled={isReadOnly}
                />
              </div>
            </div>
            <div className="flex justify-between py-3">
              <Button
                variant="secondary"
                disabled={isSubmitting}
                onClick={handleBackBtn}
              >
                Go back
              </Button>
              <Button type="submit" isLoading={isSubmitting}>
                {isReadOnly ? "Next step" : "Save and proceed"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UploadDocuments;
