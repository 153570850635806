import axios from "../axios";

const getCurrentUser = () => axios.get(`/auth/get-current-user`);

const resendEmailLink = (payload) =>
  axios.post(`/auth/resend-verify-email`, payload);

const confirmAccount = (token) => axios.get(`/auth/confirm-account/${token}`);

const authDataService = {
  getCurrentUser,
  resendEmailLink,
  confirmAccount,
};

export default authDataService;
