import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import _get from "lodash/get";
import { format } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";

import ReactTable from "components/lib/Global/ReactTable";
import Sidebar from "components/Applications/Sidebar";
import HeroSection from "components/Applications/HeroSection";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import OrgAdminDashboardWrapper from "components/lib/Global/OrgAdminDashboardWrapper";
import SearchInput from "components/lib/Shared/SearchInput";
import Unauthorized from "pages/Unauthorized";
import SubRowAsync from "./SubRow";
import GoBackToHomeBtn from "../GoBackToHomeBtn";
import { getCorporates } from "state/slices/contactPersonCorporates";
import { capitalizeFirstLetter, delay } from "utils";

export default function Corporates() {
  const dispatch = useDispatch();
  const { getUserStatus } = useSelector((state) => state.authSlice);
  const { isAuthenticated, user } = useAuth0();
  const { items, totalCount, getOrganizations } = useSelector(
    (state) => state.contactPersonCorporatesSlice
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (isAuthenticated) {
      if (debouncedSearchTerm) setCurrentPage(1);
      dispatch(
        getCorporates({
          queryParams: {
            search: debouncedSearchTerm,
            page: "corporate-directors-shareholders",
            limit: rowsPerPage,
            skip: (currentPage - 1) * rowsPerPage,
            order: "desc",
          },
        })
      );
    }
  }, [isAuthenticated, rowsPerPage, currentPage, debouncedSearchTerm]);

  const currentUser = useSelector((state) => state.userSlice);

  const refreshTableData = async () => {
    dispatch(
      getCorporates({
        queryParams: {
          search: debouncedSearchTerm,
          page: "corporate-directors-shareholders",
          limit: rowsPerPage,
          skip: (currentPage - 1) * rowsPerPage,
          order: "desc",
        },
      })
    );
  };

  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <SubRowAsync
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          refreshTableData={refreshTableData}
        />
      );
    },
    []
  );

  if (!currentUser?._id || getUserStatus.status === "loading") {
    return (
      <OrgAdminDashboardWrapper>
        <div className="flex justify-center mx-auto mt-10 text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </OrgAdminDashboardWrapper>
    );
  }

  if (currentUser?.role !== 9 && getUserStatus.status !== "loading") {
    return <Unauthorized />;
  }

  const columns = [
    {
      Header: "Organization",
      accessor: "organizationName",
      className: "p-4 whitespace-normal max-w-[150px]",
      Cell: ({ row }) => row.original.names?.companyName ?? "N/A",
    },
    {
      Header: "Contact Person",
      accessor: "contactPerson",
      Cell: ({ row }) =>
        capitalizeFirstLetter(
          row?.original?.contactPerson?.names?.firstName ?? "N/A"
        ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => row.original.contactPerson?.email ?? "N/A",
    },
    { Header: "Type", accessor: "idType" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Date",
      Cell: ({ row }) =>
        row.original?.createdAt
          ? format(new Date(row.original?.createdAt), "yyyy/MM/dd")
          : "N/A",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const handleViewRow = async (row) => {
          if (!row.isExpanded) {
            row?.toggleAllRowsExpanded(false); // Close all rows
            await delay(10);
            row?.toggleRowExpanded(); // Open the clicked row
          } else {
            row?.toggleRowExpanded();
          }
        };

        return (
          <button
            type="button"
            onClick={() => handleViewRow(row)}
            className={`text-gray-900 group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
          >
            {row.isExpanded ? "Close" : "View"}
          </button>
        );
      },
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <OrgAdminDashboardWrapper>
      <div className="grid gap-4 py-10 mx-auto">
        <HeroSection />
        <GoBackToHomeBtn />
        <div className="grid grid-cols-6 border-t border-gray">
          <div className="col-span-6 pr-8 md:col-span-1">
            <Sidebar />
          </div>
          <div className="col-span-6 md:border-l md:col-span-5 md:pl-8 pt-8 border-gray">
            <div className="mb-6">
              <h1 className="text-title5">Incorporated</h1>
            </div>
            <div className="flex items-center justify-between my-2">
              <div className="flex items-center space-x-4">
                <h2 className="font-semibold">Filter by:</h2>
              </div>
              <SearchInput value={searchTerm} handleOnChange={handleSearch} />
            </div>
            {totalCount > 0 ? (
              <ReactTable
                columns={columns}
                data={items}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                totalRows={totalCount}
                setCurrentPage={setCurrentPage}
                paginate
                headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-slate-200"
                headingStyle="pl-4 text-left"
                dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
                renderRowSubComponent={renderRowSubComponent}
                isLoading={getOrganizations.status === "loading"}
              />
            ) : (
              <p className="text-2xl text-center text-primary">
                No applications available.
              </p>
            )}
          </div>
        </div>
      </div>
    </OrgAdminDashboardWrapper>
  );
}
