import React from "react";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import { format } from "date-fns";
import { Formik, Field } from "formik";

import Accordion from "components/lib/Global/Accordion";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import addressOptions from "components/PLCTABS/SFLdata/addressOptions";

const CompanyFormation = () => {
  const {
    companyFormation: { step1, step2, step3 },
  } = useSelector((state) => state.companySlice);
  const { preferredNamesCount } = step1;

  const articlesOfAssociationFields = [
    {
      key: "Articles of association",
      value: "N/A",
    },
    {
      key: "Other objects for which company is established",
      value: _get(step2, "articlesOfAssociation.articlesType", "N/A"),
    },
    {
      key: "Nature of business",
      value: _get(step2, "articlesOfAssociation.natureOfBusiness", "N/A"),
    },
  ];

  const targetBusinessStartDate = _get(
    step3,
    "primaryBusinessActivity.targetBusinessStartDate"
  );

  const businessApplicationDetailsFields1 = [
    {
      key: "Primary Business Activity Sector",
      value: _get(step3, "primaryBusinessActivity.sector", "N/A"),
    },
    {
      key: "Primary Business Activity Division",
      value: _get(step3, "primaryBusinessActivity.division", "N/A"),
    },
    {
      key: "Primary Business Activity Group",
      value: _get(step3, "primaryBusinessActivity.group", "N/A"),
    },
    {
      key: "Primary Business Activity Class",
      value: _get(step3, "primaryBusinessActivity.businessClass", "N/A"),
    },
    {
      key: "Target Business Start Date",
      value: targetBusinessStartDate
        ? format(new Date(targetBusinessStartDate), "yyyy-MM-dd")
        : "N/A",
    },
    {
      key: "Accounting Period End Month",
      value:
        addressOptions.months.find(
          (month) =>
            month.value === step3?.primaryBusinessActivity?.accountingEndMonth
        )?.label ?? "N/A",
    },
  ];

  const businessApplicationDetailsFields2 = [
    {
      key: "No. of Employees at target start date",
      value: _get(step3, "primaryBusinessActivity.numberOfEmployees", "N/A"),
    },
    {
      key: "Estimated annual turnover",
      value: _get(step3, "primaryBusinessActivity.turnOver", "N/A"),
    },
    {
      key: "Is the company you are registering a subsidiary company or branch?",
      value: _get(step3, "subsidiaryDetail.isSubsidiaryCompany", "N/A"),
    },
    {
      key: "Holding Company Name *",
      value: _get(step3, "subsidiaryDetail.holdingCompanyName", "N/A"),
    },
    {
      key: "Holding Company Country *",
      value: _get(step3, "subsidiaryDetail.holdingCompanyCountry", "N/A"),
    },
    {
      key: "Was your business formed as a result of amalgamation or acquisition?",
      value: _get(step3, "amalgamationDetail.isAmalgamationCompany", "N/A"),
    },
  ];

  const accordionTabs = [
    {
      title: "Step 1 of 3  : Preferred business/company name",
      content: (
        <>
          {Array.from({ length: preferredNamesCount }, (_, i) => i + 1).map(
            (nameCount) => {
              const letterOfNoObjection = _get(
                step1,
                `fileUploads.affiliatedBusiness${nameCount}_LNO`
              );
              return (
                <FormFieldsCard
                  key={`preferredCompanyNameCard_${nameCount}`}
                  fields={[
                    {
                      key: "Name",
                      value: _get(step1, `companyName${nameCount}`, "N/A"),
                    },
                    {
                      key: "Abbreviate",
                      value: _get(
                        step1,
                        `isCompanyName${nameCount}Abbreviated`,
                        "N/A"
                      ),
                    },
                    {
                      key: "Special Instruction",
                      value: _get(
                        step1,
                        `specialInstructions${nameCount}`,
                        "N/A"
                      ),
                    },
                    {
                      key: "Affiliated Business",
                      value: _get(
                        step1,
                        `affiliatedBusiness${nameCount}.affiliatedBusinessName`,
                        "N/A"
                      ),
                    },
                    {
                      key: "No objection letter",
                      value: (
                        <>
                          <Formik
                            enableReinitialize
                            initialValues={{
                              fileUploads: {
                                [`affiliatedBusiness${nameCount}_LNO`]:
                                  letterOfNoObjection,
                              },
                            }}
                            onSubmit={() => null}
                          >
                            {() => {
                              return (
                                <div className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300">
                                  {letterOfNoObjection ? (
                                    <Field
                                      name="fileUploads"
                                      component={FileUploadForm}
                                      documentData={[
                                        {
                                          name: `affiliatedBusiness${nameCount}_LNO`,
                                          label: `affiliatedBusiness${nameCount}_LNO`,
                                        },
                                      ]}
                                      onlyPreview
                                    />
                                  ) : (
                                    "N/A"
                                  )}
                                </div>
                              );
                            }}
                          </Formik>
                        </>
                      ),
                    },
                  ]}
                />
              );
            }
          )}
        </>
      ),
    },
    {
      title: "Step 2 of 3  :  Articles of association",
      content: <FormFieldsCard fields={articlesOfAssociationFields} />,
    },
    {
      title: "Step 3 of 3  :  Business application details",
      content: (
        <>
          <FormFieldsCard fields={businessApplicationDetailsFields1} />
          <FormFieldsCard fields={businessApplicationDetailsFields2} />
        </>
      ),
    },
  ];

  return <Accordion tabs={accordionTabs} />;
};

export default CompanyFormation;
