import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { XIcon } from "@heroicons/react/outline";
import _groupBy from "lodash/groupBy";
import _forOwn from "lodash/forOwn";

import Table from "components/lib/Global/ReactTable";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { useToast } from "hooks/useToast";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import {
  getAccessRequestComments,
  resetAccessRequestCommentsSlice,
} from "state/slices/accessRequestComment";
import { closeModal } from "state/slices/modals";
import { capitalizeFirstLetter, getFullName } from "utils";
import { editAccessRequest } from "state/slices/accessRequest";
import SubRowAsync from "./SubRowAsync";
import Button from "components/lib/Shared/Button";

export default function RequestsTable({ requests, registrarView }) {
  const [expandedRow, setExpandedRow] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { toast } = useToast(5000);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (
      expandedRow?.original?._id &&
      currentUser?._id &&
      expandedRow?.original?.status === "Pending"
    ) {
      dispatch(
        getAccessRequestComments({
          companyId: expandedRow?.original?.company?._id,
          organizationId: expandedRow?.original?.organization?._id,
          accessRequest: expandedRow?.original?._id,
        })
      );
    }

    return () => {
      dispatch(resetAccessRequestCommentsSlice());
    };
  }, [expandedRow, currentUser?._id]);

  const columns = useMemo(
    () => [
      {
        Header: "Initiated by",
        id: "initiatedBy",
        accessor: "initiatedBy",
        Cell: ({ row: { original } }) => {
          if (original?.createdBy?._id === currentUser?._id)
            return `${capitalizeFirstLetter(
              currentUser.names.firstName
            )} (You)`;
          const { names } = original?.createdBy ?? {};
          if (!names) return "N/A";
          return getFullName(names);
        },
      },
      {
        Header: "Requested to",
        id: "initiatedTo",
        accessor: "initiatedTo",
        Cell: ({ row: { original } }) => {
          if (original?.applicant) {
            return (
              <div className="flex space-x-1 items-center">
                <p className="flex-grow">
                  {getFullName(
                    registrarView && original?.registrar
                      ? original.registrar.names
                      : original.applicant.names
                  )}
                </p>
                <span className="items-center w-fit gap-x-1.5 rounded-full px-2 py-1 text-xxs font-medium text-gray-900 ring-1 ring-inset ring-primary-200 bg-purple-100/25 tracking-wide">
                  {registrarView && original?.registrar
                    ? "Registrar"
                    : original?.minor
                    ? "Minor"
                    : "Applicant"}
                </span>
              </div>
            );
          }
          if (
            original?.corporate?.identificationDetails?.emailAddress ===
            currentUser?.electronicAddress?.emailAddress
          )
            return `${capitalizeFirstLetter(
              currentUser.names.firstName
            )} (You)`;
          if (original?.initiatedTo?._id === currentUser?._id)
            return `${capitalizeFirstLetter(
              currentUser.names.firstName
            )} (You)`;
          const { names } = original?.initiatedTo ?? {};
          if (!names) return original?.initiatedToEmail ?? "N/A";
          return getFullName(names);
        },
      },
      {
        Header: "No of Applications",
        id: "numberOfApplications",
        accessor: "numberOfApplications",
        Cell: ({ row: { original } }) => {
          const companiesCount = original?.children?.reduce(
            (accumulator, currentValue) =>
              accumulator + 1 + currentValue?.companies?.length ?? 0,
            0
          );
          return companiesCount || 1;
        },
      },
      {
        Header: "Data Type",
        id: "dataType",
        accessor: "dataType",
        Cell: ({ row: { original } }) => original?.requestFor ?? "N/A",
      },
      {
        Header: "Status",
        id: "status",
        accessor: "status",
        Cell: ({
          row: {
            original: { status },
          },
        }) => status || "N/A",
      },
      {
        // Make an expander cell
        Header: () => "Actions", // No header
        id: "actions", // It needs an ID
        Cell: ({ row }) => {
          const onClick = () => {
            setExpandedRow(row);
            row.getToggleRowExpandedProps().onClick();
          };

          return (
            <button
              {...row.getToggleRowExpandedProps()}
              onClick={onClick}
              className="flex items-center w-full px-2 text-sm text-gray-900 rounded-md group disabled:cursor-not-allowed"
            >
              {row.isExpanded ? (
                <XIcon className="w-5 h-5" aria-hidden="true" />
              ) : (
                "View"
              )}
            </button>
          );
        },
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },
    ],
    [expandedRow, setExpandedRow]
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        setSelectedRow={setSelectedRow}
      />
    ),
    []
  );

  const handleDenyAccess = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        editAccessRequest({
          accessRequestsId: selectedRow?._id,
          data: {
            status: "Denied",
            revokedAt: new Date(),
            revokedBy: currentUser?._id,
          },
        })
      );
      toast("success", "Access denied successfully.");
      dispatch(closeModal("deny_request_confirmation_modal"));
      expandedRow?.toggleRowExpanded();
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRecallAccess = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        editAccessRequest({
          accessRequestsId: selectedRow?._id,
          data: { status: "Recalled", recalledAt: new Date() },
        })
      );
      toast("success", "Request recalled successfully.");
      dispatch(closeModal("recall_request_confirmation_modal"));
      expandedRow?.toggleRowExpanded();
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRevokeAccess = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        editAccessRequest({
          accessRequestsId: selectedRow?._id,
          data: {
            status: "Revoked",
            revokedAt: new Date(),
            revokedBy: currentUser?._id,
          },
        })
      );
      toast("success", "Access revoked successfully.");
      dispatch(closeModal("revoke_request_confirmation_modal"));
      expandedRow?.toggleRowExpanded();
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const initiatedByName = getFullName(selectedRow?.createdBy?.names);
  const initiatedToName = getFullName(selectedRow?.initiatedTo?.names);

  const requestsByOther = requests.filter(
    (request) => request.createdBy._id !== currentUser._id
  );
  const requestsByMe = requests.filter(
    (request) => request.createdBy._id === currentUser._id
  );
  const groupedRequestsByOther = _groupBy(
    requestsByOther,
    (row) => `${row.initiatedBy}_${row.requestFor}`
  );
  const groupedRequestsByMe = _groupBy(
    requestsByMe,
    (row) => `${row.initiatedToEmail}_${row.initiatedTo?._id}_${row.requestFor}`
  );

  const groupedTableData = [];

  _forOwn(groupedRequestsByOther, (value, key) => {
    const initiatedById = key.split("_")[0];
    groupedTableData.push({
      ...requests.find(
        (request) =>
          request.initiatedBy === initiatedById &&
          request.requestFor === key.split("_")[1]
      ),
      children: value,
    });
  });

  _forOwn(groupedRequestsByMe, (value, key) => {
    const initiatedToEmail = key.split("_")[0];
    const initiatedToId = key.split("_")[1];
    groupedTableData.push({
      ...requests.find(
        (request) =>
          (request.initiatedToEmail === initiatedToEmail ||
            request.initiatedTo?._id === initiatedToId) &&
          request.requestFor === key.split("_")[2]
      ),
      children: value,
    });
  });

  return (
    <>
      <ModalWrapper
        name="deny_request_confirmation_modal"
        title="Confirm Deny"
        maxWidth="sm:max-w-fit"
      >
        <div className="my-8 text-lg">
          You are about to reject access to {initiatedByName}. By clicking "I
          confirm" you will be denying access. <br />
          However, if you have clicked the deny button by mistake, click
          "cancel" the deny process.
        </div>
        <hr />
        <div className="flex justify-between mt-4">
          <Button
            disabled={isLoading}
            onClick={() =>
              dispatch(closeModal("deny_request_confirmation_modal"))
            }
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            onClick={handleDenyAccess}
            loadingText="Confirming..."
          >
            I confirm
          </Button>
        </div>
      </ModalWrapper>
      <ModalWrapper
        name="recall_request_confirmation_modal"
        title="Confirm Recall"
        maxWidth="sm:max-w-fit"
      >
        <div className="my-8 text-lg">
          You are about to recall access request from {initiatedToName}. By
          clicking "I confirm" you will be canceling request. <br />
          However, if you have clicked the deny button by mistake, click
          "cancel" the deny process.
        </div>
        <hr />
        <div className="flex justify-between mt-4">
          <button
            disabled={isLoading}
            onClick={() =>
              dispatch(closeModal("recall_request_confirmation_modal"))
            }
            className="inline-flex items-center px-10 py-2 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed"
          >
            <p>Cancel</p>
          </button>
          <button
            disabled={isLoading}
            onClick={handleRecallAccess}
            className="inline-flex items-center justify-center px-8 py-2 space-x-2 font-medium text-white border border-transparent rounded-md shadow-sm bg-sflPrimary hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sflPrimary disabled:cursor-not-allowed"
          >
            {isLoading ? (
              <>
                <SpinnerIcon />
                Submitting...
              </>
            ) : (
              "I confirm"
            )}
          </button>
        </div>
      </ModalWrapper>
      <ModalWrapper
        name="revoke_request_confirmation_modal"
        title="Confirm Revoke Access"
        maxWidth="sm:max-w-fit"
      >
        <div className="my-8 text-lg">
          You are about to revoke access to {initiatedByName}. By clicking "I
          confirm" you will be revoking access. <br />
          However, if you have clicked the deny button by mistake, click
          "cancel" the deny process.
        </div>
        <hr />
        <div className="flex justify-between mt-4">
          <Button
            disabled={isLoading}
            onClick={() =>
              dispatch(closeModal("revoke_request_confirmation_modal"))
            }
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            onClick={handleRevokeAccess}
            loadingText="Confirming..."
          >
            I confirm
          </Button>
        </div>
      </ModalWrapper>
      <div className="w-full mt-4 overflow-x-auto">
        <Table
          columns={columns}
          data={groupedTableData}
          renderRowSubComponent={renderRowSubComponent}
          headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
          headingStyle="pl-4 text-left"
          dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          dataRowCellStyle="pl-4 font-normal"
        />
      </div>
    </>
  );
}
