/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _pickBy from "lodash/pickBy";
import _merge from "lodash/merge";
import _omit from "lodash/omit";
import Cookies from "js-cookie";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

import SectionDescription from "components/lib/Shared/SectionDescription";
import ArticlesOfAssociation from "./CompanyFormationComponents/ArticlesOfAssociation";
import { updateCompanyState } from "state/slices/company";
import { handleFilesUpload } from "utils/filesUtils";
import step2Validation from "./step2Validation";
import CommentsList from "components/lib/Global/CommentsList";
import ValidationError from "components/lib/Shared/ValidationError";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import Button from "components/lib/Shared/Button";
import { Constants } from "config/constants";
import {
  addTemplateAsync,
  deleteTemplateAsync,
  editTemplateAsync,
  getTemplateForApplicantAsync,
  updateTemplateState,
} from "state/slices/template";
import { updateCompanyAsync } from "state/slices/company";
import TemplateSummary from "components/OrgAndSuperAdminDashboard/TemplatesSettings/Templates/SummaryForm";
import OnlyOfficeDocumentEditor from "components/lib/Shared/OnlyOfficeDocumentEditor";
import { useToast } from "hooks/useToast";
import FileUploadForm from "../CompanyOfficialsTab/FileUploadForm";
import { closeModal, openModal } from "state/slices/modals";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import { getFullName } from "utils";
import Overlay from "components/lib/Shared/Overlay";

const MODAL_NAME = "template_override_confirmation_modal";

const Step2 = ({ goToPreviousStep, goToNextStep }) => {
  const role = Cookies.get("role");

  const dispatch = useDispatch();
  const { toast, clearAllToasts } = useToast();
  const summaryFormRef = useRef(null);
  const { companyFormation, company } = useSelector(
    (state) => state.companySlice
  );
  const closeArticlesAccordionRef = useRef(null);
  const closeSummaryAccordionRef = useRef(null);
  const closeFilePreviewRef = useRef(null);

  const { step2 } = companyFormation;

  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const {
    selectedTemplate,
    selectedVersion,
    getTemplateForApplicant,
    showOverlay,
  } = useSelector((state) => state.templateSlice);
  const { modalRow } = useSelector((state) => state.modalsSlice);
  const currentUser = useSelector((state) => state.userSlice);

  const { schemaInitialValues, schema } = step2Validation();

  const initialValues = _merge(schemaInitialValues, step2);

  const articlesOfAssociationToUseComments =
    transformedComments?.["articlesOfAssociation"]
      ?.articlesOfAssociationToUse ?? [];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        resolvedFields: companyFormation.resolvedFields,
        summary: {},
        fileUploads: {},
      }}
      validationSchema={schema}
      onSubmit={async (values) => {
        clearAllToasts();
        if (
          role &&
          ![
            Constants.COOKIE_ROLES.APPLICANT,
            Constants.COOKIE_ROLES.CO_APPLICANT,
          ].includes(role)
        ) {
          goToNextStep();
          return;
        }

        if (_isEmpty(values.fileUploads)) {
          toast(
            "error",
            "Articles of Association and its Summary are required."
          );
          return;
        }

        // validate summary form
        const summaryFormErrors = await summaryFormRef.current?.validateForm();
        if (!_isEmpty(summaryFormErrors)) {
          summaryFormRef.current.submitForm();
          return;
        }

        closeArticlesAccordionRef.current?.click();
        closeSummaryAccordionRef.current?.click();

        const uploadedFiles = await handleFilesUpload(
          values.fileUploads,
          null,
          false,
          "templates"
        );
        const templateData = {
          ..._omit(values, [
            "articlesOfAssociation",
            "articlesOfAssociationToUse",
            "associationChoice",
            "resolvedFields",
          ]),
          category: selectedTemplate?.category,
          name: selectedTemplate?.name ?? "Articles of Association",
          entityType: selectedTemplate?.entityType ?? company.type,
          templateType:
            selectedTemplate?.templateType ??
            values.articlesOfAssociationToUse ===
              "ModifiedArticlesOfAssociation"
              ? "Short"
              : "Long",
          company: company._id,
          fileUploads: {
            ..._pickBy(values.fileUploads, (file) => typeof file === "string"),
            ...uploadedFiles,
          },
        };

        let response = {};
        if (selectedTemplate?._id && selectedTemplate?.company) {
          response = await dispatch(
            editTemplateAsync({
              templateId: selectedTemplate?._id,
              data: templateData,
            })
          );
        } else {
          response = await dispatch(addTemplateAsync({ data: templateData }));
        }
        console.log({ selectedTemplate, response });
        if (response.error) {
          toast(
            "error",
            response.payload?.messageText ??
              "Something went wrong please try again"
          );
        } else {
          dispatch(
            updateTemplateState({
              selectedTemplate: response.payload?.template ?? response.payload,
            })
          );
          const companyData = {
            companyFormation: {
              ...companyFormation,
              step2: {
                ...values,
                articlesOfAssociation: {
                  ...values.articlesOfAssociation,
                  template:
                    response.payload?.template?._id ?? response.payload._id,
                },
                fileUploads: {
                  ...company.fileUploads,
                  ..._pickBy(
                    values.fileUploads,
                    (file) => typeof file === "string"
                  ),
                  ...uploadedFiles,
                },
              },
            },
          };
          console.log({ companyData });
          dispatch(updateCompanyState(companyData));
          await dispatch(
            updateCompanyAsync({
              id: company._id,
              data: {
                ...values,
                articlesOfAssociation: {
                  ...values.articlesOfAssociation,
                  template:
                    response.payload?.template?._id ?? response.payload._id,
                },
                fileUploads: {
                  ...company.fileUploads,
                  ..._pickBy(
                    values.fileUploads,
                    (file) => typeof file === "string"
                  ),
                  ...uploadedFiles,
                },
                tabName: "Company formation -> Step: 2 of 3",
              },
            })
          );
        }
        goToNextStep(3);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => {
        const [uploadingTemplate, setUploadingTemplate] = useState(false);
        const [deletingPreviousTemplate, setDeletingPreviousTemplate] =
          useState(false);

        useEffect(() => {
          setFieldValue("summary", {});
          setFieldValue("fileUploads", company?.fileUploads ?? {});
          dispatch(
            updateTemplateState({
              selectedTemplate: {},
            })
          );
          if (
            values.articlesOfAssociationToUse &&
            values.associationChoice === "adopt" &&
            !company.articlesOfAssociation?.template
          ) {
            dispatch(
              getTemplateForApplicantAsync({
                name: "Articles of Association",
                entityType: company.type,
                templateType:
                  values.articlesOfAssociationToUse ===
                  "ModifiedArticlesOfAssociation"
                    ? "Short"
                    : "Long",
              })
            );
          } else if (company.articlesOfAssociation?.template) {
            dispatch(
              updateTemplateState({
                selectedTemplate: company.articlesOfAssociation?.template,
              })
            );
          }
        }, [
          values.articlesOfAssociationToUse,
          values.associationChoice,
          company.type,
          company.fileUploads,
        ]);

        const templateVersions = selectedTemplate?.versions
          ? selectedTemplate.versions
          : [];
        const latestTemplate =
          templateVersions?.[templateVersions.length - 1] ?? {};

        const handleChangeArticlesOfAssociationToUse = (e) => {
          const { name, value } = e.target;
          if (company.articlesOfAssociation?.template) {
            dispatch(
              openModal({ modalName: MODAL_NAME, modalRow: { name, value } })
            );
          } else {
            setFieldValue(e.target.name, value);
          }
        };

        const handleChangeAssociationChoice = (e) => {
          const { name, value } = e.target;
          if (company.articlesOfAssociation?.template) {
            dispatch(
              openModal({ modalName: MODAL_NAME, modalRow: { name, value } })
            );
          } else {
            setFieldValue(e.target.name, value);
          }
        };

        useEffect(() => {
          if (
            values.associationChoice &&
            latestTemplate?.fileUploads?.template
          ) {
            setFieldValue("summary", latestTemplate.summary);
            setFieldValue("fileUploads", {
              ...(company?.fileUploads ?? {}),
              ...latestTemplate.fileUploads,
            });
          } else {
            setFieldValue("summary", {});
            setFieldValue("fileUploads", company?.fileUploads ?? {});
          }
        }, [
          latestTemplate?.fileUploads?.template,
          values.associationChoice,
          company.fileUploads,
        ]);

        const handleUploadTemplate = async () => {
          try {
            if (!values.fileUploads?.template) return;
            setUploadingTemplate(true);
            setFieldValue("fileUploads", company?.fileUploads ?? {});
            // remove previous template
            await handleRemovePreviousTemplate();
            const uploadedFiles = await handleFilesUpload(
              values.fileUploads,
              null,
              false,
              "templates"
            );
            // create new template
            const templateData = {
              name: "Articles of Association",
              entityType: company.type,
              templateType:
                values.articlesOfAssociationToUse ===
                "ModifiedArticlesOfAssociation"
                  ? "Short"
                  : "Long",
              company: company._id,
              fileUploads: uploadedFiles,
            };
            const response = await dispatch(
              addTemplateAsync({ data: templateData })
            );
            const newTemplate = response.payload?.template ?? response.payload;
            dispatch(
              updateTemplateState({
                selectedTemplate: newTemplate,
              })
            );
            const companyData = {
              companyFormation: {
                ...companyFormation,
                step2: {
                  ...step2,
                  articlesOfAssociation: {
                    ...step2.articlesOfAssociation,
                    template: newTemplate._id,
                  },
                  fileUploads: uploadedFiles,
                },
              },
            };
            dispatch(updateCompanyState(companyData));
            await dispatch(
              updateCompanyAsync({
                id: company._id,
                data: {
                  articlesOfAssociation: {
                    ...step2.articlesOfAssociation,
                    template: newTemplate._id,
                  },
                },
              })
            );
            setFieldValue("fileUploads", uploadedFiles);
          } catch (error) {
            toast("error", "Something went wrong please try again");
          } finally {
            setUploadingTemplate(false);
          }
        };

        const handleSaveSummary = async (summary) => {
          try {
            if (!values.fileUploads?.template) return;
            setUploadingTemplate(true);
            await dispatch(
              editTemplateAsync({
                templateId: selectedTemplate?._id,
                data: {
                  ...values,
                  summary,
                },
              })
            );
            setFieldValue("summary", summary);
            closeFilePreviewRef.current?.click();
            toast("success", "Successfully saved the details");
          } catch (error) {
            toast("error", "Something went wrong please try again");
          } finally {
            setUploadingTemplate(false);
          }
        };

        const handleRemovePreviousTemplate = async () => {
          try {
            setDeletingPreviousTemplate(true);
            if (selectedTemplate?._id) {
              await dispatch(
                deleteTemplateAsync({ templateId: selectedTemplate._id })
              );
            }
            await dispatch(
              updateCompanyAsync({
                id: company._id,
                data: {
                  ..._omit(values, ["fileUploads"]),
                  articlesOfAssociation: {
                    ...values.articlesOfAssociation,
                    template: null,
                  },
                },
              })
            );
            const companyData = {
              companyFormation: {
                ...companyFormation,
                step2: {
                  ...values,
                  fileUploads: {},
                  articlesOfAssociation: {
                    ...values.articlesOfAssociation,
                    template: null,
                  },
                },
              },
            };
            dispatch(updateCompanyState(companyData));
            dispatch(
              updateTemplateState({
                selectedTemplate: {},
              })
            );
            setFieldValue("summary", {});
            setFieldValue("fileUploads", company?.fileUploads ?? {});
            if (modalRow?.name) {
              setFieldValue(modalRow.name, modalRow.value);
              dispatch(closeModal(MODAL_NAME));
            }
          } catch (error) {
            toast("error", "Something went wrong please try again");
          } finally {
            setDeletingPreviousTemplate(false);
          }
        };

        return (
          <Form>
            <fieldset
              disabled={role === Constants.COOKIE_ROLES.OFFICIAL || showOverlay}
              className="relative"
            >
              {showOverlay && <Overlay />}
              <SectionDescription
                id="Step: 2 of 3"
                title="Articles Of Association"
                description="Please complete the relevant information in each of the following categories."
              />
              <div>
                <div className="mb-4 shadow-sm sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-6 space-y-4 bg-white sm:p-6">
                    <div className="inline-block">
                      <p className="relative">
                        <span className="font-medium">
                          ARTICLES OF ASSOCIATION (Select the option that
                          applies)
                        </span>
                        {articlesOfAssociationToUseComments.length > 0 && (
                          <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-2 -right-5">
                            <span>
                              {articlesOfAssociationToUseComments.length}
                            </span>
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <Field
                        name="articlesOfAssociationToUse"
                        type="radio"
                        id="ModifiedArticlesOfAssociation"
                        value="ModifiedArticlesOfAssociation"
                        onChange={handleChangeArticlesOfAssociationToUse}
                        className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                        disabled={values.resolvedFields.includes(
                          "articlesOfAssociationToUse"
                        )}
                      />
                      <label
                        htmlFor="ModifiedArticlesOfAssociation"
                        className="block ml-3 text-sm font-medium text-gray-700"
                      >
                        The company will adopt some of those model articles and
                        has prepared its own articles of association to
                        supplement or modify those model articles
                      </label>
                    </div>
                    <div className="flex items-center">
                      <Field
                        name="articlesOfAssociationToUse"
                        type="radio"
                        id="OwnArticlesOfAssociation"
                        value="OwnArticlesOfAssociation"
                        onChange={handleChangeArticlesOfAssociationToUse}
                        className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                        disabled={values.resolvedFields.includes(
                          "articlesOfAssociationToUse"
                        )}
                      />
                      <label
                        htmlFor="OwnArticlesOfAssociation"
                        className="block ml-3 text-sm font-medium text-gray-700"
                      >
                        The company has prepared its own articles of association
                      </label>
                    </div>
                    {values.articlesOfAssociationToUse && (
                      <div className="m-4 p-4 bg-gray-100 space-y-2 sm:rounded-md sm:overflow-hidden">
                        <span className="font-medium">Will you be?</span>
                        <div className="flex items-center">
                          <Field
                            name="associationChoice"
                            type="radio"
                            id="adoptOption"
                            value="adopt"
                            onChange={handleChangeAssociationChoice}
                            className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                            disabled={values.resolvedFields.includes(
                              "associationChoice"
                            )}
                          />
                          <label
                            htmlFor="adoptOption"
                            className="block ml-3 text-sm font-medium text-gray-700"
                          >
                            Adopting our styled articles of association
                          </label>
                        </div>
                        <div className="flex items-center">
                          <Field
                            name="associationChoice"
                            type="radio"
                            id="uploadYourOwnOption"
                            value="uploadYourOwn"
                            onChange={handleChangeAssociationChoice}
                            className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                            disabled={values.resolvedFields.includes(
                              "associationChoice"
                            )}
                          />
                          <label
                            htmlFor="uploadYourOwnOption"
                            className="block ml-3 text-sm font-medium text-gray-700"
                          >
                            Uploading your own articles of association
                          </label>
                        </div>
                        {getTemplateForApplicant.status === "loading" && (
                          <div className="flex justify-center mx-auto mt-10 text-white">
                            <SpinnerIcon className="text-gray-400" />
                          </div>
                        )}
                        {getTemplateForApplicant.status !== "loading" &&
                          selectedTemplate?.versions &&
                          values.associationChoice === "adopt" && (
                            <div className="p-4">
                              <Disclosure>
                                {({ open, close }) => (
                                  <>
                                    <button
                                      type="button"
                                      className="hidden"
                                      onClick={close}
                                      ref={closeArticlesAccordionRef}
                                    >
                                      close articles of Association
                                    </button>
                                    <Disclosure.Button className="flex w-full justify-between text-sm font-medium">
                                      <span>Articles of Association</span>
                                      <ChevronUpIcon
                                        className={`${
                                          open ? "rotate-180 transform" : ""
                                        } h-5 w-5`}
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="pt-4 h-full">
                                      <OnlyOfficeDocumentEditor
                                        userFullName={getFullName(
                                          currentUser.names
                                        )}
                                        userId={currentUser._id}
                                        companyId={company._id}
                                        url={`${process.env.REACT_APP_IMAGE_BASE_URL}/templates/${values.fileUploads.template}`}
                                        editorKey={`${selectedTemplate._id}-${
                                          selectedVersion?._id ||
                                          latestTemplate?._id
                                        }`}
                                        containerHeight="850px"
                                      />
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                              <hr className="my-4" />
                              <Disclosure>
                                {({ open, close }) => (
                                  <>
                                    <button
                                      type="button"
                                      className="hidden"
                                      onClick={close}
                                      ref={closeSummaryAccordionRef}
                                    >
                                      close summary of Articles
                                    </button>
                                    <Disclosure.Button className="flex w-full justify-between text-sm font-medium">
                                      <span>Summary of Articles</span>
                                      <ChevronUpIcon
                                        className={`${
                                          open ? "rotate-180 transform" : ""
                                        } h-5 w-5`}
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel>
                                      <TemplateSummary
                                        viewOnly
                                        selectedVersion={{
                                          summary: values.summary,
                                          fileUploads: values.fileUploads,
                                        }}
                                        comments={
                                          transformedComments.articlesOfAssociation
                                        }
                                      />
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          )}
                        {values.associationChoice === "uploadYourOwn" && (
                          <div>
                            <Field
                              name="fileUploads"
                              component={FileUploadForm}
                              documentData={[
                                {
                                  label: "",
                                  name: "template",
                                  accept:
                                    ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.documen",
                                },
                              ]}
                              innerRef={closeFilePreviewRef}
                              editorParams={{
                                containerHeight: "900px",
                              }}
                            />
                            {["object", "undefined"].includes(
                              typeof values.fileUploads.template
                            ) && (
                              <div className="flex justify-end">
                                <Button
                                  onClick={handleUploadTemplate}
                                  disabled={!values.fileUploads?.template}
                                  isLoading={uploadingTemplate}
                                  loadingText="Uploading..."
                                >
                                  Upload
                                </Button>
                              </div>
                            )}
                            {typeof values.fileUploads.template ===
                              "string" && (
                              <TemplateSummary
                                ref={summaryFormRef}
                                selectedVersion={{
                                  summary: values.summary,
                                  fileUploads: values.fileUploads,
                                }}
                                handleSaveSummary={handleSaveSummary}
                                comments={
                                  transformedComments.articlesOfAssociation
                                }
                              >
                                {({ isSubmitting, handleSubmit }) => (
                                  <div className="mt-6 flex items-center justify-end">
                                    <Button
                                      onClick={handleSubmit}
                                      loadingText="Saving..."
                                      isLoading={isSubmitting}
                                    >
                                      Save
                                    </Button>
                                  </div>
                                )}
                              </TemplateSummary>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {articlesOfAssociationToUseComments.length > 0 && (
                      <CommentsList
                        comments={articlesOfAssociationToUseComments}
                        color={"text-red-500"}
                      />
                    )}
                    <ValidationError
                      errors={errors}
                      touched={touched}
                      name="articlesOfAssociationToUse"
                    />
                  </div>
                </div>
                <Field
                  name="articlesOfAssociation"
                  component={ArticlesOfAssociation}
                  transformedComments={transformedComments}
                />
              </div>
            </fieldset>
            <div className="flex justify-between items-center mt-6">
              <Button
                preIcon={ChevronLeftIcon}
                variant="text"
                className="button-back button-back-hover items-center button-focus px-2 py-2 button-disabled button-outline button-focus"
                onClick={goToPreviousStep}
              >
                Back to previous step
              </Button>
              <Button
                onClick={handleSubmit}
                isLoading={isSubmitting}
                postIcon={ChevronRightIcon}
                className="flex button button-hover button-disabled button-outline button-focus bg-sflPrimary px-2 py-2 text-white"
              >
                {role === Constants.COOKIE_ROLES.OFFICIAL
                  ? "Next step"
                  : "Save and proceed"}
              </Button>
            </div>
            <ModalWrapper
              name={MODAL_NAME}
              title="Warning"
              titleColor="text-red-500"
              maxWidth="sm:max-w-[35%]"
            >
              <div className="my-4 text-lg">
                You will lose all data relating to the articles of association
                you have provided so far.
              </div>
              <hr />
              <div className="flex justify-end mt-4 space-x-4">
                <Button
                  onClick={() => dispatch(closeModal(MODAL_NAME))}
                  variant="outline"
                  disabled={deletingPreviousTemplate}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={handleRemovePreviousTemplate}
                  loadingText="Removing..."
                  isLoading={deletingPreviousTemplate}
                >
                  Proceed
                </Button>
              </div>
            </ModalWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Step2;
