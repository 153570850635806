import React from "react";
import { Logo } from "logo";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Constants } from "config/constants";

export default function Header() {
  const { loginWithRedirect } = useAuth0();

  const handleRedirectToLoginPage = () => {
    if (window.location.href === Constants.SFL_URL) {
      window.location.href = `${Constants.SFL_APP_URL}/login`;
    } else {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: "login",
          redirect_uri: window.location.origin,
          base_api_url: process.env.REACT_APP_BASE_API_URL,
        },
      });
    }
  };

  return (
    <>
      <header className="container mx-auto sm:mt-16">
        <div className="flex justify-between">
          <div className="flex items-center justify-between w-full md:w-auto">
            <Link to="/">
              <div className="w-40">
                <Logo />
              </div>
            </Link>
          </div>
          <div>
            <div className="flex items-center space-x-4">
              <p>Already have an account?</p>
              <button
                className="pb-1 font-semibold border-b-2 text-sflPrimary"
                onClick={handleRedirectToLoginPage}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
