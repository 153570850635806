import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDebounce } from "use-debounce";

import ReactTable from "components/lib/Global/ReactTable";
import Sidebar from "components/Applications/Sidebar";
import HeroSection from "components/Applications/HeroSection";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import OrgAdminDashboardWrapper from "components/lib/Global/OrgAdminDashboardWrapper";
import SearchInput from "components/lib/Shared/SearchInput";
import Unauthorized from "pages/Unauthorized";
import SubRowAsync from "./SubRow";
import { getCompaniesAsync } from "state/slices/companies";
import Checkbox from "components/lib/Shared/Checkbox";
import GoBackToHomeBtn from "../GoBackToHomeBtn";
import { getCompanyNameWithSuffix } from "utils/company";

const statuses = [
  { value: "Pending Incorporation", label: "Pending Incorporation" },
  { value: "BRS Review", label: "BRS Review" },
  { value: "BRS Review II", label: "BRS Review II" },
  { value: "BRS Corrections", label: "BRS Corrections" },
  { value: "Incorporation Deferred", label: "Incorporation Deferred" },
  { value: "Vetting", label: "Vetting" },
];

export default function BRSSubmission() {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useAuth0();
  const location = useLocation();
  const history = useHistory();

  const { getUserStatus } = useSelector((state) => state.authSlice);
  const { getCompanies, companies } = useSelector(
    (state) => state.companiesSlice
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState(() =>
    statuses.map((status) => status.value)
  );

  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (isAuthenticated) {
      const queryParams = new URLSearchParams(location.search);
      if (debouncedSearchTerm) setCurrentPage(1);
      const status = queryParams.getAll("status");
      setFilters(queryParams.getAll("status"));
      dispatch(
        getCompaniesAsync({
          limit: rowsPerPage,
          skip: (currentPage - 1) * rowsPerPage,
          search: debouncedSearchTerm,
          status: status.length > 0 ? status : null,
          order: "asc",
        })
      );
    }
  }, [
    isAuthenticated,
    rowsPerPage,
    currentPage,
    debouncedSearchTerm,
    location.search,
  ]);

  const currentUser = useSelector((state) => state.userSlice);

  const refreshTableData = async () => {
    dispatch(
      getCompaniesAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm,
        status: [
          "Pending Incorporation",
          "BRS Review",
          "BRS Corrections",
          "Incorporation Deferred",
          "Vetting",
        ],
        order: "asc",
      })
    );
  };

  // this function will render our row sub components
  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <SubRowAsync
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          refreshTableData={refreshTableData}
        />
      );
    },
    []
  );

  if (!currentUser?._id || getUserStatus.status === "loading") {
    return (
      <OrgAdminDashboardWrapper>
        <div className="flex justify-center mx-auto mt-10 text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </OrgAdminDashboardWrapper>
    );
  }

  if (currentUser?.role !== 9 && getUserStatus.status !== "loading") {
    return <Unauthorized />;
  }

  const columns = [
    {
      Header: "Company Name",
      accessor: "companyName",
      className: "p-4 whitespace-normal max-w-[150px]",
      Cell: ({ row }) => getCompanyNameWithSuffix(row.original),
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row }) => row.original.type,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        if (row.original.status.includes("II")) {
          return (
            <>
              {row.original.status}{" "}
              <span className="items-center gap-x-1.5 rounded-full px-2 py-1 text-xxs font-medium text-gray-900 ring-1 ring-inset ring-green-primary bg-green-100/25 tracking-wide">
                Corrected
              </span>
            </>
          );
        }
        if (
          ["Admin Review", "Submission", "BRS Review"].includes(
            row.original.status
          )
        ) {
          return (
            <>
              {row.original.status}{" "}
              <span className="items-center gap-x-1.5 rounded-full px-2 py-1 text-xxs font-medium text-gray-900 ring-1 ring-inset ring-primary-200 bg-purple-100/25 tracking-wide">
                New
              </span>
            </>
          );
        }
        return row.original.status;
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            {...row?.getToggleRowExpandedProps()}
            className={`text-gray-900 group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
          >
            {row.isExpanded ? "Close" : "View"}
          </button>
        );
      },
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  const handleFilterChange = (e) => {
    const params = new URLSearchParams();
    if (e.target.checked) {
      const tempFilters = [...filters, e.target.value];
      tempFilters.forEach((status) => params.append("status", status));
      history.push({ search: params.toString() });
      setFilters([...filters, e.target.value]);
    } else {
      const tempFilters = filters.filter((filter) => filter !== e.target.value);
      tempFilters.forEach((status) => params.append("status", status));
      history.push({ search: params.toString() });
      setFilters(tempFilters);
    }
    setCurrentPage(1);
  };

  return (
    <OrgAdminDashboardWrapper>
      <div className=" grid gap-4 py-10 mx-auto">
        <HeroSection />
        <GoBackToHomeBtn />
        <div className="grid grid-cols-6 border-t border-gray">
          <div className="col-span-6 pr-8 md:col-span-1">
            <Sidebar />
          </div>
          <div className="col-span-6 md:border-l md:col-span-5 md:pl-8 pt-8 border-gray">
            <div className="mb-6">
              <h1 className="text-title5">BRS Submitted</h1>
            </div>
            <div className="flex items-center justify-between my-2">
              <div className="flex items-center space-x-4">
                <h2 className="font-semibold">Filter by:</h2>
                <div className="flex space-x-2 my-2">
                  {statuses.map((status) => (
                    <Checkbox
                      key={status.value}
                      name={status.label}
                      label={status.label}
                      checked={filters.includes(status.value)}
                      onChange={handleFilterChange}
                    />
                  ))}
                </div>
              </div>
              <SearchInput value={searchTerm} handleOnChange={handleSearch} />
            </div>
            {companies.totalCount > 0 ? (
              <ReactTable
                columns={columns}
                data={companies.items}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                totalRows={companies.totalCount}
                setCurrentPage={setCurrentPage}
                paginate
                headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-slate-200"
                headingStyle="pl-4 text-left"
                dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
                renderRowSubComponent={renderRowSubComponent}
                isLoading={getCompanies.status === "loading"}
              />
            ) : (
              <p className="text-2xl text-center text-primary">
                No applications available.
              </p>
            )}
          </div>
        </div>
      </div>
    </OrgAdminDashboardWrapper>
  );
}
