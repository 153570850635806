import { object, number, string, boolean } from "yup";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";

/**
 * This returns an object composed of conditional `yup` schema and initial values
 * related to share allocation and beneficial ownership form.
 *
 * @param {string} idType The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {object} beneficialOwnershipForm - Object containing fields related to BO form.
 * @param {Boolean} renderLongForm Render long form `true` | `false`.
 * @return {object} This returns object containing schema and initialValues based on schema.
 * @example
 *  {
 *    schema // yup schema
 *    schemaInitialValues
 *  }
 */
const beneficialOwnershipFormValidation = (
  idType,
  beneficialOwnershipForm,
  renderLongForm
) => {
  const {
    maxPercentShareholding = 0,
    maxPercentVotingRights = 0,
    directPercentShareholding,
    indirectPercentShareholding,
    directPercentVotingRights,
    indirectPercentVotingRights,
  } = beneficialOwnershipForm || {};

  const schema = object().shape({
    renderLongForm: boolean().default(renderLongForm),
    showShareAllocationForm: boolean().default(true),
    beneficialOwnershipForm: object()
      .when("showShareAllocationForm", {
        is: true,
        then: object().shape({
          maxPercentShareholding: number().required(
            "Max percent shareholding is required"
          ),
          directPercentShareholding: number().when("idType", {
            is: () => INDIVIDUALS.includes(idType),
            then: number()
              .min(0, "Value must be greater than or equal to 0")
              .max(
                maxPercentShareholding - indirectPercentShareholding,
                `${
                  indirectPercentShareholding > 0
                    ? `Sum of direct and indirect shares can not be more than ${maxPercentShareholding}`
                    : `Value must be less than or equal to ${maxPercentShareholding}`
                }`
              )
              .required("Direct percent shareholding is required"),
          }),
          indirectPercentShareholding: number().when("maxPercentShareholding", {
            is: (val) => val > 0,
            then: number()
              .min(0, "Value must be greater than or equal to 0")
              .max(
                maxPercentShareholding - directPercentShareholding,
                `${
                  directPercentShareholding > 0
                    ? `Sum of direct and indirect shares can not be more than ${maxPercentShareholding}`
                    : `Value must be less than or equal to ${maxPercentShareholding}`
                }`
              )
              .required("Indirect percent shareholding is required"),
            otherwise: number().notRequired(),
          }),
          maxPercentVotingRights: number().required(
            "Max percent voting rights is required"
          ),
          directPercentVotingRights: number().when("idType", {
            is: () => INDIVIDUALS.includes(idType),
            then: number()
              .min(0, "Value must be greater than or equal to 0")
              .max(
                maxPercentVotingRights - indirectPercentVotingRights,
                `${
                  indirectPercentVotingRights > 0
                    ? `Sum of direct and indirect voting rights can not be more than ${maxPercentVotingRights}`
                    : `Value must be less than or equal to ${maxPercentVotingRights}`
                }`
              )
              .required("Direct percent voting rights is required"),
          }),
          indirectPercentVotingRights: number().when("maxPercentVotingRights", {
            is: (val) => val > 0,
            then: number()
              .min(0, "Value must be greater than or equal to 0")
              .max(
                maxPercentVotingRights - directPercentVotingRights,
                `${
                  directPercentVotingRights > 0
                    ? `Sum of direct and indirect voting rights can not be more than ${maxPercentVotingRights}`
                    : `Value must be less than or equal to ${maxPercentVotingRights}`
                }`
              )
              .required("Indirect percent voting rights is required"),
            otherwise: number().notRequired(),
          }),
          directRightRemoveDirector: string().required(
            "Please select one option"
          ),
          indirectRightRemoveDirector: string().required(
            "Please select one option"
          ),
          directCompanyControlRight: string().required(
            "Please select one option"
          ),
          indirectCompanyControlRight: string().required(
            "Please select one option"
          ),
          dateOfBecomingBO: string().when("renderLongForm", {
            is: () => renderLongForm === true,
            then: string().required("Date of becoming BO is required"),
            otherwise: string().notRequired(),
          }),
          sourceOfBOInformation: string().required(
            "Source of beneficial owner information is required"
          ),
        }),
      })
      .default({}),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default beneficialOwnershipFormValidation;
