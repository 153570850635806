import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _set from "lodash/set";

import officialDataService from "services/official";
import companyDashboardDataService from "services/companyDashboard";

/* if these become too many, considering using https://redux-toolkit.js.org/api/createSlice#the-extrareducers-map-object-notation */

export const addCompanyOfficialAsync = createAsyncThunk(
  "companyOfficials/create",
  async ({ companyId, data }) => {
    const res = await officialDataService.create({ companyId, ...data });
    return res.data;
  }
);

export const updateCompanyOfficialAsync = createAsyncThunk(
  "companyOfficials/update",
  async ({ officialId, data }) => {
    const res = await officialDataService.update(officialId, data);
    return res.data;
  }
);

export const deleteCompanyOfficialAsync = createAsyncThunk(
  "companyOfficials/delete",
  async ({ officialId, idType, companyId }) => {
    const res = await officialDataService.remove(officialId, idType, companyId);
    return res.data;
  }
);

export const getCompanyOfficialsAsync = createAsyncThunk(
  "companyOfficials/getCompanyOfficialsAsync",
  async ({ companyId, queryParams }, thunkAPI) => {
    try {
      const res = await companyDashboardDataService.getCompanyOfficials(
        companyId,
        queryParams
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  companyOfficials: {
    items: [],
    totalCount: 0,
  },
  getCompanyOfficialsStatus: {
    status: "loading",
    error: false,
  },
};

const companyOfficialsSlice = createSlice({
  name: "Company Officials Slice",
  initialState,
  reducers: {
    addCompanyOfficial: (state, action) => {
      state.companyOfficials.items.push(action.payload.official);
    },
    updateOfficialsNestedField: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
    deleteCompanyOfficial: (state, { payload }) => {
      const officialId = payload.id || payload._id;
      const index = state.companyOfficials.items.findIndex(
        (item) => officialId === item.id || officialId === item._id
      );
      if (index > -1) state.companyOfficials.items.splice(index, 1);
    },
    updateCompanyOfficial: (state, { payload: { official } }) => {
      const id = official._id;
      const index = state.companyOfficials.items.findIndex(
        (item) => id === item._id
      );
      if (index > -1) state.companyOfficials.items.splice(index, 1, official);
    },
    resetCompanyOfficialsTableSlice: () => {
      return initialState;
    },
  },
  extraReducers: {
    [addCompanyOfficialAsync.fulfilled]: (state, action) => {
      state.companyOfficials.items.push(action.payload.official);
    },
    [updateCompanyOfficialAsync.fulfilled]: (
      state,
      { payload: { official } }
    ) => {
      const id = official._id;
      const index = state.companyOfficials.items.findIndex(
        (item) => id === item._id
      );
      if (index > -1) state.companyOfficials.items.splice(index, 1, official);
    },
    [deleteCompanyOfficialAsync.fulfilled]: (state, { payload }) => {
      const officialId = payload.id || payload._id;
      const index = state.companyOfficials.items.findIndex(
        (item) => officialId === item.id || officialId === item._id
      );
      if (index > -1) state.companyOfficials.items.splice(index, 1);
    },

    [getCompanyOfficialsAsync.pending]: (state) => {
      state.getCompanyOfficialsStatus.status = "loading";
      state.getCompanyOfficialsStatus.error = false;
    },
    [getCompanyOfficialsAsync.fulfilled]: (state, action) => {
      state.getCompanyOfficialsStatus.status = "idle";
      state.getCompanyOfficialsStatus.error = false;
      state.companyOfficials = action.payload;
    },
    [getCompanyOfficialsAsync.rejected]: (state, action) => {
      state.getCompanyOfficialsStatus.status = "failed";
      state.getCompanyOfficialsStatus.error = action.payload.messageText;
    },
  },
});

export const {
  addCompanyOfficial,
  updateCompanyOfficial,
  deleteCompanyOfficial,
  updateOfficialsNestedField,
  resetCompanyOfficialsTableSlice,
} = companyOfficialsSlice.actions;

export default companyOfficialsSlice.reducer;
