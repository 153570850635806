import React, { useState } from "react";
import { Menu } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  PencilIcon,
  TrashIcon,
  EyeIcon,
  ChevronLeftIcon,
} from "@heroicons/react/outline";

import ConfirmDialog from "components/lib/Shared/ConfirmDialog";
import TableActionMenuWrapper from "./TableActionMenuWrapper";
import { closeModal, openModal } from "state/slices/modals";

const TableActionMenu = ({
  row,
  disableEditBtn,
  disableDeleteBtn,
  disableReviewBtn,
  hideEditBtn,
  hideDeleteBtn,
  showViewBtn,
  showCorrectedBtn,
  showRecallBtn,
  children,
  showDeleteConfirmation = true,
  deleteConfirmationLabel = "Are you sure you want to permanently delete this entry?",
  showReviewBtn = true,
  showResendInviteBtn,
  renderViewAsSubRow = false,
  handleEdit = () => null,
  handleDelete = () => null,
  handleView = () => null,
  handleCorrected = () => null,
  handleRecall = () => null,
  handleReview = () => null,
  handleResendInvite = () => null,
}) => {
  const dispatch = useDispatch();
  const { modalRow } = useSelector((state) => state.modalsSlice);

  let reviewBtnProps = {};
  if (row?.getToggleRowExpandedProps) {
    reviewBtnProps = row?.getToggleRowExpandedProps();
  }

  const originalRow = row.original;

  const renderReviewBtn =
    showReviewBtn &&
    originalRow.type &&
    originalRow?.type === "invited" &&
    originalRow?.inviteStatus === "Data Pending Review";

  return (
    <div>
      <TableActionMenuWrapper>
        {showResendInviteBtn && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleResendInvite(originalRow)}
                className={`${
                  active ? "bg-violet-500 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                Resend invite
              </button>
            )}
          </Menu.Item>
        )}
        {showViewBtn && (row.isExpanded ? !renderReviewBtn : true) && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleView(originalRow)}
                {...(renderViewAsSubRow ? reviewBtnProps : {})}
                className={`${
                  active ? "bg-violet-500 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                {row.isExpanded ? (
                  "Close"
                ) : (
                  <>
                    <EyeIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                    View
                  </>
                )}
              </button>
            )}
          </Menu.Item>
        )}
        {showCorrectedBtn && (row.isExpanded ? !renderReviewBtn : true) && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleCorrected(originalRow)}
                {...(renderViewAsSubRow ? reviewBtnProps : {})}
                className={`${
                  active ? "bg-violet-500 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                {row.isExpanded ? (
                  "Close"
                ) : (
                  <>
                    <PencilIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                    Correct
                  </>
                )}
              </button>
            )}
          </Menu.Item>
        )}
        {!hideEditBtn && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleEdit(originalRow)}
                disabled={disableEditBtn}
                className={`${
                  active && !disableEditBtn
                    ? "bg-violet-500 text-white"
                    : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                <PencilIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                Edit
              </button>
            )}
          </Menu.Item>
        )}
        {renderReviewBtn && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleReview(originalRow)}
                disabled={disableReviewBtn}
                {...reviewBtnProps}
                className={`${
                  active ? "bg-violet-500 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                {row.isExpanded ? "Close" : "Review"}
              </button>
            )}
          </Menu.Item>
        )}
        {showRecallBtn && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => handleRecall(originalRow)}
                className={`${
                  active ? "bg-violet-500 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                <ChevronLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                Recall
              </button>
            )}
          </Menu.Item>
        )}
        {!hideDeleteBtn && (
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() =>
                  showDeleteConfirmation
                    ? dispatch(
                        openModal({
                          modalName: "delete_confirmation_modal",
                          modalRow: originalRow,
                        })
                      )
                    : handleDelete(originalRow)
                }
                disabled={disableDeleteBtn}
                className={`${
                  active && !disableDeleteBtn
                    ? "bg-red-600 text-white"
                    : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                <TrashIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                Delete
              </button>
            )}
          </Menu.Item>
        )}
        {children}
      </TableActionMenuWrapper>
      <ConfirmDialog
        name="delete_confirmation_modal"
        onClose={() => dispatch(closeModal("delete_confirmation_modal"))}
        onConfirm={() => handleDelete(modalRow)}
      >
        {deleteConfirmationLabel}
      </ConfirmDialog>
    </div>
  );
};

export default TableActionMenu;
