import React from "react";

function TypeOfControlColumn({ fields = [] }) {
  if (fields.length === 0) return "N/A";

  return (
    <table class="table-auto">
      <thead>
        <tr>
          <th></th>
          <th className="border-b-2 border-gray-300/50 p-1 text-center px-4">
            Direct
          </th>
          <th className="border-b-2 border-gray-300/50 p-1 text-center px-4">
            Indirect
          </th>
        </tr>
      </thead>
      <tbody className="">
        {fields.map((field, index) => {
          return (
            <tr key={`${field.key}_${index}`}>
              <td
                className={`text-sm text-primary text-left py-1 ${
                  index !== 0 ? "border-t-2 border-white" : ""
                }`}
              >
                {field.key}
              </td>
              <td className="border-r-2 border-gray-300/50 p-1 text-center w-24">
                {typeof field.value.direct === "number"
                  ? `${field.value.direct}%`
                  : field.value.direct}
              </td>
              <td className="p-2 text-center w-24">
                {typeof field.value.indirect === "number"
                  ? `${field.value.indirect}%`
                  : field.value.indirect}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TypeOfControlColumn;
