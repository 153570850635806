/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import CompanyOfficialsAuthorizedDirectorForm from "../AssembledCompanyOfficialForms/CompanyOfficialsAuthorizedDirector";
import CompanyOfficialShareholder from "../AssembledCompanyOfficialForms/CompanyOfficialShareholder";
import CompanySecretaryForms from "../AssembledCompanyOfficialForms/CompanySecretaryForms";
import JointShareholderForms from "../AssembledCompanyOfficialForms/JointComponents/JointShareholderForms";

const CompanyOfficialsForms = (props) => {
  const {
    values,
    mode,
    toggleShowOfficialsForm,
    currentOfficial,
    showJSForm,
    showShareAllocation,
    toggleShowJSForm,
    selectedTab,
    isFirstStep,
    isLastStep,
    goToPrevStep,
    goToNextStep,
    goToAssignOfficialsTab,
    isViewOnly,
    setTabsClickable,
  } = props;
  const { designation, idType, isDirector } = values;

  if (idType) {
    if (
      designation &&
      idType &&
      (isDirector === "Yes" || isDirector === "No" || idType === "Minor")
    ) {
      if (designation === "Joint Shareholder" && showJSForm) {
        return (
          <JointShareholderForms
            {...values}
            showShareAllocation={showShareAllocation}
            mode={mode}
            toggleShowJSForm={toggleShowJSForm}
            toggleShowOfficialsForm={toggleShowOfficialsForm}
            selectedTab={selectedTab}
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            goToPrevStep={goToPrevStep}
            goToNextStep={goToNextStep}
            goToAssignOfficialsTab={goToAssignOfficialsTab}
            isViewOnly={isViewOnly}
            setTabsClickable={setTabsClickable}
          />
        );
      }
    }
    if (designation === "Director" || designation === "Authorized Person") {
      return (
        <CompanyOfficialsAuthorizedDirectorForm
          {...values}
          toggleShowOfficialsForm={toggleShowOfficialsForm}
          currentOfficial={currentOfficial}
          mode={mode}
          selectedTab={selectedTab}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          goToPrevStep={goToPrevStep}
          goToNextStep={goToNextStep}
          isViewOnly={isViewOnly}
          setTabsClickable={setTabsClickable}
        />
      );
    }
    if (
      designation === "Shareholder" ||
      designation === "Director Shareholder"
    ) {
      return (
        <CompanyOfficialShareholder
          {...values}
          toggleShowOfficialsForm={toggleShowOfficialsForm}
          currentOfficial={currentOfficial}
          mode={mode}
          selectedTab={selectedTab}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          goToPrevStep={goToPrevStep}
          goToNextStep={goToNextStep}
          isViewOnly={isViewOnly}
          setTabsClickable={setTabsClickable}
        />
      );
    }
    if (designation === "Company Secretary") {
      return (
        <CompanySecretaryForms
          {...values}
          toggleShowOfficialsForm={toggleShowOfficialsForm}
          currentOfficial={currentOfficial}
          mode={mode}
          selectedTab={selectedTab}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          goToPrevStep={goToPrevStep}
          goToNextStep={goToNextStep}
          isViewOnly={isViewOnly}
          setTabsClickable={setTabsClickable}
        />
      );
    }
  }
  return null;
};

export default CompanyOfficialsForms;
