import React from "react";
import FileUploads from "components/CorporateOnboarding/SummaryTab/FileUploads";

function MinuteBook() {
  return (
    <div className="px-8 pt-5 pb-6 bg-white mt-4">
      <FileUploads hideResolveAllCheckbox />
    </div>
  );
}

export default MinuteBook;
