import { object, string, boolean, number, array } from "yup";
import _sortBy from "lodash/sortBy";
import { Constants } from "config/constants";

const schemaGenerator = ({ takenSharesCategories, minSharesNumber, shares }) =>
  object().shape({
    sharesCategory: string()
      .notOneOf(
        takenSharesCategories.filter(
          (category) => category !== Constants.PREFERENCE_SHARES
        ),
        "Class of shares already taken"
      )
      .required("Share category is required"),
    sharesNumber: number()
      .required("Number of shares required")
      .min(
        minSharesNumber,
        `Number of shares cannot be less than ${minSharesNumber}`
      ),
    sharesValue: number()
      .required("Value of shares required")
      .positive("Value of shares must be a positive number"),
    showCustomSharesCategory: boolean(),
    customSharesCategory: string()
      .trim("Share category cannot include leading and trailing spaces")
      .strict(true)
      .when("showCustomSharesCategory", {
        is: true,
        then: string()
          .test({
            name: "customSharesCategory",
            test: function (value) {
              if (
                this.parent.sharesCategory === "Other Shares" &&
                [
                  "ordinary shares",
                  "preference shares",
                  "other shares",
                ].includes(value?.toLowerCase())
              ) {
                return this.createError({
                  message: `Custom class of share cannot be '${value}'`,
                });
              }
              if (
                this.parent.sharesCategory === "Preference Shares" &&
                ["ordinary shares", "other shares"].includes(
                  value?.toLowerCase()
                )
              ) {
                return this.createError({
                  message: `Custom class of share cannot be '${value}'`,
                });
              }
              if (takenSharesCategories.includes(value)) {
                return this.createError({
                  message: "Class of shares already taken",
                });
              }
              return true;
            },
          })
          .required("Share category is required"),
      }),
    hasVotingRights: string().required("Required"),
    rights: array()
      .when("sharesCategory", {
        is: (sharesCategory) => {
          if (sharesCategory === Constants.PREFERENCE_SHARES) return true;
          else return false;
        },
        then: array()
          .required("Select at least two rights")
          .min(2, `Select at least two rights`)
          .max(4, `You can only select four rights`),
      })
      .test("len", "", function (value) {
        const { sharesCategory } = this.parent;
        const titlePrefix = _sortBy(value, ["order"])
          .map(({ label }) => label)
          .join(" ");
        let combinationAlreadyExists = false;
        shares.map(({ hasVotingRights, rights }) => {
          const tempTitlePrefix = _sortBy(rights, ["order"])
            .map(({ label }) => label)
            .join(" ");
          if (
            hasVotingRights === this.parent.hasVotingRights &&
            !!titlePrefix &&
            titlePrefix === tempTitlePrefix
          )
            combinationAlreadyExists = true;
        });
        const affiliatedRights = titlePrefix.split(" ");
        if (
          sharesCategory === Constants.PREFERENCE_SHARES &&
          affiliatedRights.length < 2
        ) {
          return this.createError({
            message: "Select at least two rights",
          });
        }
        if (
          !(
            affiliatedRights.includes("Redeemable") ||
            affiliatedRights.includes("Non-Redeemable")
          )
        ) {
          return this.createError({
            message:
              "At least one right should be 'Redeemable' or 'Non-Redeemable'",
          });
        }
        if (
          combinationAlreadyExists &&
          sharesCategory === Constants.PREFERENCE_SHARES
        )
          return this.createError({
            message: "This combination of rights already exists",
          });
        return true;
      }),
  });

export default schemaGenerator;
