/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from "react";
import {
  Formik,
  Form,
  Field,
  setNestedObjectValues,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
import { useSelector, useDispatch } from "react-redux";
import _omit from "lodash/omit";
import _pickBy from "lodash/pickBy";
import _pick from "lodash/pick";
import _values from "lodash/values";
import _merge from "lodash/merge";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import { XIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";

import Label from "components/lib/Shared/Label";
import getInitialValues from "./getInitialValues";
import getValidationSchema from "./getValidationSchema";
import { Constants } from "config/constants";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import NamesForm from "components/PLCTABS/CompanyOfficialsTab/NamesComponents/index";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import { OWNERS_ROLES } from "components/PLCTABS/SFLdata/config/constants";
import IdentificationDetailsForm from "components/PLCTABS/CompanyOfficialsTab/PersonalDetailsComponents/index";
import AddressParticulars from "components/PLCTABS/BeneficialOwnershipTab/BeneficialOwnersAddressParticulars";
import ValidationError from "components/lib/Shared/ValidationError";
import Tabs from "components/lib/Shared/Tabs";
import Verify from "components/lib/Shared/Verify";
import { handleFilesUpload } from "utils/filesUtils";
import { getFullName } from "utils";
import { closeModal } from "state/slices/modals";
import {
  addOrganizationPartner,
  updateOrganizationPartner,
} from "state/slices/tables/partnersSlice";
import InviteNewUser from "components/lib/Shared/Verify/InviteNewUser";
import InviteExistingUser from "components/lib/Shared/Verify/InviteExistingUser";
import SectionDescription from "components/lib/Shared/SectionDescription";
import ErrorBanner from "components/lib/Shared/ErrorBanner";
import Button from "components/lib/Shared/Button";
import CommentsList from "components/lib/Global/CommentsList";

const initialTabs = [{ id: "assignOfficials", label: "Assign Officials" }];

const longFormTabs = [
  { id: "assignOfficials", label: "Assign Officials" },
  { id: "selectRole", label: "Role" },
  { id: "generalInformation", label: "General Information" },
  { id: "residentialDetails", label: "Residential Details" },
  { id: "uploadDocuments", label: "Upload Documents" },
];

const formFields = [
  "role",
  "inviteStatus",
  "names",
  "identificationDetails",
  "isNonResident",
  "postalAddress",
  "electronicAddress",
  "residentialAddressDetails",
  "fileUploads",
];

const OwnershipSelection = (props) => {
  const {
    mode,
    setShowPartnerBtn,
    currentOfficial,
    isViewOnly,
    setTabsClickable = () => null,
  } = props || {};

  const params = useParams();
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState({
    id: "assignOfficials",
    label: "Assign Officials",
  });
  const [validationSchema, setValidationSchema] = useState(null);
  const [initialValues, setInitialValues] = useState({});

  // Verify component related state
  const [showIdentificationSection, setShowIdentificationSection] =
    useState(true);
  const [verificationValue, setVerifyingValue] = useState("");
  const [isIdAssociated, setIsIdAssociated] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [inviteNewUserWorkflow, setInviteNewUserWorkflow] = useState(false);
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);
  const [isAccessRevoked, setIsAccessRevoked] = useState(false);
  const [inviteExistingUser, setInviteExistingUser] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const formStepErrors = useRef(null);
  const currentUser = useSelector((state) => state.userSlice);

  const { organization } = useSelector((state) => state.organizationsSlice);
  const { partnershipType = "" } = organization;
  const { comments, transformedComments } = useSelector(
    (state) => state.commentsSlice
  );

  const dispatch = useDispatch();

  const goToPrevStep = () => {
    if (selectedTabIndex === 0) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  const handleExitWorkflow = () => {
    setShowPartnerBtn(true);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  const addOrEditOfficial = async (values) => {
    const uploadedFiles = await handleFilesUpload(
      values.fileUploads,
      currentOfficial,
      mode.editing
    );

    let payload = {
      ..._omit(values, ["fileUploads", "comments"]),
      fileUploads: {
        ..._pickBy(values.fileUploads, (file) => typeof file === "string"),
        ...uploadedFiles,
      },
      names: {
        ...values.names,
        fullName: getFullName(values.names),
      },
    };

    if (!mode.editing)
      await dispatch(
        addOrganizationPartner({
          corporateId: params.corporateId,
          data: payload,
        })
      );
    else {
      const isOwnRow = [
        values?.electronicAddress?.emailAddress,
        values?.identificationDetails?.emailAddress,
      ].includes(currentUser.electronicAddress.emailAddress);
      const isApplicant = currentUser?._id === organization?.createdBy?._id;

      if (isOwnRow && payload.type === "invited" && !isApplicant) {
        payload.inviteStatus = "Data Pending Review";
      }

      const updatedOfficialData = {};
      formFields.map((formField) => {
        if (!_isEqual(props.currentOfficial[formField], payload[formField])) {
          updatedOfficialData[formField] = payload[formField];
        }
      });
      await dispatch(
        updateOrganizationPartner({
          partnerId: payload._id,
          data: { ...updatedOfficialData, idType: payload.idType },
        })
      );
      dispatch(closeModal("partners_edit_modal"));
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        role: [],
        ...initialValues,
        // ...currentOfficial,
        comments:
          transformedComments?.[currentOfficial?._id]?.["ownership"] ?? {},
      }}
      validate={(values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            makeFieldsOptional: isIdAssociated === false || isViewOnly,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={async (values, { resetForm }) => {
        // validate form & store errors for the ErrorBanner
        try {
          validateYupSchema(values, validationSchema, true);
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: [],
          };
        } catch (error) {
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: error.errors,
          };
          if (!isLastStep) {
            setTimeout(goToNextStep, Constants.DELAY_FORM_SUBMISSION);
            return;
          }
        }
        if (!isLastStep) {
          goToNextStep();
          return;
        }
        const officialSchema = getValidationSchema({
          idType: values.idType,
          isNonResident: values.isNonResident,
          documentData: getDocumentData(values.idType),
        });
        console.log({ values });
        try {
          setIsFormValid(true);
          validateYupSchema(values, officialSchema, true);
        } catch (error) {
          console.log({ error });
          if (error.errors.length > 0) {
            setIsFormValid(false);
            return;
          }
        }
        if (!isViewOnly) await addOrEditOfficial(values);
        dispatch(closeModal("partners_edit_modal"));
        setShowPartnerBtn(true);
        resetForm();
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitCount,
        setFieldValue,
        handleSubmit,
        setTouched,
      }) => {
        const isContactPerson =
          [
            values?.electronicAddress?.emailAddress,
            values?.identificationDetails?.emailAddress,
          ].includes(currentUser.electronicAddress.emailAddress) &&
          values?.type === "invited";

        useEffect(() => {
          if (isContactPerson) {
            setFieldValue(
              "identificationDetails.companyContactName",
              getFullName(currentUser?.names)
            );
            setFieldValue(
              "identificationDetails.telNumber",
              currentUser?.electronicAddress?.mobileNumber ?? ""
            );
          }
        }, [isContactPerson]);

        useEffect(() => {
          setVerifyingValue("");
          setIsIdAssociated(null);
          setInviteNewUserWorkflow(false);
          setIsAccessAllowed(false);
          setIsAccessRevoked(false);
          if (!mode.editing || isViewOnly) setDisableForm(true);
        }, [values.idType]);

        // update initial values based on current official
        useEffect(() => {
          setInitialValues(_merge(values, currentOfficial));
        }, [currentOfficial]);

        // update initial values based on current form step
        useEffect(() => {
          const documentData = getDocumentData(values.idType);
          setInitialValues(
            _merge(
              getInitialValues({
                idType: values.idType,
                isNonResident: values.isNonResident,
                documentData,
              }),
              values
            )
          );
        }, [values.idType, values.isNonResident]);

        const [selectedRoles, setSelectedRoles] = useState(
          currentOfficial?.role?.map((role) => ({ label: role, value: role }))
        );

        useEffect(() => {
          setSelectedRoles(
            currentOfficial?.role?.map((role) => ({ label: role, value: role }))
          );
        }, [currentOfficial]);

        const handleRoleSelect = (name) => (option) => {
          const roles = Array.isArray(option)
            ? option.map(({ value }) => value)
            : option?.value
            ? [option?.value]
            : [];
          setFieldValue(name, roles);
          setSelectedRoles(option);
        };

        // Update validation schema based on current form step
        useEffect(() => {
          const documentData = getDocumentData(values.idType);
          setValidationSchema(
            getValidationSchema({
              selectedTab,
              idType: values.idType,
              isNonResident: values.isNonResident,
              documentData,
            }).schema
          );
        }, [selectedTab, values.idType, values.isNonResident]);

        // update form steps based on long or short form
        useEffect(() => {
          let tempTabs = longFormTabs;
          if (mode.editing) {
            tempTabs = tempTabs.filter((tab) => tab.id !== "assignOfficials");
          }
          setTabs(tempTabs);
        }, [values, mode.editing, partnershipType]);

        // Reset touched fields on tab change, e.g fix errors jumping around
        useEffect(() => {
          setTouched({}, false);
          setIsFormValid(true);
        }, [selectedTab, setTouched]);

        // Invite new user form
        if (inviteNewUserWorkflow && selectedTab.id !== "assignOfficials") {
          return (
            <>
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                />
              </div>
              <Button
                className="flex ml-auto my-2"
                onClick={handleExitWorkflow}
                variant="text"
              >
                <XIcon className="block w-6 h-6" aria-hidden="true" />
              </Button>
              <InviteNewUser
                closeVerifyProcess={handleExitWorkflow}
                idType={values.idType}
                designation={"Partner"}
                values={values}
                inviteOfficial={addOrEditOfficial}
              />
            </>
          );
        }

        if (inviteExistingUser) {
          return (
            <>
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                />
              </div>
              <Button
                className="flex ml-auto my-2"
                onClick={handleExitWorkflow}
                variant="text"
              >
                <XIcon className="block w-6 h-6" aria-hidden="true" />
              </Button>
              <InviteExistingUser
                userId={values.userId}
                idType={values.idType}
                designation={"Partner"}
                closeVerifyProcess={handleExitWorkflow}
                userEmailAddress={values.userEmailAddress}
                values={values}
                inviteOfficial={addOrEditOfficial}
              />
            </>
          );
        }

        const handleNewUserWorkflow = () => {
          if (Object.keys(errors).length > 0) {
            setTouched(setNestedObjectValues(errors, true));
            return;
          } else setInviteNewUserWorkflow(true);
        };

        const handleResetForm = () => {
          setFieldValue("names", initialValues.names);
          setFieldValue(
            "identificationDetails",
            initialValues.identificationDetails
          );
          setFieldValue("postalAddress", initialValues.postalAddress);
          setFieldValue("electronicAddress", initialValues.electronicAddress);
          setFieldValue(
            "residentialAddressDetails",
            initialValues.residentialAddressDetails
          );
          setFieldValue("fileUploads", initialValues.fileUploads);
          formStepErrors.current = null;
        };

        const stakeholderComments = comments.filter(
          (comment) =>
            comment?.officialId &&
            comment.officialId === values._id &&
            comment?.tabName &&
            comment.tabName === "ownership"
        );

        const roleComments = stakeholderComments.filter(
          (comment) => comment.formName === "role"
        );

        return (
          <Form>
            {/* Owner's form tabs */}
            {
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                />
              </div>
            }
            {isIdAssociated === false &&
              formStepErrors.current?.[selectedTab.id]?.length > 0 && (
                <ErrorBanner
                  errors={[...(formStepErrors.current?.[selectedTab.id] ?? [])]}
                  className="my-4"
                />
              )}
            {verificationValue &&
              isIdAssociated === false &&
              selectedTab.id &&
              ["residentialDetails", "uploadDocuments"].includes(
                selectedTab.id
              ) &&
              !mode.editing && (
                <Button onClick={handleNewUserWorkflow} className="mt-2">
                  Invite
                </Button>
              )}
            {!mode.editing && (
              <Button
                className="flex ml-auto"
                onClick={handleExitWorkflow}
                variant="text"
              >
                <XIcon className="block w-6 h-6" aria-hidden="true" />
              </Button>
            )}
            {
              <>
                {selectedTab.id === "assignOfficials" && !mode.editing && (
                  <div className="px-4 py-6 mt-4 mb-2 bg-white rounded-sm shadow-sm sm:p-6 sm:rounded md:rounded-md">
                    <div className="col-span-6 sm:col-span-3">
                      <Label htmlFor="ownerIdType">Select ID Types</Label>
                      <Field
                        name="idType"
                        component={ReactSelectWithFormik}
                        options={Constants.OWNERS_ID_TYPES}
                        id="ownerIdType"
                        placeholder="Select id type"
                      />
                    </div>
                  </div>
                )}
                {values.idType && (
                  <>
                    {selectedTab.id === "selectRole" && (
                      <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                        <div className="px-4 py-6 sm:p-6">
                          <h2 className="mb-1 text-lg font-medium leading-6 text-gray-900">
                            Role
                          </h2>
                          <Field
                            onChange={handleRoleSelect("role")}
                            name="role"
                            component={ReactSelectWithFormik}
                            options={OWNERS_ROLES[partnershipType]}
                            value={selectedRoles}
                            id="role"
                            placeholder="Select role"
                            resolvedFields={_get(values, "resolvedFields")}
                            isDisabled={isViewOnly}
                          />
                          {roleComments.length > 0 && (
                            <CommentsList
                              comments={roleComments}
                              color="text-red-500"
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {selectedTab.id === "generalInformation" && (
                      <>
                        <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                          <div className="px-4 py-6 space-y-6 sm:p-6">
                            {!mode.editing && (
                              <>
                                <div className="mb-4">
                                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    General Information
                                  </h3>
                                  <SectionDescription description="To activate the form fields please verify your ID first" />
                                </div>
                                <Verify
                                  idType={values.idType}
                                  designation={"Partner"}
                                  setShowIdentificationSection={
                                    setShowIdentificationSection
                                  }
                                  verificationValue={verificationValue}
                                  isIdAssociated={isIdAssociated}
                                  isAccessAllowed={isAccessAllowed}
                                  isAccessRevoked={isAccessRevoked}
                                  setFieldValue={setFieldValue}
                                  setVerifyingValue={setVerifyingValue}
                                  setIsIdAssociated={setIsIdAssociated}
                                  setDisableForm={setDisableForm}
                                  resetForm={handleResetForm}
                                  handleNewUserWorkflow={handleNewUserWorkflow}
                                  setIsAccessAllowed={setIsAccessAllowed}
                                  setIsAccessRevoked={setIsAccessRevoked}
                                  setInviteExistingUser={setInviteExistingUser}
                                  setTabsClickable={setTabsClickable}
                                />
                              </>
                            )}
                            {showIdentificationSection && (
                              <>
                                {submitCount > 2 &&
                                  disableForm &&
                                  !isAccessAllowed &&
                                  !isViewOnly && (
                                    <div
                                      className="py-1 text-sm bg-gray-200 items-center text-red-500 leading-none lg:rounded-full flex lg:inline-flex"
                                      role="alert"
                                    >
                                      <span className="font-semibold px-2 text-left flex-auto">
                                        To activate the form fields please
                                        verify your ID
                                      </span>
                                    </div>
                                  )}
                                <Field
                                  name="names"
                                  component={NamesForm}
                                  idType={values.idType}
                                  disabled={disableForm}
                                  disableVerificationField={
                                    isIdAssociated === false || mode.editing
                                  }
                                />
                                <hr />
                                <IdentificationDetailsForm
                                  idType={values.idType}
                                  disabled={disableForm}
                                  disableVerificationField={
                                    isIdAssociated === false || mode.editing
                                  }
                                  disableEmailAddress={
                                    mode.editing && values?.type === "invited"
                                  }
                                  disableContactName={
                                    mode.editing && values?.type === "invited"
                                  }
                                  disableTelNumber={
                                    mode.editing && values?.type === "invited"
                                  }
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {selectedTab.id === "residentialDetails" && (
                      <AddressParticulars
                        idType={values.idType}
                        isNonResident={values.isNonResident}
                        resolvedFields={values?.resolvedFields ?? []}
                        disableForm={disableForm}
                      />
                    )}
                    {selectedTab.id === "uploadDocuments" && (
                      <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                        <div className="px-4 py-6 sm:p-6">
                          <h4>Please upload the following documents</h4>
                          <Field
                            name="fileUploads"
                            component={FileUploadForm}
                            documentData={getDocumentData(values.idType)}
                            disabled={disableForm}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            }
            {isLastStep && !isFormValid && isIdAssociated === false && (
              <p className="text-sm text-red-500 my-2">
                Either enter all the required information or invite the
                official.
              </p>
            )}
            {
              <div className="flex justify-between py-3">
                <div
                  className={`flex w-full ${
                    isFirstStep ? "justify-end" : "justify-between"
                  } py-3`}
                >
                  {!isFirstStep && (
                    <Button
                      disabled={isSubmitting}
                      onClick={goToPrevStep}
                      variant="secondary"
                    >
                      Go back
                    </Button>
                  )}
                  <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                  >
                    Save and proceed
                  </Button>
                </div>
              </div>
            }
          </Form>
        );
      }}
    </Formik>
  );
};

export default OwnershipSelection;
