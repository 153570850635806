/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React from "react";
import Navbar from "./Navbar";
// import Sidebar from './Sidebar'
import Footer from "./Footer";
import BreadCrumb from "./BreadCrumb";

const DashboardWrapper = ({
  children,
  showBreadCrumbs,
  pages,
  showSystemAdminBar,
  exitDashboardText,
  handleExitDashboard,
}) => {
  return (
    <div className="flex flex-col min-h-screen">
      {" "}
      <Navbar />
      {showSystemAdminBar && (
        <nav className="relative hidden h-10 bg-white sm:flex sm:px-20">
          <ol className="flex items-center justify-end w-full">
            <p className="text-sm text-sflPrimary mr-2">
              Signed in as:
              <br />
            </p>
            <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
              System Administrator
            </span>
          </ol>
        </nav>
      )}
      <div className="relative bg-white">
        <div className="container mx-auto ">
          <div>
            {showBreadCrumbs && (
              <BreadCrumb
                pages={pages}
                exitDashboardText={exitDashboardText}
                handleExitDashboard={handleExitDashboard}
              />
            )}
          </div>
        </div>
      </div>
      {/* <Sidebar /> */}
      <div className="flex-1">
        <div className="container mx-auto">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardWrapper;
