import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { Constants } from "config/constants";
import _has from "lodash/has";
import { RadioGroup } from "@headlessui/react";
import Cookies from "js-cookie";
import {
  CogIcon,
  DocumentIcon,
  FolderIcon,
  HomeIcon,
  OfficeBuildingIcon,
} from "@heroicons/react/outline";
import UserOnboarding from "components/UserOnboarding";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import { AdminDashboardView } from "./AdminDashboard";
import {
  getCompaniesToReviewAsync,
  getCompaniesForCorrectionsAsync,
  getReviewer,
} from "state/slices/tables/reviewersTable";
import { getFullName, getGreetings } from "utils";
import { getComments } from "state/slices/comment";
import ReviewApplicationList from "components/ReviewApplicationList";
import SuperAdminWelcomeScreen from "./SystemAdminWelcomeScreen";
import { PreviewIcon } from "components/lib/Shared/Icons/sflIcons";
import { SwitchHorizontalIcon } from "@heroicons/react/solid";
import { resetCompanyRelatedSlices } from "state/slices/company";
import RoleSwitch from "components/lib/Shared/RoleSwitch";
import ServicesList from "components/lib/Shared/ServicesList";
import NotificationBanner from "components/lib/Shared/NotificationBanner";
import CompanyProcesses from "components/lib/Shared/CompanyProcesses";
import { resetActiveDashboardSlice } from "state/slices/activeDashboard";
import Settings from "components/OrgAndSuperAdminDashboard/Settings";
import { getCompaniesForSignatureAsync } from "state/slices/companies";
import SignApplications from "components/SignApplications";
import { getStatsAsync } from "state/slices/stats";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import Button from "components/lib/Shared/Button";
import TemplatesSettings from "components/OrgAndSuperAdminDashboard/TemplatesSettings";
import ReviewApplicationCorrections from "components/ReviewApplicationCorrections";
import { updateTemplateState } from "state/slices/template";
import { useSocket } from "context/SocketContext";
import { setCookie } from "utils/cookie";
import BusinessDetails from "components/CompanyDashboard/BusinessDetails";
import ApplicantCompanyDashboard from "components/TransferYourBusiness/ApplicantWorkflow";

const DashboardPage = () => {
  const [selectedCompanyProcess, setSelectedCompanyProcess] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [cookieRole, setCookieRole] = useState(null);
  const [selectedTab, setSelectedTab] = useState("home");

  const dispatch = useDispatch();
  const socket = useSocket();
  const history = useHistory();
  const location = useLocation();
  const hash = location.hash.slice(1);
  const { isAuthenticated } = useAuth0();

  const currentUser = useSelector((state) => state.userSlice);
  const { names } = currentUser || {};
  const { reviewer, companiesToReview, companiesForCorrections } = useSelector(
    (state) => state.reviewersTable
  );
  const { getUserStatus } = useSelector((state) => state.authSlice);
  const { organizationName, dashboardUrl, breadCrumbs } = useSelector(
    (state) => state.activeDashboardSlice
  );
  const { companiesForSignature } = useSelector(
    (state) => state.companiesSlice
  );
  const { stats } = useSelector((state) => state.statsSlice);
  const { selectedTemplate } = useSelector((state) => state.templateSlice);

  useEffect(() => {
    if (selectedTemplate?._id) {
      socket.emit("leave-selected-template-room", selectedTemplate._id);
      socket.emit("join-selected-template-room", selectedTemplate._id);

      socket.on(
        "set-overlay-on-company-formation-second-step",
        (showOverlay) => {
          dispatch(updateTemplateState({ showOverlay }));
        }
      );
      socket.on("replace-selected-template", (template) => {
        console.log("replace-selected-template event", template);
        dispatch(
          updateTemplateState({
            selectedTemplate: template,
          })
        );
      });
    }
  }, [selectedTemplate?._id]);

  useEffect(() => {
    const hiddenNotifications =
      JSON.parse(window.sessionStorage.getItem("hiddenNotifications")) || [];
    if (
      currentUser?.isProfessional &&
      !currentUser?.attachProfessionsDetails &&
      !hiddenNotifications.includes("professionalInfoMessageDashboard")
    ) {
      window.sessionStorage.setItem(
        "professionalInfoMessageDashboard",
        `You have a pending profile to complete, Please proceed to the profile page and update your professional details.`
      );
      window.dispatchEvent(new Event("storage"));
    } else {
      window.sessionStorage.removeItem("professionalInfoMessageDashboard");
    }
    if (currentUser?.email) {
      dispatch(getReviewer({ email: currentUser?.email }));
      dispatch(
        getCompaniesToReviewAsync({
          user: currentUser._id,
          applicationReviewed: false,
        })
      );
      dispatch(
        getCompaniesForSignatureAsync({
          status: "Signature",
          createdBy: currentUser._id,
        })
      );
      dispatch(getCompaniesForCorrectionsAsync());
    }

    if (currentUser?.role === Constants.ROLES.SYSTEM_ADMIN) {
      setCookie("role", Constants.COOKIE_ROLES.SYSTEM_ADMIN);
      setCookieRole(Constants.COOKIE_ROLES.SYSTEM_ADMIN);
    }
    if (!Cookies.get("role")) {
      setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
      setCookieRole(Constants.COOKIE_ROLES.APPLICANT);
    } else {
      setCookieRole(Cookies.get("role"));
    }
  }, [currentUser?.email]);

  useEffect(() => {
    if (
      isAuthenticated &&
      (!Cookies.get("role") ||
        Cookies.get("role") === Constants.COOKIE_ROLES.APPLICANT)
    ) {
      dispatch(getStatsAsync({ correctionsCount: true }));
    }
  }, []);

  useEffect(() => {
    const hiddenNotifications =
      JSON.parse(window.sessionStorage.getItem("hiddenNotifications")) || [];
    if (
      stats.companies?.companiesNeedCorrection > 0 &&
      !hiddenNotifications.includes("applicationsNeedCorrections")
    ) {
      window.sessionStorage.setItem(
        "applicationsNeedCorrections",
        `You have ${stats.companies?.companiesNeedCorrection} ${
          stats.companies?.companiesNeedCorrection > 1
            ? "applications"
            : "application"
        } to correct`
      );
      window.dispatchEvent(new Event("storage"));
    } else {
      window.sessionStorage.removeItem("applicationsNeedCorrections");
    }
  }, [stats.companies]);

  useEffect(() => {
    if (
      reviewer?.companyId &&
      currentUser?.isOnboarded &&
      !currentUser?.isProfileApprovedByUser
    ) {
      // dispatch(getComments({ companyId: reviewer.companyId, createdBy: reviewer._id }));
      history.push(`/profile`);
    }
  }, [reviewer, currentUser.isOnboarded]);

  useEffect(() => {
    if (selectedCompany) {
      setCookie("role", selectedCompany.role);
      if (selectedCompany.role === Constants.ROLES.APPLICANT)
        history.push(
          `/companies/${selectedCompany?.company?._id || selectedCompany?._id}`
        );
      if (selectedCompany.role === Constants.ROLES.REVIEWER)
        history.push(
          `/companies/${
            selectedCompany?.company?._id || selectedCompany?._id
          }/review`
        );
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedService) {
      setCookie("role", Constants.ROLES.APPLICANT);
      // reset previous company related data
      dispatch(resetCompanyRelatedSlices());
      history.push(selectedService.href);
    }
  }, [selectedService]);

  useEffect(() => {
    if (selectedCompanyProcess?.href) {
      history.push(selectedCompanyProcess.href);
    }
  }, [selectedCompanyProcess]);

  const isProfileIncomplete = ["Employee", "Co Applicant"].includes(
    currentUser.profileType
  );

  useEffect(() => {
    // Check if the selectedCompanyProcess is "transferYourBusiness"
    if (
      !isProfileIncomplete &&
      selectedCompanyProcess?.id === "transferYourBusiness"
    ) {
      // Redirect to "/applicant-transfer-business" route
      history.push("/applicant-transfer-business");
    }
  }, [selectedCompanyProcess, isProfileIncomplete]);

  const companyProcesses = useMemo(() => {
    let tempCompanyProcesses = [
      {
        id: "setupYourBusiness",
        title: "Set up your business",
        description:
          "Initiate a new application with just a few clicks and you will be on your way.",
        icon: <FolderIcon />,
        hash: "package-selection",
      },
      {
        id: "transferYourBusiness",
        title: "Transfer your business",
        description:
          "Initiate transfer of existing business operations or ownership to Simple Formations Limited. ",
        icon: <SwitchHorizontalIcon />,
        status: (
          <div className="col-span-2 sm:col-span-4 bg-[#9EA0A6] text-white px-3 py-0.25 rounded-md shadow-md">
            Coming Soon
          </div>
        ),
        disabled: true,
        //hash: "applicant-transfer-business",
      },
    ];
    if (companiesToReview.totalCount > 0) {
      tempCompanyProcesses.push({
        id: "reviewApplications",
        title: "Review applications",
        description: "Your application has been received and is under review.",
        icon: <OfficeBuildingIcon />,
        hash: "review-applications",
      });
    }
    if (
      companiesForCorrections.totalCount > 0 ||
      companiesForCorrections.queryParams?.search
    ) {
      tempCompanyProcesses.push({
        id: "reviewCompaniesCorrections",
        title: "Review Application Corrections",
        description: "Your application has been received but need corrections.",
        icon: <OfficeBuildingIcon />,
        hash: "correct-applications",
      });
    }
    if (companiesForSignature.totalCount > 0) {
      tempCompanyProcesses.push({
        id: "signApplication",
        title: "Signature",
        description:
          "Please sign the attached forms and resubmit them back to Admins for review",
        icon: <OfficeBuildingIcon />,
        hash: "sign-applications",
      });
    }
    return tempCompanyProcesses;
  }, [
    companiesToReview.totalCount,
    companiesForSignature.totalCount,
    companiesForCorrections,
  ]);

  // Organization onboarding redirect
  const draftOrganization = currentUser?.organizations?.find(
    ({ corporate, role }) => role === 12 && corporate.status === "Draft"
  );
  useEffect(() => {
    if (draftOrganization?.corporate?._id && currentUser?.isOnboarded) {
      history.push(`/corporates/${draftOrganization?.corporate?._id}`);
    }
  }, [draftOrganization, currentUser?.isOnboarded]);

  useEffect(() => {
    if (hash) {
      setSelectedCompanyProcess(
        companyProcesses.find((companyProcess) => companyProcess.hash === hash)
      );
    }
  }, [hash, companyProcesses]);

  if (!_has(currentUser, "isOnboarded") || getUserStatus.status === "loading") {
    return (
      <DashboardWrapper>
        <div className="flex items-center justify-center h-screen">
          <SpinnerIcon className="text-gray-500" />
        </div>
      </DashboardWrapper>
    );
  }

  const role = Cookies.get("role");

  const changeApplicantAdminView = () => {
    if (role === "10") {
      setCookie("role", 7);
      setCookieRole(Constants.COOKIE_ROLES.APPLICANT);
    }
    if (role === Constants.COOKIE_ROLES.APPLICANT) {
      setCookie("role", 10);
      setCookieRole("10");
    }
    setSelectedCompanyProcess(null);
  };

  const completedCompanies = currentUser?.linkedCompanies?.filter(
    ({ company }) =>
      company?.status === "Active" &&
      company?.createdBy?._id !== currentUser?._id
  );

  // Render user onboarding form if not onboarded
  if (currentUser && !currentUser?.isOnboarded) {
    return (
      <DashboardWrapper
        showSystemAdminBar={role === Constants.COOKIE_ROLES.SYSTEM_ADMIN}
      >
        <div className="grid mx-auto">
          <UserOnboarding />
        </div>
      </DashboardWrapper>
    );
  }

  if (
    [
      Constants.COOKIE_ROLES.SUPER_ADMIN,
      Constants.COOKIE_ROLES.ORG_ADMIN,
    ].includes(role)
  ) {
    const handleExitDashboard = () => {
      dispatch(resetActiveDashboardSlice());
      setCookieRole(Constants.COOKIE_ROLES.APPLICANT);
      setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
      history.push(dashboardUrl);
    };

    const navigation = [
      {
        id: "home",
        title: "Home",
        Icon: HomeIcon,
      },
      {
        id: "settings",
        title: "Settings",
        Icon: CogIcon,
      },
      {
        id: "templateSettings",
        title: "Templates",
        Icon: DocumentIcon,
      },
    ];

    return (
      <DashboardWrapper
        pages={breadCrumbs}
        showBreadCrumbs
        exitDashboardText="Exit back to Company Dashboard"
        handleExitDashboard={handleExitDashboard}
      >
        <div className="py-10 mx-auto">
          <NotificationBanner
            key="flashMessageDashboard"
            notificationKey="flashMessageDashboard"
          />
          <NotificationBanner
            key="professionalInfoMessageDashboard"
            notificationKey="professionalInfoMessageDashboard"
            handleView={() => history.push(`/profile`)}
          />
          <div className="flex flex-col justify-between sm:flex-row">
            <h1 className="text-4xl font-semibold text-primary">
              {organizationName}
            </h1>
            <div className="flex flex-col justify-end my-6 mb-4 lg:flex-row sm:my-0">
              <RoleSwitch
                setCookieRole={setCookieRole}
                setSelectedCompanyProcess={setSelectedCompanyProcess}
                organizationName={organizationName}
                breadCrumbs={breadCrumbs}
                dashboardUrl={dashboardUrl}
              />
            </div>
          </div>
          <div className="flex mb-8 space-x-4 font-normal border-b border-gray-200 text-tertiary">
            {navigation.map(({ id, title, Icon }) => (
              <Button
                key={id}
                overrideBaseStyles
                onClick={() => setSelectedTab(id)}
                className={`flex space-x-2 items-center px-1 pb-1 ${
                  selectedTab === id
                    ? "text-primary border-b-[3px] border-primary font-medium"
                    : ""
                }`}
              >
                <Icon
                  className={`w-5 h-5 ${
                    selectedTab === id ? "text-secondary" : ""
                  }`}
                  aria-hidden="true"
                />
                <span>{title}</span>
              </Button>
            ))}
          </div>
          {selectedTab === "home" && <AdminDashboardView role={role} />}
          {selectedTab === "settings" && <Settings />}
          {selectedTab === "templateSettings" && <TemplatesSettings />}
        </div>
      </DashboardWrapper>
    );
  }

  const handleCompanyProcessSelection = (companyProcess) => {
    if (companyProcess.hash) {
      window.location.hash = companyProcess.hash;
    } else setSelectedCompanyProcess(companyProcess);
  };

  return (
    <DashboardWrapper
      showSystemAdminBar={role === Constants.COOKIE_ROLES.SYSTEM_ADMIN}
    >
      <div className="py-10 mx-auto">
        <NotificationBanner
          key="flashMessageDashboard"
          notificationKey="flashMessageDashboard"
        />
        <NotificationBanner
          key="professionalInfoMessageDashboard"
          notificationKey="professionalInfoMessageDashboard"
          handleView={() => history.push(`/profile`)}
        />
        <NotificationBanner
          key="applicationsNeedCorrections"
          notificationKey="applicationsNeedCorrections"
          handleView={() => history.push(`/transactions`)}
          type="info"
        />
        {role === "10" && <AdminDashboardView role={role} />}
        {role === Constants.COOKIE_ROLES.SYSTEM_ADMIN && (
          <SuperAdminWelcomeScreen />
        )}
        {[
          Constants.COOKIE_ROLES.APPLICANT,
          Constants.COOKIE_ROLES.REVIEWER,
        ].includes(role) && (
          <div className="my-10 text-sflPrimary">
            <RadioGroup
              value={selectedCompanyProcess}
              onChange={handleCompanyProcessSelection}
              className="my-4"
            >
              <RadioGroup.Label className="text-base">
                <div>
                  <h2 className="pb-4 font-semibold text-title5">
                    {getGreetings()}, {getFullName(names)}
                  </h2>
                  <h1 className="font-medium">
                    What would you like to do today?
                  </h1>
                </div>
              </RadioGroup.Label>
              <CompanyProcesses companyProcesses={companyProcesses} />
            </RadioGroup>
          </div>
        )}
        {selectedCompanyProcess?.id === "setupYourBusiness" && (
          <>
            {isProfileIncomplete ||
            (currentUser?.status === "Pending Review" &&
              currentUser?.companies.length >=
                Constants.COMPANIES_LIMIT_FOR_UNVERIFIED_USER) ? (
              <>
                {isProfileIncomplete ? (
                  <span className="font-medium text-red-500">
                    You cannot make this application until your profile is{" "}
                    <Link to="/profile" className="underline">
                      upgraded.
                    </Link>
                  </span>
                ) : (
                  <>
                    <span className="font-medium text-red-500">
                      You can only create one company until your profile is
                      verified.
                    </span>

                    <div className="mt-4">
                      <Link to="/transactions" className="">
                        <div className="inline-flex text-white button button-hover button-disabled button-outline button-focus bg-sflPrimary">
                          <div className="flex items-center gap-2">
                            <PreviewIcon />
                            View Transactions
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {role !== Constants.COOKIE_ROLES.SYSTEM_ADMIN && (
                  <ServicesList
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                    className="mb-4"
                  />
                )}
              </>
            )}
          </>
        )}
        {selectedCompanyProcess?.id === "transferYourBusiness" && (
          <>
            {isProfileIncomplete ? (
              <span className="font-medium text-red-500">
                You cannot transfer your business until your profile is{" "}
                <Link to="/profile" className="underline">
                  upgraded.
                </Link>
              </span>
            ) : (
              <>
                {role !== Constants.COOKIE_ROLES.SYSTEM_ADMIN && (
                  <div className="mt-2">
                    <ApplicantCompanyDashboard />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {selectedCompanyProcess?.id === "reviewApplications" &&
          companiesToReview.totalCount > 0 && (
            <RadioGroup value={selectedService} onChange={setSelectedCompany}>
              <RadioGroup.Label className="font-medium text-gray-900">
                Application Review
              </RadioGroup.Label>
              <div className="mt-2">
                <ReviewApplicationList
                  setSelectedCompany={setSelectedCompany}
                />
              </div>
            </RadioGroup>
          )}
        {selectedCompanyProcess?.id === "reviewCompaniesCorrections" && (
          <RadioGroup
            value={companiesForCorrections}
            onChange={setSelectedCompany}
          >
            <RadioGroup.Label className="font-medium text-gray-900">
              Application Corrections Review
            </RadioGroup.Label>
            <div className="mt-2">
              <ReviewApplicationCorrections
                setSelectedCompany={setSelectedCompany}
              />
            </div>
          </RadioGroup>
        )}
        {selectedCompanyProcess?.id === "signApplication" &&
          companiesForSignature.totalCount > 0 && (
            <RadioGroup value={selectedService} onChange={setSelectedCompany}>
              <div className="mt-2">
                <SignApplications />
              </div>
            </RadioGroup>
          )}
        {(completedCompanies.length > 0 ||
          currentUser?.linkedOrganizations.length > 0 ||
          currentUser?.myCompanies?.length > 0) &&
          role === Constants.COOKIE_ROLES.APPLICANT && (
            <div className="grid gap-4 mt-4">
              <div>
                <h1 className="font-medium text-gray-900">Summary</h1>
              </div>
              <div>
                <div className="grid grid-cols-3 gap-6">
                  {/* Completed card */}
                  <div className="p-6 space-y-4 bg-white rounded-md shadow-sm">
                    <Link to="/companies">
                      <div>
                        <span className="text-xs font-medium uppercase">
                          APPROVED COMPANIES
                        </span>
                        <h1 className="text-7xl font-base">
                          {completedCompanies.length +
                            currentUser?.linkedOrganizations.length +
                            currentUser?.myCompanies?.length ?? 0}
                        </h1>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </DashboardWrapper>
  );
};

export default DashboardPage;
