import { object, string } from 'yup'

const selectIdTypeValidation = () => {
    const schema = object().shape({
        idType: string().required('Id type is required').default(''),
        estateIDType: string().when('idType', {
            is: idType => idType && idType === 'Estate',
            then: string().required('Id type of estate is required'),
            otherwise: string().notRequired()
        }),
        isEstateDistributionDetermined: string().when('idType', {
            is: idType => idType && idType === 'Estate',
            then: string().required('Please select one option'),
            otherwise: string().notRequired()
        })
    })

    return {
        schema,
        schemaInitialValues: schema.cast()
    }
}

export default selectIdTypeValidation
