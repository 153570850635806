import { useState } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { Formik, Form, Field } from "formik";
import { number, object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";

import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Button from "components/lib/Shared/Button";
import CurrenciesTable from "./Table";
import { addCurrencyAsync, updateCurrencyAsync } from "state/slices/package";
import { Constants } from "config/constants";
import Cookies from "js-cookie";
import { formatCurrency } from "utils";

const Currencies = () => {
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();
  const [showAddCurrency, setShowAddCurrency] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [mode, setMode] = useState("");
  const { selectedPackage } = useSelector((state) => state.packageSlice);

  const currencies = selectedPackage?.currencies ?? [];
  const takenCurrencies = currencies.map(({ type }) => type);
  const currencyOptions = Constants.CURRENCIES.filter(
    (currency) => !takenCurrencies.includes(currency.value)
  );

  const handleSubmit = async (data) => {
    if (data?._id) {
      await dispatch(
        updateCurrencyAsync({
          packageId: selectedPackage?._id,
          currencyId: data._id,
          data,
        })
      );
    } else {
      await dispatch(
        addCurrencyAsync({ packageId: selectedPackage?._id, data })
      );
    }
    setShowAddCurrency(false);
    setSelectedCurrency("");
    setMode("");
  };

  const handleViewPackage = async (row) => {
    setSelectedCurrency(row);
    setMode("view");
    setShowAddCurrency(true);
  };

  const handleEditPackage = async (row) => {
    setSelectedCurrency(row);
    setMode("edit");
    setShowAddCurrency(true);
  };

  const validationSchema = object().shape({
    type: string().required("Currency type is required"),
    applicableTax: string().required("Applicable tax is required"),
    basePrice: number().required("Base price is required"),
    profitMargin: number().when("type", {
      is: cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN,
      then: number().required("Profit margin is required"),
      otherwise: number(),
    }),
  });

  if (!showAddCurrency) {
    return (
      <div className="flex flex-col space-y-2">
        {currencies.length > 0 ? (
          <CurrenciesTable
            handleViewPackage={handleViewPackage}
            handleEditPackage={handleEditPackage}
          />
        ) : (
          <p>A list of all currencies for the package</p>
        )}
        {currencyOptions.length !== 0 &&
          cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
            <Button
              preIcon={PlusIcon}
              variant="text"
              className="self-start"
              onClick={() => setShowAddCurrency(true)}
            >
              Add Currency
            </Button>
          )}
      </div>
    );
  }

  const handleCancel = () => {
    setSelectedCurrency("");
    setMode("");
    setShowAddCurrency(false);
  };

  if (showAddCurrency) {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          type: "",
          applicableTax: "",
          basePrice: "",
          ...selectedCurrency,
          profitMargin: selectedCurrency?.profitMargin ?? 0,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => {
          const margin = (
            ((values?.profitMargin ?? 0) / values.basePrice) *
            100
          ).toFixed(2);

          return (
            <Form className="text-sm w-full">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                  <div className="Currency space-y-4">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <Field
                          name="type"
                          label="Currency Type"
                          options={
                            mode ? Constants.CURRENCIES : currencyOptions
                          }
                          component={ReactSelectWithFormik}
                          isDisabled={
                            mode === "view" ||
                            cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                          }
                          className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          id="type"
                          placeholder="Select currency"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <Field
                          name="applicableTax"
                          label="VAT Applicable?"
                          options={Constants.APPLICABLE_TAX_OPTIONS}
                          component={ReactSelectWithFormik}
                          isDisabled={
                            mode === "view" ||
                            cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                          }
                          className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          id="applicableTax"
                          placeholder="Select type of applicable tax"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <Field
                          component={Input}
                          disabled={
                            mode === "view" ||
                            cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                          }
                          min={1}
                          label="Base Price"
                          name="basePrice"
                          type="number"
                          placeholder="Set base price"
                        />
                      </div>
                      {cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN && (
                        <div className="col-span-6 sm:col-span-3">
                          <Field
                            component={Input}
                            disabled={mode === "view"}
                            min={0}
                            type="number"
                            label="Profit Margin"
                            name="profitMargin"
                            placeholder="Set profit margin"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {values?.profitMargin > -1 &&
                cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN && (
                  <div className="relative flex justify-between font-medium mt-8">
                    <span>Org Total:</span>
                    <p className="">
                      <span className="absolute -right-0 -top-4 text-xxs">
                        {margin}% MARGIN
                      </span>
                      <span>
                        {values.type}:{" "}
                        {formatCurrency(
                          (values.basePrice + (values?.profitMargin ?? 0)) *
                            values.applicableTax
                        )}
                      </span>
                    </p>
                  </div>
                )}
              {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
                <div className="flex justify-between font-medium mt-8">
                  <span>Super Admin Total:</span>
                  <p className="relative">
                    <span>
                      {values.type}:{" "}
                      {formatCurrency(values.basePrice * values.applicableTax)}
                    </span>
                  </p>
                </div>
              )}
              <div className="mt-6 flex items-center justify-end gap-x-4">
                <Button
                  disabled={isSubmitting}
                  variant="secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {mode !== "view" && (
                  <Button type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
};

export default Currencies;
