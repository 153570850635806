import React, { useState, useEffect, useMemo } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

import Table from "components/lib/Global/ReactTable";
import { delay, getFullName } from "utils";
import CorporateOfficialSubRow from "./CorporateOfficialSubRow";
import SearchInput from "components/lib/Shared/SearchInput";

function CompanyStakeholderTable({
  data,
  currentTab,
  currentSubTab,
  goToPrevStep = () => null,
}) {
  const [expandedRow, setExpandedRow] = useState({});
  const [initialRows, setInitialRows] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const transformSharesAllotted = (sharesAllotted) =>
    sharesAllotted
      .map(
        ({ classOfShares, allottedShares }) =>
          `<div>${classOfShares}: ${allottedShares}</div>`
      )
      .join("");

  const columns = useMemo(
    () => [
      {
        Header: () => "Full Name",
        id: "Name",
        accessor: "fullName",
        Cell: ({
          row: {
            original: { names },
          },
        }) => {
          return getFullName(names) || "N/A";
        },
        // We can render something different for subRows
        SubCell: (cellProps) => <>{cellProps.value}</>,
      },
      {
        Header: () => "ID Type",
        id: "ID Type",
        accessor: "idType",
      },
      {
        // Make an expander cell
        Header: () => "Action", // No header
        id: "expander", // It needs an ID
        Cell: ({ row, ...best }) => {
          const originalRow = row.original;
          let handleViewRow = async () => {
            const shouldExpand = !row.isExpanded;
            row.toggleAllRowsExpanded(false); // Close all rows
            await delay(10);
            setInitialRows(best.initialRows);
            setExpandedRow(shouldExpand ? row : null);
            if (shouldExpand) {
              row.getToggleRowExpandedProps().onClick();
            }
          };
          if (
            !originalRow?.linkToCompany &&
            !originalRow?.shareholderWithBO &&
            currentTab === "beneficialOwnershipTab"
          ) {
            handleViewRow = async () => {
              goToPrevStep();
              await delay(); // wait for the stakeholders tab to be mounted
              let companyShareholderEditBtn = null;
              if (originalRow?.JSGroupID)
                companyShareholderEditBtn = document.querySelector(
                  `[id="${originalRow?.JSGroupID}"]`
                );
              else if (originalRow?._id && !originalRow.JSGroupID)
                companyShareholderEditBtn = document.querySelector(
                  `[id="${originalRow?._id}"]`
                );
              if (companyShareholderEditBtn) {
                companyShareholderEditBtn.click();
              }
            };
          }

          return (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span
              className="text-sm font-normal text-primary"
              {...row.getToggleRowExpandedProps()}
              onClick={handleViewRow}
              id={
                !originalRow.linkToCompany &&
                currentTab === "beneficialOwnershipTab"
                  ? null
                  : originalRow._id
              }
            >
              {row.isExpanded ? (
                <ChevronUpIcon className="w-6 h-6" />
              ) : (
                <ChevronDownIcon className="w-6 h-6" />
              )}
            </span>
          );
        },
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },
    ],
    [setExpandedRow]
  );

  if (currentTab === "stakeholderTab") {
    // add designation & share allocation columns for stakeholder tab
    columns.splice(
      2,
      0,
      {
        Header: () => "Designation",
        id: "Designation",
        Cell: ({ row: { original } }) => {
          if (original.fullName) {
            return Array.isArray(original.fullName)
              ? "Joint Shareholder"
              : original.isDirector === "Yes"
              ? "Director"
              : original?.designation ?? "";
          }
          return original?.role ?? "";
        },
      },
      {
        Header: () => "Shares Alloted",
        id: "Shares alloted",
        Cell: ({ row }) => {
          if (
            row.original?.isDirector === "Yes" &&
            !Array.isArray(row.original?.fullName)
          )
            return "";
          return (
            <span
              className="space-y-1"
              dangerouslySetInnerHTML={{
                __html: transformSharesAllotted(
                  row.original?.sharesAllotted ?? []
                ),
              }}
            />
          );
        },
      }
    );
  }

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <CorporateOfficialSubRow
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        currentTab={currentTab}
        initialRows={initialRows}
      />
    ),
    [initialRows]
  );

  useEffect(() => {
    if (expandedRow?.isExpanded) expandedRow?.toggleRowExpanded();
  }, [currentTab, currentSubTab]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput
          value={searchTerm}
          placeholder="search by full name"
          handleOnChange={handleSearch}
        />
      </div>
      <div className="w-full overflow-x-auto">
        <Table
          columns={columns}
          data={data}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRows={data.length}
          setCurrentPage={setCurrentPage}
          paginate
          dataRowCellStyle="whitespace-normal"
          renderRowSubComponent={renderRowSubComponent}
        />
      </div>
    </div>
  );
}

export default CompanyStakeholderTable;
