import React from "react";
import _get from "lodash/get";
import { useSelector } from "react-redux";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import CommentsList from "components/lib/Global/CommentsList";
import { getFormFields } from "utils";

const AddressParticulars = ({
  addReviewComment,
  hideResolveAllCheckbox,
  currentTab = null,
  toggleCommentStatus = true,
  paginate = true,
  handleSaveComment = () => null,
}) => {
  const organization = useSelector(
    (state) => state.organizationsSlice.organization
  );

  const { comments } = useSelector((state) => state.commentsSlice);

  const addressParticularsComments = comments.filter(
    (comment) => comment.tabName === currentTab
  );

  const {
    idType,
    postalAddress = {},
    electronicAddress = {},
    residentialAddressDetails = {},
  } = organization;

  return (
    <>
      {addressParticularsComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 bg-white mt-4">
          <CommentsList
            comments={addressParticularsComments}
            toggleCommentStatus={toggleCommentStatus}
            paginate={paginate}
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {addressParticularsComments.length} of{" "}
            {addressParticularsComments.length}
          </p>
        </div>
      )}
      <div className="divide-y-2 bg-white">
        <FormFieldsCard
          addReviewComment={addReviewComment}
          formName="postalAddress"
          formHelperKey="postalAddress"
          formId={_get(postalAddress, "_id")}
          updateEffectKey="updateOrganizationState"
          updateEffectPath={`organizationsSlice.organization.postalAddress`}
          resolvedFields={_get(postalAddress, "resolvedFields")}
          hideResolveAllCheckbox={hideResolveAllCheckbox}
          fields={getFormFields({
            idType,
            formType: "postalAddress",
            formValues: postalAddress,
          })}
          handleSaveComment={handleSaveComment}
          comments={addressParticularsComments}
        />
        <FormFieldsCard
          addReviewComment={addReviewComment}
          wrapperStyles="mt-0"
          formName="electronicAddress"
          formHelperKey="electronicAddress"
          formId={_get(electronicAddress, "_id")}
          updateEffectKey="updateOrganizationState"
          updateEffectPath={`organizationsSlice.organization.electronicAddress`}
          resolvedFields={_get(electronicAddress, "resolvedFields")}
          hideResolveAllCheckbox={hideResolveAllCheckbox}
          fields={getFormFields({
            idType,
            formType: "electronicAddress",
            formValues: electronicAddress,
          })}
          handleSaveComment={handleSaveComment}
          comments={addressParticularsComments}
        />
        <FormFieldsCard
          addReviewComment={addReviewComment}
          wrapperStyles="mt-0"
          formName="residentialAddressDetails"
          formHelperKey="residentialAddressDetails"
          formId={_get(residentialAddressDetails, "_id")}
          updateEffectKey="updateOrganizationState"
          updateEffectPath={`organizationsSlice.organization.residentialAddressDetails`}
          resolvedFields={_get(residentialAddressDetails, "resolvedFields")}
          hideResolveAllCheckbox={hideResolveAllCheckbox}
          fields={getFormFields({
            idType,
            formType: "residentialAddressDetails",
            formValues: residentialAddressDetails,
          })}
          handleSaveComment={handleSaveComment}
          comments={addressParticularsComments}
        />
      </div>
    </>
  );
};

export default AddressParticulars;
