/* eslint-disable eol-last */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";

import { deleteShares } from "state/slices/tables/sharesAllocationTable";
import ReactTable from "components/lib/Shared/ReactTable";
import Tag from "components/lib/Shared/Tag";
import { roundNumber } from "utils";
import Button from "components/lib/Shared/Button";

const SharesAllocationTable = ({
  setEditableShareAlloc,
  isCorporate,
  resolvedFields,
  showActionCol = true,
}) => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext();
  const { shares } = useSelector((state) => state.sharesAllocationTableSlice);
  const { shares: nominalShareCapital } = useSelector(
    (state) => state.sharesTableSlice
  );

  const { sumOfNominalValue, sumOfVotingRights } = shares.reduce(
    (acc, share) => ({
      sumOfNominalValue: acc.sumOfNominalValue + Number(share.nominalValue),
      sumOfVotingRights: acc.sumOfVotingRights + Number(share.votingRights),
    }),
    { sumOfNominalValue: 0, sumOfVotingRights: 0 }
  );

  useEffect(() => {
    setFieldValue("sharesAllotted", shares);
  }, [shares]);

  useEffect(() => {
    const getMaxPercentShareholding = () => {
      const nominalShareCapitalSum = nominalShareCapital.reduce(
        (acc, share) => acc + Number(share.nominalValue),
        0
      );
      if (nominalShareCapitalSum === 0) return 0;
      return roundNumber((sumOfNominalValue / nominalShareCapitalSum) * 100, 2);
    };

    const getMaxPercentVotingRights = () => {
      const allVotingRights = nominalShareCapital.reduce(
        (acc, share) => acc + Number(share.votingRights),
        0
      );
      if (allVotingRights === 0) return 0;
      return roundNumber((sumOfVotingRights / allVotingRights) * 100, 2);
    };

    if (nominalShareCapital.length > 0) {
      const maxPercentShareholding = getMaxPercentShareholding();
      const maxPercentVotingRights = getMaxPercentVotingRights();
      setFieldValue(
        "beneficialOwnershipForm.maxPercentShareholding",
        maxPercentShareholding
      );
      setFieldValue(
        "beneficialOwnershipForm.maxPercentVotingRights",
        maxPercentVotingRights
      );
      if (isCorporate) {
        setFieldValue(
          "beneficialOwnershipForm.indirectPercentShareholding",
          maxPercentShareholding
        );
        setFieldValue(
          "beneficialOwnershipForm.indirectPercentVotingRights",
          maxPercentVotingRights
        );
      }
    }
  }, [
    sumOfNominalValue,
    sumOfVotingRights,
    nominalShareCapital,
    setFieldValue,
    roundNumber,
    isCorporate,
  ]);

  const handleShareEdit = (row) => {
    setEditableShareAlloc(row);
  };

  const handleShareDelete = (row) => {
    if (window.confirm("are you sure you want to delete this row?")) {
      setEditableShareAlloc(null);
      dispatch(deleteShares(row));
      setFieldValue("sharesAllotted", [
        ...shares.filter((share) => share.id !== row.id),
      ]);
      setFieldValue("beneficialOwnershipForm", {
        ...values?.beneficialOwnershipForm,
        directPercentShareholding: "",
        indirectPercentShareholding: "",
        directPercentVotingRights: "",
        indirectPercentVotingRights: "",
        dateOfBecomingBO: "",
      });
    }
  };

  const columns = [
    {
      Header: "Class of shares",
      Cell: ({ row }) => {
        if (row.original.id === "total") return <b>Total</b>;
        return row.original.classOfShares;
      },
    },
    { Header: "Number of Shares", accessor: "allottedShares" },
    {
      Header: "Value Of Shares",
      Cell: ({ row }) => {
        if (row.original.id !== "total") {
          return (
            <div className="inline-flex items-baseline">
              <span className="px-1 text-xs font-medium">KES</span>{" "}
              <Tag>{row.original.sharesValue}</Tag>
            </div>
          );
        } else return "";
      },
    },
    { Header: "Paid Up Shares", accessor: "paidUpShares" },
    {
      Header: "Premium Paid",
      Cell: ({ row }) => {
        if (row.original.id !== "total") {
          return (
            <div className="inline-flex items-baseline">
              <span className="px-1 text-xs font-medium">KES</span>{" "}
              <Tag>{row.original.sharePremium}</Tag>
            </div>
          );
        } else return "";
      },
    },
    {
      Header: "Nominal Value",
      Cell: ({ row }) => {
        return (
          <div className="inline-flex items-baseline">
            <span className="px-1 text-xs font-medium">KES</span>{" "}
            <Tag>
              {row.original.id === "total"
                ? row.original.nominalValue
                : row.original.nominalValue}
            </Tag>
          </div>
        );
      },
    },
    {
      Header: "Voting Rights",
      Cell: ({ row }) => {
        if (row.original.id === "total")
          return <b>{row.original.votingRights}</b>;
        return row.original.votingRights;
      },
    },
    showActionCol && {
      Header: "Action",
      Cell: ({ row }) => {
        if (row.original.id === "total") return "";
        return (
          <div className="grid gap-2 p-2">
            <Button
              className="items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed"
              onClick={() => handleShareEdit(row.original)}
              disabled={resolvedFields.includes(row.original.classOfShares)}
            >
              Edit
            </Button>
            <Button
              onClick={() => handleShareDelete(row.original)}
              disabled={resolvedFields.includes(row.original.classOfShares)}
              className="items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:cursor-not-allowed"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ].filter(Boolean);

  const data = [...shares];
  if (shares.length > 0) {
    data.push({
      id: "total",
      classOfShares: "Total",
      allottedShares: "",
      sharesValue: "",
      paidUpShares: "",
      sharePremium: "",
      nominalValue: sumOfNominalValue,
      votingRights: sumOfVotingRights,
    });
  }

  return (
    <>
      {data.length > 0 && (
        <div className="w-full bg-white shadow">
          <div className="w-full overflow-auto">
            <ReactTable
              columns={columns}
              data={data}
              headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600"
              headingStyle="pl-4 text-left bg-sflPrimary text-white"
              dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SharesAllocationTable;
