import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import { object, string } from "yup";

import ValidationError from "../ValidationError";
import { useToast } from "hooks/useToast";
import { getAccessRequests } from "state/slices/accessRequest";
import {
  profileAccessRequest,
  validateEmailAddress,
} from "state/slices/verifyInvite";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";
import Button from "../Button";

const InviteNewUser = ({
  closeVerifyProcess,
  idType,
  designation,
  values,
  inviteOfficial,
}) => {
  const { company } = useSelector((state) => state.companySlice);
  const { organization } = useSelector((state) => state.organizationsSlice);
  const userSlice = useSelector((state) => state.userSlice);
  const { names } = userSlice;

  const dispatch = useDispatch();
  const { toast } = useToast(5000);

  const handleInviteNewUser = async ({ newUserEmail, accessType }) => {
    if (!company?._id && !organization?._id) {
      toast(
        "error",
        "Something went wrong please reload the page and try again."
      );
      return;
    }
    if (userSlice.email === newUserEmail) {
      toast(
        "error",
        "Please enter official details manually or change the email address."
      );
      return;
    }
    if (idType !== "Minor") {
      const response = await dispatch(
        validateEmailAddress({ email: newUserEmail })
      );
      if (response.error) {
        toast(
          "error",
          response?.payload?.messageText ??
            "Something went wrong please try again."
        );
        return;
      }
    }
    try {
      const officialData = {
        ...values,
        type: "invited",
        inviteStatus: "Awaiting Data",
        initiatedToEmail: newUserEmail,
      };
      if (idType === "Minor") {
        officialData["identificationDetails"]["guardianEmailAddress"] =
          newUserEmail;
      } else {
        officialData["electronicAddress"] = {
          ...values.electronicAddress,
          emailAddress: newUserEmail,
        };
      }

      await inviteOfficial(officialData);

      const response = await dispatch(
        profileAccessRequest({
          data: {
            userEmail: newUserEmail,
            appointedAs: designation,
            company: company?._id,
            organization: organization?._id,
            accessType,
            guardianEmail: idType === "Minor" ? newUserEmail : undefined,
            idType,
          },
        })
      );

      if (response?.payload?.status) {
        await dispatch(
          getAccessRequests({ query: { createdBy: userSlice?._id } })
        );
        closeVerifyProcess();
      } else {
        toast(
          "error",
          response?.payload?.message ?? "Something went wrong please try again."
        );
      }
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  const handleInviteNewCorporate = async ({
    companyEmailAddress,
    accessType,
  }) => {
    if (!company?._id && !organization?._id) {
      toast(
        "error",
        "Something went wrong please reload the page and try again."
      );
      return;
    }

    if (userSlice.email === companyEmailAddress) {
      toast(
        "error",
        "Please enter corporate details manually or change the email address."
      );
      return;
    }

    try {
      const createOfficialEntryResponse = await inviteOfficial({
        ...values,
        type: "invited",
        inviteStatus: "Awaiting Data",
        identificationDetails: {
          ...values.identificationDetails,
          emailAddress: companyEmailAddress,
        },
        initiatedToEmail: companyEmailAddress,
      });

      const response = await dispatch(
        profileAccessRequest({
          data: {
            companyEmailAddress: companyEmailAddress,
            appointedAs: designation,
            company: company?._id,
            organization: organization?._id,
            accessType,
            corporate: createOfficialEntryResponse?.payload?.official?._id,
          },
        })
      );

      if (response?.payload?.status) {
        await dispatch(
          getAccessRequests({ query: { createdBy: userSlice?._id } })
        );
        closeVerifyProcess();
      } else {
        toast(
          "error",
          response?.payload?.message ?? "Something went wrong please try again."
        );
      }
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  if (CORPORATES.includes(idType)) {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          companyEmailAddress:
            values?.identificationDetails?.emailAddress ?? "",
          accessType: "Recurring",
        }}
        validationSchema={object().shape({
          companyEmailAddress: string()
            .email("Invalid email format")
            .trim("Email cannot include leading and trailing spaces")
            .strict(true)
            .required("Email address is required"),
        })}
        onSubmit={async (values) => {
          await handleInviteNewCorporate(values);
        }}
      >
        {({ values, errors, touched, handleSubmit, isSubmitting }) => {
          return (
            <Form>
              <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                <div className="px-4 py-6 space-y-6 sm:p-6">
                  <div className="mt-4">
                    <h4 className="text-lg font-medium leading-6 text-gray-900">
                      Invite
                    </h4>
                    <p className="mt-1 text-sm text-gray-500">
                      We recommend going with the recurring access option to
                      make future application process easier.
                    </p>
                    <div className="pl-2 m-2">
                      <p className="mt-1 text-sm text-gray-500">
                        Please select whether you would like to:
                      </p>
                      <div className="p-4 mt-2 space-y-2 bg-gray-100 rounded-md">
                        <div className="flex items-center">
                          <Field
                            name="accessType"
                            type="radio"
                            id="recurring"
                            value="Recurring"
                            defaultChecked={values.accessType === "Recurring"}
                            className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                          />
                          <label
                            htmlFor="recurring"
                            className="block ml-3 text-sm font-medium text-gray-700"
                          >
                            (Recurring access). Authorize {names?.firstName} to
                            have a access to my data for this application and
                            any future applications.
                          </label>
                        </div>
                        <div className="flex items-center">
                          <Field
                            name="accessType"
                            type="radio"
                            id="oneOff"
                            value="One Off"
                            defaultChecked={values.accessType === "One Off"}
                            className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                          />
                          <label
                            htmlFor="oneOff"
                            className="block ml-3 text-sm font-medium text-gray-700"
                          >
                            (One-time access). Authorize {names?.firstName} to
                            have a access to my data for this application only.
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <label className="block text-sm font-medium text-gray-600">
                    We will send an invite link to the contact person for them
                    to set up a profile which will be used for future
                    applications.
                  </label>
                  <div className="grid grid-cols-6 gap-2 my-4">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="companyEmailAddress"
                        className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500"
                      >
                        Company Email Address
                      </label>
                      <Field
                        type="email"
                        name="companyEmailAddress"
                        id="companyEmailAddress"
                        autoComplete="off"
                        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                        placeholder="Enter company email address"
                        disabled={isSubmitting}
                      />
                      <ValidationError
                        errors={errors}
                        touched={touched}
                        name="companyEmailAddress"
                      />
                    </div>
                  </div>
                  <div className="my-4">
                    <Button
                      onClick={handleSubmit}
                      isLoading={isSubmitting}
                      loadingText="Sending..."
                    >
                      Send Invite
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        newUserEmail: values?.electronicAddress?.emailAddress ?? "",
        accessType: "Recurring",
      }}
      validationSchema={object().shape({
        newUserEmail: string()
          .email("Invalid email format")
          .trim("Email cannot include leading and trailing spaces")
          .strict(true)
          .required("Email address is required"),
      })}
      onSubmit={handleInviteNewUser}
    >
      {({ values, errors, touched, handleSubmit, isSubmitting }) => {
        return (
          <Form>
            <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
              <div className="px-4 py-6 space-y-6 sm:p-6">
                <div className="col-span-6 my-4 sm:col-span-3">
                  <label
                    htmlFor="newUserEmail"
                    className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500"
                  >
                    {idType === "Minor" ? "Guardian" : ""} Email Address
                  </label>
                  <Field
                    type="email"
                    name="newUserEmail"
                    id="newUserEmail"
                    autoComplete="off"
                    className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                    placeholder="Nial@email.com"
                    disabled={isSubmitting}
                  />
                  <ValidationError
                    errors={errors}
                    touched={touched}
                    name="newUserEmail"
                  />
                </div>
                <div className="mt-4">
                  <h4 className="text-lg font-medium leading-6 text-gray-900">
                    Invite
                  </h4>
                  <p className="mt-1 text-sm text-gray-500">
                    We recommend going with the recurring access option to make
                    future application process easier.
                  </p>
                  <div className="pl-2 m-2">
                    <p className="mt-1 text-sm text-gray-500">
                      Please select whether you would like to:
                    </p>
                    <div className="p-4 mt-2 space-y-2 bg-gray-100 rounded-md">
                      <div className="flex items-center">
                        <Field
                          name="accessType"
                          type="radio"
                          id="recurring"
                          value="Recurring"
                          defaultChecked={values.accessType === "Recurring"}
                          className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                        />
                        <label
                          htmlFor="recurring"
                          className="block ml-3 text-sm font-medium text-gray-700"
                        >
                          (Recurring access). Authorize {names?.firstName} to
                          have a access to my data for this application and any
                          future applications.
                        </label>
                      </div>
                      <div className="flex items-center">
                        <Field
                          name="accessType"
                          type="radio"
                          id="oneOff"
                          value="One Off"
                          defaultChecked={values.accessType === "One Off"}
                          className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                        />
                        <label
                          htmlFor="oneOff"
                          className="block ml-3 text-sm font-medium text-gray-700"
                        >
                          (One-time access). Authorize {names?.firstName} to
                          have a access to my data for this application only.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <label className="block text-sm font-medium text-gray-600">
                  We will send an invite link to the above address for them to
                  set up a profile which will be used for future applications.
                </label>
                <div className="my-4">
                  <Button
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                    loadingText="Sending..."
                  >
                    Send Invite
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteNewUser;
