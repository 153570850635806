import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useDebounce } from "use-debounce";
import _get from "lodash/get";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { format } from "date-fns";

import ReactTable from "components/lib/Global/ReactTable";
import Sidebar from "components/Applications/Sidebar";
import HeroSection from "components/Applications/HeroSection";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import OrgAdminDashboardWrapper from "components/lib/Global/OrgAdminDashboardWrapper";
import SearchInput from "components/lib/Shared/SearchInput";
import Unauthorized from "pages/Unauthorized";
import SubRowAsync from "./SubRow";
import { getCompaniesAsync } from "state/slices/companies";
import GoBackToHomeBtn from "../GoBackToHomeBtn";
import { getCompanyNameWithSuffix } from "utils/company";

export default function AllApplications() {
  const dispatch = useDispatch();
  const { getUserStatus } = useSelector((state) => state.authSlice);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { getCompanies, companies } = useSelector(
    (state) => state.companiesSlice
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  useEffect(() => {
    if (isAuthenticated) {
      if (debouncedSearchTerm) setCurrentPage(1);
      dispatch(
        getCompaniesAsync({
          limit: rowsPerPage,
          skip: (currentPage - 1) * rowsPerPage,
          search: debouncedSearchTerm,
          status: ["Active"],
          order: "desc",
        })
      );
    }
  }, [isAuthenticated, rowsPerPage, currentPage, debouncedSearchTerm]);

  const currentUser = useSelector((state) => state.userSlice);

  const refreshTableData = async () => {
    dispatch(
      getCompaniesAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm,
        status: ["Active"],
        order: "desc",
      })
    );
  };

  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => {
      return (
        <SubRowAsync
          row={row}
          rowProps={rowProps}
          visibleColumns={visibleColumns}
          refreshTableData={refreshTableData}
        />
      );
    },
    []
  );

  if (!currentUser?._id || getUserStatus.status === "loading") {
    return (
      <OrgAdminDashboardWrapper>
        <div className="flex justify-center mx-auto mt-10 text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </OrgAdminDashboardWrapper>
    );
  }

  if (currentUser?.role !== 9 && getUserStatus.status !== "loading") {
    return <Unauthorized />;
  }

  const columns = [
    {
      Header: "Business Name",
      accessor: "businessName",
      className: "p-4 whitespace-normal max-w-[150px]",
      Cell: ({ row }) => getCompanyNameWithSuffix(row.original),
    },
    {
      Header: "Ref. No",
      accessor: "refNumber",
      Cell: ({ row }) => _get(row.original, "refNo", "N/A"),
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row }) => row.original.type,
    },
    {
      Header: "Verified",
      accessor: "verified",
      Cell: ({ row }) => {
        return (
          <CheckCircleIcon
            className={`w-5 h-5 ${
              _get(row.original, "status") === "Active"
                ? "text-green-800"
                : "text-gray-300"
            }`}
          />
        );
      },
    },
    {
      Header: "Published",
      accessor: "published",
      Cell: ({ row }) => {
        return (
          <CheckCircleIcon
            className={`w-5 h-5 ${
              _get(row.original, "status") === "Active"
                ? "text-green-800"
                : "text-gray-300"
            }`}
          />
        );
      },
    },
    { Header: "Status", accessor: "status" },
    {
      Header: "Date",
      Cell: ({ row }) =>
        row.original?.createdAt
          ? format(new Date(row.original?.createdAt), "yyyy/MM/dd")
          : "N/A",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            {...row?.getToggleRowExpandedProps()}
            className={`text-gray-900 group flex rounded-md items-center w-full px-2 py-1 text-sm disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-500`}
          >
            {row.isExpanded ? "Close" : "View"}
          </button>
        );
      },
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <OrgAdminDashboardWrapper>
      <div className="grid gap-4 py-10 mx-auto">
        <HeroSection />
        <GoBackToHomeBtn />
        <div className="grid grid-cols-6 border-t border-gray">
          <div className="col-span-6 pr-8 md:col-span-1">
            <Sidebar />
          </div>
          <div className="col-span-6 md:border-l md:col-span-5 md:pl-8 pt-8 border-gray">
            <div className="mb-6">
              <h1 className="text-title5">Incorporated</h1>
            </div>
            <div className="flex items-center justify-between my-2">
              <div className="flex items-center space-x-4">
                <h2 className="font-semibold">Filter by:</h2>
              </div>
              <SearchInput value={searchTerm} handleOnChange={handleSearch} />
            </div>
            {companies.totalCount > 0 ? (
              <>
                <div>
                  <ReactTable
                    columns={columns}
                    data={companies.items}
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    totalRows={companies.totalCount}
                    setCurrentPage={setCurrentPage}
                    paginate
                    headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-slate-200"
                    headingStyle="pl-4 text-left"
                    dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
                    renderRowSubComponent={renderRowSubComponent}
                    isLoading={getCompanies.status === "loading"}
                  />
                </div>
              </>
            ) : (
              <p className="text-2xl text-center text-primary">
                No applications available.
              </p>
            )}
          </div>
        </div>
      </div>
    </OrgAdminDashboardWrapper>
  );
}
