import { Constants } from "config/constants";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import AccountSettings from "components/lib/Shared/CompanyDashboard/AccessTab";
import AssignPermission from "./AssignPermission";
import Invites from "components/lib/Shared/CompanyDashboard/Invites";
import { classNames } from "utils";

const initialTabs = [{ id: "access", name: "Access" }];

const DashboardAccessRequests = () => {
  const [generalTabs, setGeneralTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState(initialTabs[0]);
  const { organization, dashboardRole } = useSelector(
    (state) => state.organizationsSlice
  );

  useEffect(() => {
    if (dashboardRole === Constants.DASHBOARD_ROLES.VOR) {
      setGeneralTabs([{ id: "access", name: "Access" }]);
    }
    if (dashboardRole === Constants.DASHBOARD_ROLES.REGISTRAR) {
      setGeneralTabs(
        [
          { id: "invites", name: "Invites" },
          { id: "access", name: "Access" },
          organization?.partnershipType
            ? { id: "assign", name: "Assign" }
            : null,
        ].filter(Boolean)
      );
    }
  }, [dashboardRole]);

  const handleTabClick = (item) => {
    if (item?.disabled) return null;
    else setSelectedTab(item);
  };

  return (
    <div className="grid gap-4 mx-auto">
      <h4 className="text-sflPrimary text-xl font-medium">Access control</h4>
      <div className="grid grid-cols-8 border-t border-gray">
        <div className="col-span-8 pr-8 md:col-span-1 pt-14">
          <div>
            <ul>
              {generalTabs.map((item) => (
                <li
                  key={item.name}
                  className={classNames(
                    item.id === selectedTab.id
                      ? "text-primary"
                      : "text-tertiary",
                    `mb-4 font-medium ${
                      item.disabled
                        ? "cursor-not-allowed text-gray-300"
                        : "hover:text-sflPrimary cursor-pointer"
                    }`
                  )}
                  onClick={() => handleTabClick(item)}
                >
                  <span className="truncate">{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-span-8 md:border-l md:col-span-7 md:pl-11 pt-14 border-gray">
          {selectedTab.id === "invites" && <Invites />}
          {selectedTab.id === "access" && (
            <AccountSettings
              page="ORGANIZATION_DASHBOARD"
              organization={organization}
              dashboardRole={dashboardRole}
            />
          )}
          {selectedTab.id === "assign" && <AssignPermission />}
        </div>
      </div>
    </div>
  );
};

export default DashboardAccessRequests;
