import Toast from './Toast';
import { useToastStateContext } from 'context/ToastContext';

export default function ToastContainer() {
    const { toasts } = useToastStateContext();

    return (
        <div className="fixed right-0 z-50 mt-24 top-10">
            <div className="max-w-xl mx-auto">
                {toasts &&
                    toasts.map((toast) => (
                        <Toast
                            id={toast.id}
                            key={toast.id}
                            type={toast.type}
                            message={toast.message}
                        />
                    ))}
            </div>
        </div>
    );
}
