import { format } from "date-fns";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils";

import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

const ViewInvoice = ({}) => {
  const { invoice, getInvoice } = useSelector((state) => state.invoiceSlice);

  if (getInvoice.status === "loading") {
    return (
      <div className="flex justify-center items-center mx-auto w-52 h-10">
        <SpinnerIcon className="text-gray-400" />
      </div>
    );
  }

  const subTotal = invoice.items.reduce((total, item) => {
    if (invoice.isTaxApplicable) {
      return total + (item.rate / invoice.applicableTax) * item.quantity;
    } else return total + item.rate * item.quantity;
  }, 0);

  const totalAmount = invoice.items.reduce(
    (total, item) => total + item.rate * item.quantity,
    0
  );

  return (
    <div className="max-w-5xl mx-auto bg-white text-[#431646]">
      <article className="overflow-hidden p-2">
        <div className="bg-[white] rounded-b-md">
          {/* header */}
          <div className="mb-10">
            <div className="flex justify-between">
              <img
                className="object-cover h-10"
                src="https://res.cloudinary.com/duqjf9tj1/image/upload/v1639701540/Assets/sfl_bxbwqe.svg"
              />
              <div className="flex flex-col">
                <h1 className="text-2xl font-bold uppercase">Tax Invoice</h1>
                <span className="text-sm">#PAID</span>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-between text-slate-500 mb-10">
            <div className="flex flex-col space-y-4 w-1/4">
              <div className="text-sm font-light">
                <p className="text-sm font-semibold text-slate-800">
                  {invoice.billFrom.name}
                </p>
                <p>{invoice.billFrom.address}</p>
                <p>{invoice.billFrom.pin}</p>
              </div>
              <div className="text-sm font-light">
                <p className="text-sm font-semibold text-slate-800">
                  {invoice.billTo.name}
                </p>
                <p>{invoice.billTo.address}</p>
                <p>{invoice.billTo.pin}</p>
              </div>
            </div>
            <div className="text-sm font-light space-y-1 flex flex-col justify-between">
              <div>
                <h2 className="font-bold text-slate-700 uppercase">Details</h2>
                <div>
                  <div className="flex items-end space-x-2">
                    <span className="text-sm font-medium text-slate-700">
                      Invoice Number:
                    </span>
                    <span>{invoice.number}</span>
                  </div>
                  <div className="flex items-end space-x-2">
                    <span className="text-sm font-medium text-slate-700">
                      Ref No:
                    </span>
                    <span>{invoice.transRef}</span>
                  </div>
                  <div className="flex items-end space-x-2">
                    <span className="text-sm font-medium text-slate-700">
                      Exchange Rate:
                    </span>
                    <span>{invoice.exchangeRate}</span>
                  </div>
                  <div className="flex items-end space-x-2">
                    <span className="text-sm font-medium text-slate-700">
                      Date of Issue
                    </span>
                    <span>
                      {format(new Date(invoice.createdAt), "MM/dd/yyyy")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-slate-100 h-20 flex justify-center items-center print:hidden">
                <span className="font-semibold">ETR</span>
              </div>
            </div>
          </div>
          {/* items table */}
          <div className="flex flex-col mx-0 my-8">
            <table className="min-w-full divide-y divide-slate-500">
              <thead className="bg-[#431646] text-white">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-normal sm:pl-6"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-normal sm:table-cell"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-normal sm:table-cell"
                  >
                    Rate
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-4 text-right text-sm font-normal sm:pr-6"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoice.items.map(({ _id, description, quantity, rate }) => {
                  const itemRate = invoice.isTaxApplicable
                    ? rate / invoice.applicableTax
                    : rate;
                  return (
                    <tr className="border-b border-slate-200" key={_id}>
                      <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium">{description}</div>
                        <div className="mt-0.5 text-slate-500 sm:hidden">
                          {quantity}
                        </div>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                        {quantity}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-right text-slate-500 sm:table-cell">
                        {invoice.currency} {formatCurrency(itemRate)}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6">
                        {invoice.currency} {formatCurrency(quantity * itemRate)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                {/* large devices */}
                <tr className="hidden sm:table-row">
                  <td></td>
                  <td></td>
                  <td
                    scope="row"
                    className="pt-6 pl-6 pr-3 text-sm font-semibold text-slate-700"
                  >
                    Subtotal
                  </td>
                  <td className="pt-6 pl-3 pr-4 text-sm text-slate-500 sm:pr-6">
                    {invoice.currency} {formatCurrency(subTotal)}
                  </td>
                </tr>
                <tr className="hidden sm:table-row">
                  <td></td>
                  <td></td>
                  <td
                    scope="row"
                    className="pt-1 pl-6 pr-3 text-sm font-semibold text-slate-700"
                  >
                    Tax
                  </td>
                  <td className="pt-1 pl-3 pr-4 text-sm text-slate-500 sm:pr-6">
                    {invoice.isTaxApplicable ? "16 %" : "N/A"}
                  </td>
                </tr>
                <tr className="hidden sm:table-row">
                  <td></td>
                  <td></td>
                  <td
                    scope="row"
                    className="pt-1 pl-6 pr-3 text-sm font-semibold"
                  >
                    Total
                  </td>
                  <td className="pt-1 pl-3 pr-4 text-sm font-normal sm:pr-6">
                    {invoice.currency} {formatCurrency(totalAmount)}
                  </td>
                </tr>
                {/* small devices */}
                <tr className="sm:hidden">
                  <td
                    scope="row"
                    className="pt-6 pl-4 pr-3 text-sm font-semibold text-left text-slate-700"
                  >
                    Subtotal
                  </td>
                  <td className="pt-6 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6">
                    {invoice.currency} {formatCurrency(subTotal)}
                  </td>
                </tr>
                <tr className="sm:hidden">
                  <td
                    scope="row"
                    className="pt-4 pl-4 pr-3 text-sm font-semibold text-left text-slate-700"
                  >
                    Tax
                  </td>
                  <td className="pt-4 pl-3 pr-4 text-sm text-right text-slate-500 sm:pr-6">
                    {invoice.isTaxApplicable ? "16 %" : "N/A"}
                  </td>
                </tr>
                <tr className="sm:hidden">
                  <td
                    scope="row"
                    className="pt-4 pl-4 pr-3 text-sm font-semibold text-left"
                  >
                    Total
                  </td>
                  <td className="pt-4 pl-3 pr-4 text-sm font-normal text-right sm:pr-6">
                    {invoice.currency} {formatCurrency(totalAmount)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          {/* notes */}
          {invoice.notes.length > 0 && (
            <div className="my-8 w-1/2">
              <span className="font-semibold">Notes:</span>
              <ul className="text-sm list-disc">
                {invoice.notes.map((note) => (
                  <li key={note._id}>{note.text}</li>
                ))}
              </ul>
            </div>
          )}
          {/* footer */}
          <div className="pt-4 mt-24 text-center text-base font-semibold">
            <p>Thank you for doing business with us.</p>
          </div>
        </div>
      </article>
    </div>
  );
};

export default ViewInvoice;
