import React from "react";

function ExportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#092C4C"
        d="M11.668 9.834h-.826a.12.12 0 00-.118.12v.966H1.06V1.08h9.665v.965c0 .066.054.12.118.12h.826a.12.12 0 00.118-.12V.48c0-.266-.21-.48-.471-.48H.471A.475.475 0 000 .48v11.04c0 .266.21.48.471.48h10.844c.26 0 .47-.214.47-.48V9.955a.12.12 0 00-.117-.12zm.285-3.932l-2.178-1.75a.123.123 0 00-.2.098v1.188H4.758a.124.124 0 00-.123.125v.875c0 .068.056.125.123.125h4.819V7.75c0 .105.12.164.2.098l2.177-1.75A.125.125 0 0012 6a.126.126 0 00-.047-.098z"
      ></path>
    </svg>
  );
}

export default ExportIcon;
