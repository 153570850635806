import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";

import Table from "components/lib/Global/ReactTable";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import ApplicantSubRow from "./ApplicantSubRow";
import {
  deleteCoApplicantAsync,
  reinstateCoApplicantAsync,
  resendCoApplicantInviteAsync,
} from "state/slices/coApplicant";

const ApplicantsTable = ({
  data,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  totalRows,
  isLoading = false,
  coApplicantsTable = false,
}) => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companySlice);

  const { isApplicant } = company;

  const handleDelete = async (row) => {
    await dispatch(deleteCoApplicantAsync({ coApplicantId: row._id }));
  };

  const reinstateCoApplicant = async (row) => {
    await dispatch(
      reinstateCoApplicantAsync({
        coApplicantId: row._id,
      })
    );
  };

  const handleResendInvite = async (row) => {
    await dispatch(
      resendCoApplicantInviteAsync({
        coApplicantId: row._id,
      })
    );
  };
  const applicantCapacity = company?.applicantCapacity?.capacity ?? "";

  const columns = [
    { Header: "Name", accessor: "name" },
    {
      Header: "Capacity",
      accessor: "capacity",
      Cell: () => applicantCapacity,
    },
    { Header: "Status", accessor: "status" },
    coApplicantsTable
      ? {
          Header: "Actions",
          Cell: ({ row }) => {
            const { status } = row.original;
            const isPending = status === "Pending";
            const isFormer = status === "Former";
            return (
              <div className="flex items-center space-x-2">
                <TableActionMenu
                  row={row}
                  showResendInviteBtn={isPending}
                  showRecallBtn={false}
                  hideEditBtn
                  hideDeleteBtn={isFormer}
                  handleDelete={handleDelete}
                  handleResendInvite={handleResendInvite}
                >
                  {isApplicant && isFormer ? (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => reinstateCoApplicant(row.original)}
                          className={`${
                            active
                              ? "bg-violet-500 text-white"
                              : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                        >
                          Reinstate
                        </button>
                      )}
                    </Menu.Item>
                  ) : null}
                </TableActionMenu>
              </div>
            );
          },
        }
      : {
          Header: "Actions",
          Cell: ({ row }) => {
            return (
              <div className="flex items-center space-x-2">
                {row.isExpanded ? (
                  <span
                    className="cursor-pointer"
                    onClick={() => row.toggleRowExpanded()}
                  >
                    Hide Details
                  </span>
                ) : !applicantCapacity && isApplicant ? (
                  <p className="text-red-500 font-medium">
                    Select your capacity to proceed,{" "}
                    <span
                      className="cursor-pointer font-bold"
                      onClick={() => row.toggleRowExpanded()}
                    >
                      click here
                    </span>
                  </p>
                ) : (
                  <span
                    className="cursor-pointer"
                    onClick={() => row.toggleRowExpanded()}
                  >
                    View details
                  </span>
                )}
              </div>
            );
          },
        },
  ].filter(Boolean);

  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <ApplicantSubRow
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  return (
    <div className="w-full bg-white shadow">
      <div className="w-full">
        <Table
          showSnColumn={coApplicantsTable}
          columns={columns}
          data={data}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          setCurrentPage={setCurrentPage}
          paginate
          isLoading={isLoading}
          headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
          headingStyle="pl-4 text-left"
          dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          renderRowSubComponent={renderRowSubComponent}
        />
      </div>
    </div>
  );
};

export default ApplicantsTable;
