import { Field, useFormikContext } from "formik";

const InviteExistingUser = () => {
  const formik = useFormikContext();
  return (
    <div className="mt-4">
      <div className="pl-2 m-2">
        <p className="mt-1 text-sm text-gray-500">
          Please select whether you would like to:
        </p>
        <div className="p-4 mt-2 space-y-2 bg-gray-100 rounded-md">
          <div className="flex items-center">
            <Field
              name="accessType"
              type="radio"
              id="recurring"
              value="Recurring"
              onChange={formik.handleChange}
              className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            />
            <label
              htmlFor="recurring"
              className="block ml-3 text-sm font-medium text-gray-700"
            >
              Request the co-applicant to give you access to their data for this
              application and future applications
            </label>
          </div>
          <div className="flex items-center">
            <Field
              name="accessType"
              type="radio"
              id="oneOff"
              value="One Off"
              onChange={formik.handleChange}
              className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            />
            <label
              htmlFor="oneOff"
              className="block ml-3 text-sm font-medium text-gray-700"
            >
              Request the co-applicant to give you access to their data for this
              application only
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteExistingUser;
