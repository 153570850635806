/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Field } from "formik";

import PrincipalHoldingCompany from "./PrincipalHoldingCompany";
import CommentsList from "components/lib/Global/CommentsList";
import ValidationError from "components/lib/Shared/ValidationError";

function SubsidiaryComponent({
  field,
  form: { setFieldValue, errors, touched, values = {} },
  transformedComments,
}) {
  const isSubsidiaryCompany = field.value?.isSubsidiaryCompany;

  const handleChange = (e) => {
    setFieldValue(field.name, {
      isSubsidiaryCompany: e.target.value,
    });
  };

  const isSubsidiaryCompanyComments =
    transformedComments?.["businessApplicationDetails"]?.isSubsidiaryCompany ??
    [];

  return (
    <>
      <h5>
        <span className="relative">
          Is the company you are registering a subsidiary company or branch?
          {isSubsidiaryCompanyComments.length > 0 && (
            <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
              <span>{isSubsidiaryCompanyComments.length}</span>
            </span>
          )}
        </span>
      </h5>
      <div className="flex mb-4 space-x-16">
        <div className="space-x-2">
          <Field
            {...field}
            type="radio"
            name="isSubsidiaryCompany"
            id="subsidiaryCompany"
            value="Yes"
            checked={isSubsidiaryCompany === "Yes"}
            onChange={handleChange}
            className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            disabled={values?.resolvedFields?.includes("isSubsidiaryCompany")}
          />
          <label
            className="text-sm font-medium text-gray-700"
            htmlFor="subsidiaryCompany"
          >
            Yes
          </label>
        </div>
        <div className="space-x-2">
          <Field
            {...field}
            type="radio"
            name="isSubsidiaryCompany"
            id="nonSubsidiaryCompany"
            value="No"
            checked={isSubsidiaryCompany === "No"}
            onChange={handleChange}
            className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            disabled={values?.resolvedFields?.includes("isSubsidiaryCompany")}
          />
          <label
            className="text-sm font-medium text-gray-700"
            htmlFor="nonSubsidiaryCompany"
          >
            No
          </label>
        </div>
      </div>
      {isSubsidiaryCompanyComments.length > 0 && (
        <CommentsList
          comments={isSubsidiaryCompanyComments}
          color={"text-red-500"}
        />
      )}
      <ValidationError
        errors={errors}
        touched={touched}
        name="subsidiaryDetail.isSubsidiaryCompany"
      />
      <div>
        {isSubsidiaryCompany === "Yes" && (
          <Field
            name="subsidiaryDetail"
            component={PrincipalHoldingCompany}
            transformedComments={transformedComments}
          />
        )}
      </div>
    </>
  );
}

export default SubsidiaryComponent;
