import React from "react";

const Confirmation = () => {
  return (
    <>
      <div className="flex flex-col my-10 space-y-2 align-middle">
        <div className="flex items-center form-check">
          <input
            className="float-left mt-1 mr-4 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border rounded appearance-none cursor-pointer form-check-input border-tertiary checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
            type="checkbox"
            id="flexCheckDefault"
          />
          <label
            className="inline-block form-check-label text-tertiary"
            htmlFor="flexCheckDefault"
          >
            Are you sure you want to submit the following details to the BRS
          </label>
        </div>
        <div className="flex items-center form-check">
          <input
            className="float-left mt-1 mr-4 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border rounded appearance-none cursor-pointer form-check-input border-tertiary checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
            type="checkbox"
            id="agreeToTerms"
          />
          <label
            className="inline-block form-check-label text-tertiary"
            htmlFor="agreeToTerms"
          >
            I agree to the terms and conditions
          </label>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
