/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from "formik";
import React from "react";

import Input from "components/lib/Shared/Input";
import { useSelector } from "react-redux";

// Electronic Address form with with common input for all ID types
const ElectronicAddress = ({ field, disabled }) => {
  const { setFieldValue } = useFormikContext();
  const { modals } = useSelector((state) => state.modalsSlice);

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div
      className={`${
        modals.length === 0
          ? "rounded-sm shadow-sm sm:rounded md:rounded-md px-4 sm:p-6"
          : ""
      } mt-4 bg-white`}
    >
      <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">
        Electronic Address
      </h3>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <Input
            label="Email Address"
            name="emailAddress"
            placeholder="johndoe@gmail.com"
            type="email"
            value={field.value?.emailAddress ?? ""}
            errorPath="electronicAddress.emailAddress"
            onBlur={field.onBlur}
            onChange={handleSetValue}
            disabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <Input
            label="Mobile Number"
            name="mobileNumber"
            placeholder=""
            value={field.value?.mobileNumber ?? ""}
            errorPath="electronicAddress.mobileNumber"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
      </div>
    </div>
  );
};

export default ElectronicAddress;
