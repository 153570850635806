import _forOwn from "lodash/forOwn";
import { object, mixed } from "yup";

const uploadDocumentsValidation = (documentData) => {
  const getFileValidationSchema = () => {
    const fileUploadsSchema = {};
    _forOwn(documentData, ({ name, label, optional = false }) => {
      fileUploadsSchema[name] = mixed().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional || optional,
        then: mixed().optional(),
        otherwise: mixed().required(`${label} is required`),
      });
    });
    return fileUploadsSchema;
  };

  const schema = object().shape({
    fileUploads: object(getFileValidationSchema()),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default uploadDocumentsValidation;
