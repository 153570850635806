import React from "react";

export const Logo = () => {
  return (
    <>
      <img
        src="https://res.cloudinary.com/duqjf9tj1/image/upload/v1639701540/Assets/sfl_bxbwqe.svg"
        alt="bg"
        className="w-full"
      />
      <span className="sr-only">Simple Formations Limited</span>
    </>
  );
};
