/* eslint-disable eol-last */
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";

import SharesAllocationTable from "./ShareAllocationTable";
import ShareAllocationSelection from "./ShareAllocationSelection";
import { resetSharesTable } from "state/slices/tables/sharesAllocationTable";
import Button from "components/lib/Shared/Button";

const MainShareAllocationComponent = ({
  currentOfficialId,
  isCorporate,
  resolvedFields = [],
  disabled,
}) => {
  const officialFormContext = useFormikContext();
  const [showAllocateSharesBtn, setAllocateSharesBtn] = useState(true);
  const [editableShareAlloc, setEditableShareAlloc] = useState(null);
  const dispatch = useDispatch();
  const { shares: nominalShareCapital } = useSelector(
    (state) => state.sharesTableSlice
  );

  useEffect(() => {
    if (!currentOfficialId) {
      // dispatch(resetSharesTable())
      setAllocateSharesBtn(true);
      setEditableShareAlloc(null);
    }
  }, [nominalShareCapital, currentOfficialId]);

  const { shares } = useSelector((state) => state.sharesAllocationTableSlice);

  const alreadyTakenClassOfShares = shares.map((share) => share.classOfShares);

  const classOfSharesOptions = useMemo(() => {
    return nominalShareCapital.map(({ sharesCategory }) => ({
      label: sharesCategory,
      value: sharesCategory,
    }));
  }, [nominalShareCapital]);

  const [selectedClassOfShare, setSelectedClassOfShare] = useState("");

  useEffect(() => {
    if (editableShareAlloc?.classOfShares) {
      setSelectedClassOfShare(
        classOfSharesOptions.find(
          (classOfShares) =>
            classOfShares.value === editableShareAlloc.classOfShares
        )
      );
    }
    officialFormContext.setTouched({}, false);
  }, [classOfSharesOptions, editableShareAlloc?.classOfShares]);

  const availableClassOfSharesOptions = classOfSharesOptions.filter(
    (option) => !alreadyTakenClassOfShares.includes(option.value)
  );

  const showShareAllocationSelection =
    !showAllocateSharesBtn || editableShareAlloc;

  return (
    <>
      {!showShareAllocationSelection && (
        <SharesAllocationTable
          setEditableShareAlloc={setEditableShareAlloc}
          isCorporate={isCorporate}
          resolvedFields={resolvedFields}
          showActionCol={!disabled}
        />
      )}
      {showShareAllocationSelection ? (
        <ShareAllocationSelection
          setAllocateSharesBtn={setAllocateSharesBtn}
          editableShareAlloc={editableShareAlloc}
          setEditableShareAlloc={setEditableShareAlloc}
          currentOfficialId={currentOfficialId}
          classOfSharesOptions={availableClassOfSharesOptions}
          alreadyTakenClassOfShares={alreadyTakenClassOfShares}
          selectedClassOfShare={selectedClassOfShare}
          setSelectedClassOfShare={setSelectedClassOfShare}
          disabled={disabled}
        />
      ) : (
        <Button
          onClick={() => {
            setAllocateSharesBtn(false);
          }}
          disabled={availableClassOfSharesOptions.length === 0 || disabled}
          className="inline-flex justify-center px-4 py-2 mt-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sflPrimary hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-optional disabled:text-white"
        >
          Allot Shares
        </Button>
      )}
    </>
  );
};

export default MainShareAllocationComponent;
