/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useSelector } from "react-redux";
import { useFormikContext, Field } from "formik";

import ValidationError from "components/lib/Shared/ValidationError";
import SubsidiaryComponent from "./SubsidiaryComponent";
import AmalgamationComponent from "./AmalgamationComponent";
import CommentsList from "components/lib/Global/CommentsList";

function BusinessApplicationDetails({
  field,
  form: { values = {}, errors = {}, touched = {} },
}) {
  const { setFieldValue } = useFormikContext();
  const { transformedComments } = useSelector((state) => state.commentsSlice);

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  const numberOfEmployeesComments =
    transformedComments?.["businessApplicationDetails"]?.numberOfEmployees ??
    [];
  const turnOverComments =
    transformedComments?.["businessApplicationDetails"]?.turnOver ?? [];

  return (
    <>
      <div className="mb-4 bg-white shadow-sm sm:rounded-md">
        <div className="px-4 py-6 sm:p-6">
          <h5>
            <span className="relative">
              <h3 className=" font-medium mb-3 after:content-['*'] after:ml-0.5 after:text-red-500">
                Number of employees at target start date
              </h3>
              {numberOfEmployeesComments.length > 0 && (
                <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-2 -right-5">
                  <span>{numberOfEmployeesComments.length}</span>
                </span>
              )}
            </span>
          </h5>
          <div className="space-y-4">
            <div>
              <span className="text-sm font-semibold">Note</span>
              <div className="p-4 mb-2 rounded-md bg-green-50">
                <div className="flex-1 md:flex md:justify-between">
                  <p className="text-sm">
                    Include <b>ONLY</b> the number of employees expected to earn
                    more than <b> KES 13,486</b> monthly and 0 if none
                  </p>
                </div>
              </div>
              <input
                type="number"
                min={0}
                {...field}
                onBlur={field.onBlur}
                value={field.value.numberOfEmployees}
                onChange={handleSetValue}
                onWheel={(e) => e.target.blur()}
                disabled={values.resolvedFields.includes("numberOfEmployees")}
                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                id="numberOfEmployees"
                name="numberOfEmployees"
                placeholder="Enter number of employees"
              />
              {numberOfEmployeesComments.length > 0 && (
                <CommentsList
                  comments={numberOfEmployeesComments}
                  color={"text-red-500"}
                />
              )}
              <ValidationError
                errors={errors}
                touched={touched}
                name="primaryBusinessActivity.numberOfEmployees"
              />
            </div>
            <hr className="my-4" />
            <div className="my-4">
              <h5>
                <span className="relative">
                  <h3 className="font-medium mb-3 after:content-['*'] after:ml-0.5 after:text-red-500">
                    Estimated annual turnover for first year(KES)
                  </h3>

                  {turnOverComments.length > 0 && (
                    <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-2 -right-5">
                      <span>{turnOverComments.length}</span>
                    </span>
                  )}
                </span>
              </h5>
              <span className="text-sm font-semibold">Note</span>
              <div className="p-4 mb-2 rounded-md bg-green-50">
                <div className="flex-1 md:flex md:justify-between">
                  <p className="text-sm">
                    You will automatically be registered for{" "}
                    <b>Value Added Tax(VAT)</b> if your annual turnover is more
                    than &nbsp;
                    <b>KES.5,000,000</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="my-2">
              <input
                type="number"
                {...field}
                onBlur={field.onBlur}
                value={field.value.turnOver}
                onChange={handleSetValue}
                disabled={values.resolvedFields.includes("turnOver")}
                onWheel={(e) => e.target.blur()}
                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                id="turnOver"
                name="turnOver"
                placeholder="Enter estimated annual turnover"
              />
              {turnOverComments.length > 0 && (
                <CommentsList
                  comments={turnOverComments}
                  color={"text-red-500"}
                />
              )}
              <ValidationError
                errors={errors}
                touched={touched}
                name="primaryBusinessActivity.turnOver"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4 bg-white shadow-sm sm:rounded-md">
        <div className="px-4 py-6 sm:p-6">
          <Field
            name="subsidiaryDetail"
            component={SubsidiaryComponent}
            transformedComments={transformedComments}
          />
        </div>
      </div>
      <div className="mb-4 bg-white shadow-sm sm:rounded-md">
        <div className="px-4 py-6 sm:p-6">
          <Field
            name="amalgamationDetail"
            component={AmalgamationComponent}
            transformedComments={transformedComments}
          />
        </div>
      </div>
    </>
  );
}

export default BusinessApplicationDetails;
