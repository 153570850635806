/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import accessRequestDataService from "services/accessRequest";
import { updateCommentState } from "./accessRequestComment";

export const getAccessRequests = createAsyncThunk(
  "accessRequests/getAccessRequests",
  async ({ query }) => {
    const res = await accessRequestDataService.getAll(query);
    return res.data;
  }
);

export const addAccessRequest = createAsyncThunk(
  "accessRequests/create",
  async ({ data }) => {
    const res = await accessRequestDataService.create(data);
    return res.data;
  }
);

export const editAccessRequest = createAsyncThunk(
  "accessRequests/update",
  async ({ accessRequestsId, data }) => {
    const res = await accessRequestDataService.update(accessRequestsId, data);
    return res.data;
  }
);

export const sendAccessRequestInvite = createAsyncThunk(
  "accessRequests/sendInvite",
  async ({ data }) => {
    const res = await accessRequestDataService.sendInvite(data);
    return res.data;
  }
);

export const deleteAccessRequest = createAsyncThunk(
  "accessRequests/delete",
  async ({ accessRequestsId }) => {
    await accessRequestDataService.remove(accessRequestsId);
    return { accessRequestsId };
  }
);

export const notifyAboutComments = createAsyncThunk(
  "accessRequests/notifyAboutComments",
  async (data, thunkAPI) => {
    const res = await accessRequestDataService.notifyAboutComments(data);
    thunkAPI.dispatch(updateCommentState(res.data));
    return res.data;
  }
);

const initialState = {
  items: [],
  getAccessRequestsStatus: {
    status: "idle",
    error: false,
  },
};

const accessRequestsSlice = createSlice({
  name: "Access Requests",
  initialState,
  reducers: {
    resetAccessRequestsSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [addAccessRequest.fulfilled]: (state, action) => {
      state.items.push(action.payload.accessRequest);
    },
    [getAccessRequests.pending]: (state) => {
      state.getAccessRequestsStatus.status = "loading";
      state.getAccessRequestsStatus.error = false;
    },
    [getAccessRequests.fulfilled]: (state, action) => {
      state.items = action.payload.items;
      state.getAccessRequestsStatus.status = "idle";
    },
    [getAccessRequests.rejected]: (state, action) => {
      state.getAccessRequestsStatus.status = "failed";
      state.getAccessRequestsStatus.error = action?.payload?.messageText;
    },
    [editAccessRequest.fulfilled]: (state, { payload }) => {
      const index = state.items.findIndex(
        (request) => payload._id === request._id
      );
      if (index > -1) {
        state.items.splice(index, 1, payload);
      }
    },
    [deleteAccessRequest.fulfilled]: (state, { payload }) => {
      const index = state.items.findIndex(
        (accessRequest) => payload.accessRequestsId === accessRequest._id
      );
      if (index > -1) state.items.splice(index, 1);
    },
  },
});

export const { resetAccessRequestsSlice } = accessRequestsSlice.actions;

export default accessRequestsSlice.reducer;
