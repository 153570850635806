import { object, string } from "yup";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";

/**
 * This returns an object composed of conditional `yup` schema and initial values
 * related to identification form for the company secretary officials.
 *
 * @param {string} idType - The idType of the current official e.g `"Kenyan Citizen"`.
 * @return {object} This returns object containing schema and initial values based on schema.
 * @example
 *  {
 *    schema
 *    schemaInitialValues
 *  }
 */
const identificationValidation = (idType) => {
  let schema = object()
    .shape({
      names: object()
        .shape()
        .when("idType", {
          is: () => {
            return INDIVIDUALS.includes(idType);
          },
          then: object().shape({
            firstName: string()
              .trim("First name cannot include leading and trailing spaces")
              .strict(true)
              .when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: string().optional(),
                otherwise: string().required("First name is required"),
              }),
            middleName: string()
              .trim("Middle name cannot include leading and trailing spaces")
              .strict(true)
              .optional(),
            lastName: string()
              .trim("Last name cannot include leading and trailing spaces")
              .strict(true)
              .when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: string().optional(),
                otherwise: string().required("Last name is required"),
              }),
          }),
          otherwise: object().shape({
            companyName: string()
              .trim("Company name cannot include leading and trailing spaces")
              .strict(true)
              .when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: string().optional(),
                otherwise: string().required("Company name is required"),
              }),
            companyRegNumber: string()
              .trim(
                "Company registration cannot include leading and trailing spaces"
              )
              .strict(true)
              .when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: string().optional(),
                otherwise: string().required(
                  "Company registration number is required"
                ),
              }),
            dateOfRegistration: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required(
                "Company registration date is required"
              ),
            }),
          }),
        }),
    })
    .default({});

  const commonIndividualIdentificationDetails = object().shape({
    identificationDetails: object({
      secretaryNumber: string()
        .trim("Secretary number cannot include leading and trailing spaces")
        .strict(true)
        .when("$areCSFieldsOptional", {
          is: (areCSFieldsOptional) => areCSFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Secretary number is required"),
        }),
      occupation: string()
        .trim("Occupation cannot include leading and trailing spaces")
        .strict(true)
        .when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Occupation is required"),
        }),
      practicingNumber: string()
        .trim("Practicing number cannot include leading and trailing spaces")
        .strict(true)
        .when("$areCSFieldsOptional", {
          is: (areCSFieldsOptional) => areCSFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Practicing number is required"),
        }),
    }),
  });

  if (idType === "Kenyan Citizen") {
    const kenyanCitizenSchema = object().shape({
      identificationDetails: object({
        dateOfBirth: string().when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Date of birth is required"),
        }),
        nationalIDNumber: string()
          .trim("National ID number cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("National ID number is required"),
          })
          .min(6, "National ID must contain at least six characters")
          .max(8, "National ID cannot be more than eight characters")
          .test("len", "National ID can only be a number", (value) =>
            /^\d+$/.test(value)
          ),
        KRAPin: string()
          .trim("KRA PIN cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("KRA PIN is required"),
          })
          .min(11, "Must be exactly 11 characters")
          .max(11, "Must be exactly 11 characters"),
      }),
    });
    schema = schema.concat(
      commonIndividualIdentificationDetails.concat(kenyanCitizenSchema)
    );
  }

  if (idType === "Foreign Resident") {
    const foreignResidentSchema = object().shape({
      identificationDetails: object({
        dateOfBirth: string().when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Date of birth is required"),
        }),
        foreignCertificateID: string()
          .trim("Occupation cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Foreign certificate ID is required"),
          }),
        KRAPin: string()
          .trim("KRA PIN cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("KRA PIN is required"),
          })
          .min(11, "Must be exactly 11 characters")
          .max(11, "Must be exactly 11 characters"),
        nationality: string()
          .trim("Nationality cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Nationality is required"),
          }),
      }),
    });
    schema = schema.concat(
      commonIndividualIdentificationDetails.concat(foreignResidentSchema)
    );
  }

  if (idType === "Local Company") {
    const localCompanySchema = object().shape({
      identificationDetails: object({
        companyContactName: string()
          .trim("Name cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Name is required"),
          }),
        companyContactDesignation: string()
          .trim("Designation cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Designation is required"),
          }),
        telNumber: string()
          .trim("Telephone cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Telephone number is required"),
          }),
        emailAddress: string()
          .email("Invalid email format")
          .trim("Email cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Email address is required"),
          }),
        companyKRAPIN: string()
          .trim("KRA PIN cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("KRA PIN is required"),
          })
          .min(11, "Must be exactly 11 characters")
          .max(11, "Must be exactly 11 characters"),
        companyFormerName: string()
          .trim("Former name cannot include leading and trailing spaces")
          .strict(true)
          .optional(),
        countryOfIncorporation: string()
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required(
              "Country of Incorporation is required"
            ),
          })
          .default("KE"),
      }),
    });
    schema = schema.concat(localCompanySchema);
  }

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default identificationValidation;
