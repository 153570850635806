import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import Tabs from "components/lib/Shared/Tabs";
import StepButtons from "components/lib/Global/StepButtons";
import CorporateSummary from "./CorporateSummary";
import AddressParticulars from "./AddressParticulars";
import PartnersDetails from "./PartnersDetails";
import FileUploads from "./FileUploads";
import Confirmation from "./Confirmation";
import Cookies from "js-cookie";
import { submitOrganization } from "state/slices/organizations";
import { getCurrentUser } from "state/slices/auth";
import { Constants } from "config/constants";

const initialTabs = [
  {
    id: "corporateSummary",
    label: "Corporate Summary",
  },
  {
    id: "addressParticulars",
    label: "Address particulars",
  },
  {
    id: "ownerships",
    label: "Stakeholder Details",
  },
  {
    id: "fileUploads",
    label: "File Uploads",
  },
  {
    id: "confirmation",
    label: "Confirmation",
  },
];

const SummaryTab = ({ handleBackBtn, hideResolveAllCheckbox }) => {
  const params = useParams();
  const history = useHistory();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const cookieRole = Cookies.get("role");
  const isViewMode =
    history.location.pathname.split("/").pop() === "view" ||
    cookieRole === Constants.COOKIE_ROLES.OFFICIAL;

  useEffect(() => {
    setTabs(initialTabs.slice(0, -1));
  }, [isViewMode]);

  const { names = {} } =
    useSelector((state) => state.organizationsSlice.organization) || {};

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await dispatch(
      submitOrganization({
        data: {
          corporateBody: {
            _id: params.corporateId,
            status: "Review",
          },
        },
      })
    );
    await dispatch(getCurrentUser());
    setIsSubmitting(false);
    history.push("/");
  };

  const goToNextStep = () => {
    if (isLastStep && cookieRole === Constants.COOKIE_ROLES.OFFICIAL) {
      history.push("/transactions");
      return;
    }
    if (isLastStep && isViewMode) {
      history.push("/companies");
      return;
    }
    if (isLastStep) handleSubmit();
    else setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isLastStep = tabs.length - 1 === selectedTabIndex;

  return (
    <>
      <p className="mb-4 text-primary">
        {names?.companyName ?? "Corporate summary"}
      </p>

      <div className="overflow-x-auto">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          showTooltip
        />
      </div>
      {selectedTab.id === "corporateSummary" && (
        <CorporateSummary hideResolveAllCheckbox={hideResolveAllCheckbox} />
      )}
      {selectedTab.id === "addressParticulars" && (
        <AddressParticulars hideResolveAllCheckbox={hideResolveAllCheckbox} />
      )}
      {selectedTab.id === "ownerships" && (
        <PartnersDetails hideResolveAllCheckbox={hideResolveAllCheckbox} />
      )}
      {selectedTab.id === "fileUploads" && (
        <FileUploads hideResolveAllCheckbox={hideResolveAllCheckbox} />
      )}
      {selectedTab.id === "confirmation" && (
        <Confirmation hideResolveAllCheckbox={hideResolveAllCheckbox} />
      )}
      <StepButtons
        isFirstStep
        goToPrevStep={goToPrevStep}
        goToNextStep={goToNextStep}
        prevBtnLabel={"Previous Step"}
        nextBtnLabel={
          isLastStep
            ? isViewMode
              ? "Close"
              : "Submit for review"
            : isViewMode
            ? "Next step"
            : "Confirm details"
        }
        isNextBtnLoading={isSubmitting}
        isPrevBtnDisabled={isSubmitting}
      />
    </>
  );
};

export default SummaryTab;
