import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import paymentService from "services/payment";

export const initiateCheckoutAsync = createAsyncThunk(
  "payment/initiateCheckoutAsync",
  async ({ data }, thunkAPI) => {
    try {
      const res = await paymentService.initiateCheckout(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkTransactionTokenAsync = createAsyncThunk(
  "payment/checkTransactionTokenAsync",
  async ({ data }, thunkAPI) => {
    try {
      const res = await paymentService.checkTransactionToken(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkTransactionStatusAsync = createAsyncThunk(
  "payment/checkTransactionStatusAsync",
  async ({ data }, thunkAPI) => {
    try {
      const res = await paymentService.checkTransactionStatus(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const cancelTransactionAsync = createAsyncThunk(
  "payment/cancelTransactionAsync",
  async ({ data }, thunkAPI) => {
    try {
      const res = await paymentService.cancelTransaction(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyPaymentAsync = createAsyncThunk(
  "payment/verifyPaymentAsync",
  async ({ data }, thunkAPI) => {
    try {
      const res = await paymentService.verifyPayment(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {};

const paymentSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {},
});

export const { reset } = paymentSlice.actions;
const { reducer } = paymentSlice;

export default reducer;
