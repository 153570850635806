import { useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { mixed, object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { components } from "react-select";
import { PlusIcon } from "@heroicons/react/outline";
import _pickBy from "lodash/pickBy";

import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Input from "components/lib/Shared/Input";
import Button from "components/lib/Shared/Button";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import {
  addTemplateAsync,
  editTemplateAsync,
  getTemplatesAsync,
} from "state/slices/template";
import { Constants } from "config/constants";
import { useToast } from "hooks/useToast";
import { categoryValidationSchema } from "../Categories/Form";
import {
  addTemplateCategoryAsync,
  getTemplateCategoriesAsync,
} from "state/slices/templateCategory";
import { closeModal, openModal } from "state/slices/modals";
import { handleFilesUpload } from "utils/filesUtils";

const templateValidationSchema = object().shape({
  category: string().required("Category is required"),
  name: string().required("Name is required"),
  entityType: string().required("Entity type is required"),
  templateType: string().required("Template type is required"),
  fileUploads: object({
    template: mixed().required(`Template is required`),
  }),
});

/**
 * Get available entity types based on specified template types.
 *
 * @param {Array} entityTypes - An array of entity types to filter.
 * @param {Array} templates - An array of template objects.
 * @param {string} categoryId - The category ID to filter templates.
 * @param {Array} templateTypes - An array of template types to check for availability.
 * @returns {Array} - An array of available entity types.
 */
const getAvailableEntityTypes = (
  entityTypes,
  templates,
  categoryId,
  templateTypes,
  selectedTemplate
) => {
  const availableEntityTypes = entityTypes.filter((entity) => {
    const templateTypeExists = templateTypes.every((type) =>
      templates.some(
        (template) =>
          template.templateType === type.value &&
          template.entityType === entity.value &&
          template.category === categoryId
      )
    );
    return !templateTypeExists;
  });
  return [
    ...availableEntityTypes,
    { label: selectedTemplate.entityType, value: selectedTemplate.entityType },
  ];
};

/**
 * Get template types based on the provided category name.
 *
 * @param {string} categoryName - The name of the category to retrieve template types for.
 * @returns {Array} - An array of template types for the specified category.
 */
const getTemplateTypesAgainstCategory = (categoryName) => {
  if (!categoryName) return [];
  if (categoryName === "Shares Rights")
    return Constants.TEMPLATES.TYPES.SHARES_RIGHTS;
  else if (categoryName === "Articles")
    return Constants.TEMPLATES.TYPES.ARTICLES;
};

/**
 * Get available template types based on specified entity types.
 *
 * @param {Array} entityTypes - An array of entity types to filter.
 * @param {Array} templates - An array of template objects.
 * @param {string} categoryId - The category ID to filter templates.
 * @param {Array} templateTypes - An array of template types to check for availability.
 * @returns {Array} - An array of available template types.
 */
const getAvailableTemplateTypes = (
  entityTypes,
  templates,
  categoryId,
  templateTypes,
  selectedTemplate
) => {
  const availableTemplateTypes = templateTypes.filter((type) => {
    const entityTypeExists = entityTypes.every((entity) =>
      templates.some(
        (template) =>
          template.templateType === type.value &&
          template.entityType === entity.value &&
          template.category === categoryId
      )
    );
    return !entityTypeExists;
  });
  return [
    ...availableTemplateTypes,
    {
      label: selectedTemplate.templateType,
      value: selectedTemplate.templateType,
    },
  ];
};

function TemplateForm({
  viewOnly,
  selectedVersion,
  handleExitWorkflow = () => null,
}) {
  const cookieRole = Cookies.get("role");
  const { toast } = useToast();
  const dispatch = useDispatch();
  const categoryRef = useRef(null);
  const closeFilePreviewRef = useRef(null);
  const { templateCategories } = useSelector((state) => state.templateCategory);
  const { selectedTemplate, templates, mode } = useSelector(
    (state) => state.templateSlice
  );

  useEffect(() => {
    dispatch(getTemplateCategoriesAsync());
    return () => {
      dispatch(getTemplatesAsync());
    };
  }, []);

  const handleSubmit = async (values) => {
    const uploadedFiles = await handleFilesUpload(
      values.fileUploads,
      null,
      false,
      "templates"
    );
    const data = {
      ...values,
      fileUploads: {
        ..._pickBy(values.fileUploads, (file) => typeof file === "string"),
        ...uploadedFiles,
      },
      summary: selectedVersion?.summary ?? {},
    };
    let response = {};
    if (selectedTemplate?._id) {
      response = await dispatch(
        editTemplateAsync({ templateId: selectedTemplate?._id, data })
      );
    } else {
      response = await dispatch(addTemplateAsync({ data }));
    }
    if (response.error) {
      toast(
        "error",
        response.payload?.messageText ?? "Something went wrong please try again"
      );
    } else {
      closeFilePreviewRef.current.click();
      toast("success", "Successfully saved the details");
    }
  };

  const CategoryMenu = (props) => {
    return (
      <components.Menu {...props}>
        <div>{props.children}</div>
        <Button
          overrideBaseStyles
          className="flex space-x-2 p-2 text-center w-full items-center justify-center border-t border-gray-300"
          onClick={() => {
            categoryRef.current.select.blur();
            dispatch(openModal({ modalName: "add_category_modal" }));
          }}
        >
          <PlusIcon className="w-5 h-5" />
          <span>Add new Category </span>
        </Button>
      </components.Menu>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        category: "",
        name: "",
        entityType: "",
        templateType: "",
        fileUploads: { template: "" },
        ...selectedTemplate,
      }}
      validationSchema={templateValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, setFieldValue, setTouched }) => {
        const handleAddCategory = async (values) => {
          try {
            const response = await dispatch(
              addTemplateCategoryAsync({ data: values })
            );
            setFieldValue("category", response.payload.templateCategory._id);
            dispatch(closeModal("add_category_modal"));
          } catch (error) {
            console.log({ error });
          }
        };

        useEffect(() => {
          if (values.category) {
            const category = templateCategories.items.find(
              (templateCategory) => templateCategory._id === values.category
            );
            setFieldValue(
              "name",
              category.name === "Articles"
                ? `Articles of Association`
                : category.name
            );
          } else {
            setFieldValue("name", "");
          }
          setTouched({}, false);
        }, [values.category]);

        useEffect(() => {
          setFieldValue(
            "fileUploads",
            selectedVersion?.fileUploads ?? { template: "" }
          );
        }, [selectedVersion]);

        const templateTypeOptions = getTemplateTypesAgainstCategory(
          templateCategories.items.find(
            (category) => category._id === values?.category
          )?.name
        );

        return (
          <Form className="text-sm w-full">
            <div className="">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6 shadow sm:rounded-md">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <Field
                      name="category"
                      label="Select/Add your category of template"
                      options={templateCategories.items.map(
                        (templateCategory) => ({
                          label: templateCategory.name,
                          value: templateCategory._id,
                        })
                      )}
                      Menu={CategoryMenu}
                      component={ReactSelectWithFormik}
                      isDisabled={
                        cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN ||
                        viewOnly
                      }
                      innerRef={categoryRef}
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Select category"
                      value={values?.category ?? ""}
                    />
                  </div>
                  {values?.category && (
                    <>
                      <div className="col-span-6 sm:col-span-3">
                        <Field
                          name="name"
                          label="Name of template"
                          component={Input}
                          disabled
                          className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={values?.name ?? ""}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <Field
                          name="entityType"
                          label="Name of Entity"
                          options={getAvailableEntityTypes(
                            Constants.TEMPLATES.ENTITY_TYPES,
                            templates.items,
                            values.category,
                            templateTypeOptions,
                            selectedTemplate
                          )}
                          component={ReactSelectWithFormik}
                          isDisabled={
                            cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN ||
                            viewOnly
                          }
                          className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={values?.entityType ?? ""}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6">
                        <Field
                          name="templateType"
                          label="Type of template"
                          options={getAvailableTemplateTypes(
                            Constants.TEMPLATES.ENTITY_TYPES,
                            templates.items,
                            values.category,
                            templateTypeOptions,
                            selectedTemplate
                          )}
                          component={ReactSelectWithFormik}
                          isDisabled={
                            cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN ||
                            viewOnly
                          }
                          className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          value={values?.templateType ?? ""}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {values?.category && (
                <div className="mt-8">
                  <p className="text-gray-800 mb-2">
                    Upload the articles of association that will be used in the
                    system
                  </p>
                  <div className="bg-white py-6 px-4 space-y-6 sm:p-6 shadow sm:rounded-md">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <Field
                          name="fileUploads"
                          component={FileUploadForm}
                          editorParams={{
                            mode: viewOnly ? "view" : "edit",
                            containerHeight: "900px",
                          }}
                          disabled={viewOnly}
                          documentData={[
                            {
                              label: "",
                              name: "template",
                              accept:
                                ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.documen",
                            },
                          ]}
                          innerRef={closeFilePreviewRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
              <div className="mt-6 flex items-center justify-end gap-x-4">
                <Button
                  variant="secondary"
                  disabled={isSubmitting}
                  onClick={handleExitWorkflow}
                >
                  {viewOnly ? "Close" : "Cancel"}
                </Button>
                {!viewOnly && (
                  <Button
                    type="submit"
                    loadingText="Saving..."
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                )}
              </div>
            )}

            {/* Add category modal */}
            <ModalWrapper
              name="add_category_modal"
              title="Add Category"
              maxWidth="sm:max-w-fit"
            >
              <div className="mt-8 text-lg w-96">
                <Formik
                  enableReinitialize
                  initialValues={{ name: "", description: "" }}
                  validationSchema={categoryValidationSchema}
                  onSubmit={handleAddCategory}
                >
                  {({ isSubmitting }) => {
                    return (
                      <Form className="text-sm w-full">
                        <div className="flex flex-col grow">
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6">
                              <Field
                                component={Input}
                                disabled={
                                  cookieRole !==
                                  Constants.COOKIE_ROLES.SUPER_ADMIN
                                }
                                label="Category name"
                                id="name"
                                name="name"
                                placeholder="Enter category name"
                                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                              />
                            </div>
                            <div className="col-span-6">
                              <Field
                                component={Input}
                                label="Product Description"
                                id="description"
                                name="description"
                                rows="2"
                                renderTextarea
                                placeholder="Write a brief description of the product"
                                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end space-x-2 mt-4">
                          <Button
                            disabled={isSubmitting}
                            onClick={() =>
                              dispatch(closeModal("add_category_modal"))
                            }
                            variant="outline"
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            isLoading={isSubmitting}
                            loadingText="Confirming..."
                          >
                            Add
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </ModalWrapper>
          </Form>
        );
      }}
    </Formik>
  );
}

export default TemplateForm;
