import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { format } from "date-fns";

import ReactTable from "components/lib/Global/ReactTable";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import NoCompanies from "components/CompaniesList/NoCompanies";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { getCorporates } from "state/slices/contactPersonCorporates";
import { Constants } from "config/constants";
import { classNames } from "utils";
import SearchInput from "components/lib/Shared/SearchInput";
import { setCookie } from "utils/cookie";

const navigation = [
  { name: "1.Managed Companies", href: "/companies" },
  {
    name: "2. Corporate directors/shareholders",
    href: "/corporate-directors-shareholders",
  },
];

const ContactPersonCompaniesList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const { items, totalCount, getOrganizations } = useSelector(
    (state) => state.contactPersonCorporatesSlice
  );

  const currentUser = useSelector((state) => state.userSlice);

  const currentPathname = history.location.pathname;

  useEffect(() => {
    if (
      currentPathname === "/corporate-directors-shareholders" &&
      currentUser?._id
    ) {
      if (debouncedSearchTerm) setCurrentPage(1);
      dispatch(
        getCorporates({
          queryParams: {
            contactPerson: currentUser._id,
            page: "corporate-directors-shareholders",
            limit: rowsPerPage,
            skip: (currentPage - 1) * rowsPerPage,
            search: debouncedSearchTerm,
            order: "asc",
          },
        })
      );
    }
  }, [
    currentPathname,
    currentUser?._id,
    rowsPerPage,
    currentPage,
    debouncedSearchTerm,
  ]);

  const columns = [
    {
      Header: "Organization",
      accessor: "companyName1",
      Cell: ({ row }) => row.original.names?.companyName ?? "N/A",
    },
    { Header: "IdType", accessor: "idType" },
    { Header: "Designation", accessor: "designation" },
    {
      Header: "Status",
      Cell: ({ row }) => {
        let status = row.original?.status ?? "N/A";
        if (status === "Active")
          return (
            <span className="inline-flex items-center justify-center w-full px-3 py-1 mx-auto text-sm font-medium text-white rounded-full bg-green-primary">
              {status}
            </span>
          );
        return status;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) =>
        row.original?.createdAt
          ? format(new Date(row.original?.createdAt), "yyyy/MM/dd")
          : "N/A",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        // if (row?.original?.status === "Active") {
        return (
          <Link to={`/corporates/${row.original._id}/dashboard`}>View</Link>
        );
        // }

        return (
          <TableActionMenu
            row={row}
            handleEdit={() => {
              setCookie("role", Constants.COOKIE_ROLES.CONTACT_PERSON);
              history.push(`/corporates/${row?.original._id}`);
            }}
            hideDeleteBtn
          />
        );
      },
    },
  ].filter(Boolean);

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  return (
    <div className="mt-6">
      <div className="my-16">
        <h1 className="text-2xl font-medium text-primary">My Companies</h1>
        <p className="text-gray-800">
          A list of all your registered and verified companies.
        </p>
      </div>

      <div className="grid grid-cols-4 border-t border-gray">
        <div className="col-span-4 pr-8 md:col-span-1 pt-14">
          {navigation.map((item, index) => (
            <button
              key={`${item.name}_${index}`}
              className={classNames(
                item.href === currentPathname
                  ? "bg-gray-50  hover:text-indigo-700 hover:bg-white border-sflPrimary"
                  : "rounded-md pr-3 py-2 flex items-center text-gray-400 text-base font-medium disabled:cursor-not-allowed",
                "flex items-center pr-3 py-2 text-base font-medium hover:text-gray-900 hover:bg-gray-50"
              )}
              onClick={() => (item?.href ? history.push(item.href) : null)}
            >
              <span className="truncate">{item.name}</span>
            </button>
          ))}
        </div>
        <div className="col-span-4 md:border-l md:col-span-3 md:pl-11 pt-14 border-gray">
          <div className="flex items-center justify-between my-2">
            <div className="flex items-center space-x-4">
              <h2 className="font-semibold">Filter by:</h2>
            </div>
            <SearchInput value={searchTerm} handleOnChange={handleSearch} />
          </div>
          {items.length > 0 ? (
            <>
              <div className="w-full bg-white shadow">
                <div className="w-full">
                  <ReactTable
                    columns={columns}
                    data={items}
                    currentPage={currentPage}
                    rowsPerPage={rowsPerPage}
                    totalRows={totalCount}
                    setCurrentPage={setCurrentPage}
                    paginate
                    headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
                    headingStyle="pl-4 text-left"
                    dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
                    dataRowCellStyle="pl-4 leading-snug py-2"
                    isLoading={getOrganizations.status === "loading"}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {getOrganizations.status === "loading" ? (
                <div className="flex items-center justify-center z-10 mt-10">
                  <SpinnerIcon className="text-gray-400" />
                </div>
              ) : (
                <NoCompanies />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactPersonCompaniesList;
