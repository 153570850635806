import { object, string } from "yup";

const kenyanCitizen = {
  "Kenyan Citizen": {
    label: "National ID number",
    name: "nationalIDNumber",
    placeholder: "Enter national id number",
    validationSchema: object({
      nationalIDNumber: string()
        .trim("National ID number cannot include leading and trailing spaces")
        .strict(true)
        .required("National ID number is required")
        .min(6, "National ID must contain at least six characters")
        .max(8, "National ID cannot be more than eight characters")
        .test("len", "National ID can only be a number", (value) =>
          /^\d+$/.test(value)
        ),
    }),
  },
};

const foreignResident = {
  "Foreign Resident": {
    label: "Foreign Certificate ID",
    name: "foreignCertificateID",
    placeholder: "Enter foreign certificate ID number",
    validationSchema: object({
      foreignCertificateID: string()
        .trim(
          "Foreign certificate ID cannot include leading and trailing spaces"
        )
        .strict(true)
        .required("Foreign certificate ID is required"),
    }),
  },
};

const foreigner = {
  Foreigner: {
    label: "Passport number",
    name: "passportNumber",
    placeholder: "Enter passport number",
    validationSchema: object({
      passportNumber: string()
        .trim("Passport cannot include leading and trailing spaces")
        .strict(true)
        .required("Passport number is required"),
    }),
  },
};

const minor = {
  Minor: {
    label: "Birth Certificate Number",
    name: "birthCertificateNumber",
    placeholder: "Enter birth certificate number",
    validationSchema: object({
      birthCertificateNumber: string()
        .trim(
          "Birth Certificate Number cannot include leading and trailing spaces"
        )
        .strict(true)
        .required("Birth Certificate Number is required"),
    }),
  },
};

const companyParams = {
  label: "Company Registration Number",
  name: "companyRegNumber",
  placeholder: "Enter company registration number",
  validationSchema: object({
    companyRegNumber: string()
      .trim("Company registration cannot include leading and trailing spaces")
      .strict(true)
      .required("Company registration number is required"),
  }),
};

export const verifyBtnParams = {
  Director: {
    ...kenyanCitizen,
    ...foreignResident,
    ...foreigner,
    "Local Company": companyParams,
    "Foreign Company": companyParams,
    "Other e.g Parastatals": companyParams,
  },
  Partner: {
    ...kenyanCitizen,
    ...foreignResident,
    ...foreigner,
    "Local Company": companyParams,
    "Foreign Company": companyParams,
    "Other e.g Parastatals": companyParams,
  },
  "Authorized Person": {
    ...kenyanCitizen,
    ...foreignResident,
    ...foreigner,
    "Local Company": companyParams,
  },
  "Company Secretary": {
    ...kenyanCitizen,
    ...foreignResident,
    "Local Company": companyParams,
  },
  "Director Shareholder": {
    ...kenyanCitizen,
    ...foreignResident,
    ...foreigner,
    "Local Company": companyParams,
    "Foreign Company": companyParams,
    "Other e.g Parastatals": companyParams,
  },
  Shareholder: {
    ...kenyanCitizen,
    ...foreignResident,
    ...foreigner,
    "Local Company": companyParams,
    "Foreign Company": companyParams,
    "Other e.g Parastatals": companyParams,
    ...minor,
  },
  "Joint Shareholder": {
    ...kenyanCitizen,
    ...foreignResident,
    ...foreigner,
    ...minor,
  },
};
