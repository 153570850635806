import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Tabs from "components/lib/Shared/Tabs";
import AddressParticulars from "components/CorporateOnboarding/SummaryTab/AddressParticulars";
import ShareInformation from "./ShareInformation";
import CorporateSummary from "./CorporateSummary";
import StakeholderDetails from "./StakeholderDetails";
import BeneficialOwnership from "components/PLCTABS/ApplicationReviewTab/BeneficialOwnership";
import PartnersDetails from "./PartnersDetails";

export default function BusinessDetails() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { organization } = useSelector((state) => state.organizationsSlice);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState({});

  useEffect(() => {
    if (!organization?._id) {
      return;
    }
    const tempTabs = [
      {
        id: "corporateSummary",
        label: "Summary",
      },
      {
        id: "addressParticulars",
        label: "Address particulars",
      },
      organization.partnershipType
        ? {
            id: "ownerships",
            label: "Stakeholder Details",
          }
        : null,
    ].filter(Boolean);

    if (organization.topTenShareholders.length > 0) {
      tempTabs.push(
        {
          id: "shareInformation",
          label: "Share Information",
        },
        {
          id: "topShareholder",
          label: "Stakeholder Details",
        },
        {
          id: "beneficialOwnership",
          label: "BeneficialOwnership",
        }
      );
    }
    setTabs(tempTabs);
    setSelectedTab(tempTabs[0]);
  }, [organization]);

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const goToPrevStep = () => {
    setSelectedTabIndex(selectedTabIndex - 1);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          showTooltip
        />
      </div>
      {selectedTab.id === "corporateSummary" && <CorporateSummary />}
      {selectedTab.id === "addressParticulars" && (
        <AddressParticulars hideResolveAllCheckbox />
      )}
      {selectedTab.id === "shareInformation" && (
        <ShareInformation disabledRightsEditor className="ml-72" />
      )}
      {selectedTab.id === "ownerships" && <PartnersDetails />}
      {selectedTab.id === "topShareholder" && <StakeholderDetails />}
      {selectedTab.id === "beneficialOwnership" && (
        <BeneficialOwnership
          hideReviewWorkflow
          renderShortTypeOfControl
          beneficialOwners={organization.beneficialOwners}
          handleBackBtn={goToPrevStep}
          disableDeleteEffect
          hideResolveAllCheckbox
        />
      )}
    </>
  );
}
