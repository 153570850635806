import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _pick from "lodash/pick";
import { Formik, Form, Field } from "formik";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { getBusinessDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import Tabs from "components/lib/Shared/Tabs";
import StepButtons from "components/lib/Global/StepButtons";
import Button from "components/lib/Shared/Button";
import CompanyFormation from "./CompanyFormation";
import AddressParticulars from "./AddressParticulars";
import ShareInformation from "./ShareInformation";
import StakeholderDetails from "./CompanyOfficials/StakeholderDetails";
import BeneficialOwnership from "./BeneficialOwnership";
import Status from "./Status";
import BusinessDocuments from "./BusinessDocuments";
import ContactPerson from "./ContactPerson";
import CompanySummary from "./CompanySummary";
import AssignedReviewer from "./AssignedReviewer";
import { getReviewers } from "state/slices/tables/reviewersTable";
import { Constants } from "config/constants";
import FileUploads from "./FileUploads";
import { useToast } from "hooks/useToast";
import {
  convertArticlesOfAssociationToPdfAsync,
  submitApplication,
  submitApplicationToBRS,
  updateCompanyAsync,
} from "state/slices/company";
import { updateLinkedCompanies } from "state/slices/user";
import { classNames, getFullName } from "utils";
import { usePopper } from "hooks/usePopper";
import { getCompanyNameWithSuffix } from "utils/company";
import { setCookie } from "utils/cookie";

export default function ApplicationReviewTab({ handleBackBtn, ...restProps }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeferring, setIsDeferring] = useState(false);
  const [isVetting, setIsVetting] = useState(false);
  const [agreeToSubmit, setAgreeToSubmit] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [agreeToSubmitToBrs, setAgreeToSubmitToBrs] = useState(false);
  const currentUser = useSelector((state) => state.userSlice);
  const { names } = currentUser || {};
  const currentUserFullName = getFullName(names);
  const history = useHistory();
  const dispatch = useDispatch();
  const { toast } = useToast(4000);
  const { company = {} } = useSelector((state) => state.companySlice);
  const { comments } = useSelector((state) => state.commentsSlice);
  const companyStatus = company?.status ?? "";
  const companyId = company?._id ?? "";
  const isViewMode =
    history.location.pathname.split("/").pop() === "view" ||
    ["Pending Incorporation", "Vetting", "Active"].includes(companyStatus) ||
    restProps.isViewMode;
  const isReviewer = Cookies.get("role") === Constants.COOKIE_ROLES.REVIEWER;
  const isOrgAdmin = Cookies.get("role") === Constants.COOKIE_ROLES.ORG_ADMIN;
  const isSuperAdmin =
    Cookies.get("role") === Constants.COOKIE_ROLES.SUPER_ADMIN;
  const [initialTabs, setInitialTabs] = useState([]);
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState();

  const [trigger, container] = usePopper({
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  useEffect(() => {
    const companyFormationCommentsCounts =
      comments.filter((comment) => {
        if (
          ["articlesOfAssociation", "businessApplicationDetails"].includes(
            comment.formName
          ) ||
          comment.formName.includes(`preferredCompanyNameCard_`)
        )
          return true;
      })?.length ?? 0;

    const addressParticularsCommentsCount =
      comments.filter((comment) => comment.formName === "addressParticulars")
        ?.length ?? 0;
    const shareInformationCommentsCount =
      comments.filter((comment) => comment.formName === "shareInformation")
        ?.length ?? 0;
    const companyOfficialsCommentCount =
      comments.filter((comment) => comment.tabName === "stakeholderTab")
        ?.length ?? 0;
    const beneficialOwnersCommentCount =
      comments.filter((comment) => comment.tabName === "beneficialOwnership")
        ?.length ?? 0;
    const tempTabs = [
      {
        id: "companyFormation",
        label: "Company formation",
        commentsCount: companyFormationCommentsCounts,
      },
      {
        id: "addressParticulars",
        label: "Address particulars",
        commentsCount: addressParticularsCommentsCount,
      },
      {
        id: "shareInformation",
        label: "Share information",
        commentsCount: shareInformationCommentsCount,
      },
      {
        id: "stakeholderDetails",
        label: "Stakeholder details",
        commentsCount: companyOfficialsCommentCount,
      },
      {
        id: "beneficialOwnership",
        label: "Beneficial ownership",
        commentsCount: beneficialOwnersCommentCount,
      },
      {
        id: "assignedReviewers",
        label: "Assigned Reviewers",
      },
    ];
    setInitialTabs(tempTabs);
    setTabs(tempTabs);
    setSelectedTab(tempTabs[0]);
  }, [comments.length]);

  useEffect(() => {
    if (companyId) {
      dispatch(getReviewers({ companyId }));
    }
  }, [companyId]);

  useEffect(() => {
    if (
      isSuperAdmin &&
      ["Pending Incorporation", "Active"].includes(companyStatus)
    ) {
      setTabs([
        {
          id: "companySummary",
          label: "Summary",
        },
        ...initialTabs.slice(1),
      ]);
    }
    if (!isViewMode && !isSuperAdmin) {
      setTabs(
        [
          ...initialTabs,
          isOrgAdmin
            ? {
                id: "contactPerson",
                label: "Contact Person",
              }
            : null,
          {
            id: "status",
            label: "Status",
          },
        ].filter(Boolean)
      );
    }
    if (["BRS Review", "BRS Corrections"].includes(companyStatus)) {
      setTabs([
        ...initialTabs,
        {
          id: "contactPerson",
          label: "Contact Person",
        },
        {
          id: "fileUploads",
          label: "File Uploads",
        },
      ]);
    }
  }, [isViewMode, isOrgAdmin, isSuperAdmin, companyStatus, initialTabs]);

  const handleDeferApplication = async () => {
    try {
      setIsDeferring(true);
      let status = companyStatus;
      if (
        ["Directors Review", "Directors Corrections"].includes(companyStatus)
      ) {
        status = "Directors Deferred";
      } else if (
        ["Admin Review", "Admin Review II", "Admin Corrections"].includes(
          companyStatus
        )
      ) {
        status = "Admin Deferred";
      } else if (
        ["Submission", "Submission II", "Submission Corrections"].includes(
          companyStatus
        )
      ) {
        status = "Submission Deferred";
      } else if (
        ["Signature", "Signature II", "Signature Corrections"].includes(
          companyStatus
        )
      ) {
        status = "Signature Deferred";
      }
      await dispatch(
        updateCompanyAsync({
          id: company?._id,
          data: { status },
        })
      );
      toast(
        "success",
        "The application has been successfully marked as deferred."
      );
      history.push("/");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsDeferring(false);
    }
  };

  const handleVettedApplication = async () => {
    try {
      setIsVetting(true);
      let status = companyStatus;

      if (["BRS Review", "BRS Review II"].includes(companyStatus)) {
        status = "Vetting";

        await dispatch(
          updateCompanyAsync({
            id: companyId,
            data: { status },
          })
        );

        toast("success", "The application is being vetted.");
        history.push("/");
      }
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsVetting(false);
    }
  };

  const unresolvedComments = comments.filter((comment) => !comment.isResolved);

  const submitToLabel =
    unresolvedComments.length !== 0
      ? "applicant"
      : isReviewer
      ? "admin"
      : isOrgAdmin
      ? "BRS"
      : "";

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = async () => {
    const lastTabIndex = tabs.length - 1;
    if (selectedTabIndex < lastTabIndex) {
      setSelectedTabIndex(selectedTabIndex + 1);
      return;
    }

    if (
      companyStatus === "Active" ||
      (["Signature", "Signature Corrections"].includes(companyStatus) &&
        (isOrgAdmin || isSuperAdmin))
    ) {
      handleBackBtn();
      return;
    }
    if (
      isViewMode &&
      companyStatus !== "Pending Incorporation" &&
      companyStatus !== "Vetting" &&
      companyStatus !== "Incorporation Deferred"
    ) {
      setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
      history.push("/transactions");
      return;
    }

    if (isSuperAdmin) {
      if (
        companyStatus === "Pending Incorporation" ||
        companyStatus === "Vetting" ||
        companyStatus === "Incorporation Deferred"
      ) {
        handleIncorporation();
        return;
      }
      if (unresolvedComments.length > 0) {
        handleSubmitApplication();
        return;
      }
      if (unresolvedComments.length === 0) {
        if (
          ["Submission", "Submission II"].includes(companyStatus) &&
          selectedTab.id === "confirmation"
        ) {
          setSubmitting(true);
          await dispatch(
            updateCompanyAsync({
              id: companyId,
              data: { status: "Signature", agreeToSubmit: agreeToSubmitToBrs },
            })
          );
          setSubmitting(false);
          toast(
            "success",
            "The application has been successfully sent to the applicant for signature."
          );
          handleBackBtn();
          return;
        }
        if (
          ["Submission", "Submission II"].includes(companyStatus) &&
          selectedTab.id === "assignedReviewers"
        ) {
          setSubmitting(true);
          await dispatch(convertArticlesOfAssociationToPdfAsync({ companyId }));
          setSubmitting(false);
        }
        const tempTabs = [
          ...initialTabs,
          {
            id: "contactPerson",
            label: "Contact Person",
          },
        ];
        if (
          ["Submission", "Submission II", "Submission Corrections"].includes(
            companyStatus
          )
        ) {
          tempTabs.push(
            {
              id: "manualBridge",
              label: "Manual Bridge",
            },
            {
              id: "businessDocuments",
              label: "Business Documents",
            },
            {
              id: "confirmation",
              label: "Confirmation",
            }
          );
        }
        if (["BRS Review", "BRS Corrections"].includes(companyStatus)) {
          if (selectedTab.id === "fileUploads") {
            uploadBusinessDocuments();
            return;
          } else {
            tempTabs.push({
              id: "fileUploads",
              label: "File Uploads",
            });
          }
        }
        setTabs(tempTabs);
        setSelectedTabIndex(selectedTabIndex + 1);
        return;
      }
    }

    if (isOrgAdmin && unresolvedComments.length === 0) {
      handleSubmitToBRS();
    } else handleSubmitApplication();
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  useEffect(() => {
    if (companyId) {
      const selectedLinkedCompany = currentUser?.linkedCompanies.find(
        (linkedCompany) => linkedCompany.company?._id === companyId
      );
      setAgreeToSubmit(selectedLinkedCompany?.agreeToSubmit);
      setAgreeToTerms(selectedLinkedCompany?.agreeToTerms);
    }
  }, [currentUser.linkedCompanies, companyId]);

  useEffect(() => {
    setAgreeToSubmitToBrs(company.agreeToSubmit);
  }, [company.agreeToSubmit]);

  if (!selectedTab || !companyId) return "loading...";

  let companyName = getCompanyNameWithSuffix(company);

  const handleSubmitApplication = async () => {
    let status = "";
    let toastMessage = "";
    if (unresolvedComments.length > 0) {
      toastMessage =
        "The application has been successfully sent to the applicant for corrections.";
      if (isReviewer) status = "Directors Corrections";
      if (isOrgAdmin) status = "Admin Corrections";
      if (isSuperAdmin) status = "BRS Corrections";
      if (isSuperAdmin && companyStatus === "Submission")
        status = "Submission Corrections";
    } else {
      if (isReviewer) {
        status = "Admin Review";
        toastMessage =
          "The application has been successfully sent to the admins.";
      }
      if (isSuperAdmin) {
        status = "BRS Review";
        toastMessage =
          "The application has been successfully sent to BRS for review.";
      }
    }

    try {
      setSubmitting(true);
      const updatedLinkedCompanies = currentUser?.linkedCompanies.map(
        (linkedCompany) => {
          if (linkedCompany.company?._id === companyId) {
            return {
              ...linkedCompany,
              status,
              agreeToSubmit,
              agreeToTerms,
            };
          }
          return linkedCompany;
        }
      );
      const updateLinkedCompanyStatus = dispatch(
        updateLinkedCompanies({
          data: {
            companyId,
            status,
            agreeToSubmit,
            agreeToTerms,
          },
          updatedLinkedCompanies,
        })
      );
      const submitApplicationPromise = dispatch(
        submitApplication({
          data: {
            companyId,
            companyName,
            role: Cookies.get("role"),
            unresolvedCommentsCount: unresolvedComments.length,
            applicantName: getFullName(company.createdBy.names),
            applicantEmail: company.createdBy.email,
            reviewerName: currentUserFullName,
            status,
          },
        })
      );
      await Promise.all([updateLinkedCompanyStatus, submitApplicationPromise]);
      toast("success", toastMessage);
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
    history.push("/");
  };

  const handleSubmitToBRS = async () => {
    try {
      setSubmitting(true);
      const updatedLinkedCompanies = currentUser?.linkedCompanies.map(
        (linkedCompany) => {
          if (linkedCompany.company?._id === companyId) {
            return {
              ...linkedCompany,
              status: "Submission",
              agreeToSubmit,
              agreeToTerms,
            };
          }
          return linkedCompany;
        }
      );
      const updateLinkedCompanyStatus = dispatch(
        updateLinkedCompanies({
          data: {
            companyId,
            status: "Submission",
            agreeToSubmit,
            agreeToTerms,
          },
          updatedLinkedCompanies,
        })
      );
      const updateCompanyData = dispatch(
        updateCompanyAsync({
          id: companyId,
          data: { status: "Submission" },
        })
      );
      const submitApplicationToBRSPromise = dispatch(
        submitApplicationToBRS({
          data: {
            companyId,
            companyName,
            role: Cookies.get("role"),
          },
        })
      );
      await Promise.all([
        updateLinkedCompanyStatus,
        updateCompanyData,
        submitApplicationToBRSPromise,
      ]);
      toast(
        "success",
        "The application has been successfully sent to BRS for review."
      );
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
    history.push("/");
  };

  const uploadBusinessDocuments = async () => {
    try {
      setSubmitting(true);
      const updatedLinkedCompanies = currentUser?.linkedCompanies.map(
        (linkedCompany) => {
          if (linkedCompany.company?._id === companyId) {
            return {
              ...linkedCompany,
              status: "Pending Incorporation",
            };
          }
          return linkedCompany;
        }
      );
      const updateLinkedCompanyStatus = dispatch(
        updateLinkedCompanies({
          data: {
            companyId,
            status: "Pending Incorporation",
          },
          updatedLinkedCompanies,
        })
      );
      const updateCompanyData = dispatch(
        updateCompanyAsync({
          id: companyId,
          data: { status: "Pending Incorporation" },
        })
      );
      await Promise.all([updateLinkedCompanyStatus, updateCompanyData]);
      toast(
        "success",
        "The application has been successfully submitted for incorporation."
      );
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
    handleBackBtn();
  };

  const handleIncorporation = async () => {
    setSubmitting(true);
    try {
      const updatedLinkedCompanies = currentUser?.linkedCompanies.map(
        (linkedCompany) => {
          if (linkedCompany.company?._id === companyId) {
            return {
              ...linkedCompany,
              status: "Active",
            };
          }
          return linkedCompany;
        }
      );
      const updateLinkedCompanyStatus = dispatch(
        updateLinkedCompanies({
          data: {
            companyId,
            status: "Active",
          },
          updatedLinkedCompanies,
        })
      );
      const updateCompanyData = dispatch(
        updateCompanyAsync({
          id: companyId,
          data: {
            status: "Active",
            fileUploads: company?.fileUploads,
            ..._pick(company, [
              "registrationName",
              "registrationDate",
              "kraPin",
              "NSSFNo",
              "NHIFNo",
            ]),
          },
        })
      );
      await Promise.all([updateLinkedCompanyStatus, updateCompanyData]);
      toast("success", "The application has been successfully incorporated.");
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
    history.push("/applications/all-applications");
  };

  const isLastStep = tabs.length - 1 === selectedTabIndex;

  let nextBtnLabel = isLastStep
    ? isViewMode
      ? "Back to Transactions Page"
      : `Submit to ${submitToLabel}`
    : isViewMode
    ? "Next Step"
    : "Confirm details";

  if (isSuperAdmin) {
    if (unresolvedComments.length === 0 && isLastStep) {
      if (["Submission", "Submission II"].includes(company?.status))
        nextBtnLabel = "Submit for Signature";
      else nextBtnLabel = "Confirm details";
    }
    // For Vetting Status
    if (companyStatus === "Vetting" && isLastStep) {
      nextBtnLabel = "Incorporate";
    }

    // For Pending Incorporation Status
    if (companyStatus === "Pending Incorporation" && isLastStep) {
      nextBtnLabel = "Incorporate";
    }
  }

  // For BRS Review Status
  let submitBtnDropdownOptions = [];

  if (["BRS Review", "BRS Review II"].includes(companyStatus) && isLastStep) {
    submitBtnDropdownOptions.push({
      name: "Vetting",
      handleOnClick: handleVettedApplication,
      disabled: company.status.includes("Vetting"),
    });
  } else {
    submitBtnDropdownOptions.push({
      name: "Mark as Deferred",
      handleOnClick: handleDeferApplication,
      disabled: company.status.includes("Deferred"),
    });
  }

  const SubmitBtnDropdown = (
    <Menu as="div" className="relative -ml-px block">
      <Menu.Button
        ref={trigger}
        disabled={isSubmitting || isDeferring}
        className="relative inline-flex border-l border-white items-center rounded-r-md bg-green-focused px-2 py-2 text-white hover:bg-green-700 focus:z-10 disabled:cursor-not-allowed disabled:bg-green-200"
      >
        <span className="sr-only">Open options</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <div ref={container} className="z-50">
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {submitBtnDropdownOptions.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Button
                      onClick={item.handleOnClick}
                      variant="text"
                      disabled={item.disabled}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-sm"
                      )}
                    >
                      {item.name}
                    </Button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );

  return (
    <>
      {companyStatus !== "Active" && (
        <>
          <h2 className="mb-4 text-primary text-title6 font-semibold">
            {company?.companyName1 ? companyName : "Application reviews"}
          </h2>
          <div className="rounded-md bg-sky-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-sky-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between whitespace-normal">
                <p className="text-sm text-sky-700">
                  {selectedTab.id === "confirmation"
                    ? "Please confirm if the forms uploaded are correct."
                    : "Please confirm if the data submitted is correct. If not, please leave comments which will be amended by the applicant."}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="overflow-x-auto">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          showTooltip
        />
      </div>
      {selectedTab.id === "companyFormation" && (
        <CompanyFormation isViewMode={isViewMode} />
      )}
      {selectedTab.id === "companySummary" && (
        <CompanySummary
          goToPrevStep={goToPrevStep}
          goToNextStep={goToNextStep}
        />
      )}
      {selectedTab.id === "addressParticulars" && (
        <AddressParticulars isViewMode={isViewMode} />
      )}
      {selectedTab.id === "shareInformation" && (
        <ShareInformation disabledRightsEditor isViewMode={isViewMode} />
      )}
      {selectedTab.id === "stakeholderDetails" && (
        <StakeholderDetails isViewMode={isViewMode} />
      )}
      {selectedTab.id === "beneficialOwnership" && (
        <BeneficialOwnership
          handleBackBtn={goToPrevStep}
          hideReviewWorkflow={isViewMode}
          isViewMode={isViewMode}
        />
      )}
      {selectedTab.id === "assignedReviewers" && <AssignedReviewer />}
      {selectedTab.id === "fileUploads" && <FileUploads />}
      {selectedTab.id === "status" && (
        <Status
          submitToLabel={submitToLabel}
          setDisableSubmitBtn={setDisableSubmitBtn}
          agreeToSubmit={agreeToSubmit}
          agreeToTerms={agreeToTerms}
          setAgreeToSubmit={setAgreeToSubmit}
          setAgreeToTerms={setAgreeToTerms}
        />
      )}
      {selectedTab.id === "manualBridge" && (
        <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6 whitespace-normal">
          <p>
            Information is populated to the E-Citizen platform manually to
            generate the required documents for Electronic signing.
          </p>
          <p>
            Once all the incorporation forms have been generated, click "Confirm
            details" below to uploaded them in the next step.
          </p>
        </div>
      )}
      {selectedTab.id === "businessDocuments" && (
        <BusinessDocuments
          handleBackBtn={goToPrevStep}
          handleNextBtn={goToNextStep}
        />
      )}
      {selectedTab.id === "contactPerson" && (
        <ContactPerson
          handleBackBtn={goToPrevStep}
          handleNextBtn={goToNextStep}
        />
      )}
      {selectedTab.id === "confirmation" && (
        <Formik
          enableReinitialize
          initialValues={{
            fileUploads: company?.preSignedBusinessDocuments ?? {},
          }}
          onSubmit={goToNextStep}
        >
          {() => {
            return (
              <Form>
                {!_isEmpty(company?.preSignedBusinessDocuments ?? {}) ? (
                  <div>
                    <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        File uploads
                      </h3>
                      <div className="space-y-2">
                        <Field
                          name="fileUploads"
                          component={FileUploadForm}
                          documentData={getBusinessDocumentData()}
                          onlyPreview
                          showDocumentLabel
                        />
                      </div>
                    </div>
                    <div className="flex my-4 align-middle">
                      <div className="flex items-center form-check">
                        <input
                          className="mr-4 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border rounded appearance-none cursor-pointer form-check-input border-tertiary checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                          type="checkbox"
                          checked={agreeToSubmitToBrs}
                          onChange={(e) =>
                            setAgreeToSubmitToBrs(e.target.checked)
                          }
                          id="flexCheckDefault"
                        />
                        <label
                          className="inline-block form-check-label text-tertiary"
                          htmlFor="flexCheckDefault"
                        >
                          I confirm that the Statutory Forms are in order and
                          may be submitted to the company promoters for
                          signatures.
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="mt-4 text-center">No documents found</p>
                )}
              </Form>
            );
          }}
        </Formik>
      )}
      {!["businessDocuments", "companySummary", "contactPerson"].includes(
        selectedTab.id
      ) && (
        <StepButtons
          isFirstStep
          goToPrevStep={goToPrevStep}
          goToNextStep={goToNextStep}
          prevBtnLabel={"Previous Step"}
          nextBtnLink={null}
          nextBtnLabel={nextBtnLabel}
          loadingText={isDeferring ? "Deferring..." : "Submitting..."}
          isNextBtnLoading={isSubmitting || isDeferring || isVetting}
          isNextBtnDisabled={
            (disableSubmitBtn && selectedTab.id === "status") ||
            (!agreeToSubmitToBrs && selectedTab.id === "confirmation")
          }
          isLastStep={isLastStep}
          submitBtnDropdown={
            submitBtnDropdownOptions.length > 0 ? SubmitBtnDropdown : null
          }
        />
      )}
    </>
  );
}
