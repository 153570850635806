import { Field } from "formik";
import Label from "components/lib/Shared/Label";

const IsDirectorSelection = ({ isMinor, isDirector }) => {
  return (
    <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
      <div className="px-4 py-6 space-y-2 sm:p-6">
        <span>Will the above Official be a Director?</span>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Label htmlFor="yesDirector" disabled={isMinor}>
              YES
            </Label>
            <Field
              type="radio"
              name="isDirector"
              value="Yes"
              id="yesDirector"
              disabled={isMinor}
              checked={isDirector === "Yes"}
              className={
                "w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500"
              }
            />
          </div>
          <div className="flex items-center space-x-2">
            <Label htmlFor="notDirector">NO</Label>
            <Field
              type="radio"
              name="isDirector"
              value="No"
              id="notDirector"
              className={
                "w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
              }
              checked={isMinor || isDirector === "No"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsDirectorSelection;
