import { Fragment, useState } from "react";
import { string, object } from "yup";
import { Formik, Field, Form } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/outline";

import Input from "components/lib/Shared/Input";
import Button from "../Shared/Button";
import { Constants } from "config/constants";

const commentValidation = object().shape({
  comment: string()
    .trim("Comment cannot include leading and trailing spaces")
    .strict(true)
    .required("Comment cannot be empty"),
});

export default function CommentsPopup({
  handleSaveComment = () => null,
  fieldName,
  formName,
  comment = {},
  fieldLabel,
  children,
}) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleSubmit = async (values) => {
    await handleSaveComment({
      text: values.comment,
      fieldLabel,
      fieldName,
      formName,
      commentId: comment?._id,
    });
    closeModal();
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <button type="button" onClick={openModal} className="flex space-x-1">
          {children}
        </button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[9999] overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl">
                <Formik
                  enableReinitialize
                  initialValues={{ comment: comment?.text ?? "" }}
                  validationSchema={commentValidation}
                  onSubmit={handleSubmit}
                  validateOnBlur={false}
                >
                  {({ values, isSubmitting }) => {
                    const remainingChars =
                      Constants.MAX_COMMENT_LENGTH - values.comment.length;
                    return (
                      <Form>
                        <div className="mt-2">
                          <Field
                            component={Input}
                            renderTextarea
                            name="comment"
                            placeholder="Add your comment..."
                            maxLength={Constants.MAX_COMMENT_LENGTH}
                          />
                        </div>
                        <small className="mt-1 text-gray-500 block text-right">
                          {remainingChars}{" "}
                          {remainingChars === 1 ? "character" : "characters"}{" "}
                          left
                        </small>
                        <div className="flex justify-between mt-2">
                          <Button
                            variant="text"
                            className="py-2 font-semibold rounded text-primary"
                            onClick={closeModal}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            className="flex items-center px-4 py-2 font-semibold text-white rounded bg-green-focused disabled:cursor-not-allowed"
                            isLoading={isSubmitting}
                            postIcon={ChevronRightIcon}
                          >
                            Save comment
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
