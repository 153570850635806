import axios from "../axios";

const getAll = (companyId, organizationId, accessRequest) => {
  let queryParams = "";

  if (companyId) {
    queryParams = `?company=${encodeURIComponent(companyId)}`;
  }

  if (organizationId) {
    queryParams = `?organization=${encodeURIComponent(organizationId)}`;
  }

  if (accessRequest) {
    queryParams += `&accessRequest=${accessRequest}`;
  }

  return axios.get(`/access-request-comments${queryParams}&order=asc`);
};

const create = (data) => axios.post("/access-request-comments", data);

const update = (commentId, data) =>
  axios.put(`/access-request-comments/${commentId}`, data);

const remove = (commentId) =>
  axios.delete(`/access-request-comments/${commentId}`);

const accessRequestCommentDataService = {
  getAll,
  create,
  update,
  remove,
};

export default accessRequestCommentDataService;
