/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { subYears, format } from "date-fns";
import DatePickerField from "components/lib/Shared/DatePickerField";
import Input from "components/lib/Shared/Input";

const NamesForm = ({
  field,
  form,
  resolvedFields = [],
  disableVerificationField,
  disableDateOfRegistration,
  ...props
}) => {
  const { idType, designation, disabled } = props;

  let maxDateOfRegistration = subYears(new Date(), 0);
  const [startDate, setStartDate] = useState(maxDateOfRegistration);

  useEffect(() => {
    if (designation === "Shareholder") {
      maxDateOfRegistration = new Date();
    }
    setStartDate(maxDateOfRegistration);
  }, [designation]);

  const { setFieldValue } = useFormikContext();

  const handleDateValue = (date) => {
    setFieldValue(field.name, {
      ...field.value,
      dateOfRegistration: format(date, "yyyy/MM/dd"),
    });
    return setStartDate(date);
  };

  const selectedDate =
    field.value && field.value.dateOfRegistration
      ? new Date(field.value.dateOfRegistration)
      : null;

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  if (
    ["Local Company", "Foreign Company", "Other e.g Parastatals"].includes(
      idType
    )
  ) {
    return (
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Company Name"
            name="companyName"
            placeholder="Enter company name"
            form={form}
            value={field.value?.companyName ?? ""}
            disabled={resolvedFields.includes("companyName") || disabled}
            errorPath="names.companyName"
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Company Registration Number"
            name="companyRegNumber"
            placeholder="Enter company registration number"
            form={form}
            value={field.value?.companyRegNumber ?? ""}
            disabled={
              resolvedFields.includes("companyRegNumber") ||
              disabled ||
              disableVerificationField
            }
            errorPath="names.companyRegNumber"
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <DatePickerField
            label="Company Registration Date"
            onChange={handleDateValue}
            selected={selectedDate}
            maxDate={maxDateOfRegistration}
            disabled={
              resolvedFields.includes("dateOfRegistration") ||
              disabled ||
              disableDateOfRegistration
            }
            form={form}
            name="dateOfRegistration"
            placeholderText="Select date of registration"
            errorPath="names.dateOfRegistration"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="First Name"
            name="firstName"
            placeholder="Enter first name"
            form={form}
            errorPath="names.firstName"
            value={field.value?.firstName ?? ""}
            onBlur={field.onBlur}
            disabled={resolvedFields.includes("firstName") || disabled}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Middle Name"
            name="middleName"
            placeholder="Enter middle name"
            form={form}
            value={field.value?.middleName ?? ""}
            disabled={resolvedFields.includes("middleName") || disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
            errorPath="names.middleName"
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Last Name"
            name="lastName"
            placeholder="Enter last name"
            form={form}
            value={field.value?.lastName ?? ""}
            disabled={resolvedFields.includes("lastName") || disabled}
            errorPath="names.lastName"
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Former Name (IF ANY)"
            name="formerName"
            placeholder="Enter former name"
            form={form}
            value={field.value?.formerName ?? ""}
            disabled={resolvedFields.includes("formerName") || disabled}
            errorPath="names.formerName"
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
      </div>
    </>
  );
};

export default NamesForm;
