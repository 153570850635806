import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import orderService from "services/order";

const initialState = {};

export const generateOrderIdAsync = createAsyncThunk(
  "orders/generateOrderIdAsync",
  async (queryParams, { rejectWithValue }) => {
    try {
      const res = await orderService.generateOrderId(queryParams);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    resetOrdersSlice: () => initialState,
  },
  extraReducers: {},
});

export default ordersSlice.reducer;
