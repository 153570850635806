import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Tabs from "components/lib/Shared/Tabs";
import RequestsTable from "components/AccountSettings/RequestsTable";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import RefreshIcon from "components/lib/Shared/Icons/RefreshIcon";
import { getAccessRequests } from "state/slices/accessRequest";
import { useToast } from "hooks/useToast";
import { getAccessRequestsTabsAndData } from "utils/accessRequests";
import { Constants } from "config/constants";

const initialTabs = [];

const Invites = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [transformedInvites, setTransformedInvites] = useState({});
  const [tabs, setTabs] = useState(initialTabs);
  const [isLoading, setIsLoading] = useState(false);

  // redux selector
  const userSlice = useSelector((state) => state.userSlice);
  const { electronicAddress } = userSlice;

  const { items: accessRequests, getAccessRequestsStatus } = useSelector(
    (state) => state.accessRequestsSlice
  );

  const dispatch = useDispatch();
  const { toast } = useToast(4000);

  const handleRefreshData = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await dispatch(
        getAccessRequests({
          query: {
            initiatedToEmail: electronicAddress?.emailAddress,
            registrarView: true,
          },
        })
      );
      toast("success", "Data refreshed successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { tempTabs, tempTransformedInvites = {} } =
      getAccessRequestsTabsAndData({ accessRequests });
    setTabs(tempTabs);
    setTransformedInvites(tempTransformedInvites);
  }, [accessRequests]);

  useEffect(() => {
    dispatch(
      getAccessRequests({
        query: {
          initiatedToEmail: electronicAddress?.emailAddress,
          registrarView: true,
        },
      })
    );
  }, []);

  const selectedTab = tabs[selectedTabIndex];
  let tableData = transformedInvites?.[selectedTab?.id] ?? [];

  if (getAccessRequestsStatus.status === "loading") {
    return (
      <p className="flex justify-center">
        <SpinnerIcon className="text-gray-500" />
      </p>
    );
  }

  if (tableData.length === 0) {
    return (
      <div className="text-center">
        <svg
          className="w-12 h-12 mx-auto text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No Invites</h3>
      </div>
    );
  }

  return (
    <>
      <div className="sm:-mt-4">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          PostPendComponent={
            <div
              onClick={handleRefreshData}
              className="relative px-1 py-4 overflow-hidden text-sm font-medium text-gray-500 border-b-2 border-transparent cursor-pointer hover:text-gray-700 hover:border-gray-300 whitespace-nowrap text-ellipsis"
            >
              <RefreshIcon
                className={`w-5 h-5 ${
                  isLoading ? "animate-spin cursor-not-allowed" : ""
                }`}
                aria-hidden="true"
              />
            </div>
          }
        />
      </div>
      {accessRequests.length > 0 && (
        <div className="flex items-center justify-between mt-10">
          <div>
            <h2 className="font-semibold ">{selectedTab?.description}</h2>
          </div>
        </div>
      )}
      <div>
        {tableData.length > 0 ? (
          <RequestsTable
            requests={tableData}
            key={selectedTab.id}
            registrarView
          />
        ) : (
          <h3 className="mt-6 text-xl font-medium text-center">
            No data found
          </h3>
        )}
      </div>
    </>
  );
};

export default Invites;
