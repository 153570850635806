/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Field } from "formik";

import HoldingCompany from "./HoldingCompanyComponent";
import CommentsList from "components/lib/Global/CommentsList";
import ValidationError from "components/lib/Shared/ValidationError";

function AmalgamationComponent({
  field,
  form: { setFieldValue, errors, touched, values = {} },
  transformedComments,
}) {
  const isAmalgamationCompany = field.value?.isAmalgamationCompany;

  const handleChange = (e) => {
    setFieldValue(field.name, {
      isAmalgamationCompany: e.target.value,
    });
  };

  const isAmalgamationCompanyComments =
    transformedComments?.["businessApplicationDetails"]
      ?.isAmalgamationCompany ?? [];

  return (
    <>
      <h5>
        <span className="relative">
          Was your business formed as a result of amalgamation or acquisition?
          {isAmalgamationCompanyComments.length > 0 && (
            <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
              <span>{isAmalgamationCompanyComments.length}</span>
            </span>
          )}
        </span>
      </h5>
      <div>
        <div className="flex mb-4 space-x-16">
          <div className="space-x-2">
            <Field
              type="radio"
              name="isAmalgamationCompany"
              id="amalgamationCompany"
              value="Yes"
              checked={isAmalgamationCompany === "Yes"}
              onChange={handleChange}
              className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              disabled={values?.resolvedFields?.includes(
                "isAmalgamationCompany"
              )}
            />
            <label
              className="text-sm font-medium text-gray-700"
              htmlFor="amalgamationCompany"
            >
              Yes
            </label>
          </div>
          <div className="space-x-2">
            <Field
              type="radio"
              name="isAmalgamationCompany"
              id="nonAmalgamationCompany"
              value="No"
              checked={isAmalgamationCompany === "No"}
              onChange={handleChange}
              className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              disabled={values?.resolvedFields?.includes(
                "isAmalgamationCompany"
              )}
            />
            <label
              className="text-sm font-medium text-gray-700"
              htmlFor="nonAmalgamationCompany"
            >
              No
            </label>
          </div>
        </div>
        {isAmalgamationCompanyComments.length > 0 && (
          <CommentsList
            comments={isAmalgamationCompanyComments}
            color={"text-red-500"}
          />
        )}
        <ValidationError
          errors={errors}
          touched={touched}
          name="amalgamationDetail.isAmalgamationCompany"
        />
        {isAmalgamationCompany === "Yes" && (
          <Field
            name="amalgamationDetail"
            component={HoldingCompany}
            transformedComments={transformedComments}
          />
        )}
      </div>
    </>
  );
}

export default AmalgamationComponent;
