/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { subYears, format } from "date-fns";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import DatePickerField from "components/lib/Shared/DatePickerField";
import Input from "components/lib/Shared/Input";

// Identification Details for Kenyan Citizen
const IdentificationDetailsCompanySecretaryKeCi = ({ field }) => {
  const maxDateOfBirth = subYears(new Date(), 18);

  const [startDate, setStartDate] = useState("");

  const { setFieldValue } = useFormikContext();

  const handleSetValue = (e) => {
    const value =
      e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: value,
    });
  };

  const { values } = form;
  const currentUser = useSelector((state) => state.userSlice);
  const isApplicant = values?.createdBy === currentUser._id;
  const areCSFieldsOptional =
    (values?._id ? isApplicant : true) &&
    (values?.type === "invited" || values?.hasAccess);

  const handleDateValue = (date) => {
    setFieldValue(field.name, {
      ...field.value,
      dateOfBirth: format(date, "yyyy/MM/dd"),
    });
    return setStartDate(date);
  };

  const selectedDOB =
    field.value && field.value.dateOfBirth
      ? new Date(field.value.dateOfBirth)
      : startDate;

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-6 sm:col-span-2">
        <DatePickerField
          label="Date of Birth"
          onChange={handleDateValue}
          selected={selectedDOB}
          maxDate={maxDateOfBirth}
          name="dateOfBirth"
          placeholderText="Select date of birth"
          errorPath="identificationDetails.dateOfBirth"
          disabled={true}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Company Secretary Number"
          name="secretaryNumber"
          placeholder="Enter secretary number"
          showRequiredAsterisk
          value={field.value?.secretaryNumber ?? ""}
          areCSFieldsOptional={areCSFieldsOptional}
          errorPath="identificationDetails.secretaryNumber"
          disabled={
            selectedValueType !== "changes-excluding-email" ? false : true
          }
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="CS Practicing Number"
          name="practicingNumber"
          placeholder="Enter practicing number"
          showRequiredAsterisk
          value={field.value?.practicingNumber ?? ""}
          areCSFieldsOptional={areCSFieldsOptional}
          errorPath="identificationDetails.practicingNumber"
          disabled={true}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="National ID Number"
          name="nationalIDNumber"
          placeholder="Enter national ID number"
          value={field.value?.nationalIDNumber ?? ""}
          errorPath="identificationDetails.nationalIDNumber"
          disabled={true}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="KRA PIN"
          name="KRAPin"
          placeholder="Enter KRA PIN"
          value={field.value?.KRAPin ?? ""}
          errorPath="identificationDetails.KRAPin"
          disabled={true}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Occupation"
          name="occupation"
          placeholder="Enter occupation"
          value={field.value?.occupation ?? ""}
          errorPath="identificationDetails.occupation"
          disabled={false}
          onBlur={field.onBlur}
          onChange={handleSetValue}
        />
      </div>
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="Nationality"
          name="nationality"
          placeholder="KENYAN"
          value={"KENYAN"}
          errorPath="identificationDetails.nationality"
          onBlur={field.onBlur}
          onChange={handleSetValue}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default IdentificationDetailsCompanySecretaryKeCi;
