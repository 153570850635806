/* eslint-disable no-dupe-keys */
import React, { useState, useEffect, useRef } from "react";
import {
  Formik,
  Field,
  Form,
  validateYupSchema,
  yupToFormErrors,
  setNestedObjectValues,
} from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _pickBy from "lodash/pickBy";
import _omit from "lodash/omit";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _merge from "lodash/merge";
import NamesForm from "../../NamesComponents/index";
import IdentificationDetailsForm from "../../PersonalDetailsComponents";
import FileUploadForm from "../../FileUploadForm";
import { getCompanySecretaryDocumentData } from "../../FileUploadForm/documentData";
import {
  addCompanyOfficialAsync,
  updateCompanyOfficialAsync,
} from "state/slices/tables/companyOfficialsSlice";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";
import { closeModal } from "state/slices/modals";
import { resetSharesTable } from "state/slices/tables/sharesAllocationTable";
import { handleFilesUpload } from "utils/filesUtils";
import getInitialValues from "./getInitialValues";
import getValidationSchema from "./getValidationSchema";
import Verify from "components/lib/Shared/Verify";
import { clearEmpties, getFullName, getMaskedEmail } from "utils";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import InviteNewUser from "components/lib/Shared/Verify/InviteNewUser";
import InviteExistingUser from "components/lib/Shared/Verify/InviteExistingUser";
import ResidentialDetails from "./ResidentialDetails";
import ErrorBanner from "components/lib/Shared/ErrorBanner";
import { Constants } from "config/constants";

const formFields = [
  "type",
  "inviteStatus",
  "names",
  "identificationDetails",
  "isNonResident",
  "postalAddress",
  "electronicAddress",
  "residentialAddressDetails",
  "fileUploads",
  "inviteStatus",
];

const CompanySecretaryForms = (props) => {
  const dispatch = useDispatch();
  const [validationSchema, setValidationSchema] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const { company } = useSelector((state) => state.companySlice);
  const params = useParams();
  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const [showIdentificationSection, setShowIdentificationSection] =
    useState(true);
  const [verificationValue, setVerifyingValue] = useState("");
  const [isIdAssociated, setIsIdAssociated] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [inviteNewUserWorkflow, setInviteNewUserWorkflow] = useState(false);
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);
  const [isAccessRevoked, setIsAccessRevoked] = useState(false);
  const [inviteExistingUser, setInviteExistingUser] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const formStepErrors = useRef(null);
  const currentUser = useSelector((state) => state.userSlice);

  const {
    mode,
    currentOfficial,
    toggleShowOfficialsForm,
    idType,
    designation,
    isDirector = "No",
    selectedTab,
    isFirstStep,
    isLastStep,
    goToPrevStep,
    goToNextStep,
    isViewOnly,
    setTabsClickable,
  } = props;

  const documentData = getCompanySecretaryDocumentData(idType);

  const closeVerifyProcess = () => {
    toggleShowOfficialsForm(false);
    dispatch(resetSharesTable());
  };

  useEffect(() => {
    setValidationSchema(
      getValidationSchema({
        selectedTab,
        isNonResident: currentOfficial.isNonResident,
        idType,
        documentData,
      }).schema
    );
    setInitialValues(
      _merge(
        getInitialValues({
          idType,
          isNonResident: currentOfficial.isNonResident,
          documentData,
        }),
        currentOfficial
      )
    );
    setShowIdentificationSection(true);
  }, [selectedTab, idType, currentOfficial]);

  useEffect(() => {
    setVerifyingValue("");
    setIsIdAssociated(null);
    setInviteNewUserWorkflow(false);
    setIsAccessAllowed(false);
    setIsAccessRevoked(false);
    if (!mode.editing || isViewOnly) setDisableForm(true);
  }, [idType]);

  const addOrEditOfficial = async (values) => {
    const uploadedFiles = await handleFilesUpload(
      values.fileUploads,
      currentOfficial,
      mode.editing
    );
    const payload = {
      ..._omit(values, [
        "fileUploads",
        "beneficialOwnershipForm",
        "companyAddressDetails",
        "companyNameDetails",
        "comments",
        "userId",
      ]),
      idType,
      designation,
      isDirector,
      fileUploads: {
        ..._pickBy(values.fileUploads, (file) => typeof file === "string"),
        ...uploadedFiles,
      },
    };
    const companyId = company?._id ?? params?.companyId;
    const isGuardian =
      values?.identificationDetails?.guardianEmailAddress ===
      currentUser.electronicAddress.emailAddress;
    if (values.type === "invited" && isGuardian) {
      payload.inviteStatus = "Data Pending Review";
    }
    if (values.hasAccess) {
      payload.type = "invited";
      payload.inviteStatus =
        INDIVIDUALS.includes(payload.idType) &&
        !values.identificationDetails.secretaryNumber &&
        values?.areCSFieldsOptional
          ? "Awaiting Data"
          : "Approved";
    }
    if (!mode.editing) {
      const addOfficialResponse = await dispatch(
        addCompanyOfficialAsync({ companyId, data: clearEmpties(payload) })
      );
      if (values?.type === "invited" && !values.hasAccess) {
        window.sessionStorage.setItem(
          "flashMessageCOTable",
          `we have successfully sent the invite to ${getMaskedEmail(
            values.initiatedToEmail
          )}.
        In case they do not receive any email, let them check the spam folder before initiating another invite.`
        );
      }
      return addOfficialResponse;
    } else {
      const isOwnRow = [
        values?.electronicAddress?.emailAddress,
        values?.identificationDetails?.emailAddress,
      ].includes(currentUser.electronicAddress.emailAddress);
      const isApplicant = currentUser?._id === company?.createdBy?._id;

      if (isOwnRow && payload.type === "invited" && !isApplicant) {
        payload.inviteStatus = "Data Pending Review";
      }
      const updatedOfficialData = {};
      formFields.map((formField) => {
        if (!_isEqual(props.currentOfficial[formField], payload[formField])) {
          updatedOfficialData[formField] = payload[formField];
        }
      });

      if (!_isEmpty(updatedOfficialData)) {
        await dispatch(
          updateCompanyOfficialAsync({
            officialId: mode.id,
            data: { ...updatedOfficialData, companyId, idType },
          })
        );
        if (isOwnRow && payload.type === "invited" && !isApplicant) {
          window.sessionStorage.setItem(
            "flashMessageCOTable",
            `The application has been submitted back to the applicant for review.`
          );
          window.dispatchEvent(new Event("storage"));
        }
      }
      dispatch(closeModal("companyOfficial_edit_modal"));
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        comments:
          transformedComments?.[currentOfficial?._id]?.["stakeholderTab"] ?? [],
      }}
      validate={(values) => {
        try {
          const isApplicant = values?.createdBy === currentUser._id;
          const areCSFieldsOptional =
            (values?._id ? isApplicant : true) &&
            (values?.type === "invited" || values?.hasAccess);
          const makeFieldsOptional = isIdAssociated === false || isViewOnly;

          validateYupSchema(values, validationSchema, true, {
            makeFieldsOptional,
            areCSFieldsOptional:
              makeFieldsOptional ||
              (areCSFieldsOptional && !values.isIncompleteProfile),
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={async (values, { resetForm }) => {
        // validate form & store errors for the ErrorBanner
        try {
          validateYupSchema(values, validationSchema, true);
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: [],
          };
        } catch (error) {
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: error.errors,
          };
          if (!isLastStep) {
            setTimeout(goToNextStep, Constants.DELAY_FORM_SUBMISSION);
            return;
          }
        }
        if (!isLastStep) {
          goToNextStep();
          return;
        }

        const isApplicant = values?.createdBy === currentUser._id;
        const areCSFieldsOptional =
          (values?._id ? isApplicant : true) &&
          (values?.type === "invited" || values?.hasAccess);
        const makeFieldsOptional = isIdAssociated === false || isViewOnly;

        const officialSchema = getValidationSchema({
          idType,
          isNonResident: values.isNonResident,
          documentData,
        });
        console.log({ values });
        try {
          setIsFormValid(true);
          validateYupSchema(values, officialSchema, true, {
            areCSFieldsOptional: makeFieldsOptional || areCSFieldsOptional,
          });
          if (
            !isViewOnly ||
            company.createdBy._id === currentUser._id ||
            company.isCoApplicant
          )
            await addOrEditOfficial({ ...values, areCSFieldsOptional });
          dispatch(closeModal("companyOfficial_edit_modal"));
          toggleShowOfficialsForm(false);
          dispatch(resetSharesTable());
          resetForm();
        } catch (error) {
          console.log({ error });
          setIsFormValid(false);
          return;
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitCount,
        resetForm,
        setFieldValue,
        setTouched,
      }) => {
        const isContactPerson =
          [
            values?.electronicAddress?.emailAddress,
            values?.identificationDetails?.emailAddress,
          ].includes(currentUser.electronicAddress.emailAddress) &&
          values?.type === "invited";

        useEffect(() => {
          if (isContactPerson) {
            setFieldValue(
              "identificationDetails.companyContactName",
              getFullName(currentUser?.names)
            );
            setFieldValue(
              "identificationDetails.telNumber",
              currentUser?.electronicAddress?.mobileNumber ?? ""
            );
          }
        }, [isContactPerson]);

        useEffect(() => {
          setValidationSchema(
            getValidationSchema({
              selectedTab,
              idType,
              isNonResident: values.isNonResident,
              documentData,
            }).schema
          );
        }, [values.isNonResident]);

        useEffect(() => {
          resetForm();
          formStepErrors.current = null;
        }, [idType]);

        // Reset touched fields on tab change, e.g fix errors jumping around
        useEffect(() => {
          setTouched({}, false);
          setIsFormValid(true);
        }, [selectedTab, setTouched]);

        // Update ErrorBanner errors when user update data
        useEffect(() => {
          if (formStepErrors.current?.[selectedTab.id]?.length > 0) {
            try {
              validateYupSchema(values, validationSchema, true);
              formStepErrors.current = {
                ...formStepErrors.current,
                [selectedTab.id]: [],
              };
            } catch (error) {
              formStepErrors.current = {
                ...formStepErrors.current,
                [selectedTab.id]: error.errors,
              };
            }
          }
        }, [values]);

        // set touched to true for required fields to render errors
        useEffect(() => {
          if (
            values.isIncompleteProfile &&
            Object.keys(errors).length > 0 &&
            submitCount > 0
          ) {
            setTouched(setNestedObjectValues(errors, true));
          }
        }, [values.isIncompleteProfile, errors, submitCount]);

        // Invite new user form
        if (inviteNewUserWorkflow && selectedTab.id !== "assignOfficials") {
          return (
            <InviteNewUser
              closeVerifyProcess={closeVerifyProcess}
              idType={idType}
              designation={designation}
              values={values}
              inviteOfficial={addOrEditOfficial}
            />
          );
        }

        if (inviteExistingUser) {
          return (
            <InviteExistingUser
              userId={values.userId}
              idType={idType}
              designation={designation}
              closeVerifyProcess={closeVerifyProcess}
              userEmailAddress={values.userEmailAddress}
              values={values}
              inviteOfficial={addOrEditOfficial}
            />
          );
        }

        const handleNewUserWorkflow = () => {
          if (Object.keys(errors).length > 0) {
            setTouched(setNestedObjectValues(errors, true));
            return;
          } else setInviteNewUserWorkflow(true);
        };

        const handleResetForm = () => {
          resetForm();
          formStepErrors.current = null;
        };

        return (
          <Form>
            {isIdAssociated === false &&
              formStepErrors.current?.[selectedTab.id]?.length > 0 && (
                <ErrorBanner
                  errors={[...(formStepErrors.current?.[selectedTab.id] ?? [])]}
                  className="my-4"
                />
              )}
            {/* Invite new user button */}
            {verificationValue &&
              isIdAssociated === false &&
              selectedTab.id &&
              !["assignOfficials", "generalInformation"].includes(
                selectedTab.id
              ) &&
              !mode.editing && (
                <button
                  type="button"
                  onClick={handleNewUserWorkflow}
                  className="inline-flex self-end justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm w-content bg-sflPrimary hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-optional disabled:hover:text-white"
                >
                  Invite
                </button>
              )}
            {/* Personal Information */}
            {selectedTab.id === "generalInformation" && (
              <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                <div className="px-4 py-6 space-y-6 sm:p-6">
                  <div className="mb-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Personal Information
                    </h3>
                  </div>
                  <div>
                    {!mode.editing && (
                      <Verify
                        idType={idType}
                        designation={designation}
                        setShowIdentificationSection={
                          setShowIdentificationSection
                        }
                        verificationValue={verificationValue}
                        isIdAssociated={isIdAssociated}
                        isAccessAllowed={isAccessAllowed}
                        isAccessRevoked={isAccessRevoked}
                        setFieldValue={setFieldValue}
                        setVerifyingValue={setVerifyingValue}
                        setIsIdAssociated={setIsIdAssociated}
                        setDisableForm={setDisableForm}
                        resetForm={handleResetForm}
                        handleNewUserWorkflow={handleNewUserWorkflow}
                        setIsAccessAllowed={setIsAccessAllowed}
                        setIsAccessRevoked={setIsAccessRevoked}
                        setInviteExistingUser={setInviteExistingUser}
                        setTabsClickable={setTabsClickable}
                      />
                    )}
                  </div>
                  {showIdentificationSection && (
                    <>
                      {[
                        "Kenyan Citizen",
                        "Foreign Resident",
                        "Local Company",
                        "Other",
                      ].includes(idType) && (
                        <>
                          {submitCount > 0 &&
                            disableForm &&
                            !isAccessAllowed &&
                            !isViewOnly && (
                              <div
                                className="py-1 text-sm bg-gray-200 items-center text-red-500 leading-none lg:rounded-full flex lg:inline-flex"
                                role="alert"
                              >
                                <span className="font-semibold px-2 text-left flex-auto">
                                  To activate the form fields please verify your
                                  ID
                                </span>
                              </div>
                            )}
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            General Information
                          </h3>
                          <Field
                            name="names"
                            component={NamesForm}
                            idType={idType}
                            disabled={disableForm}
                            disableVerificationField={
                              isIdAssociated === false || mode.editing
                            }
                          />
                        </>
                      )}
                      <IdentificationDetailsForm
                        idType={idType}
                        designation={designation}
                        disabled={disableForm && !values.isIncompleteProfile}
                        disableVerificationField={
                          isIdAssociated === false || mode.editing
                        }
                        disableEmailAddress={
                          mode.editing && values?.type === "invited"
                        }
                        disableContactName={
                          mode.editing && values?.type === "invited"
                        }
                        disableTelNumber={
                          mode.editing && values?.type === "invited"
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            {/*  Address Particulars */}
            {selectedTab.id === "residentialDetails" && (
              <ResidentialDetails
                designation={designation}
                idType={idType}
                disabled={
                  disableForm &&
                  !values?.enableLongForm &&
                  !values.isIncompleteProfile
                }
                errors={errors}
                touched={touched}
                isNonResident={values.isNonResident}
              />
            )}
            {/* File Uploads Form */}
            {selectedTab.id === "uploadDocuments" && (
              <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                <div className="px-4 py-6 sm:p-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    KYC Document Uploads
                  </h3>
                  {(idType === "Kenyan Citizen" ||
                    idType === "Foreign Resident" ||
                    idType === "Local Company" ||
                    idType === "Other") && (
                    <div className="mb-2">
                      <h4>Please upload the following documents</h4>
                    </div>
                  )}
                  <Field
                    name="fileUploads"
                    component={FileUploadForm}
                    documentData={documentData}
                    disabled={
                      disableForm &&
                      !values?.enableLongForm &&
                      !values.isIncompleteProfile
                    }
                  />
                </div>
              </div>
            )}
            {isLastStep && !isFormValid && isIdAssociated === false && (
              <p className="text-sm text-red-500 my-2">
                Either enter all the required information or invite the
                official.
              </p>
            )}
            {selectedTab.id !== "assignOfficials" && (
              <div
                className={`flex ${
                  isFirstStep ? "justify-end" : "justify-between"
                } py-3`}
              >
                {!isFirstStep && (
                  <button
                    type="button"
                    disabled={isSubmitting}
                    onClick={goToPrevStep}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:cursor-not-allowed"
                  >
                    Go back
                  </button>
                )}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
                >
                  {isSubmitting && isLastStep ? (
                    <>
                      <SpinnerIcon />
                      Submitting...
                    </>
                  ) : (
                    "Save and proceed"
                  )}
                </button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanySecretaryForms;
