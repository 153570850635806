import { Formik, Field, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _pickBy from "lodash/pickBy";
import ValidationError from "components/lib/Shared/ValidationError";
import Input from "components/lib/Shared/Input";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import Button from "components/lib/Shared/Button.jsx";
import { updateCompanyDashboardState } from "state/slices/companyDashboard";
import { updateCompanyAsync } from "state/slices/company";
import { getCompanyDocumentsValidation } from "./companyDocumentsValidation.js";
import { handleFilesUpload } from "utils/filesUtils";

export default function CompanyDocumentsForm({ handleFormClose, field }) {
  const { company } = useSelector((state) => state.companyDashboardSlice);
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        kraPin: company?.kraPin ?? "",
        NSSFNo: company?.NSSFNo ?? "",
        NHIFNo: company?.NHIFNo ?? "",
        fileUploads: {
          kraPin: company?.fileUploads?.kraPin ?? "",
          NSSFNo: company?.fileUploads?.NSSFNo ?? "",
          NHIFNo: company?.fileUploads?.NHIFNo ?? "",
        },
      }}
      validationSchema={() => getCompanyDocumentsValidation(field)}
      onSubmit={async (values) => {
        const uploadedFiles = await handleFilesUpload(values.fileUploads);
        dispatch(
          updateCompanyAsync({
            id: company._id,
            data: {
              ...values,
              fileUploads: {
                ...company.fileUploads,
                ..._pickBy(
                  values.fileUploads,
                  (file) => typeof file === "string"
                ),
                ...uploadedFiles,
              },
            },
          })
        );
        dispatch(
          updateCompanyDashboardState({
            ...values,
            fileUploads: {
              ...company.fileUploads,
              ..._pickBy(
                values.fileUploads,
                (file) => typeof file === "string"
              ),
              ...uploadedFiles,
            },
          })
        );
        handleFormClose();
      }}
    >
      {({ errors, touched, isSubmitting }) => {
        return (
          <Form>
            <div className="flex flex-col space-y-3">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-6 space-y-4 bg-white border-gray-300 sm:p-6">
                  {field.name === "kraPin" && (
                    <div className="flex justify-between">
                      <div className="flex flex-col w-full">
                        <Field
                          name="kraPin"
                          id="kraPin"
                          placeholder="Add KRA PIN"
                          component={Input}
                          className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                        />
                        <div className="mt-1">
                          <label className="block mb-1 text-sm text-gray-600">
                            KRA PIN Certificate
                          </label>
                          <Field
                            name="fileUploads"
                            component={FileUploadForm}
                            wrapperDivStyles="py-2"
                            documentData={[{ label: "", name: "kraPin" }]}
                            onlyPreview={false}
                          />
                          <ValidationError
                            errors={errors.fileUploads}
                            touched={touched.fileUploads}
                            name={"fileUploads.kraPin"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {field.name === "NSSFNo" && (
                    <div className="flex justify-between">
                      <div className="flex flex-col w-full">
                        <Field
                          name="NSSFNo"
                          id="NSSFNo"
                          placeholder="Add NSSF NO"
                          component={Input}
                          className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                        />
                        <div className="mt-1">
                          <label className="block mb-1 text-sm text-gray-600">
                            NSSF Registration Certificate
                          </label>
                          <Field
                            name="fileUploads"
                            component={FileUploadForm}
                            wrapperDivStyles="py-2"
                            documentData={[{ label: "", name: "NSSFNo" }]}
                          />
                          <ValidationError
                            errors={errors.fileUploads}
                            touched={touched.fileUploads}
                            name={"fileUploads.NSSFNo"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {field.name === "NHIFNo" && (
                    <div className="flex justify-between">
                      <div className="flex flex-col w-full">
                        <Field
                          name="NHIFNo"
                          id="NHIFNo"
                          placeholder="Add NHIF NO"
                          component={Input}
                          className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                        />
                        <div className="mt-1">
                          <label className="block mb-1 text-sm text-gray-600">
                            NHIF Registration Certificate
                          </label>
                          <Field
                            name="fileUploads"
                            component={FileUploadForm}
                            wrapperDivStyles="py-2"
                            documentData={[{ label: "", name: "NHIFNo" }]}
                          />
                          <ValidationError
                            errors={errors.fileUploads}
                            touched={touched.fileUploads}
                            name={"fileUploads.NHIFNo"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-end space-x-2 py-3">
                    <Button
                      disabled={isSubmitting}
                      className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed`}
                      onClick={handleFormClose}
                    >
                      <div className="mr-1.5 flex items-center">Cancel</div>
                    </Button>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      loadingText="Saving..."
                      className="flex px-2 py-2 text-white button button-hover button-disabled button-outline button-focus bg-sflPrimary"
                    >
                      <div className="ml-1.5 flex items-center">Save</div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
