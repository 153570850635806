import React, { useState, useMemo } from "react";
import { SwitchHorizontalIcon } from "@heroicons/react/solid";
import BreadCrumb from "components/lib/Global/BreadCrumb";
import { RadioGroup } from "@headlessui/react";
import CompanyProcesses from "components/lib/Shared/CompanyProcesses";
import ServicesList from "components/lib/Shared/ServicesList";
import VerifyCorporateDashboards from "./VerifyCorporateDashboards";

const TransferYourBusiness = () => {
  const [selectedCompanyProcess, setSelectedCompanyProcess] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const pages = [
    {
      name: "Select your package",
      href: "/#package-selection",
      current: false,
    },
  ];

  const companyProcesses = useMemo(() => {
    return [
      {
        id: "setUpYourBusiness",
        title: "Setup your business",
        description:
          "Initiate transfer of existing business operations or ownership to Simple Formations Limited.",
        icon: <SwitchHorizontalIcon />,
        hash: "setup-business",
      },
      {
        id: "transferYourBusiness",
        title: "Transfer your business",
        description:
          "Initiate transfer of existing business operations or ownership to Simple Formations Limited.",
        icon: <SwitchHorizontalIcon />,
        hash: "transfer-business",
      },
    ];
  }, []);

  const handleCompanyProcessSelection = (processId) => {
    setSelectedCompanyProcess(processId);
  };

  return (
    <>
      <BreadCrumb pages={pages} />
      <div className="my-10 text-sflPrimary">
        <RadioGroup
          value={selectedCompanyProcess}
          onChange={handleCompanyProcessSelection}
          className="my-4"
        >
          <RadioGroup.Label className="text-base">
            <h1 className="font-medium">What would you like to do today?</h1>
          </RadioGroup.Label>
          <CompanyProcesses companyProcesses={companyProcesses} />
        </RadioGroup>
        {selectedCompanyProcess?.id === "setUpYourBusiness" && (
          <>
            <ServicesList
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              className="mb-4"
            />
          </>
        )}
        {selectedCompanyProcess?.id === "transferYourBusiness" && (
          <>
            <div className="mt-2">
              <VerifyCorporateDashboards />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TransferYourBusiness;
