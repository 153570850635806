import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "@heroicons/react/outline";

import Button from "components/lib/Shared/Button";
import useFullscreenToggle from "hooks/useFullscreenToggle";
import PaginationControls from "../PaginationControls";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import BreadCrumbs from "../BreadCrumbs";
import {
  downloadRegistersOfShareCertificateAsync,
  getRegisterOfShareCertificatesAsync,
} from "state/slices/minuteBook";
import IssuedCertificate from "./IssuedCertificate";
import Counterfeit from "./Counterfeit";

function ShareCertificates({ selectedTab }) {
  const { isFullscreen, ToggleFullscreenContainer, ToggleFullscreenButton } =
    useFullscreenToggle();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { company } = useSelector((state) => state.companyDashboardSlice);
  const {
    registerOfShareCertificates,
    getRegisterOfShareCertificates,
    downloadRegisterOfShareCertificates,
  } = useSelector((state) => state.minuteBookSlice);

  const { unpaidClassesOfShares } = registerOfShareCertificates;

  const companyId = company._id;

  useEffect(() => {
    if (companyId) {
      dispatch(
        getRegisterOfShareCertificatesAsync({
          queryParams: { companyId },
        })
      );
    }
  }, [companyId]);

  const currentShareCertificate =
    registerOfShareCertificates.items[currentPage - 1];

  if (
    getRegisterOfShareCertificates.status === "loading" ||
    !currentShareCertificate
  ) {
    return (
      <div className="col-span-6">
        <div className="flex justify-center mx-auto text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </div>
    );
  }

  const handleDownloadClick = () => {
    dispatch(
      downloadRegistersOfShareCertificateAsync({
        queryParams: {
          companyId: company._id,
          fileName: `${company.registrationName}.zip`,
        },
      })
    );
  };

  const companySuffix = company.registrationName.split(" ").pop();

  const companyRegistrationName = company.registrationName
    .replace(companySuffix, "")
    .trim();

  const sumNominalCapital = company.nominalShares
    .map((share) => share.sharesValue * share.sharesNumber)
    .reduce((a, b) => a + b, 0);

  return (
    <div className="overflow-auto">
      <div className="flex justify-between w-full mb-4">
        <BreadCrumbs breadCrumbs={selectedTab.breadCrumbs} />
        <Button
          isLoading={downloadRegisterOfShareCertificates.status === "loading"}
          loadingText="Downloading..."
          onClick={handleDownloadClick}
          preIcon={DownloadIcon}
        >
          Download
        </Button>
      </div>
      <ToggleFullscreenContainer>
        <ToggleFullscreenButton />
        <div
          className={`bg-white relative ${
            isFullscreen ? "h-[88vh] overflow-auto" : ""
          }`}
        >
          <div
            className={`overflow-auto px-1 pt-4 pb-20 space-y-2 flex flex-col text-center ${
              isFullscreen ? "px-12" : ""
            }`}
          >
            <div className="flex flex-col justify-center items-center">
              <h2 className="text-title6 font-medium">SHARE CERTIFICATE</h2>
              <div
                className="mt-4 text-sm flex text-gray-700 max-w-[80rem]"
                style={{
                  backgroundImage:
                    "url('https://sfl-bucket.s3.us-west-2.amazonaws.com/share-certificate-background.jpg')",
                }}
              >
                <Counterfeit
                  companyRegistrationName={companyRegistrationName}
                  companySuffix={companySuffix}
                  currentShareCertificate={currentShareCertificate}
                  unpaidClassesOfShares={unpaidClassesOfShares}
                  isFullscreen={isFullscreen}
                />
                <IssuedCertificate
                  companyRegistrationName={companyRegistrationName}
                  companySuffix={companySuffix}
                  currentShareCertificate={currentShareCertificate}
                  sumNominalCapital={sumNominalCapital}
                  unpaidClassesOfShares={unpaidClassesOfShares}
                  nominalShares={company.nominalShares}
                  isFullscreen={isFullscreen}
                />
              </div>
            </div>
          </div>
          <PaginationControls
            isFullscreen={isFullscreen}
            disableNextBtn={
              currentPage === registerOfShareCertificates.totalCount
            }
            renderNextBtn={
              currentPage !== registerOfShareCertificates.totalCount
            }
            currentPage={currentPage}
            totalRows={registerOfShareCertificates.totalCount}
            totalPages={registerOfShareCertificates.totalCount}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </ToggleFullscreenContainer>
    </div>
  );
}

export default ShareCertificates;
