/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { Field } from "formik";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

import ValidationError from "components/lib/Shared/ValidationError";
import CommentsList from "components/lib/Global/CommentsList";

function HoldingCompany({
  field,
  form: { setFieldValue, errors, touched, values = {} },
  transformedComments,
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const detailOfBond = field.value?.detailOfBond;

  // initialValues are required for validation
  useEffect(() => {
    setFieldValue(field.name, {
      detailOfBond: "",
      holdingCompanyName: "",
      holdingCompanyPin: "",
      holdingCompanyiTaxEmail: "",
      amalgamationDate: "",
      isAmalgamationCompany: "Yes",
      ...field.value,
    });
  }, []);

  useEffect(() => {
    if (field.value?.amalgamationDate) {
      setSelectedDate(new Date(field.value.amalgamationDate));
    }
  }, [field]);

  const handleChange = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateValue = (type) => (date) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: format(date, "yyyy/MM/dd"),
    });
    setSelectedDate(date);
  };

  const detailOfBondComments =
    transformedComments?.["businessApplicationDetails"]?.[
      "amalgamationDetail.detailOfBond"
    ] ?? [];
  const holdingCompanyPinComments =
    transformedComments?.["businessApplicationDetails"]?.[
      "amalgamationDetail.holdingCompanyPin"
    ] ?? [];
  const holdingCompanyNameComments =
    transformedComments?.["businessApplicationDetails"]?.[
      "amalgamationDetail.holdingCompanyName"
    ] ?? [];
  const holdingCompanyiTaxEmailComments =
    transformedComments?.["businessApplicationDetails"]?.[
      "amalgamationDetail.holdingCompanyiTaxEmail"
    ] ?? [];
  const amalgamationDateComments =
    transformedComments?.["businessApplicationDetails"]?.[
      "amalgamationDetail.amalgamationDate"
    ] ?? [];

  return (
    <div>
      <b id="my-radio-group">
        <span className="relative">
          <h3 className="font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
            Detail of Bond?
          </h3>
          {detailOfBondComments.length > 0 && (
            <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
              <span>{detailOfBondComments.length}</span>
            </span>
          )}
        </span>
      </b>
      <div className=" mb-4">
        <div className="flex space-x-16">
          <label>
            <Field
              type="radio"
              name="detailOfBond"
              value="Amalgamation"
              checked={detailOfBond === "Amalgamation"}
              onChange={handleChange}
              className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              disabled={values?.resolvedFields?.includes(
                "amalgamationDetail.detailOfBond"
              )}
            />{" "}
            Amalgamation
          </label>
          <label>
            <Field
              type="radio"
              name="detailOfBond"
              value="Acquisition"
              checked={detailOfBond === "Acquisition"}
              onChange={handleChange}
              className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              disabled={values?.resolvedFields?.includes(
                "amalgamationDetail.detailOfBond"
              )}
            />{" "}
            Acquisition
          </label>
        </div>
        {detailOfBondComments.length > 0 && (
          <CommentsList
            comments={detailOfBondComments}
            color={"text-red-500"}
          />
        )}
        <ValidationError
          errors={errors}
          touched={touched}
          name="amalgamationDetail.detailOfBond"
        />
      </div>
      <div className="grid grid-cols-6 gap-6 bg-gray-50 p-4">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="holdingCompanyPin"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Holding Company Pin
              {holdingCompanyPinComments.length > 0 && (
                <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
                  <span>{holdingCompanyPinComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <input
            type="text"
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            id="holdingCompanyPin"
            name="holdingCompanyPin"
            placeholder="Enter holding company pin"
            value={field.value?.holdingCompanyPin}
            onChange={handleChange}
            disabled={values?.resolvedFields?.includes(
              "amalgamationDetail.holdingCompanyPin"
            )}
          />
          {holdingCompanyPinComments.length > 0 && (
            <CommentsList
              comments={holdingCompanyPinComments}
              color={"text-red-500"}
            />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="amalgamationDetail.holdingCompanyPin"
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="holdingCompanyName2"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Holding Company Name
              {holdingCompanyNameComments.length > 0 && (
                <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
                  <span>{holdingCompanyNameComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <input
            type="text"
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            id="holdingCompanyName2"
            name="holdingCompanyName"
            placeholder="Enter holding company name"
            value={field.value?.holdingCompanyName}
            onChange={handleChange}
            disabled={values?.resolvedFields?.includes(
              "amalgamationDetail.holdingCompanyName"
            )}
          />
          {holdingCompanyNameComments.length > 0 && (
            <CommentsList
              comments={holdingCompanyNameComments}
              color={"text-red-500"}
            />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="amalgamationDetail.holdingCompanyName"
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="holdingCompanyiTaxEmail"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Holding Company's Email On iTax
              {holdingCompanyiTaxEmailComments.length > 0 && (
                <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
                  <span>{holdingCompanyiTaxEmailComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <input
            type="email"
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            id="holdingCompanyiTaxEmail"
            name="holdingCompanyiTaxEmail"
            placeholder="Enter holding company iTax email"
            value={field.value?.holdingCompanyiTaxEmail}
            onChange={handleChange}
            disabled={values?.resolvedFields?.includes(
              "amalgamationDetail.holdingCompanyiTaxEmail"
            )}
          />
          {holdingCompanyiTaxEmailComments.length > 0 && (
            <CommentsList
              comments={holdingCompanyiTaxEmailComments}
              color={"text-red-500"}
            />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="amalgamationDetail.holdingCompanyiTaxEmail"
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="amalgamationDate"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
              Date Of Amalgamation/Acquisition
              {amalgamationDateComments.length > 0 && (
                <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
                  <span>{amalgamationDateComments.length}</span>
                </span>
              )}
            </span>
          </label>
          <div className="form-group">
            <div className="row">
              <DatePicker
                name="amalgamationDate"
                id="amalgamationDate"
                selected={selectedDate}
                onChange={handleDateValue("amalgamationDate")}
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                autoComplete="off"
                placeholderText="Select amalgamation/acquisition date"
                showYearDropdown
                scrollableMonthYearDropdown
                disabled={values?.resolvedFields?.includes(
                  "amalgamationDetail.amalgamationDate"
                )}
              />
              {amalgamationDateComments.length > 0 && (
                <CommentsList
                  comments={amalgamationDateComments}
                  color={"text-red-500"}
                />
              )}
              <ValidationError
                errors={errors}
                touched={touched}
                name="amalgamationDetail.amalgamationDate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoldingCompany;
