import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/lib/Shared/Button";
import ProductsTable from "./Table";
import ProductForm from "./Form";
import DashOutline from "components/lib/Shared/Icons/DashOutline";
import { getProductsAsync } from "state/slices/product";

const Products = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.productSlice);
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [editableProduct, setEditableProduct] = useState({});

  useEffect(() => {
    dispatch(getProductsAsync());
  }, []);

  if (showAddProductForm) {
    return (
      <ProductForm
        editableProduct={editableProduct}
        setShowAddProductForm={setShowAddProductForm}
        setEditableProduct={setEditableProduct}
      />
    );
  }

  const handleAddNewProduct = () => {
    setShowAddProductForm(true);
    setEditableProduct({});
  };

  const handleEditProduct = (product) => {
    setEditableProduct(product);
    setShowAddProductForm(true);
  };

  return (
    <div className="pl-4">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold text-sflPrimary">Products</h3>
        <Button onClick={handleAddNewProduct}>Add new product</Button>
      </div>
      <ProductsTable handleEditProduct={handleEditProduct} />
    </div>
  );
};

export default Products;
