import React, { useState, useEffect } from "react";

import Tabs from "components/lib/Shared/Tabs";
import StepButtons from "components/lib/Global/StepButtons";

import CompanyFormation from "./CompanyFormation";
import AddressParticulars from "./AddressParticulars";
import ShareInformation from "./ShareInformation";
import StakeholderDetails from "./StakeholderDetails";
import BeneficialOwnership from "./BeneficialOwnership";
import FileUploads from "./FileUploads";
import Confirmation from "./Confirmation";

const tabs = [
  {
    id: "companyFormation",
    label: "1. Company formation",
  },
  {
    id: "addressParticulars",
    label: "2. Address particulars",
  },
  {
    id: "shareInformation",
    label: "3. Share information",
  },
  {
    id: "stakeholderDetails",
    label: "4. Stakeholder details",
  },
  {
    id: "beneficialOwnership",
    label: "5. Beneficial ownership",
  },
  {
    id: "fileUploads",
    label: "6. File Uploads",
  },
  {
    id: "confirmation",
    label: "7. Confirmation",
  },
];

const SummaryTab = ({ handleBackBtn }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isLastStep = tabs.length - 1 === selectedTabIndex;

  return (
    <>
      <p className="mb-4 text-primary">Applicants summary</p>

      <div className="overflow-x-auto">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          showTooltip
        />
      </div>
      {selectedTab.id === "companyFormation" && <CompanyFormation />}
      {selectedTab.id === "addressParticulars" && <AddressParticulars />}
      {selectedTab.id === "shareInformation" && <ShareInformation />}
      {selectedTab.id === "stakeholderDetails" && <StakeholderDetails />}
      {selectedTab.id === "beneficialOwnership" && <BeneficialOwnership />}
      {selectedTab.id === "fileUploads" && <FileUploads />}
      {selectedTab.id === "confirmation" && <Confirmation />}
      <StepButtons
        isFirstStep
        goToPrevStep={goToPrevStep}
        goToNextStep={goToNextStep}
        prevBtnLabel={"Previous Step"}
        nextBtnLink={isLastStep ? "/successful-submission" : null}
        nextBtnLabel={isLastStep ? "Submit for review" : "Confirm details"}
      />
    </>
  );
};

export default SummaryTab;
