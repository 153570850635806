const INDIVIDUALS = [
  "Kenyan Citizen",
  "Foreign Resident",
  "Foreigner",
  "Minor",
  "Estate",
];
const CORPORATES = [
  "Local Company",
  "Foreign Company",
  "Other e.g Parastatals",
];
const CORPORATES_ID_TYPES = {
  "Public Limited": "Add a Shareholder",
  "Private Limited": "Add a Shareholder",
  "Limited Liability Partnership": "Add Partner",
  "Limited Partnership": "Add Partner",
  Trust: "Add a Trustee",
  "Company Limited By Guarantee/NGO": "Add Member",
  "State Owned Enterprise": "Add Member",
};

const CORPORATE_ROLES = {
  "Public Limited": [
    { label: "Director", value: "Director" },
    { label: "Shareholder", value: "Shareholder" },
  ],
  "Private Limited": [
    { label: "Director", value: "Director" },
    { label: "Shareholder", value: "Shareholder" },
  ],
  "Limited Liability Partnership": [
    { label: "Partner", value: "Partner" },
    { label: "Manager", value: "Manager" },
    { label: "Partner Manager", value: "Partner Manager" },
  ],
  "Limited Partnership": [
    { label: "General  Partner", value: "General Partner" },
    { label: "Limited  Partner", value: "Limited Partner" },
  ],
  "Company Limited By Guarantee/NGO": [
    { label: "Member", value: "Member" },
    { label: "Director", value: "Director" },
    { label: "Director Member", value: "Director Member" },
  ],
  Trust: [
    { label: "Settlor", value: "Settlor" },
    { label: "Protector", value: "Protector" },
    { label: "Trustee", value: "Trustee" },
    { label: "Beneficiary", value: "Beneficiary" },
  ],
  "State Owned Enterprise": [
    { label: "Shareholder", value: "Shareholder" },
    { label: "Director", value: "Director" },
  ],
  Estate: [
    { label: "Executor / Executrix", value: "Executor / Executrix" },
    { label: "Administrator", value: "Administrator" },
    { label: "Beneficiary", value: "Beneficiary" },
  ],
};

const OWNERS_ROLES = {
  "Limited Liability Partnership": [
    { label: "Partner", value: "Partner" },
    { label: "Manager", value: "Manager" },
    { label: "Partner Manager", value: "Partner Manager" },
    { label: "Authorized Person", value: "Authorized Person" },
  ],
  "Limited Partnership": [
    { label: "General  Partner", value: "General Partner" },
    { label: "Limited  Partner", value: "Limited Partner" },
    { label: "Authorized Person", value: "Authorized Person" },
  ],
  "General Partnership": [
    { label: "General  Partner", value: "General Partner" },
    { label: "Authorized Person", value: "Authorized Person" },
  ],
  "Sole Proprietorship": [
    { label: "Sole Proprietorship", value: "Sole Proprietorship" },
    { label: "Authorized Person", value: "Authorized Person" },
  ],
};

const CORPORATE_MAX_VALUE = 100;
const CORPORATE_SHAREHOLDERS_LIMIT = 10;

export {
  INDIVIDUALS,
  CORPORATES,
  CORPORATES_ID_TYPES,
  CORPORATE_ROLES,
  CORPORATE_MAX_VALUE,
  CORPORATE_SHAREHOLDERS_LIMIT,
  OWNERS_ROLES,
};
