/* eslint-disable react/jsx-props-no-spreading */
import { useFormikContext } from "formik";
import React from "react";

import Input from "components/lib/Shared/Input";
// Residential Address form with with common input for all ID types
const ResidentialAddressDetailsForm = ({ field, disabled }) => {
  const { setFieldValue } = useFormikContext();

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Residential Address
      </h3>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Building Name"
            name="buildingName"
            placeholder="Enter building name"
            value={field.value?.buildingName ?? ""}
            errorPath="residentialAddressDetails.buildingName"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="House Number"
            name="houseNumber"
            placeholder="Enter house number"
            value={field.value?.houseNumber ?? ""}
            errorPath="residentialAddressDetails.houseNumber"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Estate"
            name="estate"
            placeholder="Enter estate name"
            value={field.value?.estate ?? ""}
            errorPath="residentialAddressDetails.estate"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Floor Number"
            name="floorNumber"
            placeholder="Enter floor number"
            value={field.value?.floorNumber ?? ""}
            errorPath="residentialAddressDetails.floorNumber"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Street Name"
            name="streetName"
            placeholder="Enter street name"
            value={field.value?.streetName ?? ""}
            errorPath="residentialAddressDetails.streetName"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Street Number"
            name="streetNumber"
            placeholder="Enter street number"
            value={field.value?.streetNumber ?? ""}
            errorPath="residentialAddressDetails.streetNumber"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
      </div>
    </>
  );
};

export default ResidentialAddressDetailsForm;
