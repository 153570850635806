import { object, array, number, string, boolean } from "yup";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";

/**
 * This returns an object composed of conditional `yup` schema and initial values
 * related to share allocation and beneficial ownership form.
 *
 * @param {string} idType The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {array} shares The array of allotted shares to current official.
 * @param {number} maxPercentShareholding Max percentage of shareholding.
 * @param {number} maxPercentVotingRights Max percentage of voting rights.
 * @param {Boolean} isReportable Is current official reportable or not `true` | `false`.
 * @return {object} This returns object containing schema and intialValues based on schema.
 * @example
 *  {
 *    schema // yup schema
 *    schemaInitialValues
 *  }
 */
const shareAllocationValidation = (idType, shares, isReportable) => {
  const schema = object().shape({
    isReportable: boolean().default(isReportable),
    showShareAllocationForm: boolean(),
    sharesAllotted: array().when("showShareAllocationForm", {
      is: (showShareAllocationForm) =>
        showShareAllocationForm && shares && shares.length === 0,
      then: array().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: array().optional(),
        otherwise: array().required("Allot at least one share"),
      }),
    }),
    beneficialOwnershipForm: object()
      .when("showShareAllocationForm", {
        is: (showShareAllocationForm) => showShareAllocationForm,
        then: object().shape({
          maxPercentShareholding: number().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: number().optional(),
            otherwise: number().required(
              "Max percent shareholding is required"
            ),
          }),
          directPercentShareholding: number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .when("$maxPercentShareholding", {
              is: (maxPercentShareholding) =>
                INDIVIDUALS.includes(idType) && maxPercentShareholding > 0,
              then: number().when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: number().optional(),
                otherwise: number()
                  .min(0, "Value must be greater than or equal to 0")
                  .required("Direct percent shareholding is required"),
              }),
            }),
          indirectPercentShareholding: number().when(
            "$maxPercentShareholding",
            {
              is: (maxPercentShareholding) => maxPercentShareholding > 0,
              then: number().when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: number().optional(),
                otherwise: number()
                  .min(0, "Value must be greater than or equal to 0")
                  .required("Indirect percent shareholding is required"),
              }),
            }
          ),
          maxPercentVotingRights: number().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: number().optional(),
            otherwise: number().required(
              "Max percent voting rights is required"
            ),
          }),
          directPercentVotingRights: number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .when("$maxPercentVotingRights", {
              is: (maxPercentVotingRights) =>
                INDIVIDUALS.includes(idType) && maxPercentVotingRights > 0,
              then: number().when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: number().optional(),
                otherwise: number()
                  .min(0, "Value must be greater than or equal to 0")
                  .required("Direct percent voting rights is required"),
              }),
            }),
          indirectPercentVotingRights: number().when(
            "$maxPercentVotingRights",
            {
              is: (maxPercentVotingRights) => maxPercentVotingRights > 0,
              then: number().when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: number().optional(),
                otherwise: number()
                  .min(0, "Value must be greater than or equal to 0")
                  .required("Indirect percent voting rights is required"),
              }),
            }
          ),
          directRightRemoveDirector: string().when("idType", {
            is: () => INDIVIDUALS.includes(idType),
            then: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Please select one option"),
            }),
          }),
          indirectRightRemoveDirector: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Please select one option"),
          }),
          directCompanyControlRight: string().when("idType", {
            is: () => INDIVIDUALS.includes(idType),
            then: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Please select one option"),
            }),
          }),
          indirectCompanyControlRight: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Please select one option"),
          }),
          dateOfBecomingBO: string().when("isReportable", {
            is: () => isReportable === true,
            then: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Date of becoming BO is required"),
            }),
            otherwise: string().notRequired(),
          }),
          sourceOfBOInformation: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required(
              "Source of beneficial owner information is required"
            ),
          }),
        }),
      })
      .default({
        maxPercentShareholding: "",
        directPercentShareholding: "",
        indirectPercentShareholding: "",
        maxPercentVotingRights: "",
        directPercentVotingRights: "",
        indirectPercentVotingRights: "",
        directRightRemoveDirector: "",
        indirectRightRemoveDirector: "",
        directCompanyControlRight: "",
        indirectCompanyControlRight: "",
        dateOfBecomingBO: "",
        sourceOfBOInformation: "",
      }),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default shareAllocationValidation;
