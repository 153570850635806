import React from "react";

const Hero = ({ heading, description = "" }) => {
  return (
    <div>
      <h1 className="text-4xl font-semibold text-primary">{heading}</h1>
      <p className="my-3 text-primary sm:w-2/3">{description}</p>
    </div>
  );
};

export default Hero;
