import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import ConfirmOrganizationReviewerSuccess from "components/SystemAdminDashboard/ConfirmOrganizationReviewerSuccess";
import { confirmOrganizationReviewer } from "state/slices/organizations";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

export default function ConfirmReviewerPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [redirectTo, setRedirectTo] = useState("login");

  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(async () => {
    if (token) {
      const response = await dispatch(confirmOrganizationReviewer({ token }));
      if (response?.error?.name) {
        setError("Link is not valid.");
      } else {
        setRedirectTo(response?.payload?.redirectTo ?? "login");
      }
      setLoading(false);
    }
  }, [token]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <SpinnerIcon className="text-gray-500" />
      </div>
    );

  if (error) return <span>{error}</span>;

  return <ConfirmOrganizationReviewerSuccess redirectTo={redirectTo} />;
}
