import React, { useState } from "react";
import { PencilAltIcon } from "@heroicons/react/solid";
import { format } from "date-fns";

import Button from "components/lib/Shared/Button";
import useFullscreenToggle from "hooks/useFullscreenToggle";
import Overlay from "components/lib/Shared/Overlay";
import PaginationControls from "./PaginationControls";
import BreadCrumbs from "./BreadCrumbs";

function RegisterOfChargesOrMortgages({ selectedTab }) {
  const { isFullscreen, ToggleFullscreenContainer, ToggleFullscreenButton } =
    useFullscreenToggle();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="relative overflow-auto">
      <Overlay showSpinner={false} />
      <div className="flex justify-between w-full mb-4">
        <BreadCrumbs breadCrumbs={selectedTab.breadCrumbs} />
        <Button disabled>Download</Button>
      </div>
      <ToggleFullscreenContainer>
        <ToggleFullscreenButton />
        <div
          className={`bg-white relative ${
            isFullscreen ? "h-[88vh] overflow-auto" : ""
          }`}
        >
          {currentPage === 1 && (
            <div
              className={`overflow-auto px-1 pt-4 pb-20 space-y-2 flex flex-col text-center ${
                isFullscreen ? "px-12" : ""
              }`}
            >
              {/* PARTICULARS OF MORGAGES OR CHARGES */}
              <div>
                <h2 className="text-title6 font-medium">
                  PARTICULARS OF MORGAGES OR CHARGES
                </h2>
                <div className="flex items-center justify-center">
                  <span className="mr-2 text-sm">
                    as at {format(new Date(), "dd-MM-yyyy")}
                  </span>
                  <PencilAltIcon className="w-4 h-4" />
                </div>
                <div className="mt-4">
                  <table className="min-w-full divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
                    <thead>
                      <tr className="text-xs">
                        {[
                          "Date of the Instrument Creating or Evidencing the Mortgage or Charge and Description thereof.  A description of the Instrument - e.g., Trust Deed, Mortgage, & c., as the case may be - should be given.",
                          "Amount secured b the Mortgage or Charge",
                          "Short Particulars of the Property Mortgaged or Charged",
                          "",
                          "",
                          "Names (with Addresses and Descriptions) of the Mortgages, or Persons Entitled to the Charge",
                          "Amount or rate per cent of the Commission Allowance, or Discount (if any) paid or made either directly or indirectly by the Company to any person in consideration of this Subscribing or agreeing to subscribe, whether absolutely or conditionally or procuring or agreeing to procure subscriptions, whether absolure or conditional, for any of the Debentures included in this return.",
                        ].map((heading) => {
                          return (
                            <th
                              scope="col"
                              className="font-semibold text-gray-900 bg-gray-100"
                            >
                              {heading}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {Array.from(new Array(17)).map((_, index) => (
                        <tr key={index} className="text-xs">
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <PaginationControls isFullscreen={isFullscreen} />
        </div>
      </ToggleFullscreenContainer>
    </div>
  );
}

export default RegisterOfChargesOrMortgages;
