/* eslint-disable no-param-reassign */
/* eslint-disable  no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { CORPORATE_SHAREHOLDERS_LIMIT } from 'components/PLCTABS/SFLdata/config/constants'

const corporateShareholderTableSlice = createSlice({
  name: 'Corporate Shareholder Table',
  initialState: [],
  reducers: {
    addMultipleCorporateShareholder: (state, action) => {
      state.push(...action.payload)
    },
    addCorporateShareholder: (state, action) => {
      const id = uuidv4()
      const { payload } = action
      if (!payload.id) payload.id = id
      state.push(payload)
    },
    deleteCorporateShareholder: (state, { payload }) => {
      const officialId = payload.id || payload._id
      // if (state.length === CORPORATE_SHAREHOLDERS_LIMIT + 1) state.pop()
      const index = state.findIndex(item => officialId === item.id || officialId === item._id)
      if (index > -1) state.splice(index, 1)
    },
    editCorporateShareholder: (state, { payload }) => {
      const officialId = payload?.id || payload?._id
      const index = state.findIndex(item => officialId === item.id || officialId === item._id)
      if (index > -1) {
        state.splice(index, 1, {
          ...payload,
        })
      }
    },
    resetCorporateShareholderTable: state => {
      return []
    }
  },
})

export const {
  addMultipleCorporateShareholder,
  addCorporateShareholder,
  deleteCorporateShareholder,
  editCorporateShareholder,
  resetCorporateShareholderTable
} = corporateShareholderTableSlice.actions

export default corporateShareholderTableSlice.reducer
