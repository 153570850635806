/* eslint-disable react/button-has-type */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import _isFunction from "lodash/isFunction";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import { closeModal } from "state/slices/modals";

const ModalWrapper = (props) => {
  const {
    maxWidth = "sm:max-w-lg",
    titleColor = "text-gray-900",
    handleClose = () => null,
  } = props;
  const { modals } = useSelector((state) => state.modalsSlice);

  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  const modalIndex = modals.indexOf(props.name);

  useEffect(() => {
    setModalOpen(modalIndex > -1);
  }, [modalIndex, modals, props.name]);

  const close = () => {
    if (_isFunction(props?.toggleShowJSForm)) {
      props.toggleShowJSForm(false);
    }
    handleClose();
    dispatch(closeModal(props.name));
  };

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block px-4 pt-5 pb-4 mt-20 mb-10 text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:align-middle ${maxWidth} sm:w-full sm:p-6`}
            >
              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => close(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className={`text-lg font-medium leading-6 ${titleColor}`}
                  >
                    {props.title}
                  </Dialog.Title>
                  <div className="mt-2">{props.children}</div>
                </div>
              </div>
              {props.actionBtnText && (
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button color="primary" onClick={() => props.action()}>
                    {props.actionBtnText}
                  </button>
                  {/* {
                  _isFunction(props?.toggleShowJSForm) && (
                    <button
                    className="inline-flex justify-center px-4 py-2 mt-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    onClick={close}
                    >
                    Cancel
                    </button>
                  )} */}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalWrapper;
