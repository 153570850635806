import React from "react";

const Label = ({ htmlFor, disabled, className, children }) => (
  <label
    htmlFor={htmlFor}
    className={`block text-sm font-medium ${
      disabled
        ? "opacity-40 text-slate-500 cursor-not-allowed"
        : "text-gray-700"
    } ${className}`}
  >
    {children}
  </label>
);

export default Label;
