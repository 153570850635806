import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

const Accordion = ({
  tabs = [],
  openIcon = ChevronDownIcon,
  collapseIcon = ChevronUpIcon,
  wrapperClasses = "py-8 mx-auto max-w-7xl",
  titleClasses = "font-semibold text-tertiary",
  verticalSpace = "space-y-6",
  iconColor = "text-primary",
}) => {
  return (
    <div className={wrapperClasses}>
      <div className="divide-y-2 divide-gray-200 ">
        <dl className={`${verticalSpace} divide-y divide-gray-200`}>
          {tabs.map((tab, index) => (
            <Disclosure as="div" key={`${tab.title}_${index}`} className="pt-4">
              {({ open }) => {
                const Icon = open ? collapseIcon : openIcon;
                return (
                  <>
                    <dt className="text-md">
                      <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
                        <span className={`relative ${titleClasses}`}>
                          {tab.title}
                          {tab?.commentsCount > 0 && (
                            <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
                              <span>{tab?.commentsCount}</span>
                            </span>
                          )}
                          {tab.description && (
                            <p className="py-2 text-sm font-semibold text-primary">
                              {tab.description}
                            </p>
                          )}
                        </span>
                        <span
                          className={`flex items-center ml-6 h-7 ${iconColor}`}
                        >
                          <Icon
                            className="h-6 w-6 transform"
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2">
                      <div>{tab.content}</div>
                    </Disclosure.Panel>
                  </>
                );
              }}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default Accordion;
