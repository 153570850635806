/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _isEqual from "lodash/isEqual";
import Cookies from "js-cookie";

import registeredOfficeValidation from "./registeredOfficeValidation";
import CountyDistrictLocalityForm from "components/PLCTABS/CompanyOfficialsTab/AddressParticularsComponents/ResidentialAddressComponents/CountyDistrictLocalityForm";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect/index";
import addressOptions from "components/PLCTABS/SFLdata/addressOptions";
import {
  updateRegisteredOfficeState,
  createRegisteredOfficeAsync,
} from "state/slices/registeredOffice";
import SectionDescription from "components/lib/Shared/SectionDescription";
import Input from "components/lib/Shared/Input";
import { Constants } from "config/constants";
import Button from "components/lib/Shared/Button";

const initialValues = {
  postalAddress: "",
  postalCode: "",
  serviceAddress: "",
  companyEmailAddress: "",
  mobileNumber: "",
  buildingName: "",
  houseNumber: "",
  estate: "",
  floorNumber: "",
  streetName: "",
  streetNumber: "",
  location: {
    county: "",
    district: "",
    locality: "",
  },
};

const SFLData = {
  postalAddress: "96",
  postalCode: "00606 - SARIT CENTER-NAIROBI",
  serviceAddress: "",
  companyEmailAddress: "info@liroja-services.co.ke",
  mobileNumber: "+254734774000",
  buildingName: "The Address",
  houseNumber: "",
  estate: "Westlands",
  floorNumber: "8th Floor",
  streetName: "Muthangari Drive Off Waiyaki way",
  streetNumber: "",
  location: {
    county: "Nairobi",
    district: "Westlands District",
    locality: "Westlands",
  },
};

const RegisteredOfficeAddress = ({
  field,
  handleBackBtn,
  handleNextBtn,
  submitBtnLabel,
  isSubmitting,
}) => {
  const role = Cookies.get("role");
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companySlice);
  const registeredOffice = useSelector((state) => state.registeredOfficeSlice);
  const { resolvedFields } = registeredOffice;
  const [useSimpleFormationsOffice, setUseSimpleFormationsOffice] = useState();
  const [defaultFormData, setDefaultFormData] = useState(registeredOffice);
  const [postalCode, setPostalCode] = useState({
    label: field?.value?.postalCode ?? "",
    value: field?.value?.postalCode ?? "",
  });
  const { transformedComments } = useSelector((state) => state.commentsSlice);

  useEffect(() => {
    setUseSimpleFormationsOffice(registeredOffice?.useSimpleFormationsOffice);
  }, [registeredOffice?.useSimpleFormationsOffice]);

  useEffect(() => {
    setPostalCode({
      label: defaultFormData?.postalCode,
      value: defaultFormData?.postalCode,
    });
  }, [defaultFormData]);

  const handleChecked = (e) => {
    if (e.target.checked) {
      setDefaultFormData(SFLData);
      setUseSimpleFormationsOffice(true);
    } else {
      setDefaultFormData(initialValues);
      setUseSimpleFormationsOffice(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={defaultFormData}
      validationSchema={registeredOfficeValidation}
      onSubmit={async (formValues) => {
        if (
          role &&
          ![
            Constants.COOKIE_ROLES.APPLICANT,
            Constants.COOKIE_ROLES.CO_APPLICANT,
          ].includes(role)
        ) {
          handleNextBtn();
          return;
        }
        const values = { ...formValues, useSimpleFormationsOffice };
        dispatch(updateRegisteredOfficeState(values));
        if (!_isEqual(values, registeredOffice)) {
          await dispatch(
            createRegisteredOfficeAsync({
              companyId: company._id,
              data: values,
            })
          );
        }
        handleNextBtn();
      }}
    >
      {({ values, setFieldValue, isSubmitting: isLoading }) => {
        const handleSelect = (type) => (option) => {
          if (type === "postalCode") {
            setPostalCode({ label: option.value, value: option.value });
            setFieldValue("postalCode", option.value);
          } else {
            setDefaultFormData(SFLData);
          }
        };

        useEffect(() => {
          if (!isLoading) {
            setDefaultFormData(registeredOffice);
          }
        }, [registeredOffice]);

        return (
          <Form>
            <fieldset disabled={role === Constants.COOKIE_ROLES.OFFICIAL}>
              <SectionDescription
                id="Step: 2 of 5"
                title="General Information"
                description="Please provide the correct details. The information provided will be used to process your application."
              />
              <div className="mb-2 space-x-2">
                <Field
                  className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                  type="checkbox"
                  id="useSimpleFormationsOffice"
                  name="useSimpleFormationsOffice"
                  onChange={handleChecked}
                  checked={useSimpleFormationsOffice}
                  disabled={resolvedFields.length === 10}
                />
                <label
                  className="text-sm font-medium text-gray-700"
                  htmlFor="useSimpleFormationsOffice"
                >
                  Use Simple Formations Office as your Registered Business
                  Address in Kenya. (What is included?)
                </label>
              </div>
              <div className="bg-white mb-4 shadow sm:rounded-md">
                <div className="px-4 py-6 space-y-6 sm:p-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Postal Address
                  </h3>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      type="number"
                      label="Postal Address"
                      name="postalAddress"
                      placeholder="Enter postal address"
                      component={Input}
                      disabled={
                        resolvedFields.includes("postalAddress") ||
                        useSimpleFormationsOffice
                      }
                      comments={
                        transformedComments?.["addressParticulars"]
                          ?.postalAddress ?? []
                      }
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      value={postalCode.value}
                      onChange={handleSelect("postalCode")}
                      component={ReactSelectWithFormik}
                      options={addressOptions?.postalCodes}
                      type="text"
                      label="Postal Code"
                      className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                      id="postalCode"
                      name="postalCode"
                      placeholder="Select postal code"
                      isDisabled={
                        resolvedFields.includes("postalCode") ||
                        useSimpleFormationsOffice ||
                        role === Constants.COOKIE_ROLES.OFFICIAL
                      }
                      comments={
                        transformedComments?.["addressParticulars"]
                          ?.postalCode ?? []
                      }
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      label="Service Address(If different from above address)"
                      name="serviceAddress"
                      placeholder="Enter service address"
                      value={
                        values.serviceAddress ||
                        (useSimpleFormationsOffice ||
                        role === Constants.COOKIE_ROLES.OFFICIAL
                          ? "N/A"
                          : "")
                      }
                      component={Input}
                      disabled={
                        resolvedFields.includes("serviceAddress") ||
                        useSimpleFormationsOffice
                      }
                      comments={
                        transformedComments?.["addressParticulars"]
                          ?.serviceAddress ?? []
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="bg-white mb-4 shadow sm:rounded-md">
                <div className="px-4 py-6 space-y-6 sm:p-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Electronic Address
                  </h3>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <Field
                        type="email"
                        label="Company Email Address"
                        name="companyEmailAddress"
                        placeholder="Enter company email address"
                        component={Input}
                        disabled={
                          resolvedFields.includes("companyEmailAddress") ||
                          useSimpleFormationsOffice
                        }
                        comments={
                          transformedComments?.["addressParticulars"]
                            ?.companyEmailAddress ?? []
                        }
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <Field
                        type="tel"
                        label="Mobile Number"
                        name="mobileNumber"
                        placeholder="Enter mobile number"
                        component={Input}
                        disabled={
                          resolvedFields.includes("mobileNumber") ||
                          useSimpleFormationsOffice
                        }
                        comments={
                          transformedComments?.["addressParticulars"]
                            ?.mobileNumber ?? []
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow sm:rounded-md">
                <div className="px-4 py-6 space-y-6 sm:p-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Registered Address Of The Company
                  </h3>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-2">
                      <Field
                        type="text"
                        label="Building Name"
                        name="buildingName"
                        placeholder="Enter building name"
                        component={Input}
                        disabled={
                          resolvedFields.includes("buildingName") ||
                          useSimpleFormationsOffice
                        }
                        comments={
                          transformedComments?.["addressParticulars"]
                            ?.buildingName ?? []
                        }
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <Field
                        type="text"
                        label="House Number"
                        name="houseNumber"
                        placeholder="Enter house number"
                        value={
                          values.houseNumber ||
                          (useSimpleFormationsOffice ||
                          role === Constants.COOKIE_ROLES.OFFICIAL
                            ? "N/A"
                            : "")
                        }
                        component={Input}
                        disabled={
                          resolvedFields.includes("houseNumber") ||
                          useSimpleFormationsOffice
                        }
                        comments={
                          transformedComments?.["addressParticulars"]
                            ?.houseNumber ?? []
                        }
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <Field
                        type="text"
                        label="Estate/Locality"
                        name="estate"
                        placeholder="Enter estate name"
                        component={Input}
                        disabled={
                          resolvedFields.includes("estate") ||
                          useSimpleFormationsOffice
                        }
                        comments={
                          transformedComments?.["addressParticulars"]?.estate ??
                          []
                        }
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <Field
                        type="text"
                        label="Floor or Suite Number"
                        name="floorNumber"
                        placeholder="Enter floor or suite number"
                        component={Input}
                        disabled={
                          resolvedFields.includes("floorNumber") ||
                          useSimpleFormationsOffice
                        }
                        comments={
                          transformedComments?.["addressParticulars"]
                            ?.floorNumber ?? []
                        }
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <Field
                        type="text"
                        label="Street Name"
                        name="streetName"
                        placeholder="Enter street name"
                        value={
                          values.streetName ||
                          (useSimpleFormationsOffice ||
                          role === Constants.COOKIE_ROLES.OFFICIAL
                            ? "N/A"
                            : "")
                        }
                        component={Input}
                        disabled={
                          resolvedFields.includes("streetName") ||
                          useSimpleFormationsOffice
                        }
                        comments={
                          transformedComments?.["addressParticulars"]
                            ?.streetName ?? []
                        }
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <Field
                        type="text"
                        label="Street Number"
                        name="streetNumber"
                        placeholder="Enter street Number"
                        value={
                          values.streetNumber ||
                          (useSimpleFormationsOffice ||
                          role === Constants.COOKIE_ROLES.OFFICIAL
                            ? "N/A"
                            : "")
                        }
                        component={Input}
                        disabled={
                          resolvedFields.includes("streetNumber") ||
                          useSimpleFormationsOffice
                        }
                        comments={
                          transformedComments?.["addressParticulars"]
                            ?.streetNumber ?? []
                        }
                      />
                    </div>
                  </div>
                  <Field
                    name="location"
                    component={CountyDistrictLocalityForm}
                    location={defaultFormData?.location ?? {}}
                    transformedComments={transformedComments}
                    resolvedFields={resolvedFields}
                    useSimpleFormationsOffice={useSimpleFormationsOffice}
                    disabled={role === Constants.COOKIE_ROLES.OFFICIAL}
                  />
                </div>
              </div>
            </fieldset>
            <div className="flex justify-between py-3">
              <Button
                type="button"
                onClick={handleBackBtn}
                disabled={isSubmitting || isLoading}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:cursor-not-allowed"
              >
                Go back
              </Button>
              <Button
                type="submit"
                isLoading={isSubmitting || isLoading}
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
              >
                {role === Constants.COOKIE_ROLES.OFFICIAL
                  ? "Next step"
                  : submitBtnLabel}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default React.memo(RegisteredOfficeAddress);
