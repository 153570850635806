import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import BeneficialOwnersTable from "components/PLCTABS/BeneficialOwnershipTab/BeneficialOwnersTable";
import Tabs from "components/lib/Shared/Tabs";
import { CORPORATES, INDIVIDUALS } from "../SFLdata/config/constants";

const tabs = [
  { id: "reportableBO", label: "Reportable BO" },
  { id: "nonReportableBO", label: "Non-Reportable BO" },
];

const BeneficialOwnership = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { beneficialOwners } = useSelector(
    (state) => state.beneficialOwnersTableSlice
  );
  const [reportableBO, setReportableBO] = useState([]);
  const [nonReportableBO, setNonReportableBO] = useState([]);

  useEffect(() => {
    const tempReportableBO = [];
    const tempNonReportableBO = [];
    if (beneficialOwners.length > 0) {
      beneficialOwners.forEach((bo) => {
        const {
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = bo?.beneficialOwnershipForm ?? {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";
        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";

        // hide eleventh minority entry on BO table
        if (bo.idType === "Minority") {
          bo = {
            ...bo,
            hidden: true,
          };
        }

        if (!bo?.hidden === true) {
          const parentBO = bo?.shareholderWithBO
            ? beneficialOwners.find(
                (official) => official._id === bo.shareholderWithBO
              )
            : {};
          if (
            INDIVIDUALS.includes(bo.idType) &&
            bo.isReportable &&
            (CORPORATES.includes(parentBO.idType)
              ? isDirectBeneficiary
              : isDirectBeneficiary || isIndirectBeneficiary) &&
            bo.isEstateDistributionDetermined !== "No" &&
            bo.idType !== "Estate"
          ) {
            tempReportableBO.push(bo);
          } else tempNonReportableBO.push(bo);
        }
      });
    }
    setReportableBO(tempReportableBO);
    setNonReportableBO(tempNonReportableBO);
  }, [beneficialOwners, setReportableBO, setNonReportableBO]);

  const selectedTab = tabs[selectedTabIndex];
  const tableData =
    selectedTab.id === "reportableBO" ? reportableBO : nonReportableBO;

  return (
    <div className="mt-4">
      {/* Reportable and Non-Reportable BO tabs */}
      <div className="sm:-mt-4">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </div>

      {/* Reportable and Non-Reportable BO table */}
      <BeneficialOwnersTable
        handleEdit={() => null}
        handleDelete={() => null}
        data={tableData}
      />
    </div>
  );
};

export default BeneficialOwnership;
