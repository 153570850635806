import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getRegistersOfDirectorsAndSecretaries = (queryParams) =>
  axios.get(
    `/minute-book/register-of-directors-and-secretaries?${getQueryString(
      queryParams
    )}`
  );

const downloadRegistersOfDirectorsAndSecretaries = (queryParams) =>
  axios.get(
    `/minute-book/download-register-of-directors-and-secretaries?${getQueryString(
      queryParams
    )}`,
    {
      responseType: "blob",
    }
  );

const getRegistersOfDirectorsAndSecretariesResidences = (queryParams) =>
  axios.get(
    `/minute-book/register-of-directors-and-secretaries-residences?${getQueryString(
      queryParams
    )}`
  );

const downloadRegistersOfDirectorsAndSecretariesResidences = (queryParams) =>
  axios.get(
    `/minute-book/download-register-of-directors-and-secretaries-residences?${getQueryString(
      queryParams
    )}`,
    {
      responseType: "blob",
    }
  );

const getRegisterOfApplicationsAndAllotments = (queryParams) =>
  axios.get(
    `/minute-book/register-of-applications-and-allotments?${getQueryString(
      queryParams
    )}`
  );

const downloadRegisterOfApplicationsAndAllotments = (queryParams) =>
  axios.get(
    `/minute-book/download-register-of-applications-and-allotments?${getQueryString(
      queryParams
    )}`,
    {
      responseType: "blob",
    }
  );

const getRegisterOfMembersAndShareLedger = (queryParams) =>
  axios.get(
    `/minute-book/register-of-members-and-share-ledger?${getQueryString(
      queryParams
    )}`
  );

const downloadRegisterOfMembersAndShareLedger = (queryParams) =>
  axios.get(
    `/minute-book/download-register-of-members-and-share-ledger?${getQueryString(
      queryParams
    )}`,
    {
      responseType: "blob",
    }
  );

const getRegisterOfBeneficialOwners = (queryParams) =>
  axios.get(
    `/minute-book/register-of-beneficial-owners?${getQueryString(queryParams)}`
  );

const downloadRegisterOfBeneficialOwners = (queryParams) =>
  axios.get(
    `/minute-book/download-register-of-beneficial-owners?${getQueryString(
      queryParams
    )}`,
    {
      responseType: "blob",
    }
  );

const getRegisterOfShareCertificates = (queryParams) =>
  axios.get(
    `/minute-book/register-of-share-certificates?${getQueryString(queryParams)}`
  );

const updateAllotments = (queryParams, data) =>
  axios.post(
    `/minute-book/update-allotments?${getQueryString(queryParams)}`,
    data
  );

const downloadRegistersOfShareCertificate = (queryParams) =>
  axios.get(
    `/minute-book/download-share-certificate?${getQueryString(queryParams)}`,
    { responseType: "blob" }
  );

const minuteBookDataService = {
  getRegistersOfDirectorsAndSecretaries,
  downloadRegistersOfDirectorsAndSecretaries,
  getRegistersOfDirectorsAndSecretariesResidences,
  downloadRegistersOfDirectorsAndSecretariesResidences,
  getRegisterOfApplicationsAndAllotments,
  downloadRegisterOfApplicationsAndAllotments,
  getRegisterOfMembersAndShareLedger,
  downloadRegisterOfMembersAndShareLedger,
  getRegisterOfBeneficialOwners,
  downloadRegisterOfBeneficialOwners,
  getRegisterOfShareCertificates,
  updateAllotments,
  downloadRegistersOfShareCertificate,
};

export default minuteBookDataService;
