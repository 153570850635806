import React from "react";

import Button from "./Button";
import { useDispatch } from "react-redux";
import { openModal } from "state/slices/modals";

const DeleteButtonWithConfirmation = ({
  row,
  btnClasses,
  confirmationModalName,
  btnLabel = "Delete",
  ...restProps
}) => {
  const dispatch = useDispatch();

  return (
    <Button
      {...restProps}
      onClick={() =>
        dispatch(openModal({ modalName: confirmationModalName, modalRow: row }))
      }
      className={btnClasses}
    >
      {btnLabel}
    </Button>
  );
};

export default DeleteButtonWithConfirmation;
