import { Constants } from "config/constants";
import { string, object, number } from "yup";

const registeredOfficeValidation = object().shape({
  buildingName: string()
    .trim("Building name cannot include leading and trailing spaces")
    .strict(true)
    .required("Building name is required"),
  houseNumber: string()
    .trim("House number cannot include leading and trailing spaces")
    .strict(true)
    .optional(),
  estate: string()
    .trim("Estate cannot include leading and trailing spaces")
    .strict(true)
    .required("Estate is required"),
  floorNumber: string()
    .trim("Floor name cannot include leading and trailing spaces")
    .strict(true),
  postalAddress: number().required("Postal address is required"),
  postalCode: string().required("Postal code is required"),
  serviceAddress: string()
    .trim("Service address cannot include leading and trailing spaces")
    .strict(true)
    .optional(),
  companyEmailAddress: string()
    .email("Invalid email format")
    .required("Company email address is required"),
  mobileNumber: string()
    .trim("Mobile number cannot include leading and trailing spaces")
    .min(13, "Mobile number must be exactly 13 characters")
    .max(13, "Mobile number must be exactly 13 characters")
    .strict(true)
    .required("Mobile number is required")
    .matches(Constants.PHONE_NUMBER_REGEX, "Phone number is not valid")
    .test("len", "Correct format is +254#########", (val) => {
      if (val) return val.startsWith("+254");
      else return true;
    }),
  location: object().shape({
    county: string()
      .trim("County cannot include leading and trailing spaces")
      .strict(true)
      .required("County is required"),
    district: string()
      .trim("District cannot include leading and trailing spaces")
      .strict(true)
      .required("District is required"),
    locality: string()
      .trim("Locality cannot include leading and trailing spaces")
      .strict(true)
      .required("Locality is required"),
  }),
});

export default registeredOfficeValidation;
