/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from "react";
import {
  Formik,
  Form,
  Field,
  setNestedObjectValues,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
import { useSelector, useDispatch } from "react-redux";
import _keys from "lodash/keys";
import _values from "lodash/values";
import _omit from "lodash/omit";
import _pickBy from "lodash/pickBy";
import _merge from "lodash/merge";
import { XIcon } from "@heroicons/react/outline";
import Verify from "components/lib/Shared/Verify";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Label from "components/lib/Shared/Label";
import OfficialsBeneficialOwnershipComponent from "../CompanyOfficialsTab/BeneficialOwnershipComponents/OfficialsBeneficialOwnershipComponent";
import NamesForm from "../CompanyOfficialsTab/NamesComponents/index";
import { getDocumentData } from "../CompanyOfficialsTab/FileUploadForm/documentData";
import FileUploadForm from "../CompanyOfficialsTab/FileUploadForm";
import { handleFilesUpload } from "utils/filesUtils";
import {
  addBeneficialOwnerAsync,
  editBeneficialOwnerAsync,
  updateDropdownEntryStatusAsync,
} from "state/slices/tables/beneficialOwnersTableSlice";
import { closeModal } from "state/slices/modals";
import CorporateComponent from "./CorporateComponent/index";
import {
  INDIVIDUALS,
  CORPORATES_ID_TYPES,
  CORPORATES,
} from "../SFLdata/config/constants";
import IdentificationDetailsForm from "../CompanyOfficialsTab/PersonalDetailsComponents/index";
// import initialValues from '../initialValues'
import AddressParticulars from "./BeneficialOwnersAddressParticulars";
import getInitialValues from "./getInitialValues";
import getValidationSchema from "./getValidationSchema";
import { Constants } from "config/constants";
import { roundNumber, clearEmpties, getFullName } from "utils";
import ValidationError from "components/lib/Shared/ValidationError";
import Tabs from "components/lib/Shared/Tabs";
import { useParams } from "react-router-dom";
import InviteExistingUser from "components/lib/Shared/Verify/InviteExistingUser";
import InviteNewUser from "components/lib/Shared/Verify/InviteNewUser";
import { useToast } from "hooks/useToast";
import SectionDescription from "components/lib/Shared/SectionDescription";
import Button from "components/lib/Shared/Button";
import ErrorBanner from "components/lib/Shared/ErrorBanner";

const initialTabs = [{ id: "assignOfficials", label: "Assign Officials" }];

const shortFormTabs = [
  { id: "assignOfficials", label: "Assign Officials" },
  {
    id: "beneficialOwnershipAllocation",
    label: "Beneficial Ownership Allocation",
  },
  { id: "generalInformation", label: "General Information" },
];

const longFormTabs = [
  { id: "assignOfficials", label: "Assign Officials" },
  {
    id: "beneficialOwnershipAllocation",
    label: "Beneficial Ownership Allocation",
  },
  { id: "generalInformation", label: "General Information" },
  { id: "residentialDetails", label: "Residential Details" },
  { id: "uploadDocuments", label: "Upload Documents" },
];

const BeneficialOwnerSelection = (props) => {
  const {
    mode,
    currentOfficial,
    setShowBeneficialOwnersBtn,
    selectedDropdownBO,
    setSelectedDropdownBO,
    selectedCorporateIDType,
    isViewOnly,
  } = props;

  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState({
    id: "assignOfficials",
    label: "Assign Officials",
  });
  const [validationSchema, setValidationSchema] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const [verificationValue, setVerifyingValue] = useState("");
  const [isIdAssociated, setIsIdAssociated] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [inviteNewUserWorkflow, setInviteNewUserWorkflow] = useState(false);
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);
  const [isAccessRevoked, setIsAccessRevoked] = useState(false);
  const [inviteExistingUser, setInviteExistingUser] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const formStepErrors = useRef(null);
  const { toast } = useToast(4000);
  const dispatch = useDispatch();
  const params = useParams();
  const { beneficialOwners } = useSelector(
    (state) => state.beneficialOwnersTableSlice
  );
  const { company } = useSelector((state) => state.companySlice);
  const companyId = company?._id ?? params?.companyId;
  const beneficialOwnersDropdown = beneficialOwners.filter(
    (bo) => bo?.showInBoDropdown
  );
  const companyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );
  const [selectedShareholder, setSelectedShareholder] = useState(
    () =>
      beneficialOwners.find(
        (bo) =>
          bo._id === currentOfficial.linkToCompany ||
          bo._id === currentOfficial.linkToCompany?._id
      ) || {}
  );
  const [takenUpShares, setTakenUpShares] = useState(0);
  const [takenUpVotingRights, setTakenUpVotingRights] = useState(0);

  useEffect(() => {
    if (currentOfficial.linkToCompany) {
      setTakenUpShares(
        beneficialOwners.reduce((acc, bo) => {
          if (
            bo.linkToCompany === currentOfficial.linkToCompany &&
            bo._id !== currentOfficial._id
          ) {
            return (
              acc +
              Number(bo.beneficialOwnershipForm.indirectPercentShareholding)
            );
          }
          return acc;
        }, 0)
      );
      setTakenUpVotingRights(
        beneficialOwners.reduce((acc, bo) => {
          if (
            bo.linkToCompany === currentOfficial.linkToCompany &&
            bo._id !== currentOfficial._id
          ) {
            return (
              acc +
              Number(bo.beneficialOwnershipForm.indirectPercentVotingRights)
            );
          }
          return acc;
        }, 0)
      );
    }
  }, [currentOfficial]);

  const goToPrevStep = () => {
    if (selectedTabIndex === 0) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  const handleExitWorkflow = () => {
    setShowBeneficialOwnersBtn(true);
    setSelectedDropdownBO(false);
  };

  const closeVerifyProcess = () => {
    setShowBeneficialOwnersBtn(true);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  const linkedCompany = beneficialOwners.find(
    (bo) =>
      bo._id === currentOfficial.linkToCompany ||
      bo._id === currentOfficial.linkToCompany?._id
  );

  const addOrEditOfficial = async (values) => {
    try {
      const uploadedFiles = await handleFilesUpload(
        values.fileUploads,
        currentOfficial,
        mode.editing
      );

      if (!values.renderLongForm)
        values = _omit(values, [
          "postalAddress",
          "residentialAddressDetails",
          "fileUploads",
          "comments",
        ]);
      let payload = {
        ..._omit(values, [
          "fileUploads",
          "renderLongForm",
          "isStateOwnedEnterprise",
        ]),
        fileUploads: {
          ..._pickBy(values.fileUploads, (file) => typeof file === "string"),
          ...uploadedFiles,
        },
        names: {
          ...values.names,
          fullName: getFullName(values.names),
        },
        proRata: values.beneficialOwnershipForm.indirectPercentShareholding,
      };

      const { idType } = payload;
      const {
        directPercentShareholding,
        indirectPercentShareholding,
        directPercentVotingRights,
        indirectPercentVotingRights,
        directRightRemoveDirector,
        indirectRightRemoveDirector,
        directCompanyControlRight,
        indirectCompanyControlRight,
      } = payload.beneficialOwnershipForm;
      if (selectedShareholder.JSGroupID) {
        payload.JSCombinedName = selectedShareholder.JSCombinedName;
        payload.JSGroupID = selectedShareholder.JSGroupID;
      }
      const isDirectBeneficiary =
        directPercentShareholding >= 10 ||
        directPercentVotingRights >= 10 ||
        directRightRemoveDirector === "yes" ||
        directCompanyControlRight === "yes";
      const isIndirectBeneficiary =
        indirectPercentShareholding >= 10 ||
        indirectPercentVotingRights >= 10 ||
        indirectRightRemoveDirector === "yes" ||
        indirectCompanyControlRight === "yes";
      if (
        ((INDIVIDUALS.includes(selectedShareholder.idType) &&
          !selectedShareholder?.linkToCompany &&
          !selectedShareholder?.shareholderWithBO) ||
          selectedShareholder.isReportable) &&
        (isIndirectBeneficiary || isDirectBeneficiary)
      ) {
        payload.isReportable = true;
      } else payload.isReportable = false;
      payload = clearEmpties(payload);

      if (INDIVIDUALS.includes(idType)) {
        payload.showInBoTable = true;
        if (mode.editing) {
          const response = await dispatch(
            editBeneficialOwnerAsync({
              companyId,
              beneficialOwnerId: payload._id,
              data: payload,
            })
          );
          if (response.payload.messageText) {
            toast(
              "error",
              response.payload.messageText ??
                "Something went wrong please try again."
            );
          }
        } else {
          const response = await dispatch(
            addBeneficialOwnerAsync({ companyId, data: payload })
          );
          if (response.payload.messageText) {
            toast(
              "error",
              response.payload.messageText ??
                "Something went wrong please try again."
            );
          }
          return response;
        }
      }

      if (mode.editing) {
        dispatch(closeModal("beneficialOwner_edit_modal"));
        if (
          linkedCompany &&
          !beneficialOwnersDropdown.find(
            (bo) => bo._id === linkedCompany._id
          ) &&
          (takenUpShares +
            payload?.beneficialOwnershipForm?.indirectPercentShareholding !==
            linkedCompany?.beneficialOwnershipForm
              ?.indirectPercentShareholding ||
            takenUpVotingRights +
              payload?.beneficialOwnershipForm?.indirectPercentVotingRights !==
              linkedCompany?.beneficialOwnershipForm
                ?.indirectPercentVotingRights)
        ) {
          dispatch(
            updateDropdownEntryStatusAsync({
              beneficialOwnerId: linkedCompany._id,
              data: { showInBoDropdown: true },
            })
          );
        }
      }
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        comments:
          transformedComments?.[currentOfficial?._id]?.[
            "beneficialOwnershipTab"
          ] ?? [],
      }}
      validate={(values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            makeFieldsOptional: isIdAssociated === false || isViewOnly,
            maxPercentShareholding:
              values.beneficialOwnershipForm?.maxPercentShareholding ?? 0,
            maxPercentVotingRights:
              values.beneficialOwnershipForm?.maxPercentVotingRights ?? 0,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={async (values, { resetForm }) => {
        // validate form & store errors for the ErrorBanner
        try {
          validateYupSchema(values, validationSchema, true);
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: [],
          };
        } catch (error) {
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: error.errors,
          };
          if (!isLastStep) {
            setTimeout(goToNextStep, Constants.DELAY_FORM_SUBMISSION);
            return;
          }
        }
        if (!isLastStep) {
          goToNextStep();
          return;
        }

        // for partial data
        const {
          maxPercentShareholding,
          maxPercentVotingRights,
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = values?.beneficialOwnershipForm || {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";
        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";

        let validBOFormFieldsCount = 2;
        if (maxPercentShareholding > 0) validBOFormFieldsCount += 1;
        if (maxPercentVotingRights > 0) validBOFormFieldsCount += 1;
        const renderForm =
          _values(values.beneficialOwnershipForm).filter(
            (value) => value !== ""
          ).length > validBOFormFieldsCount;

        const officialSchema = getValidationSchema({
          idType: values.idType,
          isNonResident: values.isNonResident,
          documentData: getDocumentData(values.idType),
          beneficialOwnershipForm: values.beneficialOwnershipForm,
          selectedShareholder,
          renderLongForm:
            renderForm && (isDirectBeneficiary || isIndirectBeneficiary),
        });
        console.log({ values });
        try {
          setIsFormValid(true);
          validateYupSchema(values, officialSchema, true);
        } catch (error) {
          console.log({ error });
          setIsFormValid(false);
          if (error.errors.length > 0) {
            return;
          }
        }

        if (!isViewOnly) await addOrEditOfficial(values);
        dispatch(closeModal("beneficialOwner_edit_modal"));
        setShowBeneficialOwnersBtn(true);
        resetForm();
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitCount,
        setFieldValue,
        setTouched,
      }) => {
        const [IDTypeVal, setIDTypeVal] = useState({
          value: values.idType,
          label: values.idType,
        });
        const [isIndividual, setIsIndividual] = useState(true);

        useEffect(() => {
          setInitialValues(_merge(values, currentOfficial));
        }, [currentOfficial]);

        useEffect(() => {
          const documentData = getDocumentData(values.idType);
          if (!selectedDropdownBO) {
            setInitialValues(
              _merge(
                getInitialValues({
                  idType: values.idType,
                  isNonResident: values.isNonResident,
                  documentData,
                }),
                values
              )
            );
          }
          if (
            selectedShareholder.beneficialOwnershipForm
              ?.indirectPercentShareholding &&
            currentOfficial?._id
          ) {
            setFieldValue(
              "beneficialOwnershipForm.maxPercentShareholding",
              roundNumber(
                selectedShareholder.beneficialOwnershipForm
                  .indirectPercentShareholding - takenUpShares
              )
            );
            setFieldValue(
              "beneficialOwnershipForm.maxPercentVotingRights",
              roundNumber(
                selectedShareholder.beneficialOwnershipForm
                  .indirectPercentVotingRights - takenUpVotingRights
              )
            );
          }
        }, [
          selectedShareholder,
          takenUpShares,
          takenUpVotingRights,
          selectedTab,
          values.idType,
          values.isNonResident,
        ]);

        const handleSelect =
          (name) =>
          ({ _id, ...option }) => {
            if (name === "linkToCompany") {
              const selectedBO = beneficialOwnersDropdown.find(
                (shareholder) => shareholder._id === _id
              );
              setSelectedShareholder(selectedBO);
              if (INDIVIDUALS.includes(selectedBO.idType))
                setIsIndividual(true);
              else setIsIndividual(false);
              const { allottedIndirectShares, allottedIndirectVotingRights } = [
                ...beneficialOwners,
                ...beneficialOwnersDropdown,
              ]
                .filter(
                  (bo) =>
                    bo.linkToCompany === selectedBO._id ||
                    bo?.linkToCompany?._id === selectedBO._id
                )
                .reduce(
                  (acc, bo) => ({
                    allottedIndirectShares:
                      acc.allottedIndirectShares +
                      Number(
                        bo.beneficialOwnershipForm?.directPercentShareholding ??
                          0
                      ) +
                      Number(
                        bo.beneficialOwnershipForm.indirectPercentShareholding
                      ),
                    allottedIndirectVotingRights:
                      acc.allottedIndirectVotingRights +
                      Number(
                        bo.beneficialOwnershipForm?.directPercentVotingRights ??
                          0
                      ) +
                      Number(
                        bo.beneficialOwnershipForm.indirectPercentVotingRights
                      ),
                  }),
                  { allottedIndirectShares: 0, allottedIndirectVotingRights: 0 }
                );

              const {
                directPercentVotingRights = 0,
                indirectPercentVotingRights = 0,
                directPercentShareholding = 0,
                indirectPercentShareholding = 0,
              } = selectedBO?.beneficialOwnershipForm ?? {};
              const selectedBOEntityType =
                selectedBO.identificationDetails?.entityType;
              if (selectedBOEntityType) {
                setIDTypeVal({
                  value: selectedBOEntityType,
                  label: selectedBOEntityType,
                });
                setFieldValue("idType", selectedBOEntityType);
              } else {
                setIDTypeVal("");
                setFieldValue("idType", "");
              }
              let maxPercentShareholding = indirectPercentShareholding;
              let maxPercentVotingRights = indirectPercentVotingRights;
              if (selectedBO.idType === "Estate") {
                maxPercentShareholding += directPercentShareholding;
                maxPercentVotingRights += directPercentVotingRights;
              }
              setFieldValue(
                "beneficialOwnershipForm.maxPercentShareholding",
                roundNumber(maxPercentShareholding - allottedIndirectShares)
              );
              setFieldValue(
                "beneficialOwnershipForm.maxPercentVotingRights",
                roundNumber(
                  maxPercentVotingRights - allottedIndirectVotingRights
                )
              );
            }
            if (name === "idType") setIDTypeVal(option);
            if (option.value === "State Owned Enterprise")
              setFieldValue("isStateOwnedEnterprise", true);
            else setFieldValue("isStateOwnedEnterprise", false);
            setFieldValue(name, option.value);
          };

        const {
          maxPercentShareholding,
          maxPercentVotingRights,
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = values?.beneficialOwnershipForm || {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";
        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";

        let validBOFormFieldsCount = 2;
        if (maxPercentShareholding > 0) validBOFormFieldsCount += 1;
        if (maxPercentVotingRights > 0) validBOFormFieldsCount += 1;
        const renderForm =
          _values(values.beneficialOwnershipForm).filter(
            (value) => value !== ""
          ).length > validBOFormFieldsCount;
        const renderLongForm =
          renderForm && (isDirectBeneficiary || isIndirectBeneficiary);
        const renderShortForm =
          renderForm && !isDirectBeneficiary && !isIndirectBeneficiary;

        useEffect(() => {
          setVerifyingValue("");
          setIsIdAssociated(null);
          setInviteNewUserWorkflow(false);
          setIsAccessAllowed(false);
          setIsAccessRevoked(false);
          formStepErrors.current = null;
          if (!mode.editing || isViewOnly) setDisableForm(true);
        }, [values.idType]);

        useEffect(() => {
          const documentData = getDocumentData(values.idType);
          setValidationSchema(
            getValidationSchema({
              selectedTab,
              idType: values.idType,
              isNonResident: values.isNonResident,
              documentData,
              beneficialOwnershipForm: values.beneficialOwnershipForm,
              selectedShareholder,
              renderLongForm,
            }).schema
          );
        }, [
          selectedTab,
          values.idType,
          values.isNonResident,
          values.beneficialOwnershipForm,
          selectedShareholder,
          renderLongForm,
        ]);

        useEffect(() => {
          setFieldValue("renderLongForm", renderLongForm);
        }, [renderLongForm]);

        useEffect(() => {
          if (values.selectedJointShareholder) {
            const tempShareholder = companyOfficials.find(
              (shareholder) =>
                shareholder.id === values.selectedJointShareholder
            );
            setFieldValue("idType", tempShareholder.idType);
          }
        }, [values.selectedJointShareholder]);

        useEffect(() => {
          if (selectedDropdownBO?._id) {
            const tempCorporateIDType = selectedCorporateIDType;
            setIsIndividual(false);
            setIDTypeVal({
              label: tempCorporateIDType,
              value: tempCorporateIDType,
            });
            setFieldValue("idType", tempCorporateIDType);
          }
        }, [selectedDropdownBO, selectedCorporateIDType]);

        useEffect(() => {
          let tempTabs = [];
          if (renderLongForm) tempTabs = longFormTabs;
          if (!renderLongForm) tempTabs = shortFormTabs;
          if (mode.editing) {
            tempTabs = tempTabs.filter((tab) => tab.id !== "assignOfficials");
          }
          if (_keys(CORPORATES_ID_TYPES).includes(values.idType))
            tempTabs = initialTabs;
          setTabs(tempTabs);
        }, [values, renderLongForm, mode.editing]);

        // Reset touched fields on tab change, e.g fix errors jumping around
        useEffect(() => {
          setTouched({}, false);
          setIsFormValid(true);
        }, [selectedTab, setTouched]);

        // Update ErrorBanner errors when user update data
        useEffect(() => {
          if (formStepErrors.current?.[selectedTab.id]?.length > 0) {
            try {
              validateYupSchema(values, validationSchema, true);
              formStepErrors.current = {
                ...formStepErrors.current,
                [selectedTab.id]: [],
              };
            } catch (error) {
              formStepErrors.current = {
                ...formStepErrors.current,
                [selectedTab.id]: error.errors,
              };
            }
          }
        }, [values]);

        const getIdTypes = () => {
          return selectedShareholder.JSGroupID || isIndividual
            ? Constants.INDIVIDUAL_BO_OPTIONS
            : Constants.CORPORATE_BO_OPTIONS;
        };

        const handleNewUserWorkflow = () => {
          if (Object.keys(errors).length > 0) {
            setTouched(setNestedObjectValues(errors, true));
            return;
          } else setInviteNewUserWorkflow(true);
        };

        const handleResetForm = () => {
          setFieldValue("names", {});
          setFieldValue("isNonResident", "No");
          setFieldValue("identificationDetails", {});
          setFieldValue("postalAddress", {});
          setFieldValue("electronicAddress", {});
          setFieldValue("residentialAddressDetails", {});
          setFieldValue("fileUploads", {});
          formStepErrors.current = null;
        };

        // set touched to true for required fields to render errors
        useEffect(() => {
          if (
            values.isIncompleteProfile &&
            Object.keys(errors).length > 0 &&
            submitCount > 0
          ) {
            setTouched(setNestedObjectValues(errors, true));
          }
        }, [values.isIncompleteProfile, errors, submitCount]);

        // Invite new user form
        if (inviteNewUserWorkflow && selectedTab.id !== "assignOfficials") {
          return (
            <>
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                />
              </div>
              <Button
                className="flex my-2 ml-auto"
                onClick={handleExitWorkflow}
                variant="text"
              >
                <XIcon className="block w-6 h-6" aria-hidden="true" />
              </Button>
              <InviteNewUser
                closeVerifyProcess={closeVerifyProcess}
                idType={values.idType}
                designation={values.designation}
                values={values}
                inviteOfficial={addOrEditOfficial}
              />
            </>
          );
        }

        if (inviteExistingUser) {
          return (
            <>
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                />
              </div>
              <button
                type="button"
                className="flex my-2 ml-auto"
                onClick={handleExitWorkflow}
              >
                <XIcon className="block w-6 h-6" aria-hidden="true" />
              </button>
              <InviteExistingUser
                userId={values.userId}
                idType={values.idType}
                designation={values.designation}
                closeVerifyProcess={closeVerifyProcess}
                userEmailAddress={values.userEmailAddress}
                values={values}
                inviteOfficial={addOrEditOfficial}
              />
            </>
          );
        }

        return (
          <Form>
            {/* Official's form tabs */}
            {values.designation === "Joint Shareholder" &&
            !showJSForm ? null : (
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={
                    isViewOnly ? setSelectedTabIndex : () => null
                  }
                />
              </div>
            )}
            {isIdAssociated === false &&
              formStepErrors.current?.[selectedTab.id]?.length > 0 && (
                <ErrorBanner
                  errors={[...(formStepErrors.current?.[selectedTab.id] ?? [])]}
                  className="my-4"
                />
              )}
            <div className="flex justify-between mt-2">
              {/* Invite new user button */}
              {verificationValue &&
                isIdAssociated === false &&
                selectedTab.id &&
                ![
                  "assignOfficials",
                  "beneficialOwnershipAllocation",
                  "generalInformation",
                ].includes(selectedTab.id) &&
                !mode.editing && (
                  <Button onClick={handleNewUserWorkflow}>Invite</Button>
                )}
              {!mode.editing && (
                <Button
                  className="flex ml-auto"
                  variant="text"
                  onClick={handleExitWorkflow}
                >
                  <XIcon className="block w-6 h-6" aria-hidden="true" />{" "}
                </Button>
              )}
            </div>
            {selectedTab.id === "assignOfficials" && (
              <div className="px-4 py-6 mt-4 bg-white rounded-sm shadow-sm sm:p-6 sm:rounded md:rounded-md">
                <div className="grid grid-cols-6 gap-6">
                  {!mode.editing && !selectedDropdownBO?._id && (
                    <div className="col-span-6 sm:col-span-3">
                      <Label htmlFor="linkToCompany">
                        Shareholder with B.O
                      </Label>
                      <Field
                        name="linkToCompany"
                        component={ReactSelectWithFormik}
                        options={beneficialOwnersDropdown.map(
                          ({ _id, names }) => {
                            return {
                              value: _id,
                              label: getFullName(names),
                              _id,
                            };
                          }
                        )}
                        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="linkToCompany"
                        onChange={handleSelect("linkToCompany")}
                      />
                    </div>
                  )}
                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="idType">ID Type of the B.O</Label>
                    <Field
                      name="idType"
                      component={ReactSelectWithFormik}
                      options={getIdTypes()}
                      isDisabled={
                        (!values.linkToCompany && !mode.editing) ||
                        CORPORATES.includes(selectedShareholder.idType)
                      }
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="idType"
                      onChange={handleSelect("idType")}
                      value={IDTypeVal}
                    />
                  </div>
                </div>
              </div>
            )}
            {!selectedDropdownBO?._id &&
              (mode.editing || values.linkToCompany) &&
              (values.idType || values.existingUser) &&
              !_keys(CORPORATES_ID_TYPES).includes(values.idType) &&
              values.idType !== "State Owned Enterprise" && (
                <div>
                  {selectedTab.id === "beneficialOwnershipAllocation" && (
                    <div className="my-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                      <div className="px-4 py-6 sm:p-6">
                        <h2 className="mb-1 text-lg font-medium leading-6 text-gray-900">
                          Beneficial Ownership Allocation:
                        </h2>
                        <Field
                          name="beneficialOwnershipForm"
                          component={OfficialsBeneficialOwnershipComponent}
                          selectedShareholder={selectedShareholder}
                          disableChangeEffect
                          disableDirectFields
                          isReportable={renderLongForm === true}
                          disabled={values?.isReadOnly}
                        />
                      </div>
                    </div>
                  )}
                  {(renderShortForm || renderLongForm) &&
                    selectedTab.id === "generalInformation" && (
                      <div className="px-4 py-6 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:p-6 sm:rounded md:rounded-md">
                        {values?.idType && !mode.editing && (
                          <>
                            <div className="mb-4">
                              <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Personal Information
                              </h3>
                              <SectionDescription description="To activate the form fields please verify your ID first" />
                            </div>
                            <div>
                              <Verify
                                idType={values.idType}
                                designation="Shareholder"
                                tabName="beneficialOwners"
                                verificationValue={verificationValue}
                                isIdAssociated={isIdAssociated}
                                isAccessAllowed={isAccessAllowed}
                                isAccessRevoked={isAccessRevoked}
                                beneficialOwner={values.linkToCompany}
                                setFieldValue={setFieldValue}
                                setVerifyingValue={setVerifyingValue}
                                setIsIdAssociated={setIsIdAssociated}
                                setDisableForm={setDisableForm}
                                resetForm={handleResetForm}
                                handleNewUserWorkflow={handleNewUserWorkflow}
                                setIsAccessAllowed={setIsAccessAllowed}
                                setIsAccessRevoked={setIsAccessRevoked}
                                setInviteExistingUser={setInviteExistingUser}
                              />
                            </div>
                            {submitCount > 1 &&
                              disableForm &&
                              !isAccessAllowed &&
                              !isViewOnly && (
                                <div
                                  className="flex items-center py-1 text-sm leading-none text-red-500 bg-gray-200 lg:rounded-full lg:inline-flex"
                                  role="alert"
                                >
                                  <span className="flex-auto px-2 font-semibold text-left">
                                    To activate the form fields please verify
                                    your ID
                                  </span>
                                </div>
                              )}
                          </>
                        )}
                        <Field
                          name="names"
                          component={NamesForm}
                          idType={values.idType}
                          disabled={disableForm}
                          disableVerificationField={
                            isIdAssociated === false || mode.editing
                          }
                        />
                        <hr />
                        <IdentificationDetailsForm
                          idType={values.idType}
                          disabled={disableForm && !values.isIncompleteProfile}
                          disableVerificationField={
                            isIdAssociated === false || mode.editing
                          }
                        />
                      </div>
                    )}
                  {renderLongForm && (
                    <>
                      {selectedTab.id === "residentialDetails" && (
                        <AddressParticulars
                          idType={values.idType}
                          isNonResident={values.isNonResident}
                          disabled={
                            disableForm &&
                            !values?.enableLongForm &&
                            !values.isIncompleteProfile
                          }
                        />
                      )}
                      {selectedTab.id === "uploadDocuments" && (
                        <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                          <div className="px-4 py-6 sm:p-6">
                            <h3>Please upload the following documents</h3>
                            <Field
                              name="fileUploads"
                              component={FileUploadForm}
                              documentData={getDocumentData(values.idType)}
                              disabled={
                                disableForm &&
                                !values?.enableLongForm &&
                                !values.isIncompleteProfile
                              }
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            {isLastStep && !isFormValid && isIdAssociated === false && (
              <p className="my-2 text-sm text-red-500">
                Either enter all the required information or invite the
                official.
              </p>
            )}
            {!_keys(CORPORATES_ID_TYPES).includes(values.idType) &&
              values.idType !== "State Owned Enterprise" && (
                <div className="flex justify-between py-3">
                  <div
                    className={`flex w-full ${
                      isFirstStep ? "justify-end" : "justify-between"
                    } py-3`}
                  >
                    {!isFirstStep && (
                      <Button
                        disabled={isSubmitting}
                        onClick={goToPrevStep}
                        color="gray"
                      >
                        Go back
                      </Button>
                    )}
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      isLoading={isSubmitting && isLastStep}
                    >
                      Save and proceed
                    </Button>
                  </div>
                </div>
              )}
            {(selectedDropdownBO?._id ||
              ((mode.editing || values.linkToCompany) &&
                values.idType &&
                _keys(CORPORATES_ID_TYPES).includes(values.idType))) && (
              <CorporateComponent
                setSelectedDropdownBO={setSelectedDropdownBO}
                setShowBeneficialOwnersBtn={setShowBeneficialOwnersBtn}
                shareholderWithBO={
                  values?.linkToCompany ?? selectedDropdownBO?._id
                }
                selectedShareholder={selectedDropdownBO || selectedShareholder}
                idType={values.idType}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default BeneficialOwnerSelection;
