import { twMerge } from "tailwind-merge";

export default function Badge({
  bgColor = "",
  textColor = "",
  text,
  className,
}) {
  return (
    <span
      className={twMerge(
        `inline-flex items-center px-2 py-1 rounded text-xs font-medium ${bgColor} ${textColor}`,
        className
      )}
    >
      {text}
    </span>
  );
}
