import React, { useEffect, useMemo, useState } from "react";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import _pickBy from "lodash/pickBy";

import Tabs from "components/lib/Shared/Tabs";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect/index";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { verifyBtnParams } from "components/lib/Shared/Verify/verifyBtnParams";
import { getFormFields, getFullName, getVerifyId } from "utils";
import {
  addProfessionalDetail,
  getProfessionalDetailsAsync,
} from "state/slices/professionalDetails";
import { Constants } from "config/constants";
import Button from "components/lib/Shared/Button";
import FileUploadForm from "../CompanyOfficialsTab/FileUploadForm";
import professionalDetailValidation from "components/UserOnboarding/professionalDetailValidation";
import { handleFilesUpload } from "utils/filesUtils";
import { updateUser } from "state/slices/user";
import { useToast } from "hooks/useToast";
import { updateCompanyAsync } from "state/slices/company";

const {
  DIRECTOR,
  DIRECTOR_SHAREHOLDER,
  SHAREHOLDER,
  BENEFICIAL_OWNER,
  COMPANY_SECRETARY,
  ADVOCATE,
} = Constants.APPLICANT_CAPACITIES;

function ApplicantSubRow({ visibleColumns, row }) {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const { company } = useSelector((state) => state.companySlice);
  const { items: professionalDetails, getProfessionalDetails } = useSelector(
    (state) => state.professionalDetailsSlice
  );
  const currentUser = useSelector((state) => state.userSlice);
  const applicantCapacity = company?.applicantCapacity ?? "";
  const [capacity, setCapacity] = useState({
    label: applicantCapacity?.capacity,
    value: applicantCapacity?.capacity,
  });

  useEffect(() => {
    if (currentUser?._id) {
      dispatch(getProfessionalDetailsAsync({ user: currentUser._id }));
    }
  }, [currentUser.isProfessional]);

  const tabs = useMemo(() => {
    return [
      {
        id: "generalInformation",
        label: "General information",
      },
      {
        id: "residentialDetails",
        label: "Address details",
      },
    ];
  }, []);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const selectedTab = tabs[selectedTabIndex];
  const verifyParams = verifyBtnParams["Director"][row?.original?.idType];

  const { schema, schemaInitialValues } = professionalDetailValidation();

  const { isApplicant, isCoApplicant } = company;

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9 pb-7">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "generalInformation" && (
          <Formik
            enableReinitialize
            initialValues={{
              practicingNumber: "",
              renderForm: "",
              ...schemaInitialValues,
              capacity: applicantCapacity?.capacity,
            }}
            validationSchema={schema}
            onSubmit={async (values, { setFieldValue }) => {
              if (isCoApplicant) return;
              if (values.renderForm) {
                try {
                  const uploadedFiles = await handleFilesUpload(
                    values.fileUploads
                  );
                  const payload = {
                    ...values,
                    fileUploads: {
                      ..._pickBy(
                        values.fileUploads,
                        (file) => typeof file === "string"
                      ),
                      ...uploadedFiles,
                    },
                  };
                  await dispatch(addProfessionalDetail({ data: payload }));
                  if (professionalDetails.length === 0) {
                    dispatch(
                      updateUser({
                        id: currentUser._id,
                        data: { attachProfessionsDetails: true },
                      })
                    );
                  }
                  await updateCompanyAsync({
                    id: company._id,
                    data: {
                      applicantCapacity: {
                        capacity: values.capacity,
                        practicingNumber: values?.practicingNumber,
                        fullName: getFullName(currentUser.names),
                        idNumber: getVerifyId(
                          currentUser.idType,
                          currentUser.identificationDetails
                        ),
                        postalAddress: currentUser.postalAddress.postalAddress,
                        electronicAddress:
                          currentUser.electronicAddress.emailAddress,
                      },
                      tabName: "Applicant details",
                    },
                  });
                  setFieldValue("renderForm", "");
                } catch (error) {
                  toast(
                    "error",
                    error?.message ?? "Something went wrong please try again."
                  );
                }
              }
            }}
          >
            {({ values, isSubmitting, handleSubmit, setFieldValue }) => {
              // Render changes for non applicant officials
              useEffect(() => {
                if (!isApplicant) {
                  setCapacity({
                    label: applicantCapacity?.capacity,
                    value: applicantCapacity?.capacity,
                  });
                  setFieldValue("capacity", applicantCapacity?.capacity);
                  setFieldValue(
                    "practicingNumber",
                    applicantCapacity?.practicingNumber
                  );
                }
              }, [applicantCapacity?.capacity]);

              const hasCompanySecretaryProfile = useMemo(
                () =>
                  professionalDetails.find(
                    (professionalProfile) =>
                      professionalProfile.capacity === COMPANY_SECRETARY
                  ),
                [professionalDetails]
              );

              const hasAdvocateProfile = useMemo(
                () =>
                  professionalDetails.find(
                    (professionalProfile) =>
                      professionalProfile.capacity === ADVOCATE
                  ),
                [professionalDetails]
              );

              const handleSelect =
                (type) =>
                async ({ value }) => {
                  setFieldValue("practicingNumber", "");
                  setFieldValue("practicingCertificate", "");
                  setFieldValue("fileUploads", {});
                  setFieldValue("renderForm", "");
                  if (type === "capacity") {
                    setCapacity({ label: value, value: value });
                    setFieldValue("capacity", value);
                    const selectedProfile =
                      applicantCapacity?.capacity === COMPANY_SECRETARY
                        ? hasCompanySecretaryProfile
                        : hasAdvocateProfile;
                    if (
                      !selectedProfile?._id &&
                      [COMPANY_SECRETARY, ADVOCATE].includes(value)
                    ) {
                      setFieldValue("renderForm", value);
                      return;
                    }
                    await dispatch(
                      updateCompanyAsync({
                        id: company._id,
                        data: {
                          applicantCapacity: {
                            capacity: value,
                            practicingNumber: selectedProfile?.practicingNumber,
                            fullName: getFullName(currentUser.names),
                            idNumber: getVerifyId(
                              currentUser.idType,
                              currentUser.identificationDetails
                            ),
                            postalAddress:
                              currentUser.postalAddress.postalAddress,
                            electronicAddress:
                              currentUser.electronicAddress.emailAddress,
                          },
                          tabName: "Applicant details",
                        },
                      })
                    );
                  }
                };

              const capacityOptions = useMemo(() => {
                if (hasCompanySecretaryProfile || hasAdvocateProfile) {
                  return [
                    {
                      label: COMPANY_SECRETARY,
                      value: COMPANY_SECRETARY,
                    },
                    {
                      label: ADVOCATE,
                      value: ADVOCATE,
                    },
                  ];
                }
                return [
                  {
                    label: DIRECTOR,
                    value: DIRECTOR,
                  },
                  {
                    label: DIRECTOR_SHAREHOLDER,
                    value: DIRECTOR_SHAREHOLDER,
                  },
                  {
                    label: SHAREHOLDER,
                    value: SHAREHOLDER,
                  },
                  {
                    label: BENEFICIAL_OWNER,
                    value: BENEFICIAL_OWNER,
                  },
                  {
                    label: COMPANY_SECRETARY,
                    value: COMPANY_SECRETARY,
                  },
                  {
                    label: ADVOCATE,
                    value: ADVOCATE,
                  },
                ];
              }, [hasCompanySecretaryProfile, hasAdvocateProfile]);

              useEffect(() => {
                if (values.capacity === COMPANY_SECRETARY && isApplicant) {
                  if (hasCompanySecretaryProfile) {
                    setFieldValue("capacity", COMPANY_SECRETARY);
                    setFieldValue(
                      "practicingNumber",
                      hasCompanySecretaryProfile.practicingNumber
                    );
                    setFieldValue("renderForm", "");
                  } else {
                    setFieldValue("renderForm", COMPANY_SECRETARY);
                  }
                }
                if (values.capacity === ADVOCATE && isApplicant) {
                  if (hasAdvocateProfile) {
                    setFieldValue("capacity", ADVOCATE);
                    setFieldValue(
                      "practicingNumber",
                      hasAdvocateProfile.practicingNumber
                    );
                    setFieldValue("renderForm", "");
                  } else {
                    setFieldValue("renderForm", ADVOCATE);
                  }
                }
              }, [
                values.capacity,
                hasAdvocateProfile,
                hasCompanySecretaryProfile,
              ]);

              return (
                <>
                  {getProfessionalDetails.status === "loading" ? (
                    <SpinnerIcon className="text-gray-600 mx-auto my-2" />
                  ) : (
                    <Form>
                      <div className="bg-white my-4 shadow sm:rounded-md">
                        <div className="px-4 py-6 space-y-6 sm:p-6">
                          <div className="grid grid-cols-12 gap-4 items-center">
                            <div className="col-span-5">
                              <Field
                                value={capacity.value}
                                onChange={handleSelect("capacity")}
                                component={ReactSelectWithFormik}
                                options={capacityOptions}
                                isDisabled={
                                  isSubmitting ||
                                  isCoApplicant ||
                                  ([COMPANY_SECRETARY, ADVOCATE].includes(
                                    applicantCapacity?.capacity
                                  ) &&
                                    professionalDetails.length === 1)
                                }
                                type="text"
                                label="Capacity"
                                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                                id="capacity"
                                name="capacity"
                                placeholder={
                                  isApplicant ? "Select capacity" : ""
                                }
                              />
                            </div>
                            {[COMPANY_SECRETARY, ADVOCATE].includes(
                              values.capacity
                            ) &&
                              !values.renderForm && (
                                <div className="col-span-5">
                                  <Field
                                    label="Practicing Number"
                                    name="practicingNumber"
                                    component={Input}
                                    disabled
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                        {values.renderForm && (
                          <div className="p-4 pt-0">
                            <div className="flex flex-col space-y-4">
                              <div className="grid grid-cols-12 sm:grid-cols-4 space-x-4 w-full">
                                <Field
                                  component={Input}
                                  label="Practicing Number"
                                  name="practicingNumber"
                                  placeholder="Enter practicing number"
                                  className="flex-grow"
                                  showRequiredAsterisk
                                />
                                <Field
                                  component={Input}
                                  label="Practicing Certificate"
                                  name="practicingCertificate"
                                  placeholder="Enter practicing certificate"
                                  className="flex-grow"
                                  showRequiredAsterisk
                                />
                              </div>
                              <Field
                                name="fileUploads"
                                component={FileUploadForm}
                                documentData={[
                                  {
                                    accept: "application/pdf",
                                    label: "Upload Certificate",
                                    name: "practicingCertificate",
                                  },
                                ]}
                                showRequiredAsterisk
                              />
                            </div>
                            <div className="flex justify-end space-x-2 mt-4">
                              <Button
                                isLoading={isSubmitting}
                                onClick={handleSubmit}
                                loadingText={"Saving..."}
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </Form>
                  )}
                  <FormFieldsCard
                    fields={[
                      {
                        key: "First name",
                        value: row?.original.names["firstName"],
                      },
                      {
                        key: "Middle name",
                        value: row?.original.names["middleName"],
                      },
                      {
                        key: "Former name",
                        value: row?.original.names["lastName"],
                      },
                      {
                        key: verifyParams.label,
                        value:
                          row.original.identificationDetails[verifyParams.name],
                      },
                    ]}
                    hideResolveAllCheckbox
                  />
                </>
              );
            }}
          </Formik>
        )}
        {selectedTab.id === "residentialDetails" && (
          <>
            <FormFieldsCard
              fields={[
                {
                  key: "Postal Address",
                  value: row?.original.postalAddress["postalAddress"],
                },
                {
                  key: "Postal Code",
                  value: row?.original.postalAddress["postalCode"],
                },
                {
                  key: "Postal Address",
                  value: row?.original.postalAddress["postalAddress"],
                },
              ]}
              hideResolveAllCheckbox
            />
            <FormFieldsCard
              fields={getFormFields({
                idType: row?.original?.idType,
                formType: "electronicAddress",
                formValues: row?.original?.electronicAddress,
              })}
              hideResolveAllCheckbox
            />
          </>
        )}
      </td>
    </tr>
  );
}

export default ApplicantSubRow;
