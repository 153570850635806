import { XCircleIcon } from "@heroicons/react/solid";
import { twMerge } from "tailwind-merge";

export default function ErrorBanner({
  errors = [],
  className,
  showTitle = true,
}) {
  if (errors.length === 0) return null;

  return (
    <div className={twMerge("rounded-md bg-red-50 p-4", className)}>
      <div className="flex">
        {showTitle && (
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
        )}
        <div className={`${showTitle ? "ml-3" : ""}`}>
          {showTitle && (
            <h3 className="text-sm font-medium text-red-800">
              {errors.length === 1
                ? "There is one error with your submission"
                : `There are ${errors.length} errors with your submission`}
            </h3>
          )}
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {errors.map((error, index) => (
                <li key={`${error}_${index}`}>{error}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
