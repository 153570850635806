import React, { useState, useEffect } from "react";
import { ArrowsExpandIcon } from "@heroicons/react/outline";

import ArrowsPointingInIcon from "components/lib/Shared/Icons/ArrowsPointingIn";
import Button from "components/lib/Shared/Button";
import { delay } from "utils";

const useFullscreenToggle = (
  containerStyles = "",
  containerHeight = "100vh"
) => {
  const [isFullscreen, setIsFullscreen] = useState(
    JSON.parse(localStorage.getItem("isFullscreen")) || false
  );
  const [documentEditorHeight, setDocumentEditorHeight] = useState("100%");

  const toggleFullscreen = () => {
    localStorage.setItem("isFullscreen", JSON.stringify(!isFullscreen));
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    async function toggleFullscreen() {
      if (isFullscreen) {
        window.scroll(0, 0);
        await delay(100);
        document.body.style.overflow = "hidden";
        setDocumentEditorHeight("100%");
      } else {
        setDocumentEditorHeight("96%");
        document.body.style.overflow = "auto";
      }
    }
    toggleFullscreen();
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isFullscreen]);

  const ToggleFullscreenContainer = ({ children }) => (
    <div
      className={`${
        isFullscreen
          ? `fixed inset-0 ${containerStyles} top-[7.5rem] z-50`
          : "bg-white"
      }`}
      style={{
        height: isFullscreen
          ? (window.innerHeight || document.documentElement.clientHeight) -
            160 +
            "px"
          : containerHeight,
      }}
    >
      {children}
    </div>
  );

  const ToggleFullscreenButton = () => (
    <div className="w-full bg-[#555555]">
      <Button
        overrideBaseStyles
        className="text-white flex ml-auto p-2 space-x-2"
        onClick={toggleFullscreen}
      >
        <span>
          {isFullscreen ? "Toggle in-line editor" : "Toggle Fullscreen"}
        </span>
        {isFullscreen ? (
          <ArrowsPointingInIcon />
        ) : (
          <ArrowsExpandIcon className="w-6 h-6" />
        )}
      </Button>
    </div>
  );

  return {
    isFullscreen,
    documentEditorHeight,
    toggleFullscreen,
    ToggleFullscreenContainer,
    ToggleFullscreenButton,
  };
};

export default useFullscreenToggle;
