import Label from "components/lib/Shared/Label";
import ValidationError from "components/lib/Shared/ValidationError";
import CommentsList from "components/lib/Global/CommentsList";

const RadioGroup = (props) => {
  let {
    label,
    errors,
    touched,
    name,
    comments = [],
    children,
    showRequiredAsterisk,
  } = props;

  return (
    <div className="space-y-2">
      <Label>
        <span
          className={`relative 
                ${
                  showRequiredAsterisk
                    ? "after:content-['*'] after:ml-0.5 after:text-red-500"
                    : ""
                }
                `}
        >
          {label}
          {comments.length > 0 && (
            <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-3 -right-5">
              <span>{comments.length}</span>
            </span>
          )}
        </span>
      </Label>
      <div className="flex space-x-10">{children}</div>
      {comments.length > 0 && (
        <CommentsList comments={comments} color={"text-red-500"} />
      )}
      <ValidationError errors={errors} touched={touched} name={name} />
    </div>
  );
};

export default RadioGroup;
