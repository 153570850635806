import React from "react";

const SectionDescription = ({ id, title, description }) => (
  <div className="max-w-prose mb-4">
    <h3 className="text-lg leading-6 font-medium text-gray-900">
      {id && `${id}.`} {title && title}
    </h3>
    {description && (
      <p className="mt-1 text-sm text-gray-500 whitespace-normal">
        {description}
      </p>
    )}
  </div>
);

export default SectionDescription;
