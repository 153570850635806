import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { Formik, Field } from "formik";
import { MinusIcon } from "@heroicons/react/outline";
import _get from "lodash/get";
import _has from "lodash/has";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import {
  getCompanySecretaryDocumentData,
  getDocumentData,
} from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import Tabs from "components/lib/Shared/Tabs";
import {
  calculateCumulativeOwnership,
  getBeneficialOwnershipFormFields,
  getFormFields,
} from "utils";
import BeneficialOwnersTableReview from "./BeneficialOwnersTableReview";
import CommentsList from "components/lib/Global/CommentsList";
import { useToast } from "hooks/useToast";
import { addComment, editComment } from "state/slices/comment";
import { editBeneficialOwnerAsync } from "state/slices/tables/beneficialOwnersTableSlice";

function BeneficialOwnersSubRowReview({
  visibleColumns,
  row,
  currentTab = null,
  handleBackBtn,
  selectedTableType,
  hideEditBtn,
  hideDeleteBtn,
  isViewOnly,
  hideReviewWorkflow,
  hideResolveAllCheckbox,
  showDocumentLabel,
  ...restProps
}) {
  const beneficialOwnersTableSlice = useSelector(
    (state) => state.beneficialOwnersTableSlice
  );
  const beneficialOwners =
    restProps.beneficialOwners || beneficialOwnersTableSlice.beneficialOwners;
  const history = useHistory();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch();
  const isViewMode =
    history.location.pathname.split("/").pop() === "view" ||
    isViewOnly ||
    restProps.isViewMode;
  const { comments } = useSelector((state) => state.commentsSlice);

  const stakeholderComments = comments.filter(
    (comment) =>
      comment?.officialId &&
      comment.officialId === row.original._id &&
      comment?.tabName &&
      comment.tabName === currentTab
  );
  const generalInformationComments = stakeholderComments.filter(
    (comment) =>
      comment.formName === "identificationDetails" ||
      comment.formName === "names"
  );
  const residentialDetailsComments = stakeholderComments.filter(
    (comment) =>
      comment.formName === "postalAddress" ||
      comment.formName === "electronicAddress" ||
      comment.formName === "residentialAddressDetails" ||
      comment.formName === "isNonResident"
  );
  const officialBeneficialOwnershipComments = stakeholderComments.filter(
    (comment) => comment.formName === "beneficialOwnershipForm"
  );
  const fileUploadComments = stakeholderComments.filter(
    (comment) => comment.formName === "uploadDocuments"
  );

  const { company } = useSelector((state) => state.companySlice);

  const tabs = useMemo(() => {
    return [
      {
        id: "generalInformation",
        label: "General Information",
        commentsCount: generalInformationComments?.length,
      },
      {
        id: "residentialDetails",
        label: "Address Details",
        commentsCount: residentialDetailsComments?.length,
      },
      {
        id: "officialBeneficialOwnership",
        label: "Beneficial ownership",
        commentsCount: officialBeneficialOwnershipComments?.length,
      },
      {
        id: "uploadDocuments",
        label: "File Uploads",
        commentsCount: fileUploadComments?.length,
      },
    ];
  }, [comments]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { toast } = useToast(5000);

  const goToPrevStep = () => {
    if (setSelectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex((setSelectedTabIndex) => setSelectedTabIndex - 1);
  };
  const goToNextStep = () => {
    setSelectedTabIndex((selectedTabIndex) => selectedTabIndex + 1);
  };

  const handleSaveComment = async (data) => {
    if (!company?._id) {
      toast(
        "error",
        "Something went wrong please reload the page and try again."
      );
      return;
    }
    if (data?.commentId) {
      await dispatch(editComment({ commentID: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            company: company._id,
            officialId: row.original._id,
            tabName: currentTab,
          },
        })
      );
    }
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  let dateOfBirth = _get(row.original.identificationDetails, "dateOfBirth");
  if (dateOfBirth) dateOfBirth = format(new Date(dateOfBirth), "yyyy/MM/dd");

  const [acceptingData, setAcceptingData] = useState(false);

  const officialIndex = beneficialOwners.findIndex(
    (official) => official._id === row.original._id
  );

  const officialSliceName = `beneficialOwnersTableSlice.beneficialOwners.${officialIndex}`;
  const companyOfficialEffectKey = "updateBeneficialOfficialState";

  const acceptData = async () => {
    if (!company?._id) {
      toast(
        "error",
        "Something went wrong please reload the page and try again"
      );
      return;
    }
    try {
      setAcceptingData(true);
      await dispatch(
        editBeneficialOwnerAsync({
          companyId: company?._id,
          beneficialOwnerId: row.original._id,
          data: {
            inviteStatus: "Approved",
            companyId: company?._id,
            idType: row?.original?.idType,
          },
        })
      );
      row.toggleRowExpanded();
    } catch (error) {
    } finally {
      setAcceptingData(false);
    }
  };

  if (row?.original?.children?.length > 1) {
    let cumulativeShareholdings = 0;
    let cumulativeVotings = 0;
    if (
      _has(row.original, "cumulativeShareholdings") &&
      _has(row.original, "cumulativeVotings")
    ) {
      cumulativeShareholdings = row.original?.cumulativeShareholdings;
      cumulativeVotings = row.original?.cumulativeVotings;
    } else {
      const cumulativeOwnership = calculateCumulativeOwnership(
        row?.original,
        beneficialOwners
      );
      cumulativeShareholdings = cumulativeOwnership.cumulativeShareholdings;
      cumulativeVotings = cumulativeOwnership.cumulativeVotings;
    }

    let reviewBtnProps = {};
    if (row?.getToggleRowExpandedProps) {
      reviewBtnProps = row?.getToggleRowExpandedProps();
    }

    return (
      <tr className="bg-white">
        <td colSpan={visibleColumns.length}>
          <BeneficialOwnersTableReview
            data={row?.original?.children}
            showActionColumn
            handleBackBtn={handleBackBtn}
            groupBOEntries={false}
            hideColumnsRow={true}
            selectedTableType={selectedTableType}
            hideEditBtn={hideEditBtn}
            hideDeleteBtn={hideDeleteBtn}
            hideReviewWorkflow={hideReviewWorkflow}
            beneficialOwners={beneficialOwners}
            showDocumentLabel={showDocumentLabel}
          />
          <div className="flex items-center justify-end w-full h-12 px-8 space-x-16 text-white bg-primary">
            <span>{`${cumulativeShareholdings}%, ${cumulativeVotings}%`}</span>
            <button
              type="button"
              {...reviewBtnProps}
              className={`border-white border-2 items-center rounded-full`}
            >
              <MinusIcon className="w-5 h-5 p-1" aria-hidden="true" />
            </button>
          </div>
        </td>
      </tr>
    );
  }

  const renderAcceptDataBtn =
    stakeholderComments.length === 0 &&
    row.original.inviteStatus === "Pending approval";

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "generalInformation" && (
          <>
            {!hideReviewWorkflow &&
              generalInformationComments &&
              generalInformationComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={generalInformationComments}
                    toggleCommentStatus={!restProps.isViewMode}
                    applicantReviewer
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments:{generalInformationComments.length} of {""}
                    {generalInformationComments.length}
                  </p>
                </div>
              )}
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType: row.original.idType,
                formType: "names",
                formValues: row.original.names,
              })}
              resolvedFields={_get(row.original.names, "resolvedFields")}
              addReviewComment={!hideReviewWorkflow && !restProps.isViewMode}
              hideResolveAllCheckbox={
                hideReviewWorkflow || restProps.isViewMode
              }
              formName="names"
              formHelperKey="names"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.names`}
              formId={_get(row.original.names, "_id")}
              comments={generalInformationComments.filter(
                (comment) => comment.formName === "names"
              )}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType: row.original.idType,
                formType: "identificationDetails",
                formValues: row.original.identificationDetails,
              })}
              resolvedFields={_get(
                row.original.identificationDetails,
                "resolvedFields"
              )}
              addReviewComment={!hideReviewWorkflow && !restProps.isViewMode}
              hideResolveAllCheckbox={
                hideReviewWorkflow || restProps.isViewMode
              }
              formName="identificationDetails"
              formHelperKey="identificationDetails"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.identificationDetails`}
              formId={_get(row.original.identificationDetails, "_id")}
              comments={generalInformationComments.filter(
                (comment) => comment.formName === "identificationDetails"
              )}
              files={row?.original?.fileUploads ?? {}}
            />
            {[
              "Local Company",
              "Foreign Company",
              "Other e.g Parastatals",
            ].includes(row?.original?.idType) && (
              <FormFieldsCard
                fields={getFormFields({
                  idType: row.original.idType,
                  formType: "contactPersonInfo",
                  formValues: row.original.identificationDetails,
                })}
                resolvedFields={_get(
                  row.original.identificationDetails,
                  "resolvedFields"
                )}
                addReviewComment={!hideReviewWorkflow && !restProps.isViewMode}
                hideResolveAllCheckbox={
                  hideReviewWorkflow || restProps.isViewMode
                }
                formName="identificationDetails"
                formHelperKey="identificationDetails"
                updateEffectKey={companyOfficialEffectKey}
                updateEffectPath={`${officialSliceName}.identificationDetails`}
                formId={_get(row.original.identificationDetails, "_id")}
                comments={generalInformationComments.filter(
                  (comment) => comment.formName === "identificationDetails"
                )}
                files={row?.original?.fileUploads ?? {}}
              />
            )}
          </>
        )}
        {selectedTab.id === "residentialDetails" && (
          <>
            {!hideReviewWorkflow &&
              residentialDetailsComments &&
              residentialDetailsComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={residentialDetailsComments}
                    toggleCommentStatus={!restProps.isViewMode}
                    applicantReviewer
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments:{residentialDetailsComments.length} of {""}
                    {residentialDetailsComments.length}
                  </p>
                </div>
              )}
            {row?.original?.idType === "Kenyan Citizen" && (
              <FormFieldsCard
                handleSaveComment={handleSaveComment}
                fields={[
                  {
                    key: "Non-resident",
                    name: "isNonResident",
                    value: row.original?.isNonResident ?? "N/A",
                  },
                ]}
                resolvedFields={_get(row.original, "resolvedFields")}
                addReviewComment={!hideReviewWorkflow && !restProps.isViewMode}
                hideResolveAllCheckbox={
                  hideReviewWorkflow || restProps.isViewMode
                }
                formName="isNonResident"
                formHelperKey="beneficialOwner"
                updateEffectKey={companyOfficialEffectKey}
                updateEffectPath={officialSliceName}
                formId={_get(row.original, "_id")}
                comments={residentialDetailsComments.filter(
                  (comment) => comment.formName === "isNonResident"
                )}
              />
            )}
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType:
                  row?.original?.isNonResident &&
                  row?.original?.isNonResident === "Yes"
                    ? "Foreigner"
                    : row?.original?.idType,
                formType: "postalAddress",
                formValues: row?.original?.postalAddress,
              })}
              resolvedFields={_get(
                row.original.postalAddress,
                "resolvedFields"
              )}
              addReviewComment={!hideReviewWorkflow && !restProps.isViewMode}
              hideResolveAllCheckbox={
                hideReviewWorkflow || restProps.isViewMode
              }
              formName="postalAddress"
              formHelperKey="beneficialOwner"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.postalAddress`}
              formId={_get(row.original.postalAddress, "_id")}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "postalAddress"
              )}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType: row?.original?.idType,
                formType: "electronicAddress",
                formValues: row?.original?.electronicAddress,
              })}
              resolvedFields={_get(
                row.original.electronicAddress,
                "resolvedFields"
              )}
              addReviewComment={!hideReviewWorkflow && !restProps.isViewMode}
              hideResolveAllCheckbox={
                hideReviewWorkflow || restProps.isViewMode
              }
              formName="postalAddress"
              formHelperKey="beneficialOwner"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.electronicAddress`}
              formId={_get(row.original.electronicAddress, "_id")}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "electronicAddress"
              )}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType:
                  row?.original?.isNonResident &&
                  row?.original?.isNonResident === "Yes"
                    ? "Foreigner"
                    : row?.original?.idType,
                formType: "residentialAddressDetails",
                formValues: row?.original?.residentialAddressDetails,
              })}
              resolvedFields={_get(
                row.original.residentialAddressDetails,
                "resolvedFields"
              )}
              addReviewComment={!hideReviewWorkflow && !restProps.isViewMode}
              hideResolveAllCheckbox={
                hideReviewWorkflow || restProps.isViewMode
              }
              formName="residentialAddressDetails"
              formHelperKey="residentialAddressDetails"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.residentialAddressDetails`}
              formId={_get(row.original.residentialAddressDetails, "_id")}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "residentialAddressDetails"
              )}
            />
          </>
        )}
        {selectedTab.id === "officialBeneficialOwnership" && (
          <>
            {!hideReviewWorkflow &&
              officialBeneficialOwnershipComments &&
              officialBeneficialOwnershipComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={officialBeneficialOwnershipComments}
                    toggleCommentStatus={!restProps.isViewMode}
                    applicantReviewer
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments:{officialBeneficialOwnershipComments.length} of{" "}
                    {""}
                    {officialBeneficialOwnershipComments.length}
                  </p>
                </div>
              )}
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getBeneficialOwnershipFormFields({
                beneficialOwnershipForm: row.original.beneficialOwnershipForm,
                reviewTab: true,
                beneficialOwner: row.original,
                beneficialOwners,
              })}
              resolvedFields={_get(
                row.original.beneficialOwnershipForm,
                "resolvedFields"
              )}
              addReviewComment={!hideReviewWorkflow && !restProps.isViewMode}
              hideResolveAllCheckbox={
                hideReviewWorkflow || restProps.isViewMode
              }
              formName="beneficialOwnershipForm"
              formHelperKey="beneficialOwnershipForm"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.beneficialOwnershipForm`}
              formId={_get(row.original.beneficialOwnershipForm, "_id")}
              comments={officialBeneficialOwnershipComments}
            />
            <FormFieldsCard
              fields={getBeneficialOwnershipFormFields({
                beneficialOwnershipForm: row.original.beneficialOwnershipForm,
                reviewTab: true,
                renderShortTypeOfControl: true,
                onlyDates: true,
                beneficialOwner: row.original,
                beneficialOwners,
              })}
              formName="beneficialOwnershipForm"
              formHelperKey="beneficialOwnershipForm"
              hideResolveAllCheckbox={hideResolveAllCheckbox}
            />
          </>
        )}
        {selectedTab.id === "uploadDocuments" && (
          <>
            {!hideReviewWorkflow &&
              fileUploadComments &&
              fileUploadComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={fileUploadComments}
                    toggleCommentStatus={!restProps.isViewMode}
                    applicantReviewer
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments:{fileUploadComments.length} of {""}{" "}
                    {fileUploadComments.length}
                  </p>
                </div>
              )}
            <div className="px-8 pt-5 pb-6 mt-6 bg-white">
              <h3 className="mb-2 text-lg">Uploaded incorporation forms</h3>
              <Formik
                enableReinitialize
                initialValues={{
                  fileUploads: row.original.fileUploads,
                }}
                onSubmit={() => null}
              >
                {() => {
                  return (
                    <div className="pl-4">
                      <Field
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={
                          row.original.designation === "Company Secretary"
                            ? getCompanySecretaryDocumentData(
                                row.original.idType
                              )
                            : getDocumentData(row.original.idType)
                        }
                        resolvedFields={_get(row.original, "resolvedFields")}
                        onlyPreview
                        showDocumentLabel={showDocumentLabel}
                        addReviewComment={
                          !hideReviewWorkflow && !restProps.isViewMode
                        }
                        hideResolveAllCheckbox={
                          hideReviewWorkflow || restProps.isViewMode
                        }
                        handleSaveComment={handleSaveComment}
                        formName="uploadDocuments"
                        formHelperKey="beneficialOwner"
                        updateEffectKey={companyOfficialEffectKey}
                        updateEffectPath={officialSliceName}
                        formId={_get(row.original, "_id")}
                        resolveAllFieldsCheckbox
                        fileUploadComments={fileUploadComments}
                      />
                    </div>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
        {!hideReviewWorkflow ? (
          <div className="flex justify-between">
            {!isFirstStep && (
              <button
                onClick={goToPrevStep}
                className="flex items-start float-right py-2 font-semibold rounded text-primary mt-7"
              >
                <span className="flex items-center mr-4 h-7">
                  <ChevronLeftIcon
                    className="flex-shrink-0 w-5 h-5"
                    aria-hidden="true"
                  />
                </span>
                <p className="font-semibold">Go back</p>
              </button>
            )}
            {isLastStep ? (
              <button
                disabled={acceptingData}
                onClick={() =>
                  renderAcceptDataBtn ? acceptData() : row.toggleRowExpanded()
                }
                className="flex items-center px-4 py-2 mb-2 font-semibold text-white rounded bg-green-focused mt-7 disabled:cursor-not-allowed"
              >
                {acceptingData ? (
                  <>
                    <SpinnerIcon />
                    Accepting...
                  </>
                ) : (
                  <p>
                    {isViewMode
                      ? "Close"
                      : renderAcceptDataBtn
                      ? "Accept Data"
                      : "Confirm Details"}
                  </p>
                )}
                {!isViewMode && !acceptingData && (
                  <span className="flex items-center ml-4 text-white h-7">
                    <ChevronRightIcon
                      className="flex-shrink-0 w-5 h-5"
                      aria-hidden="true"
                    />
                  </span>
                )}
              </button>
            ) : (
              <button
                onClick={goToNextStep}
                className="flex items-start float-right py-2 ml-auto font-semibold rounded text-primary mt-7"
              >
                <p className="font-semibold">Review next step</p>
                <span className="flex items-center ml-4 h-7">
                  <ChevronRightIcon
                    className="flex-shrink-0 w-5 h-5"
                    aria-hidden="true"
                  />
                </span>
              </button>
            )}
          </div>
        ) : (
          <div className="mt-7" />
        )}
      </td>
    </tr>
  );
}

export default BeneficialOwnersSubRowReview;
