import { useEffect, useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import Overview from "components/OrgAndSuperAdminDashboard/Settings/Management/Packages/Overview";
import Features from "components/OrgAndSuperAdminDashboard/Settings/Management/Packages/Features";
import Currencies from "components/OrgAndSuperAdminDashboard/Settings/Management/Packages/Currencies";
import Subscription from "components/OrgAndSuperAdminDashboard/Settings/Management/Packages/Subscription";
import Button from "components/lib/Shared/Button";
import {
  editPackageAsync,
  getPackagesAsync,
  updatePackageState,
} from "state/slices/package";
import { useToast } from "hooks/useToast";
import { Constants } from "config/constants";

export default ({ setShowAddPackageForm }) => {
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { selectedPackage } = useSelector((state) => state.packageSlice);

  useEffect(() => {
    return () => {
      dispatch(getPackagesAsync());
    };
  }, []);

  const handleExitWorkflow = () => {
    setShowAddPackageForm(false);
    dispatch(
      updatePackageState({
        selectedPackage: {
          features: [],
          currencies: [],
        },
        mode: "",
      })
    );
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (selectedPackage.status === "") {
      await dispatch(
        editPackageAsync({
          packageId: selectedPackage?._id,
          data: { status: "Draft" },
        })
      );
      toast("success", "Saved package as draft");
    } else {
      await dispatch(
        editPackageAsync({
          packageId: selectedPackage?._id,
          data: { status: "Active" },
        })
      );
      toast("success", "Published package");
    }
    handleExitWorkflow();
  };

  return (
    <div>
      <Button
        onClick={handleExitWorkflow}
        variant="text"
        className="flex space-x-2 text-sm font-medium rounded-md group text-gray-600 mb-2"
      >
        <ArrowLeftIcon
          className="w-5 h-5 text-gray-400 group-hover:text-gray-800"
          aria-hidden="true"
        />
        <span className="group-hover:text-gray-800">Exit</span>
      </Button>
      <div className="space-y-12">
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4">
          <div className="tile  col-span-1 md:col-span-2 lg:col-span-2">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Package Overview
              </h2>
            </div>
          </div>
          <div className="tile col-span-2 md:col-span-3 lg:col-span-6">
            <Overview />
          </div>
        </div>
        {selectedPackage?._id && (
          <>
            <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4">
              <div className="tile  col-span-1 md:col-span-2 lg:col-span-2">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Package Details
                  </h2>
                </div>
              </div>
              <div className="tile col-span-2 md:col-span-3 lg:col-span-6">
                <div className="my-2">
                  <Features />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4">
              <div className="tile  col-span-1 md:col-span-2 lg:col-span-2">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Currency Settings
                  </h2>
                </div>
              </div>
              <div className="tile col-span-2 md:col-span-3 lg:col-span-6">
                <div className="my-2">
                  <Currencies />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4">
              <div className="tile  col-span-1 md:col-span-2 lg:col-span-2">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Subscription Management
                  </h2>
                </div>
              </div>
              <div className="tile col-span-2 md:col-span-3 lg:col-span-6">
                <div className="my-2">
                  <Subscription />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-4 mt-1">
              <Button
                disabled={isSubmitting}
                variant="secondary"
                onClick={handleExitWorkflow}
              >
                {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN
                  ? "Cancel"
                  : "Close"}
              </Button>
              {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN &&
                selectedPackage.status !== "Active" && (
                  <Button
                    type="submit"
                    loadingText="Saving..."
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {selectedPackage.status === ""
                      ? "Save as draft"
                      : "Publish"}
                  </Button>
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
