import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";

import { useToast } from "hooks/useToast";
import Tabs from "components/lib/Shared/Tabs";
import CorporateSummary from "components/CorporateOnboarding/SummaryTab/CorporateSummary";
import AddressParticulars from "components/CorporateOnboarding/SummaryTab/AddressParticulars";
import PartnersDetails from "components/CorporateOnboarding/SummaryTab/PartnersDetails";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { Field, Formik } from "formik";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import { getComments, resetCommentsSlice } from "state/slices/comment";
import CommentsList from "components/lib/Global/CommentsList";
import Button from "components/lib/Shared/Button";
import {
  getTransactionCorporate,
  resetTransactionCorporate,
} from "state/slices/corporatesForDashboard";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import StakeholderDetails from "../BusinessDetails/StakeholderDetails";
import BeneficialOwnership from "components/PLCTABS/ApplicationReviewTab/BeneficialOwnership";
import ShareInformation from "../BusinessDetails/ShareInformation";

function SubRowAsync({ visibleColumns, row }) {
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState({});
  const { toast } = useToast(5000);

  const { comments } = useSelector((state) => state.commentsSlice);

  const fileUploadsComments = comments.filter(
    (comment) => comment.tabName === "uploadDocuments"
  );

  const { transactionCorporate, getTransactionCorporateStatus } = useSelector(
    (state) => state.corporatesForDashboardSlice
  );

  useEffect(() => {
    if (!transactionCorporate?._id) return;
    const tempTabs = [
      {
        id: "corporateSummary",
        label: "Corporate Summary",
      },
      {
        id: "addressParticulars",
        label: "Address particulars",
      },
      transactionCorporate?.partnershipType
        ? {
            id: "ownerships",
            label: "Ownerships",
          }
        : null,
    ].filter(Boolean);

    if (organization.designation?.includes("Shareholder")) {
      tempTabs.push(
        {
          id: "shareInformation",
          label: "Share Information",
        },
        {
          id: "topShareholder",
          label: "Stakeholder Details",
        },
        {
          id: "beneficialOwnership",
          label: "BeneficialOwnership",
        }
      );
    }
    tempTabs.push({
      id: "uploadDocuments",
      label: "File Uploads",
    });
    setTabs(tempTabs);
    setSelectedTab(tempTabs[0]);
  }, [transactionCorporate]);

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  useEffect(() => {
    if (row?.original?._id) {
      dispatch(getTransactionCorporate({ corporateId: row.original._id }));
      dispatch(getComments({ organizationId: row.original._id }));
    }
    return () => {
      dispatch(resetTransactionCorporate());
      dispatch(resetCommentsSlice());
    };
  }, [row?.original?._id]);

  useEffect(() => {
    if (
      getTransactionCorporateStatus.status === "failed" &&
      getTransactionCorporateStatus.error
    ) {
      toast("error", getTransactionCorporateStatus.error);
      row.toggleRowExpanded();
    }
  }, [
    getTransactionCorporateStatus.status,
    getTransactionCorporateStatus.error,
  ]);

  if (
    !transactionCorporate?._id ||
    getTransactionCorporateStatus.status === "loading"
  ) {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto my-4">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  const organization = transactionCorporate;

  const partners = [
    ...organization.individualOfficials,
    ...organization.corporateOfficials,
  ];

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "corporateSummary" && (
          <CorporateSummary
            currentTab={selectedTab.id}
            toggleCommentStatus={false}
            hideResolveAllCheckbox
            paginate
          />
        )}
        {selectedTab.id === "addressParticulars" && (
          <AddressParticulars
            currentTab={selectedTab.id}
            toggleCommentStatus={false}
            hideResolveAllCheckbox
            paginate
          />
        )}
        {selectedTab.id === "shareInformation" && (
          <ShareInformation
            disabledRightsEditor
            className="text-center mr-24"
          />
        )}
        {selectedTab.id === "ownerships" && (
          <>
            {partners.length > 0 ? (
              <PartnersDetails
                data={partners}
                toggleCommentStatus={false}
                hideResolveAllCheckbox
                paginate
                readOnly
              />
            ) : (
              <p className="mt-4 text-center">No data available</p>
            )}
          </>
        )}
        {selectedTab.id === "topShareholder" && <StakeholderDetails />}
        {selectedTab.id === "beneficialOwnership" && (
          <BeneficialOwnership
            hideReviewWorkflow
            renderShortTypeOfControl
            beneficialOwners={organization.beneficialOwners}
            handleBackBtn={goToPrevStep}
            disableDeleteEffect
          />
        )}
        {selectedTab.id === "uploadDocuments" && (
          <>
            {fileUploadsComments.length > 0 && (
              <div className="px-8 pt-5 pb-6 bg-white mt-4">
                <CommentsList
                  comments={fileUploadsComments}
                  toggleCommentStatus={false}
                  paginate
                />
                <p className="mt-6 text-sm text-primary">
                  Comments: {fileUploadsComments.length} of{" "}
                  {fileUploadsComments.length}
                </p>
              </div>
            )}
            <div className="px-8 pt-5 pb-6 mt-6 bg-white">
              <h3 className="mb-2 text-lg">Uploaded incorporation forms</h3>
              <Formik
                enableReinitialize
                initialValues={{
                  fileUploads: organization.fileUploads,
                }}
                onSubmit={() => null}
              >
                {() => {
                  return (
                    <div className="pl-4">
                      <Field
                        currentTab={selectedTab.id}
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={getDocumentData(organization.idType)}
                        resolvedFields={_get(organization, "resolvedFields")}
                        onlyPreview
                        formName="uploadDocuments"
                        formHelperKey="corporateOfficial"
                        updateEffectKey="updateOrganizationState"
                        updateEffectPath="organizationsSlice.organization"
                        formId={_get(organization, "_id")}
                        showResolveAllFieldsCheckbox={false}
                        showDocumentLabel
                        fileUploadComments={fileUploadsComments}
                      />
                    </div>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
        <div className="flex justify-between">
          {!isFirstStep && (
            <Button
              onClick={goToPrevStep}
              preIcon={ChevronLeftIcon}
              variant="text"
              className="my-4"
            >
              Go back
            </Button>
          )}
          {isLastStep ? (
            <Button
              onClick={() => row.toggleRowExpanded()}
              className="my-4"
              postIcon={ChevronRightIcon}
            >
              Close
            </Button>
          ) : (
            <Button
              onClick={goToNextStep}
              postIcon={ChevronRightIcon}
              className="my-4 ml-auto"
            >
              Next step
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
}

export default SubRowAsync;
