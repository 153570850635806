/* eslint-disable eol-last */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";

import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import getValidationSchema from "./getValidationSchema";
import {
  addShares,
  editShares,
} from "state/slices/tables/sharesAllocationTable";
import { getTakenShares, getUpdatedFormData, roundNumber } from "utils";
import Input from "components/lib/Shared/Input";
import Button from "components/lib/Shared/Button";

const formFields = [
  "classOfShares",
  "allottedShares",
  "paidUpShares",
  "sharesValue",
  "sharePrice",
  "sharePremium",
];

const ShareAllocationSelection = (props) => {
  const {
    editableShareAlloc,
    setEditableShareAlloc,
    setAllocateSharesBtn,
    classOfSharesOptions,
    selectedClassOfShare,
    setSelectedClassOfShare,
    alreadyTakenClassOfShares,
  } = props;
  const officialFormContext = useFormikContext();

  const dispatch = useDispatch();
  const [validationSchema, setValidationSchema] = useState(null);
  const takenShares = getTakenShares(
    useSelector(
      (state) => state.companyOfficialsSlice.companyOfficials.items
    ).filter((co) => co._id !== props.currentOfficialId),
    editableShareAlloc?._id
  );

  const { shares: nominalShareCapital } = useSelector(
    (state) => state.sharesTableSlice
  );

  const handleCancel = () => {
    setAllocateSharesBtn(true);
    setEditableShareAlloc(null);
  };

  useEffect(() => {
    setValidationSchema(getValidationSchema({}).schema);
    setSelectedClassOfShare("");
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          classOfShares: "",
          allottedShares: "",
          paidUpShares: "",
          sharesValue: "",
          sharePrice: "",
          sharePremium: "",
          ...editableShareAlloc,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const selectedClassOfShares = nominalShareCapital.find(
            ({ sharesCategory }) => sharesCategory === values.classOfShares
          );
          const nominalValue = values.allottedShares * values.sharesValue;
          const payload = {
            ...values,
            nominalValue,
            votingRights:
              values.allottedShares * selectedClassOfShares.votesPerShare,
          };
          const updatedOfficialData = getUpdatedFormData(
            formFields,
            editableShareAlloc || {},
            payload
          );

          if (editableShareAlloc) dispatch(editShares(payload));
          else dispatch(addShares(payload));
          if (!_isEmpty(updatedOfficialData)) {
            officialFormContext.setFieldValue("beneficialOwnershipForm", {
              ...officialFormContext.values?.beneficialOwnershipForm,
              directPercentShareholding: "",
              indirectPercentShareholding: "",
              directPercentVotingRights: payload.votingRights === 0 ? 0 : "",
              indirectPercentVotingRights: payload.votingRights === 0 ? 0 : "",
              dateOfBecomingBO: "",
            });
          }
          setAllocateSharesBtn(true);
          setEditableShareAlloc(null);
        }}
      >
        {({ setFieldValue: setShareFormValue, values, handleSubmit }) => {
          const selectedClassOfShares = nominalShareCapital.find(
            ({ sharesCategory }) => sharesCategory === values.classOfShares
          );

          useEffect(() => {
            setShareFormValue(
              "sharePremium",
              roundNumber(
                values?.sharePrice
                  ? values?.sharePrice - selectedClassOfShares?.sharesValue ?? 0
                  : 0
              )
            );
            setShareFormValue(
              "sharesValue",
              selectedClassOfShares?.sharesValue ?? 0
            );
          }, [values, selectedClassOfShares]);

          const availableShares =
            (selectedClassOfShares?.sharesNumber ?? 0) -
            (takenShares?.[values?.classOfShares] ?? 0);

          const handleClassOfShareChange = (option) => {
            setShareFormValue("classOfShares", option.value);
            setSelectedClassOfShare(option);
          };

          useEffect(() => {
            setValidationSchema(
              getValidationSchema({
                availableShares,
                allottedShares: values?.allottedShares ?? 0,
                sharesValue: selectedClassOfShares?.sharesValue ?? 0,
              }).schema
            );
          }, [
            availableShares,
            values?.allottedShares,
            selectedClassOfShares?.sharesValue,
          ]);

          const getAvailableSharesOptions = (takenShares) => {
            const availableOptions = [];
            nominalShareCapital.map((nominalShare) => {
              if (
                (nominalShare?.sharesNumber ?? 0) -
                  (takenShares?.[nominalShare?.sharesCategory] ?? 0) >
                0
              ) {
                availableOptions.push({
                  label: nominalShare.sharesCategory,
                  value: nominalShare.sharesCategory,
                });
              }
            });
            return availableOptions.filter(
              (option) =>
                !alreadyTakenClassOfShares.includes(option.value) ||
                (editableShareAlloc &&
                  option.value === editableShareAlloc?.classOfShares)
            );
          };

          return (
            <Form>
              <div className="space-y-6">
                <Field
                  label="Class Of Shares"
                  value={selectedClassOfShare}
                  name="classOfShares"
                  component={ReactSelectWithFormik}
                  options={getAvailableSharesOptions(takenShares)}
                  errorPath="classOfShares"
                  placeholder="Select class of shares"
                  onChange={handleClassOfShareChange}
                />
                <hr className="my-2" />
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      component={Input}
                      label="Number of shares allotted"
                      type="number"
                      name="allottedShares"
                      placeholder="Add number of shares. Eg 100"
                      disabled={!selectedClassOfShares?.sharesCategory}
                    />
                    <h6>
                      Shares available to allot:{" "}
                      {roundNumber(
                        availableShares - (values?.allottedShares ?? 0)
                      )}
                    </h6>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      component={Input}
                      label="Number of shares fully paid up"
                      type="number"
                      value={values?.paidUpShares?.toString()}
                      name="paidUpShares"
                      placeholder="Add number of shares. Eg 100"
                      disabled={!selectedClassOfShares?.sharesCategory}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      component={Input}
                      label="Amount to be paid per share"
                      type="number"
                      name="sharePrice"
                      placeholder="Add amount no less than 1"
                      disabled={!selectedClassOfShares?.sharesValue}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      component={Input}
                      label="Premium in paid share"
                      type="number"
                      name="sharePremium"
                      placeholder="0"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-2 space-x-2">
                <Button
                  onClick={handleCancel}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sflPrimary hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {editableShareAlloc ? "Update" : "Submit"}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ShareAllocationSelection;
