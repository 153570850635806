import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { Formik, Field } from "formik";

import OfficialShareAllocation from "./OfficialShareAllocation";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import {
  getCompanySecretaryDocumentData,
  getDocumentData,
} from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import Tabs from "components/lib/Shared/Tabs";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";
import CommentsList from "components/lib/Global/CommentsList";
import { addComment, editComment } from "state/slices/comment";
import { updateCompanyOfficialAsync } from "state/slices/tables/companyOfficialsSlice";
import { useToast } from "hooks/useToast";
import { getFormFields } from "utils";
import { getAllBeneficialOwners } from "state/slices/tables/beneficialOwnersTableSlice";
import { setShares } from "state/slices/tables/sharesAllocationTable";
import OfficialBeneficialOwnership from "./OfficialBeneficialOwnership";
import { resetSharesTable } from "state/slices/tables/sharesAllocationTable";
import { useJointShareholders } from "hooks/useJointShareholders";
import { editJointShareholder } from "state/slices/tables/jointShareholderTable";

function SubRowAsync(props) {
  const {
    visibleColumns,
    row,
    handleBackBtn,
    syncChangesToBOTab,
    currentTab = null,
  } = props;
  const history = useHistory();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch();

  const isViewMode = history.location.pathname.split("/").pop() === "view";

  const { comments } = useSelector((state) => state.commentsSlice);
  const currentUser = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);
  const isApplicant = currentUser?._id === company?.createdBy?._id;
  const { beneficialOwners } = useSelector(
    (state) => state.beneficialOwnersTableSlice
  );
  const stakeholderComments = comments.filter(
    (comment) =>
      comment?.officialId &&
      comment.officialId === row.original._id &&
      comment?.tabName &&
      comment.tabName === currentTab
  );

  const generalInformationComments = stakeholderComments.filter(
    (comment) =>
      comment.formName === "identificationDetails" ||
      comment.formName === "names"
  );
  const residentialDetailsComments = stakeholderComments.filter(
    (comment) =>
      comment.formName === "postalAddress" ||
      comment.formName === "electronicAddress" ||
      comment.formName === "residentialAddressDetails" ||
      comment.formName === "isNonResident"
  );
  const officialShareAllocationComments = stakeholderComments.filter(
    (comment) => comment.formName === "officialShareAllocation"
  );
  const officialBeneficialOwnershipComments = stakeholderComments.filter(
    (comment) => comment.formName === "beneficialOwnershipForm"
  );
  const fileUploadComments = stakeholderComments.filter(
    (comment) => comment.formName === "uploadDocuments"
  );

  const tabs = useMemo(() => {
    let tempTabs = [];
    if (
      ["Director", "Authorized Person", "Company Secretary"].includes(
        row.original.designation
      )
    ) {
      tempTabs = [
        {
          id: "generalInformation",
          label: "General information",
          commentsCount: generalInformationComments?.length,
        },
        {
          id: "residentialDetails",
          label: "Address details",
          commentsCount: residentialDetailsComments?.length,
        },
        {
          id: "uploadDocuments",
          label: "File Uploads",
          commentsCount: fileUploadComments?.length,
        },
      ];
    } else if (row.original.designation === "Joint Shareholder") {
      const isSeniorJointShareholder = row.index === 0;
      tempTabs = [
        {
          id: "generalInformation",
          label: "General information",
          commentsCount: generalInformationComments?.length,
        },
        {
          id: "residentialDetails",
          label: "Address details",
          commentsCount: residentialDetailsComments?.length,
        },
        isSeniorJointShareholder
          ? {
              id: "officialShareAllocation",
              label: "Share information",
              commentsCount: officialShareAllocationComments?.length,
            }
          : null,
        isSeniorJointShareholder
          ? {
              id: "officialBeneficialOwnership",
              label: "Beneficial ownership",
              commentsCount: officialBeneficialOwnershipComments?.length,
            }
          : null,
        {
          id: "uploadDocuments",
          label: "File Uploads",
          commentsCount: fileUploadComments?.length,
        },
      ].filter(Boolean);
    } else {
      tempTabs = [
        {
          id: "generalInformation",
          label: "General information",
          commentsCount: generalInformationComments?.length,
        },
        {
          id: "residentialDetails",
          label: "Address details",
          commentsCount: residentialDetailsComments?.length,
        },
        {
          id: "officialShareAllocation",
          label: "Share information",
          commentsCount: officialShareAllocationComments?.length,
        },
        {
          id: "officialBeneficialOwnership",
          label: "Beneficial ownership",
          commentsCount: officialBeneficialOwnershipComments?.length,
        },
        {
          id: "uploadDocuments",
          label: "File Uploads",
          commentsCount: fileUploadComments?.length,
        },
      ];
    }
    if (currentTab === "beneficialOwnership") {
      tempTabs = [
        {
          id: "generalInformation",
          label: "General information",
          commentsCount: generalInformationComments?.length,
        },
        {
          id: "residentialDetails",
          label: "Address details",
          commentsCount: residentialDetailsComments?.length,
        },
        {
          id: "officialBeneficialOwnership",
          label: "Beneficial ownership",
          commentsCount: officialBeneficialOwnershipComments?.length,
        },
        {
          id: "uploadDocuments",
          label: "File Uploads",
          commentsCount: fileUploadComments?.length,
        },
      ];
    }
    return tempTabs;
  }, [
    row.original.designation,
    row.index,
    currentTab,
    generalInformationComments?.length,
    residentialDetailsComments?.length,
    fileUploadComments?.length,
    officialShareAllocationComments?.length,
    officialBeneficialOwnershipComments?.length,
  ]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { toast } = useToast(5000);
  const { syncBeneficialOwnersWithJointShareholders } = useJointShareholders();

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  const handleSaveComment = async (data) => {
    if (!company?._id) {
      toast(
        "error",
        "Something went wrong please reload the page and try again."
      );
      return;
    }
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            company: company._id,
            officialId: row.original._id,
            tabName: currentTab,
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setShares(row.original.sharesAllotted ?? []));
    return () => {
      dispatch(resetSharesTable());
    };
  }, [dispatch, row.original.sharesAllotted]);

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  let dateOfBirth = _get(row.original.identificationDetails, "dateOfBirth");
  if (dateOfBirth) dateOfBirth = format(new Date(dateOfBirth), "yyyy/MM/dd");
  else dateOfBirth = "N/A";

  const [acceptingData, setAcceptingData] = useState(false);

  const officialIndex =
    currentTab === "stakeholderTab"
      ? props.officialIndex || row.index
      : beneficialOwners.findIndex(
          (official) => official._id === row.original._id
        );

  const officialSliceName =
    currentTab === "stakeholderTab"
      ? `companyOfficialsSlice.companyOfficials.items.${officialIndex}`
      : `beneficialOwnersTableSlice.beneficialOwners.${officialIndex}`;

  const companyOfficialEffectKey =
    currentTab === "stakeholderTab"
      ? "updateCompanyOfficialState"
      : "updateBeneficialOfficialState";

  const acceptData = async () => {
    if (!company?._id) {
      toast(
        "error",
        "Something went wrong please reload the page and try again."
      );
      return;
    }
    try {
      setAcceptingData(true);
      const updateOfficialResponse = await dispatch(
        updateCompanyOfficialAsync({
          officialId: row.original._id,
          data: {
            inviteStatus: "Approved",
            companyId: company?._id,
            idType: row?.original?.idType,
            initiatedToCompanyRegNumber: row.original.names.companyRegNumber,
          },
        })
      );
      if (syncChangesToBOTab) {
        dispatch(editJointShareholder(updateOfficialResponse.payload.official));
        syncBeneficialOwnersWithJointShareholders();
      }
      await dispatch(getAllBeneficialOwners({ companyId: company?._id }));
      row.toggleRowExpanded();
    } catch (error) {
    } finally {
      setAcceptingData(false);
    }
  };

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "generalInformation" && (
          <>
            {generalInformationComments &&
              generalInformationComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={generalInformationComments}
                    toggleCommentStatus
                    applicantReviewer
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments: {generalInformationComments.length} of{" "}
                    {generalInformationComments.length}
                  </p>
                </div>
              )}
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType: row.original.idType,
                formType: "names",
                formValues: row.original.names,
              })}
              resolvedFields={_get(row.original.names, "resolvedFields")}
              addReviewComment
              formName="names"
              formHelperKey="names"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.names`}
              formId={_get(row.original.names, "_id")}
              comments={generalInformationComments.filter(
                (comment) => comment.formName === "names"
              )}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType: row?.original?.idType,
                formType: "identificationDetails",
                formValues: row?.original?.identificationDetails,
              })}
              resolvedFields={_get(
                row.original.identificationDetails,
                "resolvedFields"
              )}
              addReviewComment
              formName="identificationDetails"
              formHelperKey="identificationDetails"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.identificationDetails`}
              formId={_get(row.original.identificationDetails, "_id")}
              comments={generalInformationComments.filter(
                (comment) => comment.formName === "identificationDetails"
              )}
              files={row?.original?.fileUploads ?? {}}
            />
            {[
              "Local Company",
              "Foreign Company",
              "Other e.g Parastatals",
            ].includes(row?.original?.idType) && (
              <FormFieldsCard
                handleSaveComment={handleSaveComment}
                addReviewComment
                formName="identificationDetails"
                formHelperKey="identificationDetails"
                formId={_get(row.original.identificationDetails, "_id")}
                comments={generalInformationComments.filter(
                  (comment) => comment.formName === "identificationDetails"
                )}
                updateEffectKey={companyOfficialEffectKey}
                updateEffectPath={`${officialSliceName}.identificationDetails`}
                resolvedFields={_get(
                  row.original.identificationDetails,
                  "resolvedFields"
                )}
                fields={getFormFields({
                  idType: row?.original?.idType,
                  formType: "contactPersonInfo",
                  formValues: row?.original?.identificationDetails,
                })}
              />
            )}
          </>
        )}
        {selectedTab.id === "residentialDetails" && (
          <>
            {residentialDetailsComments &&
              residentialDetailsComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={residentialDetailsComments}
                    toggleCommentStatus
                    applicantReviewer
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments: {residentialDetailsComments.length} of{" "}
                    {residentialDetailsComments.length}
                  </p>
                </div>
              )}
            {row?.original?.idType === "Kenyan Citizen" && (
              <FormFieldsCard
                handleSaveComment={handleSaveComment}
                fields={[
                  {
                    key: "Non-Resident",
                    name: "isNonResident",
                    value: row.original?.isNonResident ?? "N/A",
                  },
                ]}
                resolvedFields={_get(row.original, "resolvedFields")}
                addReviewComment
                formName="isNonResident"
                formHelperKey={
                  currentTab === "stakeholderTab"
                    ? INDIVIDUALS.includes(row?.original?.idType)
                      ? "individualOfficial"
                      : "corporateOfficial"
                    : "beneficialOwner"
                }
                updateEffectKey={companyOfficialEffectKey}
                updateEffectPath={officialSliceName}
                formId={_get(row.original, "_id")}
                comments={residentialDetailsComments.filter(
                  (comment) => comment.formName === "isNonResident"
                )}
              />
            )}
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType:
                  row?.original?.isNonResident &&
                  row?.original?.isNonResident === "Yes"
                    ? "Foreigner"
                    : row?.original?.idType,
                formType: "postalAddress",
                formValues: row?.original?.postalAddress,
              })}
              resolvedFields={_get(
                row.original.postalAddress,
                "resolvedFields"
              )}
              addReviewComment
              formName="postalAddress"
              formHelperKey="postalAddress"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.postalAddress`}
              formId={_get(row.original.postalAddress, "_id")}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "postalAddress"
              )}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType: row?.original?.idType,
                formType: "electronicAddress",
                formValues: row?.original?.electronicAddress,
              })}
              resolvedFields={_get(
                row.original.electronicAddress,
                "resolvedFields"
              )}
              addReviewComment
              formName="electronicAddress"
              formHelperKey="electronicAddress"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.electronicAddress`}
              formId={_get(row.original.electronicAddress, "_id")}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "electronicAddress"
              )}
            />
            <FormFieldsCard
              handleSaveComment={handleSaveComment}
              fields={getFormFields({
                idType:
                  row?.original?.isNonResident &&
                  row?.original?.isNonResident === "Yes"
                    ? "Foreigner"
                    : row?.original?.idType,
                formType: "residentialAddressDetails",
                formValues: row?.original?.residentialAddressDetails,
              })}
              resolvedFields={_get(
                row.original.residentialAddressDetails,
                "resolvedFields"
              )}
              addReviewComment
              formName="residentialAddressDetails"
              formHelperKey="residentialAddressDetails"
              updateEffectKey={companyOfficialEffectKey}
              updateEffectPath={`${officialSliceName}.residentialAddressDetails`}
              formId={_get(row.original.residentialAddressDetails, "_id")}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "residentialAddressDetails"
              )}
            />
          </>
        )}
        {selectedTab.id === "officialShareAllocation" && (
          <OfficialShareAllocation
            row={row}
            isViewMode={isViewMode}
            officialSliceName={officialSliceName}
            companyOfficialEffectKey={companyOfficialEffectKey}
            officialShareAllocationComments={officialShareAllocationComments}
          />
        )}
        {selectedTab.id === "officialBeneficialOwnership" && (
          <OfficialBeneficialOwnership
            row={row}
            officialBeneficialOwnershipComments={
              officialBeneficialOwnershipComments
            }
            companyOfficialEffectKey={companyOfficialEffectKey}
            officialSliceName={officialSliceName}
            handleSaveComment={handleSaveComment}
            beneficialOwners={beneficialOwners}
          />
        )}
        {selectedTab.id === "uploadDocuments" && (
          <>
            {fileUploadComments && fileUploadComments.length > 0 && (
              <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                <CommentsList
                  comments={fileUploadComments}
                  toggleCommentStatus
                  applicantReviewer
                />
                <p className="mt-6 text-sm text-primary">
                  Comments: {fileUploadComments.length} of{" "}
                  {fileUploadComments.length}
                </p>
              </div>
            )}
            <div className="px-8 pt-5 pb-6 mt-6 bg-white">
              <h3 className="mb-2 text-lg">Uploaded incorporation forms</h3>
              <Formik
                enableReinitialize
                initialValues={{
                  fileUploads: row.original.fileUploads,
                }}
                onSubmit={() => null}
              >
                {() => {
                  return (
                    <div className="pl-4">
                      <Field
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={
                          row.original.designation === "Company Secretary"
                            ? getCompanySecretaryDocumentData(
                                row.original.idType
                              )
                            : getDocumentData(row.original.idType)
                        }
                        resolvedFields={_get(row.original, "resolvedFields")}
                        onlyPreview
                        addReviewComment
                        handleSaveComment={handleSaveComment}
                        formName="uploadDocuments"
                        formHelperKey={
                          currentTab === "stakeholderTab"
                            ? INDIVIDUALS.includes(row?.original?.idType)
                              ? "individualOfficial"
                              : "corporateOfficial"
                            : "beneficialOwner"
                        }
                        updateEffectKey={companyOfficialEffectKey}
                        updateEffectPath={officialSliceName}
                        formId={_get(row.original, "_id")}
                        resolveAllFieldsCheckbox
                        fileUploadComments={fileUploadComments}
                      />
                    </div>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
        <div className="flex justify-between">
          {!isFirstStep && (
            <button
              onClick={goToPrevStep}
              className="flex items-start float-right py-2 font-semibold rounded text-primary mt-7"
            >
              <span className="flex items-center mr-4 h-7">
                <ChevronLeftIcon
                  className="flex-shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
              </span>
              <p className="font-semibold">Go back</p>
            </button>
          )}
          {isLastStep ? (
            <button
              disabled={acceptingData}
              onClick={() =>
                stakeholderComments.length === 0
                  ? acceptData()
                  : row.toggleRowExpanded()
              }
              className="flex items-center px-4 py-2 mb-2 font-semibold text-white rounded bg-green-focused mt-7 disabled:cursor-not-allowed"
            >
              {acceptingData ? (
                <>
                  <SpinnerIcon />
                  Accepting...
                </>
              ) : (
                <p>
                  {isViewMode
                    ? "Close"
                    : stakeholderComments.length === 0
                    ? "Accept Data"
                    : isApplicant
                    ? "Submit for correction"
                    : "Confirm details"}
                </p>
              )}
              {!isViewMode && !acceptingData && (
                <span className="flex items-center ml-4 text-white h-7">
                  <ChevronRightIcon
                    className="flex-shrink-0 w-5 h-5"
                    aria-hidden="true"
                  />
                </span>
              )}
            </button>
          ) : (
            <button
              onClick={goToNextStep}
              className="flex items-start float-right py-2 ml-auto font-semibold rounded text-primary mt-7"
            >
              <p className="font-semibold">Review next step </p>
              <span className="flex items-center ml-4 h-7">
                <ChevronRightIcon
                  className="flex-shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
              </span>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
}

export default SubRowAsync;
