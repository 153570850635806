import React from "react";
import _get from "lodash/get";

const ValidationError = (props) => {
  const { errors, touched, name } = props;

  const errorMessage = _get(errors, name.split("."), "");
  const isTouched = _get(touched, name.split("."), false);

  const showError = errorMessage && isTouched;

  if (!showError) return null;

  return (
    <>
      <div className="text-red-500 flex items-center py-2">
        <svg
          aria-hidden="true"
          className="flex-shrink-0 inline w-5 h-5 mr-1"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>

        <p className="text-sm">{errorMessage}</p>
      </div>
    </>
  );
};

export default ValidationError;
