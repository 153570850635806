import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactTable from "components/lib/Global/ReactTable";
import DeleteButtonWithConfirmation from "components/lib/Shared/DeleteButtonWithConfirmation";
import { getFullName } from "utils";
import { updateDiscountAssigneeSlice } from "state/slices/discountAssignee";
import ConfirmDialog from "components/lib/Shared/ConfirmDialog";
import SearchInput from "components/lib/Shared/SearchInput";

const AssignedTable = () => {
  const dispatch = useDispatch();
  const { discountAssignees, discountAssigneesToDelete } = useSelector(
    (state) => state.discountAssigneeSlice
  );
  const { modalRow } = useSelector((state) => state.modalsSlice);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const handleDeleteDiscountAssignees = async (row) => {
    dispatch(
      updateDiscountAssigneeSlice({
        discountAssignees: {
          items: discountAssignees.items.filter(
            (discountAssignee) => discountAssignee._id !== row._id
          ),
          totalCount: discountAssignees.totalCount - 1,
        },
        discountAssigneesToDelete: [...discountAssigneesToDelete, row._id],
      })
    );
  };

  const columns = [
    {
      Header: "Name",
      Cell: ({ row }) => {
        const { original } = row;
        if (original.user) return getFullName(original.user.names);
        if (original.organization)
          return getFullName(original.organization.names);
        if (original.company) return original.company.registrationName;
        return "N/A";
      },
    },
    {
      Header: "Id Type",
      Cell: ({ row: { original } }) => {
        if (original.user) return original.user.idType;
        if (original.organization) return original.organization.idType;
        if (original.company) return original.company.type;
        return "N/A";
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <DeleteButtonWithConfirmation
            confirmationModalName="delete_discount_assignee_confirmation_modal"
            btnClasses="items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:cursor-not-allowed"
            row={row.original}
          />
        </div>
      ),
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full bg-white shadow">
        <div className="w-full">
          <ReactTable
            columns={columns}
            data={discountAssignees.items}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={discountAssignees?.items?.length}
            setCurrentPage={setCurrentPage}
            headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
            headingStyle="pl-4 text-left text-white"
            dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          />
        </div>
      </div>
      <ConfirmDialog
        name="delete_discount_assignee_confirmation_modal"
        onConfirm={() => handleDeleteDiscountAssignees(modalRow)}
      >
        Are you sure you want to permanently delete this entry?
      </ConfirmDialog>
    </>
  );
};

export default AssignedTable;
