import { useMemo, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { PlusSmIcon, XIcon } from "@heroicons/react/outline";

import ReactTable from "components/lib/Global/ReactTable";
import { getFullName } from "utils";
import SubRowAsync from "./SubRowAsync";
import Checkbox from "components/lib/Shared/Checkbox";
import options from "components/PLCTABS/SFLdata/selectOptions";

const AssignPermission = () => {
  const [filters, setFilters] = useState([]);

  const { companyOfficials, currentUserRow } = useSelector(
    (state) => state.companyDashboardSlice
  );

  const filteredOfficials = companyOfficials.items.filter(
    (official) =>
      official._id !== currentUserRow._id &&
      filters.includes(official.designation)
  );

  const columns = useMemo(
    () => [
      {
        Header: () => "Full Name",
        id: "fullName",
        accessor: "fullName",
        Cell: ({ row }) => getFullName(row.original.names) || "N/A",
      },
      {
        Header: () => "ID Type",
        id: "ID Type",
        accessor: "idType",
      },
      {
        Header: "Role",
        Cell: ({ row: { original } }) => original?.role?.join(", ") ?? "",
      },
      {
        id: "expander", // It needs an ID
        Cell: ({ row, rows }) => {
          const onClick = () => {
            const alreadyExpandedRow = rows.find((row) => row.isExpanded);
            if (
              alreadyExpandedRow?.getToggleRowExpandedProps &&
              alreadyExpandedRow?.isExpanded
            ) {
              alreadyExpandedRow?.getToggleRowExpandedProps().onClick();
            }
            if (alreadyExpandedRow?.id !== row.id) {
              row.getToggleRowExpandedProps().onClick();
            }
          };

          return (
            <span
              className="text-sm font-normal text-primary"
              {...row.getToggleRowExpandedProps()}
              onClick={onClick}
            >
              {row.isExpanded ? (
                <XIcon className="inline w-4 h-4 mr-1 text-tertiary" />
              ) : (
                <PlusSmIcon className="inline w-4 h-4 mr-1 text-tertiary" />
              )}
            </span>
          );
        },
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },
    ],
    []
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  useEffect(() => {
    setFilters(options.companyOfficials.map((role) => role.value));
  }, [options.companyOfficials]);

  const handleFilterChange = (e) => {
    if (e.target.checked) {
      setFilters([...filters, e.target.value]);
    } else {
      setFilters(filters.filter((filter) => filter !== e.target.value));
    }
  };

  return (
    <>
      {companyOfficials.items.length > 0 ? (
        <>
          <p className="text-gray-500 text-sm mb-1">Filters</p>
          <div className="flex space-x-2 my-2">
            {options.companyOfficials.map((role) => (
              <Checkbox
                key={role.value}
                name={role.label}
                label={role.label}
                checked={filters.includes(role.value)}
                onChange={handleFilterChange}
              />
            ))}
          </div>
          <hr />
          <p className="text-gray-500 text-sm mb-1 mt-4">Officials</p>
          <hr />
          <div className="mt-6 w-full bg-white shadow">
            <ReactTable
              columns={columns}
              data={filteredOfficials}
              renderRowSubComponent={renderRowSubComponent}
              headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
              headingStyle="pl-4 text-left"
              dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
              dataRowCellStyle="pl-4 leading-snug py-2"
            />
          </div>
        </>
      ) : (
        <div className="text-center">
          <svg
            className="w-12 h-12 mx-auto text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No Officials found
          </h3>
        </div>
      )}
    </>
  );
};

export default AssignPermission;
