/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable no-alert */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ExclamationIcon } from "@heroicons/react/solid";
import _values from "lodash/values";
import _cloneDeep from "lodash/cloneDeep";
import _uniqBy from "lodash/uniqBy";
import _omit from "lodash/omit";
import { PlusIcon } from "@heroicons/react/solid";

import CorporateSelection from "./CorporateSelection";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import { openModal } from "state/slices/modals";
import CorporateShareholderTable from "./CorporateShareholderTable";
import {
  INDIVIDUALS,
  CORPORATES,
  CORPORATES_ID_TYPES,
  CORPORATE_MAX_VALUE,
  CORPORATE_SHAREHOLDERS_LIMIT,
} from "../../SFLdata/config/constants";
import {
  addBeneficialOwnerAsync,
  deleteBeneficialOwnerAsync,
  getAllBeneficialOwners,
  updateDropdownEntryStatusAsync,
} from "state/slices/tables/beneficialOwnersTableSlice";
import { clearEmpties, getFullName, roundNumber } from "utils";
import {
  deleteCorporateShareholder,
  resetCorporateShareholderTable,
} from "state/slices/tables/corporateShareholderTableSlice";
import {
  setCurrentJSGroup,
  setJointShareholders,
  deleteJointShareholder,
  resetJointShareholderTable,
} from "state/slices/tables/corporateJointShareholderTableSlice";
import { deleteFiles } from "utils/filesUtils";
import { useParams } from "react-router-dom";
import Button from "components/lib/Shared/Button";

const CorporateComponent = (props) => {
  const {
    shareholderWithBO,
    selectedShareholder,
    idType,
    setShowBeneficialOwnersBtn,
    setSelectedDropdownBO,
  } = props;
  const [mode, setMode] = useState({ id: "", editing: false });
  const [subIDType, setSubIDType] = useState("");
  const dispatch = useDispatch();
  const [currentOfficial, setCurrentOfficial] = useState({});
  const [allottedShares, setAllottedShares] = useState(0);
  const [allottedVotingRights, setAllottedVotingRights] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const { company } = useSelector((state) => state.companySlice);
  const params = useParams();
  const companyId = company?._id ?? params?.companyId;

  const [showCorporateBtn, setShowCorporateBtn] = useState(true);
  const { shareholders: jointShareholders, currentJSGroup } = useSelector(
    (state) => state
  ).corporateJointShareholderTableSlice;

  const { modals } = useSelector((state) => state.modalsSlice);
  const corporateShareholders = useSelector(
    (state) => state.corporateShareholderTableSlice
  ).filter(
    (shareholder) => shareholder.shareholderWithBO === shareholderWithBO
  );
  const { beneficialOwners } = useSelector(
    (state) => state.beneficialOwnersTableSlice
  );
  const beneficialOwnersDropdown = beneficialOwners.filter(
    (bo) => bo.showInBoDropdown
  );

  const handleEditAndDeleteJointShareholder = (row) => {
    let jointShareholderForEdit = jointShareholders.filter(
      (js) =>
        js.JSGroupID === row.JSGroupID && js.idType !== "Joint Shareholder"
    );
    if (jointShareholderForEdit.length === 0) {
      jointShareholderForEdit = beneficialOwners.filter(
        (official) =>
          !official.linkToCompany &&
          official.JSGroupID === row.JSGroupID &&
          (row?.id !== official?.id || row?._id !== official?._id) &&
          official.idType !== "Joint Shareholder"
      );
    }
    jointShareholderForEdit.map((js) => dispatch(deleteJointShareholder(js)));
    dispatch(setCurrentJSGroup(row));
    dispatch(setJointShareholders(jointShareholderForEdit));
    setSubIDType("Joint Shareholder");
    setShowCorporateBtn(false);
  };

  const isMinorityRowExists = () =>
    corporateShareholders.find((co) => co?.remainingMinorityShareholders > 0);

  const handleEdit = (row) => {
    if (
      !row.remainingMinorityShareholders &&
      corporateShareholders.length > CORPORATE_SHAREHOLDERS_LIMIT &&
      isMinorityRowExists() &&
      !row?.isReadOnly
    ) {
      alert("Please first remove the minority row");
      return;
    }
    if (row.idType === "Joint Shareholder") {
      handleEditAndDeleteJointShareholder(row);
      return;
    }
    if (!row.isViewOnly && !row?.isReadOnly) setIsViewOnly(false);
    else setIsViewOnly(true);
    setCurrentOfficial(row);
    setMode({ editing: true, id: row?.id || row?._id });
    dispatch(openModal({ modalName: "corporate_edit_modal" }));
  };

  const handleDelete = async (row) => {
    if (
      !row.remainingMinorityShareholders &&
      corporateShareholders.length > CORPORATE_SHAREHOLDERS_LIMIT &&
      isMinorityRowExists()
    ) {
      alert("Please first remove the minority row");
      return;
    }
    if (row.idType === "Joint Shareholder") {
      handleEditAndDeleteJointShareholder(row);
      return;
    }
    // const filesToDelete = _values(row?.fileUploads ?? {})
    // if (filesToDelete.length > 0) await deleteFiles(filesToDelete)
    dispatch(deleteCorporateShareholder(row));
    setShowCorporateBtn(true);
    setCurrentOfficial({});
  };

  useEffect(() => {
    if (!currentJSGroup?.id || !currentJSGroup?._id) {
      setSubIDType("");
    }
  }, [currentJSGroup]);

  useEffect(() => {
    setShowCorporateBtn(true);
  }, [idType]);

  useEffect(() => {
    if (
      !modals.includes("corporate_edit_modal") &&
      (currentOfficial?.id || currentOfficial?._id)
    ) {
      setMode({ id: "", editing: false });
      setCurrentOfficial({});
      setShowCorporateBtn(true);
    }
  }, [modals, currentOfficial]);

  useEffect(() => {
    const calculateAllottedShares = () => {
      let { tempAllottedShares, tempAllottedVotingRights } =
        corporateShareholders.reduce(
          (acc, bo) => ({
            tempAllottedShares:
              acc.tempAllottedShares +
              Number(
                bo.beneficialOwnershipForm?.directPercentShareholding ?? 0
              ) +
              Number(
                bo.beneficialOwnershipForm?.indirectPercentShareholding ?? 0
              ) +
              Number(bo?.totalMinorityShareholdingPercent ?? 0),
            tempAllottedVotingRights:
              acc.tempAllottedVotingRights +
              Number(
                bo.beneficialOwnershipForm?.directPercentVotingRights ?? 0
              ) +
              Number(
                bo.beneficialOwnershipForm?.indirectPercentVotingRights ?? 0
              ) +
              Number(bo?.totalMinorityVotingPercent ?? 0),
          }),
          { tempAllottedShares: 0, tempAllottedVotingRights: 0 }
        );

      if (currentOfficial?.id || currentOfficial?._id) {
        const { beneficialOwnershipForm } = currentOfficial;
        tempAllottedShares -=
          Number(beneficialOwnershipForm?.directPercentShareholding ?? 0) +
            Number(beneficialOwnershipForm?.indirectPercentShareholding ?? 0) ||
          (currentOfficial?.totalMinorityShareholdingPercent ?? 0);

        tempAllottedVotingRights -=
          Number(beneficialOwnershipForm?.directPercentVotingRights ?? 0) +
            Number(beneficialOwnershipForm?.indirectPercentVotingRights ?? 0) ||
          (currentOfficial?.totalMinorityVotingPercent ?? 0);
      }

      setAllottedShares(roundNumber(tempAllottedShares));
      setAllottedVotingRights(roundNumber(tempAllottedVotingRights));
    };
    calculateAllottedShares();
  }, [currentOfficial, corporateShareholders, mode.editing]);

  const handleCorporateTableSubmit = async () => {
    setIsSubmitting(true);
    const addToBOTableOrDropdown = async (shareholder) => {
      const getCorporateShareholders = (parentCorporate) => {
        const currentShareholderTopTenShareholder = [
          ...beneficialOwners,
          ...beneficialOwnersDropdown,
        ].filter(
          (bo) =>
            (bo?.shareholderWithBO &&
              bo?.shareholderWithBO === parentCorporate._id) ||
            (bo?.linkToCompany && bo.linkToCompany === parentCorporate._id)
        );
        return _uniqBy(currentShareholderTopTenShareholder, (official) => {
          if (official?.JSGroupID) return official.JSGroupID;
          else return official._id;
        });
      };

      const currentShareholderTopTenShareholder =
        getCorporateShareholders(shareholder);

      const calculateAllottedShares = (corporateShareholders = []) => {
        const { tempAllottedShares, tempAllottedVotingRights } =
          corporateShareholders.reduce(
            (acc, bo) => ({
              tempAllottedShares:
                acc.tempAllottedShares +
                Number(
                  bo.beneficialOwnershipForm?.directPercentShareholding ?? 0
                ) +
                Number(
                  bo.beneficialOwnershipForm?.indirectPercentShareholding ?? 0
                ) +
                Number(bo?.totalMinorityShareholdingPercent ?? 0),
              tempAllottedVotingRights:
                acc.tempAllottedVotingRights +
                Number(
                  bo.beneficialOwnershipForm?.directPercentVotingRights ?? 0
                ) +
                Number(
                  bo.beneficialOwnershipForm?.indirectPercentVotingRights ?? 0
                ) +
                Number(bo?.totalMinorityVotingPercent ?? 0),
            }),
            { tempAllottedShares: 0, tempAllottedVotingRights: 0 }
          );
        return { tempAllottedShares, tempAllottedVotingRights };
      };

      const { tempAllottedShares, tempAllottedVotingRights } =
        calculateAllottedShares(currentShareholderTopTenShareholder);

      const {
        idType: shareholderIDType,
        corporateIdType,
        isEstateDistributionDetermined,
      } = shareholder;
      const {
        directPercentShareholding,
        indirectPercentShareholding,
        directPercentVotingRights,
        indirectPercentVotingRights,
        directRightRemoveDirector,
        indirectRightRemoveDirector,
        directCompanyControlRight,
        indirectCompanyControlRight,
      } = shareholder?.beneficialOwnershipForm ?? {};

      const isDirectBeneficiary =
        directPercentShareholding >= 10 ||
        directPercentVotingRights >= 10 ||
        directRightRemoveDirector === "yes" ||
        directCompanyControlRight === "yes";
      const isIndirectBeneficiary =
        indirectPercentShareholding >= 10 ||
        indirectPercentVotingRights >= 10 ||
        indirectRightRemoveDirector === "yes" ||
        indirectCompanyControlRight === "yes";
      const isStateOwnedEnterprise =
        corporateIdType === "State Owned Enterprise";
      const payload = _cloneDeep(shareholder);

      if (shareholderIDType === "Joint Shareholder") {
        let filteredJointShareholders = jointShareholders.filter(
          (js) => js.JSGroupID === shareholder.JSGroupID
        );
        if (filteredJointShareholders.length === 0) {
          filteredJointShareholders = beneficialOwners.filter(
            (bo) =>
              bo.JSGroupID === shareholder.JSGroupID &&
              (bo?.id !== shareholder?.id || bo?._id !== shareholder?._id)
          );
        }
        if (filteredJointShareholders.length > 0) {
          await Promise.all(
            beneficialOwners.map((bo) => {
              if (bo.JSGroupID === shareholder.JSGroupID) {
                return dispatch(
                  deleteBeneficialOwnerAsync({
                    beneficialOwnerId: bo?._id,
                    companyId,
                    hardDelete: !bo?.isReadOnly,
                  })
                );
              }
              return Promise.resolve();
            })
          );
          payload.JSCombinedName = "";
          if (
            isDirectBeneficiary ||
            directPercentShareholding > 0 ||
            directPercentVotingRights > 0
          ) {
            payload["showInBoTable"] = true;
          } else payload["showInBoTable"] = false;

          if (
            isIndirectBeneficiary ||
            indirectPercentShareholding > 0 ||
            indirectPercentVotingRights > 0
          ) {
            payload["showInBoDropdown"] = true;
          } else payload["showInBoDropdown"] = false;
          const combinedEntryResponse = await dispatch(
            addBeneficialOwnerAsync({ companyId, data: clearEmpties(payload) })
          );
          await Promise.all(
            filteredJointShareholders.map(async (JS) => {
              const boJS = _cloneDeep(JS);
              boJS.JSGroupID = shareholder.JSGroupID;
              boJS.beneficialOwnershipForm =
                shareholder?.beneficialOwnershipForm;
              boJS.proRata =
                directPercentShareholding || indirectPercentShareholding;
              boJS.shareholderWithBO =
                combinedEntryResponse?.payload?.beneficialOwner?._id;
              boJS.isReportable = false;
              if (selectedShareholder.isReportable && isDirectBeneficiary)
                boJS.isReportable = true;
              if (
                !isDirectBeneficiary &&
                !directPercentShareholding > 0 &&
                !directPercentVotingRights > 0
              )
                boJS.hidden = true;
              else boJS.hidden = false;
              boJS["showInBoTable"] = true;
              return dispatch(
                addBeneficialOwnerAsync({ companyId, data: clearEmpties(boJS) })
              );
            })
          );
        }
      }

      if (shareholderIDType === "Estate") {
        if (isDirectBeneficiary || isIndirectBeneficiary)
          payload.isMeetingThreshold = true;
        else payload.isMeetingThreshold = false;
      }
      let isPayloadBeneficiary = false;
      if (
        selectedShareholder.isMeetingThreshold &&
        shareholderIDType !== "Estate" &&
        (isDirectBeneficiary || isIndirectBeneficiary)
      )
        payload.isReportable = true;
      if (
        (INDIVIDUALS.includes(shareholderIDType) &&
          (isDirectBeneficiary || !isIndirectBeneficiary)) ||
        isStateOwnedEnterprise ||
        isEstateDistributionDetermined === "No"
      ) {
        payload["showInBoTable"] = true;
        isPayloadBeneficiary = true;
      } else payload["showInBoTable"] = false;

      if (
        [...INDIVIDUALS, ...CORPORATES].includes(shareholderIDType) &&
        (isIndirectBeneficiary || isEstateDistributionDetermined === "Yes") &&
        !isStateOwnedEnterprise
      ) {
        payload.showInBoDropdown = true;
        isPayloadBeneficiary = true;
      } else {
        payload.showInBoDropdown = false;
        isPayloadBeneficiary = true;
      }
      if (payload?.remainingMinorityShareholders > 0) {
        payload.showInBoDropdown = false;
        isPayloadBeneficiary = true;
      }
      if (
        currentShareholderTopTenShareholder.length > 0 &&
        tempAllottedShares === CORPORATE_MAX_VALUE &&
        tempAllottedVotingRights === CORPORATE_MAX_VALUE
      ) {
        payload.hasAccess = true;
      }
      if (
        isPayloadBeneficiary === true &&
        shareholderIDType !== "Joint Shareholder"
      ) {
        await dispatch(
          addBeneficialOwnerAsync({
            companyId,
            data: clearEmpties(
              _omit(payload, ["_id", "__v", "createdAt", "updatedAt"])
            ),
          })
        );
      }
    };

    await Promise.all(corporateShareholders.map(addToBOTableOrDropdown));
    // delete previous top ten shareholders
    await Promise.all(
      beneficialOwners.map((bo) => {
        if (bo.shareholderWithBO === selectedShareholder._id) {
          return dispatch(
            deleteBeneficialOwnerAsync({
              beneficialOwnerId: bo._id,
              companyId,
              hardDelete: !bo?.isReadOnly,
            })
          );
        }
        return Promise.resolve();
      })
    );

    await Promise.all(
      beneficialOwnersDropdown.map((bo) => {
        if (bo.shareholderWithBO === selectedShareholder._id) {
          return dispatch(
            updateDropdownEntryStatusAsync({
              beneficialOwnerId: bo._id,
              data: { showInBoDropdown: false },
            })
          );
        }
        return Promise.resolve();
      })
    );

    dispatch(resetJointShareholderTable());
    await dispatch(
      updateDropdownEntryStatusAsync({
        beneficialOwnerId: selectedShareholder._id,
        data: { showInBoDropdown: false },
      })
    );
    setShowBeneficialOwnersBtn(true);
    const isInBOTable = beneficialOwners.find(
      (bo) => bo._id === shareholderWithBO
    );
    if (!isInBOTable && shareholderWithBO.link) {
      selectedShareholder.showInBoTable = true;
      await dispatch(
        addBeneficialOwnerAsync({
          companyId,
          data: clearEmpties(selectedShareholder),
        })
      );
    }
    dispatch(resetCorporateShareholderTable());
    setSelectedDropdownBO(false);
    setIsSubmitting(false);
    await dispatch(getAllBeneficialOwners({ companyId }));
  };

  const isEveryShareholderReadOnly =
    corporateShareholders.length > 0 &&
    corporateShareholders.every((shareholder) => shareholder.isReadOnly);

  const showSubmitListButton =
    !isEveryShareholderReadOnly &&
    (!currentJSGroup?.JSGroupID || showCorporateBtn) &&
    ((allottedShares === CORPORATE_MAX_VALUE &&
      allottedVotingRights === CORPORATE_MAX_VALUE) ||
      (allottedVotingRights === CORPORATE_MAX_VALUE &&
        idType === "Company Limited By Guarantee/NGO"));

  return (
    <div className="space-y-4">
      <h2 className="mt-2 text-lg font-medium leading-6 text-gray-900">
        Add the shareholders of the corporate company:{" "}
        {getFullName(selectedShareholder.names)}
      </h2>

      <div className="p-4 rounded-md bg-yellow-50">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="w-5 h-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Attention needed
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                Please add upto ten top shareholders of the company and
                thereafter update the particulars of the beneficial owners for
                those shareholders who meet of the set criteria
              </p>
            </div>
          </div>
        </div>
      </div>
      {corporateShareholders.length > 0 && (
        <CorporateShareholderTable
          corporateShareholders={corporateShareholders}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          allottedShares={allottedShares}
          allottedVotingRights={allottedVotingRights}
        />
      )}
      {(currentOfficial?.id || currentOfficial?._id) && (
        <ModalWrapper
          name="corporate_edit_modal"
          title={
            isViewOnly
              ? "View Corporate Shareholder"
              : "Edit Corporate Shareholder"
          }
          maxWidth="sm:max-w-fit"
        >
          <CorporateSelection
            currentOfficial={currentOfficial}
            corporateIdType={currentOfficial.corporateIdType || idType}
            shareholderWithBO={shareholderWithBO}
            selectedShareholder={selectedShareholder}
            setShowCorporateBtn={setShowCorporateBtn}
            allottedShares={allottedShares}
            allottedVotingRights={allottedVotingRights}
            mode={mode}
            isViewOnly={isViewOnly}
          />
        </ModalWrapper>
      )}
      {!showCorporateBtn && !mode.editing ? (
        <CorporateSelection
          currentOfficial={currentOfficial}
          corporateIdType={currentOfficial.corporateIdType || idType}
          shareholderWithBO={shareholderWithBO}
          selectedShareholder={selectedShareholder}
          setShowCorporateBtn={setShowCorporateBtn}
          allottedShares={allottedShares}
          allottedVotingRights={allottedVotingRights}
          mode={mode}
          subIDType={subIDType}
          jointShareholders={jointShareholders}
        />
      ) : (
        (allottedShares < CORPORATE_MAX_VALUE ||
          allottedVotingRights < CORPORATE_MAX_VALUE) &&
        (allottedVotingRights !== CORPORATE_MAX_VALUE ||
          idType !== "Company Limited By Guarantee/NGO") &&
        corporateShareholders.length <= CORPORATE_SHAREHOLDERS_LIMIT && (
          <button
            onClick={() => {
              setShowCorporateBtn(false);
            }}
            className="inline-flex items-center justify-center space-x-2 text-sm font-medium rounded-md text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusIcon className="w-6 h-6" aria-hidden="true" />
            {corporateShareholders.length === CORPORATE_SHAREHOLDERS_LIMIT
              ? " Add Minority"
              : CORPORATES_ID_TYPES[idType]}
          </button>
        )
      )}
      {showSubmitListButton && (
        <Button onClick={handleCorporateTableSubmit} isLoading={isSubmitting}>
          Submit List
        </Button>
      )}
    </div>
  );
};

export default CorporateComponent;
