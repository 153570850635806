import _has from "lodash/has";

export const getPaginationParams = (
  officials,
  maxTableBodyHeight,
  tableBodyRef,
  getPopulatedRowNode = () => null,
  key
) => {
  const hiddenTempTableBodyRef = document.getElementById(
    "tempTableForRegisters"
  );
  let currentPage = 1;
  let rowsPerPage = {};

  const nextPageTableBody = document.createElement("tbody");
  nextPageTableBody.setAttribute(
    "class",
    "divide-y divide-gray-200 invisible absolute -top-[100rem]"
  );
  let tableIndex = key
    ? `${key}-${currentPage.toString()}`
    : currentPage.toString();
  nextPageTableBody.dataset.tableIndex = tableIndex;
  hiddenTempTableBodyRef.appendChild(nextPageTableBody);

  nextPageTableBody.style.width = `${tableBodyRef.current.clientWidth}px`;
  nextPageTableBody.style.display = "table";

  // Calculate initial rows per page
  officials.map((official, index) => {
    const tableBody = document.querySelector(
      `[data-table-index="${tableIndex}"]`
    );
    const currentTableRowNode = getPopulatedRowNode(official, index);

    tableBody.appendChild(currentTableRowNode);
    if (!_has(rowsPerPage[currentPage], "start")) {
      rowsPerPage[currentPage] = {
        start: index,
      };
    }

    if (tableBody.clientHeight < maxTableBodyHeight || index === 0) {
      rowsPerPage[currentPage]["numberOfRows"] =
        (rowsPerPage[currentPage]?.["numberOfRows"] || 0) + 1;
    } else {
      rowsPerPage[currentPage]["end"] = index;
      currentPage++;
      tableIndex = key
        ? `${key}-${currentPage.toString()}`
        : currentPage.toString();
      rowsPerPage[currentPage] = {
        start: index,
        numberOfRows: 1,
      };
      const nextPageTableBody = document.createElement("tbody");
      nextPageTableBody.setAttribute(
        "class",
        "divide-y divide-gray-200 invisible absolute -top-[100rem]"
      );
      nextPageTableBody.dataset.tableIndex = tableIndex;
      nextPageTableBody.style.width = `${tableBodyRef.current.clientWidth}px`;
      nextPageTableBody.style.display = "table";
      nextPageTableBody.appendChild(currentTableRowNode);
      hiddenTempTableBodyRef.appendChild(nextPageTableBody);
    }
  });

  if (rowsPerPage[currentPage]?.["start"]) {
    rowsPerPage[currentPage] = {
      ...rowsPerPage[currentPage],
      end: officials.length,
    };
  }

  return { rowsPerPage, lastPage: currentPage };
};
