/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { PlusIcon, MinusIcon } from "@heroicons/react/outline";
import Badge from "components/lib/Shared/Badge";

import { getReviewers, addReviewer } from "state/slices/tables/reviewersTable";
import Table from "components/lib/Global/ReactTable";
import AddReviewerForm from "./AddReviewerForm";
import { getFullName } from "utils";
import { INDIVIDUALS } from "../SFLdata/config/constants";
import AssignReviewerSubRow from "./AssignReviewerSubRow";
import Button from "components/lib/Shared/Button";
import { useToast } from "hooks/useToast";
import AvatarIcon from "components/lib/Shared/Icons/AvatarIcon";
import { getCompanyNameWithSuffix } from "utils/company";
import {
  notifyApplicantOnSubmissionByACoApplicantAsync,
  sendApplicationToDirectorsReviewAsync,
} from "state/slices/company";
import { useSocket } from "context/SocketContext";
import { Constants } from "config/constants";
import { setCookie } from "utils/cookie";

const ReviewerTab = ({ handleBackBtn }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { beneficialOwners } = useSelector(
    (state) => state.beneficialOwnersTableSlice
  );
  const beneficialOwnersDropdown = beneficialOwners.filter(
    (bo) => bo.showInBoDropdown
  );
  const [boTabValidationError] = useState(
    "Allocate indirect beneficial ownership interest on the previous tab."
  );
  const [showAddReviewerForm, setShowAddReviewerForm] = useState(false);
  const [representativeOf, setRepresentativeOf] = useState(null);
  const [parentReviewerEmail, setParentReviewerEmail] = useState("");

  const dispatch = useDispatch();
  const { toast } = useToast();
  const history = useHistory();
  const params = useParams();

  const socket = useSocket();
  const currentUser = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);
  const reviewersSlice = useSelector((state) => state.reviewersTable.reviewers);
  const assignedReviewersEmails = reviewersSlice.map(
    (reviewer) => reviewer.email
  );
  const companyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );
  const { reviewers } = useSelector((state) => state.reviewersTable);

  const isApplicant = company?.isApplicant;
  const isCoApplicant = company?.isCoApplicant;

  const reviewerOfficials = companyOfficials.filter(
    (official) =>
      ["Director", "Director Shareholder"].includes(official.designation) ||
      official.isDirector === "Yes"
  );

  useEffect(() => {
    if (company?._id) {
      dispatch(getReviewers({ companyId: company?._id }));
    }
  }, [company]);

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <AssignReviewerSubRow
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  const handleAddReviewer = async (reviewer) => {
    if (reviewer.email.toLowerCase() === parentReviewerEmail.toLowerCase()) {
      toast(
        "error",
        "Email should be different from the original reviewer's email address"
      );
      return;
    }
    const companyId = company?._id ?? params?.companyId;
    await dispatch(
      addReviewer({
        companyId,
        data: { ...reviewer, representativeOf, sendNotification: false },
      })
    );
    socket.emit("changed-in-assign-reviewer-tab", {
      companyId,
      currentSocket: window.sessionStorage.getItem("socketId"),
      toastData: {
        type: "info",
        message: `${currentUser.names.firstName} has made some changes in Assign Reviewer`,
      },
    });
    setShowAddReviewerForm(false);
  };

  const companyName = getCompanyNameWithSuffix(company);

  const candidatesThatHaveAlternateReviewer = reviewers.map(
    (reviewer) => reviewer.representativeOf
  );

  const uniquePendingEmails = [];
  const pendingReviewInvitation = reviewerOfficials
    .map(({ _id, idType, names, electronicAddress, identificationDetails }) => {
      const emailAddress = INDIVIDUALS.includes(idType)
        ? electronicAddress?.emailAddress
        : identificationDetails?.emailAddress;
      if (
        uniquePendingEmails.includes(emailAddress) ||
        candidatesThatHaveAlternateReviewer.includes(_id)
      )
        return {};
      uniquePendingEmails.push(emailAddress);

      return {
        name: getFullName(names),
        companyName,
        email: emailAddress,
        officialId: _id,
        companyId: company?._id,
      };
    })
    .filter(({ email }) => email && !assignedReviewersEmails.includes(email));

  reviewersSlice.map((reviewer) => {
    if (!uniquePendingEmails.includes(reviewer.email)) {
      uniquePendingEmails.push(reviewer.email);
    }
    if (reviewer.representativeOf && !reviewer.notificationSent) {
      pendingReviewInvitation.push(reviewer);
    }
  });

  const notificationSent =
    reviewerOfficials.length > 0 && pendingReviewInvitation.length === 0;

  const handleSendApplicationToDirectors = async () => {
    setIsSubmitting(true);
    const companyId = company?._id ?? params?.companyId;
    await dispatch(sendApplicationToDirectorsReviewAsync({ companyId }));
    setIsSubmitting(false);
    toast(
      "success",
      "Your application has been successfully submitted to the directors."
    );
    history.push("/");
  };

  const handleSendNotification = async () => {
    if (notificationSent || beneficialOwnersDropdown.length !== 0) return null;
    if (!["Correction", "Directors Review"].includes(company?.status)) {
      setIsSubmitting(true);
      const emailAlreadySent = [];
      const companyId = company?._id ?? params?.companyId;
      await Promise.all(
        pendingReviewInvitation.map((reviewer) => {
          const addReviewerPromise = dispatch(
            addReviewer({
              companyId,
              data: {
                ...reviewer,
                companyName,
                sendNotification: !emailAlreadySent.includes(reviewer.email),
              },
            })
          );
          emailAlreadySent.push(reviewer.email);
          return addReviewerPromise;
        })
      );
      if (isCoApplicant) {
        await dispatch(
          notifyApplicantOnSubmissionByACoApplicantAsync({ companyId })
        );
      }
      setIsSubmitting(false);
    }
    toast(
      "success",
      `Your application has been successfully submitted to the ${
        isCoApplicant ? "applicant" : "directors"
      }.`
    );
    history.push("/");
    setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
  };

  const handleAddAlternateReviewerClick = (row) => {
    setRepresentativeOf(row?._id);
    setParentReviewerEmail(row.electronicAddress.emailAddress);
    setShowAddReviewerForm(true);
  };

  const columns = [
    {
      id: "expander", // Make sure it has an ID
      Header: "Name",
      Cell: ({
        row: {
          getToggleRowExpandedProps,
          isExpanded,
          original: {
            _id,
            names,
            electronicAddress,
            fileUploads,
            idType,
            identificationDetails,
          },
        },
      }) => {
        let fullName = getFullName(names) || "N/A";
        const hasChildReviewer = reviewers.find(
          (reviewer) => reviewer?.representativeOf === _id
        );
        const emailAddress = INDIVIDUALS.includes(idType)
          ? electronicAddress?.emailAddress
          : identificationDetails?.emailAddress;
        return (
          <div className="flex items-center space-x-1 w-52">
            {hasChildReviewer && (
              <span
                className="text-sm font-normal text-primary"
                {...getToggleRowExpandedProps()}
              >
                {isExpanded ? (
                  <MinusIcon className="w-4 h-4" aria-hidden="true" />
                ) : (
                  <PlusIcon className="w-4 h-4" aria-hidden="true" />
                )}
              </span>
            )}
            <div
              className={`flex-shrink-0 ${hasChildReviewer ? "pl-6" : "pl-6"}`}
            >
              {fileUploads?.passportPhoto ? (
                <img
                  className="w-8 h-8 rounded-full"
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${fileUploads.passportPhoto}`}
                  alt={fullName}
                />
              ) : (
                <div className="w-8 h-8 rounded-full">
                  <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <AvatarIcon />
                  </div>
                </div>
              )}
            </div>
            <div className="px-3">
              <h1 className="mb-2 font-bold">{fullName}</h1>
              <p>{emailAddress}</p>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Role",
      Cell: ({ row }) => {
        if (
          row?.original?.isDirector === "Yes" &&
          row.original?.designation === "Joint Shareholder"
        )
          return "Director";
        return row.original?.designation;
      },
    },
    {
      Header: "Status",
      Cell: ({ row }) => {
        const hasChildReviewer = reviewers.find(
          (reviewer) => reviewer?.representativeOf === row.original._id
        );
        if (hasChildReviewer) return "";
        const { electronicAddress, idType, identificationDetails } =
          row.original;
        const emailAddress = INDIVIDUALS.includes(idType)
          ? electronicAddress?.emailAddress
          : identificationDetails?.emailAddress;
        return (
          <Badge
            bgColor="bg-green-400"
            textColor="text-gray-800"
            text={
              assignedReviewersEmails.includes(emailAddress)
                ? "Assigned"
                : "To be Assigned"
            }
          />
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const hasChildReviewer = reviewers.find(
          (reviewer) => reviewer?.representativeOf === row.original?._id
        );
        if (hasChildReviewer) return "";
        return (
          <Button
            onClick={() => handleAddAlternateReviewerClick(row.original)}
            disabled={["Correction", "Directors Review"].includes(
              company?.status
            )}
            variant="text"
          >
            <PlusIcon className="w-4 h-4" aria-hidden="true" />
            <span>Add alternate reviewer</span>
          </Button>
        );
      },
    },
  ];

  if (showAddReviewerForm) {
    return (
      <AddReviewerForm
        handleBackBtn={() => setShowAddReviewerForm(false)}
        handleAddReviewer={handleAddReviewer}
      />
    );
  }

  return (
    <div>
      {reviewerOfficials.length > 0 ? (
        <div className="w-full bg-white shadow">
          <div className="w-full">
            <Table
              columns={columns}
              data={reviewerOfficials}
              renderRowSubComponent={renderRowSubComponent}
              headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
              headingStyle="pl-4 text-left"
              dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
              dataRowCellStyle="pl-4"
            />
          </div>
        </div>
      ) : (
        <p>No reviewer available</p>
      )}
      <div className="flex justify-between py-3">
        <Button
          onClick={handleBackBtn}
          variant="backBtn"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Go back
        </Button>
        {reviewerOfficials.length > 0 && (
          <Button
            onClick={
              notificationSent && isApplicant
                ? handleSendApplicationToDirectors
                : handleSendNotification
            }
            disabled={
              isSubmitting ||
              (notificationSent && !isApplicant) ||
              beneficialOwnersDropdown.length !== 0
            }
            isLoading={isSubmitting}
            loadingText="Sending..."
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
          >
            {notificationSent
              ? isApplicant
                ? "Submit to directors"
                : "Notifications Sent"
              : isCoApplicant
              ? "Submit to the Applicant"
              : "Send notification"}
          </Button>
        )}
      </div>
      {boTabValidationError && beneficialOwnersDropdown.length !== 0 && (
        <p className="ml-auto text-red-500 max-w-max">{boTabValidationError}</p>
      )}
    </div>
  );
};

export default ReviewerTab;
