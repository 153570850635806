import React, { useState, useEffect, useMemo } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/lib/Global/ReactTable";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { delay, getFullName } from "utils";
import CompanyOfficialSubRow from "./CompanyOfficialSubRow";
import SearchInput from "components/lib/Shared/SearchInput";
import { getCompanyOfficialsAsync } from "state/slices/companyDashboard";
import AvatarIcon from "components/lib/Shared/Icons/AvatarIcon";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";

function StakeholderTable({
  companyId,
  data,
  totalCount,
  currentTab,
  currentSubTab,
  isLoading,
  goToPrevStep = () => null,
}) {
  const [expandedRow, setExpandedRow] = useState({});
  const [initialRows, setInitialRows] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
  const dispatch = useDispatch();

  const companyDashboardSlice = useSelector(
    (state) => state.companyDashboardSlice
  );

  const { getCompanyOfficialsStatus } = companyDashboardSlice;

  const transformSharesAllotted = (sharesAllotted) =>
    sharesAllotted
      .map(
        ({ classOfShares, allottedShares }) =>
          `<div>${classOfShares}: ${allottedShares}</div>`
      )
      .join("");

  const columns = useMemo(() => {
    const tempTabs = [
      {
        Header: () => "Full Name",
        id: "Name",
        accessor: "fullName",
        Cell: ({ row: { original } }) => {
          const { names, fileUploads } = original;
          const fullName = getFullName(names) || "N/A";
          if (
            CORPORATES.includes(original.idType) ||
            (original.designation === "Joint Shareholder" &&
              original.isDirector !== "Yes")
          ) {
            return <h1>{fullName}</h1>;
          }
          return (
            <div className="flex items-center space-x-1">
              <div className={`flex-shrink-0`}>
                {fileUploads?.passportPhoto ? (
                  <img
                    className="w-8 h-8 rounded-full"
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${fileUploads?.passportPhoto}`}
                    alt={fullName}
                  />
                ) : (
                  <div className="w-8 h-8 rounded-full">
                    <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                      <AvatarIcon />
                    </div>
                  </div>
                )}
              </div>
              <div className="px-3">
                <h1 className="">{fullName}</h1>
              </div>
            </div>
          );
        },
        // We can render something different for subRows
        SubCell: (cellProps) => <>{cellProps.value}</>,
      },
      {
        Header: () => "ID Type",
        id: "ID Type",
        accessor: "idType",
      },
      {
        // Make an expander cell
        Header: () => "Action", // No header
        id: "expander", // It needs an ID
        Cell: ({ row, ...best }) => {
          const originalRow = row.original;
          let handleViewRow = async () => {
            const shouldExpand = !row.isExpanded;
            row.toggleAllRowsExpanded(false); // Close all rows
            await delay(10);
            setInitialRows(best.initialRows);
            setExpandedRow(shouldExpand ? row : null);
            if (shouldExpand) {
              row.getToggleRowExpandedProps().onClick();
            }
          };
          if (
            !originalRow?.linkToCompany &&
            !originalRow?.shareholderWithBO &&
            currentTab === "beneficialOwnershipTab"
          ) {
            handleViewRow = async () => {
              goToPrevStep();
              await delay(); // wait for the stakeholders tab to be mounted
              let companyShareholderEditBtn = null;
              if (originalRow?.JSGroupID)
                companyShareholderEditBtn = document.querySelector(
                  `[id="${originalRow?.JSGroupID}"]`
                );
              else if (originalRow?._id && !originalRow.JSGroupID)
                companyShareholderEditBtn = document.querySelector(
                  `[id="${originalRow?._id}"]`
                );
              if (companyShareholderEditBtn) {
                companyShareholderEditBtn.click();
              }
            };
          }

          return (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span
              className="text-sm font-normal text-primary"
              {...row.getToggleRowExpandedProps()}
              onClick={handleViewRow}
              id={
                !originalRow.linkToCompany &&
                currentTab === "beneficialOwnershipTab"
                  ? null
                  : originalRow._id
              }
            >
              {row.isExpanded ? (
                <ChevronUpIcon className="w-6 h-6" />
              ) : (
                <ChevronDownIcon className="w-6 h-6" />
              )}
            </span>
          );
        },
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },
    ];
    if (currentTab === "stakeholderTab") {
      // add designation & share allocation columns for stakeholder tab
      tempTabs.splice(
        2,
        0,
        {
          Header: () => "Designation",
          id: "Designation",
          Cell: ({ row: { original } }) => {
            return Array.isArray(original.fullName)
              ? "Joint Shareholder"
              : original.isDirector === "Yes"
              ? "Director"
              : original.designation ?? "";
          },
        },
        {
          Header: () => "Shares Alloted",
          id: "Shares alloted",
          Cell: ({ row }) => {
            if (
              row.original?.isDirector === "Yes" &&
              !Array.isArray(row.original?.fullName)
            )
              return "";
            return (
              <span
                className="space-y-1"
                dangerouslySetInnerHTML={{
                  __html: transformSharesAllotted(
                    row.original?.sharesAllotted ?? []
                  ),
                }}
              />
            );
          },
        }
      );
    }
    return tempTabs;
  }, [setExpandedRow, currentTab]);

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <CompanyOfficialSubRow
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        currentTab={currentTab}
        initialRows={initialRows}
      />
    ),
    [initialRows]
  );

  useEffect(() => {
    if (debouncedSearchTerm) setCurrentPage(1);
    dispatch(
      getCompanyOfficialsAsync({
        companyId,
        queryParams: {
          search: debouncedSearchTerm.trim(),
          limit: rowsPerPage,
          skip: (currentPage - 1) * rowsPerPage,
          order: "desc",
          isDashboard: true,
        },
      })
    );
  }, [companyId, currentPage, debouncedSearchTerm, rowsPerPage]);

  useEffect(() => {
    if (expandedRow?.isExpanded) expandedRow?.toggleRowExpanded();
  }, [currentTab, currentSubTab]);

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full overflow-x-auto">
        {data?.length > 0 ? (
          <Table
            columns={columns}
            data={data}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={totalCount}
            setCurrentPage={setCurrentPage}
            paginate
            dataRowCellStyle="whitespace-normal"
            renderRowSubComponent={renderRowSubComponent}
            isLoading={getCompanyOfficialsStatus.status === "loading"}
          />
        ) : (
          <>
            {getCompanyOfficialsStatus.status === "loading" ? (
              <div className="flex items-center justify-center z-10 mt-10">
                <SpinnerIcon className="text-gray-400" />
              </div>
            ) : (
              <p className="text-xl text-center text-primary">
                No record found
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default StakeholderTable;
