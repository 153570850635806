import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import corporateDataService from "services/corporate";

export const getCorporatesForDashboard = createAsyncThunk(
  "corporatesForDashboardSlice/getCorporatesForDashboard",
  async (queryParams) => {
    const res = await corporateDataService.getCorporatesForDashboard(
      queryParams
    );
    return res.data;
  }
);

export const getTransactionCorporate = createAsyncThunk(
  "corporatesForDashboardSlice/get",
  async ({ corporateId }, thunkAPI) => {
    try {
      const res = await corporateDataService.get(corporateId);
      if (res) {
        return res.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  corporates: {
    items: [],
    totalCount: 0,
  },
  transactionCorporate: {},
  getCorporatesForDashboardStatus: {
    status: "idle",
    error: false,
  },
  getTransactionCorporateStatus: {
    status: "idle",
    error: false,
  },
};

const corporatesForDashboardSlice = createSlice({
  name: "corporatesForDashboardSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    resetTransactionCorporate: (state) => {
      state.transactionCorporate = {};
    },
  },
  extraReducers: {
    [getCorporatesForDashboard.pending]: (state) => {
      state.getCorporatesForDashboardStatus.status = "loading";
      state.getCorporatesForDashboardStatus.error = false;
    },
    [getCorporatesForDashboard.fulfilled]: (state, action) => {
      state.getCorporatesForDashboardStatus.status = "idle";
      state.getCorporatesForDashboardStatus.error = false;
      state.corporates = action.payload.corporates;
    },
    [getCorporatesForDashboard.rejected]: (state, action) => {
      state.getCorporatesForDashboardStatus.status = "failed";
      state.getCorporatesForDashboardStatus.error = action.payload.messageText;
    },

    [getTransactionCorporate.pending]: (state) => {
      state.getTransactionCorporateStatus.status = "loading";
      state.getTransactionCorporateStatus.error = false;
    },
    [getTransactionCorporate.fulfilled]: (state, action) => {
      state.getTransactionCorporateStatus.status = "idle";
      state.getTransactionCorporateStatus.error = false;
      state.transactionCorporate = action.payload.corporate;
    },
    [getTransactionCorporate.rejected]: (state, action) => {
      state.getTransactionCorporateStatus.status = "failed";
      state.getTransactionCorporateStatus.error = action.payload.messageText;
    },
  },
});

export const { reset, resetTransactionCorporate } =
  corporatesForDashboardSlice.actions;
const { reducer } = corporatesForDashboardSlice;

export default reducer;
