import React from "react";
import { useHistory } from "react-router-dom";
import { Disclosure, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

import FolderIcon from "components/lib/Shared/Icons/Folder";
import LadderIcon from "components/lib/Shared/Icons/Ladder";
import PointerIcon from "components/lib/Shared/Icons/Pointer";
import Button from "components/lib/Shared/Button";
import { minuteBookSidebarItems } from "./minuteBookSidebarItems";

const SidebarRenderer = ({
  sidebarItem,
  selectedTab,
  sidebarItemIndex,
  handleTabClick,
}) => {
  if (sidebarItem.children?.length > 0) {
    return (
      <Disclosure
        key={sidebarItem.id}
        as="div"
        className={`${
          sidebarItemIndex !== 0 && !sidebarItem.parentCategory
            ? "border-t"
            : ""
        }`}
      >
        <Disclosure.Button
          className={`${
            !sidebarItem.parentCategory ||
            sidebarItem.parentCategory === "minuteBook"
              ? "font-medium text-sflPrimary"
              : ""
          } disabled:cursor-not-allowed flex items-center hover:text-sflPrimary cursor-pointer text-left`}
        >
          <p
            handleTabClick={handleTabClick}
            className={!sidebarItem.parentCategory ? "p-2" : ""}
          >
            {sidebarItem.label}
          </p>
        </Disclosure.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel as="ul" className="ml-3">
            {sidebarItem?.children?.map(
              (nestedSidebarItem, nestedChildrenIndex) => (
                <SidebarRenderer
                  key={`${nestedSidebarItem.label}_${nestedChildrenIndex}`}
                  sidebarItem={nestedSidebarItem}
                  sidebarItemIndex={nestedChildrenIndex}
                  selectedTab={selectedTab}
                  handleTabClick={handleTabClick}
                />
              )
            )}
          </Disclosure.Panel>
        </Transition>
      </Disclosure>
    );
  }

  return (
    <li
      key={sidebarItem.label}
      className={twMerge(
        sidebarItem.id === selectedTab.id ? "text-primary" : "text-gray-400",
        !sidebarItem.parentCategory ? "font-medium text-sflPrimary" : "mb-2 ",
        sidebarItemIndex !== 0 && !sidebarItem.parentCategory ? "border-t" : "",
        `font-medium ${
          sidebarItem.disabled
            ? "cursor-not-allowed text-gray-300/95"
            : "hover:text-sflPrimary cursor-pointer"
        }`
      )}
      onClick={() => handleTabClick(sidebarItem)}
    >
      <p className={!sidebarItem.parentCategory ? "p-2" : ""}>
        {sidebarItem.label}
      </p>{" "}
    </li>
  );
};

function MinuteBookSidebar({
  selectedTab,
  setSelectedTab,
  goToBusinessDetails,
}) {
  const history = useHistory();

  const handleTabClick = (sidebarItem) => {
    if (sidebarItem?.disabled) return null;
    if (sidebarItem?.href) return history.push(sidebarItem.href);
    else setSelectedTab(sidebarItem);
  };

  return (
    <div className="col-span-4 md:col-span-1">
      <div className="flex bg-gray-100">
        <div className="flex flex-col space-y-4 bg-white px-1 py-4">
          <Button overrideBaseStyles onClick={goToBusinessDetails}>
            <FolderIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
          <Button overrideBaseStyles onClick={goToBusinessDetails}>
            <LadderIcon
              className="w-5 h-5"
              aria-hidden="true"
              onClick={goToBusinessDetails}
            />
          </Button>
          <Button overrideBaseStyles onClick={goToBusinessDetails}>
            <PointerIcon
              className="w-5 h-5"
              aria-hidden="true"
              onClick={goToBusinessDetails}
            />
          </Button>
        </div>
        <ul className="p-2 text-xs w-full">
          {minuteBookSidebarItems.map((sidebarItem, sidebarItemIndex) => {
            return (
              <SidebarRenderer
                key={`${sidebarItem.label}_${sidebarItemIndex}`}
                sidebarItem={sidebarItem}
                sidebarItemIndex={sidebarItemIndex}
                selectedTab={selectedTab}
                handleTabClick={handleTabClick}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default MinuteBookSidebar;
