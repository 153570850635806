import React from "react";
import { useSelector } from 'react-redux'
import _get from 'lodash/get'

import FormFieldsCard from "components/lib/Global/FormFieldsCard";

const AddressParticulars = () => {
  const registeredOfficeSlice = useSelector(state => state.registeredOfficeSlice)
  const addressParticularsFormFields = [
    {
      key: "Name of Building",
      value: _get(registeredOfficeSlice, 'buildingName', 'N/A'),
    },
    {
      key: "Floor or Suite Number",
      value: _get(registeredOfficeSlice, 'floorNumber', 'N/A'),
    },
    {
      key: "Street Address",
      value: _get(registeredOfficeSlice, 'streetAddress', 'N/A'),
    },
    {
      key: "Postal Address",
      value: _get(registeredOfficeSlice, 'postalAddress', 'N/A'),
    },
    {
      key: "Postal Code",
      value: _get(registeredOfficeSlice, 'postalCode', 'N/A'),
    },
    {
      key: "Company Email Address",
      value: _get(registeredOfficeSlice, 'companyEmailAddress', 'N/A'),
    },
    {
      key: "Phone Number",
      value: _get(registeredOfficeSlice, 'mobileNumber', 'N/A'),
    },
    {
      key: "County",
      value: _get(registeredOfficeSlice, 'location.county', 'N/A'),
    },
    {
      key: "District",
      value: _get(registeredOfficeSlice, 'location.district', 'N/A'),
    },
    {
      key: "Locality",
      value: _get(registeredOfficeSlice, 'location.locality', 'N/A'),
    },
  ];

  return (
    <>
      <div className="flex my-10 align-middle">
        <div className="flex items-center form-check">
          <input
            className="float-left mt-1 mr-4 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border rounded appearance-none cursor-pointer form-check-input h-7 w-7 border-tertiary checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
            type="checkbox"
            defaultChecked={registeredOfficeSlice?.useSimpleFormationsOffice}
            id="flexCheckDefault"
          />
          <label
            className="inline-block form-check-label text-tertiary"
            htmlFor="flexCheckDefault"
          >
            Use Simple Formations office details as your registered
            business address in Kenya.
          </label>
        </div>
      </div>
      <FormFieldsCard fields={addressParticularsFormFields} />
    </>
  );
}

export default AddressParticulars

