import React from "react";
import { useSelector } from "react-redux";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import _sortBy from "lodash/sortBy";
import { ChevronDownIcon } from "@heroicons/react/solid";

import StakeholderTable from "components/PLCTABS/ApplicationReviewTab/CompanyOfficials/StakeholderTable";
import { getFullName } from "utils";

export default function StakeholderDetails({ isViewMode }) {
  const originalCompanyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );

  const companyOfficials = originalCompanyOfficials;

  const JSGroups = new Set();
  companyOfficials.forEach((item) => {
    if (item.JSGroupID) JSGroups.add(item.JSGroupID);
  });

  const allJS = [...JSGroups].map((id) => {
    const names = [];
    let combinedJS = {};
    const currentGroupOfficials = [];
    companyOfficials.forEach((official) => {
      if (official.JSGroupID === id) currentGroupOfficials.push(official);
    });
    // sorted by JS Group order
    const sortedJsOfficials = _sortBy(currentGroupOfficials, ["JSGroupOrder"]);
    sortedJsOfficials.forEach((official) => {
      // debugger;
      if (official.JSGroupID === id) {
        names.push(getFullName(official.names));
        if (official.sharesAllotted && official.sharesAllotted.length > 0)
          combinedJS.sharesAllotted = official.sharesAllotted;
        combinedJS = {
          ...combinedJS,
          ..._omit(_cloneDeep(official), ["sharesAllotted"]),
          designation: "Joint Shareholder",
          idType: "Joint Shareholder",
        };
        combinedJS.fullName = names.map((name, index) => {
          if (names.length === 2 && index === 0) {
            return `${name} & `;
          }
          if (names.length > 2 && index === names.length - 2) {
            return `${name} & `;
          }

          if (
            names.length > 2 &&
            index !== names.length - 2 &&
            index !== names.length - 1
          ) {
            return `${name}, `;
          }
          return name;
        });
      }
    });
    return combinedJS;
  });

  const data = [
    ...companyOfficials.filter(
      (item) =>
        (item.designation === "Joint Shareholder" &&
          item.isDirector === "Yes") ||
        item.designation !== "Joint Shareholder"
    ),
    ...allJS.filter((item) => !_isEmpty(item)),
  ];

  return (
    <div className="mt-6">
      <p className="font-semibold text-primary">Current appointments</p>
      <div className="flex items-end justify-between mb-10">
        <div className="relative text-gray-600">
          <button
            type="submit"
            className="absolute left-0 ml-2 transform -translate-y-1/2 top-1/2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="512"
              height="512"
              x="0"
              y="0"
              className="w-4 h-4 fill-current text-primary"
              enableBackground="new 0 0 56.966 56.966"
              version="1.1"
              viewBox="0 0 56.966 56.966"
              xmlSpace="preserve"
            >
              <path d="M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z"></path>
            </svg>
          </button>
          <input
            className="pr-20 text-sm bg-white border-2 border-gray-300 rounded h-9 px-7 focus:outline-none"
            name="search"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="flex items-center float-right mb-5">
        <p className="float-right text-sm text-primary">Row per page: 10</p>
        <ChevronDownIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
      </div>
      <StakeholderTable
        data={data}
        currentTab="stakeholderTab"
        isViewMode={isViewMode}
      />
    </div>
  );
}
