import { object, string, mixed } from "yup";

export const getCompanyDocumentsValidation = (field) => {
  return object().shape({
    kraPin:
      field.name === "kraPin"
        ? string()
            .trim("KRA PIN cannot include leading and trailing spaces")
            .strict(true)
            .min(11, "Must be exactly 11 characters")
            .max(11, "Must be exactly 11 characters")
            .required("KRA PIN is required")
        : string(),
    NSSFNo:
      field.name === "NSSFNo"
        ? string().required("NSSF NO is required")
        : string(),
    NHIFNo:
      field.name === "NHIFNo"
        ? string().required("NHIF NO is required")
        : string(),
    fileUploads: object().shape({
      kraPin:
        field.name === "KraPin"
          ? mixed().test(
              "length",
              "KRA PIN certificate is required",
              function (value) {
                if (!value && this.options.context.kraPin) {
                  return false;
                }
                return true;
              }
            )
          : mixed(),
      NSSFNo:
        field.name === "NSSFNo"
          ? mixed().required("NSSF NO certificate is required")
          : mixed(),
      NHIFNo:
        field.name === "NHIFNo"
          ? mixed().required("NHIF NO certificate is required")
          : mixed(),
    }),
  });
};
