import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PlusIcon } from "@heroicons/react/solid";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

import OwnershipSelection from "./OwnershipSelection";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import OwnersTable from "./OwnersTable";
import { openModal } from "state/slices/modals";
import { deleteOrganizationPartner } from "state/slices/tables/partnersSlice";
import Button from "components/lib/Shared/Button";
import ErrorBanner from "components/lib/Shared/ErrorBanner";
import {
  checkForRegistrar,
  validateOrganizationRestrictions,
} from "utils/organization";
import { Constants } from "config/constants";

const Ownership = ({ handleBackBtn, handleNextBtn }) => {
  const [mode, setMode] = useState({ id: "", editing: false });
  const dispatch = useDispatch();
  const params = useParams();
  const [currentOfficial, setCurrentOfficial] = useState({});
  const [showPartnerBtn, setShowPartnerBtn] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [formStepErrors, setFormStepErrors] = useState([]);
  const [showSelectRegistrarBtn, setShowSelectRegistrarBtn] = useState(false);
  const [registrarCandidates, setRegistrarCandidates] = useState([]);
  const [assigningRegistrar, setAssigningRegistrar] = useState(false);
  const cookieRole = Cookies.get("role");

  const { modals } = useSelector((state) => state.modalsSlice);
  const currentUser = useSelector((state) => state.userSlice);
  const partners = useSelector((state) => state.partnersSlice);
  const organization =
    useSelector((state) => state.organizationsSlice.organization) || {};

  const applicantOrganization = currentUser?.organizations?.find(
    ({ corporate, role }) =>
      role === 12 && corporate?._id === params?.corporateId
  );
  const isContactPerson =
    currentUser.email === organization.identificationDetails.emailAddress;
  const isApplicant = applicantOrganization || isContactPerson;

  const handleEdit = (row) => {
    if (!row.isViewOnly) setIsViewOnly(false);
    else setIsViewOnly(true);
    setCurrentOfficial(row);
    setMode({ editing: true, id: row?.id || row?._id });
    dispatch(openModal({ modalName: "partners_edit_modal" }));
  };

  const handleDelete = async (row) => {
    await dispatch(
      deleteOrganizationPartner({
        partnerId: row._id,
        idType: row.idType,
        corporateId: params.corporateId,
      })
    );
    setShowPartnerBtn(true);
    setCurrentOfficial({});
  };

  useEffect(() => {
    if (
      !modals.includes("partners_edit_modal") &&
      (currentOfficial?.id || currentOfficial?._id)
    ) {
      setMode({ id: "", editing: false });
      setCurrentOfficial({});
      setShowPartnerBtn(true);
    }
  }, [modals, currentOfficial]);

  const validateTab = () => {
    if (!isApplicant) handleNextBtn();
    setFormStepErrors([]);
    const { errors } = validateOrganizationRestrictions({
      partnershipType: organization?.partnershipType,
      partners,
    });
    if (errors.length === 0) handleNextBtn();
    else setFormStepErrors(errors);
  };

  useEffect(() => {
    if (isApplicant) {
      const { showSelectRegistrarBtn, registrarCandidates = [] } =
        checkForRegistrar({
          partnershipType: organization?.partnershipType,
          partners,
        });
      setShowSelectRegistrarBtn(showSelectRegistrarBtn);
      setRegistrarCandidates(registrarCandidates);
    }
  }, [partners, isApplicant]);

  useEffect(() => {
    if (formStepErrors.length > 0) {
      validateTab();
    }
  }, [formStepErrors.length, partners.length]);

  return (
    <div className="space-y-4">
      <ErrorBanner errors={formStepErrors} className="mb-2" />
      {partners.length === 0 && (
        <h2 className="mt-2 text-lg font-medium leading-6 text-gray-900">
          Add partners of the organization:
        </h2>
      )}
      {partners.length > 0 && showPartnerBtn && (
        <>
          {showSelectRegistrarBtn && !assigningRegistrar && (
            <div className="flex justify-end">
              <Button onClick={() => setAssigningRegistrar(true)}>
                Assign Registrar
              </Button>
            </div>
          )}
          <OwnersTable
            partners={partners}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            assigningRegistrar={assigningRegistrar}
            registrarCandidates={registrarCandidates}
          />
        </>
      )}
      {(currentOfficial?.id || currentOfficial?._id) && (
        <ModalWrapper
          name="partners_edit_modal"
          title="Edit Corporate Shareholder"
          maxWidth="sm:max-w-fit"
        >
          <OwnershipSelection
            currentOfficial={currentOfficial}
            setShowPartnerBtn={setShowPartnerBtn}
            mode={mode}
            isViewOnly={isViewOnly}
          />
        </ModalWrapper>
      )}
      {!showPartnerBtn && !mode.editing && (
        <OwnershipSelection
          currentOfficial={currentOfficial}
          setShowPartnerBtn={setShowPartnerBtn}
          mode={mode}
        />
      )}
      {showPartnerBtn && cookieRole !== Constants.COOKIE_ROLES.OFFICIAL && (
        <Button
          onClick={() => {
            setShowPartnerBtn(false);
          }}
          variant="text"
          preIcon={PlusIcon}
        >
          Add Partner
        </Button>
      )}
      {showPartnerBtn && (
        <div className="flex justify-between py-3">
          <Button variant="secondary" onClick={handleBackBtn}>
            Go back
          </Button>
          <Button onClick={validateTab} disabled={showSelectRegistrarBtn}>
            {cookieRole === Constants.COOKIE_ROLES.OFFICIAL
              ? "Next step"
              : "Save and proceed"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Ownership;
