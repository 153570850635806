import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";

import Button from "../Shared/Button";

function StepButtons({
  prevBtnLabel,
  prevBtnLink,
  nextBtnLabel,
  nextBtnLink,
  goToPrevStep,
  goToNextStep,
  isNextBtnLoading,
  isNextBtnDisabled,
  isPrevBtnDisabled,
  isLastStep,
  submitBtnDropdown,
  loadingText,
}) {
  const history = useHistory();
  const renderDropdown = isLastStep && submitBtnDropdown;

  return (
    <div className="flex justify-between">
      <Button
        onClick={() =>
          prevBtnLink ? history.push(prevBtnLink) : goToPrevStep()
        }
        variant="text"
        preIcon={ChevronLeftIcon}
        className="flex items-start py-2 font-semibold text-primary mt-7 disabled:shadow-none disabled:cursor-not-allowed"
        disabled={isPrevBtnDisabled}
      >
        <p className="font-semibold">{prevBtnLabel}</p>
      </Button>
      <div className="inline-flex rounded-md shadow-sm mt-auto">
        <Button
          onClick={() =>
            nextBtnLink ? history.push(nextBtnLink) : goToNextStep()
          }
          isLoading={isNextBtnLoading}
          postIcon={renderDropdown ? null : ChevronRightIcon}
          className={`relative inline-flex items-center px-4 text-white bg-green-focused disabled:bg-green-200 disabled:shadow-none disabled:cursor-not-allowed text-sm font-semibold ${
            renderDropdown ? "py-0 rounded-none rounded-l" : "py-2"
          }`}
          disabled={isNextBtnDisabled}
          loadingText={loadingText}
        >
          {nextBtnLabel}
        </Button>
        {renderDropdown ? submitBtnDropdown : null}
      </div>
    </div>
  );
}

export default StepButtons;
