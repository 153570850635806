import PlaceHolderTable from "components/AdminTableComponents/AdminTable";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import Sidebar from "components/Applications/Sidebar";
import HeroSection from "components/Applications/HeroSection";
import SearchInput from "components/lib/Shared/SearchInput";

export default function Corrected() {
  const applications = [];
  return (
    <DashboardWrapper>
      <div className="grid gap-10 py-10 mx-auto">
        <HeroSection />
        <div className="grid grid-cols-6 border-t border-gray">
          <div className="col-span-6 pr-8 md:col-span-1">
            <Sidebar />
          </div>
          <div className="col-span-6 md:border-l md:col-span-5 md:pl-11 pt-14 border-gray">
            <div className="mb-6">
              <h1 className="text-title5">Corrected</h1>
            </div>
            {applications.length > 0 ? (
              <>
                <div className="flex items-center justify-between mt-10">
                  <div>
                    <h2 className="font-semibold ">Filter by:</h2>
                  </div>
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <SearchInput placeholder="Search" />
                  </div>
                </div>
                <div>
                  <PlaceHolderTable />
                </div>
              </>
            ) : (
              <p className="text-2xl text-center text-primary">
                No applications available.
              </p>
            )}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
