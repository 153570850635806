/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { Field, useFormikContext } from "formik";

import Input from "components/lib/Shared/Input";
import Label from "components/lib/Shared/Label";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import ValidationError from "components/lib/Shared/ValidationError";
import { Constants } from "config/constants";
import { getEntityTypeOptions } from "utils";

// Identification Details for Local Company
const IdentificationDetailsOther = ({
  field,
  form,
  disabled,
  disableEntityType,
  designation,
  idType,
}) => {
  const { setFieldValue } = useFormikContext();
  const [selectedEntityType, setSelectedEntityType] = useState(() =>
    Constants.CORPORATE_BO_OPTIONS.find(
      (option) => option.value === field.value?.entityType
    )
  );

  const handleSelect = (type) => (option) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
    if (type === "entityType") {
      setSelectedEntityType({ label: option.label, value: option.value });
    }
  };

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  const entityTypeExistsInOptions = Constants.CORPORATE_BO_OPTIONS.find(
    (option) => option.value === field.value?.entityType
  );

  return (
    <>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Company's KRA PIN"
            name="companyKRAPIN"
            placeholder={`Enter company's KRA pin`}
            form={form}
            value={field.value?.companyKRAPIN ?? ""}
            errorPath="identificationDetails.companyKRAPIN"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Former Name(IF ANY)"
            name="companyFormerName"
            placeholder="Enter company former name"
            form={form}
            value={field.value?.companyFormerName ?? ""}
            errorPath="identificationDetails.companyFormerName"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Governing Law"
            name="governingLaw"
            placeholder="Enter governing law"
            form={form}
            value={field.value?.governingLaw ?? ""}
            errorPath="identificationDetails.governingLaw"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Country of Incorporation"
            name="countryOfIncorporation"
            placeholder="KENYA"
            form={form}
            value={"KENYA"}
            errorPath="identificationDetails.countryOfIncorporation"
            disabled
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          {!field.value?.entityType || entityTypeExistsInOptions ? (
            <>
              <Label htmlFor="entityType">Entity Type</Label>
              <Field
                name="entityType"
                component={ReactSelectWithFormik}
                options={getEntityTypeOptions(designation, idType)}
                isDisabled={disabled || disableEntityType}
                placeholder="Select entity type"
                className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="entityType"
                value={selectedEntityType}
                onChange={handleSelect("entityType")}
              />
            </>
          ) : (
            <Input
              label="Entity Type"
              name="entityType"
              form={form}
              value={field.value?.entityType ?? ""}
              errorPath="entityType"
              onBlur={field.onBlur}
              onChange={handleSetValue}
              disabled={disabled || disableEntityType}
            />
          )}
          <ValidationError
            errors={form.errors}
            touched={form.touched}
            name="entityType"
          />
        </div>
      </div>
    </>
  );
};

export default IdentificationDetailsOther;
