import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/lib/Shared/Button";
import CategoriesTable from "./Table";
import CategoryForm from "./Form";
import { getTemplateCategoriesAsync } from "state/slices/templateCategory";

const Categories = () => {
  const dispatch = useDispatch();
  const { templateCategories } = useSelector((state) => state.templateCategory);
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
  const [editableCategory, setEditableCategory] = useState({});

  useEffect(() => {
    dispatch(getTemplateCategoriesAsync());
  }, []);

  if (showAddCategoryForm) {
    return (
      <CategoryForm
        editableCategory={editableCategory}
        setShowAddCategoryForm={setShowAddCategoryForm}
        setEditableCategory={setEditableCategory}
      />
    );
  }

  const handleAddNewCategory = () => {
    setShowAddCategoryForm(true);
    setEditableCategory({});
  };

  const handleEditCategory = (category) => {
    setEditableCategory(category);
    setShowAddCategoryForm(true);
  };

  return (
    <div className="pl-4">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold text-sflPrimary">Categories</h3>
        <Button onClick={handleAddNewCategory}>Add new category</Button>
      </div>
      <CategoriesTable
        handleAddNewCategory={handleAddNewCategory}
        handleEditCategory={handleEditCategory}
      />
    </div>
  );
};

export default Categories;
