/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import commentDataService from "services/comment";

export const getComments = createAsyncThunk(
  "comments/get-comments",
  async ({ companyId, organizationId, createdBy, officialIds, corporate }) => {
    const res = await commentDataService.getAll(
      companyId,
      organizationId,
      createdBy,
      officialIds,
      corporate
    );
    return res.data;
  }
);

export const addComment = createAsyncThunk(
  "comments/add-comment",
  async ({ data }) => {
    const res = await commentDataService.create(data);
    return res.data;
  }
);

export const editComment = createAsyncThunk(
  "comments/update",
  async ({ commentId, data }) => {
    const res = await commentDataService.update(commentId, data);
    return res.data;
  }
);

export const deleteComment = createAsyncThunk(
  "comments/delete",
  async ({ commentId }) => {
    await commentDataService.remove(commentId);
    return { commentId };
  }
);

export const getProfileComments = createAsyncThunk(
  "comments/getProfileComments",
  async ({ userId }) => {
    const res = await commentDataService.getProfileComments(userId);
    return res.data;
  }
);

const initialState = {
  comments: [],
  transformedComments: {},
  profileComments: {
    items: [],
    totalCount: 0,
    transformedComments: {},
  },
};

const commentsSlice = createSlice({
  name: "Comments",
  initialState,
  reducers: {
    resetCommentsSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getComments.fulfilled]: (state, action) => {
      state.comments = action.payload.items;
      state.transformedComments = action.payload.transformedComments;
    },
    [addComment.fulfilled]: (state, action) => {
      if (action?.meta?.arg?.isProfileReview) {
        state.profileComments.items.push(action.payload.comment);
      } else state.comments.push(action.payload.comment);
    },
    [editComment.fulfilled]: (state, action) => {
      const { payload } = action;
      if (action?.meta?.arg?.isProfileReview) {
        const index = state.profileComments.items.findIndex(
          (comment) => payload._id === comment._id
        );
        if (index > -1) {
          state.profileComments.items.splice(index, 1, payload);
        }
      } else {
        const { comments } = state;
        const index = comments.findIndex(
          (comment) => payload._id === comment._id
        );
        if (index > -1) {
          comments.splice(index, 1, payload);
        }
      }
    },
    [deleteComment.fulfilled]: (state, action) => {
      const { payload } = action;
      if (action?.meta?.arg?.isProfileReview) {
        const index = state.profileComments.items.findIndex(
          (comment) => payload.commentId === comment._id
        );
        if (index > -1) state.profileComments.items.splice(index, 1);
      } else {
        const { comments } = state;
        const index = comments.findIndex(
          (comment) => payload.commentId === comment._id
        );
        if (index > -1) comments.splice(index, 1);
      }
    },
    [getProfileComments.fulfilled]: (state, { payload }) => {
      state.profileComments = payload;
    },
  },
});

export const { resetCommentsSlice } = commentsSlice.actions;

export default commentsSlice.reducer;
