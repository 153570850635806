import React from "react";
import { Field } from "formik";

import IdentificationDetailsFormFoRe from "./IdentificationDetailsFormFoRe";
import IdentificationDetailsFormFo from "./IdentificationDetailsFormFo";
import IdentificationDetailsFoCo from "./IdentificationDetailsFormFoCo";
import IdentificationDetailsLoCo from "./IdentificationDetailsFormLoCo";
import IdentificationDetailsFormMi from "./IdentificationDetailsFormMi";
import IdentificationDetailsOther from "./IdentificationDetailsOther";
import IdentificationDetailsKeCi from "./IdentificationDetailsFormKeCi";
import IdentificationDetailsFormGuardian from "./IdentificationDetailsFormGuardian";
import IdentificationDetailsCompanyContactPerson from "./IdentificationDetailsCompanyContactPerson";
import IdentificationDetailsCompanySecretaryKeCi from "./IdentificationDetailsCompanySecretaryKeCi";
import IdentificationDetailsCompanySecretaryFoRe from "./IdentificationDetailsCompanySecretaryFoRe";

const IdentificationDetailsForm = (props) => {
  const { idType, designation } = props;
  if (idType && !designation) {
    return (
      <>
        {idType === "Kenyan Citizen" && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsKeCi}
            {...props}
          />
        )}
        {idType === "Foreign Resident" && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsFormFoRe}
            {...props}
          />
        )}
        {idType === "Foreigner" && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsFormFo}
            {...props}
          />
        )}
        {idType === "Minor" && (
          <>
            <Field
              name="identificationDetails"
              component={IdentificationDetailsFormMi}
              {...props}
            />
            <Field
              name="identificationDetails"
              component={IdentificationDetailsFormGuardian}
              {...props}
            />
          </>
        )}
        {idType === "Local Company" && (
          <>
            <Field
              name="identificationDetails"
              component={IdentificationDetailsLoCo}
              {...props}
            />
          </>
        )}
        {idType === "Foreign Company" && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsFoCo}
            {...props}
          />
        )}
        {idType === "Other e.g Parastatals" && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsOther}
            {...props}
          />
        )}
        {/* Company Contact Person */}
        {["Local Company", "Foreign Company", "Other e.g Parastatals"].includes(
          idType
        ) && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsCompanyContactPerson}
            {...props}
          />
        )}
      </>
    );
  }

  return (
    <div>
      {idType === "Kenyan Citizen" && (
        <>
          {[
            "Director",
            "Authorized Person",
            "Director Shareholder",
            "Shareholder",
            "Joint Shareholder",
          ].includes(designation) ? (
            <Field
              name="identificationDetails"
              component={IdentificationDetailsKeCi}
              {...props}
            />
          ) : (
            designation === "Company Secretary" && (
              <Field
                name="identificationDetails"
                component={IdentificationDetailsCompanySecretaryKeCi}
                {...props}
              />
            )
          )}
        </>
      )}
      {idType === "Foreign Resident" &&
        ([
          "Director",
          "Authorized Person",
          "Director Shareholder",
          "Shareholder",
          "Joint Shareholder",
        ].includes(designation) ? (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsFormFoRe}
            {...props}
          />
        ) : (
          designation === "Company Secretary" && (
            <Field
              name="identificationDetails"
              component={IdentificationDetailsCompanySecretaryFoRe}
              {...props}
            />
          )
        ))}
      {idType === "Foreigner" &&
        [
          "Director",
          "Authorized Person",
          "Director Shareholder",
          "Shareholder",
          "Joint Shareholder",
        ].includes(designation) && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsFormFo}
            {...props}
          />
        )}
      {idType === "Local Company" &&
        [
          "Director",
          "Authorized Person",
          "Company Secretary",
          "Director Shareholder",
          "Shareholder",
        ].includes(designation) && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsLoCo}
            {...props}
          />
        )}
      {idType === "Foreign Company" &&
        [
          "Director",
          "Authorized Person",
          "Director Shareholder",
          "Shareholder",
        ].includes(designation) && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsFoCo}
            {...props}
          />
        )}
      {idType === "Minor" &&
        [
          "Director",
          "Authorized Person",
          "Director Shareholder",
          "Shareholder",
          "Joint Shareholder",
        ].includes(designation) && (
          <>
            <Field
              name="identificationDetails"
              component={IdentificationDetailsFormMi}
              {...props}
            />
            <Field
              name="identificationDetails"
              component={IdentificationDetailsFormGuardian}
              {...props}
            />
          </>
        )}
      {idType === "Other e.g Parastatals" &&
        [
          "Director",
          "Authorized Person",
          "Director Shareholder",
          "Shareholder",
        ].includes(designation) && (
          <Field
            name="identificationDetails"
            component={IdentificationDetailsOther}
            {...props}
          />
        )}
      {/* Company Contact Person */}
      {["Local Company", "Foreign Company", "Other e.g Parastatals"].includes(
        idType
      ) && (
        <Field
          name="identificationDetails"
          component={IdentificationDetailsCompanyContactPerson}
          {...props}
        />
      )}
    </div>
  );
};

export default IdentificationDetailsForm;
