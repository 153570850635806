import React from "react";
import { useParams } from "react-router-dom";

import { DataPrivacy } from "../LegalDocuments/DataPrivacy";
import { TermOfUse } from "../LegalDocuments/TermOfUse";

export default function Documents() {
  const { id } = useParams();
  const data = {
    1: {
      title: "1. Data Privacy ",
      description: <DataPrivacy />,
    },
    2: {
      title: "2.Terms of Use",
      description: <TermOfUse />,
    },
  };
  return (
    <>
      <div className="">
        <div className="mx-auto py-4 px-10">
          <div className="w-11/12 xl:w-full mx-auto">
            <div className="space-y-6">
              <h1 className="xl:text-2xl md:text-2xl text-2xl font-extrabold">
                {data[id].title}
              </h1>
              <div>
                <p>{data[id].description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
