import { Field } from "formik";
import Label from "components/lib/Shared/Label";

function ESOPSelection() {
  return (
    <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
      <div className="px-4 py-6 space-y-2 sm:p-6">
        <span className="after:content-['*'] after:ml-0.5 after:text-red-500">
          EmployeeShareholder?
        </span>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Label htmlFor="yesMemberOfESOP">YES</Label>
            <Field
              type="radio"
              name="isMemberOfESOP"
              value="Yes"
              id="yesMemberOfESOP"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Label htmlFor="notMemberOfESOP">NO</Label>
            <Field
              type="radio"
              name="isMemberOfESOP"
              value="No"
              id="notMemberOfESOP"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ESOPSelection;
