import _get from "lodash/get";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import { getTransformedFormFields } from "utils";

function ResidentialDetails({
  official,
  hideResolveAllCheckbox,
  isCurrentUserRow,
  viewRestrictedData,
}) {
  const emailAddressFormFields = [
    {
      key: "Email address",
      name: "emailAddress",
      value: _get(official.electronicAddress, "emailAddress", "N/A"),
    },
    {
      key: "Mobile number",
      name: "mobileNumber",
      value: _get(official.electronicAddress, "mobileNumber", "N/A"),
    },
  ];

  const residentialDetailsFormFields = [
    {
      key: "Building name",
      name: "buildingName",
      value: _get(official.residentialAddressDetails, "buildingName", "N/A"),
    },
    {
      key: "House number",
      name: "houseNumber",
      value: _get(official.residentialAddressDetails, "houseNumber", "N/A"),
    },
    {
      key: "Estate",
      name: "estate",
      value: _get(official.residentialAddressDetails, "estate", "N/A"),
    },
    {
      key: "Floor number",
      name: "floorNumber",
      value: _get(official.residentialAddressDetails, "floorNumber", "N/A"),
    },
    {
      key: "Street name",
      name: "streetName",
      value: _get(official.residentialAddressDetails, "streetName", "N/A"),
    },
    {
      key: "Street number",
      name: "streetNumber",
      value: _get(official.residentialAddressDetails, "streetNumber", "N/A"),
    },
  ];

  const city = _get(official.residentialAddressDetails, "city");
  if (city) {
    residentialDetailsFormFields.push({
      key: "City",
      name: "city",
      value: city,
    });
  }

  const country = _get(official.residentialAddressDetails, "country");
  if (country) {
    residentialDetailsFormFields.push({
      key: "Country",
      name: "country",
      value: country,
    });
  }

  const county = _get(official.residentialAddressDetails, "county");
  if (county) {
    residentialDetailsFormFields.push({
      key: "County",
      name: "county",
      value: county,
    });
  }

  const district = _get(official.residentialAddressDetails, "district");
  if (district) {
    residentialDetailsFormFields.push({
      key: "District",
      name: "district",
      value: district,
    });
  }

  const locality = _get(official.residentialAddressDetails, "locality");
  if (locality) {
    residentialDetailsFormFields.push({
      key: "Locality",
      name: "locality",
      value: locality,
    });
  }

  return (
    <>
      <FormFieldsCard
        fields={getTransformedFormFields({
          idType: official.idType,
          formType: "postalAddress",
          formValues: official.postalAddress,
        })}
        hideResolveAllCheckbox={hideResolveAllCheckbox}
      />
      {(isCurrentUserRow || viewRestrictedData) && (
        <>
          <FormFieldsCard
            fields={emailAddressFormFields}
            hideResolveAllCheckbox={hideResolveAllCheckbox}
          />
          <FormFieldsCard
            fields={residentialDetailsFormFields}
            hideResolveAllCheckbox={hideResolveAllCheckbox}
          />
        </>
      )}
    </>
  );
}

export default ResidentialDetails;
