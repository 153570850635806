import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

function ActionsButtons({
  userStatus,
  row,
  isLoading,
  unresolvedCommentsCount,
  isFirstStep,
  isLastStep,
  goToPrevStep,
  goToNextStep,
  handleVerifyProfile,
  handleSendNotification,
}) {
  return (
    <div className="flex justify-between">
      {!isFirstStep && (
        <button
          onClick={goToPrevStep}
          disabled={isLoading}
          className="flex items-start float-right py-2 font-semibold rounded text-primary mt-7 disabled:cursor-not-allowed"
        >
          <span className="flex items-center mr-4 h-7">
            <ChevronLeftIcon
              className="flex-shrink-0 w-5 h-5"
              aria-hidden="true"
            />
          </span>
          <p className="font-semibold">Go back</p>
        </button>
      )}
      {isLastStep ? (
        <button
          onClick={() =>
            unresolvedCommentsCount > 0
              ? handleSendNotification(row)
              : handleVerifyProfile(row)
          }
          disabled={isLoading}
          className="flex items-center px-4 py-2 mb-2 font-semibold text-white rounded bg-green-focused mt-7 disabled:cursor-not-allowed"
        >
          {isLoading ? (
            <>
              <SpinnerIcon />
              Submitting...
            </>
          ) : (
            <p>
              {unresolvedCommentsCount > 0
                ? "Send notification"
                : userStatus === "Verified"
                ? "Close"
                : "Verify profile"}
            </p>
          )}
          {
            <span className="flex items-center ml-4 text-white h-7">
              <ChevronRightIcon
                className="flex-shrink-0 w-5 h-5"
                aria-hidden="true"
              />
            </span>
          }
        </button>
      ) : (
        <button
          onClick={goToNextStep}
          className="flex items-start float-right py-2 ml-auto font-semibold rounded text-primary mt-7"
        >
          <p className="font-semibold">Review next step </p>
          <span className="flex items-center ml-4 h-7">
            <ChevronRightIcon
              className="flex-shrink-0 w-5 h-5"
              aria-hidden="true"
            />
          </span>
        </button>
      )}
    </div>
  );
}

export default ActionsButtons;
