import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "@heroicons/react/outline";
import { PencilAltIcon } from "@heroicons/react/solid";
import { twMerge } from "tailwind-merge";
import { format } from "date-fns";
import _has from "lodash/has";
import { useDebounce } from "use-debounce";

import Button from "components/lib/Shared/Button";
import useFullscreenToggle from "hooks/useFullscreenToggle";
import PaginationControls from "./PaginationControls";
import SearchInput from "components/lib/Shared/SearchInput";
import {
  downloadRegistersOfDirectorsAndSecretariesAsync,
  getRegistersOfDirectorsAndSecretariesAsync,
} from "state/slices/minuteBook";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { Constants } from "config/constants";
import {
  getCountryLabel,
  getEmptyRowsForRegisters,
  getFullName,
  getPoBoxAddress,
} from "utils";
import { getPaginationParams } from "utils/registers";
import BreadCrumbs from "./BreadCrumbs";

const { ROW_HEIGHT, DIRECTORS_ROWS, SECRETARIES_ROWS } = Constants.REGISTERS;
const MAX_DIRECTORS_SECTION_HEIGHT =
  ROW_HEIGHT * DIRECTORS_ROWS + (DIRECTORS_ROWS - 2) * 2;
const MAX_SECRETARIES_SECTION_HEIGHT =
  ROW_HEIGHT * SECRETARIES_ROWS + (SECRETARIES_ROWS - 2) * 2;

const getBirthOrRegistrationDate = (official) => {
  const birthOrRegistration =
    official.names.dateOfRegistration ||
    official.identificationDetails.dateOfBirth;
  return birthOrRegistration
    ? format(new Date(birthOrRegistration), "dd-MM-yyyy")
    : "";
};

const DirectorsTableHeading = () => {
  return (
    <thead>
      <tr className="text-xs">
        {[
          {
            label:
              "NAME. (In the case of an individual, present first name or names and surname (2). In the case of a corporation, the corporate name.)",
            className: "text-left w-32",
          },
          {
            label: "Any former Christian name or names and surnames. (3)",
            className: "w-24",
          },
          {
            label: "Nationality",
            className: "w-32",
          },
          {
            label: "POSTAL ADDRESS",
            className: "text-left w-64",
          },
          {
            label:
              "Business occupation and particulars of other directorships. (4)",
            className: "w-64",
          },
          {
            label: "Date of Birth / Registration. (5)",
            className: "w-20",
          },
          {
            label: "DATE OF APPOINTMENT",
            className: "w-20",
          },
          {
            label: "DATE OF CESSATION",
            className: "w-20",
          },
        ].map((heading) => {
          return (
            <th
              key={heading.label}
              scope="col"
              className={twMerge(
                "font-semibold text-gray-900 bg-gray-100",
                heading.className
              )}
            >
              {heading.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const SecretariesTableHeading = () => {
  return (
    <thead>
      <tr className="text-xs">
        {[
          {
            label:
              "NAME. (In the case of an individual, present first name or names and surname (2). In the case of a corporation, the corporate name.)",
            className: "text-left w-32",
          },
          {
            label: "Any former first name(s) and surname(s).",
            className: "w-24",
          },
          {
            label: "Nationality",
            className: "w-32",
          },
          {
            label: "POSTAL ADDRESS",
            className: "text-left w-64",
          },
          {
            label:
              "Business occupation and particulars of other directorships. (4)",
            className: "w-64",
          },
          {
            label: "Date of Birth / Registration. (5)",
            className: "w-20",
          },
          {
            label: "DATE OF APPOINTMENT",
            className: "w-20",
          },
          {
            label: "DATE OF CESSATION",
            className: "w-20",
          },
        ].map((heading) => {
          return (
            <th
              key={heading.label}
              scope="col"
              className={twMerge(
                "font-semibold text-gray-900 bg-gray-100",
                heading.className
              )}
            >
              {heading.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const Footnotes = () => (
  <div className="p-1 mb-20 text-xs text-gray-600 mt-1 bg-gray-100 mx-12">
    <p id="1">
      (1) "Director" includes any person who occupies the position of a director
      by whatsoever name called, and any person in accordance whose directions
      or instructions the directors of the company are accustomed to act.
    </p>
    <p id="2">
      (2) "Christian name" includes a forename and "Surname" in the case of a
      peer or person usually known by a title different from his surname, means
      that title
    </p>
    <p id="3">
      (3) "Former Christian name" and "former surname" do not include:-
    </p>
    <p id="a">
      (a) in the case of a peer or a person usually known by a British title
      different from his surname, the name by which he was know previously to
      the adoption of or succession to the title or,
    </p>
    <p id="b">
      (b) in the case of any person a former Christian name or surname where
      that name or surname was changed or disused before the person bearing the
      name attained the age of eighteen years or has been changed or disused for
      a period of not less than twenty years, or{" "}
    </p>
    <p id="4">
      (4) Directorships: The names of all bodies corporate incorporated in Kenya
      of which the director , should be given except bodies corporate which the
      company making the return is the wholly-owned subsidiary or bodies
      corporate which are the wholly-owed subsidiaries either of the company or
      another company which the company is wholly-owned subsidiary. A body
      corporate is deemed to be the wholly-owned subsidiary of another if it has
      no members except that other and that other's wholly-owned subsidiaries
      and its or their nominees, if the space provided in the form is
      insufficient, particulars of other directorships should be listed on a
      separate statement attached to this form.
    </p>
    <p id="5">
      (5) Dates of birth for individuals and dates of registration for
      corporates need to be given for all Officers of the Company.
    </p>
    <p id="6">
      (6) Where all the partners in a firm are joint secretaries, the name and
      principal office of the firm may be stated.
    </p>
  </div>
);

const PopulatedDirectorAndSecretaryRow = ({ official }) => {
  const { nationality, entityType, occupation } =
    official.identificationDetails;
  return (
    <tr className="text-xs">
      <td className="p-1 text-gray-600 bg-gray-100 text-left w-32">
        {getFullName(official.names)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-24">
        {official.names.formerName}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-32">
        {getCountryLabel(nationality)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 text-left w-64">
        {getPoBoxAddress(
          official.idType,
          official.postalAddress,
          official.isNonResident
        )}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-64">
        {entityType || occupation}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-20">
        {getBirthOrRegistrationDate(official)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-20">
        {official?.appointmentDate
          ? format(new Date(official.appointmentDate), "dd-MM-yyyy")
          : ""}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-20">
        {official?.cessationDate
          ? format(new Date(official.cessationDate), "dd-MM-yyyy")
          : ""}
      </td>
    </tr>
  );
};

const EmptyRow = () => {
  return (
    <tr className="text-xs h-6">
      <td className="p-1 text-gray-600 bg-gray-100 w-32"></td>
      <td className="p-1 text-gray-600 bg-gray-100 w-24"></td>
      <td className="p-1 text-gray-600 bg-gray-100 w-32"></td>
      <td className="p-1 text-gray-600 bg-gray-100 w-64"></td>
      <td className="p-1 text-gray-600 bg-gray-100 w-64"></td>
      <td className="p-1 text-gray-600 bg-gray-100 w-20"></td>
      <td className="p-1 text-gray-600 bg-gray-100 w-20"></td>
      <td className="p-1 text-gray-600 bg-gray-100 w-20"></td>
    </tr>
  );
};

const getPopulatedRowNode = (official) => {
  if (!official) return null;
  const { nationality, entityType, occupation } =
    official.identificationDetails;
  const currentTableRowNode = document.createElement("tr");
  currentTableRowNode.setAttribute("class", "text-xs");
  currentTableRowNode.innerHTML = `<td class="p-1 text-gray-600 bg-gray-100 text-left w-32">
    ${getFullName(official.names)}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-24">
    ${official.names.formerName}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-32">
    ${getCountryLabel(nationality)}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 text-left w-64">
    ${getPoBoxAddress(
      official.idType,
      official.postalAddress,
      official.isNonResident
    )}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-64">
    ${entityType || occupation}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-20">
    ${getBirthOrRegistrationDate(official)}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-20">
    ${
      official?.appointmentDate
        ? format(new Date(official.appointmentDate), "dd-MM-yyyy")
        : ""
    }
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-20">
    ${
      official?.cessationDate
        ? format(new Date(official.cessationDate), "dd-MM-yyyy")
        : ""
    }
  </td>
`;
  return currentTableRowNode;
};

function RegisterOfDirectorsAndSecretaries({ selectedTab }) {
  const { isFullscreen, ToggleFullscreenContainer, ToggleFullscreenButton } =
    useFullscreenToggle();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const directorsTableBodyRef = useRef(null);
  const secretariesTableBodyRef = useRef(null);
  const hiddenTempTableBodyRef = document.getElementById(
    "tempTableForRegisters"
  );
  const [paginatedDirectors, setPaginatedDirectors] = useState([]);
  const [emptyRowsCountForDirectors, setEmptyRowsCountDirectors] = useState(0);
  const [emptyRowsCountForSecretaries, setEmptyRowsCountSecretaries] =
    useState(0);
  const [paginatedSecretaries, setPaginatedSecretaries] = useState([]);
  const [rowsPerPageForDirectors, setRowsPerPageForDirectors] = useState({});
  const [rowsPerPageForSecretaries, setRowsPerPageForSecretaries] = useState(
    {}
  );
  const [directors, setDirectors] = useState({ items: [], totalCount: 0 });
  const [secretaries, setSecretaries] = useState({ items: [], totalCount: 0 });
  const [directorsLastPageWithData, setDirectorsLastPageWithData] = useState(1);
  const [secretariesLastPageWithData, setSecretariesLastPageWithData] =
    useState(1);

  const { company } = useSelector((state) => state.companyDashboardSlice);
  const {
    registersOfDirectorsAndSecretaries,
    getRegistersOfDirectorsAndSecretaries,
    downloadRegistersOfDirectorsAndSecretaries,
  } = useSelector((state) => state.minuteBookSlice);

  useEffect(() => {
    if (company._id) {
      dispatch(
        getRegistersOfDirectorsAndSecretariesAsync({
          queryParams: { companyId: company._id },
        })
      );
    }
  }, [company._id, dispatch]);

  useEffect(() => {
    let tempDirectors = registersOfDirectorsAndSecretaries.directors.items;
    let tempSecretaries = registersOfDirectorsAndSecretaries.secretaries.items;
    const searchQuery = debouncedSearchTerm.toLowerCase();
    if (searchQuery) {
      tempDirectors = tempDirectors.filter((director) =>
        getFullName(director.names).toLowerCase().includes(searchQuery)
      );
      tempSecretaries = tempSecretaries.filter((secretary) =>
        getFullName(secretary.names).toLowerCase().includes(searchQuery)
      );
    }
    if (currentPage !== 0) {
      setCurrentPage(1);
    }
    setDirectors({ items: tempDirectors, totalCount: tempDirectors.length });
    setSecretaries({
      items: tempSecretaries,
      totalCount: tempSecretaries.length,
    });
  }, [
    registersOfDirectorsAndSecretaries.directors,
    registersOfDirectorsAndSecretaries.secretaries,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    const getEmptyRowsCount = (currentPage, maxSectionHeight, key) => {
      let tempEmptyRowsCount = 0;
      const tableBody = document.querySelector(
        `[data-table-index="${key}-${currentPage}"]`
      );
      if (!tableBody) return tempEmptyRowsCount;
      while (
        directorsTableBodyRef.current &&
        secretariesTableBodyRef.current &&
        tableBody.clientHeight < maxSectionHeight &&
        maxSectionHeight - tableBody.clientHeight > ROW_HEIGHT &&
        tempEmptyRowsCount < DIRECTORS_ROWS
      ) {
        const emptyTableRow = document.createElement("tr");
        emptyTableRow.setAttribute("class", "text-xs h-6");
        emptyTableRow.innerHTML = `
        <td class="p-1 text-gray-600 bg-gray-100 w-32"></td>
        <td class="p-1 text-gray-600 bg-gray-100 w-24"></td>
        <td class="p-1 text-gray-600 bg-gray-100 w-32"></td>
        <td class="p-1 text-gray-600 bg-gray-100 w-64"></td>
        <td class="p-1 text-gray-600 bg-gray-100 w-64"></td>
        <td class="p-1 text-gray-600 bg-gray-100 w-20"></td>
        <td class="p-1 text-gray-600 bg-gray-100 w-20"></td>
        <td class="p-1 text-gray-600 bg-gray-100 w-20"></td>
        `;
        tableBody.appendChild(emptyTableRow);
        tempEmptyRowsCount++;
      }
      hiddenTempTableBodyRef.innerHTML = "";
      return tempEmptyRowsCount;
    };

    const calculateRowsPerPage = () => {
      if (
        !hiddenTempTableBodyRef ||
        (!directorsTableBodyRef.current && !secretariesTableBodyRef.current)
      )
        return;

      hiddenTempTableBodyRef.innerHTML = "";

      const paginationParamsForDirectors = getPaginationParams(
        directors.items,
        MAX_DIRECTORS_SECTION_HEIGHT,
        directorsTableBodyRef,
        getPopulatedRowNode,
        "director"
      );
      setRowsPerPageForDirectors(paginationParamsForDirectors.rowsPerPage);
      setDirectorsLastPageWithData(paginationParamsForDirectors.lastPage);
      setEmptyRowsCountDirectors(
        paginationParamsForDirectors.lastPage
          ? getEmptyRowsCount(
              paginationParamsForDirectors.lastPage,
              MAX_DIRECTORS_SECTION_HEIGHT,
              "director"
            )
          : 0
      );

      const paginationParamsForSecretaries = getPaginationParams(
        secretaries.items,
        MAX_SECRETARIES_SECTION_HEIGHT,
        secretariesTableBodyRef,
        getPopulatedRowNode,
        "secretary"
      );

      setRowsPerPageForSecretaries(paginationParamsForSecretaries.rowsPerPage);
      setSecretariesLastPageWithData(paginationParamsForSecretaries.lastPage);
      setEmptyRowsCountSecretaries(
        paginationParamsForSecretaries.lastPage
          ? getEmptyRowsCount(
              paginationParamsForSecretaries.lastPage,
              MAX_SECRETARIES_SECTION_HEIGHT,
              "secretary"
            )
          : 0
      );

      setCurrentPage(1);
    };

    if (currentPage === 1) {
      calculateRowsPerPage();
    }

    window.addEventListener("resize", calculateRowsPerPage);

    return () => {
      window.removeEventListener("resize", calculateRowsPerPage);
    };
  }, [directors.items, secretaries.items, isFullscreen, currentPage]);

  useEffect(() => {
    if (currentPage > 0) {
      if (_has(rowsPerPageForDirectors[currentPage], "start")) {
        setPaginatedDirectors(
          directors.items.slice(
            rowsPerPageForDirectors[currentPage].start,
            rowsPerPageForDirectors[currentPage].end
          )
        );
      } else {
        setPaginatedDirectors([]);
      }
      if (_has(rowsPerPageForSecretaries[currentPage], "start")) {
        setPaginatedSecretaries(
          secretaries.items.slice(
            rowsPerPageForSecretaries[currentPage].start,
            rowsPerPageForSecretaries[currentPage].end
          )
        );
      } else {
        setPaginatedSecretaries([]);
      }
    }
  }, [
    currentPage,
    directors,
    secretaries,
    SECRETARIES_ROWS,
    rowsPerPageForDirectors,
  ]);

  if (getRegistersOfDirectorsAndSecretaries.status === "loading") {
    return (
      <div className="col-span-6">
        <div className="flex justify-center mx-auto text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </div>
    );
  }

  const handleDownloadClick = () => {
    dispatch(
      downloadRegistersOfDirectorsAndSecretariesAsync({
        queryParams: { companyId: company._id, fileFormat: "xlsx" },
      })
    );
  };

  return (
    <div className="overflow-auto">
      <div className="flex flex-col md:flex-row justify-between w-full mb-4">
        <BreadCrumbs breadCrumbs={selectedTab.breadCrumbs} />
        <Button
          onClick={handleDownloadClick}
          preIcon={DownloadIcon}
          isLoading={
            downloadRegistersOfDirectorsAndSecretaries.status === "loading"
          }
          loadingText="Downloading..."
        >
          Download
        </Button>
      </div>
      <ToggleFullscreenContainer>
        <ToggleFullscreenButton />
        <div
          className={`bg-white relative ${
            isFullscreen ? "h-[88vh] overflow-auto" : ""
          }`}
        >
          {currentPage === 0 && (
            <div className="px-6 space-y-20 py-20 text-center flex flex-col">
              <h2 className="text-title3">THE COMPANIES ACT, NO 17 OF 2015</h2>
              <h3 className="text-title4">STATUTORY REGISTERS</h3>
              <h4 className="text-title5">{company.registrationName}</h4>
              <h5 className="text-title6">{company.registrationNumber}</h5>
            </div>
          )}
          {currentPage > 0 && (
            <div
              className={`overflow-auto px-1 pt-4 pb-20 space-y-2 flex flex-col text-center ${
                isFullscreen ? "px-12" : ""
              }`}
            >
              {/* REGISTER OF DIRECTORS */}
              <div>
                {/* Header */}
                <div className="relative flex flex-col items-center">
                  <div>
                    <h2 className="text-title6 font-medium">
                      REGISTER OF DIRECTORS
                    </h2>
                    <div className="flex items-center justify-center">
                      <span className="mr-2 text-sm">
                        as at {format(new Date(), "dd-MM-yyyy")}
                      </span>
                      <PencilAltIcon className="w-4 h-4" />
                    </div>
                  </div>
                  {isFullscreen && (
                    <SearchInput
                      value={searchTerm}
                      placeholder="Search register"
                      className="lg:absolute w-64 top-0 right-0 mt-2"
                      handleOnChange={(event) =>
                        setSearchTerm(event.target.value)
                      }
                      autoFocus
                    />
                  )}
                </div>
                <div className="mt-4">
                  <table className="min-w-full divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
                    <DirectorsTableHeading />
                    <tbody
                      className={`divide-y divide-gray-200`}
                      style={{ height: `${MAX_DIRECTORS_SECTION_HEIGHT}px` }}
                      ref={directorsTableBodyRef}
                    >
                      {paginatedDirectors.map((director) => (
                        <PopulatedDirectorAndSecretaryRow
                          key={director._id}
                          official={director}
                        />
                      ))}
                      {currentPage === directorsLastPageWithData &&
                      emptyRowsCountForDirectors
                        ? Array.from({
                            length: emptyRowsCountForDirectors,
                          }).map((_, index) => (
                            <EmptyRow key={`director_empty_row_${index}`} />
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* REGISTER OF SECRETARIES */}
              <div>
                <h2 className="text-title6 font-medium">
                  REGISTER OF SECRETARIES (6)
                </h2>
                <div className="mt-4">
                  <table className="min-w-full divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
                    <SecretariesTableHeading />
                    <tbody
                      style={{ height: `${MAX_SECRETARIES_SECTION_HEIGHT}px` }}
                      className="divide-y divide-gray-200"
                      ref={secretariesTableBodyRef}
                    >
                      {paginatedSecretaries.map((secretary) => (
                        <PopulatedDirectorAndSecretaryRow
                          key={secretary._id}
                          official={secretary}
                        />
                      ))}
                      {currentPage === secretariesLastPageWithData &&
                      emptyRowsCountForSecretaries
                        ? Array.from({
                            length: emptyRowsCountForSecretaries,
                          }).map((_, index) => (
                            <EmptyRow key={`secretary_empty_row_${index}`} />
                          ))
                        : null}
                      {currentPage > secretariesLastPageWithData
                        ? getEmptyRowsForRegisters(0, SECRETARIES_ROWS).map(
                            (_, index) => (
                              <EmptyRow key={`secretary_empty_row_${index}`} />
                            )
                          )
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {currentPage > 0 && isFullscreen && <Footnotes />}
          <PaginationControls
            isFullscreen={isFullscreen}
            renderBackBtn={currentPage !== 0}
            disableBackBtn={currentPage === 0}
            isLastPage={currentPage === directorsLastPageWithData}
            currentPage={currentPage}
            rowsPerPage={rowsPerPageForDirectors[currentPage] || 0}
            totalRows={directors.totalCount}
            totalPages={Object.keys(rowsPerPageForDirectors).length}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </ToggleFullscreenContainer>
    </div>
  );
}

export default RegisterOfDirectorsAndSecretaries;
