import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { format } from "date-fns";
import Cookies from "js-cookie";
import { PencilIcon } from "@heroicons/react/solid";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import Tabs from "components/lib/Shared/Tabs";
import { Constants } from "config/constants";
import TemplateSummary from "components/OrgAndSuperAdminDashboard/TemplatesSettings/Templates/SummaryForm";
import { updateTemplateState } from "state/slices/template";
import RegistrarEditForm from "./CompanyDocumentsForm";
import { setCookie } from "utils/cookie";

export default function CompanySummary() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedField, setSelectedField] = useState(null);

  const dispatch = useDispatch();
  const { company, dashboardPermissions, dashboardRole } = useSelector(
    (state) => state.companyDashboardSlice
  );

  useEffect(() => {
    if (dashboardRole === "REGISTRAR") {
      setCookie("role", Constants.COOKIE_ROLES.CONTACT_PERSON);
    }
  }, [dashboardRole]);

  const handleFormOpen = (field) => {
    setSelectedField(field);
  };

  const handleFormClose = () => {
    setSelectedField(null);
  };

  const viewRestrictedData = dashboardPermissions.includes(
    Constants.DASHBOARD_PERMISSIONS.VIEW_RESTRICTED_DATA
  );

  const tabs = useMemo(
    () => [
      {
        id: "company",
        label: "1. Company Summary",
      },
      {
        id: "governance",
        label: "2. Governance Summary",
        disabled: !viewRestrictedData,
      },
    ],
    [viewRestrictedData]
  );

  if (!company?._id) return "loading...";

  const { contactPerson, articlesOfAssociation } = company;

  const templateVersions = articlesOfAssociation?.template?.versions ?? [];

  useEffect(() => {
    dispatch(
      updateTemplateState({
        selectedTemplate: articlesOfAssociation?.template,
      })
    );
  }, [articlesOfAssociation?.template]);

  const latestTemplateVersion = templateVersions[templateVersions.length - 1];

  return (
    <>
      <Tabs
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        showBorderBottom={false}
      />
      <div>
        {selectedTabIndex === 0 ? (
          <div className="divide-y-2">
            <FormFieldsCard
              fields={[
                {
                  key: "Registration Name",
                  label: "registrationName",
                  value: _get(company, "registrationName", "N/A"),
                },
                {
                  key: "Registration Number",
                  name: "registrationNumber",
                  value: _get(company, "registrationNumber", "N/A"),
                },
                {
                  key: "Entity Type",
                  name: "type",
                  value:
                    Constants.ENTITY_TYPE_AGAINST_TYPE[
                      _get(company, "type", "N/A")
                    ],
                },
              ]}
              hideResolveAllCheckbox
            />
            <FormFieldsCard
              wrapperStyles="mt-0"
              fields={[
                {
                  key: "Entity Status",
                  name: "status",
                  value: _get(company, "status", "N/A"),
                },
                {
                  key: "Registration Date",
                  name: "registrationDate",
                  value: company?.registrationDate
                    ? format(new Date(company?.registrationDate), "yyyy-MM-dd")
                    : "N/A",
                },
                {
                  key: "Nature of Business",
                  name: "articlesOfAssociation.natureOfBusiness",
                  value: _get(
                    company,
                    "articlesOfAssociation.natureOfBusiness",
                    "N/A"
                  ),
                },
              ]}
              hideResolveAllCheckbox
            />
            <div className="px-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <div className="flex flex-col space-y-2">
                {[
                  {
                    key: "KRA PIN",
                    name: "kraPin",
                    value: _get(company, "kraPin", "N/A"),
                  },
                  {
                    key: "NSSF No.",
                    name: "NSSFNo",
                    value: _get(company, "NSSFNo", "N/A"),
                  },
                  {
                    key: "NHIF No.",
                    name: "NHIFNo",
                    value: _get(company, "NHIFNo", "N/A"),
                  },
                ].map((field, index) => {
                  const renderCompanyDocumentForm =
                    selectedField && selectedField.name === field.name;
                  return (
                    <React.Fragment key={`${field.key}_${index}`}>
                      <div className="flex flex-col">
                        <div className="flex items-baseline justify-between">
                          <label
                            htmlFor={field.name}
                            className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                          >
                            {field.key}
                          </label>
                          <div
                            className={`bg-formInput flex ${
                              Constants.COOKIE_ROLES.CONTACT_PERSON ===
                              Cookies.get("role")
                                ? "w-[60%]"
                                : "w-full"
                            } justify-between relative`}
                          >
                            {field.value}
                          </div>
                          {dashboardRole === "REGISTRAR" && (
                            <button
                              className="flex space-x-1 "
                              onClick={() => {
                                handleFormOpen(field);
                              }}
                            >
                              <span>Edit</span>
                              <PencilIcon className="inline w-4 h-4" />
                            </button>
                          )}
                        </div>
                        <div
                          className={
                            renderCompanyDocumentForm ? "mt-5 mb-5" : ""
                          }
                        >
                          {renderCompanyDocumentForm && (
                            <RegistrarEditForm
                              field={selectedField}
                              handleFormClose={handleFormClose}
                            />
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div className="px-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <div className="flex justify-between">
                {[
                  {
                    key: "Annual Return Date",
                    name: "registrationDate",
                    value: company?.registrationDate
                      ? `${format(
                          new Date(company?.registrationDate),
                          "dd/MM/yyyy"
                        ).slice(0, -2)}**`
                      : "N/A",
                  },
                  {
                    key: "Financial YE",
                    name: "accountingEndMonth",
                    value: company?.primaryBusinessActivity?.accountingEndMonth
                      ? format(
                          new Date(
                            new Date().getFullYear(),
                            company.primaryBusinessActivity.accountingEndMonth -
                              1,
                            1
                          ),
                          "MMMM"
                        )
                      : "N/A",
                  },
                ].map((field, index) => {
                  return (
                    <React.Fragment key={`${field.key}_${index}`}>
                      <div className="flex flex-col items-baseline justify-between">
                        <label
                          htmlFor={field.name}
                          className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal"
                        >
                          {field.key}
                        </label>
                        <p className="w-full p-2 text-sm whitespace-normal rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300">
                          {field.value}
                        </p>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <>
            <h2 className="font-medium">Articles</h2>
            <TemplateSummary
              viewOnly
              selectedVersion={{
                summary: latestTemplateVersion.summary,
                fileUploads: latestTemplateVersion.fileUploads,
              }}
            />
            {contactPerson && (
              <FormFieldsCard
                wrapperStyles="mt-4"
                hideResolveAllCheckbox
                fields={[
                  {
                    name: "companyContactName",
                    key: "Full Name",
                    value: contactPerson.fullName || "N/A",
                  },
                  {
                    name: "companyContactDesignation",
                    key: "Designation",
                    value: contactPerson.designation || "N/A",
                  },
                  {
                    name: "telNumber",
                    key: "Telephone Number",
                    value: contactPerson.mobileNumber || "N/A",
                  },
                  {
                    name: "emailAddress",
                    key: "Email Address",
                    value: contactPerson.emailAddress || "N/A",
                  },
                ]}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
