import React from "react";
import { format } from "date-fns";

import DistinctiveNumbers from "./DistinctiveNumbers";
import { getFullName, getPoBoxAddress } from "utils";

const calculateLengthOfRows = (stringLength) => {
  const firstRowLength = 22;
  if (stringLength < firstRowLength) return [firstRowLength];
  const remainingStringLength = stringLength - firstRowLength;
  const fullRowsLength = Math.ceil(remainingStringLength / 35);

  return [firstRowLength, ...Array(fullRowsLength).fill(35)];
};

function sliceString(str, maxLengths) {
  let words = str.split(" ");
  let lines = [];
  let currentLine = "";

  words.forEach((word) => {
    const maxLength = maxLengths[lines.length];
    if (currentLine.length + word.length <= maxLength) {
      currentLine += word + " ";
    } else {
      lines.push(currentLine.trim());
      currentLine = word + " ";
    }
  });

  lines.push(currentLine.trim());
  return lines;
}

function Counterfeit({
  companyRegistrationName,
  companySuffix,
  currentShareCertificate,
  unpaidClassesOfShares,
  isFullscreen,
}) {
  const fullName = getFullName(currentShareCertificate.names);
  const fullNameLines = sliceString(
    fullName,
    calculateLengthOfRows(fullName.length)
  );

  const fullAddress = getPoBoxAddress(
    currentShareCertificate.idType,
    currentShareCertificate.postalAddress,
    currentShareCertificate.isNonResident
  );
  const addressLines = sliceString(
    fullAddress,
    calculateLengthOfRows(fullAddress.length)
  );

  return (
    <div
      className={`flex flex-col w-[30rem] p-4 lg:p-6 lg border-r-2 border-dashed border-gray-400 ${
        isFullscreen ? "" : "ml-32"
      }`}
    >
      <div>
        <span className="border-b-1-dashed-gray-400 font-semibold uppercase px-2">
          {companyRegistrationName}
        </span>
        <p className="font-semibold py-1 uppercase">{companySuffix}</p>
      </div>
      <ul className="text-left space-y-3 my-4">
        <li className="flex">
          <p className="w-32 italic">No. of Certificate</p>
          <p className="border-b-1-dashed-gray-400 flex-grow font-semibold">
            {
              currentShareCertificate.currentAllottedClass
                .shareCertificateNumber
            }
          </p>
        </li>
        <li className="flex">
          <p className="w-32 italic">No. of Shares</p>
          <p className="border-b-1-dashed-gray-400 flex-grow font-semibold">
            {currentShareCertificate.currentAllottedClass.allottedShares}
          </p>
        </li>
        <li className="flex">
          <p className="w-32 italic">Class of Shares</p>
          <p className="border-b-1-dashed-gray-400 flex-grow font-semibold uppercase">
            {currentShareCertificate.currentAllottedClass.classOfShares}
          </p>
        </li>
        <li className="flex">
          <p className="w-32 italic">Date of Issue</p>
          <p className="border-b-1-dashed-gray-400 flex-grow font-semibold">
            {format(
              new Date(
                currentShareCertificate.currentAllottedClass.allotmentDate
              ),
              "do MMM yyyy"
            )}
          </p>
        </li>
        <li>
          <div className="flex">
            <p className="w-32 italic">NAME</p>
            <p className="border-b-1-dashed-gray-400 flex-grow font-semibold mb-2 uppercase">
              {fullNameLines[0]}
            </p>
          </div>
          <div className="font-semibold space-y-3 uppercase">
            <p className="border-b-1-dashed-gray-400 h-6">{fullNameLines[1]}</p>
            <p className="border-b-1-dashed-gray-400 h-6">{fullNameLines[2]}</p>
          </div>
        </li>
        <li>
          <div className="flex">
            <p className="w-32 italic">ADDRESS</p>
            <p className="border-b-1-dashed-gray-400 flex-grow font-semibold mb-2 uppercase">
              {addressLines[0]}
            </p>
          </div>
          <div className="font-semibold space-y-3 uppercase">
            <p className="border-b-1-dashed-gray-400 h-6">{addressLines[1]}</p>
            <p className="border-b-1-dashed-gray-400 h-6">{addressLines[2]}</p>
            {addressLines.slice(3).map((address, index) => (
              <p key={index} className="border-b-1-dashed-gray-400 h-6">
                {address}
              </p>
            ))}
          </div>
        </li>
      </ul>
      <DistinctiveNumbers
        currentAllottedClass={currentShareCertificate.currentAllottedClass}
        unpaidClassesOfShares={unpaidClassesOfShares}
      />
      <ul className="text-left space-y-3 mt-4">
        <li className="flex">
          <p className="w-40">No. of Transfer</p>
          <p className="border-b-1-dashed-gray-400 flex-grow px-1 font-semibold"></p>
        </li>
        <li className="flex">
          <p className="w-40">No. of Allotment</p>
          <p className="border-b-1-dashed-gray-400 flex-grow px-1 font-semibold">
            {
              currentShareCertificate.currentAllottedClass
                .numberOfAllotmentLetter
            }
          </p>
        </li>
        <li className="flex">
          <p className="w-40">No. of Old Certificate</p>
          <p className="border-b-1-dashed-gray-400 flex-grow px-1 font-semibold"></p>
        </li>
        <li className="flex">
          <p className="w-40">Received by</p>
          <p className="border-b-1-dashed-gray-400 flex-grow px-1 font-semibold"></p>
        </li>
      </ul>
    </div>
  );
}

export default Counterfeit;
