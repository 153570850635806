import React from "react";
import { Formik, Field, Form } from "formik";

import addReviewerValidation from "./addReviewerValidation";
import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Button from "components/lib/Shared/Button";

const AddReviewerForm = ({ handleBackBtn, handleAddReviewer }) => {
  return (
    <div className="Registered Office Address df-example">
      <Formik
        enableReinitialize
        initialValues={{
          name: "",
          email: "",
          role: "",
        }}
        validationSchema={addReviewerValidation}
        onSubmit={handleAddReviewer}
      >
        {({ isSubmitting, handleSubmit }) => {
          return (
            <Form>
              <div className="bg-white shadow sm:rounded-md">
                <div className="px-4 py-6 space-y-6 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-2">
                      <Field
                        component={Input}
                        label="Name"
                        name="name"
                        placeholder="John Doe"
                        showRequiredAsterisk
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <Field
                        component={Input}
                        label="Email address"
                        name="email"
                        type="email"
                        placeholder="john@site.com"
                        showRequiredAsterisk
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <Field
                        label="Role"
                        name="role"
                        component={ReactSelectWithFormik}
                        showRequiredAsterisk
                        options={[
                          {
                            value: "Company Secretary",
                            label: "Company Secretary",
                          },
                          {
                            value: "Authorized Person",
                            label: "Authorized Person",
                          },
                          { value: "Other Reviewer", label: "Other Reviewer" },
                        ]}
                        placeholder="Select role"
                        errorPath="role"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between py-3">
                <Button
                  onClick={handleBackBtn}
                  variant="secondary"
                  disabled={isSubmitting}
                >
                  Go back
                </Button>
                <Button
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  loadingText="Assigning..."
                >
                  Assign reviewer
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddReviewerForm;
