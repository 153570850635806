/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { object, string } from "yup";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";

import SectionDescription from "components/lib/Shared/SectionDescription";
import ValidationError from "components/lib/Shared/ValidationError";
import { updateUserState } from "state/slices/user";
import { ChevronRightIcon } from "components/lib/Shared/Icons/sflIcons";
import Button from "components/lib/Shared/Button";

const officialIdTypes = [
  { id: "kenyanCitizen", title: "Kenyan Citizen" },
  { id: "foreignResident", title: "Foreign Resident" },
  { id: "foreigner", title: "Foreigner" },
];

const SelectIdType = ({ handleNextBtn }) => {
  const { idType = "", electronicAddress } = useSelector(
    (state) => state.userSlice
  );
  const dispatch = useDispatch();

  const handleSubmit = (formData) => {
    dispatch(updateUserState(formData));
    handleNextBtn();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ idType }}
      validationSchema={object().shape({
        idType: string().required("ID type is required"),
      })}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ errors, touched, setFieldValue }) => {
        const handleChangeIdType = (e) => {
          setFieldValue("idType", e.target.value);
          dispatch(
            updateUserState({
              names: {},
              identificationDetails: {},
              electronicAddress: {
                ...electronicAddress,
                mobileNumber: "",
              },
              fileUploads: {},
            })
          );
        };

        return (
          <Form>
            <SectionDescription
              id="Step: 1 of 5"
              title="Select ID Type"
              description="The information provided will be used for current and future application process."
            />
            {/* Users id types */}
            <div>
              <fieldset className="mt-4">
                <legend className="sr-only">Users Id types</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {officialIdTypes.map(({ id, title }) => (
                    <div key={id} className="flex items-center">
                      <input
                        id={id}
                        onChange={handleChangeIdType}
                        value={title}
                        name="idType"
                        type="radio"
                        defaultChecked={title === idType}
                        className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor={id}
                        className="block ml-3 text-sm font-medium text-gray-700"
                      >
                        {title}
                      </label>
                    </div>
                  ))}
                </div>
                <ValidationError
                  errors={errors}
                  touched={touched}
                  name="idType"
                />
              </fieldset>
            </div>
            <div className="py-3 text-right float-right">
              <Button type="submit" postIcon={ChevronRightIcon}>
                Save and proceed
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SelectIdType;
