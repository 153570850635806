/* eslint-disable import/order */
import React, { useEffect } from "react";
import { Field } from "formik";
import { subYears, format } from "date-fns";
import _get from "lodash/get";

import BoMaxValue from "./BoMaxValue";
import Input from "components/lib/Shared/Input";
import Radio from "components/lib/Shared/Radio";
import RadioGroup from "components/lib/Shared/RadioGroup";
import DatePickerField from "components/lib/Shared/DatePickerField";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import { roundNumber } from "utils";
import { Constants } from "config/constants";

const OfficialsBeneficialOwnershipComponent = (props) => {
  const {
    field,
    form,
    corporateIdType,
    isBOTab,
    disableChangeEffect,
    selectedShareholder,
    disableDirectFields,
    isReportable,
    disabled,
  } = props;

  let resolvedFields = props?.resolvedFields
    ? props.resolvedFields
    : field.value?.resolvedFields ?? [];

  const minDate = subYears(new Date(), 1);
  const {
    errors,
    touched,
    setFieldValue,
    values: { comments },
  } = form;
  const handleDateValue = (date) => {
    setFieldValue(field.name, {
      ...field.value,
      dateOfBecomingBO: format(date, "yyyy/MM/dd"),
    });
  };

  const {
    maxPercentShareholding,
    maxPercentVotingRights,
    indirectPercentShareholding,
    directPercentShareholding,
    indirectPercentVotingRights,
    directPercentVotingRights,
    directRightRemoveDirector,
    indirectRightRemoveDirector,
    directCompanyControlRight,
    indirectCompanyControlRight,
    dateOfBecomingBO,
    sourceOfBOInformation,
  } = field?.value ?? {};

  // temporary update null values to empty strings
  useEffect(() => {
    setFieldValue("beneficialOwnershipForm", {
      ...field?.value,
      maxPercentShareholding:
        maxPercentShareholding !== null ? maxPercentShareholding : "",
      maxPercentVotingRights:
        maxPercentVotingRights !== null ? maxPercentVotingRights : "",
      directPercentShareholding:
        directPercentShareholding !== null ? directPercentShareholding : "",
      indirectPercentShareholding:
        indirectPercentShareholding !== null ? indirectPercentShareholding : "",
      directPercentVotingRights:
        directPercentVotingRights !== null ? directPercentVotingRights : "",
      indirectPercentVotingRights:
        indirectPercentVotingRights !== null ? indirectPercentVotingRights : "",
      dateOfBecomingBO: dateOfBecomingBO !== null ? dateOfBecomingBO : "",
    });
  }, []);

  const options = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const sourceOfBOInformationOptions = Constants.SOURCE_OF_BO_INFO_OPTIONS;

  const selectedDirectRightRemoveDirector = options.find(
    (option) => option.value === directRightRemoveDirector
  );

  const selectedIndirectRightRemoveDirector = options.find(
    (option) => option.value === indirectRightRemoveDirector
  );

  const selectedDirectCompanyControlRight = options.find(
    (option) => option.value === directCompanyControlRight
  );

  const selectedIndirectCompanyControlRight = options.find(
    (option) => option.value === indirectCompanyControlRight
  );

  const selectedSourceOfBOInformation = sourceOfBOInformationOptions.find(
    (option) => option.value === sourceOfBOInformation
  );

  const handleChange = ({ target: { value, name, max }, currentTarget }) => {
    setFieldValue(name, roundNumber(value));
    if (!disableChangeEffect)
      setFieldValue(
        currentTarget.getAttribute("applychangeeffectto"),
        roundNumber(max - value)
      );
  };

  return (
    <div className="grid gap-4">
      <div className="space-y-2">
        <h3 className="font-medium">
          1. What percentage of the shareholding do you hold in the company?
        </h3>
        <div className="grid gap-2">
          <BoMaxValue value={maxPercentShareholding} />
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Field
                name="beneficialOwnershipForm.directPercentShareholding"
                type="number"
                component={Input}
                placeholder="Eg. 10%"
                label="Directly"
                showRequiredAsterisk
                errorPath="beneficialOwnershipForm.directPercentShareholding"
                commentPath="beneficialOwnershipForm.percentShareholding"
                onChange={handleChange}
                value={directPercentShareholding?.toString()}
                min={0}
                max={
                  isBOTab
                    ? maxPercentShareholding - indirectPercentShareholding
                    : maxPercentShareholding
                }
                step="0.01"
                applychangeeffectto="beneficialOwnershipForm.indirectPercentShareholding"
                disabled={
                  corporateIdType === "Company Limited By Guarantee/NGO" ||
                  disableDirectFields ||
                  maxPercentShareholding === 0 ||
                  resolvedFields.includes("directPercentShareholding") ||
                  disabled
                }
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="Indirectly"
                showRequiredAsterisk
                name="beneficialOwnershipForm.indirectPercentShareholding"
                type="number"
                placeholder="Eg. 10%"
                errorPath="beneficialOwnershipForm.indirectPercentShareholding"
                commentPath=""
                onChange={handleChange}
                value={indirectPercentShareholding?.toString()}
                min={0}
                max={
                  isBOTab
                    ? maxPercentShareholding - directPercentShareholding
                    : maxPercentShareholding
                }
                step="0.01"
                applychangeeffectto="beneficialOwnershipForm.directPercentShareholding"
                disabled={
                  corporateIdType === "Company Limited By Guarantee/NGO" ||
                  maxPercentShareholding === 0 ||
                  resolvedFields.includes("indirectPercentShareholding") ||
                  disabled
                }
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="space-y-2">
        <h3 className="font-medium">
          2. What percentage of the voting rights do you exercise in the
          company?
        </h3>
        <div className="grid gap-2">
          <BoMaxValue value={maxPercentVotingRights} />
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="Directly"
                showRequiredAsterisk
                name="beneficialOwnershipForm.directPercentVotingRights"
                errorPath="beneficialOwnershipForm.directPercentVotingRights"
                commentPath="beneficialOwnershipForm.percentVotingRights"
                type="number"
                onChange={handleChange}
                value={directPercentVotingRights?.toString()}
                min={0}
                max={
                  isBOTab
                    ? maxPercentVotingRights - indirectPercentVotingRights
                    : maxPercentVotingRights
                }
                step="0.01"
                applychangeeffectto="beneficialOwnershipForm.indirectPercentVotingRights"
                placeholder="Eg. 10%"
                disabled={
                  disableDirectFields ||
                  maxPercentVotingRights === 0 ||
                  resolvedFields.includes("directPercentVotingRights") ||
                  disabled
                }
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Field
                component={Input}
                label="Indirectly"
                showRequiredAsterisk
                type="number"
                name="beneficialOwnershipForm.indirectPercentVotingRights"
                errorPath="beneficialOwnershipForm.indirectPercentVotingRights"
                commentPath=""
                onChange={handleChange}
                value={indirectPercentVotingRights?.toString()}
                min={0}
                max={
                  isBOTab
                    ? maxPercentVotingRights - directPercentVotingRights
                    : maxPercentVotingRights
                }
                step="0.01"
                applychangeeffectto="beneficialOwnershipForm.directPercentVotingRights"
                placeholder="Eg. 10%"
                disabled={
                  maxPercentVotingRights === 0 ||
                  resolvedFields.includes("indirectPercentVotingRights") ||
                  disabled
                }
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="space-y-2">
        <h3 className="font-medium">
          3. Do you have a right to appoint/remove a director in the company?
        </h3>
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-6 sm:col-span-3">
            <RadioGroup
              label="Directly"
              showRequiredAsterisk
              errors={errors}
              touched={touched}
              comments={_get(
                comments,
                "beneficialOwnershipForm.rightRemoveDirector",
                []
              )}
              name="beneficialOwnershipForm.directRightRemoveDirector"
            >
              <Radio
                name="beneficialOwnershipForm.directRightRemoveDirector"
                id="yesDirectRightRemoveDirector"
                label="Yes"
                value="yes"
                defaultChecked={selectedDirectRightRemoveDirector}
                disabled={
                  disableDirectFields ||
                  resolvedFields.includes("directRightRemoveDirector") ||
                  disabled
                }
              />
              <Radio
                name="beneficialOwnershipForm.directRightRemoveDirector"
                id="nodirectRightRemoveDirector"
                label="No"
                value="no"
                defaultChecked={selectedDirectRightRemoveDirector}
                disabled={
                  disableDirectFields ||
                  resolvedFields.includes("directRightRemoveDirector") ||
                  disabled
                }
              />
            </RadioGroup>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <RadioGroup
              label="Indirectly"
              showRequiredAsterisk
              errors={errors}
              touched={touched}
              name="beneficialOwnershipForm.indirectRightRemoveDirector"
            >
              <Radio
                name="beneficialOwnershipForm.indirectRightRemoveDirector"
                id="yesIndirectRightRemoveDirector"
                label="Yes"
                value="yes"
                defaultChecked={selectedIndirectRightRemoveDirector}
                disabled={
                  selectedShareholder?.beneficialOwnershipForm
                    ?.indirectRightRemoveDirector === "no" ||
                  resolvedFields.includes("indirectRightRemoveDirector") ||
                  disabled
                }
              />
              <Radio
                name="beneficialOwnershipForm.indirectRightRemoveDirector"
                id="noIndirectRightRemoveDirector"
                label="No"
                value="no"
                defaultChecked={selectedIndirectRightRemoveDirector}
                disabled={
                  selectedShareholder?.beneficialOwnershipForm
                    ?.indirectRightRemoveDirector === "no" ||
                  resolvedFields.includes("indirectRightRemoveDirector") ||
                  disabled
                }
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <hr />
      <div className="space-y-2">
        <h3 className="font-medium">
          4. Do you exercise significant influence or control over the company?
        </h3>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <RadioGroup
              label="Directly"
              showRequiredAsterisk
              errors={errors}
              touched={touched}
              comments={_get(
                comments,
                "beneficialOwnershipForm.companyControlRight",
                []
              )}
              name="beneficialOwnershipForm.directCompanyControlRight"
            >
              <Radio
                name="beneficialOwnershipForm.directCompanyControlRight"
                id="yesDirectCompanyControlRight"
                label="Yes"
                value="yes"
                defaultChecked={selectedDirectCompanyControlRight}
                disabled={
                  disableDirectFields ||
                  resolvedFields.includes("directCompanyControlRight") ||
                  disabled
                }
              />
              <Radio
                name="beneficialOwnershipForm.directCompanyControlRight"
                id="noDirectCompanyControlRight"
                label="No"
                value="no"
                defaultChecked={selectedDirectCompanyControlRight}
                disabled={
                  disableDirectFields ||
                  resolvedFields.includes("directCompanyControlRight") ||
                  disabled
                }
              />
            </RadioGroup>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <RadioGroup
              label="Indirectly"
              showRequiredAsterisk
              errors={errors}
              touched={touched}
              name="beneficialOwnershipForm.indirectCompanyControlRight"
            >
              <Radio
                name="beneficialOwnershipForm.indirectCompanyControlRight"
                id="yesIndirectCompanyControlRight"
                label="Yes"
                value="yes"
                defaultChecked={selectedIndirectCompanyControlRight}
                disabled={
                  selectedShareholder?.beneficialOwnershipForm
                    ?.indirectCompanyControlRight === "no" ||
                  resolvedFields.includes("indirectCompanyControlRight") ||
                  disabled
                }
              />
              <Radio
                name="beneficialOwnershipForm.indirectCompanyControlRight"
                id="noIndirectCompanyControlRight"
                label="No"
                value="no"
                defaultChecked={selectedIndirectCompanyControlRight}
                disabled={
                  selectedShareholder?.beneficialOwnershipForm
                    ?.indirectCompanyControlRight === "no" ||
                  resolvedFields.includes("indirectCompanyControlRight") ||
                  disabled
                }
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <hr />
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <DatePickerField
            label="Effective Date Of Becoming B.O"
            showRequiredAsterisk={isReportable}
            selected={dateOfBecomingBO ? new Date(dateOfBecomingBO) : ""}
            onChange={handleDateValue}
            minDate={minDate}
            maxDate={new Date()}
            name="beneficialOwnershipForm.dateOfBecomingBO"
            form={form}
            placeholderText="Select date of becoming B.O"
            errorPath="beneficialOwnershipForm.dateOfBecomingBO"
            disabled={
              isReportable === false ||
              resolvedFields.includes("dateOfBecomingBO") ||
              disabled
            }
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <Field
            label="Source of beneficial owner information"
            showRequiredAsterisk
            value={selectedSourceOfBOInformation}
            name="beneficialOwnershipForm.sourceOfBOInformation"
            component={ReactSelectWithFormik}
            options={sourceOfBOInformationOptions}
            errorPath="beneficialOwnershipForm.sourceOfBOInformation"
            placeholder="Select source of beneficial owner information"
            isDisabled={
              resolvedFields.includes("sourceOfBOInformation") || disabled
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OfficialsBeneficialOwnershipComponent;
