import { Constants } from "config/constants";

const CrSharesAcquired = ({ classOfSharesHeaders = [] }) => {
  return (
    <table className="divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
      <thead className="text-xs">
        <tr>
          <th
            colspan={classOfSharesHeaders.length + 2}
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          >
            SHARES TRANSFERRED
          </th>
          <th
            colspan="4"
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          >
            Cr.
          </th>
        </tr>
        <tr style={{ height: "110px" }}>
          <th
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 rotate-180"
            style={{
              writingMode: "vertical-lr",
              textOrientation: "mixed",
            }}
          >
            No. of Transfer
          </th>
          <th
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 w-24"
          >
            Date of Entry of Transfer
          </th>
          <th
            scope="col"
            colspan={classOfSharesHeaders.length}
            className="font-semibold text-gray-900 bg-gray-100 w-24"
          >
            Total Number of Shares Transferred
          </th>
          <th
            scope="col"
            colspan="2"
            className="font-semibold text-gray-900 bg-gray-100 w-24"
          >
            Distinctive Numbers (if Used) (Inclusive)
          </th>
          <th
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 rotate-180"
            style={{
              writingMode: "vertical-lr",
              textOrientation: "mixed",
            }}
          >
            Transferee's Folio
          </th>
          <th scope="col" className="font-semibold text-gray-900 bg-gray-100">
            Total Value of Shares Transferred (KES)
          </th>
        </tr>
        <tr>
          <th
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          ></th>
          <th
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          ></th>
          {classOfSharesHeaders.map((classOfShare) => {
            return (
              <th
                scope="col"
                className="font-semibold text-gray-900 bg-gray-100 h-10"
              >
                {classOfShare.label}
              </th>
            );
          })}
          <th
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          >
            From
          </th>
          <th
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          >
            To
          </th>
          <th
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          ></th>
          <th
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          ></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {Array.from(new Array(Constants.REGISTERS.LEDGER_ROWS)).map(
          (_, index) => (
            <tr key={index} className="text-xs">
              <td
                className="p-1 text-gray-600 bg-gray-100"
                style={{ height: "26px" }}
              ></td>
              <td
                className="p-1 text-gray-600 bg-gray-100"
                style={{ height: "26px" }}
              ></td>
              {classOfSharesHeaders.map((classOfShare) => {
                return (
                  <td
                    key={classOfShare.label}
                    className="p-1 text-gray-600 bg-gray-100"
                    style={{ height: "26px" }}
                  ></td>
                );
              })}
              <td
                className="p-1 text-gray-600 bg-gray-100"
                style={{ height: "26px" }}
              ></td>
              <td
                className="p-1 text-gray-600 bg-gray-100"
                style={{ height: "26px" }}
              ></td>
              <td
                className="p-1 text-gray-600 bg-gray-100"
                style={{ height: "26px" }}
              ></td>
              <td
                className="p-1 text-gray-600 bg-gray-100"
                style={{ height: "26px" }}
              ></td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default CrSharesAcquired;
