import React, { useMemo } from "react";

import { roundNumber } from "utils";
import Table from "components/lib/Global/ReactTable";

function MinorityDetails({ data, currentTab }) {
  const columns = useMemo(
    () => [
      {
        Header: () => "Name",
        id: "Name",
        accessor: "fullName",
        Cell: ({ row }) => (
          <span>{`${row.original.remainingMinorityShareholders} Minority Shareholders`}</span>
        ),
      },
      {
        Header: () => "ID Type",
        id: "ID Type",
        accessor: "idType",
      },
      {
        Header: "Percentage Shareholding",
        Cell: ({ row: { original } }) => {
          if ("totalMinorityShareholdingPercent" in original)
            return (
              <span>{`${roundNumber(
                original.totalMinorityShareholdingPercent
              )}%`}</span>
            );
        },
      },
      {
        Header: "Percentage Voting",
        Cell: ({ row: { original } }) => {
          if ("totalMinorityVotingPercent" in original)
            return (
              <span>{`${roundNumber(
                original.totalMinorityVotingPercent
              )}%`}</span>
            );
        },
      },
    ],
    [currentTab]
  );

  return (
    <div className="w-full">
      <Table
        columns={columns}
        data={data}
        dataRowCellStyle="whitespace-normal"
        headingStyle="bg-sflPrimary text-white"
      />
    </div>
  );
}

export default MinorityDetails;
