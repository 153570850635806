import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (params) =>
  axios.get(`/discount-assignees?${getQueryString(params)}`);

const create = (data) => axios.post("/discount-assignees", data);

const remove = (discountAssigneeId) =>
  axios.delete(`/discount-assignees/${discountAssigneeId}`);

const getUser = (params) =>
  axios.get(`/discount-assignees/get-user?${getQueryString(params)}`);

const discountAssigneeService = {
  getAll,
  create,
  remove,
  getUser,
};

export default discountAssigneeService;
