/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
import React, { useState } from "react";
import _isEmpty from "lodash/isEmpty";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const index = (props) => {
  const [companyFormationStep, setCompanyFormationStep] = useState(1);

  const goToPreviousStep = () =>
    setCompanyFormationStep(companyFormationStep - 1);

  const goToNextStep = () => setCompanyFormationStep(companyFormationStep + 1);

  if (companyFormationStep === 1) {
    return (
      <Step1
        handleBackBtn={props.handleBackBtn}
        goToNextStep={goToNextStep}
        type={props.type}
      />
    );
  }
  if (companyFormationStep === 2) {
    return (
      <Step2 goToPreviousStep={goToPreviousStep} goToNextStep={goToNextStep} />
    );
  }
  if (companyFormationStep === 3) {
    return <Step3 goToPreviousStep={goToPreviousStep} {...props} />;
  }
};

export default index;
