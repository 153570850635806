import { format } from "date-fns";

const CrFolio = ({ shareholder = {} }) => {
  return (
    <div className="flex flex-col text-left h-[142px] justify-end">
      <div className="flex space-x-2">
        <span className="p-1 m-1 text-gray-600 bg-gray-100 w-64 font-medium">
          DATE OF ENTRY AS A MEMBER
        </span>
        <span className="p-1 m-1 text-gray-600 bg-gray-100 flex-1">
          {shareholder?.appointmentDate
            ? format(new Date(shareholder.appointmentDate), "dd-MM-yyyy")
            : ""}
        </span>
      </div>
      <div className="flex space-x-2">
        <span className="p-1 m-1 text-gray-600 bg-gray-100 w-64 font-medium">
          DATE OF CEASING TO BE MEMBER
        </span>
        <span className="p-1 m-1 text-gray-600 bg-gray-100 flex-1">
          {shareholder?.cessationDate
            ? format(new Date(shareholder.cessationDate), "dd-MM-yyyy")
            : ""}
        </span>
      </div>
    </div>
  );
};

export default CrFolio;
