import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { Formik, Field } from "formik";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import Tabs from "components/lib/Shared/Tabs";
import CommentsList from "components/lib/Global/CommentsList";
import Button from "components/lib/Shared/Button";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { getFormFields, getTransformedFormFields } from "utils";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";
import { addComment, editComment } from "state/slices/comment";

function PartnerSubRow({
  visibleColumns,
  row,
  hideResolveAllCheckbox,
  addReviewComment,
  toggleCommentStatus = true,
  readOnly,
}) {
  const currentTab = "ownership";
  const history = useHistory();
  const dispatch = useDispatch();
  const { partner } = useSelector((state) => state.organizationDashboardSlice);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const isViewMode =
    history.location.pathname.split("/").pop() === "view" || readOnly;

  const { organization } = useSelector((state) => state.organizationsSlice);
  const { comments } = useSelector((state) => state.commentsSlice);

  const stakeholderComments = comments.filter(
    (comment) =>
      comment?.officialId &&
      comment.officialId === row.original._id &&
      comment?.tabName &&
      comment.tabName === currentTab
  );

  const generalInformationComments = stakeholderComments.filter((comment) =>
    ["role", "names", "identificationDetails"].includes(comment.formName)
  );

  const residentialDetailsComments = stakeholderComments.filter((comment) =>
    [
      "postalAddress",
      "electronicAddress",
      "residentialAddressDetails",
      "isNonResident",
    ].includes(comment.formName)
  );

  const fileUploadComments = stakeholderComments.filter(
    (comment) => comment.formName === "uploadDocuments"
  );

  const official = row?.original ?? {};

  const tabs = useMemo(() => {
    return [
      {
        id: "generalInformation",
        label: "General information",
        commentsCount: generalInformationComments?.length,
      },
      {
        id: "residentialDetails",
        label: "Address details",
        commentsCount: residentialDetailsComments?.length,
      },
      {
        id: "uploadDocuments",
        label: "File Uploads",
        commentsCount: fileUploadComments?.length,
      },
    ];
  }, [comments]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  const partnerPathPrefix = `partnersSlice.${row.index}`;

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            organization: organization._id,
            officialId: row.original._id,
            tabName: currentTab,
          },
        })
      );
    }
  };

  const fileUploads = row.original?.fileUploads ?? {};

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "generalInformation" && (
          <>
            {generalInformationComments &&
              generalInformationComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={generalInformationComments}
                    toggleCommentStatus={toggleCommentStatus}
                    paginate
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments: {generalInformationComments.length} of{" "}
                    {generalInformationComments.length}
                  </p>
                </div>
              )}
            <FormFieldsCard
              addReviewComment={addReviewComment}
              formHelperKey={
                INDIVIDUALS.includes(official?.idType)
                  ? "individualOfficial"
                  : "corporateOfficial"
              }
              formName="role"
              formId={_get(row.original, "_id")}
              updateEffectKey="updateOrganizationPartnersState"
              updateEffectPath={partnerPathPrefix}
              resolvedFields={_get(row.original, "resolvedFields")}
              fields={[
                {
                  key: "Role",
                  name: "role",
                  value: row.original.role?.join(", ") ?? "N/A",
                },
              ]}
              hideResolveAllCheckbox={hideResolveAllCheckbox}
              handleSaveComment={handleSaveComment}
              comments={generalInformationComments.filter(
                (comment) => comment.formName === "role"
              )}
            />
            <FormFieldsCard
              addReviewComment={addReviewComment}
              formName="names"
              formHelperKey="names"
              formId={_get(row.original.names, "_id")}
              updateEffectKey="updateOrganizationPartnersState"
              updateEffectPath={`${partnerPathPrefix}.names`}
              resolvedFields={_get(row.original.names, "resolvedFields")}
              fields={getFormFields({
                idType: official.idType,
                formType: "names",
                formValues: official.names,
              })}
              hideResolveAllCheckbox={hideResolveAllCheckbox}
              handleSaveComment={handleSaveComment}
              comments={generalInformationComments.filter(
                (comment) => comment.formName === "names"
              )}
            />
            <FormFieldsCard
              addReviewComment={addReviewComment}
              formName="identificationDetails"
              formHelperKey="identificationDetails"
              formId={_get(row.original.identificationDetails, "_id")}
              updateEffectKey="updateOrganizationPartnersState"
              updateEffectPath={`${partnerPathPrefix}.identificationDetails`}
              resolvedFields={_get(
                row.original.identificationDetails,
                "resolvedFields"
              )}
              fields={getFormFields({
                idType: official.idType,
                formType: "identificationDetails",
                formValues: official.identificationDetails,
              })}
              hideResolveAllCheckbox={hideResolveAllCheckbox}
              handleSaveComment={handleSaveComment}
              comments={generalInformationComments.filter(
                (comment) => comment.formName === "identificationDetails"
              )}
            />
            {[
              "Local Company",
              "Foreign Company",
              "Other e.g Parastatals",
            ].includes(official?.idType) && (
              <FormFieldsCard
                addReviewComment={addReviewComment}
                formName="identificationDetails"
                formHelperKey="identificationDetails"
                formId={_get(row.original.identificationDetails, "_id")}
                updateEffectKey="updateOrganizationPartnersState"
                updateEffectPath={`${partnerPathPrefix}.identificationDetails`}
                resolvedFields={_get(
                  row.original.identificationDetails,
                  "resolvedFields"
                )}
                fields={getFormFields({
                  idType: official.idType,
                  formType: "contactPersonInfo",
                  formValues: official.identificationDetails,
                })}
                hideResolveAllCheckbox={hideResolveAllCheckbox}
                handleSaveComment={handleSaveComment}
                comments={generalInformationComments.filter(
                  (comment) => comment.formName === "identificationDetails"
                )}
              />
            )}
          </>
        )}
        {selectedTab.id === "residentialDetails" && (
          <>
            {residentialDetailsComments &&
              residentialDetailsComments.length > 0 && (
                <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                  <CommentsList
                    comments={residentialDetailsComments}
                    toggleCommentStatus={toggleCommentStatus}
                    paginate
                  />
                  <p className="mt-6 text-sm text-primary">
                    Comments: {residentialDetailsComments.length} of{" "}
                    {residentialDetailsComments.length}
                  </p>
                </div>
              )}
            {official?.idType === "Kenyan Citizen" && (
              <FormFieldsCard
                addReviewComment={addReviewComment}
                formHelperKey="individualOfficial"
                formName="isNonResident"
                formId={_get(row.original, "_id")}
                updateEffectKey="updateOrganizationPartnersState"
                updateEffectPath={partnerPathPrefix}
                resolvedFields={_get(row.original, "resolvedFields")}
                fields={[
                  {
                    key: "Non-Resident",
                    name: "isNonResident",
                    value: official.isNonResident ?? "N/A",
                  },
                ]}
                hideResolveAllCheckbox={hideResolveAllCheckbox}
                handleSaveComment={handleSaveComment}
                comments={residentialDetailsComments.filter(
                  (comment) => comment.formName === "isNonResident"
                )}
              />
            )}
            <FormFieldsCard
              addReviewComment={addReviewComment}
              formName="postalAddress"
              formHelperKey="postalAddress"
              formId={_get(row.original.postalAddress, "_id")}
              updateEffectKey="updateOrganizationPartnersState"
              updateEffectPath={`${partnerPathPrefix}.postalAddress`}
              resolvedFields={_get(
                row.original.postalAddress,
                "resolvedFields"
              )}
              fields={getFormFields({
                idType:
                  official?.isNonResident && official?.isNonResident === "Yes"
                    ? "Foreigner"
                    : official?.idType,
                formType: "postalAddress",
                formValues: official?.postalAddress,
              })}
              hideResolveAllCheckbox={hideResolveAllCheckbox}
              handleSaveComment={handleSaveComment}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "postalAddress"
              )}
            />
            <FormFieldsCard
              addReviewComment={addReviewComment}
              formName="electronicAddress"
              formHelperKey="electronicAddress"
              formId={_get(row.original.electronicAddress, "_id")}
              updateEffectKey="updateOrganizationPartnersState"
              updateEffectPath={`${partnerPathPrefix}.electronicAddress`}
              resolvedFields={_get(
                row.original.electronicAddress,
                "resolvedFields"
              )}
              fields={getFormFields({
                idType: official.idType,
                formType: "electronicAddress",
                formValues: official.electronicAddress,
              })}
              hideResolveAllCheckbox={hideResolveAllCheckbox}
              handleSaveComment={handleSaveComment}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "electronicAddress"
              )}
            />
            <FormFieldsCard
              addReviewComment={addReviewComment}
              formName="residentialAddressDetails"
              formHelperKey="residentialAddressDetails"
              formId={_get(row.original.residentialAddressDetails, "_id")}
              updateEffectKey="updateOrganizationPartnersState"
              updateEffectPath={`${partnerPathPrefix}.residentialAddressDetails`}
              resolvedFields={_get(
                row.original.residentialAddressDetails,
                "resolvedFields"
              )}
              fields={getFormFields({
                idType:
                  official.isNonResident && official.isNonResident === "Yes"
                    ? "Foreigner"
                    : official.idType,
                formType: "residentialAddressDetails",
                formValues: official.residentialAddressDetails,
              })}
              hideResolveAllCheckbox={hideResolveAllCheckbox}
              handleSaveComment={handleSaveComment}
              comments={residentialDetailsComments.filter(
                (comment) => comment.formName === "residentialAddressDetails"
              )}
            />
          </>
        )}
        {selectedTab.id === "uploadDocuments" && (
          <>
            {fileUploadComments && fileUploadComments.length > 0 && (
              <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                <CommentsList
                  comments={fileUploadComments}
                  toggleCommentStatus={toggleCommentStatus}
                  paginate
                />
                <p className="mt-6 text-sm text-primary">
                  Comments: {fileUploadComments.length} of{" "}
                  {fileUploadComments.length}
                </p>
              </div>
            )}
            <div className="px-8 pt-5 pb-6 mt-6 bg-white">
              <h3 className="mb-2 text-lg">
                {_isEmpty(fileUploads)
                  ? "No documents found"
                  : "Uploaded documents"}
              </h3>
              <Formik
                enableReinitialize
                initialValues={{ fileUploads }}
                onSubmit={() => null}
              >
                {() => {
                  return (
                    <div className="pl-4">
                      <Field
                        addReviewComment={addReviewComment}
                        formHelperKey={
                          INDIVIDUALS.includes(official?.idType)
                            ? "individualOfficial"
                            : "corporateOfficial"
                        }
                        formName="uploadDocuments"
                        formId={_get(row.original, "_id")}
                        updateEffectKey="updateOrganizationPartnersState"
                        updateEffectPath={partnerPathPrefix}
                        resolvedFields={_get(row.original, "resolvedFields")}
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={getDocumentData(row.original.idType)}
                        onlyPreview
                        showDocumentLabel
                        showResolveAllFieldsCheckbox={!hideResolveAllCheckbox}
                        handleSaveComment={handleSaveComment}
                        fileUploadComments={fileUploadComments}
                      />
                    </div>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
        <div className="flex justify-between">
          {!isFirstStep && (
            <Button
              onClick={goToPrevStep}
              preIcon={ChevronLeftIcon}
              variant="text"
              className="flex items-start float-right py-2 font-semibold text-primary my-2"
            >
              Go back
            </Button>
          )}
          {isLastStep ? (
            <Button
              onClick={() => row.toggleRowExpanded()}
              postIcon={!isViewMode && ChevronRightIcon}
              className="flex items-center px-4 py-2 mb-2 font-semibold text-white rounded bg-green-focused my-2"
            >
              {isViewMode ? "Close" : "Confirm details"}
            </Button>
          ) : (
            <Button
              onClick={goToNextStep}
              variant="text"
              postIcon={ChevronRightIcon}
              className="flex py-2 ml-auto text-primary"
            >
              <p className="font-semibold">
                {isViewMode ? "Next step" : "Review next step"}
              </p>
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
}

export default PartnerSubRow;
