/* eslint-disable no-param-reassign */
import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/api`,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.request.use((config) => {
  if (!window.navigator.onLine) {
    return Promise.reject({
      messageText: "No internet connection.",
    });
  }

  // Retrieve the socketId from sessionStorage
  const socketId = window.sessionStorage.getItem("socketId");

  // Add the socketId as a header to the request
  if (socketId) {
    config.headers["x-socket-id"] = socketId;
  }

  try {
    // Get the Auth0 access token
    const auth0AccessToken = window.localStorage.getItem("token");
    // Add the Auth0 access token as a header to the request
    config.headers.Authorization = `Bearer ${auth0AccessToken}`;
  } catch (error) {
    Cookies.remove("role");
    localStorage.clear();
    window.location.href = "/login";
  }

  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 403) {
      window.location.href = "/access-denied";
    }
    if (error?.response?.status === 401) {
      Cookies.remove("role");
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error?.response?.data ?? error);
  }
);

export default instance;
