import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _orderBy from "lodash/orderBy";
import _get from "lodash/get";

import Tabs from "components/lib/Shared/Tabs";
import { calculateCumulativeOwnership, groupBeneficialOwners } from "utils";
import BeneficialOwnersTableReview from "./BeneficialOwnersTableReview";
import {
  CORPORATES,
  INDIVIDUALS,
} from "components/PLCTABS/SFLdata/config/constants";

const tabs = [
  { id: "reportableBO", label: "Reportable BO" },
  { id: "nonReportableBO", label: "Non-Reportable BO" },
];

const BeneficialOwnership = (props) => {
  const { groupedBeneficialOwners } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const beneficialOwners =
    props.beneficialOwners ||
    useSelector((state) => state.beneficialOwnersTableSlice.beneficialOwners);

  const [reportableBO, setReportableBO] = useState([]);
  const [nonReportableBO, setNonReportableBO] = useState([]);

  useEffect(() => {
    let tempReportableBO = [];
    let tempNonReportableBO = [];
    if (beneficialOwners.length > 0) {
      beneficialOwners.forEach((bo) => {
        const {
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = bo?.beneficialOwnershipForm ?? {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";
        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";

        // hide eleventh minority entry on BO table
        if (bo.idType === "Minority") {
          bo = {
            ...bo,
            hidden: true,
          };
        }

        if (!bo?.hidden === true) {
          const parentBO = bo?.shareholderWithBO
            ? beneficialOwners.find(
                (official) => official._id === bo.shareholderWithBO
              )
            : {};
          if (
            INDIVIDUALS.includes(bo.idType) &&
            bo.isReportable &&
            (CORPORATES.includes(parentBO?.idType)
              ? isDirectBeneficiary
              : isDirectBeneficiary || isIndirectBeneficiary) &&
            bo.isEstateDistributionDetermined !== "No" &&
            bo.idType !== "Estate"
          ) {
            tempReportableBO.push(bo);
          } else tempNonReportableBO.push(bo);
        }
      });
    }

    const verifyKeys = [
      "identificationDetails.nationalIDNumber",
      "identificationDetails.foreignCertificateID",
      "identificationDetails.passportNumber",
      "identificationDetails.birthCertificateNumber",
    ];

    let filteredTempReportableBOs = [...tempReportableBO];
    let filteredTempNonReportableBOs = [...tempNonReportableBO];
    // if one of the multiple instances is reportable shift non-reportable instances to reportable table
    verifyKeys.map((verifyKey) => {
      tempNonReportableBO.map((bo) => {
        // check if current bo has reportable entry/instance
        const hasReportableInstance = tempReportableBO.find(
          (reportableBoRow) =>
            _get(reportableBoRow, verifyKey) &&
            _get(reportableBoRow, verifyKey) === _get(bo, verifyKey)
        );
        if (hasReportableInstance) {
          filteredTempReportableBOs.push(bo);
          const boIndex = filteredTempNonReportableBOs.findIndex(
            (bo) =>
              _get(bo, verifyKey) === _get(hasReportableInstance, verifyKey)
          );
          filteredTempNonReportableBOs.splice(boIndex, 1);
        }
      });
    });

    filteredTempReportableBOs = groupBeneficialOwners(
      _orderBy(
        filteredTempReportableBOs,
        ["shareholderWithBO", "linkToCompany"],
        ["desc", "desc"]
      )
    );

    const jointShareholdersNonReportableBOs =
      filteredTempNonReportableBOs.filter(
        (official) => official.idType === "Joint Shareholder"
      );

    filteredTempNonReportableBOs = groupBeneficialOwners(
      _orderBy(
        filteredTempNonReportableBOs.filter(
          (official) => official.idType !== "Joint Shareholder"
        ),
        ["shareholderWithBO", "linkToCompany"],
        ["desc", "desc"]
      )
    );

    tempReportableBO = filteredTempReportableBOs;
    tempNonReportableBO = filteredTempNonReportableBOs;

    const reportableBOAfterCumulative = tempNonReportableBO
      .filter((bo) => INDIVIDUALS.includes(bo.idType))
      .filter((bo) => {
        const { cumulativeShareholdings, cumulativeVotings } =
          calculateCumulativeOwnership(bo, beneficialOwners);
        if (cumulativeShareholdings >= 10 || cumulativeVotings >= 10) {
          const indexOfBO = tempNonReportableBO.findIndex(
            (beneficialOwner) => beneficialOwner._id === bo._id
          );
          filteredTempNonReportableBOs.splice(indexOfBO, 1);
          return true;
        }
      });

    setReportableBO([
      ...filteredTempReportableBOs,
      ...reportableBOAfterCumulative,
    ]);
    setNonReportableBO([
      ...filteredTempNonReportableBOs,
      ...jointShareholdersNonReportableBOs,
    ]);
  }, [beneficialOwners, setReportableBO, setNonReportableBO]);

  const selectedTab = tabs[selectedTabIndex];
  let tableData =
    selectedTab.id === "reportableBO" ? reportableBO : nonReportableBO;

  if (groupedBeneficialOwners?.totalCount > 0) {
    tableData =
      selectedTab.id === "reportableBO"
        ? groupedBeneficialOwners?.reportableBOs
        : groupedBeneficialOwners?.nonReportableBOs;
  }

  return (
    <>
      {/* Reportable and Non-Reportable BO tabs */}
      <div className="mb-2 sm:-mt-4">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </div>
      {/* Reportable and Non-Reportable BO table */}
      {tableData.length === 0 ? (
        <p className="text-center">No data available</p>
      ) : (
        <BeneficialOwnersTableReview
          data={tableData}
          showActionColumn
          selectedTableType={selectedTab.id}
          hideEditBtn
          hideDeleteBtn
          showDocumentLabel
          {...props}
        />
      )}
    </>
  );
};

export default BeneficialOwnership;
