import React from "react";
import { useSelector } from "react-redux";
import { ChevronDownIcon } from "@heroicons/react/solid";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _cloneDeep from "lodash/cloneDeep";

import CommentsList from "components/lib/Global/CommentsList";
import ReactTable from "components/lib/Shared/ReactTable";
import { flattenObject, getFullName } from "utils";

const comments = [
  "Malesuada tellus tincidunt fringilla enim, id mauris. Id etiam nibh suscipit aliquam dolor. .....",
  "Malesuada tellus tincidunt fringilla enim, id mauris. Id etiam nibh suscipit aliquam dolor. .....",
  "Malesuada tellus tincidunt fringilla enim, id mauris. Id etiam nibh suscipit aliquam dolor. .....",
  "Malesuada tellus tincidunt fringilla enim, id mauris. Id etiam nibh suscipit aliquam dolor. .....",
  "Malesuada tellus tincidunt fringilla enim, id mauris. Id etiam nibh suscipit aliquam dolor. .....",
];

const StakeholderDetails = () => {
  const originalCompanyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );

  const companyOfficials = originalCompanyOfficials.map((co) =>
    flattenObject(co)
  );

  const JSGroups = new Set();
  companyOfficials.forEach((item) => {
    if (item.JSGroupID) JSGroups.add(item.JSGroupID);
  });

  const allJS = [...JSGroups].map((id) => {
    const names = [];
    let combinedJS = {};
    companyOfficials.forEach((official) => {
      if (official.JSGroupID === id) {
        names.push(getFullName(official));
        if (official.sharesAllotted && official.sharesAllotted.length > 0)
          combinedJS.sharesAllotted = official.sharesAllotted;
        combinedJS = {
          ...combinedJS,
          ..._omit(_cloneDeep(official), ["sharesAllotted"]),
          designation: "Joint Shareholder",
          idType: "Joint Shareholder",
        };
        combinedJS.fullName = names.map((name, index) => {
          if (names.length === 2 && index === 0) {
            return `${name} & `;
          }
          if (names.length > 2 && index === names.length - 2) {
            return `${name} & `;
          }

          if (
            names.length > 2 &&
            index !== names.length - 2 &&
            index !== names.length - 1
          ) {
            return `${name}, `;
          }
          return name;
        });
      }
    });
    return combinedJS;
  });

  const data = [
    ...companyOfficials.filter(
      (item) =>
        (item.designation === "Joint Shareholder" &&
          item.isDirector === "Yes") ||
        item.designation !== "Joint Shareholder"
    ),
    ...allJS.filter((item) => !_isEmpty(item)),
  ];

  const transformSharesAllotted = (sharesAllotted) =>
    sharesAllotted
      .map(
        ({ classOfShares, allottedShares }) =>
          `<div>${classOfShares}: ${allottedShares}</div>`
      )
      .join("");

  const columns = [
    {
      Header: "Full Name",
      accessor: "fullName",
      Cell: ({ row: { original } }) => getFullName(original),
    },
    { Header: "ID Type", accessor: "idType" },
    {
      Header: "Designation",
      Cell: ({ row: { original } }) => {
        return Array.isArray(original.fullName)
          ? "Joint Shareholder"
          : original.isDirector === "Yes"
          ? "Director"
          : original.designation;
      },
    },
    {
      Header: "Shares Allotted",
      Cell: ({ row }) => {
        if (
          row.original?.isDirector === "Yes" &&
          !Array.isArray(row.original?.fullName)
        )
          return "";
        return (
          <span
            className="space-y-1"
            dangerouslySetInnerHTML={{
              __html: transformSharesAllotted(
                row.original?.sharesAllotted ?? []
              ),
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="mt-6">
      <p className="mb-5">
        Please review the following comments before you can proceed to the next
        step.
      </p>
      <div className="px-8 pt-5 pb-6 mb-10 bg-white">
        <CommentsList comments={comments} />
        <p className="mt-6 text-sm text-primary">Comments: 5 of 10</p>
      </div>
      <p className="mt-12 font-semibold text-primary">Current appointments</p>
      <div className="flex items-end justify-between mb-10">
        <div className="relative text-gray-600">
          <button
            type="submit"
            className="absolute left-0 ml-2 transform -translate-y-1/2 top-1/2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="512"
              height="512"
              x="0"
              y="0"
              className="w-4 h-4 fill-current text-primary"
              enableBackground="new 0 0 56.966 56.966"
              version="1.1"
              viewBox="0 0 56.966 56.966"
              xmlSpace="preserve"
            >
              <path d="M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z"></path>
            </svg>
          </button>
          <input
            className="pr-20 text-sm bg-white border-2 border-gray-300 rounded h-9 px-7 focus:outline-none"
            name="search"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="flex items-center float-right mb-5">
        <p className="float-right text-sm text-primary">Row per page: 10</p>
        <ChevronDownIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
      </div>
      {/* <StakeholderTable /> */}
      <div className="w-full mt-4">
        <div className="w-full overflow-x-auto">
          <ReactTable
            columns={columns}
            data={data}
            headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
            headingStyle="pl-4 text-left"
            dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          />
        </div>
      </div>
    </div>
  );
};

export default StakeholderDetails;
