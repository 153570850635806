import { useSelector } from "react-redux";
import _get from "lodash/get";
import { format } from "date-fns";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import CommentsList from "components/lib/Global/CommentsList";
import { getFormFields } from "utils";

function GeneralInformation({
  handleSaveComment,
  generalInformationComments,
  userPathPrefix = "orgAdminSlice.user",
  updateEffectKey = "updateUserStateForOrgAdmin",
  addReviewComment,
  toggleCommentStatus,
  isProfileReview,
}) {
  const { user } = useSelector((state) => state.orgAdminSlice);

  let dateOfBirth = _get(user.identificationDetails, "dateOfBirth");
  if (dateOfBirth) dateOfBirth = format(new Date(dateOfBirth), "yyyy/MM/dd");
  else dateOfBirth = "N/A";

  const { idType, names, identificationDetails } = user;

  return (
    <>
      {generalInformationComments && generalInformationComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 mt-6 bg-white">
          <CommentsList
            comments={generalInformationComments}
            toggleCommentStatus={toggleCommentStatus}
            isProfileReview={isProfileReview}
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {generalInformationComments.length} of{" "}
            {generalInformationComments.length}
          </p>
        </div>
      )}
      <FormFieldsCard
        handleSaveComment={handleSaveComment}
        fields={getFormFields({
          idType,
          formType: "names",
          formValues: names,
        })}
        resolvedFields={_get(user.names, "resolvedFields")}
        addReviewComment={addReviewComment}
        formName="names"
        formHelperKey="names"
        updateEffectKey={updateEffectKey}
        updateEffectPath={`${userPathPrefix}.names`}
        formId={_get(user.names, "_id")}
        comments={generalInformationComments.filter(
          (comment) => comment.formName === "names"
        )}
        isProfileReview={isProfileReview}
        rowId={user._id}
      />
      <FormFieldsCard
        handleSaveComment={handleSaveComment}
        fields={getFormFields({
          idType,
          formType: "identificationDetails",
          formValues: identificationDetails,
        })}
        resolvedFields={_get(user.identificationDetails, "resolvedFields")}
        addReviewComment={addReviewComment}
        formName="identificationDetails"
        formHelperKey="identificationDetails"
        updateEffectKey={updateEffectKey}
        updateEffectPath={`${userPathPrefix}.identificationDetails`}
        formId={_get(user.identificationDetails, "_id")}
        comments={generalInformationComments.filter(
          (comment) => comment.formName === "identificationDetails"
        )}
        isProfileReview={isProfileReview}
        rowId={user._id}
        files={user.fileUploads}
      />
    </>
  );
}

export default GeneralInformation;
