import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { format } from "date-fns";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { Constants } from "config/constants";
import options from "../PLCTABS/SFLdata/selectOptions";
import { updateUser } from "state/slices/user";
import SectionDescription from "components/lib/Shared/SectionDescription";
import ProfileLabel from "components/lib/Shared/ProfileLabel";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import { getFullName } from "utils";
import Button from "components/lib/Shared/Button";

const formFields = [
  "names",
  "identificationDetails",
  "postalAddress",
  "electronicAddress",
  "residentialAddressDetails",
  "fileUploads",
];

function ConfirmDetails({ handleBackBtn }) {
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [accessType, setAccessType] = useState("");
  const history = useHistory();
  const { currentUser } = useSelector((state) => state.authSlice);
  const userSlice = useSelector((state) => state.userSlice);
  const accessRequests = useSelector(
    (state) => state.accessRequestsSlice.items
  );
  const {
    idType,
    names,
    identificationDetails,
    electronicAddress,
    fileUploads,
  } = userSlice;
  const dispatch = useDispatch();

  const handleAccessType = (e) => setAccessType(e.target.value);

  const filteredAccessRequestForProfile = accessRequests.find(
    (accessRequest) =>
      accessRequest.initiatedToEmail &&
      !accessRequest?.isActive &&
      accessRequest?.status === "Pending" &&
      Constants.ACCESS_REQUEST.PERSONAL_DATA === accessRequest.requestFor
  );

  const filteredAccessRequestForCoApplicant = accessRequests.find(
    (accessRequest) =>
      accessRequest.initiatedToEmail &&
      !accessRequest?.isActive &&
      accessRequest?.status === "Pending" &&
      Constants.ACCESS_REQUEST.CO_APPLICANT === accessRequest.requestFor
  );

  const filteredAccessRequestForCorporate = accessRequests.find(
    (accessRequest) =>
      accessRequest.initiatedToEmail &&
      !accessRequest?.isActive &&
      accessRequest?.status === "Pending" &&
      accessRequest.requestFor === Constants.ACCESS_REQUEST.CORPORATE_DATA
  );

  const activeAccessRequestForProfileOrCoApplicant =
    filteredAccessRequestForProfile || filteredAccessRequestForCoApplicant;

  useEffect(() => {
    setAccessType(activeAccessRequestForProfileOrCoApplicant?.accessType);
  }, [activeAccessRequestForProfileOrCoApplicant]);

  const handleSubmit = async () => {
    const newUserData = {};
    if (!userSlice?.isProfileApprovedByUser) {
      newUserData.isProfileApprovedByUser = true;
    }

    if (
      userSlice?.idType &&
      currentUser?.idType &&
      userSlice?.idType !== currentUser?.idType
    ) {
      newUserData.idType = userSlice?.idType;
    }

    if (
      userSlice?.isNonResident &&
      userSlice?.isNonResident !== currentUser?.isNonResident
    ) {
      newUserData.isNonResident = userSlice?.isNonResident;
    }

    formFields.map((formField) => {
      if (!_isEqual(userSlice[formField], currentUser[formField])) {
        newUserData[formField] = userSlice[formField];
      }
    });

    if (filteredAccessRequestForCoApplicant) {
      newUserData["profileType"] = "Co Applicant";
    }

    if (!_isEmpty(newUserData)) {
      try {
        await dispatch(
          updateUser({
            id: currentUser._id,
            data: {
              ...newUserData,
              idType: userSlice.idType,
              initiatedToEmail:
                activeAccessRequestForProfileOrCoApplicant?.initiatedToEmail,
            },
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
    if (history.location.pathname === "/profile/edit") {
      history.push("/profile");
    }
    if (
      activeAccessRequestForProfileOrCoApplicant ||
      filteredAccessRequestForCorporate
    ) {
      history.push("/account-settings");
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ fileUploads }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <SectionDescription
              id="Step: 5 of 5"
              title="Confirm your details"
              description=""
            />
            <div className="px-2 space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
              <div className="px-4 my-4 sm:px-0">
                <div>
                  <div className="grid gap-6">
                    <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                      <h3 className="font-medium leading-6 text-gray-900">
                        Personal details
                      </h3>
                      <div className="flex flex-col space-y-2">
                        <ProfileLabel
                          label="Full names"
                          value={getFullName(names) || "N/A"}
                          className="w-64"
                        />
                        {idType &&
                          Constants.ON_BOARDING_FORM_FIELDS?.[
                            idType
                          ]?.identificationDetails?.map((formField) => {
                            let value =
                              identificationDetails?.[formField.key] ?? "N/A";
                            if (formField.isDate && value && value !== "N/A") {
                              value = format(new Date(value), "yyyy/MM/dd");
                            }

                            if (formField.isCountry) {
                              value = options.countries.find(
                                (country) => country.value === value
                              )?.label;
                            }

                            return (
                              <ProfileLabel
                                label={formField?.label}
                                value={value}
                                key={formField.key}
                                className="w-64"
                              />
                            );
                          })}
                      </div>
                    </div>
                    <div>
                      <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                        <h3 className="my-4 text-lg font-medium leading-6 text-gray-900">
                          Electronic address
                        </h3>
                        <div className="flex flex-col space-y-2">
                          <ProfileLabel
                            label="Email address"
                            value={electronicAddress?.emailAddress ?? "N/A"}
                            className="w-64"
                          />
                          <ProfileLabel
                            label="Mobile number"
                            value={electronicAddress?.mobileNumber ?? "N/A"}
                            className="w-64"
                          />
                        </div>
                      </div>
                    </div>
                    {!_isEmpty(fileUploads) && (
                      <div>
                        <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                          <h3 className="text-lg font-medium leading-6 text-gray-900">
                            File uploads
                          </h3>
                          <div className="flex flex-col space-y-2">
                            <Field
                              name="fileUploads"
                              component={FileUploadForm}
                              documentData={getDocumentData(idType)}
                              onlyPreview
                              showDocumentLabel
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeAccessRequestForProfileOrCoApplicant && (
                      <>
                        <div className="flex items-center form-check">
                          <input
                            className="float-left mr-4 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border rounded appearance-none cursor-pointer form-check-input border-tertiary checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                            type="checkbox"
                            id="flexCheckDefault"
                            defaultChecked={agreeToTerms}
                            onChange={({ target: { checked } }) =>
                              setAgreeToTerms(checked)
                            }
                          />
                          <label
                            className="inline-block form-check-label text-tertiary"
                            htmlFor="flexCheckDefault"
                          >
                            Your profile needs to be reviewed and verified by
                            the applicant. Click the checkbox to accept the said
                            terms and conditions.
                            <sup className="text-red-500">*</sup>
                          </label>
                        </div>
                        {agreeToTerms && (
                          <div className="mt-4 bg-white">
                            <h4 className="text-lg font-medium leading-6 text-gray-900">
                              Invite
                            </h4>
                            <p className="mt-1 text-sm text-gray-500">
                              We recommend giving going with the one time invite
                              option to make future application process easier.
                            </p>
                            <div className="pl-2 m-2">
                              <p className="mt-1 text-sm text-gray-500">
                                Please select whether you would like to:
                              </p>
                              <div className="p-4 mt-2 space-y-2 bg-gray-100 rounded-md">
                                <div className="flex items-center">
                                  <input
                                    name="accessType"
                                    type="radio"
                                    id="recurring"
                                    value="Recurring"
                                    onChange={handleAccessType}
                                    defaultChecked={accessType === "Recurring"}
                                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                                  />
                                  <label
                                    htmlFor="recurring"
                                    className="block ml-3 text-sm font-medium text-gray-700"
                                  >
                                    (Recurring access). Authorize{" "}
                                    {
                                      activeAccessRequestForProfileOrCoApplicant
                                        ?.createdBy?.names?.firstName
                                    }{" "}
                                    to have a access to my data for this
                                    application and any future applications.
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    name="accessType"
                                    type="radio"
                                    id="oneOff"
                                    value="One Off"
                                    onChange={handleAccessType}
                                    defaultChecked={accessType === "One Off"}
                                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                                  />
                                  <label
                                    htmlFor="oneOff"
                                    className="block ml-3 text-sm font-medium text-gray-700"
                                  >
                                    (One-time access). Authorize{" "}
                                    {
                                      activeAccessRequestForProfileOrCoApplicant
                                        ?.names?.firstName
                                    }{" "}
                                    to have a access to my data for this
                                    application only.
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between py-3">
              <Button
                disabled={isSubmitting}
                onClick={handleBackBtn}
                overrideBaseStyles
                className="flex items-center px-2 py-2 button button-back button-back-hover button-focus button-disabled button-outline"
              >
                <div className="mr-1.5 flex items-center">
                  <ChevronLeftIcon />
                  Back to previous step
                </div>
              </Button>
              <Button
                type="submit"
                disabled={
                  isSubmitting ||
                  (activeAccessRequestForProfileOrCoApplicant && !agreeToTerms)
                }
                loadingText="Saving..."
                isLoading={isSubmitting}
              >
                <div className="ml-1.5 flex items-center">
                  Save and proceed
                  <ChevronRightIcon />
                </div>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ConfirmDetails;
