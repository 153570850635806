import { date, object, string } from "yup";

const PersonalInformationValidation = (idType) => {
  let schema = object().shape({
    names: object({
      firstName: string()
        .trim("First name cannot include leading and trailing spaces")
        .strict(true)
        .required("First name is required"),
      middleName: string()
        .trim("Middle name cannot include leading and trailing spaces")
        .strict(true)
        .optional(),
      lastName: string()
        .trim("Last name cannot include leading and trailing spaces")
        .strict(true)
        .required("Last name is required"),
    }),
  });

  if (idType === "Kenyan Citizen") {
    const kenyanCitizenSchema = object().shape({
      identificationDetails: object({
        nationalIDNumber: string()
          .trim("National ID number cannot include leading and trailing spaces")
          .strict(true)
          .required("National ID number is required")
          .min(6, "National ID must contain at least six characters")
          .max(8, "National ID cannot be more than eight characters")
          .test("len", "National ID can only be a number", (value) =>
            /^\d+$/.test(value)
          ),
      }),
    });
    schema = schema.concat(kenyanCitizenSchema);
  }

  if (idType === "Foreign Resident") {
    const foreignResidentSchema = object().shape({
      identificationDetails: object({
        foreignCertificateID: string()
          .trim(
            "Foreign certificate ID cannot include leading and trailing spaces"
          )
          .strict(true)
          .required("Foreign certificate ID is required"),
        foreignCertificateDateOfExpiry: date().required(
          "Id date of expiry is required"
        ),
      }),
    });
    schema = schema.concat(foreignResidentSchema);
  }

  if (idType === "Foreigner") {
    const foreignerSchema = object().shape({
      identificationDetails: object({
        passportNumber: string()
          .trim("Passport cannot include leading and trailing spaces")
          .strict(true)
          .required("Passport number is required"),
        passportDateOfExpiry: date().required(
          "Passport date of expiry is required"
        ),
      }),
    });
    schema = schema.concat(foreignerSchema);
  }

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default PersonalInformationValidation;
