/* eslint-disable space-before-blocks */
const COMPANY_SECRETARY_FOREIGN_RESIDENT = [
  {
    label: "Passport Photo(On A White Background)",
    name: "passportPhoto",
    accept: "image/jpeg, image/jpg, image/png",
  },
  { label: "Passport(BIOS PAGE)", name: "passportBIOS", optional: true },
  { label: "Current Work Permit", name: "workPermit", optional: true },
  { label: "KRA Certificate", name: "KRACert" },
  { label: "Foreigner Certificate", name: "foreignerCert" },
  { label: "ICPSK Practicing Certificate", name: "practicingCert" },
];

const REGULATED_ARTICLES = [{ label: "Choose File", name: "Choose File" }];
const SPECIAL_INSTRUCTIONS = [{ label: "Choose File", name: "Choose File" }];

const COMPANY_SECRETARY_KENYAN = [
  {
    label: "Passport Photo(On A White Background)",
    name: "passportPhoto",
    accept: "image/jpeg, image/jpg, image/png",
  },
  { label: "National Id", name: "nationalID" },
  { label: "KRA PIN Certificate", name: "KRACert" },
  { label: "ICPSK Practicing Certificate", name: "practicingCert" },
];

const COMPANY_SECRETARY_LOCAL_COMPANY = [
  { label: "Certificate Of Incorporation", name: "certOfIncorporation" },
  {
    label: "Certificate Of Incumbency",
    name: "certOfIncumbency",
    optional: true,
  },
  { label: "KRA PIN Certificate", name: "KRAPIN" },
  {
    label: " ICPSK Practicing Certificate For Lead Partner",
    name: "leadPartnerCertificate",
  },
];

const FOREIGNER = [
  {
    label: "Passport Photo(On A White Background)",
    name: "passportPhoto",
    accept: "image/jpeg, image/jpg, image/png ",
  },
  { label: "Passport(BIOS PAGE)", name: "passportBIOS" },
];

const FOREIGN_COMPANY = [
  { label: "Certificate Of Incorporation", name: "certOfIncorporation" },
  {
    label: "Memorandum and Articles Of Association",
    name: "articlesOfAssociation",
  },
  {
    label: "Certificate Of Incumbency",
    name: "certOfIncumbency",
    optional: true,
  },
];

const FOREIGN_RESIDENT = [
  {
    label: "Upload Passport Photo(On White-Background)",
    name: "passportPhoto",
    accept: "image/jpeg, image/jpg, image/png",
  },
  { label: "Foreigner Certificate", name: "foreignerCert" },
  { label: "KRA Certificate", name: "KRACert" },
  { label: "Current Work Permit", name: "workPermit", optional: true },
];

const KENYAN_CITIZEN = [
  {
    label: "Upload Passport Photo(On White Background)",
    name: "passportPhoto",
    accept: "image/jpeg, image/jpg, image/png",
  },
  { label: "National Id", name: "nationalID" },
  { label: "KRA Certificate", name: "KRACert" },
];

const MINOR = [
  {
    label: "Passport Photo(On White Background)",
    name: "passportPhoto",
    accept: "image/jpeg, image/jpg, image/png",
  },
  { label: "Birth Certificate", name: "birthCertificate" },
];

const LOCAL_COMPANY = [
  { label: "Certificate Of Incorporation", name: "certOfIncorporation" },
  { label: "KRA Certificate", name: "KRACert" },
  {
    label: "Memorandum And Articles Of Association",
    name: "articlesOfAssociation",
  },
  {
    label: "Official Search Results(FORM CR12)",
    name: "searchResults",
    optional: true,
  },
  {
    label: "Certificate Of Incumbency",
    name: "certOfIncumbency",
    optional: true,
  },
];

const RESIGNATION_OFFICIAL = [
  {
    label: "Letter of Resignation | Template",
    name: "letterOfResignation",
    accept: "image/jpeg, image/jpg, image/png",
  },
  {
    label: "Statutory Declaration | Template",
    name: "statutoryDeclaration",
    accept: "image/jpeg, image/jpg, image/png",
    optional: true,
  },
  {
    label: "Extract of Board Resolution | Template",
    name: "extractOfBoardResolution",
    accept: "image/jpeg, image/jpg, image/png",
    optional: true,
  },
];

const OTHER = [{ label: "Gazette Notice", name: "gazetteNotice" }];

const getEstateDocumentData = (isEstateDistributed) => {
  let estateDocuments = [
    { label: "Copy of ID Card", name: "copyOfIDCard" },
    { label: "Copy of Death Certificate", name: "copyOfDeathCertificate" },
  ];
  if (isEstateDistributed === "Yes") {
    estateDocuments = [
      ...estateDocuments,
      {
        label: "Certificate of Confirmation of Probate",
        name: "certificateOfConfirmationOfProbate",
      },
      {
        label: "Copy of Will/Schedule of Distribution)",
        name: "copyOfWill/ScheduleOfDistribution)",
      },
    ];
  }
  return estateDocuments;
};

const getOnboardingDocuments = (idType) => {
  let documentData = [];
  switch (idType) {
    case "Kenyan Citizen":
      documentData = [
        {
          label: "Upload Passport Photo(On White Background)",
          name: "passportPhoto",
          accept: "image/jpeg, image/jpg, image/png",
        },
        { label: "National Id", name: "nationalID" },
      ];
      break;
    case "Foreign Resident":
      documentData = [
        {
          label: "Upload Passport Photo(On White-Background)",
          name: "passportPhoto",
          accept: "image/jpeg, image/jpg, image/png",
        },
        { label: "Foreigner Certificate", name: "foreignerCert" },
      ];
      break;
    case "Foreigner":
      documentData = FOREIGNER;
      break;
    default:
      documentData = [];
  }
  return documentData;
};

const getDocumentData = (idType) => {
  let documentData = [];
  switch (idType) {
    case "Kenyan Citizen":
      documentData = KENYAN_CITIZEN;
      break;
    case "Foreign Resident":
      documentData = FOREIGN_RESIDENT;
      break;
    case "Foreigner":
      documentData = FOREIGNER;
      break;
    case "Local Company":
      documentData = LOCAL_COMPANY;
      break;
    case "Other e.g Parastatals":
      documentData = OTHER;
      break;
    case "Foreign Company":
      documentData = FOREIGN_COMPANY;
      break;
    case "Minor":
      documentData = MINOR;
      break;
    default:
      documentData = [];
  }
  return documentData;
};

const getCompanySecretaryDocumentData = (idType) => {
  let documentData = [];
  switch (idType) {
    case "Kenyan Citizen":
      documentData = COMPANY_SECRETARY_KENYAN;
      break;
    case "Foreign Resident":
      documentData = COMPANY_SECRETARY_FOREIGN_RESIDENT;
      break;
    case "Other":
      documentData = OTHER;
      break;
    case "Local Company":
      documentData = COMPANY_SECRETARY_LOCAL_COMPANY;
      break;
    default:
      documentData = [];
  }
  return documentData;
};

const getBusinessDocumentData = () => {
  return [
    { label: "Form CR1", name: "formCR1", accept: "application/pdf" },
    { label: "Form CR2", name: "formCR2", accept: "application/pdf" },
    {
      label: "Statement of Nominal Capital",
      name: "statementOfNominalCapital",
      accept: "application/pdf",
    },
    {
      label: "Articles of Association",
      name: "articlesOfAssociation",
      accept: "application/pdf",
    },
    { label: "Form CR8", name: "formCR8", accept: "application/pdf" },
    { label: "Form CR10", name: "formCR10", accept: "application/pdf" },
    { label: "Form CR12", name: "formCR12", accept: "application/pdf" },
    {
      label: "Form BOF1",
      name: "formBOF1",
      accept: "application/pdf",
      optional: true,
    },
  ];
};

export {
  COMPANY_SECRETARY_FOREIGN_RESIDENT,
  COMPANY_SECRETARY_KENYAN,
  COMPANY_SECRETARY_LOCAL_COMPANY,
  FOREIGNER,
  FOREIGN_COMPANY,
  FOREIGN_RESIDENT,
  KENYAN_CITIZEN,
  MINOR,
  LOCAL_COMPANY,
  OTHER,
  REGULATED_ARTICLES,
  SPECIAL_INSTRUCTIONS,
  RESIGNATION_OFFICIAL,
  getDocumentData,
  getOnboardingDocuments,
  getEstateDocumentData,
  getCompanySecretaryDocumentData,
  getBusinessDocumentData,
};
