import { object, string } from "yup";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";

/**
 * This returns an object composed of conditional `yup` schema and initial values
 * related to identification form.
 *
 * @param {string} idType - The idType of the current official e.g `"Kenyan Citizen"`.
 * @return {object} This returns object containing schema and initial values based on schema.
 * @example
 *  {
 *    schema
 *    schemaInitialValues
 *  }
 */
const identificationValidation = (idType) => {
  let schema = object()
    .shape({
      names: object()
        .shape()
        .when("idType", {
          is: () => INDIVIDUALS.includes(idType),
          then: object()
            .shape({
              firstName: string()
                .max(50, "Too Long!")
                .trim("First name cannot include leading and trailing spaces")
                .strict(true)
                .when("$makeFieldsOptional", {
                  is: (makeFieldsOptional) => makeFieldsOptional,
                  then: string().optional(),
                  otherwise: string().required("First name is required"),
                }),
              middleName: string()
                .max(50, "Too Long!")
                .trim("Middle name cannot include leading and trailing spaces")
                .strict(true)
                .optional(),
              lastName: string()
                .max(50, "Too Long!")
                .trim("Last name cannot include leading and trailing spaces")
                .strict(true)
                .when("$makeFieldsOptional", {
                  is: (makeFieldsOptional) => makeFieldsOptional,
                  then: string().optional(),
                  otherwise: string().required("Last name is required"),
                }),
            })
            .default({ firstName: "", middleName: "", lastName: "" }),
          otherwise: object().shape({
            companyName: string()
              .trim("Company name cannot include leading and trailing spaces")
              .strict(true)
              .when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: string().optional(),
                otherwise: string().required("Company name is required"),
              }),
            companyRegNumber: string()
              .trim(
                "Company registration cannot include leading and trailing spaces"
              )
              .strict(true)
              .when("$makeFieldsOptional", {
                is: (makeFieldsOptional) => makeFieldsOptional,
                then: string().optional(),
                otherwise: string().required(
                  "Company registration number is required"
                ),
              }),
            dateOfRegistration: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required(
                "Company registration date is required"
              ),
            }),
          }),
        }),
    })
    .default({});

  const commonIndividualIdentificationDetails = object().shape({
    identificationDetails: object({
      dateOfBirth: string().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: string().optional(),
        otherwise: string().required("Date of birth is required"),
      }),
      gender: string().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: string().optional(),
        otherwise: string().required("Gender is required"),
      }),
    }),
  });

  if (idType === "Kenyan Citizen") {
    const kenyanCitizenSchema = object().shape({
      identificationDetails: object({
        nationalIDNumber: string()
          .trim("National ID number cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("National ID number is required"),
          })
          .min(6, "National ID must contain at least six characters")
          .max(8, "National ID cannot be more than eight characters")
          .test("len", "National ID can only be a number", (value) =>
            /^\d+$/.test(value)
          ),
        KRAPin: string()
          .trim("KRA PIN cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("KRA PIN is required"),
          })
          .min(11, "Must be exactly 11 characters")
          .max(11, "Must be exactly 11 characters"),
        occupation: string()
          .trim("Occupation cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Occupation is required"),
          }),
        nationality: string().default("KE"),
      }),
    });
    schema = schema.concat(
      commonIndividualIdentificationDetails.concat(kenyanCitizenSchema)
    );
  }

  if (idType === "Foreign Resident") {
    const foreignResidentSchema = object().shape({
      identificationDetails: object({
        KRAPin: string()
          .trim("KRA PIN cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("KRA PIN is required"),
          })
          .min(11, "Must be exactly 11 characters")
          .max(11, "Must be exactly 11 characters"),
        occupation: string()
          .trim("Occupation cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Occupation is required"),
          }),
        foreignCertificateID: string()
          .trim(
            "Foreign certificate ID cannot include leading and trailing spaces"
          )
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Foreign certificate ID is required"),
          }),
        nationality: string()
          .trim("Nationality cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Nationality is required"),
          }),
      }),
    });
    schema = schema.concat(
      commonIndividualIdentificationDetails.concat(foreignResidentSchema)
    );
  }

  if (idType === "Foreigner") {
    const foreignerSchema = object().shape({
      identificationDetails: object({
        passportNumber: string()
          .trim("Passport cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Passport number is required"),
          }),
        passportDateOfExpiry: string().when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Passport date of expiry is required"),
        }),
        occupation: string()
          .trim("Occupation cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Occupation is required"),
          }),
        nationality: string()
          .trim("Nationality cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Nationality is required"),
          }),
      }),
    });
    schema = schema.concat(
      commonIndividualIdentificationDetails.concat(foreignerSchema)
    );
  }

  if (idType === "Minor") {
    const minorSchema = object().shape({
      identificationDetails: object({
        birthCertificateNumber: string()
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required(
              "Birth certificate number is required"
            ),
          })
          .test(
            "test",
            "Birth certificate number can only be a number",
            function (value) {
              if (!this.parent.nationality) return true;
              if (this.parent.nationality === "KE") return /^\d+$/.test(value);
              return true;
            }
          ),
        nationality: string()
          .trim("Nationality cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Nationality is required"),
          }),
        guardianFullName: string()
          .trim("Full name cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Guardian full name is required"),
          }),
        guardianRelationship: string()
          .trim(
            "Guardian relationship cannot include leading and trailing spaces"
          )
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Guardian relationship is required"),
          }),
        guardianMobileNumber: string()
          .trim("Mobile number cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Guardian mobile number is required"),
          }),
        guardianEmailAddress: string()
          .email("Invalid email format")
          .trim("Email cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Guardian email address is required"),
          }),
      }),
    });
    schema = schema.concat(
      commonIndividualIdentificationDetails.concat(minorSchema)
    );
  }

  if (
    ["Local Company", "Foreign Company", "Other e.g Parastatals"].includes(
      idType
    )
  ) {
    const companyContactPersonSchema = object().shape({
      identificationDetails: object({
        companyContactName: string()
          .trim("Name cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Name is required"),
          }),
        companyContactDesignation: string()
          .trim("Designation cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Designation is required"),
          }),
        telNumber: string()
          .trim("Telephone cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Telephone number is required"),
          }),
        emailAddress: string()
          .email("Invalid email format")
          .trim("Email cannot include leading and trailing spaces")
          .strict(true)
          .when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required("Email address is required"),
          }),
      }),
    });

    if (idType === "Local Company") {
      const localCompanySchema = object().shape({
        identificationDetails: object({
          companyKRAPIN: string()
            .trim("KRA PIN cannot include leading and trailing spaces")
            .strict(true)
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("KRA PIN is required"),
            })
            .min(11, "Must be exactly 11 characters")
            .max(11, "Must be exactly 11 characters"),
          companyFormerName: string()
            .trim("Former name cannot include leading and trailing spaces")
            .strict(true)
            .optional(),
          countryOfIncorporation: string()
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required(
                "Country of Incorporation is required"
              ),
            })
            .default("KE"),
        }),
      });
      schema = schema.concat(
        companyContactPersonSchema.concat(localCompanySchema)
      );
    }

    if (idType === "Foreign Company") {
      const foreignCompanySchema = object().shape({
        identificationDetails: object({
          countryOfIncorporation: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required(
              "Country of incorporation is required"
            ),
          }),
          companyFormerName: string().optional(),
        }),
      });
      schema = schema.concat(
        companyContactPersonSchema.concat(foreignCompanySchema)
      );
    }

    if (idType === "Other e.g Parastatals") {
      const parastatalsSchema = object().shape({
        identificationDetails: object({
          companyKRAPIN: string()
            .trim("KRA PIN cannot include leading and trailing spaces")
            .strict(true)
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("KRA PIN is required"),
            })
            .min(11, "Must be exactly 11 characters")
            .max(11, "Must be exactly 11 characters"),
          governingLaw: string()
            .trim("Governing law cannot include leading and trailing spaces")
            .strict(true)
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Governing law is required"),
            }),
          countryOfIncorporation: string()
            .when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required(
                "Country of incorporation is required"
              ),
            })
            .default("KE"),
        }),
      });
      schema = schema.concat(
        companyContactPersonSchema.concat(parastatalsSchema)
      );
    }
  }

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default identificationValidation;
