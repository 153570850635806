import React from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { twMerge } from "tailwind-merge";

function SearchInput({
  id,
  srLabel = "Search",
  type = "search",
  name = "search",
  placeholder = "Search by name",
  className = "",
  value,
  handleOnChange = () => null,
  ...restProps
}) {
  return (
    <div className={twMerge("w-full max-w-lg lg:max-w-xs", className)}>
      <label htmlFor="search" className="sr-only">
        {srLabel}
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <SearchIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          id={id || name}
          name={name}
          className="block w-full py-2 pl-10 pr-3 leading-5 placeholder-gray-400 border border-gray-200 rounded-md focus:outline-none focus:bg-white focus:border-indigo-500 focus:ring-indigo-500 focus:text-gray-900 sm:text-sm"
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={handleOnChange}
          {...restProps}
        />
      </div>
    </div>
  );
}

export default SearchInput;
