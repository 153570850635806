/* eslint-disable function-paren-newline */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from "react";
import { useFormikContext, Field } from "formik";
import { addMonths, format, subYears } from "date-fns";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DatePickerField from "components/lib/Shared/DatePickerField";
import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "../../../../../PLCTABS/SFLdata/selectOptions";

// Identification Details for Foreigner
const IdentificationDetailsFormFo = ({ field, idType }) => {
  const minDateOfExpiry = addMonths(new Date(), 1);
  const maxDateOfBirth = subYears(new Date(), 18);

  const [DOBDate, setDOBDate] = useState("");
  const [DOEDate, setDOEDate] = useState("");

  const { identificationDetails, isOnboarded } = useSelector(
    (state) => state.userSlice
  );
  const { setFieldValue } = useFormikContext();
  const history = useHistory();

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  const countriesOptions = useMemo(() => {
    return options.countries.map((country) => {
      if (
        ["Foreign Resident", "Foreigner", "Foreign Company"].includes(idType) &&
        country.value === "KE"
      ) {
        return { ...country, disabled: true };
      }
      return country;
    });
  }, [idType, options.countries]);

  const handleSelect = (type) => (option) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
  };

  const handleDateValue = (type) => (date) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: format(date, "yyyy/MM/dd"),
    });
    if (type === "passportDateOfExpiry") setDOEDate(date);
    else setDOBDate(date);
  };

  const selectedDOB =
    field.value && field.value.dateOfBirth
      ? new Date(field.value.dateOfBirth)
      : DOBDate;

  const selectedDOE =
    field.value && field.value.passportDateOfExpiry
      ? new Date(field.value.passportDateOfExpiry)
      : DOEDate;

  return (
    <>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <DatePickerField
            label="Date of Birth"
            onChange={handleDateValue("dateOfBirth")}
            selected={selectedDOB}
            maxDate={maxDateOfBirth}
            name="dateOfBirth"
            placeholderText="Select date of birth"
            errorPath="identificationDetails.dateOfBirth"
            disabled={true}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Passport Number"
            name="passportNumber"
            placeholder="Enter passport number"
            value={field.value?.passportNumber ?? ""}
            errorPath="identificationDetails.passportNumber"
            disabled={true}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <DatePickerField
            label="Passport Date Of Expiry"
            onChange={handleDateValue("passportDateOfExpiry")}
            selected={selectedDOE}
            minDate={minDateOfExpiry}
            name="passportDateOfExpiry"
            placeholderText="Select date of expiry"
            errorPath="identificationDetails.passportDateOfExpiry"
            disabled={false}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Occupation"
            name="occupation"
            placeholder="Enter occupation"
            value={field.value?.occupation ?? ""}
            errorPath="identificationDetails.occupation"
            onBlur={field.onBlur}
            onChange={handleSetValue}
            disabled={false}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Field
            {...field}
            label="Nationality"
            value={field?.value?.nationality ?? ""}
            name="nationality"
            component={ReactSelectWithFormik}
            options={countriesOptions}
            errorPath="identificationDetails.nationality"
            placeholder="Select nationality"
            onBlur={field.onBlur}
            onChange={handleSelect("nationality")}
            isSearchable
            isDisabled={true}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Field
            {...field}
            label="Gender"
            value={field?.value?.gender ?? ""}
            name="gender"
            component={ReactSelectWithFormik}
            options={options.gender}
            errorPath="identificationDetails.gender"
            placeholder="Select gender"
            onBlur={field.onBlur}
            onChange={handleSelect("gender")}
            isDisabled={true}
          />
        </div>
      </div>
    </>
  );
};

export default IdentificationDetailsFormFo;
