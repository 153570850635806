/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _set from "lodash/set";

import organizationDashboardDataService from "services/organizationDashboard";

export const getPartner = createAsyncThunk(
  "organizationDashboard/getPartner",
  async ({ organizationId, partnerId, idType }, thunkAPI) => {
    try {
      const res = await organizationDashboardDataService.getPartner(
        organizationId,
        partnerId,
        idType
      );
      if (res) {
        return res.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOfficial = createAsyncThunk(
  "organizationDashboard/getOfficial",
  async ({ corporateId, officialId, idType }, thunkAPI) => {
    try {
      const res = await organizationDashboardDataService.getOfficial(
        corporateId,
        officialId,
        idType
      );
      if (res) {
        return res.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  partner: null,
  official: null,
  getPartnerStatus: {
    status: "idle",
    error: false,
  },
  getOfficialStatus: {
    status: "idle",
    error: false,
  },
};

const organizationDashboardSlice = createSlice({
  name: "organizationDashboard",
  initialState,
  reducers: {
    updatePartnerState: (state, action) => {
      state.partner = { ...state.partner, ...action.payload };
    },
    updatePartnerNestedField: (
      state,
      { payload: { newNestedField, updateEffectPath } }
    ) => {
      return _set(state, updateEffectPath.split(".").slice(1), newNestedField);
    },
    resetPartner: (state) => {
      state.partner = null;
      state.getPartnerStatus = {
        status: "idle",
        error: false,
      };
    },
    resetOfficial: (state) => {
      state.official = null;
      state.getOfficialStatus = {
        status: "idle",
        error: false,
      };
    },
  },
  extraReducers: {
    [getPartner.pending]: (state) => {
      state.getPartnerStatus.status = "loading";
      state.getPartnerStatus.error = false;
    },
    [getPartner.fulfilled]: (state, action) => {
      state.getPartnerStatus.status = "idle";
      state.getPartnerStatus.error = false;
      state.partner = { ...state.partner, ...action.payload.partner };
    },
    [getPartner.rejected]: (state, action) => {
      state.getPartnerStatus.status = "failed";
      state.getPartnerStatus.error = action.payload.messageText;
    },

    [getOfficial.pending]: (state) => {
      state.getOfficialStatus.status = "loading";
      state.getOfficialStatus.error = false;
    },
    [getOfficial.fulfilled]: (state, action) => {
      state.getOfficialStatus.status = "idle";
      state.getOfficialStatus.error = false;
      state.official = { ...state.official, ...action.payload.official };
    },
    [getOfficial.rejected]: (state, action) => {
      state.getOfficialStatus.status = "failed";
      state.getOfficialStatus.error = action.payload.messageText;
    },
  },
});

export const {
  updatePartnerState,
  updatePartnerNestedField,
  resetPartner,
  resetOfficial,
} = organizationDashboardSlice.actions;

const { reducer } = organizationDashboardSlice;

export default reducer;
