import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftIcon, CheckCircleIcon } from "@heroicons/react/outline";

import Button from "components/lib/Shared/Button";
import { updatePackageState } from "state/slices/package";
import { formatCurrency, getCompletePackageName } from "utils";

function PackageView({ setShowAddPackageForm }) {
  const dispatch = useDispatch();
  const { selectedPackage } = useSelector((state) => state.packageSlice);

  if (!selectedPackage) return null;

  const handleBackBtn = () => {
    setShowAddPackageForm(false);
    dispatch(updatePackageState({ selectedPackage: {}, mode: "" }));
  };

  const features = selectedPackage.features;

  return (
    <div className="pl-4">
      <Button
        onClick={handleBackBtn}
        variant="text"
        className="flex space-x-2 text-sm font-medium rounded-md group text-gray-600 mb-2"
      >
        <ArrowLeftIcon
          className="w-5 h-5 text-gray-400 group-hover:text-gray-800"
          aria-hidden="true"
        />
        <span className="group-hover:text-gray-800">Back</span>
      </Button>
      <div className="grid grid-cols-3 place-content-end">
        <div className="col-span-2">
          <h2 className="font-semibold text-xl">
            {getCompletePackageName(selectedPackage)}
          </h2>
          <p className="text-sm text-gray-500 py-4">
            {selectedPackage.description}
          </p>
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div className="flex items-center space-x-2">
                <CheckCircleIcon className="w-5 h-5" />
                <p className="text-gray-800 text-lg">This package includes:</p>
              </div>
              <p className="text-sm text-gray-600 ml-8">
                Preparation of the Limited Company Formation Documents
              </p>
              <ul className="list-disc py-4 px-8 space-y-2">
                {features.map((feature) => {
                  return (
                    <li key={feature._id} className="ml-4">
                      <div className="flex justify-between items-center">
                        <span>{feature.description}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-span-1 ml-auto font-medium">
          {selectedPackage.currencies.map((currency) => {
            return (
              <div key={currency._id} className="">
                <span>Currency: </span>
                <span>
                  {currency.type}{" "}
                  {formatCurrency(
                    (currency.basePrice + (currency?.profitMargin ?? 0)) *
                      currency.applicableTax
                  )}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PackageView;
