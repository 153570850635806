/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useFormikContext, Field } from "formik";
import { subYears, format } from "date-fns";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DatePickerField from "components/lib/Shared/DatePickerField";
import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "../../SFLdata/selectOptions";

// Identification Details for Kenyan Citizen
const IdentificationDetailsKeCi = ({
  field,
  form,
  disabled,
  disableVerificationField,
  isOnboardingStage,
}) => {
  const maxDateOfBirth = subYears(new Date(), 18);

  const [startDate, setStartDate] = useState("");

  const { identificationDetails, isOnboarded } = useSelector(
    (state) => state.userSlice
  );
  const { setFieldValue } = useFormikContext();
  const history = useHistory();

  const handleSetValue = (e) => {
    const value =
      e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: value,
    });
  };

  if (isOnboardingStage) {
    return (
      <div className="col-span-6 sm:col-span-2">
        <Input
          label="National ID Number"
          name="nationalIDNumber"
          placeholder="Enter national id number"
          form={form}
          value={field.value?.nationalIDNumber ?? ""}
          errorPath="identificationDetails.nationalIDNumber"
          onBlur={field.onBlur}
          disabled={
            (identificationDetails?.nationalIDNumber &&
              isOnboarded &&
              history.location.pathname === "/profile/edit") ||
            disabled ||
            disableVerificationField
          }
          onChange={handleSetValue}
        />
      </div>
    );
  }

  const handleSelect = (type) => (option) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
  };

  const handleDateValue = (date) => {
    setFieldValue(field.name, {
      ...field.value,
      dateOfBirth: format(date, "yyyy/MM/dd"),
    });
    return setStartDate(date);
  };

  const selectedDOB =
    field.value && field.value.dateOfBirth
      ? new Date(field.value.dateOfBirth)
      : startDate;

  return (
    <>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <DatePickerField
            label="Date of Birth"
            onChange={handleDateValue}
            selected={selectedDOB}
            maxDate={maxDateOfBirth}
            form={form}
            name="dateOfBirth"
            placeholderText="Select date of birth"
            errorPath="identificationDetails.dateOfBirth"
            disabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="National ID Number"
            name="nationalIDNumber"
            placeholder="Enter national id number"
            form={form}
            value={field.value?.nationalIDNumber ?? ""}
            errorPath="identificationDetails.nationalIDNumber"
            onBlur={field.onBlur}
            disabled={
              (identificationDetails?.nationalIDNumber &&
                isOnboarded &&
                history.location.pathname === "/profile/edit") ||
              disabled ||
              disableVerificationField
            }
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="KRA PIN"
            name="KRAPin"
            placeholder="Enter KRA PIN"
            form={form}
            value={field.value?.KRAPin ?? ""}
            errorPath="identificationDetails.KRAPin"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Occupation"
            name="occupation"
            placeholder="Enter occupation"
            form={form}
            value={field.value?.occupation ?? ""}
            errorPath="identificationDetails.occupation"
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={handleSetValue}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Field
            {...field}
            label="Gender"
            onBlur={field.onBlur}
            onChange={handleSelect("gender")}
            value={field?.value?.gender ?? ""}
            name="gender"
            component={ReactSelectWithFormik}
            options={options.gender}
            errorPath="identificationDetails.gender"
            isDisabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Nationality"
            name="nationality"
            placeholder="KENYAN"
            form={form}
            value={"KENYAN"}
            errorPath="identificationDetails.nationality"
            onBlur={field.onBlur}
            onChange={handleSetValue}
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default IdentificationDetailsKeCi;
