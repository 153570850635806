import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import Button from "components/lib/Shared/Button";
import PackagesTable from "./Table";
import PackageForm from "./Form";
import PackageView from "./View";
import { getPackagesAsync, updatePackageState } from "state/slices/package";
import { Constants } from "config/constants";

const Packages = () => {
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();
  const [showAddPackageForm, setShowAddPackageForm] = useState(false);
  const { packages, selectedPackage, mode } = useSelector(
    (state) => state.packageSlice
  );

  useEffect(() => {
    dispatch(getPackagesAsync());
    return () => {
      dispatch(
        updatePackageState({
          selectedPackage: {
            features: [],
            currencies: [],
          },
        })
      );
    };
  }, []);

  if (mode === "view") {
    return <PackageView setShowAddPackageForm={setShowAddPackageForm} />;
  }

  if (showAddPackageForm || (selectedPackage?._id && mode === "edit")) {
    return <PackageForm setShowAddPackageForm={setShowAddPackageForm} />;
  }

  const handleAddNewPackage = () => {
    dispatch(
      updatePackageState({
        selectedPackage: {
          features: [],
          currencies: [],
        },
      })
    );
    setShowAddPackageForm(true);
  };

  return (
    <div className="pl-4">
      <div className="flex justify-between mb-4">
        <h3 className="text-lg font-semibold text-sflPrimary">Packages</h3>
        {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
          <Button onClick={handleAddNewPackage}>Add new package</Button>
        )}
      </div>
      <PackagesTable />
    </div>
  );
};

export default Packages;
