import React from "react";
import { useDispatch } from "react-redux";

import ModalWrapper from "components/lib/Shared/ModalWrapper";
import Button from "components/lib/Shared/Button";
import { closeModal } from "state/slices/modals";

function RequestConfirmationModal({
  modalName,
  handleShowAccessRequestTab = () => null,
}) {
  const dispatch = useDispatch();
  return (
    <ModalWrapper name={modalName} title="Confirm" maxWidth="sm:max-w-[50%]">
      <div className="my-8 text-lg">
        Are you sure you want to request access to the Company's officials
        restricted data. These details are restricted pursuant to the provisions
        of the Data Protection Act, Laws of kenya.
      </div>
      <hr />
      <div className="flex justify-between mt-4">
        <Button
          onClick={() => dispatch(closeModal(modalName))}
          variant="outline"
        >
          Cancel
        </Button>
        <Button
          onClick={handleShowAccessRequestTab}
          loadingText="Confirming..."
        >
          I confirm
        </Button>
      </div>
    </ModalWrapper>
  );
}

export default RequestConfirmationModal;
