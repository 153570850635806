import React from "react";

const ShareInformation = () => {
  return (
    <div
      className={`px-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6 text-center mt-4`}
    >
      No data
    </div>
  );
};

export default ShareInformation;
