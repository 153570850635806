import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import { getFullName, getGreetings } from "utils";
import Cookies from "js-cookie";
import { Constants } from "config/constants";

export const SystemAdminDashboardView = () => {
  const { items: organizations } = useSelector(
    (state) => state.organizationsSlice
  ).organizations;

  return (
    <div className="grid grid-cols-12 gap-8">
      <div className="col-span-9">
        <div>
          <div className="grid grid-cols-3 gap-6">
            <div className="p-6 space-y-4 bg-white rounded-lg text-primary shadow-card">
              <div className="mb-5">
                <h1 className="text-7xl font-base">
                  <span>{organizations.length}</span>
                </h1>
                <span className="text-xs font-bold uppercase">SUBMITTED</span>
                <hr className="w-full border-gray-200 my-1" />
              </div>
              <div className="grid gap-2">
                <div className="SubMenuLinks">
                  <Link to="/organizations">
                    <div className="flex justify-between text-xs uppercase">
                      <p>Draft</p>
                      <span>
                        {
                          organizations.filter(
                            ({ corporate }) => corporate.status === "Draft"
                          ).length
                        }
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="SubMenuLinks">
                  <Link to="/organizations">
                    <div className="flex justify-between text-xs uppercase">
                      <p>Review</p>
                      <span>
                        {
                          organizations.filter(
                            ({ corporate }) => corporate.status === "Review"
                          ).length
                        }
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="SubMenuLinks">
                  <Link to="/organizations">
                    <div className="flex justify-between text-xs uppercase">
                      <p>APPROVED</p>
                      <span>
                        {
                          organizations.filter(
                            ({ corporate }) => corporate.status === "Active"
                          ).length
                        }
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function AdminDashboard() {
  const currentUser = useSelector((state) => state.userSlice);
  const role = Cookies.get("role");

  return (
    <DashboardWrapper
      showSystemAdminBar={role === Constants.COOKIE_ROLES.SYSTEM_ADMIN}
    >
      <div className="text-sflPrimary">
        <div className="py-10">
          <div className="py-4 text-title5">
            <h2 className="font-semibold text-title5">
              {getGreetings()}, {getFullName(currentUser?.names ?? {})}
            </h2>
          </div>
          <SystemAdminDashboardView />
        </div>
      </div>
    </DashboardWrapper>
  );
}
