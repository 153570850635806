/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { PencilIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";

import ReactTable from "components/lib/Global/ReactTable";
import { Constants } from "config/constants";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";
import { capitalizeFirstLetter, getFullName } from "utils";
import { getCompaniesToReviewAsync } from "state/slices/tables/reviewersTable";
import SearchInput from "components/lib/Shared/SearchInput";
import { getCompanyNameWithSuffix } from "utils/company";

const ReviewApplicationList = ({ setSelectedCompany = () => null }) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const currentUser = useSelector((state) => state.userSlice);
  const { companiesToReview, getCompaniesToReview } = useSelector(
    (state) => state.reviewersTable
  );

  useEffect(() => {
    dispatch(
      getCompaniesToReviewAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        user: currentUser._id,
        applicationReviewed: false,
      })
    );
  }, [rowsPerPage, currentPage]);

  const columns = [
    {
      Header: "Full Name",
      accessor: "companyName1",
      Cell: ({ row: { original } }) => getCompanyNameWithSuffix(original),
    },
    {
      Header: "Application Type",
      accessor: "applicationType",
      Cell: ({ row }) => {
        let type = "N/A";
        if (CORPORATES.includes(row?.original?.idType)) {
          type = "Organization";
        } else type = row?.original?.type;
        return (
          <div className="inline-flex items-center justify-center w-full px-3 py-1 mx-auto text-sm font-medium text-white rounded-full bg-green-primary">
            {type}
          </div>
        );
      },
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      Cell: ({ row: { original } }) => {
        if (CORPORATES.includes(original?.idType)) {
          return getFullName(original.createdBy.names);
        }
        return capitalizeFirstLetter(
          original?.createdBy?.names?.firstName ?? "N/A"
        );
      },
    },
    {
      Header: "Individual Officials",
      Cell: ({ row: { original } }) =>
        original?.individualOfficials?.length ?? 0,
    },
    {
      Header: "Corporate Officials",
      Cell: ({ row: { original } }) =>
        original?.corporateOfficials?.length ?? 0,
    },
    {
      Header: "Date",
      Cell: ({ row }) => format(new Date(row.original.createdAt), "yyyy/MM/dd"),
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const handleReviewBtnClick = () => {
          setSelectedCompany({
            ...row.original,
            role: Constants.ROLES.REVIEWER,
          });
        };
        return (
          <button onClick={handleReviewBtnClick}>
            <span>Review</span>
            <PencilIcon className="inline w-4 h-4" />
          </button>
        );
      },
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full bg-white shadow">
        <div className="w-full">
          <ReactTable
            columns={columns}
            data={companiesToReview.items}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={companiesToReview.totalCount}
            setCurrentPage={setCurrentPage}
            paginate
            isLoading={getCompaniesToReview.status === "loading"}
            headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
            headingStyle="pl-4 text-left"
            dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewApplicationList;
