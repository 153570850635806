import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";
import Accordion from "components/lib/Global/Accordion";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import { addComment, editComment } from "state/slices/comment";

export default function CompanyFormation({ isViewMode }) {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.companySlice);
  const { comments } = useSelector((state) => state.commentsSlice);

  const articlesOfAssociationComments = comments.filter(
    (comment) => comment.formName === "articlesOfAssociation"
  );
  const businessApplicationComments = comments.filter(
    (comment) => comment.formName === "businessApplicationDetails"
  );

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            company: company._id,
          },
        })
      );
    }
  };

  const accordionTabs = [
    {
      title: "Step 1 of 3  : Preferred business/company name",
      commentsCount: comments.filter((comment) =>
        comment.formName?.includes(`preferredCompanyNameCard_`)
      ).length,
      content: (
        <FirstStep
          handleSaveComment={handleSaveComment}
          isViewMode={isViewMode}
        />
      ),
    },
    {
      title: "Step 2 of 3  :  Articles Of Association",
      commentsCount: articlesOfAssociationComments.length,
      content: (
        <SecondStep
          handleSaveComment={handleSaveComment}
          isViewMode={isViewMode}
        />
      ),
    },
    {
      title: "Step 3 of 3  :  Business application details",
      commentsCount: businessApplicationComments.length,
      content: (
        <ThirdStep
          handleSaveComment={handleSaveComment}
          isViewMode={isViewMode}
        />
      ),
    },
  ];

  return <Accordion tabs={accordionTabs} />;
}
