import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OnlyOfficeDocumentEditor from "components/lib/Shared/OnlyOfficeDocumentEditor";
import { classNames, getFullName } from "utils";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { updateTemplateState } from "state/slices/template";
import { updateNominalSharesSlice } from "state/slices/tables/nominalSharesTable";
import Button from "components/lib/Shared/Button";
import { Constants } from "config/constants";

function ShareRights({ tabs = [], toggle = () => null, activeTab, disabled }) {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="flex -mb-px space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <Button
                key={tab}
                disabled={disabled}
                overrideBaseStyles
                className={classNames(
                  tab === activeTab
                    ? "border-sflPrimary text-sflPrimary"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "cursor-pointer whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm disabled:cursor-not-allowed"
                )}
                onClick={() => (disabled ? null : toggle(tab))}
                aria-current={tab === activeTab ? "page" : undefined}
              >
                {tab}
              </Button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

const SharesCategoryTabs = (props) => {
  const { disabledRightsEditor, disableTabs } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const currentUser = useSelector((state) => state.userSlice);

  const shares =
    props.nominalShares ||
    useSelector((state) => state.sharesTableSlice.shares);
  const { activeTab } = useSelector((state) => state.sharesTableSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { selectedTemplate, getTemplateForApplicant, showOverlay } =
    useSelector((state) => state.templateSlice);

  const companyId = company?._id ?? params.companyId;

  const selectedShare = shares.find(
    (share) => share.sharesCategory === activeTab
  );

  const templateVersions = selectedTemplate?.versions
    ? selectedTemplate.versions
    : [];

  const latestTemplateVersion =
    templateVersions?.[templateVersions.length - 1] ?? {};

  useEffect(() => {
    dispatch(
      updateTemplateState({
        selectedTemplate: selectedShare?.rightsAttached,
      })
    );
  }, [selectedShare]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      dispatch(updateNominalSharesSlice({ activeTab: tab }));
    } else dispatch(updateNominalSharesSlice({ activeTab: "" }));
  };

  useEffect(() => {
    if (!selectedShare?._id && activeTab)
      dispatch(updateNominalSharesSlice({ activeTab: "" }));
  }, [shares, selectedShare, activeTab]);

  return (
    <div className="shadow sm:rounded-md sm:overflow-hidden relative">
      <div className="px-4 py-6 space-y-6 bg-white sm:p-6">
        <ShareRights
          tabs={props.categories}
          toggle={toggle}
          activeTab={activeTab}
          disabled={disableTabs}
        />
        <div className="outlet">
          {getTemplateForApplicant.status === "loading" || showOverlay ? (
            <div className="flex justify-center mx-auto mt-10 text-white">
              <SpinnerIcon className="text-gray-400" />
            </div>
          ) : (
            <>
              {!selectedTemplate?._id && activeTab ? (
                <p className="text-center font-medium">Not found</p>
              ) : (
                <>
                  {activeTab === Constants.ORDINARY_SHARES && (
                    <OnlyOfficeDocumentEditor
                      key={activeTab}
                      userFullName={getFullName(currentUser.names)}
                      title={activeTab}
                      mode="view"
                      userId={currentUser._id}
                      companyId={companyId}
                      url={
                        latestTemplateVersion?.fileUploads?.template
                          ? `${process.env.REACT_APP_IMAGE_BASE_URL}/templates/${latestTemplateVersion.fileUploads.template}`
                          : null
                      }
                      editorKey={`${selectedTemplate?._id}-${latestTemplateVersion?._id}`}
                      containerHeight="900px"
                    />
                  )}
                  {activeTab === Constants.PREFERENCE_SHARES && (
                    <OnlyOfficeDocumentEditor
                      key={activeTab}
                      userFullName={getFullName(currentUser.names)}
                      mode={disabledRightsEditor ? "view" : "edit"}
                      title={activeTab}
                      userId={currentUser._id}
                      companyId={companyId}
                      url={
                        latestTemplateVersion?.fileUploads?.template
                          ? `${process.env.REACT_APP_IMAGE_BASE_URL}/templates/${latestTemplateVersion.fileUploads.template}`
                          : null
                      }
                      editorKey={`${selectedTemplate?._id}-${latestTemplateVersion?._id}`}
                      containerHeight="900px"
                    />
                  )}
                  {activeTab &&
                    ![
                      Constants.ORDINARY_SHARES,
                      Constants.PREFERENCE_SHARES,
                    ].includes(activeTab) && (
                      <OnlyOfficeDocumentEditor
                        key={activeTab}
                        userFullName={getFullName(currentUser.names)}
                        mode={disabledRightsEditor ? "view" : "edit"}
                        userId={currentUser._id}
                        title={activeTab}
                        companyId={companyId}
                        url={
                          latestTemplateVersion?.fileUploads?.template
                            ? `${process.env.REACT_APP_IMAGE_BASE_URL}/templates/${latestTemplateVersion.fileUploads.template}`
                            : null
                        }
                        editorKey={`${selectedTemplate?._id}-${latestTemplateVersion?._id}`}
                        containerHeight="900px"
                      />
                    )}
                  {!activeTab && (
                    <p className="text-center font-medium">
                      Select tab to open and close respective rights
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SharesCategoryTabs;
