import React, { useState, useRef } from "react";
import { XIcon, CheckIcon, PlusIcon, PencilIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { useToast } from "hooks/useToast";
import {
  addAccessRequestComment,
  deleteAccessRequestComment,
  editAccessRequestComment,
} from "state/slices/accessRequestComment";
import { openModal } from "state/slices/modals";
import { capitalizeFirstLetter } from "utils";
import {
  editAccessRequest,
  notifyAboutComments,
} from "state/slices/accessRequest";
import Button from "components/lib/Shared/Button";
import TrashIcon from "components/lib/Shared/Icons/TrashIcon";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { Constants } from "config/constants";
import Checkbox from "components/lib/Shared/Checkbox";

const DASHBOARD_PERMISSIONS = Constants.DASHBOARD_PERMISSIONS;

const dataPermissions = [
  {
    name: DASHBOARD_PERMISSIONS.VIEW_RESTRICTED_DATA,
    label: "View restricted data",
  },
  {
    name: DASHBOARD_PERMISSIONS.EDIT_OTHER_DATA,
    label: "Edit other data",
  },
  {
    name: DASHBOARD_PERMISSIONS.MANAGE_USERS,
    label: "Manage users",
  },
];

const documentPermissions = [
  {
    name: DASHBOARD_PERMISSIONS.REGISTER_OF_MEMBERS,
    label: "Register of members",
  },
  {
    name: DASHBOARD_PERMISSIONS.REGISTER_OF_DIRECTORS,
    label: "Register of Directors",
  },
  {
    name: DASHBOARD_PERMISSIONS.MINUTE_BOOK_DIRECTORS,
    label: "Minute Book - Directors",
  },
];

export default function SubRowAsync({ setSelectedRow, visibleColumns, row }) {
  const { original } = row;
  const closeSubRowRef = useRef(null);
  const [showCommentArea, setShowCommentArea] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedComment, setSelectedComment] = useState({});
  const [sendingComment, setSendingComment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [removingComment, setRemovingComment] = useState(false);
  const [permissions, setPermissions] = useState(original.permissions);

  const {
    createdBy,
    company,
    organization,
    initiatedTo,
    status,
    requestFor,
    initiatedToEmail,
  } = original;

  const { toast } = useToast(5000);
  const dispatch = useDispatch();
  const userSlice = useSelector((state) => state.userSlice);
  const { comments, getAccessRequestCommentsStatus } = useSelector(
    (state) => state.accessRequestComments
  );

  const lastComment = comments?.[comments.length - 1];
  const isLastCommentAuthor = lastComment?.createdBy?._id === userSlice?._id;

  const toggleCommentArea = () => {
    setShowCommentArea((prevValue) => !prevValue);
    setComment("");
  };

  const handleCommentValueChange = (e) => setComment(e.target.value);

  const handleSendComment = async () => {
    if (!comment.trim() || comment.trim() === selectedComment.text) {
      toggleCommentArea();
      return;
    }
    setSendingComment(true);
    const commentData = {
      from: userSlice?._id,
      to: userSlice?._id === createdBy?._id ? initiatedTo?._id : createdBy?._id,
      company: company?._id,
      organization: organization?._id,
      text: comment,
      accessRequest: original._id,
    };
    try {
      if (selectedComment?._id) {
        await dispatch(
          editAccessRequestComment({
            commentId: selectedComment._id,
            data: { text: comment },
          })
        );
      } else await dispatch(addAccessRequestComment({ data: commentData }));
      toast("success", "Comment sent successfully.");
      toggleCommentArea();
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setSendingComment(false);
      setSelectedComment({});
    }
  };

  const handleEditBtnClicked = (comment) => {
    setSelectedComment(comment);
    setComment(comment.text);
    setShowCommentArea(true);
  };

  const handleDeleteComment = async (commentId) => {
    try {
      setRemovingComment(true);
      await dispatch(deleteAccessRequestComment({ commentId }));
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setRemovingComment(false);
    }
  };

  const handleDenyRequest = () => {
    setSelectedRow(original);
    dispatch(openModal({ modalName: "deny_request_confirmation_modal" }));
  };

  const handleRecallRequest = () => {
    setSelectedRow(original);
    dispatch(openModal({ modalName: "recall_request_confirmation_modal" }));
  };

  const handleAcceptInvite = async () => {
    try {
      setIsAccepting(true);
      await dispatch(
        editAccessRequest({
          accessRequestsId: original?._id,
          data: {
            status: "Confirmed",
            grantedAt: new Date(),
            grantedBy: userSlice?._id,
            permissions,
          },
        })
      );
      closeSubRowRef.current.click();
      toast("success", "Allowed access successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsAccepting(false);
    }
  };

  const handleNotifyApplicant = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        notifyAboutComments({
          receiverEmail: createdBy?.electronicAddress?.emailAddress,
          receiverName: capitalizeFirstLetter(createdBy?.names?.firstName),
          senderName:
            requestFor === Constants.ACCESS_REQUEST.CORPORATE_DATA
              ? capitalizeFirstLetter(userSlice?.names?.firstName)
              : capitalizeFirstLetter(initiatedTo?.names?.firstName),
          commentId: lastComment._id,
        })
      );
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
      closeSubRowRef.current.click();
    }
  };

  const handleNotifyInvitedOfficial = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        notifyAboutComments({
          receiverEmail:
            requestFor === Constants.ACCESS_REQUEST.CORPORATE_DATA
              ? initiatedToEmail
              : initiatedTo?.electronicAddress?.emailAddress,
          receiverName: capitalizeFirstLetter(initiatedTo?.names?.firstName),
          senderName: capitalizeFirstLetter(createdBy?.names?.firstName),
          commentId: lastComment._id,
        })
      );
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
      closeSubRowRef.current.click();
    }
  };

  const handleTogglePermission = async (e) => {
    if (e.target.checked) {
      setPermissions((prevPermissions) => [...prevPermissions, e.target.value]);
    } else
      setPermissions((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== e.target.value)
      );
  };

  const handleRevokeAccess = () => {
    setSelectedRow(original);
    dispatch(openModal({ modalName: "revoke_request_confirmation_modal" }));
  };

  const showAddCommentBtn =
    (createdBy?._id !== userSlice?._id && !isLastCommentAuthor) ||
    (createdBy?._id === userSlice?._id && lastComment && !isLastCommentAuthor);

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <div className="flex justify-between items-center mt-2">
          {/* button to close sub row programmatically */}
          {row.isExpanded && (
            <Button
              ref={closeSubRowRef}
              disabled={isAccepting}
              variant="text"
              className="space-x-0 gap-0"
              onClick={() => row.getToggleRowExpandedProps()}
              {...row.getToggleRowExpandedProps()}
            ></Button>
          )}
        </div>
        {status === "Confirmed" &&
          createdBy?._id !== userSlice?._id &&
          requestFor === Constants.ACCESS_REQUEST.PERSONAL_DATA && (
            <p className="w-[21rem] ml-auto whitespace-normal text-sm py-2">
              If you would wish to revoke {createdBy?.names?.firstName}'s access{" "}
              <br />
              <span
                className="text-red-500 underline decoration-red-500 cursor-pointer"
                onClick={handleRevokeAccess}
              >
                Click here to revoke access.
              </span>
            </p>
          )}
        <hr />
        <div className="flex space-x-2 my-2 justify-center items-center">
          <div className="bg-white p-4 flex flex-col grow space-y-2 rounded-sm shadow-sm sm:rounded md:rounded-md">
            <p>Data</p>
            {dataPermissions.map((checkboxParams) => (
              <Checkbox
                key={checkboxParams.name}
                {...checkboxParams}
                checked={permissions.includes(checkboxParams.name)}
                disabled={isLoading || status !== "Pending"}
                onChange={handleTogglePermission}
              />
            ))}
          </div>
          <div className="bg-white p-4 flex flex-col grow space-y-2 rounded-sm shadow-sm sm:rounded md:rounded-md">
            <p>Document</p>
            {documentPermissions.map((checkboxParams) => (
              <Checkbox
                key={checkboxParams.name}
                {...checkboxParams}
                checked={permissions.includes(checkboxParams.name)}
                disabled={isLoading || status !== "Pending"}
                onChange={handleTogglePermission}
              />
            ))}
          </div>
        </div>
        <hr />

        {/* Comments and action buttons */}
        {status === "Pending" &&
        getAccessRequestCommentsStatus.status === "loading" ? (
          <p className="flex justify-center py-4">
            <SpinnerIcon className="text-gray-500" />
          </p>
        ) : (
          <>
            {status === "Pending" && (
              <div className="my-4 d-flex justify-content-end">
                <ul className="space-y-2 text-gray-800">
                  {!showCommentArea &&
                    comments.map((comment) => {
                      const isCommentAuthor =
                        comment.createdBy?._id === userSlice?._id;
                      return (
                        <li key={comment?._id}>
                          <div className="flex space-x-2 justify-between">
                            <p>
                              {isCommentAuthor
                                ? "You:"
                                : `${capitalizeFirstLetter(
                                    comment.createdBy?.names?.firstName
                                  )}:`}{" "}
                              <span className="text-gray-500 text-sm">
                                {format(new Date(comment.createdAt), "PPPp")}
                              </span>
                            </p>
                            {isCommentAuthor &&
                              !isAccepting &&
                              lastComment?._id === comment?._id && (
                                <div className="flex space-x-2 mt-1">
                                  <button disabled={removingComment}>
                                    <PencilIcon
                                      onClick={() =>
                                        handleEditBtnClicked(comment)
                                      }
                                      className="w-4 h-4 cursor-pointer text-light-blue"
                                    />
                                  </button>
                                  <TrashIcon
                                    onClick={() =>
                                      handleDeleteComment(comment._id)
                                    }
                                    isLoading={removingComment}
                                  />
                                </div>
                              )}
                          </div>
                          <p className="pl-2">{comment.text}</p>
                        </li>
                      );
                    })}
                </ul>
                {comments.length > 0 && status === "Pending" && (
                  <hr className="my-2" />
                )}
                {showCommentArea ? (
                  <div>
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Use the textarea to add your message incase you need
                      clarification
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        placeholder="Add you comment"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={comment}
                        onChange={handleCommentValueChange}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {showAddCommentBtn && status === "Pending" && (
                      <Button
                        onClick={toggleCommentArea}
                        disabled={isAccepting}
                        preIcon={PlusIcon}
                        variant="text"
                      >
                        <span>
                          {createdBy?._id === userSlice?._id
                            ? "Add your response"
                            : "Add your comments"}
                        </span>
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
            {showCommentArea ? (
              <div className="flex my-4 space-x-2">
                <Button
                  onClick={toggleCommentArea}
                  disabled={sendingComment}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSendComment}
                  isLoading={sendingComment}
                  disabled={sendingComment || !comment.trim()}
                >
                  Send
                </Button>
              </div>
            ) : (
              <>
                {
                  <>
                    {createdBy?._id !== userSlice?._id && (
                      <div className="flex justify-between my-4">
                        {status === "Pending" && (
                          <>
                            <Button
                              onClick={handleDenyRequest}
                              disabled={isAccepting}
                              loadingText="Denying..."
                              variant="danger"
                              preIcon={XIcon}
                            >
                              Deny
                            </Button>
                            <Button
                              isLoading={isAccepting}
                              loadingText="Accepting..."
                              preIcon={CheckIcon}
                              onClick={handleAcceptInvite}
                            >
                              Accept
                            </Button>
                          </>
                        )}
                      </div>
                    )}
                    {createdBy?._id === userSlice?._id &&
                      status === "Pending" &&
                      (!lastComment ||
                        (lastComment && lastComment?.notificationSent)) && (
                        <div className="flex justify-between my-4">
                          <Button
                            onClick={handleRecallRequest}
                            variant="danger"
                            loadingText="Recalling..."
                            preIcon={XIcon}
                          >
                            Recall
                          </Button>
                        </div>
                      )}
                  </>
                }
              </>
            )}
            {!showAddCommentBtn &&
              !showCommentArea &&
              comments.length > 0 &&
              lastComment &&
              !lastComment?.notificationSent &&
              status === "Pending" && (
                <div className="flex justify-end my-4">
                  {createdBy?._id === userSlice?._id ? (
                    <Button
                      isLoading={isLoading}
                      loadingText="Notifying..."
                      onClick={handleNotifyInvitedOfficial}
                    >
                      Notify{" "}
                      {capitalizeFirstLetter(initiatedTo?.names?.firstName) ||
                        "official"}
                    </Button>
                  ) : (
                    <Button
                      isLoading={isLoading}
                      loadingText="Notifying..."
                      onClick={handleNotifyApplicant}
                    >
                      Notify{" "}
                      {capitalizeFirstLetter(createdBy?.names?.firstName)}
                    </Button>
                  )}
                </div>
              )}
          </>
        )}
      </td>
    </tr>
  );
}
