import RegisterOfDirectorsAndSecretaries from "./RegisterOfDirectorsAndSecretaries";
import RegisterOfDirectorsAndSecretariesResidences from "./RegisterOfDirectorsAndSecretariesResidences";
import RegisterOfTransfers from "./RegisterOfTransfers";
import RegisterOfApplicationsAndAllotments from "./RegisterOfApplicationsAndAllotments";
import RegisterOfDebentures from "./RegisterOfDebentures";
import RegisterOfChargesOrMortgages from "./RegisterOfChargesOrMortgages";
import RegisterOfMembers from "./RegisterOfMembers";
import RegisterOfBeneficialOwners from "./RegisterOfBeneficialOwners";
import ShareCertificates from "./ShareCertificates";

function MinuteBook({ selectedTab }) {
  if (selectedTab.id === "minuteBook") {
    return (
      <div className="px-8 pt-5 pb-6 bg-white mt-4">
        <p className="mb-2 text-center">
          Select one of the documents from the sidebar to view their details.
        </p>
      </div>
    );
  }

  if (selectedTab.id === "registerOfDirectorsAndSecretaries")
    return <RegisterOfDirectorsAndSecretaries selectedTab={selectedTab} />;

  if (selectedTab.id === "registerOfDirectorsAndSecretariesResidences")
    return (
      <RegisterOfDirectorsAndSecretariesResidences selectedTab={selectedTab} />
    );

  if (selectedTab.id === "registerOfTransfers")
    return <RegisterOfTransfers selectedTab={selectedTab} />;

  if (selectedTab.id === "registerOfApplicationsAndAllotments")
    return <RegisterOfApplicationsAndAllotments selectedTab={selectedTab} />;

  if (selectedTab.id === "registerOfDebentures")
    return <RegisterOfDebentures selectedTab={selectedTab} />;

  if (selectedTab.id === "registerOfChargesMortgages")
    return <RegisterOfChargesOrMortgages selectedTab={selectedTab} />;

  if (selectedTab.id === "registerOfMembers")
    return <RegisterOfMembers selectedTab={selectedTab} />;

  if (selectedTab.id === "registerOfBeneficialOwners")
    return <RegisterOfBeneficialOwners selectedTab={selectedTab} />;

  if (selectedTab.id === "shareCertificates")
    return <ShareCertificates selectedTab={selectedTab} />;

  return null;
}

export default MinuteBook;
