import React from "react";

function LadderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect width="32" height="32" fill="#F3F4F6" rx="16"></rect>
      <path
        fill="#1F2937"
        d="M12.5 9a.5.5 0 01.5.5v.5h6v-.5a.5.5 0 011 0v14a.5.5 0 01-1 0V23h-6v.5a.5.5 0 01-1 0v-14a.5.5 0 01.5-.5zm.5 13h6v-2h-6v2zm0-3h6v-2h-6v2zm0-3h6v-2h-6v2zm0-3h6v-2h-6v2z"
      ></path>
    </svg>
  );
}

export default LadderIcon;
