import React, { useState, useEffect } from "react";
import { Field, useFormikContext } from "formik";

import ElectronicAddress from "./ElectronicAddress";
import ResidentialAddressDetailsFormShared from "./ResidentialAddressDetailsFormShared";
import ReactSelectWithFormik from "./ReactSelectForChangeOfParticulars";
import countyOptions from "components/PLCTABS/SFLdata/countyOptions";
import { useSelector } from "react-redux";

// Residential Address Specific Details For Kenyan Citizen && Foreign Resident
const ResidentialAddressDetailsFormKeCiFoRe = (props) => {
  const { field, disabled, disableEmailAddress, selectedValueType } = props;
  const { setFieldValue } = useFormikContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const { modals } = useSelector((state) => state.modalsSlice);
  const { county = "", district = "", locality = "" } = field?.value ?? {};

  useEffect(() => {
    // for validation
    setFieldValue(field.name, {
      county: "",
      district: "",
      locality: "",
      ...field.value,
    });
  }, []);

  const [dropdownData, setDropdownData] = useState({
    county: countyOptions.residence.county,
    district: [],
    locality: [],
  });

  useEffect(() => {
    setDropdownData({
      ...dropdownData,
      district: countyOptions.residence.district.filter(
        (item) => item.county === county
      ),
      locality: countyOptions.residence.locality.filter(
        (item) => item.district === district
      ),
    });
  }, [setDropdownData, county, district]);

  useEffect(() => {
    if (isLoaded) {
      setFieldValue(field.name, {
        ...field.value,
        district: "",
        locality: "",
      });
    }
  }, [county, setFieldValue]);

  useEffect(() => {
    if (isLoaded) {
      setFieldValue(field.name, {
        ...field.value,
        locality: "",
      });
    }
  }, [district, setFieldValue]);

  const handleSelect = (type) => (option) => {
    if (!isLoaded) setIsLoaded(true);
    if (type === "county") {
      setDropdownData({
        ...dropdownData,
        district: countyOptions.residence.district.filter(
          (item) => item.county === option.value
        ),
      });
    }
    if (type === "district") {
      setDropdownData({
        ...dropdownData,
        locality: countyOptions.residence.locality.filter(
          (item) => item.district === option.value
        ),
      });
    }
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
  };

  return (
    <>
      <Field
        name="electronicAddress"
        component={ElectronicAddress}
        disabled={
          selectedValueType === "changes-including-email" ? false : true
        }
        disableEmailAddress={disableEmailAddress}
      />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div
        className={`${
          modals.length === 0 ? "px-4 shadow-sm sm:rounded-sm sm:p-6" : ""
        } mt-4 space-y-4 bg-white`}
      >
        <ResidentialAddressDetailsFormShared {...props} />
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-2">
            <Field
              {...field}
              label="County"
              value={county}
              name="county"
              component={ReactSelectWithFormik}
              options={countyOptions.residence.county}
              errorPath="residentialAddressDetails.county"
              placeholder="Select county"
              isDisabled={disabled}
              onBlur={field.onBlur}
              onChange={handleSelect("county")}
              isSearchable
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Field
              {...field}
              label="District"
              value={district}
              name="district"
              component={ReactSelectWithFormik}
              options={dropdownData.district}
              errorPath="residentialAddressDetails.district"
              placeholder="Select district"
              isDisabled={disabled}
              onBlur={field.onBlur}
              onChange={handleSelect("district")}
              isSearchable
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Field
              {...field}
              label="Locality"
              value={locality}
              name="locality"
              component={ReactSelectWithFormik}
              options={dropdownData.locality}
              errorPath="residentialAddressDetails.locality"
              placeholder="Select locality"
              isDisabled={disabled}
              onBlur={field.onBlur}
              onChange={handleSelect("locality")}
              isSearchable
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResidentialAddressDetailsFormKeCiFoRe;
