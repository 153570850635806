import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _merge from "lodash/merge";
import Cookies from "js-cookie";

import SectionDescription from "components/lib/Shared/SectionDescription";
import contactPersonValidation from "./contactPersonValidation";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import {
  getContactPersonCandidatesAsync,
  updateCompanyAsync,
  updateCompanyState,
} from "state/slices/company";
import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Button from "components/lib/Shared/Button";
import { updateCompanyOfficialAsync } from "state/slices/tables/companyOfficialsSlice";
import { Constants } from "config/constants";

const ContactPerson = ({ handleBackBtn, handleNextBtn }) => {
  const { company, contactPersonCandidates, getContactPersonCandidatesStatus } =
    useSelector((state) => state.companySlice);
  const [registrarAlreadyExist, setRegistrarAlreadyExist] = useState(false);
  const [selectedContactPerson, setSelectedContactPerson] = useState({
    ...company?.contactPerson,
    label: company?.contactPerson?.fullName ?? "",
    value: company?.contactPerson?._id ?? "",
  });
  const [initialFormValues, setInitialFormValues] = useState({
    fullName: company?.contactPerson?.fullName ?? "",
    emailAddress: company?.contactPerson?.emailAddress ?? "",
    designation: company?.contactPerson?.designation ?? "",
    mobileNumber: company?.contactPerson?.mobileNumber ?? "",
  });

  const isOrgAdmin = Cookies.get("role") === Constants.COOKIE_ROLES.ORG_ADMIN;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactPersonCandidatesAsync({ companyId: company._id }));
  }, []);

  const handleSubmit = async (formData) => {
    if (isOrgAdmin) {
      const companyData = {
        contactPerson: formData,
      };
      dispatch(updateCompanyState(companyData));
      await dispatch(
        updateCompanyAsync({
          id: company?._id,
          data: companyData,
        })
      );
      if (!registrarAlreadyExist) {
        await dispatch(
          updateCompanyOfficialAsync({
            officialId: selectedContactPerson.value,
            data: {
              isRegistrar: true,
              companyId: company._id,
              idType: selectedContactPerson.idType,
            },
          })
        );
      }
    }
    handleNextBtn();
  };

  useEffect(() => {
    const populateRegistrar = () => {
      setRegistrarAlreadyExist(
        contactPersonCandidates.find((official) => official.isRegistrar)
      );
      const companySecretary = contactPersonCandidates.find(
        (official) => official.designation === "Company Secretary"
      );
      if (companySecretary) {
        setSelectedContactPerson({
          ...companySecretary,
          value: companySecretary._id,
          label: companySecretary.fullName,
        });
        return;
      }

      const director = contactPersonCandidates.find((official) =>
        ["Director", "Director Shareholder"].includes(official.designation)
      );
      if (director) {
        setSelectedContactPerson({
          ...director,
          value: director._id,
          label: director.fullName,
        });
      }
    };
    populateRegistrar();
  }, [contactPersonCandidates]);

  useEffect(() => {
    if (selectedContactPerson.value) {
      setInitialFormValues(
        contactPersonCandidates.find(
          (contactPerson) => contactPerson._id === selectedContactPerson.value
        )
      );
    }
  }, [selectedContactPerson]);

  const { schemaInitialValues, schema } = contactPersonValidation();
  const initialValues = _merge(schemaInitialValues, initialFormValues);

  const requiredFields = Object.keys(schema.fields).reduce((acc, curr) => {
    if (schema.fields[curr].tests.some((t) => t.OPTIONS.name === "required")) {
      acc.push(curr);
    }
    return acc;
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        requiredFields,
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="mt-4">
            <SectionDescription
              title="Company Contact Person / Registrar Information"
              description="Its essential for a company to have a designated contact person, please select or add a designated contact person from the dropdown."
            />
            <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
              <div className="px-4 py-6 space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-2">
                    <Field
                      name="selectedContactPerson"
                      id="selectedContactPerson"
                      placeholder="Select official"
                      component={ReactSelectWithFormik}
                      isDisabled={
                        !isOrgAdmin ||
                        contactPersonCandidates.length === 1 ||
                        getContactPersonCandidatesStatus.status === "loading"
                      }
                      showRequiredAsterisk
                      label="Official"
                      value={
                        selectedContactPerson.value ? selectedContactPerson : ""
                      }
                      onChange={setSelectedContactPerson}
                      options={contactPersonCandidates.map((contactPerson) => ({
                        label: contactPerson.fullName,
                        value: contactPerson._id,
                      }))}
                      isSearchable
                      className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      name="fullName"
                      id="fullName"
                      placeholder="John Doe"
                      component={Input}
                      disabled
                      label="Full Name"
                      className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      name="emailAddress"
                      id="emailAddress"
                      placeholder="john@gmail.com"
                      component={Input}
                      disabled
                      label="Email Address"
                      className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      name="mobileNumber"
                      id="mobileNumber"
                      placeholder="Add registration number"
                      component={Input}
                      disabled
                      label="Telephone Number"
                      className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <Field
                      name="designation"
                      id="designation"
                      placeholder="Director"
                      component={Input}
                      disabled
                      label="Designation"
                      className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between py-3">
              <Button
                variant="text"
                disabled={isSubmitting}
                onClick={handleBackBtn}
                preIcon={ChevronLeftIcon}
                className="button button-back button-back-hover"
              >
                Back to previous step
              </Button>
              <Button
                loadingText="Saving..."
                type="submit"
                isLoading={isSubmitting}
                disabled={
                  isSubmitting ||
                  getContactPersonCandidatesStatus.status === "loading"
                }
                postIcon={ChevronRightIcon}
              >
                Save and proceed
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactPerson;
