import _orderBy from "lodash/orderBy";

export const getAccessRequestsTabsAndData = ({ accessRequests }) => {
  const tabsParams = {
    Confirmed: {
      id: "Confirmed",
      label: "Approved invites",
      description: "Access control",
      order: 1,
    },
    Pending: {
      id: "Pending",
      label: "Awaiting review",
      description: "Users requesting access",
      order: 2,
    },
    Recalled: {
      id: "Recalled",
      label: "Recalled invites",
      description: "Recalled invites",
      order: 3,
    },
    Denied: {
      id: "Denied",
      label: "Denied invites",
      description: "Denied invites",
      order: 4,
    },
    Revoked: {
      id: "Revoked",
      label: "Revoked invites",
      description: "Denied invites",
      order: 5,
    },
  };

  let tempTabs = [];

  const tempTransformedInvites = {};

  if (accessRequests.length > 0) {
    accessRequests.forEach((access) => {
      // filter data based on status
      const { status } = access;
      if (!tempTransformedInvites[status]) {
        tempTabs.push(tabsParams[status]);
        tempTransformedInvites[status] = [];
      }
      tempTransformedInvites[status].push(access);
    });
  }

  return {
    tempTabs: _orderBy(tempTabs, ["order"]),
    tempTransformedInvites,
  };
};
