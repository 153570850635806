/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable multiline-ternary */
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import _flatten from "lodash/flatten";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import JointShareholderTable from "./JointShareholderTable";
import SubmitJointShareholdersList from "./SubmitJointShareholdersList";
import JointShareholderForms from "./JointShareholderForms";
import { openModal } from "state/slices/modals";
import {
  setCurrentJSGroup,
  resetJointShareholderTable,
} from "state/slices/tables/corporateJointShareholderTableSlice";
import ModalWrapper from "components/lib/Shared/ModalWrapper";
import { deleteCorporateShareholder } from "state/slices/tables/corporateShareholderTableSlice";
import { deleteFiles } from "utils/filesUtils";

const JointComponentsSelection = (props) => {
  const [showShareAllocation, setShowShareAllocation] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { currentJSGroup } = useSelector(
    (state) => state
  ).corporateJointShareholderTableSlice;

  const jointShareholderTable = useSelector((state) => {
    return state.corporateJointShareholderTableSlice.shareholders.filter(
      (js) => js.JSGroupID === currentJSGroup.JSGroupID
    );
  }, shallowEqual);

  const [mode, setMode] = useState({ id: null, editing: false });
  const [showJSForm, setShowJSForm] = useState();
  const { modals } = useSelector((state) => state.modalsSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (modals.length === 0) {
      setShowJSForm(jointShareholderTable.length < 2);
      setMode({ editing: false, id: "" });
    }
    setIsMounted(true);
    if (jointShareholderTable.length === 0 && isMounted) {
      dispatch(deleteCorporateShareholder(currentJSGroup));
      dispatch(setCurrentJSGroup(""));
      props.setShowCorporateBtn(true);
    }
  }, [jointShareholderTable.length, modals]);

  const handleEditJointShareholder = (row, isFirstRow) => {
    props.handleJsIdChange({ value: row.idType, label: row.idType });
    setMode({ editing: true, id: row?.id ?? row._id });
    setShowJSForm(true);
    setShowShareAllocation(isFirstRow);
    dispatch(openModal({ modalName: "jointShareholderForm_edit_modal" }));
  };

  const toggleShowJSForm = (btn) => {
    if (btn === false) {
      setShowJSForm(btn);
    } else setShowJSForm(true);
  };

  const handleAddMoreShareholder = () => {
    props.handleJsIdChange({
      value: "Kenyan Citizen",
      label: "Kenyan Citizen",
    });
    toggleShowJSForm();
  };

  const currentOfficial = jointShareholderTable.filter(
    (co) => (co.id && co.id === mode.id) || (co._id && co._id === mode.id)
  )[0];

  const resetFormState = () => {
    setCurrentJSGroup("");
    props.setShowCorporateBtn(true);
    toggleShowJSForm();
  };

  const handleExitForm = async (resetForm) => {
    if (
      window.confirm(
        "are you sure you want to exit? Your changes will not be saved."
      )
    ) {
      // const filesToDelete = _flatten(jointShareholderTable
      //   .map(({ fileUploads }) => Object.values(fileUploads)))
      // if (filesToDelete.length > 0) await deleteFiles(filesToDelete)
      jointShareholderTable.map((js) =>
        dispatch(deleteCorporateShareholder(js))
      );
      dispatch(resetJointShareholderTable());
      dispatch(deleteCorporateShareholder(currentJSGroup));
      dispatch(setCurrentJSGroup(""));
      resetFormState();
      resetForm();
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          ...currentOfficial,
        }}
      >
        {({ values, setFieldValue, ...formikProps }) => {
          return (
            <div>
              {!mode.editing && (
                <Form>
                  <JointShareholderTable
                    handleEdit={handleEditJointShareholder}
                  />
                  <div className={`d-flex space-x-2 justify-content-end`}>
                    {jointShareholderTable.length > 0 && (
                      <button
                        className="inline-flex px-4 py-2 mt-2 mb-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm file:justify-center bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        type="button"
                        onClick={() => handleExitForm(formikProps.resetForm)}
                      >
                        Exit
                      </button>
                    )}
                    {!showJSForm && jointShareholderTable.length < 4 && (
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sflPrimary hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleAddMoreShareholder}
                      >
                        Add more
                      </button>
                    )}
                    <SubmitJointShareholdersList
                      selectedShareholder={props.selectedShareholder}
                      shareholderWithBO={props.shareholderWithBO}
                      resetFormState={() =>
                        resetFormState(formikProps.resetForm)
                      }
                    />
                  </div>
                </Form>
              )}
              {mode.editing ? (
                <ModalWrapper
                  name="jointShareholderForm_edit_modal"
                  title="Edit Company Official"
                  maxWidth="sm:max-w-fit"
                  toggleShowJSForm={
                    jointShareholderTable.length > 1 ? toggleShowJSForm : null
                  }
                >
                  <JointShareholderForms
                    values={{ idType: props.idType }}
                    showShareAllocation={showShareAllocation}
                    mode={mode}
                    toggleShowJSForm={toggleShowJSForm}
                    showJSForm={showJSForm}
                    beneficialOwnershipForm={props.beneficialOwnershipForm}
                    corporateIdType={props.corporateIdType}
                  />
                </ModalWrapper>
              ) : (
                showJSForm && (
                  <JointShareholderForms
                    values={{ idType: props.idType }}
                    showShareAllocation={showShareAllocation}
                    mode={mode}
                    toggleShowJSForm={toggleShowJSForm}
                    showJSForm={showJSForm}
                    beneficialOwnershipForm={props.beneficialOwnershipForm}
                    corporateIdType={props.corporateIdType}
                  />
                )
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default JointComponentsSelection;
