import React from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { classNames } from "utils";

function CompanyProcesses({ companyProcesses }) {
  return (
    <div className="grid grid-cols-1 mt-4 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
      {companyProcesses.map((companyProcess) => (
        <RadioGroup.Option
          key={companyProcess.id}
          value={companyProcess}
          disabled={companyProcess.disabled}
          className={({ checked, active }) =>
            classNames(
              checked ? "border-transparent" : "border-gray-300",
              active ? "ring-2 ring-indigo-500" : "",
              companyProcess.disabled ? "cursor-not-allowed" : "cursor-pointer",
              "relative bg-white border rounded-lg shadow-sm p-4 flex focus:outline-none select-none"
            )
          }
        >
          {({ checked, active }) => (
            <>
              <div className="flex flex-1">
                <div className="flex flex-col">
                  <div className="w-12 h-12">{companyProcess.icon}</div>
                  <RadioGroup.Label
                    as="span"
                    className="block text-sm font-medium"
                  >
                    <div className="grid items-start justify-between sm:grid-cols-12 sm:flex-row">
                      <div className="col-span-10 ">{companyProcess.title}</div>
                      {companyProcess.status}
                    </div>
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className="flex items-center mt-1 text-sm"
                  >
                    {companyProcess.description}
                  </RadioGroup.Description>
                </div>
              </div>
              <CheckCircleIcon
                className={classNames(
                  !checked ? "invisible" : "",
                  "h-5 w-5 text-indigo-600"
                )}
                aria-hidden="true"
              />
              <div
                className={classNames(
                  active && !checked ? "border" : "border-2",
                  checked ? "border-indigo-500" : "border-transparent",
                  "absolute -inset-px rounded-lg pointer-events-none"
                )}
                aria-hidden="true"
              />
            </>
          )}
        </RadioGroup.Option>
      ))}
    </div>
  );
}

export default CompanyProcesses;
