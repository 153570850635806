import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Formik,
  Field,
  Form,
  yupToFormErrors,
  validateYupSchema,
  setNestedObjectValues,
} from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _omit from "lodash/omit";
import _pickBy from "lodash/pickBy";
import _isEmpty from "lodash/isEmpty";
import _merge from "lodash/merge";
import _keys from "lodash/keys";

import ValidationError from "components/lib/Shared/ValidationError";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import getInitialValues from "./getInitialValues";
import getValidationSchema from "./getValidationSchema";
import Verify from "components/lib/Shared/Verify";
import CommentsList from "components/lib/Global/CommentsList";
import InviteNewUser from "components/lib/Shared/Verify/InviteNewUser";
import InviteExistingUser from "components/lib/Shared/Verify/InviteExistingUser";
import ErrorBanner from "components/lib/Shared/ErrorBanner";
import { Constants } from "config/constants";
import NamesForm from "components/PLCTABS/CompanyOfficialsTab/NamesComponents";
import IdentificationDetailsForm from "components/PLCTABS/CompanyOfficialsTab/PersonalDetailsComponents";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import MainShareAllocationComponent from "components/PLCTABS/CompanyOfficialsTab/ShareAllocationComponents";
import { INDIVIDUALS } from "components/PLCTABS/SFLdata/config/constants";
import {
  addJointShareholder,
  editJointShareholder,
} from "state/slices/tables/jointShareholderTable";
import {
  addCompanyOfficialAsync,
  updateCompanyOfficialAsync,
} from "state/slices/tables/companyOfficialsSlice";
import OfficialsBeneficialOwnershipComponent from "components/PLCTABS/CompanyOfficialsTab/BeneficialOwnershipComponents/OfficialsBeneficialOwnershipComponent";
import JointResidentialDetails from "./JointResidentialDetails";
import Button from "components/lib/Shared/Button";
import { closeModal } from "state/slices/modals";
import { resetSharesTable } from "state/slices/tables/sharesAllocationTable";
import { getAllBeneficialOwners } from "state/slices/tables/beneficialOwnersTableSlice";
import { handleFilesUpload } from "utils/filesUtils";
import {
  clearEmpties,
  getFullName,
  getMaskedEmail,
  getUpdatedFormData,
} from "utils";
import { useJointShareholders } from "hooks/useJointShareholders";

const formFields = [
  "isDirector",
  "names",
  "identificationDetails",
  "isNonResident",
  "postalAddress",
  "electronicAddress",
  "residentialAddressDetails",
  "fileUploads",
  "sharesAllotted",
  "beneficialOwnershipForm",
  "inviteStatus",
];

const JointShareholderForms = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [validationSchema, setValidationSchema] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const { transformedComments } = useSelector((state) => state.commentsSlice);

  const [showIdentificationSection, setShowIdentificationSection] =
    useState(true);
  const [verificationValue, setVerifyingValue] = useState("");
  const [isIdAssociated, setIsIdAssociated] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [inviteNewUserWorkflow, setInviteNewUserWorkflow] = useState(false);
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);
  const [isAccessRevoked, setIsAccessRevoked] = useState(false);
  const [inviteExistingUser, setInviteExistingUser] = useState(false);
  const currentUser = useSelector((state) => state.userSlice);
  const [isFormValid, setIsFormValid] = useState(false);
  const formStepErrors = useRef(null);
  const { comments } = useSelector((state) => state.commentsSlice);
  const generalInformationComments = comments.filter(
    (comment) => comment.formName === "generalInformation"
  );
  const { shares } = useSelector((state) => state.sharesAllocationTableSlice);
  const { company } = useSelector((state) => state.companySlice);

  const { jointShareholders, currentJSGroupID } = useSelector(
    (state) => state.jointShareholderTableSlice
  );

  const { syncBeneficialOwnersWithJointShareholders } = useJointShareholders();

  const jointShareholdersCount = jointShareholders.length;

  const {
    mode,
    toggleShowOfficialsForm,
    idType,
    designation,
    isDirector,
    selectedTab,
    isFirstStep,
    isLastStep,
    goToPrevStep,
    goToNextStep,
    isViewOnly,
    setTabsClickable,
    goToAssignOfficialsTab,
  } = props;

  const documentData = getDocumentData(idType);

  const closeVerifyProcess = () => {
    toggleShowOfficialsForm(false);
    dispatch(resetSharesTable());
  };

  const currentRow = useMemo(() => {
    if (mode.editing && !_isEmpty(jointShareholders)) {
      return jointShareholders.find((item) => item._id === mode.id) || {};
    }
    return {};
  }, [jointShareholders, mode]);

  useEffect(() => {
    if (
      jointShareholders.length === Constants.MAX_JOINT_SHAREHOLDER_LIMIT &&
      mode.designation !== "Joint"
    ) {
      props.toggleShowJSForm(false);
    }
    if (jointShareholders.length === 1) {
      props.toggleShowJSForm(true);
    }
  }, [jointShareholders.length, mode.designation]);

  let isFirstJSReportable = false;
  if (jointShareholders[0]?.beneficialOwnershipForm) {
    const {
      directPercentShareholding,
      directPercentVotingRights,
      directRightRemoveDirector,
      directCompanyControlRight,
    } = jointShareholders[0]?.beneficialOwnershipForm;
    isFirstJSReportable =
      directPercentShareholding >= 10 ||
      directPercentVotingRights >= 10 ||
      directRightRemoveDirector === "yes" ||
      directCompanyControlRight === "yes";
  }

  useEffect(() => {
    setVerifyingValue("");
    setIsIdAssociated(null);
    setInviteNewUserWorkflow(false);
    setIsAccessAllowed(false);
    setIsAccessRevoked(false);
    if (!mode.editing || isViewOnly) setDisableForm(true);
  }, [idType]);

  useEffect(() => {
    const { isNonResident } = currentRow || {};
    setValidationSchema(
      getValidationSchema({
        selectedTab,
        idType,
        isNonResident,
        documentData,
        shares,
      }).schema
    );
    setInitialValues(
      _merge(
        getInitialValues({ idType, isNonResident, documentData, shares }),
        currentRow
      )
    );
    setShowIdentificationSection(true);
  }, [selectedTab, idType, currentRow, shares]);

  const addOrEditOfficial = async (values) => {
    const uploadedFiles = await handleFilesUpload(
      values.fileUploads,
      currentRow,
      mode.editing
    );
    let payload = {
      ..._omit(values, ["fileUploads", "beneficialOwnershipForm"]),
      idType,
      designation,
      fileUploads: {
        ..._pickBy(values.fileUploads, (file) => typeof file === "string"),
        ...uploadedFiles,
      },
      names: {
        ...values.names,
        fullName: getFullName(values.names),
      },
      beneficialOwnershipForm: values.beneficialOwnershipForm,
    };
    const companyId = company?._id ?? params?.companyId;
    if (!companyId) {
      toggleShowOfficialsForm(false);
      dispatch(resetSharesTable());
    }
    const isGuardian =
      values?.identificationDetails?.guardianEmailAddress ===
      currentUser.electronicAddress.emailAddress;
    if (values.type === "invited" && isGuardian) {
      payload.inviteStatus = "Data Pending Review";
    }
    if (values.hasAccess) {
      payload.type = "invited";
      payload.inviteStatus = "Approved";
    }
    let addOfficialResponse = {};
    if (!mode.editing) {
      if (isDirector === "Yes") {
        payload = {
          ...payload,
          details: {
            ...payload.details,
            designation: "Director",
          },
        };
      }
      payload = clearEmpties(payload);
      addOfficialResponse = await dispatch(
        addCompanyOfficialAsync({ companyId, data: payload })
      );
      dispatch(addJointShareholder(addOfficialResponse.payload.official));
      if (values?.type === "invited" && !values.hasAccess) {
        window.sessionStorage.setItem(
          "flashMessageCOTable",
          `We have successfully sent the invite to ${getMaskedEmail(
            values.initiatedToEmail
          )}.
          In case they do not receive any email, let them check the spam folder before initiating another invite.`
        );
      }
    } else {
      const updatedOfficialData = getUpdatedFormData(
        formFields,
        currentRow,
        payload
      );
      if (!_isEmpty(updatedOfficialData)) {
        const updateOfficialResponse = await dispatch(
          updateCompanyOfficialAsync({
            officialId: mode.id,
            data: { ...updatedOfficialData, companyId, idType },
          })
        );
        dispatch(editJointShareholder(updateOfficialResponse.payload.official));
        if (
          currentUser.electronicAddress.emailAddress ===
          values.electronicAddress.emailAddress
        ) {
          const {
            indirectPercentShareholding,
            indirectPercentVotingRights,
            indirectRightRemoveDirector,
            indirectCompanyControlRight,
          } = values?.beneficialOwnershipForm ?? {};

          const isIndirectBeneficiary =
            indirectPercentShareholding >= 10 ||
            indirectPercentVotingRights >= 10 ||
            indirectRightRemoveDirector === "yes" ||
            indirectCompanyControlRight === "yes";

          let showInBoDropdown = false;
          if (
            [...INDIVIDUALS].includes(payload.idType) &&
            (isIndirectBeneficiary ||
              indirectPercentShareholding > 0 ||
              indirectPercentVotingRights > 0)
          ) {
            showInBoDropdown = true;
          } else showInBoDropdown = false;

          let flashMessageCOTable =
            "The application has been submitted back to the applicant for review";
          if (showInBoDropdown) {
            flashMessageCOTable =
              "Please proceed to the beneficial ownership tab to disclose your B.O status";
            window.sessionStorage.setItem(
              "flashMessageBOTable",
              "Click on + Add Beneficial Owners to disclose ownership and the submitted data to finalize the application."
            );
          }
          window.sessionStorage.setItem(
            "flashMessageCOTable",
            flashMessageCOTable
          );
          window.dispatchEvent(new Event("storage"));
        }
      }
      dispatch(closeModal("jointShareholder_edit_modal"));
    }
    syncBeneficialOwnersWithJointShareholders();
    dispatch(getAllBeneficialOwners({ companyId }));
    return addOfficialResponse;
  };

  const showShareAllocationForm =
    (props.showShareAllocation && mode.editing) ||
    (!mode.editing && jointShareholdersCount === 0) ||
    (!mode.editing && isDirector === "Yes" && jointShareholdersCount === 0);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        isDirector,
        JSGroupID: currentJSGroupID,
        showShareAllocationForm,
        comments:
          transformedComments?.[currentRow?._id]?.["stakeholderTab"] ?? [],
      }}
      validate={(values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            makeFieldsOptional: isIdAssociated === false || isViewOnly,
            maxPercentShareholding:
              values.beneficialOwnershipForm?.maxPercentShareholding ?? 0,
            maxPercentVotingRights:
              values.beneficialOwnershipForm?.maxPercentVotingRights ?? 0,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={async (values, { resetForm }) => {
        // validate form & store errors for the ErrorBanner
        try {
          validateYupSchema(values, validationSchema, true);
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: [],
          };
        } catch (error) {
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: error.errors,
          };
          if (!isLastStep) {
            setTimeout(goToNextStep, Constants.DELAY_FORM_SUBMISSION);
            return;
          }
        }

        if (!isLastStep) {
          goToNextStep();
          return;
        }
        const officialSchema = getValidationSchema({
          idType,
          isNonResident: values.isNonResident,
          documentData,
          shares,
        });
        console.log({ values });
        try {
          setIsFormValid(true);
          validateYupSchema(values, officialSchema, true);
        } catch (error) {
          console.log({ error });
          setIsFormValid(false);
          if (error.errors.length > 0) {
            return;
          }
        }
        if (
          !isViewOnly ||
          company.createdBy._id === currentUser._id ||
          company.isCoApplicant
        ) {
          await addOrEditOfficial(values);
        }
        if (mode.editing) {
          dispatch(closeModal("jointShareholderForm_edit_modal"));
          toggleShowOfficialsForm(true);
        } else {
          if (jointShareholdersCount > 0) {
            props.toggleShowJSForm(false);
          } else props.toggleShowJSForm(true);
        }
        resetForm();
        goToAssignOfficialsTab();
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        resetForm,
        submitCount,
        setTouched,
      }) => {
        const {
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = values?.beneficialOwnershipForm ?? {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";

        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";
        let isReportable = false;

        if (INDIVIDUALS.includes(idType) && isDirectBeneficiary)
          isReportable = true;
        if (INDIVIDUALS.includes(idType) && isIndirectBeneficiary)
          isReportable = false;

        useEffect(() => {
          setValidationSchema(
            getValidationSchema({
              selectedTab,
              idType,
              isNonResident: values.isNonResident,
              documentData,
              shares,
              isReportable,
            }).schema
          );
        }, [values.isNonResident, shares, isReportable]);

        useEffect(() => {
          if (!isReportable) {
            setFieldValue("beneficialOwnershipForm.dateOfBecomingBO", "");
          }
        }, [isReportable, setFieldValue]);

        // Reset touched fields on tab change, e.g fix errors jumping around
        useEffect(() => {
          setTouched({}, false);
          setIsFormValid(true);
        }, [selectedTab, setTouched]);

        // set touched to true for required fields to render errors
        useEffect(() => {
          if (
            values.isIncompleteProfile &&
            Object.keys(errors).length > 0 &&
            submitCount > 0
          ) {
            setTouched(setNestedObjectValues(errors, true));
          }
        }, [values.isIncompleteProfile, errors, submitCount]);

        // Invite new user form
        if (inviteNewUserWorkflow && selectedTab.id !== "assignOfficials") {
          return (
            <InviteNewUser
              closeVerifyProcess={closeVerifyProcess}
              idType={idType}
              designation={designation}
              values={values}
              inviteOfficial={addOrEditOfficial}
            />
          );
        }

        if (inviteExistingUser) {
          return (
            <InviteExistingUser
              userId={values.userId}
              idType={idType}
              designation={designation}
              closeVerifyProcess={closeVerifyProcess}
              userEmailAddress={values.userEmailAddress}
              values={values}
              inviteOfficial={addOrEditOfficial}
            />
          );
        }

        const handleNewUserWorkflow = () => {
          if (Object.keys(errors).length > 0) {
            setTouched(setNestedObjectValues(errors, true));
            return;
          } else setInviteNewUserWorkflow(true);
        };

        const handleResetFormFromVerify = () => {
          dispatch(resetSharesTable());
          resetForm();
          formStepErrors.current = null;
        };

        return (
          <Form>
            {isIdAssociated === false &&
              formStepErrors.current?.[selectedTab.id]?.length > 0 && (
                <ErrorBanner
                  errors={[...(formStepErrors.current?.[selectedTab.id] ?? [])]}
                  className="my-4"
                />
              )}
            {/* Invite new user button */}
            {verificationValue &&
              isIdAssociated === false &&
              selectedTab.id &&
              !["assignOfficials", "generalInformation"].includes(
                selectedTab.id
              ) &&
              !mode.editing && (
                <Button
                  onClick={handleNewUserWorkflow}
                  className="inline-flex self-end justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm w-content bg-sflPrimary hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed disabled:bg-optional disabled:hover:text-white"
                >
                  Invite
                </Button>
              )}
            {/* Personal Information */}
            {selectedTab.id === "generalInformation" && (
              <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                <div className="px-4 py-6 space-y-6 sm:p-6">
                  <div className="mb-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Personal Information
                    </h3>
                  </div>
                  <div>
                    {!mode.editing && (
                      <Verify
                        idType={idType}
                        designation={designation}
                        setShowIdentificationSection={
                          setShowIdentificationSection
                        }
                        verificationValue={verificationValue}
                        isIdAssociated={isIdAssociated}
                        isAccessAllowed={isAccessAllowed}
                        isAccessRevoked={isAccessRevoked}
                        JSGroupID={currentJSGroupID}
                        setFieldValue={setFieldValue}
                        setVerifyingValue={setVerifyingValue}
                        setIsIdAssociated={setIsIdAssociated}
                        setDisableForm={setDisableForm}
                        resetForm={handleResetFormFromVerify}
                        handleNewUserWorkflow={handleNewUserWorkflow}
                        setIsAccessAllowed={setIsAccessAllowed}
                        setIsAccessRevoked={setIsAccessRevoked}
                        setInviteExistingUser={setInviteExistingUser}
                        setTabsClickable={setTabsClickable}
                      />
                    )}
                  </div>
                  {showIdentificationSection && (
                    <>
                      {[
                        "Kenyan Citizen",
                        "Foreign Resident",
                        "Foreigner",
                        "Minor",
                      ].includes(idType) && (
                        <>
                          {submitCount > 0 &&
                            disableForm &&
                            !isAccessAllowed &&
                            !isViewOnly && (
                              <div
                                className="flex items-center py-1 text-sm leading-none text-red-500 bg-gray-200 lg:rounded-full lg:inline-flex"
                                role="alert"
                              >
                                <span className="flex-auto px-2 font-semibold text-left">
                                  To activate the form fields please verify your
                                  ID
                                </span>
                              </div>
                            )}
                          <Field
                            name="names"
                            component={NamesForm}
                            idType={idType}
                            disabled={disableForm}
                            disableVerificationField={
                              isIdAssociated === false || mode.editing
                            }
                            resolvedFields={
                              currentRow?.names?.resolvedFields ?? []
                            }
                            comments={generalInformationComments}
                          />
                        </>
                      )}
                    </>
                  )}

                  <hr />
                  <IdentificationDetailsForm
                    idType={idType}
                    designation={designation}
                    disabled={disableForm && !values.isIncompleteProfile}
                    disableVerificationField={
                      isIdAssociated === false || mode.editing
                    }
                  />
                </div>
              </div>
            )}
            {/*  Address Particulars */}
            {selectedTab.id === "residentialDetails" && (
              <JointResidentialDetails
                idType={idType}
                disabled={
                  disableForm &&
                  !values?.enableLongForm &&
                  !values.isIncompleteProfile
                }
                errors={errors}
                touched={touched}
                isNonResident={values.isNonResident}
                renderIsNonResidentComponent={
                  ["Kenyan Citizen", "Minor"].includes(idType) &&
                  (isDirector === "Yes" ||
                    jointShareholdersCount === 0 ||
                    (props.showShareAllocation && mode.editing) ||
                    isFirstJSReportable)
                }
              />
            )}
            {/* File Uploads Form */}
            {selectedTab.id === "uploadDocuments" && (
              <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                <div className="px-4 py-6 sm:p-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    KYC Document Uploads
                  </h3>
                  {[
                    "Kenyan Citizen",
                    "Foreign Resident",
                    "Foreigner",
                    "Minor",
                  ].includes(idType) && (
                    <div className="mb-2">
                      <h4>Please upload the following documents</h4>
                    </div>
                  )}
                  <Field
                    name="fileUploads"
                    component={FileUploadForm}
                    documentData={documentData}
                    disabled={
                      disableForm &&
                      !values?.enableLongForm &&
                      !values.isIncompleteProfile
                    }
                  />
                </div>
              </div>
            )}
            {/* Share Allocation form */}
            {selectedTab.id === "shareAllocation" && (
              <>
                {((showShareAllocationForm && mode.editing) ||
                  [
                    "Kenyan Citizen",
                    "Foreign Resident",
                    "Foreigner",
                    "Minor",
                  ].includes(idType)) && (
                  <>
                    <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                      <div className="px-4 py-6 sm:p-6">
                        <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">
                          Share Allocation
                        </h3>
                        <div className="pl-2">
                          {_keys(values?.comments?.officialShareAllocation).map(
                            (rowName) => {
                              if (
                                !Array.isArray(
                                  values?.comments?.officialShareAllocation?.[
                                    rowName
                                  ]
                                )
                              )
                                return;
                              return (
                                <>
                                  <span className="font-semibold">
                                    {rowName}
                                  </span>
                                  <div className="pl-4">
                                    <CommentsList
                                      comments={
                                        values?.comments
                                          ?.officialShareAllocation?.[rowName]
                                      }
                                      color={"text-red-500"}
                                    />
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                        <Field
                          name="shareAllocationForm"
                          component={MainShareAllocationComponent}
                          currentOfficialId={initialValues._id}
                          resolvedFields={currentRow?.resolvedFields ?? []}
                          disabled={
                            disableForm &&
                            !isAccessAllowed &&
                            mode.editing &&
                            values?.createdBy !== currentUser._id
                          }
                        />
                        <ValidationError
                          errors={errors}
                          touched={touched}
                          name="sharesAllotted"
                        />
                      </div>
                    </div>
                    {/* Beneficial Ownership form */}
                    <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                      <div className="px-4 py-6 sm:p-6">
                        <h2 className="mb-1 text-lg font-medium leading-6 text-gray-900">
                          Beneficial Ownership
                        </h2>
                        {[
                          "Kenyan Citizen",
                          "Foreign Resident",
                          "Foreigner",
                          "Minor",
                        ].includes(idType) && (
                          <Field
                            name="beneficialOwnershipForm"
                            component={OfficialsBeneficialOwnershipComponent}
                            isReportable={isReportable}
                            disabled={
                              disableForm &&
                              !isAccessAllowed &&
                              mode.editing &&
                              values?.createdBy !== currentUser._id
                            }
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {isLastStep && !isFormValid && isIdAssociated !== null && (
              <p className="my-2 text-sm text-red-500">
                Either enter all the required information or invite the
                official.
              </p>
            )}
            {selectedTab.id !== "assignOfficials" && (
              <div
                className={`flex ${
                  isFirstStep ? "justify-end" : "justify-between"
                } py-3`}
              >
                {!isFirstStep && (
                  <Button
                    disabled={isSubmitting}
                    onClick={goToPrevStep}
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:cursor-not-allowed"
                  >
                    Go back
                  </Button>
                )}
                <Button
                  type="submit"
                  isLoading={isSubmitting && isLastStep}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
                >
                  {isLastStep ? "Submit" : "Next"}
                </Button>
              </div>
            )}
            {jointShareholdersCount >= Constants.MAX_JOINT_SHAREHOLDER_LIMIT &&
              mode.designation !== "Joint" && (
                <small className="text-red-500">
                  YOU HAVE REACHED THE MAXIMUM NUMBER OF JOINT SHAREHOLDERS
                </small>
              )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default JointShareholderForms;
