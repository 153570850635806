import axios from "../axios";

const getAll = (user) => axios.get(`/professional-details?user=${user}`);

const create = (data) => axios.post("/professional-details", data);

const update = (professionalDetailId, data) =>
  axios.put(`/professional-details/${professionalDetailId}`, data);

const remove = (professionalDetailId) =>
  axios.delete(`/professional-details/${professionalDetailId}`);

const professionalDetailsDataService = {
  getAll,
  create,
  update,
  remove,
};

export default professionalDetailsDataService;
