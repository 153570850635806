import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import _pickBy from "lodash/pickBy";
import _merge from "lodash/merge";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import Label from "components/lib/Shared/Label";
import Button from "components/lib/Shared/Button";
import Input from "components/lib/Shared/Input";
import professionalDetailValidation from "./professionalDetailValidation";
import ValidationError from "components/lib/Shared/ValidationError";
import ProfileLabel from "components/lib/Shared/ProfileLabel";
import { useToast } from "hooks/useToast";
import {
  addProfessionalDetail,
  deleteProfessionalDetail,
  editProfessionalDetail,
} from "state/slices/professionalDetails";
import { handleFilesUpload } from "utils/filesUtils";
import { updateUser } from "state/slices/user";
import CommentsList from "components/lib/Global/CommentsList";

const formFields = [
  "capacity",
  "practicingNumber",
  "practicingCertificate",
  "fileUploads",
];

function ProfessionalDetail({
  professionalDetail = {},
  isViewMode = true,
  isEditMode = false,
  capacities = [],
  comments = {},
  handleEdit = () => null,
  setAddNewDetail = () => null,
}) {
  const dispatch = useDispatch();
  const { toast } = useToast(5000);
  const userSlice = useSelector((state) => state.userSlice);
  const professionalDetails = useSelector(
    (state) => state.professionalDetailsSlice.items
  );
  const { schema, schemaInitialValues } = professionalDetailValidation(true);

  const initialValues = _merge(schemaInitialValues, professionalDetail);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        try {
          const uploadedFiles = await handleFilesUpload(values.fileUploads);
          const payload = {
            ...values,
            fileUploads: {
              ..._pickBy(
                values.fileUploads,
                (file) => typeof file === "string"
              ),
              ...uploadedFiles,
            },
          };
          if (isEditMode) {
            const updatedOfficialData = {};
            formFields.map((formField) => {
              if (
                !_isEqual(professionalDetail[formField], payload[formField])
              ) {
                updatedOfficialData[formField] = payload[formField];
              }
            });
            if (!_isEmpty(updatedOfficialData)) {
              await dispatch(
                editProfessionalDetail({
                  professionalDetailId: values._id,
                  data: updatedOfficialData,
                })
              );
            }
          } else {
            await dispatch(addProfessionalDetail({ data: payload }));
            if (professionalDetails.length === 0) {
              dispatch(
                updateUser({
                  id: userSlice._id,
                  data: { attachProfessionsDetails: true },
                })
              );
            }
          }
          handleEdit(null);
          setAddNewDetail(false);
        } catch (error) {
          toast(
            "error",
            error?.message ?? "Something went wrong please try again."
          );
        }
      }}
    >
      {({ values, errors, touched, isSubmitting, handleSubmit }) => {
        const [isDeleting, setIsDeleting] = useState(false);

        const handleDelete = async (professionalDetail) => {
          const professionalDetailId = professionalDetail._id;
          const user = professionalDetail.user;
          try {
            setIsDeleting(true);
            const response = await dispatch(
              deleteProfessionalDetail({ professionalDetailId })
            );
            if (response.error?.message) {
              toast(
                "error",
                response?.payload?.messageText ??
                  "Something went wrong please try again."
              );
            } else if (professionalDetails.length === 1) {
              await dispatch(
                updateUser({
                  id: user,
                  data: {
                    isProfessional: false,
                    attachProfessionsDetails: false,
                  },
                })
              );
            }
          } catch (error) {
            toast(
              "error",
              error?.message ?? "Something went wrong please try again."
            );
          } finally {
            setIsDeleting(false);
          }
        };

        const handleCancel = () => {
          handleEdit(null);
          setAddNewDetail(false);
        };

        const fileUploadsComments =
          comments[`${values?.capacity}.fileUploads.practicingCertificate`] ||
          [];
        console.log({ values });
        return (
          <Form>
            {!isViewMode && !isEditMode && (
              <div className="px-4 space-y-2 mt-2 sm:px-6">
                <span>Select role/s that best suit your qualifications</span>
                <div className="flex space-x-8 ml-4">
                  {capacities.map((capacity) => (
                    <div className="flex items-center space-x-2" key={capacity}>
                      <Field
                        type="radio"
                        name="capacity"
                        id={capacity}
                        value={capacity}
                      />
                      <Label htmlFor={capacity}>{capacity}</Label>
                    </div>
                  ))}
                </div>
                <ValidationError
                  errors={errors}
                  touched={touched}
                  name={"capacity"}
                />
              </div>
            )}
            <div className="px-4 space-y-2 sm:p-6">
              <div className="flex justify-between">
                <p className="font-medium text-gray-900">
                  {values?.capacity} Details
                </p>
                {isViewMode && (
                  <div className="space-x-4">
                    <Button
                      type="button"
                      variant="text"
                      disabled={isDeleting}
                      onClick={() =>
                        handleEdit(isEditMode ? null : professionalDetail)
                      }
                    >
                      {isEditMode ? "Cancel" : "Edit"}
                    </Button>
                    <Button
                      type="button"
                      variant="text"
                      disabled={isDeleting}
                      isLoading={isDeleting}
                      loadingText="Deleting..."
                      onClick={() => handleDelete(professionalDetail)}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
              {isViewMode ? (
                <div className="flex flex-col space-y-2">
                  <ProfileLabel
                    label="Practicing Number"
                    value={values?.practicingNumber ?? "N/A"}
                    className="w-48"
                    comments={comments[`${values?.capacity}.practicingNumber`]}
                  />
                  <ProfileLabel
                    label="Practicing Certificate"
                    value={values?.practicingCertificate ?? "N/A"}
                    className="w-48"
                    comments={
                      comments[`${values?.capacity}.practicingCertificate`]
                    }
                  />
                  <Field
                    name="fileUploads"
                    component={FileUploadForm}
                    documentData={[
                      {
                        accept: "image/jpeg, image/jpg",
                        label: "Practicing Certificate",
                        name: "practicingCertificate",
                      },
                    ]}
                    onlyPreview
                    showDocumentLabel
                  />
                  {fileUploadsComments.length > 0 && (
                    <CommentsList
                      comments={fileUploadsComments}
                      color={"text-red-500"}
                    />
                  )}
                </div>
              ) : (
                <div className="flex flex-col space-y-2">
                  <Field
                    component={Input}
                    label="Practicing Number"
                    name="practicingNumber"
                    placeholder="Enter practicing number"
                    horizontalLayout
                    showRequiredAsterisk
                  />
                  <Field
                    component={Input}
                    label="Practicing Certificate"
                    name="practicingCertificate"
                    placeholder="Enter practicing certificate"
                    horizontalLayout
                    showRequiredAsterisk
                  />
                  <Field
                    name="fileUploads"
                    component={FileUploadForm}
                    documentData={[
                      {
                        accept: "application/pdf",
                        label: "Upload Certificate",
                        name: "practicingCertificate",
                      },
                    ]}
                    horizontalLayout
                    showRequiredAsterisk
                  />
                </div>
              )}
            </div>
            {!isViewMode && (
              <div className="flex justify-end space-x-2 mb-2">
                <Button
                  type="button"
                  variant="secondary"
                  disabled={isSubmitting}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={handleSubmit}
                  loadingText={isEditMode ? "Updating..." : "Saving..."}
                >
                  {isEditMode ? "Update" : "Save"}
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ProfessionalDetail;
