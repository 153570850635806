/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from "react";
import {
  Formik,
  Form,
  Field,
  validateYupSchema,
  yupToFormErrors,
  setNestedObjectValues,
} from "formik";
import { useSelector, useDispatch } from "react-redux";
import _omit from "lodash/omit";
import _pickBy from "lodash/pickBy";
import _pick from "lodash/pick";
import _values from "lodash/values";
import _merge from "lodash/merge";
import { object, number } from "yup";
import { v4 as uuidv4 } from "uuid";
import { XIcon } from "@heroicons/react/outline";

import Label from "components/lib/Shared/Label";
import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import CompaniesBeneficialOwnershipComponent from "../../CompanyOfficialsTab/BeneficialOwnershipComponents/CompaniesBeneficialOwnershipComponent";
import OfficialsBeneficialOwnershipComponent from "../../CompanyOfficialsTab/BeneficialOwnershipComponents/OfficialsBeneficialOwnershipComponent";
import NamesForm from "../../CompanyOfficialsTab/NamesComponents/index";
import {
  getEstateDocumentData,
  getDocumentData,
} from "../../CompanyOfficialsTab/FileUploadForm/documentData";
import FileUploadForm from "../../CompanyOfficialsTab/FileUploadForm";
import {
  CORPORATE_ROLES,
  CORPORATE_MAX_VALUE,
  CORPORATE_SHAREHOLDERS_LIMIT,
  INDIVIDUALS,
} from "components/PLCTABS/SFLdata/config/constants";
import { Constants } from "config/constants";
import options from "../../SFLdata/selectOptions";
import JointComponents from "./JointComponents";
import {
  addCorporateShareholder,
  editCorporateShareholder,
} from "state/slices/tables/corporateShareholderTableSlice";
import { setCurrentJSGroup } from "state/slices/tables/corporateJointShareholderTableSlice";
import IdentificationDetailsForm from "../../CompanyOfficialsTab/PersonalDetailsComponents/index";
import getInitialValues from "./getInitialValues";
import getValidationSchema from "./getValidationSchema";
import AddressParticulars from "../BeneficialOwnersAddressParticulars";
import { closeModal } from "state/slices/modals";
import { getFullName, roundNumber } from "utils";
import { handleFilesUpload } from "utils/filesUtils";
import ValidationError from "components/lib/Shared/ValidationError";
import Tabs from "components/lib/Shared/Tabs";
import Verify from "components/lib/Shared/Verify";
import Button from "components/lib/Shared/Button";
import InviteNewUser from "components/lib/Shared/Verify/InviteNewUser";
import InviteExistingUser from "components/lib/Shared/Verify/InviteExistingUser";
import { useToast } from "hooks/useToast";
import ErrorBanner from "components/lib/Shared/ErrorBanner";
import { verifyBtnParams } from "components/lib/Shared/Verify/verifyBtnParams";

const initialTabs = [{ id: "assignOfficials", label: "Assign Officials" }];

const shortFormTabs = [
  { id: "assignOfficials", label: "Assign Officials" },
  {
    id: "beneficialOwnershipAllocation",
    label: "Beneficial Ownership Allocation",
  },
  { id: "selectRole", label: "Role" },
  { id: "generalInformation", label: "General Information" },
  { id: "uploadDocuments", label: "Upload Documents" },
];

const longFormTabs = [
  { id: "assignOfficials", label: "Assign Officials" },
  {
    id: "beneficialOwnershipAllocation",
    label: "Beneficial Ownership Allocation",
  },
  { id: "selectRole", label: "Role" },
  { id: "generalInformation", label: "General Information" },
  { id: "residentialDetails", label: "Residential Details" },
  { id: "uploadDocuments", label: "Upload Documents" },
];

const eleventhEntryFormFields = [
  "id",
  "_id",
  "remainingMinorityShareholders",
  "totalMinorityShareholdingPercent",
  "totalMinorityVotingPercent",
  "corporateIdType",
  "isMeetingThreshold",
  "isReportable",
  "shareholderWithBO",
];

const CorporateSelection = (props) => {
  const {
    mode,
    shareholderWithBO,
    selectedShareholder,
    setShowCorporateBtn,
    corporateIdType,
    currentOfficial,
    allottedShares,
    allottedVotingRights,
    subIDType,
    jointShareholders,
    isViewOnly,
  } = props;

  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState({
    id: "assignOfficials",
    label: "Assign Officials",
  });
  const [validationSchema, setValidationSchema] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const [verificationValue, setVerifyingValue] = useState("");
  const [isIdAssociated, setIsIdAssociated] = useState(null);
  const [disableForm, setDisableForm] = useState(false);
  const [inviteNewUserWorkflow, setInviteNewUserWorkflow] = useState(false);
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);
  const [isAccessRevoked, setIsAccessRevoked] = useState(false);
  const [inviteExistingUser, setInviteExistingUser] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const formStepErrors = useRef(null);

  const { toast } = useToast(4000);
  const dispatch = useDispatch();

  const corporateShareholders = useSelector(
    (state) => state.corporateShareholderTableSlice
  ).filter((bo) => bo.shareholderWithBO === shareholderWithBO);

  const { currentJSGroup } = useSelector(
    (state) => state
  ).corporateJointShareholderTableSlice;

  const goToPrevStep = () => {
    if (selectedTabIndex === 0) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  const handleExitWorkflow = () => {
    setShowCorporateBtn(true);
  };

  const closeVerifyProcess = () => {
    setShowCorporateBtn(true);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  const addOrEditOfficial = async (values) => {
    try {
      const verifyParams = verifyBtnParams["Shareholder"][values.idType];
      if (verifyParams?.name && verifyParams.name === "companyRegNumber") {
        const verifyValue = values.names[verifyParams.name];
        const alreadyExists = corporateShareholders.find(
          (topShareholder) =>
            topShareholder.names[verifyParams.name] === verifyValue &&
            (!mode.editing || mode.id !== (values?.id || values?._id))
        );
        if (alreadyExists) {
          toast("error", "Official already exists as top shareholder.");
          return;
        }
      } else if (verifyParams?.name) {
        const verifyValue = values.identificationDetails[verifyParams.name];
        const alreadyExists = corporateShareholders.find(
          (topShareholder) =>
            topShareholder.identificationDetails[verifyParams.name] ===
              verifyValue &&
            (!mode.editing || mode.id !== (values?.id || values?._id))
        );
        if (alreadyExists) {
          toast("error", "Official already exists as top shareholder.");
          return;
        }
      }
      const uploadedFiles = await handleFilesUpload(
        values.fileUploads,
        currentOfficial,
        mode.editing
      );
      // if (!values.renderLongForm) values = _omit(values, ['postalAddress', 'residentialAddressDetails'])
      let payload = {
        ..._omit(values, ["fileUploads", "renderLongForm", "comments"]),
        fileUploads: {
          ..._pickBy(values.fileUploads, (file) => typeof file === "string"),
          ...uploadedFiles,
        },
        names: {
          ...values.names,
          fullName: getFullName(values.names),
        },
        proRata: roundNumber(
          (selectedShareholder.proRata / 100) *
            values?.beneficialOwnershipForm?.indirectPercentShareholding ?? 0
        ),
        isReportable: selectedShareholder.isReportable,
        isMeetingThreshold: false,
        shareholderWithBO,
      };
      if (corporateIdType === "State Owned Enterprise") {
        payload.idType = "State Owned Enterprise";
        payload.names.fullName = values.nameOfStateAgency;
        payload.beneficialOwnershipForm = {
          maxPercentShareholding: 100,
          indirectPercentShareholding: 100,
          maxPercentVotingRights: 100,
          indirectPercentVotingRights: 100,
          indirectRightRemoveDirector:
            selectedShareholder.beneficialOwnershipForm
              ?.indirectRightRemoveDirector,
          indirectCompanyControlRight:
            selectedShareholder.beneficialOwnershipForm
              ?.indirectCompanyControlRight,
        };
      }
      if (values.idType === "Estate" && !mode.editing) {
        payload.names.fullName = `Estate of the late ${payload.names.fullName}`;
      }
      if (
        payload?.remainingMinorityShareholders &&
        payload?.remainingMinorityShareholders !== ""
      ) {
        payload = _pick(payload, eleventhEntryFormFields);
        payload.idType = "Minority";
      }
      if (!mode.editing) dispatch(addCorporateShareholder(payload));
      else {
        dispatch(editCorporateShareholder(payload));
        dispatch(closeModal("corporate_edit_modal"));
      }
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        role: [],
        corporateIdType,
        idType: subIDType,
        ...initialValues,
        comments:
          transformedComments?.[currentOfficial?._id]?.[
            "beneficialOwnershipTab"
          ] ?? [],
      }}
      validate={(values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            makeFieldsOptional: isIdAssociated === false || isViewOnly,
            maxPercentShareholding:
              values.beneficialOwnershipForm?.maxPercentShareholding ?? 0,
            maxPercentVotingRights:
              values.beneficialOwnershipForm?.maxPercentVotingRights ?? 0,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={async (values, { resetForm }) => {
        // validate form & store errors for the ErrorBanner
        try {
          validateYupSchema(values, validationSchema, true);
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: [],
          };
        } catch (error) {
          formStepErrors.current = {
            ...formStepErrors.current,
            [selectedTab.id]: error.errors,
          };
          if (!isLastStep) {
            setTimeout(goToNextStep, Constants.DELAY_FORM_SUBMISSION);
            return;
          }
        }
        if (!isLastStep) {
          goToNextStep();
          return;
        }

        const {
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = values?.beneficialOwnershipForm ?? {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";

        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";

        const renderForm =
          _values(values.beneficialOwnershipForm).filter(
            (value) => value !== ""
          ).length > 5;

        const renderLongForm =
          renderForm &&
          values.idType !== "Estate" &&
          (isDirectBeneficiary || isIndirectBeneficiary);

        let officialSchema = {};
        if (values?.remainingMinorityShareholders) {
          officialSchema = object().shape({
            remainingMinorityShareholders: number()
              .required("Remaining minority shareholders is required")
              .min(1, "Must be greater than or equal to 1"),
          });
        } else {
          officialSchema = getValidationSchema({
            idType: values.estateIDType || values.idType,
            isNonResident: values.isNonResident,
            documentData: getDocumentData(values.idType),
            beneficialOwnershipForm: values.beneficialOwnershipForm,
            corporateIdType,
            renderLongForm,
          });
        }
        console.log({ values });
        try {
          setIsFormValid(true);
          validateYupSchema(values, officialSchema, true);
        } catch (error) {
          console.log({ error });
          setIsFormValid(false);
          if (error.errors.length > 0) {
            return;
          }
        }
        if (!isViewOnly) await addOrEditOfficial(values);

        dispatch(closeModal("corporate_edit_modal"));
        setShowCorporateBtn(true);
        resetForm();
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        submitCount,
        setFieldValue,
        handleSubmit,
        setTouched,
      }) => {
        const [IDTypeVal, setIDTypeVal] = useState({
          value: values.idType,
          label: values.idType,
        });
        const [estateIDType, setEstateIDType] = useState({
          value: values.estateIDType,
          label: values.estateIDType,
        });

        // update initial values based on current official
        useEffect(() => {
          setInitialValues(_merge(values, currentOfficial));
        }, [currentOfficial]);

        const showEleventhEntryForm =
          (!mode.editing || currentOfficial.remainingMinorityShareholders) &&
          (mode.editing ||
            corporateShareholders.length === CORPORATE_SHAREHOLDERS_LIMIT) &&
          (allottedShares < CORPORATE_MAX_VALUE ||
            allottedVotingRights < CORPORATE_MAX_VALUE) &&
          !currentJSGroup?.JSGroupID;

        // update initial values based on current form step
        useEffect(() => {
          let documentData = [];
          if (values.idType === "Estate") {
            documentData = getEstateDocumentData(
              values.isEstateDistributionDetermined
            );
          } else {
            documentData = getDocumentData(values.idType);
          }
          if (!showEleventhEntryForm) {
            let tempValues = { ...values };
            if (values?.beneficialOwnershipForm) {
              tempValues = {
                ...values,
                beneficialOwnershipForm: {
                  ...values.beneficialOwnershipForm,
                  dateOfBecomingBO:
                    values?.beneficialOwnershipForm?.dateOfBecomingBO !== null
                      ? values?.beneficialOwnershipForm?.dateOfBecomingBO
                      : "",
                },
              };
            }
            setInitialValues(
              _merge(
                getInitialValues({
                  idType: values.idType,
                  isNonResident: values.isNonResident,
                  documentData,
                  beneficialOwnershipForm: values.beneficialOwnershipForm,
                  allottedShares,
                  allottedVotingRights,
                }),
                tempValues
              )
            );
          }
        }, [
          values.idType,
          values.isEstateDistributionDetermined,
          values.isNonResident,
          allottedShares,
          allottedVotingRights,
          showEleventhEntryForm,
        ]);

        const [jsIDType, setJSIDType] = useState({
          value: values.jsIDType,
          label: values.jsIDType,
        });

        useEffect(() => {
          if (values.idType === "Joint Shareholder" && !currentJSGroup)
            dispatch(setCurrentJSGroup({ JSGroupID: uuidv4() }));
        }, [values.idType, currentJSGroup]);

        useEffect(() => {
          if (jointShareholders && jointShareholders.length > 0)
            setFieldValue("jsIDType", jointShareholders[0]?.idType);
        }, [jointShareholders]);

        const [selectedRoles, setSelectedRoles] = useState(
          currentOfficial?.role?.map((role) => ({ label: role, value: role }))
        );

        useEffect(() => {
          setSelectedRoles(
            currentOfficial?.role?.map((role) => ({ label: role, value: role }))
          );
        }, [currentOfficial]);

        const [selectedCountry, seSelectedCountry] = useState(
          options.countries.find(
            (country) => country.value === currentOfficial.country
          )
        );

        const handleSelect =
          (name) =>
          ({ id, ...option }) => {
            setFieldValue(name, option.value);
            if (name === "estateIDType") setEstateIDType(option);
            if (name === "idType") {
              setFieldValue("jsIDType", "");
              setJSIDType({});
              setFieldValue("estateIDType", "");
              setEstateIDType({});
              setFieldValue("isEstateDistributionDetermined", "");
              setIDTypeVal(option);
            }
            if (name === "jsIDType") setJSIDType(option);
          };

        const handleRoleSelect = (name) => (option) => {
          const roles = Array.isArray(option)
            ? option.map(({ value }) => value)
            : option?.value
            ? [option?.value]
            : [];
          setFieldValue(name, roles);
          setSelectedRoles(option);
        };

        const handleSetValue = ({ target: { name, value } }) => {
          setFieldValue(name, value);
        };

        const {
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = values?.beneficialOwnershipForm ?? {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";
        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";

        const renderForm =
          _values(values.beneficialOwnershipForm).filter(
            (value) => value !== ""
          ).length > 5;
        const renderLongForm =
          renderForm &&
          values.idType !== "Estate" &&
          (isDirectBeneficiary || isIndirectBeneficiary);
        const renderShortForm =
          renderForm &&
          ((!isDirectBeneficiary && !isIndirectBeneficiary) ||
            values.idType === "Estate");

        const handleResetForm = () => {
          setFieldValue("names", initialValues.names);
          setFieldValue(
            "identificationDetails",
            initialValues.identificationDetails
          );
          setFieldValue("postalAddress", initialValues.postalAddress);
          setFieldValue("electronicAddress", initialValues.electronicAddress);
          setFieldValue(
            "residentialAddressDetails",
            initialValues.residentialAddressDetails
          );
          setFieldValue("fileUploads", initialValues.fileUploads);
          formStepErrors.current = null;
        };

        useEffect(() => {
          setVerifyingValue("");
          setIsIdAssociated(null);
          setInviteNewUserWorkflow(false);
          setIsAccessAllowed(false);
          setIsAccessRevoked(false);
          formStepErrors.current = null;
          if (!mode.editing || isViewOnly) setDisableForm(true);
        }, [values.idType]);

        // Update validation schema based on current form step
        useEffect(() => {
          let documentData = [];
          if (values.idType === "Estate") {
            documentData = getEstateDocumentData(
              values.isEstateDistributionDetermined
            );
          } else {
            documentData = getDocumentData(values.idType);
          }
          if (!showEleventhEntryForm) {
            setValidationSchema(
              getValidationSchema({
                selectedTab,
                idType: values.estateIDType || values.idType,
                isNonResident: values.isNonResident,
                documentData,
                beneficialOwnershipForm: values.beneficialOwnershipForm,
                corporateIdType,
                renderLongForm,
              }).schema
            );
          } else
            setValidationSchema(
              object().shape({
                remainingMinorityShareholders: number()
                  .required("Remaining minority shareholders is required")
                  .min(1, "Must be greater than or equal to 1"),
              })
            );
        }, [
          selectedTab,
          values.idType,
          values.isEstateDistributionDetermined,
          values.isNonResident,
          values?.beneficialOwnershipForm,
          allottedShares,
          allottedVotingRights,
          showEleventhEntryForm,
          corporateIdType,
          renderLongForm,
        ]);

        useEffect(() => {
          setFieldValue("renderLongForm", renderLongForm);
        }, [renderLongForm]);

        useEffect(() => {
          if (currentOfficial?.id || currentOfficial?._id) {
            setFieldValue(
              "beneficialOwnershipForm.maxPercentShareholding",
              roundNumber(CORPORATE_MAX_VALUE - allottedShares)
            );
            setFieldValue(
              "beneficialOwnershipForm.maxPercentVotingRights",
              roundNumber(CORPORATE_MAX_VALUE - allottedVotingRights)
            );
          }
        }, [
          CORPORATE_MAX_VALUE,
          allottedShares,
          allottedVotingRights,
          currentOfficial,
        ]);

        useEffect(() => {
          if (showEleventhEntryForm) {
            let totalMinorityShareholdingPercent =
              CORPORATE_MAX_VALUE - allottedShares;
            if (corporateIdType === "Company Limited By Guarantee/NGO")
              totalMinorityShareholdingPercent = 0;
            setFieldValue(
              "totalMinorityShareholdingPercent",
              roundNumber(totalMinorityShareholdingPercent)
            );
            setFieldValue(
              "totalMinorityVotingPercent",
              roundNumber(CORPORATE_MAX_VALUE - allottedVotingRights)
            );
          }
        }, [
          corporateIdType,
          showEleventhEntryForm,
          allottedShares,
          allottedVotingRights,
        ]);

        // update form steps based on long or short form
        useEffect(() => {
          let tempTabs = [];
          if (renderLongForm) tempTabs = longFormTabs;
          if (!renderLongForm || values.idType === "Estate")
            tempTabs = shortFormTabs;
          if (mode.editing) {
            tempTabs = tempTabs.filter((tab) => tab.id !== "assignOfficials");
          }
          if (
            showEleventhEntryForm ||
            corporateIdType === "State Owned Enterprise" ||
            values.idType === "Joint Shareholder"
          ) {
            tempTabs = [{ id: "assignOfficials", label: "Assign Officials" }];
          }
          setTabs(tempTabs);
        }, [
          values,
          renderLongForm,
          mode.editing,
          showEleventhEntryForm,
          corporateIdType,
        ]);

        // Reset touched fields on tab change, e.g fix errors jumping around
        useEffect(() => {
          setTouched({}, false);
          setIsFormValid(true);
        }, [selectedTab, setTouched]);

        const handleCountryChange = (option) => {
          setFieldValue("country", option.value);
          seSelectedCountry(option);
        };

        const getIdTypes = () => {
          const IdTypes = [
            ...Constants.COMPANY_OFFICIAL_ID_TYPES,
            { value: "Estate", label: "Estate" },
          ];
          if (["Company Limited By Guarantee/NGO"].includes(corporateIdType)) {
            IdTypes.push({
              value: "Joint Shareholder",
              label: "Joint Shareholder",
            });
          }
          return IdTypes;
        };

        const getRoleOptions = () => {
          if (values.idType === "Estate") {
            return [
              { label: "Director", value: "Director" },
              { label: "Shareholder", value: "Shareholder" },
            ];
          }
          return CORPORATE_ROLES[corporateIdType];
        };

        const handleNewUserWorkflow = () => {
          if (Object.keys(errors).length > 0) {
            setTouched(setNestedObjectValues(errors, true));
            return;
          } else setInviteNewUserWorkflow(true);
        };

        // Update ErrorBanner errors when user update data
        useEffect(() => {
          if (formStepErrors.current?.[selectedTab.id]?.length > 0) {
            try {
              validateYupSchema(values, validationSchema, true);
              formStepErrors.current = {
                ...formStepErrors.current,
                [selectedTab.id]: [],
              };
            } catch (error) {
              formStepErrors.current = {
                ...formStepErrors.current,
                [selectedTab.id]: error.errors,
              };
            }
          }
        }, [values]);

        // set touched to true for required fields to render errors
        useEffect(() => {
          if (
            values.isIncompleteProfile &&
            Object.keys(errors).length > 0 &&
            submitCount > 0
          ) {
            setTouched(setNestedObjectValues(errors, true));
          }
        }, [values.isIncompleteProfile, errors, submitCount]);

        // Invite new user form
        if (inviteNewUserWorkflow && selectedTab.id !== "assignOfficials") {
          return (
            <>
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                />
              </div>
              <Button
                className="flex my-2 ml-auto"
                onClick={handleExitWorkflow}
                variant="text"
              >
                <XIcon className="block w-6 h-6" aria-hidden="true" />
              </Button>
              <InviteNewUser
                closeVerifyProcess={closeVerifyProcess}
                idType={IDTypeVal.idType}
                designation={"Shareholder"}
                values={values}
                inviteOfficial={addOrEditOfficial}
              />
            </>
          );
        }

        if (inviteExistingUser) {
          return (
            <>
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                />
              </div>
              <Button
                variant="text"
                className="flex my-2 ml-auto"
                onClick={handleExitWorkflow}
              >
                <XIcon className="block w-6 h-6" aria-hidden="true" />
              </Button>
              <InviteExistingUser
                userId={values.userId}
                idType={IDTypeVal.idType}
                designation={"Shareholder"}
                closeVerifyProcess={closeVerifyProcess}
                userEmailAddress={values.userEmailAddress}
                values={values}
                inviteOfficial={addOrEditOfficial}
              />
            </>
          );
        }

        return (
          <Form>
            {/* Official's form tabs */}
            {values.designation === "Joint Shareholder" &&
            !showJSForm ? null : (
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={
                    isViewOnly ? setSelectedTabIndex : () => null
                  }
                />
              </div>
            )}
            {isIdAssociated === false &&
              formStepErrors.current?.[selectedTab.id]?.length > 0 && (
                <ErrorBanner
                  errors={[...(formStepErrors.current?.[selectedTab.id] ?? [])]}
                  className="my-4"
                />
              )}
            <div className="flex justify-between mt-2">
              {/* Invite new user button */}
              {verificationValue &&
                isIdAssociated === false &&
                selectedTab.id &&
                ![
                  "assignOfficials",
                  "beneficialOwnershipAllocation",
                  "selectRole",
                  "generalInformation",
                ].includes(selectedTab.id) &&
                !mode.editing && (
                  <Button onClick={handleNewUserWorkflow}>Invite</Button>
                )}
              {!mode.editing && (
                <Button
                  className="flex ml-auto"
                  variant="text"
                  onClick={handleExitWorkflow}
                >
                  <XIcon className="block w-6 h-6" aria-hidden="true" />
                </Button>
              )}
            </div>
            {/* <h3 className="mb-2 text-lg font-medium leading-6 text-gray-900">Beneficial Owner Appointment</h3> */}
            {(mode.editing || shareholderWithBO) &&
            corporateIdType === "State Owned Enterprise" ? (
              <>
                <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                  <div className="px-4 py-6 space-y-2 sm:p-6">
                    <div className="grid grid-cols-4 gap-4">
                      <div className="col-span-4 sm:col-span-2">
                        <Label htmlFor="stateOwnedEnterpriseRole">Role</Label>
                        <Field
                          onChange={handleRoleSelect("role")}
                          name="role"
                          component={ReactSelectWithFormik}
                          options={getRoleOptions()}
                          value={selectedRoles}
                          placeholder="Select role"
                          id="stateOwnedEnterpriseRole"
                          isMulti
                          isDisabled={isViewOnly || values?.isReadOnly}
                        />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <Label htmlFor="nameOfStateAgency">
                          Name of State Agency
                        </Label>
                        <Field
                          component={Input}
                          name="nameOfStateAgency"
                          type="text"
                          id="nameOfStateAgency"
                          disabled={isViewOnly || values?.isReadOnly}
                        />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <Label htmlFor="nameOfGovernment">
                          Name of Government
                        </Label>
                        <Field
                          component={Input}
                          name="nameOfGovernment"
                          type="text"
                          id="nameOfGovernment"
                          disabled={isViewOnly || values?.isReadOnly}
                        />
                      </div>
                      <div className="col-span-4 sm:col-span-2">
                        <Label htmlFor="country">Country</Label>
                        <Field
                          name="country"
                          component={ReactSelectWithFormik}
                          options={options.countries}
                          onChange={handleCountryChange}
                          value={selectedCountry}
                          placeholder="Select country"
                          id="country"
                          isDisabled={isViewOnly || values?.isReadOnly}
                        />
                        <ValidationError
                          errors={errors}
                          touched={touched}
                          name="country"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Button className="mt-2" type="button" onClick={handleSubmit}>
                  Submit
                </Button>
              </>
            ) : (
              <>
                {showEleventhEntryForm && (
                  <>
                    <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                      <div className="px-4 py-6 space-y-2 sm:p-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Eleventh Entry Form
                        </h3>
                        <div className="grid grid-cols-6 gap-4">
                          <div className="col-span-6 sm:col-span-3">
                            <Label htmlFor="remainingMinorityShareholders">
                              Total Number of Minority Shareholders remaining
                            </Label>
                            <Input
                              id="remainingMinorityShareholders"
                              name="remainingMinorityShareholders"
                              type="number"
                              onChange={handleSetValue}
                              value={values.remainingMinorityShareholders}
                              required
                              disabled={isViewOnly || values?.isReadOnly}
                            />
                            <ValidationError
                              errors={errors}
                              touched={{
                                ...touched,
                                remainingMinorityShareholders: true,
                              }}
                              name="remainingMinorityShareholders"
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <Label htmlFor="totalMinorityShareholdingPercent">
                              Total percentage shareholding of the Minority
                              Shareholders
                            </Label>
                            <Input
                              id="totalMinorityShareholdingPercent"
                              name="totalMinorityShareholdingPercent"
                              type="number"
                              onChange={handleSetValue}
                              value={values.totalMinorityShareholdingPercent}
                              readOnly
                              disabled={isViewOnly || values?.isReadOnly}
                            />
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <Label htmlFor="totalMinorityVotingPercent">
                              Total percentage voting of the Minority
                              Shareholders{" "}
                            </Label>
                            <Input
                              id="totalMinorityVotingPercent"
                              name="totalMinorityVotingPercent"
                              type="number"
                              onChange={handleSetValue}
                              value={values.totalMinorityVotingPercent}
                              readOnly
                              disabled={isViewOnly || values?.isReadOnly}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "98%" }}>
                      <Button
                        className="mt-2"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                )}
                {corporateShareholders.length < CORPORATE_SHAREHOLDERS_LIMIT &&
                  selectedTab.id === "assignOfficials" &&
                  !mode.editing && (
                    <div className="px-4 py-6 mt-4 mb-2 bg-white rounded-sm shadow-sm sm:p-6 sm:rounded md:rounded-md">
                      <div className="col-span-6 sm:col-span-3">
                        <Label htmlFor="nested-idType">Select ID Types</Label>
                        <Field
                          name="idType"
                          component={ReactSelectWithFormik}
                          options={getIdTypes()}
                          id="nested-idType"
                          placeholder="Select id type"
                          onChange={handleSelect("idType")}
                          value={IDTypeVal.value}
                        />
                      </div>
                    </div>
                  )}
                {values.idType === "Joint Shareholder" && (
                  <div className="px-4 py-6 mt-4 mb-2 bg-white rounded-sm shadow-sm sm:p-6 sm:rounded md:rounded-md">
                    <div className="col-span-6 sm:col-span-3">
                      <Label htmlFor="jsIDType">
                        Joint Shareholder ID Type
                      </Label>
                      <Field
                        name="jsIDType"
                        component={ReactSelectWithFormik}
                        options={options.shareholderIDs}
                        id="jsIDType"
                        placeholder="Select id type"
                        onChange={handleSelect("jsIDType")}
                        value={jsIDType}
                      />
                    </div>
                  </div>
                )}
                {values.idType === "Joint Shareholder" &&
                  (values.jsIDType ||
                    currentJSGroup?._id ||
                    currentJSGroup?.id) && (
                    <JointComponents
                      selectedShareholder={selectedShareholder}
                      shareholderWithBO={shareholderWithBO}
                      beneficialOwnershipForm={{
                        ...values.beneficialOwnershipForm,
                        maxPercentShareholding: roundNumber(
                          CORPORATE_MAX_VALUE - allottedShares
                        ),
                        maxPercentVotingRights: roundNumber(
                          CORPORATE_MAX_VALUE - allottedVotingRights
                        ),
                      }}
                      idType={values.jsIDType}
                      setShowCorporateBtn={setShowCorporateBtn}
                      allottedShares={allottedShares}
                      allottedVotingRights={allottedVotingRights}
                      corporateIdType={corporateIdType}
                      handleJsIdChange={handleSelect("jsIDType")}
                    />
                  )}
                {values.idType === "Estate" &&
                  selectedTab.id === "assignOfficials" && (
                    <div className="px-4 py-6 mt-4 mb-2 bg-white rounded-sm shadow-sm sm:p-6 sm:rounded md:rounded-md">
                      <div className="col-span-6 sm:col-span-6">
                        <Label htmlFor="estateIDType">
                          Select Estate ID Types
                        </Label>
                        <Field
                          name="estateIDType"
                          component={ReactSelectWithFormik}
                          options={options.shareholderIDs}
                          placeholder="Select Estate ID Types"
                          id="estateIDType"
                          onChange={handleSelect("estateIDType")}
                        />
                        <ValidationError
                          errors={errors}
                          touched={touched}
                          name="estateIDType"
                        />
                      </div>
                    </div>
                  )}
                {values.estateIDType &&
                  selectedTab.id === "assignOfficials" && (
                    <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                      <div className="px-4 py-6 space-y-2 sm:p-6">
                        <span>Is the Estate distribution determined?</span>
                        <div className="flex space-x-2">
                          <div className="flex space-x-2">
                            <Label htmlFor="yesEstateIsDistributionDetermined">
                              YES
                            </Label>
                            <Field
                              type="radio"
                              name="isEstateDistributionDetermined"
                              id="yesEstateIsDistributionDetermined"
                              value="Yes"
                            />
                          </div>
                          <div className="flex space-x-2">
                            <Label htmlFor="noEstateIsDistributionDetermined">
                              NO
                            </Label>
                            <Field
                              type="radio"
                              name="isEstateDistributionDetermined"
                              id="noEstateIsDistributionDetermined"
                              value="No"
                            />
                          </div>
                        </div>
                        <ValidationError
                          errors={errors}
                          touched={touched}
                          name="isEstateDistributionDetermined"
                        />
                      </div>
                    </div>
                  )}
                {values.idType &&
                  (values.idType !== "Estate" ||
                    values.isEstateDistributionDetermined) &&
                  values.idType !== "Joint Shareholder" && (
                    <>
                      {selectedTab.id === "beneficialOwnershipAllocation" && (
                        <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                          <div className="px-4 py-6 sm:p-6">
                            <h2 className="mb-1 text-lg font-medium leading-6 text-gray-900">
                              Beneficial Ownership
                            </h2>
                            {INDIVIDUALS.includes(values.idType) ||
                            values.idType === "Estate" ? (
                              <Field
                                name="beneficialOwnershipForm"
                                component={
                                  OfficialsBeneficialOwnershipComponent
                                }
                                corporateIdType={corporateIdType}
                                disableChangeEffect
                                isBOTab
                                isReportable={renderLongForm === true}
                                disabled={values?.isReadOnly}
                              />
                            ) : (
                              <Field
                                name="beneficialOwnershipForm"
                                component={
                                  CompaniesBeneficialOwnershipComponent
                                }
                                corporateIdType={corporateIdType}
                                isReportable={renderLongForm === true}
                                disabled={values?.isReadOnly}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {(renderShortForm || renderLongForm) && (
                        <div>
                          {selectedTab.id === "selectRole" && (
                            <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                              <div className="px-4 py-6 sm:p-6">
                                <h2 className="mb-1 text-lg font-medium leading-6 text-gray-900">
                                  Role
                                </h2>
                                <Field
                                  onChange={handleRoleSelect("role")}
                                  name="role"
                                  component={ReactSelectWithFormik}
                                  options={getRoleOptions()}
                                  value={selectedRoles}
                                  id="role"
                                  placeholder="Select role"
                                  isMulti={[
                                    "Trust",
                                    "Estate",
                                    "Public Limited",
                                    "Private Limited",
                                  ].includes(corporateIdType)}
                                  isDisabled={values?.isReadOnly}
                                />
                              </div>
                            </div>
                          )}
                          {selectedTab.id === "generalInformation" && (
                            <>
                              <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                                <div className="px-4 py-6 space-y-6 sm:p-6">
                                  {!mode.editing && (
                                    <>
                                      <div className="mb-4">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                                          Personal Information
                                        </h3>
                                      </div>
                                      <Verify
                                        idType={
                                          values.estateIDType || values.idType
                                        }
                                        designation="Shareholder"
                                        tabName="beneficialOwners"
                                        verificationValue={verificationValue}
                                        isIdAssociated={isIdAssociated}
                                        isAccessAllowed={isAccessAllowed}
                                        isAccessRevoked={isAccessRevoked}
                                        beneficialOwner={shareholderWithBO}
                                        setFieldValue={setFieldValue}
                                        setVerifyingValue={setVerifyingValue}
                                        setIsIdAssociated={setIsIdAssociated}
                                        setDisableForm={setDisableForm}
                                        resetForm={handleResetForm}
                                        handleNewUserWorkflow={
                                          handleNewUserWorkflow
                                        }
                                        setIsAccessAllowed={setIsAccessAllowed}
                                        setIsAccessRevoked={setIsAccessRevoked}
                                        setInviteExistingUser={
                                          setInviteExistingUser
                                        }
                                      />
                                    </>
                                  )}
                                  {submitCount > 3 &&
                                    disableForm &&
                                    !isAccessAllowed &&
                                    !isViewOnly && (
                                      <div
                                        className="flex items-center py-1 text-sm leading-none text-red-500 bg-gray-200 lg:rounded-full lg:inline-flex"
                                        role="alert"
                                      >
                                        <span className="flex-auto px-2 font-semibold text-left">
                                          To activate the form fields please
                                          verify your ID
                                        </span>
                                      </div>
                                    )}
                                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    General Information
                                  </h3>
                                  <Field
                                    name="names"
                                    component={NamesForm}
                                    disabled={disableForm}
                                    disableVerificationField={
                                      isIdAssociated === false || mode.editing
                                    }
                                    idType={
                                      values.estateIDType || values.idType
                                    }
                                  />
                                  <hr />
                                  <IdentificationDetailsForm
                                    disabled={
                                      disableForm && !values.isIncompleteProfile
                                    }
                                    disableVerificationField={
                                      isIdAssociated === false || mode.editing
                                    }
                                    idType={
                                      values.estateIDType || values.idType
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          {!renderLongForm &&
                            selectedTab.id === "uploadDocuments" && (
                              <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                                <div className="px-4 py-6 sm:p-6">
                                  <h4>Please upload the following documents</h4>
                                  {values.idType === "Estate" ? (
                                    <Field
                                      name="fileUploads"
                                      component={FileUploadForm}
                                      documentData={getEstateDocumentData(
                                        values.isEstateDistributionDetermined
                                      )}
                                    />
                                  ) : (
                                    <Field
                                      name="fileUploads"
                                      component={FileUploadForm}
                                      documentData={getDocumentData(
                                        values.estateIDType || values.idType
                                      )}
                                      disabled={
                                        disableForm && !values?.enableLongForm
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                      {renderLongForm && (
                        <>
                          {selectedTab.id === "residentialDetails" && (
                            <AddressParticulars
                              idType={values.estateIDType || values.idType}
                              isNonResident={values.isNonResident}
                              disabled={
                                disableForm &&
                                !values?.enableLongForm &&
                                !values.isIncompleteProfile
                              }
                            />
                          )}
                          {selectedTab.id === "uploadDocuments" && (
                            <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                              <div className="px-4 py-6 sm:p-6">
                                <h4>Please upload the following documents</h4>
                                {values.idType === "Estate" ? (
                                  <Field
                                    name="fileUploads"
                                    component={FileUploadForm}
                                    documentData={getEstateDocumentData(
                                      values.isEstateDistributionDetermined
                                    )}
                                  />
                                ) : (
                                  <Field
                                    name="fileUploads"
                                    component={FileUploadForm}
                                    documentData={getDocumentData(
                                      values.estateIDType || values.idType
                                    )}
                                    disabled={
                                      disableForm &&
                                      !values?.enableLongForm &&
                                      !values.isIncompleteProfile
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                {isLastStep && !isFormValid && isIdAssociated === false && (
                  <p className="my-2 text-sm text-red-500">
                    Either enter all the required information or invite the
                    official.
                  </p>
                )}
                {values.idType !== "Joint Shareholder" && (
                  <div className="flex justify-between py-3">
                    <div
                      className={`flex w-full ${
                        isFirstStep ? "justify-end" : "justify-between"
                      } py-3`}
                    >
                      {!isFirstStep && (
                        <Button
                          disabled={isSubmitting}
                          onClick={goToPrevStep}
                          color="gray"
                        >
                          Go back
                        </Button>
                      )}
                      <Button
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        isLoading={isSubmitting && isLastStep}
                      >
                        Save and proceed
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CorporateSelection;
