import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import _pick from "lodash/pick";

import Hero from "components/lib/Global/Hero";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import PreferredPlan from "./PreferredPlan";
// import PaymentMethod from "./PaymentMethod";
// import PaymentSummary from "./PaymentSummary";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { getPackagesAsync } from "state/slices/package";
import { verifyDiscountCodeAsync } from "state/slices/discount";
import { useToast } from "hooks/useToast";
import { generateOrderIdAsync } from "state/slices/order";
import { Constants } from "config/constants";
import { classNames, delay, getFullName } from "utils";
import {
  // cancelTransactionAsync,
  // checkTransactionStatusAsync,
  initiateCheckoutAsync,
  checkTransactionTokenAsync,
  // verifyPaymentAsync,
} from "state/slices/payment";
// import { format } from "date-fns";
import { ExclamationIcon } from "@heroicons/react/outline";
// import { updateUser } from "state/slices/user";

const pages = [
  {
    name: "Select your package",
    href: "/#package-selection",
    current: false,
  },
  { name: "Register your business", href: "#", current: false },
  {
    name: "Status",
    href: "#",
    current: true,
  },
];

function Checkout({ self, companyType, company, organization }) {
  const [fetchingPackage, setFetchingPackage] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [submittingToCheckout, setSubmittingToCheckout] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [paymentResponse, setPaymentResponse] = useState(false);
  const [transactionToken, setTransactionToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canRetryPayment, setCanRetryPayment] = useState(false);
  const [checkoutPayload, setCheckoutPayload] = useState(false);
  const [checkoutResponse, setCheckoutResponse] = useState(null);
  const [isRetrying, setIsRetrying] = useState(false);
  const history = useHistory();

  const { packages } = useSelector((state) => state.packageSlice);
  const { getUserStatus } = useSelector((state) => state.authSlice);
  const userSlice = useSelector((state) => state.userSlice);

  const { pendingPaidApplicationsCount } = packages;

  const dispatch = useDispatch();
  const { toast } = useToast();

  const navigation = [
    {
      id: "preferredPlan",
      name: "1. Preferred plan",
    },
    // {
    //   id: "paymentMethod",
    //   name: "2. Payment method",
    // },
    // {
    //   id: "paymentSummary",
    //   name: "3. Payment summary",
    // },
  ];

  const [selectedTab, setSelectedTab] = useState(navigation[0]);
  const abbrevCompanyType = Constants.COMPANY_NAME_ABBREVIATION[companyType];

  useEffect(async () => {
    const token = await dispatch(
      checkTransactionTokenAsync({
        data: {
          companyType,
        },
      })
    );
    const { nextStep } = token.payload;
    if (nextStep === Constants.PAYMENT_TRANSACTION_NEXT_STEPS.PROCEED) {
      // Redirect to company formation
      history.push(
        `/checkout-success?${token.payload.paymentTransaction.query}`
      );
    }
  }, []);
  useEffect(async () => {
    await dispatch(
      getPackagesAsync({
        service:
          companyType === "Private Company"
            ? "Private Limited Company"
            : companyType,
      })
    );
    setFetchingPackage(false);
  }, [companyType, abbrevCompanyType]);

  const tabs = useMemo(() => {
    return packages.items
      .map((pricingPackage) => ({
        id: pricingPackage._id,
        label: pricingPackage.name,
      }))
      .sort((a, b) => {
        const order = ["Basic", "Print", "Premium"];

        // Get the index of each object's label in the order array
        const indexA = order.indexOf(a.label);
        const indexB = order.indexOf(b.label);

        // Compare the indices and return the comparison result
        return indexA - indexB;
      });
  }, [packages.items]);

  const selectedPackage = packages.items.find(
    (pricingPackage) => pricingPackage._id === tabs[selectedTabIndex].id
  );

  if (fetchingPackage || getUserStatus.status === "loading") {
    return (
      <DashboardWrapper showBreadCrumbs pages={pages}>
        <div className="flex justify-center mx-auto mt-4 text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </DashboardWrapper>
    );
  }

  if (packages.totalCount === 0) {
    return (
      <DashboardWrapper showBreadCrumbs pages={pages}>
        <div className="flex justify-center mx-auto mt-4">
          No Pricing package found
        </div>
      </DashboardWrapper>
    );
  }

  const handleProceedToCheckout = async (selectedPackage, values) => {
    let tempDiscountPercentage = 0;
    try {
      setSubmittingToCheckout(true);
      if (promoCode) {
        const response = await dispatch(
          verifyDiscountCodeAsync({
            code: promoCode,
            package: selectedPackage._id,
            self,
            company,
            organization,
          })
        );
        if (response.error) {
          toast(
            "error",
            response.payload?.messageText ??
              "Something went wrong please try again"
          );
          return;
        }
        setDiscountPercentage(response.payload.discount);
        tempDiscountPercentage = response.payload.discount;
      }

      const actualAmount =
        (selectedCurrency.basePrice + (selectedCurrency?.profitMargin ?? 0)) *
        selectedCurrency.applicableTax;
      const discountAmount = tempDiscountPercentage
        ? actualAmount * (tempDiscountPercentage / 100)
        : 0;
      // const totalAmount = actualAmount - discountAmount;

      const initialValues = {
        firstName: values.firstName ?? "",
        lastName: values.lastName ?? "",
        email: values.email ?? "",
        mobileNumber: values.mobileNumber ?? "",
        billingAddress: values.billingAddress,
        paymentMethod: "mpesa",
        mpesaPhoneNumber:
          userSlice?.mpesaPhoneNumber ??
          userSlice?.electronicAddress?.mobileNumber?.replace("+", "") ??
          "",
        zipCode: values.zipCode ?? "",
        city: values.city ?? "",
        country: values.country ?? "",
        pin: values.pin ?? "",
      };

      await handleMakePayment(actualAmount, discountAmount, initialValues);
      return;
    } catch (error) {
      toast("error", error?.message ?? "Something went wrong please try again");
      setSubmittingToCheckout(false);
    } finally {
    }
  };

  // const handleGoBack = (tab) => {
  //   if (tab === 0) {
  //     setDiscountPercentage(0);
  //     setPromoCode("");
  //   }
  //   setSelectedTab(navigation[tab]);
  // };

  const sflData = Constants.SFL_DATA;

  // const handleMpesaPaymentResponse = async (response, payload) => {
  //   console.log("trigger redeploy");
  //   setPaymentResponse(response.payload.message);
  //   setTransactionToken(response.payload.transactionToken);

  //   // wait for 40 seconds before checking the transaction status
  //   await delay(40000);

  //   const MAX_DURATION = 25000; // 25 seconds
  //   const startTime = new Date().getTime();
  //   let isTransactionPending = true;

  //   while (isTransactionPending) {
  //     const currentTime = new Date().getTime();
  //     const elapsedTime = currentTime - startTime;
  //     console.log({ elapsedTime }, "seconds passed");
  //     if (elapsedTime >= MAX_DURATION) {
  //       // Break out of the loop if the maximum duration is reached
  //       isTransactionPending = false;
  //       setCanRetryPayment(true);
  //       toast("error", "Something went wrong please try again");
  //       break;
  //     }

  //     const transactionStatusResponse = await dispatch(
  //       checkTransactionStatusAsync({
  //         data: { transactionToken: response.payload.transactionToken },
  //       })
  //     );

  //     if (transactionStatusResponse.payload.status) {
  //       isTransactionPending = false;
  //       const verifyPaymentResponse = await dispatch(
  //         verifyPaymentAsync({
  //           data: {
  //             TransID: response.payload.transactionToken,
  //             transRef: response.payload.transRef,
  //             payload,
  //           },
  //         })
  //       );
  //       const { redirectUrl } = verifyPaymentResponse.payload;
  //       history.push(redirectUrl);
  //     } else if (!transactionStatusResponse.payload.reconfirm) {
  //       isTransactionPending = false;
  //       setTransactionToken(null);
  //       setPaymentResponse(false);
  //       toast(
  //         "error",
  //         transactionStatusResponse.payload?.message ??
  //         "Something went wrong please try again"
  //       );
  //       setIsSubmitting(false);
  //     } else {
  //       await delay(5000);
  //     }
  //   }
  //   setIsSubmitting(false);
  // };

  // const handleCardPaymentResponse = async (response, payload) => {
  //   const verifyPaymentResponse = await dispatch(
  //     verifyPaymentAsync({
  //       data: {
  //         TransID: response.payload.transactionToken,
  //         transRef: response.payload.transRef,
  //         payload,
  //       },
  //     })
  //   );
  //   const { redirectUrl } = verifyPaymentResponse.payload;
  //   history.push(redirectUrl);
  //   setIsSubmitting(false);
  // };

  const handleMakePayment = async (
    actualAmount,
    discountAmount,
    values,
    onSuccess = () => {}
  ) => {
    const amountToBeCharged = actualAmount - discountAmount;
    setCanRetryPayment(false);
    setPaymentResponse(false);
    setIsSubmitting(true);
    const invoice = {
      plan: selectedPackage.name,
      itemDescription: `Incorporation of a ${selectedPackage.service}`,
      itemActualAmount: actualAmount,
      itemDiscountAmount: discountAmount,
      currency: selectedCurrency.type,
      applicableTax: selectedCurrency.applicableTax,
      // paymentMethod,
    };

    // const invoiceOld = {
    //   plan: selectedPackage.name,
    //   billFrom: {
    //     name: sflData.name,
    //     address: `${sflData.buildingName}, ${sflData.floorNumber}, ${sflData.streetName}, ${sflData.location.county}`,
    //     pin: sflData.pin,
    //   },
    //   billTo: {
    //     name: getFullName(userSlice.names),
    //     address: values.billingAddress,
    //     pin: userSlice.identificationDetails?.KRAPin,
    //   },
    //   items: [
    //     {
    //       description: selectedPackage.product.description,
    //       quantity: 1,
    //       actualAmount,
    //       discountAmount,
    //       rate: amountToBeCharged,
    //     },
    //   ],
    //   currency: selectedCurrency.type,
    //   isTaxApplicable: selectedCurrency.applicableTax > 1,
    //   applicableTax: selectedCurrency.applicableTax,
    //   paymentMethod,
    //   ..._pick(values, ["firstName", "email"]),
    // };

    const generateOrderIdResponse = await dispatch(
      generateOrderIdAsync({ type: abbrevCompanyType })
    );

    const orderId = generateOrderIdResponse.payload.orderId;

    const payload = {
      company: abbrevCompanyType,
      orderId,
      ...invoice,
    };
    // console.log({
    //   payload,
    //   mpesaPhoneNumber,
    //   st: Constants.DPO.SERVICE_TYPES.COMPANY_REGISTRATION,
    // });
    // return;
    setCheckoutPayload(payload);
    // dispatch(updateUser({ id: userSlice._id, data: { mpesaPhoneNumber } }));
    const response = await dispatch(
      initiateCheckoutAsync({
        data: {
          amount: parseFloat(amountToBeCharged.toFixed(2)),
          currency: selectedCurrency.type,
          customer: {
            country: userSlice.residentialAddressDetails.country || "KE",
            postalCode: userSlice.postalAddress.postalCode,
            name: userSlice.names,
            email: userSlice.email,
            phoneNumber:
              userSlice?.mpesaPhoneNumber ??
              userSlice?.electronicAddress?.mobileNumber?.replace("+", "") ??
              "",
          },
          serviceType: Constants.DPO.SERVICE_TYPES.COMPANY_REGISTRATION,
          serviceDescription: selectedPackage.product.description,
          redirectData: payload,
          dpoData: values,
        },
      })
    );
    console.log({ response });
    onSuccess();
    if (response.meta.requestStatus === "fulfilled")
      window.location.href = response.payload.redirectUrl;
    return;
    // setCheckoutResponse(response);
    // if (response.payload.status) {
    //   if (paymentMethod === "mpesa") {
    //     handleMpesaPaymentResponse(response, payload);
    //   } else if (paymentMethod === "card") {
    //     handleCardPaymentResponse(response, payload);
    //   }
    // } else {
    //   toast(
    //     "error",
    //     response.payload?.message ?? "Something went wrong please try again"
    //   );
    //   setIsSubmitting(false);
    // }
  };

  // const handleCancelTransaction = async (transactionToken) => {
  //   await dispatch(
  //     cancelTransactionAsync({
  //       data: { transactionToken },
  //     })
  //   );
  //   setTransactionToken(null);
  //   setPaymentResponse(false);
  //   setCanRetryPayment(false);
  //   setIsSubmitting(false);
  // };

  // const handleRetryPayment = async () => {
  //   setIsRetrying(true);
  //   const MAX_TRIES = 6; // 6 tries which should take around 30 seconds
  //   let retryCount = 0;
  //   let isTransactionPending = true;

  //   while (isTransactionPending) {
  //     console.log({ retryCount });
  //     if (retryCount >= MAX_TRIES) {
  //       // Break out of the loop if the maximum duration is reached
  //       isTransactionPending = false;
  //       handleCancelTransaction(checkoutResponse.payload.transactionToken);
  //       setCanRetryPayment(false);
  //       toast("error", "Something went wrong please try again");
  //       break;
  //     }

  //     const transactionStatusResponse = await dispatch(
  //       checkTransactionStatusAsync({
  //         data: { transactionToken: checkoutResponse.payload.transactionToken },
  //       })
  //     );

  //     if (transactionStatusResponse.payload.status) {
  //       isTransactionPending = false;
  //       const verifyPaymentResponse = await dispatch(
  //         verifyPaymentAsync({
  //           data: {
  //             TransID: checkoutResponse.payload.transactionToken,
  //             transRef: checkoutResponse.payload.transRef,
  //             payload: checkoutPayload,
  //           },
  //         })
  //       );
  //       const { redirectUrl } = verifyPaymentResponse.payload;
  //       history.push(redirectUrl);
  //     } else if (!transactionStatusResponse.payload.reconfirm) {
  //       isTransactionPending = false;
  //       setCanRetryPayment(false);
  //       setTransactionToken(null);
  //       setPaymentResponse(false);
  //       toast(
  //         "error",
  //         transactionStatusResponse.payload?.message ??
  //         "Something went wrong please try again"
  //       );
  //       setIsRetrying(false);
  //     } else {
  //       await delay(5000);
  //       retryCount++;
  //     }
  //   }
  //   setIsRetrying(false);
  // };

  const isProfileIncomplete = ["Employee", "Co Applicant"].includes(
    userSlice.profileType
  );

  if (isProfileIncomplete) {
    return (
      <DashboardWrapper>
        <div className="flex justify-center items-center h-screen">
          <span className="font-medium text-red-500">
            You cannot make this application until your profile is{" "}
            <Link to="/profile" className="underline">
              upgraded.
            </Link>
          </span>
        </div>
      </DashboardWrapper>
    );
  }
  console.log({ submittingToCheckout });
  return (
    <DashboardWrapper showBreadCrumbs pages={pages}>
      <div className="grid gap-10 py-10">
        <Hero
          heading={"Checkout"}
          description="
            Please select your mode of payment."
        />
        <div className="grid grid-cols-6 border-t border-gray">
          <div className="col-span-1 pr-8 pt-14">
            <p className="mb-4 text-sflPrimary">Select you plan:</p>
            <ul>
              {navigation.map((item) => (
                <li
                  key={item.name}
                  className={classNames(
                    item.id === selectedTab.id
                      ? "text-sflPrimary"
                      : "text-tertiary",
                    `mb-4 font-medium`
                  )}
                >
                  <span className="relative truncate">{item.name}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-5 md:border-l md:pl-11 pt-14 border-gray">
            {pendingPaidApplicationsCount > 0 && (
              <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      You already have {pendingPaidApplicationsCount} paid
                      pending{" "}
                      <span className="font-medium text-yellow-700">
                        {companyType}
                      </span>{" "}
                      {pendingPaidApplicationsCount === 1
                        ? "application"
                        : "applications"}
                      . You can go to{" "}
                      <Link
                        to="/transactions"
                        className="font-medium text-yellow-700 underline hover:text-yellow-600"
                      >
                        transactions
                      </Link>{" "}
                      to complete and submit{" "}
                      {pendingPaidApplicationsCount === 1 ? "it" : "them"}.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {selectedTab?.id === "preferredPlan" && (
              <PreferredPlan
                tabs={tabs}
                selectedPackage={selectedPackage}
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                submittingToCheckout={submittingToCheckout}
                handleProceedToCheckout={handleProceedToCheckout}
              />
            )}
            {/* {selectedTab?.id === "paymentMethod" && (
              <PaymentMethod
                companyType={companyType}
                discountPercentage={discountPercentage}
                selectedPackage={selectedPackage}
                selectedCurrency={selectedCurrency}
                paymentResponse={paymentResponse}
                transactionToken={transactionToken}
                isSubmitting={isSubmitting}
                canRetryPayment={canRetryPayment}
                isRetrying={isRetrying}
                handleMakePayment={handleMakePayment}
                handleGoBack={handleGoBack}
                handleCancelTransaction={handleCancelTransaction}
                handleRetryPayment={handleRetryPayment}
              />
            )}
            {selectedTab?.id === "paymentSummary" && <PaymentSummary />} */}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Checkout;
