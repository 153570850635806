import React, { useEffect, useRef } from "react";

function Checkbox({ name, label, disabled, checked, onChange, ...restProps }) {
  const checkboxRef = useRef(null);

  useEffect(() => {
    checkboxRef.current.indeterminate = restProps.indeterminate ? "true" : "";
  }, [restProps.indeterminate]);

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        name={name}
        id={name}
        value={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        ref={checkboxRef}
        className="w-4 h-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
      />
      <label
        htmlFor={name}
        className={`block ml-2 text-sm font-medium text-gray-700 ${
          disabled ? "disabled:text-slate-500 cursor-not-allowed" : ""
        }`}
      >
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
