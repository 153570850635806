import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import _get from "lodash/get";

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import CommentsList from "components/lib/Global/CommentsList";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { addComment, editComment } from "state/slices/comment";

const UploadDocuments = ({ row }) => {
  const dispatch = useDispatch();
  const { organization } = useSelector((state) => state.organizationsSlice);
  const { comments } = useSelector((state) => state.commentsSlice);

  const uploadFilesComments = comments.filter((comment) => {
    return comment.formName === "uploadDocuments" && !comment.officialId;
  });

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            corporate: organization._id,
          },
        })
      );
    }
  };

  return (
    <>
      {uploadFilesComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 bg-white mt-4">
          <CommentsList comments={uploadFilesComments} toggleCommentStatus />
          <p className="mt-6 text-sm text-primary">
            Comments: {uploadFilesComments.length} of{" "}
            {uploadFilesComments.length}
          </p>
        </div>
      )}
      <div className="px-8 pt-5 pb-6 mt-6 bg-white">
        <h3 className="mb-2 text-lg">Uploaded incorporation forms</h3>
        <Formik
          enableReinitialize
          initialValues={{
            fileUploads: organization.fileUploads,
          }}
          onSubmit={() => null}
        >
          {() => {
            return (
              <div className="pl-4">
                <Field
                  addReviewComment={organization.status !== "Approved"}
                  name="fileUploads"
                  component={FileUploadForm}
                  documentData={getDocumentData(organization.idType)}
                  resolvedFields={_get(
                    organization.fileUploads,
                    "resolvedFields"
                  )}
                  onlyPreview
                  formName="uploadDocuments"
                  formHelperKey="corporateOfficial"
                  updateEffectKey="updateOrganizationState"
                  updateEffectPath="organizationsSlice.organization.fileUploads"
                  formId={_get(organization, "_id")}
                  showResolveAllFieldsCheckbox={
                    row.original.status !== "Active"
                  }
                  showDocumentLabel
                  handleSaveComment={handleSaveComment}
                  fileUploadComments={uploadFilesComments}
                />
              </div>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default UploadDocuments;
