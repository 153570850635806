import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  jointShareholders: [],
  currentJSGroupID: "",
};

export const getJointShareholders = createAsyncThunk(
  "Joint Shareholders Table/getJointShareholders",
  async (_, thunkAPI) => {
    const applicationState = thunkAPI.getState();
    return applicationState.jointShareholderTableSlice;
  }
);

const jointShareholderTableSlice = createSlice({
  name: "Joint Shareholders Table",
  initialState,
  reducers: {
    setJointShareholders: (state, action) => {
      state.jointShareholders = action.payload;
    },
    addJointShareholder: (state, action) => {
      state.jointShareholders.push(action.payload);
    },
    deleteJointShareholder: (state, { payload }) => {
      const index = state.jointShareholders.findIndex(
        (jointShareholder) => jointShareholder._id === payload._id
      );
      if (index > -1) state.jointShareholders.splice(index, 1);
    },
    editJointShareholder: (state, { payload }) => {
      const index = state.jointShareholders.findIndex(
        (jointShareholder) => jointShareholder._id === payload._id
      );
      if (index > -1) state.jointShareholders.splice(index, 1, payload);
    },
    setCurrentJSGroupID: (state, action) => {
      state.currentJSGroupID = action.payload;
    },
    resetJointShareholderTable: () => initialState,
  },
  extraReducers: {},
});

export const {
  addJointShareholder,
  setJointShareholders,
  deleteJointShareholder,
  editJointShareholder,
  setCurrentJSGroupID,
  resetJointShareholderTable,
} = jointShareholderTableSlice.actions;

export default jointShareholderTableSlice.reducer;
