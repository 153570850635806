import React, { useEffect, useMemo, useState } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { EyeIcon } from "@heroicons/react/outline";
import { format } from "date-fns";

import Table from "components/lib/Global/ReactTable";
import { delay, getFullName } from "utils";
import PartnerSubRow from "./PartnerSubRow";
import SearchInput from "components/lib/Shared/SearchInput";

function PartnersTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: () => "Name",
        id: "Name",
        accessor: "fullName",
        Cell: ({
          row: {
            original: { names },
          },
        }) => {
          return getFullName(names) || "N/A";
        },
        // We can render something different for subRows
        SubCell: (cellProps) => <>{cellProps.value}</>,
      },
      {
        Header: "Role",
        Cell: ({ row: { original } }) => {
          return original?.role?.join(", ") ?? "";
        },
      },
      {
        Header: () => "ID Type",
        id: "ID Type",
        accessor: "idType",
      },
      {
        Header: "Date",
        Cell: ({ row }) =>
          row.original?.createdAt
            ? format(new Date(row.original?.createdAt), "yyyy/MM/dd")
            : "N/A",
      },
      {
        // Make an expander cell
        Header: () => "Actions", // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => {
          const originalRow = row.original;

          const handleViewRow = async () => {
            if (!row.isExpanded) {
              row.toggleAllRowsExpanded(false); // Close all rows
              await delay(10);
              row.getToggleRowExpandedProps().onClick(); // Open the clicked row
            } else {
              row.getToggleRowExpandedProps().onClick();
            }
          };

          return (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span
              className="text-sm font-normal text-primary"
              {...row.getToggleRowExpandedProps()}
              onClick={handleViewRow}
            >
              <EyeIcon className="inline w-4 h-4 mr-1 text-tertiary" />
              {originalRow.isExpanded ? "Hide Details" : "view details"}
            </span>
          );
        },
        // We can override the cell renderer with a SubCell to be used with an expanded row
        SubCell: () => null, // No expander on an expanded row
      },
    ],
    []
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <PartnerSubRow
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full overflow-x-auto">
        <Table
          columns={columns}
          data={data}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          totalRows={data.length}
          setCurrentPage={setCurrentPage}
          paginate
          headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
          headingStyle="pl-4 text-left text-white"
          renderRowSubComponent={renderRowSubComponent}
        />
      </div>
    </>
  );
}

export default PartnersTable;
