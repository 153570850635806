import { Constants } from "config/constants";

const DrCashPayableOnShares = () => {
  return (
    <table className="divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
      <thead className="text-xs">
        <tr>
          <th
            colspan="2"
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          >
            Dr.
          </th>
          <th
            colspan="2"
            scope="col"
            className="font-semibold text-gray-900 bg-gray-100 h-10"
          >
            CASH PAYABLE ON SHARES
          </th>
        </tr>
        <tr style={{ height: "110px" }}>
          <th
            scope="col"
            rowspan="2"
            className="font-semibold text-gray-900 bg-gray-100"
          >
            Date When Called
          </th>
          <th
            scope="col"
            rowspan="2"
            className="font-semibold text-gray-900 bg-gray-100"
          >
            Description of Payment or No. of Call
          </th>
          <th
            scope="col"
            rowspan="2"
            className="font-semibold text-gray-900 bg-gray-100"
          >
            Amount per Share
          </th>
          <th
            scope="col"
            rowspan="2"
            className="font-semibold text-gray-900 bg-gray-100"
          >
            Total Amount
          </th>
        </tr>
        <tr style={{ height: "42px" }}></tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {Array.from(new Array(Constants.REGISTERS.LEDGER_ROWS)).map(
          (_, index) => (
            <tr key={index} className="text-xs">
              <td className="p-1 text-gray-600 bg-gray-100"></td>
              <td className="p-1 text-gray-600 bg-gray-100"></td>
              <td className="p-1 text-gray-600 bg-gray-100"></td>
              <td className="p-1 text-gray-600 bg-gray-100"></td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default DrCashPayableOnShares;
