import { object, string, mixed } from "yup";

const companySummaryValidation = () =>
  object().shape({
    registrationName: string().required("Registration name is required"),
    registrationDate: string().required("Registration date is required"),
    kraPin: string()
      .trim("KRA PIN cannot include leading and trailing spaces")
      .strict(true)
      .min(11, "Must be exactly 11 characters")
      .max(11, "Must be exactly 11 characters"),
    NSSFNo: string().notRequired("NSSF NO is required"),
    NHIFNo: string().notRequired("NHIF NO is required"),
    fileUploads: object().shape({
      certificateOfIncorporation: mixed().required(
        "Certificate of Incorporation is required"
      ),
      kraPin: mixed().test("length", function (value) {
        if (!value && this.options.context.kraPin) {
          return this.createError({
            message: "KRA PIN certificate is required",
          });
        }
        return true;
      }),
      NSSFNo: mixed().notRequired("NHIF NO certificate is required"),
      NHIFNo: mixed().notRequired("NHIF NO certificate is required"),
      nameReservationConfirmation: mixed().required(
        "Name reservation confirmation is required"
      ),
    }),
  });

export default companySummaryValidation;
