import axios from "../axios";

const getAll = (queryObject = {}) =>
  axios.get(`/access-requests?${new URLSearchParams(queryObject).toString()}`);

const get = (email) => axios.get(`/access-requests?email=${email}`);

const create = (data) => axios.post("/access-requests", data);

const update = (accessRequestsId, data) =>
  axios.put(`/access-requests/${accessRequestsId}`, data);

const remove = (accessRequestsId) =>
  axios.delete(`/access-requests/${accessRequestsId}`);

const sendInvite = (data) => axios.post(`/access-requests/send-invite`, data);

const notifyAboutComments = (data) =>
  axios.post("/access-requests/notify-about-comments", data);

const accessRequestDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  sendInvite,
  notifyAboutComments,
};

export default accessRequestDataService;
