import React, { useState } from "react";
import { PencilAltIcon } from "@heroicons/react/solid";
import { format } from "date-fns";

import Button from "components/lib/Shared/Button";
import useFullscreenToggle from "hooks/useFullscreenToggle";
import Overlay from "components/lib/Shared/Overlay";
import PaginationControls from "./PaginationControls";
import BreadCrumbs from "./BreadCrumbs";

function RegisterOfDebentures({ selectedTab }) {
  const { isFullscreen, ToggleFullscreenContainer, ToggleFullscreenButton } =
    useFullscreenToggle();

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="relative overflow-auto">
      <Overlay showSpinner={false} />
      <div className="flex justify-between w-full mb-4">
        <BreadCrumbs breadCrumbs={selectedTab.breadCrumbs} />
        <Button disabled>Download</Button>
      </div>
      <ToggleFullscreenContainer>
        <ToggleFullscreenButton />
        <div
          className={`bg-white relative ${
            isFullscreen ? "h-[88vh] overflow-auto" : ""
          }`}
        >
          {currentPage === 1 && (
            <div
              className={`overflow-auto px-1 pt-4 pb-20 space-y-2 flex flex-col text-center ${
                isFullscreen ? "px-12" : ""
              }`}
            >
              <div>
                <h2 className="text-title6 font-medium">
                  REGISTER OF APPLICATIONS AND ALLOTMENTS
                </h2>
                <div className="flex items-center justify-center">
                  <span className="mr-2 text-sm">
                    as at {format(new Date(), "dd-MM-yyyy")}
                  </span>
                  <PencilAltIcon className="w-4 h-4" />
                </div>
                <div className="mt-4">
                  <table className="min-w-full divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
                    <thead className="text-xs">
                      <tr>
                        <th
                          colspan="6"
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          (2) COPIES OF ALL DEBENTURES MUST BE KEPT WITHT
                          REGISTER FOR INSEPECTION
                        </th>
                        <th
                          rowspan="2"
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          NAME AND ADDRESS OF DEBENTURE HOLDER OR HOLDERS OR OF
                          THE TRUSTEES OF AN ISSUE OF DEBENTURE STOCK
                        </th>
                        <th
                          rowspan="2"
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          RATE OF INTEREST PER ANNUM
                        </th>
                        <th
                          rowspan="2"
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          AMOUNT OF HALF YEAR'S INTEREST LESS INCOME TAX
                        </th>
                        <th
                          rowspan="2"
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          DATE OF INTEREST BECOMING DUE
                        </th>
                        <th
                          rowspan="2"
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          DATE OF INTEREST BECOMING DUE
                        </th>
                        <th
                          rowspan="2"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          DATE OF REDEMPTION OR REPAYMENT
                        </th>
                        <th
                          rowspan="2"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          REMARKS
                        </th>
                      </tr>
                      <tr>
                        <th
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          NO. OF DEBENTURE OR OF SERIES OF DEBENTURES
                        </th>
                        <th
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          DATE OF DEBETURE OR SERIES OF DEBENTURES
                        </th>
                        <th
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          AUTHORISING THE ISSUE OF THE DEBENTURES
                        </th>
                        <th
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          DATE OF
                        </th>
                        <th
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          AMOUNT OF
                        </th>
                        <th
                          scope="col"
                          className="font-semibold text-gray-900 bg-gray-100"
                        >
                          SHORT PARTICULARS OF THE DEBENTURE
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {Array.from(new Array(17)).map((_, index) => (
                        <tr key={index} className="text-xs">
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                          <td className="p-1 text-gray-600 bg-gray-100">*</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <PaginationControls isFullscreen={isFullscreen} />
        </div>
      </ToggleFullscreenContainer>
    </div>
  );
}

export default RegisterOfDebentures;
