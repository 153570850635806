import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToast } from "hooks/useToast";
import _omit from "lodash/omit";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";

import { getFullName } from "utils";
import { getCompanyOfficialsAsync } from "state/slices/tables/companyOfficialsSlice";
import ManageChangeOfParticularsTable from "./ManageChangeOfParticularsTable";
import { ManageModuleContext } from "context/ManageCompanyModuleContext";
import { XIcon } from "@heroicons/react/outline";

const ChangeOfParticulars = ({ companyId }) => {
  const dispatch = useDispatch();
  const { toast } = useToast(3000);
  const { changeOfParticularsInfo } = useContext(ManageModuleContext);
  const [isLoading, setIsLoading] = useState(true);

  const [officialsList, setOfficialsList] = useState(null);

  const [showChangeOfParticularsForm, setShowChangeOfParticularsForm] =
    useState(false);

  const [selectedValueOfParticulars, setSelectedValueOfParticulars] =
    useState(null);
  const [showChangeOfParticularsModal, setShowChangeOfParticularsModal] =
    useState(false);

  const companyDashboardSlice = useSelector(
    (state) => state.companyDashboardSlice
  );

  const { getCompanyOfficialsStatus } = companyDashboardSlice;

  const companyOfficials = companyDashboardSlice.companyOfficials.items;
  const { totalCount } = companyDashboardSlice.companyOfficials;

  const JSGroups = new Set();
  companyOfficials.forEach((item) => {
    if (item.JSGroupID) JSGroups.add(item.JSGroupID);
  });

  const allJS = [...JSGroups].map((id) => {
    const names = [];
    let combinedJS = {};
    companyOfficials.forEach((official) => {
      if (official.JSGroupID === id) {
        names.push(getFullName(official));
        if (official.sharesAllotted && official.sharesAllotted.length > 0)
          combinedJS.sharesAllotted = official.sharesAllotted;
        combinedJS = {
          ...combinedJS,
          ..._omit(_cloneDeep(official), ["sharesAllotted"]),
          designation: "Joint Shareholder",
          idType: "Joint Shareholder",
        };
        combinedJS.fullName = names.map((name, index) => {
          if (names.length === 2 && index === 0) {
            return `${name} & `;
          }
          if (names.length > 2 && index === names.length - 2) {
            return `${name} & `;
          }

          if (
            names.length > 2 &&
            index !== names.length - 2 &&
            index !== names.length - 1
          ) {
            return `${name}, `;
          }
          return name;
        });
      }
    });
    return combinedJS;
  });

  const data = [
    ...companyOfficials.filter(
      (item) =>
        (item.designation === "Joint Shareholder" &&
          item.isDirector === "Yes") ||
        item.designation !== "Joint Shareholder"
    ),
    ...allJS.filter((item) => !_isEmpty(item)),
  ];

  const handleChangeOfParticularValue = (e) => {
    setSelectedValueOfParticulars(e.target.value);
    setShowChangeOfParticularsForm(true);
    changeOfParticularsInfo.selectedValueType = e.target.value;
    // set here context api of which type is selected
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (officialsList === null && !isLoading) {
      setShowChangeOfParticularsModal(true);
      toast("error", "Please appoint Authorized-Person to proceed further.");
    }
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getCompanyOfficialsAsync({
            companyId,
            queryParams: { designation: "Authorized Person" },
          })
        );
        setOfficialsList(response.payload.items);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading && <>loading...</>}
      {data?.length < 1 &&
        !isLoading &&
        (officialsList === null || officialsList?.length < 1) && (
          <div className="notification-container flex justify-center px-4 py-10 mt-2 mb-2 bg-gray-200 rounded-sm shadow-sm sm:rounded md:rounded-md relative">
            <h2 className="text-gray-900 font-normal">
              You have no assigned Authorized Persons to proceed with the
              workflow
            </h2>
            <span
              className="close-icon absolute top-2 right-2 cursor-pointer text-lg hover:bg-gray-300"
              onClick={() => setShowChangeOfParticularsModal(false)}
            >
              <XIcon className="h-6 w-6" />
            </span>
          </div>
        )}
      {data?.length > 0 && !isLoading && (
        <>
          <h2 className="font-semibold text-grey-700"> Select Type: </h2>
          <div className="flex justify-between px-4 py-6 mt-2 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
            <div className="flex items-center space-x-2">
              <input
                id="changes-excluding-email"
                type="radio"
                value="changes-excluding-email"
                checked={
                  selectedValueOfParticulars === "changes-excluding-email"
                }
                onChange={handleChangeOfParticularValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="changes-excluding-email"
                className="radio-label flex"
              >
                Changes excluding e-mail or telephone number
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <input
                id="changes-including-email"
                type="radio"
                value="changes-including-email"
                checked={
                  selectedValueOfParticulars === "changes-including-email"
                }
                onChange={handleChangeOfParticularValue}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="changes-including-email"
                className="radio-label flex"
              >
                Changes to include e-mail or telephone number
              </label>
            </div>
          </div>
        </>
      )}

      {selectedValueOfParticulars && data?.length > 0 && (
        <>
          <div className="mt-4">
            <h4 className="text-gray-800 font-semibold mt-2 mb-2">
              Officials List
            </h4>
            <div>
              <ManageChangeOfParticularsTable
                companyId={companyId}
                data={data}
                totalCount={totalCount}
                currentTab="stakeholderTab"
                isLoading={getCompanyOfficialsStatus.status === "loading"}
              />
            </div>
          </div>
        </>
      )}
      {!isLoading && officialsList?.length > 0 && (
        <div className="mt-4">
          <h4 className="text-gray-800 font-semibold mt-2 mb-2">
            Recent Updates
          </h4>
          <div>
            <ManageChangeOfParticularsTable
              data={officialsList}
              totalCount={0}
              companyId={companyId}
              isLoading={isLoading}
              typeOfTable={"recent-updates"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeOfParticulars;
