import _get from "lodash/get";
import { getFormFields } from "utils";
import { useSelector } from "react-redux";
import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import CommentsList from "components/lib/Global/CommentsList";

const CompanyFormation = ({ comments, isViewMode, handleSaveComment }) => {
  const { organization } = useSelector((state) => state.organizationsSlice);

  return (
    <div>
      {comments?.length > 0 && (
        <div className="px-8 pt-5 pb-6 mt-4 bg-white">
          <CommentsList comments={comments} toggleCommentStatus={!isViewMode} />
          <p className="mt-6 text-sm text-primary">
            Comments: {comments?.length} of {comments?.length}
          </p>
        </div>
      )}
      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="names"
        fields={getFormFields({
          idType: organization.idType,
          formType: "names",
          formValues: organization.names,
        })}
        resolvedFields={organization.names.resolvedFields}
        formHelperKey="names"
        updateEffectKey="updateOrganizationState"
        updateEffectPath="organizationsSlice.organization.names"
        formId={_get(organization.names, "_id")}
        handleSaveComment={handleSaveComment}
        comments={comments}
        hideResolveAllCheckbox={isViewMode}
      />

      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="identificationDetails"
        fields={getFormFields({
          idType: organization.idType,
          formType: "identificationDetails",
          formValues: organization.identificationDetails,
        })}
        files={organization.fileUploads}
        resolvedFields={organization.identificationDetails?.resolvedFields}
        formHelperKey="identificationDetails"
        updateEffectKey="updateOrganizationState"
        updateEffectPath="organizationsSlice.organization.identificationDetails"
        formId={_get(organization.identificationDetails, "_id")}
        handleSaveComment={handleSaveComment}
        comments={comments}
        hideResolveAllCheckbox={isViewMode}
      />
    </div>
  );
};

export default CompanyFormation;
