import { useEffect } from "react";
import { Formik, Form } from "formik";
import { boolean, object } from "yup";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import Button from "components/lib/Shared/Button";
import Radio from "components/lib/Shared/Radio";
import ValidationError from "components/lib/Shared/ValidationError";
import { editPackageAsync } from "state/slices/package";
import { Constants } from "config/constants";

const validationSchema = object().shape({
  isSubscriptionBased: boolean().required("Please select one option"),
});

const Subscription = () => {
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();
  const { selectedPackage } = useSelector((state) => state.packageSlice);

  const handleSubmit = async (data) => {
    await dispatch(editPackageAsync({ packageId: selectedPackage?._id, data }));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        isSubscriptionBased: selectedPackage?.isSubscriptionBased ?? false,
        subscriptionType: selectedPackage?.subscriptionType ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => {
        useEffect(() => {
          if (values.isSubscriptionBased) {
            setFieldValue(
              "subscriptionType",
              values?.subscriptionType || "monthly"
            );
          } else {
            setFieldValue("subscriptionType", "");
          }
        }, [values]);

        return (
          <Form className="text-sm w-full">
            <div className="flex flex-col justify-between">
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  Is this a subscription based product
                </legend>
                <div className="mt-1 pl-4">
                  <div className="flex space-x-6">
                    <Radio
                      id="yesIsSubscriptionBased"
                      name="yesIsSubscriptionBased"
                      label="Yes"
                      type="radio"
                      disabled={
                        cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                      }
                      checked={values.isSubscriptionBased === true}
                      onChange={() =>
                        setFieldValue("isSubscriptionBased", true)
                      }
                      className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                    />
                    <Radio
                      id="notIsSubscriptionBased"
                      name="notIsSubscriptionBased"
                      label="No"
                      type="radio"
                      disabled={
                        cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                      }
                      checked={values.isSubscriptionBased === false}
                      onChange={() =>
                        setFieldValue("isSubscriptionBased", false)
                      }
                      className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                    />
                  </div>
                  <ValidationError
                    name="isSubscriptionBased"
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </fieldset>
              {values.isSubscriptionBased === true && (
                <fieldset className="shadow sm:rounded-md sm:overflow-hidden w-32">
                  <div className="mt-1 pl-4 bg-white py-6 px-4 space-y-6 sm:p-6">
                    <div className="flex space-x-6">
                      <Radio
                        id="monthly"
                        name="monthly"
                        label="Monthly"
                        type="radio"
                        disabled={
                          cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                        }
                        checked={values.subscriptionType === "monthly"}
                        onChange={() =>
                          setFieldValue("subscriptionType", "monthly")
                        }
                        className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                      />
                      <Radio
                        id="quarterly"
                        name="quarterly"
                        label="Quarterly"
                        type="radio"
                        disabled={
                          cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                        }
                        checked={values.subscriptionType === "quarterly"}
                        onChange={() =>
                          setFieldValue("subscriptionType", "quarterly")
                        }
                        className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                      />
                      <Radio
                        id="annually"
                        name="annually"
                        label="Annually"
                        type="radio"
                        disabled={
                          cookieRole !== Constants.COOKIE_ROLES.SUPER_ADMIN
                        }
                        checked={values.subscriptionType === "annually"}
                        onChange={() =>
                          setFieldValue("subscriptionType", "annually")
                        }
                        className="h-4 w-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary"
                      />
                    </div>
                    <ValidationError
                      name="subscriptionType"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </fieldset>
              )}
              {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
                <div className="flex items-center justify-end gap-x-4">
                  <Button
                    type="submit"
                    loadingText="Saving..."
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Subscription;
