/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Field } from "formik";
import _isEmpty from "lodash/isEmpty";

import RegulatedArticles from "./RegulatedArticles";
import NonRegulatedArticles from "./NonRegulatedArticles";
import CommentsList from "components/lib/Global/CommentsList";
import ValidationError from "components/lib/Shared/ValidationError";

function ArticlesOfAssociation({
  field,
  form: { values, errors, touched, setFieldValue },
  transformedComments,
}) {
  const articlesType = field.value?.articlesType;

  const handleChange = (e) => {
    setFieldValue(field.name, {
      [e.target.name]: e.target.value,
    });
  };

  const articlesTypeComments =
    transformedComments?.["articlesOfAssociation"]?.articlesType ?? [];

  return (
    <div className="shadow-sm sm:rounded-md sm:overflow-hidden">
      <div className="px-4 py-6 space-y-4 bg-white sm:p-6">
        <div className="inline-block">
          <p className="relative">
            <span className=" font-medium">
              OBJECTS FOR WHICH THE COMPANY IS ESTABLISHED ARE:{" "}
            </span>

            {articlesTypeComments.length > 0 && (
              <span className="absolute flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full -top-2 -right-5">
                <span>{articlesTypeComments.length}</span>
              </span>
            )}
          </p>
        </div>
        <div className="flex items-center">
          <Field
            className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            type="radio"
            name="articlesType"
            id="Non-Regulated"
            value="Non-Regulated"
            disabled={values.resolvedFields.includes("articlesType")}
            checked={articlesType === "Non-Regulated"}
            onChange={handleChange}
          />
          <label
            htmlFor="Non-Regulated"
            className="block ml-3 text-sm font-medium text-gray-700"
          >
            Non-Regulated
          </label>
        </div>
        {articlesType === "Non-Regulated" && (
          <Field
            name="articlesOfAssociation"
            component={NonRegulatedArticles}
            transformedComments={transformedComments}
          />
        )}
        <div className="flex items-center">
          <Field
            className="w-4 h-4 text-sflPrimary border-gray-300 focus:ring-green-focused disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            type="radio"
            name="articlesType"
            id="Regulated"
            value="Regulated"
            disabled={values.resolvedFields.includes("articlesType")}
            checked={articlesType === "Regulated"}
            onChange={handleChange}
          />
          <label
            className="block ml-3 text-sm font-medium text-gray-700"
            htmlFor="Regulated"
          >
            Regulated
          </label>
        </div>
        {articlesTypeComments.length > 0 && (
          <CommentsList
            comments={articlesTypeComments}
            color={"text-red-500"}
          />
        )}
        <ValidationError
          errors={errors}
          touched={touched}
          name="articlesOfAssociation.articlesType"
        />
        {articlesType === "Regulated" && (
          <Field
            name="articlesOfAssociation"
            component={RegulatedArticles}
            transformedComments={transformedComments}
          />
        )}
      </div>
    </div>
  );
}

export default ArticlesOfAssociation;
