import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "components/lib/Shared/Dialog";
import Button from "./Button";

import { closeModal } from "state/slices/modals";

export default function ConfirmDialog(props) {
  const {
    onClose = () => null,
    children,
    onConfirm,
    name,
    title = "Confirmation",
    confirmBtnLabel = "Confirm",
    loadingText = "Deleting...",
  } = props;

  const dispatch = useDispatch();
  const { modals } = useSelector((state) => state.modalsSlice);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const modalIndex = modals.indexOf(name);

  useEffect(() => {
    setModalOpen(modalIndex > -1);
  }, [modalIndex, modals, name]);

  const handleClose = () => {
    dispatch(closeModal(name));
    onClose();
  };

  const handleAccept = async () => {
    try {
      setIsSubmitting(true);
      await onConfirm();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <h2 className="text-xl font-medium">{title}</h2>
      <div className="py-5 whitespace-normal">{children}</div>
      <div className="flex justify-end">
        <div className="p-1">
          <Button
            disabled={isSubmitting}
            className={`inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-transparent rounded-md shadow-sm hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed`}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </div>
        <div className="p-1">
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            loadingText={loadingText}
            className={`inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sflPrimary hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:cursor-not-allowed`}
            onClick={handleAccept}
          >
            {confirmBtnLabel}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
