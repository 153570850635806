import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SearchIcon } from "@heroicons/react/outline";
import _isEmpty from "lodash/isEmpty";

import Tabs from "components/lib/Shared/Tabs";
import RequestsTable from "components/AccountSettings/RequestsTable";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import RefreshIcon from "components/lib/Shared/Icons/RefreshIcon";
import { getAccessRequests } from "state/slices/accessRequest";
import { useToast } from "hooks/useToast";
import { getAccessRequestsTabsAndData } from "utils/accessRequests";

const initialTabs = [];

export default function AccountSettings() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [transformedInvites, setTransformedInvites] = useState({});
  const [tabs, setTabs] = useState(initialTabs);
  const [isLoading, setIsLoading] = useState(false);

  // redux selector
  const userSlice = useSelector((state) => state.userSlice);
  const { electronicAddress } = userSlice;

  const { items: accessRequests, getAccessRequestsStatus } = useSelector(
    (state) => state.accessRequestsSlice
  );

  const dispatch = useDispatch();
  const { toast } = useToast(4000);

  const handleRefreshData = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await dispatch(
        getAccessRequests({
          query: { initiatedToEmail: electronicAddress?.emailAddress },
        })
      );
      toast("success", "Data refreshed successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { tempTabs, tempTransformedInvites = {} } =
      getAccessRequestsTabsAndData({ accessRequests });

    setTabs(tempTabs);
    setTransformedInvites(tempTransformedInvites);
  }, [accessRequests]);

  useEffect(() => {
    dispatch(
      getAccessRequests({
        query: { initiatedToEmail: electronicAddress?.emailAddress },
      })
    );
  }, []);

  const selectedTab = tabs[selectedTabIndex];
  const tableData = transformedInvites?.[selectedTab?.id] ?? [];

  return (
    <>
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <div className="px-10">
          {getAccessRequestsStatus.status === "idle" ||
          accessRequests.length > 0 ? (
            <>
              {/* accepted & awaiting tabs */}
              <div className="sm:-mt-4">
                <Tabs
                  tabs={tabs}
                  selectedTabIndex={selectedTabIndex}
                  setSelectedTabIndex={setSelectedTabIndex}
                  PostPendComponent={
                    <div
                      onClick={handleRefreshData}
                      className="relative px-1 py-4 overflow-hidden text-sm font-medium text-gray-500 border-b-2 border-transparent cursor-pointer hover:text-gray-700 hover:border-gray-300 whitespace-nowrap text-ellipsis"
                    >
                      <RefreshIcon
                        className={`w-5 h-5 ${
                          isLoading ? "animate-spin cursor-not-allowed" : ""
                        }`}
                        aria-hidden="true"
                      />
                    </div>
                  }
                />
              </div>
              {accessRequests.length > 0 && (
                <div className="flex items-center justify-between mt-10">
                  <div>
                    <h2 className="font-semibold ">
                      {selectedTab?.description}
                    </h2>
                  </div>
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <SearchIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full py-2 pl-10 pr-3 leading-5 text-gray-300 placeholder-gray-400 border border-transparent rounded-md focus:outline-none focus:bg-white focus:text-gray-900 sm:text-sm border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Search"
                        type="search"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div>
                {tableData.length > 0 ? (
                  <RequestsTable requests={tableData} key={selectedTab.id} />
                ) : (
                  <h3 className="mt-6 text-xl font-medium text-center">
                    No data found
                  </h3>
                )}
              </div>
            </>
          ) : (
            <p className="flex justify-center">
              <SpinnerIcon className="text-gray-500" />
            </p>
          )}
        </div>
      </div>
    </>
  );
}
