import selectIdTypeValidation from './selectIdTypeValidation'
import identificationValidation from 'components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/identificationValidation'
import residentialDetailsValidation from 'components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/residentialDetailsValidation'
import uploadDocumentsValidation from 'components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/uploadDocumentsValidation'
import beneficialOwnershipValidation from './beneficialOwnershipFormValidation'

/**
 * This returns an object composed of conditional initial values using `yup` schema
 * for identification, residential details, share allocation table and upload documents forms
 * of `Shareholder` and `Director Shareholder` officials.
 * 
 * @param {string} idType The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {string} isNonResident Conditional string value `'No' || 'Yes` of the current official.
 * @param {array} documentData The array of objects related to file uploads.
 * @return {object} This returns object containing initial values.
 * @example
 *  {}
 */
const getInitialValues = ({ idType, isNonResident = null, documentData }) => {
  return {
    ...selectIdTypeValidation()
      .schemaInitialValues,
    ...identificationValidation(idType)
      .schemaInitialValues,
    ...residentialDetailsValidation({ idType, isNonResident })
      .schemaInitialValues,
    ...uploadDocumentsValidation(documentData)
      .schemaInitialValues,
    ...beneficialOwnershipValidation()
      .schemaInitialValues
  }
}

export default getInitialValues
