import React from 'react'

const ViewShare = ({ selectedShare }) => (
  <div className="py-6">
    <div className='space-y-2'>
      <div className='flex space-x-2'>
        <label className="text-base font-medium text-gray-900">
          Share category type
        </label>
        <span>
          {selectedShare.sharesCategory}
        </span>
      </div>
      <div className='flex space-x-2'>
        <label className="text-base font-medium text-gray-900">
          Do the shares have voting rights?
        </label>
        <span>
          {selectedShare.hasVotingRights}
        </span>
      </div>
      <div className='flex space-x-2'>
        <label
          htmlFor='sharesNumber'
          className="text-base font-medium text-gray-900"
        >
          Number of shares
        </label>
        <span>
          {selectedShare.sharesNumber}
        </span>
      </div>
      <div className='flex space-x-2'>
        <label
          htmlFor="sharesValue"
          className="text-base font-medium text-gray-900"
        >
          Value of shares
        </label>
        <span>{selectedShare.sharesValue}</span>
      </div>
      <div className='flex space-x-2'>
        <label
          htmlFor="votesPerShare"
          className="text-base font-medium text-gray-900"
        >
          Votes Per Share
        </label>
        <div>{selectedShare.votesPerShare}</div>
      </div>
    </div>
  </div>
)

export default ViewShare
