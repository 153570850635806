import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";

import Tabs from "components/lib/Shared/Tabs";
import CompanyFormation from "./CompanyFormation";
import ShareInformation from "./ShareInformation";
import StakeHolderDetails from "./StakeHolderDetails";
import BeneficialOwnership from "./BeneficialOwnership";
import AddressParticulars from "./AddressParticulars";
import StepButtons from "components/lib/Global/StepButtons";
import UploadDocuments from "./UploadDocuments";
import ContactPersonDetails from "./ContactPersonDetails";
import { addComment, editComment } from "state/slices/comment";
import { updateCorporate } from "state/slices/organizations";
import { useToast } from "hooks/useToast";

const CorporateReviewTab = ({
  handleBackBtn,
  refreshTableData,
  row,
  ...restProps
}) => {
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { organization } = useSelector((state) => state.organizationsSlice);

  const { comments } = useSelector((state) => state.commentsSlice);
  const corporateComments = comments.filter((comment) => !comment.officialId);
  const isViewMode =
    ["Active", "Approved"].includes(organization?.status) ||
    restProps.isViewMode;
  const [initialTabs, setInitialTabs] = useState([]);
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const { toast } = useToast();

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };
  const companyFormationFields = [
    "companyKRAPIN",
    "companyFormerName",
    "governingLaw",
    "countryOfIncorporation",
    "entityType",
  ];
  const contactPersonFields = [
    "companyContactDesignation",
    "companyContactName",
    "telNumber",
    "emailAddress",
  ];

  const companyFormationFieldsComments = corporateComments.filter(
    (comment) =>
      ["names", "identificationDetails"].includes(comment.formName) &&
      !contactPersonFields.includes(comment.fieldName)
  );
  const contactPersonFieldsComments = corporateComments.filter(
    (comment) =>
      comment.formName === "identificationDetails" &&
      !companyFormationFields.includes(comment.fieldName)
  );

  const handleCorporateApprove = async () => {
    setSubmitting(true);
    try {
      await dispatch(
        updateCorporate({
          id: organization?._id,
          data: {
            status: "Approved",
          },
        })
      );
      toast("success", "The application has been successfully incorporated.");
      row.toggleRowExpanded();
      refreshTableData();
    } catch (error) {
      toast("error", error?.message ?? "Something went wrong please try again");
    } finally {
      setSubmitting(false);
    }
  };

  const goToNextStep = async () => {
    const lastTabIndex = tabs.length - 1;

    if (
      lastTabIndex &&
      organization.status !== "Approved" &&
      comments.length === 0
    ) {
      handleCorporateApprove();
      return;
    }
    if (selectedTabIndex < lastTabIndex) {
      setSelectedTabIndex(selectedTabIndex + 1);
      return;
    }
    row.toggleRowExpanded();
  };

  useEffect(() => {
    const companyFormationCommentsCounts =
      companyFormationFieldsComments?.length;
    const companyContactPersonCounts = contactPersonFieldsComments?.length;
    const addressParticularsCommentsCount =
      corporateComments.filter(
        (comment) => comment.formName === "residentialAddressDetails"
      )?.length ?? 0;
    const shareInformationCommentsCount =
      corporateComments.filter(
        (comment) => comment.formName === "shareInformation"
      )?.length ?? 0;
    const companyOfficialsCommentCount =
      corporateComments.filter(
        (comment) => comment.tabName === "stakeholderTab"
      )?.length ?? 0;
    const beneficialOwnersCommentCount =
      corporateComments.filter(
        (comment) => comment.tabName === "beneficialOwnership"
      )?.length ?? 0;
    const uploadDocumentsComment =
      corporateComments.filter(
        (comment) =>
          comment.formName === "uploadDocuments" && !comment.officialId
      )?.length ?? 0;
    let tempTabs = [];
    if (
      ["Shareholder", "Director Shareholder"].includes(organization.designation)
    ) {
      tempTabs = [
        {
          id: "companyFormation",
          label: "Company formation",
          commentsCount: companyFormationCommentsCounts,
        },
        {
          id: "contactPerson",
          label: "Contact Person Details",
          commentsCount: companyContactPersonCounts,
        },
        {
          id: "addressParticulars",
          label: "Address particulars",
          commentsCount: addressParticularsCommentsCount,
        },
        {
          id: "shareInformation",
          label: "Share information",
          commentsCount: shareInformationCommentsCount,
        },
        {
          id: "stakeholderDetails",
          label: "Stakeholder details",
          commentsCount: companyOfficialsCommentCount,
        },
        {
          id: "beneficialOwnership",
          label: "Beneficial ownership",
          commentsCount: beneficialOwnersCommentCount,
        },
        {
          id: "uploadDocuments",
          label: "File Uploads",
          commentsCount: uploadDocumentsComment,
        },
      ];
    } else {
      tempTabs = [
        {
          id: "companyFormation",
          label: "Company formation",
          commentsCount: companyFormationCommentsCounts,
        },
        {
          id: "contactPerson",
          label: "Contact Person Details",
          commentsCount: companyContactPersonCounts,
        },
        {
          id: "addressParticulars",
          label: "Address particulars",
          commentsCount: addressParticularsCommentsCount,
        },
        {
          id: "shareInformation",
          label: "Share information",
          commentsCount: shareInformationCommentsCount,
        },
        {
          id: "stakeholderDetails",
          label: "Stakeholder details",
          commentsCount: companyOfficialsCommentCount,
        },
      ];
    }
    setInitialTabs(tempTabs);
    setTabs(tempTabs);
  }, [corporateComments.length]);

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  if (!selectedTab) return "loading...";

  const isLastStep = tabs.length - 1 === selectedTabIndex;

  const handleSaveComment = async (data) => {
    if (data?.commentId) {
      await dispatch(editComment({ commentId: data?.commentId, data }));
    } else {
      await dispatch(
        addComment({
          data: {
            ...data,
            corporate: organization._id,
          },
        })
      );
    }
  };

  if (!organization?._id) return null;

  return (
    <>
      <div className="overflow-x-auto">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          showTooltip
        />
      </div>
      {selectedTab.id === "companyFormation" && (
        <CompanyFormation
          isViewMode={isViewMode}
          handleSaveComment={handleSaveComment}
          comments={companyFormationFieldsComments}
        />
      )}
      {selectedTab.id === "contactPerson" && (
        <ContactPersonDetails
          isViewMode={isViewMode}
          handleSaveComment={handleSaveComment}
          comments={contactPersonFieldsComments}
        />
      )}
      {selectedTab.id === "addressParticulars" && (
        <AddressParticulars
          isViewMode={isViewMode}
          handleSaveComment={handleSaveComment}
        />
      )}
      {selectedTab.id === "shareInformation" && (
        <ShareInformation disabledRightsEditor isViewMode={isViewMode} />
      )}
      {selectedTab.id === "stakeholderDetails" && (
        <StakeHolderDetails isViewMode={isViewMode} />
      )}
      {selectedTab.id === "beneficialOwnership" && (
        <BeneficialOwnership
          hideReviewWorkflow={isViewMode}
          isViewMode={isViewMode}
        />
      )}
      {selectedTab.id === "uploadDocuments" && (
        <UploadDocuments isViewMode={isViewMode} row={row} />
      )}
      <StepButtons
        goToPrevStep={goToPrevStep}
        goToNextStep={goToNextStep}
        isNextBtnLoading={isSubmitting}
        prevBtnLabel={"Previous Step"}
        nextBtnLabel={
          isLastStep &&
          comments.length === 0 &&
          organization.status !== "Approved"
            ? "Accepts Data"
            : isLastStep
            ? "Close"
            : "Next Step"
        }
      />
    </>
  );
};

export default CorporateReviewTab;
