import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadIcon } from "@heroicons/react/outline";
import { PencilAltIcon } from "@heroicons/react/solid";
import { format } from "date-fns";
import { twMerge } from "tailwind-merge";
import _has from "lodash/has";
import { useDebounce } from "use-debounce";

import Button from "components/lib/Shared/Button";
import useFullscreenToggle from "hooks/useFullscreenToggle";
import PaginationControls from "./PaginationControls";
import SearchInput from "components/lib/Shared/SearchInput";
import {
  getRegisterOfBeneficialOwnersAsync,
  downloadRegisterOfBeneficialOwnersAsync,
} from "state/slices/minuteBook";
import {
  calculateCumulativeOwnership,
  getBeneficialOwnershipFormFields,
  getCountryLabel,
  getEmptyRowsForRegisters,
  getFullName,
  getFullResidentialAddress,
  getPoBoxAddress,
  getVerifyId,
} from "utils";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import TypeOfControlColumn from "components/lib/Shared/TypeOfControlColumn";
import { Constants } from "config/constants";
import { getPaginationParams } from "utils/registers";
import BreadCrumbs from "./BreadCrumbs";

const applyCellFormatting = (
  cell,
  type = "rows",
  verticalLineBorder = "default"
) => {
  cell.alignment = {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
  };
  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: {
      argb:
        type === "header" ? "cfcfcf" : type === "folio" ? "FFFFFF" : "F2F2F2",
    },
  };
  cell.border = {
    top: { style: "double", color: { argb: "ffffff" } },
    bottom: { style: "double", color: { argb: "ffffff" } },
    left:
      verticalLineBorder === "left"
        ? { style: "double", color: { argb: "737272" } }
        : { style: "double", color: { argb: "ffffff" } },
    right:
      verticalLineBorder === "right"
        ? { style: "double", color: { argb: "737272" } }
        : { style: "double", color: { argb: "ffffff" } },
  };
  type === "column"
    ? (cell.font = {
        bold: true,
        size: 9, // You can adjust the font size as needed
      })
    : "";
};

const getBoPersonalDetails = (idType, identificationDetails) => {
  if (
    [Constants.ID_TYPES.KENYAN_CITIZEN, Constants.ID_TYPES.MINOR].includes(
      idType
    )
  ) {
    const formattedDateOfBirth = format(
      new Date(identificationDetails.dateOfBirth),
      "dd-MM-yyyy"
    );

    return `Date of Birth: ${formattedDateOfBirth}, KRA Pin: ${identificationDetails.KRAPin}, National ID Number: ${identificationDetails.nationalIDNumber}, Occupation: ${identificationDetails.occupation}`;
  }

  // to-do add more conditions here
};

const getBoAddress = (
  idType,
  postalAddress,
  isNonResident,
  electronicAddress,
  residentialAddressDetails
) => {
  const email = electronicAddress?.emailAddress ?? "Not Available";
  const mobileNumber = electronicAddress?.mobileNumber ?? "Not Available";
  const poBoxAddress = `${postalAddress?.postalAddress ?? "Not Available"} ${
    postalAddress?.postalCode ?? ""
  }`;
  const residentialAddress = `${
    residentialAddressDetails?.buildingName ?? "Not Available"
  } ${residentialAddressDetails?.county ?? ""} ${
    residentialAddressDetails?.district ?? ""
  } ${residentialAddressDetails?.locality ?? ""}`;

  if (
    [Constants.ID_TYPES.KENYAN_CITIZEN, Constants.ID_TYPES.MINOR].includes(
      idType
    ) &&
    isNonResident === "No"
  ) {
    return `E-mail Address: ${email}, Tel No: ${mobileNumber}, PO BOX: ${poBoxAddress}, Residential Address: ${residentialAddress}`;
  }

  if (
    [
      Constants.ID_TYPES.FOREIGN_RESIDENT,
      Constants.ID_TYPES.LOCAL_COMPANY,
      Constants.ID_TYPES.OTHER_PARASTATALS,
    ].includes(idType)
  ) {
    return `E-mail Address: ${email}, Tel No: ${mobileNumber}, PO BOX: ${poBoxAddress}, Residential Address: ${residentialAddress}`;
  }

  return `E-mail Address: ${email}, Tel No: ${mobileNumber}, PO BOX: ${poBoxAddress}, Residential Address: ${residentialAddress}`;
};

const ROW_HEIGHT = 300;
const { BENEFICIAL_OWNERS_ROWS } = Constants.REGISTERS;
const MAX_BO_SECTION_HEIGHT =
  ROW_HEIGHT * BENEFICIAL_OWNERS_ROWS + (BENEFICIAL_OWNERS_ROWS - 2) * 2;

const getBoFullName = ({ names, remainingMinorityShareholders }) => {
  if (remainingMinorityShareholders && remainingMinorityShareholders >= 0)
    return (
      <span>{`${remainingMinorityShareholders} Minority Shareholders`}</span>
    );
  return getFullName(names);
};

const getLinkedName = (beneficialOwner, allBeneficialOwners) => {
  const { linkToCompany, shareholderWithBO, JSCombinedName } = beneficialOwner;

  if (JSCombinedName) return JSCombinedName;
  if (linkToCompany) {
    const linkToCompanyBO = allBeneficialOwners.find(
      (bo) => bo._id === linkToCompany || bo._id === linkToCompany?._id
    );
    if (!linkToCompanyBO) return "";
    if (linkToCompanyBO.linkToCompany || linkToCompanyBO.shareholderWithBO)
      return getLinkedName(linkToCompanyBO, allBeneficialOwners)
        ? `${getLinkedName(
            linkToCompanyBO,
            allBeneficialOwners
          )} -> ${getBoFullName(linkToCompanyBO)}`
        : getBoFullName(linkToCompanyBO);
    return getBoFullName(linkToCompanyBO);
  }

  if (shareholderWithBO) {
    const linkedCompany = allBeneficialOwners.find(
      (bo) => bo._id === shareholderWithBO
    );
    if (!linkedCompany) return "";
    if (linkedCompany.linkToCompany || linkedCompany.shareholderWithBO)
      return getLinkedName(linkedCompany, allBeneficialOwners)
        ? `${getLinkedName(
            linkedCompany,
            allBeneficialOwners
          )} -> ${getBoFullName(linkedCompany)}`
        : getBoFullName(linkedCompany);
    return getBoFullName(linkedCompany);
  }
  return "Directly";
};

const LinkToCompanyCell = ({ beneficialOwner, allBeneficialOwners = [] }) => {
  if (beneficialOwner?.LinkToCompanyLabel)
    return <div>{beneficialOwner.LinkToCompanyLabel}</div>;
  if (beneficialOwner?.children) {
    const linkedCompanyNames = beneficialOwner.children?.map((child) =>
      getLinkedName(child, allBeneficialOwners)
    );
    if (linkedCompanyNames.length === 1)
      return <div>{linkedCompanyNames[0] || "Directly"}</div>;
    const lastName = linkedCompanyNames.pop();
    const linkedNames = linkedCompanyNames.join(", ") + " and " + lastName;
    return <div>{linkedNames}</div>;
  }

  return <div>{getLinkedName(beneficialOwner, allBeneficialOwners)}</div>;
};

const TableHeader = () => {
  return (
    <thead>
      <tr className="text-xs">
        {[
          {
            label: "Entry No:",
          },
          {
            label: `Full Name of the Beneficial Owner ("BO") (as it appears in their ID)`,
            className: "text-left w-32",
          },
          {
            label:
              "Link of the Beneficial Owner with the Company (Full name of the Shareholder):",
            className: "w-64",
          },
          {
            label: `BO's Personal Details`,
          },
          {
            label: "ADDRESS of the BO",
            className: "text-left",
          },
          {
            label:
              "Nature of ownership or control the beneficial owner has in the company",
          },
          {
            label: "Cumulative Interest",
            className: "w-40",
            subTitle: {
              label: "(shareholding, voting)",
              className: "italic",
            },
          },
          {
            label: `Reasonable Steps Taken to Obtain BO Information (e.g. Notice, Warning Notice, Restriction Notice, Withdrawal Notice with dates)`,
          },
          {
            label: `Source of BO Particulars:`,
            className: "w-32",
          },
          {
            label: `Date of`,
          },
        ].map((heading) => {
          return (
            <th
              key={heading.label}
              scope="col"
              className={twMerge(
                "font-semibold text-gray-900 bg-gray-100",
                heading.className
              )}
            >
              <p>{heading.label}</p>
              {heading.subTitle && (
                <p className={heading.subTitle.className}>
                  {heading.subTitle.label}
                </p>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const getVerifyIdLabel = (idType) => {
  const { KENYAN_CITIZEN, FOREIGN_RESIDENT, FOREIGNER, MINOR } =
    Constants.ID_TYPES;
  const labels = {
    [KENYAN_CITIZEN]: "ID No",
    [FOREIGN_RESIDENT]: "FC No",
    [FOREIGNER]: "PP No",
    [MINOR]: "Birth Cert. No",
  };
  return labels[idType] || "";
};

const PopulatedRow = ({ sn, beneficialOwner, allBeneficialOwners }) => {
  return (
    <tr className="text-xs">
      <td className="p-1 text-gray-600 bg-gray-100 align-top">{sn}</td>
      <td className="p-1 text-gray-600 bg-gray-100 text-left w-32 align-top">
        {getFullName(beneficialOwner.names)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 w-64 align-top">
        <LinkToCompanyCell
          beneficialOwner={beneficialOwner}
          allBeneficialOwners={allBeneficialOwners}
        />
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 align-top">
        <div className="flex flex-col h-64 w-32 space-y-2">
          <div className="flex flex-col justify-center flex-1 text-left border-b-2 border-white">
            <span className="font-semibold">Date of Birth:</span>
            <span>
              {beneficialOwner.identificationDetails.dateOfBirth
                ? format(
                    new Date(beneficialOwner.identificationDetails.dateOfBirth),
                    "dd-MM-yyyy"
                  )
                : ""}
            </span>
          </div>
          <div className="flex flex-col justify-center flex-1 text-left border-b-2 border-white">
            <span className="font-semibold">Nationality:</span>
            <span>
              {getCountryLabel(
                beneficialOwner.identificationDetails.nationality
              )}
            </span>
          </div>
          <div className="flex flex-col justify-center flex-1 text-left border-b-2 border-white">
            <span className="font-semibold">
              {getVerifyIdLabel(beneficialOwner.idType)}:
            </span>
            <span>
              {getVerifyId(
                beneficialOwner.idType,
                beneficialOwner.identificationDetails,
                beneficialOwner.names
              )}
            </span>
          </div>
          <div className="flex flex-col justify-center flex-1 text-left border-b-2 border-white">
            <span className="font-semibold">KRA PIN:</span>
            <span>{beneficialOwner.identificationDetails.KRAPin}</span>
          </div>
          <div className="flex flex-col justify-center flex-1 text-left">
            <span className="font-semibold">Occupation:</span>
            <span>{beneficialOwner.identificationDetails.occupation}</span>
          </div>
        </div>
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 align-top">
        <div className="flex flex-col h-64">
          <div className="flex flex-col flex-1 text-left border-b-2 border-white p-1">
            <span className="font-semibold">E-Mail Address:</span>
            <span>{beneficialOwner.electronicAddress.emailAddress}</span>
          </div>
          <div className="flex flex-col flex-1 text-left border-b-2 border-white p-1">
            <span className="font-semibold">Tel No:</span>
            <span>{beneficialOwner.electronicAddress.mobileNumber}</span>
          </div>
          <div className="flex flex-col flex-1 text-left border-b-2 border-white p-1">
            <span className="font-semibold">Postal Address:</span>
            <span>
              {getPoBoxAddress(
                beneficialOwner.idType,
                beneficialOwner.postalAddress,
                beneficialOwner.isNonResident
              )}
            </span>
          </div>
          <div className="flex flex-col flex-1 text-left p-1">
            <span className="font-semibold">Residential Address:</span>
            <span>
              {getFullResidentialAddress(
                beneficialOwner.residentialAddressDetails
              )}
            </span>
          </div>
        </div>
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 align-top">
        <TypeOfControlColumn fields={beneficialOwner.typeOfControl} />
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 align-top">
        {`${beneficialOwner.cumulativeShareholdings}%, ${beneficialOwner.cumulativeVotings}%`}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 align-top"></td>
      <td className="p-1 text-gray-600 bg-gray-100 align-top">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <span className="font-semibold">The BO information was:</span>
            <span>
              {
                Constants.SOURCE_OF_BO_INFO[
                  beneficialOwner.beneficialOwnershipForm.sourceOfBOInformation
                ]
              }
            </span>
          </div>
        </div>
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 align-top w-32">
        <div className="flex flex-col h-64">
          <div className="flex flex-col justify-center flex-1 text-left border-b-2 border-white p-1">
            <span className="font-semibold">Becoming as BO:</span>
            <span>
              {beneficialOwner.beneficialOwnershipForm?.dateOfBecomingBO
                ? format(
                    new Date(
                      beneficialOwner.beneficialOwnershipForm?.dateOfBecomingBO
                    ),
                    "yyyy-dd-MM"
                  )
                : ""}
            </span>
          </div>
          <div className="flex flex-col justify-center flex-1 text-left p-1">
            <span className="font-semibold">Cessation as a BO:</span>
            <span>
              {beneficialOwner.beneficialOwnershipForm?.cessationDate
                ? format(
                    new Date(
                      beneficialOwner.beneficialOwnershipForm?.cessationDate
                    ),
                    "yyyy-dd-MM"
                  )
                : ""}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

const EmptyRow = () => {
  return (
    <tr className="text-xs">
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100">
        <div className="flex flex-col h-64 w-32 space-y-2">
          <div className="flex flex-col flex-1 justify-center text-left border-b-2 border-white">
            <span className="font-semibold">Date of Birth:</span>
            <span></span>
          </div>
          <div className="flex flex-col flex-1 justify-center text-left border-b-2 border-white">
            <span className="font-semibold">Nationality:</span>
            <span></span>
          </div>
          <div className="flex flex-col flex-1 justify-center text-left border-b-2 border-white">
            <span className="font-semibold">ID/PP/Birth Cert. No:</span>
            <span></span>
          </div>
          <div className="flex flex-col flex-1 justify-center text-left border-b-2 border-white">
            <span className="font-semibold">KRA PIN:</span>
            <span></span>
          </div>
          <div className="flex flex-col flex-1 justify-center text-left">
            <span className="font-semibold">Occupation:</span>
            <span></span>
          </div>
        </div>
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 align-top">
        <div className="flex flex-col h-64">
          <div className="flex flex-col flex-1 justify-center text-left border-b-2 border-white p-1">
            <span className="font-semibold">E-Mail Address:</span>
            <span></span>
          </div>
          <div className="flex flex-col flex-1 justify-center text-left border-b-2 border-white p-1">
            <span className="font-semibold">Tel No:</span>
            <span></span>
          </div>
          <div className="flex flex-col flex-1 justify-center text-left border-b-2 border-white p-1">
            <span className="font-semibold">Postal Address:</span>
            <span></span>
          </div>
          <div className="flex flex-col flex-1 justify-center text-left p-1">
            <span className="font-semibold">Residential Address:</span>
            <span></span>
          </div>
        </div>
      </td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100"></td>
      <td className="p-1 text-gray-600 bg-gray-100">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <span className="font-semibold">The BO information was:</span>
            <span></span>
          </div>
        </div>
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 align-top w-32">
        <div className="flex flex-col h-64">
          <div className="flex flex-col justify-center flex-1 text-left border-b-2 border-white p-1">
            <span className="font-semibold">Becoming as BO:</span>
            <span></span>
          </div>
          <div className="flex flex-col justify-center flex-1 text-left p-1">
            <span className="font-semibold">Cessation as a BO:</span>
            <span></span>
          </div>
        </div>
      </td>
    </tr>
  );
};

// TODO: replace with renderToString https://react.dev/reference/react-dom/server/renderToString
const getLinkToCompanyCellHTML = (beneficialOwner, allBeneficialOwners) => {
  if (beneficialOwner?.LinkToCompanyLabel)
    return `<div>${beneficialOwner.LinkToCompanyLabel}</div>`;
  if (beneficialOwner?.children) {
    const linkedCompanyNames = beneficialOwner.children?.map((child) =>
      getLinkedName(child, allBeneficialOwners)
    );
    if (linkedCompanyNames.length === 1)
      return `<div>${linkedCompanyNames[0] || "Directly"}</div>`;
    const lastName = linkedCompanyNames.pop();
    const linkedNames = linkedCompanyNames.join(", ") + " and " + lastName;
    return `<div>${linkedNames}</div>`;
  }

  return `<div>
        ${getLinkedName(beneficialOwner, allBeneficialOwners)}
      </div>`;
};

// TODO: replace with renderToString https://react.dev/reference/react-dom/server/renderToString
const getTypeOfControlColumnHTML = (fields) => {
  if (fields.length === 0) return "N/A";

  return `<table class="table-auto">
      <thead>
        <tr>
          <th></th>
          <th class="border-b-2 border-gray-300/50 p-1 text-center px-4">
            Direct
          </th>
          <th class="border-b-2 border-gray-300/50 p-1 text-center px-4">
            Indirect
          </th>
        </tr>
      </thead>
      <tbody class="">
        ${fields.map((field, index) => {
          return `<tr>
                  <td
                    class="text-sm text-primary text-left py-1 ${
                      index !== 0 ? "border-t-2 border-white" : ""
                    }"
                  >
                    ${field.key}
                  </td>
                  <td class="border-r-2 border-gray-300/50 p-1 text-center w-24">
                    ${
                      typeof field.value.direct === "number"
                        ? `${field.value.direct}%`
                        : field.value.direct
                    }
                  </td>
                  <td class="p-2 text-center w-24">
                    ${
                      typeof field.value.indirect === "number"
                        ? `${field.value.indirect}%`
                        : field.value.indirect
                    }
                  </td>
                </tr>`;
        })}
      </tbody>
    </table>`;
};

const getPopulatedRowNode = (beneficialOwner, sn, allBeneficialOwners) => {
  if (!beneficialOwner) return null;
  const currentTableRowNode = document.createElement("tr");
  currentTableRowNode.setAttribute("class", "text-xs");
  currentTableRowNode.innerHTML = `<td class="p-1 text-gray-600 bg-gray-100">
  ${sn}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 text-left w-32">
    ${getFullName(beneficialOwner.names)}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 w-64">
    ${getLinkToCompanyCellHTML(beneficialOwner, allBeneficialOwners)}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100">
    <div class="flex flex-col h-64 w-32 space-y-2">
      <div class="flex flex-col justify-center flex-1 text-left border-b-2 border-white">
        <span class="font-semibold">
          Date of Birth:
        </span>
        <span>
          ${
            beneficialOwner.identificationDetails.dateOfBirth
              ? format(
                  new Date(beneficialOwner.identificationDetails.dateOfBirth),
                  "dd-MM-yyyy"
                )
              : ""
          }
        </span>
      </div>
      <div class="flex flex-col justify-center flex-1 text-left border-b-2 border-white">
        <span class="font-semibold">
          Nationality:
        </span>
        <span>
          ${getCountryLabel(beneficialOwner.identificationDetails.nationality)}
        </span>
      </div>
      <div class="flex flex-col justify-center flex-1 text-left border-b-2 border-white">
        <span class="font-semibold">
         ${getVerifyIdLabel(beneficialOwner.idType)}:
        </span>
        <span>
          ${getVerifyId(
            beneficialOwner.idType,
            beneficialOwner.identificationDetails,
            beneficialOwner.names
          )}
        </span>
      </div>
      <div class="flex flex-col justify-center flex-1 text-left border-b-2 border-white">
        <span class="font-semibold">
          KRA PIN:
        </span>
        <span>
          ${beneficialOwner.identificationDetails.KRAPin}
        </span>
      </div>
      <div class="flex flex-col justify-center flex-1 text-left">
        <span class="font-semibold">
          Occupation:
        </span>
        <span>
          ${beneficialOwner.identificationDetails.occupation}
        </span>
      </div>
    </div>
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 align-top">
    <div class="flex flex-col h-64">
      <div class="flex flex-col flex-1 text-left border-b-2 border-white p-1">
        <span class="font-semibold">
          E-Mail Address:
        </span>
        <span>
        ${beneficialOwner.electronicAddress.emailAddress}
        </span>
      </div>
      <div class="flex flex-col flex-1 text-left border-b-2 border-white p-1">
        <span class="font-semibold">
          Tel No:
        </span>
        <span>
          ${beneficialOwner.electronicAddress.mobileNumber}
        </span>
      </div>
      <div class="flex flex-col flex-1 text-left border-b-2 border-white p-1">
        <span class="font-semibold">
          Postal Address:
        </span>
        <span>
        ${getPoBoxAddress(
          beneficialOwner.idType,
          beneficialOwner.postalAddress,
          beneficialOwner.isNonResident
        )}
        </span>
      </div>
      <div class="flex flex-col flex-1 text-left p-1">
        <span class="font-semibold">
          Residential Address:
        </span>
        <span>
        ${getFullResidentialAddress(beneficialOwner.residentialAddressDetails)}
        </span>
      </div>
    </div>
  </td>
  <td class="p-1 text-gray-600 bg-gray-100">
    ${getTypeOfControlColumnHTML(beneficialOwner.typeOfControl)}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100">
  ${`${beneficialOwner.cumulativeShareholdings}%, ${beneficialOwner.cumulativeVotings}% `}
  </td>
  <td class="p-1 text-gray-600 bg-gray-100"></td>
  <td class="p-1 text-gray-600 bg-gray-100">
    <div class="flex flex-col">
      <div class="flex flex-col">
        <span class="font-semibold">
          The BO information was:
        </span>
        <span>
        ${
          Constants.SOURCE_OF_BO_INFO[
            beneficialOwner.beneficialOwnershipForm.sourceOfBOInformation
          ]
        }
        </span>
      </div>
    </div>
  </td>
  <td class="p-1 text-gray-600 bg-gray-100 align-top w-32">
    <div class="flex flex-col h-64">
      <div class="flex flex-col justify-center flex-1 text-left border-b-2 border-white p-1">
        <span class="font-semibold">
          Becoming as BO:
        </span>
        <span>
        ${
          beneficialOwner.beneficialOwnershipForm?.dateOfBecomingBO
            ? format(
                new Date(
                  beneficialOwner.beneficialOwnershipForm?.dateOfBecomingBO
                ),
                "yyyy-dd-MM"
              )
            : ""
        }
        </span>
      </div>
      <div class="flex flex-col justify-center flex-1 text-left p-1">
        <span class="font-semibold">
          Cessation as a BO:
        </span>
        <span>
        ${
          beneficialOwner.beneficialOwnershipForm?.cessationDate
            ? format(
                new Date(
                  beneficialOwner.beneficialOwnershipForm?.cessationDate
                ),
                "yyyy-dd-MM"
              )
            : ""
        }
        </span>
      </div>
    </div>
  </td>
`;
  return currentTableRowNode;
};

function RegisterOfBeneficialOwners({ selectedTab }) {
  const { isFullscreen, ToggleFullscreenContainer, ToggleFullscreenButton } =
    useFullscreenToggle();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const beneficialOwnersTableBodyRef = useRef(null);
  const hiddenTempTableBodyRef = document.getElementById(
    "tempTableForRegisters"
  );
  const [paginatedBeneficialOwners, setPaginatedBeneficialOwners] = useState(
    []
  );
  const [emptyRowsCount, setEmptyRowsCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState({});
  const [beneficialOwners, setBeneficialOwners] = useState({
    items: [],
    totalCount: 0,
  });
  const [lastPageWithData, setLastPageWithData] = useState(1);

  const { company } = useSelector((state) => state.companyDashboardSlice);
  const {
    registerOfBeneficialOwners,
    getRegisterOfBeneficialOwners,
    downloadRegisterOfBeneficialOwners,
  } = useSelector((state) => state.minuteBookSlice);

  useEffect(() => {
    if (company._id) {
      dispatch(
        getRegisterOfBeneficialOwnersAsync({
          queryParams: { companyId: company._id },
        })
      );
    }
  }, [company._id]);

  useEffect(() => {
    let tempBeneficialOwners = registerOfBeneficialOwners.items;
    const searchQuery = debouncedSearchTerm.toLowerCase();
    if (searchQuery) {
      tempBeneficialOwners = tempBeneficialOwners.filter((director) =>
        getFullName(director.names).toLowerCase().includes(searchQuery)
      );
    }
    setCurrentPage(1);
    setBeneficialOwners({
      items: tempBeneficialOwners,
      totalCount: tempBeneficialOwners.length,
    });
  }, [registerOfBeneficialOwners.items, debouncedSearchTerm]);

  useEffect(() => {
    const getEmptyRowsCount = (currentPage, maxSectionHeight) => {
      let tempEmptyRowsCount = 0;
      const tableBody = document.querySelector(
        `[data-table-index="${currentPage}"]`
      );
      if (!tableBody) return tempEmptyRowsCount;
      while (
        beneficialOwnersTableBodyRef.current &&
        tableBody.clientHeight < maxSectionHeight &&
        maxSectionHeight - tableBody.clientHeight > ROW_HEIGHT &&
        tempEmptyRowsCount < BENEFICIAL_OWNERS_ROWS
      ) {
        const emptyTableRow = document.createElement("tr");
        emptyTableRow.setAttribute("class", "text-xs h-6");
        emptyTableRow.innerHTML = `
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100">
            <div class="flex flex-col h-64 w-32 space-y-2">
              <div class="flex flex-col flex-1 justify-center text-left border-b-2 border-white">
                <span class="font-semibold">
                  Date of Birth:
                </span>
                <span></span>
              </div>
              <div class="flex flex-col flex-1 justify-center text-left border-b-2 border-white">
                <span class="font-semibold">
                  Nationality:
                </span>
                <span></span>
              </div>
              <div class="flex flex-col flex-1 justify-center text-left border-b-2 border-white">
                <span class="font-semibold">
                  ID/PP/Birth Cert. No:
                </span>
                <span></span>
              </div>
              <div class="flex flex-col flex-1 justify-center text-left border-b-2 border-white">
                <span class="font-semibold">
                  KRA PIN:
                </span>
                <span></span>
              </div>
              <div class="flex flex-col flex-1 justify-center text-left">
                <span class="font-semibold">
                  Occupation:
                </span>
                <span></span>
              </div>
            </div>
          </td>
          <td class="p-1 text-gray-600 bg-gray-100 align-top">
            <div class="flex flex-col h-64">
              <div class="flex flex-col flex-1 justify-center text-left border-b-2 border-white p-1">
                <span class="font-semibold">
                  E-Mail Address:
                </span>
                <span></span>
              </div>
              <div class="flex flex-col flex-1 justify-center text-left border-b-2 border-white p-1">
                <span class="font-semibold">Tel No:</span>
                <span></span>
              </div>
              <div class="flex flex-col flex-1 justify-center text-left border-b-2 border-white p-1">
                <span class="font-semibold">
                  Postal Address:
                </span>
                <span></span>
              </div>
              <div class="flex flex-col flex-1 justify-center text-left p-1">
                <span class="font-semibold">
                  Residential Address:
                </span>
                <span></span>
              </div>
            </div>
          </td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100">
            <div class="flex flex-col">
              <div class="flex flex-col">
                <span class="font-semibold">
                  The BO information was:
                </span>
                <span></span>
              </div>
            </div>
          </td>
          <td class="p-1 text-gray-600 bg-gray-100 align-top w-32">
            <div class="flex flex-col h-64">
              <div class="flex flex-col justify-center flex-1 text-left border-b-2 border-white p-1">
                <span class="font-semibold">
                  Becoming as BO:
                </span>
                <span></span>
              </div>
              <div class="flex flex-col justify-center flex-1 text-left p-1">
                <span class="font-semibold">
                  Cessation as a BO:
                </span>
                <span></span>
              </div>
            </div>
          </td>
        `;
        tableBody.appendChild(emptyTableRow);
        tempEmptyRowsCount++;
      }
      return tempEmptyRowsCount;
    };

    const calculateRowsPerPage = () => {
      if (!hiddenTempTableBodyRef || !beneficialOwnersTableBodyRef.current)
        return;

      hiddenTempTableBodyRef.innerHTML = "";

      const { rowsPerPage, lastPage } = getPaginationParams(
        beneficialOwners.items,
        MAX_BO_SECTION_HEIGHT,
        beneficialOwnersTableBodyRef,
        (official, index) =>
          getPopulatedRowNode(official, index + 1, beneficialOwners.items)
      );

      setRowsPerPage(rowsPerPage);
      setLastPageWithData(lastPage);
      setEmptyRowsCount(
        lastPage ? getEmptyRowsCount(lastPage, MAX_BO_SECTION_HEIGHT) : 0
      );

      setCurrentPage(1);
    };

    calculateRowsPerPage();

    window.addEventListener("resize", calculateRowsPerPage);

    return () => {
      window.removeEventListener("resize", calculateRowsPerPage);
    };
  }, [beneficialOwners.items, isFullscreen]);

  useEffect(() => {
    if (
      beneficialOwners.totalCount &&
      _has(rowsPerPage[currentPage], "start")
    ) {
      setPaginatedBeneficialOwners(
        beneficialOwners.items.slice(
          rowsPerPage[currentPage].start,
          rowsPerPage[currentPage].end
        )
      );
    } else {
      setPaginatedBeneficialOwners([]);
    }
  }, [currentPage, beneficialOwners, rowsPerPage]);

  const handleDownloadOnFE = async () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`Page 1}`);
    addHeader(worksheet, "REGISTER OF BENEFICIAL OWNERS", 1);
    BeneficialOwnersCellsLayout(worksheet, 3, 1, "Name", "A2:C2", true);
    BeneficialOwnersCellsLayout(
      worksheet,
      3,
      2,
      "Link of the Beneficial Owner with the Company (Full name of the Shareholder)",
      "D2:F2",
      true
    );
    BeneficialOwnersCellsLayout(
      worksheet,
      3,
      3,
      "BO Personal Details",
      "G2:J2",
      true
    );
    BeneficialOwnersCellsLayout(
      worksheet,
      3,
      4,
      "ADDRESS of the BO",
      "K2:M2",
      true
    );
    BeneficialOwnersCellsLayout(
      worksheet,
      3,
      5,
      "Nature of ownership or control the beneficial owner has in the company",
      "N2:S2",
      true
    );
    BeneficialOwnersCellsLayout(
      worksheet,
      3,
      6,
      "Cumulative Interest(shareholding,voting)",
      "T2:V2",
      true
    );
    BeneficialOwnersCellsLayout(
      worksheet,
      3,
      7,
      "Reasonable Steps Taken to Obtain BO Information",
      "W2:Y2",
      true
    );
    BeneficialOwnersCellsLayout(
      worksheet,
      3,
      8,
      "Source of BO Particulars",
      "Z2:AD2",
      true
    );
    BeneficialOwnersCellsLayout(worksheet, 3, 9, "Date of", "AE2:AG2", true);

    addBeneficialOwnersRowData(worksheet, beneficialOwners.items, 3);

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), "register.xlsx");
  };

  const handleDownloadClick = () => {
    dispatch(
      downloadRegisterOfBeneficialOwnersAsync({
        queryParams: { companyId: company._id, fileFormat: "xlsx" },
      })
    );
  };

  if (getRegisterOfBeneficialOwners.status === "loading") {
    return (
      <div className="col-span-6">
        <div className="flex justify-center mx-auto text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-auto">
      <div className="flex justify-between w-full mb-4">
        <BreadCrumbs breadCrumbs={selectedTab.breadCrumbs} />
        <Button
          onClick={handleDownloadClick}
          preIcon={DownloadIcon}
          isLoading={downloadRegisterOfBeneficialOwners.status === "loading"}
          loadingText="Downloading..."
        >
          Download
        </Button>
      </div>
      <ToggleFullscreenContainer>
        <ToggleFullscreenButton />
        <div
          className={`bg-white relative ${
            isFullscreen ? "h-[88vh] overflow-auto" : ""
          }`}
        >
          <div
            className={`overflow-auto px-1 pt-4 pb-20 space-y-2 flex flex-col text-center ${
              isFullscreen ? "px-12" : ""
            }`}
          >
            {/* REGISTER OF BENEFICIAL OWNERS */}
            <div className="relative flex flex-col items-center">
              <div>
                <h2 className="text-title6 font-medium">
                  REGISTER OF BENEFICIAL OWNERS
                </h2>
                <div className="flex items-center justify-center">
                  <span className="mr-2 text-sm">
                    as at {format(new Date(), "dd-MM-yyyy")}
                  </span>
                  <PencilAltIcon className="w-4 h-4" />
                </div>
                {isFullscreen && (
                  <SearchInput
                    value={searchTerm}
                    placeholder="Search register"
                    className="lg:absolute w-64 top-0 right-0 mt-2"
                    handleOnChange={(event) =>
                      setSearchTerm(event.target.value)
                    }
                    autoFocus
                  />
                )}
              </div>
              <div className="mt-4">
                <table className="min-w-full divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
                  <TableHeader />
                  <tbody
                    ref={beneficialOwnersTableBodyRef}
                    style={{ height: `${MAX_BO_SECTION_HEIGHT}px` }}
                    className="divide-y divide-gray-200"
                  >
                    {paginatedBeneficialOwners.map((beneficialOwner, index) => (
                      <PopulatedRow
                        key={beneficialOwner._id}
                        sn={
                          index +
                          1 +
                          (currentPage > 1
                            ? rowsPerPage[currentPage - 1].end
                            : 0)
                        }
                        beneficialOwner={beneficialOwner}
                        allBeneficialOwners={beneficialOwners.items}
                      />
                    ))}
                    {currentPage === lastPageWithData && emptyRowsCount
                      ? Array.from({
                          length: emptyRowsCount,
                        }).map((_, index) => (
                          <EmptyRow
                            key={`beneficial_owner_empty_row_${index}`}
                          />
                        ))
                      : null}
                    {currentPage > lastPageWithData
                      ? getEmptyRowsForRegisters(0, BENEFICIAL_OWNERS_ROWS).map(
                          (_, index) => (
                            <EmptyRow
                              key={`beneficial_owner_empty_row_${index}`}
                            />
                          )
                        )
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <PaginationControls
            isFullscreen={isFullscreen}
            isLastPage={currentPage === lastPageWithData}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage[currentPage] || 0}
            totalRows={beneficialOwners.totalCount}
            totalPages={Object.keys(rowsPerPage).length}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </ToggleFullscreenContainer>
    </div>
  );
}

export default RegisterOfBeneficialOwners;
