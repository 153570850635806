import { object, string } from 'yup'

const step2Validation = () => {
  const schema = object().shape({
    articlesOfAssociationToUse: string().required('Please select one option'),
    articlesOfAssociation: object().shape({
      articlesType: string().required('Please select one option'),
      natureOfBusiness: string().when('articlesType', {
        is: "Non-Regulated",
        then: string()
          .trim('Nature of business cannot include leading and trailing spaces')
          .strict(true)
          .required("Nature of business is required")
      }),
      nameOfRegulator: string().when('articlesType', {
        is: "Regulated",
        then: string()
          .trim('Nature of regulator cannot include leading and trailing spaces')
          .strict(true)
          .required("Nature of regulator is required")
      }),
      regulatorInstitute: string().when('articlesType', {
        is: "Regulated",
        then: string()
          .required("Regulator institute is required")
      }),
    })
  })

  return {
    schema,
    schemaInitialValues: schema.cast()
  }
}

export default step2Validation
