import { RadioGroup } from "@headlessui/react";
import { FolderOpenIcon, CheckCircleIcon } from "@heroicons/react/outline";

import { OfficeBuilding } from "components/lib/Shared/Icons/sflIcons";
import { classNames } from "utils";

const services = [
  {
    id: 1,
    title: "Public Limited Company (PLC)",
    description:
      "Its articles allow its members the right to transfer their shares in the company and do not prohibit invitations to the public to subscribe for shares or debentures of the company.",
    href: "public-limited-company",
    icon: <OfficeBuilding />,
  },
  {
    id: 2,
    title: "Private Company (PVT)",
    description:
      "A company is limited by shares if the liability of its members is limited by the memorandum to the amount, if any, unpaid on the shares respectively held by them.",
    href: "private-limited-company",
    icon: <FolderOpenIcon />,
  },
  {
    id: 3,
    title: "Company Limited By Guarantee (CLG)",
    description:
      "This is an alternative type of corporation used primarily for non-profit organizations that requires all  members to undergo vetting before the company is registered.",
    href: "company-limited-guarantee",
    icon: <FolderOpenIcon />,
    status: (
      <div className="col-span-2 sm:col-span-4 bg-[#9EA0A6] text-white px-3 py-0.25 rounded-md shadow-md">
        Coming Soon
      </div>
    ),
    disabled: true,
  },
  {
    id: 4,
    title: "Limited Liability Partnership (LLP)",
    description:
      "This is a unique form of business association that combines elements of a company with those of a partnership.",
    href: "limited-liability-partnership",
    icon: <FolderOpenIcon />,
    status: (
      <div className="col-span-2 sm:col-span-4 bg-[#9EA0A6] text-white px-3 py-0.25 rounded-md shadow-md">
        Coming Soon
      </div>
    ),
    disabled: true,
  },
  {
    id: 5,
    title: "Foreign Company",
    description:
      "A foreign corporation refers to  an existing corporation that is registered to do business in a state or jurisdiction other than where it was originally incorporated.",
    href: "public-limited-company",
    icon: <FolderOpenIcon />,
    status: (
      <div className="col-span-2 sm:col-span-4 bg-[#9EA0A6] text-white px-3 py-0.25 rounded-md shadow-md">
        Coming Soon
      </div>
    ),
    disabled: true,
  },
  {
    id: 6,
    title: "Limited Partnership (LP)",
    description:
      "A Limited Partnership is a form of partnership where at least one of the Partners is a General Partners, and at least one of the other Partners is a Limited Partner. A General Partner has the general responsibilities as to the management of the Partnership. A Limited Partnership is a legal person.",
    href: "limited-partnership",
    icon: <FolderOpenIcon />,
    status: (
      <div className="col-span-2 sm:col-span-4 bg-[#9EA0A6] text-white px-3 py-0.25 rounded-md shadow-md">
        Coming Soon
      </div>
    ),
    disabled: true,
  },
  {
    id: 7,
    title: "General Partnership (GP)",
    description:
      "A General Partnership is a form of partnership where all the Partners have equal and general responsibilities as to the management of the Partnership. It has no separate legal persona from its partners.",
    href: "general-partnership",
    icon: <FolderOpenIcon />,
    status: (
      <div className="col-span-2 sm:col-span-4 bg-[#9EA0A6] text-white px-3 py-0.25 rounded-md shadow-md">
        Coming Soon
      </div>
    ),
    disabled: true,
  },
  {
    id: 8,
    title: "Sole Proprietorship (SP)",
    description:
      "A sole proprietorship is a registered business name under which a person carries on business. It has no separate legal persona from the business person.",
    href: "sole-proprietorship",
    icon: <FolderOpenIcon />,
    status: (
      <div className="col-span-2 sm:col-span-4 bg-[#9EA0A6] text-white px-3 py-0.25 rounded-md shadow-md">
        Coming Soon
      </div>
    ),
    disabled: true,
  },
];

function ServicesList({ selectedService, setSelectedService, className }) {
  return (
    <RadioGroup
      value={selectedService}
      onChange={setSelectedService}
      className={className}
    >
      <RadioGroup.Label className="font-medium text-gray-900">
        Select a service
      </RadioGroup.Label>
      <div className="grid grid-cols-1 mt-2 gap-y-6 md:grid-cols-2 lg:grid-cols-3 sm:gap-x-4">
        {services.map((service) => (
          <RadioGroup.Option
            key={service.id}
            value={service}
            disabled={service.disabled}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-gray-300",
                active ? "ring-2 ring-indigo-500" : "",
                service.disabled ? "cursor-not-allowed" : "",
                "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none select-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <div className="flex flex-1">
                  <div className="flex flex-col">
                    <div className="w-24 h-24">{service.icon}</div>
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-gray-900"
                    >
                      <div className="grid items-start justify-between sm:grid-cols-12 sm:flex-row">
                        <div className="col-span-10 ">{service.title}</div>
                        {service.status}
                      </div>
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className="flex items-center mt-1 text-sm text-gray-500"
                    >
                      {service.description}
                    </RadioGroup.Description>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-indigo-600"
                  )}
                  aria-hidden="true"
                />
                <div
                  className={classNames(
                    active && !checked
                      ? "border border-indigo-500"
                      : "border-2 ",
                    checked ? "border-indigo-500" : "border-transparent",
                    "absolute -inset-px rounded-lg pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}

export default ServicesList;
