import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { useDebounce } from "use-debounce";

import ReactTable from "components/lib/Global/ReactTable";
import DeleteButtonWithConfirmation from "components/lib/Shared/DeleteButtonWithConfirmation";
import Button from "components/lib/Shared/Button";
import { deleteProductAsync, getProductsAsync } from "state/slices/product";
import { useToast } from "hooks/useToast";
import TableActionMenuWrapper from "components/lib/Shared/TableActionMenuWrapper";
import ConfirmDialog from "components/lib/Shared/ConfirmDialog";
import SearchInput from "components/lib/Shared/SearchInput";
import DashOutline from "components/lib/Shared/Icons/DashOutline";

const ProductsTable = ({ handleEditProduct }) => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const { products, getProducts } = useSelector((state) => state.productSlice);
  const { modalRow } = useSelector((state) => state.modalsSlice);

  const handleDeleteProduct = async (row) => {
    const response = await dispatch(deleteProductAsync({ productId: row._id }));
    if (response.error) {
      toast(
        "error",
        response.payload?.messageText ?? "Something went wrong please try again"
      );
    } else {
      dispatch(getProductsAsync());
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) setCurrentPage(1);
    dispatch(
      getProductsAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm.trim(),
        order: "asc",
      })
    );
  }, [dispatch, currentPage, debouncedSearchTerm]);

  const columns = [
    {
      Header: "Name",
      className: "p-4 text-ellipsis overflow-hidden max-w-[150px]",
      Cell: ({ row }) => row.original?.name ?? "N/A",
    },
    {
      Header: "Updated At",
      Cell: ({ row }) => format(new Date(row.original?.updatedAt), "PP"),
    },
    {
      Header: "Date",
      Cell: ({ row }) => format(new Date(row.original?.createdAt), "PP"),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <TableActionMenuWrapper>
          <Menu.Item>
            {({ active }) => (
              <Button
                onClick={() => handleEditProduct(row.original)}
                overrideBaseStyles
                className={`${
                  active ? "bg-violet-500 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                Edit
              </Button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <DeleteButtonWithConfirmation
                confirmationModalName="delete_product_confirmation_modal"
                overrideBaseStyles
                btnClasses={`${
                  active ? "bg-red-600 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                row={row.original}
              />
            )}
          </Menu.Item>
        </TableActionMenuWrapper>
      ),
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full bg-white shadow">
        {products.totalCount === 0 ? (
          <div className="relative flex flex-col items-center justify-center p-32 text-center">
            <DashOutline />
            <div className="absolute flex flex-col items-center justify-center">
              <h2 className="text-title5">There are no products to display</h2>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <ReactTable
              columns={columns}
              data={products.items}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={products.totalCount}
              setCurrentPage={setCurrentPage}
              paginate
              isLoading={getProducts.status === "loading"}
              headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
              headingStyle="pl-4 text-left text-white"
              dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            />
          </div>
        )}
      </div>
      <ConfirmDialog
        name="delete_product_confirmation_modal"
        onConfirm={() => handleDeleteProduct(modalRow)}
      >
        Are you sure you want to permanently delete this entry?
      </ConfirmDialog>
    </>
  );
};

export default ProductsTable;
