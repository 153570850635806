import ResidentialAddressDetailsFormKeCiFoRe from "components/PLCTABS/CompanyOfficialsTab/AddressParticularsComponents/ResidentialAddressComponents/ResidentialAddressDetailsFormKeCiFoRe";
import IsNonResidentSelection from "components/lib/Shared/IsNonResidentSelection";
import { Field } from "formik";
import ResidentialAddressDetailsFormFormMi from "components/PLCTABS/CompanyOfficialsTab/AddressParticularsComponents/ResidentialAddressComponents/ResidentialAddressDetailsFormFoMi";
import PostalAddressFormLocal from "components/PLCTABS/CompanyOfficialsTab/PostalComponents/PostalAddressFormLocal";
import PostalAddressFormForeign from "components/PLCTABS/CompanyOfficialsTab/PostalComponents/PostalAddressFormForeign";
import PostalCodeForm from "components/PLCTABS/CompanyOfficialsTab/PostalComponents/PostalCodeForm";

const JointResidentialDetails = ({
  idType,
  disabled,
  errors,
  touched,
  isNonResident,
  renderIsNonResidentComponent,
}) => {
  return (
    <>
      {renderIsNonResidentComponent && (
        <IsNonResidentSelection
          disabled={disabled}
          errors={errors}
          touched={touched}
        />
      )}
      <div>
        {(((idType === "Kenyan Citizen" || idType === "Minor") &&
          isNonResident === "No") ||
          idType === "Foreign Resident") && (
          <>
            <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <Field
                name="postalAddress"
                component={PostalAddressFormLocal}
                idType={idType}
                disabled={disabled}
              />
              <Field
                name="postalAddress"
                component={PostalCodeForm}
                disabled={disabled}
              />
              <Field
                name="residentialAddressDetails"
                component={ResidentialAddressDetailsFormKeCiFoRe}
                colWidth={4}
                disabled={disabled}
              />
            </div>
          </>
        )}
        {(idType === "Foreigner" ||
          ((idType === "Kenyan Citizen" || idType === "Minor") &&
            isNonResident === "Yes")) && (
          <>
            <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <Field
                name="postalAddress"
                component={PostalAddressFormForeign}
                idType={idType}
                disabled={disabled}
              />
            </div>
            <Field
              name="residentialAddressDetails"
              component={ResidentialAddressDetailsFormFormMi}
              disabled={disabled}
            />
          </>
        )}
      </div>
    </>
  );
};

export default JointResidentialDetails;
