import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import Label from "components/lib/Shared/Label";
import ApplicantsTable from "./ApplicantsTable";
import Button from "components/lib/Shared/Button";
import { ChevronRightIcon } from "components/lib/Shared/Icons/sflIcons";
import AddCoApplicantForm from "./AddCoApplicantForm";
import { getFullName } from "utils";
import { updateCompanyAsync, updateCompanyState } from "state/slices/company";
import { getCoApplicantAsync } from "state/slices/coApplicant";
import Overlay from "components/lib/Shared/Overlay";

function ApplicantsDetails({ handleNextBtn }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  const { company, updateCompany } = useSelector((state) => state.companySlice);
  const currentUser = useSelector((state) => state.userSlice);
  const { coApplicants, getCoApplicants } = useSelector(
    (state) => state.coApplicantSlice
  );
  const dispatch = useDispatch();

  const [mode, setMode] = useState(null);

  useEffect(() => {
    if (company?._id) {
      dispatch(
        getCoApplicantAsync({
          company: company?._id,
          limit: rowsPerPage,
          skip: (currentPage - 1) * rowsPerPage,
        })
      );
    }
  }, [rowsPerPage, currentPage]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        addCoApplicants: company?.addCoApplicants,
      }}
      validateOnBlur={false}
      onSubmit={handleNextBtn}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        const applicant = company?.createdBy;
        const { isCoApplicant } = company || {};

        const handleChange = (addCoApplicants) => {
          if (isCoApplicant) return null;
          if (addCoApplicants && coApplicants.items.length === 0) {
            setMode("addCoApplicants");
          } else {
            setMode(null);
          }
          setFieldValue("addCoApplicants", addCoApplicants);
          dispatch(
            updateCompanyAsync({
              id: company._id,
              data: { addCoApplicants },
            })
          );
          dispatch(
            updateCompanyState({
              company: {
                ...company,
                addCoApplicants,
              },
            })
          );
        };

        const submitForm = (e) => {
          e.preventDefault();
          if (!company?.applicantCapacity) return;
          handleSubmit();
        };

        return (
          <Form onSubmit={submitForm}>
            <div className="flex flex-col relative space-y-8">
              <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
                <div className="px-4 py-6 sm:p-6 space-y-2">
                  <span>
                    Would you wish to invite a co-applicant to add relevant
                    details to this application
                  </span>
                  <div className="flex space-x-10">
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="addCoApplicants"
                        id="yes"
                        value={true}
                        disabled={isCoApplicant}
                        onChange={() => handleChange(true)}
                        className="disabled:opacity-40 disabled:cursor-not-allowed"
                      />
                      <Label htmlFor="yes" className="pl-4">
                        YES
                      </Label>
                    </div>
                    <div className="flex items-center">
                      <Field
                        type="radio"
                        name="addCoApplicants"
                        id="no"
                        value={false}
                        disabled={isCoApplicant}
                        onChange={() => handleChange(false)}
                        className="disabled:opacity-40 disabled:cursor-not-allowed"
                      />
                      <Label htmlFor="no" className="pl-4">
                        NO
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
              {typeof values.addCoApplicants !== "undefined" && (
                <div>
                  <h2 className="font-medium te mb-2">Applicant</h2>
                  <ApplicantsTable
                    data={[
                      {
                        ...applicant,
                        name: getFullName(applicant.names),
                        idType: applicant.idType,
                        designation: "Company Secretary",
                        status: "Approved",
                      },
                    ]}
                  />
                </div>
              )}
              {values.addCoApplicants && !mode && (
                <div>
                  {(company.isApplicant || company.isCoApplicant) && (
                    <div className="flex justify-between">
                      <h2 className="font-medium te mb-2">Co Applicants</h2>
                      {/* co applicant can invite other co applicants */}
                      <Button
                        variant="text"
                        onClick={() => setMode("addCoApplicants")}
                      >
                        + Add a new Co Applicant
                      </Button>
                    </div>
                  )}
                  <ApplicantsTable
                    coApplicantsTable
                    isLoading={getCoApplicants.status === "loading"}
                    data={coApplicants.items.map((coApplicant) => ({
                      ...coApplicant,
                      name: coApplicant?.user
                        ? getFullName(coApplicant.user.names)
                        : coApplicant?.email ?? "",
                    }))}
                    totalRows={coApplicants.totalCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    rowsPerPage={rowsPerPage}
                  />
                </div>
              )}
              {mode === "addCoApplicants" && (
                <AddCoApplicantForm
                  currentUserFullName={getFullName(currentUser.names)}
                  setMode={setMode}
                />
              )}
              {!mode && (
                <div className="flex justify-end">
                  <Button
                    disabled={!company?.applicantCapacity}
                    onClick={submitForm}
                    postIcon={ChevronRightIcon}
                  >
                    Save and proceed
                  </Button>
                </div>
              )}
              {updateCompany.status === "loading" ? <Overlay /> : null}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ApplicantsDetails;
