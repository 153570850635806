import { useToastDispatchContext } from "context/ToastContext";

export function useToast(delay = 5000) {
  const dispatch = useToastDispatchContext();

  function toast(type, message) {
    const id = Math.random().toString(36).slice(2);

    dispatch({
      type: "ADD_TOAST",
      toast: {
        type,
        message,
        id,
      },
    });

    const timer = setTimeout(() => {
      dispatch({ type: "DELETE_TOAST", id });
      clearInterval(timer);
    }, delay);
  }

  function clearAllToasts() {
    dispatch({ type: "DELETE_ALL_TOASTS" });
  }

  return { toast, clearAllToasts };
}
