import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import Cookies from "js-cookie";
import { object, string } from "yup";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import SectionDescription from "components/lib/Shared/SectionDescription";
import ValidationError from "components/lib/Shared/ValidationError";
import {
  updateCorporate,
  updateOrganizationState,
} from "state/slices/organizations";
import Label from "components/lib/Shared/Label";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import { Constants } from "config/constants";
import Button from "components/lib/Shared/Button";
import CommentsList from "components/lib/Global/CommentsList";

const officialIdTypes = [
  { id: "Local Company", title: "Local Company" },
  { id: "Foreign Company", title: "Foreign Company" },
  { id: "Other e.g Parastatals", title: "Other e.g Parastatals" },
];

const formFields = ["idType", "partnershipType"];

const SelectIdType = ({ handleNextBtn }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const cookieRole = Cookies.get("role");
  const partners = useSelector((state) => state.partnersSlice);
  const organization =
    useSelector((state) => state.organizationsSlice.organization) || {};
  const { transformedComments } = useSelector((state) => state.commentsSlice);

  const isReadOnly = [
    Constants.COOKIE_ROLES.OFFICIAL,
    Constants.COOKIE_ROLES.CONTACT_PERSON,
  ].includes(cookieRole);

  const {
    idType = "",
    partnershipType = "",
    identificationDetails,
    resolvedFields = [],
  } = organization;

  const idTypeComments = transformedComments?.idType?.idType ?? [];
  const partnershipTypeComments =
    transformedComments?.idType?.partnershipType ?? [];

  return (
    <Formik
      enableReinitialize
      initialValues={{ idType, partnershipType }}
      validationSchema={object().shape({
        idType: string().required("ID type is required"),
        partnershipType: string().required("Partnership type is required"),
      })}
      onSubmit={async (values) => {
        const updatedOfficialData = {};
        formFields.map((formField) => {
          if (!_isEqual(organization[formField], values[formField])) {
            updatedOfficialData[formField] = values[formField];
          }
        });
        if (cookieRole === Constants.COOKIE_ROLES.OFFICIAL) {
          handleNextBtn();
          return;
        }
        if (!_isEmpty(updatedOfficialData)) {
          const data = {
            ...values,
            identificationDetails: {
              ...identificationDetails,
              entityType: values.partnershipType,
            },
          };
          dispatch(updateOrganizationState(data));
          dispatch(updateCorporate({ id: params.corporateId, data }));
        }
        handleNextBtn();
      }}
    >
      {({ values, errors, touched, handleChange }) => {
        return (
          <Form>
            <fieldset disabled={cookieRole === Constants.COOKIE_ROLES.OFFICIAL}>
              <SectionDescription
                id="Step: 1 of 5"
                title="Select ID Type"
                description="This information will be displayed publicly. Please provide correct details."
              />
              {/* Corporates id types */}
              <div className="px-4 space-y-4 py-6 mt-2 bg-white rounded-sm shadow-sm sm:p-6 sm:rounded md:rounded-md">
                <fieldset className="mt-4" disabled={isReadOnly}>
                  <legend className="sr-only">corporates Id types</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {officialIdTypes.map(({ id, title }) => (
                      <div key={id} className="flex items-center">
                        <input
                          id={id}
                          onChange={handleChange}
                          value={title}
                          name="idType"
                          type="radio"
                          checked={title === values.idType}
                          disabled={resolvedFields?.includes("idType")}
                          className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                        />
                        <label
                          htmlFor={id}
                          className="block ml-3 text-sm font-medium text-gray-700"
                        >
                          {title}
                        </label>
                      </div>
                    ))}
                  </div>
                  {idTypeComments.length > 0 && (
                    <CommentsList
                      comments={idTypeComments}
                      color="text-red-500"
                    />
                  )}
                  <ValidationError
                    errors={errors}
                    touched={touched}
                    name="idType"
                  />
                </fieldset>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <Label htmlFor="partnershipType">Partnership Type</Label>
                    <Field
                      name="partnershipType"
                      options={Constants.PARTNERSHIP_OPTIONS}
                      component={ReactSelectWithFormik}
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      id="partnershipType"
                      type="text"
                      placeholder="Select Partnership"
                      value={values?.partnershipType ?? ""}
                      isDisabled={partners.length > 0}
                      comments={partnershipTypeComments}
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="py-3 text-right">
              <Button type="submit">
                {isReadOnly ? "Next step" : "Save and proceed"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SelectIdType;
