import beneficialOwnershipValidation from './beneficialOwnershipValidation'
import identificationValidation from 'components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/identificationValidation'
import residentialDetailsValidation from 'components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/residentialDetailsValidation'
import uploadDocumentsValidation from 'components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/uploadDocumentsValidation'

/**
 * This returns an object composed of conditional `yup` schema,
 * based on the current tab of `Shareholder` and `Director Shareholder` officials.
 * 
 * @param {string} selectedTab The current tab/step of the multi step form e.g `"generalInformation"`.
 * @param {string} idType The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {string} isNonResident Conditional string value `'No' || 'Yes` of the current official.
 * @param {array} documentData The array of objects related to file uploads.
 * @param {object} beneficialOwnershipForm - Object containing fields related to BO form.
 * @param {Boolean} renderLongForm Render long form `true` | `false`.
 * @return {object} This returns object containing schema.
 * @example
 *  {
 *    schema // yup schema
 *  }
 */
const getValidationSchema = ({
  selectedTab,
  idType,
  isNonResident = null,
  documentData,
  beneficialOwnershipForm,
  renderLongForm
}) => {

  switch (selectedTab?.id) {
    case 'beneficialOwnershipAllocation':
      return beneficialOwnershipValidation(idType, beneficialOwnershipForm, renderLongForm)
    case 'generalInformation':
      return identificationValidation(idType);
    case 'residentialDetails':
      return residentialDetailsValidation({ idType, isNonResident });
    case 'uploadDocuments':
      return uploadDocumentsValidation(documentData);
    default:
      return { schema: null };
  }
}

export default getValidationSchema
