import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ReactTable from "components/lib/Shared/ReactTable";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { getCorporatesForDashboard } from "state/slices/corporatesForDashboard";
import { getCompanyNameWithSuffix } from "utils/company";
import SearchInput from "components/lib/Shared/SearchInput";
import { useDebounce } from "use-debounce";

const TransactionsList = ({ goToBusinessDetails = () => null }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);

  const { company } = useSelector((state) => state.companyDashboardSlice);
  const { corporates, getCorporatesForDashboardStatus } = useSelector(
    (state) => state.corporatesForDashboardSlice
  );

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  const columns = [
    {
      Header: "Details",
      accessor: "details",
      Cell: ({ row }) => getCompanyNameWithSuffix(row.original),
    },
    {
      Header: "Application Type",
      accessor: "applicationType",
      Cell: ({ row }) => {
        return (
          <div className="inline-flex items-center justify-center w-1/2 px-3 py-1 mx-auto text-sm font-medium text-white rounded-full bg-green-primary">
            {row?.original?.type ?? "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Individual Officials",
      Cell: ({ row }) => row.original?.individualOfficials?.length ?? 0,
    },
    {
      Header: "Corporate Officials",
      Cell: ({ row }) => row.original?.corporateOfficials?.length ?? 0,
    },
    {
      Header: "Status",
      Cell: ({ row }) => row.original?.status ?? "N/A",
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return row.original?.createdAt
          ? format(new Date(row.original?.createdAt), "yyyy/MM/dd")
          : "N/A";
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        if (row.original.type) {
          return (
            <Link
              to={`/companies/${row.original._id}/dashboard`}
              onClick={goToBusinessDetails}
            >
              View
            </Link>
          );
        } else {
          return (
            <Link
              to={`/corporates/${row.original._id}/dashboard`}
              onClick={goToBusinessDetails}
            >
              View
            </Link>
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (debouncedSearchTerm) setCurrentPage(1);
    if (company?._id) {
      dispatch(
        getCorporatesForDashboard({
          companyRegNumber: company.registrationNumber,
          companyDashboard: true,
          limit: rowsPerPage,
          skip: currentPage - 1,
          search: debouncedSearchTerm,
          order: "asc",
        })
      );
    }
  }, [dispatch, currentPage, debouncedSearchTerm, company?._id, rowsPerPage]);

  if (getCorporatesForDashboardStatus.status === "loading") {
    return (
      <div className="flex justify-center mx-auto my-2 ">
        <SpinnerIcon className="text-gray-400" />
      </div>
    );
  }

  const allCompanies = [...(corporates?.items ?? [])];

  return (
    <>
      {allCompanies?.length > 0 ? (
        <div>
          <div className="flex items-center justify-between my-2">
            <div className="flex items-center space-x-4">
              <h2 className="font-semibold">Filter by:</h2>
            </div>
            <SearchInput value={searchTerm} handleOnChange={handleSearch} />
          </div>
          <div className="mt-6 w-full bg-white shadow">
            <ReactTable
              columns={columns}
              data={allCompanies}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={corporates?.totalCount}
              setCurrentPage={setCurrentPage}
              paginate
              headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
              headingStyle="pl-4 text-left"
              dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
              dataRowCellStyle="pl-4 leading-snug py-2"
              isLoading={getCorporatesForDashboardStatus.status === "isLoading"}
            />
          </div>
        </div>
      ) : (
        <div className="text-center">
          <svg
            className="w-12 h-12 mx-auto text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No companies
          </h3>
        </div>
      )}
    </>
  );
};

export default TransactionsList;
