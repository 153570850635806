import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import _get from "lodash/get";
import { Formik, Field } from "formik";
import DatePicker from "react-datepicker"; // Import DatePicker from react-datepicker

import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import Tabs from "components/lib/Shared/Tabs";
import AccessRequestButton from "components/lib/Shared/CompanyDashboard/AccessRequestButton";
import RequestConfirmationModal from "components/lib/Shared/CompanyDashboard/RequestConfirmationModal";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { RESIGNATION_OFFICIAL } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";

import { useToast } from "hooks/useToast";

const MODAL_NAME = "initiate_request_confirmation_modal_company_dashboard";
const initialTabs = [
  {
    id: "generalInformation",
    label: "General information",
  },
  {
    id: "uploadDocuments",
    label: "Upload Documents",
  },
];

const CessationSubRowForDelete = () => {
  const { toast } = useToast(5000);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(initialTabs[0]);
  const dispatch = useDispatch();

  const goToPrevStep = () => {
    if (selectedTabIndex === 0) return;
    setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  useEffect(() => {
    const tempSelectedTab = initialTabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [selectedTabIndex]);

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = initialTabs.length - 1 === selectedTabIndex;
  const documentData = RESIGNATION_OFFICIAL;

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={12} className={`px-9`}>
        <Tabs
          tabs={initialTabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab?.id === "generalInformation" && (
          <div className="grid grid-cols-6 gap-6">
            <div className="py-6 col-span-6 sm:col-span-3 flex flex-col space-y-2">
              <p className="block text-sm font-medium text-gray-700">
                Effective dates
              </p>
              <Field name="cessationDate">
                {({ field, form }) => (
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => form.setFieldValue(field.name, date)}
                    placeholderText="Enter date of cessation"
                    className="border border-gray-300 rounded px-3 py-2 mt-1 focus:outline-none focus:border-blue-500"
                  />
                )}
              </Field>
            </div>
          </div>
        )}
        {selectedTab?.id === "uploadDocuments" && (
          <div className="mt-4">
            <Field
              name="fileUploads"
              component={FileUploadForm}
              documentData={documentData}
              disabled={false}
              isManageCompanyModule
            />
          </div>
        )}
        <div className="flex justify-between">
          {!isFirstStep && (
            <button
              onClick={goToPrevStep}
              className="flex items-start float-right py-2 font-semibold rounded text-primary mt-7"
              type="button"
            >
              <span className="flex items-center mr-4 h-7">
                <ChevronLeftIcon
                  className="flex-shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
              </span>
              <p className="font-semibold">Go back</p>
            </button>
          )}
          {isLastStep ? (
            <button
              type="submit"
              className="flex items-center px-4 py-2 mb-2 font-semibold text-white rounded bg-green-focused mt-7 disabled:cursor-not-allowed"
            >
              Submit
            </button>
          ) : (
            <button
              onClick={goToNextStep}
              className="flex items-start float-right py-2 ml-auto font-semibold rounded text-primary mt-7"
              type="button"
            >
              <p className="font-semibold">Next step </p>
              <span className="flex items-center ml-4 h-7">
                <ChevronRightIcon
                  className="flex-shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
              </span>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default CessationSubRowForDelete;
