import React from 'react'

import DashboardWrapper from 'components/lib/Global/DashboardWrapper'

const SuccessfulSubmission = () => {
    return (
        <DashboardWrapper>
            <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                <div className="mx-auto">
                    <h1 className="text-4xl font-extrabold tracking-tight text-gray-800 sm:text-5xl">Dashboard</h1>
                    <div className="p-4 mt-6 bg-white rounded-sm shadow-sm sm:p-8 sm:rounded md:rounded-md">
                        <h2 className="text-2xl font-medium text-gray-800">Submission successful</h2>
                    </div>
                </div>
            </div>
        </DashboardWrapper>
    )
}

export default SuccessfulSubmission
