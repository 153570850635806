import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilAltIcon } from "@heroicons/react/solid";
import { Popover, Transition } from "@headlessui/react";
import {
  CheckIcon,
  DownloadIcon,
  PencilIcon,
  XIcon,
} from "@heroicons/react/outline";
import { format } from "date-fns";
import _has from "lodash/has";
import { object, number } from "yup";
import { Field, Form, Formik } from "formik";
import { useDebounce } from "use-debounce";

import Button from "components/lib/Shared/Button";
import useFullscreenToggle from "hooks/useFullscreenToggle";
import PaginationControls from "../PaginationControls";
import BreadCrumbs from "../BreadCrumbs";
import SearchInput from "components/lib/Shared/SearchInput";
import Input from "components/lib/Shared/Input";
import Overlay from "components/lib/Shared/Overlay";
import TableHeader from "./TableHeader";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import {
  formatCurrency,
  getEmptyRowsForRegisters,
  getFullName,
  getPoBoxAddress,
} from "utils";
import { Constants } from "config/constants";
import { getPaginationParams } from "utils/registers";
import {
  downloadRegisterOfApplicationsAndAllotmentsAsync,
  getRegisterOfApplicationsAndAllotmentsAsync,
  updateAllotmentsAsync,
} from "state/slices/minuteBook";
import EmptyRow from "./EmptyRow";

const ROW_HEIGHT = 26;
const { APPLICATIONS_AND_ALLOTMENT_ROWS } = Constants.REGISTERS;
const MAX_ALLOTMENTS_SECTION_HEIGHT =
  ROW_HEIGHT * APPLICATIONS_AND_ALLOTMENT_ROWS +
  (APPLICATIONS_AND_ALLOTMENT_ROWS - 2) * 2;

const PopulatedRow = ({
  dateOfApplication,
  shareholder,
  currentAllottedClass,
  allotmentIndex,
  maxAllotmentNumber,
  unpaidClassesOfShares = [],
  allotments = [],
  openedRowIndex,
  setOpenedRowIndex = () => null,
  setAllotments = () => null,
}) => {
  const {
    numberOfAllotmentLetter,
    shareCertificateNumber,
    sharePrice,
    paidUpShares,
    allottedShares,
  } = currentAllottedClass;
  const { company } = useSelector((state) => state.companyDashboardSlice);
  const dispatch = useDispatch();

  const depositAmount = paidUpShares * sharePrice;

  const totalAmountForAllotedShares = allottedShares * sharePrice;

  const furtherAmountPayable = totalAmountForAllotedShares - depositAmount;

  const amountReturnable = depositAmount - totalAmountForAllotedShares;

  const getUpdatedAllotments = (indexToInsertRow, indexToRemoveRow) => {
    const tempAllotments = [...allotments];

    // insert updated allotment into the rows at new position
    tempAllotments.splice(indexToInsertRow, 0, shareholder);

    const distinctiveNumbersAgainstClass = {};
    const shareholderFolios = {};
    let folioCounter = 1;
    tempAllotments.splice(indexToRemoveRow, 1); // remove current allotment which is being updated

    const updatedAllotments = tempAllotments.map((allotment, index) => {
      let shareStart = 0;
      let shareEnd = 0;

      const folioLetter = index % 2 === 0 ? "a" : "b";
      let memberRegisterFolio = "";

      if (shareholderFolios[allotment._id]) {
        memberRegisterFolio = shareholderFolios[allotment._id];
      } else {
        memberRegisterFolio = `${folioCounter}${folioLetter}`;
        shareholderFolios[allotment._id] = memberRegisterFolio;
      }

      const { classOfShares, allottedShares } = allotment.currentAllottedClass;
      const classSharesInfo = distinctiveNumbersAgainstClass[classOfShares];
      if (classSharesInfo?.alreadyAssigned) {
        const alreadyAssignedNumbers =
          classSharesInfo.alreadyAssigned + allottedShares;
        shareStart += classSharesInfo.alreadyAssigned + 1;
        shareEnd += alreadyAssignedNumbers;
        classSharesInfo.alreadyAssigned = alreadyAssignedNumbers;
      } else {
        shareStart = 1;
        shareEnd = allottedShares;
        distinctiveNumbersAgainstClass[classOfShares] = {
          alreadyAssigned: allottedShares,
        };
      }
      if (folioLetter === "b") {
        folioCounter++;
      }
      return {
        ...allotment,
        memberRegisterFolio,
        currentAllottedClass: {
          ...allotment.currentAllottedClass,
          numberOfAllotmentLetter: index + 1,
          shareCertificateNumber: index + 1,
          shareStart,
          shareEnd,
        },
      };
    });
    return updatedAllotments;
  };

  const toggleEditMode = (currentAllottedClass, editMode) => {
    if (editMode) {
      setOpenedRowIndex(currentAllottedClass);
    } else {
      setOpenedRowIndex({});
    }
  };

  const handleUpdateAllotments = async (newAllotmentNumber) => {
    if (newAllotmentNumber === numberOfAllotmentLetter) {
      setOpenedRowIndex({});
      return;
    }
    let updatedAllotments = [];
    if (newAllotmentNumber < numberOfAllotmentLetter) {
      updatedAllotments = getUpdatedAllotments(
        newAllotmentNumber - 1,
        allotmentIndex + 1
      );
    } else {
      updatedAllotments = getUpdatedAllotments(
        newAllotmentNumber,
        allotmentIndex
      );
    }
    const changedAllotmentsStart =
      newAllotmentNumber < allotmentIndex
        ? newAllotmentNumber - 1
        : allotmentIndex;
    const changedAllotmentsEnd =
      newAllotmentNumber < allotmentIndex
        ? allotmentIndex
        : newAllotmentNumber - 1;
    const response = await dispatch(
      updateAllotmentsAsync({
        data: updatedAllotments.slice(
          changedAllotmentsStart,
          changedAllotmentsEnd
        ),
        queryParams: { companyId: company._id },
      })
    );

    if (response.payload.success) {
      setAllotments(updatedAllotments);
    }
    setOpenedRowIndex({});
  };

  return (
    <tr
      key={`${shareholder._id}_${currentAllottedClass._id}`}
      className="text-xs"
    >
      <td className="p-1 text-gray-600 bg-gray-100">{dateOfApplication}</td>
      <td className="p-1 text-gray-600 bg-gray-100 text-left">
        {getFullName(shareholder.names)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100 text-left">
        {getPoBoxAddress(
          shareholder.idType,
          shareholder.postalAddress,
          shareholder.isNonResident
        )}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {currentAllottedClass.classOfShares}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {currentAllottedClass.allottedShares}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {formatCurrency(depositAmount)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {unpaidClassesOfShares.includes(currentAllottedClass.classOfShares)
          ? currentAllottedClass.shareStart
          : "-"}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {unpaidClassesOfShares.includes(currentAllottedClass.classOfShares)
          ? currentAllottedClass.shareEnd
          : "-"}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {formatCurrency(totalAmountForAllotedShares)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {formatCurrency(furtherAmountPayable)}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {amountReturnable > 0 ? formatCurrency(amountReturnable) : "0.00"}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {shareholder?.appointmentDate
          ? format(new Date(shareholder.appointmentDate), "dd-MM-yyyy")
          : ""}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {openedRowIndex._id === currentAllottedClass._id ? (
          <Formik
            enableReinitialize
            initialValues={{
              numberOfAllotmentLetter: numberOfAllotmentLetter,
            }}
            validationSchema={object().shape({
              numberOfAllotmentLetter: number()
                .min(1, "Allotment number cannot be less than 1")
                .max(
                  maxAllotmentNumber,
                  `Allotment number cannot be more than ${maxAllotmentNumber}`
                )
                .required("Allotment number is required"),
            })}
            onSubmit={(values) => {
              handleUpdateAllotments(values.numberOfAllotmentLetter);
            }}
          >
            {({ errors, handleSubmit }) => (
              <Form className="flex px-1 relative">
                <Field
                  component={Input}
                  name="numberOfAllotmentLetter"
                  className={`[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none py-px ${
                    errors.numberOfAllotmentLetter
                      ? "border border-red-500"
                      : ""
                  }`}
                  type="number"
                  step={1}
                  min={1}
                  max={maxAllotmentNumber}
                  renderError={false}
                />
                <Popover>
                  <Transition
                    as={Fragment}
                    show={Boolean(errors.numberOfAllotmentLetter)}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      static
                      className="absolute right-[7rem] z-10 mt-1"
                    >
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                        <p className="bg-white px-2 py-1 text-sm font-medium text-red-500 w-max">
                          {errors.numberOfAllotmentLetter}
                        </p>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
                <div class="flex items-center">
                  <div className="flex space-x-1">
                    <Button
                      variant="outline"
                      overrideBaseStyles
                      onClick={() =>
                        toggleEditMode(currentAllottedClass, false)
                      }
                    >
                      <XIcon className="w-5 h-5 text-red-500" />
                    </Button>
                    <Button
                      variant="outline"
                      overrideBaseStyles
                      onClick={handleSubmit}
                    >
                      <CheckIcon className="w-5 h-5 text-green-500" />
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <div className="flex justify-center space-x-4">
            <span>{numberOfAllotmentLetter}</span>
            <PencilIcon
              className="w-4 h-4 text-sflPrimary"
              onClick={() => toggleEditMode(currentAllottedClass, true)}
            />
          </div>
        )}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {shareholder.memberRegisterFolio}
      </td>
      <td className="p-1 text-gray-600 bg-gray-100">
        {shareCertificateNumber}
      </td>
    </tr>
  );
};

const getPopulatedRowNode = (
  dateOfApplication,
  shareholder,
  unpaidClassesOfShares = []
) => {
  const { currentAllottedClass } = shareholder;
  const depositAmount =
    currentAllottedClass.paidUpShares * currentAllottedClass.sharePrice;

  const totalAmountForAllotedShares =
    currentAllottedClass.allottedShares * currentAllottedClass.sharePrice;

  const furtherAmountPayable = totalAmountForAllotedShares - depositAmount;

  const amountReturnable = depositAmount - totalAmountForAllotedShares;

  const currentTableRowNode = document.createElement("tr");
  currentTableRowNode.setAttribute("class", "text-xs");
  currentTableRowNode.innerHTML = `<td class="p-1 text-gray-600 bg-gray-100">
      ${dateOfApplication}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100 text-left">
    ${getFullName(shareholder.names)}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100 text-left">
    ${getPoBoxAddress(
      shareholder.idType,
      shareholder.postalAddress,
      shareholder.isNonResident
    )}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
      ${currentAllottedClass.classOfShares}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${currentAllottedClass.allottedShares}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${depositAmount}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${
      unpaidClassesOfShares.includes(currentAllottedClass.classOfShares)
        ? currentAllottedClass.shareStart
        : "-"
    }
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${
      unpaidClassesOfShares.includes(currentAllottedClass.classOfShares)
        ? currentAllottedClass.shareEnd
        : "-"
    }
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${totalAmountForAllotedShares}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${furtherAmountPayable}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${amountReturnable > 0 ? amountReturnable : 0}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${
      shareholder?.appointmentDate
        ? format(new Date(shareholder.appointmentDate), "dd-MM-yyyy")
        : ""
    }
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${currentAllottedClass.numberOfAllotmentLetter}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${shareholder.memberRegisterFolio}
    </td>
    <td class="p-1 text-gray-600 bg-gray-100">
    ${currentAllottedClass.shareCertificateNumber}
    </td>
  </tr>`;
  return currentTableRowNode;
};

function RegisterOfApplicationsAndAllotments({ selectedTab }) {
  const { isFullscreen, ToggleFullscreenContainer, ToggleFullscreenButton } =
    useFullscreenToggle();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [openedRowIndex, setOpenedRowIndex] = useState({});
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const dispatch = useDispatch();
  const allotmentTableBodyRef = useRef(null);
  const hiddenTempTableBodyRef = document.getElementById(
    "tempTableForRegisters"
  );
  const [paginatedAllotments, setPaginatedAllotments] = useState([]);
  const [emptyRowsCount, setEmptyRowsCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState({});
  const [lastPageWithData, setLastPageWithData] = useState(1);
  const [allotments, setAllotments] = useState([]);
  const [applicationsAndAllotments, setApplicationsAndAllotments] = useState({
    items: [],
    totalCount: 0,
  });

  const { company } = useSelector((state) => state.companyDashboardSlice);
  const {
    registerOfApplicationsAndAllotments,
    getRegisterOfApplicationsAndAllotments,
    downloadRegisterOfApplicationsAndAllotments,
    updateAllotments,
  } = useSelector((state) => state.minuteBookSlice);

  const { unpaidClassesOfShares } = registerOfApplicationsAndAllotments;

  useEffect(() => {
    if (company._id) {
      dispatch(
        getRegisterOfApplicationsAndAllotmentsAsync({
          queryParams: { companyId: company._id },
        })
      );
    }
  }, [company._id, registerOfApplicationsAndAllotments.totalCount]);

  useEffect(() => {
    setAllotments(registerOfApplicationsAndAllotments.items);
  }, [registerOfApplicationsAndAllotments.items]);

  useEffect(() => {
    let tempApplicationsAndAllotments = allotments;
    const searchQuery = debouncedSearchTerm.toLowerCase();
    if (searchQuery) {
      tempApplicationsAndAllotments = tempApplicationsAndAllotments.filter(
        (official) =>
          getFullName(official.names).toLowerCase().includes(searchQuery)
      );
    }
    setApplicationsAndAllotments({
      items: tempApplicationsAndAllotments,
      totalCount: tempApplicationsAndAllotments.length,
    });
  }, [allotments, debouncedSearchTerm]);

  useEffect(() => {
    const getEmptyRowsCount = (currentPage, maxSectionHeight) => {
      let tempEmptyRowsCount = 0;
      const tableBody = document.querySelector(
        `[data-table-index="${currentPage}"]`
      );
      if (!tableBody) return tempEmptyRowsCount;
      while (
        allotmentTableBodyRef.current &&
        tableBody.clientHeight < maxSectionHeight &&
        maxSectionHeight - tableBody.clientHeight > ROW_HEIGHT &&
        tempEmptyRowsCount < APPLICATIONS_AND_ALLOTMENT_ROWS
      ) {
        const emptyTableRow = document.createElement("tr");
        emptyTableRow.setAttribute("class", "text-xs h-6");
        emptyTableRow.innerHTML = `
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100 text-left"></td>
          <td class="p-1 text-gray-600 bg-gray-100 text-left"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
          <td class="p-1 text-gray-600 bg-gray-100"></td>
        `;
        tableBody.appendChild(emptyTableRow);
        tempEmptyRowsCount++;
      }
      return tempEmptyRowsCount;
    };

    const calculateRowsPerPage = () => {
      if (!hiddenTempTableBodyRef || !allotmentTableBodyRef.current) return;

      hiddenTempTableBodyRef.innerHTML = "";

      const { rowsPerPage, lastPage } = getPaginationParams(
        applicationsAndAllotments.items,
        MAX_ALLOTMENTS_SECTION_HEIGHT,
        allotmentTableBodyRef,
        (allotment) =>
          getPopulatedRowNode(
            company.registrationDate
              ? format(new Date(company.registrationDate), "dd-MM-yyyy")
              : "",
            allotment,
            unpaidClassesOfShares
          )
      );

      setRowsPerPage(rowsPerPage);
      setLastPageWithData(lastPage);
      setEmptyRowsCount(
        lastPage
          ? getEmptyRowsCount(lastPage, MAX_ALLOTMENTS_SECTION_HEIGHT)
          : 0
      );
    };

    calculateRowsPerPage();

    window.addEventListener("resize", calculateRowsPerPage);

    return () => {
      window.removeEventListener("resize", calculateRowsPerPage);
    };
  }, [applicationsAndAllotments.items, isFullscreen]);

  useEffect(() => {
    if (
      applicationsAndAllotments.totalCount &&
      _has(rowsPerPage[currentPage], "start")
    ) {
      setPaginatedAllotments(
        applicationsAndAllotments.items.slice(
          rowsPerPage[currentPage].start,
          rowsPerPage[currentPage].end
        )
      );
    } else {
      setPaginatedAllotments([]);
    }
  }, [currentPage, applicationsAndAllotments.items, rowsPerPage]);

  if (getRegisterOfApplicationsAndAllotments.status === "loading") {
    return (
      <div className="col-span-6">
        <div className="flex justify-center mx-auto text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </div>
    );
  }

  const handleDownloadClick = () => {
    dispatch(
      downloadRegisterOfApplicationsAndAllotmentsAsync({
        queryParams: { companyId: company._id, fileFormat: "xlsx" },
      })
    );
  };

  const isUpdatingAllotments = updateAllotments.status === "loading";

  return (
    <div className="overflow-auto">
      <div className="flex justify-between w-full mb-4">
        <BreadCrumbs breadCrumbs={selectedTab.breadCrumbs} />
        <Button
          onClick={handleDownloadClick}
          preIcon={DownloadIcon}
          isLoading={
            downloadRegisterOfApplicationsAndAllotments.status === "loading"
          }
          loadingText="Downloading..."
        >
          Download
        </Button>
      </div>
      <ToggleFullscreenContainer>
        <ToggleFullscreenButton />
        <div
          className={`bg-white relative ${
            isFullscreen ? "h-[88vh] overflow-auto" : ""
          }`}
        >
          <div
            className={`overflow-auto px-1 pt-4 pb-20 space-y-2 flex flex-col text-center ${
              isFullscreen ? "px-12" : ""
            }`}
          >
            <div className="relative flex flex-col items-center">
              <div>
                <h2 className="text-title6 font-medium">
                  REGISTER OF APPLICATIONS AND ALLOTMENTS
                </h2>
                <div className="flex items-center justify-center">
                  <span className="mr-2 text-sm">
                    as at {format(new Date(), "dd-MM-yyyy")}
                  </span>
                  <PencilAltIcon className="w-4 h-4" />
                </div>
                {isFullscreen && (
                  <SearchInput
                    value={searchTerm}
                    placeholder="Search register"
                    className="lg:absolute w-64 top-0 right-0 mt-2"
                    handleOnChange={(event) =>
                      setSearchTerm(event.target.value)
                    }
                  />
                )}
              </div>
              <div className="mt-4">
                <table className="min-w-full divide-y divide-gray-300 border-separate border-spacing-1 table-auto">
                  <TableHeader />
                  <tbody
                    className="divide-y divide-gray-200"
                    ref={allotmentTableBodyRef}
                    style={{ height: `${MAX_ALLOTMENTS_SECTION_HEIGHT}px` }}
                  >
                    {isUpdatingAllotments && <Overlay />}
                    {paginatedAllotments.map((shareholder, index) => {
                      const { currentAllottedClass } = shareholder;
                      return (
                        <PopulatedRow
                          key={`${shareholder._id}_${currentAllottedClass._id} `}
                          dateOfApplication={
                            company.registrationDate
                              ? format(
                                  new Date(company.registrationDate),
                                  "dd-MM-yyyy"
                                )
                              : ""
                          }
                          shareholder={shareholder}
                          currentAllottedClass={currentAllottedClass}
                          unpaidClassesOfShares={unpaidClassesOfShares}
                          allotmentIndex={
                            index +
                            (currentPage > 1
                              ? rowsPerPage[currentPage - 1].end
                              : 0)
                          }
                          maxAllotmentNumber={
                            registerOfApplicationsAndAllotments.items.length
                          }
                          allotments={allotments}
                          openedRowIndex={openedRowIndex}
                          setOpenedRowIndex={setOpenedRowIndex}
                          setAllotments={setAllotments}
                        />
                      );
                    })}
                    {currentPage === lastPageWithData && emptyRowsCount
                      ? Array.from({
                          length: emptyRowsCount,
                        }).map((_, emptyRowIndex) => (
                          <EmptyRow key={`empty_row_${emptyRowIndex} `} />
                        ))
                      : null}
                    {currentPage > lastPageWithData
                      ? getEmptyRowsForRegisters(
                          0,
                          APPLICATIONS_AND_ALLOTMENT_ROWS
                        ).map((_, emptyRowIndex) => (
                          <EmptyRow key={`empty_row_${emptyRowIndex} `} />
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <PaginationControls
            isFullscreen={isFullscreen}
            isLastPage={currentPage === lastPageWithData}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage[currentPage] || 0}
            totalRows={applicationsAndAllotments.totalCount}
            totalPages={Object.keys(rowsPerPage).length}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </ToggleFullscreenContainer>
    </div>
  );
}

export default RegisterOfApplicationsAndAllotments;
