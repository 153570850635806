import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from "hooks/useToast";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import ApplicationReviewTab from "components/PLCTABS/ApplicationReviewTab";
import { getComments, resetCommentsSlice } from "state/slices/comment";
import { getCompany, reset } from "state/slices/company";

function SubRowAsync({ visibleColumns, row, refreshTableData }) {
  const dispatch = useDispatch();
  const { toast } = useToast(5000);

  const { company, getCompanyStatus } = useSelector(
    (state) => state.companySlice
  );

  useEffect(() => {
    if (row?.original?._id) {
      dispatch(getCompany({ id: row.original._id }));
    }
    return () => {
      dispatch(reset());
      dispatch(resetCommentsSlice());
    };
  }, [row?.original?._id]);

  useEffect(() => {
    if (
      !["Pending Incorporation", "Vetting", "Active"].includes(
        company?.status
      ) &&
      company?.status
    ) {
      dispatch(getComments({ companyId: row.original._id }));
    }
  }, [company?.status]);

  useEffect(() => {
    if (getCompanyStatus.status === "failed" && getCompanyStatus.error) {
      toast("error", getCompanyStatus.error);
      row.toggleRowExpanded();
    }
  }, [getCompanyStatus.status, getCompanyStatus.error]);

  if (!company?._id || getCompanyStatus.status === "loading") {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto my-4">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  const handleBackBtn = () => {
    row.toggleRowExpanded();
    refreshTableData();
  };

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="p-8 max-w-lg">
        <ApplicationReviewTab handleBackBtn={handleBackBtn} />
      </td>
    </tr>
  );
}

export default SubRowAsync;
