import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import Profile from "components/UserOnboarding/Profile";
import AccountSettings from "components/AccountSettings";
import { updateUser } from "state/slices/user";
import useCounter from "hooks/useCounter";
import { getProfileComments } from "state/slices/comment";
import Cookies from "js-cookie";
import { Constants } from "config/constants";
import InvoicesTable from "components/OrgAndSuperAdminDashboard/Settings/Management/Invoices";

const navigation = [
  { name: "User profile", href: "/profile" },
  { name: "Settings" },
];
const managementNav = [
  { name: "Access Control", href: "/account-settings" },
  { name: "Billing", href: "/billing" },
];
const auditNav = [
  { name: "Notifications" },
  { name: "Transactions" },
  { name: "Audit Trail" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SuccessfulApproved() {
  const [counter] = useCounter();
  const history = useHistory();

  useEffect(async () => {
    if (counter === 0) {
      history.push("/");
    }
  }, [counter]);

  return <p>Redirecting to the dashboard in {counter} seconds</p>;
}

export default function ProfileComponent() {
  const role = Cookies.get("role");
  const [isApproving, setIsApproving] = useState(false);
  const [approveBtnLabel, setApproveBtnLabel] = useState("Approve profile");
  const { getUserStatus } = useSelector((state) => state.authSlice);
  const currentUser = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentPathname = history.location.pathname;

  useEffect(() => {
    if (currentUser?._id) {
      dispatch(getProfileComments({ userId: currentUser?._id }));
    }
  }, [currentUser?._id]);

  const handleApproveProfileData = async () => {
    try {
      setIsApproving(true);
      await dispatch(
        updateUser({
          id: currentUser._id,
          data: { isProfileApprovedByUser: true },
        })
      );
      setApproveBtnLabel("Approved");
    } catch (error) {
      console.log(error);
    } finally {
      setIsApproving(false);
    }
  };

  if (getUserStatus.status === "loading" || !currentUser?._id) {
    return (
      <DashboardWrapper>
        <div className="flex justify-center mx-auto my-2 ">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper
      showSystemAdminBar={role === Constants.COOKIE_ROLES.SYSTEM_ADMIN}
    >
      <div className="my-16">
        <div className="flex items-center justify-between">
          <div className="flex-1 my-10">
            <h1 className="my-4 text-2xl font-bold leading-7 sm:text-3xl">
              Account Settings
            </h1>
          </div>
          <div className="flex flex-col justify-end px-6">
            {currentUser?.isOnboarded &&
              !currentUser.isProfileApprovedByUser && (
                <button
                  disabled={isApproving || approveBtnLabel === "Approved"}
                  onClick={handleApproveProfileData}
                  className="inline-flex items-center justify-center h-8 px-4 py-2 ml-auto text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-sflPrimary hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:cursor-not-allowed"
                >
                  {isApproving ? (
                    <>
                      <SpinnerIcon />
                      Approving...
                    </>
                  ) : (
                    approveBtnLabel
                  )}
                </button>
              )}
            {approveBtnLabel === "Approved" && <SuccessfulApproved />}
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="px-2 py-6 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav className="flex flex-col space-y-10 ">
              <div className="">
                <h2>General</h2>
                {navigation.map((item, index) => (
                  <button
                    key={`${item.name}_${index}`}
                    className={classNames(
                      item.href === currentPathname
                        ? "bg-gray-50  hover:text-indigo-700 hover:bg-white border-sflPrimary"
                        : "rounded-md px-3 py-2 flex items-center text-gray-400 text-base font-medium disabled:cursor-not-allowed",
                      "flex items-center px-3 py-2 text-base font-medium hover:text-gray-900 hover:bg-gray-50"
                    )}
                    disabled={role === Constants.COOKIE_ROLES.SYSTEM_ADMIN}
                    onClick={() =>
                      item?.href ? history.push(item.href) : null
                    }
                  >
                    <span className="truncate">{item.name}</span>
                  </button>
                ))}
              </div>

              <div className="">
                <h2>Management</h2>
                {managementNav.map((item, index) => (
                  <button
                    key={`${item.name}_${index}`}
                    className={classNames(
                      item.href === currentPathname
                        ? "bg-gray-50  hover:text-indigo-700 hover:bg-white border-r-2 border-sflPrimary"
                        : "rounded-md px-3 py-2 flex items-center text-gray-400 text-base font-medium disabled:cursor-not-allowed",
                      "flex items-center px-3 py-2 text-base font-medium hover:text-gray-900 hover:bg-gray-50"
                    )}
                    disabled={role === Constants.COOKIE_ROLES.SYSTEM_ADMIN}
                    onClick={() =>
                      item?.href ? history.push(item.href) : null
                    }
                  >
                    <span className="truncate">{item.name}</span>
                  </button>
                ))}
              </div>
              <div className="">
                <h2>Audit</h2>
                {auditNav.map((item, index) => (
                  <button
                    key={`${item.name}_${index}`}
                    className={classNames(
                      item.href === currentPathname
                        ? "bg-gray-50  hover:text-indigo-700 hover:bg-white border-r-2 border-sflPrimary"
                        : "rounded-md px-3 py-2 flex items-center text-gray-400 text-base font-medium disabled:cursor-not-allowed",
                      "flex items-center px-3 py-2 text-base font-medium hover:text-gray-900 hover:bg-gray-50"
                    )}
                    disabled={role === Constants.COOKIE_ROLES.SYSTEM_ADMIN}
                    onClick={() =>
                      item?.href ? history.push(item.href) : null
                    }
                  >
                    <span className="truncate">{item.name}</span>
                  </button>
                ))}
              </div>
            </nav>
          </aside>

          <div className="px-2 space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            {currentPathname === "/profile" && <Profile />}
            {currentPathname === "/account-settings" && <AccountSettings />}
            {currentPathname === "/billing" && <InvoicesTable onlySelf />}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
