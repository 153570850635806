import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import templateService from "services/template";

export const getTemplatesAsync = createAsyncThunk(
  "templates/getTemplatesAsync",
  async (queryParams) => {
    const res = await templateService.getAll(queryParams);
    return res.data;
  }
);

export const addTemplateAsync = createAsyncThunk(
  "templates/addTemplateAsync",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await templateService.create(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editTemplateAsync = createAsyncThunk(
  "templates/editTemplateAsync",
  async ({ templateId, data }) => {
    const res = await templateService.update(templateId, data);
    return res.data;
  }
);

export const deleteTemplateAsync = createAsyncThunk(
  "templates/deleteTemplateAsync",
  async ({ templateId }, { rejectWithValue }) => {
    try {
      await templateService.remove(templateId);
      return { templateId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTemplateForApplicantAsync = createAsyncThunk(
  "templates/getTemplateForApplicantAsync",
  async (queryParams) => {
    const res = await templateService.getAll(queryParams);
    return res.data;
  }
);

export const generateTokenAsync = createAsyncThunk(
  "templates/generateTokenAsync",
  async ({ data }) => {
    const res = await templateService.generateToken(data);
    return res.data;
  }
);

const initialState = {
  templates: {
    items: [],
    totalCount: 0,
  },
  selectedTemplate: {},
  selectedVersion: {},
  mode: "",
  getTemplates: {
    status: "idle",
    error: false,
  },
  updatingTemplate: {
    status: "idle",
    error: false,
  },
  getTemplateForApplicant: {
    status: "idle",
    error: false,
  },
  showOverlay: false,
};

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    updateTemplateState: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetTemplatesSlice: () => initialState,
  },
  extraReducers: {
    [editTemplateAsync.pending]: (state) => {
      state.updatingTemplate.status = "loading";
      state.updatingTemplate.error = false;
    },
    [editTemplateAsync.fulfilled]: (state, action) => {
      state.updatingTemplate.status = "loading";
      state.updatingTemplate.error = false;
      state.selectedTemplate = action.payload;
    },
    [editTemplateAsync.rejected]: (state, action) => {
      state.updatingTemplate.status = "failed";
      state.updatingTemplate.error = action?.payload?.messageText;
    },

    [getTemplatesAsync.pending]: (state) => {
      state.getTemplates.status = "loading";
      state.getTemplates.error = false;
    },
    [getTemplatesAsync.fulfilled]: (state, action) => {
      state.getTemplates.status = "idle";
      state.templates = action.payload;
    },
    [getTemplatesAsync.rejected]: (state, action) => {
      state.getTemplates.status = "failed";
      state.getTemplates.error = action?.payload?.messageText;
    },

    [addTemplateAsync.fulfilled]: (state, action) => {
      state.selectedTemplate = action.payload.template;
    },

    [getTemplateForApplicantAsync.pending]: (state) => {
      state.getTemplateForApplicant.status = "loading";
      state.getTemplateForApplicant.error = false;
    },
    [getTemplateForApplicantAsync.fulfilled]: (state, action) => {
      state.getTemplateForApplicant.status = "idle";
      state.selectedTemplate = action.payload.items[0];
    },
    [getTemplateForApplicantAsync.rejected]: (state) => {
      state.getTemplateForApplicant.status = "failed";
      state.getTemplateForApplicant.error = false;
    },
  },
});

export const { updateTemplateState, resetTemplatesSlice } =
  templatesSlice.actions;

export default templatesSlice.reducer;
