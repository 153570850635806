import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _omit from "lodash/omit";
import _get from "lodash/get";
import { Formik, Field } from "formik";

import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import Tabs from "components/lib/Shared/Tabs";
import CommentsList from "components/lib/Global/CommentsList";
import { addComment, editComment } from "state/slices/comment";
import { sendCorrectionNotification } from "state/slices/user";
import { useToast } from "hooks/useToast";
import ProfessionalDetails from "./ProfessionalDetails";
import GeneralInformation from "./GeneralInformation";
import ResidentialDetails from "./ResidentialDetails";
import ActionsButtons from "./ActionsButtons";
import { updateUserAsync } from "state/slices/orgAdmin";

export default function SubRowAsync({ visibleColumns, row, refreshTableData }) {
  const { user, getUser } = useSelector((state) => state.orgAdminSlice);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  const { toast } = useToast(5000);
  const professionalDetails = useSelector(
    (state) => state.professionalDetailsSlice
  );
  const { items: comments } = useSelector(
    (state) => state.commentsSlice.profileComments
  );
  const unresolvedComments = comments.filter((comment) => !comment.isResolved);

  const generalInformationComments = comments.filter(
    (comment) =>
      comment.formName === "identificationDetails" ||
      comment.formName === "names"
  );
  const residentialDetailsComments = comments.filter(
    (comment) =>
      comment.formName === "postalAddress" ||
      comment.formName === "electronicAddress" ||
      comment.formName === "residentialAddressDetails"
  );
  const fileUploadComments = comments.filter(
    (comment) => comment.formName === "uploadDocuments"
  );

  const tabs = useMemo(() => {
    let tempTabs = [
      {
        id: "generalInformation",
        label: "General information",
        commentsCount: generalInformationComments?.length,
      },
      {
        id: "residentialDetails",
        label: "Address details",
        commentsCount: residentialDetailsComments?.length,
      },
      {
        id: "uploadDocuments",
        label: "File Uploads",
        commentsCount: fileUploadComments?.length,
      },
      professionalDetails.totalCount > 0
        ? {
            id: "professionalDetails",
            label: "Professional Details",
            commentsCount: fileUploadComments?.length,
          }
        : null,
    ].filter(Boolean);
    return tempTabs;
  }, [comments, professionalDetails.totalCount]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  if (getUser.status === "loading") {
    return (
      <tr className="bg-[#EDF0F5]">
        <td colSpan={visibleColumns.length} className="px-9">
          <div className="flex justify-center mx-auto m-2">
            <SpinnerIcon className="text-gray-400" />
          </div>
        </td>
      </tr>
    );
  }

  if (user?._id) {
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        Cannot fetch user
      </td>
    </tr>;
  }

  const goToPrevStep = () => {
    if (selectedTabIndex === 0 && handleBackBtn) handleBackBtn();
    else setSelectedTabIndex(selectedTabIndex - 1);
  };

  const goToNextStep = () => {
    setSelectedTabIndex(selectedTabIndex + 1);
  };

  const handleSaveComment = async (data) => {
    const commentData = _omit(data, ["companyId", "officialId"]);
    if (commentData?.commentId) {
      await dispatch(
        editComment({
          commentId: commentData.commentId,
          data: commentData,
          isProfileReview: true,
        })
      );
    } else {
      await dispatch(
        addComment({
          data: {
            ...commentData,
            user: user._id,
          },
          isProfileReview: true,
        })
      );
    }
  };

  const isFirstStep = selectedTabIndex === 0;
  const isLastStep = tabs.length - 1 === selectedTabIndex;

  const handleVerifyProfile = async (row) => {
    if (user?.status === "Verified") {
      row.toggleRowExpanded();
      return;
    }
    try {
      setIsLoading(true);
      await dispatch(
        updateUserAsync({ userId: user._id, data: { status: "Verified" } })
      );
      await refreshTableData();
    } catch (error) {
    } finally {
      setIsLoading(false);
      row.toggleRowExpanded();
    }
  };

  const handleSendNotification = async (row) => {
    try {
      setIsLoading(true);
      await Promise.all([
        dispatch(
          sendCorrectionNotification({
            data: {
              userEmail: user.electronicAddress.emailAddress,
              userName: user.names.firstName,
              reviewerName: currentUser.names.firstName,
              companyName: "profile data",
              unresolvedCommentsCount: unresolvedComments.length,
            },
          })
        ),
        dispatch(
          updateUserAsync({
            userId: user._id,
            data: { status: "Pending Correction" },
          })
        ),
      ]);
      toast("success", "Notification sent successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
      row.toggleRowExpanded();
    }
  };

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
        {selectedTab.id === "generalInformation" && (
          <GeneralInformation
            handleSaveComment={handleSaveComment}
            generalInformationComments={generalInformationComments}
            addReviewComment
            toggleCommentStatus
            isProfileReview
          />
        )}
        {selectedTab.id === "residentialDetails" && (
          <ResidentialDetails
            handleSaveComment={handleSaveComment}
            residentialDetailsComments={residentialDetailsComments}
            addReviewComment
            toggleCommentStatus
            isProfileReview
          />
        )}
        {selectedTab.id === "uploadDocuments" && (
          <>
            {fileUploadComments && fileUploadComments.length > 0 && (
              <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                <CommentsList
                  comments={fileUploadComments}
                  toggleCommentStatus
                  isProfileReview
                />
                <p className="mt-6 text-sm text-primary">
                  Comments: {fileUploadComments.length} of{" "}
                  {fileUploadComments.length}
                </p>
              </div>
            )}
            <div className="px-8 pt-5 pb-6 mt-6 bg-white">
              <h3 className="mb-2 text-lg">Uploaded incorporation forms</h3>
              <Formik
                enableReinitialize
                initialValues={{
                  fileUploads: user.fileUploads,
                }}
                onSubmit={() => null}
              >
                {() => {
                  return (
                    <div className="pl-4">
                      <Field
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={getDocumentData(user.idType)}
                        resolvedFields={_get(user, "resolvedFields")}
                        onlyPreview
                        addReviewComment
                        handleSaveComment={handleSaveComment}
                        formName="uploadDocuments"
                        formHelperKey={"user"}
                        updateEffectKey="updateUserStateForOrgAdmin"
                        updateEffectPath="orgAdminSlice.user"
                        formId={_get(user, "_id")}
                        resolveAllFieldsCheckbox
                        fileUploadComments={fileUploadComments}
                        isProfileReview
                        rowId={user._id}
                      />
                    </div>
                  );
                }}
              </Formik>
            </div>
          </>
        )}
        {selectedTab.id === "professionalDetails" && (
          <ProfessionalDetails
            handleSaveComment={handleSaveComment}
            isProfileReview
          />
        )}
        <ActionsButtons
          userStatus={user?.status}
          row={row}
          unresolvedCommentsCount={unresolvedComments.length}
          isLoading={isLoading}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          goToPrevStep={goToPrevStep}
          goToNextStep={goToNextStep}
          handleVerifyProfile={handleVerifyProfile}
          handleSendNotification={handleSendNotification}
        />
      </td>
    </tr>
  );
}
