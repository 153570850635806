import axios from '../axios'

const create = data => {
  return axios.post('/registered-offices', data)
}

const registeredOfficeDataService = {
  create
}

export default registeredOfficeDataService
