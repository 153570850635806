/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useMemo } from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import ValidationError from "components/lib/Shared/ValidationError";
import CommentsList from "components/lib/Global/CommentsList";
import { verifyName } from "state/slices/company";
import { Constants } from "config/constants";
import { getCompanySuffix } from "utils/company";

const PreferredCompanyName = ({
  field,
  form,
  abbreviatedFlagName,
  nameComments,
  abbreviationComments,
  type,
  nameCount,
  ...props
}) => {
  const role = Cookies.get("role");
  // component state
  const [isAbbreviated, setIsAbbreviated] = useState(
    form.values[abbreviatedFlagName]
  );
  const [companySuffix, setCompanySuffix] = useState("");
  const [companyName, setCompanyName] = useState(field?.value ?? "");

  // hooks
  const { setFieldValue, setFieldError } = useFormikContext();
  const dispatch = useDispatch();

  // redux selector
  const { nameValidity } = useSelector((state) => state.companySlice);

  const handleChange = ({ target: { value } }) => {
    setCompanyName(value);
    setFieldValue(field.name, value);
  };

  useEffect(() => {
    setIsAbbreviated(form.values[abbreviatedFlagName]);
    setCompanyName(field.value);
  }, [form.values]);

  useEffect(() => {
    if (companyName === "") {
      setCompanySuffix("");
    } else {
      setCompanySuffix(` ${getCompanySuffix(isAbbreviated, type)}`);
    }
    if (
      companyName &&
      [
        Constants.COOKIE_ROLES.APPLICANT,
        Constants.COOKIE_ROLES.CO_APPLICANT,
      ].includes(role)
    ) {
      dispatch(verifyName({ name: companyName, fieldName: field.name }));
    }
  }, [companyName, isAbbreviated, field.name, role, type]);

  const fieldName = useMemo(() => field.name, []);

  // set name field error programmatically from the verify name response
  useEffect(() => {
    if (
      nameValidity[fieldName] &&
      !nameValidity[fieldName]?.available &&
      nameValidity[fieldName]?.message
    ) {
      setFieldError([fieldName], nameValidity[fieldName]?.message);
    }
  }, [fieldName, nameValidity, setFieldError]);

  const handleChecked = ({ target: { checked } }) => {
    setIsAbbreviated(checked);
    setFieldValue(abbreviatedFlagName, checked);
  };

  return (
    <>
      <label
        htmlFor={field.name}
        className="block text-sm font-medium text-gray-700"
      >
        <span
          className={`relative ${
            nameCount < 4
              ? "after:content-['*'] after:ml-0.5 after:text-red-500"
              : ""
          }`}
        >
          {props.title}
          {nameComments?.length > 0 && (
            <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-4 -right-5">
              <span>{nameComments?.length}</span>
            </span>
          )}
        </span>
        <p>{companyName ? companyName + companySuffix : null}</p>
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          {...field}
          onChange={handleChange}
          name={props.name}
          id={field.name}
          disabled={form.values.resolvedFields.includes(field.name)}
          placeholder="Enter name"
          className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
        />
        {nameValidity[field.name]?.isVerifying && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <SpinnerIcon className="text-gray-400" />
          </div>
        )}
      </div>
      {nameComments && (
        <CommentsList comments={nameComments} color={"text-red-500"} />
      )}
      <div className="relative flex items-start mt-2">
        <div className="flex items-center h-5">
          <input
            {...field}
            name={abbreviatedFlagName}
            id={abbreviatedFlagName}
            type="checkbox"
            disabled={form.values.resolvedFields.includes(abbreviatedFlagName)}
            onChange={handleChecked}
            checked={isAbbreviated}
            className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            htmlFor={abbreviatedFlagName}
            className="font-medium text-gray-700 select-none"
          >
            <span className="relative">
              Abbreviate (Shorten name to initials)
              {abbreviationComments?.length > 0 && (
                <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-4 -right-5">
                  <span>{abbreviationComments?.length}</span>
                </span>
              )}
            </span>
          </label>
        </div>
      </div>
      {abbreviationComments && (
        <CommentsList comments={abbreviationComments} color={"text-red-500"} />
      )}
      <ValidationError
        errors={form.errors}
        touched={form.touched}
        name={field.name}
      />
    </>
  );
};

export default PreferredCompanyName;
