import React from "react";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import { format } from "date-fns";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import addressOptions from "components/PLCTABS/SFLdata/addressOptions";
import CommentsList from "components/lib/Global/CommentsList";

function ThirdStep({ handleSaveComment, isViewMode }) {
  const { company } = useSelector((state) => state.companySlice);
  const {
    companyFormation: { step3, resolvedFields },
  } = useSelector((state) => state.companySlice);
  const { comments } = useSelector((state) => state.commentsSlice);

  const businessApplicationComments = comments.filter(
    (comment) => comment.formName === "businessApplicationDetails"
  );

  const targetBusinessStartDate = _get(
    step3,
    "primaryBusinessActivity.targetBusinessStartDate"
  );

  const businessApplicationDetailsFields1 = [
    {
      key: "Primary Business Activity Sector",
      name: "sector",
      value: _get(step3, "primaryBusinessActivity.sector", "N/A"),
    },
    {
      key: "Primary Business Activity Division",
      name: "division",
      value: _get(step3, "primaryBusinessActivity.division", "N/A"),
    },
    {
      key: "Primary Business Activity Group",
      name: "group",
      value: _get(step3, "primaryBusinessActivity.group", "N/A"),
    },
    {
      key: "Primary Business Activity Class",
      name: "businessClass",
      value: _get(step3, "primaryBusinessActivity.businessClass", "N/A"),
    },
    {
      key: "Target Business Start Date",
      name: "targetBusinessStartDate",
      value: targetBusinessStartDate
        ? format(new Date(targetBusinessStartDate), "yyyy-MM-dd")
        : "N/A",
    },
    {
      key: "Accounting Period End Month",
      name: "accountingEndMonth",
      value:
        addressOptions.months.find(
          (month) =>
            month.value === step3?.primaryBusinessActivity?.accountingEndMonth
        )?.label ?? "N/A",
    },
  ];

  const isSubsidiaryCompany = _get(
    step3,
    "subsidiaryDetail.isSubsidiaryCompany"
  );

  const businessApplicationDetailsFields2 = [
    {
      key: "No. of Employees at target start date",
      name: "numberOfEmployees",
      value: _get(step3, "primaryBusinessActivity.numberOfEmployees", "N/A"),
    },
    {
      key: "Estimated annual turnover",
      name: "turnOver",
      value: _get(step3, "primaryBusinessActivity.turnOver", "N/A"),
    },
    {
      key: "Is the company you are registering a subsidiary company or branch?",
      name: "isSubsidiaryCompany",
      value: isSubsidiaryCompany || "N/A",
    },
  ];

  if (isSubsidiaryCompany === "Yes") {
    const isKenyanResident = _get(step3, "subsidiaryDetail.isKenyanResident");
    businessApplicationDetailsFields2.push({
      key: "Is the principal holding company resident in Kenya?",
      name: "subsidiaryDetail.isKenyanResident",
      value: isKenyanResident || "N/A",
    });

    if (isKenyanResident === "No") {
      businessApplicationDetailsFields2.push(
        {
          key: "Holding Company Name * (subsidiary/branch)",
          name: "subsidiaryDetail.holdingCompanyName",
          value: _get(step3, "subsidiaryDetail.holdingCompanyName", "N/A"),
        },
        {
          key: "Holding Company Country *",
          name: "subsidiaryDetail.holdingCompanyCountry",
          value: _get(step3, "subsidiaryDetail.holdingCompanyCountry", "N/A"),
        }
      );
    } else {
      businessApplicationDetailsFields2.push({
        key: "Holding Company Pin * (subsidiary/branch)",
        name: "subsidiaryDetail.holdingCompanyPin",
        value: _get(step3, "subsidiaryDetail.holdingCompanyPin", "N/A"),
      });
    }
  }
  const isAmalgamationCompany = _get(
    step3,
    "amalgamationDetail.isAmalgamationCompany"
  );
  businessApplicationDetailsFields2.push({
    key: "Was your business formed as a result of amalgamation or acquisition?",
    name: "isAmalgamationCompany",
    value: isAmalgamationCompany || "N/A",
  });
  const detailOfBond = _get(step3, "amalgamationDetail.detailOfBond");
  if (isAmalgamationCompany === "Yes") {
    businessApplicationDetailsFields2.push(
      {
        key: "Detail of Bond? *",
        name: "amalgamationDetail.detailOfBond",
        value: detailOfBond || "N/A",
      },
      {
        key: `Holding Company Pin * (${detailOfBond})`,
        name: "amalgamationDetail.holdingCompanyPin",
        value: _get(step3, "amalgamationDetail.holdingCompanyPin", "N/A"),
      },
      {
        key: `Holding Company Name * (${detailOfBond})`,
        name: "amalgamationDetail.holdingCompanyName",
        value: _get(step3, "amalgamationDetail.holdingCompanyName", "N/A"),
      },
      {
        key: "Holding Company's Email On iTax *",
        name: "amalgamationDetail.holdingCompanyiTaxEmail",
        value: _get(step3, "amalgamationDetail.holdingCompanyiTaxEmail", "N/A"),
      },
      {
        key: `Date of ${detailOfBond} *`,
        name: "amalgamationDetail.amalgamationDate",
        value: _get(step3, "amalgamationDetail.amalgamationDate", "N/A"),
      }
    );
  }

  return (
    <>
      {businessApplicationComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 bg-white">
          <CommentsList
            comments={businessApplicationComments}
            toggleCommentStatus={!isViewMode}
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {businessApplicationComments.length} of{" "}
            {businessApplicationComments.length}
          </p>
        </div>
      )}
      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="businessApplicationDetails"
        fields={businessApplicationDetailsFields1}
        resolvedFields={resolvedFields}
        formHelperKey="company"
        updateEffectKey="updateCompanyFormationState"
        formId={_get(company, "_id")}
        handleSaveComment={handleSaveComment}
        comments={businessApplicationComments}
        hideResolveAllCheckbox={isViewMode}
      />
      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="businessApplicationDetails"
        fields={businessApplicationDetailsFields2}
        resolvedFields={resolvedFields}
        formHelperKey="company"
        updateEffectKey="updateCompanyFormationState"
        formId={_get(company, "_id")}
        handleSaveComment={handleSaveComment}
        comments={businessApplicationComments}
        hideResolveAllCheckbox={isViewMode}
      />
    </>
  );
}

export default ThirdStep;
