import { Fragment, useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  CogIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter, classNames } from "utils";
import { Constants } from "config/constants";
import AvatarIcon from "../Shared/Icons/AvatarIcon";
import { setCookie } from "utils/cookie";

const userNavLinks = [
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "Transactions",
    href: "/transactions",
  },
  {
    label: "Reports",
    href: "#",
  },
  {
    label: "Companies",
    href: "/companies",
  },
];

const systemAdminNavLinks = [
  {
    label: "Dashboard",
    href: "/dashboard",
  },
  {
    label: "Analytics",
    href: "#",
  },
  {
    label: "Accounts",
    href: "#",
  },
];

const menuLinks = [
  {
    label: "Your profile",
    href: "/profile",
  },
  {
    label: "Settings",
    href: "/account-settings",
  },
];

export default function Navbar() {
  const history = useHistory();
  const { isAuthenticated, logout } = useAuth0();
  const [navLinks, setNavLinks] = useState(userNavLinks);
  const { organization } = useSelector((state) => state.organizationsSlice);
  const { organizationName, dashboardUrl } = useSelector(
    (state) => state.activeDashboardSlice
  );
  const { currentUser } = useSelector((state) => state.authSlice);
  const userSlice = useSelector((state) => state.userSlice);
  const { names, electronicAddress } = currentUser || {};

  useEffect(() => {
    if (organization?.status === "Active" || organizationName) {
      setNavLinks([]);
    } else {
      setNavLinks(userNavLinks);
    }
  }, [organizationName, organization]);

  useEffect(() => {
    if (currentUser?.role === 11) {
      setNavLinks(systemAdminNavLinks);
    }
  }, [currentUser?.role]);

  const handleLogout = () => {
    Cookies.remove("role");
    const broadcastChannel = new BroadcastChannel("logoutChannel");
    broadcastChannel.postMessage("logout");
  };

  useEffect(() => {
    if (!isAuthenticated) {
      Cookies.remove("role");
    }
  }, [isAuthenticated]);

  const { pathname } = history.location;

  return (
    <Disclosure as="nav" className="sticky top-0 z-30 bg-sflPrimary ">
      {({ open }) => (
        <>
          <div className="container mx-auto">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  {dashboardUrl ? (
                    <button>
                      <img
                        onClick={() => {
                          setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
                          history.push(dashboardUrl);
                        }}
                        src="https://res.cloudinary.com/duqjf9tj1/image/upload/v1642587752/Assets/SFL_White_h6r9lx.svg"
                        alt="bg"
                        className="w-32"
                      />
                    </button>
                  ) : (
                    <Link to="/">
                      <img
                        src="https://res.cloudinary.com/duqjf9tj1/image/upload/v1642587752/Assets/SFL_White_h6r9lx.svg"
                        alt="bg"
                        className="w-32"
                      />
                    </Link>
                  )}
                </div>
                <div className="hidden lg:block lg:ml-6">
                  <div className="flex space-x-4">
                    {dashboardUrl && (
                      <button
                        className={classNames(
                          "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "px-3 py-2 text-sm font-medium rounded-md"
                        )}
                        onClick={() => {
                          setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
                          history.push(dashboardUrl);
                        }}
                      >
                        Dashboard
                      </button>
                    )}
                    {navLinks.map((navLink, index) => (
                      <Link
                        key={`${navLink.href}_${index}_desktop`}
                        to={navLink.href}
                        className={classNames(
                          pathname === navLink.href
                            ? "text-white bg-gray-900"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "px-3 py-2 text-sm font-medium rounded-md"
                        )}
                      >
                        {navLink.label}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex justify-center flex-1 px-2 lg:ml-6 lg:justify-end">
                <div className="w-full max-w-lg lg:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <SearchIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full py-2 pl-10 pr-3 text-xs leading-5 text-gray-300 placeholder-gray-400 border border-transparent rounded-md focus:outline-none focus:bg-white focus:border-indigo-500 focus:ring-indigo-500 focus:text-gray-900 sm:text-sm"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </div>
              </div>
              <div className="mx-4 w-0.5 hidden h-8 bg-slate-300 lg:block"></div>

              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:block">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="relative flex-shrink-0 p-1 text-gray-600 bg-white rounded-full hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <div className="absolute top-0 right-0 z-10 p-1 bg-red-600 rounded-full"></div>
                    <BellIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    className="flex-shrink-0 p-1 ml-2 text-gray-600 bg-white rounded-full hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <CogIcon className="w-6 h-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0 ml-2">
                    <div className="flex items-center space-x-2">
                      {userSlice?.fileUploads?.passportPhoto ? (
                        <img
                          className="w-8 h-8 rounded-full"
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${userSlice.fileUploads.passportPhoto}`}
                          alt={names?.firstName ?? ""}
                        />
                      ) : (
                        <div className="w-8 h-8 rounded-full">
                          <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                            <AvatarIcon />
                          </div>
                        </div>
                      )}
                      <div className="ml-3 text-base font-medium text-white">
                        {capitalizeFirstLetter(names?.firstName ?? "")}
                      </div>
                      <Menu.Button className="flex text-sm text-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <ChevronDownIcon
                          className="w-5 h-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 w-40 py-1 mt-2 text-left origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {menuLinks.map((menuLink) => {
                          const isActive = menuLink.href === pathname;
                          return (
                            <Menu.Item key={menuLink.href}>
                              <Link to={menuLink.href}>
                                <div
                                  className={classNames(
                                    isActive ? "bg-gray-100" : "",
                                    "hover:bg-gray-100 block px-4 py-2 text-sm text-gray-700 w-full"
                                  )}
                                >
                                  {menuLink.label}
                                </div>
                              </Link>
                            </Menu.Item>
                          );
                        })}
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              <button
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "text-left block px-4 py-2 text-sm text-gray-700 w-full"
                                )}
                                onClick={handleLogout}
                              >
                                Sign out
                              </button>
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navLinks.map((navLink, index) => (
                <button
                  key={`${navLink.href}_${index}_mobile`}
                  onClick={() => history.push(navLink.href)}
                  className={classNames(
                    pathname === navLink.href
                      ? " text-white bg-gray-900"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 text-base py-2 font-medium rounded-md w-full text-left"
                  )}
                >
                  {navLink.label}
                </button>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  {userSlice?.fileUploads?.passportPhoto ? (
                    <img
                      className="w-10 h-10 rounded-full"
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${userSlice.fileUploads.passportPhoto}`}
                      alt={names?.firstName ?? ""}
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full">
                      <div className="relative w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                        <AvatarIcon />
                      </div>
                    </div>
                  )}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {capitalizeFirstLetter(names?.firstName ?? "")}
                  </div>
                  <div className="text-sm font-medium text-gray-400">
                    {electronicAddress?.emailAddress}
                  </div>
                </div>
                <button
                  type="button"
                  className="flex-shrink-0 p-1 ml-auto text-gray-400 bg-gray-800 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>

              <div className="px-2 mt-3 space-y-1">
                {menuLinks.map((menuLink) => {
                  const isActive = menuLink.href === pathname;
                  return (
                    <Link to={menuLink.href} key={menuLink.href}>
                      <Disclosure.Button
                        className={`block w-full text-left px-3 py-2 text-base font-medium text-gray-400 rounded-md hover:text-white hover:bg-gray-700 ${
                          isActive && "bg-gray-700 text-white"
                        }`}
                      >
                        {menuLink.label}
                      </Disclosure.Button>
                    </Link>
                  );
                })}
                <button
                  className="block w-full px-3 py-2 text-base font-medium text-left text-gray-400 rounded-md hover:text-white hover:bg-gray-700 w-100"
                  onClick={handleLogout}
                >
                  Sign out
                </button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
