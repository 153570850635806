import React, { forwardRef, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";
import { Formik, Form, Field } from "formik";

import Input from "components/lib/Shared/Input";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import CommentsPopup from "./CommentsPopup";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { resolveFields, invalidateFields } from "state/slices/resolveField";
import { getComments } from "state/slices/comment";
import { Constants } from "config/constants";
import Button from "../Shared/Button";

export const summaryOfArticlesFieldsName = [
  "directorsLimit",
  "directorMustBeShareholder",
  "directorMaxAgeLimit",
  "boardMeetingNoticePeriod",
  "directorsRequiredForQuorum",
  "resolutionApprovalSignatories",
  "rotationOfDirectors",
  "directorRetiresAtNextAGM",
  "noticePeriodForAGM",
  "membersRequiredForQuorum",
  "companySealAffixationInPresence",
  "borrowingPowersForDirectors",
  "approvalRequirementForWrittenResolutions",
  "shareholdersRightOfFirstRefusal",
];

const SummaryOfArticlesReview = forwardRef(
  (
    {
      viewOnly,
      summary,
      addReviewComment,
      comments = [],
      formName = "articlesOfAssociation",
      templateType = "short",
      entityType = "PVT",
      resolvedFields = [],
      formHelperKey,
      updateEffectKey = "",
      updateEffectPath = "",
      wrapperStyles = "text-sm w-full mt-4",
      renderReviewIcon = true,
      renderAcceptIcon = true,
      formId,
      handleSaveComment,
      hideResolveAllCheckbox,
      ...restProps
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRows, setSelectedRow] = useState([]);
    const [allFieldsResolved, setAllFieldsResolved] = useState(false);
    const currentUser = useSelector((state) => state.userSlice);
    const isReviewer = Cookies.get("role") === Constants.COOKIE_ROLES.REVIEWER;
    const history = useHistory();
    const dispatch = useDispatch();
    const isViewMode =
      history.location.pathname.split("/").pop() === "view" ||
      restProps.isViewMode;
    const isReviewerMode =
      history.location.pathname.split("/").pop() === "review";

    const handleResolveBtnClicked = async (fieldName) => {
      const commentsToBeResolved = comments?.filter(
        (comment) => comment.fieldName === fieldName && !comment.isResolved
      );
      const commentsToBeResolvedIds = commentsToBeResolved?.map(
        ({ _id }) => _id
      );
      setIsLoading(true);
      setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
      const promise = await dispatch(
        resolveFields({
          data: {
            formId,
            formName: formHelperKey,
            fieldName,
            resolvedFields: [fieldName],
            commentsToBeResolvedIds,
          },
          updateEffectKey,
          updateEffectPath,
        })
      );
      if (commentsToBeResolvedIds.length > 0) {
        const query = {
          companyId: commentsToBeResolved[0]?.company,
          corporate: commentsToBeResolved[0]?.corporate,
        };
        if (isReviewerMode) query.createdBy = currentUser?._id;
        await dispatch(getComments(query));
      }
      setIsLoading(false);
      setSelectedRow((selectedRows) => [
        ...selectedRows.filter(
          (rowName) => rowName !== promise.meta.arg.data.fieldName
        ),
      ]);
    };

    const handleInvalidateBtnClicked = async (fieldName) => {
      setIsLoading(true);
      setSelectedRow((selectedRows) => [...selectedRows, fieldName]);
      const promise = await dispatch(
        invalidateFields({
          data: {
            formId,
            formName: formHelperKey,
            fieldName,
            invalidFields: [fieldName],
          },
          updateEffectKey,
          updateEffectPath,
        })
      );
      setIsLoading(false);
      setSelectedRow((selectedRows) => [
        ...selectedRows.filter(
          (rowName) => rowName !== promise.meta.arg.data.fieldName
        ),
      ]);
    };

    useEffect(() => {
      setAllFieldsResolved(
        summaryOfArticlesFieldsName.every((element) =>
          resolvedFields.includes(element)
        )
      );
    }, [summaryOfArticlesFieldsName, resolvedFields]);

    const handleAllFieldsStatus = async (e) => {
      const commentsToBeResolved = comments?.filter(
        (comment) => !comment.isResolved
      );
      const commentsToBeResolvedIds = commentsToBeResolved?.map(
        ({ _id }) => _id
      );
      setIsLoading(true);
      if (e.target.checked) {
        // resolve/approve all the form fields
        const unresolvedFields = summaryOfArticlesFieldsName.filter(
          (fieldName) => !resolvedFields.includes(fieldName)
        );
        setSelectedRow((selectedRows) => [
          ...selectedRows,
          ...unresolvedFields,
        ]);
        await dispatch(
          resolveFields({
            data: {
              formId,
              formName: formHelperKey,
              resolvedFields: unresolvedFields,
              commentsToBeResolvedIds,
            },
            updateEffectKey,
            updateEffectPath,
          })
        );
        if (commentsToBeResolvedIds.length > 0) {
          const query = {
            companyId: commentsToBeResolved[0]?.company,
            corporate: commentsToBeResolved[0]?.corporate,
          };
          if (isReviewerMode) query.createdBy = currentUser?._id;
          await dispatch(getComments(query));
        }
        setIsLoading(false);
        setSelectedRow([]);
      } else {
        // invalidate/disapprove all the form fields
        const resolvedFieldsSlice = summaryOfArticlesFieldsName.filter(
          (fieldName) => resolvedFields.includes(fieldName)
        );
        setSelectedRow((selectedRows) => [
          ...selectedRows,
          ...resolvedFieldsSlice,
        ]);
        await dispatch(
          invalidateFields({
            data: {
              formId,
              formName: formHelperKey,
              invalidFields: resolvedFieldsSlice,
            },
            updateEffectKey,
            updateEffectPath,
          })
        );
        setSelectedRow([]);
      }
      setIsLoading(false);
    };

    const CommentAndResolveSection = ({ field }) => {
      const showCommentAndResolveSection =
        addReviewComment && field.name && !viewOnly;
      if (!showCommentAndResolveSection) return null;
      const isRowLoading = isLoading && selectedRows.includes(field.name);

      return (
        <div className="flex items-center justify-end space-x-3 text-xs z-20 ml-auto mb-4">
          {!resolvedFields.includes(field.name) && (
            <CommentsPopup
              handleSaveComment={handleSaveComment}
              formName={formName}
              fieldName={field.name}
              fieldLabel={field.key}
              comment={comments.find(
                (comment) =>
                  comment.fieldName === field.name &&
                  currentUser?._id === comment.createdBy?._id
              )}
            >
              <span>Review</span>
              {renderReviewIcon && <PencilIcon className="inline w-4 h-4" />}
            </CommentsPopup>
          )}
          <div className={`flex items-center justify-center`}>
            {resolvedFields.includes(field.name) ? (
              <Button
                className={`flex space-x-1 ${
                  isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={() =>
                  isRowLoading ? null : handleInvalidateBtnClicked(field.name)
                }
                overrideBaseStyles
              >
                {isRowLoading ? (
                  <SpinnerIcon className="text-primary" />
                ) : (
                  <>
                    <CheckIcon className="inline w-4 h-4" />
                    <span>Accepted</span>
                  </>
                )}
              </Button>
            ) : (
              <Button
                className={`flex space-x-1 ${
                  isRowLoading ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={() =>
                  isRowLoading ? null : handleResolveBtnClicked(field.name)
                }
                overrideBaseStyles
              >
                {isRowLoading ? (
                  <SpinnerIcon className="text-primary" />
                ) : (
                  <>
                    <span>Accept</span>
                    {renderAcceptIcon && (
                      <CheckIcon className="inline w-4 h-4" />
                    )}
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      );
    };

    const showCommentAndResolveSection = addReviewComment && !viewOnly;
    const gridColumns = showCommentAndResolveSection
      ? "grid-cols-6"
      : "grid-cols-3";

    return (
      <Formik
        enableReinitialize
        initialValues={summary}
        innerRef={ref}
        onSubmit={() => null}
      >
        {() => {
          return (
            <Form className={wrapperStyles}>
              <div className="bg-white py-6 px-4 sm:p-6 shadow sm:rounded-md">
                <div className="flex justify-between font-medium text-gray-800">
                  <p className="">Summary of Articles</p>
                  <p>
                    {templateType === "Short"
                      ? "Model Articles apply"
                      : "Model Articles do not apply"}
                  </p>
                </div>
                <div className="mt-2 grid grid-cols-12 gap-6 border-t border-gray-200">
                  <div className="col-span-12 sm:col-span-12">
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">1. Directors</h3>
                        <div className="grid grid-cols-4 gap-6">
                          <Field
                            name="directorsLimit.minimum"
                            label="Minimum"
                            component={Input}
                            disabled
                            placeholder="Enter minimum number"
                            type="number"
                            min={0}
                            max={1000}
                          />
                          <Field
                            name="directorsLimit.maximum"
                            label="Maximum"
                            component={Input}
                            disabled
                            placeholder="Enter maximum number"
                            type="number"
                            min={0}
                            max={1000}
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="directorsLimit.for"
                          label="To complete e.g. Article 10."
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{ key: "Directors", name: "directorsLimit" }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">
                          2. Share Qualifications
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="directorMustBeShareholder.value"
                            label="Is a Director required to be a Shareholder of the Company?"
                            component={ReactSelectWithFormik}
                            options={
                              Constants.TEMPLATES.DIRECTOR_MUST_BE_SHAREHOLDER
                            }
                            isDisabled
                            placeholder="Enter share qualifications"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="directorMustBeShareholder.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Share Qualifications",
                              name: "directorMustBeShareholder",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">3. Age Limit</h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="directorMaxAgeLimit.value"
                            label="Is there a maximum age limit for a Director?, if not put N/A"
                            component={Input}
                            disabled
                            placeholder="Enter age number"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="directorMaxAgeLimit.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Age Limit",
                              name: "directorMaxAgeLimit",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">
                          4. Period of notice for board meeting
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="boardMeetingNoticePeriod.value"
                            label="Consider whether clear days apply"
                            component={Input}
                            disabled
                            placeholder="Enter period of notice"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="boardMeetingNoticePeriod.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Period of notice for board meeting",
                              name: "boardMeetingNoticePeriod",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">
                          5. Quorum for board meeting
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="directorsRequiredForQuorum.value"
                            label="Number of Directors required for a meeting to have quorum"
                            component={Input}
                            disabled
                            placeholder="Enter number of quorum"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="directorsRequiredForQuorum.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Quorum for board meeting",
                              name: "directorsRequiredForQuorum",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">
                          6. Signed board resolution by
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="resolutionApprovalSignatories.value"
                            label="Indicate whether, all or majority is required to approve written resolutions."
                            component={Input}
                            disabled
                            placeholder="Enter threshold"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="resolutionApprovalSignatories.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Signed board resolution by",
                              name: "resolutionApprovalSignatories",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">
                          7. Rotation of directors
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="rotationOfDirectors.value"
                            label="Indicate whether retirement by rotation applies."
                            component={Input}
                            disabled
                            placeholder="Enter rotation of directors details"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="rotationOfDirectors.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Rotation of directors",
                              name: "rotationOfDirectors",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">
                          8. Board to fill a Director casual vacancy
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="directorRetiresAtNextAGM.value"
                            label="Indicate whether new Director to retire at next AGM."
                            component={Input}
                            className="resize-none"
                            renderTextarea
                            rows={2}
                            disabled
                            placeholder="Yes, retires at next AGM"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="directorRetiresAtNextAGM.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Board to fill a Director casual vacancy",
                              name: "directorRetiresAtNextAGM",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">
                          9. Period of notice for Annual General Meetings
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="noticePeriodForAGM.value"
                            label="At least 21 days required. You may indicate a longer period if need be."
                            component={Input}
                            disabled
                            placeholder="Enter period of notice"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="noticePeriodForAGM.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Period of notice for Annual General Meetings",
                              name: "noticePeriodForAGM",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">
                          10. Quorum for General meetings
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="membersRequiredForQuorum.value"
                            label="Indicate the number of members required for Quorum at General Meetings.."
                            component={Input}
                            disabled
                            placeholder="Enter number of members"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="membersRequiredForQuorum.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Quorum for General meetings",
                              name: "membersRequiredForQuorum",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <div className="grid grid-cols-2 gap-6 pt-4">
                          <Field
                            name="companySealAffixationInPresence.value"
                            label="11. Company Seal to be affixed, where applicable, in the presence of:"
                            component={Input}
                            disabled
                            placeholder="N/A"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="companySealAffixationInPresence.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Company Seal to be affixed, where applicable, in the presence of",
                              name: "companySealAffixationInPresence",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`grid ${gridColumns} gap-4 items-end`}>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-3"
                            : "col-span-2"
                        } border-r border-gray-300 pr-4`}
                      >
                        <h3 className="font-medium pt-4">
                          12. Borrowing powers
                        </h3>
                        <div className="grid grid-cols-2 gap-6">
                          <Field
                            name="borrowingPowersForDirectors.value"
                            label="Indicate whether the Directors have limited or unlimited borrowing powers."
                            options={
                              Constants.TEMPLATES.BORROWING_POWERS_FOR_DIRECTORS
                            }
                            component={ReactSelectWithFormik}
                            isDisabled
                            placeholder="Select borrowing power"
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          showCommentAndResolveSection
                            ? "col-span-2"
                            : "col-span-1"
                        }`}
                      >
                        <Field
                          name="borrowingPowersForDirectors.for"
                          component={Input}
                          disabled
                          placeholder="Add details"
                        />
                      </div>
                      {showCommentAndResolveSection && (
                        <div className="col-span-1">
                          <CommentAndResolveSection
                            field={{
                              key: "Borrowing powers",
                              name: "borrowingPowersForDirectors",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {entityType === "PVT" && (
                      <>
                        <div className={`grid ${gridColumns} gap-4 items-end`}>
                          <div
                            className={`${
                              showCommentAndResolveSection
                                ? "col-span-3"
                                : "col-span-2"
                            } border-r border-gray-300 pr-4`}
                          >
                            <h3 className="font-medium pt-4">
                              13. Signed member resolution by
                            </h3>
                            <div className="grid grid-cols-2 gap-6">
                              <Field
                                name="approvalRequirementForWrittenResolutions.value"
                                label="Indicate whether, all or majority is required to approve written resolutions."
                                component={Input}
                                disabled
                                placeholder="Enter minimum number"
                              />
                            </div>
                          </div>
                          <div
                            className={`${
                              showCommentAndResolveSection
                                ? "col-span-2"
                                : "col-span-1"
                            }`}
                          >
                            <Field
                              name="approvalRequirementForWrittenResolutions.for"
                              component={Input}
                              disabled
                              placeholder="Add details"
                            />
                          </div>
                          {showCommentAndResolveSection && (
                            <div className="col-span-1">
                              <CommentAndResolveSection
                                field={{
                                  key: "Signed member resolution by",
                                  name: "approvalRequirementForWrittenResolutions",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className={`grid ${gridColumns} gap-4 items-end`}>
                          <div
                            className={`${
                              showCommentAndResolveSection
                                ? "col-span-3"
                                : "col-span-2"
                            } border-r border-gray-300 pr-4`}
                          >
                            <h3 className="font-medium pt-4">
                              14. Pre-emption Rights applicable
                            </h3>
                            <div className="grid grid-cols-2 gap-6">
                              <Field
                                name="shareholdersRightOfFirstRefusal.value"
                                label="Indicate whether the shareholders have the right of first refusal on transfers and allotment of shares."
                                options={
                                  Constants.TEMPLATES
                                    .SHAREHOLDER_RIGHT_OF_FIRST_REFUSAL
                                }
                                component={ReactSelectWithFormik}
                                isDisabled
                                placeholder="Select"
                              />
                            </div>
                          </div>
                          <div
                            className={`${
                              showCommentAndResolveSection
                                ? "col-span-2"
                                : "col-span-1"
                            }`}
                          >
                            <Field
                              name="shareholdersRightOfFirstRefusal.for"
                              component={Input}
                              disabled
                              placeholder="Add details"
                            />
                          </div>
                          {showCommentAndResolveSection && (
                            <div className="col-span-1">
                              <CommentAndResolveSection
                                field={{
                                  key: "Pre-emption Rights applicable",
                                  name: "shareholdersRightOfFirstRefusal",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {!hideResolveAllCheckbox && !isViewMode && (
                  <div className="flex items-center mt-4">
                    <input
                      aria-describedby="approve-disapprove"
                      type="checkbox"
                      className="mr-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      onChange={handleAllFieldsStatus}
                      checked={allFieldsResolved}
                    />
                    <p
                      id="approve-disapprove"
                      className="flex items-center text-sm font-semibold"
                    >
                      Click this checkbox to{" "}
                      {allFieldsResolved ? "disapprove" : "approve"} all entries
                      if they are {allFieldsResolved ? "incorrect" : "correct"}{" "}
                      then proceed to next step
                    </p>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
);

export default SummaryOfArticlesReview;
