import { object, boolean, number, string } from "yup";

/**
 * This returns an object composed of conditional `yup` schema and initial values
 * related to share allocation and beneficial ownership form.
 *
 * @param {string} idType The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {object} beneficialOwnershipForm - Object containing fields related to BO form.
 * @param {object} selectedShareholder - Selected shareholder from the dropdown.
 * @param {Boolean} renderLongForm Render long form `true` | `false`.
 * @return {object} This returns object containing schema and initialValues based on schema.
 * @example
 *  {
 *    schema // yup schema
 *    schemaInitialValues
 *  }
 */
const beneficialOwnershipFormValidation = (
  idType,
  beneficialOwnershipForm,
  selectedShareholder,
  renderLongForm
) => {
  const { maxPercentShareholding = 0, maxPercentVotingRights = 0 } =
    beneficialOwnershipForm || {};

  const schema = object().shape({
    renderLongForm: boolean().default(renderLongForm),
    isStateOwnedEnterprise: boolean(),
    beneficialOwnershipForm: object()
      .when("isStateOwnedEnterprise", {
        is: false,
        then: object().shape({
          maxPercentShareholding: number().required(
            "Max percent shareholding is required"
          ),
          indirectPercentShareholding: number().when("maxPercentShareholding", {
            is: (val) => val > 0,
            then: number().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: number().optional(),
              otherwise: number()
                .min(0, "Value must be greater than or equal to 0")
                .max(
                  maxPercentShareholding,
                  `Value must be less than or equal to ${maxPercentShareholding}`
                )
                .required("Indirect percent shareholding is required"),
            }),
            otherwise: number()
              .notRequired()
              .nullable()
              .transform((_, val) => (val === Number(val) ? val : null)),
          }),
          maxPercentVotingRights: number().required(
            "Max percent voting rights is required"
          ),
          indirectPercentVotingRights: number().when("maxPercentVotingRights", {
            is: (val) => val > 0,
            then: number().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: number().optional(),
              otherwise: number()
                .min(0, "Value must be greater than or equal to 0")
                .max(
                  maxPercentVotingRights,
                  `Value must be less than or equal to ${maxPercentVotingRights}`
                )
                .required("Indirect percent voting rights is required"),
            }),
            otherwise: number()
              .notRequired()
              .nullable()
              .transform((_, val) => (val === Number(val) ? val : null)),
          }),
          indirectRightRemoveDirector: string().when("selectedShareholder", {
            is:
              selectedShareholder?.beneficialOwnershipForm
                ?.selectedIndirectRightRemoveDirector !== "no",
            then: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Please select one option"),
            }),
            otherwise: string().notRequired(),
          }),
          indirectCompanyControlRight: string().when("selectedShareholder", {
            is:
              selectedShareholder?.beneficialOwnershipForm
                ?.indirectCompanyControlRight !== "no",
            then: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Please select one option"),
            }),
            otherwise: string().notRequired(),
          }),
          dateOfBecomingBO: string().when("renderLongForm", {
            is: () => renderLongForm === true,
            then: string().when("$makeFieldsOptional", {
              is: (makeFieldsOptional) => makeFieldsOptional,
              then: string().optional(),
              otherwise: string().required("Date of becoming BO is required"),
            }),
            otherwise: string().notRequired(),
          }),
          sourceOfBOInformation: string().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: string().optional(),
            otherwise: string().required(
              "Source of beneficial owner information is required"
            ),
          }),
        }),
      })
      .default({
        maxPercentShareholding: "",
        directPercentShareholding: "",
        indirectPercentShareholding: "",
        maxPercentVotingRights: "",
        directPercentVotingRights: "",
        indirectPercentVotingRights: "",
        directRightRemoveDirector: "",
        indirectRightRemoveDirector: "no",
        directCompanyControlRight: "",
        indirectCompanyControlRight: "no",
        dateOfBecomingBO: "",
        sourceOfBOInformation: "",
      }),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default beneficialOwnershipFormValidation;
