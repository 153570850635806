import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Tabs from "components/lib/Shared/Tabs";
import CompanySummary from "./CompanySummary";
import RegisteredAddress from "./RegisteredAddress";
import ShareInformation from "./ShareInformation";
import StakeholderDetails from "./StakeholderDetails";
import BeneficialOwnership from "components/PLCTABS/ApplicationReviewTab/BeneficialOwnership";
import { Constants } from "config/constants";

export default function BusinessDetails({ companyId }) {
  const {
    beneficialOwners,
    groupedBeneficialOwners,
    dashboardPermissions,
    designation,
  } = useSelector((state) => state.companyDashboardSlice);

  const viewRestrictedData = dashboardPermissions.includes(
    Constants.DASHBOARD_PERMISSIONS.VIEW_RESTRICTED_DATA
  );

  const tabs = useMemo(() => {
    return [
      {
        id: "companySummary",
        label: "Summary",
      },
      {
        id: "registeredAddress",
        label: "Registered Address",
      },
      {
        id: "shareInformation",
        label: "Share information",
      },
      {
        id: "stakeholderDetails",
        label: "Stakeholder details",
      },
      viewRestrictedData || designation.includes("Shareholder")
        ? {
            id: "beneficialOwnership",
            label: "Beneficial ownership",
          }
        : null,
    ].filter(Boolean);
  }, [viewRestrictedData]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const history = useHistory();
  const isViewMode = history.location.pathname.split("/").pop() === "view";

  const goToPrevStep = () => {
    setSelectedTabIndex(selectedTabIndex - 1);
  };

  useEffect(() => {
    const tempSelectedTab = tabs[selectedTabIndex];
    if (tempSelectedTab) setSelectedTab(tempSelectedTab);
  }, [tabs, selectedTabIndex]);

  return (
    <>
      <div className="overflow-x-auto">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          showTooltip
        />
      </div>
      {selectedTab.id === "companySummary" && (
        <CompanySummary isViewMode={isViewMode} />
      )}
      {selectedTab.id === "registeredAddress" && <RegisteredAddress />}
      {selectedTab.id === "shareInformation" && (
        <ShareInformation disabledRightsEditor />
      )}
      {selectedTab.id === "stakeholderDetails" && (
        <StakeholderDetails companyId={companyId} />
      )}
      {selectedTab.id === "beneficialOwnership" && (
        <BeneficialOwnership
          beneficialOwners={beneficialOwners.items}
          groupedBeneficialOwners={groupedBeneficialOwners}
          handleBackBtn={goToPrevStep}
          hideReviewWorkflow
          renderShortTypeOfControl
          showDocumentLabel
        />
      )}
    </>
  );
}
