/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _get from "lodash/get";

import { updateUserState } from "./user";
import authDataService from "../../services/auth";
import { updateOrganizationsState } from "./organizations";

const initialState = {
  getUserStatus: {
    status: "idle",
    error: false,
  },
  forgotPasswordStatus: {
    status: "idle",
    error: false,
  },
  resetPasswordStatus: {
    status: "idle",
    error: false,
  },
  resendEmailLinkStatus: {
    status: "idle",
    error: false,
  },
};

export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async (_, thunkAPI) => {
    const res = await authDataService.getCurrentUser();
    thunkAPI.dispatch(updateUserState(res.data.currentUser));
    thunkAPI.dispatch(
      updateOrganizationsState({
        organizations: {
          items: res.data.currentUser?.organizations ?? [],
          totalCount: res.data.currentUser?.organizations?.length ?? 0,
        },
      })
    );
    return res.data;
  }
);

export const confirmAccount = createAsyncThunk(
  "auth/confirmAccount",
  async ({ token }) => {
    const res = await authDataService.confirmAccount(token);
    return res.data;
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, thunkAPI) => {
    try {
      const res = await authDataService.forgotPassword(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, thunkAPI) => {
    try {
      const res = await authDataService.resetPassword(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resendEmailLink = createAsyncThunk(
  "/auth/resend-verify-email",
  async (payload, thunkAPI) => {
    try {
      const res = await authDataService.resendEmailLink(payload);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => initialState,
    resetForgotPasswordState: (state) => {
      state.forgotPasswordStatus.status = "idle";
      state.forgotPasswordStatus.error = false;
    },
    resendEmailLinkState: (state) => {
      state.resendEmailLinkStatus.status = "idle";
      state.resendEmailLinkStatus.error = false;
    },
  },
  extraReducers: {
    [getCurrentUser.pending]: (state) => {
      state.getUserStatus.status = "loading";
      state.getUserStatus.error = false;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      return {
        getUserStatus: {
          status: "idle",
          error: false,
        },
        ...action.payload,
      };
    },
    [getCurrentUser.rejected]: (state, action) => {
      state.getUserStatus.status = "failed";
      state.getUserStatus.error = action?.payload?.messageText;
    },
    [forgotPassword.pending]: (state) => {
      state.forgotPasswordStatus.status = "loading";
      state.forgotPasswordStatus.error = false;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.forgotPasswordStatus.status = "success";
      state.forgotPasswordStatus.error = false;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.forgotPasswordStatus.status = "failed";
      state.forgotPasswordStatus.error = action.payload.messageText;
    },
    [resetPassword.pending]: (state) => {
      state.resetPasswordStatus.status = "loading";
      state.resetPasswordStatus.error = false;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.resetPasswordStatus.status = "success";
      state.resetPasswordStatus.error = false;
    },
    [resetPassword.rejected]: (state, action) => {
      state.resetPasswordStatus.status = "failed";
      state.resetPasswordStatus.error = action.payload.messageText;
    },
    [resendEmailLink.pending]: (state, action) => {
      state.resendEmailLinkStatus.status = "loading";
      state.resendEmailLinkStatus.error = false;
    },
    [resendEmailLink.fulfilled]: (state, action) => {
      state.resendEmailLinkStatus.status = "success";
      state.resendEmailLinkStatus.error = false;
    },
    [resendEmailLink.rejected]: (state, action) => {
      state.resendEmailLinkStatus.status = "failed";
      state.resendEmailLinkStatus.error = action.payload.messageText;
    },
  },
});

export const { logout, resetForgotPasswordState, resendEmailLinkState } =
  authSlice.actions;
const { reducer } = authSlice;

export default reducer;
