import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { EyeIcon, SwitchVerticalIcon } from "@heroicons/react/outline";

import { delay, getFullName } from "utils";
import Table from "components/lib/Global/ReactTable";
import StakeholderSubRow from "./StakeholderSubrow";

function StakeholderTable({
  data,
  currentTab,
  currentSubTab,
  isJointShareholderTable,
  isViewMode,
  hideReviewWorkflow,
  goToPrevStep = () => null,
}) {
  const [expandedRow, setExpandedRow] = useState({});
  const [initialRows, setInitialRows] = useState({});
  const { comments } = useSelector((state) => state.commentsSlice);

  const transformSharesAllotted = (sharesAllotted) =>
    sharesAllotted
      .map(
        ({ classOfShares, allottedShares }) =>
          `<div>${classOfShares}: ${allottedShares}</div>`
      )
      .join("");

  const columns = useMemo(
    () =>
      [
        {
          Header: () => (
            <div>
              Name
              {!isJointShareholderTable && (
                <SwitchVerticalIcon className="inline w-4 h-4 ml-1 text-tertiary" />
              )}
            </div>
          ),
          id: "Name",
          accessor: "fullName",
          Cell: ({ row: { original } }) => {
            if (original.fullName) return original.fullName;
            return getFullName(original.names) || "N/A";
          },
          // We can render something different for subRows
          SubCell: (cellProps) => <>{cellProps.value}</>,
        },
        {
          Header: () => (
            <div>
              ID Type
              {!isJointShareholderTable && (
                <SwitchVerticalIcon className="inline w-4 h-4 ml-1 text-tertiary" />
              )}
            </div>
          ),
          id: "ID Type",
          accessor: "idType",
        },
        // add is Director column for joint shareholders
        isJointShareholderTable
          ? {
              Header: () => "Is a Director?",
              id: "isDirector",
              Cell: ({ row }) => {
                return row.original.isDirector;
              },
            }
          : null,
        // add designation column for stakeholder tab
        !isJointShareholderTable && currentTab === "stakeholderTab"
          ? {
              Header: () => (
                <div>
                  Designation
                  {!isJointShareholderTable && (
                    <SwitchVerticalIcon className="inline w-4 h-4 ml-1 text-tertiary" />
                  )}
                </div>
              ),
              id: "Designation",
              Cell: ({ row: { original } }) => {
                if (original.fullName) {
                  return Array.isArray(original.fullName)
                    ? "Joint Shareholder"
                    : original.isDirector === "Yes"
                    ? "Director"
                    : original?.designation ?? "";
                }
                return ["Shareholder", "Director"].every((i) =>
                  original?.role.includes(i)
                )
                  ? "Director Shareholder"
                  : original?.role ?? "";
              },
            }
          : null,
        // add share allocation column for stakeholder tab
        !isJointShareholderTable && currentTab === "stakeholderTab"
          ? {
              Header: () => (
                <div>
                  Shares alloted
                  {!isJointShareholderTable && (
                    <SwitchVerticalIcon className="inline w-4 h-4 ml-1 text-tertiary" />
                  )}
                </div>
              ),
              id: "Shares alloted",
              Cell: ({ row }) => {
                if (
                  row.original?.isDirector === "Yes" &&
                  !Array.isArray(row.original?.fullName)
                )
                  return "";
                return (
                  <span
                    className="space-y-1"
                    dangerouslySetInnerHTML={{
                      __html: transformSharesAllotted(
                        row.original?.sharesAllotted ?? []
                      ),
                    }}
                  />
                );
              },
            }
          : null,
        {
          // Make an expander cell
          Header: () => "Actions", // No header
          id: "expander", // It needs an ID
          Cell: ({ row, ...best }) => {
            const originalRow = row.original;
            let onClick = () => {
              setInitialRows(best.initialRows);
              setExpandedRow(row);
              row.getToggleRowExpandedProps().onClick();
            };
            if (
              !originalRow?.linkToCompany &&
              !originalRow?.shareholderWithBO &&
              currentTab === "beneficialOwnershipTab"
            ) {
              onClick = async () => {
                goToPrevStep();
                await delay(); // wait for the stakeholders tab to be mounted
                let companyShareholderEditBtn = null;
                if (originalRow?.JSGroupID)
                  companyShareholderEditBtn = document.querySelector(
                    `[id="${originalRow?.JSGroupID}"]`
                  );
                else if (originalRow?._id && !originalRow.JSGroupID)
                  companyShareholderEditBtn = document.querySelector(
                    `[id="${originalRow?._id}"]`
                  );
                if (companyShareholderEditBtn) {
                  companyShareholderEditBtn.click();
                }
              };
            }

            const officialComments =
              comments.filter(
                (comment) =>
                  comment?.officialId &&
                  comment.officialId === row.original._id &&
                  comment?.tabName &&
                  comment.tabName === "stakeholderTab"
              )?.length ?? 0;

            return (
              // Use Cell to render an expander for each row.
              // We can use the getToggleRowExpandedProps prop-getter
              // to build the expander.
              <span
                className="text-sm font-normal text-primary relative"
                {...row.getToggleRowExpandedProps()}
                onClick={onClick}
                id={
                  !originalRow.linkToCompany &&
                  currentTab === "beneficialOwnershipTab"
                    ? null
                    : originalRow._id
                }
              >
                <EyeIcon className="inline w-4 h-4 mr-1 text-tertiary" />
                {originalRow.isExpanded ? "Hide Details" : "view details"}
                {officialComments ? (
                  <span className="absolute flex items-center justify-center min-w-[4] h-4 p-1 text-white bg-red-700 rounded-full -top-3 -right-6">
                    {officialComments}
                  </span>
                ) : null}
              </span>
            );
          },
          // We can override the cell renderer with a SubCell to be used with an expanded row
          SubCell: () => null, // No expander on an expanded row
        },
      ].filter(Boolean),
    [isJointShareholderTable, currentTab, comments, goToPrevStep]
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <StakeholderSubRow
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        currentTab={currentTab}
        initialRows={initialRows}
        isViewMode={isViewMode}
        hideReviewWorkflow={hideReviewWorkflow}
      />
    ),
    [currentTab, initialRows, isViewMode]
  );

  useEffect(() => {
    if (expandedRow?.isExpanded) expandedRow?.toggleRowExpanded();
  }, [currentTab, currentSubTab, expandedRow]);

  return (
    <div className="w-full">
      {data?.length > 0 ? (
        <Table
          columns={columns}
          data={data}
          dataRowCellStyle="whitespace-normal"
          headingStyle="bg-sflPrimary text-white"
          renderRowSubComponent={renderRowSubComponent}
        />
      ) : (
        <p className="text-center">No data available</p>
      )}
    </div>
  );
}

export default StakeholderTable;
