import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { SignInPartial } from "./SignInPartial";

export default function VerifiedEmailSuccessSignUp({ user }) {
  const [counter, setCounter] = useState(8);
  const history = useHistory();

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 1) history.push("/login");
  }, [counter]);

  return (
    <div className="grid min-h-screen gap-2 SignIn sm:grid-cols-8">
      <div className="items-center justify-center hidden h-full rounded-md sm:col-span-2 sm:flex"></div>
      <div className="flex items-center justify-center h-full px-4 rounded-md sm:col-span-4 sm:px-0">
        <div className="w-full sm:max-w-[560px]">
          <div className="w-full py-4 sm:px-4 md:px-0">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="w-48">
                  <img
                    src="https://res.cloudinary.com/duqjf9tj1/image/upload/v1639701540/Assets/sfl_bxbwqe.svg"
                    alt="SFL Logo"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-6 mx-auto my-10 bg-white rounded-md shadow-sm drop-shadow-md">
            <div className="grid gap-2">
              <div className="">
                <h1 className="text-2xl text-center text-gray-700">
                  Verification successful
                </h1>
              </div>
              <div className="text-center">
                <p>
                  Thank you for verifying{" "}
                  <span className="font-semibold">{user?.email}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-2 text-center text-gray-400">
            <SignInPartial />
            <p className="text-sm">
              If not automatically redirected within {counter} seconds, manually
              click on this{" "}
              <Link className="font-semibold cursor-pointer" to="/login">
                link.
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="items-center justify-center hidden h-full rounded-md sm:col-span-2 sm:flex"></div>
    </div>
  );
}
