import Footer from "components/lib/Global/Footer";
import Header from "components/lib/Global/Header";
import { useAuth0 } from "@auth0/auth0-react";
import { Constants } from "config/constants";

export default function FrontPage() {
  const { loginWithRedirect } = useAuth0();

  const handleRedirectToSignUpPage = () => {
    if (window.location.href === Constants.SFL_URL) {
      window.location.href = `${Constants.SFL_APP_URL}/register`;
    } else {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    }
  };

  return (
    <>
      <div className="flex flex-col min-h-screen overflow-y-hidden text-lg text-sflPrimary">
        <section className="z-50 ">
          <Header />
        </section>

        <section className="container flex flex-col flex-1 mx-auto items-center ">
          <div className="px-16 max-w-sm sm:max-w-6xl text-center py-32 ">
            <p className="text-base sm:text-lg md:mt-5 md:text-xl ">
              We have made it easy for you to setup and manage your business.
            </p>
            <h1 className="mb-8 font-bold text-6xl leading-tight lg:text-6xl xl:text-8xl">
              Manage your Kenyan business from anywhere,{" "}
              <span className="block text-green-primary xl:inline">
                anytime.
              </span>
            </h1>
            <div className="space-y-6">
              <button
                className="inline-flex items-center w-1/4 shadow-sm justify-center py-4 px-10 text-base font-medium text-white rounded-md bg-green-primary hover:bg-green-primary md:py-4 md:text-lg md:px-6 "
                onClick={handleRedirectToSignUpPage}
              >
                Get Started
              </button>
            </div>
          </div>
        </section>

        {/* <main className="container flex items-center justify-center flex-1 mx-auto mt-0 md:-mt-64">
          <div className="px-16 text-center">
            <p className="max-w-md mx-auto text-base sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              We have made it easy for you to setup and manage your business.
            </p>
            <h1 className="mb-8 font-bold tracking-tighter text-8xl">
              Manage your Kenyan business from anywhere,{" "}
              <span className="block text-green-primary xl:inline">
                anytime.
              </span>
            </h1>
            <div>
              <Link
                to="/register"
                className="inline-flex items-center py-2 text-base font-medium text-white rounded bg-green-primary hover:bg-green-primary md:py-4 md:text-lg md:px-7"
              >
                Get started
                <ChevronRightIcon className="w-7 h-7" />
              </Link>
            </div>
          </div>
        </main> */}

        <div>
          <Footer />
        </div>

        {/* <BackgroundImg /> */}
        <div className="absolute right-0 min-h-screen z-index-10">
          <svg
            width="496"
            height="1024"
            viewBox="0 0 496 900"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="495.5"
              cy="453.5"
              r="495.5"
              fill="#431646"
              fillOpacity="0.03"
            />
          </svg>
        </div>
      </div>
    </>
  );
}
