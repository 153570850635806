import React from "react";

import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import ContactPersonCompaniesList from "components/ContactPersonCompaniesList";

export default function ContactPersonCompaniesListPage() {
  return (
    <DashboardWrapper>
      <ContactPersonCompaniesList />
    </DashboardWrapper>
  );
}
