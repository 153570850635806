import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import { useHistory, useParams } from "react-router-dom";

import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import SelectIdType from "./SelectIdType";
import GeneralInformation from "./GeneralInformation";
import AddressDetails from "./AddressDetails";
import Ownership from "./Ownership";
import UploadDocuments from "./UploadDocuments";
import SummaryTab from "./SummaryTab";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import RoleSwitch from "components/lib/Shared/RoleSwitch";
import { classNames, getFullName, getGreetings } from "utils";
import { getCurrentUser } from "state/slices/auth";
import { getCorporate } from "state/slices/organizations";
import { getComments } from "state/slices/comment";
import { getAccessRequests } from "state/slices/accessRequest";
import identificationValidation from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/identificationValidation";
import residentialDetailsValidation from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/residentialDetailsValidation";
import { validateOrganizationRestrictions } from "utils/organization";
import uploadDocumentsValidation from "components/UserOnboarding/validations/uploadDocumentsValidation";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import { Constants } from "config/constants";
import { setCookie } from "utils/cookie";

const initialNavigation = [
  { id: "selectIDType", name: "Select ID Type" },
  { id: "generalInformation", name: "General Information" },
  { id: "addressDetails", name: "Address Details" },
  { id: "ownership", name: "Ownership" },
  { id: "uploadDocuments", name: "Upload Documents" },
  { id: "summary", name: "Summary" },
];

const initialValidTabs = {
  selectIDType: false,
  generalInformation: false,
  addressDetails: false,
  ownership: false,
  uploadDocuments: false,
};

export default function CorporateOnboarding() {
  const history = useHistory();
  const params = useParams();
  const cookieRole = Cookies.get("role");
  const [navigation, setNavigation] = useState([]);
  const [validTabs, setValidTabs] = useState(initialValidTabs);
  const { isAuthenticated, user } = useAuth0();
  const dispatch = useDispatch();
  const [currentSection, setCurrentSection] = useState(0);

  const currentUser = useSelector((state) => state.userSlice);
  const partners = useSelector((state) => state.partnersSlice);
  const { getUserStatus } = useSelector((state) => state.authSlice);
  const { transformedComments = {} } = useSelector(
    (state) => state.commentsSlice
  );
  const accessRequests = useSelector(
    (state) => state.accessRequestsSlice.items
  );

  const { items: contactPersonCorporates } = useSelector(
    (state) => state.contactPersonCorporatesSlice
  );
  const { getOrganization, organization } = useSelector(
    (state) => state.organizationsSlice
  );
  const { names } = organization || {};
  const isViewMode = history.location.pathname.split("/").pop() === "view";

  useEffect(() => {
    const tempValidTabs = {
      selectIDType: false,
      generalInformation: false,
      addressDetails: false,
      ownership: false,
      uploadDocuments: false,
    };
    const { idType, isNonResident, partnershipType } = organization || {};
    const checkSidebarNavigationValidation = async () => {
      tempValidTabs.selectIDType = Boolean(idType) && Boolean(partnershipType);
      tempValidTabs.generalInformation = await identificationValidation(
        idType
      ).schema.isValid(organization);
      tempValidTabs.addressDetails = await residentialDetailsValidation({
        idType,
        isNonResident,
      }).schema.isValid(organization);
      const { errors } = validateOrganizationRestrictions({
        partnershipType: organization?.partnershipType,
        partners,
      });
      tempValidTabs.ownership = errors.length === 0;
      tempValidTabs.uploadDocuments = await uploadDocumentsValidation(
        getDocumentData(idType)
      ).schema.isValid(organization);
      setValidTabs(tempValidTabs);
    };
    checkSidebarNavigationValidation();

    return () => {
      setValidTabs(initialValidTabs);
    };
  }, [currentUser, organization, partners.length]);

  useEffect(() => {
    if (organization?._id) {
      dispatch(getComments({ organizationId: organization._id }));
    }
  }, [organization]);

  const pendingRequests = useMemo(
    () =>
      accessRequests.filter(
        (accessRequest) =>
          accessRequest.status === "Pending" &&
          accessRequest?.organization?._id &&
          accessRequest?.organization?._id === organization?._id
      ),
    [accessRequests]
  );

  useEffect(() => {
    const disableGeneralInformation = !validTabs.selectIDType;
    const disableAddressDetails =
      disableGeneralInformation || !validTabs.generalInformation;
    const disableOwnership = disableAddressDetails || !validTabs.addressDetails;
    const disableFileUploads = disableOwnership || !validTabs.ownership;
    const disableSummary = disableFileUploads || !validTabs.uploadDocuments;

    const defaultNavigation = [
      {
        id: "selectIDType",
        name: "Select ID Type",
        commentsCount: _get(transformedComments, "idType.commentsCount", 0),
      },
      {
        id: "generalInformation",
        name: "General Information",
        commentsCount:
          _get(transformedComments, "names.commentsCount", 0) +
          _get(transformedComments, "identificationDetails.commentsCount", 0),
        disabled: disableGeneralInformation,
      },
      {
        id: "addressDetails",
        name: "Address Details",
        commentsCount:
          _get(transformedComments, "postalAddress.commentsCount", 0) +
          _get(transformedComments, "electronicAddress.commentsCount", 0) +
          _get(
            transformedComments,
            "residentialAddressDetails.commentsCount",
            0
          ),
        disabled: disableAddressDetails,
      },
      {
        id: "ownership",
        name: "Ownership",
        commentsCount: _get(transformedComments, "ownershipCommentCount"),
        disabled: disableOwnership,
      },
      {
        id: "uploadDocuments",
        name: "Upload Documents",
        commentsCount: _get(
          transformedComments,
          "uploadDocuments.commentsCount",
          0
        ),
        disabled: disableFileUploads,
      },
      {
        id: "summary",
        name: "Summary",
        disabled: disableSummary,
      },
    ];
    if (isViewMode) {
      setNavigation([{ id: "summary", name: "Summary" }]);
      return;
    }
    if (
      pendingRequests.length > 0 ||
      cookieRole === Constants.COOKIE_ROLES.OFFICIAL
    ) {
      setNavigation(defaultNavigation.slice(0, 4));
    } else setNavigation(defaultNavigation);
  }, [transformedComments, validTabs, pendingRequests, cookieRole, isViewMode]);

  useEffect(() => {
    if (isAuthenticated && !currentUser._id) {
      dispatch(getCurrentUser());
    }
  }, [isAuthenticated, currentUser._id]);

  useEffect(() => {
    if (isViewMode) {
      dispatch(getCorporate({ corporateId: params?.corporateId }));
    }
    return () => {
      setCookie("role", Constants.COOKIE_ROLES.APPLICANT);
    };
  }, [isViewMode]);

  useEffect(() => {
    const partnerOrganization = currentUser?.linkedDraftOrganizations?.find(
      ({ _id }) => _id === params?.corporateId
    );

    const applicantOrganization = currentUser?.organizations?.find(
      ({ corporate, role }) =>
        role === 12 && corporate?._id === params?.corporateId
    );

    const isContactPerson = contactPersonCorporates.find(
      (corporate) => corporate._id === params?.corporateId
    );

    if (currentUser._id && !isViewMode) {
      if (applicantOrganization || partnerOrganization || isContactPerson) {
        dispatch(getCorporate({ corporateId: params?.corporateId }));
        return;
      } else history.push(`/404`);
    }
  }, [currentUser?.organizations?.length, isViewMode]);

  useEffect(() => {
    if (currentUser?.electronicAddress?.emailAddress) {
      dispatch(
        getAccessRequests({
          query: {
            initiatedToEmail: currentUser.electronicAddress.emailAddress,
          },
        })
      );
    }
  }, [currentUser?.electronicAddress?.emailAddress, partners.length]);

  const handleBackBtn = () => {
    if (currentSection - 1 >= 0) setCurrentSection(currentSection - 1);
  };

  const handleNextBtn = () => {
    if (navigation.length - 1 === currentSection) {
      history.push("/transactions");
      return;
    }
    setCurrentSection(currentSection + 1);
  };

  const selectedTabId = navigation?.[currentSection]?.id;

  return (
    <DashboardWrapper>
      <div>
        <div className="flex justify-between pt-16 mb-10">
          <div className="py-4">
            <h2 className="font-semibold text-title5">
              {getGreetings()}, {getFullName(currentUser?.names ?? {})}
            </h2>
          </div>
          <RoleSwitch organizationName={names?.companyName} />
        </div>
        <div className="grid grid-cols-4 border-t border-gray">
          {/* sidebar */}
          <div className="col-span-4 pr-8 md:col-span-1 pt-14">
            <p className="mb-4 text-primary">
              Complete the following steps to set up your account
            </p>
            <ul className="flex flex-col space-y-1">
              {navigation.map((item, index) => (
                <button
                  key={item.name}
                  className={classNames(
                    index === currentSection
                      ? "bg-gray-50 text-sflPrimary hover:bg-white"
                      : "rounded-md px-3 py-2 text-tertiary flex items-center text-base font-medium",
                    "flex max-w-max relative items-center px-3 py-2 text-base font-medium hover:text-sflPrimary hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300"
                  )}
                  onClick={() => setCurrentSection(index)}
                  disabled={item.disabled}
                >
                  <span className="truncate">
                    {item.name}
                    {item?.commentsCount > 0 && (
                      <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-1 -right-2">
                        <span>{item.commentsCount}</span>
                      </span>
                    )}
                  </span>
                </button>
              ))}
            </ul>
          </div>

          {/* Detailed section */}
          <div className="col-span-4 md:border-l md:col-span-3 md:pl-11 pt-14 border-gray">
            {getOrganization.status === "loading" ||
            getUserStatus.status === "loading" ? (
              <div className="flex justify-center mx-auto text-white">
                <SpinnerIcon className="text-gray-400" />
              </div>
            ) : (
              <>
                {selectedTabId === "selectIDType" && (
                  <SelectIdType handleNextBtn={handleNextBtn} />
                )}
                {selectedTabId === "generalInformation" && (
                  <GeneralInformation
                    handleBackBtn={handleBackBtn}
                    handleNextBtn={handleNextBtn}
                  />
                )}
                {selectedTabId === "addressDetails" && (
                  <AddressDetails
                    handleBackBtn={handleBackBtn}
                    handleNextBtn={handleNextBtn}
                  />
                )}
                {selectedTabId === "ownership" && (
                  <Ownership
                    handleBackBtn={handleBackBtn}
                    handleNextBtn={handleNextBtn}
                  />
                )}
                {selectedTabId === "uploadDocuments" && (
                  <UploadDocuments
                    handleBackBtn={handleBackBtn}
                    handleNextBtn={handleNextBtn}
                  />
                )}
                {selectedTabId === "summary" && (
                  <SummaryTab
                    handleBackBtn={handleBackBtn}
                    hideResolveAllCheckbox
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
