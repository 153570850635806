import { useMemo } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _pickBy from "lodash/pickBy";
import _merge from "lodash/merge";
import _get from "lodash/get";
import _orderBy from "lodash/orderBy";

import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import SectionDescription from "components/lib/Shared/SectionDescription";
import businessDocumentsValidation from "./businessDocumentsValidation";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import { handleFilesUpload } from "utils/filesUtils";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import { updateCompanyAsync, updateCompanyState } from "state/slices/company";
import { getBusinessDocumentData } from "../../CompanyOfficialsTab/FileUploadForm/documentData";
import Input from "components/lib/Shared/Input";
import {
  CORPORATES,
  INDIVIDUALS,
} from "components/PLCTABS/SFLdata/config/constants";
import { calculateCumulativeOwnership, groupBeneficialOwners } from "utils";

const BusinessDocuments = ({ handleBackBtn, handleNextBtn }) => {
  const { company } = useSelector((state) => state.companySlice);
  const { preSignedBusinessDocuments = {}, registrationNumber } = company || {};
  const dispatch = useDispatch();

  const beneficialOwners = useSelector(
    (state) => state.beneficialOwnersTableSlice.beneficialOwners
  );

  const reportableBO = useMemo(() => {
    let tempReportableBO = [];
    let tempNonReportableBO = [];
    if (beneficialOwners.length > 0) {
      beneficialOwners.forEach((bo) => {
        const {
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = bo?.beneficialOwnershipForm ?? {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";
        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";

        // hide eleventh minority entry on BO table
        if (bo.idType === "Minority") {
          bo = {
            ...bo,
            hidden: true,
          };
        }

        if (!bo?.hidden === true) {
          const parentBO = bo?.shareholderWithBO
            ? beneficialOwners.find(
                (official) => official._id === bo.shareholderWithBO
              )
            : {};
          if (
            INDIVIDUALS.includes(bo.idType) &&
            bo.isReportable &&
            (CORPORATES.includes(parentBO.idType)
              ? isDirectBeneficiary
              : isDirectBeneficiary || isIndirectBeneficiary) &&
            bo.isEstateDistributionDetermined !== "No" &&
            bo.idType !== "Estate"
          ) {
            tempReportableBO.push(bo);
          } else tempNonReportableBO.push(bo);
        }
      });
    }

    const verifyKeys = [
      "identificationDetails.nationalIDNumber",
      "identificationDetails.foreignCertificateID",
      "identificationDetails.passportNumber",
      "identificationDetails.birthCertificateNumber",
    ];

    let filteredTempReportableBOs = [...tempReportableBO];
    let filteredTempNonReportableBOs = [...tempNonReportableBO];
    // if one of the multiple instances is reportable shift non-reportable instances to reportable table
    verifyKeys.map((verifyKey) => {
      tempNonReportableBO.map((bo) => {
        // check if current bo has reportable entry/instance
        const hasReportableInstance = tempReportableBO.find(
          (reportableBoRow) =>
            _get(reportableBoRow, verifyKey) &&
            _get(reportableBoRow, verifyKey) === _get(bo, verifyKey)
        );
        if (hasReportableInstance) {
          filteredTempReportableBOs.push(bo);
          const boIndex = filteredTempNonReportableBOs.findIndex(
            (bo) =>
              _get(bo, verifyKey) === _get(hasReportableInstance, verifyKey)
          );
          filteredTempNonReportableBOs.splice(boIndex, 1);
        }
      });
    });

    filteredTempReportableBOs = groupBeneficialOwners(
      _orderBy(
        filteredTempReportableBOs,
        ["shareholderWithBO", "linkToCompany"],
        ["desc", "desc"]
      )
    );

    filteredTempNonReportableBOs = groupBeneficialOwners(
      _orderBy(
        filteredTempNonReportableBOs,
        ["shareholderWithBO", "linkToCompany"],
        ["desc", "desc"]
      )
    );

    tempNonReportableBO = filteredTempNonReportableBOs;

    const reportableBOAfterCumulative = tempNonReportableBO
      .filter((bo) => INDIVIDUALS.includes(bo.idType))
      .filter((bo) => {
        const { cumulativeShareholdings, cumulativeVotings } =
          calculateCumulativeOwnership(bo, beneficialOwners);
        if (cumulativeShareholdings >= 10 || cumulativeVotings >= 10) {
          const indexOfBO = tempNonReportableBO.findIndex(
            (beneficialOwner) => beneficialOwner._id === bo._id
          );
          filteredTempNonReportableBOs.splice(indexOfBO, 1);
          return true;
        }
      });

    return [...filteredTempReportableBOs, ...reportableBOAfterCumulative];
  }, [beneficialOwners]);

  const fileUploads = preSignedBusinessDocuments;

  const handleSubmit = async (formData) => {
    const uploadedFiles = await handleFilesUpload(formData.fileUploads);
    const companyData = {
      registrationNumber: formData.registrationNumber,
      preSignedBusinessDocuments: {
        ..._pickBy(formData.fileUploads, (file) => typeof file === "string"),
        ...uploadedFiles,
      },
    };
    dispatch(
      updateCompanyState({
        company: {
          ...company,
          ...companyData,
        },
      })
    );
    await dispatch(
      updateCompanyAsync({
        id: company?._id,
        data: companyData,
      })
    );
    handleNextBtn();
  };

  const documents = getBusinessDocumentData();

  const { schemaInitialValues, schema } = businessDocumentsValidation(
    documents,
    reportableBO.length
  );

  const initialValues = _merge(schemaInitialValues, {
    registrationNumber,
    fileUploads,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="mt-4">
            <SectionDescription
              title="Upload Incorporation forms"
              description="Please upload the incorporation forms generated from the BRS Portal, to be signed by the promoters and subscribers of the Company."
            />
            <div className="bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
              <div className="px-4 py-6 space-y-6 sm:p-6">
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="registrationNumber"
                    className="block mb-2 text-sm text-primary font-medium min-w-[13rem] w-52 whitespace-normal after:content-['*'] after:ml-0.5 after:text-red-500"
                  >
                    Registration Number
                  </label>
                  <div className="flex flex-col w-full">
                    <Field
                      name="registrationNumber"
                      id="registrationNumber"
                      placeholder="Add registration number"
                      component={Input}
                      className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Upload documents
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Please provide the correct documents as instructed.
                  </p>
                </div>
                <Field
                  name="fileUploads"
                  component={FileUploadForm}
                  documentData={documents.map((document) => {
                    if (document.name === "articlesOfAssociation") {
                      return {
                        ...document,
                        disableReplace: true,
                      };
                    }
                    return document;
                  })}
                />
              </div>
            </div>
            <div className="flex justify-between py-3">
              <button
                type="button"
                disabled={isSubmitting}
                onClick={handleBackBtn}
                className="flex items-center px-2 py-2 button button-back button-back-hover button-focus button-disabled button-outline"
              >
                <div className="mr-1.5 flex items-center">
                  <ChevronLeftIcon />
                  Back to previous step
                </div>
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex px-2 py-2 text-white button button-hover button-disabled button-outline button-focus bg-sflPrimary"
              >
                {isSubmitting ? (
                  <>
                    <SpinnerIcon />
                    Saving...
                  </>
                ) : (
                  <>
                    <div className="ml-1.5 flex items-center">
                      Save and proceed
                      <ChevronRightIcon />
                    </div>
                  </>
                )}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BusinessDocuments;
