import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

const variantStyles = {
  primary:
    "py-2 px-4 text-white border border-transparent shadow-sm bg-sflPrimary hover:bg-green-primary hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sflPrimary focus:bg-green-focused disabled:bg-optional",
  secondary:
    "py-2 px-4 text-white bg-gray-500 border border-transparent hover:bg-optional hover:text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:bg-optional",
  danger:
    "py-2 px-4 text-white bg-red-500 border border-transparent shadow-sm hover:bg-optional hover:text-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-optional",
  outline:
    "py-2 px-4 px-10 text-gray-700 bg-white border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
  text: "inline-flex items-center justify-center space-x-2 text-sm font-medium rounded-md text-sflPrimary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sflPrimary",
  backBtn:
    "flex button button-back button-back-hover items-center button-focus px-2 py-2 button-disabled button-outline button-focus",
};

const colorStyles = {
  gray: "bg-gray-600 hover:bg-gray-700 focus:bg-gray-800 text-white hover:text-gray-200 focus:text-gray-200 focus:ring-gray-500",
};

const Button = React.forwardRef(
  (
    {
      className,
      overrideBaseStyles,
      href,
      children,
      preIcon: PreIcon,
      postIcon: PostIcon,
      disabled = false,
      type = "button",
      variant = "primary",
      color,
      isLoading = false,
      loadingText = "Submitting...",
      ...props
    },
    ref
  ) => {
    const buttonClasses = overrideBaseStyles
      ? className
      : twMerge(
          "inline-flex items-center text-sm gap-1 justify-center font-medium rounded-md outline-offset-2 transition active:transition-none disabled:cursor-not-allowed disabled:text-gray-500",
          variantStyles?.[variant] ?? "",
          colorStyles?.[color] ?? "",
          className
        );

    return href ? (
      <Link href={href} className={buttonClasses} {...props}>
        {isLoading ? (
          <>
            <SpinnerIcon />
            {loadingText}
          </>
        ) : (
          children
        )}
      </Link>
    ) : (
      <button
        className={buttonClasses}
        disabled={disabled || isLoading}
        type={type}
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <>
            <SpinnerIcon className="mr-0" />
            {loadingText}
          </>
        ) : (
          <>
            {PreIcon && (
              <span className="flex items-center">
                <PreIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
              </span>
            )}
            {children}
            {PostIcon && (
              <span className="flex items-center">
                <PostIcon
                  className="flex-shrink-0 w-5 h-5"
                  aria-hidden="true"
                />
              </span>
            )}
          </>
        )}
      </button>
    );
  }
);

export default Button;
