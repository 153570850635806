import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import discountAssigneeService from "services/discountAssignee";

export const getDiscountAssigneesAsync = createAsyncThunk(
  "discountAssignees/getDiscountAssigneesAsync",
  async (queryParams) => {
    const res = await discountAssigneeService.getAll(queryParams);
    return res.data;
  }
);

export const addDiscountAssigneeAsync = createAsyncThunk(
  "discountAssignees/addDiscountAssigneeAsync",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await discountAssigneeService.create(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDiscountAssigneeAsync = createAsyncThunk(
  "discountAssignees/deleteDiscountAssigneeAsync",
  async ({ discountAssigneeId }) => {
    await discountAssigneeService.remove(discountAssigneeId);
    return { discountAssigneeId };
  }
);

export const getDiscountAssigneeAsync = createAsyncThunk(
  "discountAssignees/getDiscountAssigneeAsync",
  async (queryParams, { rejectWithValue }) => {
    try {
      const res = await discountAssigneeService.getUser(queryParams);
      return res.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const initialState = {
  discountAssignees: {
    items: [],
    totalCount: 0,
  },
  discountAssigneesToDelete: [],
  getDiscountAssignees: {
    status: "idle",
    error: false,
  },
};

const discountAssigneesSlice = createSlice({
  name: "discountAssignee",
  initialState,
  reducers: {
    updateDiscountAssigneeSlice: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetDiscountAssigneeSlice: () => initialState,
    addDiscountAssignee: (state, action) => {
      state.discountAssignees.items.push(action.payload.discountAssignee);
      state.discountAssignees.totalCount += 1;
    },
  },
  extraReducers: {
    [getDiscountAssigneesAsync.pending]: (state) => {
      state.getDiscountAssignees.status = "loading";
      state.getDiscountAssignees.error = false;
    },
    [getDiscountAssigneesAsync.fulfilled]: (state, action) => {
      state.getDiscountAssignees.status = "idle";
      state.discountAssignees = action.payload;
    },
    [getDiscountAssigneesAsync.rejected]: (state, action) => {
      state.getDiscountAssignees.status = "failed";
      state.getDiscountAssignees.error = action?.payload?.messageText;
    },
  },
});

export const {
  updateDiscountAssigneeSlice,
  addDiscountAssignee,
  resetDiscountAssigneeSlice,
} = discountAssigneesSlice.actions;

export default discountAssigneesSlice.reducer;
