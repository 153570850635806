import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import VerifiedEmailSuccessSignUp from "components/Auth/Partials/VerifiedEmailSuccessSignUp";
import { confirmAccount } from "state/slices/auth";

export default function ConfirmAccount() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { token } = useParams();
  const [user, setUser] = useState(null);

  useEffect(async () => {
    if (token) {
      const response = await dispatch(confirmAccount({ token }));
      if (response?.error?.message) {
        setError(
          response.error.message === "Request failed with status code 401"
            ? "Link is not valid."
            : "Something went wrong, please try again later."
        );
      }
      if (response?.payload?.user) setUser(response?.payload?.user);
      setLoading(false);
    }
  }, [token]);

  if (loading) return <Loader message={"Verifying..."} />;

  if (error) return <span>{error}</span>;

  return <VerifiedEmailSuccessSignUp user={user} />;
}

const Loader = ({ message }) => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-white z-50">
      <div className="bg-white">
        <div className="flex flex-col items-center justify-between space-x-4 h-60 rounded-md shadow-md p-8">
          <div className="w-58">
            <img
              src="https://res.cloudinary.com/duqjf9tj1/image/upload/v1639701540/Assets/sfl_bxbwqe.svg"
              alt="SFL Logo"
              className="w-full"
            />
          </div>
          <div className="flex space-x-2">
            <svg
              className="animate-spin w-6 text-green-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5a8 8 0 018-8h4c0 6.627-5.373 12-12 12v-4zM6 12a6 6 0 016-6V0C7.925 0 2 5.373 2 12h4zm2 5a6 6 0 016-6h4c0 3.313-2.687 6-6 6v-6zm2 5a4 4 0 004-4h-4v4z"
              ></path>
            </svg>
            <p className="text-gray-700">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
