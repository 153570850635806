/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import { useFormikContext, Field } from "formik";

import ElectronicAddress from "./ElectronicAddress";
import Input from "components/lib/Shared/Input";
import ResidentialAddressDetailsFormShared from "./ResidentialAddressDetailsFormShared";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "components/PLCTABS/SFLdata/addressOptions";

// Residential Address Specific Details For Foreigners && Minor
const ResidentialAddressDetailsFormFoMi = (props) => {
  const { field, disabled, disableEmailAddress, selectedValueType } = props;
  const { setFieldValue } = useFormikContext();
  const selectedCountry = options.countries.find(
    (country) => country.value === field.value?.country
  );

  useEffect(() => {
    // for validation
    setFieldValue(field.name, {
      city: "",
      country: "",
      ...field.value,
    });
  }, []);

  const handleSetValue = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (option) => {
    setFieldValue(field.name, {
      ...field.value,
      country: option.value,
    });
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Field
        name="electronicAddress"
        component={ElectronicAddress}
        disabled={
          selectedValueType === "changes-including-email" ? false : true
        }
        disableEmailAddress={disableEmailAddress}
      />
      <div className="px-4 mt-4 space-y-4 bg-white shadow-sm sm:rounded-sm sm:p-6">
        <ResidentialAddressDetailsFormShared {...props} />
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="City"
              name="city"
              placeholder="Enter city"
              value={field.value?.city ?? ""}
              errorPath="residentialAddressDetails.city"
              disabled={disabled}
              onBlur={field.onBlur}
              onChange={handleSetValue}
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Field
              {...field}
              label="Country"
              value={selectedCountry}
              name="country"
              component={ReactSelectWithFormik}
              options={options.countries}
              errorPath="residentialAddressDetails.country"
              placeholder="Select country"
              isDisabled={disabled}
              onBlur={field.onBlur}
              onChange={handleSelect}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResidentialAddressDetailsFormFoMi;
