import React from "react";
import { useFormikContext } from "formik";
import { format } from "date-fns";
import DatePickerField from "components/lib/Shared/DatePickerField";

const ManageCessationDate = ({ field }) => {
  const { setFieldValue } = useFormikContext();

  const handleAppointmentDateValue = (date) => {
    const formattedDate = format(date, "yyyy/MM/dd");
    setFieldValue(field.name, formattedDate);
  };

  return (
    <div className="col-span-6 sm:col-span-3">
      <DatePickerField
        label="Date of Ceasing"
        onChange={handleAppointmentDateValue}
        selected={field.value ? new Date(field.value) : null}
        name="cessationDate"
        placeholderText="Enter date of cessation"
        errorPath="identificationDetails.cessationDate"
        disabled={false}
      />
    </div>
  );
};

export default ManageCessationDate;
