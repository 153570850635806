import Cookies from "js-cookie";

export const setCookie = (name, value) => {
  Cookies.set(name, value, {
    domain: window.location.host.includes("simpleformations.ke")
      ? ".simpleformations.ke"
      : window.location.host.includes("localhost")
      ? "localhost"
      : window.location.host,
  });
};
