import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import { SignInPartial, SignUpPartial } from "./Auth/Partials/SignInPartial";

export default function AcceptCoApplicantInvite({ redirectTo }) {
  const [counter, setCounter] = useState(5);
  const { logout, isAuthenticated } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const countdown = setInterval(() => {
      if (counter > 1) {
        setCounter(counter - 1);
      } else {
        clearInterval(countdown);
        handleRedirect();
      }
    }, 1000);

    return () => clearInterval(countdown); // Cleanup function to clear interval
  }, [counter]);

  const handleRedirect = () => {
    Cookies.remove("role");
    if (redirectTo === "login") {
      if (isAuthenticated) {
        history.push("/"); // Redirect to the homepage if already authenticated
      } else {
        window.location.href = `${window.location.origin}/login`; // Redirect to the sign-in page
      }
    } else {
      if (isAuthenticated) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      } else {
        window.location.href = `${window.location.origin}/${redirectTo}`;
      }
    }
  };

  return (
    <div className="grid min-h-screen gap-2 SignIn sm:grid-cols-8">
      <div className="items-center justify-center hidden h-full rounded-md sm:col-span-2 sm:flex"></div>
      <div className="flex items-center justify-center h-full px-4 rounded-md sm:col-span-4 sm:px-0">
        <div className="w-full sm:max-w-[560px]">
          <div className="w-full py-4 sm:px-4 md:px-0">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="w-48">
                  <img
                    src="https://res.cloudinary.com/duqjf9tj1/image/upload/v1639701540/Assets/sfl_bxbwqe.svg"
                    alt="SFL Logo"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="p-6 mx-auto my-10 bg-white rounded-md shadow-sm drop-shadow-md">
            <div className="grid gap-2">
              <div className="">
                <h1 className="text-2xl text-center text-gray-700">
                  Invitation accepted successfully
                </h1>
              </div>
            </div>
          </div>
          <div className="space-y-2 text-center text-gray-400">
            {redirectTo === "login" ? <SignInPartial /> : <SignUpPartial />}
            <p className="text-sm">
              If not automatically redirected within {counter} seconds, manually
              click on this{" "}
              <span
                className="cursor-pointer text-sflPrimary"
                onClick={handleRedirect}
              >
                link.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="items-center justify-center hidden h-full rounded-md sm:col-span-2 sm:flex"></div>
    </div>
  );
}
