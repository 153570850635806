import Footer from "components/lib/Global/Footer";
import { Logo } from "logo";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function AboutUs() {
  const { loginWithRedirect } = useAuth0();

  const handleRedirectToLoginPage = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "login",
        redirect_uri: window.location.origin,
        base_api_url: process.env.REACT_APP_BASE_API_URL,
      },
    });
  };
  return (
    <>
      <div className="flex flex-col min-h-screen overflow-y-hidden text-lg text-sflPrimary">
        <section className="z-50 ">
          <header className="container p-4 mx-auto sm:mt-16">
            <div className="flex justify-between">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to="/">
                  <div className="w-40">
                    <Logo />
                  </div>
                </Link>
              </div>

              <div>
                <div className="flex items-center space-x-4">
                  <p>Already have an account?</p>

                  <button
                    className="pb-1 font-semibold border-b-2 text-sflPrimary"
                    onClick={handleRedirectToLoginPage}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </header>
        </section>

        <main className="container flex flex-col mx-auto">
          <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
            <section>
              <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
                <div className="w-full lg:w-6/12">
                  <h2 className="w-full font-bold lg:text-4xl text-3xl lg:leading-10 leading-9">
                    We are a platform as a service that that enables companies
                    to manage their corporate and legal compliance requirements
                    and due diligence.
                  </h2>

                  <div className="font-normal text-base leading-6 text-gray-600 mt-6">
                    <div>
                      <h3 className="font-medium text-base text-gray-500 hover:text-gray-900 py-3">
                        The problem
                      </h3>
                    </div>
                    <div className="space-y-4">
                      <p>
                        Time - Managing legal entities using legacy registers
                        and excel sheets is very difficult and time consuming,
                        prone to errors of omission and commission during data
                        entry.
                      </p>
                      <p>
                        Verification - It is impossible to validate or ascertain
                        the accuracy of the information contained in the
                        Registers or in the Minute Book of the Company due to
                        lack of an audit trail.
                      </p>
                      <p>Data Integrity - Data consistency in not possible.</p>

                      <p>
                        Access, Control and Confidentiality - Access to a
                        Company’s records is limited to a few individuals who
                        have access to the physical registers, with no
                        restrictions on what an individual can see or do when
                        they have the Register at any given moment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12">
                  <img
                    className="lg:block hidden w-full"
                    src="https://images.unsplash.com/photo-1573496358773-bdcdbd984982?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                    alt="people discussing on board"
                  />
                  <img
                    className="lg:hidden sm:block hidden w-full"
                    src="https://images.unsplash.com/photo-1573496358773-bdcdbd984982?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                    alt="people discussing on board"
                  />
                  <img
                    className="sm:hidden block w-full"
                    src="https://images.unsplash.com/photo-1573496358773-bdcdbd984982?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                    alt="people discussing on board"
                  />
                </div>
              </div>
            </section>

            <section className="mt-24">
              <div className="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
                <div className="w-full lg:w-6/12 rounded-md relative">
                  <img
                    className="lg:block hidden w-full"
                    src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                    alt="people discussing on board"
                  />
                  <img
                    className="lg:hidden sm:block hidden w-full"
                    src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                    alt="people discussing on board"
                  />
                  <img
                    className="sm:hidden block w-full"
                    src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80"
                    alt="people discussing on board"
                  />
                </div>
                <div className="w-full lg:w-6/12">
                  <div className="font-normal text-base leading-6 text-gray-600 ">
                    <div>
                      <h3 className="font-medium text-base text-gray-500 hover:text-gray-900 py-3">
                        The solution
                      </h3>
                    </div>
                    <div className="space-y-4">
                      <p>
                        A single access platform as a service designed for
                        company secretaries, lawyers, paralegals and company
                        owners to collaboratively manage their entities from one
                        place.
                      </p>
                      <p>
                        Our product unique features include collating of Owners
                        and Directors full particulars, online collaboration on
                        the production of incorporation documents (articles and
                        rights attaching to shares), capturing the chain of
                        control and ownership for beneficial owners, controls on
                        making of applications to change any aspect of the
                        Company’s records, electronic signatures and automated
                        Statutory Registers.
                      </p>
                    </div>
                  </div>
                  <div className="mt-6">
                    <p>
                      Our Founder, Malachi Adedeh, has more than 15 years
                      providing consultancy services in corporate governance,
                      registration and set-up of all types of companies, and
                      more than four years advising the Business Registration
                      Service, through the Joint Liaison Committee, on their
                      system requirements for the eCitizen platform for
                      maintenance of companies. He has unparalleled network of
                      corporate lawyers and company secretaries in the Kenyan
                      market.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <div className="flex lg:flex-row flex-col md:gap-14 gap-16 justify-between lg:mt-20 my-16">
              <div className="w-full lg:w-6/12">
                <div className="">
                  <h2 className="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800">
                    The Mission
                  </h2>
                  <p className="font-normal text-base leading-6 text-gray-600 mt-6 w-full lg:w-10/12 xl:w-9/12">
                    To provide our clients with a platform as a service that
                    enables them to manage their companies’ lifecycle, with
                    clear emphasis on meeting ever changing due diligence,
                    corporate and legal compliance requirements.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-6/12">
                <div>
                  <h2 className="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800">
                    The Vision
                  </h2>
                  <p className="font-normal text-base leading-6 text-gray-600 mt-6 w-full lg:w-10/12 xl:w-9/12">
                    To encourage and increase the adoption of automation in the
                    registration and maintenance of businesses in emerging
                    markets.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="w-full ">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 lg:gap-12 gap-10">
                  {/* <!-- Press Card --> */}
                  <div className="flex p-4 shadow-md">
                    <div className="mr-6">
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28.5 7.5H7.5C5.84315 7.5 4.5 8.84315 4.5 10.5V25.5C4.5 27.1569 5.84315 28.5 7.5 28.5H28.5C30.1569 28.5 31.5 27.1569 31.5 25.5V10.5C31.5 8.84315 30.1569 7.5 28.5 7.5Z"
                          stroke="#1F2937"
                          strokeWidth="2.75"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.5 10.5L18 19.5L31.5 10.5"
                          stroke="#1F2937"
                          strokeWidth="2.75"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="">
                      <p className="font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800">
                        Get in touch
                      </p>
                      <p className="mt-2 font-normal text-base leading-6 text-gray-600">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div>
          <Footer />
        </div>

        {/* <BackgroundImg /> */}
        <div className="absolute right-0 min-h-screen z-index-10">
          <svg
            width="496"
            height="1024"
            viewBox="0 0 496 900"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="495.5"
              cy="453.5"
              r="495.5"
              fill="#431646"
              fillOpacity="0.03"
            />
          </svg>
        </div>
      </div>
    </>
  );
}
