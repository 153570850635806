import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from "hooks/useToast";
import { CORPORATES } from "components/PLCTABS/SFLdata/config/constants";
import Button from "../Button";
import { profileAccessRequest } from "state/slices/verifyInvite";

const InviteExistingUser = ({
  userId,
  idType,
  designation,
  userEmailAddress,
  values,
  closeVerifyProcess = () => null,
  inviteOfficial = () => null,
}) => {
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [sendingInvite, setSendingInvite] = useState(false);
  const [accessType, setAccessType] = useState("");

  const dispatch = useDispatch();
  const { toast } = useToast(5000);
  const { names } = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { organization } = useSelector((state) => state.organizationsSlice);

  const handleAccessType = (e) => setAccessType(e.target.value);

  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleCompanyNameChange = (e) => setCompanyName(e.target.value);

  const handleSendCorporateInvite = async () => {
    if (!company?._id && !organization?._id) {
      toast(
        "error",
        "Something went wrong please reload the page and try again."
      );
      return;
    }
    setSendingInvite(true);
    try {
      const response = await dispatch(
        profileAccessRequest({
          data: {
            corporateId: userId,
            initiatedToCompany: values.initiatedToCompany,
            companyName: companyName.trim(),
            appointedAs: designation,
            company: company?._id,
            organization: organization?._id,
            accessType,
            contactPersonEmailAddress: values.userEmailAddress,
            idType,
          },
        })
      );

      if (response?.payload?.status) {
        await inviteOfficial({
          ...values,
          type: "invited",
          inviteStatus: "Awaiting Data",
          names: {
            ...(values?.names ?? {}),
            companyName: companyName.trim(),
          },
          identificationDetails: {
            ...(values?.identificationDetails ?? {}),
            emailAddress: userEmailAddress,
          },
          initiatedToEmail: userEmailAddress,
          primarySource: userId,
        });
        closeVerifyProcess();
      } else {
        toast(
          "error",
          response?.payload?.message ?? "Something went wrong please try again."
        );
      }
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setSendingInvite(false);
    }
  };

  const handleSendInvite = async () => {
    if (!company?._id && !organization?._id) {
      toast(
        "error",
        "Something went wrong please reload the page and try again."
      );
      return;
    }
    setSendingInvite(true);
    try {
      const response = await dispatch(
        profileAccessRequest({
          data: {
            userId,
            applicant: values.applicant,
            registrar: values.registrar,
            individual: values.individual,
            beneficialOwner: values.beneficialOwner,
            userEmail: values.userEmailAddress,
            firstName: firstName.trim(),
            appointedAs: designation,
            company: company?._id,
            organization: organization?._id,
            accessType,
            idType,
          },
        })
      );

      if (response?.payload?.status) {
        await inviteOfficial({
          ...values,
          type: "invited",
          inviteStatus: "Awaiting Data",
          names: {
            ...(values?.names ?? {}),
            firstName: firstName.trim(),
          },
          electronicAddress: {
            ...(values?.electronicAddress ?? {}),
            emailAddress: userEmailAddress,
          },
          initiatedToEmail: userEmailAddress,
          primarySource: userId,
        });
        closeVerifyProcess();
      } else {
        toast(
          "error",
          response?.payload?.message ?? "Something went wrong please try again."
        );
      }
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setSendingInvite(false);
    }
  };

  if (CORPORATES.includes(idType)) {
    return (
      <>
        <div className="mt-4">
          <h4 className="text-lg font-medium leading-6 text-gray-900">
            Invite
          </h4>
          <p className="mt-1 text-sm text-gray-500">
            We recommend going with the recurring access option to make future
            application process easier.
          </p>
          <div className="pl-2 m-2">
            <p className="mt-1 text-sm text-gray-500">
              Please select whether you would like to:
            </p>
            <div className="p-4 mt-2 space-y-2 bg-gray-100 rounded-md">
              <div className="flex items-center">
                <input
                  name="accessType"
                  type="radio"
                  id="recurring"
                  value="Recurring"
                  onChange={handleAccessType}
                  className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                />
                <label
                  htmlFor="recurring"
                  className="block ml-3 text-sm font-medium text-gray-700"
                >
                  (Recurring access). Authorize {names?.firstName} to have a
                  access to my data for this application and any future
                  applications.
                </label>
              </div>
              <div className="flex items-center">
                <input
                  name="accessType"
                  type="radio"
                  id="oneOff"
                  value="One Off"
                  onChange={handleAccessType}
                  className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                />
                <label
                  htmlFor="oneOff"
                  className="block ml-3 text-sm font-medium text-gray-700"
                >
                  (One-time access). Authorize {names?.firstName} to have a
                  access to my data for this application only.
                </label>
              </div>
            </div>
          </div>
        </div>
        {accessType && (
          <>
            <div>
              <div className="col-span-6 sm:col-span-3 ">
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500"
                >
                  Company name
                </label>
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  autoComplete="off"
                  className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                  placeholder="Eg. Jane"
                  value={companyName}
                  onChange={handleCompanyNameChange}
                />
                <p className="mt-1 text-sm text-gray-500">
                  The company name must match the corporate's name for the
                  invite to be sent.
                </p>
              </div>
              <div className="my-4">
                <Button
                  disabled={!companyName.trim() || sendingInvite}
                  onClick={handleSendCorporateInvite}
                  isLoading={sendingInvite}
                  loadingText="Sending..."
                >
                  Send invite
                </Button>
              </div>
            </div>
            <hr />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="mt-4">
        <h4 className="text-lg font-medium leading-6 text-gray-900">Invite</h4>
        <p className="mt-1 text-sm text-gray-500">
          We recommend going with the recurring access option to make future
          application process easier.
        </p>
        <div className="pl-2 m-2">
          <p className="mt-1 text-sm text-gray-500">
            Please select whether you would like to:
          </p>
          <div className="p-4 mt-2 space-y-2 bg-gray-100 rounded-md">
            <div className="flex items-center">
              <input
                name="accessType"
                type="radio"
                id="recurring"
                value="Recurring"
                onChange={handleAccessType}
                className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              />
              <label
                htmlFor="recurring"
                className="block ml-3 text-sm font-medium text-gray-700"
              >
                (Recurring access). Authorize {names?.firstName} to have a
                access to my data for this application and any future
                applications.
              </label>
            </div>
            <div className="flex items-center">
              <input
                name="accessType"
                type="radio"
                id="oneOff"
                value="One Off"
                onChange={handleAccessType}
                className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
              />
              <label
                htmlFor="oneOff"
                className="block ml-3 text-sm font-medium text-gray-700"
              >
                (One-time access). Authorize {names?.firstName} to have a access
                to my data for this application only.
              </label>
            </div>
          </div>
        </div>
      </div>
      {accessType && (
        <>
          <div>
            <div className="col-span-6 sm:col-span-3 ">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500"
              >
                First name
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                autoComplete="off"
                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                placeholder="Eg. Jane"
                value={firstName}
                onChange={handleFirstNameChange}
              />
              <p className="mt-1 text-sm text-gray-500">
                The first name must match the user's first name for the invite
                to be sent.
              </p>
            </div>
            <div className="my-4">
              <Button
                disabled={!firstName.trim() || sendingInvite}
                onClick={handleSendInvite}
                isLoading={sendingInvite}
                loadingText="Sending..."
              >
                Send invite
              </Button>
            </div>
          </div>
          <hr />
        </>
      )}
    </>
  );
};

export default InviteExistingUser;
