import React from "react";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import { Formik, Field } from "formik";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import CommentsList from "components/lib/Global/CommentsList";
import Accordion from "components/lib/Global/Accordion";
import SummaryOfArticlesReview, {
  summaryOfArticlesFieldsName,
} from "components/lib/Global/SummaryOfArticlesReview";
import { Constants } from "config/constants";
import { InformationCircleIcon } from "@heroicons/react/solid";

function SecondStep({ handleSaveComment, isViewMode }) {
  const { company } = useSelector((state) => state.companySlice);
  const {
    companyFormation: { step2, resolvedFields },
  } = useSelector((state) => state.companySlice);
  const { comments } = useSelector((state) => state.commentsSlice);
  const history = useHistory();
  const isDirectorReviewer =
    Cookies.get("role") === Constants.COOKIE_ROLES.REVIEWER ||
    history.location.pathname.split("/").pop() === "review";
  const isOrgAdmin = Cookies.get("role") === Constants.COOKIE_ROLES.ORG_ADMIN;
  const isSuperAdmin =
    Cookies.get("role") === Constants.COOKIE_ROLES.SUPER_ADMIN;

  const articlesOfAssociationComments = comments.filter(
    (comment) => comment.formName === "articlesOfAssociation"
  );

  const articlesType = _get(step2, "articlesOfAssociation.articlesType");
  let articlesOfAssociationToUse = _get(step2, "articlesOfAssociationToUse");

  if (articlesOfAssociationToUse) {
    if (articlesOfAssociationToUse === "ModifiedArticlesOfAssociation")
      articlesOfAssociationToUse = "Modified articles of association";
    else articlesOfAssociationToUse = "Own articles of association";
  }
  const articlesOfAssociation = _get(
    step2,
    "articlesOfAssociation.template",
    {}
  );
  const latestTemplateVersion =
    articlesOfAssociation.versions[articlesOfAssociation.versions.length - 1];

  const summaryOfArticles = latestTemplateVersion?.summary ?? {};

  const articlesOfAssociationFields = [
    {
      key: "Articles of association",
      name: "articlesOfAssociationToUse",
      value: articlesOfAssociationToUse || "N/A",
      editorParams: {
        companyId: company._id,
        url: latestTemplateVersion?.fileUploads?.template
          ? `${process.env.REACT_APP_IMAGE_BASE_URL}/templates/${latestTemplateVersion.fileUploads.template}`
          : null,
        editorKey: `${articlesOfAssociation._id}-${latestTemplateVersion?._id}`,
        mode:
          !isViewMode &&
          (isDirectorReviewer ||
            (isSuperAdmin &&
              [
                "Submission",
                "Submission Deferred",
                "Submission Corrections",
                "Submission II",
                "Signature",
                "Signature Corrections",
                "Signature Deferred",
              ].includes(company.status)) ||
            (isOrgAdmin &&
              [
                "Draft",
                "Directors Review",
                "Directors Deferred",
                "Directors Corrections",
                "Admin Review",
                "Admin Deferred",
                "Admin Review II",
                "Admin Corrections",
              ].includes(company.status)))
            ? "edit"
            : "view",
        permissions: {
          edit: !isDirectorReviewer,
          review: true,
        },
        containerHeight: "900px",
      },
      SummaryOfArticles: () => (
        <div className="border border-gray-300 overflow-auto max-h-full">
          {articlesOfAssociationComments.length > 0 && (
            <div className="px-8 pt-5 pb-6 bg-white">
              <CommentsList
                comments={articlesOfAssociationComments}
                toggleCommentStatus={!isViewMode}
              />
              <p className="mt-6 text-sm text-primary">
                Comments: {articlesOfAssociationComments.length} of{" "}
                {articlesOfAssociationComments.length}
              </p>
            </div>
          )}
          <SummaryOfArticlesReview
            summary={summaryOfArticles}
            templateType={articlesOfAssociation.templateType}
            entityType={articlesOfAssociation.entityType}
            formName="articlesOfAssociation"
            addReviewComment={!isViewMode}
            resolvedFields={resolvedFields}
            formHelperKey="company"
            updateEffectKey="updateCompanyFormationState"
            formId={_get(company, "_id")}
            handleSaveComment={handleSaveComment}
            comments={articlesOfAssociationComments}
            hideResolveAllCheckbox={isViewMode}
            isViewMode={isViewMode}
            wrapperStyles="mt-0"
            renderReviewIcon={false}
            renderAcceptIcon={false}
          />
        </div>
      ),
    },
    {
      key: "Summary of articles",
      value: (
        <Accordion
          titleClasses="font-medium text-primary text-sm"
          wrapperClasses=""
          tabs={[
            {
              title: "Summary of articles",
              commentsCount: comments.filter(
                (comment) =>
                  comment.formName?.includes(`articlesOfAssociation`) &&
                  summaryOfArticlesFieldsName.includes(comment.fieldName)
              ).length,
              content: (
                <SummaryOfArticlesReview
                  summary={summaryOfArticles}
                  templateType={articlesOfAssociation.templateType}
                  entityType={articlesOfAssociation.entityType}
                  formName="articlesOfAssociation"
                  addReviewComment={!isViewMode}
                  resolvedFields={resolvedFields}
                  formHelperKey="company"
                  updateEffectKey="updateCompanyFormationState"
                  formId={_get(company, "_id")}
                  handleSaveComment={handleSaveComment}
                  comments={articlesOfAssociationComments}
                  hideResolveAllCheckbox={isViewMode}
                  isViewMode={isViewMode}
                />
              ),
            },
          ]}
        />
      ),
      onlyRenderValue: true,
    },
    {
      key: "Other objects for which company is established",
      name: "articlesType",
      value: articlesType || "N/A",
    },
  ];

  if (articlesType === "Regulated") {
    articlesOfAssociationFields.push(
      {
        key: "Name of regulator",
        name: "nameOfRegulator",
        value: _get(step2, "articlesOfAssociation.nameOfRegulator", "N/A"),
      },
      {
        key: "Regulator institute",
        name: "regulatorInstitute",
        value: _get(step2, "articlesOfAssociation.regulatorInstitute", "N/A"),
      },
      {
        key: "No objection letter",
        name: "regulatedArticles_LNO",
        value: (
          <>
            <Formik
              enableReinitialize
              initialValues={{
                fileUploads: {
                  regulatedArticles_LNO: _get(
                    step2,
                    "fileUploads.regulatedArticles_LNO"
                  ),
                },
              }}
              onSubmit={() => null}
            >
              {() => {
                return (
                  <div className="w-full p-2 text-sm rounded-md bg-formInput text-primary placeholder-primary focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300">
                    {_get(step2, "fileUploads.regulatedArticles_LNO") ? (
                      <Field
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={[
                          {
                            name: `regulatedArticles_LNO`,
                            label: `regulatedArticles_LNO`,
                          },
                        ]}
                        onlyPreview
                        formName={`articlesOfAssociation`}
                        handleSaveComment={handleSaveComment}
                      />
                    ) : (
                      "N/A"
                    )}
                  </div>
                );
              }}
            </Formik>
          </>
        ),
      }
    );
  } else {
    articlesOfAssociationFields.push({
      key: "Nature of business",
      name: "natureOfBusiness",
      value: _get(step2, "articlesOfAssociation.natureOfBusiness", "N/A"),
    });
  }

  return (
    <>
      {articlesOfAssociationComments.length > 0 && (
        <div className="px-8 pt-5 pb-6 bg-white">
          <CommentsList
            comments={articlesOfAssociationComments}
            toggleCommentStatus={!isViewMode}
          />
          <p className="mt-6 text-sm text-primary">
            Comments: {articlesOfAssociationComments.length} of{" "}
            {articlesOfAssociationComments.length}
          </p>
        </div>
      )}
      {isSuperAdmin && company.status.includes("Submission") && (
        <div className="rounded-md bg-light-blue/10 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-light-blue"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="font-medium text-sm text-light-blue">
                Please ensure you complete the Company Number in the Articles of
                Association below.
              </p>
            </div>
          </div>
        </div>
      )}
      <FormFieldsCard
        addReviewComment={!isViewMode}
        formName="articlesOfAssociation"
        fields={articlesOfAssociationFields}
        resolvedFields={resolvedFields}
        formHelperKey="company"
        updateEffectKey="updateCompanyFormationState"
        formId={_get(company, "_id")}
        handleSaveComment={handleSaveComment}
        comments={articlesOfAssociationComments}
        hideResolveAllCheckbox={isViewMode}
        isViewMode={isViewMode}
      />
    </>
  );
}

export default SecondStep;
