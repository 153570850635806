import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import { object, string } from "yup";

import Input from "components/lib/Shared/Input";
import Button from "components/lib/Shared/Button";
import {
  addTemplateCategoryAsync,
  editTemplateCategoryAsync,
  getTemplateCategoriesAsync,
} from "state/slices/templateCategory";
import { useToast } from "hooks/useToast";

export const categoryValidationSchema = object().shape({
  name: string()
    .trim("Name cannot include leading and trailing spaces")
    .strict(true)
    .required("Name is required"),
  description: string()
    .trim("Description cannot include leading and trailing spaces")
    .strict(true),
});

const CategoryForm = ({
  editableCategory = {},
  setShowAddCategoryForm,
  setEditableCategory,
}) => {
  const dispatch = useDispatch();
  const { toast } = useToast();

  const handleCancel = () => {
    setEditableCategory({});
    setShowAddCategoryForm(false);
  };

  const handleSubmit = async (data) => {
    let response = {};
    if (data?._id) {
      response = await dispatch(
        editTemplateCategoryAsync({ templateCategoryId: data?._id, data })
      );
    } else {
      response = await dispatch(addTemplateCategoryAsync({ data }));
    }
    if (response.error) {
      toast(
        "error",
        response.payload?.messageText ??
          "Something went wrong please try again."
      );
    } else {
      dispatch(getTemplateCategoriesAsync());
      setEditableCategory({});
      setShowAddCategoryForm(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        description: "",
        ...editableCategory,
      }}
      validationSchema={categoryValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="text-sm w-full">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <Field
                      component={Input}
                      label="Name"
                      id="name"
                      name="name"
                      placeholder="Enter category name"
                      className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className="col-span-6">
                    <Field
                      component={Input}
                      label="Description"
                      id="description"
                      name="description"
                      rows="2"
                      renderTextarea
                      placeholder="Write a brief description of the category"
                      className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-4">
              <Button
                variant="secondary"
                disabled={isSubmitting}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button type="submit" isLoading={isSubmitting}>
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CategoryForm;
