import React from "react";
import { format } from "date-fns";

import DistinctiveNumbers from "./DistinctiveNumbers";
import { getFullName, getPoBoxAddress } from "utils";
import { numberToWords } from "utils/numberToWords";

function IssuedCertificate({
  companyRegistrationName,
  companySuffix,
  currentShareCertificate,
  sumNominalCapital,
  unpaidClassesOfShares,
  nominalShares,
  isFullscreen,
}) {
  const {
    allottedShares,
    classOfShares,
    sharesValue,
    shareCertificateNumber,
    allotmentDate,
  } = currentShareCertificate.currentAllottedClass;

  const uniqueValueOfShare = new Set();
  nominalShares.forEach((nominalShare) => {
    uniqueValueOfShare.add(nominalShare.sharesValue);
  });

  return (
    <div className="flex flex-col flex-grow p-4 lg:p-6">
      <div className="flex font-semibold justify-between items-end">
        <div className="flex font-semibold justify-between items-end">
          <p className="font-bold">No. of Certificate</p>
          <span className="border-b-1-dashed-gray-400 mx-2 px-2">
            {shareCertificateNumber}
          </span>
        </div>
        <div className="flex font-semibold justify-between items-end">
          <p className="font-bold">No. of Shares</p>
          <span className="border-b-1-dashed-gray-400 mx-2 px-2">
            {allottedShares}
          </span>
        </div>
      </div>
      <p className="font-bold uppercase">
        <span className="border-b-1-dashed-gray-400 px-2 relative ">
          {companyRegistrationName}
          <span className="absolute -bottom-6 left-1/2 transform -translate-x-1/2">
            {companySuffix}
          </span>
        </span>
      </p>
      <div className="flex font-semibold mt-10 items-end">
        <p className="italic">Capital KES</p>
        <span className="border-b-1-dashed-gray-400 flex-grow mx-2 px-2">
          {Number(sumNominalCapital).toLocaleString()}
        </span>
        <span className="italic">Divided into</span>
        <p className="border-b-1-dashed-gray-400 flex-grow mx-2 px-2 flex flex-col uppercase">
          {nominalShares.map((nominalShare, shareIndex) => (
            <span key={`nominal_share_${shareIndex}`}>
              {`${nominalShare.sharesNumber} ${nominalShare.sharesCategory}`}
            </span>
          ))}
        </p>
        <p className="italic">of Shillings</p>
        <p className="border-b-1-dashed-gray-400 flex-grow mx-2 px-2 flex flex-col">
          {uniqueValueOfShare.size === 1 ? (
            <span key={`shares_of_shilling_1`}>
              {Number(Array.from(uniqueValueOfShare)).toLocaleString()}/=
            </span>
          ) : (
            nominalShares.map((nominalShare, index) => (
              <span key={`shares_of_shilling_${index}`}>
                {Number(nominalShare.sharesValue).toLocaleString()}/=
              </span>
            ))
          )}
        </p>
        each
      </div>
      <div className="flex flex-col space-y-2 mb-4 text-left mt-4">
        <p className="flex">
          <span className="italic">This is to certify that</span>
          <span className="border-b-1-dashed-gray-400 font-semibold flex-grow mx-2 px-2 uppercase">
            {getFullName(currentShareCertificate.names)}
          </span>
        </p>
        <p className="flex">
          <span className="italic">of</span>
          <span className="border-b-1-dashed-gray-400 font-semibold flex-grow mx-2 px-2 uppercase">
            {getPoBoxAddress(
              currentShareCertificate.idType,
              currentShareCertificate.postalAddress,
              currentShareCertificate.isNonResident
            )}
          </span>
        </p>
        <p className="flex">
          <span className="italic">is the registered proprietor of</span>
          <span className="border-b-1-dashed-gray-400 font-semibold flex-grow mx-2 px-2 uppercase">
            {numberToWords(allottedShares)} {classOfShares}
          </span>
        </p>
        <p>
          <span className="italic">of</span>
          <span className="border-b-1-dashed-gray-400 font-semibold mx-2 px-2 uppercase">
            <span>SHILLINGS</span> <span>{numberToWords(sharesValue)}</span>
          </span>
          <span className="italic">
            each,{" "}
            {!unpaidClassesOfShares.includes(
              currentShareCertificate.currentAllottedClass.classOfShares
            )
              ? "fully paid, "
              : "numbered as in margin, "}
            in the above named Company subject to the regulations thereof.
          </span>
        </p>
      </div>

      <div className="flex justify-between items-center mt-4">
        <DistinctiveNumbers
          currentAllottedClass={currentShareCertificate.currentAllottedClass}
          unpaidClassesOfShares={unpaidClassesOfShares}
        />
        <div
          className={`${
            isFullscreen ? "w-64 h-64" : "w-48 h-48"
          } flex justify-center items-center rounded-full bg-gray-50 border-8 border-white placeholder-gray-400`}
        />
      </div>

      <div className="flex justify-between mt-auto">
        <div className="mt-auto text-left w-1/3">
          <p className="font-bold">NOTE</p>
          <p className="font-semibold">
            No transfer of the above Shares can be registered unless accompanied
            by this certificate
          </p>
        </div>
        <div className="w-[27rem] text-start">
          <p>
            Given under the Authority of the Board of Directors of the company
            this
            <span className="border-b-1-dashed-gray-400 font-semibold flex-grow mx-1 px-1">
              {format(new Date(allotmentDate), "do")}
            </span>
            day of
            <span className="border-b-1-dashed-gray-400 font-semibold flex-grow mx-1 px-1 uppercase">
              {format(new Date(allotmentDate), "MMMM")}
            </span>
            {String(new Date(allotmentDate).getFullYear()).slice(0, 2)}
            <span className="border-b-1-dashed-gray-400 font-semibold flex-grow">
              {format(new Date(allotmentDate), "yy")}
            </span>
          </p>
          <ul className="mt-10 space-y-10">
            <li className="flex space-x-4">
              <span>Directors</span>
              <div className="flex-grow space-y-6 mt-4">
                <p className="border-b-1-dashed-gray-400 font-semibold flex-grow mx-2 px-4"></p>
                <br></br>
                <p className="border-b-1-dashed-gray-400 font-semibold flex-grow mx-2 px-4"></p>
              </div>
            </li>
            <li className="flex space-x-4">
              <span>Secretary</span>
              <div className="flex-grow space-y-6 mt-4">
                <p className="border-b-1-dashed-gray-400 font-semibold flex-grow mx-2 px-4"></p>
                <br></br>
                <p className="border-b-1-dashed-gray-400 font-semibold flex-grow mx-2 px-4"></p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default IssuedCertificate;
