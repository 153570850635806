import { useSelector } from "react-redux";
import _get from "lodash/get";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";

export default function RegisteredAddress() {
  const {
    company: { registeredOffice },
  } = useSelector((state) => state.companyDashboardSlice);

  return (
    <div className="divide-y-2 bg-white">
      <FormFieldsCard
        fields={[
          {
            key: "Postal Address",
            name: "postalAddress",
            value: _get(registeredOffice, "postalAddress") || "N/A",
          },
          {
            key: "Postal Code",
            name: "postalCode",
            value: _get(registeredOffice, "postalCode") || "N/A",
          },
          {
            key: "Service Address",
            name: "serviceAddress",
            value: _get(registeredOffice, "serviceAddress") || "N/A",
          },
        ]}
        hideResolveAllCheckbox
      />
      <FormFieldsCard
        wrapperStyles="mt-0"
        fields={[
          {
            key: "Company Email",
            name: "companyEmailAddress",
            value: _get(registeredOffice, "companyEmailAddress") || "N/A",
          },
          {
            key: "Mobile Number",
            name: "mobileNumber",
            value: _get(registeredOffice, "mobileNumber") || "N/A",
          },
        ]}
        hideResolveAllCheckbox
      />
      <FormFieldsCard
        wrapperStyles="mt-0"
        fields={[
          {
            key: "Building Name",
            name: "buildingName",
            value: _get(registeredOffice, "buildingName") || "N/A",
          },
          {
            key: "House Number",
            name: "houseNumber",
            value: _get(registeredOffice, "houseNumber") || "N/A",
          },
          {
            key: "Estate",
            name: "estate",
            value: _get(registeredOffice, "estate") || "N/A",
          },
          {
            key: "Floor",
            name: "floorNumber",
            value: _get(registeredOffice, "floorNumber") || "N/A",
          },
          {
            key: "Street Name",
            name: "streetName",
            value: _get(registeredOffice, "streetName") || "N/A",
          },
          {
            key: "Street Number",
            name: "streetNumber",
            value: _get(registeredOffice, "streetNumber") || "N/A",
          },
        ]}
        hideResolveAllCheckbox
      />
      <FormFieldsCard
        wrapperStyles="mt-0"
        fields={[
          {
            key: "County",
            name: "location.county",
            value: _get(registeredOffice, "location.county") || "N/A",
          },
          {
            key: "District",
            name: "location.district",
            value: _get(registeredOffice, "location.district") || "N/A",
          },
          {
            key: "Locality",
            name: "location.locality",
            value: _get(registeredOffice, "location.locality") || "N/A",
          },
        ]}
        hideResolveAllCheckbox
      />
    </div>
  );
}
