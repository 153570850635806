import React from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";

function BreadCrumbs({ breadCrumbs }) {
  return (
    <p className="flex items-center text-sm mb-2 md:mb-0">
      {breadCrumbs.map((breadCrumb, index) => (
        <div
          key={breadCrumb.label}
          className={`${breadCrumb.disabled ? "" : ""} flex items-center`}
        >
          <span>{breadCrumb.label}</span>
          {index !== breadCrumbs.length - 1 && (
            <ArrowRightIcon className="w-5 h-5 mx-2" />
          )}
        </div>
      ))}
    </p>
  );
}

export default BreadCrumbs;
