import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SignInForm from "components/Auth/SignInForm";

const SignIn = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  // console.log({ isAuthenticated, isLoading });
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <div className="relative text-base tracking-tight">
      <SignInForm />
    </div>
  );
};

export default SignIn;
