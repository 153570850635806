import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FolderIcon from "components/lib/Shared/Icons/Folder";
import LadderIcon from "components/lib/Shared/Icons/Ladder";
import PointerIcon from "components/lib/Shared/Icons/Pointer";
import SidebarItem from "./SidebarItem";

const auditTabs = [
  { id: "accountSettings", label: "Account Settings" },
  { id: "notifications", label: "Notifications", disabled: true },
  { id: "transactions", label: "Transactions" },
  { id: "auditTrail", label: "Audit Trail", disabled: true },
];

function Sidebar({ selectedTab, setSelectedTab }) {
  const history = useHistory();
  const { dashboardRole } = useSelector((state) => state.companyDashboardSlice);

  const generalTabs = useMemo(
    () => [
      { id: "businessDetails", label: "Business Details" },
      {
        id: "minuteBook",
        label: "Minute Book",
        disabled: dashboardRole === "VOR",
      },
    ],
    [dashboardRole]
  );

  const managementTabs = useMemo(
    () => [
      {
        id: "compliance",
        label: "Compliance",
        disabled: true,
        comingSoon: true,
      },
      {
        id: "companies",
        label: "Companies",
        disabled: dashboardRole !== "REGISTRAR",
      },
      {
        id: "manageYourCompany",
        label: "Manage your company",
        disabled: true,
        comingSoon: true,
      },
      {
        id: "contracts",
        label: "Contracts",
        disabled: true,
        comingSoon: true,
      },
    ],
    [dashboardRole]
  );

  const handleTabClick = (item) => {
    if (item?.disabled) return null;
    if (item?.href) return history.push(item.href);
    else setSelectedTab(item);
  };

  return (
    <div className="col-span-4 md:col-span-1 pt-8">
      <div>
        <h4 className="mb-2 text-sflPrimary font-medium flex items-center">
          <FolderIcon className="w-5 h-5" aria-hidden="true" />
          <span className="pl-2">General</span>
        </h4>
        <ul className="ml-10">
          {generalTabs.map((item) => (
            <SidebarItem
              key={item.label}
              item={item}
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
            />
          ))}
        </ul>
      </div>
      <div className="border-t pt-2">
        <h4 className="mb-2 text-sflPrimary font-medium flex items-center">
          <LadderIcon className="w-5 h-5" aria-hidden="true" />
          <span className="pl-2">Management</span>
        </h4>
        <ul className="ml-10">
          {managementTabs.map((item) => (
            <SidebarItem
              key={item.label}
              item={item}
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
            />
          ))}
        </ul>
      </div>
      <div className="border-t pt-2">
        <h4 className="mb-2 text-sflPrimary font-medium flex items-center">
          <PointerIcon className="w-5 h-5" aria-hidden="true" />
          <span className="pl-2">Audit</span>
        </h4>
        <ul className="ml-10">
          {auditTabs.map((item) => (
            <SidebarItem
              key={item.label}
              item={item}
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
