/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _merge from "lodash/merge";
import { useHistory } from "react-router-dom";

import SectionDescription from "components/lib/Shared/SectionDescription";
import IdentificationDetailsForm from "components/PLCTABS/CompanyOfficialsTab/PersonalDetailsComponents";
import NamesForm from "components/PLCTABS/CompanyOfficialsTab/NamesComponents";
import personalInformationValidation from "./validations/personalInformationValidation";
import { updateUserState } from "state/slices/user";
import VerifyUserOnboarding from "components/lib/Shared/VerifyUserOnboarding";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import Button from "components/lib/Shared/Button";

const PersonalInformation = ({ handleBackBtn, handleNextBtn }) => {
  const [verificationValue, setVerifyingValue] = useState("");
  const [isIdAvailable, setIsIdAvailable] = useState(null);
  const [disableForm, setDisableForm] = useState(false);

  const { transformedComments } = useSelector(
    (state) => state.commentsSlice.profileComments
  );
  const { idType, names, identificationDetails } = useSelector(
    (state) => state.userSlice
  );

  useEffect(() => {
    setVerifyingValue("");
    setIsIdAvailable(null);
    setDisableForm(true);
  }, [idType]);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = (formData) => {
    dispatch(updateUserState(formData));
    handleNextBtn();
  };

  const { schemaInitialValues, schema } = personalInformationValidation(idType);

  const initialValues = _merge(schemaInitialValues, {
    names,
    identificationDetails,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        comments: transformedComments,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ setFieldValue, resetForm }) => {
        return (
          <Form>
            <SectionDescription
              id="Step: 2 of 5"
              title="General Information"
              description="Please provide the correct details. The information provided will be used to process your application."
            />
            <div className="bg-white shadow sm:rounded-md">
              <div className="px-4 py-6 space-y-6 sm:p-6">
                <div className="mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Personal Information
                  </h3>
                </div>
                {history.location.pathname !== "/profile/edit" && (
                  <div>
                    <VerifyUserOnboarding
                      idType={idType}
                      verificationValue={verificationValue}
                      isIdAvailable={isIdAvailable}
                      setIsIdAvailable={setIsIdAvailable}
                      setVerifyingValue={setVerifyingValue}
                      setFieldValue={setFieldValue}
                      setDisableForm={setDisableForm}
                      resetForm={resetForm}
                    />
                  </div>
                )}
                <Field
                  name="names"
                  component={NamesForm}
                  idType={idType}
                  disabled={disableForm}
                />
                <hr />
                <IdentificationDetailsForm
                  idType={idType}
                  disabled={disableForm}
                  disableVerificationField
                  isOnboardingStage
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-6">
              <Button
                variant="backBtn"
                onClick={handleBackBtn}
                preIcon={ChevronLeftIcon}
              >
                Back to previous step
              </Button>
              <Button type="submit" postIcon={ChevronRightIcon}>
                Save and proceed
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonalInformation;
