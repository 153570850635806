import { object, array } from "yup";

const selectRoleValidation = () => {
  const schema = object().shape({
    role: array().required("Role is required").default(""),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default selectRoleValidation;
