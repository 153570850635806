import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { useDebounce } from "use-debounce";

import ReactTable from "components/lib/Global/ReactTable";
import TableActionMenuWrapper from "components/lib/Shared/TableActionMenuWrapper";
import ConfirmDialog from "components/lib/Shared/ConfirmDialog";
import Button from "components/lib/Shared/Button";
import DeleteButtonWithConfirmation from "components/lib/Shared/DeleteButtonWithConfirmation";
import {
  deleteTemplateCategoryAsync,
  getTemplateCategoriesAsync,
} from "state/slices/templateCategory";
import { useToast } from "hooks/useToast";
import SearchInput from "components/lib/Shared/SearchInput";

const CategoriesTable = ({ handleAddNewCategory, handleEditCategory }) => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const { templateCategories, getTemplateCategories } = useSelector(
    (state) => state.templateCategory
  );
  const { modalRow } = useSelector((state) => state.modalsSlice);

  const handleDeleteCategory = async (row) => {
    const response = await dispatch(
      deleteTemplateCategoryAsync({ templateCategoryId: row._id })
    );
    if (response.error) {
      toast(
        "error",
        response.payload?.messageText ?? "Something went wrong please try again"
      );
    } else {
      dispatch(getTemplateCategoriesAsync());
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) setCurrentPage(1);
    dispatch(
      getTemplateCategoriesAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm.trim(),
        order: "asc",
      })
    );
  }, [dispatch, currentPage, debouncedSearchTerm]);

  const columns = [
    {
      Header: "Name",
      className: "p-4 text-ellipsis overflow-hidden max-w-[150px]",
      Cell: ({ row }) => row.original?.name ?? "N/A",
    },
    {
      Header: "Updated At",
      Cell: ({ row }) => format(new Date(row.original?.updatedAt), "PP"),
    },
    {
      Header: "Date",
      Cell: ({ row }) => format(new Date(row.original?.createdAt), "PP"),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <TableActionMenuWrapper>
          <Menu.Item>
            {({ active }) => (
              <Button
                onClick={() => handleEditCategory(row.original)}
                overrideBaseStyles
                className={`${
                  active ? "bg-violet-500 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
              >
                Edit
              </Button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <DeleteButtonWithConfirmation
                confirmationModalName="delete_category_confirmation_modal"
                overrideBaseStyles
                btnClasses={`${
                  active ? "bg-red-600 text-white" : "text-gray-900"
                } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                row={row.original}
              />
            )}
          </Menu.Item>
        </TableActionMenuWrapper>
      ),
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput value={searchTerm} handleOnChange={handleSearch} />
      </div>
      <div className="w-full bg-white shadow">
        {templateCategories.totalCount === 0 ? (
          <div className="relative flex flex-col items-center justify-center p-32 text-center bg-white border border-gray-100 rounded-md shadow-sm">
            <div className="absolute flex flex-col items-center justify-center">
              <p className="text-title5">No category to display yet.</p>
              <span className="text-title5">
                Click{" "}
                <span
                  className="cursor-pointer font-medium text-sflPrimary"
                  onClick={handleAddNewCategory}
                >
                  here
                </span>{" "}
                to create one
              </span>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <ReactTable
              columns={columns}
              data={templateCategories.items}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={templateCategories.totalCount}
              setCurrentPage={setCurrentPage}
              paginate
              isLoading={getTemplateCategories.status === "loading"}
              headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
              headingStyle="pl-4 text-left text-white"
              dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            />
          </div>
        )}
      </div>
      <ConfirmDialog
        name="delete_category_confirmation_modal"
        onConfirm={() => handleDeleteCategory(modalRow)}
      >
        Are you sure you want to permanently delete this entry?
      </ConfirmDialog>
    </>
  );
};

export default CategoriesTable;
