import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import TransactionsList from "components/TransactionsList";

export default function Transactions() {
  return (
    <DashboardWrapper>
      <TransactionsList />
    </DashboardWrapper>
  );
}
