import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { EyeIcon } from '@heroicons/react/outline'

import TableActionMenu from 'components/lib/Shared/TableActionMenu'
import { deleteJointShareholder } from 'state/slices/tables/corporateJointShareholderTableSlice'
import ReactTable from 'components/lib/Shared/ReactTable'
import { deleteFiles } from 'utils/filesUtils'

const JointShareholderTable = props => {
  const { currentJSGroup } = useSelector(state => state)
    .corporateJointShareholderTableSlice

  const jointShareholders = useSelector(state => {
    return state.corporateJointShareholderTableSlice.shareholders
      .filter(js => js.JSGroupID === currentJSGroup.JSGroupID)
  })

  const dispatch = useDispatch()

  const handleEdit = row => {
    const isFirstRow = jointShareholders.findIndex(official => (official?.id && official?.id === row?.id) || (official?._id && official?._id === row?._id)) === 0
    props.handleEdit(row, isFirstRow)
  }

  const handleDelete = async row => {
    // const filesToDelete = Object.values(jointShareholders
    //   .filter(official => official.id === row.id || official._id === row._id)[0]?.fileUploads ?? {})
    // if (filesToDelete.length > 0) await deleteFiles(filesToDelete)
    dispatch(deleteJointShareholder(row))
  }

  const columns = [
    {
      Header: 'Sn',
      Cell: ({ row }) => row.index + 1
    },
    { Header: 'Full Name', accessor: 'names.fullName' },
    { Header: 'ID Type', accessor: 'idType' },
    {
      Header: 'Action',
      Cell: ({ row }) => (
        <div className='flex items-center space-x-2'>
          <button
            onClick={() => null}
            className='flex space-x-1 justify-center items-center focus:outline-none mr-5 hover:text-gray-600 py-2.5 text-sm leading-3 text-gray-500'
          >
            <EyeIcon className='w-6 h-6' />
            <span>Quick View</span>
          </button>
          <TableActionMenu
            row={row}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <h2 className="mb-2 text-lg font-medium leading-6 text-gray-900">Joint Shareholder Appointments:</h2>
      <div className="w-full bg-white shadow">
        <div className="w-full overflow-x-auto">
          <ReactTable
            columns={columns}
            data={jointShareholders}
            headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600 bg-slate-200"
            headingStyle="pl-4 text-left"
            dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
          />
        </div>
      </div>
    </>
  )
}

export default JointShareholderTable
