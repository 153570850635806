/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import accessRequestCommentDataService from "services/accessRequestComment";

export const getAccessRequestComments = createAsyncThunk(
  "accessRequestComments/getComments",
  async ({ companyId, organizationId, accessRequest }) => {
    const res = await accessRequestCommentDataService.getAll(
      companyId,
      organizationId,
      accessRequest
    );
    return res.data;
  }
);

export const addAccessRequestComment = createAsyncThunk(
  "accessRequestComments/addComment",
  async ({ data }) => {
    const res = await accessRequestCommentDataService.create(data);
    return res.data;
  }
);

export const editAccessRequestComment = createAsyncThunk(
  "accessRequestComments/update",
  async ({ commentId, data }) => {
    const res = await accessRequestCommentDataService.update(commentId, data);
    return res.data;
  }
);

export const deleteAccessRequestComment = createAsyncThunk(
  "accessRequestComments/delete",
  async ({ commentId }) => {
    await accessRequestCommentDataService.remove(commentId);
    return { commentId };
  }
);

const initialState = {
  comments: [],
  transformedComments: {},
  getAccessRequestCommentsStatus: {
    status: "idle",
    error: false,
  },
};

const accessRequestCommentsSlice = createSlice({
  name: "Comments",
  initialState,
  reducers: {
    updateCommentState: (state, { payload }) => {
      const { comments } = state;
      const index = comments.findIndex(
        (comment) => payload._id === comment._id
      );
      if (index > -1) {
        comments.splice(index, 1, payload);
      }
    },
    resetAccessRequestCommentsSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [getAccessRequestComments.pending]: (state) => {
      state.getAccessRequestCommentsStatus.status = "loading";
      state.getAccessRequestCommentsStatus.error = false;
    },
    [getAccessRequestComments.fulfilled]: (state, action) => {
      state.getAccessRequestCommentsStatus.status = "idle";
      state.comments = action.payload.items;
    },
    [getAccessRequestComments.rejected]: (state, action) => {
      state.getAccessRequestCommentsStatus.status = "failed";
      state.getAccessRequestCommentsStatus.error = action?.payload?.messageText;
    },
    [addAccessRequestComment.fulfilled]: (state, action) => {
      state.comments.push(action.payload.accessRequestComment);
    },
    [editAccessRequestComment.fulfilled]: (state, { payload }) => {
      const { comments } = state;
      const index = comments.findIndex(
        (comment) => payload._id === comment._id
      );
      if (index > -1) {
        comments.splice(index, 1, payload);
      }
    },
    [deleteAccessRequestComment.fulfilled]: (state, action) => {
      const index = state.comments.findIndex(
        (comment) => action.payload.commentId === comment._id
      );
      if (index > -1) state.comments.splice(index, 1);
    },
  },
});

export const { updateCommentState, resetAccessRequestCommentsSlice } =
  accessRequestCommentsSlice.actions;

export default accessRequestCommentsSlice.reducer;
