import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import Cookies from "js-cookie";
import { useDebounce } from "use-debounce";

import ReactTable from "components/lib/Global/ReactTable";
import Button from "components/lib/Shared/Button";
import DeleteButtonWithConfirmation from "components/lib/Shared/DeleteButtonWithConfirmation";
import {
  deletePackageAsync,
  getPackagesAsync,
  updatePackageState,
} from "state/slices/package";
import { Constants } from "config/constants";
import { useToast } from "hooks/useToast";
import { formatCurrency } from "utils";
import TableActionMenuWrapper from "components/lib/Shared/TableActionMenuWrapper";
import ConfirmDialog from "components/lib/Shared/ConfirmDialog";
import SearchInput from "components/lib/Shared/SearchInput";
import DashOutline from "components/lib/Shared/Icons/DashOutline";

const PackagesTable = () => {
  const cookieRole = Cookies.get("role");
  const dispatch = useDispatch();
  const { toast } = useToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1000);

  const { packages, getPackages } = useSelector((state) => state.packageSlice);
  const { modalRow } = useSelector((state) => state.modalsSlice);

  const handleViewPackage = async (row) => {
    dispatch(updatePackageState({ selectedPackage: row, mode: "view" }));
  };

  const handleEditPackage = async (row) => {
    dispatch(updatePackageState({ selectedPackage: row, mode: "edit" }));
  };

  const handleDeletePackage = async (row) => {
    const response = await dispatch(deletePackageAsync({ packageId: row._id }));
    if (response.error) {
      toast(
        "error",
        response.payload?.messageText ?? "Something went wrong please try again"
      );
    } else {
      dispatch(getPackagesAsync());
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) setCurrentPage(1);
    dispatch(
      getPackagesAsync({
        limit: rowsPerPage,
        skip: (currentPage - 1) * rowsPerPage,
        search: debouncedSearchTerm,
        order: "asc",
      })
    );
  }, [dispatch, currentPage, debouncedSearchTerm]);

  const columns = [
    {
      Header: "Service",
      accessor: "service",
    },
    {
      Header: "Product",
      Cell: ({ row }) => row.original?.product?.name ?? "N/A",
    },
    {
      Header: "Package",
      accessor: "name",
    },
    {
      Header: "Currency",
      accessor: "currency",
      Cell: () => "KES",
    },
    {
      Header: "Base",
      accessor: "base",
      Cell: ({ row }) => {
        const basePrice = row.original.currencies.find(
          (currency) => currency.type === "KES"
        )?.basePrice;
        if (basePrice) {
          return formatCurrency(basePrice);
        }
        return "";
      },
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <TableActionMenuWrapper>
            <Menu.Item>
              {({ active }) => (
                <Button
                  overrideBaseStyles
                  onClick={() => handleViewPackage(row.original)}
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                >
                  View
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Button
                  onClick={() => handleEditPackage(row.original)}
                  overrideBaseStyles
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                >
                  Edit
                </Button>
              )}
            </Menu.Item>
            {cookieRole === Constants.COOKIE_ROLES.SUPER_ADMIN && (
              <Menu.Item>
                {({ active }) => (
                  <DeleteButtonWithConfirmation
                    confirmationModalName="delete_package_confirmation_modal"
                    overrideBaseStyles
                    btnClasses={`${
                      active ? "bg-red-600 text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-4 py-2 text-sm disabled:cursor-not-allowed disabled:text-gray-400`}
                    row={row.original}
                  />
                )}
              </Menu.Item>
            )}
          </TableActionMenuWrapper>
        );
      },
    },
  ];

  function handleSearch(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <>
      <div className="flex items-center justify-between my-2">
        <div className="flex items-center space-x-4">
          <h2 className="font-semibold">Filter by:</h2>
        </div>
        <SearchInput
          value={searchTerm}
          placeholder="search by service or package"
          handleOnChange={handleSearch}
        />
      </div>
      <div className="w-full bg-white shadow">
        {packages.totalCount === 0 ? (
          <div className="relative flex flex-col items-center justify-center p-32 text-center">
            <DashOutline />
            <div className="absolute flex flex-col items-center justify-center">
              <h2 className="text-title5">There are no packages to display</h2>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <ReactTable
              columns={columns}
              data={packages.items}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={packages.totalCount}
              setCurrentPage={setCurrentPage}
              paginate
              isLoading={getPackages.status === "loading"}
              headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-sflPrimary"
              headingStyle="pl-4 text-left text-white"
              dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            />
          </div>
        )}
      </div>
      <ConfirmDialog
        name="delete_package_confirmation_modal"
        onConfirm={() => handleDeletePackage(modalRow)}
      >
        Are you sure you want to permanently delete this entry?
      </ConfirmDialog>
    </>
  );
};

export default PackagesTable;
