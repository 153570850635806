import React, { useState, useEffect, useRef } from "react";
import { XIcon, CheckIcon, PlusIcon, PencilIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

import { useToast } from "hooks/useToast";
import {
  addAccessRequestComment,
  deleteAccessRequestComment,
  editAccessRequestComment,
} from "state/slices/accessRequestComment";
import { openModal } from "state/slices/modals";
import { capitalizeFirstLetter, getFullName } from "utils";
import {
  editAccessRequest,
  notifyAboutComments,
} from "state/slices/accessRequest";
import Button from "components/lib/Shared/Button";
import TrashIcon from "components/lib/Shared/Icons/TrashIcon";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import CompaniesList from "./CompaniesList";
import { getCurrentUser } from "state/slices/auth";
import { getCompanyNameWithSuffix } from "utils/company";
import { Constants } from "config/constants";
import { checkAffiliationAsync } from "state/slices/company";
import { setCookie } from "utils/cookie";

export default function SubRowAsync({ setSelectedRow, visibleColumns, row }) {
  const { original } = row;
  const closeSubRowRef = useRef(null);
  const [showCommentArea, setShowCommentArea] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedComment, setSelectedComment] = useState({});
  const [sendingComment, setSendingComment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [removingComment, setRemovingComment] = useState(false);
  const [accessType, setAccessType] = useState("");
  const history = useHistory();

  const {
    createdBy,
    company,
    organization,
    appointedAs,
    recalledAt,
    grantedAt,
    expiredAt,
    revokedAt,
    createdAt,
    initiatedTo,
    status,
    requestFor,
    corporate,
    initiatedToEmail,
    grantedBy,
  } = original;

  useEffect(() => {
    setAccessType(original.accessType);
    return () => {
      setAccessType("");
    };
  }, [original.accessType]);

  const { toast } = useToast(5000);
  const dispatch = useDispatch();
  const userSlice = useSelector((state) => state.userSlice);
  const { comments, getAccessRequestCommentsStatus } = useSelector(
    (state) => state.accessRequestComments
  );

  const initiatedBy = getFullName(createdBy?.names);
  const lastComment = comments?.[comments.length - 1];
  const isLastCommentAuthor = lastComment?.createdBy?._id === userSlice?._id;

  const toggleCommentArea = () => {
    setShowCommentArea((prevValue) => !prevValue);
    setComment("");
  };

  const handleCommentValueChange = (e) => setComment(e.target.value);

  const handleSendComment = async () => {
    if (!comment.trim() || comment.trim() === selectedComment.text) {
      toggleCommentArea();
      return;
    }
    setSendingComment(true);
    const commentData = {
      from: userSlice?._id,
      to: userSlice?._id === createdBy?._id ? initiatedTo?._id : createdBy?._id,
      company: company?._id,
      organization: organization?._id,
      text: comment,
      accessRequest: original._id,
    };
    try {
      if (selectedComment?._id) {
        await dispatch(
          editAccessRequestComment({
            commentId: selectedComment._id,
            data: { text: comment },
          })
        );
      } else await dispatch(addAccessRequestComment({ data: commentData }));
      toast("success", "Comment sent successfully.");
      toggleCommentArea();
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setSendingComment(false);
      setSelectedComment({});
    }
  };

  const handleEditBtnClicked = (comment) => {
    setSelectedComment(comment);
    setComment(comment.text);
    setShowCommentArea(true);
  };

  const handleDeleteComment = async (commentId) => {
    try {
      setRemovingComment(true);
      await dispatch(deleteAccessRequestComment({ commentId }));
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setRemovingComment(false);
    }
  };

  const handleDenyRequest = () => {
    setSelectedRow(original);
    dispatch(openModal({ modalName: "deny_request_confirmation_modal" }));
  };

  const handleRevokeAccess = () => {
    setSelectedRow(original);
    dispatch(openModal({ modalName: "revoke_request_confirmation_modal" }));
  };

  const handleRecallRequest = () => {
    setSelectedRow(original);
    dispatch(openModal({ modalName: "recall_request_confirmation_modal" }));
  };

  const getVerificationParams = () => {
    if (requestFor === Constants.ACCESS_REQUEST.MINOR_DATA) {
      return {};
    }
    const verificationParams = {
      "Kenyan Citizen": {
        verificationKey: "nationalIDNumber",
        verificationValue: userSlice?.identificationDetails?.nationalIDNumber,
      },
      "Foreign Resident": {
        verificationKey: "foreignCertificateID",
        verificationValue:
          userSlice?.identificationDetails?.foreignCertificateID,
      },
      Foreigner: {
        verificationKey: "passportNumber",
        verificationValue: userSlice?.identificationDetails?.passportNumber,
      },
    };
    return verificationParams[userSlice.idType] || {};
  };

  const handleAcceptInvite = async () => {
    try {
      setIsAccepting(true);
      await dispatch(
        editAccessRequest({
          accessRequestsId: original?._id,
          data: {
            status: "Confirmed",
            accessType,
            grantedAt: new Date(),
            grantedBy: userSlice?._id,
            ...getVerificationParams(),
          },
        })
      );
      await dispatch(getCurrentUser());
      closeSubRowRef.current?.click();
      if (company?._id) {
        const response = await dispatch(
          checkAffiliationAsync({ companyId: company?._id })
        );
        if (
          response.payload.isCompanyOfficial ||
          response.payload.isBeneficialOwner ||
          response.payload.isCoApplicant
        ) {
          setCookie(
            "role",
            response.payload.isCoApplicant
              ? Constants.ROLES.CO_APPLICANT
              : Constants.ROLES.OFFICIAL
          );
          history.push(`/companies/${original.company._id}`);
        }
      } else if (organization?._id) {
        history.push(`/corporates/${original.organization._id}`);
      }
      toast("success", "Allowed access successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsAccepting(false);
    }
  };

  const handleAccessTypeChange = async (e) => {
    setAccessType(e.target.value);
    try {
      setIsLoading(true);
      await dispatch(
        editAccessRequest({
          accessRequestsId: original?._id,
          data: { accessType: e.target.value },
        })
      );
      toast("success", "Updated access type successfully.");
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotifyApplicant = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        notifyAboutComments({
          receiverEmail: createdBy?.electronicAddress?.emailAddress,
          receiverName: capitalizeFirstLetter(createdBy?.names?.firstName),
          senderName:
            requestFor === Constants.ACCESS_REQUEST.CORPORATE_DATA
              ? capitalizeFirstLetter(userSlice?.names?.firstName)
              : capitalizeFirstLetter(initiatedTo?.names?.firstName),
          commentId: lastComment._id,
        })
      );
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
      closeSubRowRef.current?.click();
    }
  };

  const handleNotifyInvitedOfficial = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        notifyAboutComments({
          receiverEmail:
            requestFor === Constants.ACCESS_REQUEST.CORPORATE_DATA
              ? initiatedToEmail
              : initiatedTo?.electronicAddress?.emailAddress,
          receiverName: capitalizeFirstLetter(initiatedTo?.names?.firstName),
          senderName: capitalizeFirstLetter(createdBy?.names?.firstName),
          commentId: lastComment._id,
        })
      );
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    } finally {
      setIsLoading(false);
      closeSubRowRef.current?.click();
    }
  };

  let companies = [];

  if (row.original?.children?.length > 0) {
    companies = row.original?.children?.reduce((accumulator, currentValue) => {
      return [
        ...accumulator,
        {
          company: currentValue?.company,
          organization: currentValue?.organization,
          designation: currentValue.appointedAs,
          grantedAt: currentValue.grantedAt,
          grantedBy: currentValue.grantedBy,
          requestedAt: currentValue.createdAt,
          parentRow: currentValue,
          commentsCount: currentValue.commentsCount,
        },
        ...currentValue?.companies?.map((company) => ({
          parentRow: currentValue,
          ...company,
        })),
      ];
    }, []);
  }

  const showAddCommentBtn =
    (createdBy?._id !== userSlice?._id && !isLastCommentAuthor) ||
    (createdBy?._id === userSlice?._id && lastComment && !isLastCommentAuthor);

  let companyName = "";
  if (company?.companyName1) {
    companyName = getCompanyNameWithSuffix(company);
  }

  let organizationName = "";
  if (organization?._id) {
    organizationName = organization.names.companyName;
  }

  return (
    <tr className="bg-[#EDF0F5]">
      <td colSpan={visibleColumns.length} className="px-9">
        <div className="flex justify-between items-center mt-2">
          {/* button to close sub row programmatically */}
          {row.isExpanded && (
            <Button
              ref={closeSubRowRef}
              disabled={isAccepting}
              variant="text"
              className="space-x-0 gap-0"
              onClick={() => row.getToggleRowExpandedProps()}
              {...row.getToggleRowExpandedProps()}
            ></Button>
          )}
        </div>
        <div className="grid grid-cols-2 mb-2 text-gray-800">
          {requestFor === Constants.ACCESS_REQUEST.PERSONAL_DATA && (
            <div className="flex flex-col">
              <div className="grid w-2/3 grid-cols-2 gap-1">
                <span>Requester Email:</span>
                <span>{createdBy?.electronicAddress?.emailAddress}</span>
              </div>
              {status === "Confirmed" && grantedBy && (
                <div className="grid w-2/3 grid-cols-2 gap-1">
                  <span>Approved By:</span>
                  <span>
                    {getFullName(grantedBy?.names)}
                    {grantedBy._id === userSlice?._id ? "(You)" : ""}
                  </span>
                </div>
              )}
              {status === "Revoked" && revokedBy && (
                <div className="grid w-2/3 grid-cols-2 gap-1">
                  <span>Approved By:</span>
                  <span>
                    {getFullName(revokedBy?.names)}
                    {revokedBy._id === userSlice?._id ? "(You)" : ""}
                  </span>
                </div>
              )}
            </div>
          )}
          {status === "Confirmed" &&
            createdBy?._id !== userSlice?._id &&
            requestFor === Constants.ACCESS_REQUEST.PERSONAL_DATA && (
              <div className="ml-auto">
                <p className="text-sm text-gray-500">Change access type</p>
                <div className="flex p-2 mt-1 space-x-4 justify-between rounded-md bg-gray-50">
                  <div className="flex items-center">
                    <input
                      name={`accessType-${row.id}`}
                      type="radio"
                      id={`recurring-${row.id}`}
                      value="Recurring"
                      checked={accessType === "Recurring"}
                      disabled={isAccepting}
                      onChange={handleAccessTypeChange}
                      className="w-4 h-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                    />
                    <label
                      htmlFor={`recurring-${row.id}`}
                      className="block ml-2 text-sm font-medium text-gray-700"
                    >
                      (Recurring access)
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      name={`accessType-${row.id}`}
                      type="radio"
                      id={`oneOff-${row.id}`}
                      value="One Off"
                      checked={accessType === "One Off"}
                      disabled={isAccepting}
                      onChange={handleAccessTypeChange}
                      className="w-4 h-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                    />
                    <label
                      htmlFor={`oneOff-${row.id}`}
                      className="block ml-2 text-sm font-medium text-gray-700"
                    >
                      (One-time access)
                    </label>
                  </div>
                </div>
              </div>
            )}
        </div>
        {status === "Confirmed" &&
          createdBy?._id !== userSlice?._id &&
          requestFor === Constants.ACCESS_REQUEST.PERSONAL_DATA && (
            <p className="w-[21rem] ml-auto whitespace-normal text-sm py-2">
              If you would wish to revoke {createdBy?.names?.firstName}'s access
              to future applications,{" "}
              <span
                className="text-red-500 underline decoration-red-500 cursor-pointer"
                onClick={handleRevokeAccess}
              >
                Click here to revoke access.
              </span>
            </p>
          )}
        <hr />
        {status === "Pending" && (
          <div className="grid grid-cols-2 my-4">
            <div className="grid grid-cols-3 gap-2">
              <span>Company:</span>
              <span className="font-medium text-gray-800 whitespace-normal col-span-2">
                {companyName || organizationName || "N/A"}
              </span>
              <span>Type:</span>
              <span className="font-medium text-gray-800 col-span-2">
                {accessType || "N/A"}
              </span>
              <span>Designation:</span>
              <span className="font-medium text-gray-800 col-span-2">
                {appointedAs}
              </span>
              {requestFor === Constants.ACCESS_REQUEST.CORPORATE_DATA && (
                <>
                  <span>Request for:</span>
                  {corporate ? (
                    <span className="font-medium text-gray-800">
                      {corporate?.names?.companyName ??
                        `Company Reg. #${corporate?.names?.companyRegNumber}`}
                    </span>
                  ) : (
                    <span className="font-medium text-gray-800">
                      Corporate Data
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="grid grid-cols-3 gap-2">
              {createdAt && (
                <>
                  <span>Requested:</span>
                  <span className="font-medium text-gray-800 col-span-2">
                    {format(new Date(createdAt), "PP")}
                  </span>
                </>
              )}
              {grantedAt && (
                <>
                  <span>Granted:</span>
                  <span className="font-medium text-gray-800 col-span-2">
                    {format(new Date(grantedAt), "PP")}
                  </span>
                </>
              )}
              {revokedAt && (
                <>
                  <span>Revoked:</span>
                  <span className="font-medium text-gray-800 col-span-2">
                    {format(new Date(revokedAt), "PP")}
                  </span>
                </>
              )}
              {expiredAt && (
                <>
                  <span>Expired:</span>
                  <span className="font-medium text-gray-800 col-span-2">
                    {format(new Date(expiredAt), "PP")}
                  </span>
                </>
              )}
              {recalledAt && (
                <>
                  <span>Recalled:</span>
                  <span className="font-medium text-gray-800 col-span-2">
                    {format(new Date(recalledAt), "PP")}
                  </span>
                </>
              )}
            </div>
          </div>
        )}

        {status === "Pending" && (
          <>
            <hr />
            <div className="mt-2">
              <p className="mt-1 text-sm text-gray-500">
                Please select whether you would like to:
              </p>
              <div className="p-4 mt-2 space-y-2 rounded-md bg-gray-50">
                <div className="flex items-center">
                  <input
                    name={`accessType-${row.id}`}
                    type="radio"
                    id={`recurring-${row.id}`}
                    value="Recurring"
                    checked={accessType === "Recurring"}
                    disabled={status !== "Pending" || isAccepting}
                    onChange={handleAccessTypeChange}
                    className="w-4 h-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                  />
                  <label
                    htmlFor={`recurring-${row.id}`}
                    className="block ml-3 text-sm font-medium text-gray-700"
                  >
                    (Recurring access). Authorize{" "}
                    <span className="font-bold">
                      {createdBy?.names?.firstName}
                    </span>{" "}
                    to have a access to my data for this application and any
                    future applications.
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    name={`accessType-${row.id}`}
                    type="radio"
                    id={`oneOff-${row.id}`}
                    value="One Off"
                    checked={accessType === "One Off"}
                    disabled={status !== "Pending" || isAccepting}
                    onChange={handleAccessTypeChange}
                    className="w-4 h-4 border-gray-300 text-sflPrimary focus:ring-sflPrimary disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                  />
                  <label
                    htmlFor={`oneOff-${row.id}`}
                    className="block ml-3 text-sm font-medium text-gray-700"
                  >
                    (One-time access). Authorize{" "}
                    <span className="font-bold">
                      {createdBy?.names?.firstName}
                    </span>{" "}
                    to have a access to my data for this application only.
                  </label>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Comments and action buttons */}
        {status === "Pending" &&
        getAccessRequestCommentsStatus.status === "loading" ? (
          <p className="flex justify-center py-4">
            <SpinnerIcon className="text-gray-500" />
          </p>
        ) : (
          <>
            {status === "Pending" && (
              <div className="my-4 d-flex justify-content-end">
                <ul className="space-y-2 text-gray-800">
                  {!showCommentArea &&
                    comments.map((comment) => {
                      const isCommentAuthor =
                        comment.createdBy?._id === userSlice?._id;
                      return (
                        <li key={comment?._id}>
                          <div className="flex space-x-2 justify-between">
                            <p>
                              {isCommentAuthor
                                ? "You:"
                                : `${capitalizeFirstLetter(
                                    comment.createdBy?.names?.firstName
                                  )}:`}{" "}
                              <span className="text-gray-500 text-sm">
                                {format(new Date(comment.createdAt), "PPPp")}
                              </span>
                            </p>
                            {isCommentAuthor &&
                              !isAccepting &&
                              lastComment?._id === comment?._id && (
                                <div className="flex space-x-2 mt-1">
                                  <button disabled={removingComment}>
                                    <PencilIcon
                                      onClick={() =>
                                        handleEditBtnClicked(comment)
                                      }
                                      className="w-4 h-4 cursor-pointer text-light-blue"
                                    />
                                  </button>
                                  <TrashIcon
                                    onClick={() =>
                                      handleDeleteComment(comment._id)
                                    }
                                    isLoading={removingComment}
                                  />
                                </div>
                              )}
                          </div>
                          <p className="pl-2">{comment.text}</p>
                        </li>
                      );
                    })}
                </ul>
                {comments.length > 0 && status === "Pending" && (
                  <hr className="my-2" />
                )}
                {showCommentArea ? (
                  <div>
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Use the textarea to add your message incase you need
                      clarification
                    </label>
                    <div className="mt-1">
                      <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        placeholder="Add you comment"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={comment}
                        onChange={handleCommentValueChange}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {showAddCommentBtn && status === "Pending" && (
                      <Button
                        onClick={toggleCommentArea}
                        disabled={isAccepting}
                        preIcon={PlusIcon}
                        variant="text"
                      >
                        <span>
                          {createdBy?._id === userSlice?._id
                            ? "Add your response"
                            : "Add your comments"}
                        </span>
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
            {showCommentArea ? (
              <div className="flex my-4 space-x-2">
                <Button
                  onClick={toggleCommentArea}
                  disabled={sendingComment}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSendComment}
                  isLoading={sendingComment}
                  disabled={sendingComment || !comment.trim()}
                >
                  Send
                </Button>
              </div>
            ) : (
              <>
                {
                  <>
                    {createdBy?._id !== userSlice?._id && (
                      <div className="flex justify-between my-4">
                        {status === "Pending" && (
                          <>
                            <Button
                              onClick={handleDenyRequest}
                              disabled={isAccepting}
                              loadingText="Denying..."
                              variant="danger"
                              preIcon={XIcon}
                            >
                              Deny
                            </Button>
                            <Button
                              isLoading={isAccepting}
                              loadingText="Accepting..."
                              preIcon={CheckIcon}
                              onClick={handleAcceptInvite}
                            >
                              Accept
                            </Button>
                          </>
                        )}
                      </div>
                    )}
                    {createdBy?._id === userSlice?._id &&
                      status === "Pending" &&
                      (!lastComment ||
                        (lastComment && lastComment?.notificationSent)) && (
                        <div className="flex justify-between my-4">
                          <Button
                            onClick={handleRecallRequest}
                            variant="danger"
                            loadingText="Recalling..."
                            preIcon={XIcon}
                          >
                            Recall
                          </Button>
                        </div>
                      )}
                  </>
                }
              </>
            )}
            {!showAddCommentBtn &&
              !showCommentArea &&
              comments.length > 0 &&
              lastComment &&
              !lastComment?.notificationSent &&
              status === "Pending" && (
                <div className="flex justify-end my-4">
                  {createdBy?._id === userSlice?._id ? (
                    <Button
                      isLoading={isLoading}
                      loadingText="Notifying..."
                      onClick={handleNotifyInvitedOfficial}
                    >
                      Notify{" "}
                      {capitalizeFirstLetter(initiatedTo?.names?.firstName) ||
                        "official"}
                    </Button>
                  ) : (
                    <Button
                      isLoading={isLoading}
                      loadingText="Notifying..."
                      onClick={handleNotifyApplicant}
                    >
                      Notify{" "}
                      {capitalizeFirstLetter(createdBy?.names?.firstName)}
                    </Button>
                  )}
                </div>
              )}
          </>
        )}
        {status !== "Pending" && (
          <CompaniesList
            companies={companies}
            original={original}
            setSelectedRow={setSelectedRow}
          />
        )}
      </td>
    </tr>
  );
}
