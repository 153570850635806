import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import { formatNumber, getFullName, getGreetings } from "utils";
import { getStatsAsync } from "state/slices/stats";
import { Constants } from "config/constants";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { twMerge } from "tailwind-merge";
import { getQueryString } from "utils/getQueryString";

const ProfilesSummaryCard = ({
  pendingReviewCount = 0,
  pendingCorrectionCount = 0,
  deferredCount = 0,
}) => {
  const { getStats } = useSelector((state) => state.statsSlice);
  if (getStats.status === "loading") {
    return (
      <div className="p-6 space-y-4 bg-white rounded-lg text-primary shadow-card min-h-[15rem]">
        <p className="flex justify-center h-full items-center">
          <SpinnerIcon className="text-gray-500" />
        </p>
      </div>
    );
  }
  return (
    <div className="p-6 space-y-4 bg-white rounded-lg text-primary shadow-card">
      <div className="mb-5">
        <h1 className="text-7xl font-base">
          {pendingReviewCount + pendingCorrectionCount + deferredCount}
        </h1>{" "}
        <span className="text-xs font-bold uppercase">Profiles</span>
        <hr className="w-full border-gray-200 my-1" />
      </div>
      <div className="grid gap-2">
        <div className="SubMenuLinks">
          <Link
            to={`/profiles?${new URLSearchParams({
              status: "Pending Review",
            })}`}
          >
            <div className="flex justify-between text-xs uppercase">
              <p>Review</p>
              <span>{pendingReviewCount}</span>
            </div>
          </Link>
        </div>
        <div className="SubMenuLinks">
          <Link
            to={`/profiles?${new URLSearchParams({
              status: "Pending Correction",
            })}`}
          >
            <div className="flex justify-between text-xs uppercase">
              <p>Correction</p>
              <span>{pendingCorrectionCount}</span>
            </div>
          </Link>
        </div>
        <div className="SubMenuLinks">
          <Link to={`/profiles?${new URLSearchParams({ status: "Deferred" })}`}>
            <div className="flex justify-between text-xs uppercase">
              <p>Deferred</p>
              <span>{deferredCount}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const ApplicationSummaryCard = ({
  pendingReviewCount = 0,
  pendingCorrectionCount = 0,
  deferredCount = 0,
  role = 13,
}) => {
  const { getStats } = useSelector((state) => state.statsSlice);
  if (getStats.status === "loading") {
    return (
      <div className="p-6 space-y-4 bg-white rounded-lg text-primary shadow-card min-h-[15rem]">
        <p className="flex justify-center h-full items-center">
          <SpinnerIcon className="text-gray-500" />
        </p>
      </div>
    );
  }

  const reviewFilters =
    role === Constants.COOKIE_ROLES.ORG_ADMIN
      ? ["Admin Review", "Admin Review II"]
      : ["Submission", "Submission II"];
  const correctionFilters =
    role === Constants.COOKIE_ROLES.ORG_ADMIN
      ? "Admin Corrections"
      : "Submission Corrections";

  return (
    <div className="p-6 space-y-4 bg-white rounded-lg text-primary shadow-card">
      <div className="mb-5">
        <h1 className="text-7xl font-base">
          {pendingReviewCount + pendingCorrectionCount + deferredCount}
        </h1>{" "}
        <span className="text-xs font-bold uppercase">Submitted</span>
        <hr className="w-full border-gray-200 my-1" />
      </div>
      <div className="grid gap-2">
        <div className="SubMenuLinks">
          <Link
            to={`/applications/pending?${getQueryString({
              status: reviewFilters,
            })}`}
          >
            <div className="flex justify-between text-xs uppercase">
              <p>Review</p>
              <span>{pendingReviewCount}</span>
            </div>
          </Link>
        </div>
        <div className="SubMenuLinks">
          <Link
            to={`/applications/pending?${getQueryString({
              status: correctionFilters,
            })}`}
          >
            <div className="flex justify-between text-xs uppercase">
              <p>Correction</p>
              <span>{pendingCorrectionCount}</span>
            </div>
          </Link>
        </div>
        <div className="SubMenuLinks">
          <Link to="/applications/deferred">
            <div className="flex justify-between text-xs uppercase">
              <p>Deferred</p>
              <span>{deferredCount}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const SignatureSummaryCard = ({
  pendingReviewCount = 0,
  pendingCorrectionCount = 0,
  deferredCount = 0,
}) => {
  const { getStats } = useSelector((state) => state.statsSlice);
  if (getStats.status === "loading") {
    return (
      <div className="p-6 space-y-4 bg-white rounded-lg text-primary shadow-card min-h-[15rem]">
        <p className="flex justify-center h-full items-center">
          <SpinnerIcon className="text-gray-500" />
        </p>
      </div>
    );
  }
  return (
    <div className="p-6 space-y-4 bg-white rounded-lg text-primary shadow-card">
      <div className="mb-5">
        <h1 className="text-7xl font-base">
          {pendingReviewCount + pendingCorrectionCount + deferredCount}
        </h1>
        <span className="text-xs font-bold uppercase">Signature</span>
        <hr className="w-full border-gray-200 my-1" />
      </div>
      <div className="grid gap-2">
        <div className="SubMenuLinks">
          <Link to="/applications/signature?status=Signature">
            <div className="flex justify-between text-xs uppercase">
              <p>Review</p>
              <span>{pendingReviewCount}</span>
            </div>
          </Link>
        </div>
        <div className="SubMenuLinks">
          <Link to="/applications/signature?status=Signature Corrections">
            <div className="flex justify-between text-xs uppercase">
              <p>Correction</p>
              <span>{pendingCorrectionCount}</span>
            </div>
          </Link>
        </div>
        <div className="SubMenuLinks">
          <Link to="/applications/signature?status=Signature Deferred">
            <div className="flex justify-between text-xs uppercase">
              <p>Deferred</p>
              <span>{deferredCount}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const BRSSubmittedSummaryCard = ({
  pendingReviewCount = 0,
  pendingCorrectionCount = 0,
  deferredCount = 0,
}) => {
  const { getStats } = useSelector((state) => state.statsSlice);
  if (getStats.status === "loading") {
    return (
      <div className="p-6 space-y-4 bg-white rounded-lg text-primary shadow-card min-h-[15rem]">
        <p className="flex justify-center h-full items-center">
          <SpinnerIcon className="text-gray-500" />
        </p>
      </div>
    );
  }
  return (
    <div className="p-6 space-y-4 bg-white rounded-lg text-primary shadow-card">
      <div className="mb-5">
        <h1 className="text-7xl font-base">
          {pendingReviewCount + pendingCorrectionCount + deferredCount}
        </h1>
        <span className="text-xs font-bold uppercase">BRS SUBMITTED</span>
        <hr className="w-full border-gray-200 my-1" />
      </div>
      <div className="grid gap-2">
        <div className="SubMenuLinks">
          <Link
            to={`/applications/brs-submission?${getQueryString({
              status: ["BRS Review", "BRS Review II"],
            })}`}
          >
            <div className="flex justify-between text-xs uppercase">
              <p>BRS Review</p>
              <span>{pendingReviewCount}</span>
            </div>
          </Link>
        </div>
        <div className="SubMenuLinks">
          <Link to="/applications/brs-submission?status=BRS Corrections">
            <div className="flex justify-between text-xs uppercase">
              <p>BRS Correction</p>
              <span>{pendingCorrectionCount}</span>
            </div>
          </Link>
        </div>
        <div className="SubMenuLinks">
          <Link to="/applications/brs-submission?status=Incorporation Deferred">
            <div className="flex justify-between text-xs uppercase">
              <p>BRS Deferred</p>
              <span>{deferredCount}</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const SummaryCard = ({
  className,
  count = 0,
  label = "Completed",
  linkLabel = "All Applications",
  href = "#",
}) => {
  const { getStats } = useSelector((state) => state.statsSlice);

  if (getStats.status === "loading") {
    return (
      <div
        className={twMerge(
          "flex items-center overflow-hidden h-40 w-full bg-white rounded-lg text-primary drop-shadow-sm",
          className
        )}
      >
        <div className="relative w-full p-6 space-y-4 bg-white rounded-lg text-primary shadow-card min-h-[15rem]">
          <p className="flex justify-center absolute inset-0 items-center">
            <SpinnerIcon className="text-gray-500" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        "flex items-center overflow-hidden h-40 w-full bg-white rounded-lg text-primary drop-shadow-sm",
        className
      )}
    >
      <div className="bg-gray-200 text-4xl flex-shrink-0 flex-grow-0 h-full w-36 flex items-center justify-center">
        {formatNumber(count)}
      </div>
      <div className="p-6 flex flex-col">
        <span className="font-medium">{label}</span>
        <Link className="flex space-x-2 pt-6" to={href}>
          <span className="text-sm">{linkLabel}</span>
          <ArrowRightIcon className="w-5 h-5" aria-hidden="true" />
        </Link>
      </div>
    </div>
  );
};

export const AdminDashboardView = ({
  role = Constants.COOKIE_ROLES.APPLICANT,
}) => {
  const dispatch = useDispatch();
  const { stats } = useSelector((state) => state.statsSlice);
  const {
    companies = {},
    profiles = {},
    corporateDirectorsShareholdersCount,
  } = stats;

  useEffect(() => {
    dispatch(getStatsAsync());
  }, [role]);

  return (
    <div className="grid grid-cols-12 gap-8">
      <div className="col-span-12 lg:col-span-8 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {role === Constants.COOKIE_ROLES.ORG_ADMIN && (
            <>
              <ProfilesSummaryCard
                pendingReviewCount={profiles.pendingReviewCount}
                pendingCorrectionCount={profiles.pendingCorrectionCount}
                deferredCount={profiles.deferredCount}
              />
              <ApplicationSummaryCard
                pendingReviewCount={
                  (companies?.adminReview ?? 0) +
                  (companies?.adminReviewII ?? 0)
                }
                pendingCorrectionCount={companies.adminCorrections}
                deferredCount={companies.adminDeferred}
                role={role}
              />
              <SignatureSummaryCard
                pendingReviewCount={companies.signature}
                pendingCorrectionCount={companies.signatureCorrections}
                deferredCount={companies.signatureDeferred}
              />
            </>
          )}
          {role === Constants.COOKIE_ROLES.SUPER_ADMIN && (
            <>
              <ProfilesSummaryCard
                pendingReviewCount={profiles.pendingReviewCount}
                pendingCorrectionCount={profiles.pendingCorrectionCount}
                verifiedCount={profiles.verifiedCount}
                deferredCount={profiles.deferredCount}
              />
              <ApplicationSummaryCard
                pendingReviewCount={
                  (companies?.submission ?? 0) + (companies?.submissionII ?? 0)
                }
                pendingCorrectionCount={companies.submissionCorrections}
                deferredCount={companies.submissionDeferred}
                role={role}
              />
              <SignatureSummaryCard
                pendingReviewCount={companies.signature}
                pendingCorrectionCount={companies.signatureCorrections}
                deferredCount={companies.signatureDeferred}
              />
              <BRSSubmittedSummaryCard
                pendingReviewCount={
                  (companies?.brsReview ?? 0) +
                  (companies?.brsReviewII ?? 0) +
                  (companies?.pendingIncorporation ?? 0)
                }
                pendingCorrectionCount={companies.brsCorrections}
                deferredCount={companies.deferredCount}
              />
            </>
          )}
        </div>
      </div>
      <div className="col-span-12 lg:col-span-4 pt-6 lg:pt-0 border-t lg:border-t-0 lg:border-l border-gray-200 lg:pl-6 space-y-4">
        <h2 className="text-primary font-semibold">Summary</h2>
        {role === Constants.COOKIE_ROLES.ORG_ADMIN && (
          <div className="grid grid-cols-2 gap-4">
            <SummaryCard
              className="col-span-2 sm:col-span-1 lg:col-span-2"
              count={companies.active}
              href="/applications/all-applications"
            />
            <SummaryCard
              className="col-span-2 sm:col-span-1 lg:col-span-2"
              count={profiles.verifiedCount}
              linkLabel="All Profiles"
              href={`/profiles?${new URLSearchParams({ status: "Verified" })}`}
            />
            <SummaryCard
              className="col-span-2 sm:col-span-1 lg:col-span-2"
              count={corporateDirectorsShareholdersCount}
              label="All Corporate Directors/ Shareholders"
              href="/corporates"
            />
          </div>
        )}
        {role === Constants.COOKIE_ROLES.SUPER_ADMIN && (
          <div className="grid grid-cols-2 gap-4">
            <SummaryCard
              className="col-span-2 sm:col-span-1 lg:col-span-2"
              count={0}
              label="Org Admins"
              linkLabel="All Org Admins"
              href="#"
            />
            <SummaryCard
              className="col-span-2 sm:col-span-1 lg:col-span-2"
              count={companies.active}
              href="/applications/all-applications"
            />
            <SummaryCard
              className="col-span-2 sm:col-span-1 lg:col-span-2"
              count={profiles.verifiedCount}
              linkLabel="All Profiles"
              href={`/profiles?${new URLSearchParams({ status: "Verified" })}`}
            />
            <SummaryCard
              className="col-span-2 sm:col-span-1 lg:col-span-2"
              count={corporateDirectorsShareholdersCount}
              label="All Corporate Directors/ Shareholders"
              href="/corporates"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default function AdminDashboard() {
  const currentUser = useSelector((state) => state.userSlice);

  return (
    <DashboardWrapper>
      <div className="text-sflPrimary">
        <div className="container py-16 mx-auto">
          <div className="py-4 text-title5">
            <h2 className="font-semibold text-title5">
              {getGreetings()}, {getFullName(currentUser.names)}
            </h2>
          </div>
          <AdminDashboardView />
        </div>
      </div>
    </DashboardWrapper>
  );
}
