import axios from "../axios";

const getPartner = (organizationId, partnerId, idType) =>
  axios.get(
    `/corporates/${organizationId}/partners/${partnerId}?idType=${idType}`
  );

const getOfficial = (corporateId, officialId, idType) =>
  axios.get(
    `/corporates/${corporateId}/officials/${officialId}?idType=${idType}`
  );

const organizationDashboardDataService = {
  getPartner,
  getOfficial,
};

export default organizationDashboardDataService;
