import React, { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

import AssignedTable from "./Table";
import Button from "components/lib/Shared/Button";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import ValidationError from "components/lib/Shared/ValidationError";
import { verifyBtnParams } from "components/lib/Shared/Verify/verifyBtnParams";
import { useToast } from "hooks/useToast";
import { Constants } from "config/constants";
import {
  addDiscountAssignee,
  getDiscountAssigneeAsync,
} from "state/slices/discountAssignee";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";

function AssignmentForm({ values, errors, touched, submitCount }) {
  const { idType } = values;
  const dispatch = useDispatch();
  const { toast } = useToast();

  const [showAssignButton, setShowAssignButton] = useState(false);
  const [renderAssignForm, setRenderAssignForm] = useState(false);

  const {
    name,
    label,
    placeholder,
    type = "string",
    validationSchema = null,
  } = verifyBtnParams["Director"][idType] ?? {};

  const { selectedDiscount } = useSelector((state) => state.discountSlice);
  const { discountAssignees, getDiscountAssignees } = useSelector(
    (state) => state.discountAssigneeSlice
  );

  useEffect(() => {
    if (!values.isGeneral) {
      setShowAssignButton(true);
      setRenderAssignForm(false);
    } else setShowAssignButton(false);
  }, [values.isGeneral]);

  const handleExistAssignWorkflow = () => {
    setRenderAssignForm(false);
    setShowAssignButton(true);
  };

  const handleAddDiscountAssignee = async (values, verificationKey) => {
    try {
      const verificationValue = values[verificationKey];
      const response = await dispatch(
        getDiscountAssigneeAsync({
          verificationKey,
          verificationValue:
            typeof verificationValue === "string"
              ? verificationValue?.trim()
              : verificationValue,
          idType,
          discountId: selectedDiscount._id,
        })
      );
      if (!response.payload.status) {
        toast(
          "error",
          response.payload.message ?? "Something went wrong please try again."
        );
      } else {
        const userAlreadyAssigned = discountAssignees.items.find(
          (discountAssignee) =>
            discountAssignee.user._id ===
            response.payload?.discountAssignee.user._id
        );
        if (userAlreadyAssigned) {
          toast("error", "User already assigned.");
          return;
        }
        dispatch(
          addDiscountAssignee({
            discountAssignee: response.payload.discountAssignee,
          })
        );
        setRenderAssignForm(false);
        setShowAssignButton(true);
      }
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  if (getDiscountAssignees.status === "loading") {
    return (
      <div className="col-span-6">
        <div className="flex justify-center mx-auto text-white">
          <SpinnerIcon className="text-gray-400" />
        </div>
      </div>
    );
  }

  return (
    <>
      {discountAssignees.totalCount > 0 && (
        <div className="col-span-6">
          <AssignedTable />
        </div>
      )}
      {!values?.isGeneral && showAssignButton && !renderAssignForm && (
        <>
          {discountAssignees.totalCount === 0 ? (
            <div className="col-span-6">
              <Button
                preIcon={PlusIcon}
                variant="text"
                className="mt-2"
                onClick={() => setRenderAssignForm(true)}
              >
                Assign
              </Button>
              <ValidationError
                errors={errors}
                touched={{ ...touched, discountAssignees: submitCount > 0 }}
                name={"discountAssignees"}
              />
            </div>
          ) : (
            <div className="col-span-6">
              <Button
                preIcon={PlusIcon}
                variant="text"
                className="mt-2"
                onClick={() => setRenderAssignForm(true)}
              >
                Assign More
              </Button>
            </div>
          )}
        </>
      )}
      {!values?.isGeneral && renderAssignForm && (
        <div className="col-span-6 grid grid-cols-5 gap-6">
          <div className="col-span-2">
            <Field
              component={ReactSelectWithFormik}
              showRequiredAsterisk
              label="Select Id Type"
              id="idType"
              name="idType"
              options={Constants.COMPANY_OFFICIAL_ID_TYPES.filter(
                (idType) => idType.value !== "Minor"
              )}
              placeholder="Select id type"
            />
            <ValidationError
              errors={errors}
              touched={{ ...touched, idType: true }}
              name={"idType"}
            />
          </div>
          {values?.idType && (
            <div className="col-span-3">
              <Formik
                enableReinitialize
                initialValues={{ [name]: "" }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleAddDiscountAssignee(values, name)}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  ...verificationFormProps
                }) => {
                  const handleKeyDown = (e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      handleAddDiscountAssignee(values, name);
                    }
                  };

                  return (
                    <Form onKeyDown={handleKeyDown} className="flex space-x-2">
                      <div className="flex-grow">
                        <label
                          htmlFor={`${name}_verify_btn`}
                          className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500"
                        >
                          {label}
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <Field
                            type={type}
                            name={name}
                            id={`${name}_verify_btn`}
                            autoComplete={"off"}
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
                            placeholder={placeholder}
                            disabled={isSubmitting}
                          />
                        </div>
                        <ValidationError
                          errors={errors}
                          touched={{ ...touched, [name]: true }}
                          name={name}
                        />
                      </div>
                      <div className="flex space-x-2 mt-6 h-fit">
                        <Button
                          disabled={isSubmitting}
                          variant="secondary"
                          onClick={handleExistAssignWorkflow}
                        >
                          Cancel
                        </Button>
                        <Button
                          className=""
                          onClick={verificationFormProps.handleSubmit}
                          loadingText="Saving..."
                          isLoading={isSubmitting}
                        >
                          Add
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default AssignmentForm;
