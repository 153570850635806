// import valid from "card-validator";
import { object, string } from "yup";
import { Constants } from "config/constants";

const billingDetailsValidation = ({ idType }) => {
  console.log({ idType });
  const validationSchema = object().shape({
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    email: string()
      .email("Invalid email address format")
      .required("Email is required"),
    mobileNumber: string()
      .trim("Mobile number cannot include leading and trailing spaces")
      .when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: string().optional(),
        otherwise: string().required("Mobile number is required"),
      })
      .matches(Constants.PHONE_NUMBER_REGEX, "Phone number is not valid")
      .test("length", function (value) {
        if (
          value &&
          [
            "Kenyan Citizen",
            "Foreign Resident",
            "Local Company",
            "Other e.g Parastatals",
          ].includes(idType)
        ) {
          if (value.length !== 12) {
            return this.createError({
              message: "Mobile number must be exactly 12 characters",
            });
          }
          if (!value.startsWith("254")) {
            return this.createError({
              message: "Correct format is 254#########",
            });
          }
          return true;
        } else if (
          value &&
          (value.length < 11 || value.length > 13) &&
          ["Foreigner", "Foreign Company"].includes(idType)
        ) {
          return this.createError({
            message: "Mobile number is not correct",
          });
        } else return true;
      }),
    billingAddress: string().required("Billing address is required"),
    zipCode: string().required("Zip code or Postal code is required"),
    city: string().required("City is required"),
    country: string().required("Country is required"),
    pin: string(),
  });

  return {
    schema: validationSchema,
    schemaInitialValues: validationSchema.cast(),
  };
};

export default billingDetailsValidation;
