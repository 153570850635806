import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils";
import { XCircleIcon } from "@heroicons/react/outline";

import Tabs from "components/lib/Shared/Tabs";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import Input from "components/lib/Shared/Input";
import Button from "components/lib/Shared/Button";
import { BillingDetails } from "./PaymentMethod/BillingDetails";

import validationSchema from "./PaymentMethod/validationSchema";

import { getPoBoxAddress } from "utils";
function PreferredPlan({
  tabs,
  selectedPackage,
  selectedTabIndex,
  setSelectedTabIndex,
  selectedCurrency,
  setSelectedCurrency,
  promoCode,
  setPromoCode,
  submittingToCheckout,
  handleProceedToCheckout,
}) {
  const userSlice = useSelector((state) => state.userSlice);

  useEffect(() => {
    const defaultCurrencyType = [
      "Kenyan Citizen",
      "Foreign Resident",
      "Local Company",
    ].includes(userSlice.idType)
      ? "KES"
      : "USD";
    setSelectedCurrency(
      selectedPackage.currencies.find(
        (currency) => currency.type === defaultCurrencyType
      )
    );
    setPromoCode(selectedPackage?.discountCode ?? "");
  }, [selectedPackage, userSlice.idType]);

  const handleDefaultCurrencyChange = ({ value }) => {
    setSelectedCurrency(
      selectedPackage.currencies.find((currency) => currency._id === value)
    );
  };
  console.log({ userSlice });
  const billingInitialValues = {
    firstName: userSlice?.billing?.firstName
      ? userSlice?.billing?.firstName
      : userSlice?.names?.firstName ?? "",
    lastName: userSlice?.billing?.lastName
      ? userSlice?.billing?.lastName
      : userSlice?.names?.lastName ?? "",
    email: userSlice?.billing?.email
      ? userSlice?.billing?.email
      : userSlice?.email ?? "",
    mobileNumber: userSlice?.billing?.mobileNumber
      ? userSlice?.billing?.mobileNumber?.replace("+", "")
      : userSlice?.electronicAddress?.mobileNumber.replace("+", "") ?? "",
    billingAddress: getPoBoxAddress(
      userSlice.idType,
      userSlice.postalAddress,
      userSlice.isNonResident
    ),
    zipCode: userSlice?.billing?.zipCode ?? "",
    city: userSlice?.billing?.city ?? "",
    // country: "",
    country: userSlice?.billing?.country
      ? userSlice?.billing?.country
      : userSlice?.identificationDetails?.nationality ?? "",
    pin: userSlice?.billing?.KRAPin
      ? userSlice?.billing?.KRAPin
      : userSlice?.identificationDetails?.KRAPin ?? "",
  };

  return (
    <>
      <div className="mb-2 sm:-mt-4">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
        />
      </div>

      <Formik
        enableReinitialize
        initialValues={billingInitialValues}
        validationSchema={validationSchema({ idType: userSlice.idType }).schema}
        onSubmit={(values) => {
          handleProceedToCheckout(selectedPackage, values);
        }}
      >
        {(pps) => {
          const { values, setTouched, setFieldValue, field } = pps;
          console.log({ pps });
          return (
            <Form>
              <div className="grid grid-cols-12 gap-4 Wrapper">
                {/* Package detail */}
                <div className="col-span-8 space-y-5">
                  <div className="mt-8 px-4">
                    <p>{selectedPackage.description}</p>
                  </div>
                  <div className="py-1 text-sm">
                    <div className="bg-white px-4 py-6 rounded-sm">
                      <div className="flex space-x-2">
                        <XCircleIcon className="w-5 h-5" />
                        <p>This package includes:</p>
                      </div>
                      <div>
                        <ul className="px-12 py-2 list-disc break-words">
                          {selectedPackage.features.map((feature) => (
                            <li key={feature._id}>{feature.description}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="py-3">
                      <h1 className="font-medium">Billing details</h1>
                      <hr className="my-1" />
                    </div>
                    <BillingDetails
                      country={values.country}
                      idType={userSlice.idType}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>

                <div className="col-span-4 mt-8">
                  <div className="flex justify-between font-medium text-lg items-center ">
                    <h1 className="">Total</h1>
                    <h3>
                      {selectedCurrency.type}{" "}
                      {formatCurrency(
                        (selectedCurrency.basePrice +
                          (selectedCurrency?.profitMargin ?? 0)) *
                          selectedCurrency.applicableTax
                      )}
                    </h3>
                  </div>
                  <div>
                    {/* Change your default currency */}
                    <div className="col-span-6 sm:col-span-3">
                      <ReactSelectWithFormik
                        name="defaultCurrency"
                        label="Change your default currency"
                        onChange={handleDefaultCurrencyChange}
                        value={
                          selectedCurrency
                            ? {
                                value: selectedCurrency._id,
                                label: selectedCurrency.type,
                              }
                            : ""
                        }
                        options={selectedPackage.currencies.map((currency) => ({
                          value: currency._id,
                          label: currency.type,
                        }))}
                        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        id="defaultCurrency"
                        placeholder="Select currency"
                      />
                    </div>
                    {selectedPackage?.renderPromoCode && (
                      <div className="col-span-6 sm:col-span-3 mt-2">
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                          <div className="bg-white p-2">
                            <Input
                              name="promoCode"
                              label="Have a promo code?"
                              onChange={(e) => setPromoCode(e.target.value)}
                              value={promoCode}
                              disabled={selectedPackage?.discountCode}
                              className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              id="promoCode"
                              placeholder="Enter promo code"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="relative">
                      <Button
                        isLoading={submittingToCheckout}
                        type="submit"
                        // onClick={() => handleProceedToCheckout(selectedPackage, values)}
                        loadingText="You are being redirected"
                        className="flex items-center px-4 py-2 font-semibold text-white rounded bg-sflPrimary mt-7 w-full justify-center"
                      >
                        <p className="font-semibold">Proceed to checkout</p>
                        <span className="flex items-center ml-2 text-white h-7"></span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default PreferredPlan;
