import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { object, number, string } from "yup";
import {
  Field,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from "formik";
import _isEmpty from "lodash/isEmpty";

import CompaniesBeneficialOwnershipComponent from "components/PLCTABS/CompanyOfficialsTab/BeneficialOwnershipComponents/CompaniesBeneficialOwnershipComponent";
import OfficialsBeneficialOwnershipComponent from "components/PLCTABS/CompanyOfficialsTab/BeneficialOwnershipComponents/OfficialsBeneficialOwnershipComponent";
import {
  CORPORATES,
  INDIVIDUALS,
} from "components/PLCTABS/SFLdata/config/constants";
import Button from "components/lib/Shared/Button";
import { updateCompanyOfficialAsync } from "state/slices/tables/companyOfficialsSlice";
import { getAllBeneficialOwners } from "state/slices/tables/beneficialOwnersTableSlice";
import { getUpdatedFormData, roundNumber } from "utils";

const getValidationSchema = ({ idType, isReportable }) => {
  const schema = object().shape({
    beneficialOwnershipForm: object().shape({
      maxPercentShareholding: number().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: number().optional(),
        otherwise: number().required("Max percent shareholding is required"),
      }),
      directPercentShareholding: number()
        .transform((value) => (isNaN(value) ? 0 : value))
        .when("$maxPercentShareholding", {
          is: (maxPercentShareholding) =>
            INDIVIDUALS.includes(idType) && maxPercentShareholding > 0,
          then: number()
            .min(0, "Value must be greater than or equal to 0")
            .required("Direct percent shareholding is required"),
        }),
      indirectPercentShareholding: number().when("$maxPercentShareholding", {
        is: (maxPercentShareholding) => maxPercentShareholding > 0,
        then: number()
          .min(0, "Value must be greater than or equal to 0")
          .required("Indirect percent shareholding is required"),
      }),
      maxPercentVotingRights: number().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: number().optional(),
        otherwise: number().required("Max percent voting rights is required"),
      }),
      directPercentVotingRights: number()
        .transform((value) => (isNaN(value) ? 0 : value))
        .when("$maxPercentVotingRights", {
          is: (maxPercentVotingRights) =>
            INDIVIDUALS.includes(idType) && maxPercentVotingRights > 0,
          then: number().when("$makeFieldsOptional", {
            is: (makeFieldsOptional) => makeFieldsOptional,
            then: number().optional(),
            otherwise: number()
              .min(0, "Value must be greater than or equal to 0")
              .required("Direct percent voting rights is required"),
          }),
        }),
      indirectPercentVotingRights: number().when("$maxPercentVotingRights", {
        is: (maxPercentVotingRights) => maxPercentVotingRights > 0,
        then: number().when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: number().optional(),
          otherwise: number()
            .min(0, "Value must be greater than or equal to 0")
            .required("Indirect percent voting rights is required"),
        }),
      }),
      directRightRemoveDirector: string().when("idType", {
        is: () => INDIVIDUALS.includes(idType),
        then: string().when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Please select one option"),
        }),
      }),
      indirectRightRemoveDirector: string().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: string().optional(),
        otherwise: string().required("Please select one option"),
      }),
      directCompanyControlRight: string().when("idType", {
        is: () => INDIVIDUALS.includes(idType),
        then: string().when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Please select one option"),
        }),
      }),
      indirectCompanyControlRight: string().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: string().optional(),
        otherwise: string().required("Please select one option"),
      }),
      dateOfBecomingBO: string().when("isReportable", {
        is: () => isReportable === true,
        then: string().when("$makeFieldsOptional", {
          is: (makeFieldsOptional) => makeFieldsOptional,
          then: string().optional(),
          otherwise: string().required("Date of becoming BO is required"),
        }),
        otherwise: string().notRequired(),
      }),
      sourceOfBOInformation: string().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) => makeFieldsOptional,
        then: string().optional(),
        otherwise: string().required(
          "Source of beneficial owner information is required"
        ),
      }),
    }),
  });
  return {
    schema,
  };
};

function BeneficialOwnershipForm({ row, setEditOfficialBeneficialOwnership }) {
  const { idType } = row.original;
  const dispatch = useDispatch();
  const [tempBeneficialOwnership, setTempBeneficialOwnership] = useState({});
  const { company } = useSelector((state) => state.companySlice);
  const { shares } = useSelector((state) => state.sharesAllocationTableSlice);
  const { shares: nominalShareCapital } = useSelector(
    (state) => state.sharesTableSlice
  );

  const { sumOfNominalValue, sumOfVotingRights } = useMemo(
    () =>
      shares.reduce(
        (acc, share) => ({
          sumOfNominalValue: acc.sumOfNominalValue + Number(share.nominalValue),
          sumOfVotingRights: acc.sumOfVotingRights + Number(share.votingRights),
        }),
        { sumOfNominalValue: 0, sumOfVotingRights: 0 }
      ),
    [shares]
  );

  const [validationSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    const { isNonResident } = row.original || {};
    setValidationSchema(
      getValidationSchema({
        idType,
        isNonResident,
      }).schema
    );
  }, [idType, row.original]);

  useEffect(() => {
    const getMaxPercentShareholding = () => {
      const nominalShareCapitalSum = nominalShareCapital.reduce(
        (acc, share) => acc + Number(share.nominalValue),
        0
      );
      if (nominalShareCapitalSum === 0) return 0;
      return roundNumber((sumOfNominalValue / nominalShareCapitalSum) * 100, 2);
    };

    const getMaxPercentVotingRights = () => {
      const allVotingRights = nominalShareCapital.reduce(
        (acc, share) => acc + Number(share.votingRights),
        0
      );
      if (allVotingRights === 0) return 0;
      return roundNumber((sumOfVotingRights / allVotingRights) * 100, 2);
    };

    if (nominalShareCapital.length > 0) {
      let tempBeneficialOwnership = {};
      const maxPercentShareholding = getMaxPercentShareholding();
      const maxPercentVotingRights = getMaxPercentVotingRights();
      tempBeneficialOwnership["maxPercentShareholding"] =
        maxPercentShareholding;
      tempBeneficialOwnership["maxPercentVotingRights"] =
        maxPercentVotingRights;
      if (CORPORATES.includes(idType)) {
        tempBeneficialOwnership["indirectPercentShareholding"] =
          maxPercentShareholding;
        tempBeneficialOwnership["indirectPercentVotingRights"] =
          maxPercentVotingRights;
      }
      setTempBeneficialOwnership(tempBeneficialOwnership);
    }
  }, [sumOfNominalValue, sumOfVotingRights, nominalShareCapital, roundNumber]);

  const editOfficial = async (values) => {
    let payload = {
      beneficialOwnershipForm: values.beneficialOwnershipForm || {},
    };
    if (
      ["Local Company", "Foreign Company", "Other e.g Parastatals"].includes(
        payload.idType
      )
    ) {
      payload = {
        beneficialOwnershipForm: {
          ...payload.beneficialOwnershipForm,
          directPercentShareholding: null,
          directPercentVotingRights: null,
        },
      };
    }

    const companyId = company?._id;

    const updatedOfficialData = getUpdatedFormData(
      ["beneficialOwnershipForm"],
      row.original,
      payload
    );
    if (!_isEmpty(updatedOfficialData)) {
      await dispatch(
        updateCompanyOfficialAsync({
          officialId: row.original._id,
          data: {
            beneficialOwnershipForm: values.beneficialOwnershipForm,
            companyId,
            idType,
          },
        })
      );
    }
    setEditOfficialBeneficialOwnership(false);
    dispatch(getAllBeneficialOwners({ companyId }));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        beneficialOwnershipForm: {
          maxPercentShareholding: "",
          directPercentShareholding: "",
          indirectPercentShareholding: "",
          maxPercentVotingRights: "",
          directPercentVotingRights: "",
          indirectPercentVotingRights: "",
          directRightRemoveDirector: "",
          indirectRightRemoveDirector: "",
          directCompanyControlRight: "",
          indirectCompanyControlRight: "",
          sourceOfBOInformation: "",
          ...row.original.beneficialOwnershipForm,
          dateOfBecomingBO:
            row.original.beneficialOwnershipForm.dateOfBecomingBO || "",
          ...tempBeneficialOwnership,
        },
      }}
      validate={(values) => {
        try {
          validateYupSchema(values, validationSchema, true, {
            maxPercentShareholding:
              values.beneficialOwnershipForm?.maxPercentShareholding ?? 0,
            maxPercentVotingRights:
              values.beneficialOwnershipForm?.maxPercentVotingRights ?? 0,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={async (values) => {
        const officialSchema = getValidationSchema({
          idType,
        }).schema;
        try {
          validateYupSchema(values, officialSchema, true);
        } catch (error) {
          if (error.errors.length > 0) {
            return;
          }
        }
        await editOfficial(values);
      }}
    >
      {({ values, errors, isSubmitting }) => {
        const {
          maxPercentVotingRights = 0,
          maxPercentShareholding = 0,
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = values?.beneficialOwnershipForm ?? {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";
        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";
        let isReportable = false;

        if (INDIVIDUALS.includes(idType) && isDirectBeneficiary)
          isReportable = true;
        if (CORPORATES.includes(idType) && isIndirectBeneficiary)
          isReportable = true;

        return (
          <Form>
            <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
              <div className="px-4 py-6 sm:p-6">
                <h2 className="mb-1 text-lg font-medium leading-6 text-gray-900">
                  Beneficial Ownership
                </h2>
                {[
                  "Kenyan Citizen",
                  "Foreign Resident",
                  "Foreigner",
                  "Minor",
                ].includes(row.original.idType) && (
                  <Field
                    name="beneficialOwnershipForm"
                    component={OfficialsBeneficialOwnershipComponent}
                    isReportable={isReportable}
                  />
                )}
                {[
                  "Local Company",
                  "Foreign Company",
                  "Other e.g Parastatals",
                ].includes(idType) && (
                  <Field
                    name="beneficialOwnershipForm"
                    component={CompaniesBeneficialOwnershipComponent}
                    isCompanyOfficialTab
                    isReportable={isReportable}
                  />
                )}
              </div>
              <div className="flex justify-end space-x-2 py-3">
                <Button
                  disabled={isSubmitting}
                  onClick={() => setEditOfficialBeneficialOwnership(false)}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:cursor-not-allowed"
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:cursor-not-allowed"
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default BeneficialOwnershipForm;
