import identificationValidation from "../commonValidationSchema/identificationValidation";
import residentialDetailsValidation from "../commonValidationSchema/residentialDetailsValidation";
import uploadDocumentsValidation from "../commonValidationSchema/uploadDocumentsValidation";
import shareAllocationValidation from "./shareAllocationValidation";

/**
 * This returns an object composed of conditional `yup` schema,
 * based on the current tab of `Shareholder` and `Director Shareholder` officials.
 *
 * @param {string} selectedTab The current tab/step of the multi step form e.g `"generalInformation"`.
 * @param {string} idType The idType of the current official e.g `"Kenyan Citizen"`.
 * @param {string} isNonResident Conditional string value `'No' || 'Yes` of the current official.
 * @param {array} documentData The array of objects related to file uploads.
 * @param {array} shares The array of allotted shares to current official.
 * @param {Boolean} isReportable Is current official reportable or not `true` | `false`.
 * @return {object} This returns object containing schema.
 * @example
 *  {
 *    schema // yup schema
 *  }
 */
const getValidationSchema = ({
  selectedTab,
  idType,
  isNonResident = null,
  documentData,
  shares,
  isReportable,
}) => {
  if (!selectedTab?.id)
    return identificationValidation(idType)
      .schema.concat(
        residentialDetailsValidation({ idType, isNonResident }).schema
      )
      .concat(uploadDocumentsValidation(documentData).schema)
      .concat(shareAllocationValidation(idType, shares, isReportable).schema);

  switch (selectedTab.id) {
    case "generalInformation":
      return identificationValidation(idType);
    case "residentialDetails":
      return residentialDetailsValidation({ idType, isNonResident });
    case "uploadDocuments":
      return uploadDocumentsValidation(documentData);
    case "shareAllocation":
      return shareAllocationValidation(idType, shares, isReportable);
    default:
      return { schema: null };
  }
};

export default getValidationSchema;
