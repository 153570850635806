import ReactTable from "components/lib/Shared/ReactTable";
import Tag from "components/lib/Shared/Tag";

function OfficialShareAllocation({ official, sharesAllotted = [] }) {
  const officialShareAllocationColumns = [
    {
      Header: "Class of shares",
      Cell: ({ row }) => row.original.classOfShares,
    },
    { Header: "Number of Shares", accessor: "allottedShares" },
    {
      Header: "Value Of Shares",
      Cell: ({ row }) => (
        <div className="inline-flex items-baseline">
          <span className="px-1 text-xs font-medium">KES</span>{" "}
          <Tag>{row.original.sharesValue}</Tag>
        </div>
      ),
    },
    { Header: "Paid Up Shares", accessor: "paidUpShares" },
    {
      Header: "Premium Paid",
      Cell: ({ row }) => (
        <div className="inline-flex items-baseline">
          <span className="px-1 text-xs font-medium">KES</span>{" "}
          <Tag>{row.original.sharePremium}</Tag>
        </div>
      ),
    },
    {
      Header: "Nominal Value",
      Cell: ({ row }) => (
        <div className="inline-flex items-baseline">
          <span className="px-1 text-xs font-medium">KES</span>{" "}
          <Tag>{row.original.nominalValue}</Tag>
        </div>
      ),
    },
    {
      Header: "Voting Rights",
      Cell: ({ row }) => row.original.votingRights,
    },
  ];

  return (
    <>
      {sharesAllotted.length > 0 ? (
        <div className="w-full bg-white shadow">
          <div className="w-full overflow-x-auto mt-4">
            <ReactTable
              columns={officialShareAllocationColumns}
              data={sharesAllotted}
              headingRowStyle="w-full h-12 text-sm font-semibold leading-none text-gray-600"
              headingStyle="pl-4 text-left"
              dataRowStyle="h-12 text-sm leading-none text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
            />
          </div>
        </div>
      ) : (
        <p className="mt-4 ml-80">No data available</p>
      )}
    </>
  );
}

export default OfficialShareAllocation;
