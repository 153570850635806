import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import Input from "components/lib/Shared/Input";
import { useToast } from "hooks/useToast";
import { inviteOrganization } from "state/slices/organizations";
import Button from "components/lib/Shared/Button";

function OrganizationInviteForm({ searchValue, setShowSearchSection }) {
  const dispatch = useDispatch();
  const { toast } = useToast(5000);
  const { inviteOrganizationStatus } = useSelector(
    (state) => state.organizationsSlice
  );

  const schema = object().shape({
    organizationName: string()
      .trim("Organization name cannot include leading and trailing spaces")
      .strict(true)
      .notRequired("Organization name is required"),
    contactPersonEmail: string()
      .email("Invalid email format")
      .trim("Email cannot include leading and trailing spaces")
      .strict(true)
      .required("Email address is required"),
  });

  useEffect(() => {
    if (inviteOrganizationStatus.error) {
      toast("error", inviteOrganizationStatus.error);
    }
  }, [inviteOrganizationStatus.error]);

  const handleSendInvite = async (values, resetForm) => {
    try {
      await dispatch(inviteOrganization({ data: values }));
      resetForm();
      setShowSearchSection(false);
    } catch (error) {
      toast(
        "error",
        error?.message ?? "Something went wrong please try again."
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        organizationName: searchValue,
        contactPersonEmail: "",
      }}
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => {
        handleSendInvite(values, resetForm);
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <div className="flex flex-col mt-6">
              <h3 className="font-medium text-gray-800">
                Organizations Invite
              </h3>
              <p className="text-gray-400">
                Your organization administrator will receive send you a special
                onboarding link to upload all the required information to
                generate a dashboard for your company.
              </p>
              <div className="grid grid-cols-1 gap-6 p-8 mt-4 bg-white border border-gray-100">
                <div className="grid gap-6 mb-0 md:grid-cols-2">
                  <div className="">
                    <label
                      htmlFor="organizationName"
                      className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500"
                    >
                      Organization Name
                    </label>
                    <Field
                      component={Input}
                      name="organizationName"
                      id="organizationName"
                      autoComplete="off"
                      disabled={isSubmitting}
                      placeholder="Liroja Services"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="contactPersonEmail"
                      className="block text-sm font-medium text-gray-700 after:content-['*'] after:ml-0.5 after:text-red-500"
                    >
                      Email Address
                    </label>
                    <Field
                      component={Input}
                      name="contactPersonEmail"
                      id="contactPersonEmail"
                      autoComplete="off"
                      type="email"
                      placeholder="lirojaservices@email.com"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <div className="my-0">
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    loadingText="Sending..."
                  >
                    Send Invite
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OrganizationInviteForm;
