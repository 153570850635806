export const TermOfUse = () => {
  return (
    <>
      <div>
        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          In the course of using our website, you may provide us with your
          personal data and we are obliged to use the personal data in
          accordance with all the laws concerning the protection of personal
          data including the Data Protection Act 2019 (“DPA”) and the
          Regulations thereto. Please read this Data Privacy Statement carefully
          to understand our personal data management practices.
        </p>
      </div>

      <div className="space-y-2 pb-10 lg:text-base text-sm">
        <p className="text-gray-500 font-semibold text-base py-4">
          Definitions
        </p>
        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          This Terms of Use Agreement (the “Terms of Use” or “Agreement”)
          describes the terms and conditions applicable to your access and use
          of the Website.
        </p>

        <p>
          The following meanings apply in these terms of service: "Site" refers
          to any website made available by Simple Formations. "Services" refers
          to all services provided by Simple Formations on the site, "we", "us"
          and "our" refer to Simple Formations and its employees, consultants,
          officers, directors, or agents (the "Representatives"). "User," "you"
          and "your" refer to each customer, visitor or user of any Services
          offered by Simple Formations. If you access or use the Services on
          behalf of a company, organization, or other entity, then (a) "you" and
          "your" also refers to that entity, (b) you represent and warrant that
          you are a Representative of the entity with the authority to bind it
          to these terms of service, and (c) you agree to these terms of service
          on the entity's behalf.
        </p>

        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          By accessing or using any website or service made available by Simple
          Formations, you acknowledge that you;
        </p>

        <div className="text-base">
          <ul className="space-y-0.5">
            <li>
              <span>1) </span>
              have read and agree to be bound by, and to comply with, these and
              any additional or amended terms of service;
            </li>
            <li>
              <span>2) </span>
              agree to comply with all applicable laws and regulations; and
            </li>
            <li>
              <span>3) </span>
              you are legally allowed to enter into a contract.
            </li>
          </ul>
        </div>
      </div>
      <div className="space-y-2 pb-10 ">
        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          Simple Formations reserves the right, at its sole discretion, to
          change, modify, add or remove portions of these Terms of Use, at any
          time. It is therefore your responsibility to check these Terms of Use
          periodically for changes.
        </p>
        <p className="xl:w-10/12 w-full  lg:text-base text-sm">
          Your continued use of the site following the posting of changes or
          amendment(s) will mean that you accept and agree to the changes. As
          long as you comply with these Terms of Use, Simple Formations grants
          you a non-exclusive, non-transferable, limited privilege, revocable
          license to enter and use the Site. If at any time you find these terms
          of service unacceptable or if you do not agree to these terms of
          service, please do not proceed to access the services.
        </p>

        <div>
          {/* Tab 0 */}
          <div>
            <div className="flex py-6 justify-between items-center border-b border-gray-200">
              <h1 className="text-base md:text-xl lg:text-2xl font-bold">
                User interaction and user content
              </h1>
            </div>
            <div className="py-4 space-y-4">
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                Your continued use of the site following the posting of changes
                or amendment(s) will mean that you accept and agree to the
                changes. As long as you comply with these Terms of Use, Simple
                Formations grants you a non-exclusive, non-transferable, limited
                privilege, revocable license to enter and use the Site. If at
                any time you find these terms of service unacceptable or if you
                do not agree to these terms of service, please do not proceed to
                access the services.
              </p>
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                All text, graphics, user interfaces, visual interfaces,
                photographs, trademarks, logos, artwork and computer codes
                (collectively “Content”), including but not limited to the
                design, structure, selection, coordination, expression, “look
                and feel” and arrangement of such Content, contained on the Site
                is owned, controlled or licensed by or to Simple Formations, and
                is protected by copyright, patent and trademark laws, and
                various other intellectual property rights and unfair
                competition laws.
              </p>
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                For the purposes of this terms of service, "Intellectual
                Property Rights" means all patent rights, copyright rights,
                moral rights, rights of publicity, trademark, goodwill, trade
                secret rights and other intellectual property rights as may
                exist now or hereafter come into existence, and all applications
                therefore and registrations, renewals and extensions thereof,
                under the Laws of Kenya, territory or other jurisdiction.
              </p>
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                You are solely responsible for your interactions with other
                users of the Services. We reserve the right, but have no
                obligation, to monitor interactions between you and other users.
                Simple Formations shall have no liability for your interactions
                with other users, or for any user’s action or inaction.
              </p>

              <div className="text-base">
                <p>You agree not to post User Content that: </p>
                <p className="xl:w-10/12 lg:w-10/12 w-full  ">
                  <ul className="space-y-0.5">
                    <li>
                      <span>1.) </span>
                      may create a risk of harm, loss, physical or mental
                      injury, emotional distress, death, disability,
                      disfigurement, or physical or mental illness to you, to
                      any other person;
                    </li>
                    <li>
                      <span>2.) </span>
                      may create a risk of any other loss or damage to any
                      person or property
                    </li>
                    <li>
                      <span>3.) </span>
                      seeks to harm or exploit children by exposing them to
                      inappropriate content, asking for personally identifiable
                      details or otherwise;
                    </li>
                    <li>
                      <span>4.) </span>
                      may constitute or contribute to a crime or tort
                    </li>
                    <li>
                      <span>5.) </span>
                      contains any information or content that we deem to be
                      unlawful, harmful, abusive, racially or ethnically
                      offensive, defamatory, infringing, invasive of personal
                      privacy or publicity rights, harassing, humiliating to
                      other people (publicly or otherwise), libelous,
                      threatening, profane, or otherwise objectionable
                    </li>
                    <li>
                      <span>6.) </span>
                      contains any information or content that is illegal
                      (including, without limitation, the disclosure of insider
                      information under securities laws or of another party's
                      trade secrets);
                    </li>
                    <li>
                      <span>7.) </span>
                      contains any information or content that you do not have a
                      right to make available under any law or under contractual
                      or fiduciary relationships; or
                    </li>

                    <li>
                      <span>8.) </span>
                      contains any information or content that you know is not
                      correct, current or factual. You agree that any User
                      Content that you post does not and will not violate
                      third-party rights of any kind, including without
                      limitation any Intellectual Property Rights (as defined
                      above), rights of publicity and privacy.
                    </li>
                  </ul>
                </p>
              </div>

              <div>
                <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                  You acknowledge and agree that any User Content that you post
                  may be displayed and disclosed to other users as permitted
                  through the functionality of the Services. Simple Formations
                  reserves the right, but is not obligated, to edit, remove,
                  re-categorize, pre-screen, reject and/or otherwise modify any
                  User Content that Simple Formations believes, in its sole
                  discretion, violates these provisions.
                </p>
              </div>
            </div>
          </div>

          {/* Tab 2 */}
          <div>
            <div className="flex py-6 justify-between items-center border-b border-gray-200">
              <h1 className="text-base md:text-xl lg:text-2xl font-bold">
                Terms of sale
              </h1>
            </div>
            <div className="py-4 space-y-4">
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                Upon payment, you agree to the terms of sale, pricing, payment
                and billing policies applicable to such fees and charges. You
                will pay any applicable taxes, if any, relating to any such
                purchases and transactions. Simple Formations may add new
                services for additional fees and charges, or amend fees and
                charges for existing services, at any time in its sole
                discretion.  You may cancel your account at any time; however,
                there are no refunds for cancellation. In the event that Simple
                Formations suspends or terminates your account, you understand
                and agree that you shall receive no refund or exchange for any
                Simple Formations’ Content, any unused time or service on a
                subscription, any license or subscription fees for any portion
                of the Services, any content or data associated with your
                account.
              </p>
            </div>
          </div>

          {/* Tab 3 */}
          <div>
            <div className="flex py-6 justify-between items-center border-b border-gray-200">
              <h1 className="text-base md:text-xl lg:text-2xl font-bold">
                System security
              </h1>
            </div>
            <div className="py-2 ">
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                You are responsible for all activity that happens on or through
                your User Account. You are also responsible for maintaining and
                keeping your User Account information including your password,
                and any activity that occurs under your account, secure and
                confidential. You agree to notify Simple Formations immediately
                of any unauthorized use of your account or password, or any
                other breach of security. Simple Formations is not liable for
                any harm you incur as a result of another person's use of your
                User Account. You may be held liable for any harm Simple
                Formations incurs as a result of another person's use of your
                User Account.
              </p>
            </div>
          </div>

          {/* Tab 4 */}
          <div>
            <div className="flex py-6 justify-between items-center border-b border-gray-200">
              <h1 className="text-base md:text-xl lg:text-2xl font-bold">
                Confidential information
              </h1>
            </div>
            <div className="my-4 space-y-4">
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                Information you provide to Simple Formations that is not
                Confidential Information will be treated in accordance with the
                Privacy Policy. You can view our Data Privacy Statement at
                www.simpleformations.ke/legals/privacy.
              </p>

              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                “Confidential Information" means all non-public, proprietary
                information concerning the user including, but not limited to,
                its business, finances, shareholders’ and directors’
                information, customers, intellectual property, business plans.
              </p>

              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                Confidential Information does not include information which (a)
                the receiving party can show was in possession or known to the
                receiving party by being in its use or being recorded in its
                files or computers or other recording media prior to receipt
                from the disclosing party or on its behalf and was not
                previously acquired by the Receiving Party from the disclosing
                Party or its behalf under an obligation of confidence (b) is or
                becomes generally available to the public (c) becomes available
                to the Receiving Party from a source other than the Disclosing
                Party provided that such source is not actually known by the
                Receiving Party to be bound by any contractual, legal or
                fiduciary obligation of confidentiality to Disclosing Party with
                respect to such information ;or (d) is independently developed
                by the Receiving Party or its representatives without use of, or
                reference to, confidential information.
              </p>

              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                You may choose to or we may invite you to submit comments or
                ideas about the Services, including without limitation about how
                to improve the Services or our products ("Ideas"). By submitting
                any Idea, you agree that your disclosure is gratuitous,
                unsolicited and without restriction and will not place Simple
                Formations under any fiduciary or other obligation, and that we
                are free to use the Idea without any additional compensation to
                you, and/or to disclose the Idea on a non-confidential basis or
                otherwise to anyone. You further acknowledge that, by acceptance
                of your submission, Simple Formations does not waive any rights
                to use similar or related ideas previously known to Simple
                Formations, or developed by its employees, or obtained from
                sources other than you.
              </p>
            </div>
          </div>

          {/* Tab 5 */}
          <div>
            <div className="flex py-6 justify-between items-center border-b border-gray-200">
              <h1 className="text-base md:text-xl lg:text-2xl font-bold">
                Disclaimer
              </h1>
            </div>
            <div className="my-4 space-y-4">
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                The Site and its content are delivered on an “as-is” and
                “as-available” basis. All information provided on the site is
                subject to change without notice. Simple Formations disclaims
                any and all liability for the acts, omissions and conduct of any
                third parties in connection with or related to your use of the
                site.
              </p>

              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                The above disclaimer applies to any damages, liability or
                injuries caused by any failure of performance, error, omission,
                interruption, deletion, defect, delay in operation or
                transmission, computer virus, communication line failure, theft
                or destruction of or unauthorized access to, alteration of, or
                use, whether for breach of contract, tort, negligence or any
                other cause of action.
              </p>

              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                Simple Formations reserves the right to do any of the following,
                at any time, without notice (a) to modify, suspend or terminate
                operation of or access to the site, or any portion of the site,
                for any reason; (b) to modify or change the site, or any portion
                of the site, and any applicable policies or terms (c) to
                interrupt the operation of the site, as necessary to perform
                routine or non-routine maintenance, error correction, or other
                changes.
              </p>
            </div>
          </div>

          {/* Tab 7 */}
          <div>
            <div className="flex py-6 justify-between items-center border-b border-gray-200">
              <h1 className="text-base md:text-xl lg:text-2xl font-bold">
                Prohibited conduct
              </h1>
            </div>
            <div className="my-4 space-y-4">
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                In addition to the restrictions set out above, you must not use
                the site in any inappropriate or unlawful manner. Simple
                Formations reserves the right to bar any prohibited conduct or
                activity and/or suspend your use of the site or take other
                appropriate remedial action, to address any prohibited conducted
                or any violation or suspected violation of these terms of use.
              </p>

              <div className="text-base">
                <p>Examples of inappropriate and unlawful use include: </p>
                <p className="xl:w-10/12 lg:w-10/12 w-full  ">
                  <ul className="space-y-0.5">
                    <li>
                      <span>1.) </span>
                      Probing, scanning or testing the vulnerability of the site
                      or any network connected to the site, or breaching the
                      security or authentication measures on the site.
                    </li>
                    <li>
                      <span>2.) </span>
                      Reverse look-up, trace or seek to trace any information on
                      any other user of or visitor to the Site, or any other
                      customer of Simple Formations, including any Simple
                      Formations account not owned by you, to its source, or
                      exploit the Site or any service or information made
                      available of offered by or through the Site, in any way
                      where the purpose is to reveal any information.
                    </li>
                    <li>
                      <span>3.) </span>
                      Use of any “deep-link”, “page-scrape”, “robot” or other
                      device, program, algorithm or methodology, or any similar
                      manual process, to access, acquire, copy or monitor any
                      portion of the Site or any Content, or in any way
                      reproduce or circumvent the navigational structure or
                      presentation of the Site or any Content, to obtain or
                      attempt to obtain any materials, documents or information
                      through any means not purposely made available through the
                      Site.
                    </li>
                    <li>
                      <span>4.) </span>
                      damage, disables, interferes with, overburdens, or
                      otherwise impairs the Services or any computer software or
                      hardware or electronic communications equipment.
                    </li>
                    <li>
                      <span>5.) </span>
                      improperly impersonates others or misrepresents or
                      conceals your identity or your affiliation with others.
                    </li>
                    <li>
                      <span>6.) </span>
                      uses any content or other information contained on or
                      obtained from or through the Services, except as provided
                      for in these terms of service or with our prior written
                      permission.
                    </li>
                    <li>
                      <span>7.) </span>
                      copies or modifies the Services or otherwise affects the
                      display of the Services as provided by Simple Formations
                      or distributes, sells, leases, rents, modifies,
                      distributes, copies, reproduces, transmits, public
                      displays, publicly performs, publishes, adapts, edits,
                      creates derivative works or makes any portion of the
                      Services available for access by third parties in any
                      manner other than as provided by Simple Formations.
                    </li>

                    <li>
                      <span>8.) </span>
                      accesses or uses the Site or Services for unauthorized
                      advertising, marketing, or communication or for other
                      unauthorized commercial purposes.
                    </li>

                    <li>
                      <span>9.) </span>
                      Engaging, encouraging or permitting another person or
                      solicit the performance to do any of the above.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          {/* Tab 8 */}
          <div>
            <div className="flex py-6 justify-between items-center border-b border-gray-200">
              <h1 className="text-base md:text-xl lg:text-2xl font-bold">
                Third party services
              </h1>
            </div>
            <div className="my-4 space-y-4">
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                The Services may refer you to or allow you to share information
                with/from third parties that are not owned or controlled by
                Simple Formations, such as collaborators, applications,
                websites, information, advertisers, promotions, products,
                services, or other resources (collectively, the "Third-Party
                Services"). Simple Formations does not endorse or assume any
                responsibility for any Third-Party Services. You access and use
                Third-Party Services at your own risk, and you understand that
                these terms of service and Simple Formations’ Privacy Policy do
                not apply to your use of Third-Party Services. You expressly
                relieve Simple Formations from any and all liability arising
                from your use of any Third-Party Services.
              </p>
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                In addition, the site may contain links to other websites of
                interest. However, once you exit the Site, you should note that
                we do not have control over that other website. Therefore, we
                cannot be responsible for the protection and privacy of any
                information which you provide whilst visiting such site and such
                sites are not governed by our privacy statement. You should
                exercise caution and review the private statement for the
                website in question before proceeding.
              </p>
            </div>
          </div>

          {/* Tab 9 */}
          <div>
            <div className="flex py-6 justify-between items-center border-b border-gray-200">
              <h1 className="text-base md:text-xl lg:text-2xl font-bold">
                Termination/access restriction
              </h1>
            </div>
            <div className="my-4 space-y-4">
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                The above licenses granted by you in your User Content terminate
                within a commercially reasonable time after you remove or delete
                your User Content from the Services. We may retain and use your
                User Content as necessary to comply with our legal obligations,
                resolve disputes, and enforce our agreements. Consistent with
                these requirements, we will try to delete your User Content
                quickly upon request; however we do not delete User Content that
                you have in common with other users. Please note, however, that
                there might be latency in deleting User Content from our servers
                and backed-up versions might exist after deletion.
              </p>
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                In addition, Simple Formations may also at its sole discretion
                limit access to the Services and/or terminate the accounts of
                any users who infringe any intellectual property rights of
                others, whether or not there is any repeat infringement.
              </p>
            </div>
          </div>

          {/* Tab 10 */}
          <div>
            <div className="flex py-6 justify-between items-center border-b border-gray-200">
              <h1 className="text-base md:text-xl lg:text-2xl font-bold">
                General
              </h1>
            </div>
            <div className="my-4 space-y-4">
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                The interpretation, construction and effect of these Terms shall
                be governed by and construed in all respects in accordance with
                the Law of Kenya.
              </p>
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                Any dispute which relates to Services offered on this Site shall
                be referred to Senior representatives of Simple Formations and
                parties shall use their best endevours to resolve the dispute
                within 30 days of the dispute. Any dispute which has not been
                settled in full by mutual agreement shall be referred to a
                single arbitrator to be appointed by agreement of parties or in
                default of such agreement, by an arbitrator appointed by the
                Chairman of the Kenyan Chapter of the Chartered Institute of
                Arbitrators.
              </p>
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                The invalidity, unenforceability of any of the terms in this
                Agreement shall not affect the validity, legality or
                enforceability of the remaining provisions of this Agreement.
              </p>
              <p className="xl:w-10/12 w-full  lg:text-base text-sm">
                No waiver or forbearance by either party, whether express or
                implied, in enforcing any of its rights hereunder shall
                prejudice or affect its ability to enforce such rights or any of
                its other rights hereunder at any time in the future. No waiver
                shall be effective unless in writing and signed by the waiving
                party.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
