import React from "react";
import { useSelector } from "react-redux";
import _get from "lodash/get";

import FormFieldsCard from "components/lib/Global/FormFieldsCard";
import CommentsList from "components/lib/Global/CommentsList";

function ProfessionalDetails({ handleSaveComment, isProfileReview }) {
  const { user } = useSelector((state) => state.orgAdminSlice);
  const professionalDetails = useSelector(
    (state) => state.professionalDetailsSlice
  );
  const { items: comments } = useSelector(
    (state) => state.commentsSlice.profileComments
  );

  return (
    <>
      {professionalDetails.items.map(
        (professionalDetail, professionalDetailIndex) => {
          const formFields = [
            {
              key: "Role",
              name: `${professionalDetail.capacity}.capacity`,
              value: _get(professionalDetail, "capacity", "N/A"),
            },
            {
              key: "Practicing Number",
              name: `${professionalDetail.capacity}.practicingNumber`,
              value: _get(professionalDetail, "practicingNumber", "N/A"),
            },
            {
              key: "Practicing Certificate",
              name: `${professionalDetail.capacity}.practicingCertificate`,
              value: _get(professionalDetail, "practicingCertificate", "N/A"),
            },
            {
              key: "Practicing Certificate",
              fileName: "practicingCertificate",
              name: `${professionalDetail.capacity}.fileUploads.practicingCertificate`,
              showFilePreview: true,
            },
          ];
          const professionalDetailComments = comments.filter(
            (comment) =>
              comment.formName === "professionalDetail" &&
              comment.fieldName.includes(professionalDetail.capacity)
          );

          return (
            <div key={_get(professionalDetail, "_id")}>
              {professionalDetailComments &&
                professionalDetailComments.length > 0 && (
                  <div className="px-8 pt-5 pb-6 mt-6 bg-white">
                    <CommentsList
                      comments={professionalDetailComments}
                      toggleCommentStatus
                      isProfileReview
                    />
                    <p className="mt-6 text-sm text-primary">
                      Comments: {professionalDetailComments.length} of{" "}
                      {professionalDetailComments.length}
                    </p>
                  </div>
                )}
              <FormFieldsCard
                handleSaveComment={handleSaveComment}
                fields={formFields}
                resolvedFields={_get(professionalDetail, "resolvedFields")}
                addReviewComment
                formName="professionalDetail"
                formHelperKey="professionalDetail"
                updateEffectKey="updateProfessionalState"
                updateEffectPath={`professionalDetailsSlice.items.${professionalDetailIndex}`}
                formId={_get(professionalDetail, "_id")}
                comments={professionalDetailComments}
                isProfileReview={isProfileReview}
                rowId={user._id}
                files={professionalDetail.fileUploads}
              />
            </div>
          );
        }
      )}
    </>
  );
}

export default ProfessionalDetails;
