/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useFormikContext, Field } from "formik";
import { subYears, format, addDays } from "date-fns";

import Input from "components/lib/Shared/Input";
import DatePickerField from "components/lib/Shared/DatePickerField";
import ReactSelectWithFormik from "components/lib/Shared/DropdownSelect";
import options from "../../SFLdata/selectOptions";

// Identification Details for Minor
const IdentificationDetailsFormMi = ({
  field,
  form,
  disabled,
  disableVerificationField,
}) => {
  const { setFieldValue } = useFormikContext();
  const minDateOfBirth = addDays(subYears(new Date(), 18), 1);

  const [startDate, setStartDate] = useState("");
  const [gender, setGender] = useState({
    label: field?.value?.gender ?? "",
    value: field?.value?.gender ?? "",
  });
  const [nationality, setNationality] = useState(() =>
    options.countries.find(
      (country) => country.value === field?.value?.nationality
    )
  );

  const handleSetValue = (e) => {
    const value =
      e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
    setFieldValue(field.name, {
      ...field.value,
      [e.target.name]: value,
    });
  };

  const handleSelect = (type) => (option) => {
    setFieldValue(field.name, {
      ...field.value,
      [type]: option.value,
    });
    if (type === "gender") {
      setGender({ label: option.value, value: option.value });
    }
    if (type === "nationality") {
      setNationality({ label: option.label, value: option.value });
    }
  };

  const handleDateValue = (date) => {
    setFieldValue(field.name, {
      ...field.value,
      dateOfBirth: format(date, "yyyy/MM-/d"),
    });
    return setStartDate(date);
  };

  const selectedDOB =
    field.value && field.value.dateOfBirth
      ? new Date(field.value.dateOfBirth)
      : startDate;

  return (
    <>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-2">
          <Input
            label="Birth Certificate Number"
            name="birthCertificateNumber"
            placeholder="Enter national id number"
            form={form}
            type={form.values?.nationality === "KE" ? "number" : "text"}
            value={field.value?.birthCertificateNumber ?? ""}
            errorPath="identificationDetails.birthCertificateNumber"
            onBlur={field.onBlur}
            onChange={handleSetValue}
            disabled={disabled || disableVerificationField}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <DatePickerField
            label="Date of Birth"
            onChange={handleDateValue}
            selected={selectedDOB}
            minDate={minDateOfBirth}
            form={form}
            name="dateOfBirth"
            placeholderText="Select date of birth"
            errorPath="identificationDetails.dateOfBirth"
            disabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Field
            {...field}
            label="Gender"
            value={gender.value}
            name="gender"
            component={ReactSelectWithFormik}
            options={options.gender}
            errorPath="identificationDetails.gender"
            placeholder="Select gender"
            onBlur={field.onBlur}
            onChange={handleSelect("gender")}
            isDisabled={disabled}
          />
        </div>
        <div className="col-span-6 sm:col-span-2">
          <Field
            {...field}
            label="Nationality"
            value={nationality}
            name="nationality"
            component={ReactSelectWithFormik}
            options={options.countries}
            errorPath="identificationDetails.nationality"
            placeholder="Select nationality"
            onBlur={field.onBlur}
            onChange={handleSelect("nationality")}
            isSearchable
            isDisabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

export default IdentificationDetailsFormMi;
