import { Field } from "formik";
import { useContext } from "react";

import { ManageModuleContext } from "context/ManageCompanyModuleContext";
import IsNonResidentSelection from "components/lib/Shared/IsNonResidentSelection";
import PostalAddressFormLocal from "./PostalAddressFormLocal";
import PostalCodeForm from "./PostalCodeForm";
import ResidentialAddressDetailsFormKeCiFoRe from "./ResidentialAddressDetailsFormKeCiFoRe";
import PostalAddressFormForeign from "./PostalAddressFormForeign";
import ResidentialAddressDetailsFormFoMi from "./ResidentialAddressDetailsFormFoMi";
import ResidentialPhysicalAddressFoCo from "./ResidentialPhysicalAddressFoCo";
import ResidentialPhysicalAddressLoCo from "./ResidentialPhysicalAddressLoCo";

const ResidentialDetails = ({
  designation,
  idType,
  errors,
  touched,
  isNonResident,
}) => {
  const { changeOfParticularsInfo } = useContext(ManageModuleContext);

  return (
    <>
      {(designation === "Director Shareholder" ||
        designation === "Shareholder") &&
        (idType === "Kenyan Citizen" || idType === "Minor") && (
          <IsNonResidentSelection
            disabled={false}
            errors={errors}
            touched={touched}
          />
        )}
      <div>
        {(((idType === "Kenyan Citizen" || idType === "Minor") &&
          isNonResident === "No") ||
          idType === "Foreign Resident") && (
          <>
            <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <Field
                name="postalAddress"
                component={PostalAddressFormLocal}
                idType={idType}
                disabled={false}
              />
              <Field
                name="postalAddress"
                component={PostalCodeForm}
                disabled={false}
              />
            </div>
            <Field
              name="residentialAddressDetails"
              component={ResidentialAddressDetailsFormKeCiFoRe}
              colWidth={4}
              disabled={false}
              selectedValueType={changeOfParticularsInfo.selectedValueType}
            />
          </>
        )}
        {(idType === "Foreigner" ||
          ((idType === "Kenyan Citizen" || idType === "Minor") &&
            isNonResident === "Yes")) && (
          <>
            <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <Field
                name="postalAddress"
                component={PostalAddressFormForeign}
                idType={idType}
                disabled={false}
              />
            </div>
            <Field
              name="residentialAddressDetails"
              component={ResidentialAddressDetailsFormFoMi}
              disabled={false}
              selectedValueType={changeOfParticularsInfo.selectedValueType}
            />
          </>
        )}
        {(idType === "Local Company" || idType === "Other e.g Parastatals") && (
          <>
            <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <Field
                name="postalAddress"
                component={PostalAddressFormLocal}
                disabled={false}
              />
              <Field
                name="postalAddress"
                component={PostalCodeForm}
                disabled={false}
              />
            </div>
            <Field
              name="residentialAddressDetails"
              component={ResidentialPhysicalAddressLoCo}
              disabled={false}
              selectedValueType={changeOfParticularsInfo.selectedValueType}
            />
          </>
        )}
        {idType === "Foreign Company" && (
          <>
            <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
              <Field
                name="postalAddress"
                component={PostalAddressFormForeign}
                disabled={false}
              />
            </div>
            <Field
              name="residentialAddressDetails"
              component={ResidentialPhysicalAddressFoCo}
              disabled={false}
              selectedValueType={changeOfParticularsInfo.selectedValueType}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ResidentialDetails;
