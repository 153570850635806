import React, { useState, useEffect, useMemo } from "react";
import { EyeIcon, SwitchVerticalIcon } from "@heroicons/react/outline";

import Table from "components/lib/Global/ReactTable";
import { delay, getFullName } from "utils";
import StakeholderSubRow from "./StakeholderSubRow";
import { useSelector } from "react-redux";

function StakeholderTable({
  data,
  currentTab,
  currentSubTab,
  isJointShareholderTable,
  isViewMode,
  goToPrevStep = () => null,
}) {
  const [expandedRow, setExpandedRow] = useState({});
  const [initialRows, setInitialRows] = useState({});
  const { comments } = useSelector((state) => state.commentsSlice);
  const originalCompanyOfficials = useSelector(
    (state) => state.companyOfficialsSlice.companyOfficials.items
  );

  const transformSharesAllotted = (sharesAllotted) =>
    sharesAllotted
      .map(
        ({ classOfShares, allottedShares }) =>
          `<div>${classOfShares}: ${allottedShares}</div>`
      )
      .join("");

  const columns = useMemo(
    () =>
      [
        {
          Header: () => (
            <div>
              Name
              {!isJointShareholderTable && (
                <SwitchVerticalIcon className="inline w-4 h-4 ml-1 text-tertiary" />
              )}
            </div>
          ),
          id: "Name",
          accessor: "fullName",
          Cell: ({ row: { original } }) => {
            if (original.fullName) return original.fullName;
            return getFullName(original.names) || "N/A";
          },
          // We can render something different for subRows
          SubCell: (cellProps) => <>{cellProps.value}</>,
        },
        {
          Header: () => (
            <div>
              ID Type
              {!isJointShareholderTable && (
                <SwitchVerticalIcon className="inline w-4 h-4 ml-1 text-tertiary" />
              )}
            </div>
          ),
          id: "ID Type",
          accessor: "idType",
        },
        // add is Director column for joint shareholders
        isJointShareholderTable
          ? {
              Header: () => "Is a Director?",
              id: "isDirector",
              Cell: ({ row }) => {
                return row.original.isDirector;
              },
            }
          : null,
        // add designation column for stakeholder tab
        !isJointShareholderTable && currentTab === "stakeholderTab"
          ? {
              Header: () => (
                <div>
                  Designation
                  {!isJointShareholderTable && (
                    <SwitchVerticalIcon className="inline w-4 h-4 ml-1 text-tertiary" />
                  )}
                </div>
              ),
              id: "Designation",
              Cell: ({ row: { original } }) => {
                return Array.isArray(original.fullName)
                  ? "Joint Shareholder"
                  : original.isDirector === "Yes"
                  ? "Director"
                  : original?.designation ?? "";
              },
            }
          : null,
        // add share allocation column for stakeholder tab
        !isJointShareholderTable && currentTab === "stakeholderTab"
          ? {
              Header: () => (
                <div>
                  Shares alloted
                  {!isJointShareholderTable && (
                    <SwitchVerticalIcon className="inline w-4 h-4 ml-1 text-tertiary" />
                  )}
                </div>
              ),
              id: "Shares alloted",
              Cell: ({ row }) => {
                if (
                  row.original?.isDirector === "Yes" &&
                  !Array.isArray(row.original?.fullName)
                )
                  return "";
                return (
                  <span
                    className="space-y-1"
                    dangerouslySetInnerHTML={{
                      __html: transformSharesAllotted(
                        row.original?.sharesAllotted ?? []
                      ),
                    }}
                  />
                );
              },
            }
          : null,
        {
          // Make an expander cell
          Header: () => "Actions", // No header
          id: "expander", // It needs an ID
          Cell: ({ row, ...rest }) => {
            const originalRow = row.original;
            let onClick = async () => {
              if (!row.isExpanded) {
                row.toggleAllRowsExpanded(false); // Close all rows
                await delay(10);
                setInitialRows(rest.initialRows);
                setExpandedRow(row);
                row.toggleRowExpanded(); // Open the clicked row
              } else {
                setInitialRows({});
                setExpandedRow({});
                row.toggleRowExpanded(); // Open the clicked row
              }
            };
            if (
              !originalRow?.linkToCompany &&
              !originalRow?.shareholderWithBO &&
              currentTab === "beneficialOwnershipTab"
            ) {
              onClick = async () => {
                goToPrevStep();
                await delay(); // wait for the stakeholders tab to be mounted
                let companyShareholderEditBtn = null;
                if (originalRow?.JSGroupID)
                  companyShareholderEditBtn = document.querySelector(
                    `[id="${originalRow?.JSGroupID}"]`
                  );
                else if (originalRow?._id && !originalRow.JSGroupID)
                  companyShareholderEditBtn = document.querySelector(
                    `[id="${originalRow?._id}"]`
                  );
                if (companyShareholderEditBtn) {
                  companyShareholderEditBtn.click();
                }
              };
            }

            let officialComments =
              comments.filter(
                (comment) =>
                  comment?.officialId &&
                  comment.officialId === row.original._id &&
                  comment?.tabName &&
                  comment.tabName === "stakeholderTab"
              )?.length ?? 0;

            const jointShareholderGroup = originalCompanyOfficials.filter(
              (official) =>
                row.original?.JSGroupID &&
                official?.JSGroupID === row.original?.JSGroupID &&
                row.original.idType === "Joint Shareholder"
            );

            const jointShareholderGroupIds = jointShareholderGroup.map(
              (official) => official._id
            );

            if (row.original.JSGroupID && jointShareholderGroup.length > 0) {
              officialComments =
                comments.filter(
                  (comment) =>
                    comment?.officialId &&
                    jointShareholderGroupIds.includes(comment.officialId) &&
                    comment?.tabName &&
                    comment.tabName === "stakeholderTab"
                )?.length ?? 0;
            }

            return (
              // Use Cell to render an expander for each row.
              // We can use the getToggleRowExpandedProps prop-getter
              // to build the expander.
              <span
                className="text-sm font-normal text-primary relative cursor-pointer"
                onClick={onClick}
                id={
                  !originalRow.linkToCompany &&
                  currentTab === "beneficialOwnershipTab"
                    ? null
                    : originalRow._id
                }
              >
                <EyeIcon className="inline w-4 h-4 mr-1 text-tertiary" />
                <span>{row.isExpanded ? "Hide Details" : "view details"}</span>
                {officialComments ? (
                  <span className="absolute flex items-center justify-center min-w-[4] h-4 p-1 text-white bg-red-700 rounded-full -top-3 -right-6">
                    {officialComments}
                  </span>
                ) : null}
              </span>
            );
          },
          // We can override the cell renderer with a SubCell to be used with an expanded row
          SubCell: () => null, // No expander on an expanded row
        },
      ].filter(Boolean),
    [isJointShareholderTable, currentTab, comments, goToPrevStep]
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <StakeholderSubRow
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        currentTab={currentTab}
        initialRows={initialRows}
        isViewMode={isViewMode}
        seniorJointShareholder={isJointShareholderTable ? data[0] : null}
      />
    ),
    [currentTab, initialRows, isViewMode]
  );

  useEffect(() => {
    if (expandedRow?.isExpanded) expandedRow?.toggleRowExpanded();
  }, [currentTab, currentSubTab, expandedRow]);

  return (
    <div className="w-full">
      <Table
        columns={columns}
        data={data}
        dataRowCellStyle="whitespace-normal"
        headingStyle={`${
          isJointShareholderTable ? "bg-sflPrimary text-white" : ""
        }`}
        // We added this as a prop for our table component
        // Remember, this is not part of the React Table API,
        // it's merely a rendering option we created for
        // ourselves
        renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
}

export default StakeholderTable;
