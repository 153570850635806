import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import Tabs from "components/lib/Shared/Tabs";
import CompanySummary from "./CompanySummary";
import RegisteredAddress from "./RegisteredAddress";
import ShareInformation from "./ShareInformation";
import StakeholderDetails from "./StakeholderDetails";
import BeneficialOwnership from "components/PLCTABS/ApplicationReviewTab/BeneficialOwnership";

export default function BusinessDetails() {
  const { beneficialOwners, groupedBeneficialOwners } = useSelector(
    (state) => state.companyDashboardSlice
  );

  const tabs = useMemo(() => {
    return [
      {
        id: "companySummary",
        label: "Summary",
      },
      {
        id: "registeredAddress",
        label: "Registered Address",
      },
      {
        id: "shareInformation",
        label: "Share information",
      },
      {
        id: "stakeholderDetails",
        label: "Stakeholder details",
      },

      {
        id: "beneficialOwnership",
        label: "Beneficial ownership",
      },
    ].filter(Boolean);
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <>
      <div className="overflow-x-auto">
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          showTooltip
        />
      </div>
      {selectedTab.id === "companySummary" && <CompanySummary />}
      {selectedTab.id === "registeredAddress" && <RegisteredAddress />}
      {selectedTab.id === "shareInformation" && <ShareInformation />}
      {selectedTab.id === "stakeholderDetails" && <StakeholderDetails />}
      {selectedTab.id === "beneficialOwnership" && (
        <BeneficialOwnership
          beneficialOwners={beneficialOwners.items}
          groupedBeneficialOwners={groupedBeneficialOwners}
          //handleBackBtn={goToPrevStep}
          hideReviewWorkflow
          renderShortTypeOfControl
          showDocumentLabel
        />
      )}
    </>
  );
}
