import React from "react";
import { useSelector } from "react-redux";

import PartnersTable from "./PartnersTable";

const PartnersDetails = (props) => {
  const partners = useSelector((state) => state.partnersSlice);

  return (
    <div className="mt-6">
      <div className="w-full mt-4">
        <div className="w-full overflow-x-auto">
          <PartnersTable data={partners} {...props} />
        </div>
      </div>
    </div>
  );
};

export default PartnersDetails;
