/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SignUpForm from "components/Auth/SignUpForm";

const SignUp = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <div className="relative text-base tracking-tight">
      <SignUpForm />
    </div>
  );
};

export default SignUp;
