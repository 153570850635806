/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";

import ValidationError from "components/lib/Shared/ValidationError";
import CommentsList from "components/lib/Global/CommentsList";

function YesSubsidiaryRadioButton({
  field,
  form: { setFieldValue, errors, touched, values = {} },
  transformedComments,
}) {
  const handleChange = (e) => {
    setFieldValue(field.name, {
      ...field.value,
      holdingCompanyPin: e.target.value,
    });
  };

  // initialValues are required for validation
  useEffect(() => {
    setFieldValue(field.name, {
      holdingCompanyPin: "",
      ...field.value,
    });
  }, []);

  const holdingCompanyPinComments =
    transformedComments?.["businessApplicationDetails"]?.[
      "subsidiaryDetail.holdingCompanyPin"
    ] ?? [];

  return (
    <div>
      <div className="form-group row">
        <label
          htmlFor="holdingCompanyPin"
          className="block text-sm font-medium text-gray-700"
        >
          <span className="relative after:content-['*'] after:ml-0.5 after:text-red-500">
            Holding Company Pin
            {holdingCompanyPinComments.length > 0 && (
              <span className="absolute -top-2 -right-5 flex items-center justify-center p-1 h-4 text-white bg-red-700 rounded-full">
                <span>{holdingCompanyPinComments.length}</span>
              </span>
            )}
          </span>
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm capitalized focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none disabled:cursor-not-allowed"
            id="holdingCompanyPin"
            name="holdingCompanyPin"
            placeholder="Enter holding company pin"
            value={field.value.holdingCompanyPin}
            disabled={values?.resolvedFields?.includes(
              "subsidiaryDetail.holdingCompanyPin"
            )}
            onChange={handleChange}
          />
          {holdingCompanyPinComments.length > 0 && (
            <CommentsList
              comments={holdingCompanyPinComments}
              color={"text-red-500"}
            />
          )}
          <ValidationError
            errors={errors}
            touched={touched}
            name="subsidiaryDetail.holdingCompanyPin"
          />
        </div>
      </div>
    </div>
  );
}

export default YesSubsidiaryRadioButton;
