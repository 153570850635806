import { twMerge } from "tailwind-merge";

import CommentsList from "../Global/CommentsList";

const ProfileLabel = ({
  name,
  label,
  value,
  className = "",
  width = "w-32",
  comments = [],
}) => {
  return (
    <div>
      <div className="flex items-baseline">
        <label
          htmlFor={name}
          className={twMerge(
            `block  mb-2 text-sm text-gray-600 font-medium ${width}`,
            className
          )}
        >
          {label}
        </label>
        <input
          disabled
          name={name}
          value={value}
          className="w-full text-sm px-3 py-2 ml-1 placeholder-gray-300 bg-green-100 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
        />
      </div>
      {comments.length > 0 && (
        <CommentsList comments={comments} color={"text-red-500"} />
      )}
    </div>
  );
};

export default ProfileLabel;
