import { getQueryString } from "utils/getQueryString";
import axios from "../axios";

const getAll = (queryParams) =>
  axios.get(`/invoices?${getQueryString(queryParams)}`);

const get = (invoiceId) => axios.get(`/invoices/${invoiceId}`);

const downloadInvoice = (invoiceId) =>
  axios.get(`/invoices/${invoiceId}/download-invoice`, {
    responseType: "blob",
  });

const exportInvoices = (queryParams) =>
  axios.get(`/invoices/export-invoices?${getQueryString(queryParams)}`, {
    responseType: "blob",
  });

const invoiceService = {
  getAll,
  get,
  downloadInvoice,
  exportInvoices,
};

export default invoiceService;
