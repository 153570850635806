import React from "react";
import _orderBy from "lodash/orderBy";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

import ReactTable from "components/lib/Shared/ReactTable";
import TableActionMenu from "components/lib/Shared/TableActionMenu";
import { roundNumber } from "utils";
import { Constants } from "config/constants";

const CorporateShareholderTable = (props) => {
  const {
    corporateShareholders,
    handleEdit,
    handleDelete,
    allottedShares,
    allottedVotingRights,
  } = props;

  const currentUser = useSelector((state) => state.userSlice);
  const role = Cookies.get("role");

  const transformRightLabel = (value) => {
    return value === "yes" ? "Yes" : "No";
  };

  const columns = [
    {
      Header: "Sn",
      Cell: ({ row }) => {
        if (row.original.id === "total") return <b>Total</b>;
        return row.index + 1;
      },
    },
    {
      Header: "Role",
      Cell: ({ row: { original } }) => {
        if (original.id === "total") return "";
        if (original.remainingMinorityShareholders) return "Shareholders";
        return original?.role?.join(", ") ?? "";
      },
    },
    {
      Header: "Full Name",
      className: "p-4 text-ellipsis overflow-hidden max-w-[150px]",
      Cell: ({ row: { original } }) => {
        if (original.id === "total") return "";
        if (original.remainingMinorityShareholders) {
          const minorityFullName = `${original.remainingMinorityShareholders} Minority Shareholders`;
          return <span>{minorityFullName}</span>;
        }
        const fullName = original.names?.fullName ?? "";
        return <span>{fullName}</span>;
      },
    },
    { Header: "ID Type", accessor: "idType" },
    {
      Header: "Percentage Shareholding",
      Cell: ({ row: { original } }) => {
        if (original.id === "total") return <b>{`${allottedShares}%`}</b>;
        if ("totalMinorityShareholdingPercent" in original)
          return (
            <span>{`${roundNumber(
              original.totalMinorityShareholdingPercent
            )}%`}</span>
          );
        return `${roundNumber(
          Number(
            original.beneficialOwnershipForm?.directPercentShareholding || 0
          ) +
            Number(
              original.beneficialOwnershipForm?.indirectPercentShareholding || 0
            )
        )}%`;
      },
    },
    {
      Header: "Percentage Voting",
      Cell: ({ row: { original } }) => {
        if (original.id === "total") return <b>{`${allottedVotingRights}%`}</b>;
        if ("totalMinorityVotingPercent" in original)
          return (
            <span>{`${roundNumber(
              original.totalMinorityVotingPercent
            )}%`}</span>
          );
        return `${roundNumber(
          Number(
            original.beneficialOwnershipForm?.directPercentVotingRights || 0
          ) +
            Number(
              original.beneficialOwnershipForm?.indirectPercentVotingRights || 0
            )
        )}%`;
      },
    },
    {
      Header: "Appoint/Remove Director",
      Cell: ({ row: { original } }) => {
        if (original.id === "total") return "";
        return transformRightLabel(
          original.beneficialOwnershipForm?.indirectRightRemoveDirector
        );
      },
    },
    {
      Header: "Significant control over company",
      Cell: ({ row: { original } }) => {
        if (original.id === "total") return "";
        return transformRightLabel(
          original.beneficialOwnershipForm?.indirectCompanyControlRight
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        if (row.original.id === "total") return "";

        const isOwnerRow = [
          row.original?.electronicAddress?.emailAddress,
          row.original?.identificationDetails?.emailAddress,
        ].includes(currentUser.electronicAddress.emailAddress);

        const disableEditBtn =
          (row.original?.type === "invited" ||
            role === Constants.COOKIE_ROLES.OFFICIAL) &&
          !isOwnerRow;

        return (
          <div className="flex items-center space-x-2">
            <TableActionMenu
              row={row}
              showViewBtn={role !== Constants.COOKIE_ROLES.OFFICIAL}
              showReviewBtn={
                !isOwnerRow &&
                row.original?.type === "invited" &&
                row.original.createdBy === currentUser._id &&
                !row.original?.isReadOnly
              }
              disableEditBtn={
                ((row.original?.type === "invited" ||
                  role === Constants.COOKIE_ROLES.OFFICIAL) &&
                  !isOwnerRow) ||
                row.original?.isReadOnly
              }
              disableDeleteBtn={
                ((row.original?.type === "invited" ||
                  role === Constants.COOKIE_ROLES.OFFICIAL) &&
                  !isOwnerRow) ||
                row.original?.isReadOnly
              }
              handleEdit={(row) =>
                handleEdit({
                  ...row,
                  isViewOnly: disableEditBtn || row.original?.isReadOnly,
                })
              }
              handleView={(row) =>
                handleEdit({
                  ...row,
                  isViewOnly: disableEditBtn || row.original?.isReadOnly,
                })
              }
              handleDelete={handleDelete}
            />
          </div>
        );
      },
    },
  ];

  const data = _orderBy(
    corporateShareholders,
    [
      "beneficialOwnershipForm.directPercentShareholding",
      "beneficialOwnershipForm.indirectPercentShareholding",
      "beneficialOwnershipForm.directPercentVotingRights",
      "beneficialOwnershipForm.indirectPercentVotingRights",
      "beneficialOwnershipForm.directRightRemoveDirector",
      "beneficialOwnershipForm.indirectRightRemoveDirector",
      "beneficialOwnershipForm.directCompanyControlRight",
      "beneficialOwnershipForm.indirectCompanyControlRight",
    ],
    ["desc", "desc", "desc", "desc", "desc", "desc", "desc", "desc"]
  );

  // Move minority entry to the last on top ten shareholders table
  if (corporateShareholders.length > 10) {
    const minorityShareholders = data.splice(0, 1)[0];
    data.splice(10, 0, minorityShareholders);
  }

  if (corporateShareholders.length > 0) data.push({ id: "total" });

  return (
    <div className="w-full bg-white shadow">
      <div className="w-full overflow-x-auto">
        <ReactTable
          columns={columns}
          data={data}
          showSnColumn={false}
          headingRowStyle="w-full h-12 text-sm font-semibold text-gray-600 bg-slate-200"
          headingStyle="p-2 text-left"
          dataRowStyle="h-12 text-sm text-gray-700 bg-white border-t border-b border-gray-200 hover:bg-gray-50"
        />
      </div>
    </div>
  );
};

export default CorporateShareholderTable;
